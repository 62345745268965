<app-header></app-header>
<section class="banner text-center text-white d-flex align-items-center banner-full"
    style="background-image: url(assets/images/banner.jpg)">
    <div class="container position-relative">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <div class="heading-dual">
                    <h1>LogSolutec</h1>
                    <h2>Feedback</h2>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="about-wrapper text-dark py-8" style="background-image: url(assets/images/feedback_bg.png)">
    <div class="row justify-content-evenly align-items-center">
        <div class="col-lg-4 pl-5">
            <h3>Give your Valuable
                <span class="text-success">Feedback</span>
            </h3>
            <p>Our barometer of success is determined through our customers’ feedback. Thus, we pull up our socks and
                work our bottoms off to turn your vision into reality. Kindly drop your feedback so that we can improve
                our business solutions to meet your requirements.
            </p>
        </div>
        <div [formGroup]="feedbackForm" class="col-lg-4 pr-5">
            <div class="row">
                <div class="col-md-6">
                    <label for="">Name*</label>
                    <input type="text" class="form-control" formControlName="name"
                    [ngClass]="{'is-invalid': feedback.name.invalid && ( feedback.name.dirty || feedback.name.touched)}">
                    <div class="text-danger validation-message"
                        *ngIf="feedback.name.invalid && ( feedback.name.dirty || feedback.name.touched)">
                        Full Name is mandatory
                    </div>
                </div>
                <div class="col-md-6">
                    <label for="">Email-Id*</label>
                    <input type="email" class="form-control" formControlName="emailId"
                    [ngClass]="{'is-invalid': feedback.emailId.invalid && ( feedback.emailId.dirty || feedback.emailId.touched)}">
                    <div class="text-danger validation-message"
                        *ngIf="feedback.emailId.invalid && ( feedback.emailId.dirty || feedback.emailId.touched)">
                        EmailId is mandatory
                    </div>
                </div>
                <div class="col-md-12 mt-2">
                    <label for="">Description*</label>
                    <textarea type="text" class="form-control" formControlName="description"
                    [ngClass]="{'is-invalid': feedback.description.invalid && ( feedback.description.dirty || feedback.description.touched)}">
                    </textarea>
                    <div class="text-danger validation-message"
                        *ngIf="feedback.description.invalid && ( feedback.description.dirty || feedback.description.touched)">
                        Description is mandatory
                    </div>
                </div>
                <div class="col-md-6 mt-4">
                    <button (click)="submitFeedback()" class="btn btn-outline-success text-dark font-weight-bold" type="submit">SEND FEEDBACK
                        <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.57471 10.9422L19.988 10.9422" stroke="#21BB9F" stroke-width="1.36777"
                              stroke-linecap="round"></path>
                            <path
                              d="M14.5168 16.4133L19.6656 11.2646C19.8436 11.0866 19.8436 10.7979 19.6656 10.6198L14.5168 5.47113"
                              stroke="#21BB9F" stroke-width="1.36777" stroke-linecap="round"></path>
                          </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>