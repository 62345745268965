<app-header></app-header>
<section class="banner text-center text-white d-flex align-items-center banner-full"
    style="background-image: url(assets/images/banner.jpg)">
    <div class="container position-relative">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <div class="heading-dual">
                    <h1>LogSolutec</h1>
                    <h2>Privacy Policy</h2>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="about-wrapper text-dark py-8">
    <div class="container-fluid p-lg-start-end">
        <div class="row">
            <h2 class="text-center">
                <span class="text-success">LogSolutec</span> Privacy Policy
            </h2>
            <p>To ensure consumer privacy, LogSolutec needs to collect personal information from individuals and its
                affiliates for business objectives. Our data gathering method and general usage policies are governed by
                our website's privacy policy. If you do not agree to the conditions of our privacy policy, you are not
                permitted to use our services. We advise you to revisit this policy at regular intervals since we may
                make changes to it in certain areas. If you have any questions or issues about our privacy policy or
                data treatment, please contact us at <span><a href="">contact@logsolutec.com</a> </span>
            </p>
        </div>

        <div>
            <h4 class="text-success">Personal Information</h4>
            <p>This refers to any information obtained from customers, business connections, workers, suppliers, or
                other individuals connected to the firm. Personal data might comprise direct or indirect information,
                names of persons, postal addresses, email addresses, phone numbers, and company types. We believe in
                lawfulness, transparency, purpose intimation, data minimization, correctness, secrecy, and integrity
                when it comes to preserving personal information. Personal information can also comprise non-personal
                information such as IP addresses, browser types, and geographic locations based on website visits. We do
                not, however, gather any sensitive information from your source.</p>
        </div>

        <br>

        <div>
            <h4 class="text-success">Notices, Revisions, and Conditions of Use</h4>
            <p>You consent to the collection, use, and sharing of information in accordance with the provisions of this
                Privacy Policy by using our services. At any moment, we retain the right to change or update our Privacy
                Policy. If we make significant changes to our Privacy Policy, we will tell you here, by a notice on our
                LogSolutec Platform so that you are always informed of the information we collect, how we use it, and
                when we share it. Unless we expressly specify otherwise, any changes to our Privacy Policy will only
                apply to information collected after the change's effective date. Please contact us at
                <span> <a href="">contact@logsolutec.com</a> </span> if you have any questions or issues about any of
                the provisions of this Privacy
                Policy.
            </p>
        </div>

        <br>

        <div>
            <h2 class="text-success">INFORMATION IS COLLECTED IN THE FOLLOWING MANNER:</h2>
            <p>Personal Information and Other Information may be obtained by LogSolutec and its third-party service
                providers from a number of sources that fall into one of categories:</p>

            <p> <b class="text-success">Direct Interactions:</b> We acquire information about you via your use of the
                Channels and other
                activities such as account creation, registration and form submission, and sales enquiries and
                transactions.
            </p>
            <p>Data from non-LogSolutec websites' Automated Interactions, or other publicly accessible data you may have
                made publicly available, such as social media postings, or data given by third-party sources, such as
                marketing opt-in lists or data aggregators. </p>

            <br>

            <p>
                <b class="text-success">Electronic Communications Protocols:</b>
                As with most websites and apps, LogSolutec may receive information from you as part of the communication
                connection itself, such as network routing information, equipment information, and your IP address
                (which may identify your general geographical location or company), as well as date and time.
            </p>
            <p>LogSolutec may also collect and store information regarding your interactions with the Channels, such as
                clickstream data (when each LogSolutec web page was viewed and for how long), frequency of access,
                performance statistics, aggregate use, or general geolocation data.</p>

            <br>

            <p>
                <b class="text-success">Cookies:</b>
                LogSolutec uses cookies in compliance with our policy.
            </p>

            <br>

            <p>
                <b class="text-success">Cookie Options:</b>
                You have total control over your cookie preferences, which you may change at any time. If you have any
                problems with this cookie preference method, please email us on <span><a
                        href="contact@logsolutec.com">contact@logsolutec.com</a></span>
            </p>

            <br>

            <p>
                <b class="text-success">Flash Cookies:</b>
                We may use Flash cookies, also known as "local shared objects," on our electronic Channels that use
                Flash technology. Flash cookies are used to save site settings so that the site's appearance and
                experience may be customized. Flash cookies, like conventional cookies, are saved as little files on
                your computer. One approach to avoid Flash cookies is to change your preferences in the LogSolutec
                Settings Panel at <span><a href="www.logsolutec.com">www.logsolutec.com</a></span>
            </p>

            <br>

            <p>
                <b class="text-success">Embedded URLs:</b>
                LogSolutec may employ an embedded URLs tracking technology to enable users to access electronic Channels
                without the usage of cookies.
            </p>

            <br>

            <p>
                <b class="text-success">Widgets, Buttons, and Tools:</b>
                The information collected or used by the widget, button, or tool is governed by the privacy policy of
                the company that created it, including cookie settings and preferences.
            </p>

            <br>

            <b class="text-success">Your Decisions</b>
            <p>You consent to the use of your Personal Information as described in this Policy by providing it. We'd
                like to hear what you think about the LogSolutec Platform and Policy. Providing personally identifiable
                information, on the other hand, is absolutely optional. It is not necessary for you to register with us.
                Please note that some services are only available to visitors who register. You can access your
                information in the My Account section of the Website Application at any time to amend and change your
                Personal Information. You have the option to adjust your interests at any time and to opt-in or out of
                marketing, promotional, or newsletter mailings. We reserve the right to send you service-related
                communications that are deemed part of your account with us without providing you with the option to
                opt-out. You can edit your account settings and update your information.</p>

            <br>

            <b class="text-success">Information Disclosures and Sharing</b>
            <p>We do not share your Personal Information with third parties:</p>
            <ol type="a">
                <li>unless we have your permission;</li>
                <li>unless we need to provide services that you have requested;</li>
                <li>unless we need to investigate, prevent, or take action regarding unlawful and illegal activities,
                    suspected fraud, or a potential threat to the safety or security of any person or organisation,
                    property or asset, or LogSolutec Platform rights from unauthorised use or misuse of the LogSolutec
                    Technology Platform.
                </li>
                <li>to react to claims that any photo, text, or other material violates the rights of third parties; or
                    to safeguard the LogSolutec Platform , its users, or the general public's rights, property, or
                    personal safety.
                </li>
            </ol>
            <p>For the purpose of performing the required service on our behalf, we provide related companies or other
                trusted business partners with some required personal and contact information. These parties must agree
                to process such information in accordance with our instructions and in accordance with this Policy and
                any other relevant confidentiality and security measures.
            </p>

            <br>

            <b class="text-success">Business Transfer</b>
            <p>As our business grows, we may be acquired in its entirety by a company/LLP or merged with another
                company. Customer information is typically one of the business assets in such agreements. In this
                scenario, we shall protect your Personal Information in accordance with this Policy.
            </p>

            <br>

            <b class="text-success">Information Security</b>
            <p>We take adequate security measures to prevent unauthorized access to data, as well as unlawful change,
                disclosure, or destruction of data. Internal evaluations of our data collecting, storage, and processing
                procedures and security measures, as well as physical security measures to prevent unauthorized access
                to systems where we hold personal data, are all part of this process. Personal Information is only
                accessible to our employees, contractors, and agents who require it in order to operate, develop, or
                improve our services. These individuals are bound by confidentiality duties, and if they fail to follow
                these commitments, they may face disciplinary action, including termination and criminal prosecution.
            </p>
            <p>While we make every effort to protect the security of your personally identifiable information,
                transmissions over the internet cannot be guaranteed to be completely secure. By using our LogSolutec
                Platform, you acknowledge that we are not responsible for the disclosure of your information as a result
                of transmission mistakes or unauthorised third-party activity.</p>
            <p>You also agree that you are solely responsible and liable for any and all costs, expenses, damages, fees,
                and other losses that we may incur or suffer as a result of any Personal Information or other materials
                that you post, upload, submit, or otherwise make available on the LogSolutec Platform, including public
                areas of the Platform. Personal Information that you disclose in public parts of the LogSolutec Platform
                is beyond our control and protection.</p>
            <p>Your Personal Information may be collected and used by third parties without our or your knowledge if you
                share it in public locations. You agree that by displaying your Personal Information or photographs on
                the LogSolutec Platform and the internet for the purpose of showing them to your friends, family,
                acquaintances, clients, business partners, and others, you are relinquishing certain traditional privacy
                rights, as anyone with internet access has the potential to view your information / photographs. Do not
                share your information or images if you do not want to give up these customary privacy protections. Your
                personal information is kept on secure systems. Unauthorized access to our computers is prevented using
                advanced security procedures.</p>

            <br>

            <b class="text-success">Policy Adherence</b>
            <p>We examine our compliance with this Policy on a regular basis. Please contact us at
                <span><a href="">contact@logsolutec.com</a></span> if you have any questions or issues about this Policy
                or our handling of Personal
                Information. It is our policy to contact the complaining user addressing his or her issues when we get
                complaints at this address. If a complaint about the transfer of personal data cannot be resolved
                between us and an individual, we shall work with the necessary regulatory authorities to rectify it.
            </p>

            <br>

            <b class="text-success">Why do we collect and process your personal information?</b>

            <br>

            <b class="text-success">Personal information retention</b>
            <p>After a set period of time, we either save and isolate your information or anonymize it in our database.
                We can erase your information at your request. If we save your personal information, we'll use it to
                determine our market preferences.
            </p>

            <br>

            <b class="text-success">Disclosure Is Obligated</b>
            <p>Legally, we have the right to release your personal information. This means that LogSolutec will be
                required to release your personal information in order to defend your rights and safety, as well as to
                investigate fraud or legal processes.</p>

            <br>

            <b class="text-success">Information transfer across international borders</b>
            <p>LogSolutec's technical entities are involved in international transfer. We employ clauses to ensure that
                personal information from international entities is protected. However, we transfer information to have
                access to demographics and website tracking information in order to assist our operations in overseas
                areas.</p>

            <br>

            <b class="text-success">Advertising</b>
            <p>LogSolutec's in-house staff is in charge of advertising the website and certain external websites. We
                collect information on the relevant usage of our website using the same cookies or similar technologies,
                and our targeted information is based on the same collected information. If disclosing your information
                goes against your personal preferences, you can contact us at <span><a
                        href="">contact@logsolutec.com</a></span></p>

            <br>

            <b class="text-success">How to get access to and manage your personal information</b>
            <p>You can contact the LogSolutec Platform team to seek access to your personal information or to limit how
                it is used. We always gather personal information with your agreement, but if you believe it is in
                violation of your wishes, you can object to the use of your personal information by withdrawing it.</p>
            <p>You can contact our team to exercise any of these rights, and we will react to your request as soon as
                possible and within a reasonable timescale. The course of action you choose will be communicated to you.
            </p>

            <br>

            <b class="text-success">To unsubscribe from our emails, click here.</b>
            <p>By clicking on the "unsubscribe" link in the footer of our emails, you can unsubscribe from our marketing
                newsletter. You can contact us at our email address if you have any further questions.</p>

            <br>

            <b class="text-success">Rights of Data Subjects</b>
            <p>LogSolutec Platform, through the use of law, informs data subjects about their rights and how to exercise
                them through the provision of a privacy policy. The right to know- LogSolutec Platform's team is
                dedicated to providing openness at every step, which is why the privacy policy is available on the
                internet. The data subject will be notified if there are any changes. Nonetheless, it is recommended
                that they monitor the situation on a regular basis.</p>

            <br>

            <b class="text-success">Right to Access</b>
            <p>LogSolutec Platform guarantees that the data subject has the right to receive confirmation that their
                data is being processed, copied, or that they have particular access to it.</p>

            <br>

            <b class="text-success">Right to Rectification</b>
            <p>LogSolutec Platform gives data subjects access to their information and allows them to correct any errors
                or typos. Correction requests can be sent to the back desk via email or phone call.</p>

            <br>

            <b class="text-success">Right to Restrict Processing</b>
            <p>Personal data processing is restricted. LogSolutec Platform assures that the data subject's personal
                information is only saved and not processed further. The processing restriction is used in the following
                situations:</p>
            <ul>
                <li>When a data subject expresses dissatisfaction with the accuracy of the data collected,</li>
                <li>When a data subject raises an objection to the way information is being processed,</li>
                <li>When the data subject expresses his or her dissatisfaction with the processing,</li>
                <li>When a data subject objects to erasure or requests that the information be processed in a specific
                    way</li>
            </ul>

            <br>

            <b class="text-success">Preferences & Opt-Out</b>
            <p>Email marketing is a type of advertising that involves sending emails to a group of people. You can opt
                out of receiving marketing messages from LogSolutec. You may unsubscribe from our marketing emails or
                other mailings at any time if you wish to be removed from our mailing lists.</p>
            <p>Product downloads, sales transactions, software updates, and other support-related information, patches
                and fixes, conferences or events for which you have registered, legal disclosures, and (where permitted
                by law) quality assurance surveys are not covered by the general opt-out.</p>
            <p>Product alerts, updates, contractual marketing, and sales materials, as well as any messages linked to
                partner status, are not subject to general opt-out.</p>

            <br>

            <b class="text-success">Safety and security are paramount</b>
            <p>We use 128-bit Secure Sockets Layer (SSL) encryption to protect your personal information and payment
                card details. We also keep technological solutions in place to prevent unauthorised access to our
                database and limit access to personal data.</p>
            <p>LogSolutec will always do everything possible to protect your personal information, but no system is
                totally secure, thus we, like any other organisation, cannot guarantee complete security. However, once
                we have your information, we follow strong security procedures to keep it safe from unwanted access.</p>

        </div>

    </div>
</section>