<!--header start-->
<div class="box-absolute d-flex align-items-center topline text-white">
    <button class="btn-opensidebar" type="button">
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M38 24H22" stroke="white" stroke-width="2" stroke-linecap="round"></path>
            <path d="M38 14H10" stroke="white" stroke-width="2" stroke-linecap="round"></path>
            <path d="M38 34H14" stroke="white" stroke-width="2" stroke-linecap="round"></path>
        </svg>
    </button>
    <ul class="breadcrumb p-0 m-0">
        <li class="breadcrumb-item 3" id="0"><a href="dashboard.html">Dashboard</a></li>
        <li class="breadcrumb-item 3" id="1"><a href="auction.html">Auction</a></li>
        <li class="breadcrumb-item"><a href="javascript:void(0)">List</a></li>
    </ul>
</div>
<!--header end-->
<section class="banner text-center text-white d-flex" id="bnner">
    <div class="container position-relative">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <h1>Document Sequence Configuration</h1>
            </div>
        </div>
    </div>
</section>
<!--request start-->
<section class="request-demo-wrapper small pb-10">
    <div class="container shadow bg-white">
        <div class="row">
            <div class="col-lg-12">
                <div class="box">
                    <form>
                        <div class="position-relative d-flex flex-wrap align-items-center add-new-wrap mb-2">
                            <div class="flex-grow-1">
                                <h4 class="mb-0"></h4>
                            </div>
                            <div class="position-absolute end-0 top-50 translate-middle-y">
                                <button class="btn-add-new text-dark btn-outline-success" type="button"> <i
                                        class="fa fa-plus me-1"></i><span>New Row</span>
                                </button>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-borderless table-striped table-head-dark text-center">
                                <thead>
                                    <tr>
                                        <th>
                                            <input class="form-check-input" type="checkbox" id="" name="option1">
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                        </th>
                                        <th>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <input class="form-check-input" type="checkbox" id="" name="option1">
                                        </td>
                                        <td>
                                            <input class="form-control-plaintext" type="text" value="Service Order"
                                                id="">
                                        </td>
                                        <td>
                                            <input class="form-control-plaintext" type="text" value="000001" id="">
                                        </td>
                                        <td>
                                            <input class="form-control-plaintext" type="text" value="1" id="">
                                        </td>
                                        <td>
                                            <input class="form-control-plaintext" type="text" value="SO" id="">
                                        </td>
                                        <td>
                                            <input class="form-control-plaintext" type="text" value="Yes" id="">
                                        </td>
                                        <td>
                                            <input class="form-check-input" type="checkbox" id="" name="option1">
                                        </td>
                                        <td>
                                            <button class="fa fa-trash text-primary lead" type="button">
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <input class="form-check-input" type="checkbox" id="" name="option1">
                                        </td>
                                        <td>
                                            <input class="form-control-plaintext" type="text" value="Service Order"
                                                id="">
                                        </td>
                                        <td>
                                            <input class="form-control-plaintext" type="text" value="000001" id="">
                                        </td>
                                        <td>
                                            <input class="form-control-plaintext" type="text" value="1" id="">
                                        </td>
                                        <td>
                                            <input class="form-control-plaintext" type="text" value="RFQ" id="">
                                        </td>
                                        <td>
                                            <input class="form-control-plaintext" type="text" value="Yes" id="">
                                        </td>
                                        <td>
                                            <input class="form-check-input" type="checkbox" id="" name="option1">
                                        </td>
                                        <td>
                                            <button class="fa fa-trash text-primary lead" type="button">
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <input class="form-check-input" type="checkbox" id="" name="option1">
                                        </td>
                                        <td>
                                            <input class="form-control-plaintext" type="text" value="Service Order"
                                                id="">
                                        </td>
                                        <td>
                                            <input class="form-control-plaintext" type="text" value="000001" id="">
                                        </td>
                                        <td>
                                            <input class="form-control-plaintext" type="text" value="1" id="">
                                        </td>
                                        <td>
                                            <input class="form-control-plaintext" type="text" value="AUC" id="">
                                        </td>
                                        <td>
                                            <input class="form-control-plaintext" type="text" value="Yes" id="">
                                        </td>
                                        <td>
                                            <input class="form-check-input" type="checkbox" id="" name="option1">
                                        </td>
                                        <td>
                                            <button class="fa fa-trash text-primary lead" type="button">
                                            </button>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>

                            <table mat-table [dataSource]="dataSource" matSort class="table table-borderless">

                                <ng-container matColumnDef="documentNumber">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-white">Document Type</th>
                                    <td mat-cell *matCellDef="let element">{{element.documentNumber}}</td>
                                </ng-container>

                                <ng-container matColumnDef="description">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class=" text-white">
                                        Starting Digit</th>
                                    <td mat-cell *matCellDef="let element" class="">{{element.description}}</td>
                                </ng-container>

                                <ng-container matColumnDef="creatorName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class=" text-white">
                                        Increment By</th>
                                    <td mat-cell *matCellDef="let element" class="">{{element.creatorName}}</td>
                                </ng-container>

                                <ng-container matColumnDef="creationDate">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-white">
                                        Prefix</th>
                                    <td mat-cell *matCellDef="let element" class="">{{element.creationDate}}</td>
                                </ng-container>

                                <ng-container matColumnDef="valueCurrency">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class=" text-white">Auto reset final digit</th>
                                    <td mat-cell *matCellDef="let element" class="">{{element.value}}
                                        {{element.currency}}</td>
                                </ng-container>

                                <ng-container matColumnDef="status">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class=" text-white">Suffix Year
                                    </th>
                                    <td mat-cell *matCellDef="let element" class="">{{element.status}}</td>
                                </ng-container>

                                <ng-container matColumnDef="view">
                                    <th mat-header-cell *matHeaderCellDef class="text-white"></th>
                                    <td mat-cell *matCellDef="let element" class="text-center">
                                        <button class="fa fa-trash text-primary lead" type="button">
                                        </button>
                                    </td>

                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displaycolumns" class="bg-dark"></tr>
                                <tr mat-row *matRowDef="let row; columns:displaycolumns;"></tr>
                            </table>

                        </div>
                        <div class="row justify-content-center">
                            <div class="col-md-6 col-lg-6 form-group pt-lg-3">
                                <button class="btn small btn-success px-2" type="button">SAVE</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!--request end -->