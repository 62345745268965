<section class="wrapper multi-step-form">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4 col-xl-3 col-left pt-12 d-flex flex-column justify-content-center position-sticky top-0">
        <div class="box-absolute d-flex">
          <app-sidebar></app-sidebar>
        </div>
        <div class="box mx-auto">
          <h3 class="text-center text-md-start">
            <div *ngIf="!updateServiceOrder; else elseBlock">Create</div>
            <ng-template #elseBlock>Update</ng-template>
          </h3>
          <ul class="list-unstyled step-one">
            <li class="active" id="list-item-0">
              <div class="icon"> <i class="fa fa-check"></i></div><span>Details</span>
            </li>
            <li class=" " id="list-item-1">
              <div class="icon"> <i class="fa fa-check"></i></div><span>Shipment Details</span>
            </li>
            <li class=" " id="list-item-2">
              <div class="icon"> <i class="fa fa-check"></i></div><span>Service &amp; Rate Details</span>
            </li>
            <li class=" " id="list-item-3">
              <div class="icon"> <i class="fa fa-check"></i></div><span>Terms &amp; Conditions</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-8 col-xl-9 col-right px-start-end pb-6" (click)="hideSideBar()">
        <div class="topline py-start-end d-flex align-items-center justify-content-between mb-4 mb-md-5">
          <ul *ngIf="dashboardBool" class="breadcrumb p-0 m-0">
            <li class="breadcrumb-item 3 brand" id="0"><button [routerLink]="['/v1/dashboard']">Dashboard</button></li>
          </ul>
          <ul class="breadcrumb p-0 m-0">
            <li *ngIf="backDetailsPage" class="breadcrumb-item 3 brand"><button
                (click)="goBackDetailsPage()">Back</button></li>
            <li *ngIf="backShipmentPage" class="breadcrumb-item 3 brand"><button
                (click)="goBackShipmentPage()">Back</button></li>
            <li *ngIf="backServiceRatePage" class="breadcrumb-item 3 brand"><button
                (click)="goBackServiceRatePage()">Back</button></li>
            <li *ngIf="backTermConditionPage" class="breadcrumb-item 3 brand"><button
                (click)="goBackTermConditionPage()">Back</button>
            </li>
          </ul>
          <div class="tab-content step-two">
            <div class="tab-pane active" id="item-0">1/4</div>
            <div class="tab-pane" id="item-1">2/4</div>
            <div class="tab-pane" id="item-2">3/4</div>
            <div class="tab-pane" id="item-3">4/4</div>
          </div>
        </div>
        <form [formGroup]="serviceOrder">
          <div class="tab-content form-step">

            <div class="tab-pane active" id="item-5">
              <h3 class="text-center">Service Order</h3>
              <div class="row">
                <div class="col-md-4 form-group">
                  <label class="form-label" for="number">Service Order Number</label>
                  <input class="form-control" type="text" id="number" placeholder="Auto generated if empty"
                    formControlName="number">
                </div>

                <div class="col-md-4  form-group">
                  <label class="form-label" for="serviceOrderDate">Service Order Date</label>
                  <input class="form-control" type="date" id="serviceOrderDate" formControlName="serviceOrderDate">
                </div>

                <div class="col-md-4  form-group" *ngIf="!isCreatingFromEnquiry">
                  <label class="form-label" for="quotation_number">Auction Number</label>
                  <input class="form-control" type="text" id="quotation_number" formControlName="auctionNumber"
                    [attr.disabled]="disabled">
                </div>

                <div class="col-md-4  form-group" *ngIf="!isCreatingFromEnquiry">
                  <label class="form-label" for="quotation_date">Auction Date</label>
                  <input class="form-control" type="date" id="quotation_date" formControlName="auctionDate"
                    [attr.disabled]="disabled">
                </div>

                <div class="col-md-4  form-group" *ngIf="isCreatingFromEnquiry">
                  <label class="form-label" for="quotation_number">Quotation Number</label>
                  <input class="form-control" type="text" id="quotation_number" formControlName="quotationNumber"
                    [attr.disabled]="disabled">
                </div>

                <div class="col-md-4  form-group" *ngIf="isCreatingFromEnquiry">
                  <label class="form-label" for="quotation_date">Quotation Date</label>
                  <input class="form-control" type="date" id="quotation_date" formControlName="quotationDate"
                    [attr.disabled]="disabled">
                </div>
                <div class="col-md-4  form-group">
                  <label class="form-label" for="amendentNumber">Amendment Number </label>
                  <input class="form-control" type="number" id="amendentNumber" formControlName="amendentNumber">
                </div>
                <div class="col-md-4  form-group">
                  <label class="form-label" for="amendentDate">Amendment Date</label>
                  <input class="form-control" type="date" id="amendentDate" formControlName="amendentDate">
                </div>
                <div class="col-md-4  form-group">
                  <label class="form-label" for="forwaderName">Forwarder Name</label>
                  <input class="form-control" type="text" id="forwaderName" formControlName="forwaderName">
                </div>
                <div class="col-md-4  form-group">
                  <label class="form-label" for="forwaderContactNumber">Forwarder Contact Number</label>
                  <input class="form-control" type="number" id="forwaderContactNumber"
                    formControlName="forwaderContactNumber" min="0">
                </div>
                <div class="col-md-4  form-group">
                  <label class="form-label" for="emailId">Forwarder Email-ID</label>
                  <input class="form-control" type="email" id="emailId" formControlName="emailId">
                </div>

                <div class="col-md-4  form-group">
                  <label class="form-label" for="contactPersonName">Contact Person Name</label>
                  <input class="form-control" type="text" id="contactPersonName" formControlName="contactPersonName">
                </div>
                <ng-container *ngIf="updateServiceOrder">
                  <div class="col-md-4  form-group">
                    <label class="form-label" for="status">Order Status</label>
                    <input class="form-control" type="text" id="status" formControlName="status" [attr.disabled]="true">
                  </div>
                </ng-container>
                <div class="col-md-4  form-group">
                  <label class="form-label" for="trade_type">Trade Type</label>
                  <select class="form-control form-select" id="trade_type" formControlName="tradeType">
                    <option *ngFor="let item of trade_type" [value]="item.id">
                      {{ item.value }}
                    </option>
                  </select>
                </div>

                <div class="col-md-4  form-group">
                  <label class="form-label" for="nameOfCHA">Name Of CHA</label>
                  <select class="form-control form-select" id="nameOfCHA" formControlName="nameOfCHA">
                    <option selected disabled value="">Select</option>
                    <option *ngFor="let vendor of vendorList" [value]="vendor.name">
                      {{ vendor.name }}
                    </option>
                  </select>
                </div>

                <div class="col-md-4  form-group">
                  <label class="form-label" for="placeOfReceipt">Place Of Receipt*</label>
                  <input class="form-control" type="text" id="placeOfReceipt" formControlName="placeOfReceipt"
                  [ngClass]="{'is-invalid': service.placeOfReceipt.invalid && ( service.placeOfReceipt.dirty || service.placeOfReceipt.touched) }">
                  <div class="text-danger validation-message"
                    *ngIf="service.placeOfReceipt.invalid && ( service.placeOfReceipt.dirty || service.placeOfReceipt.touched)">
                    Place Of Receipt is mandatory
                  </div>
                </div>

                <div class="col-md-4  form-group">
                  <label class="form-label" for="placeOfDelivery">Place Of Delivery*</label>
                  <input class="form-control" type="text" id="placeOfDelivery" formControlName="placeOfDelivery"
                  [ngClass]="{'is-invalid': service.placeOfDelivery.invalid && ( service.placeOfDelivery.dirty || service.placeOfDelivery.touched) }">
                  <div class="text-danger validation-message"
                    *ngIf="service.placeOfDelivery.invalid && ( service.placeOfDelivery.dirty || service.placeOfDelivery.touched)">
                    Place Of Delivery is mandatory
                  </div>
                </div>

                <div class="col-md-4  form-group">
                  <label class="form-label" for="routing">Routing</label>
                  <input class="form-control" type="text" id="routing" formControlName="routing">
                </div>

                <div class="col-md-4  form-group">
                  <label class="form-label" for="carrier">Carrier</label>
                  <input class="form-control" type="text" id="carrier" formControlName="carrier">
                </div>

                <div class="col-md-4  form-group">
                  <label class="form-label" for="contractValidity">Contract Validity</label>
                  <input class="form-control" type="date" id="contractValidity" formControlName="contractValidity">
                </div>

                <div class="col-md-4  form-group">
                  <label class="form-label" for="laycan">Vessel Date / Flight date</label>
                  <input class="form-control" type="date" id="laycan" formControlName="laycan">
                </div>

                <div class="col-md-4  form-group">
                  <label class="form-label" for="volume">Volume</label>
                  <input class="form-control" type="number" id="volume" formControlName="volume" min="0">
                </div>

                <div class="col-md-4  form-group">
                  <label class="form-label" for="exRate">Exchange Rate</label>
                  <input class="form-control" type="number" id="exRate" formControlName="exRate" min="0"> 
                </div>

                <div class="col-md-4  form-group">
                  <label class="form-label" for="reference">Reference</label>
                  <input class="form-control" type="text" id="reference" formControlName="reference">
                </div>

                <div formGroupName="currency" class="col-md-4  form-group">
                  <label class="form-label" for="currency">Currency</label>
                  <select class="form-control form-select" formControlName="id" [attr.disabled]="true">
                    <option *ngFor="let item of currencyPicker" [value]="item.id">
                      {{ item.currency }}
                    </option>
                  </select>
                </div>

              </div>

              <h4 class="text-center mt-2 mt-lg-3 mb-3 mb-lg-4">Consignee Details</h4>
              <div class="row" formGroupName="consigneeDetail">
                <div class="col-md-4 form-group">
                  <label for="consigneeName" class="form-label">Name</label>
                  <input class="form-control" type="text" id="consigneeName" formControlName="consigneeName">
                </div>

                <div class="col-md-4 form-group">
                  <label for="streetAddress1" class="form-label">Street Address 1</label>
                  <input class="form-control" type="text" id="streetAddress1" formControlName="streetAddress1">
                </div>

                <div class="col-md-4 form-group">
                  <label for="streetAddress2" class="form-label">Street Address 2</label>
                  <input class="form-control" type="text" id="streetAddress2" formControlName="streetAddress2">
                </div>

                <div class="col-md-4 form-group">
                  <label for="city" class="form-label">City</label>
                  <input class="form-control" type="text" id="city" formControlName="city">
                </div>

                <div class="col-md-4 form-group">
                  <label for="state" class="form-label">State</label>
                  <input class="form-control" type="text" id="state" formControlName="state">
                </div>

                <div class="col-md-4 form-group">
                  <!-- ToDo make changes here -->
                  <label for="country" class="form-label">Country</label>
                  <select class="form-select form-control" id="country" formControlName="country">
                    <option value="" disabled selected>Select Country</option>
                    <option *ngFor="let item of countryPicker" [value]="item.name"> 
                      {{ item.name }}
                    </option>
                  </select>
                </div>

                <div class="col-md-4 form-group">
                  <label for="zipcode" class="form-label">Zip Code</label>
                  <input class="form-control" type="text" id="zipcode" formControlName="zipcode">
                </div>
              </div>

              <div class="row justify-content-center">
                <div class="col-md-6 form-group pt-4 pt-lg-5">
                  <button class="btn btn-success w-100" type="button" data-target="item-1"
                    (click)="shipmentDetailsPage()">Next</button>
                </div>
              </div>
            </div>

            <div class="tab-pane" id="item-6">
              <h3 class="text-center">Shipment Details</h3>
              <div formGroupName="shipmentDetails">
                <div class="row">
                  <div class="col-md-4 form-group">
                    <label class="form-label" for="project-number">Project Code</label>
                    <input class="form-control" type="text" id="project-number" formControlName="projectCode"
                    >
                  </div>
                  <div class="col-md-4 form-group">
                    <label class="form-label" for="project-name">Project Name</label>
                    <input class="form-control" type="text" id="project-name" formControlName='projectName'
                    >
                  </div>
                  <div class="col-md-4 form-group">
                    <label class="form-label" for="service-required-date">Service Required Date</label>
                    <input class="form-control" type="date" id="service-required-date"
                      formControlName='serviceRequiredDate'>
                  </div>
                  <div class="col-md-4 form-group">
                    <label class="form-label" for="transit-time">Transit Time</label>
                    <input class="form-control" type="text" id="transit-time" formControlName='inTransitTime'
                    >
                  </div>
                  <div formGroupName="modeOfShipment" class="col-md-4 form-group">
                    <label class="form-label" for="mode-of-shipment">Mode Of Shipment</label>
                    <select class="form-control form-select" id="mode-of-shipment" formControlName="id">
                      <option *ngFor="let item of mode_of_shipment" [value]="item.id">
                        {{ item.value }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-4 form-group">
                    <label class="form-label" for="pick-up-terms">Pick Up Terms</label>
                    <select class="form-select form-control" id="pick-up-terms" formControlName='pickUpTerms'
                    >
                      <option *ngFor="let item of pickup_terms" [value]="item.code">
                        {{ item.value }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-4 form-group">
                    <label class="form-label" for="pick-from-airport">Delivery Terms</label>
                    <select class="form-select form-control" id="pick-up-terms" formControlName='deliveryTerms'
                    >
                      <option *ngFor="let item of dropoff_terms" [value]="item.code">
                        {{ item.value }}
                      </option>
                    </select>
                  </div>
                  <div formGroupName="natureOfMovement" class="col-md-4 form-group">
                    <label class="form-label" for="mode-of-movement">Nature Of Movement</label>
                    <select class="form-control form-select" formControlName='code'>
                      <option *ngFor="let item of nature_of_movement" [value]="item.code">
                        {{ item.value }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-4 form-group">
                    <label class="form-label" for="commodity-type">Commodity Type</label>
                    <input class="form-control" type="text" id="commodity-type" formControlName='commodity'
                    >
                  </div>
                  <div formGroupName="typeOfCargo" class="col-md-4 form-group">
                    <label class="form-label" for="type-of-cargo">Type Of Cargo</label>
                    <select class="form-control form-select" formControlName='id'>
                      <option *ngFor="let item of type_cargo" [value]="item.id">
                        {{ item.value }}
                      </option>
                    </select>
                  </div>

                  <div class="col-md-4 form-group">
                    <label class="form-label" for="origin">Origin</label>
                    <mat-select #singleSelect class="form-control" formControlName="origin">
                      <mat-option>
                          <ngx-mat-select-search [(ngModel)]="searchOriginValue"
                              [ngModelOptions]="{standalone: true}"
                              (input)="getSearchResultsOrigin()" placeholderLabel="Enter Name" noEntriesFoundLabel = 'No result available'></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let item of loadingPorts" [value]="item?.code?.code">
                          {{ item?.value }}
                      </mat-option>
                    </mat-select>
                  </div>
                  <div class="col-md-4 form-group">
                    <label class="form-label" for="destination">Destination</label>
                    <mat-select #singleSelect class="form-control" formControlName="destination">
                      <mat-option>
                          <ngx-mat-select-search [(ngModel)]="searchDestinationValue"
                              [ngModelOptions]="{standalone: true}"
                              (input)="getSearchResultDestination()" placeholderLabel="Enter Name" noEntriesFoundLabel = 'No result available'></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let item of destinationPorts" [value]="item?.code?.code">
                        {{ item?.value }}
                      </mat-option>
                    </mat-select>
                  </div>

                  <div class="col-md-4 form-group">
                    <label *ngIf="air_shipment" class="form-label" for="flight-frequency">Flight Frequency</label>
                    <label *ngIf="sea_shipment" class="form-label" for="flight-frequency">Vessel Frequency</label>
                    <input class="form-control" type="text" id="flight-frequency" formControlName='vesselFrequency'
                    >
                  </div>
                  <div class="col-md-4 form-group">
                    <label class="form-label" for="transit-port">Transit Port</label>
                    <input class="form-control" type="text" id="transit-port" formControlName='transitPort'
                    >
                  </div>

                  <div class="col-md-4 form-group">
                    <label class="form-label">Total Gross Weight</label>
                    <input class="form-control" type="number" formControlName='totalGrossWeight' min="0">
                  </div>

                  <div class="col-md-4 form-group" *ngIf="sea_shipment">
                    <label class="form-label" for="container_load_type">Container Load Type</label>
                    <select class="form-control form-select" formControlName='containerLoadType' id="container_load_type">
                      <option *ngFor="let item of container_load_type" [value]="item.id">
                        {{ item.value }}
                      </option>
                    </select>
                  </div>

                  <div class="col-md-4 form-group" *ngIf="sea_shipment">
                    <label for="">HS Code*</label>
                    <input class="form-control" type="text" formControlName="hsCode"
                    [ngClass]="{'is-invalid': shipmentDetails.get('hsCode').invalid && ( shipmentDetails.get('hsCode').dirty || shipmentDetails.get('hsCode').touched) }">
                    <div class="text-danger validation-message"
                      *ngIf="shipmentDetails.get('hsCode').invalid && ( shipmentDetails.get('hsCode').dirty || shipmentDetails.get('hsCode').touched)">
                        HS Code is mandatory
                    </div>
                  </div>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-md-6 form-group pt-4 pt-lg-5">
                  <button class="btn btn-success w-100" type="button" data-target="item-2"
                    (click)="serviceRatePage()">NEXT</button>
                </div>
              </div>
            </div>

            <div class="tab-pane" id="item-7">
              <div class="position-relative d-flex flex-wrap align-items-center add-new-wrap mb-2">
                <div class="flex-grow-1">
                  <h3 class="mb-0 text-center">Service &amp; Rate Details</h3>
                </div>
                <div class="position-absolute end-0 top-50 translate-middle-y">
                  <button class="btn-add-new text-dark btn-outline-success" type="button"
                    (click)=" addServiceRateDetails()"> <i class="fa fa-plus me-1"></i><span>New Service</span>
                  </button>
                </div>
              </div>

              <div class="row mt-2 mb-2">
                  <div class="table-responsive">
                    <table class="table table-borderless text-center table-head-dark table-head-condensed table-head-py-3">
                      <thead>
                        <th>Description</th>
                        <th>Quantity</th>
                        <th>Rate</th>
                        <th>Total</th>
                        <th>Actions</th>
                      </thead>
                      <tbody formArrayName="serviceAndRateDetails">
                        <tr *ngFor="let service of serviceRateDetails.controls; index as i; let l=last" 
                          [formGroupName]="i">
                          <td>
                            <mat-form-field appearance="standard">
                              <input matInput class="form-control-plaintext"
                                  formControlName="description" type="text"
                                  placeholder="Enter Description" />
                            </mat-form-field>
                          </td>
                          <td>
                            <mat-form-field appearance="standard">
                              <input matInput class="form-control-plaintext"
                                  formControlName="quantity" type="number"
                                  placeholder="Enter Quantity" min="0" (change)="calculateTotalCharges(i)"/>
                            </mat-form-field>
                          </td>
                          <td>
                            <mat-form-field appearance="standard">
                              <input matInput class="form-control-plaintext"
                                  formControlName="rate" type="number"
                                  placeholder="Enter Rate" min="0" (change)="calculateTotalCharges(i)" />
                            </mat-form-field>
                          </td>
                          <td>
                            <mat-form-field appearance="standard">
                              <input matInput class="form-control-plaintext"
                                  formControlName="total" type="number"
                                  placeholder="Total" min="0" />
                            </mat-form-field>
                          </td>
                          <td>
                            <div class="btn-outline-danger">
                              <button title="Delete" (click)="removeServiceRateDetails(i)">
                                <i class="fa fa-trash">
                                </i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot class="table-success">
                        <tr>
                          <td>Total</td>
                          <td></td>
                          <td></td>
                          <td>
                            <mat-form-field appearance="standard">
                              <input matInput class="form-control-plaintext"
                                  formControlName="total" type="number"
                                  placeholder="Total" min="0"/>
                            </mat-form-field>
                          </td>
                          <td></td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
              </div>

              <div class="row justify-content-center">
                <div class="col-md-6 form-group pt-4 pt-lg-5">
                  <button class="btn btn-success w-100" type="button" data-target="item-2"
                    (click)="termConditionPage()">NEXT</button>
                </div>
              </div>
            </div>

            <div class="tab-pane" id="item-8">
              <div class="row justify-content-center align-items-center">
                <div class="col-lg-9">
                  <h3 class="text-center">Terms &amp; Conditions</h3>
                </div>
                <div class="col-lg-3">
                  <button style="float: right; height: 45px;" class="text-dark text-small btn-outline-success"
                    (click)="addTermsAndConditions()">
                    <i class="fa fa-plus me-1" aria-hidden="true"></i>
                    <span class="">Terms & Conditions</span>
                  </button>
                </div>
              </div>
              <br>
              <div formArrayName="terms">
                <div class="row mb-4 justify-content-center align-items-center"
                  *ngFor="let item of termsArray.controls; let i = index" [formGroupName]="i">
                  <div class="col-lg-11">
                    <input type="text" class="form-control terms-label" placeholder="Enter Label Here"
                      formControlName="label"
                      [ngClass]="{'is-invalid': termsArray.controls[i].get('label').invalid && (termsArray.controls[i].get('label').touched || termsArray.controls[i].get('label').dirty)}">
                    <textarea class="form-control" rows="6" placeholder="Type your terms &amp; Conditions Here"
                      id="terms-&amp;-conditions" formControlName="content"
                      [ngClass]="{'is-invalid': termsArray.controls[i].get('content').invalid && (termsArray.controls[i].get('content').touched || termsArray.controls[i].get('content').dirty)}">
                        </textarea>
                  </div>
                  <div class="col-lg-1 mb-5">
                    <button class="terms-delete-btn" *ngIf="termsArray.length > 1"
                      (click)="deleteTermsAndConditions(i)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                        class="bi bi-trash" viewBox="0 0 16 16">
                        <path
                          d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                        <path fill-rule="evenodd"
                          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>

              <h4 class="text-center">OR</h4>

              <div class="row mt-4">
                <div class="col-lg-6 justify-content-center align-items-center">
                  <ngx-file-drop dropZoneClassName="file-drop-2" dropZoneLabel="Drop files here"
                    (onFileDrop)="addFiles($event)" accept=".jpg, .png, .pdf, .docx, .txt, .jpeg">
                    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                      <div class="row mt-5 pt-5 text-center">
                        <div class="col-lg-12 pt-5 mt-5">
                          <i class="color-green far fa-file-alt fa-3x"></i>
                        </div>
                        <div class="col-lg-12 pt-2 pb-2">
                          Upload Multiple Files By Drag & Drop OR Click Browse Files
                        </div>
                        <div class="col-lg-12">
                          <button class="btn btn-success" type="button" (click)="openFileSelector()">Browse
                            Files</button>
                        </div>
                      </div>
                    </ng-template>
                  </ngx-file-drop>
                </div>

                <div class="col-lg-6">
                  <div class="w3-card-4 note-border">
                    <div class="card-header note-bg">
                      <h3 class="text-white text-center">Notes</h3>
                    </div>
                    <div class="card-body bg-white">
                      <ul>
                        <li class="pb-2">Allowed File are .jpg, .png, .pdf, .docx, .txt, .jpeg,
                          .doc , .xls , .xlsx</li>
                        <li class="pb-2">Upload Multiple Files By Drag & Drop OR Click Browse
                          Files</li>
                        <li class="pb-2">Max size per file 2MB</li>
                        <li class="pb-2">Max size of all files 10MB</li>
                        <li class="pb-2">Use Delete button
                          <span>
                            <i class="fa fa-trash color-blue"></i>
                          </span>
                          to remove the uploaded file
                        </li>
                        <li class="pb-2">
                          Terms & Conditions is mandatory field. Either upload the file or
                          enter the details.
                        </li>
                        <li class="pb-2">Labels to be provided for Each File, Terms & Conditions
                        </li>
                        <li class="pb-2">
                          Document will be uploaded with SUBMIT/UPDATE button.
                        </li>
                        <li class="pb-2">
                          Use
                          <span>
                            <i class="fa fa-download"></i>
                          </span>
                          to Download file
                        </li>
                        <li>
                          <span>
                            <i class="fas fa-ban"></i>
                          </span>
                          Uploaded document is over-sized.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="table-responsive mt-4">
                  <form>
                    <table
                      class="table table-borderless table-striped table-head-dark table-head-condensed table-head-py-3">
                      <thead>
                        <tr>
                          <th>Sr.No</th>
                          <th>File Name</th>
                          <th>Label</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of documentList; index as i">
                          <td>{{ i + 1 }}</td>
                          <td>{{ item.name }}</td>
                          <td>
                            <input type="text" [(ngModel)]="item.label" [ngModelOptions]="{standalone: true}">
                          </td>
                          <td>
                            <div class="row">
                              <div class="col-md-3">
                                <button *ngIf="item.id" (click)="downloadFileLocal(item)">
                                  <i class="fa fa-download" aria-hidden="true" title="Download"></i>
                                </button>
                              </div>
                              <div class="col-md-3">
                                  <button title="Oversized" class="text-danger" *ngIf="(math.round(item.size) /1024) > 2048">
                                      <i class="fas fa-ban" aria-hidden="true"></i>
                                  </button>
                              </div>
                              <div class="col-md-3">
                                  <button title="Uploaded" class="text-primary" *ngIf="item.id">
                                      <i class="fa fa-check" aria-hidden="true"></i>
                                  </button>
                              </div>
                              <div class="col-md-3">
                                  <button title="Delete" *ngIf="item.id; else localDelete">
                                      <i class="fa fa-trash color-blue"
                                          (click)="deleteServerFile(item.id, i)"></i>
                                  </button>
                                  <button title="Delete" *ngIf="!item.id">
                                      <i class="fa fa-trash color-blue" (click)="deleteDocument(i)"></i>
                                  </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </form>
                </div>
                <hr>
              </div>

              <div class="row justify-content-center mt-5">
                <div class="col-md-6 form-group pt-4 pt-lg-5">
                  <button class="btn btn-success w-100" type="submit" (click)="onSubmit()">SUBMIT</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>