import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthServiceService } from '../../helpers/auth-service.service';
import { PasswordStrengthValidator } from '../../helpers/password-strenght-validation';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {

  errorPass: Boolean;
  public signupForm!: FormGroup
  password!: boolean;
  confirm_password!: boolean;
  submitted = false;
  dialogConfig = new MatDialogConfig();
  terms_cond: boolean = false;
  org_id = sessionStorage.getItem('org_id');
  constructor(private form: FormBuilder, private signUpService: AuthServiceService, private route: Router, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.signupForm = this.form.group({
      emailId: ['', [Validators.required, Validators.email]],
      password: ['', Validators.compose([Validators.required, Validators.minLength(8), PasswordStrengthValidator])],
      confirm_password: ['', Validators.required],
      org: this.form.group({
        id: [this.org_id, Validators.required]
      })
    });
    this.signupForm.patchValue({
      emailId: sessionStorage.getItem('email_id')
    });

    this.gotoTop();
  }

  get f() { return this.signupForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.f.password.errors) {
      this.errorPass = true;
    } else {
      this.errorPass = false;
    }
    if (this.f.emailId.invalid) {
      Swal.fire({
        titleText: "Error",
        text: "Email ID is Compulsory",
        icon: "warning",
        timer: 5000
      })
    }
    else if (this.f.password.invalid) {
      let password_error = this.f.password.errors;
      Swal.fire({
        titleText: "Error",
        text: "Password Criteria don't Match",
        icon: "warning",
        timer: 5000
      })
    }
    else if (this.f.password?.value !== this.f.confirm_password?.value) {
      Swal.fire({
        titleText: "Error",
        text: "Password doesn't match",
        icon: "warning",
        timer: 5000
      })
    }
    else if (this.terms_cond != true) {
      Swal.fire({
        titleText: "Error",
        text: "Please Accept Terms And Conditions",
        icon: "warning",
        timer: 5000
      })
    }
    else {
      this.signUpService.signUpUser(this.signupForm.value).subscribe(
        (response) => {
          if (response.code == 201) {
            Swal.fire({
              titleText: "Success",
              text: response.message,
              icon: "success",
              timer: 5000
            })
            this.route.navigate(['login']);
          }
        },
        (error) => {
          let _error = error.error;
          if (_error.errorCode == 500) {
            Swal.fire({
              titleText: "Error",
              text: _error.errorMessage,
              icon: "error",
              timer: 5000
            })
          }
          else if (_error.errorCode == 412) {
            Swal.fire({
              titleText: "Error",
              text: _error.errorMessage,
              icon: "error",
              timer: 5000
            })
          }
          else {
            Swal.fire({
              titleText: "Error",
              text: _error.errorMessage,
              icon: "error",
              timer: 5000
            })
          }
        },
        () => {
        }
      )
    }
  }

  showPassword() {
    this.password = !this.password;
  }

  showHideConfirmPassword() {
    this.confirm_password = !this.confirm_password;
  }

  //fuction to scroll to top on next button click
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

}
