import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { MasterTableService } from 'src/app/helpers/master-table.service';

@Component({
  selector: 'app-vendor-view',
  templateUrl: './vendor-view.component.html',
  styleUrls: ['./vendor-view.component.css']
})
export class VendorViewComponent implements OnInit {

  local_data: any;
  address: Array<any> = [];
  artifacts: Array<any> = [];
  escalations:Array<any> = [];
  addressBool = false;
  artifactsBool = false;
  escalationsBool = false;

  constructor(public dialogRef: MatDialogRef<VendorViewComponent>,  
    @Optional() @Inject(MAT_DIALOG_DATA) public data: MasterTableService, private route: ActivatedRoute) {
      console.log(data);
      this.local_data = { ...data };
      let address: Array<any> = this.local_data.address;
      let artifacts : Array<any> = this.local_data.artifacts;
      let escalations: Array<any> = this.local_data.escalations;
      if(address.length > 0){
        this.address = address;
        this.addressBool = true;
      }
      if(artifacts.length > 0){
        this.artifacts = artifacts;
        this.artifactsBool = true;
      }
      if(escalations.length > 0){
        this.escalations = escalations;
        this.escalationsBool = true;
      }
  }


  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  downloadFileLocal(item) {
    console.log(item);
    if (item.id != null && item.label != null) {
      this.dataURItoBlob(item);
    }
  }

  dataURItoBlob(data) {
    const linkSource = data.content;
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = data.name;
    downloadLink.click();
  }

}
