import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SocketService } from '../helpers/socket.service';

@Component({
  selector: 'app-document-sequence-configuration',
  templateUrl: './document-sequence-configuration.component.html',
  styleUrls: ['./document-sequence-configuration.component.css']
})
export class DocumentSequenceConfigurationComponent implements OnInit {

  dataSource:MatTableDataSource<any>;
  displaycolumns=[''];
  constructor(private socketService: SocketService) { }

  ngOnInit(): void {
    this.dataSource=new MatTableDataSource(['']);

    // this.socketService.connectTOServerPopUpFuction();
  }

}
