
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MasterTableService } from 'src/app/helpers/master-table.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dialog-nature-of-goods',
  templateUrl: './dialog-nature-of-goods.component.html',
  styleUrls: ['./dialog-nature-of-goods.component.css']
})
export class DialogNatureOfGoodsComponent implements OnInit {

  typeOfCargo: Array<any> = [];
  dialogForm: FormGroup;
  local_data: any;
  action: any;

  constructor(
    private formbuilder: FormBuilder,
    public dialogRef: MatDialogRef<DialogNatureOfGoodsComponent>,
    public service: MasterTableService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.action = data.action;
    this.local_data = { ...data };
    console.log(this.local_data);
  }

  ngOnInit(): void {
    this.service.getAllTypeOfCargo().subscribe(
      (response: Array<any>) => {
        this.typeOfCargo = response;
      },
      (error) => {
        console.log(error);
      },
    )
    this.dialogForm = this.formbuilder.group({
      value: [''],
      typeOfCargo: this.formbuilder.group({
        id: ['']
      }),
    });
    if (this.action != "Add") {
      this.dialogForm.addControl('id', new FormControl());
      this.dialogForm.patchValue({
        id: this.local_data.id,
        value: this.local_data.value,
        // typeOfCargoDto: {
        //   id: this.local_data.typeOfCargoDto.id
        // }
        typeOfCargo: {
          id: this.local_data.typeOfCargo.id
        }
      });
    }
  }
  doAction() {
    if (this.dialogForm.valid) {
      this.dialogRef.close({ event: this.action, data: this.dialogForm.value });
    } else {
      Swal.fire({
        title: "warning",
        icon: 'warning',
        titleText: "Please Provide mandatory Field",
        timer: 3000
      })
    }
  }
  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

}
