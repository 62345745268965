import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MasterTableService } from 'src/app/helpers/master-table.service';
import { DialogPickupTermsComponent } from './dialog-pickup-terms/dialog-pickup-terms.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pick-up-inco-terms',
  templateUrl: './pick-up-inco-terms.component.html',
  styleUrls: ['./pick-up-inco-terms.component.css']
})
export class PickUpIncoTermsComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  PickUpIncoTermsList: Array<any> = [];
  dataSource: MatTableDataSource<any>;
  displayedColumns = ['code' ,'value', 'natureOfMovement' ,'action'];
  serchFilter: Boolean = false;
  nodata: Boolean = true;
  pickUpIncoTerms: Array<any> = [];
  length: number;
  size: number = 25;
  page: number = 0;

  sessionStorageUserData: any
  disableCreateBtn: boolean = true
  disableEditBtn: boolean = true
  disableDeleteBtn: boolean = true

  constructor(private service: MasterTableService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getAllPickupIncoTerms();
    this.getCurrentUserDataFromSessionStorage();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.serchFilter=true;
    this.nodata=false;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getAllPickupIncoTerms() {
    this.service.getPickupIncotermsPageable(this.page, this.size).subscribe(
      (response) => {
        if(response.content != null){
          this.pickUpIncoTerms = response.content;
          for (let index = 0; index < this.pickUpIncoTerms.length; index++) {
            const element = this.pickUpIncoTerms[index];
            element['natureOfMovementString']  = "";
            let natureOfMovement:Array<any> = element.natureOfMovement;
            if(natureOfMovement.length > 0){
              let _natureOfMovementStr = "";
              for (let index = 0; index < natureOfMovement.length; index++) {
                const _element = natureOfMovement[index];
                if(index == 0){
                  _natureOfMovementStr = _element.value
                }
                else{
                  _natureOfMovementStr =  _natureOfMovementStr +  ' , ' +  _element.value
                }
              }
              element['natureOfMovementString']  = _natureOfMovementStr;
            }
          }
          this.dataSource = new MatTableDataSource(this.pickUpIncoTerms);
          this.dataSource.sort = this.sort;
          this.length = response.totalElements;
        }
      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      },
    );
  }

  openDialog(action: String, obj: any) {
    obj.action = action;
    const dialogRef = this.dialog.open(DialogPickupTermsComponent, {
      width: '400px',
      height: '400px',
      data: obj
    });
    dialogRef.afterClosed().subscribe(
      (result) => {
        if (result.event == 'Add') {
          this.addRowData(result.data);
        } else if (result.event == 'Update') {
          this.updateRowData(result.data);
        } else if (result.event == 'Delete') {
          this.deleteRowData(result.data);
        }
      }
    );
  }

  addRowData(row_obj) {
    let _data = {
      "code": row_obj.code,
      "value": row_obj.value,
      "natureOfMovement": row_obj.natureOfMovement
    }
    this.addPickupTerms(_data);
    setTimeout(() => {
      this.getAllPickupIncoTerms();
    }, 500);
  }

  updateRowData(row_obj) {
    let data = {
      "code": row_obj.code,
      "value": row_obj.value,
      "natureOfMovement": row_obj.natureOfMovement
    }
    this.updatePickupTerms(row_obj.code, data);
    setTimeout(() => {
      this.getAllPickupIncoTerms();
    }, 500);
  }
  deleteRowData(row_obj) {
    this.deletePickupTerms(row_obj.code);
    setTimeout(() => {
      this.getAllPickupIncoTerms();
    }, 500);
  }

  addPickupTerms(data: any) {
    this.service.addPickUpIncoTerms(data).subscribe(
      (response) => {
        if(response.code == 201){
          Swal.fire({
            titleText: "Success",
            icon: "success",
            text: response.message,
            timer: 5000
          })
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          })
        }
      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      }
    )
  }

  updatePickupTerms(id: any, data: any) {
    this.service.updatePickUpIncoTerms(id, data).subscribe(
      (response) => {
        if (response.code == 201) {
          Swal.fire({
            titleText: "Success",
            icon: "success",
            text: response.message,
            timer: 5000
          })
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          })
        }
      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      }
    )
  }

  deletePickupTerms(id: any) {
    this.service.deletePickUpIncoTerms(id).subscribe(
      (response) => {
        if (response.code == 201) {
          Swal.fire({
            titleText: "Success",
            icon: "success",
            text: response.message,
            timer: 5000
          })
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          })
        }
      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      },
    )
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }
  onPageChange(event: PageEvent) {
    this.size = event.pageSize;
    this.page = event.pageIndex;
    this.getAllPickupIncoTerms();
  }

  getCurrentUserDataFromSessionStorage(){
    // create auth code
    // RB0000080
    // update auth code
    // RB0000081
    // delete auth code
    // RB0000091
      this.sessionStorageUserData = JSON.parse(sessionStorage.getItem('userData'));
      console.log('this is user data ', this.sessionStorageUserData);
      for (let index = 0; index < this.sessionStorageUserData.authorities.length; index++) {
        const authority = this.sessionStorageUserData.authorities[index];
        console.log(authority);
        if(authority == 'RB0000080'){
          console.log('create authority is given');
          this.disableCreateBtn = false
        } else if(authority == 'RB0000081'){
          console.log('update authority is given');
          this.disableEditBtn = false
        } else if(authority == 'RB0000091') {
          console.log('delete authority is given');
          this.disableDeleteBtn = false
        }
      }
    }
}
