<!--header start-->
<header class="box-absolute d-flex align-items-center topline text-white">
    <app-sidebar></app-sidebar>
    <div class="page-title-center mt-3">
      <div class="text-center">
        <h1>User Role List</h1>
      </div>
    </div>
    <div class="breadcrumb-div mt-3">
        <ul class="breadcrumb p-0 m-0">
            <li class="breadcrumb-item 3" id="0"><a [routerLink]="['/v1/dashboard']">Dashboard</a></li>
            <li class="breadcrumb-item 3" id="1"><a>User Role</a></li>
            <li class="breadcrumb-item"><a>Listing</a></li>
        </ul>
    </div>
</header>
<!--header end-->
<section class="banner text-center text-white d-flex" id="bnner">
    <div class="container position-relative">
        <div class="row justify-content-center">
            <div class="col-lg-12">
            </div>
        </div>
    </div>
</section>
<!--request start-->
<section class="request-demo-wrapper pb-10">
    <div class="container shadow bg-white" (click)="hideSideBar()">
        <div class="row">
            <div class="col-lg-12">
                <div class="box">
                    <form>
                        <div class="btn-group d-flex gap-2 pb-3 pb-lg-4 px-3 px-lg-4">
                            <div class="input-group">
                                <input class="form-control rounded-3 px-4" matInput
                                    (keyup)="applyFilter($event.target.value)" placeholder="Search" type="text" id=""
                                    #input />
                                <button class="input-group-btn end-0" type="button"><i class="fa fa-search"></i>
                                </button>
                            </div>
                            <button class="rounded-3 flex-shrink-0" type="button" [routerLink]="['/v1/role-creation']"><i
                                class="fa fa-plus me-2"></i><span>New Role</span>
                            </button>
                        </div>

                        <div class="table-responsive">
                            <table mat-table [dataSource]="dataSource" matSort class="table table-borderless table-striped 
                            table-footer-dark table-head-dark">

                                <ng-container matColumnDef="authority">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-white">
                                        Authority
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.authority}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="createdBy">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-white"> Created By
                                    </th>
                                    <td mat-cell *matCellDef="let element" class="">
                                        {{ element.createdBy?.emailId }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="createdDate">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-white"> Created
                                        Date</th>
                                    <td mat-cell *matCellDef="let element" class="">
                                        {{element.createdDate | date:'short'}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="updatedBy">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-white"> Updated By
                                    </th>
                                    <td mat-cell *matCellDef="let element" class="">
                                        {{element.updatedBy?.emailId || "---"}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="updatedDate">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-white"> Updated
                                        Date</th>
                                    <td mat-cell *matCellDef="let element" class="r">
                                        {{ (element.updatedDate|date:'short') || "---"}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="action">
                                    <th mat-header-cell *matHeaderCellDef colspan="3" class="text-white"> Actions</th>
                                    <td mat-cell *matCellDef="let element" class="">
                                        <button class="fa fa-edit text-primary" type="button"
                                        [routerLink]="['/v1/role-edit/', element.id]">
                                        </button>
                                        <button class="fa fa-trash text-primary" type="button"
                                            (click)="openDialog('Delete',element)">
                                        </button>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns" class="bg-dark"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                                <!-- Row shown when there is no matching data. -->
                                <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell text-center text-danger" colspan="6">
                                        <div *ngIf="serchFilter">
                                            No data matching the filter "{{ input.value }}"
                                        </div>
                                        <div *ngIf="nodata">
                                            <h5>Data is Not Available</h5>
                                        </div>
                                    </td>
                                </tr>

                            </table>
                            <!-- <mat-paginator #MatPaginator [pageSizeOptions]="[25,50,100]" [length]="roleLength" showFirstLastButtons
                                (page)="onPageChange($event)">
                            </mat-paginator> -->
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<!--request end -->