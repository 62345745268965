import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { AuthServiceService } from '../helpers/auth-service.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MasterTableService } from '../helpers/master-table.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css'],
})
export class SignInComponent implements OnInit {
  public loginForm: FormGroup;
  returnUrl: any;
  submitted: boolean = false;
  loading: boolean = false;
  loginSubscription: Subscription = new Subscription();
  errorHandler: any;
  error: any;
  password: boolean = false;
  rememberMe: Boolean = false;
  hideEyeButton: Boolean = true;

  allMarketingSpaceItems: Array<any> = [];
  oneMarketingSpaceForStatic: Array<any> = []

  logoPreviewArray: Array<any> = []
  imgPreviewArray: Array<any> = []

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthServiceService,
    private deviceService: DeviceDetectorService,
    private masterTableService: MasterTableService,
    private sanitizer: DomSanitizer
  ) {
    this.loginForm = this.formBuilder.group({
      emailId: ['', [Validators.required, Validators.email]],
      password: ['', Validators.compose([Validators.required])],
      os: [''],
      browser: [''],
    });
  }
  ngOnInit(): void {
    sessionStorage.removeItem('token');
    let emailId = localStorage.getItem('emailId');
    if (emailId != null) {
      this.rememberMe = true;
      this.f.emailId.patchValue(emailId);
    } else {
      this.rememberMe = false;
      this.f.emailId.patchValue('');
    }
    this.gotoTop();
    this.epicFunction();

    this.getAllMarketingSpaceList();
  }

  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.loading = true;
    if (!this.loginForm.invalid) {
      this.loginSubscription = this.authService
        .login(this.loginForm.value)
        .subscribe(
          (res: any) => {
            if (
              res.genericResponseEntity != null &&
              res.genericResponseEntity.code == 412
            ) {
              console.log(res.genericResponseEntity);
              Swal.fire({
                title: 'Warning',
                icon: 'warning',
                text: res.genericResponseEntity.message,
                timer: 15000,
                showCancelButton: true,
              }).then(
                (accept) => {
                  console.log(accept);
                  if (accept.isConfirmed) {
                    this.logoutOldSessions(res.genericResponseEntity.entityId);
                  }
                },
                (reject) => {
                  console.log(reject);
                }
              );
            } else if (res.genericResponseEntity == null) {
              console.log('this is response ', res);
              let data = {
                dType: res.dType,
                emailId: res.emailId,
                orgId: res.orgId,
                orgName: res.orgName,
                userId: res.userId,
                authorities: res.authorities,
                firstName: res.userFirstName,
                lastName: res.userLastName,
                userRoles: res.userRoles,
                sId: res.sId,
              };
              if (this.rememberMe == true) {
                localStorage.setItem('emailId', this.f.emailId.value);
              }
              sessionStorage.clear();
              sessionStorage.setItem('userData', JSON.stringify(data));
              sessionStorage.setItem('token', JSON.stringify(res.token));
              this.router.navigate(['/v1/dashboard']);
            }
          },
          (error: any) => {
            let error_message = error.error;
            if (error_message.errorCode == 412) {
              Swal.fire({
                titleText: 'Error',
                icon: 'error',
                text: error_message.errorMessage,
                timer: 5000,
              });
            } else if (error_message.status == 500) {
              console.log(error_message);
              Swal.fire({
                titleText: 'Error',
                icon: 'error',
                text: error_message.errorMessage,
                timer: 5000,
              });
            } else if (error_message.password) {
              Swal.fire({
                titleText: 'Error',
                icon: 'error',
                text: error_message.password,
                timer: 5000,
              });
            } else if (error_message.email) {
              Swal.fire({
                titleText: 'Error',
                icon: 'error',
                text: error_message.email,
                timer: 5000,
              });
            } else {
              Swal.fire({
                titleText: 'Error',
                icon: 'error',
                text: 'Something went wrong, try again later!',
                timer: 5000,
              });
            }
          }
        );
    } else {
      let control = this.findInvalidControl();
      Swal.fire({
        titleText: 'Invalid',
        text: 'Please Enter ' + control[0],
        icon: 'error',
        timer: 5000,
      });
    }
  }

  showPassword() {
    this.password = !this.password;
  }

  passwordChange() {
    if (this.f.password.errors || this.f.password.value == '') {
      this.hideEyeButton = true;
    } else {
      this.hideEyeButton = false;
    }
  }

  findInvalidControl() {
    const invalid = [];
    const controls = this.f;
    for (const name in controls) {
      if (controls[name].invalid) {
        controls[name].markAsDirty();
        invalid.push(name);
      }
    }
    return invalid;
  }

  //fuction to scroll to top on next button click
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  // Device information
  epicFunction() {
    let deviceInfo = this.deviceService.getDeviceInfo();
    this.f.os.patchValue(deviceInfo.os);
    this.f.browser.patchValue(deviceInfo.browser);
  }

  logoutOldSessions(userId) {
    this.authService.deleteOldUserSession(userId).subscribe(
      (response) => {
        console.log(response);
        if (response.code == 202) {
          Swal.fire({
            title: 'Success',
            icon: 'success',
            text: response.message,
            timer: 5000,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getAllMarketingSpaceList() {
    this.masterTableService.getAllMarketSpaceUnauth().subscribe({
      next: (res) => {
        console.log('this is response marketing space ', res);
        this.allMarketingSpaceItems = res;
        this.createImagesFromBase64(this.allMarketingSpaceItems)
        this.oneMarketingSpaceForStatic = res[0]
      },
      error: (error) => {
        console.log('this is error ', error);
      },
    });
  }

  createImagesFromBase64(allMarketingSpace: any){
    for (let index = 0; index < allMarketingSpace.length; index++) {
      const marketingSpace = allMarketingSpace[index];

      for (let index = 0; index < marketingSpace?.artifacts.length; index++) {
        const artifact = marketingSpace?.artifacts[index];
        if(artifact.label == "LOGO"){
          let logoToPreview =
          this.sanitizer.bypassSecurityTrustResourceUrl(
            `data:${artifact.contentType};base64, ${artifact.content}`
          );
          this.logoPreviewArray.push(logoToPreview)
        }

        if(artifact.label == "IMAGE"){
          let imgToPreview =
          this.sanitizer.bypassSecurityTrustResourceUrl(
            `data:${artifact.contentType};base64, ${artifact.content}`
          );
          this.imgPreviewArray.push(imgToPreview)
        }
      }

    }
  }
}
