import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MasterTableService } from 'src/app/helpers/master-table.service';
import { OnboardService } from 'src/app/helpers/onboard.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-vendor-overview',
  templateUrl: './vendor-overview.component.html',
  styleUrls: ['./vendor-overview.component.css']
})
export class VendorOverviewComponent implements OnInit {

  public company_overview!: FormGroup;
  public vendor_id = sessionStorage.getItem('org_id');
  public pre_token = sessionStorage.getItem('preToken');
  public hideLicense: Boolean = false;
  businessTypeList: Array<any> = [];
  currencyList:Array<any> = [];

  constructor(private form: FormBuilder, private vendorService: OnboardService, private router: Router, private masterTableService: MasterTableService) { }

  ngOnInit(): void {
    console.log(this.vendor_id);
    console.log(this.pre_token);
    if (this.pre_token != null) {
      this.vendorService.vendorVerifyToken(this.pre_token).subscribe(
        (response) => {
          if (response != null) {
            console.log(response);
            this.company_overview.patchValue({
              detailsOfGoodsAndServices: response.detailsOfGoodsAndServices,
              grossAnnualTurnOver: response.grossAnnualTurnOver,
              establishedOn: String(response.establishedOn).split("T")[0],
              geoServiceArea: response.geoServiceArea,
              legalStructure: response.legalStructure,
              businessType: response.businessType?.id,
              licenseNumber: response.licenseNumber,
              licensed: response.licensed,
              bonded: response.bonded,
              grossAnnualTurnOverCurrency: {
                id: response.grossAnnualTurnOverCurrency?.id
              }
            });
            if (response.bonded == null) {
              this.company_overview.get('bonded').patchValue(false);
            }
            if (response.licensed == null) {
              this.company_overview.get('licensed').patchValue(false);
            }
            else if (response.licensed == true) {
              this.hideLicense = true;
            }
          }
        },
        (error) => { },
      )
    }
    this.company_overview = this.form.group({
      detailsOfGoodsAndServices: [''],
      grossAnnualTurnOver: [''],
      establishedOn: [''],
      geoServiceArea: [''],
      legalStructure: [''],
      businessType: [],
      licenseNumber: [''],
      licensed: [false],
      bonded: [false],
      grossAnnualTurnOverCurrency: this.form.group({
        id: ['']
      })
    });

    this.gotoTop();
    this.getAllBusinessType();
    this.getAllCurrency();
  }

  get overview() { return this.company_overview.controls; }

  updateVendorOverview() {
    if (this.company_overview.invalid) {
      console.log(this.company_overview.value);
      Swal.fire({
        titleText: "Invalid",
        text: "Fill all the mandatory fields",
        timer: 5000,
        icon: "warning"
      })
    }
    else {
      let data = {
        'id': this.vendor_id,
        "bonded": this.overview.bonded.value,
        "businessType": {
          "id": JSON.parse(this.overview.businessType.value),
        },
        "detailsOfGoodsAndServices": this.overview.detailsOfGoodsAndServices.value,
        "grossAnnualTurnOver": this.overview.grossAnnualTurnOver.value,
        "grossAnnualTurnOverCurrency": {
          "id": this.overview.grossAnnualTurnOverCurrency.get('id').value
        },
        "establishedOn": this.overview.establishedOn.value,
        "geoServiceArea": this.overview.geoServiceArea.value,
        "licensed": this.overview.licensed.value,
        "legalStructure": this.overview.legalStructure.value,
        "licenseNumber": this.overview.licenseNumber.value,
      }
      console.log(data);
      console.log(this.company_overview.value);
      this.vendorService.updateVendor(data).subscribe(
        (response) => {
          console.log(response);
          this.router.navigate(['/v1/vendor-onboard/documents'])
        },
        (error) => {
          console.log(error);
          Swal.fire({
            titleText: "Error",
            text: "Work in Process, please Wait",
            timer: 5000,
            icon: "error"
          })
        },
      )
    }
  }

  licenseRadioFunc(value: string) {
    if (value == 'yes') {
      this.hideLicense = true;
      this.company_overview.controls["licenseNumber"].setValidators(Validators.required);
    }
    else {
      this.hideLicense = false;
      this.company_overview.controls["licenseNumber"].clearValidators();
      this.overview.licenseNumber.reset('');
    }
  }

  getToday() {
    return new Date().toISOString().split('T')[0]
  }

  backButton() {
    this.router.navigate(['/v1/vendor-onboard/details?t=' + this.pre_token]);
  }

  businessType() {
    let businessType = this.overview.businessType.value;
    if (businessType == "CHA") {
      this.overview.licensed.patchValue(true);
      this.hideLicense = true;
    }
    else {
      this.overview.licensed.patchValue(false);
      this.hideLicense = false;
    }
  }


  //fuction to scroll to top on next button click
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  getAllBusinessType() {
    this.masterTableService.getAllBusinessType().subscribe(
      (response) => {
        console.log(response);
        this.businessTypeList = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getAllCurrency(){
    this.masterTableService.getAllCurrency().subscribe(
      (response) => {
        this.currencyList = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

}
