import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { OnboardService } from 'src/app/helpers/onboard.service';
import { UserService } from 'src/app/helpers/user.service';
import Swal from 'sweetalert2';
import { IDropdownSettings, } from 'ng-multiselect-dropdown';
import { PasswordStrengthValidator } from 'src/app/helpers/password-strenght-validation';
import { ActivatedRoute, Router } from '@angular/router';
import { SocketService } from 'src/app/helpers/socket.service';


@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  userForm: FormGroup;
  password: boolean = false;
  rememberMe: Boolean = false;
  hideEyeButton: Boolean = true;
  companyList: Array<any> = [];
  breadcrumbBool: Boolean = true;
  backfirstPage: Boolean = false;
  page: number = 0;
  size: number = 25;
  clientBool: Boolean = false;
  vendorBool: Boolean = false;
  typeOfUser: Array<any> = [
    {
      "id": 1,
      "name": "ADMIN"
    },
    {
      "id": 2,
      "name": "CLIENT"
    },
    {
      "id": 3,
      "name": "VENDOR"
    }
  ]
  type: number = 1;
  clientLength: number;
  vendorLength: number;
  dataSource!: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  serchFilter: Boolean = false;
  displayedColumns: string[] = ['orgName', 'onboardingStatus', 'actions'];
  checkedList: Array<any> = [];

  userData = JSON.parse(sessionStorage.getItem('userData'));
  dType: number;
  confirm_password: boolean;
  rolesList: Array<any> = [];
  dropdownSettings: IDropdownSettings = {
    idField: 'id',
    textField: 'authority',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 5,
    allowSearchFilter: true,
    enableCheckAll: false,
    noDataAvailablePlaceholderText: "No Roles to be displayed"
  };
  hideAdminPage: Boolean = false;
  selectedRoles: Array<any> = [];
  updateUserBool: Boolean = false;
  confirmPasswordUpdateBool: Boolean = false;

  constructor(private userService: UserService, private fb: FormBuilder, private route: ActivatedRoute,
    private clientService: OnboardService, private router: Router, private socketService: SocketService) { }

  ngOnInit(): void {
    this.getAllRoles();
    this.userForm = this.fb.group({
      emailId: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.compose([Validators.required, Validators.minLength(8), PasswordStrengthValidator])],
      confirm_password: ['', Validators.required],
      fname: [''],
      lname: [''],
      contactNumber: [''],
      org: this.fb.group({
        id: ['', Validators.required],
      }),
      roles: this.fb.array([]),
      createdBy: this.fb.group({
        id: [this.userData['userId']]
      })
    });

    this.nextUserDetailsPage();

    let userId = this.route.snapshot.paramMap.get('id');
    if (userId) {
      this.getUserById(userId);
      this.hideAdminPage = true;
      this.userForm.addControl('unsuccessfulAttemps', new FormControl({value: '', disabled: true}));
      this.userForm.addControl('isLocked', new FormControl(false));
      this.userForm.addControl('id', new FormControl());
      this.userForm.removeControl('createdBy');
      this.userForm.removeControl('password')
      this.userForm.removeControl('confirm_password')
      this.updateUserBool = true;
      if(this.dType == 1){
        document.getElementById("item-3").classList.add("active");
        document.getElementById("list-item-1").classList.add("active");
      }
    }

    // this.socketService.connectTOServerPopUpFuction();
  }

  init_role(data: any): FormGroup {
    return this.fb.group({
      id: [data.id]
    });
  }

  get f() { return this.userForm.controls };
  get roles() { return this.userForm.get('roles') as FormArray};

  get org() { return this.userForm.get('org') as FormGroup };

  getAllRoles() {
    this.userService.getAllRoles().subscribe(
      (response) => {
        let roles: Array<any> = response;
        this.rolesList = roles;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getAllVendors() {
    this.clientService.getAllVendors(this.page, this.size, null).subscribe(
      (response) => {
        if (response.content != null) {
          this.vendorLength = response.totalElements;
          this.dataSource = new MatTableDataSource(response.content);
          this.dataSource.sort = this.sort;
        }
        else {
          window.alert("NO Vendors in your system!");
        }
      },
      (error) => {
        console.log(error);
      },
    )

  }

  getAllClients() {
    this.clientService.getAllclientListing(this.page, this.size).subscribe(
      (response) => {
        if (response.content != null) {
          this.clientLength = response.totalElements;
          this.dataSource = new MatTableDataSource(response.content);
          this.dataSource.sort = this.sort;
        }
        else {
          window.alert("NO Vendors in your system!");
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  addUser() {
    if (this.f.password?.value !== this.f.confirm_password?.value) {
      this.f.confirm_password.reset();
      this.f.confirm_password.markAsTouched();
      Swal.fire({
        titleText: "Error",
        text: "Password doesn't match",
        icon: "warning",
        timer: 5000
      })
    }
    else if (!this.userForm.invalid) {
      this.userService.addUser(this.userForm.value).subscribe(
        (response) => {
          if (response.code == 201) {
            Swal.fire({
              titleText: "Successful",
              icon: "success",
              timer: 5000,
              text: response.message
            });
            this.router.navigate(['/v1/user/listing']);
          }
        },
        (error) => {
          console.log(error);
          let _error = error.error;
          if (_error.errorCode == 412) {
            Swal.fire({
              titleText: "Error",
              icon: "error",
              timer: 5000,
              text: _error.errorMessage
            });
          }
          else {
            Swal.fire({
              titleText: "Error",
              icon: "error",
              timer: 5000,
              text: "Something went wrong, try again later."
            });
          }
        },
      )
    }
    else {
      this.userForm.markAllAsTouched();
      Swal.fire({
        text: "Fill all the fields",
        icon: "warning",
        timer: 5000,
        titleText: "Warning"
      })
    }
  }

  showPassword() {
    this.password = !this.password;
  }

  passwordChange() {
    if (this.f.password.errors || this.f.password.value == '') {
      this.hideEyeButton = true;
    }
    else {
      this.hideEyeButton = false;
    }
  }

  nextUserDetailsPage() {
    if (this.dType == 1) {
      if (this.type != 1) {
        if (this.checkedList.length == 1) {
          this.org.get('id').patchValue(this.checkedList[0]);
          let _stepper = document.getElementById("item-0").classList.contains("active");
          let _sidebar = document.getElementById("list-item-0").classList.contains("active");
          let _content = document.getElementById("list-item-0").classList.contains("active");
          if (_stepper && _sidebar && _content) {
            document.getElementById("item-0").classList.remove("active");
            document.getElementById("item-2").classList.remove("active");
          }
          this.breadcrumbBool = false;
          this.backfirstPage = true;
          document.getElementById("item-1").classList.add("active");
          document.getElementById("item-3").classList.add("active");
          document.getElementById("list-item-1").classList.add("active");
        }
        else if (this.checkedList.length > 1) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            timer: 5000,
            text: "Kindly check one Organisation"
          });
        }
        else {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            timer: 5000,
            text: "Kindly check one Organisation"
          });
        }
      }
      else {
        let adminOrg = this.userData['dType'];
        this.org.get('id').patchValue(adminOrg);
        let _stepper = document.getElementById("item-0").classList.contains("active");
        let _sidebar = document.getElementById("list-item-0").classList.contains("active");
        let _content = document.getElementById("list-item-0").classList.contains("active");
        if (_stepper && _sidebar && _content) {
          document.getElementById("item-0").classList.remove("active");
          document.getElementById("item-2").classList.remove("active");
        }
        this.breadcrumbBool = false;
        this.backfirstPage = true;
        document.getElementById("item-1").classList.add("active");
        document.getElementById("item-3").classList.add("active");
        document.getElementById("list-item-1").classList.add("active");
      }
    }
    else {
      this.org.get('id').patchValue(this.userData['orgId']);
      document.getElementById("item-3").classList.add("active");
      document.getElementById("list-item-1").classList.add("active");
    }

  }

  goBackUserDetails() {
    let _stepper = document.getElementById("item-1").classList.contains("active");
    let _sidebar = document.getElementById("list-item-1").classList.contains("active");
    let _content = document.getElementById("item-3").classList.contains("active");
    if (_stepper && _sidebar && _content) {
      document.getElementById("item-1").classList.remove("active");
      document.getElementById("item-3").classList.remove("active");
      document.getElementById("list-item-1").classList.remove("active");
    }
    this.breadcrumbBool = true;
    this.backfirstPage = false;
    document.getElementById("item-0").classList.add("active");
    document.getElementById("item-2").classList.add("active");
    document.getElementById("list-item-0").classList.add("active");
  }

  typeOfOrg() {
    this.companyList = [];
    this.checkedList = [];
    if (this.type == 3) {
      this.getAllVendors();
      this.vendorBool = true;
      this.clientBool = false;
    }
    else if (this.type == 2) {
      this.getAllClients();
      this.vendorBool = false;
      this.clientBool = true;
    }
    else {
      this.vendorBool = false;
      this.clientBool = false;
    }
  }

  onPageChange(event: PageEvent) {
    this.size = event.pageSize;
    this.page = event.pageIndex;
    if (this.type == 2) {
      this.getAllClients();
    }
    else if (this.type == 3) {
      this.getAllVendors();
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.serchFilter = true;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  compare(event, id) {
    let value = event.srcElement.checked;
    let _id = id;
    if (value == true) {
      this.checkedList.push(_id);
    }
    else if (value == false) {
      this.checkedList.splice(this.checkedList.indexOf(_id), 1);
    }
    else {
      console.log("Cant do this");
    }
  }

  showHideConfirmPassword() {
    this.confirm_password = !this.confirm_password;
  }

  onItemSelect(event) {
    this.selectedRoles.push(event);
    this.roles.push(this.init_role(event));
  }

  onDeSelect(event) {
    let id = event.id
    for (let index = 0; index < this.roles.length; index++) {
      const role = this.roles.at(index).get('id').value;
      if(id === role){
        this.selectedRoles.splice(index, 1);
        this.roles.removeAt(index);
      }
    }
  }

  getUserById(id: any){
    this.userService.getUserById(id).subscribe(
      (response) => {
        if(response.id != null){
          this.type = response.org.dtype;
          this.typeOfOrg();
          this.f.fname.patchValue(response.fname);
          this.f.lname.patchValue(response.lname);
          this.f.emailId.patchValue(response.emailId);
          this.f.emailId.disable();
          this.f.contactNumber.patchValue(response.contactNumber);
          this.org.get('id').patchValue(response.org.id);
          this.f.unsuccessfulAttemps.patchValue(response.unsuccessfulAttemps);
          this.f.isLocked.patchValue(response.locked);
          this.f.id.patchValue(response.id);
          let roles:Array<any> = response.roles;
          this.selectedRoles = roles;
          for (let index = 0; index < roles.length; index++) {
            const element = roles[index];
            this.roles.push(this.init_role(element));  
          }
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  editPassword(){
    if(this.updateUserBool){
      // If Edit Password edited then show confirm_password also.
      this.confirmPasswordUpdateBool = false;
      var uname = new String(this.f.password.value); 
      if(uname.length >= 1){
        this.f.password.setValidators([Validators.required, Validators.minLength(8), PasswordStrengthValidator]);
        this.f.confirm_password.setValidators(Validators.required);
        this.f.password.updateValueAndValidity();
        this.f.confirm_password.updateValueAndValidity();
      }
      else{
        this.confirmPasswordUpdateBool = true;
        this.f.confirm_password.clearValidators();
        this.f.confirm_password.updateValueAndValidity();
        this.f.password.clearValidators();
        this.f.password.updateValueAndValidity();
      }  
    }
  }

  updateUser(){
    // ToDo Password and confirm Password remaining backend & frontend
    if(this.f.isLocked.value == false){
      this.f.unsuccessfulAttemps.patchValue(0);
    }
    if (!this.userForm.invalid) {
      this.userService.updateUser(this.userForm.value).subscribe(
        (response) => {
          if (response.code == 202) {
            Swal.fire({
              titleText: "Successful",
              icon: "success",
              timer: 5000,
              text: response.message
            });
            this.router.navigate(['/v1/user/listing']);
          }
        },
        (error) => {
          console.log(error);
          let _error = error.error;
          if (_error.errorCode == 412) {
            Swal.fire({
              titleText: "Error",
              icon: "error",
              timer: 5000,
              text: _error.errorMessage
            });
          }
          else {
            Swal.fire({
              titleText: "Error",
              icon: "error",
              timer: 5000,
              text: "Something went wrong, try again later."
            });
          }
        },
      )
    }
    else {
      this.userForm.markAllAsTouched();
      Swal.fire({
        text: "Fill all the fields",
        icon: "warning",
        timer: 5000,
        titleText: "Warning"
      })
    }
  }

}
