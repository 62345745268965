import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { DemoService } from 'src/app/helpers/demo.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-career-page',
  templateUrl: './career-page.component.html',
  styleUrls: ['./career-page.component.css']
})
export class CareerPageComponent implements OnInit {

  careerForm: FormGroup

  constructor(private demoService: DemoService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.careerForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      emailId: ['', Validators.compose([Validators.required, Validators.email])],
      contactNumber: ['', Validators.compose([Validators.required, Validators.minLength(9), Validators.maxLength(16)])],
      artifact: this.fb.group({
        name: ['', Validators.required],
        label: ['', Validators.required],
        content: [],
        contentType: []
      })
    })
  }

  get career() { return this.careerForm.controls; }
  get artifact() { return this.careerForm.get('artifact') as FormGroup; }

  public dropped(files: NgxFileDropEntry[]) {
    for (let index = 0; index < files.length; index++) {
      const droppedFile = files[index];
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          // Here you can access the real file size & content
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            let data = {
              "content": reader.result,
              "contentType": reader.result?.toString().split(',')[0],
              "name": file.name,
              "size": file.size
            }
            this.artifact.patchValue({
              name: data.name,
              content: data.content,
              contentType: data.contentType
            });
          }
        })
      }
      else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }

  onSubmit(){
    if(!this.careerForm.invalid){
      this.demoService.postCareer(this.careerForm.value).subscribe(
        (response) => {
          console.log(response);
          if(response.code == 201){
            Swal.fire({
              title: "Success",
              text: response.message,
              icon: "success",
              timer: 5000
            });
          }
          this.careerForm.reset();
        },
        (error) => {
          console.log(error);
        },
      )
    }
    else{
      let invalid = this.findInvalidControls();
      Swal.fire({
        title: "Warning",
        text: "Please fill the field " + invalid[0],
        icon: "warning",
        timer: 5000
      });
      this.careerForm.invalid;
    }
  }

  findInvalidControls(){
    const invalid = [];
    const controls = this.career;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  

}
