<!--header start-->
<header class="box-absolute d-flex align-items-center topline text-white">
    <app-sidebar></app-sidebar>
    <div class="page-title-center mt-3">
        <div class="text-center">
            <h1>Track Air Package</h1>
        </div>
    </div>
    <div class="breadcrumb-div mt-3">
        <ul class="breadcrumb p-0 m-0">
            <li class="breadcrumb-item 3" id="0"><a [routerLink]="['/v1/dashboard']">Dashboard</a></li>
            <li class="breadcrumb-item 3" id="1"><a href="vendor.html">Track & Trace</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0)">Air Package</a></li>
        </ul>
    </div>
</header>
<!--header end-->

<section class="banner text-center text-white d-flex" id="bnner">
    <div class="container position-relative">
        <div class="row justify-content-center">
            <div class="col-lg-12">
            </div>
        </div>
    </div>
</section>

<!--Section start-->
<section class="request-demo-wrapper pb-10">
    <div class="container shadow bg-white" (click)="hideSideBar()">
        <div class="row">
            <div class="col-lg-12">
                <div class="box">
                    <form>
                        
                        <div class="card">
                            <div class="filter-wrap btn-group d-flex flex-wrap flex-md-nowrap justify-content-center gap-4 pb-3 pb-lg-4 px-3 px-lg-4"
                                style="padding-top: 3.5%;">
                                <div class="input-group">
                                    <input class="form-control rounded-3 px-3" type="text" matInput
                                        placeholder="Enter Air way bill no." #input [(ngModel)]="getSearchValue"
                                        name="getSearchValue" maxlength="15" style="height: 50px;">
                                </div>
                                <!--  <button class="rounded-3 flex-shrink-0" type="button" (click)="onClickReset()">
                                    <span> Clear</span>
                                </button> -->

                                <button class="rounded-3 flex-shrink-0" type="button" (click)="onClickSearch()">
                                    <span>&nbsp; &nbsp; &nbsp; Track &nbsp; &nbsp; &nbsp; </span>
                                </button>
                            </div>
                        </div>

                        <div *ngIf="noData" class="card mt-3">
                            <div class="row card-body">
                                <div class="col-md-8">
                                    <h2>
                                        {{testReponse.airWaybillNumber}} <br>
                                    </h2>
                                    {{mainOriginValue?.value}}({{testReponse.originAndDestination?.origin}})
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <path d="M24 11.871l-5-4.871v3h-19v4h19v3z" />
                                    </svg>
                                    {{mainDestinationValue?.value}}
                                    ({{testReponse?.originAndDestination?.destination}})<br>

                                    {{testReponse?.quantity?.numberOfPieces}} pc, {{testReponse?.quantity?.weight.amount}}
                                    {{testReponse?.quantity?.weight.shortUnit}}
                                </div>
                                <div class="col-md-4">
                                    <div class="card gradientBackground">
                                        <div class="card-body text-white">
                                            Current Status <br>

                                            {{currentEvent?.port}} - {{currentAirPortName}}<br>
                                            {{currentEvent?.numberOfPieces}} piece, {{currentEvent?.weight?.amount}}
                                            {{currentEvent?.weight?.shortUnit}} {{currentEvent?.type}} <br>

                                            {{currentEvent?.timeOfEvent | date : 'medium'}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="noData" class="card mt-3">
                            <div class="row card-body">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-3"
                                            *ngFor="let item of uniquePortArray; let i = index; let l = last">
                                            <div class="row">
                                                <div class="col-md-6 mt-3">
                                                    <span class="card m-2 text-white text-center"
                                                        [ngClass]="{'normalPort': item.port != currentEvent.port, 'currentPort': item.port == currentEvent.port}">
                                                        <span class="m-2"> {{item.port}} </span>
                                                    </span>
                                                </div>
                                                <div class="col-md-6 text-center" *ngIf="!l">
                                                    <span>
                                                        <i class="fa fa-plane" style="padding-top: 5%;"></i>
                                                    </span>

                                                    <span class="">
                                                        <hr style="width: 100%;">
                                                    </span>

                                                    <span class="">
                                                        {{item.flight}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 text-center ">
                                    <h4 class="text-black mt-2">
                                        {{currentAirPortName}} - ({{currentEvent?.port}}) |
                                        {{currentEvent?.type}} |
                                        {{currentEvent?.timeOfEvent | date : 'medium'}}
                                    </h4>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="noData" class="row mt-4">
                            <div class="col-md-12">
                                <cdk-accordion class="example-accordion">
                                    <cdk-accordion-item #airDetails="cdkAccordionItem" class="example-accordion-item"
                                        role="button" tabindex="0" [attr.id]="accordionHeading"
                                        [attr.aria-expanded]="airDetails.expanded" [attr.aria-controls]="accordionBody">
                                        <div class="example-accordion-item-header" (click)="airDetails.toggle()">
                                            <span class="example-accordion-item-description">
                                                <svg *ngIf="!airDetails.expanded" width="22" height="22"
                                                    viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M5.5 9.16663L10.3268 13.9935C10.6986 14.3652 11.3014 14.3652 11.6732 13.9935L16.5 9.16663"
                                                        stroke="#003D90" stroke-width="1.42797"
                                                        stroke-linecap="round" />
                                                </svg>
                                                <svg *ngIf="airDetails.expanded" width="22" height="22"
                                                    viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"
                                                    class="rotateimg180">
                                                    <path
                                                        d="M5.5 9.16663L10.3268 13.9935C10.6986 14.3652 11.3014 14.3652 11.6732 13.9935L16.5 9.16663"
                                                        stroke="#003D90" stroke-width="1.42797"
                                                        stroke-linecap="round" />
                                                </svg>
                                            </span>
                                            <b> Details </b>

                                        </div>
                                        <div class="example-accordion-item-body row" role="region"
                                            [style.display]="airDetails.expanded ? '' : 'none'"
                                            [attr.id]="accordionBody" [attr.aria-labelledby]="accordionHeading">
                                            <mat-tab-group>
                                                <mat-tab label="{{routeSingleStringValue}}">
                                                    <div class="row mt-5"
                                                        *ngFor="let item of detailsArray; let i = index; let l = last"
                                                        style="padding-left: 2%;">

                                                        <div class="col-md-2">
                                                            <div class="row">
                                                                <div class="card col-md-12 normalPort"
                                                                    [ngClass]="{'normalPort': !l, 'currentPort': l}">
                                                                    <div class="card-body text-white text-center">
                                                                        {{item.port}}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-12 justify-content-center"
                                                                    *ngIf="!l">
                                                                    <i class="fa fa-plane" style="padding-top: 5%;"></i>
                                                                    <b> {{item?.flight}} </b> <br>
                                                                </div>
                                                                <div class="col-md-12 justify-content-center">
                                                                    <span class="vl" sty></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-10">
                                                            <div class="row"
                                                                *ngFor="let detail of item.groupOfDetails; let i = index; let l = last; let f = first">
                                                                <div class="col-md-4">
                                                                    <span *ngIf="f"> {{detail.port}} </span> <br>
                                                                    <b> {{detail.type}} </b>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    {{detail.timeOfEvent | date : 'medium'}}
                                                                </div>
                                                                <div class="col-md-4">
                                                                    {{detail.numberOfPieces}} pcs
                                                                    {{detail.weight?.amount}}
                                                                    {{detail.weight?.shortUnit}} {{detail.type}}
                                                                    {{detail.flight}}
                                                                    {{detail.dateOfScheduledDeparture}}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </mat-tab>
                                            </mat-tab-group>
                                        </div>
                                    </cdk-accordion-item>
                                </cdk-accordion>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</section>