<header class="box-absolute d-flex align-items-center topline text-white">
    <app-sidebar></app-sidebar>
    <div class="page-title-center mt-3">
        <div class="text-center">
            <h1>Pre Shipment Invoice List</h1>
        </div>
    </div>
    <div class="breadcrumb-div">
        <ul class="breadcrumb p-0 m-0">
            <li class="breadcrumb-item 3" id="0"><a [routerLink]="['/v1/dashboard']">Dashboard</a></li>
            <li class="breadcrumb-item 3" id="0"><a>Pre Shipment Invoice List</a></li>
        </ul>
    </div>
</header>

<div (click)="hideSideBar()" class="background">
    <section class="banner text-center text-white d-flex" id="bnner">
        <div class="container position-relative">
            <div class="row justify-content-center">
                <div class="col-lg-12">
                </div>
            </div>
        </div>
    </section>
    <section class="request-demo-wrapper pb-10">
        <div class="container shadow bg-white">
            <div class="row">
                <div class="col-lg-12">
                    <div class="box">
                        <div class="table-responsive">
                            <table class="table table-striped table-footer-dark table-borderless table-head-dark"
                                mat-table [dataSource]="dataSource" matSort>
                                <!-- ID Column -->
                                <ng-container matColumnDef="number">
                                    <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header> Invoice
                                        Number </th>
                                    <td mat-cell *matCellDef="let row"> {{row.invoiceListing.number}} </td>
                                </ng-container>

                                <ng-container matColumnDef="placeOfDelivery">
                                    <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header> Place Of
                                        Delivery </th>
                                    <td mat-cell *matCellDef="let row"> {{row.invoiceListing.placeOfDelivery}} </td>
                                </ng-container>


                                <ng-container matColumnDef="portOfLoading">
                                    <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header> Port Of
                                        Loading </th>
                                    <td mat-cell *matCellDef="let row"> {{row.portOfLoadingName}} </td>
                                </ng-container>


                                <ng-container matColumnDef="portOfDischarge">
                                    <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header> Port Of
                                        Discharge </th>
                                    <td mat-cell *matCellDef="let row"> {{row.portOfDischargeName}} </td>
                                </ng-container>


                                <!-- CreatedBy Column -->
                                <!-- <ng-container matColumnDef="createdBy">
                                    <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header> Created By </th>
                                    <td mat-cell *matCellDef="let row"> {{row.invoiceListing.createdBy?.emailId}} </td>
                                </ng-container> -->

                                <!-- CreatedDate Column -->
                                <ng-container matColumnDef="createdDate">
                                    <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header>Created
                                        Date </th>
                                    <td mat-cell *matCellDef="let row"> {{row.invoiceListing.createDate | date }} </td>
                                </ng-container>

                                <!-- Origin Column -->
                                <ng-container matColumnDef="countryOfOriginOfGoods">
                                    <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header> Origin
                                    </th>
                                    <td mat-cell *matCellDef="let row"> {{row.invoiceListing.countryOfOriginOfGoods ||
                                        '---' }} </td>
                                </ng-container>

                                <!-- Destination Column -->
                                <ng-container matColumnDef="countryOfFinalDestination">
                                    <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Destination </th>
                                    <td mat-cell *matCellDef="let row"> {{row.invoiceListing.countryOfFinalDestination
                                        || '---' }} </td>
                                </ng-container>

                                <!-- Actions -->
                                <ng-container matColumnDef="action">
                                    <!-- text-center -->
                                    <th class="text-white" mat-header-cell *matHeaderCellDef>Actions </th>
                                    <td mat-cell *matCellDef="let row" class="action-link">
                                        <div class="row">
                                            <div class="col-2">
                                                <button *ngIf="!row.invoiceListing.isUseForCommercialInvoice"
                                                    class="fa fa-edit text-primary" title="Edit"
                                                    [routerLink]="['/v1/pre-shipment-invoice/edit/', row.invoiceListing.id]"
                                                    type="button">
                                                </button>
                                            </div>
                                            <div class="col-2">
                                                <button *ngIf="row.invoiceCheck" class="fa fa-download" type="button"
                                                    title="Invoice Download"
                                                    (click)="downloadPDF(row.invoiceListing.id, 'I')">
                                                </button>
                                            </div>
                                            <div class="col-2">
                                                <button *ngIf="row.packingListCheck" class="fa fa-download"
                                                    title="Packing List Download" type="button"
                                                    (click)="downloadPDF(row.invoiceListing.id, 'P')">
                                                </button>
                                            </div>
                                            <div class="col-2">
                                                <button *ngIf="row.annexurePackingListCheck" class="fa fa-download"
                                                    title="Annexure List Download" type="button"
                                                    (click)="downloadPDF(row.invoiceListing.id, 'A')">
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>


                                <tr class="bg-dark" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                                <!-- Row shown when there is no matching data. -->
                                <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell text-center text-danger" colspan="8">
                                        <div *ngIf="serchFilter">
                                            No data matching the filter "{{ input.value }}"
                                        </div>
                                        <div *ngIf="nodata">
                                            <h5>Data is Not Available</h5>
                                        </div>
                                    </td>
                                </tr>

                            </table>
                            <mat-paginator [pageSizeOptions]="[5, 10, 25]" [length]="invoiceLength" showFirstLastButtons
                                (page)="onPageChange($event)">
                            </mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>