import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  AUTH_API: string = `https://${window.location.hostname}:9004/api/v1/procurement`;

  constructor( private http:HttpClient) { }

  getAllInvoice(page, size):Observable<any>{
    let param = new HttpParams().set('page', page).set('size', size);
    return this.http.get(`${this.AUTH_API}/invoices`, {params: param});
  }
  
  //createinvoice
  createPreShipmentInvoice(data:any):Observable<any>{
    return this.http.post(`${this.AUTH_API}/invoice`, data);
  }

  //to get invoice by perticular id
  invoiceById(id: any):Observable<any>{
    return this.http.get(`${this.AUTH_API}/invoice/${id}`);
  }

  //update invoice
  updateInvoice(id: any, data: any):Observable<any>{
    return this.http.put(`${this.AUTH_API}/invoice/${id}`, data);
  }

  //deleting template
  deleteTemplate(id:any):Observable<any>{
    return this.http.delete(`${this.AUTH_API}/invoiceTemplate/${id}`);
  }
  
  //createtemplate
  saveInvoiceTemplate(data: any): Observable<any>{
    return this.http.post(`${this.AUTH_API}/template`,data);
  }

  getUserAddressDetails():Observable<any>{
    return this.http.get(`${this.AUTH_API}/template/addresses`);
  }

  //get all template list
  getInvoiceTemplates():Observable<any>{
    return this.http.get(`${this.AUTH_API}/invoiceTemplates`);
  }
  
  updateInvoiceTemplate(id: any, data: any):Observable<any>{
    return this.http.put(`${this.AUTH_API}/template/${id}`, data);
  }

  getTemplateById(id : any):Observable<any>{
    return this.http.get(`${this.AUTH_API}/invoiceTemplate/${id}`);
  }

  // to get invoices related to orgID
  getOrgInvoices():Observable<any> {
    return this.http.get(`${this.AUTH_API}/shipmentInvoices`);
  }

  getAllPreShipmentInvoice(page, size): Observable<any>{
    let param = new HttpParams().set('page', page).set('size', size);
    return this.http.get(`${this.AUTH_API}/invoiceListing`, {params: param});
  }

  downloadPreShipmentInvoicePDF(id, type):Observable<Blob>{
    let param = new HttpParams().set('type', type)
    return this.http.get(`${this.AUTH_API}/preshipmentInvoice/${id}/downloadPdf`, {params: param, responseType: 'blob'})
  }

  getAllCommercialInvoice(page, size):Observable<any> {
    let param = new HttpParams().set('page', page).set('size', size);
    return this.http.get(`${this.AUTH_API}/commercialInvoices`, {params: param});
  }

  getCommercialInvoiceById(id):Observable<any>{
    return this.http.get(`${this.AUTH_API}/commercialInvoice/${id}`);
  }

  createCommercialInvoice(data):Observable<any>{
    return this.http.post(`${this.AUTH_API}/commercialInvoice`, data);
  }

  updateCommercialInvoice(id, data):Observable<any>{
    return this.http.put(`${this.AUTH_API}/commercialInvoice/${id}`, data);
  }

  getServiceOrderListForCommercialInvoice():Observable<any>{
    return this.http.get(`${this.AUTH_API}/serviceOrderListForCommercialInvoice`);
  }

  getPreShipmentInvoiceByServiceOrderId(id):Observable<any>{
    return this.http.get(`${this.AUTH_API}/preshipmentInvoiceListing/${id}`);
  }

  getMultiplePreShipmentInvoices(data: any):Observable<any> {
    return this.http.post(`${this.AUTH_API}/preshipmentInvoices`, data);
  }

  downloadCommercialInvoice(id, type):Observable<Blob>{
    let param = new HttpParams().set('type', type);
    return this.http.get(`${this.AUTH_API}/commercialInvoice/${id}/downloadPdf`, {params: param, responseType: 'blob'})
  }

}