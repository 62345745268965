import { Injectable } from '@angular/core';
import { title } from 'process';

import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor() { }

  /**
   * Function for success alert
   * @param titleText Title Header Text (Optional)
   * @param message Success Message
   * @param time Timer for Alert (Optional)
   * @param showCancelButton 
   * @param showCloseButton 
   * @param showConfirmButton 
   */
  successAlert(message,
    titleText?,
    timer?,
    showCancelButton = false,
    showCloseButton = false,
    showConfirmButton = true) {
    Swal.fire({
      titleText: titleText || 'Success',
      icon: "success",
      text: message,
      timer: timer || 3000,
      showCancelButton,
      showCloseButton,
      showConfirmButton
    });
  }

  /**
   * Function for Error Alert
   * @param titleText Title Header Text (Optional)
   * @param message Error Message
   * @param timer Timer for Alert (Optional)
   * @param showCancelButton 
   * @param showCloseButton 
   * @param showConfirmButton 
   */
  errorAlert(message,
    titleText?,
    timer?,
    showCancelButton = false,
    showCloseButton = false,
    showConfirmButton = true) {
    Swal.fire({
      titleText: titleText || 'Error',
      icon: "error",
      text: message,
      timer: timer || 3000,
      showCancelButton,
      showCloseButton,
      showConfirmButton
    });
  }

  /**
   * 
   * @param message Warning message
   * @param titleText header title text
   * @param timer timer
   * @param showCancelButton 
   * @param showCloseButton 
   * @param showConfirmButton 
   */
  warningAlert(message,
    titleText?,
    timer?,
    showCancelButton = false,
    showCloseButton = false,
    showConfirmButton = true) {
    Swal.fire({
      titleText: titleText || 'Warning',
      icon: "warning",
      text: message,
      timer: timer || 3000,
      showCancelButton,
      showCloseButton,
      showConfirmButton
    });
  }
}
