<section class="wrapper multi-step-form">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4 col-xl-3 col-left pt-12 d-flex flex-column justify-content-center position-sticky top-0">
        <app-sidebar></app-sidebar>
        <div class="box mx-auto">
          <h3 class="text-center text-md-start">Client Onboarding</h3>
          <ul class="list-unstyled step-one">
            <li class="active" id="list-item-0">
              <div class="icon"> <i class="fa fa-check"></i></div><span>Company Details</span>
            </li>
            <li class=" " id="list-item-1">
              <div class="icon"> <i class="fa fa-check"></i></div><span>Document Uploadation</span>
            </li>
            <li class=" " id="list-item-2">
              <div class="icon"> <i class="fa fa-check"></i></div><span>Sign Up</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-8 col-xl-9 col-right px-start-end pb-6" (click)="hideSideBar()">
        <div class="topline pt-4 pt-lg-5 pb-3 pb-lg-4 d-flex align-items-center justify-content-between">
          <ul *ngIf="!client_status" class="breadcrumb p-0 m-0">
            <li class="breadcrumb-item 3" id="1"></li>
            <li class="breadcrumb-item 3 brand"><button [routerLink]="['/v1/dashboard']">Dashboard</button></li>
          </ul>
          <ul *ngIf="client_status">
          </ul>
          <div> <span>1/3</span></div>
        </div>
        <form [formGroup]="clientOnBoardForm">
          <h3 class="text-center">Company Details</h3>
          <div class="row">
            <div class="col-md-4 form-group">
              <label class="form-label" for="name">Name*</label>
              <input class="form-control" type="text" placeholder="Text Input" id="name" formControlName="name"
                [ngClass]="{'is-invalid': company.name.invalid && ( company.name.dirty || company.name.touched )}" />
              <div *ngIf="company.name.invalid && ( company.name.dirty || company.name.touched)" class="text-danger validation-message">
                <div [hidden]="!company.name.errors.maxlength">
                  Maximum 100 Characters Allowed
                </div>
                <div [hidden]="!company.name.errors.required">
                  Company Name is compulsory
                </div>
              </div>

            </div>
            <div class="col-md-4 form-group">
              <label class="form-label" for="website">Website</label>
              <input class="form-control" type="text" placeholder="Text Input" id="website" formControlName="website" />
            </div>
          </div>


          <div class="position-relative d-flex flex-wrap align-items-center add-new-wrap mb-2">
            <div class="flex-grow-1">
              <h4 class="mb-0">Address</h4>
            </div>
            <div class="position-absolute end-0 top-50 translate-middle-y">
              <button class="btn-add-new text-dark btn-outline-success" type="button" (click)="addNewAddress()"> <i
                  class="fa fa-plus me-1"></i><span>Address</span>
              </button>
            </div>
          </div>
          <div formArrayName="address" *ngFor=" let address of address.controls; let i=index">
            <div class="row" [formGroupName]="i">
              <div class="col-md-11">
              </div>
              <div class="col-md-1" style="text-align: right;">
                <button class="btn-outline-danger fas fa-trash btn-sm ml-xl-1 mt-2" type="button"
                  (click)="removeNewAddress(i)" *ngIf="i != 0">
                </button>
              </div>
              <div class="col-md-4 form-group">
                <label for="form-label">Address Type</label>
                <input type="text" list="addressType" class="form-select mt-2" formControlName="label" [ngClass]="{'is-invalid': addressTypeBoolean}" (change)="checkSameAddressType()">
                <!-- <small class="text-danger validation-message" *ngIf="addressTypeBoolean">Same AddressType Not Allowed</small> -->
                <datalist id="addressType">
                  <option value="Head Office">Head Office</option>
                  <option value="Regional Office">Regional Office</option>
                  <option value="Manufacturing Unit">Manufacturing Unit</option>
                  <option value="SEZ Unit">SEZ Unit</option>
                </datalist>
              </div>
              
              <div class="col-md-4 form-group">
                <label class="form-label" for="street-address-1">Street Address 1</label>
                <input class="form-control" type="text" placeholder="Text Input" id="street-address-1"
                  formControlName="street1" (change)="onStreetValueCheck()" [ngClass]="{'is-invalid': checkSameStreetBoolean}"/>
                  <!-- <small *ngIf="checkSameStreetBoolean" class="text-danger validation-message">Street1 & Street2 Can't Be Same</small> -->
              </div>
              <div class="col-md-4 form-group">
                <label class="form-label" for="street-address-2">Street Address 2</label>
                <input class="form-control" type="text" placeholder="Text Input" id="street-address-2"
                  formControlName="street2" (change)="onStreetValueCheck()" [ngClass]="{'is-invalid': checkSameStreetBoolean}"/>
              </div>
              <div class="col-md-4 form-group">
                <label class="form-label" for="city">City</label>
                <input class="form-control" type="text" placeholder="Text Input" id="city" formControlName="city" />
              </div>
              <div class="col-md-4 form-group">
                <label class="form-label" for="state">State</label>
                <input class="form-control" type="text" placeholder="Text Input" id="state" formControlName="state" />
              </div>
              <div formGroupName="country" class="col-md-4 form-group">
                <label class="form-label" for="country">Country</label>
                <select id="country" name="country" formControlName="id" class="form-control form-select">
                  <option value="" disabled selected>Select Country</option>
                  <option [value]="count.id" *ngFor="let count of countryCodes">
                    {{ count.name }}
                  </option>
                </select>
              </div>
              <div class="col-md-4 form-group">
                <label class="form-label" for="zip-code">Zip Code</label>
                <input class="form-control" type="text" placeholder="Text Input" id="zip-code"
                  formControlName="zipCode" />
              </div>
            </div>
          </div>

          <h4 class="mb-2 mt-4">Point Of Contact Details</h4>
          <div class="row">
            <div class="col-md-4 form-group">
              <label class="form-label" for="point-of-contact-name">Point of Contact First Name*</label>
              <input class="form-control" type="text" placeholder="Text Input" id="point-of-contact-name"
                formControlName="pocFirstName"
                [ngClass]="{'is-invalid': company.pocFirstName.invalid && ( company.pocFirstName.dirty || company.pocFirstName.touched )}" />
              <div *ngIf="company.pocFirstName.invalid && ( company.pocFirstName.dirty || company.pocFirstName.touched)"
                class="text-danger validation-message">
                First Name is compulsory
              </div>
            </div>
            <div class="col-md-4 form-group">
              <label class="form-label" for="point-of-contact-name">Point of Contact Last Name*</label>
              <input class="form-control" type="text" placeholder="Text Input" id="point-of-contact-name"
                formControlName="pocLastName"
                [ngClass]="{'is-invalid': company.pocLastName.invalid && ( company.pocLastName.dirty || company.pocLastName.touched )}" />
              <div *ngIf="company.pocLastName.invalid && ( company.pocLastName.dirty || company.pocLastName.touched)"
                class="text-danger validation-message">
                Last Name Name is compulsory
              </div>
            </div>
            <div class="col-md-4 form-group">
              <label class="form-label" for="point-of-conatct-email-id">Point of Conatct Email-Id*</label>
              <input class="form-control" type="email" placeholder="Text Input" id="point-of-conatct-email-id"
                formControlName="pocEmailId"
                [ngClass]="{'is-invalid': company.pocEmailId.invalid && ( company.pocEmailId.dirty || company.pocEmailId.touched )}" />
              <div *ngIf="company.pocEmailId.invalid && ( company.pocEmailId.dirty || company.pocEmailId.touched)"
                class="text-danger validation-message">
                <div [hidden]="!company.pocEmailId.errors.email">
                  Company EmailId is compulsoryss
                </div>
              </div>
            </div>
            <div class="col-md-4 form-group">
              <label class="form-label" for="point-of-contact-number">Point of Contact Number*</label>
              <input class="form-control" type="text" placeholder="Text Input" id="point-of-contact-number"
                formControlName="pocContactNumber"
                [ngClass]="{'is-invalid': company.pocContactNumber.invalid && ( company.pocContactNumber.dirty || company.pocContactNumber.touched )}"/>
              <div
                *ngIf="company.pocContactNumber.invalid && ( company.pocContactNumber.dirty || company.pocContactNumber.touched)"
                class="text-danger validation-message">
                <div [hidden]="!company.pocContactNumber.errors.required">
                  Company Contact Number is compulsory
                </div>
                <div [hidden]="!company.pocContactNumber.errors.minlength">
                  Minimum 9 digits are allowed
                </div>
                <div [hidden]="!company.pocContactNumber.errors.maxlength">
                  Max 16 digits are allowed
                </div>
              </div>
            </div>
            <div class="col-md-4 form-group">
              <label class="form-label" for="designation">Designation</label>
              <input class="form-control" type="text" placeholder="Text Input" id="designation"
                formControlName="pocDesignation" />
            </div>
          </div>
          <div class="row justify-content-center">
            <div *ngIf="client_status" class="col-md-6 form-group pt-4 pt-lg-5" (click)="updateClient()">
              <button class="btn btn-success w-100" type="submit">NEXT</button>
            </div>
            <div *ngIf="!client_status" class="col-md-6 form-group pt-4 pt-lg-5" (click)="clientInitiation()">
              <button class="btn btn-success w-100" type="submit">INVITE</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>