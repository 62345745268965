import { Component, OnInit , Optional, Inject} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-increase-timer',
  templateUrl: './increase-timer.component.html',
  styleUrls: ['./increase-timer.component.css']
})
export class IncreaseTimerComponent implements OnInit {

  increaseTimerForm: FormGroup

  local_data: any;
  action: string;

  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<IncreaseTimerComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) { 
    this.local_data = { ...data };
    this.action = this.local_data.action; { }
  }

  ngOnInit(): void {
    this.increaseTimerForm = this.fb.group({
      durationinMinutes: [null]
    })

    console.log('This is local data ', this.local_data);
    
  }

  doAction() {
    if (this.increaseTimerForm.valid) {
      this.dialogRef.close({ event: this.action, data: this.increaseTimerForm.value });
    } else {
      Swal.fire({
        title: "warning",
        icon: 'warning',
        titleText: "Please Provide mandatory Field",
        timer: 3000
      })
    }
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

}
