<div>
    <router-outlet>
        <div id="pause" *ngIf="loading$ | async" class="d-flex align-items-center justify-content-center">
            <div id="spinner"></div>
            <div class="loading-text">
                <h3 class="loading-text"> Loading... </h3>
            </div>
        </div>
    </router-outlet>
</div>
<div (click)="hideSideBar()" >
    <app-footer *ngIf="!isFooterVisible"></app-footer>
</div>