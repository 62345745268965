<div class="page-auction-list" id="sideBar">
    <aside *ngIf="showHamburger" class="sidebar">
        <div style="overflow-y: auto; height: 85%;" class="sidebar-inner">
            <a class="mx-auto" data-aos="fade"><img style="padding-left: 10px;" class="img-fluid"
                src="assets/images/logo-white-small.png" alt="Logsolutec" data-aos="fade" data-aos-delay="" /></a>
            <button (click)="hideSideBar()" class="btn-closesidebar" type="button">
                <svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M29.4281 29.4281L10.5719 10.5719" stroke="white" stroke-width="2" stroke-linecap="round">
                    </path>
                    <path d="M29.4281 10.5719L10.5719 29.4281" stroke="white" stroke-width="2" stroke-linecap="round">
                    </path>
                </svg>
            </button>
           <hr>
            <ul *ngIf="dType == 1" class="nav flex-column mt-3">
                <li class="nav-item" *ngFor="let type of dTypeOne; index as i">
                    <a *ngIf="type.parentRoute != undefined" [routerLink]="type.parentRoute"
                        class="nav-link nav-iten-has-children" data-bs-toggle="collapse" id="{{type.parent}}"
                        (click)="collapseExpandMenu(type.parent)">
                        {{ type.parent | uppercase }}
                    </a>
                    <a *ngIf="type.parentRoute == undefined" class="nav-link nav-iten-has-children"
                        data-bs-toggle="collapse" id="{{type.parent}}" (click)="collapseExpandMenu(type.parent)">
                        {{ type.parent | uppercase }}
                        <span class="icon-arrow">
                            <svg width="14" height="8" viewBox="0 0 14 8" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L6.29289 6.29289C6.68342 6.68342 7.31658 6.68342 7.70711 6.29289L13 1"
                                    stroke="white" stroke-width="1.5" stroke-linecap="round"></path>
                            </svg>
                        </span>
                    </a>
                    <div class="collapse nav-child" id="{{type.parent + 'child' }}">
                        <ul class="nav flex-column">
                            <li class="nav-item pt-2" *ngFor="let childType of type.child">
                                <a class="nav-link-child" [routerLink]="childType.linkRoute">
                                    {{ childType.linkName }}
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>

            <ul *ngIf="dType == 2" class="nav flex-column mt-3">
                <li class="nav-item" *ngFor="let type of dTypeTwo; index as i">
                    <a *ngIf="type.parentRoute != undefined" [routerLink]="type.parentRoute"
                        class="nav-link nav-iten-has-children" data-bs-toggle="collapse" id="{{type.parent}}"
                        (click)="collapseExpandMenu(type.parent)">
                        {{ type.parent | uppercase }}
                    </a>
                    <a *ngIf="type.parentRoute == undefined" class="nav-link nav-iten-has-children"
                        data-bs-toggle="collapse" id="{{type.parent}}" (click)="collapseExpandMenu(type.parent)">
                        {{ type.parent | uppercase }}
                        <span class="icon-arrow">
                            <svg width="14" height="8" viewBox="0 0 14 8" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L6.29289 6.29289C6.68342 6.68342 7.31658 6.68342 7.70711 6.29289L13 1"
                                    stroke="white" stroke-width="1.5" stroke-linecap="round"></path>
                            </svg>
                        </span>
                    </a>
                    <div class="collapse nav-child" id="{{type.parent + 'child' }}">
                        <ul class="nav flex-column">
                            <li class="nav-item pt-2" *ngFor="let childType of type.child">
                                <a class="nav-link-child" [routerLink]="[childType.linkRoute]">
                                    {{ childType.linkName }}
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
            <ul *ngIf="dType == 3" class="nav flex-column mt-3">
                <li class="nav-item" *ngFor="let type of dTypeThree; index as i">
                    <a *ngIf="type.parentRoute != undefined" [routerLink]="type.parentRoute"
                        class="nav-link nav-iten-has-children" data-bs-toggle="collapse" id="{{type.parent}}"
                        (click)="collapseExpandMenu(type.parent)">
                        {{ type.parent | uppercase }}
                    </a>
                    <a *ngIf="type.parentRoute == undefined" class="nav-link nav-iten-has-children"
                        data-bs-toggle="collapse" id="{{type.parent}}" (click)="collapseExpandMenu(type.parent)">
                        {{ type.parent | uppercase }}
                        <span class="icon-arrow">
                            <svg width="14" height="8" viewBox="0 0 14 8" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L6.29289 6.29289C6.68342 6.68342 7.31658 6.68342 7.70711 6.29289L13 1"
                                    stroke="white" stroke-width="1.5" stroke-linecap="round"></path>
                            </svg>
                        </span>
                    </a>
                    <div class="collapse nav-child" id="{{type.parent + 'child' }}">
                        <ul class="nav flex-column">
                            <li class="nav-item pt-2" *ngFor="let childType of type.child">
                                <a class="nav-link-child" [routerLink]="[childType.linkRoute]">
                                    {{ childType.linkName }}
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="col-md-2 mt-4">
                <a (click)="goToProfile()" style="cursor: pointer;">
                    <img *ngIf="!isImgDefault" class="img-fluid" src="assets/images/user-dp.png" alt="User Profile" title="View Profile"/>
                    <img *ngIf="isImgDefault" class="img-fluid" [src]="userImage" alt="User Profile" title="View Profile"/>
                </a>
            </div>
            <div class="col-md-9">
                <div class="row">
                    <div class="col-md-12">
                        <h6 class="pt-4">{{ emailId }}</h6>
                    </div>
                    <div class="col-md-8">
                        <h6>{{ orgName }}</h6>
                    </div>
                    <div class="col-md-3">
                        <button class="btn-success" (click)="logout()">
                            <strong>
                                Logout
                            </strong>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </aside>
</div>
<div *ngIf="!hideSubMenu" class="box-absolute d-flex">
    <button (click)="showSideBar()" class="btn-opensidebar" type="button">
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M38 24H22" stroke="white" stroke-width="2" stroke-linecap="round"></path>
            <path d="M38 14H10" stroke="white" stroke-width="2" stroke-linecap="round"></path>
            <path d="M38 34H14" stroke="white" stroke-width="2" stroke-linecap="round"></path>
        </svg>
    </button>
</div>