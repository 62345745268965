import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DemoService } from '../helpers/demo.service';

@Component({
  selector: 'app-career-listing',
  templateUrl: './career-listing.component.html',
  styleUrls: ['./career-listing.component.css']
})
export class CareerListingComponent implements OnInit {

  page = 0;
  size = 25;
  careerLength: number;
  displayedColumns: string[] = ['firstName', 'lastName' ,'emailId', 'contactNumber', 'file'];
  dataSource!: MatTableDataSource<any>;
  serchFilter: Boolean = false;
  nodata: Boolean = true;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  title = "Career List";


  constructor(private demoService: DemoService) { }

  ngOnInit(): void {
    this.getAllCareerList();
  }

  getAllCareerList(){
    this.demoService.getAllCareers(this.page, this.size).subscribe(
      (response) => {
        console.log(response);
        let content: Array<any> = response.content;
        this.careerLength = response.totalElements;
        if (content.length >= 1) {
          this.dataSource = new MatTableDataSource(response.content);
          this.dataSource.sort = this.sort;
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.nodata = false;
    this.serchFilter = true;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onPageChange(event: PageEvent) {
    this.size = event.pageSize;
    this.page = event.pageIndex;
    this.getAllCareerList();
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  downloadFileLocal(item) {
    if (item.id != null && item.label != null) {
      this.dataURItoBlob(item);
    }
  }

  dataURItoBlob(data) {
    const linkSource = data.content;
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = data.name;
    downloadLink.click();
  }

}
