import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MasterTableService } from 'src/app/helpers/master-table.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-charge-box',
  templateUrl: './charge-box.component.html',
  styleUrls: ['./charge-box.component.css']
})
export class ChargeBoxComponent implements OnInit {

  action: string;
  local_data: any;
  dialogForm: FormGroup;
  modeOfShipment: Array<any>
  chargeTypeArray: Array<any> = [
    {value: "PER_SHIPMENT"},
    {value: "PER_UNIT"},
  ]

  loadChargeTypeSea: Array<any> = [
    { code: "Break Bulk", value: "BREAK_BULK" },
    { code: "Package", value: "PACKAGE_DETAILS" },
    { code: "Additional", value: "ADDITIONAL_CHARGES" }
  ]

  loadChargeTypeAir: Array<any> = [
    { code: "Package", value: "PACKAGE_DETAILS" },
    { code: "Additional", value: "ADDITIONAL_CHARGES" }
  ]


  seaBool: Boolean = false;
  airBool: Boolean = false;

  constructor(private masterTableService: MasterTableService, public dialogRef: MatDialogRef<ChargeBoxComponent>, public fb: FormBuilder,
    //@Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.local_data = { ...data };
    this.action = this.local_data.action; { }

  }

  ngOnInit(): void {
    this.dialogForm = this.fb.group({
      name: [''],
      type: [''],
      modeOfShipment: this.fb.group({
        id: ['']
      }),
      loadChargeType: ['', Validators.required]
    });

    if (this.action != "Create") {
      this.dialogForm.addControl('id', new FormControl());
      this.dialogForm.patchValue({
        id: this.local_data.id,
        name: this.local_data.name,
        type: this.local_data.type,
        modeOfShipment: {
          id: this.local_data.modeOfShipment.id
        },
        loadChargeType: this.local_data.loadChargeType
      })
      if(this._modeOfShipment.get('id').value == 1){
        this.airBool = true;
      }
      else if(this._modeOfShipment.get('id').value == 2){
        this.seaBool = true;
      }
    }
    this.getModeOfShipment();
  }

  get form() { return this.dialogForm.controls }

  get _modeOfShipment(){ return this.dialogForm.get('modeOfShipment') as FormGroup; }
  
  getModeOfShipment(){
    this.masterTableService.getModeOfShipment().subscribe(
      (response) => {
        console.log(response);
        this.modeOfShipment = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  doAction() {
    if (this.dialogForm.valid) {
      this.dialogRef.close({ event: this.action, data: this.dialogForm.value });
    } else {
      Swal.fire({
        title: "warning",
        icon: "warning",
        titleText: "Please provide all mandatory fields",
        timer: 3000
      })
    }
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }


  changeModeOfShipment(){
    let modeOfShipment = this._modeOfShipment.value.id
    // Air
    if(modeOfShipment == 1){
      this.airBool = true;
      this.seaBool = false;
    }
    else if(modeOfShipment == 2){
      this.seaBool = true;
      this.airBool = false;
    }
    // ToDo
    else{
      console.log("Other Mode of Shipment");
    }
  }

}
