import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vendor-certificate-term',
  templateUrl: './vendor-certificate-term.component.html',
  styleUrls: ['./vendor-certificate-term.component.css']
})
export class VendorCertificateTermComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
