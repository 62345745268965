import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShipmentService {

  AUTH_API = `https://${window.location.hostname}:9004/api/v1/procurement`;

  constructor(private http: HttpClient) { }

  /**
   * Function to create shipment tracking
   * @param data Form data
   * @returns 
   */
  createShipmentTracking(data: any): Observable<any> {
    return this.http.post(this.AUTH_API + '/shipment', data);
  }

  /**
   * Function to update Shipment Tracking
   * @param data Form data
   * @param id id of shipment
   * @returns
   */
  updateShipmentTracking(data, id): Observable<any> {
    return this.http.put(this.AUTH_API + `/shipment/${id}`, data);
  }

  /**
   * Function to fetch all shipments
   * @returns Observables of Shipments
   */
  getAllShipments(page: any, size: any): Observable<any> {
    const params = new HttpParams().set('page', page).set('size', size)
    return this.http.get(`${this.AUTH_API}/shipments`, {params: params});
  }

  /**
   * Function to fetch shipment By id
   * @param id 
   * @returns 
   */
  fetchShipmentById(id): Observable<any> {
    return this.http.get(`${this.AUTH_API}/shipment/${id}`);
  }

  /**
   * Function to create/upload shipment artifact
   * @param shipmentId shipment id
   * @param data data of document
   * @returns 
   */
  createShipmentArtifact(shipmentId, data): Observable<any> {
    return this.http.post(`${this.AUTH_API}/shipment/${shipmentId}/artifact`, data);
  }

  /**
   * Function to delete shipment artifact/document
   * @param shipmentId shipment id
   * @param artifactId artifact id
   * @returns 
   */
  deleteShipmentArtifact(shipmentId, artifactId): Observable<any> {
    return this.http.delete(`${this.AUTH_API}/shipment/${shipmentId}/artifact/${artifactId}`);
  }

  /**
   * Function to get shipment documents
   * @param shipmentId shipment id
   * @returns 
   */
  getShipmentArtifacts(shipmentId) {
    return this.http.get(`${this.AUTH_API}/shipment/${shipmentId}/artifacts`);
  }

  fetchAirShipmentByIdOrByAirWaybillNumber(airWaybillNumber?: number, id?: number){
    let param = {};
    if(airWaybillNumber != null){
      param['airWaybillNumber'] = airWaybillNumber;
    }
    if(id != null){
      param['id'] = id;
    }
    return this.http.get(`${this.AUTH_API}/airShipmentByIdOrByAirWaybillNumber`, {params: param});
  }

  getFlightsShedules(data):Observable<any>{
    return this.http.post(`${this.AUTH_API}/flightSchedule`, data);
  }

  getAllShipmentCount(status):Observable<any>{
    let param = new HttpParams().set('status', status)
    return this.http.get(`${this.AUTH_API}/shipmentCount`, {params: param});
  }


  trackShipmentByContainerNumber(containerNumber):Observable<any>{
    let param = new HttpParams().set('containerNumber', containerNumber)
    return this.http.get(`${this.AUTH_API}/trackShipment`, {params: param});
  }

  getAllShipmentsStatus(page: any, size: any, status):Observable<any> {
    const params = new HttpParams().set('page', page).set('size', size).set('status', status);
    return this.http.get(`${this.AUTH_API}/shipmentListing`, {params: params});
  }

  filterShipments(param: any, value: any){
    const params = new HttpParams().set(param, value);
    return this.http.get(`${this.AUTH_API}/trackShipment`, {params: params});
  }


}
