import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { interval, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { AuctionService } from '../helpers/auction.service';
import { MasterTableService } from '../helpers/master-table.service';
import { SocketService } from '../helpers/socket.service';
import { EditBidVendorComponent } from './edit-bid-vendor/edit-bid-vendor.component';
import { IncreaseTimerComponent } from './increase-timer/increase-timer.component';



@Component({
  selector: 'app-client-live-auction',
  templateUrl: './client-live-auction.component.html',
  styleUrls: ['./client-live-auction.component.css']
})
export class ClientLiveAuctionComponent implements OnInit {

  auctionSummary: any = {};
  airBool: Boolean = false;
  seaBool: Boolean = false;
  biddersParticipating: number = 0;
  totalBids: number = 0;
  assignedVendors: Array<any> = [];
  totalVolume: number = 0;
  lowestBid: number = 0;
  savings: number = 0;
  savingsInAmt: number = 0;
  biddersArray: Array<any> = [];
  noBiddersArray: Array<any> = [];
  bidAmountArray: Array<any> = [];
  timeLeft: number;
  container_type = [];
  auctionTerms: Array<any> = [];
  auctionDocuments: Array<any> = [];
  modeOfShipment: any;
  fullContainerLoadBool: Boolean = false;
  halfContainerBool: Boolean = false;
  breakBulkBool: Boolean = false;
  packageDetails: Array<any> = [];
  contianerDetails: Array<any> = [];
  breakBulkArray: Array<any> = [];
  totalBreakBulkVolume: any;
  totalBreakBulkWeight: any;
  totalVolumeWeightLCL: number;
  finalSeaWeightUom: string;
  totalGrossWeightLCL: number;
  finalSeaWeightBool: boolean;
  totalVolumeWeightAIR: number;
  finalAirWeightUom: string;
  totalGrossWeightAIR: number;
  finalAirWeightBool: boolean;

  auctionId: any
  auctionType: string = 'r';

  color: ThemePalette = 'primary';
  mode: ProgressBarMode = 'buffer';
  curSec: number = 0;

  noShowVendorsList: Array<any> = []
  participatingFalseVendorList: Array<any> = []
  remainingTimer: number = 0;
  showButton = false;
  natureOfGoods: Array<any> = [];

  constructor(private route: ActivatedRoute, private auctionService: AuctionService, private datePipe: DatePipe, private masterTableService: MasterTableService,
    private socketService: SocketService, private router: Router, private dialog: MatDialog, private toastService: ToastrService) { }

  ngOnInit(): void {
    this.auctionId = this.route.snapshot.paramMap.get('id');
    if (this.auctionId != null) {
      this.getVendorBidById(this.auctionId)
    }
    this.getMasterTablesSessionStorage();
    this.getNoShowVendorsByAuction();
    this.getParticipatingFalseVendors();
    let x = this.socketService.connectToServer();
    x.subscribe(
      (response) => {
        let data = JSON.parse(response.data);
        console.log(data);
        if(data.eventType == "newBid"){
          this.showPopUpMsg(data.eventMessage);
          this.getVendorBidById(this.auctionId);
          this.getNoShowVendorsByAuction();
          this.getParticipatingFalseVendors();
          this.getAuctionById(this.auctionId);
        }
        else if(data.eventType == "IS_PARTICIPATING"){
          this.showPopUpMsg(data.eventMessage);
          this.getVendorBidById(this.auctionId);
          this.getNoShowVendorsByAuction();
          this.getParticipatingFalseVendors();
          this.getAuctionById(this.auctionId);
        }
        else if(data.eventType == "AUCTION_COMPLETED"){
          Swal.fire({
            title: "Auction Completed",
            icon:"success",
            html: data.eventMessage,
            timer: 5000
          });
          this.router.navigateByUrl('/v1/client-auction/listing/r');
        }
        else {
          console.log('Nothing');
        }
      },
      (error) => {
        console.log(error);
      },
    )

  }

  getAuctionById(id: any) {
    this.auctionService.fetchAuctionById(id).subscribe(
      (response) => {
        if (response != null) {
          this.packageDetails = [];
          this.breakBulkArray = [];
          this.contianerDetails = [];
          this.auctionDocuments = [];
          console.log(response);
          this.auctionType = response.type
          this.auctionSummary["description"] = response.description;
          this.auctionSummary["number"] = response.number;
          this.auctionSummary["enquiryNumber"] = response.enquiry.number;
          this.auctionSummary["projectName"] = response.enquiry.projectName;
          this.auctionSummary["projectCode"] = response.enquiry.projectCode;
          this.totalVolume = response.enquiry.chargableWeight;
          this.auctionSummary["minimumDecrement"] = response.minimumDecrement;
          this.auctionSummary["startDate"] = new Date(response.auctionDate);
          this.auctionSummary["endDate"] = new Date(response.auctionEndTime);
          this.auctionSummary["auctionConductedBy"] = response.auctionConductedBy;
          this.auctionSummary["originPort"] = response.enquiry.originPortName;
          this.auctionSummary["destinationPort"] = response.enquiry.destinationPortName;
          this.auctionSummary["basePrice"] = response.baseprice;
          this.auctionSummary["targetPrice"] = response.targetPrice;
          this.auctionSummary["biddersInvited"] = response.assignedVendors.length;
          this.auctionSummary["status"] = response.status;
          this.auctionSummary["natureOfGoods"] = response.natureOfGoods;
          if(response.status == "COMPLETE"){
            let route = 'v1/client-auction/listing/' + this.auctionType
            this.router.navigate([route])
          }
          this.auctionSummary["durationinMinutes"] = this.convertHMS(response.durationinMinutes);
          this.auctionSummary["timerCountDownInSec"] = response.timerCountDownInSec
          this.totalBids = response.vendorBidCount;
          this.showButton = response.extendTimeBool;
          // Difference in dateime params.
          this.differenceInSeconds(this.auctionSummary.startDate, this.auctionSummary.endDate);

          let packageDetails: Array<any> = response.enquiry.shipmentPackages;
          let containerDetails: Array<any> = response.enquiry.containers;
          let breakBulkDetails: Array<any> = response.enquiry.breakBulks;
          let termsConditions: Array<any> = response.terms;
          let documentList: Array<any> = response.artifacts;
          this.modeOfShipment = response.enquiry.modeOfShipment;

          // ToDo Changes according to modeOfShipment and containerLoadType

          if(this.modeOfShipment == 1) {
            this.airBool = true
            packageDetails.forEach((element, index) => {
              let details = this.prePopulatePackageAir(element);
              this.packageDetails.push(details)
            })
            this.calculateAirVolume();
          }
          else if(this.modeOfShipment == 2){
            this.seaBool = true;
            let clt = response.enquiry.containerLoadType;
            if (clt == 1) {
              this.fullContainerLoadBool = true;
              containerDetails.forEach((element) => {
                this.contianerDetails.push(this.prePopulateContainers(element));
              });
              if(breakBulkDetails.length > 0){
                this.breakBulkBool = true;
                breakBulkDetails.forEach((element, index) => {
                  this.breakBulkArray.push(this.prePopulateBreakBulk(element))
                  this.calculateBreakBulkWeight(index);
                })

              }
            }
            else if(clt == 2){
              this.halfContainerBool = true;
              packageDetails.forEach((element) => {
                this.packageDetails.push(this.prePopulatePackageDetails(element));
              })
              this.calculateTotalVolume();
            }
            else if(clt == 3){
              this.fullContainerLoadBool = true;
              this.halfContainerBool = true;
              containerDetails.forEach((element) => {
                this.contianerDetails.push(this.prePopulateContainers(element));
              });
              packageDetails.forEach((element) => {
                this.packageDetails.push(this.prePopulatePackageDetails(element));
              });
              this.calculateTotalVolume();
            }
            else{
              this.breakBulkBool = true;
              breakBulkDetails.forEach((element, index) => {
                this.breakBulkArray.push(this.prePopulateBreakBulk(element))
                this.calculateBreakBulkWeight(index);
              });
            }
          }
          else if(this.modeOfShipment == 3){
            console.log("RAIL");
          }
          else{
            console.log("ROAD");
          }

          if (documentList.length >= 1) {
            documentList.forEach((document) => {
              let data = {
                "label": document.label,
                "content": document.content,
                "contentType": document.contentType,
                "name": document.name,
                "artifactId": document.id
              }
              this.auctionDocuments.push(data);
            });
          }

          if (termsConditions.length >= 1) {
            this.auctionTerms = termsConditions
          }
          else {
            console.log("No terms & conditions uploaded by client for Auction");
          }
          this.getSavings();
        }
      },
      (error) => {
        console.log(error);
      }
    )
  }

  differenceInSeconds(start: Date, end: Date){
    // Difference in start & end is 100% of progress bar.
    let current = new Date();
    let remainingSeconds = (end.getTime() - start.getTime()) / 1000;
    let currentSecondsLeft = (end.getTime() - current.getTime()) / 1000;
    let timer = (currentSecondsLeft/remainingSeconds);
    this.remainingTimer = Math.round((timer * 100));
  }

  getVendorBidById(id: any) {
    this.auctionService.fetchVendorBidById(id).subscribe(
      (response: Array<any>) => {
        if (response.length >= 1) {
          this.biddersArray = [];
          this.bidAmountArray = [];
          this.noBiddersArray = [];
          this.biddersParticipating = response.length;
          for (let index = 0; index < response.length; index++) {
            let bid = response[index];
            console.log(bid);
            if (bid.participating == false) {
              let data = {
                "vendorName": bid.vendor.name,
                "reason": bid.reason
              }
              this.noBiddersArray.push(data);
            }
            else {
              let data = {
                "vendorName": bid.vendor?.name,
                "bidValue": bid.auctionValue,
                "quoteValue": bid.quoteValue,
                "rank": bid.rank,
                "id": bid.id,
                "reason": bid.reason
              }
              this.biddersArray.push(data);
              this.bidAmountArray.push(bid.auctionValue);
            }
          }
          this.lowestBid = Math.min(...this.bidAmountArray);
          this.getSavings();
        }
        this.getAuctionById(id);
      },
      (error) => {
        let _error = error.error;
        if (_error?.errorCode == 412) {
          this.biddersParticipating = 0;
        }
        this.getAuctionById(id);
      },
    )
  }

  getSavings(){
    if(this.lowestBid == 0){
      this.savings = 0
    }
    else if(this.auctionSummary.basePrice != undefined || null && this.lowestBid != 0){
      this.savingsInAmt = this.auctionSummary.basePrice - this.lowestBid
      let savings = (this.auctionSummary.basePrice) - this.lowestBid
      savings = (savings * 100) / this.auctionSummary.basePrice
      this.savings = savings;
    }
  }

  prePopulatePackageAir(data: any){
    return {
      id: data.id,
      length: data.length,
      height: data.height,
      width: data.width,
      quantity: data.quantity,
      grossWeight: data.grossWeight,
      dimensionsUOM: data.dimensionsUOM,
      weightUOM: data.weightUOM,
      totalVolume: data.totalVolume
    }
  }

  prePopulatePackageDetails(data: any) {
    let type = this.filterContainerType(data.type);
    return {
      length: data.length,
      height: data.height,
      width: data.width,
      type: type.value,
      quantity: data.quantity,
      grossWeight: data.grossWeight,
      dimensionsUOM: data.dimensionsUOM,
      weightUOM: data.weightUOM,
      totalVolume: data.totalVolume
    };
  }
  prePopulateContainers(data: any) {
    let type = this.filterContainerType(data.type);
    return {
      quantity: data.quantity,
      size: data.size,
      type: type.value,
      uom: data.uom,
      weight: data.weight
    }
  }
  prePopulateBreakBulk(form: any) {
    return {
      totalGrossWeight: form.totalGrossWeight,
      totalVolume: form.totalVolume,
      totalnetWeight: form.totalnetWeight
    }
  }
  getMasterTablesSessionStorage() {
    this.containerTypeList();
    this.getNatureOfGoods();
  }

  containerTypeList(){
    this.masterTableService.getContainerTypes().subscribe(
      (response) => {
        if(response){
          this.container_type = response
        }
      },
      (error) => {error},
    );
  }

  getNatureOfGoods(){
    this.masterTableService.getNatureOfGoods().subscribe(
      (response) => {
        this.natureOfGoods = response
      },
      (error) => {
        console.log(error);
      }
    )
  }

  filterContainerType(type: any) {
    if(type == null){
      return null
    }
    return this.container_type.find(
      (item) => {
        return item.id === type
      }
    )
  }


  convertHMS(value) {
    const minutes = parseInt(value);
    let seconds = Math.floor(minutes * 60);
    return seconds;
  }

  downloadFileLocal(item) {
    if (item.artifactId != null && item.label != null) {
      this.dataURItoBlob(item);
    }
  }

  dataURItoBlob(data) {
    const linkSource = data.content;
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = data.name;
    downloadLink.click();
  }

  calculateBreakBulkWeight(index: number) {
    let break_bulk = this.breakBulkArray[index];
    this.totalBreakBulkVolume = break_bulk.totalVolume;
    this.totalBreakBulkWeight = this.convertweightUOMtoMT(break_bulk.totalGrossWeightUOM, break_bulk.totalGrossWeight);
    if(this.totalBreakBulkVolume > this.totalBreakBulkWeight){
      console.log(this.totalBreakBulkVolume);
    }
    else{
      console.log(this.totalBreakBulkWeight);
    }
  }

  convertweightUOMtoMT(weightUOM: string, weight: number) {
    let _weight: number = 0.0;
    if (weightUOM == "KG") {
      _weight = (weight / 1000.00);
    }
    else if (weightUOM == "LBS") {
      _weight = (weight / 2204.62);
    }
    else {
      _weight = weight;
    }
    return parseFloat(_weight.toFixed(3));
  }

  calculateTotalVolume() {
    this.totalVolumeWeightLCL = 0.0;
    for (let index = 0; index < this.packageDetails.length; index++) {
      const _package = this.packageDetails[index];
      let quantity = _package.quantity;
      let length = _package.length;
      let height = _package.height;
      let width = _package.width;
      let dimensionsUOM = _package.dimensionsUOM;
      let totalVolume = (length * width * height) * quantity;
      let convert = this.convertdimensionsUOMtoMtr(dimensionsUOM, totalVolume);
      _package.totalVolume = convert;
      this.totalVolumeWeightLCL = convert + this.totalVolumeWeightLCL;
    }
    this.convertToMTsea();
  }


  // Comman for air/sea(ShipmentPackages).
  convertdimensionsUOMtoMtr(dimensionsUOM, totalVolume) {
    let volume: number = 0.0;
    if (dimensionsUOM == "CM") {
      // 100.00
      volume = (totalVolume / 100.00);
    }
    else if (dimensionsUOM == "MM") {
      // 1000.00
      volume = (totalVolume / 1000.00);
    }
    else if (dimensionsUOM == "IN") {
      // 39.37
      volume = (totalVolume / 39.37);
    }
    else if (dimensionsUOM == "FT") {
      // 3.2808
      volume = (totalVolume / 3.2808);
    }
    else {
      // mtr
      volume = totalVolume
    }
    return parseFloat(volume.toFixed(4));
  }

  convertToMTsea() {
    this.compareWeightUOMSea();
    this.calculateWeightSea();
    this.compareWeightVolume();
  }

  compareWeightUOMSea(){
    let comparison = ""
    for (let index = 0; index < this.packageDetails.length; index++) {
      const _package = this.packageDetails[index];
      let weightUOM = _package.weightUOM;
      let latest = weightUOM;
      if(comparison.length > 0){
        switch(latest){
          case "LBS":
            if(comparison == "KG"){
              comparison = "KG"
            }
            else if(comparison == "MT"){
              comparison = "MT"
            }
            else{
              comparison = latest
            }
            break
          case "KG":
            if(comparison == "MT"){
              comparison = "MT"
            }
            else if(comparison == "LBS"){
              comparison = "KG"
            }
            else{
              comparison = latest
            }
            break
          case "MT":
            comparison = latest
            break
        }
      }
      else{
        comparison = latest
      }
    }
    this.finalSeaWeightUom = comparison;
  }

  calculateWeightSea(){
    this.totalGrossWeightLCL = 0.0
    for (let index = 0; index < this.packageDetails.length; index++) {
      const _package = this.packageDetails[index];
      let weightUOM = _package.weightUOM;
      let weight = _package.grossWeight;
      if(this.finalSeaWeightUom == "MT"){
        if(weightUOM == "KG"){
          let kg = this.convertKGtoMT(weight);
          this.totalGrossWeightLCL = kg + this.totalGrossWeightLCL;
        }
        else if(weightUOM == "LBS"){
          let lbs = this.convertLBStoMT(weight);
          this.totalGrossWeightLCL = lbs + this.totalGrossWeightLCL;
        }
        else{
          this.totalGrossWeightLCL = weight + this.totalGrossWeightLCL;
        }
      }
      else if(this.finalSeaWeightUom == "KG"){
        if(weightUOM == "LBS"){
          let lbs = this.convertLBStoKG(weight);
          this.totalGrossWeightLCL = lbs + this.totalGrossWeightLCL;
        }
        else{
          this.totalGrossWeightLCL = weight + this.totalGrossWeightLCL;
        }
      }
      else {
        this.totalGrossWeightLCL = weight + this.totalGrossWeightLCL;
      }
    }
  }

  compareWeightVolume() {
    let chargeableWeight: number = 0.0;
    let metricTon: number = 0.0;
    for (let index = 0; index < this.packageDetails.length; index++) {
      let _package = this.packageDetails[index]
      if(this.finalSeaWeightUom == "KG"){
        if(_package.weightUOM == "LBS"){
          let lbs = this.convertLBStoMT(_package.grossWeight)
          metricTon = lbs + metricTon
        }
        else{
          let kg = this.convertKGtoMT(_package.grossWeight);
          metricTon = kg + metricTon
        }
      }
      else if(this.finalSeaWeightUom == "MT"){
        if(_package.weightUOM == "LBS"){
          let lbs = this.convertLBStoMT(_package.grossWeight)
          metricTon = lbs + metricTon
        }
        else if(_package.weightUOM == "KG"){
          let lbs = this.convertKGtoMT(_package.grossWeight)
          metricTon = lbs + metricTon
        }
        else{
          metricTon = _package.grossWeight + metricTon
        }
      }
      else{
        let lbs = this.convertLBStoMT(_package.grossWeight);
        metricTon = lbs + metricTon
      }
    }
    if(metricTon > this.totalVolumeWeightLCL) {
      this.finalSeaWeightBool = true;
      chargeableWeight = metricTon
    }
    else{
      this.finalSeaWeightBool = false;
      chargeableWeight = this.totalVolumeWeightLCL
    }
    // this.enquiryForm.get('chargableWeight').patchValue(chargeableWeight);
    return chargeableWeight;
  }

  convertKGtoLBS(weight){
    let _weight = (weight * 2.205)
    return parseFloat(_weight.toFixed(4))
  }

  convertKGtoMT(weight){
    let _weight = (weight / 1000)
    return parseFloat(_weight.toFixed(4))
  }

  convertLBStoKG(weight){
    let _weight = (weight / 2.205)
    return parseFloat(_weight.toFixed(4))
  }

  convertLBStoMT(weight){
    let _weight = (weight / 2205)
    return parseFloat(_weight.toFixed(4))
  }

  calculateAirVolume() {
    this.totalVolumeWeightAIR = 0.0;
    for (let index = 0; index < this.packageDetails.length; index++) {
      const _package = this.packageDetails[index];
      let length = _package.length;
      let height = _package.height;
      let width = _package.width;
      let quantity = _package.quantity;
      let dimensionsUOM = _package.dimensionsUOM;
      let totalVolume = (length * width * height * quantity) / 6000;
      let convert = this.convertdimensionsUOMtoCm(dimensionsUOM, totalVolume);
      _package.totalVolume = convert;
      this.totalVolumeWeightAIR = _package.totalVolume + this.totalVolumeWeightAIR;
    }
    this.convertToMTAIR();
  }

  convertToMTAIR() {
    this.compareWeightUomAir();
    this.calculateWeightAir();
    this.compareWeightVolumeAir();
  }

  compareWeightUomAir(){
    let comparison = ""
    for (let index = 0; index < this.packageDetails.length; index++) {
      const _package = this.packageDetails[index];
      let weightUOM = _package.weightUOM;
      let latest = weightUOM;
      if(comparison.length > 0){
        switch(latest){
          case "LBS":
            if(comparison == "KG"){
              comparison = "KG"
            }
            else{
              comparison = latest
            }
            break
          case "KG":
            if(comparison == "KG"){
              comparison = "KG"
            }
            else{
              comparison = latest
            }
            break
        }
      }
      else{
        comparison = latest
      }
    }
    this.finalAirWeightUom = comparison;
  }

  calculateWeightAir(){
    this.totalGrossWeightAIR = 0.0;
    for (let index = 0; index < this.packageDetails.length; index++) {
      const _package = this.packageDetails[index];
      let weightUOM = _package.weightUOM;
      let weight = _package.grossWeight;
      if(this.finalAirWeightUom == "KG"){
        if(weightUOM == "LBS"){
          let _weight = this.convertLBStoKG(weight);
          this.totalGrossWeightAIR = _weight + this.totalGrossWeightAIR;
        }
        else{
          this.totalGrossWeightAIR = weight + this.totalGrossWeightAIR;
        }
      }
      else if(this.finalAirWeightUom == "LBS"){
        this.totalGrossWeightAIR = weight + this.totalGrossWeightAIR;
      }
    }
  }

  compareWeightVolumeAir() {
    let chargeableWeight: number;
    let metricTon: number = 0.0;
    for (let index = 0; index < this.packageDetails.length; index++) {
      let _package = this.packageDetails[index]
      if(this.finalAirWeightUom == "KG"){
        if(_package.weightUOM == "LBS"){
          let lbs = this.convertLBStoMT(_package.grossWeight)
          metricTon = lbs + metricTon
        }
        else{
          let kg = this.convertKGtoMT(_package.grossWeight);
          metricTon = kg + metricTon
        }
      }
      else{
        let lbs = this.convertLBStoMT(_package.grossWeight);
        metricTon = lbs + metricTon
      }
    }
    if(metricTon > this.totalVolumeWeightAIR) {
      this.finalAirWeightBool = true;
      chargeableWeight = metricTon
    }
    else{
      this.finalAirWeightBool = false;
      chargeableWeight = this.totalVolumeWeightAIR
    }
    // ToDo
    return chargeableWeight;
  }

  convertdimensionsUOMtoCm(dimensionsUOM, totalVolume) {
    let volume: number = 0.0;
    if (dimensionsUOM == "MTR") {
      // 100.00
      volume = totalVolume * 100;
    }
    else if (dimensionsUOM == "MM") {
      // 10.00 need to make some changes here.
      volume = totalVolume / 10.00;
    }
    else if (dimensionsUOM == "IN") {
      // 2.54
      volume = totalVolume * 2.54;
    }
    else if (dimensionsUOM == "FT") {
      // 30.48
      volume = totalVolume * 30.48;
    }
    else {
      // cm
      volume = totalVolume
    }
    return parseFloat(volume.toFixed(4));
  }

  endAuction(){
    let route = 'v1/client-auction/listing/' + this.auctionType
    let data = {
      "status": "COMPLETE"
    }
    this.auctionService.updateAuctionStatus(this.auctionId, data).subscribe
    ((res)=>{
      console.log(res);
      if(res.code == 200){
        Swal.fire({
          title: 'Completed',
          text: res.message,
          timer: 5000,
          icon: 'success'
        })
        this.router.navigate([route])
      }
    },
    (error)=>{
      console.log(error);
    })
  }

  handleEventOnTime(event: any){
    let actualStartSeconds = this.auctionSummary.timerCountDownInSec
    this.startTimer(actualStartSeconds);
  }

  startTimer(seconds: number) {
    const timer$ = interval(1000);
    let start = this.auctionSummary.startDate;
    let end = this.auctionSummary.endDate;
    const sub = timer$.subscribe((sec) => {
      this.differenceInSeconds(start, end);
      this.curSec = sec;
      if (this.curSec === seconds) {
        sub.unsubscribe();
      }
    });
  }

  openDialog(action, obj) {
    let data = {
      "action": action,
      "auctionId": this.auctionId
    }
    const dialogRef = this.dialog.open(IncreaseTimerComponent, {
      width: '250px',
      data: data
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      console.log(result.data);
      if (result.event == 'Create') {
        this.updateAuctionTimeByClientUI(result.data)
      }
    });
  }

  editBidValueVendor(bidObject){
    let data = {
      "bid": bidObject,
      "auctionId": this.auctionId,
      "action": "Edit"
    }
    const dialogRef = this.dialog.open(EditBidVendorComponent, {
      width: '400px',
      data: data
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      if(result.event === "Edit"){
        let data = {
          "reason": result.data.reason,
          "auctionValue": result.data.auctionValue,
          "auction": {
            "id": this.auctionId
          }
        }
        this.auctionService.updateVendorBid(result.data.id, data).subscribe(
          (response) => {
            console.log(response);
          },
          (error) => {
            console.log(error);
          },
        );
      }
    });
  }

  //to increase the auction time from client side
  updateAuctionTimeByClientUI(item: any){
    this.auctionService.updateAuctionTimeByClient(this.auctionId, item).subscribe
    ((res)=>{
      console.log('This is res ', res);
      Swal.fire({
        title: 'Info',
        text: res.message + ' by ' + item.durationinMinutes + ' minutes',
        timer: 5000,
        icon: "info"
      })
      this.getAuctionById(this.auctionId);
      window.location.reload();
    },
    (error)=>{
      let _error = error.error;
      if(_error.errorCode == 412){
        Swal.fire({
          title: 'Warning',
          text: _error.errorMessage,
          timer: 5000,
          icon: "warning"
        })
      }
      console.log('This is error ', error);
    })
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  getNoShowVendorsByAuction(){
    this.noShowVendorsList = []
    this.auctionService.GetAllNoShowVendorsByAuctionId(this.auctionId).subscribe
    ((res)=>{
      this.noShowVendorsList = res
    },
    (error)=>{
      console.log('error at no show vendors list ', error);
    })
  }

  getParticipatingFalseVendors(){
    this.auctionService.fetchVendorBidByIdParticipatingFalse(this.auctionId).subscribe
    ((res)=>{
      this.participatingFalseVendorList = res
    },
    (error)=>{
      console.log('This is participating false error ', error);
    })
  }

  showPopUpMsg(message?): void {
    this.toastService.info(message, undefined, {
      enableHtml: true,
      closeButton: true,
      progressBar: true,
      timeOut: 5000
    });
  }

}
