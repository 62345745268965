import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SocketService } from './socket.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json'})
};

const TOKEN_KEY = 'token';
const USER_KEY = 'user';


@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {
  AUTH_API: string = `https://${window.location.hostname}:9001/api/v1`;


  constructor(private http: HttpClient, private socketService: SocketService) { }

  login(data: any):Observable<any>{
    return this.http.post(`${this.AUTH_API}/user-session`, data);
  }

  public isAuthenticated(): boolean {
    return this.getToken() !== null;
  }

  saveToken(token: string) {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  getToken(){
    let token = JSON.parse(sessionStorage.getItem(TOKEN_KEY));
    if (token != null){
        return token;
    }
    return null;
  }

  saveUser(user: any) {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  getUser() {
    let user = sessionStorage.getItem(USER_KEY)
    if (user != null){
        return user;
    }
    return null;
  }

  signOut():Observable<any>{
    return this.http.delete(`${this.AUTH_API}/deleteUserSession`);
  }

  deleteOldUserSession(userId):Observable<any>{
    return this.http.delete(`${this.AUTH_API}/deleteOldUserSession/${userId}`)
  }

  sendOtpOnEmail(credentials: string): Observable<any>{
    return this.http.post(`${this.AUTH_API}/user/otp/${credentials}`, httpOptions);
  }

  verifyUser(emailId: string, otp: number):Observable<any>{
    return this.http.post(`${this.AUTH_API}/user/otp/${emailId}/${otp}`, httpOptions);
  }

  changePassword(email: string | null, password: any): Observable<any>{
    let data = {
      "emailId": email,
      "password": password.password
    }
    return this.http.put(`${this.AUTH_API}/user`, data);
  }

  signUpUser(user: any): Observable<any> {
    return this.http.post(`${this.AUTH_API}/user`, user);
}

  getAllPermissions(): Observable<any>{
    return this.http.get(`${this.AUTH_API}/permissionList`)
  }

  getAllCategoryList(): Observable<any> {
    return this.http.get(`${this.AUTH_API}/permissionCategoryList`)
  }
}
