<!--header start-->
<header class="box-absolute d-flex align-items-center topline text-white">
    <app-sidebar></app-sidebar>
    <div class="page-title-center mt-3">
        <div class="text-center">
            <h1>{{ title }}</h1>
        </div>
    </div>
    <div class="breadcrumb-div mt-3">
        <ul class="breadcrumb p-0 m-0">
            <li class="breadcrumb-item 3" id="0"><a class="a-space" [routerLink]="['/v1/dashboard']">Dashboard</a></li>
            <li class="breadcrumb-item 3" id="1"><a class="a-space">Listing</a></li>
            <li class="breadcrumb-item"><a>{{ title }}</a></li>
        </ul>
    </div>
</header>
<!--header end-->
<div (click)="hideSideBar()">
<section class="banner text-center text-white d-flex" id="bnner">
    <div class="container position-relative">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <h1>Contact Us</h1>
            </div>
        </div>
    </div>
</section>
<!--request start-->
<section class="request-demo-wrapper pb-10">
    <div class="container shadow bg-white">
        <div class="row">
            <div class="col-lg-12">
                <div class="box">
                    <form>
                        <mat-form-field class="form-control" appearance="standard">
                            <mat-label>Search</mat-label>
                            <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
                        </mat-form-field>
                        <div class="table-responsive">
                            <table class="table table-borderless table-striped table-footer-dark table-head-dark"
                                mat-table matTableExporter #exporter="matTableExporter" [dataSource]="dataSource"
                                matSort matSortDisableClear>
                                <ng-container matColumnDef="firstName">
                                    <th mat-header-cell class="text-white" *matHeaderCellDef mat-sort-header> First Name
                                    </th>
                                    <td mat-cell *matCellDef="let row; let i = index"> {{ row.firstName | uppercase }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="lastName">
                                    <th mat-header-cell class="text-white" *matHeaderCellDef mat-sort-header> Last Name
                                    </th>
                                    <td mat-cell *matCellDef="let row; let i = index"> {{ row.lastName | uppercase }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="emailId">
                                    <th mat-header-cell class="text-white" *matHeaderCellDef mat-sort-header> EmailId
                                    </th>
                                    <td mat-cell *matCellDef="let row; let i = index"> {{ row.emailId }} </td>
                                </ng-container>
                                <ng-container matColumnDef="contactNumber">
                                    <th mat-header-cell class="text-white" *matHeaderCellDef mat-sort-header> Contact
                                        Number
                                    </th>
                                    <td mat-cell *matCellDef="let row; let i = index"> {{ row.contactNumber | uppercase
                                        }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="createdDate">
                                    <th mat-header-cell class="text-white" *matHeaderCellDef mat-sort-header> Created
                                        Date
                                    </th>
                                    <td mat-cell *matCellDef="let row; let i = index"> {{ row.createDate | date }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="description">
                                    <th class="text-white" mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Description
                                    </th>
                                    <td mat-cell *matCellDef="let row"> {{ row.description }} </td>
                                </ng-container>

                                <tr class="text-left bg-dark" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                                <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell text-center text-danger" colspan="6">
                                        <div *ngIf="serchFilter">
                                            No data matching the filter "{{ input.value }}"
                                        </div>
                                        <div *ngIf="nodata">
                                            <h5>Data is Not Available</h5>
                                        </div>
                                    </td>
                                </tr>
                            </table>

                            <mat-toolbar class="bg-white">
                                <mat-toolbar-row>
                                   <button><mat-icon (click)="exporter.exportTable('xlsx', { fileName:'Contact' })"
                                        title="Export as CSV">save_alt</mat-icon></button>
                                    <span class="example-spacer"></span>
                                    <mat-paginator [pageSizeOptions]="[25, 50, 100]" [length]="contactusLength"
                                        showFirstLastButtons (page)="onPageChange($event)">
                                    </mat-paginator>
                                </mat-toolbar-row>
                            </mat-toolbar>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-md-4 form-group pt-lg-3">
                            </div>
                            <div class="col-md-4 form-group pt-lg-3">
                            </div>
                            <div class="col-md-4 form-group pt-lg-3">
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!--request end -->
</div>