<footer class="py-4 py-lg-5">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-5">
                <h5 class="text-white">Discover</h5>
                <ul class="nav two-column">
                    <li class="nav-item"> <a class="nav-link" [routerLink]="['about-us']" >About Us</a></li>
                    <li class="nav-item"> <a class="nav-link" [routerLink]="['/career']">Career</a></li>
                    <li class="nav-item"> <a class="nav-link" [routerLink]="['services']">Services</a></li>
                    <li class="nav-item"> <a class="nav-link" [routerLink]="['request-demo']">Request Demo</a></li>
                    <li class="nav-item"> <a class="nav-link" [routerLink]="['client']">Client</a></li>
                    <li class="nav-item"> <a class="nav-link" [routerLink]="['feedback']">Feedback</a></li>
                    <!-- <li class="nav-item"> <a class="nav-link" >Referral</a></li> -->
                    <li class="nav-item"><a class="nav-link" [routerLink]="['csr-page']">CSR</a></li>
                    <li class="nav-item"> <a class="nav-link" [routerLink]="['faq-page']">FAQ</a></li>
                    <li class="nav-item"> <a class="nav-link" >Subscription</a></li>
                </ul>
            </div>
            <div class="col-sm-6 col-lg-4 px-lg-0">
                <h5 class="text-white">Office</h5>
                <nav class="nav flex-column">
                    <li class="nav-item"> <a class="nav-link"><b>Address: </b>LogSolutec LLP
                        Shiv Dutta Nagar,
                        Dapodi Pimple Gurav Road,
                        Pimple Gurav, Pune - 411061.
                        </a>
                    </li>
                    <li class="nav-item"> <a class="nav-link"> <b>Mobile No: </b>+91 8149606300</a></li>
                    <li class="nav-item"> <a class="nav-link" href="info@logsolutec.com"><b>Email:
                            </b>info@logsolutec.com</a></li>
                    <li class="nav-item"> <a class="nav-link"> <b>Business Hours: </b> 9:00 AM - 6:00 PM</a></li>
                </nav>
            </div>
            <div class="col-sm-12 col-lg-3 d-flex justify-content-center justify-content-lg-end align-items-lg-center">
                <a class="d-inline" href="index.html"><img class="img-fluid" src="../assets/images/logo-footer.png"
                    alt="LogSolutec" data-aos="" data-aos-delay="" /></a>
            </div>
        </div>
        <div class="row pt-4 pt-lg-5">
            <div class="col-lg-6 text-center text-lg-start">
                <ul class="p-0 m-0 list-inline">
                    <li class="list-inline-item"><a [routerLink]="['/term-condition']">Terms and Conditions </a></li>
                    <li class="list-inline-item"><a [routerLink]="['/privacy-policy']">Privacy Policy</a></li>
                </ul>
            </div>
            <div class="col-lg-6 text-center text-lg-end">
                <p>© 2021 Logsolutec. All rights reserved</p>
            </div>
        </div>
    </div>
</footer>