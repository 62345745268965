<section class="wrapper multi-step-form">
  <div class="container-fluid">
    <div class="row">
      <div
        class="col-md-4 col-xl-3 col-left pt-12 d-flex flex-column justify-content-center position-sticky top-0"
      >
        <div class="box-absolute d-flex">
          <app-sidebar></app-sidebar>
        </div>
        <div class="box mx-auto">
          <h3 class="text-center text-md-start">User & Roles</h3>
        </div>
      </div>

      <div class="col-md-8 col-xl-9 col-right px-start-end pb-6">
        <div
          class="topline pt-4 pt-lg-5 pb-3 pb-lg-4 d-flex align-items-center justify-content-between"
        >
          <ul *ngIf="breadcrumbBool" class="breadcrumb p-0 m-0">
            <li class="breadcrumb-item 3 brand" id="0">
              <button [routerLink]="['/v1/dashboard']">Dashboard</button>
            </li>
          </ul>
        </div>

        <div class="tab-content form-step" (click)="hideSideBar()">
          <form [formGroup]="roleForm">
            <h3 class="text-center">{{ title }}</h3>

            <div class="row mt-4">
              <div class="col-md-6">
                <label for="role_name">Role Name</label>
                <input
                  class="form-control"
                  id="role_name"
                  type="text"
                  placeholder="Example: Manager"
                  formControlName="authority"
                  [ngClass]="{'is-invalid': roleformcontroller.authority.invalid && (roleformcontroller.authority.touched || roleformcontroller.authority.dirty) }"
                />
                <div class="text-danger" *ngIf="roleformcontroller.authority.invalid && (roleformcontroller.authority.touched || roleformcontroller.authority.dirty)">
                  Authority is required
                </div>
              </div>
            </div>

            <!-- this is latest code -->
            <!-- adminPermissionList -->

            <div class="row mt-4 table-responsive content-justify-center" *ngIf="adminPermissionList.length > 0">
              <table
                class="table table-borderless table-footer-dark table-head-dark"
              >
                <thead>
                  <tr>
                    <th>Admin Permission Module</th>
                  </tr>
                </thead>
              </table>

              <div class="row">
                <div class="col-md-3" *ngFor="let admin of adminPermissionList">
                  <label id="admin.code" class="form-label">
                    <input
                      for="admin.code"
                      type="checkbox"
                      class="form-input-check"
                      [value]="admin.code"
                      (click)="onClick($event, admin.id)"
                      [title]="admin.label"
                      [checked]="admin.isChecked"
                    />
                    {{ admin.label }}
                  </label>
                </div>
              </div>
            </div>


            <!-- procurmentPermissionList -->

            <div class="row mt-4 table-responsive content-justify-center" *ngIf="procurmentPermissionList.length > 0">
              <table
                class="table table-borderless table-footer-dark table-head-dark"
              >
                <thead>
                  <tr>
                    <th>Procurement Permission Module</th>
                  </tr>
                </thead>
              </table>

              <div class="row">
                <div
                  class="col-md-3"
                  *ngFor="let procurment of procurmentPermissionList"
                >
                  <label id="procurment.code" class="form-label">
                    <input
                      for="procurment.code"
                      type="checkbox"
                      class="form-input-check"
                      [value]="procurment.code"
                      (click)="onClick($event, procurment.id)"
                      [title]="procurment.label"
                      [checked]="procurment.isChecked"
                    />
                    {{ procurment.label }}
                  </label>
                </div>
              </div>
            </div>

            <!-- auctionPermissionList -->

            <div class="row mt-4 table-responsive content-justify-center" *ngIf="auctionPermissionList.length > 0">
              <table
                class="table table-borderless table-footer-dark table-head-dark"
              >
                <thead>
                  <tr>
                    <th>Auction Permission Module</th>
                  </tr>
                </thead>
              </table>

              <div class="row">
                <div
                  class="col-md-3"
                  *ngFor="let auction of auctionPermissionList"
                >
                  <label id="auction.code" class="form-label">
                    <input
                      for="auction.code"
                      type="checkbox"
                      class="form-input-check"
                      [value]="auction.code"
                      (click)="onClick($event, auction.id)"
                      [title]="auction.label"
                      [checked]="auction.isChecked"
                    />
                    {{ auction.label }}
                  </label>
                </div>
              </div>
            </div>

            <!-- invoicePermissionList -->

            <div class="row mt-4 table-responsive content-justify-center" *ngIf="invoicePermissionList.length > 0">
              <table
                class="table table-borderless table-footer-dark table-head-dark"
              >
                <thead>
                  <tr>
                    <th>Invoice Permission Module</th>
                  </tr>
                </thead>
              </table>

              <div class="row">
                <div
                  class="col-md-3"
                  *ngFor="let invoice of invoicePermissionList"
                >
                  <label id="invoice.code" class="form-label">
                    <input
                      for="invoice.code"
                      type="checkbox"
                      class="form-input-check"
                      [value]="invoice.code"
                      (click)="onClick($event, invoice.id)"
                      [title]="invoice.label"
                      [checked]="invoice.isChecked"
                    />
                    {{ invoice.label }}
                  </label>
                </div>
              </div>
            </div>

            <!-- masterRecordPermissionList -->

            <div class="row mt-4 table-responsive content-justify-center" *ngIf="masterRecordPermissionList.length > 0">
              <table
                class="table table-borderless table-footer-dark table-head-dark"
              >
                <thead>
                  <tr>
                    <th>Master Record Permission Module</th>
                  </tr>
                </thead>
              </table>

              <div class="row">
                <div
                  class="col-md-3"
                  *ngFor="let master of masterRecordPermissionList"
                >
                  <label id="master.code" class="form-label">
                    <input
                      for="master.code"
                      type="checkbox"
                      class="form-input-check"
                      [value]="master.code"
                      (click)="onClick($event, master.id)"
                      [title]="master.label"
                      [checked]="master.isChecked"
                    />
                    {{ master.label }}
                  </label>
                </div>
              </div>
            </div>

            <!-- reportPermissionList -->
            <div class="row mt-4 table-responsive content-justify-center" *ngIf="reportPermissionList.length > 0">
              <table
                class="table table-borderless table-footer-dark table-head-dark"
              >
                <thead>
                  <tr>
                    <th>Reports Permission Module</th>
                  </tr>
                </thead>
              </table>

              <div class="row">
                <div
                  class="col-md-3"
                  *ngFor="let report of reportPermissionList"
                >
                  <label id="report.code" class="form-label">
                    <input
                      for="report.code"
                      type="checkbox"
                      class="form-input-check"
                      [value]="report.code"
                      (click)="onClick($event, report.id)"
                      [title]="report.label"
                      [checked]="report.isChecked"
                    />
                    {{ report.label }}
                  </label>
                </div>
              </div>
            </div>

            <!-- trackAndTracePermissionList -->

            <div class="row mt-4 table-responsive content-justify-center" *ngIf="trackAndTracePermissionList.length > 0">
              <table
                class="table table-borderless table-footer-dark table-head-dark"
              >
                <thead>
                  <tr>
                    <th>Track And Trace Permission Module</th>
                  </tr>
                </thead>
              </table>

              <div class="row">
                <div
                  class="col-md-3"
                  *ngFor="let trackAndTrace of trackAndTracePermissionList"
                >
                  <label id="trackAndTrace.code" class="form-label">
                    <input
                      for="trackAndTrace.code"
                      type="checkbox"
                      class="form-input-check"
                      [value]="trackAndTrace.code"
                      (click)="onClick($event, trackAndTrace.id)"
                      [title]="trackAndTrace.label"
                      [checked]="trackAndTrace.isChecked"
                    />
                    {{ trackAndTrace.label }}
                  </label>
                </div>
              </div>
            </div>

             <!-- userManagementPermissionList -->


             <div class="row mt-4 table-responsive content-justify-center" *ngIf="userManagementPermissionList.length > 0">
              <table
                class="table table-borderless table-footer-dark table-head-dark"
              >
                <thead>
                  <tr>
                    <th>User Management Permission Module</th>
                  </tr>
                </thead>
              </table>

              <div class="row">
                <div
                  class="col-md-3"
                  *ngFor="let user of userManagementPermissionList"
                >
                  <label id="user.code" class="form-label">
                    <input
                      for="user.code"
                      type="checkbox"
                      class="form-input-check"
                      [value]="user.code"
                      (click)="onClick($event, user.id)"
                      [title]="user.label"
                      [checked]="user.isChecked"
                    />
                    {{ user.label }}
                  </label>
                </div>
              </div>
            </div>

            <!-- serviceOrderPermissionList -->

            <div class="row mt-4 table-responsive content-justify-center" *ngIf="serviceOrderPermissionList.length > 0">
              <table
                class="table table-borderless table-footer-dark table-head-dark"
              >
                <thead>
                  <tr>
                    <th>Service Order Permission Module</th>
                  </tr>
                </thead>
              </table>

              <div class="row">
                <div
                  class="col-md-3"
                  *ngFor="let service of serviceOrderPermissionList"
                >
                  <label id="service.code" class="form-label">
                    <input
                      for="service.code"
                      type="checkbox"
                      class="form-input-check"
                      [value]="service.code"
                      (click)="onClick($event, service.id)"
                      [title]="service.label"
                      [checked]="service.isChecked"
                    />
                    {{ service.label }}
                  </label>
                </div>
              </div>
            </div>

            <!-- configurationPermissionList -->

            <div class="row mt-4 table-responsive content-justify-center" *ngIf="configurationPermissionList.length > 0">
              <table
                class="table table-borderless table-footer-dark table-head-dark"
              >
                <thead>
                  <tr>
                    <th>Configuration Permission Module</th>
                  </tr>
                </thead>
              </table>

              <div class="row">
                <div
                  class="col-md-3"
                  *ngFor="let configuration of configurationPermissionList"
                >
                  <label id="configuration.code" class="form-label">
                    <input
                      for="configuration.code"
                      type="checkbox"
                      class="form-input-check"
                      [value]="configuration.code"
                      (click)="onClick($event, configuration.id)"
                      [title]="configuration.label"
                      [checked]="configuration.isChecked"
                    />
                    {{ configuration.label }}
                  </label>
                </div>
              </div>
            </div>

            <!-- subscriptionPermissionList -->

            <div class="row mt-4 table-responsive content-justify-center" *ngIf="subscriptionPermissionList.length > 0">
              <table
                class="table table-borderless table-footer-dark table-head-dark"
              >
                <thead>
                  <tr>
                    <th>Subscription Permission Module</th>
                  </tr>
                </thead>
              </table>

              <div class="row">
                <div
                  class="col-md-3"
                  *ngFor="let subscription of subscriptionPermissionList"
                >
                  <label id="subscription.code" class="form-label">
                    <input
                      for="subscription.code"
                      type="checkbox"
                      class="form-input-check"
                      [value]="subscription.code"
                      (click)="onClick($event, subscription.id)"
                      [title]="subscription.label"
                      [checked]="subscription.isChecked"
                    />
                    {{ subscription.label }}
                  </label>
                </div>
              </div>
            </div>

            <!-- dashboardPermissionList -->

            <div class="row mt-4 table-responsive content-justify-center" *ngIf="dashboardPermissionList.length > 0">
              <table
                class="table table-borderless table-footer-dark table-head-dark"
              >
                <thead>
                  <tr>
                    <th>Dashboard Permission Module</th>
                  </tr>
                </thead>
              </table>

              <div class="row">
                <div
                  class="col-md-3"
                  *ngFor="let dashboard of dashboardPermissionList"
                >
                  <label id="dashboard.code" class="form-label">
                    <input
                      for="dashboard.code"
                      type="checkbox"
                      class="form-input-check"
                      [value]="dashboard.code"
                      (click)="onClick($event, dashboard.id)"
                      [title]="dashboard.label"
                      [checked]="dashboard.isChecked"
                    />
                    {{ dashboard.label }}
                  </label>
                </div>
              </div>
            </div>

          </form>

          <div class="text-center text-black">
            <button class="btn btn-success" *ngIf="!isEdit" (click)="createRole()">Create Role</button>
            <button class="btn btn-success" *ngIf="isEdit" (click)="updateRole()">Update Role</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
