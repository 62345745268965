<h1 mat-dialog-title><strong>{{action}}</strong></h1>
<div mat-dialog-content>
    <form *ngIf="action != 'Delete'; else elseTemplate" [formGroup]="countryForm">
        <mat-form-field>
            <mat-label>Country</mat-label>
            <input placeholder="Enter Country" required aria-pressed="false" matInput formControlName="name">
        </mat-form-field>

        <mat-form-field formGroupName="currency">
            <mat-label>Currency</mat-label>
            <mat-select formControlName="id" required>
                <mat-option *ngFor="let cur of currencyList" [value]="cur.id">
                    {{ cur.currency }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>
    <ng-template #elseTemplate>
        Sure to delete <b>{{ local_data.name }}</b>?
    </ng-template>
</div>

<div mat-dialog-actions>
    <button style="margin-right: 20px;" class="btn-primary" mat-button (click)="doAction()">{{action}}</button>
    <button class="btn-danger" mat-button (click)="closeDialog()" mat-flat-button color="warn">Cancel</button>
</div>