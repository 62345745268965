import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PreLoadingServiceService {

  private preLoading = new BehaviorSubject<boolean>(false);
  public readonly loading$ = this.preLoading.asObservable();

  constructor() { }

  show(){
    this.preLoading.next(true);
  }

  hide(){
    this.preLoading.next(false);
  }
}
