import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionExtensionService {

  AUTH_API: string = `https://${window.location.hostname}:9003/api/v1`;

  constructor(private http: HttpClient) { }

  //ADMIN
  //post buy subscription
  buySubscription(data: any): Observable<any> {
    return this.http.post(`${this.AUTH_API}/subscription`, data);
  }

  //get all subscription
  getAllSubscription(page , size): Observable<any> {
    const params = new HttpParams().set('page', page).set('size', size);
    return this.http.get(`${this.AUTH_API}/subscriptions`, {params: params});
  }

  //get subscription details by id
  getSubscriptionDetailsById(id: number): Observable<any> {
    return this.http.get(`${this.AUTH_API}/subscription/${id}`);
  }

  //update subscription details by id
  updateSubscriptionById(id: number, data: any): Observable<any> {
    return this.http.put(`${this.AUTH_API}/subscription/${id}`, data);
  }

  //delete subscription details
  deleteSubscriptionById(id: number): Observable<any> {
    return  this.http.delete(`${this.AUTH_API}/subscription/${id}`);
  }
}
