import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import Swal from "sweetalert2";
import { AuthServiceService } from "./auth-service.service";
import { PreLoadingServiceService } from "./pre-loading-service.service";
import { finalize } from "rxjs/operators"
import { SocketService } from "./socket.service";


@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private authService: AuthServiceService, private router: Router, private loadingService: PreLoadingServiceService,
    private socketService: SocketService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token = this.authService.getToken();
    const userData = JSON.parse(sessionStorage.getItem('userData'));
    if (token && this.authService.isAuthenticated() && userData != null) {
      this.loadingService.show();
      const cloned = req.clone({
        headers: req.headers.set("Authorization", "Bearer " + token).set('sid', userData.sId)
      });
      if(cloned.url.includes("/v1/masterData/ports/")){
        console.log('this is get ports API');
        this.loadingService.hide();
      } else {
        this.loadingService.show();
      }
      return next.handle(cloned).pipe(
        finalize(() => {
          this.loadingService.hide();
        }),
        catchError(err => this.handleAuthError(err)));
    }
    else {
      this.loadingService.show();
      return next.handle(req).pipe(
        finalize(() => {
          this.loadingService.hide();
        }),
        catchError(err =>
          this.handleAuthError(err))
      );
    }
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    console.log(err);
    // handle your auth error or rethrow
    if (err.status === 401) {
      //navigate
      this.router.navigate(['/home']);
      this.socketService.stopSSEService();
      this.authService.signOut();
      sessionStorage.clear();
      return of(err.message);
    } else if(err.status === 403){
      Swal.fire({
        titleText: "Warning",
        timer: 5000,
        text: "Logged Out due to session expiry",
        icon: "warning"
      })
      this.router.navigate(['/sign-in']);
      this.socketService.stopSSEService();
      this.authService.signOut();
      sessionStorage.clear();
    }
    else if (err.status === 0) {
      Swal.fire({
        titleText: "Warning",
        timer: 5000,
        text: "Logged Out due to session expiry",
        icon: "warning"
      })
      this.router.navigate(['/sign-in']);
      this.socketService.stopSSEService();
      this.authService.signOut();
      sessionStorage.clear();
    }
    return throwError(err);
  }

}

