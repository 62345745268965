import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ServiceOrderService } from '../helpers/service-order.service';
import { SocketService } from '../helpers/socket.service';

@Component({
  selector: 'app-service-order-list',
  templateUrl: './service-order-list.component.html',
  styleUrls: ['./service-order-list.component.css']
})

export class ServiceOrderListComponent implements OnInit {

  dataSource!: MatTableDataSource<any>;
  displayColumns: string[] = ["number", "date", "forwaderName", "quoteNumber", "auctionNumber", "actions"];
  objBool:Boolean=false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  title = "Service Order Listing";
  page: number = 0;
  size: number = 25;
  length: number = 0;

  constructor(private apiService: ServiceOrderService, private router: Router, private socketService: SocketService) { }
  ngOnInit(): void {
    this.getAllServiceOrder();

    // this.socketService.connectTOServerPopUpFuction();
  }

  getAllServiceOrder() {
    this.apiService.getAllServiceOrderPageable(this.page, this.size).subscribe(
      (response) => {
        let content: Array<any> = response.content;
        if(content.length > 0) {
          this.objBool=true;
          this.dataSource = new MatTableDataSource(content);
          this.dataSource.sort = this.sort;
          this.length = response.totalElements
        }
      },
      (error) => {
        this.objBool=false;
      }
    );
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  editServiceOrder(id: any) {
    this.router.navigate(["v1/client-service-order/edit", id]);
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  onPageChange(event: PageEvent){
    this.page = event.pageSize;
    this.size = event.pageSize;
    this.getAllServiceOrder();
  }

  downloadServiceOrder(id){
    this.apiService.getServiceOrderPDF(id).subscribe(
      (response) => {
        console.log(response);
        var file = new Blob([response], { type: 'application/pdf' });
        console.log(file)
        var fileURL = URL.createObjectURL(file);
        var a = document.createElement('a');
        a.href = fileURL;
        a.target = '_blank';
        a.download = `ServiceOrder-${id}.pdf`
        document.body.appendChild(a);
        a.click();
      },
      (error) => {
        console.log(error);
      },
    )
  }
}
