<section class="banner text-center text-white d-flex" id="bnner">
    <div class="container position-relative">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <h1>Auction Comparison</h1>
        </div>
      </div>
    </div>
  </section>
  <aside class="sidebar"> 
    <div class="sidebar-inner">
      <button class="btn-closesidebar" type="button">
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M29.4281 29.4281L10.5719 10.5719" stroke="white" stroke-width="2" stroke-linecap="round"></path>
          <path d="M29.4281 10.5719L10.5719 29.4281" stroke="white" stroke-width="2" stroke-linecap="round"></path>
        </svg>
      </button>
      <ul class="nav flex-column"> 
        <li class="nav-item"> <a class="nav-link" href="dashboard.html">Dashboard</a>
        </li>
        <li class="nav-item nav-iten-has-children"> <a class="nav-link" href="#vendor" data-bs-toggle="collapse">Vendor<span class="icon-arrow"><i class="fa fa-angle-down"> </i></span></a>
          <div class="collapse nav-child" id="vendor">
            <ul class="nav flex-column"> 
              <li class="nav-item"> <a class="nav-link" href="vendor-company-details.html">Company Details</a></li>
              <li class="nav-item"> <a class="nav-link" href="vendor-company-overview.thml">Company Overview</a></li>
              <li class="nav-item"> <a class="nav-link" href="vendor-document-uploadation.html">Document Uploadation</a></li>
              <li class="nav-item"> <a class="nav-link" href="vendor-certification.html">Certification</a></li>
            </ul>
          </div>
        </li>
        <li class="nav-item nav-iten-has-children"> <a class="nav-link" href="#rfq" data-bs-toggle="collapse">RFQ<span class="icon-arrow"><i class="fa fa-angle-down"> </i></span></a>
          <div class="collapse nav-child" id="rfq">
            <ul class="nav flex-column"> 
              <li class="nav-item"> <a class="nav-link" href="rfq-creation-shipment.html">Budgetory Enquiry List</a></li>
              <li class="nav-item"> <a class="nav-link" href="vendor-company-overview.thml">Company Overview</a></li>
              <li class="nav-item"> <a class="nav-link" href="vendor-document-uploadation.html">Document Uploadation</a></li>
              <li class="nav-item"> <a class="nav-link" href="vendor-certification.html">Certification</a></li>
            </ul>
          </div>
        </li>
        <li class="nav-item nav-iten-has-children"> <a class="nav-link" href="#auction" data-bs-toggle="collapse">Auction<span class="icon-arrow"><i class="fa fa-angle-down"> </i></span></a>
          <div class="collapse nav-child" id="auction">
            <ul class="nav flex-column"> 
              <li class="nav-item"> <a class="nav-link" href="auction-configuration.html">Auction Configuration</a></li>
              <li class="nav-item"> <a class="nav-link" href="auction-details.thml">Auction Details</a></li>
              <li class="nav-item"> <a class="nav-link" href="auction-invite.html">Auction Invite</a></li>
              <li class="nav-item"> <a class="nav-link" href="auction-terms-conditions.html">Auction Terms &amp; Conditions</a></li>
            </ul>
          </div>
        </li>
        <li class="nav-item nav-iten-has-children"> <a class="nav-link" href="#service-order" data-bs-toggle="collapse">Service Order<span class="icon-arrow"><i class="fa fa-angle-down"> </i></span></a>
          <div class="collapse nav-child" id="service-order">
            <ul class="nav flex-column"> 
              <li class="nav-item"> <a class="nav-link" href="vendor-company-details.html">Company Details</a></li>
              <li class="nav-item"> <a class="nav-link" href="vendor-company-overview.thml">Company Overview</a></li>
              <li class="nav-item"> <a class="nav-link" href="vendor-document-uploadation.html">Document Uploadation</a></li>
              <li class="nav-item"> <a class="nav-link" href="vendor-certification.html">Certification</a></li>
            </ul>
          </div>
        </li>
        <li class="nav-item nav-iten-has-children"> <a class="nav-link" href="#invoice" data-bs-toggle="collapse">Invoice<span class="icon-arrow"><i class="fa fa-angle-down"> </i></span></a>
          <div class="collapse nav-child" id="invoice">
            <ul class="nav flex-column"> 
              <li class="nav-item"> <a class="nav-link" href="vendor-company-details.html">Company Details</a></li>
              <li class="nav-item"> <a class="nav-link" href="vendor-company-overview.thml">Company Overview</a></li>
              <li class="nav-item"> <a class="nav-link" href="vendor-document-uploadation.html">Document Uploadation</a></li>
              <li class="nav-item"> <a class="nav-link" href="vendor-certification.html">Certification</a></li>
            </ul>
          </div>
        </li>
        <li class="nav-item nav-iten-has-children"> <a class="nav-link" href="#track-&amp;-trace" data-bs-toggle="collapse">Track &amp; Trace<span class="icon-arrow"><i class="fa fa-angle-down"> </i></span></a>
          <div class="collapse nav-child" id="track-&amp;-trace">
            <ul class="nav flex-column"> 
              <li class="nav-item"> <a class="nav-link" href="vendor-company-details.html">Company Details</a></li>
              <li class="nav-item"> <a class="nav-link" href="vendor-company-overview.thml">Company Overview</a></li>
              <li class="nav-item"> <a class="nav-link" href="vendor-document-uploadation.html">Document Uploadation</a></li>
              <li class="nav-item"> <a class="nav-link" href="vendor-certification.html">Certification</a></li>
            </ul>
          </div>
        </li>
        <li class="nav-item"> <a class="nav-link" href="#reports">Reports</a>
        </li>
        <li class="nav-item nav-iten-has-children"> <a class="nav-link" href="#user-management" data-bs-toggle="collapse">User Management<span class="icon-arrow"><i class="fa fa-angle-down"> </i></span></a>
          <div class="collapse nav-child" id="user-management">
            <ul class="nav flex-column"> 
              <li class="nav-item"> <a class="nav-link" href="vendor-company-details.html">Company Details</a></li>
              <li class="nav-item"> <a class="nav-link" href="vendor-company-overview.thml">Company Overview</a></li>
              <li class="nav-item"> <a class="nav-link" href="vendor-document-uploadation.html">Document Uploadation</a></li>
              <li class="nav-item"> <a class="nav-link" href="vendor-certification.html">Certification</a></li>
            </ul>
          </div>
        </li>
        <li class="nav-item nav-iten-has-children"> <a class="nav-link" href="#configuration" data-bs-toggle="collapse">Configuration<span class="icon-arrow"><i class="fa fa-angle-down"> </i></span></a>
          <div class="collapse nav-child" id="configuration">
            <ul class="nav flex-column"> 
              <li class="nav-item"> <a class="nav-link" href="vendor-company-details.html">Company Details</a></li>
              <li class="nav-item"> <a class="nav-link" href="vendor-company-overview.thml">Company Overview</a></li>
              <li class="nav-item"> <a class="nav-link" href="vendor-document-uploadation.html">Document Uploadation</a></li>
              <li class="nav-item"> <a class="nav-link" href="vendor-certification.html">Certification</a></li>
            </ul>
          </div>
        </li>
        <li class="nav-item nav-iten-has-children"> <a class="nav-link" href="#admin" data-bs-toggle="collapse">Admin<span class="icon-arrow"><i class="fa fa-angle-down"> </i></span></a>
          <div class="collapse nav-child" id="admin">
            <ul class="nav flex-column"> 
              <li class="nav-item"> <a class="nav-link" href="vendor-company-details.html">Company Details</a></li>
              <li class="nav-item"> <a class="nav-link" href="vendor-company-overview.thml">Company Overview</a></li>
              <li class="nav-item"> <a class="nav-link" href="vendor-document-uploadation.html">Document Uploadation</a></li>
              <li class="nav-item"> <a class="nav-link" href="vendor-certification.html">Certification</a></li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
    <div class="position-absolute start-0 end-0 bottom-0 py-3 px-start-end d-flex align-items-center sidebar-user"> 
      <div class="user-dp"><img class="img-fluid  " src="assets/images/user-dp.png" alt="User Name"/>
      </div>
      <div class="user-name ps-3"> 
        <h4>User Name</h4>
      </div>
    </div>
  </aside>
  <!--request start-->
  <section class="request-demo-wrapper pb-10" (click)="hideSideBar()">
    <div class="container shadow bg-white">
      <div class="row">
        <div class="col-lg-12">
          <div class="box">
            <form [formGroup]="auctionClientComparison">
              <div class="row"> 
                <div class="col-md-4 text-center form-group form-group-inline">
                  <label class="form-label" for="auction-number-:">Auction Number :</label>
                  <input class="form-control" value="AUC 2021/02-001" type="text" id="auction-number-:" readonly="readonly"/>
                </div>
                <div class="col-md-4 text-center form-group form-group-inline">
                  <label class="form-label" for="auction-date-&amp;-time-:">Auction Date &amp; Time :</label>
                  <input class="form-control" value="AUC 2021/02-001" type="text" id="auction-date-&amp;-time-:" readonly="readonly"/>
                </div>
                <div class="col-md-4 text-center form-group form-group-inline">
                  <label class="form-label" for="currency-:">Currency :</label>
                  <input class="form-control" value="USD" type="text" id="currency-:" readonly="readonly"/>
                </div>
                <div class="col-md-12 text-center form-group form-group-inline">
                  <label class="form-label" for="auction-description-:">Auction Title :</label>
                  <input class="form-control" value="Sea freight services for 3000 kgs from JNPT port ,Mumbai to Jebel Ali port,Dubai,UAE" type="text" id="auction-description-:" readonly="readonly"/>
                </div>
              </div>
              <div class="table-responsive"> 
                <table class="table table-borderless table-striped table-head-dark"> 
                  <thead> 
                    <tr> 
                      <th>
                        <input class="form-check-input" type="checkbox" id="" name="option1"/>
                      </th>
                      <th>Forwarder Name
                      </th>
                      <th>Auction Value
                      </th>
                      <th>Rank
                      </th>
                      <th>Conducted By
                      </th>
                      <th>Rank
                      </th>
                    </tr>
                  </thead>
                  <tbody> 
                    <tr> 
                      <td> 
                        <input class="form-check-input" type="checkbox" id="" name="option1"/>
                      </td>
                      <td> 
                        <input class="form-control-plaintext" type="text" value="Vendor 1" id="" readonly="readonly"/>
                      </td>
                      <td> 
                        <input class="form-control-plaintext" type="text" value="25000" id="" readonly="readonly"/>
                      </td>
                      <td> 
                        <input class="form-control-plaintext" type="text" value="L1" id="" readonly="readonly"/>
                      </td>
                      <td> 
                        <input class="form-control-plaintext" type="text" value="XYZ" id="" readonly="readonly"/>
                      </td>
                      <td> 
                        <button class="font-weight-bold text-success" type="button"><span>View</span>
                        </button>
                      </td>
                    </tr>
                    <tr> 
                      <td> 
                        <input class="form-check-input" type="checkbox" id="" name="option1"/>
                      </td>
                      <td> 
                        <input class="form-control-plaintext" type="text" value="Vendor 1" id="" readonly="readonly"/>
                      </td>
                      <td> 
                        <input class="form-control-plaintext" type="text" value="25000" id="" readonly="readonly"/>
                      </td>
                      <td> 
                        <input class="form-control-plaintext" type="text" value="L1" id="" readonly="readonly"/>
                      </td>
                      <td> 
                        <input class="form-control-plaintext" type="text" value="XYZ" id="" readonly="readonly"/>
                      </td>
                      <td> 
                        <button class="font-weight-bold text-success" type="button"><span>View</span>
                        </button>
                      </td>
                    </tr>
                    <tr> 
                      <td> 
                        <input class="form-check-input" type="checkbox" id="" name="option1"/>
                      </td>
                      <td> 
                        <input class="form-control-plaintext" type="text" value="Vendor 1" id="" readonly="readonly"/>
                      </td>
                      <td> 
                        <input class="form-control-plaintext" type="text" value="25000" id="" readonly="readonly"/>
                      </td>
                      <td> 
                        <input class="form-control-plaintext" type="text" value="L1" id="" readonly="readonly"/>
                      </td>
                      <td> 
                        <input class="form-control-plaintext" type="text" value="XYZ" id="" readonly="readonly"/>
                      </td>
                      <td> 
                        <button class="font-weight-bold text-success" type="button"><span>View</span>
                        </button>
                      </td>
                    </tr>
                    <tr> 
                      <td> 
                        <input class="form-check-input" type="checkbox" id="" name="option1"/>
                      </td>
                      <td> 
                        <input class="form-control-plaintext" type="text" value="Vendor 1" id="" readonly="readonly"/>
                      </td>
                      <td> 
                        <input class="form-control-plaintext" type="text" value="25000" id="" readonly="readonly"/>
                      </td>
                      <td> 
                        <input class="form-control-plaintext" type="text" value="L1" id="" readonly="readonly"/>
                      </td>
                      <td> 
                        <input class="form-control-plaintext" type="text" value="XYZ" id="" readonly="readonly"/>
                      </td>
                      <td> 
                        <button class="font-weight-bold text-success" type="button"><span>View</span>
                        </button>
                      </td>
                    </tr>
                    <tr> 
                      <td> 
                        <input class="form-check-input" type="checkbox" id="" name="option1"/>
                      </td>
                      <td> 
                        <input class="form-control-plaintext" type="text" value="Vendor 1" id="" readonly="readonly"/>
                      </td>
                      <td> 
                        <input class="form-control-plaintext" type="text" value="25000" id="" readonly="readonly"/>
                      </td>
                      <td> 
                        <input class="form-control-plaintext" type="text" value="L1" id="" readonly="readonly"/>
                      </td>
                      <td> 
                        <input class="form-control-plaintext" type="text" value="XYZ" id="" readonly="readonly"/>
                      </td>
                      <td> 
                        <button class="font-weight-bold text-success" type="button"><span>View</span>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row justify-content-center"> 
                <div class="col-md-6 form-group pt-lg-3">
                  <button class="btn text-dark small btn-outline-success px-3" type="button">CREATE SERVICE ORDER</button>
                </div>
                <div class="col-md-6 form-group pt-lg-3">
                  <button class="btn  small btn-success px-3" type="button">COMPARE AUCTION</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>