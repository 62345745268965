import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';



@Injectable({
    providedIn: 'root'
})
export class ClientService {

    AUTH_API = `https://${window.location.hostname}:9005/api/v1/trackAndTrace`;

    constructor(private http: HttpClient) { }

    getLocation(eventValue: any): Observable<any> {
        let param = {
            'locationKeyword': eventValue
        }
        let httpOptions = {
            headers: { 'Content-Type': 'application/json' },
            params: param
        };
        return this.http.get(`${this.AUTH_API}/vessel/location`, httpOptions);
    }

    getCarrier(): Observable<any> {
        return this.http.get(`${this.AUTH_API}/vessel/carrier`);
    }

    getDetailsOfSailing(porIDVal, fndIDVal): Observable<any> {
        let param = {
            'fndID': fndIDVal,
            'porID': porIDVal,
            'searchDuration': "1"
        }
        return this.http.get(`${this.AUTH_API}/vessel/sailingSchedule`, {params: param});
    }

    getLocationForFlightRoute(key, departureIcao) {
        let param = {
            'key': key,
            'departureIcao': departureIcao
        }
        let httpOptions = {
            headers: { 'Content-Type': 'application/json' },
            params: param
        };
        return this.http.get(`${this.AUTH_API}/flightRoute`, httpOptions);

    }

    getDetailsOfFlightSchedule(iataCode, type) {
        let param = {
            'iataCode': iataCode,
            'type': type
        }
        let httpOptions = {
            headers: { 'Content-Type': 'application/json' },
            params: param
        };
        return this.http.post(`${this.AUTH_API}/flightSchedule`, httpOptions);
    }

    getDetailofContainer(containerNo): Observable<any> {
        let param = new HttpParams().set('requestId', containerNo).set('mapPoint', 'true')
        return this.http.get(`${this.AUTH_API}/containerTracking`, {params: param, headers: { 'Content-Type': 'application/json' }});
    }

    // ToDo, remanining.
    getFlightLocation(): Observable<any> {
        return this.http.get('http://localhost:9004/api/v1/masterData/ports');
    }
}
