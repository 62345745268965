import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DemoService {

  AUTH_API: string = `https://${window.location.hostname}:9002/api/v1`
  constructor(private http: HttpClient) { }

  requestDemo(values: any): Observable<any> {
    return this.http.post(`${this.AUTH_API}/demo`, values);
  }

  contactUs(data: any): Observable<any> {
    return this.http.post(`${this.AUTH_API}/contactus`, data);
  }

  getAllContactUs(page ,size): Observable<any> {
    const params = new HttpParams().set('page', page).set('size', size);
    return this.http.get(`${this.AUTH_API}/contactus`, {params: params});
  }

  fetchContactUsById(id: any): Observable<any> {
    return this.http.get(`${this.AUTH_API}/contactus/${id}`);
  }

  getAllRequestDemo(page, size):Observable<any>{
    const params = new HttpParams().set('page', page).set('size', size);
    return this.http.get(`${this.AUTH_API}/demo`, {params: params});
  }

  fetchRequestByDemoById(id: any): Observable<any> {
    return this.http.get(`${this.AUTH_API}/demo/${id}`);
  }

  postFeedback(data: any):Observable<any>{
    return this.http.post(`${this.AUTH_API}/feedback`, data);
  }

  fetchFeedbackById(id: number):Observable<any>{
    return this.http.get(`${this.AUTH_API}/feedbac{kbyid/${id}`)
  }

  getAllFeedbacks(page, size):Observable<any>{
    let param = new HttpParams().set('page', page).set('size', size)
    return this.http.get(`${this.AUTH_API}/all-feedbacks`, {params: param});
  }

  postCareer(data:any):Observable<any>{
    return this.http.post(`${this.AUTH_API}/career`, data);
  }

  getAllCareers(page, size):Observable<any>{
    let param = new HttpParams().set('page', page).set('size', size)
    return this.http.get(`${this.AUTH_API}/all-careers`, {params: param});
  }

  getCareerById(id: number):Observable<any>{
    return this.http.get(`${this.AUTH_API}/careerbyid/${id}`);
  }

}
