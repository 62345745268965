<!--header start-->
<header class="box-absolute d-flex align-items-center topline text-white">
    <app-sidebar></app-sidebar>
    <div class="page-title-center mt-3">
      <div class="text-center">
        <h1>{{ title }}</h1>
      </div>
    </div>
    <div class="breadcrumb-div mt-3">
        <ul class="breadcrumb p-0 m-0">
            <li class="breadcrumb-item 3" id="0"><a [routerLink]="['/v1/dashboard']">Dashboard</a></li>
            <li class="breadcrumb-item 3" id="1"><a>Listing</a></li>
            <li class="breadcrumb-item"><a>{{ title }}</a></li>
        </ul>
    </div>
</header>
<!--header end-->
<section class="banner text-center text-white d-flex" id="bnner">
    <div class="container position-relative">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <!-- <h1>Client Parameters</h1> -->
            </div>
        </div>
    </div>
</section>
<!--request start-->
<section class="request-demo-wrapper pb-10">
    <div class="container shadow bg-white">
        <div class="row">
            <div class="col-lg-12">
                <div class="box">
                    <form>
                        <div class="btn-group d-flex gap-2 pb-3 pb-lg-4 px-3 px-lg-4">
                            <div class="input-group">
                                <input class="form-control rounded-3 px-4" type="text" placeholder=" Search" matInput
                                    (keyup)="applyFilter($event)" #input />
                                <button class="input-group-btn end-0" type="button"><i class="fa fa-search"></i>
                                </button>
                            </div>
                        </div>
                        <div class="mat-elevation-z8 table-responsive">
                            <table class="table table-borderless table-striped table-footer-dark table-head-dark" mat-table [dataSource]="dataSource"
                                matSort>

                                <!-- Org name Column -->
                                <ng-container matColumnDef="orgName">
                                    <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header> Org Name </th>
                                    <td mat-cell *matCellDef="let row"> {{row.org.name}} </td>
                                </ng-container>

                                <!-- hardExpiryDurationInDays Column -->
                                <ng-container matColumnDef="hardExpiryDurationInDays">
                                    <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header> Hard
                                        Expiry Duration In Days </th>
                                    <td mat-cell *matCellDef="let row"> {{row.hardExpiryDurationInDays}} </td>
                                </ng-container>

                                <!-- lockAfterMaxUnsuccuessfulAttempt Column -->
                                <ng-container matColumnDef="lockAfterMaxUnsuccuessfulAttempt">
                                    <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header> Lock
                                        After Max Unsuccuessful Attempt </th>
                                    <td mat-cell *matCellDef="let row"> {{row.lockAfterMaxUnsuccessfulAttempts }} </td>
                                </ng-container>

                                <!-- maxBidTimeExtensions Column -->
                                <ng-container matColumnDef="maxBidTimeExtensions">
                                    <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header> Max Bid
                                        Time Extensions </th>
                                    <td mat-cell *matCellDef="let row"> {{row.maxBidTimeExtensions}} </td>
                                </ng-container>

                                <!-- maxExtensionAllowed Column -->
                                <ng-container matColumnDef="maxExtensionAllowed">
                                    <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header> Max
                                        Extension Allowed </th>
                                    <td mat-cell *matCellDef="let row"> {{row.maxExtensionsAllowed}} </td>
                                </ng-container>

                                <!-- maxUnsuccessfulAttempts Column -->
                                <ng-container matColumnDef="maxUnsuccessfulAttempts">
                                    <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header> Max
                                        Unsuccessful Attempts </th>
                                    <td mat-cell *matCellDef="let row"> {{row.maxUnsuccessfulAttempts}} </td>
                                </ng-container>

                                <!-- otpExpiresInMinutes Column -->
                                <ng-container matColumnDef="otpExpiresInMinutes">
                                    <th class="text-white" mat-header-cell *matHeaderCellDef mat-sort-header> OTP
                                        Expires In Minutes </th>
                                    <td mat-cell *matCellDef="let row"> {{row.otpExpiryInMinutes}} </td>
                                </ng-container>

                                <!-- passwordExpiryDays Column -->
                                <ng-container matColumnDef="passwordExpiryDays">
                                    <th class="text-white" mat-header-cell *matHeaderCellDef mat-sort-header> Password
                                        Expiry Days </th>
                                    <td mat-cell *matCellDef="let row"> {{row.passwordExpiryDays}} </td>
                                </ng-container>

                                <!-- rfqDueReminersBeforeDueDate Column -->
                                <ng-container matColumnDef="rfqDueReminersBeforeDueDate">
                                    <th class="text-white" mat-header-cell *matHeaderCellDef mat-sort-header> RFQ Due
                                        Reminers Before Due Date </th>
                                    <td mat-cell *matCellDef="let row"> {{row.rfqDueReminersBeforeDueDate}} </td>
                                </ng-container>

                                <!-- softExpiryDurationInDays Column -->
                                <ng-container matColumnDef="softExpiryDurationInDays">
                                    <th class="text-white" mat-header-cell *matHeaderCellDef mat-sort-header> Soft
                                        Expiry Duration In Days </th>
                                    <td mat-cell *matCellDef="let row"> {{row.softExpiryDurationInDays}} </td>
                                </ng-container>

                                <tr class="bg-dark" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                                <!-- Row shown when there is no matching data. -->
                                <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell text-center text-danger" colspan="3">
                                        <div *ngIf="serchFilter">
                                            No data matching the filter "{{ input.value }}"
                                        </div>
                                        <div *ngIf="nodata">
                                            <h5>Data is Not Available</h5>
                                        </div>
                                    </td>
                                </tr>
                            </table>

                            <mat-paginator [pageSizeOptions]="[5, 10, 25]" [length]="clientLength"
                                showFirstLastButtons (page)="onPageChange($event)">
                            </mat-paginator>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>