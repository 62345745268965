import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { OnboardService } from '../helpers/onboard.service';
import { SocketService } from '../helpers/socket.service';
import { ClientViewComponent } from './client-view/client-view.component';

@Component({
  selector: 'app-client-listing',
  templateUrl: './client-listing.component.html',
  styleUrls: ['./client-listing.component.css'],
})
export class ClientListingComponent implements OnInit {
  page: number = 0;
  size: number = 25;
  clientLength: number;
  serchFilter: Boolean = false;
  nodata: Boolean = true;

  displayedColumns: string[] = [
    'name',
    'website',
    'pocEmailId',
    'createDate',
    'onboardingStatus',
    'actions',
  ];
  dataSource = new MatTableDataSource();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private clientService: OnboardService,
    private socketService: SocketService,
    private router: Router,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getAllClients();
    // this.socketService.connectTOServerPopUpFuction();
  }

  getAllClients() {
    this.clientService.getAllclientListing(this.page, this.size).subscribe(
      (response) => {
        if (response != null) {
          this.clientLength = response.totalElements;
          this.dataSource = new MatTableDataSource(response.content);
          this.dataSource.sort = this.sort;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.serchFilter = true;
    this.nodata = false;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onPageChange(event: PageEvent) {
    this.size = event.pageSize;
    this.page = event.pageIndex;
    this.getAllClients();
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove('sidebar-active');
  }

  routeToView(data) {
    this.matDialog.open(ClientViewComponent, {
      height: '100%',
      width: '100%',
      data: data,
    });
  }

  deleteClient(orgId: any, clientName: string) {
    Swal.fire({
      title: 'Delete',
      html: 'Are you sure you want to delete ' + `<br><b>${clientName}</b>`,
      icon: 'question',
      showCancelButton: true,
      showCloseButton: true,
    }).then((res) => {
      if (res.isConfirmed == true) {
        console.log('this is swal response ', res);
        this.clientService.deleteClientOrVendor(orgId).subscribe({
          next: (res) => {
            console.log('this is response ', res);
            Swal.fire({
              title: 'Success',
              text: res.message,
              timer: 5000,
              icon: 'success',
            });
            this.getAllClients();
          },
          error: (error) => {
            console.log('this is error ', error);
          },
        });
      }
    });
  }
}
