<app-header></app-header>
<section class="banner text-center text-white d-flex align-items-center banner-full"
    style="background-image: url(assets/images/banner.jpg)">
    <div class="container position-relative">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <div class="heading-dual">
                    <h1>LogSolutec</h1>
                    <h2>Careers</h2>
                </div>
                <!-- <p class="text-center">We would love to join hands with you and exceed your expectations! Connect today!
                </p> -->
            </div>
        </div>
    </div>
</section>
<section class="contact-wrapper text-white py-3 py-lg-4" id="contact-us">
    <div class="container">
        <div class="row justify-content-between align-items-center">
            <div class="col-lg-5">
            </div>

            <div class="col-lg-6">
                <div class="contact-box p-2 p-sm-3 mt-4 mt-lg-0">
                    <h2 class="text-dark">Get In Touch</h2>
                    <form [formGroup]="careerForm">
                        <div class="row">
                            <div class="col-sm-6 form-group">
                                <label for="name">First Name *</label>
                                <input class="form-control" type="text" placeholder="Enter First Name"
                                    formControlName="firstName"
                                    [ngClass]="{'is-invalid': career.firstName.invalid && ( career.firstName.dirty || career.firstName.touched )}">
                                <div class="text-danger validation-message"
                                    *ngIf="career.firstName.invalid && ( career.firstName.dirty || career.firstName.touched )">
                                    First Name Is Mandatory
                                </div>
                            </div>
                            <div class="col-sm-6 form-group">
                                <label for="name">Last Name *</label>
                                <input class="form-control" type="text" placeholder="Enter Last Name"
                                    formControlName="lastName"
                                    [ngClass]="{'is-invalid': career.lastName.invalid && ( career.lastName.dirty || career.lastName.touched )}">
                                <div class="text-danger validation-message"
                                    *ngIf="career.lastName.invalid && ( career.lastName.dirty || career.lastName.touched )">
                                    Last Name Is Mandatory
                                </div>
                            </div>

                            <div class="col-sm-6 form-group">
                                <label for="email-id">Email Id *</label>
                                <input class="form-control" type="email" placeholder="Enter Email Id"
                                    formControlName="emailId"
                                    [ngClass]="{'is-invalid': career.emailId.invalid && ( career.emailId.dirty || career.emailId.touched )}">
                                <div class="text-danger validation-message"
                                    *ngIf="career.emailId.invalid && ( career.emailId.dirty || career.emailId.touched )">
                                    Email Id Is Mandatory
                                </div>
                            </div>
                            <div class="col-sm-6 form-group">
                                <label for="phone-number">Contact Number *</label>
                                <input class="form-control" type="text" id="phone-number"
                                    placeholder="Enter Contact Number" formControlName="contactNumber"
                                    [ngClass]="{'is-invalid': career.contactNumber.invalid && ( career.contactNumber.dirty || career.contactNumber.touched )}">
                                <div class="text-danger validation-message"
                                    *ngIf="career.contactNumber.invalid && ( career.contactNumber.dirty || career.contactNumber.touched )">
                                    <div [hidden]="!career.contactNumber.errors.minlength">
                                        Minimum 9 Digits Required
                                    </div>
                                    <div [hidden]="!career.contactNumber.errors.required">
                                        Contact Number is compulsory
                                    </div>
                                    <div [hidden]="!career.contactNumber.errors.maxlength">
                                        Max 16 Digits Allowed
                                    </div>
                                </div>
                                <div>

                                </div>
                            </div>

                            <div formGroupName="artifact" class="col-sm-12 form-group mt-4">
                                <label for="">Upload CV*</label>
                                <div class="row mb-3">
                                    <div class="col-sm-12">
                                        <ngx-file-drop dropZoneClassName="" dropZoneLabel="Drop files here"
                                            (onFileDrop)="dropped($event)" multiple="false"
                                            accept=".jpg, .png, .pdf, .docx, .txt, .jpeg">
                                            <ng-template ngx-file-drop-content-tmp
                                                let-openFileSelector="openFileSelector">
                                                <div class="text-center justify-content-between">
                                                    <button class="btn btn-success" type="button"
                                                        (click)="openFileSelector()">Browse Files
                                                    </button>
                                                </div>
                                            </ng-template>
                                        </ngx-file-drop>
                                    </div>

                                    <div class="col-sm-12">
                                        <label for="">Label*</label>
                                        <input class="form-control" type="text" placeholder="Provide Label for File"
                                            formControlName="label" title="Provide Label"
                                            [ngClass]="{'is-invalid': artifact.get('label').invalid && ( artifact.get('label').dirty || artifact.get('label').touched )}">
                                        <div class="text-danger validation-message"
                                            *ngIf="artifact.get('label').invalid && ( artifact.get('label').dirty || artifact.get('label').touched )">
                                            <div [hidden]="!artifact.get('label').errors.required">
                                                Label for file is compulsory
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="col-sm-12 form-group mt-4">
                                <button (click)="onSubmit()" class="btn btn-outline-success text-dark font-weight-bold"
                                    type="submit">SUBMIT
                                    <svg width="23" height="22" viewBox="0 0 23 22" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.57471 10.9422L19.988 10.9422" stroke="#21BB9F"
                                            stroke-width="1.36777" stroke-linecap="round"></path>
                                        <path
                                            d="M14.5168 16.4133L19.6656 11.2646C19.8436 11.0866 19.8436 10.7979 19.6656 10.6198L14.5168 5.47113"
                                            stroke="#21BB9F" stroke-width="1.36777" stroke-linecap="round"></path>
                                    </svg>
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>