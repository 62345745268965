import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class EnquiryService {

  AUTH_API: string = `https://${window.location.hostname}:9004/api/v1/procurement`;
  
  constructor(private http: HttpClient) { }

  clientCreateEnquiry(data: any): Observable<any>{
    return this.http.post(`${this.AUTH_API}/enquiry`,data)
  }

  updateEnquiry(id: number, data: any):Observable<any>{
    return this.http.put(`${this.AUTH_API}/enquiry/${id}`, data);
  }

  getAllEnquiries(page: any, size: any, type: string):Observable<any>{
    const params = new HttpParams().set('page', page).set('size', size).set('type', type);
    return this.http.get(`${this.AUTH_API}/enquiries`, {params: params});
  }

  fetchEnquiryById(id: number): Observable<any>{
    return this.http.get(`${this.AUTH_API}/enquiry/${id}`);
  }

  createEnquiryArtifact(enquiryId, data):Observable<any>{
    return this.http.post(`${this.AUTH_API}/enquiry/${enquiryId}/artifact`, data);
  }

  deleteEnquiryArtifact(enquiryId, artifactId):Observable<any>{
    return this.http.delete(`${this.AUTH_API}/enquiry/${enquiryId}/artifact/${artifactId}`);
  }

  getAllEnquiryArtifact(enquiryId):Observable<any>{
    return this.http.get(`${this.AUTH_API}/enquiry/${enquiryId}/artifacts`);
  }

  getAllRecentEnquiries(page: any, size: any, type: string):Observable<any>{
    const params = new HttpParams().set('page', page).set('size', size).set('type', type);
    return this.http.get(`${this.AUTH_API}/recentEnquiries`, {params: params});
  }

  getAllDueSoonEnquiries(page: any, size: any, type: string):Observable<any>{
    const params = new HttpParams().set('page', page).set('size', size).set('type', type);
    return this.http.get(`${this.AUTH_API}/dueSoonEnquiries`, {params: params});
  }

}
