<section class="wrapper multi-step-form">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-4 col-xl-3 col-left pt-12 d-flex flex-column justify-content-center position-sticky top-0">
                <div class="box-absolute d-flex">
                    <app-sidebar></app-sidebar>
                </div>
                <div class="box mx-auto">
                    <h3 class="text-center text-md-start">Vendor Onboarding</h3>
                    <ul class="list-unstyled step-one">
                        <li class="active">
                            <div class="icon">
                                <svg width="13" height="9" viewBox="0 0 13 9" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M1 5L3.97978 7.97978C4.17504 8.17504 4.49162 8.17504 4.68689 7.97978L11.6667 1"
                                        stroke="#003D90" stroke-width="1.5" stroke-linecap="round"></path>
                                </svg>
                            </div><span>Company Details</span>
                        </li>
                        <li class="active">
                            <div class="icon">
                                <svg width="13" height="9" viewBox="0 0 13 9" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M1 5L3.97978 7.97978C4.17504 8.17504 4.49162 8.17504 4.68689 7.97978L11.6667 1"
                                        stroke="#003D90" stroke-width="1.5" stroke-linecap="round"></path>
                                </svg>
                            </div><span>Company Overview</span>
                        </li>
                        <li class=" ">
                            <div class="icon">
                            </div><span>Upload Documents</span>
                        </li>
                        <li class=" ">
                            <div class="icon">
                            </div><span>Certification</span>
                        </li>
                        <li class=" ">
                            <div class="icon">
                            </div><span>Sign In</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-8 col-xl-9 col-right px-start-end pb-6">
                <div class="topline py-start-end d-flex align-items-center justify-content-between mb-4 mb-md-5">
                    <ul class="breadcrumb p-0 m-0">
                        <li class="breadcrumb-item 3 brand">
                            <button [routerLink]="['/v1/vendor-onboard/details']" [queryParams]="{ t:pre_token }">Back</button>
                        </li>
                    </ul>
                    <div> <span>2/5</span></div>
                </div>
                <form [formGroup]="company_overview" (ngSubmit)="updateVendorOverview()">
                    <h3 class="text-center">Company Overview</h3>
                    <div class="row">
                        <div class="col-md-4 form-group">
                            <label class="form-label" for="details-of-services/goods">Details of Services/Goods</label>
                            <input class="form-control" type="text" placeholder="Text Input"
                                id="details-of-services/goods" formControlName="detailsOfGoodsAndServices" />
                        </div>
                        <div class="col-md-4 form-group">
                            <label class="form-label" for="gross-annual-turn-over">Gross Annual Turn Over</label>
                            <input class="form-control" type="text" placeholder="Text Input"
                                id="gross-annual-turn-over"  formControlName="grossAnnualTurnOver"/>
                        </div>
                        <div formGroupName="grossAnnualTurnOverCurrency" class="col-md-4 form-group">
                            <label class="form-label" for="grossAnnualTurnOverCurrency">Currency</label>
                            <select class="form-control form-select"  id="grossAnnualTurnOverCurrency" formControlName="id">
                                <option *ngFor="let currency of currencyList" [value]="currency.id">
                                    {{ currency.currency }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-4 form-group">
                            <label class="form-label" for="company-established-date">Company Established Date</label>
                            <div class="input-group">
                                <input class="form-control" type="date" placeholder="DD/MM/YY" [max]="getToday()"
                                    id="company-established-date" formControlName="establishedOn"/>
                                <button class="input-group-btn invisible" type="button">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M18.2222 5H5.77778C4.79594 5 4 5.79594 4 6.77778V19.2222C4 20.2041 4.79594 21 5.77778 21H18.2222C19.2041 21 20 20.2041 20 19.2222V6.77778C20 5.79594 19.2041 5 18.2222 5Z"
                                            stroke="#003D90" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                        <path d="M16 3V7" stroke="#003D90" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                        <path d="M8 3V7" stroke="#003D90" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                        <path d="M4 10H20" stroke="#003D90" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div class="col-md-4 form-group">
                            <label class="form-label" for="geographic-service-area">Geographic Service Area</label>
                            <input class="form-control" type="text" placeholder="Text Input"
                                id="geographic-service-area" formControlName="geoServiceArea"/>
                        </div>
                        <div class="col-md-4 form-group">
                            <label class="form-label" for="legal-structure">Legal Structure</label>
                            <input class="form-control" type="text" placeholder="Text Input" 
                                id="legal-structure" formControlName="legalStructure" />
                        </div>
                        <div class="col-md-4 form-group">
                            <label class="form-label" for="business-type">Business Type</label>
                            <select class="form-control form-select" formControlName="businessType" id="business-type"
                                (change)="businessType()">
                                <option *ngFor=" let business of businessTypeList" [value]="business.id">
                                    {{ business.value }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-4 form-group">
                            <label class="form-label" for="bonded?">Bonded?</label>
                            <div class="form-check-wrap" id="bonded?">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="bonded" id="bonded-yes"
                                        [value]="true" checked="checked" formControlName="bonded"/>
                                    <label class="form-check-label" for="bonded-yes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" id="bonded-no"
                                        [value]="false" formControlName="bonded"/>
                                    <label class="form-check-label" for="bonded-no">No</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 form-group">
                            <label class="form-label" for="licensed?">Licensed?</label>
                            <div class="form-check-wrap" id="licensed?">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" id="licensed-yes"
                                        [value]="true" formControlName="licensed" (click)="licenseRadioFunc('yes')"/>
                                    <label class="form-check-label" for="licensed-yes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" id="licensed?-no" ng-checked="true"
                                       [value]="false" formControlName="licensed" (click)="licenseRadioFunc('no')"/>
                                    <label class="form-check-label" for="licensed?-no">No</label>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="hideLicense" class="col-md-4 form-group">
                            <label class="form-label" for="license-number">License Number*</label>
                            <input class="form-control" type="email" placeholder="Text Input" 
                                id="license-number" formControlName="licenseNumber" 
                                [ngClass]="{'is-invalid': overview.licenseNumber.invalid && (overview.licenseNumber.touched || overview.licenseNumber.dirty)}"/>
                                <small class="text-danger validation-message" *ngIf="overview.licenseNumber.invalid && (overview.licenseNumber.touched || overview.licenseNumber.dirty)">Enter License Number</small>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-md-6 form-group pt-4 pt-lg-5">
                            <button class="btn btn-success w-100" type="submit">NEXT</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>