<section class="wrapper multi-step-form">
    <div class="container-fluid">
        <div class="row">
            <div
                class="col-md-4 col-xl-3 col-left pt-12 d-flex flex-column justify-content-center position-sticky top-0">
                <div class="box-absolute d-flex">
                    <app-sidebar></app-sidebar>
                </div>
                <div class="box mx-auto">
                    <h3 class="text-center text-md-start">Auction</h3>
                    <ul class="list-unstyled step-one">
                        <li class="active" id="list-item-0">
                            <div class="icon"> <i class="fa fa-check"></i></div><span>Configuration</span>
                        </li>
                        <li class=" " id="list-item-1">
                            <div class="icon"> <i class="fa fa-check"></i></div><span>Details</span>
                        </li>
                        <li class=" " id="list-item-2">
                            <div class="icon"> <i class="fa fa-check"></i></div><span>Invite</span>
                        </li>
                        <li class=" " id="list-item-3">
                            <div class="icon"> <i class="fa fa-check"></i></div><span>Terms &amp; Conditions</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-8 col-xl-9 col-right px-start-end pb-6" (click)="hideSideBar()">
                <div class="topline pt-4 pt-lg-5 pb-3 pb-lg-4 d-flex align-items-center justify-content-between">
                    <ul class="breadcrumb p-0 m-0">
                        <!-- First Page -->
                        <li *ngIf="dashboardBool" class="breadcrumb-item 1 brand" id="0">
                            <button [routerLink]="['/v1/dashboard']">Dashboard</button>
                        </li>
                        <!-- Second Page -->
                        <li *ngIf="auctionConfigBool" class="breadcrumb-item 2 brand">
                            <button (click)="backAuctionConfig()">Back</button>
                        </li>
                        <!-- Third Page -->
                        <li *ngIf="auctionDetailBool" class="breadcrumb-item 3 brand">
                            <button (click)="backAuctionDetail()">Back</button>
                        </li>
                        <!-- Fourth Page -->
                        <li *ngIf="auctionVendorsBool" class="breadcrumb-item 4 brand">
                            <button (click)="backAuctionVendors()">Back</button>
                        </li>
                    </ul>
                    <div class="tab-content step-two">
                        <div class="tab-pane active" id="item-0">1/4</div>
                        <div class="tab-pane" id="item-1">2/4</div>
                        <div class="tab-pane" id="item-2">3/4</div>
                        <div class="tab-pane" id="item-3">4/4</div>
                    </div>
                </div>
                <form [formGroup]="auctionForm">
                    <div class="tab-content form-step">
                        <!-- Auction Config -->
                        <div class="tab-pane active" id="item-4">

                            <h3 class="text-center">Auction Configuration</h3>
                            <div class="row">
                                <div class="col-md-12 form-group">
                                    <label class="form-label" for="auction-description*">Auction Title*</label>
                                    <textarea class="form-control" formControlName="description" rows="3"
                                        placeholder="Text Input" id="auction-description*" required="required"
                                        [ngClass]="{'is-invalid': auctions.description.invalid && ( auctions.description.dirty || auctions.description.touched) }">
                                    </textarea>
                                    <div class="text-danger validation-message-title"
                                        *ngIf="auctions.description.invalid && ( auctions.description.dirty || auctions.description.touched)">
                                        Auction title is mandatory
                                    </div>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="auction-start-date-&amp;-time*">Auction Start Date
                                        &amp; Time*</label>
                                    <div class="input-group">
                                        <input class="form-control" formControlName="auctionDate" type="datetime-local"
                                            [min]="getTodayDateTime()" id="auction-start-date-&amp;-time*" required="required" (change)="validateAuctionDate()"
                                            [ngClass]="{'is-invalid': auctions.auctionDate.invalid && ( auctions.auctionDate.dirty || auctions.auctionDate.touched) }">
                                        <button class="input-group-btn invisible" type="button">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M18.2222 5H5.77778C4.79594 5 4 5.79594 4 6.77778V19.2222C4 20.2041 4.79594 21 5.77778 21H18.2222C19.2041 21 20 20.2041 20 19.2222V6.77778C20 5.79594 19.2041 5 18.2222 5Z"
                                                    stroke="#003D90" stroke-width="1.5" stroke-linecap="round"
                                                    stroke-linejoin="round"></path>
                                                <path d="M16 3V7" stroke="#003D90" stroke-width="1.5"
                                                    stroke-linecap="round" stroke-linejoin="round"></path>
                                                <path d="M8 3V7" stroke="#003D90" stroke-width="1.5"
                                                    stroke-linecap="round" stroke-linejoin="round"></path>
                                                <path d="M4 10H20" stroke="#003D90" stroke-width="1.5"
                                                    stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                        </button>
                                    </div>
                                    <div class="text-danger validation-message"
                                        *ngIf="auctions.auctionDate.invalid && ( auctions.auctionDate.dirty || auctions.auctionDate.touched) && !backDatedAuctionBool">
                                        Auction Date & Time is mandatory
                                    </div>
                                    <div class="text-danger validation-message"
                                        *ngIf="backDatedAuctionBool">
                                        Back Dated Auction now Allowed.
                                    </div>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="auction-duration">Auction Duration*</label>
                                    <input class="form-control" formControlName="durationinMinutes" type="number"
                                        placeholder="Enter minutes in number" id="auction-duration"
                                        [ngClass]="{'is-invalid': auctions.durationinMinutes.invalid && ( auctions.durationinMinutes.dirty || auctions.durationinMinutes.touched) }"
                                        min="0">
                                    <div class="text-danger validation-message"
                                        *ngIf="auctions.durationinMinutes.invalid && ( auctions.durationinMinutes.dirty || auctions.durationinMinutes.touched)">
                                        Auction Duration is mandatory
                                    </div>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="auctionDurationUnit">Auction Duration Unit*</label>
                                    <select id="auctionDurationUnit" class="form-control form-select"
                                        formControlName="auctionDurationUnit"
                                        [ngClass]="{'is-invalid': auctions.auctionDurationUnit.invalid && ( auctions.auctionDurationUnit.dirty || auctions.auctionDurationUnit.touched) }">
                                        <option *ngFor="let item of auctionDurationUnitArray" [value]="item.value">
                                            {{ item.value }}
                                        </option>
                                    </select>
                                    <div class="text-danger validation-message"
                                        *ngIf="auctions.auctionDurationUnit.invalid && ( auctions.auctionDurationUnit.dirty || auctions.auctionDurationUnit.touched)">
                                        Auction Duration Unit is mandatory
                                    </div>
                                </div>


                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="auction-extension-bool*">Extend Time ?</label>
                                    <div class="form-check-wrap" id="auction-extension-bool*">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio"
                                                id="auction-extension-bool*-yes" [value]="true"
                                                formControlName="extendTimeBool" (change)="getExtensionTimeBool()" />
                                            <label class="form-check-label"
                                                for="auction-extension-bool*-yes">Yes</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" id="auction-extension-bool*-no"
                                                [value]="false" formControlName="extendTimeBool"
                                                (change)="getExtensionTimeBool()" />
                                            <label class="form-check-label" for="auction-extension-bool*-no">No</label>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="timeExtensionBool" class="col-md-4 form-group">
                                    <label class="form-label" for="auction-extension">Auction Extension Time In
                                        Minutes*</label>
                                    <input type="number" class="form-control" formControlName="extendedTime">
                                    <div class="text-danger validation-message"
                                        *ngIf="auctions.extendedTime.invalid && ( auctions.extendedTime.dirty || auctions.extendedTime.touched)">
                                        Extension Timing is mandatory
                                    </div>
                                </div>

                                <div *ngIf="timeExtensionBool" class="col-md-4 form-group">
                                    <label class="form-label" for="bidSubmitDurationInLastMinutes">Time Frame For Auto Extention*</label>
                                    <input class="form-control" type="number" placeholder="In Minutes"
                                        formControlName="bidSubmitDurationInLastMinutes" min="0">
                                    <div class="text-danger validation-message"
                                        *ngIf="auctions.bidSubmitDurationInLastMinutes.invalid && ( auctions.bidSubmitDurationInLastMinutes.dirty || auctions.bidSubmitDurationInLastMinutes.touched)">
                                        Submit Bid In Last Minutes Duration is mandatory
                                    </div>
                                </div>

                                <div *ngIf="timeExtensionBool" class="col-md-4 form-group">
                                    <label class="form-label" for="auctionTimeExtensionLimit">Time Extension
                                        Limit*</label>
                                    <input class="form-control" type="number" min="0"
                                        placeholder="Enter 0 for unlimited extension"
                                        formControlName="auctionTimeExtensionLimit">
                                    <div class="text-danger validation-message"
                                        *ngIf="auctions.auctionTimeExtensionLimit.invalid && ( auctions.auctionTimeExtensionLimit.dirty || auctions.bidSubmitDurationInLastMinutes.touched)">
                                        Time Extension Limit is mandatory
                                    </div>
                                </div>

                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="auction-base-price*">Auction Base Price*</label>
                                    <input class="form-control" formControlName="basePrice" type="number"
                                        (change)="checkAuctionBasePrice()" placeholder="Enter Digits"
                                        id="auction-base-price*" required="required"
                                        [ngClass]="{'is-invalid': auctions.basePrice.invalid && ( auctions.basePrice.dirty || auctions.basePrice.touched) }"
                                        min="0">
                                    <div class="text-danger validation-message"
                                        *ngIf="auctions.basePrice.invalid && ( auctions.basePrice.dirty || auctions.basePrice.touched)">
                                        Base Price is mandatory
                                    </div>
                                    <div class="text-danger validation-message" *ngIf="basePriceBool">
                                        Base Price cannot be greater or equal than Target Price
                                    </div>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="auction-target-price*">Auction Target Price</label>
                                    <input class="form-control" formControlName="targetPrice" type="number"
                                        (change)="checkAuctionBasePrice()" placeholder="Enter Digits"
                                        id="auction-target-price*" min="0">
                                    <div *ngIf="targetPriceBool" class="text-danger validation-message">
                                        Target Price cannot be greater or equal than Base Price
                                    </div>
                                    <!-- <div class="text-danger validation-message" *ngIf="basePriceBool">
                                        Target Price cannot be lower or equal than Base Price
                                    </div> -->
                                </div>
                                <div formGroupName="currency" class="col-md-4 form-group">
                                    <label class="form-label" for="currency*">Currency*</label>
                                    <select class="form-control form-select" formControlName="id"
                                        [ngClass]="{'is-invalid': currency.get('id').invalid && ( currency.get('id').dirty || currency.get('id').touched) }">
                                        <option *ngFor="let item of currencyPicker" [value]="item.id">
                                            {{ item.currency }}
                                        </option>
                                    </select>
                                    <div class="text-danger validation-message"
                                        *ngIf="currency.get('id').invalid && ( currency.get('id').dirty || currency.get('id').touched)">
                                        Currency is mandatory
                                    </div>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="minimum-decrement*">Minimum Decrement*</label>
                                    <input class="form-control" formControlName="minimumDecrement" type="number"
                                        placeholder="Enter Digits" id="minimum-decrement*" required="required"
                                        (change)="checkDecrementUnit()" [max]="100"
                                        [ngClass]="{'is-invalid': auctions.minimumDecrement.invalid && ( auctions.minimumDecrement.dirty || auctions.minimumDecrement.touched) }"
                                        min="0">
                                    <div class="text-danger validation-message"
                                        *ngIf="auctions.minimumDecrement.invalid">
                                        <div
                                            [hidden]="( auctions.minimumDecrement.dirty || auctions.minimumDecrement.touched)">
                                            Minimum Decrement is neccessary
                                        </div>
                                    </div>
                                    <div class="text-danger validation-message" *ngIf="minimumDecrementBool">
                                        Minimum Decrement cannot be greater than 100 %
                                    </div>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="minimumDecrementUnit*">Minimum Decrement
                                        Unit*</label>
                                    <select class="form-control form-select" formControlName="minimumDecrementUnit"
                                        (change)="checkDecrementUnit()"
                                        [ngClass]="{'is-invalid': auctions.minimumDecrementUnit.invalid && ( auctions.minimumDecrementUnit.dirty || auctions.minimumDecrementUnit.touched) }">
                                        <option *ngFor="let item of minimumDecrementUnitArray" [value]="item.value">
                                            {{ item.value }}
                                        </option>
                                    </select>
                                    <div class="text-danger validation-message"
                                        *ngIf="auctions.minimumDecrementUnit.invalid && ( auctions.minimumDecrementUnit.dirty || auctions.minimumDecrementUnit.touched)">
                                        Minimum Decrement Unit is neccessary
                                    </div>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="limit-max-attempts-per-vendor">Limit Max Attempts Per
                                        Vendor*</label>
                                    <input class="form-control" formControlName="limitMaxattemptsPerVendor"
                                        type="number" placeholder="Enter 0 for Unlimited Attempts"
                                        id="limit-max-attempts-per-vendor"
                                        [ngClass]="{'is-invalid': auctions.limitMaxattemptsPerVendor.invalid && ( auctions.limitMaxattemptsPerVendor.dirty || auctions.limitMaxattemptsPerVendor.touched) }"
                                        min="0">
                                    <div class="text-danger validation-message"
                                        *ngIf="auctions.limitMaxattemptsPerVendor.invalid && ( auctions.limitMaxattemptsPerVendor.dirty || auctions.limitMaxattemptsPerVendor.touched)">
                                        Max Limit Attempts/Vendor is mandatory
                                    </div>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="show-l1-standing">Show L1 Standing To</label>
                                    <select class="form-control form-select" formControlName="showStanding"
                                        id="show-l1-standing">
                                        <option *ngFor="let item of showStandingArray" [value]="item.value">
                                            {{ item.code }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-12 form-group">
                                    <label class="form-label" for="remark">Remark*</label>
                                    <textarea class="form-control" rows="3" formControlName="remark"
                                        placeholder="Inputs for vendors, regarding auction" id="remark"
                                        required="required"
                                        [ngClass]="{'is-invalid': auctions.remark.invalid && ( auctions.remark.dirty || auctions.remark.touched) }">
                                    </textarea>
                                    <div class="text-danger validation-message-title"
                                        *ngIf="auctions.remark.invalid && ( auctions.remark.dirty || auctions.remark.touched)">
                                        Remark is mandatory
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-md-6 form-group pt-4 pt-lg-5">
                                    <button class="btn btn-success w-100" (click)="firstPage()" type="button"
                                        data-target="item-1">Next</button>
                                </div>
                            </div>
                            <div class="row" align="right">
                                <div class="col-lg-12">
                                    <p class="bg-white text-dark">
                                        Note: * denotes mandatory fields
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- Auction Detail -->
                        <div formGroupName="auctionDetailsForm" class="tab-pane" id="item-5">
                            <h3 class="text-center">Auction Details</h3>
                            <div class="row">
                                <div class="col-md-6 form-group">
                                    <label class="form-label" for="rfq-number">RFQ Number</label>
                                    <input class="form-control" formControlName="number" placeholder="Enter Rfq No"
                                        type="text" id="rfq-number" />
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="form-label" for="rfq-date">RFQ Date</label>
                                    <input class="form-control" formControlName="createDate" placeholder="Date"
                                        type="date" id="rfq-date" [min]="getTodayDate()"/>
                                </div>
                                <div class="col-md-12 form-group">
                                    <label class="form-label" for="rfq-description">RFQ Title*</label>
                                    <textarea class="form-control" rows="3" formControlName="description"
                                        placeholder="Enter Title" id="rfq-description"
                                        [ngClass]="{'is-invalid': details.description.invalid && (details.description.dirty || details.description.touched)}"></textarea>
                                    <small class="text-danger validation-message-textArea"
                                        *ngIf="details.description.invalid && (details.description.dirty || details.description.touched)">RFQ
                                        title is mandatory</small>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="project-name">Project Name</label>
                                    <input class="form-control" formControlName="projectName"
                                        placeholder="Enter Project Name" type="text" id="project-name" />
                                </div>
                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="project-code">Project Code</label>
                                    <input class="form-control" formControlName="projectCode"
                                        placeholder="Enter Project Code" type="text" id="project-code" />
                                </div>
                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="cargpo-readiness-date">Cargo Readiness Date*</label>
                                    <input class="form-control" formControlName="cargoReadinessDate"
                                        placeholder="yyyy/dd/mm" type="date" id="cargpo-readiness-date"
                                        [min]="getTodayDate()" (change)="validateCargoReadinessDate()"
                                        [ngClass]="{'is-invalid': details.cargoReadinessDate.invalid && (details.cargoReadinessDate.dirty || details.cargoReadinessDate.touched)}" />
                                    <div class="text-danger validation-message">
                                        <div
                                            *ngIf="details.cargoReadinessDate.invalid && (details.cargoReadinessDate.dirty || details.cargoReadinessDate.touched)">
                                            Date is mandatory
                                        </div>
                                        <div *ngIf="invalidOfferDate">
                                            Can't Be Smaller Than Last Submission Date
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="offer-last-submission-date-&amp;-time">Offer Last
                                        Submission Date &amp; Time*</label>
                                    <input class="form-control" formControlName="lastSubmissionDate"
                                        placeholder="yyyy/dd/mm Time" type="datetime-local"
                                        id="offer-last-submission-date-&amp;-time" [min]="getTodayDate()"
                                        [max]="maxOfferLastSubmissionDate()" (change)="validateCargoReadinessDate()"
                                        [ngClass]="{'is-invalid': details.lastSubmissionDate.invalid && (invalidOfferDate || details.lastSubmissionDate.dirty || details.lastSubmissionDate.touched )}" />
                                    <div class="text-danger validation-message">
                                        <div
                                            *ngIf="details.lastSubmissionDate.invalid && (details.lastSubmissionDate.dirty || details.lastSubmissionDate.touched )">
                                            Date is mandatory
                                        </div>
                                        <div *ngIf="invalidOfferDate">
                                            Can't Be Greater Than CargoReadinessDate
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h4 class="text-center my-3 my-lg-4">Shipment Details</h4>
                            <div class="row">
                                <div class="col-md-4 form-group">
                                    <label for="form-label">PO Number</label>
                                    <input type="text" class="form-control mt-2" formControlName="poNumber">
                                </div>
                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="mode-of-shipment">Mode of Shipment*</label>
                                    <select class="form-control form-select" formControlName="modeOfShipment"
                                        (change)="changeModeOfShipment()"
                                        [ngClass]="{'is-invalid': details.modeOfShipment.invalid && (details.modeOfShipment.dirty || details.modeOfShipment.touched)}"
                                        [ngClass]="{'is-invalid': details.modeOfShipment.invalid && ( details.modeOfShipment.dirty || details.modeOfShipment.touched) }">
                                        <option value="" disabled selected>Select your option</option>
                                        <option *ngFor="let mode of mode_of_shipment" [value]="mode.id">
                                            {{ mode.value }}
                                        </option>
                                    </select>
                                    <div class="text-danger validation-message"
                                        *ngIf="details.modeOfShipment.invalid && ( details.modeOfShipment.dirty || details.modeOfShipment.touched)">
                                        Select Mode of Shipment
                                    </div>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="trade-type">Trade Type*</label>
                                    <select class="form-control form-select" formControlName="tradeType"
                                        (change)="changeTradeType()"
                                        [ngClass]="{'is-invalid': details.tradeType.invalid && ( details.tradeType.dirty || details.tradeType.touched) }">
                                        <option value="" disabled selected>Select your option</option>
                                        <option *ngFor="let trade of trade_type" [value]="trade.id">
                                            {{ trade.value }}
                                        </option>
                                    </select>
                                    <div class="text-danger validation-message"
                                        *ngIf="details.tradeType.invalid && ( details.tradeType.dirty || details.tradeType.touched)">
                                        Select Trade Type
                                    </div>
                                </div>

                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="trade-type">Supplier Name</label>
                                    <input class="form-control" formControlName="supplierName" type="text"
                                        id="commodity" />
                                </div>

                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="loading-port">Loading Port*</label>
                                        <mat-select #singleSelect class="form-control" formControlName="origin" (selectionChange)="selectLoadingPort()">
                                            <mat-option>
                                                <ngx-mat-select-search [(ngModel)]="searchOriginValue" [ngModelOptions]="{standalone: true}"
                                                (input)="getSearchResultsOrigin()"
                                                placeholderLabel="Enter Name" noEntriesFoundLabel = 'No result available'></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let item of loadingPorts" [value]="item?.code?.code">
                                                {{ item?.value }}
                                            </mat-option>
                                        </mat-select>
                                    <div class="text-danger validation-message"
                                        *ngIf="details.origin.invalid && ( details.origin.dirty || details.origin.touched)">
                                        Select origin
                                    </div>
                                    <div class="text-danger validation-message" *ngIf="samePortError">
                                        Same Port Not Allowed
                                    </div>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="destination-port">Destination Port*</label>
                                    <mat-select #singleSelect class="form-control" formControlName="destination" (selectionChange)="selectLoadingPort()">
                                        <mat-option>
                                            <ngx-mat-select-search [(ngModel)]="searchDestinationValue" [ngModelOptions]="{standalone: true}"
                                            (input)="getSearchResultDestination()"
                                            placeholderLabel="Enter Name" noEntriesFoundLabel = 'No result available'></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let item of destinationPorts" [value]="item?.code?.code">
                                            {{ item?.value }}
                                        </mat-option>
                                    </mat-select>
                                    <div class="text-danger validation-message"
                                        *ngIf="details.destination.invalid && ( details.destination.dirty || details.destination.touched)">
                                        Select destination
                                    </div>
                                    <div class="text-danger validation-message" *ngIf="samePortError">
                                        Same Port Not Allowed
                                    </div>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="type-of-cargo">Type Of Cargo*</label>
                                    <select class="form-control form-select" id="type_cargo"
                                        formControlName="typeOfCargo" (change)="changeTypeCargo('typeOfCargo')"
                                        [ngClass]="{'is-invalid': details.typeOfCargo.invalid && ( details.typeOfCargo.dirty || details.typeOfCargo.touched) }">
                                        <option value="" disabled selected>Select your option</option>
                                        <option *ngFor="let type of type_cargo" [value]="type.id">
                                            {{ type.value }}
                                        </option>
                                    </select>
                                    <div class="text-danger validation-message"
                                        *ngIf="details.typeOfCargo.invalid && ( details.typeOfCargo.dirty || details.typeOfCargo.touched)">
                                        Type of Cargo mandatory
                                    </div>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="commodity">Commodity*</label>
                                    <input class="form-control" formControlName="commodity"
                                        placeholder="Enter Commodity Name" type="text" id="commodity"
                                        [ngClass]="{'is-invalid': details.commodity.invalid && (details.commodity.dirty || details.commodity.touched)}" />
                                    <div class="text-danger validation-message"
                                        *ngIf="details.commodity.invalid && (details.commodity.dirty || details.commodity.touched)">
                                        Commodity is mandatory
                                    </div>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="storing-type">Storing Type*</label>
                                    <select class="form-control form-select" formControlName="storingType"
                                        [ngClass]="{'is-invalid': details.storingType.invalid && ( details.storingType.dirty || details.storingType.touched) }">
                                        <option value="" disabled selected>Select your option</option>
                                        <option *ngFor="let item of storing_type" [value]="item.code">
                                            {{ item.value }}
                                        </option>
                                    </select>
                                    <div class="text-danger validation-message"
                                        *ngIf="details.storingType.invalid && ( details.storingType.dirty || details.storingType.touched)">
                                        Storing-type is mandatory
                                    </div>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="nature-of-momevent">Nature Of Momevent*</label>
                                    <select name="" id="nature_movement" class="form-control form-select"
                                        formControlName="natureOfMovement" (change)="changeNatureOfMovement()"
                                        [ngClass]="{'is-invalid': details.natureOfMovement.invalid && ( details.natureOfMovement.dirty || details.natureOfMovement.touched) }">
                                        <option value="" disabled selected>Select your option</option>
                                        <option *ngFor="let nature_movement of nature_of_movement"
                                            [value]="nature_movement.code">
                                            {{ nature_movement.value }}
                                        </option>
                                    </select>
                                    <div class="text-danger validation-message"
                                        *ngIf="details.natureOfMovement.invalid && ( details.natureOfMovement.dirty || details.natureOfMovement.touched)">
                                        Nature of Movement is mandatory
                                    </div>
                                </div>

                                <div class="col-md-4 form-group">
                                    <label for="form-label">Nature Of Goods/Commodity</label>
                                    <select class="form-control form-select mt-2" formControlName="natureOfGoods">
                                        <option value="" disabled selected>Select your option</option>
                                        <option *ngFor="let item of filteredNatureOfGoods" [value]="item.id">
                                            {{ item.value }}
                                        </option>
                                    </select>
                                </div>

                                <div class="col-md-4 form-group">
                                    <label for="form-label">Type Of Packing</label>
                                    <select class="form-control form-select mt-2" formControlName="typeOfPacking">
                                        <option *ngFor="let packing of type_packing" [value]="packing.id">
                                            {{ packing.value }}
                                        </option>
                                    </select>
                                </div>

                                <div *ngIf="sea_mode_shipment" class="col-md-4 form-group">
                                    <label class="form-label" for="hs-code">HS Code</label>
                                    <input class="form-control" formControlName="hsCode" placeholder="Code" type="text"
                                        id="hs-code" />
                                </div>
                                <div *ngIf="sea_mode_shipment" class="col-md-4 form-group">
                                    <label class="form-label" for="container-load-type">Container Load Type</label>
                                    <select class="form-control form-select" id="container_load_type"
                                        formControlName="containerLoadType" (change)="changeContainerLoadType()"
                                        [ngClass]="{'is-invalid': details.containerLoadType.invalid && ( details.containerLoadType.dirty || details.containerLoadType.touched )}">
                                        <option value="" disabled selected>Select your option</option>
                                        <option *ngFor="let container of container_load_type" [value]="container.id">
                                            {{ container.value }}
                                        </option>
                                    </select>
                                    <div class="text-danger validation-message"
                                        *ngIf="details.containerLoadType.invalid && ( details.containerLoadType.dirty || details.containerLoadType.touched )">
                                        Container Load Type is mandatory
                                    </div>
                                </div>

                                <div *ngIf="sea_mode_shipment" class="col-md-4 form-group">
                                    <label class="form-label" for="detention">Detention (Days)</label>
                                    <input class="form-control" formControlName="detention" placeholder="Enter Days" type="number"
                                        id="detention" />
                                </div>
                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="demurrage">Demurrage (Days)</label>
                                    <input class="form-control" formControlName="demurrage" placeholder="Enter Days" type="number"
                                        id="demurrage" />
                                </div>

                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="pick-up-terms">Pick Up Terms*</label>
                                    <select id="pickup_terms" class="form-control form-select"
                                        formControlName="pickUpIncoterms"
                                        [ngClass]="{'is-invalid': details.pickUpIncoterms.invalid && ( details.pickUpIncoterms.dirty || details.pickUpIncoterms.touched) }"
                                        (change)="onChangePickupInco()">
                                        <option value="" disabled selected>Select your option</option>
                                        <option *ngFor="let pickup of pickUpIncoTermsArray" [value]="pickup.code">
                                            {{ pickup.value }}
                                        </option>
                                    </select>
                                    <div class="text-danger validation-message"
                                        *ngIf="details.pickUpIncoterms.invalid && ( details.pickUpIncoterms.dirty || details.pickUpIncoterms.touched)">
                                        Pickup Terms is mandatory
                                    </div>
                                </div>

                                <div class="col-md-4 form-group" *ngIf="showPickupAddress">
                                    <label class="form-label" for="contact-person-name">Pickup Person Name*</label>
                                    <input class="form-control" type="text" placeholder="Text Input"
                                        id="contact-person-name" formControlName="pickupPersonName"
                                        [ngClass]="{'is-invalid': details.pickupPersonName.invalid && ( details.pickupPersonName.dirty || details.pickupPersonName.touched)}" />
                                    <div class="text-danger validation-message"
                                        *ngIf="details.pickupPersonName.invalid && ( details.pickupPersonName.dirty || details.pickupPersonName.touched)">
                                        Pickup person name is mandatory
                                    </div>
                                </div>

                                <div *ngIf="showPickupAddress">
                                    <div class="row">
                                        <div formGroupName="pickupPersonAddress">
                                            <div class="row">
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label" for="street-address-1">Street Address
                                                        1</label>
                                                    <input class="form-control" type="text" placeholder="Text Input"
                                                        id="street-address-1" formControlName="street1"
                                                        (change)="street1PickupInco($event)"
                                                        (change)="validatePickupAddress()"
                                                        [ngClass]="{'is-invalid': this.pickupAddressBoolean}" />
                                                    <small class="text-danger validation-message"
                                                        *ngIf="this.pickupAddressBoolean">street 1 &
                                                        street 2 can't
                                                        be same</small>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label" for="street-address-2">Street Address
                                                        2</label>
                                                    <input class="form-control" type="text" placeholder="Text Input"
                                                        id="street-address-2" formControlName="street2"
                                                        (change)="street2PickupInco($event)"
                                                        (change)="validatePickupAddress()"
                                                        [ngClass]="{'is-invalid': this.pickupAddressBoolean}" />
                                                    <small class="text-danger validation-message"
                                                        *ngIf="this.pickupAddressBoolean">street 1 &
                                                        street 2 can't
                                                        be same</small>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label" for="city">City</label>
                                                    <input class="form-control" type="text" placeholder="Text Input"
                                                        id="city" formControlName="city" />
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label" for="state">State</label>
                                                    <input class="form-control" type="text" placeholder="Text Input"
                                                        id="state" formControlName="state" />
                                                </div>
                                                <div formGroupName="country" class="col-md-4 form-group">
                                                    <label class="form-label" for="country">Country</label>
                                                    <select id="country" name="country" formControlName="id"
                                                        class="form-control form-select">
                                                        <option value="" disabled selected>Select Country</option>
                                                        <option [value]="count.id" *ngFor="let count of countries">
                                                            {{ count.name }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label" for="zip-code">Zip Code</label>
                                                    <input class="form-control" type="text" placeholder="Text Input"
                                                        id="zip-code" formControlName="zipCode" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Delivery Inco Terms -->
                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="delivery-terms">Delivery Terms*</label>
                                    <select name="" id="delivery_terms" class="form-control form-select"
                                        formControlName="deliveryIncoterms" (change)="onChangeDeliveryInco()" [ngClass]="{'is-invalid': details.deliveryIncoterms.invalid &&
                                        ( details.deliveryIncoterms.dirty || details.deliveryIncoterms.touched) }">
                                        <option value="" disabled selected>Select your option</option>
                                        <option *ngFor="let dropoff of dropOffIncoTermsArray" [value]="dropoff.code">
                                            {{ dropoff.value }}
                                        </option>
                                    </select>
                                    <div class="text-danger validation-message"
                                        *ngIf="details.deliveryIncoterms.invalid && ( details.deliveryIncoterms.dirty || details.deliveryIncoterms.touched)">
                                        Delivery Terms is mandatory
                                    </div>
                                </div>

                                <!-- Destination Terminal -->
                                <div class="col-md-4 form-group" *ngIf="showTerminal">
                                    <label class="form-label">Destination Terminal*</label>
                                    <input class="form-control" type="text" placeholder="Text Input"
                                        id="destinationTerminal" formControlName="destinationTerminal"
                                        [ngClass]="{'is-invalid': details.destinationTerminal.invalid && ( details.destinationTerminal.dirty || details.destinationTerminal.touched)}" />
                                    <div class="text-danger validation-message"
                                        *ngIf="details.destinationTerminal.invalid && ( details.destinationTerminal.dirty || details.destinationTerminal.touched)">
                                        Destination terminal is mandatory
                                    </div>
                                </div>

                                <!-- contact Person -->
                                <div class="col-md-4 form-group" *ngIf="delivaryAddress">
                                    <label class="form-label">Delivary Person Name*</label>
                                    <input class="form-control" type="text" placeholder="Text Input" id="personName"
                                        formControlName="deliveryPersonName"
                                        [ngClass]="{'is-invalid': details.deliveryPersonName.invalid && ( details.deliveryPersonName.dirty || details.deliveryPersonName.touched)}" />
                                    <div class="text-danger validation-message"
                                        *ngIf="details.deliveryPersonName.invalid && ( details.deliveryPersonName.dirty || details.deliveryPersonName.touched)">
                                        Delivery person name is mandatory
                                    </div>
                                </div>

                                <!-- Delivery Person Adress -->
                                <div *ngIf="delivaryAddress">
                                    <div class="row">
                                        <div formGroupName="deliveryPersonAddress">
                                            <div class="row">
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label" for="street-address-1">Street Address
                                                        1</label>
                                                    <input class="form-control" type="text" placeholder="Text Input"
                                                        id="street-address-1" formControlName="street1"
                                                        (change)="validateDeliveryAddress()"
                                                        [ngClass]="{'is-invalid': this.deliveryAddressBoolean}" />
                                                    <small class="text-danger validation-message"
                                                        *ngIf="this.deliveryAddressBoolean">street 1 &
                                                        street 2 can't be same</small>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label" for="street-address-2">Street Address
                                                        2</label>
                                                    <input class="form-control" type="text" placeholder="Text Input"
                                                        id="street-address-2" formControlName="street2"
                                                        (change)="validateDeliveryAddress()"
                                                        [ngClass]="{'is-invalid': this.deliveryAddressBoolean}" />
                                                    <small class="text-danger validation-message"
                                                        *ngIf="this.deliveryAddressBoolean">street 1 &
                                                        street 2 can't
                                                        be same</small>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label" for="city">City</label>
                                                    <input class="form-control" type="text" placeholder="Text Input"
                                                        id="city" formControlName="city" />
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label" for="state">State</label>
                                                    <input class="form-control" type="text" placeholder="Text Input"
                                                        id="state" formControlName="state" />
                                                </div>
                                                <div formGroupName="country" class="col-md-4 form-group">
                                                    <label class="form-label" for="country">Country</label>
                                                    <select id="country" name="country" formControlName="id"
                                                        class="form-control form-select">
                                                        <option value="" disabled selected>Select Country</option>
                                                        <option [value]="count.id" *ngFor="let count of countries">
                                                            {{ count.name }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label" for="zipCode">ZipCode</label>
                                                    <input class="form-control" type="text" placeholder="Text Input"
                                                        id="zipCode" formControlName="zipCode" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Third Country Controls -->
                                <!-- exporter name -->
                                <div class="col-md-4 form-group" *ngIf="isThirdCountrySelected">
                                    <label class="form-label">Exporter Name*</label>
                                    <input class="form-control" type="text" placeholder="Text Input" id="personName"
                                        formControlName="exporterName" [ngClass]="{'is-invalid': details.exporterName.invalid && (details.exporterName.dirty || details.exporterName.touched)}"/>
                                    <div *ngIf="details.exporterName.invalid && (details.exporterName.dirty || details.exporterName.touched)" class="text-danger validation-message">
                                        Exporter name is required
                                    </div>
                                </div>

                                <!-- exporter email id -->
                                <div class="col-md-4 form-group" *ngIf="isThirdCountrySelected">
                                    <label class="form-label">Exporter Mail Id</label>
                                    <input class="form-control" type="text" placeholder="Text Input" id="personName"
                                        formControlName="exporterMailId" />
                                </div>

                                <!-- exporter contact no -->
                                <div class="col-md-4 form-group" *ngIf="isThirdCountrySelected">
                                    <label class="form-label">Exporter Contact No</label>
                                    <input class="form-control" type="number" placeholder="Text Input" id="personName"
                                        formControlName="exporterContact" />
                                </div>

                                <div *ngIf="isThirdCountrySelected">
                                    <div class="row">
                                        <!-- exporter Adress -->
                                        <div formGroupName="exporterAddress">
                                            <div class="row">
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label" for="street-address-1">Street Address
                                                        1</label>
                                                    <input class="form-control" type="text" placeholder="Text Input"
                                                        id="street-address-1" formControlName="street1"/>

                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label" for="street-address-2">Street Address
                                                        2</label>
                                                    <input class="form-control" type="text" placeholder="Text Input"
                                                        id="street-address-2" formControlName="street2"/>

                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label" for="city">City</label>
                                                    <input class="form-control" type="text" placeholder="Text Input"
                                                        id="city"  formControlName="city"/>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label" for="state">State</label>
                                                    <input class="form-control" type="text" placeholder="Text Input"
                                                        id="state"  formControlName="state"/>
                                                </div>
                                                <div formGroupName="country" class="col-md-4 form-group">
                                                    <label class="form-label" for="country">Country</label>
                                                    <select id="country" name="country"
                                                        class="form-control form-select" formControlName="id">
                                                        <option value="" disabled selected>Select Country</option>
                                                        <option [value]="count.id" *ngFor="let count of countries">
                                                            {{ count.name }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label" for="zipCode">ZipCode</label>
                                                    <input class="form-control" type="number" placeholder="Text Input"
                                                        id="zipCode"  formControlName="zipCode"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Sea FCL Container Details -->
                            <div *ngIf="sea_mode_shipment">
                                <button *ngIf="fullContainerLoadBool" class="btn-add-new text-dark btn-outline-success"
                                    (click)="addSeaRow()" type="button" style="float: right;" [hidden]="!hide_btn">
                                    <i class="fa fa-plus me-1"></i><span>Container</span>
                                </button>
                                <h4 *ngIf="fullContainerLoadBool" class="text-center my-3 my-lg-4">Container Details
                                </h4>
                                <div class="table-responsive">
                                    <table *ngIf="fullContainerLoadBool"
                                        class="table table-borderless text-center table-head-dark table-head-condensed table-head-py-3">
                                        <thead>
                                            <tr>
                                                <th>Type
                                                </th>
                                                <th>Size
                                                </th>
                                                <th>Quantity
                                                </th>
                                                <th>Weight
                                                </th>
                                                <th>UOM
                                                </th>
                                                <th *ngIf="container_details.length > 1" [hidden]="!hide_btn">Actions
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody formArrayName="containers">
                                            <tr *ngFor="let item of container_details.controls; let i=index; let l=last"
                                                [formGroupName]="i">
                                                <td>
                                                    <div *ngIf="!enquiryIdBool">
                                                        <mat-form-field appearance="fill">
                                                            <select matNativeControl class="form-control-plaintext"
                                                                id="container_type" formControlName="type">
                                                                <option value="" selected>Select</option>
                                                                <option *ngFor="let container of container_type"
                                                                    [value]="container.id">
                                                                    {{ container.value }}
                                                                </option>
                                                            </select>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="enquiryIdBool">
                                                        <select class="form-control-plaintext select-arrow"
                                                            formControlName="type">
                                                            <option *ngFor="let container of container_type" selected
                                                                [value]="container.id">
                                                                {{ container.value }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div *ngIf="!enquiryIdBool">
                                                        <mat-form-field appearance="standard">
                                                            <input matInput class="form-control-plaintext"
                                                                placeholder="Enter Size" formControlName="size"
                                                                type="text" list="containerSize">
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="enquiryIdBool">
                                                        <input class="form-control-plaintext" formControlName="size">
                                                    </div>
                                                    <datalist id="containerSize">
                                                        <option *ngFor="let item of containerSize" [value]="item.size">
                                                            {{ item.size }}
                                                        </option>
                                                    </datalist>
                                                </td>
                                                <td>
                                                    <div *ngIf="!enquiryIdBool">
                                                        <mat-form-field appearance="standard">
                                                            <input matInput class="form-control-plaintext" type="number"
                                                                formControlName="quantity" placeholder="Enter Quantity"
                                                                id="" min="0" />
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="enquiryIdBool">
                                                        <input class="form-control-plaintext"
                                                            formControlName="quantity">
                                                    </div>
                                                </td>
                                                <td>
                                                    <div *ngIf="!enquiryIdBool">
                                                        <mat-form-field appearance="standard">
                                                            <input matInput class="form-control-plaintext" type="number"
                                                                formControlName="weight" placeholder="Enter Weight"
                                                                id="" min="0" />
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="enquiryIdBool">
                                                        <input class="form-control-plaintext" formControlName="weight">
                                                    </div>
                                                </td>
                                                <td>
                                                    <div *ngIf="!enquiryIdBool">
                                                        <mat-form-field appearance="fill">
                                                            <select matNativeControl formControlName="uom"
                                                                class="form-control-plaintext">
                                                                <option value="" selected>Select</option>
                                                                <option *ngFor="let item of grossWeightUom"
                                                                    [value]="item.name">
                                                                    {{ item.name }}
                                                                </option>
                                                            </select>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="enquiryIdBool">
                                                        <select class="form-control-plaintext select-arrow"
                                                            formControlName="uom">
                                                            <option *ngFor="let item of grossWeightUom"
                                                                [value]="item.name">
                                                                {{ item.name }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </td>
                                                <td>
                                                    <button class="btn-outline-danger fa fa-trash"
                                                        (click)="deleteSeaRow(i)"
                                                        *ngIf="container_details.length > 1 && hide_btn">
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <button *ngIf="lessContainerLoadBool" type="button"
                                    class="btn-add-new text-dark btn-outline-success" (click)="addLCLRow()"
                                    style="float: right;" [hidden]="!hide_btn">
                                    <i class="fa fa-plus me-1"></i><span>Package</span>
                                </button>
                                <h4 *ngIf="lessContainerLoadBool" class="text-center my-3 my-lg-4">Package Details</h4>
                                <div class="table-responsive">
                                    <table *ngIf="lessContainerLoadBool"
                                        class="table table-borderless text-center table-head-dark table-head-condensed table-head-py-3">
                                        <thead>
                                            <tr>
                                                <!-- <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Type&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </th> -->
                                                <th>Quantity
                                                </th>
                                                <th>Length
                                                </th>
                                                <th>Width
                                                </th>
                                                <th>Height
                                                </th>
                                                <th>Unit of measurement
                                                </th>
                                                <th>Gross Weight
                                                </th>
                                                <th> &nbsp;&nbsp;&nbsp;Weight UOM&nbsp;&nbsp;&nbsp;
                                                </th>
                                                <th>Total Volume
                                                </th>
                                                <th *ngIf="package_details.length > 1 && hide_btn">Actions
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody formArrayName="shipmentPackages">
                                            <tr *ngFor="let item of package_details.controls; let i=index; let l=last"
                                                [formGroupName]="i">
                                                <!-- <td>
                                                    <div *ngIf="!enquiryIdBool">
                                                        <mat-form-field appearance="fill">
                                                            <select matNativeControl
                                                                class="form-control-plaintext form-select"
                                                                formControlName="type">
                                                                <option value="" selected>Select</option>
                                                                <option *ngFor="let item of container_type"
                                                                    [value]="item.id">
                                                                    {{ item.value }}
                                                                </option>
                                                            </select>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="enquiryIdBool">
                                                        <select class="form-control-plaintext select-arrow"
                                                            formControlName="type">
                                                            <option *ngFor="let item of container_type"
                                                                [value]="item.id">
                                                                {{ item.value }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </td> -->
                                                <td>
                                                    <div *ngIf="!enquiryIdBool">
                                                        <mat-form-field appearance="standard">
                                                            <input matInput class="form-control-plaintext"
                                                                formControlName="quantity" type="number"
                                                                placeholder="Enter Quantity" min="0"
                                                                (change)="calculateTotalVolume()" />
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="enquiryIdBool">
                                                        <input class="form-control-plaintext"
                                                            formControlName="quantity">
                                                    </div>
                                                </td>
                                                <td>
                                                    <div *ngIf="!enquiryIdBool">
                                                        <mat-form-field appearance="standard">
                                                            <input matInput class="form-control-plaintext"
                                                                formControlName="length" type="number"
                                                                placeholder="Enter Length" min="0"
                                                                (change)="calculateTotalVolume()" />
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="enquiryIdBool">
                                                        <input class="form-control-plaintext" formControlName="length">
                                                    </div>
                                                </td>
                                                <td>
                                                    <div *ngIf="!enquiryIdBool">
                                                        <mat-form-field appearance="standard">
                                                            <input matInput class="form-control-plaintext"
                                                                formControlName="width" type="number"
                                                                placeholder="Enter Width" min="0"
                                                                (change)="calculateTotalVolume()" />
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="enquiryIdBool">
                                                        <input class="form-control-plaintext" formControlName="width">
                                                    </div>
                                                </td>
                                                <td>
                                                    <div *ngIf="!enquiryIdBool">
                                                        <mat-form-field appearance="standard">
                                                            <input class="form-control-plaintext" matInput
                                                                formControlName="height" type="number"
                                                                placeholder="Enter Height" min="0"
                                                                (change)="calculateTotalVolume()" />
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="enquiryIdBool">
                                                        <input class="form-control-plaintext" formControlName="height">
                                                    </div>
                                                </td>
                                                <td>
                                                    <div *ngIf="!enquiryIdBool">
                                                        <mat-form-field appearance="fill">
                                                            <select matNativeControl
                                                                class="form-control-plaintext form-select"
                                                                formControlName="dimensionsUOM"
                                                                (change)="calculateTotalVolume()">
                                                                <option *ngFor="let item of dimensionsUOM"
                                                                    [value]="item.name">
                                                                    {{ item.name }}
                                                                </option>
                                                            </select>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="enquiryIdBool">
                                                        <select class="form-control-plaintext select-arrow"
                                                            formControlName="dimensionsUOM">
                                                            <option *ngFor="let item of dimensionsUOM"
                                                                [value]="item.name">
                                                                {{ item.name }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div *ngIf="!enquiryIdBool">
                                                        <mat-form-field appearance="standard">
                                                            <input matInput class="form-control-plaintext"
                                                                formControlName="grossWeight" type="number"
                                                                placeholder="Enter Gross Weight" min="0"
                                                                (change)="convertToMTsea()" />
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="enquiryIdBool">
                                                        <input class="form-control-plaintext"
                                                            formControlName="grossWeight">
                                                    </div>
                                                </td>
                                                <td>
                                                    <div *ngIf="!enquiryIdBool">
                                                        <mat-form-field appearance="fill">
                                                            <select matNativeControl
                                                                class="form-control-plaintext form-select"
                                                                formControlName="weightUOM" (change)="convertToMTsea()">
                                                                <option value="" selected>Select</option>
                                                                <option *ngFor="let item of grossWeightUom"
                                                                    [value]="item.name">
                                                                    {{ item.name }}
                                                                </option>
                                                            </select>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="enquiryIdBool">
                                                        <select class="form-control-plaintext select-arrow"
                                                            formControlName="weightUOM">
                                                            <option *ngFor="let item of grossWeightUom"
                                                                [value]="item.name">
                                                                {{ item.name }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div *ngIf="!enquiryIdBool">
                                                        <mat-form-field appearance="standard">
                                                            <input matInput class="form-control-plaintext" type="number"
                                                                placeholder="Enter Volume" min="0"
                                                                formControlName="totalVolume" />
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="enquiryIdBool">
                                                        <input class="form-control-plaintext"
                                                            formControlName="totalVolume">
                                                    </div>
                                                </td>
                                                <td>
                                                    <button class="btn-outline-danger fa fa-trash"
                                                        (click)="deleteSeaHCL(i)"
                                                        *ngIf="package_details.length > 1 && hide_btn">
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot class="table-success">
                                            <tr>
                                                <th>TOTAL</th>
                                                <!-- <td></td> -->
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td nowrap *ngIf="finalSeaWeightBool; else gross">
                                                    Chargable Weight</td>
                                                <ng-template #gross>
                                                    <td></td>
                                                </ng-template>
                                                <td>{{ totalGrossWeightLCL | number: '1.2-3' }} {{ finalSeaWeightUom }}
                                                </td>
                                                <td nowrap *ngIf="!finalSeaWeightBool; else volume">
                                                    Chargable Weight</td>
                                                <ng-template #volume>
                                                    <td></td>
                                                </ng-template>
                                                <td>{{ totalVolumeWeightLCL | number: '1.2-4' }} CBM</td>
                                                <!-- <td></td> -->
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>

                                <!-- Break Bulk Option -->
                                <div *ngIf="breakBulkBoolOption">
                                    <div class="form-check-wrap">
                                        <div class="col-md-6">
                                            <label class="font-label m-2">Break Bulk ?</label>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" title="Yes" [value]="true"
                                                    formControlName="break_bulk_label" name="break_bulk_label"
                                                    (click)="openBreakBulkTable()"> Yes
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" title="No" [value]="false"
                                                    checked formControlName="break_bulk_label" name="break_bulk_label"
                                                    (click)="closeBreakBulkTable()"> No
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Sea BreakBulk Details -->
                                <div *ngIf="breakbulkBool">
                                    <div class="row mb-3">
                                        <div class="col-md-7" style="padding-left: 30%;">
                                            <h4 class="mb-0 text-center">Break Bulk Freight</h4>
                                        </div>
                                        <div class="col-md-4 mt-2 filename">
                                            <div *ngFor="let item of breakBulkFile;index">
                                                <div *ngIf="enquiryIdBool">
                                                    <button (click)="downloadPackageslip(item)"><a
                                                            class="anchor-tag">{{item.label}}</a></button>
                                                </div>
                                                <div *ngIf="!enquiryIdBool">
                                                    <button (click)="downloadPackageslip(item)"><a
                                                            class="anchor-tag">{{item.name}}</a></button>
                                                    <button *ngIf="item.artifactId">
                                                        <i class="fa fa-trash color-blue" title="Delete"
                                                            (click)="deletePackagSlipServer(item.artifactId,index)"></i>
                                                    </button>
                                                    <button *ngIf="!item.artifactId">
                                                        <i class="fa fa-trash color-blue" title="Delete"
                                                            (click)="deletePacakgeslip(index)"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-1">
                                            <button *ngIf="!enquiryIdBool" class="btn-outline-success"
                                                (click)="file.click()" title="Upload File">
                                                <input type="file" class="file-input" #file (change)="onChange($event)"
                                                    accept=".csv, .xlsx">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                    fill="currentColor" class="bi bi-upload" viewBox="0 0 16 16">
                                                    <path
                                                        d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                    <path
                                                        d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="table-responsive">
                                        <table id="breakbulk"
                                            class="table table-borderless text-center table-head-dark table-head-condensed table-head-py-3">
                                            <thead>
                                                <tr>
                                                    <th>Total Net Weight</th>
                                                    <th>Total Gross Weight</th>
                                                    <th>Gross Weight UOM</th>
                                                    <th>Total Volume</th>
                                                </tr>
                                            </thead>
                                            <tbody formArrayName="breakBulks">
                                                <tr *ngFor="let item of break_bulk.controls; let i=index; let l=last"
                                                    [formGroupName]="i">
                                                    <td>
                                                        <div *ngIf="!enquiryIdBool">
                                                            <mat-form-field appearance="standard">
                                                                <input matInput class="form-control-plaintext"
                                                                    type="number" placeholder="Enter Net Weight"
                                                                    formControlName="totalnetWeight" min="0">
                                                            </mat-form-field>
                                                        </div>
                                                        <div *ngIf="enquiryIdBool">
                                                            <input class="form-control-plaintext"
                                                                formControlName="totalnetWeight">
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div *ngIf="!enquiryIdBool">
                                                            <mat-form-field appearance="standard">
                                                                <input matInput class="form-control-plaintext"
                                                                    type="number" placeholder="Enter Gross Weight"
                                                                    formControlName="totalGrossWeight" min="0"
                                                                    (change)="calculateBreakBulkWeight(i)">
                                                            </mat-form-field>
                                                        </div>
                                                        <div *ngIf="enquiryIdBool">
                                                            <input class="form-control-plaintext"
                                                                formControlName="totalGrossWeight">
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div *ngIf="!enquiryIdBool">
                                                            <!-- formControlName="totalGrossWeightUOM" -->
                                                            <mat-form-field appearance="fill">
                                                                <select matNativeControl
                                                                    formControlName="uom"
                                                                    (change)="calculateBreakBulkWeight(i)">
                                                                    <option value="" selected>Select</option>
                                                                    <option *ngFor="let item of grossWeightUom"
                                                                        [value]="item.name">
                                                                        {{ item.name }}
                                                                    </option>
                                                                </select>
                                                            </mat-form-field>
                                                        </div>
                                                        <div *ngIf="enquiryIdBool">
                                                            <input class="form-control-plaintext"
                                                                formControlName="uom">
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div *ngIf="!enquiryIdBool">
                                                            <mat-form-field appearance="standard">
                                                                <input matInput class="form-control-plaintext"
                                                                    placeholder="Enter Total Volume" type="number"
                                                                    formControlName="totalVolume" min="0"
                                                                    (change)="calculateBreakBulkWeight(i)">
                                                            </mat-form-field>
                                                        </div>
                                                        <div *ngIf="enquiryIdBool">
                                                            <input class="form-control-plaintext"
                                                                formControlName="totalVolume">
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot class="table-success">
                                                <tr>
                                                    <td *ngIf="totalBreakBulkWeight > totalBreakBulkVolume; else breakWeight"
                                                        nowrap>Chargable Weight
                                                    </td>
                                                    <ng-template #breakWeight>
                                                        <td></td>
                                                    </ng-template>
                                                    <td>{{ totalBreakBulkWeight | number: '1.2-3' }} MT</td>
                                                    <td *ngIf="totalBreakBulkVolume > totalBreakBulkWeight; else breakVolume"
                                                        nowrap>Chargable Weight
                                                    </td>
                                                    <ng-template #breakVolume>
                                                        <td></td>
                                                    </ng-template>
                                                    <td>{{ totalBreakBulkVolume | number: '1.2-4' }} CBM</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="air_mode_shipment">
                                <button class="btn-add-new text-dark btn-outline-success" *ngIf="hide_btn"
                                    style="float: right;" (click)="addAirRow()">
                                    <i class="fa fa-plus me-1"></i><span>Package</span>
                                </button>
                                <h4 class="text-center my-3 my-lg-4">Package Details</h4>
                                <div class="table-responsive">
                                    <table
                                        class="table table-borderless text-center table-head-dark table-head-condensed table-head-py-3">
                                        <thead>
                                            <tr>
                                                <th>Quantity</th>
                                                <th>Length</th>
                                                <th>Width</th>
                                                <th>Height</th>
                                                <th>Unit of Measurement</th>
                                                <th>Gross Weight</th>
                                                <th> &nbsp;&nbsp;&nbsp;Weight UOM &nbsp;&nbsp;&nbsp;</th>
                                                <th>Total Volume</th>
                                                <th *ngIf="package_details.length > 1 && hide_btn">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody formArrayName="shipmentPackages">
                                            <tr *ngFor="let item of package_details.controls; let i=index; let l=last"
                                                [formGroupName]="i">
                                                <td>
                                                    <div *ngIf="!enquiryIdBool">
                                                        <mat-form-field appearance="standard">
                                                            <input matInput class="form-control-plaintext"
                                                                placeholder="Enter Quantity" formControlName="quantity"
                                                                (change)="calculateAirVolume()" type="number" id=""
                                                                min="0" />
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="enquiryIdBool">
                                                        <input class="form-control-plaintext"
                                                            formControlName="quantity">
                                                    </div>
                                                </td>
                                                <td>
                                                    <div *ngIf="!enquiryIdBool">
                                                        <mat-form-field appearance="standard">
                                                            <input matInput class="form-control-plaintext"
                                                                placeholder="Enter Lenght" formControlName="length"
                                                                (change)="calculateAirVolume()" type="number" id=""
                                                                min="0" />
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="enquiryIdBool">
                                                        <input class="form-control-plaintext" formControlName="length">
                                                    </div>
                                                </td>
                                                <td>
                                                    <div *ngIf="!enquiryIdBool">
                                                        <mat-form-field appearance="standard">
                                                            <input matInput class="form-control-plaintext"
                                                                placeholder="Enter Width" formControlName="width"
                                                                (change)="calculateAirVolume()" type="number" id=""
                                                                min="0" />
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="enquiryIdBool">
                                                        <input class="form-control-plaintext" formControlName="width">
                                                    </div>
                                                </td>
                                                <td>
                                                    <div *ngIf="!enquiryIdBool">
                                                        <mat-form-field appearance="standard">
                                                            <input matInput class="form-control-plaintext"
                                                                placeholder="Enter Height" formControlName="height"
                                                                (change)="calculateAirVolume()" type="number" id=""
                                                                min="0" />
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="enquiryIdBool">
                                                        <input class="form-control-plaintext" formControlName="height">
                                                    </div>
                                                </td>
                                                <td>
                                                    <div *ngIf="!enquiryIdBool">
                                                        <mat-form-field appearance="fill">
                                                            <select matNativeControl class="form-control-plaintext"
                                                                (change)="calculateAirVolume()"
                                                                formControlName="dimensionsUOM">
                                                                <option value="" selected>Select</option>
                                                                <option *ngFor="let item of dimensionsUOM"
                                                                    [value]="item.name">
                                                                    {{ item.name }}
                                                                </option>
                                                            </select>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="enquiryIdBool">
                                                        <select class="form-control-plaintext select-arrow"
                                                            formControlName="dimensionsUOM">
                                                            <option *ngFor="let item of dimensionsUOM"
                                                                (change)="calculateAirVolume()" selected
                                                                [value]="item.name">
                                                                {{ item.name }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div *ngIf="!enquiryIdBool">
                                                        <mat-form-field appearance="standard">
                                                            <input matInput class="form-control-plaintext"
                                                                placeholder="Enter Gross Weight"
                                                                formControlName="grossWeight"
                                                                (change)="convertToMTAIR()" type="number" id=""
                                                                min="0" />
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="enquiryIdBool">
                                                        <input class="form-control-plaintext"
                                                            formControlName="grossWeight">
                                                    </div>
                                                </td>
                                                <td>
                                                    <div *ngIf="!enquiryIdBool">
                                                        <mat-form-field appearance="fill">
                                                            <select matNativeControl class="form-control-plaintext"
                                                                (change)="convertToMTAIR()" formControlName="weightUOM">
                                                                <option value="" selected>Select</option>
                                                                <option *ngFor="let item of grossWeightUomAir"
                                                                    [value]="item.name">
                                                                    {{ item.name }}
                                                                </option>
                                                            </select>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="enquiryIdBool">
                                                        <select class="form-control-plaintext select-arrow"
                                                            formControlName="weightUOM" (change)="convertToMTAIR()">
                                                            <option *ngFor="let item of grossWeightUomAir"
                                                                [value]="item.name">
                                                                {{ item.name }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div *ngIf="!enquiryIdBool">
                                                        <mat-form-field appearance="standard">
                                                            <input matInput class="form-control-plaintext"
                                                                placeholder="Enter Lenght" formControlName="totalVolume"
                                                                type="number" id="" min="0" />
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="enquiryIdBool">
                                                        <input class="form-control-plaintext"
                                                            formControlName="totalVolume">
                                                    </div>
                                                </td>
                                                <td>
                                                    <button class="btn-outline-danger fa fa-trash"
                                                        (click)="deleteAirRow(i)"
                                                        *ngIf="package_details.length > 1 && hide_btn">
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot class="table-success">
                                            <tr>
                                                <th>TOTAL</th>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td nowrap *ngIf="finalAirWeightBool; else gross_air">
                                                    Chargable Weight</td>
                                                <ng-template #gross_air>
                                                    <td></td>
                                                </ng-template>
                                                <td>{{ totalGrossWeightAIR | number: '1.2-3' }} {{ finalAirWeightUom }}
                                                </td>
                                                <td nowrap *ngIf="!finalAirWeightBool; else volume_air">Chargable Weight
                                                </td>
                                                <ng-template #volume_air>
                                                    <td></td>
                                                </ng-template>
                                                <td>{{ totalVolumeWeightAIR | number: '1.2-4' }} CBM</td>
                                                <td></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>

                            <div class="row justify-content-center">
                                <div class="col-md-6 form-group pt-4 pt-lg-5">
                                    <button class="btn btn-success w-100" type="button" (click)="secondPage()"
                                        data-target="item-2">Next</button>
                                </div>
                            </div>
                            <div class="row" align="right">
                                <div class="col-lg-12">
                                    <p class="bg-white text-dark">
                                        Note: * denotes mandatory fields
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- Assign vendors -->
                        <div class="tab-pane" id="item-6">
                            <h3 class="text-center">Auction Invite</h3>

                            <div class="row">
                                <div class="col-lg-6 form-group">
                                    <label class="form-label" for="vendorType">Vendor Type :</label>
                                    <!-- Make changes here -->
                                    <select id="vendorType" class="form-control form-select" [(ngModel)]="vendorBusinessType" [ngModelOptions]="{standalone: true}"
                                        (change)="filterVendorData()">
                                        <option value="" selected>ALL</option>
                                        <option *ngFor="let business of vendorBusinessTypeList"  [value]="business.id">
                                            {{ business.value }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-lg-6 form-group">
                                    <label class="form-label">Search :</label>
                                    <input class="form-control" type="text" (keyup)="applyFilter($event)"
                                        placeholder="Search" #input>
                                </div>
                            </div>

                            <div class="table-responsive">
                                <table class="table table-borderless table-striped table-head-dark table-head-condensed table-head-py-3"
                                    mat-table [dataSource]="dataSource" matSort matSortDisableClear>
                                    <ng-container matColumnDef="actions">
                                        <th mat-header-cell class="text-white" *matHeaderCellDef>
                                            Actions
                                            <input class="form-check-input mt-0" id="actions" type="checkbox" (click)="addAllVendors($event)">
                                        </th>
                                        <td mat-cell *matCellDef="let item; let i = index">
                                            <input class="form-check-input" type="checkbox" [(ngModel)]="item.flag"
                                                [ngModelOptions]="{standalone: true}"
                                                (change)="addVendors(item.id, i, item.flag)" id="check">
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="vendorName">
                                        <th mat-header-cell class="text-white" *matHeaderCellDef mat-sort-header> Vendor Name
                                        </th>
                                        <td mat-cell *matCellDef="let row">{{ row.name }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="businessType">
                                        <th mat-header-cell class="text-white" *matHeaderCellDef mat-sort-header> Business Type
                                        </th>
                                        <td mat-cell *matCellDef="let row">{{ row.businessType?.value }}</td>
                                    </ng-container>

                                    <tr class="text-left bg-dark" mat-header-row *matHeaderRowDef="displayedColumns">
                                    </tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                                    <tr class="mat-row" *matNoDataRow>
                                        <td class="mat-cell text-center text-danger" colspan="8">
                                            <h5>Data is Not Available</h5>
                                        </td>
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[25, 50, 100]" [length]="vendorsLength" showFirstLastButtons
                                    (page)="onPageChange($event)">
                                </mat-paginator>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-md-6 form-group pt-4 pt-lg-5">
                                    <button class="btn btn-success w-100" type="button" data-target="item-3"
                                        (click)="thirdPage()">Next</button>
                                </div>
                            </div>
                        </div>
                        <!-- Terms & Conditions -->
                        <div class="tab-pane" id="item-7">
                            <div class="row justify-content-center align-items-center">
                                <div class="col-lg-9">
                                    <h3 class="text-center">Terms &amp; Conditions</h3>
                                </div>
                                <div class="col-lg-3">
                                    <button style="float: right; height: 45px;"
                                        class="text-dark text-small btn-outline-success" (click)="addTermsCondition()">
                                        <i class="fa fa-plus me-1" aria-hidden="true"></i>
                                        <span class="">Terms & Conditions</span>
                                    </button>
                                </div>
                            </div>
                            <br>
                            <div formArrayName="terms">
                                <div class="row mb-4 justify-content-center align-items-center"
                                    *ngFor="let item of termsConditions.controls; index as i" [formGroupName]="i">
                                    <div class="col-lg-11">
                                        <input type="text" formControlName="label" class="form-control terms-label"
                                            placeholder="Enter Label Here">
                                        <textarea class="form-control" rows="6"
                                            placeholder="Type your terms &amp; Conditions Here"
                                            formControlName="content" id="terms-&amp;-conditions"></textarea>
                                    </div>
                                    <div class="col-lg-1">
                                        <button class="fa fa-trash color-blue" (click)="deleteTermsCondition(i)">
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <h4 class="text-center">OR</h4>
                            <div class="row mt-4">
                                <div class="col-lg-6 justify-content-center align-items-center">
                                    <ngx-file-drop dropZoneClassName="file-drop-2" dropZoneLabel="Drop files here"
                                        (onFileDrop)="addFiles($event)" accept=".jpg, .png, .pdf, .docx, .txt, .jpeg">
                                        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                                            <div class="row mt-5 pt-5 text-center">
                                                <div class="col-lg-12 pt-5 mt-5">
                                                    <i class="color-green far fa-file-alt fa-3x"></i>
                                                </div>
                                                <div class="col-lg-12 pt-2 pb-2 text-dark">
                                                    Upload Multiple Files By Drag & Drop OR Click Browse Files
                                                </div>
                                                <div class="col-lg-12">
                                                    <button class="btn btn-success" type="button"
                                                        (click)="openFileSelector()">Browse
                                                        Files</button>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ngx-file-drop>
                                </div>
                                <div class="col-lg-6">
                                    <div class="w3-card-4 note-border">
                                        <div class="card-header note-bg">
                                            <h3 class="text-white text-center">Notes</h3>
                                        </div>
                                        <div class="card-body bg-white">
                                            <ul>
                                                <li class="pb-2">Allowed File are .jpg, .png, .pdf, .docx, .txt, .jpeg,
                                                    .doc , .xls , .xlsx</li>
                                                <li class="pb-2">Upload Multiple Files By Drag & Drop OR Click Browse
                                                    Files</li>
                                                <li class="pb-2">Max size per file 2MB</li>
                                                <li class="pb-2">Max size of all files 10MB</li>
                                                <li class="pb-2">Use Delete button
                                                    <span>
                                                        <i class="fa fa-trash color-blue"></i>
                                                    </span> to remove the uploaded file
                                                </li>
                                                <li class="pb-2">
                                                    Terms & Conditions is mandatory field. Either upload the file or
                                                    enter the details.
                                                </li>
                                                <li class="pb-2">Labels to be provided for Each File, Terms & Conditions
                                                </li>
                                                <li class="pb-2">
                                                    Document will be uploaded with SUBMIT/UPDATE button.
                                                </li>
                                                <li class="pb-2">
                                                    Use<span><i class="fa fa-download"></i></span> to Download file
                                                </li>
                                                <li>
                                                    <span>
                                                        <i class="fas fa-ban"></i>
                                                    </span> Uploaded document is over-sized.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="table-responsive mt-4">
                                    <form>
                                        <table
                                            class="table table-borderless table-striped table-head-dark table-head-condensed table-head-py-3">
                                            <thead>
                                                <tr>
                                                    <th>Sr.No</th>
                                                    <th>File Name</th>
                                                    <th>Label</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of documentList; index as i">
                                                    <td>{{ i + 1 }}</td>
                                                    <td>{{ item.name }}</td>
                                                    <td>
                                                        <input type="text" [(ngModel)]="item.label"
                                                            [ngModelOptions]="{standalone: true}">
                                                    </td>
                                                    <td>
                                                        <div class="row">
                                                            <div class="col-md-3">
                                                                <button *ngIf="item.artifactId"
                                                                    (click)="downloadFileLocal(item)">
                                                                    <i class="fa fa-download" aria-hidden="true"
                                                                        title="Download"></i>
                                                                </button>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <button title="Oversized" class="text-danger" *ngIf="(math.round(item.size) /1024) > 2048">
                                                                    <i class="fas fa-ban" aria-hidden="true"></i>
                                                                </button>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <button title="Uploaded" class="text-primary" *ngIf="item.artifactId">
                                                                    <i class="fa fa-check" aria-hidden="true"></i>
                                                                </button>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <button title="Delete" *ngIf="item.artifactId; else localDelete">
                                                                    <i class="fa fa-trash color-blue"
                                                                        (click)="deleteServerFile(item.artifactId, i)"></i>
                                                                </button>
                                                                <button title="Delete" *ngIf="!item.artifactId">
                                                                    <i class="fa fa-trash color-blue" (click)="deleteDocument(i)"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </form>
                                </div>
                                <hr>
                            </div>
                            <div class="row justify-content-center">
                                <div *ngIf="!auctionUpdateBool" class="col-md-7 form-group pt-4 pt-lg-5">
                                    <button *ngIf="enquiryIdBool; else create" class="btn btn-success w-100"
                                        (click)="createAuction()" type="submit">submit</button>
                                    <ng-template #create>
                                        <button type="submit" (click)="createNewAuction()"
                                            class="btn btn-success w-100">CREATE</button>
                                    </ng-template>
                                </div>
                                <div *ngIf="auctionUpdateBool" class="col-md-7 form-group pt-4 pt-lg-5">
                                    <button class="btn btn-success w-100" (click)="updateAuction()"
                                        type="submit">UPDATE</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
