import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceOrderService } from '../../helpers/service-order.service'
import { QuoteService } from 'src/app/helpers/quote.service';
import { DatePipe, DecimalPipe } from '@angular/common'
import Swal from 'sweetalert2';
import { MasterTableService } from 'src/app/helpers/master-table.service';
import { SocketService } from 'src/app/helpers/socket.service';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { AuctionService } from 'src/app/helpers/auction.service';
import { OnboardService } from 'src/app/helpers/onboard.service';

@Component({
  selector: 'app-service-order',
  templateUrl: './service-order.component.html',
  styleUrls: ['./service-order.component.css']
})
export class ServiceOrderComponent implements OnInit {
  public serviceOrder: FormGroup;

  qouteId: any;
  bidId: any;
  serviceOrderId: any = undefined;
  enquiryObject: any = {} || null;
  public currencyPicker = [];
  auctionObject: any = {} || null;
  public countryPicker = [];
  break_bulk: boolean = false;
  sea_shipment: boolean = false;
  air_shipment: boolean = false;
  additional_charges: Boolean = false;
  currency: string;
  rate: Array<any> = [];

  updateServiceOrder: Boolean = false;

  dashboardBool: Boolean = true;
  backDetailsPage: Boolean = false;
  backShipmentPage: Boolean = false;
  backServiceRatePage: Boolean = false;
  backTermConditionPage: Boolean = false;
  container_type: Array<any> = [];
  mode_of_shipment: Array<any> = [];
  trade_type: Array<any> = [];
  originDestination: Array<any> = [];
  type_cargo: Array<any> = [];
  nature_of_movement: Array<any> = [];
  pickup_terms: Array<any> = [];
  dropoff_terms: Array<any> = [];
  nature_goods: Array<any> = [];
  type_packing: Array<any> = [];
  disabled: Boolean = true;
  halfContianerLoadBool: boolean = false

  vendorQuote: any = {} || null;
  vendorBidObject: any = {} || null;

  finalAirWeightUom: string
  finalSeaWeightUom: string

  isCreatingFromEnquiry: boolean = false
  isCreatingFromAuction: boolean = false

  public container_load_type = [
    { 'id': 1, 'value': 'Full Container Load (FCL)' },
    { 'id': 2, 'value': 'Less than Container Load (LCL)' },
    { 'id': 3, 'value': 'Both' },
    { 'id': 4, 'value': 'Break Bulk' }
  ]

  completeDate: Date;
  localCompleteDate: string;
  math = Math;
  searchOriginValue: string
  searchDestinationValue: string
  destinationPorts: Array<any> = [];
  loadingPorts: Array<any> = [];
  vendorList: Array<any> = [];

  constructor(private route: ActivatedRoute, private router: Router, private formbuilder: FormBuilder, private apiservice: ServiceOrderService,
    private quoteService: QuoteService, private masterTableService: MasterTableService, private decimalPipe: DecimalPipe, private socketService: SocketService,
    private auctionService: AuctionService, private datePipe: DatePipe, private onBoardService: OnboardService) { }

  get service() { return this.serviceOrder.controls; }
  get shipmentDetails() { return this.service.shipmentDetails as FormGroup; }
  get consigneeDetail() { return this.service.consigneeDetail as FormGroup; }
  get typeOfCargo() { return this.service.shipmentDetails.get('typeOfCargo') as FormGroup; }
  get termsArray() { return this.serviceOrder.get('terms') as FormArray }
  get enquiryGroup() { return this.serviceOrder.get('enquiry') as FormGroup } // No usage

  documentList: Array<any> = [];

  ngOnInit(): void {
    this.getMasterTablesSessionStorage()
    const date = new Date();

    this.serviceOrder = this.formbuilder.group({
      amendentDate: [''],
      amendentNumber: null,
      auctionDate: [''],
      auctionNumber: [''],
      quotationDate: [''],
      quotationNumber: [''],
      contactPersonName: [''],
      emailId: [''],
      forwaderContactNumber: null,
      forwaderName: [''],
      number: [''],
      serviceOrderDate: [this.datePipe.transform(date, 'yyyy-MM-dd')],
      tradeType: [''],
      status: [''],

      //new
      nameOfCHA: [''],
      placeOfReceipt: ['', Validators.required],
      placeOfDelivery: ['', Validators.required],
      routing: [''],
      carrier: [''],
      contractValidity: [''],
      laycan: [''],
      volume: [],
      exRate: [''],
      reference: [''],
      currency: this.formbuilder.group({
        id: ['', Validators.required]
      }),

      consigneeDetail: this.formbuilder.group({
        consigneeName: [''],
        streetAddress1: [''],
        streetAddress2: [''],
        city: [''],
        state: [''],
        country: [''],
        zipcode: ['']
      }),

      shipmentDetails: this.formbuilder.group({
        projectCode: [''],
        projectName: [''],
        serviceRequiredDate: [''],
        inTransitTime: [''],
        pickUpTerms: [''],
        deliveryTerms: [''],
        transitPort: [''],
        totalGrossWeight: 0,
        vesselFrequency: [''],
        exchangeRate: 0,
        commodity: [''],
        origin: [''],
        destination: [''],
        containerLoadType: [''],
        hsCode: ['', Validators.required],
        modeOfShipment: this.formbuilder.group({
          id: ['', Validators.required]
        }),
        natureOfMovement: this.formbuilder.group({
          code: ['', Validators.required]
        }),
        typeOfCargo: this.formbuilder.group({
          id: ['', Validators.required]
        }),
      }),

      enquiry: this.formbuilder.group({}),
      vendorQuoteRow: this.formbuilder.group({}),
      auction: this.formbuilder.group({}),
      vendorBid: this.formbuilder.group({}),

      terms: this.formbuilder.array([]),
      artifacts: this.formbuilder.array([]),
      serviceAndRateDetails: this.formbuilder.array([]),
      total: ['', Validators.required],
      vendor: this.formbuilder.group({
        id: ['', Validators.required]
      })
    })

    if (this.route.snapshot.routeConfig.path.includes('edit')) {
      this.updateServiceOrder = true;
      this.serviceOrderId = this.route.snapshot.paramMap.get("id");
      this.fetchServiceOrderById(this.serviceOrderId);
      this.getServiceOrderArtifacts(this.serviceOrderId);
    }
    else {
      if (this.route.snapshot.paramMap.get('type') == "a") {
        this.bidId = this.route.snapshot.paramMap.get("id");
        this.fetchVendorBid(this.bidId);
      }
      else if (this.route.snapshot.paramMap.get('type') == "q") {
        this.qouteId = this.route.snapshot.paramMap.get("id");
        this.fetchQuoteById(this.qouteId);
      }
    }
    // this.socketService.connectTOServerPopUpFuction();
  }

  addTermsAndConditions() {
    const term = this.formbuilder.group({
      label: ['', Validators.required],
      content: ['', Validators.required],
    })
    if (!this.termsArray.invalid) {
      this.termsArray.push(term);
    }
    else {
      this.termsArray.markAllAsTouched();
      Swal.fire({
        timer: 5000,
        icon: "warning",
        titleText: "Warning",
        text: "Fill all the fields"
      });
    }
  }

  prePopulateTerms(data: any): FormGroup{
    return this.formbuilder.group({
      label: [data.label, Validators.required],
      content: [data.content, Validators.required],
    })
  }

  get artifacts() {
    return this.serviceOrder.get('artifacts') as FormArray;
  }

  get serviceRateDetails(){
    return this.serviceOrder.get('serviceAndRateDetails') as FormArray;
  }

  get currencyFormGroup(){
    return this.serviceOrder.get('currency') as FormGroup;
  }

  initServiceRateDetails(): FormGroup{
    return this.formbuilder.group({
      description: ['', Validators.required],
      quantity: ['', Validators.required],
      rate: ['', Validators.required],
      total: []
    })
  }

  prePopulateServiceRateDetails(data): FormGroup{
    return this.formbuilder.group({
      description: data.description,
      quantity: data.quantity,
      rate: data.rate,
      total: data.total
    })
  }

  addServiceRateDetails(){
    this.serviceRateDetails.push(this.initServiceRateDetails());
  }

  removeServiceRateDetails(index: number){
    this.serviceRateDetails.removeAt(index);
    this.totalCalculationOfRateDetails();
  }

  addArtifacts() {
    return this.formbuilder.group({
      content: [''],
      contentType: [''],
      label: [''],
      name: ['']
    });
  }

  prePopulateArtifacts(data){
    return this.formbuilder.group({
      content: [data.content],
      contentType: [data.contentType],
      label: [data.label],
      name: [data.name]
    });
  }

  addFiles(files: NgxFileDropEntry[]) {
    for (let index = 0; index < files.length; index++) {
      const droppedFile = files[index];
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          // Here you can access the real file size & content
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            let data = {
              "content": reader.result,
              "contentType": reader.result?.toString().split(',')[0],
              "name": file.name,
              "size": file.size
            }
            this.documentList.push(data);
          }
        })
      }
      else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }

  defaultOneTermsAndConditions() {
    return this.formbuilder.group({
      label: ['', Validators.required],
      content: ['', Validators.required],
    })
  }

  deleteTermsAndConditions(index: number) {
    this.termsArray.removeAt(index);
  }

  prePopulateTermsConditions(data: any): FormGroup {
    return this.formbuilder.group({
      content: [data.content],
      label: [data.label]
    })
  }

  // Update Service Order
  fetchServiceOrderById(id) {
    this.apiservice.getServiceOrderById(id).subscribe((response) => {
      console.log(response);
      if (response != null) {
        if (response.enquiry != null) {
          this.isCreatingFromEnquiry = true
          this.enquiryObject = response.enquiry;
          this.vendorQuote = response.vendorQuoteRow
          this.service.quotationNumber.patchValue(response.quotationNumber || null);
          this.service.quotationDate.patchValue(this.vendorQuote.vendorQuote.quoteDate);
        }
        else if(response.auction != null){
          this.isCreatingFromAuction = true;
          this.auctionObject = response.auction;
          this.vendorBidObject = response.vendorBid;
          this.service.auctionDate.patchValue(response.auctionDate || null);
          this.service.auctionNumber.patchValue(response.auctionNumber || null);
        }
        this.service.number.patchValue(response.number);
        this.service.emailId.patchValue(response.emailId);
        this.service.forwaderContactNumber.patchValue(response.forwaderContactNumber);
        this.service.contactPersonName.patchValue(response.contactPersonName);
        this.service.forwaderName.patchValue(response.forwaderName);
        this.service.tradeType.patchValue(response.tradeType);
        this.service.serviceOrderDate.patchValue(response.serviceOrderDate);
        this.service.nameOfCHA.patchValue(response.nameOfCHA)
        this.service.status.patchValue(response.status);
        this.service.placeOfDelivery.patchValue(response.placeOfDelivery);
        this.service.placeOfReceipt.patchValue(response.placeOfReceipt);
        this.service.amendentNumber.patchValue(response.amendentNumber);
        this.service.amendentDate.patchValue(response.amendentDate);
        this.service.carrier.patchValue(response.carrier);
        this.service.contractValidity.patchValue(response.contractValidity);
        this.service.laycan.patchValue(response.laycan);
        this.service.volume.patchValue(response.volume);
        this.service.exRate.patchValue(response.exRate);
        this.service.routing.patchValue(response.routing);
        this.service.reference.patchValue(response.reference);
        this.service.currency.patchValue(response.currency);
        if(response.consigneeDetail != null){
          this.consigneeDetail.get('consigneeName').patchValue(response.consigneeDetail.consigneeName);
          this.consigneeDetail.get('city').patchValue(response.consigneeDetail.city);
          this.consigneeDetail.get('state').patchValue(response.consigneeDetail.state);
          this.consigneeDetail.get('country').patchValue(response.consigneeDetail.country);
          this.consigneeDetail.get('streetAddress1').patchValue(response.consigneeDetail.streetAddress1);
          this.consigneeDetail.get('streetAddress2').patchValue(response.consigneeDetail.streetAddress2);
          this.consigneeDetail.get('zipcode').patchValue(response.consigneeDetail.zipcode);
        }

        if(response.shipmentDetail != null){
          let shipment = response.shipmentDetail
          this.shipmentDetails.get('commodity').patchValue(shipment.commodity);
          this.shipmentDetails.get('projectCode').patchValue(shipment.projectCode);
          this.shipmentDetails.get('projectName').patchValue(shipment.projectName);
          this.shipmentDetails.get('projectName').patchValue(shipment.projectName);
          this.shipmentDetails.get('inTransitTime').patchValue(shipment.inTransitTime);
          this.shipmentDetails.get('serviceRequiredDate').patchValue(shipment.serviceRequiredDate);
          this.shipmentDetails.get('totalGrossWeight').patchValue(shipment.totalGrossWeight);
          this.shipmentDetails.get('transitPort').patchValue(shipment.transitPort);
          this.shipmentDetails.get('modeOfShipment').get('id').patchValue(shipment.modeOfShipment?.id);
          this.shipmentDetails.get('deliveryTerms').patchValue(shipment.deliveryTerms);
          this.shipmentDetails.get('pickUpTerms').patchValue(shipment.pickUpTerms);
          if(shipment.modeOfShipment.id == 1){
            this.air_shipment = true;
          }
          else if(shipment.modeOfShipment.id == 2){
            this.shipmentDetails.get('containerLoadType').patchValue(shipment.containerLoadType);
            this.shipmentDetails.get('hsCode').patchValue(shipment.hsCode);
            this.sea_shipment = true;
          }
          else if(shipment.modeOfShipment.id == 3){
            console.log("Nothing");
          }
          else if(shipment.modeOfShipment.id == 4){
            console.log("Nothing");
          }
          this.shipmentDetails.get('natureOfMovement').get('code').patchValue(shipment.natureOfMovement.code);
          this.shipmentDetails.get('typeOfCargo').get('id').patchValue(shipment.typeOfCargo.id);
          this.shipmentDetails.get('vesselFrequency').patchValue(shipment.vesselFrequency);
        }
        this.currency = response.currency.currency
        let rates: Array<any> = response.serviceAndRateDetails;
        if(rates.length > 0) {
          let total = 0;
          for (let index = 0; index < rates.length; index++) {
            const element = rates[index];
            this.serviceRateDetails.push(this.prePopulateServiceRateDetails(element));
            total = element.total + total
          }
          this.service.total.patchValue(total)
        }

        let terms: Array<any> =  response.terms;
        if(terms.length > 0){
          for (let index = 0; index < terms.length; index++) {
            const element = terms[index];
            this.termsArray.push(this.prePopulateTerms(element));
          }
        }
        
        if(response.vendor != null){
          this.serviceOrder.get('vendor').get('id').patchValue(response.vendor.id)
        }
        // ToDo, changes needed.492
        this.searchOriginValue = response.originPortName;
        this.searchDestinationValue = response.destinationPortName;
        this.getSearchResultsOrigin();
        this.getSearchResultDestination();
        this.shipmentDetails.get('origin').patchValue(response.shipmentDetail.origin);
        this.shipmentDetails.get('destination').patchValue(response.shipmentDetail.destination);
        console.log(this.shipmentDetails.get('origin').value);
        // ToDo, unable to populate on screen.
      }
    });
  }

  // create Service order by Auction 
  fetchVendorBid(bidId) {
    this.auctionService.getVendorBidBydBidId(bidId).subscribe(
      (response) => {
        console.log( "This is vendor bid object:: ", response);
        if(response.id != null){
          this.isCreatingFromEnquiry = false
          this.isCreatingFromAuction = true
          this.termsArray.push(this.defaultOneTermsAndConditions());
          this.enquiryObject = response.auction.enquiry;
          this.auctionObject = response.auction
          this.vendorBidObject = response
          console.log(this.vendorBidObject);
          console.log(this.auctionObject);
          console.log(this.enquiryObject);
          
          this.service.auctionNumber.patchValue(this.auctionObject.number);
          let auctionDate = this.datePipe.transform(this.auctionObject.auctionDate, 'yyyy-MM-dd')
          this.service.auctionDate.patchValue(auctionDate);
          this.service.emailId.patchValue(response.vendor.pocEmailId);
          this.service.forwaderContactNumber.patchValue(response.vendor.pocContactNumber);
          this.service.contactPersonName.patchValue(response.vendor.pocFirstName + " " + response.vendor.pocLastName);
          this.service.forwaderName.patchValue(response.vendor.name);
          this.service.tradeType.patchValue(this.enquiryObject.tradeType);

          // Shipment Details
          this.shipmentDetails.get('projectName').patchValue(this.enquiryObject.projectName);
          this.shipmentDetails.get('projectCode').patchValue(this.enquiryObject.projectCode);
          this.shipmentDetails.get('modeOfShipment').get('id').patchValue(this.enquiryObject.modeOfShipment);
          if(this.enquiryObject.modeOfShipment == 1){
            this.air_shipment = true;
          }
          else if(this.enquiryObject.modeOfShipment == 2){
            this.sea_shipment = true;
            this.shipmentDetails.get('containerLoadType').patchValue(this.enquiryObject.containerLoadType);
            this.shipmentDetails.get('hsCode').patchValue(this.enquiryObject.hsCode);
          }
          this.shipmentDetails.get('natureOfMovement').get('code').patchValue(this.enquiryObject.natureOfMovement);
          this.shipmentDetails.get('pickUpTerms').patchValue(this.enquiryObject.pickUpIncoterms);
          this.shipmentDetails.get('commodity').patchValue(this.enquiryObject.commodity);
          this.shipmentDetails.get('deliveryTerms').patchValue(this.enquiryObject.deliveryIncoterms);
          this.shipmentDetails.get('typeOfCargo').get('id').patchValue(this.enquiryObject.typeOfCargo);
          this.searchOriginValue = this.enquiryObject.originPortName;
          this.searchDestinationValue = this.enquiryObject.destinationPortName;
          console.log(this.searchOriginValue,this.searchDestinationValue);
          this.getSearchResultsOrigin();
          this.getSearchResultDestination();
          this.shipmentDetails.get('origin').patchValue(this.enquiryObject.origin);
          this.shipmentDetails.get('destination').patchValue(this.enquiryObject.destination);
          this.shipmentDetails.get('totalGrossWeight').patchValue(this.enquiryObject.totalGrossWeight);
          this.addServiceRateDetails();
          this.service.total.patchValue(response.auctionValue);
          this.currency = this.auctionObject.currency.currency;
          this.currencyFormGroup.get('id').patchValue(this.auctionObject.currency.id);
          if(this.enquiryObject.terms.length > 0){
            for (let index = 0; index < this.enquiryObject.terms.length; index++) {
              const element = this.enquiryObject.terms[index];
              this.termsArray.push(this.prePopulateTerms(element)) 
            }
          }
          else{
            this.termsArray.push(this.defaultOneTermsAndConditions());
          }
          this.populatePlaceOfReceipt();
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  fetchQuoteById(id) {
    this.quoteService.fetchVendorQuoteRowById(id).subscribe(
      (response) => {
        console.log('This is vendor quote row by id ', response);
        if (response != null) {
          this.isCreatingFromEnquiry = true
          if (response.enquiry != null) {
            this.enquiryObject = response.enquiry;
          }
          else if(response.vendorQuote.enquiry != null){
            this.enquiryObject = response.vendorQuote.enquiry;
          }
          this.vendorQuote = response
          console.log(this.enquiryObject);
          
          //creating service order from enquiry
          this.service.volume.patchValue(this.enquiryObject.chargableWeight);
          // Service Order
          let contactNo = parseInt(response.vendorQuote.vendor.pocContactNumber)
          this.service.quotationNumber.patchValue(response.vendorQuote.number);
          this.service.quotationDate.patchValue(response.vendorQuote.quoteDate);
          this.service.emailId.patchValue(response.vendorQuote.vendor.pocEmailId);
          this.service.forwaderContactNumber.patchValue(contactNo);
          this.service.contactPersonName.patchValue(response.vendorQuote.vendor.pocFirstName + " " + response.vendorQuote.vendor.pocLastName);
          this.service.forwaderName.patchValue(response.vendorQuote.vendor.pocFirstName + " " + response.vendorQuote.vendor.pocLastName);
          this.service.tradeType.patchValue(this.enquiryObject.tradeType);
          this.service.routing.patchValue(this.vendorQuote.routing);
          this.service.laycan.patchValue(this.vendorQuote.vesselSchedule);

          // Shipment Details
          this.shipmentDetails.get('projectName').patchValue(this.enquiryObject.projectName);
          this.shipmentDetails.get('projectCode').patchValue(this.enquiryObject.projectCode);
          this.shipmentDetails.get('pickUpTerms').patchValue(this.enquiryObject.pickUpIncoterms);
          this.shipmentDetails.get('commodity').patchValue(this.enquiryObject.commodity);
          this.shipmentDetails.get('deliveryTerms').patchValue(this.enquiryObject.deliveryIncoterms);
          this.shipmentDetails.get('modeOfShipment').get('id').patchValue(this.enquiryObject.modeOfShipment);
          this.searchOriginValue = this.enquiryObject.originPortName;
          this.searchDestinationValue = this.enquiryObject.destinationPortName;
          this.getSearchResultsOrigin();
          this.getSearchResultDestination();
          this.shipmentDetails.get('origin').patchValue(this.enquiryObject.origin);
          this.shipmentDetails.get('destination').patchValue(this.enquiryObject.destination);
          this.shipmentDetails.get('natureOfMovement').get('code').patchValue(this.enquiryObject.natureOfMovement);
          this.shipmentDetails.get('typeOfCargo').get('id').patchValue(this.enquiryObject.typeOfCargo);
          this.shipmentDetails.get('totalGrossWeight').patchValue(this.enquiryObject.totalGrossWeight)
          if(this.enquiryObject.modeOfShipment == 1){
            this.air_shipment = true;
          }
          else if(this.enquiryObject.modeOfShipment == 2){
            this.sea_shipment = true;
            this.shipmentDetails.get('containerLoadType').patchValue(this.enquiryObject.containerLoadType);
            this.shipmentDetails.get('hsCode').patchValue(this.enquiryObject.hsCode);
          }
          else{
            this.air_shipment = false;
            this.sea_shipment = false;
          }

          if(response.additionalCharges.length > 0){
            this.populateServiceRateDetails(response.additionalCharges);
          }
          if (response.vendorQuoteShipmentPackages.length > 0) {
            this.populateServiceRateDetails(response.vendorQuoteShipmentPackages);
          }

          if (response.vendorQuoteBreakBulks.length  > 0) {
            this.populateServiceRateDetails(response.vendorQuoteBreakBulks);
          }

          if (response.vendorQuoteContainers.length > 0) {
            for (let index = 0; index < response.vendorQuoteContainers.length; index++) {
              const element = response.vendorQuoteContainers[index];
              let description = this.getContainerType(element.container);
              let data = {
                description: description,
                quantity: element.container.quantity,
                rate: 0,
                total: element.totalFreight
              }
              if(element.exWorks != null && element.destinationCharges != null){
                data['rate'] = (element.exWorks) + (element.destinationCharges) + (element.ratePerContainer)
              }
              else if(element.exWorks != null){
                data['rate'] = (element.exWorks) + (element.ratePerContainer)
              }
              else if(element.destinationCharges != null){
                data['rate'] = (element.destinationCharges) + (element.ratePerContainer)
              }
              else{
                data['rate'] = element.ratePerContainer
              }
              this.serviceRateDetails.push(this.prePopulateServiceRateDetails(data));
            }
          }

          this.currency = this.enquiryObject.currency;
          this.service.currency.patchValue(this.currency);
          this.shipmentDetails.get('inTransitTime').patchValue(this.vendorQuote.transitTime);
          this.shipmentDetails.get('vesselFrequency').patchValue(response.shippingFrequency);
          this.shipmentDetails.get('transitPort').patchValue(this.vendorQuote.transitPort);
          this.shipmentDetails.get('vesselFrequency').patchValue(this.vendorQuote.frequency);

          for (let index = 0; index < this.serviceRateDetails.length; index++) {
            this.calculateTotalCharges(index);
          }
          if(this.enquiryObject.terms.length > 0){
            for (let index = 0; index < this.enquiryObject.terms.length; index++) {
              const element = this.enquiryObject.terms[index];
              this.termsArray.push(this.prePopulateTerms(element)) 
            }
          }
          else{
            this.termsArray.push(this.defaultOneTermsAndConditions());
          }
        }
        else {
          console.log("Create a new service order");
        }
        this.populatePlaceOfReceipt();
      },
      (error) => {
        console.log(error);
      },
    )
  }

  onSubmit() {
    if (this.updateServiceOrder) {
      this.UpdateServiceOrder();
    }
    else {
      this.createServiceOrder();
    }
  }

  ////for checking

  //Create Service Order
  createServiceOrder() {
    console.log("Create");
    if (this.termsArray.controls.length > 0) {
      for (let index = 0; index < this.termsArray.controls.length; index++) {
        const element = this.termsArray.controls[index];
        if (element.value.label == '' && element.value.content == '') {
          this.termsArray.clear()
        }
      }
    }
    const formData = this.serviceOrder.value
    console.log('This is formdata ', formData);
    console.log('This is shipment details ', formData.shipmentDetails);
    let submitBool = this.validateFilesAtSubmit();
    if (submitBool == false) {
      let data = {
        "number": formData.number,
        "serviceOrderDate": formData.serviceOrderDate,
        "forwaderName": formData.forwaderName,
        "quotationNumber": formData.quotationNumber,
        "auctionNumber": formData.auctionNumber,
        "forwaderContactNumber": formData.forwaderContactNumber,
        "auctionDate": formData.auctionDate,
        "emailId": formData.emailId,
        "amendentNumber": formData.amendentNumber,
        "contactPersonName": formData.contactPersonName,
        "amendentDate": formData.amendentDate,
        "tradeType": formData.tradeType,
        "nameOfCHA": formData.nameOfCHA,
        "placeOfReceipt": formData.placeOfReceipt,
        "placeOfDelivery": formData.placeOfDelivery,
        "routing": formData.routing,
        "carrier": formData.carrier,
        "consigneeDetail": formData.consigneeDetail,
        "shipmentDetail": formData.shipmentDetails,
        "serviceAndRateDetails": formData.serviceAndRateDetails,
        "contractValidity": formData.contractValidity,
        "laycan": formData.laycan,
        "volume": formData.volume,
        "exRate": formData.exRate,
        "reference": formData.reference,
        "currency": formData.currency,
        "terms": formData.terms,
        "total": formData.total,
        "vendor": {
          "id": ''
        }
      }
      if(this.isCreatingFromEnquiry == true){
        let enquiry = {
          id: this.enquiryObject.id
        }
        data['enquiry'] = enquiry
        let vendorQuoteRow = {
          id: this.vendorQuote.id
        }
        data['vendorQuoteRow'] = vendorQuoteRow
        data["auction"] = null,
        data["vendorBid"] = null
        data.vendor.id = this.vendorQuote.vendorQuote.vendor.id
      }
      else if(this.isCreatingFromAuction){
        let enquiry = {
          id: this.enquiryObject.id
        }
        data['enquiry'] = enquiry
        data['vendorQuoteRow'] = null
        let auction = {
          id: this.auctionObject.id
        }
        data['auction'] = auction
        let vendorBid = {
          id: this.vendorBidObject.id
        }
        data['vendorBid'] = vendorBid
        data.vendor.id = this.vendorBidObject.vendor.id
      }
      console.log('This is final data ', data);
      // ToDo, call should in sync.
      this.apiservice.createServiceOrder(data).subscribe(
        (response) => {
          if(response.code == 201){
            Swal.fire({
              titleText: "Success",
              text: response.message,
              timer: 5000,
              icon: 'success'
            })
            this.serviceOrderId = response.entityId;
            if(this.serviceOrderId != null){
              for (let index = 0; index < this.documentList.length; index++) {
                this.addServiceOrderArtifact(index);
              }
            }
            this.router.navigate(['v1/client-service-order/listing']);
          }
        },
        (error) => {
          console.log(error);
          let _error = error.error;
          if(_error.errorCode == 412){
            Swal.fire({
              title: "Error",
              icon: "error",
              text: _error.errorMessage,
              timer: 5000
            })
          }
        },
      );
    }
  }

  //Update Service Order
  UpdateServiceOrder() {
    console.log("Update");
    if (this.termsArray.controls.length > 0) {
      for (let index = 0; index < this.termsArray.controls.length; index++) {
        const element = this.termsArray.controls[index];
        if (element.value.label == '' && element.value.content == '') {
          this.termsArray.clear()
        }
      }
    }
    const formData = this.serviceOrder.value
    let submitBool = this.validateFilesAtSubmit();
    if (submitBool == false) {
      let data = {
        "id": this.serviceOrderId,
        "number": formData.number,
        "serviceOrderDate": formData.serviceOrderDate,
        "forwaderName": formData.forwaderName,
        "quotationNumber": formData.quotationNumber,
        "auctionNumber": formData.auctionNumber,
        "forwaderContactNumber": formData.forwaderContactNumber,
        "auctionDate": formData.auctionDate,
        "emailId": formData.emailId,
        "amendentNumber": formData.amendentNumber,
        "contactPersonName": formData.contactPersonName,
        "amendentDate": formData.amendentDate,
        "tradeType": formData.tradeType,
        "nameOfCHA": formData.nameOfCHA,
        "placeOfReceipt": formData.placeOfReceipt,
        "placeOfDelivery": formData.placeOfDelivery,
        "routing": formData.routing,
        "carrier": formData.carrier,
        "consigneeDetail": formData.consigneeDetail,
        "shipmentDetail": formData.shipmentDetails,
        "serviceAndRateDetails": formData.serviceAndRateDetails,
        "contractValidity": formData.contractValidity,
        "laycan": formData.laycan,
        "volume": formData.volume,
        "exRate": formData.exRate,
        "reference": formData.reference,
        "currency": formData.currency,
        "terms": formData.terms,
        "total": formData.total,
        "vendor": formData.vendor
      }
      if(this.isCreatingFromEnquiry == true){
        let enquiry = {
          id: this.enquiryObject.id
        }
        data['enquiry'] = enquiry
        let vendorQuoteRow = {
          id: this.vendorQuote.id
        }
        data['vendorQuoteRow'] = vendorQuoteRow
        data["auction"] = null,
        data["vendorBid"] = null
      }
      else if(this.isCreatingFromAuction){
        let enquiry = {
          id: this.enquiryObject.id
        }
        data['enquiry'] = enquiry
        data['vendorQuoteRow'] = null
        let auction = {
          id: this.auctionObject.id
        }
        data['auction'] = auction
        let vendorBid = {
          id: this.vendorBidObject.id
        }
        data['vendorBid'] = vendorBid
      }
      console.log('This is final data ', data);

      this.apiservice.updateServiceOrder(this.serviceOrderId, data).subscribe(
        (response) => {
          if(response.code == 201){
            Swal.fire({
              titleText: "Success",
              timer: 5000,
              text: response.message,
              icon: "success"
            })
            this.router.navigate(['v1/client-service-order/listing']);
          }
        },
        (error) => {
          console.log(error);
          let _error = error.error;
          if(_error.errorCode == 412){
            Swal.fire({
              title: "Error",
              icon: "error",
              text: _error.errorMessage,
              timer: 5000
            })
          }
      });
    }
  }

  //Navigation between Service Order Screen 
  shipmentDetailsPage() {
    this.backDetailsPage = true;
    this.dashboardBool = false;
    let currentPage = document.getElementById('item-0');
    let nextPage = document.getElementById('item-1');
    let _page = document.getElementById("item-5");
    let _next = document.getElementById("item-6");
    let _list = document.getElementById("list-item-1");
    if (currentPage.classList.contains("active") && _page.classList.contains("active")) {
      _page.classList.remove("active");
      currentPage.classList.remove('active');
      _list.classList.add("active");
      nextPage.classList.add("active");
      _next.classList.add("active");
      this.gotoTop();
    }
    else {
      console.log("NOTHING");
    }
  }
  serviceRatePage() {
    // console.log(this.serviceOrder.value);
    this.backShipmentPage = true;
    this.backDetailsPage = false;
    let currentPage = document.getElementById('item-1');
    let nextPage = document.getElementById('item-2');
    let _page = document.getElementById("item-6");
    let _next = document.getElementById("item-7");
    let _list = document.getElementById("list-item-2");
    if (currentPage.classList.contains("active") && _page.classList.contains("active")) {
      _page.classList.remove("active");
      currentPage.classList.remove('active');
      _list.classList.add("active");
      nextPage.classList.add("active");
      _next.classList.add("active");
      // this.addServiceAndRateDetails();
      this.gotoTop();
    }
    else {
      console.log("NOTHING");
    }
  }
  termConditionPage() {
    // console.log(this.serviceOrder.value);
    this.backServiceRatePage = true;
    this.backShipmentPage = false;
    let nextPage = document.getElementById('item-3');
    let currentPage = document.getElementById('item-2');
    let _page = document.getElementById('item-7');
    let _next = document.getElementById('item-8');
    let _list = document.getElementById('list-item-3');
    if (currentPage.classList.contains("active") && _page.classList.contains('active')) {
      currentPage.classList.remove('active');
      _page.classList.remove('active');
      nextPage.classList.add('active');
      _next.classList.add('active');
      _list.classList.add('active');
      this.gotoTop();
    }
    else {
      console.log('Nothing to show');
    }
  }
  goBackDetailsPage() {
    let currentPage = document.getElementById('item-1');
    let _page = document.getElementById("item-6");
    if (currentPage.classList.contains("active") && _page.classList.contains("active")) {
      _page.classList.remove("active");
      currentPage.classList.remove('active');
      document.getElementById("list-item-1").classList.remove("active");
    }
    document.getElementById("list-item-0").classList.add("active");
    document.getElementById('item-0').classList.add("active");
    document.getElementById("item-5").classList.add("active");
    this.dashboardBool = true;
    this.backDetailsPage = false;
  }
  goBackShipmentPage() {
    let currentPage = document.getElementById('item-2');
    let _page = document.getElementById("item-7");
    if (currentPage.classList.contains("active") && _page.classList.contains("active")) {
      _page.classList.remove("active");
      currentPage.classList.remove('active');
      document.getElementById("list-item-2").classList.remove("active");
    }
    document.getElementById("list-item-1").classList.add("active");
    document.getElementById('item-1').classList.add("active");
    document.getElementById("item-6").classList.add("active");
    this.backDetailsPage = true;
    this.backShipmentPage = false;
  }
  goBackServiceRatePage() {
    let currentPage = document.getElementById('item-3');
    let _page = document.getElementById("item-8");
    if (currentPage.classList.contains("active") && _page.classList.contains("active")) {
      _page.classList.remove("active");
      currentPage.classList.remove('active');
      document.getElementById("list-item-3").classList.remove("active");
    }
    document.getElementById("list-item-2").classList.add("active");
    document.getElementById('item-2').classList.add("active");
    document.getElementById("item-7").classList.add("active");
    this.backShipmentPage = true;
    this.backServiceRatePage = false;
  }
  goBackTermConditionPage() {
    let currentPage = document.getElementById('item-4');
    let _page = document.getElementById("item-9");
    if (currentPage.classList.contains("active") && _page.classList.contains("active")) {
      _page.classList.remove("active");
      currentPage.classList.remove('active');
      document.getElementById("list-item-4").classList.remove("active");
    }
    document.getElementById("list-item-3").classList.add("active");
    document.getElementById('item-3').classList.add("active");
    document.getElementById("item-8").classList.add("active");
    this.backServiceRatePage = true;
    this.backTermConditionPage = false;
  }

  getMasterTablesSessionStorage() {
    this.containerTypeList();
    this.getModeOfShipment();
    this.getTradeType();
    this.getTypeOfCargo();
    this.getNatureOfMovement();
    this.getPickUpIncoTerms();
    this.getDeliveryIncoTerms();
    this.getNatureOfGoods();
    this.getTypeOfPacking();
    this.getCurrency();
    this.getCountry();
    this.getAllVendors();
  }

  getCountry(){
    this.masterTableService.getAllCountries().subscribe(
      (response) => {
        this.countryPicker = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getModeOfShipment(){
    this.masterTableService.getModeOfShipment().subscribe(
      (response) => {
        this.mode_of_shipment = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getTradeType(){
    this.masterTableService.getTradeType().subscribe(
      (response) => {
        this.trade_type = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getTypeOfCargo(){
    this.masterTableService.getAllTypeOfCargo().subscribe(
      (response) => {
        this.type_cargo = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getNatureOfMovement(){
    this.masterTableService.getNatureOfMovement().subscribe(
      (response) => {
        this.nature_of_movement = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getPickUpIncoTerms(){
    this.masterTableService.getAllPickUpIncoTerms().subscribe(
      (response) => {
        this.pickup_terms = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getDeliveryIncoTerms(){
    this.masterTableService.getDeliveryIncoTerms().subscribe(
      (response) => {
        this.dropoff_terms = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getNatureOfGoods(){
    this.masterTableService.getNatureOfGoods().subscribe(
      (response) => {
        this.nature_goods = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getTypeOfPacking(){
    this.masterTableService.getAllTypeOfPacking().subscribe(
      (response) => {
        this.type_packing = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }
  
  getCurrency(){
    this.masterTableService.getAllCurrency().subscribe(
      (response) => {
        this.currencyPicker = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }
  
  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  deleteDocument(index: number) {
    this.documentList.splice(index, 1)
  }

  downloadFileLocal(item) {
    if (item.id != null && item.label != null) {
      this.dataURItoBlob(item);
    }
  }

  dataURItoBlob(data) {
    const linkSource = data.content;
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = data.name;
    downloadLink.click();
  }

  deleteServerFile(artifactId, index) {
    this.apiservice.deleteServiceOrderArtifact(this.serviceOrderId, artifactId).subscribe(
      (response) => {
        console.log(response);
        if (response.code == 200) {
          Swal.fire({
            titleText: "Success",
            text: response.message,
            icon: "success",
            timer: 5000
          });
          this.deleteDocument(index);
        }
      },
      (error) => {
        console.log(error);
      }
    )
  }

  validateFilesAtSubmit() {
    let submitBool = false;
    let totalFilesSize = 0;
    if (this.documentList.length >= 1) {
      for (let index = 0; index < this.documentList.length; index++) {
        const document = this.documentList[index];
        const fileSize = Math.round((document.size / 1024));
        totalFilesSize = fileSize + totalFilesSize;
        if (document.label == undefined) {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Add label to file " + document.name,
            timer: 5000
          })
          submitBool = true;
          break
        }
        else if (fileSize > 2048) {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Upload File :" + document.name + " with size less than 2MB",
            timer: 5000
          });
          submitBool = true;
          break
        }
        else if (totalFilesSize > 10240) {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Total File size should be size less than 10MB",
            timer: 5000
          });
          submitBool = true;
          break
        }
        // ToDo changes for update method.
        else if(this.updateServiceOrder == true && (document.id == undefined || null)){
          this.addServiceOrderArtifact(index);
        }
        else if ((document.id == undefined || null) && fileSize < 2048 && totalFilesSize < 10240 && document.label != undefined && (this.serviceOrderId != undefined || null)) {
          console.log(document.id, fileSize, totalFilesSize, document.label, this.serviceOrderId);
          this.addServiceOrderArtifact(index);
        }
      }
    }
    return submitBool
  }

  containerTypeList(){
    this.masterTableService.getContainerTypes().subscribe(
      (response) => {
        if(response){
          this.container_type = response
        }
      },
      (error) => {error},
    );
  }

  getServiceOrderArtifacts(id){
    this.apiservice.getServiceOrderArtifacts(id).subscribe(
      (response) => {
        this.documentList = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  addServiceOrderArtifact(index) {
    let data = this.documentList[index];
    this.apiservice.addServiceOrderArtifact(this.serviceOrderId, data).subscribe(
      (response) => {
        if (response.code == 201) {
          data['id'] = response.entityId;
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  // ToDo changes needed.
  calculateTotalCharges(index){
    let service = this.serviceRateDetails.at(index) as FormGroup;
    let rate = service.get('rate').value;
    let quantity = service.get('quantity').value;
    let total = service.get('total');
    let description = service.get('description');
    if(!description.invalid){
      let _tt = (rate * quantity);
      total.patchValue(_tt)
    }
    this.totalCalculationOfRateDetails();
  }

  totalCalculationOfRateDetails(){
    let grandTotal = this.serviceOrder.get('total');
    let _total: number = 0;
    for (let index = 0; index < this.serviceRateDetails.length; index++) {
      const element = this.serviceRateDetails.at(index);
      let total =  element.get('total').value;
      _total = parseInt(total + _total);
      grandTotal.patchValue(_total);
    }
  }

  populateServiceRateDetails(object: Array<any>){
    for (let index = 0; index < object.length; index++) {
      const element = object[index];
      let total = 0
      let data = {
        description: element.description,
        quantity: 0,
        rate: element.charge,
        total: 0
      }
      if(element.type == "PER_UNIT"){
        total = (element.charge * this.service.volume.value)
        data['quantity'] = this.service.volume.value
      }
      else{
        total = element.charge
        data['quantity'] = 1
      }
      data['total'] = total
      this.serviceRateDetails.push(this.prePopulateServiceRateDetails(data));
    }
    
  }

  getContainerType(enqContainer): string {
    const typeOfContainer = this.container_type.find(
      (container) => {
        return container.id === enqContainer.type;
      }
    );
    return typeOfContainer.value;
  }

  getSearchResultsOrigin(){
    let modeOfShipmentValue = this.shipmentDetails.get('modeOfShipment').get('id').value;
    let size = 50
    if(this.searchOriginValue != ""){
      this.masterTableService.getSearchPort(modeOfShipmentValue, this.searchOriginValue, size).subscribe((res)=>{
        this.loadingPorts = res
      },
      (error)=>{
        console.log(error);
      })
    } else {
      console.log('search value is empty ', this.searchOriginValue);
    }
  }

  getSearchResultDestination(){
    let modeOfShipmentValue = this.shipmentDetails.get('modeOfShipment').get('id').value;
    let size = 50
    if(this.searchDestinationValue != ""){
      this.masterTableService.getSearchPort(modeOfShipmentValue, this.searchDestinationValue, size).subscribe((res)=>{
        this.destinationPorts = res
      },
      (error)=>{
        console.log(error);
      })
    } else {
      console.log('search value is empty ', this.searchDestinationValue);
    }
  }

  getAllVendors(){
    this.onBoardService.getVendorsListing().subscribe(
      (response) => {
        this.vendorList = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  populatePlaceOfReceipt(){
    let deliveryTerms = this.enquiryObject.deliveryIncoterms;
    let placeOfDelivery  = this.service.placeOfDelivery;
    if(deliveryTerms == "DAT"){
      placeOfDelivery.patchValue(this.enquiryObject.destinationTerminal);
    }
    else if(deliveryTerms == "DAP"){
      console.log(this.enquiryObject.deliveryPersonAddress);
      let deliveryPersonAddress = this.enquiryObject.deliveryPersonAddress;
      let address = deliveryPersonAddress.street1 + ', ' +  deliveryPersonAddress.street2 + ', ' + 
        deliveryPersonAddress.city + ', ' + deliveryPersonAddress.state + ', ' + deliveryPersonAddress.zipCode  + ', '  + deliveryPersonAddress.country  + '.'
      placeOfDelivery.patchValue(address);
    }
    else if(deliveryTerms == "DDP"){
      console.log(this.enquiryObject.deliveryPersonAddress);
      let deliveryPersonAddress = this.enquiryObject.deliveryPersonAddress;
      let address = deliveryPersonAddress.street1 + ', ' +  deliveryPersonAddress.street2 + ', ' + 
        deliveryPersonAddress.city + ', ' + deliveryPersonAddress.state + ', ' + deliveryPersonAddress.zipCode  + ', '  + deliveryPersonAddress.country  + '.'
      placeOfDelivery.patchValue(address);
    }
    else if(deliveryTerms == "C&F"){
      placeOfDelivery.patchValue(this.enquiryObject.destinationPortName);
    }
    else if(deliveryTerms == "CIF"){
      placeOfDelivery.patchValue(this.enquiryObject.destinationPortName);
    }
    else if(deliveryTerms == "CIP"){
      placeOfDelivery.patchValue(this.enquiryObject.destinationPortName);
    }
    else if(deliveryTerms == "CPT"){
      placeOfDelivery.patchValue(this.enquiryObject.destinationPortName);
    }
  }

}