import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { OnboardService } from '../helpers/onboard.service';
import { TemplateboxComponent } from './templatebox/templatebox.component';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-message-template',
  templateUrl: './message-template.component.html',
  styleUrls: ['./message-template.component.css']
})
export class MessageTemplateComponent implements OnInit {

  displayedColumns: string[] = ['templateCode', 'templateKey', 'templateValue', 'createdDate', 'updatedDate']
  dataSource = new MatTableDataSource;
  serchFilter: Boolean = false;
  nodata: Boolean = true;
  size: number = 25;
  page: number = 0;
  length: number = 0;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private messageService: OnboardService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getAllMessageTemplate();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.nodata = false;
    this.serchFilter = true;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  getAllMessageTemplate() {
    this.messageService.getMessageTemplate(this.page, this.size).subscribe(
      (response) => {
        let content: Array<any> = response.content
        if (content.length > 0) {
          this.dataSource = new MatTableDataSource(response.content);
          this.length = response.totalElements;
          this.dataSource.sort = this.sort;
        }
      },
      (error) => {
        console.log(error)
      }
    )
  }


  openDialog(action, obj) {
    obj.action = action
    const dialogRef = this.dialog.open(TemplateboxComponent, {
      width: '750px',
      data: obj
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.event == 'Update') {
        this.updateMessageTemplate(result.data.id,result.data);
      }
    });

  }

  updateMessageTemplate(id, data: any) {
    this.messageService.updateMessageTemplate(id,data).subscribe((response) => {
      console.log(response);
      if(response.code == 201){
        Swal.fire({
          titleText: "Success",
          timer: 5000,
          icon: "success",
          text: response.message
        })
        this.getAllMessageTemplate();
      }
    },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      }
    );
  }


  onPageChange(event: PageEvent) {
    this.size = event.pageSize;
    this.page = event.pageIndex;
    this.getAllMessageTemplate();
  }
}
