import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-vessel-details-box',
  templateUrl: './vessel-details-box.component.html',
  styleUrls: ['./vessel-details-box.component.css']
})
export class VesselDetailsBoxComponent implements OnInit {

  action: string;
  local_data: any;
  displayedColumns: string[] = ['transport', 'cutOff', 'departure', 'arrival', 'serviceVessel'];
  dataSource: MatTableDataSource<any>;
  transport: any;
  jsonForRowDetails = []


  constructor(
    public dialogRef: MatDialogRef<VesselDetailsBoxComponent>,
    //@Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.local_data = { ...data };
    console.log(this.local_data);

    this.local_data.route.leg.forEach((eachLeg, i) => {
      if (eachLeg.fromPoint.location.name != undefined || eachLeg.vessel != undefined || eachLeg.imoNumber != undefined || eachLeg.externalVoyageNumber != undefined) {
        this.jsonForRowDetails.push({
          'transport': eachLeg.fromPoint.location.name,
          'cutOff': eachLeg.fromPoint.defaultCutoff,
          'departure': eachLeg.fromPoint.etd || eachLeg.fromPoint.atd,
          'arrival': eachLeg.toPoint.eta || eachLeg.toPoint.ata,
          'vesselDetails': (eachLeg.vessel ? eachLeg.vessel.name : eachLeg.transportMode) + '~' + (eachLeg.imoNumber ? eachLeg.imoNumber : '') + '~' + (eachLeg.externalVoyageNumber ? eachLeg.externalVoyageNumber : ''),
        });
      }

    });
    console.log(this.jsonForRowDetails);

    this.dataSource = new MatTableDataSource(this.jsonForRowDetails);
  }

  ngOnInit(): void {

  }

  doAction() {
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

}
