import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { AuctionService } from '../helpers/auction.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { AuctionDialogComponent } from './auction-dialog/auction-dialog.component';
import { SocketService } from '../helpers/socket.service';
import { ReportService } from '../helpers/report.service';

@Component({
  selector: 'app-client-auction-list',
  templateUrl: './client-auction-list.component.html',
  styleUrls: ['./client-auction-list.component.css'],
})
export class ClientAuctionListComponent implements OnInit {
  page: number = 0;
  size: number = 25;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  userData: any;
  dType: number;
  orgName: Boolean = false;
  dataSource!: MatTableDataSource<any>;
  displayedColumns: string[] = [
    'checkbox',
    'id',
    'description',
    'date',
    'status',
    'actions',
  ];
  displayedColumns1: string[] = [
    'checkbox',
    'orgName',
    'id',
    'date',
    'description',
    'status',
    'actions',
  ];
  checkedList: Array<any> = [];
  typeOfAuction: string;
  title: string = 'Reverse Auction List';
  AuctionStartDateTime: Array<any> = [];
  id: number;
  auctionLength: number;
  serchFilter: Boolean = false;

  isApproveBtnVisible: boolean = false;
  downloadBidReport: boolean = false

  constructor(
    private router: Router,
    private service: AuctionService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private socketService: SocketService,
    private reportService: ReportService
  ) {}
  ngOnInit(): void {
    this.userData = JSON.parse(sessionStorage.getItem('userData'));
    console.log(this.userData);
    for (let index = 0; index < this.userData.authorities.length; index++) {
      const element = this.userData.authorities[index];
      if (element == 'RB0000145') {
        console.log(element);
        this.isApproveBtnVisible = true;
      }
      if(element == 'RB0000225'){
        this.downloadBidReport = true
      }
    }
    if (this.userData == null) {
      console.log('not login....');
    } else {
      this.dType = this.userData['dType'];
      console.log(this.dType);
      if (this.dType == 1) {
        this.orgName = true;
      } else {
        this.orgName = false;
      }
    }

    this.route.paramMap.subscribe((param) => {
      let _type = param.get('type');
      this.typeOfAuction = _type.substring(0, 1).toLowerCase();
      this.getAllAuctions();
    });

    // this.socketService.connectTOServerPopUpFuction();
  }

  openDialog(obj) {
    const dialogRef = this.dialog.open(AuctionDialogComponent, {
      width: '250px',
      data: obj,
    });

    dialogRef.afterClosed().subscribe((result) => {
      // console.log(result);
      if (result.event == 'REJECTED') {
        this.rejectAuction(result.data);
      } else if (result.event == 'CANCELLED') {
        this.cancelAuction(result.data);
      } else {
        console.log('ELSE');
      }
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.serchFilter = true;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onPaginateChange(event) {
    this.size = event.pageSize;
    this.page = event.pageIndex;
  }

  getAllAuctions() {
    this.service
      .getAllAuctions(this.page, this.size, this.typeOfAuction, null)
      .subscribe(
        (response) => {
          if (response.content != null) {
            this.checkedList = [];
            this.dataSource = null;
            this.auctionLength = response.totalElements;
            this.dataSource = new MatTableDataSource(response.content);
            this.dataSource.sort = this.sort;
            for (let index = 0; index < response.content.length; index++) {
              const element = response.content[index];
              this.AuctionStartDateTime.push(element);
            }
          } else {
            console.log('No auctions to be listed');
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  createAuction() {
    let type = this.typeOfAuction;
    this.router.navigate(['v1/client-auction/creation', type]);
  }

  compare(event, id, index) {
    let value = event.srcElement.checked;
    let _id = id;
    if (value == true) {
      this.checkedList.push(_id);
    } else if (value == false) {
      this.checkedList.splice(this.checkedList.indexOf(_id), 1);
    } else {
      console.log('Cant do this');
    }
  }

  rejectAuctionDialog() {
    if (this.checkedList.length > 1 || this.checkedList.length == 0) {
      Swal.fire({
        titleText: 'Warning',
        icon: 'warning',
        text: 'Kindly Check only one checkbox',
        timer: 5000,
        showCancelButton: true,
        showCloseButton: true,
      });
    } else {
      let data = {
        status: 'REJECTED',
        reason: '',
      };
      this.openDialog(data);
    }
  }

  rejectAuction(data) {
    this.service.updateAuctionStatus(this.checkedList[0], data).subscribe(
      (response) => {
        if (response.code == 201) {
          Swal.fire({
            titleText: 'Success',
            text: response.message,
            timer: 5000,
            icon: 'success',
          });
          this.getAllAuctions();
        }
      },
      (error) => {
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: 'Error',
            text: _error.errorMessage,
            timer: 5000,
            icon: 'error',
          });
        }
      }
    );
  }

  approveAuction() {
    if (this.checkedList.length > 1 || this.checkedList.length == 0) {
      Swal.fire({
        titleText: 'Warning',
        icon: 'warning',
        text: 'Kindly Check only one checkbox',
        timer: 5000,
        showCancelButton: true,
        showCloseButton: true,
      });
    } else {
      let data = {
        status: 'APPROVED',
      };
      this.service.updateAuctionStatus(this.checkedList[0], data).subscribe(
        (response) => {
          if (response.code == 201) {
            Swal.fire({
              titleText: 'Success',
              text: response.message,
              timer: 5000,
              icon: 'success',
            });
            this.getAllAuctions();
          }
        },
        (error) => {
          let _error = error.error;
          if (_error.errorCode == 412) {
            Swal.fire({
              titleText: 'Error',
              text: _error.errorMessage,
              timer: 5000,
              icon: 'error',
            });
          }
        }
      );
    }
  }

  cancelAuctionDialog() {
    if (this.checkedList.length > 1 || this.checkedList.length == 0) {
      Swal.fire({
        titleText: 'Warning',
        icon: 'warning',
        text: 'Kindly Check only one checkbox',
        timer: 5000,
        showCancelButton: true,
        showCloseButton: true,
      });
    } else {
      let data = {
        status: 'CANCELLED',
        reason: '',
      };
      this.openDialog(data);
    }
  }

  cancelAuction(data) {
    this.service.updateAuctionStatus(this.checkedList[0], data).subscribe(
      (response) => {
        if (response.code == 201) {
          Swal.fire({
            titleText: 'Success',
            text: response.message,
            timer: 5000,
            icon: 'success',
          });
          this.getAllAuctions();
        }
      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: 'Error',
            text: _error.errorMessage,
            timer: 5000,
            icon: 'error',
          });
        }
      }
    );
  }

  proviceComments() {
    if (this.checkedList.length > 1 || this.checkedList.length == 0) {
      Swal.fire({
        titleText: 'Warning',
        icon: 'warning',
        text: 'Kindly Check only one checkbox',
        timer: 5000,
        showCancelButton: true,
        showCloseButton: true,
      });
    } else {
      console.log('Loading API');
    }
  }

  editAuctionRoute(id: any) {
    this.router.navigate(['v1/client-auction/edit'], { queryParams: { id } });
  }

  startAuction() {
    if (this.checkedList.length > 1 || this.checkedList.length == 0) {
      Swal.fire({
        titleText: 'Warning',
        icon: 'warning',
        text: 'Kindly Check only one checkbox',
        timer: 5000,
        showCloseButton: true,
      });
    } else {
      console.log(this.AuctionStartDateTime, this.checkedList[0]);
      let currentDate = new Date().toISOString();
      for (let index = 0; index < this.AuctionStartDateTime.length; index++) {
        const element = this.AuctionStartDateTime[index];
        if (
          this.checkedList[0] === element.id &&
          element.status == 'IN_PROGRESS'
        ) {
          this.router.navigate([
            '/v1/client-live-auction',
            this.checkedList[0],
          ]);
        } else if (
          (this.checkedList[0] === element.id &&
            element.status == 'APPROVED') ||
          'NOT_INITIATED'
        ) {
          console.log(element.status);
          const startDate = element.auctionDate;
          if (startDate <= currentDate) {
            this.startAuctionCall(this.checkedList[0]);
            break;
          } else {
            Swal.fire({
              titleText: 'Warning',
              icon: 'warning',
              text: 'Auction has not started yet',
              timer: 5000,
              showCloseButton: true,
            });
          }
        } else {
          console.log('NOTHING');
        }
      }
    }
  }

  onPageChange(event: PageEvent) {
    this.size = event.pageSize;
    this.page = event.pageIndex;
    this.getAllAuctions();
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove('sidebar-active');
  }

  getAllRecentAuctions() {
    this.service
      .getAllRecentAuctions(this.page, this.size, this.typeOfAuction)
      .subscribe(
        (response) => {
          if (response.content != null) {
            this.dataSource = null;
            this.auctionLength = response.totalElements;
            this.dataSource = new MatTableDataSource(response.content);
            this.dataSource.sort = this.sort;
            for (let index = 0; index < response.content.length; index++) {
              const element = response.content[index];
              this.AuctionStartDateTime.push(element);
            }
          } else {
            console.log('No auctions to be listed');
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getAllUpcomingAuctions() {
    this.service
      .getAllUpcomingAuctions(this.page, this.size, this.typeOfAuction)
      .subscribe(
        (response) => {
          if (response.content != null) {
            this.dataSource = null;
            this.auctionLength = response.totalElements;
            this.dataSource = new MatTableDataSource(response.content);
            this.dataSource.sort = this.sort;
            for (let index = 0; index < response.content.length; index++) {
              const element = response.content[index];
              this.AuctionStartDateTime.push(element);
            }
          } else {
            console.log('No auctions to be listed');
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  startAuctionCall(id: number) {
    let data = {
      status: 'IN_PROGRESS',
    };
    this.service.updateAuctionStatus(id, data).subscribe(
      (response) => {
        console.log(response);
        if (response.code == 200) {
          Swal.fire({
            titleText: 'Success',
            text: response.message,
            timer: 5000,
            icon: 'success',
          });
          this.router.navigate([
            '/v1/client-live-auction/',
            this.checkedList[0],
          ]);
        }
      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: 'Error',
            text: _error.errorMessage,
            timer: 5000,
            icon: 'error',
          });
        }
      }
    );
  }

  downloadBidSummary(auctionId: any, type: string) {
    this.reportService.downloadAuctionBidSummery(auctionId).subscribe({
      next: (res) => {
        console.log('this is res ', res);
        if (res) {
          var file = new Blob([res], { type: 'application/pdf' });
          var fileURL = URL.createObjectURL(file);
          if(type == 'view'){
            window.open(fileURL);   // if u want to open in new tab
          } else {
            var a = document.createElement('a');
            a.href = fileURL;
            a.target = '_blank';
            a.download = 'AuctionBidSummary.pdf';
            document.body.appendChild(a);
            a.click();
          }
        }
      },
      error: (error) => {
        console.log('this is error ', error);
      },
    });
  }
}
