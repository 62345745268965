<app-header></app-header>
<section class="banner text-center text-white d-flex align-items-center banner-full"
    style="background-image: url(assets/images/banner.jpg)">
    <div class="container position-relative">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <div class="heading-dual">
                    <h1>LogSolutec</h1>
                    <h2>Services</h2>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="about-wrapper text-dark py-8">
    <div class="container p-lg-start-end">
        <div class="row">
            <img src="../../../assets/images/services-main.png" alt="">
        </div>
    </div>

    <div class="container-fluid justify-content-center mt-4 pl-5 pr-5">
        <b>Our global logistics network provides the dependability, convenience, flexibility, and value that will help
            you achieve your business goals.
        </b>
        <b>Providing a worry-free, on-time shipping experience is our goal. Whatever you need to transport and wherever
            you need to move it, LogSolutec can help you get it there, whether by road, rail, air, or sea. We'll make
            doing business with us as simple as possible. Take a look at different services we offer at LogSolutec:
        </b>
    </div>

    <!-- Procurement -->
    <div class="container-fluid row mt-4">
        <div class="col-lg-8">
            <h4 class="text-dark">Procurement</h4>
            <p>
                Decide if your company wants a minute’s work or multi work! We offer a variety of procurement
                services, including an e-procurement site that makes it easier to implement techniques that boost
                productivity, operational efficiency, and risk management.
            </p>
            <p> LogSolutec's procurement logistics solution assists customers with the bulk procurement of all
                required goods, whether domestically or internationally. We can handle anything from production
                line-specific delivery to low-volume, to high-frequency. Our solutions help to connect suppliers and
                assembly companies.
            </p>
        </div>
        <div style="text-align: center; margin: auto;" class="col-lg-4 justify-content-center">
            <img src="../../../assets/images/procurement-service.png" alt="Procurement Service">
        </div>
    </div>

    <!-- Track & trace -->
    <div class="container-fluid row mt-4">
        <div style="text-align: center; margin: auto;" class="col-lg-4 justify-content-center">
            <img src="../../../assets/images/track-trace-service.png" alt="Track Trace Service">
        </div>
        <div class="col-lg-8">
            <h4 class="text-dark text-align">Track & Trace</h4>
            <p>Your shipping requirements are one-of-a-kind, so is our strategy. We'll work with you to create a
                solution that's personalized to your exact needs.
            </p>
            <p>Regardless of the size of your organization or the number of your shipments, we will meet and exceed
                your expectations from the first call to the final delivery. Let us put it to the test.
            </p>
            <p>For your supply chain solutions, LogSolutec provides a reliable network, cutting-edge technology,
                efficient processes, low costs, and a knowledgeable team.
            </p>
        </div>
    </div>

    <!-- Online Invoice -->
    <div class="container-fluid row mt-4">
        <div class="col-lg-8">
            <h4 class="text-dark">Online Documentation</h4>
            <p>Automating the online invoice process has now become easier. Our invoice processing services ensure
                that your company's resources are focused on its key business operations. In order to effectively
                recognise product positions, manage AR, and identify your company situation, we take on the entire
                back-end transaction and invoice processing activities for enterprises.
            </p>
        </div>
        <div style="text-align: center; margin: auto;" class="col-lg-4 justify-content-center">
            <img src="../../../assets/images/online-documentation.png" alt="Online Invoice">
        </div>
    </div>

    <!-- Agile platform: -->
    <div class="container-fluid row mt-4">
        <div style="text-align: center; margin: auto;" class="col-lg-4 justify-content-center">
            <img src="../../../assets/images/alige-platform.png" alt="">
        </div>

        <div class="col-lg-8">
            <h4 class="text-dark">Agile platform</h4>
            <p>Do not compromise while selecting the CRM, select the best by selecting us.
                An agile customer relationship management also known as CRM is software that provides all kinds of
                services under one umbrella.
            </p>
            <p>A modern interface dealing with services such as automation, tracking, web analytics, and many more.</p>
            <p>We help you in reducing manual dependency and ease up your business process, where you can easily control
                your supply chain even by sitting at the comforts of your home.
                By choosing us, you pass on your day-to-day worries and we ensure to provide you with end-to-end
                service.
            </p>
        </div>
    </div>

    <!-- Logistics support -->
    <div class="container-fluid row mt-4">
        <div class="col-lg-8">
            <h4 class="text-dark">Logistics support</h4>
            <p>We are always ready for you with updated logistics support that can be managed virtually without any
                unnecessary hassle. We ensure to keep all our procurement logistics data up to date and online for easy
                accessibility. We live by the motto - you come first.</p>
            <p>A combination of data science and AI ensures an efficient logistical approach without making a dent in
                your pocket. So that there is no shortage of quality deliverables.</p>
            <p>Therefore, don’t expect anything less than sheer perfection with our logistics support.</p>
        </div>
        <div style="text-align: center; margin: auto;" class="col-lg-4 justify-content-center">
            <img src="../../../assets/images/logistic-support-3.png" alt="Logistics Support">
        </div>
    </div>

    <!-- Cloud platform -->
    <div class="container-fluid row mt-4">
        <div style="text-align: center; margin: auto;" class="col-lg-4 justify-content-center">
            <img src="../../../assets/images/cloud-plaform.png" alt="">
        </div>
        <div class="col-lg-8">
            <h4 class="text-dark">Cloud platform</h4>
            <p>Accessing real-time data becomes very much easy with our upgradation in using a cloud platform for data
                storage. With this cloud platform, you can tap into your store's data and analytics and access it from
                all parts of the world.</p>
            <p>Therefore, whether you are vacationing on the beaches of Hawaii or you are on a work conference in Dubai,
                your data travels with you and is just a few clicks away with our cloud data storage.</p>
        </div>
    </div>

    <!-- Integration -->
    <div class="container-fluid row mt-4">
        <div class="col-lg-8">
            <h4 class="text-dark">Integration</h4>
            <p>LogSolutec will make sure with its functionality of integration that your company gets to connect with
                other like-minded people or fellow audiences who can synchronize with you the most.</p>
            <p>Growing with collaborations becomes a lot easier with our integration facility. You can connect and bring
                forth any new changes, and software without creating a series of actions in the process.</p>
            <p>Apart from efficient logistics we work alongside you on your integration to streamline the process and
                eliminate unnecessary hurdles such as miscommunication.</p>
        </div>
        <div style="text-align: center; margin: auto;" class="col-lg-4 justify-content-center">
            <img src="../../../assets/images/integration.png" alt="">
        </div>
    </div>

    <!-- Backend support -->
    <div class="container-fluid row mt-4">
        <div style="text-align: center; margin: auto;" class="col-lg-4 justify-content-center">
            <img src="../../../assets/images/backend-support.png" alt="">
        </div>
        <div class="col-lg-8">
            <h4 class="text-dark">Backend support</h4>
            <p>We do not stop with the just deliverability, we go further than that. With our seamless backend support,
                you can rest assured as we handle all the required communications needed to be done between you and your
                client with absolute discretion.</p>
            <p>This ensures proper undertaking of operations and systematic management of your time.
                One of our core values is Transformation. We don't just say it, we mean it with our dedicated team
                available for you all day everyday, we make sure to take prompt action to eradicate the problem from the
                root.
            </p>
            <p>So, why wait when you can have the absolute best. Choose the best by choosing us.</p>
        </div>
    </div>

</section>