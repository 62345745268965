import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ServiceOrderService } from '../helpers/service-order.service';

@Component({
  selector: 'app-vendor-service-order-listing',
  templateUrl: './vendor-service-order-listing.component.html',
  styleUrls: ['./vendor-service-order-listing.component.css']
})
export class VendorServiceOrderListingComponent implements OnInit {

  page: number = 0;
  size: number = 25;
  totalCount: number;

  dataSource!: MatTableDataSource<any>;
  displayedColumns: string[] = ["number", "date", "forwaderName", "quoteNumber", "auctionNumber", "actions"];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(private serviceOrder: ServiceOrderService, private router: Router) { }

  ngOnInit(): void {
    this.getAllServiceOrder();
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    // this.serchFilter = true;
    // this.nodata = false;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  
  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  getAllServiceOrder(){
    this.serviceOrder.getAllServiceOrderPageable(this.page, this.size).subscribe(
      (response) => {
        console.log(response);
        if(response.content.length > 0){
          this.dataSource = new MatTableDataSource(response.content);
          this.dataSource.sort = this.sort;
        }
        this.totalCount = response.totalElements;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  onPageChange(event: PageEvent) {
    this.size = event.pageSize;
    this.page = event.pageIndex;
    this.getAllServiceOrder();
  }

  vendorEdit(id: number){
    this.router.navigate(['v1/vendor-service-order/edit/', id]);
  }

  downloadServiceOrder(id){
    this.serviceOrder.getServiceOrderPDF(id).subscribe(
      (response) => {
        console.log(response);
        var file = new Blob([response], { type: 'application/pdf' });
        console.log(file)
        var fileURL = URL.createObjectURL(file);
        var a = document.createElement('a');
        a.href = fileURL;
        a.target = '_blank';
        a.download = `ServiceOrder-${id}.pdf`
        document.body.appendChild(a);
        a.click();
      },
      (error) => {
        console.log(error);
      },
    )
  }

}
