import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SocketService } from 'src/app/helpers/socket.service';

@Component({
  selector: 'app-auction-client-comparison',
  templateUrl: './auction-client-comparison.component.html',
  styleUrls: ['./auction-client-comparison.component.css']
})
export class AuctionClientComparisonComponent implements OnInit {

  auctionClientComparison: FormGroup;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private socketService: SocketService) { }

  ngOnInit(): void {

    this.auctionClientComparison= this.fb.group({
      auctionNumber:[''],
      auctionDateAndTime:[''],
      currency:[''],
      auctionDescription:[''],
      

      //created by dhiraj //held for review
      // auctionComparisonTable:

    })

    // this.socketService.connectTOServerPopUpFuction();

  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }
}
