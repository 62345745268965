import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { forkJoin, Observable } from 'rxjs';
import { InvoiceService } from 'src/app/helpers/invoice.service';
import { MasterTableService } from 'src/app/helpers/master-table.service';
import { ShipmentService } from 'src/app/helpers/shipment.service';
import { SocketService } from 'src/app/helpers/socket.service';
import { AlertService } from 'src/app/utils/sweet-alert.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-client-shipment',
  templateUrl: './client-shipment.component.html',
  styleUrls: ['./client-shipment.component.css']
})
export class ClientShipmentComponent implements OnInit {

  /**
   * FormGroup Variable for shipment tracking form
   */
  shipmentTrackingForm: FormGroup;

  /**
   * Variable to store list of Movement Types
   */
  movementTypeList = [];

  /**
   * Variable to store List of Invoices
   */
  invoicesList = [];

  /**
   * Variable to identify whether shipment is Create/Update mode. If false, Create mode, true otherwise
   */
  isEdit = false;

  /**
   * Variable to store List of artifacts
   */
  artifactsList = [];

  /**
   * Variable to store shipment Id
   */
  shipmentId;

  //variable to store pickupincoterms list from session storage
  pickupIncotermsList:Array<any> = []

  //variable to store deliveryIncotermsList list from session storage
  deliveryIncotermsList:Array<any> = []

  //dropdown setting for multiselect dropdown
  dropdownSettings: IDropdownSettings = {
    idField: 'id',
    textField: 'number',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 5,
    allowSearchFilter: true,
    noDataAvailablePlaceholderText: "No Invoice Available",
    singleSelection: true
  };
  selectedInvoice: Array<any> = []
  pickUpIncoTerms: any[];
  dropOffIncoTerms: any[];

  // To store all carriers list
  carrierList: string[]

  //to store all the modeofshipment list
  modeOfShipmentList: Array<any> = []

  isAirModeOfShipment: boolean = false
  isSeaModeOfShipment: boolean = false
  isRailModeOfShipment: boolean = false
  isRoadModeOfShipment: boolean = false

  isContainersCountValid: boolean = false

  constructor(
    private invoiceService: InvoiceService,
    private masterTableService: MasterTableService,
    private shipmentService: ShipmentService,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder, private socketService: SocketService) { }

  ngOnInit(): void {
    this.getAllMasterTable();
    this.createShipmentTrackingForm();

    // Fetching route params
    this.activatedRoute.params.subscribe((routeData) => {
      if (routeData?.id) {
        this.isEdit = true;
        this.shipmentId = routeData.id;
        this.fetchShipmentById(this.shipmentId);
        this.getShipmentArtifacts(this.shipmentId);
      }
    });

    this.gotoTop();
  }

  get shipment() {
    return this.shipmentTrackingForm.controls
  }

  get invoices() {
    return this.shipmentTrackingForm.get('invoices') as FormArray
  }

  /**
   * Function Fetch Shipment Artifacts
   * @param shipmentId shipment id
   */
  getShipmentArtifacts(shipmentId) {
    this.shipmentService.getShipmentArtifacts(shipmentId).subscribe((data: any) => {
      if (data && data.length) {
        this.artifactsList = data;
      }
    }, (error) => {
      this.alertService.errorAlert(error?.message);
    });
  }

  /**
   * Function to delete shipment artifact
   * @param shipmentId shipment id
   * @param artifactId artifact id
   * @param index index of document to be deleted
   */
  deleteShipmentArtifact(shipmentId, artifactId, index: number) {
    this.shipmentService.deleteShipmentArtifact(shipmentId, artifactId).subscribe((data) => {
      this.alertService.successAlert(data?.message);
      this.artifactsList.splice(index, 1);
      this.artifactsList = [...this.artifactsList];
    }, (error) => {
      this.alertService.errorAlert(error?.message);
    });
  }

  /**
   * Function to fetch shipment By Id
   * @param id 
   */
  fetchShipmentById(id) {
    this.shipmentService.fetchShipmentById(id).subscribe((data) => {
      if (data) {
        console.log('this is update time shipment data ', data);
        this.isEdit = true;
        this.shipment.modeOfShipment.patchValue(data.modeOfShipment);
        this.shipment.modeOfShipment.disable();
        if (data.modeOfShipment == 1) {
          this.isAirModeOfShipment = true
          this.isSeaModeOfShipment = false
          this.shipmentTrackingForm.addControl('waybillIdentification', new FormArray([]));
          let  waybillIdentification: Array<any> = data.waybillIdentification;
          for (let index = 0; index < waybillIdentification.length; index++) {
            const element = waybillIdentification[index];
            this.prePopulateWaybillIdentification(element);
          }
        } else {
          this.isAirModeOfShipment = false
          this.shipmentTrackingForm.removeControl('waybillIdentification')
        }
        if (data.modeOfShipment == 2) {
          this.isSeaModeOfShipment = true
          this.shipmentTrackingForm.addControl('shippingLine', new FormGroup({}))
          let shippingLineGroup = this.shipmentTrackingForm.get('shippingLine') as FormGroup
          shippingLineGroup.addControl('id', new FormControl(data.shippingLine.id, Validators.required))
          this.shipmentTrackingForm.addControl('blContainersRef', new FormControl(data.blContainersRef, Validators.maxLength(25)))
          this.shipmentTrackingForm.addControl('containerNumber', new FormControl(data.containerNumber, Validators.maxLength(25)))
          this.shipmentTrackingForm.addControl('bookingNumber', new FormControl(data.bookingNumber, Validators.maxLength(100)))
          this.shipmentTrackingForm.addControl('containersCount', new FormControl(data.containersCount))
        } else {
          this.isSeaModeOfShipment = false
          this.shipmentTrackingForm.removeControl('shippingLine');
          this.shipmentTrackingForm.removeControl('blContainersRef');
          this.shipmentTrackingForm.removeControl('containerNumber');
          this.shipmentTrackingForm.removeControl('bookingNumber');
          this.shipmentTrackingForm.removeControl('containersCount');
        }
        if (data.movementType !== null) {
          this.shipment.movementType.patchValue(data.movementType)
        }
        if (data.internalNumber !== null) {
          this.shipment.internalNumber.patchValue(data.internalNumber)
        }
        if (data.customer !== null) {
          this.shipment.customer.patchValue(data.customer)
        }
        if (data.customerRefNumber !== null) {
          this.shipment.customerRefNumber.patchValue(data.customerRefNumber)
        }
        if (data.supplier !== null) {
          this.shipment.supplier.patchValue(data.supplier)
        }
        if (data.supplierRefNumber !== null) {
          this.shipment.supplierRefNumber.patchValue(data.supplierRefNumber)
        }

        if (data.purchaseOrderNumber !== null) {
          this.shipment.purchaseOrderNumber.patchValue(data.purchaseOrderNumber)
        }
        if (data.productName !== null) {
          this.shipment.productName.patchValue(data.productName)
        }
        if (data.soNumber !== null) {
          this.shipment.soNumber.patchValue(data.soNumber)
        }
        if (data.stuffingId !== null) {
          this.shipment.stuffingId.patchValue(data.stuffingId)
        }
        if (data.company !== null) {
          this.shipment.company.patchValue(data.company)
        }
        if (data.businessLocation !== null) {
          this.shipment.businessLocation.patchValue(data.businessLocation)
        }
        if (data.channel !== null) {
          this.shipment.channel.patchValue(data.channel)
        }
        if(data.shipmentOwner != null || ''){
          this.shipment.shipmentOwner.patchValue(data.shipmentOwner)
        }
        if(data.referenceNo != null || ''){
          this.shipment.referenceNo.patchValue(data.referenceNo)
        }

        //Additional Details
        if (data.contractNumber !== null) {
          this.shipment.contractNumber.patchValue(data.contractNumber)
        }
        if(data.linkedBlNumber != null){
          this.shipment.linkedBlNumber.patchValue(data.linkedBlNumber)
        }
        if (data.blIssueDate !== null) {
          this.shipment.blIssueDate.patchValue(data.blIssueDate)
        }
        if (data.pgiDate !== null) {
          this.shipment.pgiDate.patchValue(data.pgiDate)
        }
        if (data.promisedDate !== null) {
          this.shipment.promisedDate.patchValue(data.promisedDate)
        }
        if(data.emailAddress != null || ''){
          this.shipment.emailAddress.patchValue(data.emailAddress)
        }

        //Demurrage And Detention Details
        if (data.demurrageFreeDays !== null) {
          this.shipment.demurrageFreeDays.patchValue(data.demurrageFreeDays)
        }
        if (data.detentionFreeDays !== null) {
          this.shipment.detentionFreeDays.patchValue(data.detentionFreeDays)
        }
        if (data.cfsFreeDays !== null) {
          this.shipment.cfsFreeDays.patchValue(data.cfsFreeDays)
        }

        //to prepopulate selected invoices
        let invoice: Array<any> = data.invoices
        this.selectedInvoice = invoice
        for (let index = 0; index < invoice.length; index++) {
          const element = invoice[index];
          this.invoices.push(this.prePopulateInvoice(element));
        }
        this.changeNatureOfMovement();
        if (data.pickupIncoterms !== null) {
          this.shipment.pickupIncoterms.patchValue(data.pickupIncoterms)
        }
        if (data.deliveryIncoterms !== null) {
          this.shipment.deliveryIncoterms.patchValue(data.deliveryIncoterms)
        }
        console.log(this.shipmentTrackingForm.value);
      }
    }, (error) => {
      this.alertService.errorAlert(error?.message);
    });
  }

  //selected invoices
  onItemSelect(event) {
    this.invoices.clear();
    this.selectedInvoice.push(event)
    this.invoices.push(this.prePopulateInvoice(event))
  }

  //deselected invoices
  onDeSelect(event) {
    let id = event.id
    for (let index = 0; index < this.invoices.length; index++) {
      const element = this.invoices.at(index).get('id').value;
      if (id === element) {
        this.selectedInvoice.splice(index, 1);
        this.invoices.removeAt(index)
      }
    }
  }

  //prepopulate selected invoice
  prePopulateInvoice(data: any) {
    return this.fb.group({
      id: [data.id],
      number: [data.number]
    })
  }

  //Function to fetch Invoices related to org   
  getOrgInvoices() {
    this.invoiceService.getOrgInvoices().subscribe
    ((response)=>{
      this.invoicesList = response;
    },
    (error)=>{
      console.log(error);
    })
  }

  /**
   * Function to declare formcontrols for shipment tracking form
   */
  createShipmentTrackingForm() {
    this.shipmentTrackingForm = this.fb.group({

      modeOfShipment: ['', Validators.required],

      // Main Section Controls

      movementType: [null, Validators.required],
      internalNumber: ['', Validators.maxLength(100)],
      customer: ['', Validators.maxLength(200)],
      customerRefNumber: ['', Validators.maxLength(100)],
      supplier: ['', Validators.maxLength(200)],
      supplierRefNumber: ['', Validators.maxLength(100)],
      // access: [''],
      purchaseOrderNumber: ['', Validators.maxLength(100)],
      productName: ['', Validators.maxLength(100)],
      soNumber: ['', Validators.maxLength(100)],
      stuffingId: ['', Validators.maxLength(100)], // number type //changed to string type
      pickupIncoterms: [''],
      deliveryIncoterms: [''],
      company: ['', Validators.maxLength(200)],
      businessLocation: ['', Validators.maxLength(100)],
      channel: ['', Validators.maxLength(100)],
      number: [''],
      invoices: this.fb.array([]),

      // Additional Details Section Controls
      contractNumber: ['', Validators.maxLength(100)],
      blIssueDate: [''],
      pgiDate: [''],
      promisedDate: [''],  // Promised Transit Days (Ask Question)
      linkedBlNumber: ['', Validators.maxLength(100)],

      // Demurrage And Detention Details Section Controls
      demurrageFreeDays: [null],
      detentionFreeDays: [null, Validators.maxLength(5)],
      cfsFreeDays: [null, Validators.maxLength(5)],

      shipmentOwner: ['', Validators.maxLength(200)],
      emailAddress: [''],
      referenceNo: [''],
    });
  }

  get waybillIdentification(){return this.shipmentTrackingForm.get('waybillIdentification') as FormArray; }

  initWaybillIdentification(){
    return this.fb.group({
      waybillIdentification: ['', Validators.compose([Validators.required, Validators.maxLength(25)])]
    })
  }

  prePopulateWaybillIdentification(data){
    let group = this.fb.group({
      waybillIdentification: [data.waybillIdentification, Validators.compose([Validators.required, Validators.maxLength(25)])]
    })
    this.waybillIdentification.push(group);
  }

  addWaybillIdentification(){
    this.waybillIdentification.push(this.initWaybillIdentification());
  }

  deleteWaybillIdentification(index){
    this.waybillIdentification.removeAt(index);
  }

  getWaybillIdentification(i) {
    return (<FormArray>this.shipmentTrackingForm.get('waybillIdentification')).controls[i].invalid;
  }

  getMaxWaybillIdentification(i){
    return (<FormArray>this.shipmentTrackingForm.get('waybillIdentification')).controls[i].errors.maxLength;
  }

  /**
   * Function to handle "Start Tracking Button" Event
   */
  onSubmit() {
    console.log(this.shipment.modeOfShipment.value);

    if (this.shipmentTrackingForm.valid) {
      this.shipmentService.createShipmentTracking(this.shipmentTrackingForm.value).subscribe(
        (response) => {
          console.log(response);
          if(response.code == 201){
            this.alertService.successAlert(response?.message);
            if (this.artifactsList.length) {
              this.uploadAllDocuments(response?.entityId || this.shipmentId);
            }
            this.router.navigateByUrl('/v1/client-track-trace/shipment/listing');
          }
        },
        (error) => {
          console.log(error);
          let _error = error.error
          if(_error.errorCode == 412){
            this.alertService.errorAlert(_error?.errorMessage);
          }
        },
      )
      // this.saveShipmentData(this.shipmentId).subscribe((data) => {
      //   console.log(data);
      //   this.alertService.successAlert(data?.message);
        // if (this.artifactsList.length) {
        //   this.uploadAllDocuments(data?.entityId || this.shipmentId);
        // }
      //   this.router.navigateByUrl('/v1/client-track-trace/shipment/listing');
      // }, (error) => {
      //   console.log(error);
      //   this.alertService.errorAlert(error?.message);
      // });
    }
    else {
      console.log(this.shipmentTrackingForm.value);
      if (this.shipment.modeOfShipment.value == 1) {
        if (this.shipment.waybillIdentification.value == '') {
          Swal.fire({
            title: 'Warning',
            text: 'Please enter way bill indentification number',
            timer: 2000,
            icon: "warning"
          })
        }
      } else if (this.shipment.modeOfShipment.value == 2) {
        if (this.shipment.blContainersRef.value == '' && this.shipment.containerNumber.value == '' && this.shipment.bookingNumber.value == '') {
          Swal.fire({
            title: 'Warning',
            text: 'Please fill BL Number Or Container Number Or Booking Number',
            timer: 5000,
            icon: 'warning'
          })
        }
        else if (this.shipment.containersCount.value == null || '') {
          this.isContainersCountValid = true 
          Swal.fire({
            title: 'Info',
            text: 'Please enter containers count',
            timer: 2000,
            icon: "warning"
          })
        } 
        else {
          this.isContainersCountValid = false
        }
      } 
      else {
        console.log('shipment creation form is invalid ');
        this.shipmentTrackingForm.markAllAsTouched();
        Swal.fire({
          title: 'Warning',
          text: 'Please mandatory fields',
          timer: 5000,
          icon: 'warning'
        })
      }
    }
  }

  onUpdate(){ 
    if(this.shipmentTrackingForm.valid){
      this.shipmentService.updateShipmentTracking(this.shipmentTrackingForm.value,this.shipmentId).subscribe(
        (response) => {
          console.log(response);
          if(response.code == 200){
            this.alertService.successAlert(response?.message);
            if (this.artifactsList.length) {
              this.uploadAllDocuments(response?.entityId || this.shipmentId);
            }
            this.router.navigateByUrl('/v1/client-track-trace/shipment/listing');
          }
        },
        (error) => {
          console.log(error);
          let _error = error.error
          if(_error.errorCode == 412){
            this.alertService.errorAlert(_error?.errorMessage);
          }
        },
      )
    }
    else{
      console.log(this.shipmentTrackingForm.value);
      if (this.shipment.modeOfShipment.value == 1) {
        if (this.shipment.waybillIdentification.value == '') {
          Swal.fire({
            title: 'Warning',
            text: 'Please enter way bill indentification number',
            timer: 2000,
            icon: "warning"
          })
        }
      } else if (this.shipment.modeOfShipment.value == 2) {
        if (this.shipment.blContainersRef.value == '' && this.shipment.containerNumber.value == '' && this.shipment.bookingNumber.value == '') {
          Swal.fire({
            title: 'Warning',
            text: 'Please fill BL Number Or Container Number Or Booking Number',
            timer: 5000,
            icon: 'warning'
          })
        }
        else if (this.shipment.containersCount.value == null || '') {
          this.isContainersCountValid = true 
          Swal.fire({
            title: 'Info',
            text: 'Please enter containers count',
            timer: 2000,
            icon: "warning"
          })
        } 
        else {
          this.isContainersCountValid = false
        }
      } 
      else {
        console.log('shipment creation form is invalid ');
        this.shipmentTrackingForm.markAllAsTouched();
        Swal.fire({
          title: 'Warning',
          text: 'Please mandatory fields',
          timer: 5000,
          icon: 'warning'
        })
      }
    }
  }

  /**
   * Function to upload all documents
   */
  uploadAllDocuments(shipmentId) {
    let docList = [];
    this.artifactsList.forEach((element) => {
      if (!element.id) {
        let data = {
          "content": element.content,
          "contentType": element.contentType,
          "name": element.name,
          "location": "India",
          "label": element?.label
        };
        docList.push(this.shipmentService.createShipmentArtifact(shipmentId, data));
      }
    });

    // uploading all the documents in parallel
    forkJoin(docList).subscribe((data) => {
      console.log("Document upload success", data);
      this.router.navigate(['/v1/client-track-trace/shipment/listing']);
    }, (error) => {
      console.log("Error", error);
    })
  }

  /**
   * Function to handle "uploadAllArtifacts" event emitter of FileUploadComponent
  * @param event 
   */
  uploadAllArtifacts(event) {
    this.artifactsList = event;
  }

  /**
   * Function to handle "deleteArtifact" event emitter of FileUploadComponent
  * @param event 
   */
  deleteDocument(event) {
    console.log("Delete", event);
    this.deleteShipmentArtifact(this.shipmentId, event.item.id, event.index);
  }

  //to hide sidebar if click outside
  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  getAllShippingLines() {
    this.masterTableService.getAllShippingLines().subscribe(
      (res) => {
        this.carrierList = res
      },
      (error) => {
        console.log('this is error ', error);
      }
    )
  }

  onModeOfShipmentChange() {
    let modeOfShipment = this.shipment.modeOfShipment.value
    console.log('this is selected mode of shipment ', modeOfShipment);

    if (modeOfShipment == 1) {
      this.isAirModeOfShipment = true
      this.isSeaModeOfShipment = false
      // this.shipmentTrackingForm.addControl('waybillIdentification', new FormControl('', Validators.compose([Validators.required, Validators.maxLength(25)])))
      this.shipmentTrackingForm.addControl('waybillIdentification', new FormArray([]));
      this.addWaybillIdentification();
    } else {
      if(this.isAirModeOfShipment == true){
        this.waybillIdentification.clear();
      }
      this.isAirModeOfShipment = false
      this.shipmentTrackingForm.removeControl('waybillIdentification')
    }

    if (modeOfShipment == 2) {
      this.isSeaModeOfShipment = true
      this.shipmentTrackingForm.addControl('shippingLine', new FormGroup({}))
      let shippingLineGroup = this.shipmentTrackingForm.get('shippingLine') as FormGroup
      shippingLineGroup.addControl('id', new FormControl(null, Validators.required))
      this.shipmentTrackingForm.addControl('blContainersRef', new FormControl('', Validators.maxLength(25)))
      this.shipmentTrackingForm.addControl('containerNumber', new FormControl('', Validators.maxLength(25)))
      this.shipmentTrackingForm.addControl('bookingNumber', new FormControl('', Validators.maxLength(100)))
      this.shipmentTrackingForm.addControl('containersCount', new FormControl(null))
    } else {
      this.isSeaModeOfShipment = false
      this.shipmentTrackingForm.removeControl('shippingLine');
      this.shipmentTrackingForm.removeControl('blContainersRef');
      this.shipmentTrackingForm.removeControl('containerNumber');
      this.shipmentTrackingForm.removeControl('bookingNumber');
      this.shipmentTrackingForm.removeControl('containersCount');
    }

    if (modeOfShipment == 3) {
      this.isRailModeOfShipment = true
    } else {
      this.isRailModeOfShipment = false
    }

    if (modeOfShipment == 4) {
      this.isRoadModeOfShipment = true
    } else {
      this.isRoadModeOfShipment = false
    }
  }

  //fuction to scroll to top on next button click
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  isContainersCountEntered(){
    let containersCount =  this.shipment.containersCount.value
    if(containersCount == null || ''){
      this.isContainersCountValid = true;
      this.shipmentTrackingForm.get('containersCount').setErrors({'incorrect': true})
    } else {
      this.isContainersCountValid = false
      this.shipmentTrackingForm.get('containersCount').setErrors(null);
    }
  } 

  getPickUpIncoTerms(){
    this.masterTableService.getAllPickUpIncoTerms().subscribe(
      (response) => {
        this.pickupIncotermsList = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getDeliveryIncoTerms(){
    this.masterTableService.getDeliveryIncoTerms().subscribe(
      (response) => {
        this.deliveryIncotermsList = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getNatureOfMovement(){
    this.masterTableService.getNatureOfMovement().subscribe(
      (response) => {
        this.movementTypeList = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getModeOfShipment(){
    this.masterTableService.getModeOfShipment().subscribe(
      (response) => {
        this.modeOfShipmentList = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  changeNatureOfMovement(){
    let movementType = this.shipmentTrackingForm.get('movementType').value;
    this.pickUpIncoTerms = [];
    this.dropOffIncoTerms = [];
    // Filter pickUp
    if(this.pickupIncotermsList.length > 0){
      for (let index = 0; index < this.pickupIncotermsList.length; index++) {
        const element: any = this.pickupIncotermsList[index];
        let natureArray: Array<any> = element.natureOfMovement;
        natureArray.filter((x) => {
          if(x.code == movementType){
            this.pickUpIncoTerms.push(element);
          }
        })
      }
    }
    else{
      this.getPickUpIncoTerms();
    }
    // // Filter dropOff
    // this.getDeliveryIncoTerms();
    if(this.deliveryIncotermsList.length > 0){
      for (let index = 0; index < this.deliveryIncotermsList.length; index++) {
        const element: any = this.deliveryIncotermsList[index];
        let natureArray: Array<any> = element.natureOfMovement;
        natureArray.filter((x) => {
          if(x.code == movementType){
            this.dropOffIncoTerms.push(element);
          }
        })
      }
    }
    else{
      this.getDeliveryIncoTerms();
    }
    
  }

  getAllMasterTable(){
    this.getPickUpIncoTerms();
    this.getDeliveryIncoTerms();
    this.getOrgInvoices();
    this.getNatureOfMovement();
    this.getModeOfShipment();
    this.getAllShippingLines();
  }

}
