<section class="wrapper multi-step-form">
  <div class="container-fluid">
      <div class="row">
          <div
              class="col-md-4 col-xl-3 col-left pt-12 d-flex flex-column justify-content-center position-sticky top-0">
              <div class="box-absolute d-flex">
                  <app-sidebar></app-sidebar>
              </div>
              <div class="box mx-auto">
                  <h3 class="text-center text-md-start">User</h3>
                  <ul class="list-unstyled step-one">
                      
                      <li class=" " id="list-item-1">
                         <span>Change Password</span>
                      </li>
                  </ul>
              </div>
          </div>
          <div class="col-md-8 col-xl-9 col-right px-start-end pb-6">
              <div class="topline pt-4 pt-lg-5 pb-3 pb-lg-4 d-flex align-items-center justify-content-between">
                  
              </div>
              <div class="row justify-content-center mt-5" [formGroup]="changePasswordForm">
                <div class="col-md-7">
                  <div class="form-group">
                    <label for="" class="form-label">User Email Id</label>
                    <input type="text" class="form-control" formControlName="emailId" />
                  </div>
          
                  <div class="form-group mt-3">
                    <label for="" class="form-label">Current Password</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="currentPassword"
                      [ngClass]="{
                        'is-invalid':
                          f.currentPassword.invalid &&
                          (f.currentPassword.touched || f.currentPassword.dirty)
                      }"
                    />
          
                    <div
                      class="text-danger"
                      *ngIf="
                        f.currentPassword.invalid &&
                        (f.currentPassword.touched || f.currentPassword.dirty)
                      "
                    >
                      *Current password required
                    </div>
                  </div>
          
                  <div class="form-group mt-3">
                    <label for="" class="form-label">New Password</label>
                    <input
                      class="form-control"
                      placeholder=""
                      id="password"
                      formControlName="password"
                      [ngClass]="{
                        'is-invalid':
                          f.password.errors && (f.password.dirty || f.password.touched)
                      }"
                    />
                    <div
                      class="invalid-feedback validation-message"
                      *ngIf="f.password.errors"
                    >
                      <div *ngIf="f.password.errors.required">Password is required</div>
                      <div *ngIf="f.password.errors.minlength">
                        Password must be at least 8 characters
                      </div>
                      <div *ngIf="f.password.hasError('passwordStrength')">
                        {{ f.password.errors["passwordStrength"] }}
                      </div>
                    </div>
                  </div>
          
                  <div class="form-group mt-3">
                    <label for="" class="form-label">Confirm New Password</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="confirmPassword"
                      [ngClass]="{
                        'is-invalid':
                          f.confirmPassword.invalid &&
                          (f.confirmPassword.touched || f.confirmPassword.dirty)
                      }"
                    />
                  </div>
                </div>
              </div>
          
              <div class="form-group text-center mt-5">
                <button
                  class="btn btn-success"
                  (click)="onSubmit()"
                  [disabled]="changePasswordForm.invalid"
                >
                  Change Password
                </button>
              </div>
          </div>
      </div>
  </div>
</section>

