<h1 mat-dialog-title><strong>{{action}}</strong></h1>
<div mat-dialog-content>
  <form *ngIf="action != 'Delete'; else elseTemplate" [formGroup]="dialogForm">
    <mat-form-field>
      <input placeholder="Charge Name" required aria-pressed="false" matInput formControlName="name">
    </mat-form-field>
    <mat-form-field formGroupName="modeOfShipment" (change)="changeModeOfShipment()">
      <mat-label>Mode Of Shipment</mat-label>
      <select matNativeControl formControlName="id">
        <option value="" disabled selected>Select your option</option>
        <option *ngFor="let item of modeOfShipment" [value]="item.id">
          {{ item.value }}
        </option>
      </select>
    </mat-form-field>
    <mat-form-field *ngIf="seaBool">
      <mat-label>Load Charge Type</mat-label>
      <select matNativeControl formControlName="loadChargeType">
        <option value="" disabled selected>Select your option</option>
        <option *ngFor="let item of loadChargeTypeSea" [value]="item.value">
          {{ item.code }}
        </option>
      </select>
    </mat-form-field>
    <mat-form-field *ngIf="airBool">
      <mat-label>Load Charge Type</mat-label>
      <select matNativeControl formControlName="loadChargeType">
        <option value="" disabled selected>Select your option</option>
        <option *ngFor="let item of loadChargeTypeAir" [value]="item.value">
          {{ item.code }}
        </option>
      </select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Type</mat-label>
      <select matNativeControl formControlName="type">
        <option disabled selected>Select your option</option>
        <option *ngFor="let item of chargeTypeArray" [value]="item.value">
          {{ item.value }}
        </option>
      </select>
    </mat-form-field>
  </form>
  <ng-template #elseTemplate>
    Sure to delete <b>{{local_data.name}}</b>?
  </ng-template>
</div>
<div mat-dialog-actions>
  <button style="margin-right:20px;" class="btn-primary" mat-button (click)="doAction()">{{action}}</button>
  <button class="btn-danger" mat-button (click)="closeDialog()" mat-flat-button color="warn">Cancel</button>
</div>