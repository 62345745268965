import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AuctionService } from '../helpers/auction.service';
import { SocketService } from '../helpers/socket.service';
import { ShowAuctionTermsAndConditionsComponent } from './show-auction-terms-and-conditions/show-auction-terms-and-conditions.component';

@Component({
  selector: 'app-vendor-auction-list',
  templateUrl: './vendor-auction-list.component.html',
  styleUrls: ['./vendor-auction-list.component.css']
})
export class VendorAuctionListComponent implements OnInit {

  page: number = 0;
  size: number = 25;
  auctionLength: number;
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort) sort!:MatSort;
  @ViewChild(MatPaginator) paginator!:MatPaginator;

  displayedColumns:string[]=["auctionNumber","date","description","baseprice","status","actions"];
  checkedList: Array<any> = [];
  title: string = "Reverse Auction Listing";
  typeOfAuction: string = '';
  currentDateTime = new Date().toISOString();

  constructor(private auctionService: AuctionService, private route: ActivatedRoute, private socketService: SocketService,
    private router: Router, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.typeOfAuction = params.get('type').substring(0 ,1).toLowerCase();;
      this.getAllAuctions();
    });

    // this.socketService.connectTOServerPopUpFuction();
  }

  getAllAuctions(){
    this.auctionService.getAllAuctions(this.page, this.size, this.typeOfAuction, null).subscribe(
      (response) => {
        console.log(response);
        if(response.content != null){
          this.auctionLength = response.totalElements;
          this.dataSource = new MatTableDataSource(response.content);
        }
      },
      (error) => {
        console.log(error);
      },
      () => {},
    )
  }

  applyFilter(filterValue:string)
  {
    this.dataSource.filter=filterValue.trim().toLowerCase();
  }

  onPaginateChange(event){
    this.size=event.pageSize;
    this.page=event.pageIndex;
  }

  rejectAuction(){
    if (this.checkedList.length > 1 || this.checkedList.length == 0) {
      Swal.fire({
        titleText: "Invalid",
        text: "Check only one checkbox",
        icon: "warning",
        timer: 5000
      })
    }
    else {
      console.log("REJECTED");
      // this.router.navigate(['/v1/client-auction/creation/', this.checkedList[0]]);
    }
  }

  participateAuction(){
    if (this.checkedList.length > 1 || this.checkedList.length == 0) {
      Swal.fire({
        titleText: "Invalid",
        text: "Check only one checkbox",
        icon: "warning",
        timer: 5000
      })
    }
    else {
      console.log("APPROVED");
      // this.router.navigate(['/v1/client-auction/creation/', this.checkedList[0]]);
    }
  }

  compare(event, id) {
    let value = event.srcElement.checked;
    let _id = id;
    if (value == true) {
      this.checkedList.push(_id);
    }
    else if (value == false) {
      this.checkedList.splice(this.checkedList.indexOf(_id), 1);
    }
    else {
      console.log("Cant do this");
    }
  }

  onPageChange(event: PageEvent){
    this.size = event.pageSize;
    this.page = event.pageIndex;
    this.getAllAuctions();
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  partcipate(){
    if (this.checkedList.length > 1 || this.checkedList.length == 0) {
      Swal.fire({
        titleText: 'Warning',
        icon: 'warning',
        text: 'Kindly Select One Auction',
        timer: 5000
      })
    } else {
      console.log(this.checkedList[0]);
      let data: Array<any> = this.dataSource.data
      let row = data.find(x => {
        if(x.id == this.checkedList[0]){
          return x
        }
      });
      console.log(row);
      if(row.status == "IN_PROGRESS"){
        this.router.navigate(['/v1/vendor-live-auction/', this.checkedList[0]])
      }
      else if(row.status == "COMPLETE"){
        Swal.fire({
          title: "Warning",
          icon: "warning",
          timer: 5000,
          text: "Auction is completed."
        })
      }
      else{
        Swal.fire({
          title: "Warning",
          icon: "warning",
          timer: 5000,
          text: "Auction not started yet."
        })
      }
    }
  }

  provideComments(){
    if (this.checkedList.length > 1 || this.checkedList.length == 0) {
      Swal.fire({
        titleText: 'Warning',
        icon: 'warning',
        text: 'Kindly Select One Auction',
        timer: 5000
      })
    } else {
      console.log(this.checkedList[0]);
    }
  }

  openTermsAndConditions(auction){
    const dialogRef = this.dialog.open(ShowAuctionTermsAndConditionsComponent, {
      width: '80%',
      height: '80%',
      data: auction
    });

    dialogRef.afterClosed().subscribe((res)=>{
      this.getAllAuctions();
    })
  }
}
