import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from "@angular/material/table";
import { NatureDialogBoxComponent } from './nature-dialog-box/nature-dialog-box.component';
import { MasterTableService } from 'src/app/helpers/master-table.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-natureof-movement',
  templateUrl: './natureof-movement.component.html',
  styleUrls: ['./natureof-movement.component.css']
})

export class NatureofMovementComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<any>;
  displayedColumns = ['code', 'value', 'action'];
  serchFilter: Boolean = false;
  nodata: Boolean = true ;
  length: number;
  page: number = 0;
  size: number = 25;

  sessionStorageUserData: any
  disableCreateBtn: boolean = true
  disableEditBtn: boolean = true
  disableDeleteBtn: boolean = true

  constructor(private service: MasterTableService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getNatureOfMovement();
    this.getCurrentUserDataFromSessionStorage();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.serchFilter=true;
    this.nodata=false;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDialog(action: String, obj: any) {
    obj.action = action;
    const dialogRef = this.dialog.open(NatureDialogBoxComponent, {
      width: '250px',
      data: obj
    });
    dialogRef.afterClosed().subscribe(
      result => {
        if (result.event == 'Add') {
          this.addRowData(result.data);
        } else if (result.event == 'Update') {
          this.updateRowData(result.data);
        } else if (result.event == 'Delete') {
          this.deleteRowData(result.data);
        }
      }
    );
  }

  addRowData(row_obj) {
    this.AddNatureOfMovement(row_obj);
    setTimeout(() => {
      this.getNatureOfMovement();
    }, 500);
  }
  updateRowData(row_obj) {
    this.updateNatureOfMovement(row_obj.code, row_obj.value);
    setTimeout(() => {
      this.getNatureOfMovement();
    }, 500);
  }
  deleteRowData(row_obj) {
    this.deleteNatureOfMovement(row_obj.code);
    setTimeout(() => {
      this.getNatureOfMovement();
    }, 500);
  }

  getNatureOfMovement() {
    this.service.getNatureOfMovementPageable(this.page, this.size).subscribe(
      (response) => {
        if(response.content != null){
          this.dataSource = new MatTableDataSource(response.content);
          this.dataSource.sort = this.sort;
          this.length = response.totalElements;
        }
      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      }
    );
  }
  AddNatureOfMovement(data) {
    this.service.AddNatureOfMOvement(data).subscribe(
      (response) => { 
        console.log(response);
        if(response.code == 201){
          Swal.fire({
            titleText: "Success",
            text: response.message,
            icon: "success",
            timer: 5000
          })
        }
       },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      });
  }
  updateNatureOfMovement(code: string, value: any) {
    const data = { value: value };
    this.service.updateNatureOfMovement(code, data).subscribe(
      (response) => { 
        console.log(response);
        if(response.code == 201){
          Swal.fire({
            titleText: "Success",
            text: response.message,
            icon: "success",
            timer: 5000
          })
        }
      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      });
  }
  deleteNatureOfMovement(code: any) {
    this.service.deleteNatureOfMovement(code).subscribe(
      (response) => { 
        console.log(response);
        if(response.code == 201){
          Swal.fire({
            titleText: "Success",
            text: response.message,
            icon: "success",
            timer: 5000
          })
        }
      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      });
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  onPageChange(event: PageEvent) {
    this.size = event.pageSize;
    this.page = event.pageIndex;
    this.getNatureOfMovement();
  }

  getCurrentUserDataFromSessionStorage(){
    // create auth code
    // RB0000078
    // update auth code
    // RB0000079
    // delete auth code
    // RB0000092
      this.sessionStorageUserData = JSON.parse(sessionStorage.getItem('userData'));
      console.log('this is user data ', this.sessionStorageUserData);
      for (let index = 0; index < this.sessionStorageUserData.authorities.length; index++) {
        const authority = this.sessionStorageUserData.authorities[index];
        console.log(authority);
        if(authority == 'RB0000078'){
          console.log('create authority is given');
          this.disableCreateBtn = false
        } else if(authority == 'RB0000079'){
          console.log('update authority is given');
          this.disableEditBtn = false
        } else if(authority == 'RB0000092') {
          console.log('delete authority is given');
          this.disableDeleteBtn = false
        }
      }
    }
}