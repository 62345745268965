<app-header></app-header>
<section class="banner text-center text-white d-flex align-items-center banner-full"
    style="background-image: url(assets/images/banner.jpg)">
    <div class="container position-relative">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <div class="heading-dual">
                    <h1>LogSolutec</h1>
                    <h2>Corporate Social Responsibility</h2>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="about-wrapper text-dark py-8">
    <div class="container p-lg-start-end mb-4">
        <div class="justify-content-center mt-2 mb-2">
            <img src="../../../assets/images/csr.png" alt="">
        </div>
    </div>
    <div class="container mt-5 ml-4">
        <p>It is critical that we continue to support the accumulation of social wealth, the advancement of social
            civilization, and the sustainability of our planet as we expand.
        </p>
        <p>
            Integrity, respect, responsibility, and peaceful coexistence with society, the environment, and
            sustainable development are values we advocate at LogSolutec. We aim to examine and improve our plans on
            a regular basis in order to provide opportunities for people and communities, safeguard the environment,
            and promote positive social change.
        </p>
        <p>We live in a society where the condition of politics, human rights, and transparency are always on
            people's minds. What makes this sector what it is are standards that hold companies to a particular
            level of responsibility outside of the basic things they must do legally. We aren't losing any of our
            competitiveness, we're just on a different level right now.</p>

        <p>The fundamental purpose of LogSolutec is to safeguard the health and safety of its employees and third
            parties when conducting worldwide business. In a multi-cultural setting, the company also aspires to
            recognise all of its talented employees without discrimination, promote gender equality and social
            inclusion, and ensure that human and fundamental rights are respected and promoted across the industry.
        </p>
        <p>We believe that our solutions and values can help your company achieve more. More spending is taking
            place across more channels than ever before, using more payment methods than ever before, as business
            advances at a faster pace. And your clients are expecting more from the companies with whom they do
            business. They want more speed, higher quality, the best experience, and complete transparency into the
            global impact you and your suppliers have.
        </p>
        <p>Procurement and supply chain managers must continue to keep tabs on all expenses, manage the risks
            inherent in your transactions, and do so while ensuring that process does not curb development.
        </p>
        <p>We think that each individual has the ability to have a significant impact on another person, a
            community, or society as a whole. If we multiply that by a large number, these moments become movements
            that transform the world and make it better for all of us.
        </p>
        <p>By using the strength of our people, collective passions and experience, and technology, LogSolutech's
            focus on corporate social responsibility enhances our effect on communities. We make a huge difference
            in every part of the world because our people put their hearts and minds into action.
        </p>
        <p>We understand that our responsibilities extend beyond earning revenue and profits. We owe it to our
            fellow humans to be good stewards of the environment by reducing the adverse effects of freight
            emissions and energy. We owe it to the communities where we live and work to contribute to their quality
            of life by sharing our good fortune. Last but not least, we owe it to ourselves, our customers, and our
            stakeholders to always act professionally and ethically.
        </p>
    </div>
</section>