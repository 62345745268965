import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/helpers/user.service';
import Swal from 'sweetalert2';
import { isFunction } from 'util';


@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {

  userProfile: any;
  userForm: FormGroup;
  isImgDefault: Boolean = false;
  userImage: any;
  userId: any;
  constructor(private userService: UserService, private fb: FormBuilder, private router: Router) { }

  ngOnInit(): void {
    let userData = JSON.parse(sessionStorage.getItem('userData'));
    console.log(userData.userId);
    if (userData.userId != null) {
      this.userId = userData.userId
      this.getUserById(this.userId);
    }
    this.userForm = this.fb.group({
      emailId: [''],
      fname: [''],
      lname: [''],
      contactNumber: [''],
      username: ['']
    });
  }
  get f() { return this.userForm.controls };


  getUserById(id: any) {
    this.userService.getUserById(id).subscribe(
      (response) => {
        if (response.id != null) {
          this.userProfile = response;
          this.f.username.patchValue(response.username);
          this.f.fname.patchValue(response.fname);
          this.f.lname.patchValue(response.lname);
          this.f.emailId.patchValue(response.emailId);
          this.f.emailId.disable();
          this.f.contactNumber.patchValue(response.contactNumber);
          if(response.artifact != null) {
            this.isImgDefault = true;
            this.dataURItoBlob(response.artifact);
          }
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  updateUser() {
    if (!this.userForm.invalid) {
      this.userService.updateUser(this.userForm.getRawValue()).subscribe(
        (response) => {
          if (response.code == 202) {
            Swal.fire({
              titleText: "Successful",
              icon: "success",
              timer: 5000,
              text: response.message
            });
            // this.router.navigate(['/v1/user/listing']);
          }
        },
        (error) => {
          console.log(error);
          let _error = error.error;
          if (_error.errorCode == 412) {
            Swal.fire({
              titleText: "Error",
              icon: "error",
              timer: 5000,
              text: _error.errorMessage
            });
          }
          else {
            Swal.fire({
              titleText: "Error",
              icon: "error",
              timer: 5000,
              text: "Something went wrong, try again later."
            });
          }
        },
      )
    }
    else {
      this.userForm.markAllAsTouched();
      Swal.fire({
        text: "Fill all the fields",
        icon: "warning",
        timer: 5000,
        titleText: "Warning"
      })
    }
  }

  routeToPasswordChange() {
    this.router.navigate(['v1/change-password'])
  }

  uploadImage(event: any) {
    let file = event.target.files[0]
    const fileSize = Math.round((file.size / 1024));
    if (fileSize > 1024) {
      Swal.fire({
        titleText: "Error",
        icon: "error",
        text: "Upload File :" + file.name + " with size less than 1MB",
        timer: 5000
      });
    }
    else {
      let _data = {};
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        _data['content'] = reader.result;
        _data['contentType'] = reader.result?.toString().split(',')[0]
        _data['name'] = file.name
        _data['size'] = file.size
        _data['label'] = "Profile Picture"
      }
      console.log(_data);
      if(_data != null || undefined){
        Swal.fire({
          icon: "question",
          timer: 10000,
          title: "Are you Sure ?"
        }).then( 
          (res) => {
            if(res.isConfirmed) {
              this.uploadProfilePicture(_data);
            }
          },
          (reject) => {
            console.log(reject);
          }
        )
      }
    }
  }

  deleteImage(){
    Swal.fire({
      timer: 10000,
      icon: "question",
      title: "Are you Sure ??",
      showCancelButton: true
    }).then(
      (res) => {
        if(res.isConfirmed){
          this.deleteProfilePicture();
        }
      },
      (reject) => {
        console.log(reject);
      }
    )
  }

  uploadProfilePicture(data){
    this.userService.postProfilePicture(data).subscribe(
      (response) => {
        console.log(response);
        if(response.code == 201){
          Swal.fire({
            title: "Success",
            icon: "success",
            timer: 5000,
            text: response.message
          });
          this.getUserById(this.userId);
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  deleteProfilePicture(){
    this.userService.deleteProfilePicture().subscribe(
      (response) => {
        console.log(response);
        if(response.code == 200){
          Swal.fire({
            title: "Success",
            text: response.message,
            icon: "success",
            timer: 5000
          });
          this.isImgDefault = false;
          this.getUserById(this.userId);
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }


  dataURItoBlob(data) {
    const linkSource = data.content;
    this.userImage = linkSource;
  }

}
