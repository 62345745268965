import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-shipment-dialog-box',
  templateUrl: './shipment-dialog-box.component.html',
  styleUrls: ['./shipment-dialog-box.component.css']
})
export class ShipmentDialogBoxComponent implements OnInit {
  dialogForm: FormGroup;
  local_data: any;
  action: any;
  isReadOnly = false;

  constructor(
    private formbuilder: FormBuilder,
    public dialogRef: MatDialogRef<ShipmentDialogBoxComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.action = data.action;
    this.local_data = { ...data };
    if (this.action == 'Update') { this.isReadOnly = true; }
  }
  ngOnInit(): void {
    this.dialogForm = this.formbuilder.group({
      value: ''
    });
    if (this.action != "Add") {
      this.dialogForm.addControl('id', new FormControl());
      this.dialogForm.patchValue({
        value: this.local_data.value,
        id: this.local_data.id
      });
    }
  }
  doAction() {
    if (this.dialogForm.valid) {
      this.dialogRef.close({ event: this.action, data: this.dialogForm.value });
    } else {
      Swal.fire({
        title: 'warning',
        icon: 'warning',
        titleText: 'Please provide mandatory field',
        timer: 3000
      });
    }
  }
  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

}
