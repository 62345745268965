import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { QuoteService } from 'src/app/helpers/quote.service';
import { DatePipe } from '@angular/common';
import { MasterTableService } from 'src/app/helpers/master-table.service';
import Swal from 'sweetalert2';
import { CountryCode, CountryCodes } from 'src/app/helpers/country_code_picker';
import { SocketService } from 'src/app/helpers/socket.service';


interface ChargeType {
  id?: number,
  chargeName: String,
  chargeType: String,
  modeOfShipment: number,
  charge?: number,
  loadChargeType?: string,
  type?: string
}

@Component({
  selector: 'app-vendor-rfq-quote',
  templateUrl: './vendor-rfq-quote.component.html',
  styleUrls: ['./vendor-rfq-quote.component.css']
})
export class VendorRfqQuoteComponent implements OnInit {

  invalidETDdate: Boolean = false;
  quoteList: Array<any> = [];
  sea_mode_shipment: Boolean = false
  air_mode_shipment!: Boolean
  break_bulk!: Boolean
  rfqDetails!: FormGroup;
  rfqRates!: FormGroup;
  particulars!: FormGroup;
  enquiryId!: any;

  public container_type = [];
  public mode_of_shipment = [];
  public trade_type = [];
  public type_cargo = [];
  public nature_of_movement = [];
  public originDestination = [];
  public pickup_terms = [];
  public dropoff_terms = [];
  updateQuote: Boolean = false;
  createQuote: Boolean = false;
  public container_load_type = [
    { 'id': 1, 'value': 'Full Container Load (FCL)' },
    { 'id': 2, 'value': 'Less than Container Load (LCL)' },
    { 'id': 3, 'value': 'Both' },
    { 'id': 4, 'value': 'Break Bulk' }
  ]

  breadcrumbBool: Boolean = true;
  backfirstPage: Boolean = false;
  backDetailPage: Boolean = false;
  disabled: Boolean = false;
  invalidQuoteValidity: Boolean = false;
  documentList: Array<any> = [];
  maxFileSize: number = 2000000;
  maxSizeImage: number = 1000000;
  quoteId: any;
  downloadDocumentList: Array<any> = [];
  dimensionsUOM: Array<any> = [
    { name: "CM" },
    { name: "MM" },
    { name: "IN" },
    { name: "FT" },
    { name: "MTR" },
  ]
  grossWeightUom: Array<any> = [
    { name: "KG" },
    { name: "MT" },
    { name: "LBS" },
  ]
  fullContainerLoadBool: Boolean = false;
  halfContianerLoadBool: Boolean = false;
  showPickupAddress: Boolean = false;
  delivaryAddress: Boolean = false;
  showTerminal: Boolean = false;
  breakbulkfile: Array<any> = []
  totalGrossWeightAIR: number;
  totalVolumeWeightAIR: number;
  totalGrossWeightSEA: number;
  totalVolumeWeightSEA: number;
  airArrayOfKeys: any;
  chargableWeight: number;
  chargableWeightUOM: string;
  totalBreakBulkWeight: number;
  totalBreakBulkVolume: number;
  chargeType: Array<any> = []

  finalSeaWeightUom: string;
  finalAirWeightUom: string;
  finalAirWeightBool: boolean;
  finalSeaWeightBool: boolean;
  selected = new FormControl(0);
  tabs = ['First'];
  transitTimeMax: number = 15;

  isBidUpdate: boolean = false

  isThirdCountrySelected: boolean = false

  currency: string = "";
  lastSubmissionDate: any;
  nature_goods: Array<any> = [];
  type_packing: Array<any> = [];
  countries: Array<any> = [];
  loadingPorts: Array<any> = [];
  destinationPorts: Array<any> = [];

  searchOriginValue: string
  searchDestinationValue: string
  exWorksBool: Boolean = false;
  deliveryChargeBool: Boolean = false;
  additionalChargePerUnitBool = false;
  // checkbox: Array<any> = [];

  constructor(private fb: FormBuilder, private router: Router, private route: ActivatedRoute, private vendorService: QuoteService, public datepipe: DatePipe, private masterTableService: MasterTableService, private socketService: SocketService) { }

  ngOnInit(): void {
    this.getMasterTablesSessionStorage();
    this.enquiryId = this.route.snapshot.paramMap.get("id");
    if (this.enquiryId != null || undefined) {
      this.fetchQuoteByEnquiryId(this.enquiryId);
    }

    this.rfqDetails = this.fb.group({
      number: [''],
      enquiryDate: [''],
      description: ['', Validators.required],
      projectName: [''],
      projectCode: [''],
      modeOfShipment: ['', Validators.required],
      tradeType: ['', Validators.required],
      origin: ['', Validators.required],
      destination: ['', Validators.required],
      typeOfCargo: ['', Validators.required],
      cargoReadinessDate: ['', Validators.required],
      commodity: ['', Validators.required],
      storingType: ['', Validators.required],
      natureOfMovement: ['', Validators.required],
      pickUpIncoterms: ['', Validators.required],
      deliveryIncoterms: ['', Validators.required],
      hsCode: [''],
      containerLoadType: [''],
      currency: [''],
      containers: this.fb.array([]),
      shipmentPackages: this.fb.array([]),
      breakBulks: this.fb.array([]),
      type: [''],
      lastSubmissionDate: [''],
      terms: this.fb.array([]),
      detention: ['', Validators.required],
      demurrage: ['']
    })

    this.rfqRates = this.fb.group({
      number: [''],
      quoteDate: new Date().toISOString().substring(0, 10),
      noRegretOffer: [false],
      vendorQuoteRows: this.fb.array([])
    });
    this.getChargeType();
    // this.socketService.connectTOServerPopUpFuction();
    this.gotoTop();
  }

  get rfq_details() { return this.rfqDetails.controls; }
  get rfq_rates() { return this.rfqRates.controls; }
  get pre_containers() { return this.rfqDetails.get('containers') as FormArray; }
  get pre_shipmentPackages() { return this.rfqDetails.get('shipmentPackages') as FormArray; }
  get pre_breakBulks() { return this.rfqDetails.get('breakBulks') as FormArray; }
  get pre_termsConditions() { return this.rfqDetails.get('terms') as FormArray; }
  get currencyId() { return this.rfqDetails.get('currency') as FormGroup; }
  

  removeTab(index: number) {
    this.tabs.splice(index, 1);
    this.vendorQuoteRow().removeAt(index);
  }

  vendorQuoteRow(): FormArray { return this.rfqRates.get('vendorQuoteRows') as FormArray; }

  vendorRowContainer(vendorIndex: number): FormArray {
    return this.vendorQuoteRow().at(vendorIndex).get('vendorQuoteContainers') as FormArray;
  }

  vendorRowShipmentPackagesPerUnit(vendorIndex: number): FormArray {
    return this.vendorQuoteRow().at(vendorIndex).get('vendorQuoteShipmentPackagesPerUnit') as FormArray;
  }
  vendorRowShipmentPackagePerShipment(vendorIndex: number): FormArray {
    return this.vendorQuoteRow().at(vendorIndex).get('vendorQuoteShipmentPackagesPerShipment') as FormArray;
  }

  vendorRowBreakBulkPerUnit(vendorIndex: number): FormArray {
    return this.vendorQuoteRow().at(vendorIndex).get('vendorQuoteBreakBulkPerUnit') as FormArray;
  }

  vendorRowBreakBulkPerShipment(vendorIndex: number): FormArray {
    return this.vendorQuoteRow().at(vendorIndex).get('vendorQuoteBreakBulkPerShipment') as FormArray;
  }

  vendorRowAdditionalChargesPerUnit(vendorIndex: number): FormArray {
    return this.vendorQuoteRow().at(vendorIndex).get('additionalChargesPerUnit') as FormArray;
  }

  vendorRowAdditionalChargesPerShipment(vendorIndex: number): FormArray {
    return this.vendorQuoteRow().at(vendorIndex).get('additionalChargesPerShipment') as FormArray;
  }

  initParticular() {
    return this.fb.group({
      additionalChargesPerUnit: this.fb.array([]),
      additionalChargesPerShipment: this.fb.array([]),
      additionalChargePerShipmentSubTotal: [0.0],
      additionalChargePerUnitSubTotal: [0.0],
      additionalChargeTotal: [0.0],
      amount: [0.0],
      eta: [''],
      etd: ['', Validators.required],
      frequency: ['', Validators.compose([Validators.required])],
      grandTotals: this.fb.array([]),
      lineName: ['Enter Name', Validators.compose([Validators.required, Validators.maxLength(40)])],
      loadingTerminal: [''],
      quoteValidTill: ['', Validators.required],
      routing: ['', Validators.required],
      sailingDays: [''],
      transitTime: ['', Validators.required],
      transitPort: ['', Validators.required],
      vendorQuoteBreakBulkPerUnit: this.fb.array([]),
      vendorQuoteBreakBulkPerShipment: this.fb.array([]),
      breakBulkPerUnitSubTotal: [0.0],
      breakBulkPerShipmentSubTotal: [0.0],
      breakBulkTotal: [0.0],
      vendorQuoteContainers: this.fb.array([]),
      containerTotal: [0.0],
      vendorQuoteShipmentPackagesPerUnit: this.fb.array([]),
      packageFreightPerShipmentSubTotal: [0.0],
      packageFreightPerUnitSubTotal: [0.0],
      packageFreightTotal: [0.0],
      vendorQuoteShipmentPackagesPerShipment: this.fb.array([]),
      vesselName: [''],
      vesselSchedule: [''],
    })
  }


  initvendorQuoteContainers(data?: any) {
    return this.fb.group({
      quantity: [data.quantity],
      type: [data.type],
      size: [data.size],
      container: this.fb.group({
        id: [data.id],
      }),
      loadChargeType: ["CONTAINER"],
      ratePerContainer: ['', Validators.required],
      exWorks: [''],
			destinationCharges: [''],
      totalFreight: [0]
    })
  }

  initAdditionalChargesPerUnit(data: any, chargeTypeId?: number) {
    return this.fb.group({
      charge: [data?.charge, Validators.required],
      chargeType: this.fb.group({
        id: [chargeTypeId || data?.id],
        loadChargeType: [data.loadChargeType],
        modeOfShipment: this.fb.group({
          value: [data.modeOfShipment]
        })
      }),
      description: [data.name || '' || data.description, Validators.required],
      loadChargeType: [data.loadChargeType],
      type: [data.type || '']
    })
  }

  initAdditionalChargesPerShipment(data: any, chargeTypeId?: number) {
    return this.fb.group({
      charge: [data?.charge, Validators.required],
      chargeType: this.fb.group({
        id: [chargeTypeId || data?.id],
        loadChargeType: [data.loadChargeType],
        modeOfShipment: this.fb.group({
          value: [data.modeOfShipment]
        })
      }),
      description: [data.name || '' || data.description, Validators.required],
      loadChargeType: [data.loadChargeType],
      type: [data.type || '']
    })
  }

  initShipmentPackagePerShipment(data: any, chargeTypeId?: number) {
    return this.fb.group({
      charge: [data?.charge, Validators.required],
      chargeType: this.fb.group({
        id: [chargeTypeId || data?.id],
        loadChargeType: [data.loadChargeType],
        modeOfShipment: this.fb.group({
          value: [data.modeOfShipment]
        })
      }),
      description: [data.name || '' || data.description, Validators.required],
      loadChargeType: [data.loadChargeType],
      type: [data.type || '']
    })
  }

  initShipmentPackagePerUnit(data: any, chargeTypeId?: number) {
    return this.fb.group({
      charge: [data?.charge, Validators.required],
      chargeType: this.fb.group({
        id: [chargeTypeId || data?.id],
        loadChargeType: [data.loadChargeType],
        modeOfShipment: this.fb.group({
          value: [data.modeOfShipment]
        })
      }),
      description: [data.name || '' || data.description, Validators.required],
      loadChargeType: [data.loadChargeType],
      type: [data.type || '']
    })
  }

  initBreakBulkPerUnit(data: any) {
    return this.fb.group({
      charge: ['', Validators.required],
      chargeType: this.fb.group({
        id: [data?.id],
        loadChargeType: [data.loadChargeType],
        modeOfShipment: this.fb.group({
          value: [data.modeOfShipment]
        })
      }),
      description: [data.name || ''],
      loadChargeType: [data.loadChargeType],
      type: [data.type || '']
    })
  }

  initBreakBulkPerShipment(data: any) {
    return this.fb.group({
      charge: ['', Validators.required],
      chargeType: this.fb.group({
        id: [data?.id],
        loadChargeType: [data.loadChargeType],
        modeOfShipment: this.fb.group({
          value: [data.modeOfShipment]
        })
      }),
      description: [data.name || ''],
      loadChargeType: [data.loadChargeType],
      type: [data.type || '']
    })
  }

  populateAdditionalChargesPerUnit(data: any) {
    if (data.chargeType != null) {
      return this.fb.group({
        charge: [data?.charge, Validators.required],
        chargeType: this.fb.group({
          id: [data.chargeType.id],
        }),
        description: [data.description || '', Validators.required],
        loadChargeType: [data.loadChargeType],
        type: [data.type]
      })
    }
    else {
      return this.fb.group({
        charge: [data?.charge, Validators.required],
        chargeType: [null],
        description: [data.description || '', Validators.required],
        loadChargeType: [data.loadChargeType],
        type: [data.type]
      })
    }
  }

  populateAddtionalChargesPerShipment(data: any) {
    if (data.chargeType != null) {
      return this.fb.group({
        charge: [data?.charge, Validators.required],
        chargeType: this.fb.group({
          id: [data.chargeType.id],
        }),
        description: [data.description || '', Validators.required],
        loadChargeType: [data.loadChargeType],
        type: [data.type]
      })
    }
    else {
      return this.fb.group({
        charge: [data?.charge, Validators.required],
        chargeType: [null],
        description: [data.description || '', Validators.required],
        loadChargeType: [data.loadChargeType],
        type: [data.type]
      })
    }
  }

  populateShipmentPackagePerShipment(data: any) {
    if (data.chargeType != null) {
      return this.fb.group({
        charge: [data?.charge, Validators.required],
        chargeType: this.fb.group({
          id: [data.chargeType.id],
        }),
        description: [data.description || '', Validators.required],
        loadChargeType: [data.loadChargeType],
        type: [data.type]
      })
    }
    else {
      return this.fb.group({
        charge: [data?.charge, Validators.required],
        chargeType: [null],
        description: [data.description || '', Validators.required],
        loadChargeType: [data.loadChargeType],
        type: [data.type]
      })
    }
  }

  populateShipmentPackagePerUnit(data: any) {
    if (data.chargeType != null) {
      return this.fb.group({
        charge: [data?.charge, Validators.required],
        chargeType: this.fb.group({
          id: [data.chargeType.id],
        }),
        description: [data.description || '', Validators.required],
        loadChargeType: [data.loadChargeType],
        type: [data.type]
      })
    }
    else {
      return this.fb.group({
        charge: [data?.charge, Validators.required],
        chargeType: [null],
        description: [data.description || '', Validators.required],
        loadChargeType: [data.loadChargeType],
        type: [data.type]
      })
    }
  }

  populateContainer(data: any) {
    return this.fb.group({
      container: this.fb.group({
        id: [data.container.id]
      }),
      id: [data.id],
      type: [data.container.type],
      size: [data.container.size],
      quantity: [data.container.quantity],
      loadChargeType: [data.loadChargeType],
      ratePerContainer: [data.ratePerContainer],
      exWorks: [data.exWorks],
      destinationCharges: [data.destinationCharges],
      totalFreight: [data.totalFreight]
    })
  }

  populateBreakBulkPerUnit(data: any) {
    if (data.chargeType != null) {
      return this.fb.group({
        charge: [data.charge],
        loadChargeType: [data.loadChargeType],
        chargeType: this.fb.group({
          id: [data.chargeType.id]
        }),
        description: [data.description],
        type: [data.type]
      })
    }
    else {
      return this.fb.group({
        charge: [data.charge],
        loadChargeType: [data.loadChargeType],
        chargeType: [null],
        description: [data.description],
        type: [data.type]
      })
    }
  }

  populateBreakBulkPerShipment(data: any) {
    if (data.chargeType != null) {
      return this.fb.group({
        charge: [data.charge],
        loadChargeType: [data.loadChargeType],
        chargeType: this.fb.group({
          id: [data.chargeType.id]
        }),
        description: [data.description],
        type: [data.type]
      })
    }
    else {
      return this.fb.group({
        charge: [data.charge],
        loadChargeType: [data.loadChargeType],
        chargeType: [null],
        description: [data.description],
        type: [data.type]
      })
    }
  }

  fetchEnquiryById(id: number) {
    this.vendorService.fetchEnquiryById(id).subscribe(
      (response: any) => {
        if (response) {
          this.vendorQuoteRow().push(this.initParticular());
          let enquiry = response;

          // ToDo Break Bulk Chargeable Weight left.
          this.createQuote = true;
          this.chargableWeight = enquiry.chargableWeight;
          this.chargableWeightUOM = enquiry.chargableWeightUOM;
          this.rfq_details.description.patchValue(enquiry.description);
          this.rfq_details.number.patchValue(enquiry.number || '12345');
          this.rfq_details.projectName.patchValue(enquiry.projectName)
          this.rfq_details.projectCode.patchValue(enquiry.projectCode)
          this.rfq_details.modeOfShipment.patchValue(enquiry.modeOfShipment);
          this.rfq_details.tradeType.patchValue(enquiry.tradeType);
          this.rfq_details.typeOfCargo.patchValue(enquiry.typeOfCargo);
          this.rfq_details.demurrage.patchValue(enquiry.demurrage);

          this.searchOriginValue = enquiry.originPortName
          this.getSearchResultsOrigin(enquiry.modeOfShipment);

          this.searchDestinationValue = enquiry.destinationPortName
          this.getSearchResultDestination(enquiry.modeOfShipment)

          this.rfq_details.origin.patchValue(enquiry.origin);
          this.rfq_details.destination.patchValue(enquiry.destination);
          this.rfq_details.commodity.patchValue(enquiry.commodity);
          if (enquiry.storingType == 'n') {
            this.rfq_details.storingType.patchValue('Non-Stackable');
          } else if (enquiry.storingType == 's') {
            this.rfq_details.storingType.patchValue('Stackable');
          }
          this.rfq_details.natureOfMovement.patchValue(enquiry.natureOfMovement);
          this.rfq_details.currency.patchValue(enquiry.currency.currency);
          this.currency = enquiry.currency.currency;
          this.rfq_details.pickUpIncoterms.patchValue(enquiry.pickUpIncoterms);
          if (enquiry.pickUpIncoterms == 'EXW' || enquiry.pickUpIncoterms == 'FCA') {
            this.showPickupAddress = true;
            if (enquiry.pickupPersonName != null) {
              this.rfqDetails.addControl('pickupPersonName', new FormControl(enquiry.pickupPersonName));
            }
            else {
              this.rfqDetails.addControl('pickupPersonName', new FormControl());
            }
            if (response.pickupPersonAddress != null) {
              this.rfqDetails.addControl('pickupPersonAddress', new FormGroup({}));
              let pickupAddress = this.rfqDetails.get('pickupPersonAddress') as FormGroup;
              pickupAddress.addControl('label', new FormControl('Pickup Address'))
              pickupAddress.addControl('street1', new FormControl(response.pickupPersonAddress.street1))
              pickupAddress.addControl('street2', new FormControl(response.pickupPersonAddress.street2))
              pickupAddress.addControl('city', new FormControl(response.pickupPersonAddress.city))
              pickupAddress.addControl('state', new FormControl(response.pickupPersonAddress.state))
              // pickupAddress.addControl('country', new FormControl(response.pickupPersonAddress.country))
              pickupAddress.addControl('country', new FormGroup({}));
              let country = pickupAddress.get('country') as FormGroup;
              country.addControl('id', new FormControl(response.pickupPersonAddress?.country?.id));
              pickupAddress.addControl('zipCode', new FormControl(response.pickupPersonAddress.zipCode))
            }
            else {
              this.showPickupAddress = false;
            }
          }
          else {
            this.showPickupAddress = false;
            this.rfqDetails.removeControl('pickupPersonName');
            this.rfqDetails.removeControl('pickupPersonAddress');
          }
          this.rfq_details.deliveryIncoterms.patchValue(enquiry.deliveryIncoterms);
          if (enquiry.deliveryIncoterms == "DAP" || enquiry.deliveryIncoterms == "DDP" || enquiry.deliveryIncoterms == "DPU") {
            this.showTerminal = false;
            if (response.deliveryPersonAddress != null) {
              this.delivaryAddress = true;
              this.rfqDetails.addControl('deliveryPersonName', new FormControl(response.deliveryPersonName));
              this.rfqDetails.addControl('deliveryPersonAddress', new FormGroup({}))
              let deliveryAddress = this.rfqDetails.get('deliveryPersonAddress') as FormGroup;
              deliveryAddress.addControl('label', new FormControl('Delivery Address'))
              deliveryAddress.addControl('street1', new FormControl(response.deliveryPersonAddress.street1))
              deliveryAddress.addControl('street2', new FormControl(response.deliveryPersonAddress.street2))
              deliveryAddress.addControl('city', new FormControl(response.deliveryPersonAddress.city))
              deliveryAddress.addControl('state', new FormControl(response.deliveryPersonAddress.state))
              deliveryAddress.addControl('country', new FormGroup({}));
              let country = deliveryAddress.get('country') as FormGroup;
              country.addControl('id', new FormControl(response.deliveryPersonAddress?.country?.id));
              deliveryAddress.addControl('zipCode', new FormControl(response.deliveryPersonAddress.zipCode))
            }
            else {
              this.delivaryAddress = false;
            }
          }
          else if (enquiry.deliveryIncoterms == "DAT") {
            this.delivaryAddress = false;
            if (response.destinationTerminal != null) {
              this.showTerminal = true;
              this.rfqDetails.addControl('destinationTerminal', new FormControl(response.destinationTerminal));
            }
            else {
              this.showTerminal = false;
            }
            this.rfqDetails.removeControl('deliveryPersonName')
            this.rfqDetails.removeControl('deliveryPersonAddress')
          }
          else {
            this.rfqDetails.removeControl('destinationTerminal')
            this.rfqDetails.removeControl('deliveryPersonName')
            this.rfqDetails.removeControl('deliveryPersonAddress')
            this.delivaryAddress = false;
            this.showTerminal = false;
          }

          if (response.tradeType == 3) {
            this.isThirdCountrySelected = true
            console.log('this is trade type 3 selected ', response.tradeType);
            this.rfqDetails.addControl('exporterName', new FormControl(response.exporterName));
            this.rfqDetails.addControl('exporterMailId', new FormControl(response.exporterMailId));
            this.rfqDetails.addControl('exporterContact', new FormControl(response.exporterContact));
            this.rfqDetails.addControl('exporterAddress', new FormGroup({}))
            const exporterAddress = this.rfqDetails.get('exporterAddress') as FormGroup
            exporterAddress.addControl('label', new FormControl('Exporter Address'));
            exporterAddress.addControl('street1', new FormControl(response.exporterAddress.street1));
            exporterAddress.addControl('street2', new FormControl(response.exporterAddress.street2));
            exporterAddress.addControl('city', new FormControl(response.exporterAddress.city));
            exporterAddress.addControl('state', new FormControl(response.exporterAddress.state));
            // exporterAddress.addControl('country', new FormControl(response.exporterAddress.country));
            exporterAddress.addControl('country', new FormGroup({}));
            let country = exporterAddress.get('country') as FormGroup;
            country.addControl('id', new FormControl(response.exporterAddress?.country?.id));
            exporterAddress.addControl('zipCode', new FormControl(response.exporterAddress.zipCode));
          } 
          else {
            this.isThirdCountrySelected = false
            this.rfqDetails.removeControl('exporterName');
            this.rfqDetails.removeControl('exporterMailId');
            this.rfqDetails.removeControl('exporterContact');
            this.rfqDetails.removeControl('exporterAddress');
          }

          this.rfq_details.cargoReadinessDate.patchValue(enquiry.cargoReadinessDate);
          let last = this.datepipe.transform(enquiry.lastSubmissionDate, 'yyyy-MM-ddTHH:mm');
          this.rfq_details.lastSubmissionDate.patchValue(last);
          this.lastSubmissionDate = String(enquiry.lastSubmissionDate).split('T')[0];
          this.rfq_details.type.patchValue(enquiry.type);
          let _enq_enquiryDate = this.datepipe.transform(enquiry.enquiryDate, 'yyyy-MM-dd');
          this.rfq_details.enquiryDate.patchValue(_enq_enquiryDate);

          if (this.rfq_details.modeOfShipment.value == 2) {
            this.transitTimeMax = 60;
            this.air_mode_shipment = false;
            this.sea_mode_shipment = true;
            this.break_bulk = false;
            this.vendorQuoteRow().at(0).get('loadingTerminal').setValidators(Validators.required);
            this.vendorQuoteRow().at(0).get('sailingDays').setValidators(Validators.required);
            this.vendorQuoteRow().at(0).get('vesselName').setValidators(Validators.required);
            this.vendorQuoteRow().at(0).get('vesselSchedule').setValidators(Validators.required);
            this.vendorQuoteRow().at(0).updateValueAndValidity();
            // ToDo.
            this.rfq_details.containerLoadType.patchValue(enquiry.containerLoadType);
            this.rfq_details.hsCode.patchValue(enquiry.hsCode);
            this.rfq_details.detention.patchValue(enquiry.detention);
            if (this.rfq_details.containerLoadType.value == 1) {
              this.populateExWorks();
              this.fullContainerLoadBool = true;
              response.containers.forEach((container) => {
                this.pre_containers.push(this.prePopulateContainer(container));
                this.vendorRowContainer(0).push(this.initvendorQuoteContainers(container));
              });

              if (enquiry.breakBulks.length > 0) {
                this.break_bulk = true;
                this.totalBreakBulkVolume = 0.0;
                enquiry.breakBulks.forEach((breakBulk) => {
                  this.totalBreakBulkVolume = breakBulk.totalVolume;
                  this.totalBreakBulkWeight = this.convertweightUOMtoMT(breakBulk.uom, breakBulk.totalGrossWeight)
                  this.pre_breakBulks.push(this.prePopulateBreakBulk(breakBulk));
                })
              }
              else{
                this.additionalChargePerUnitBool = true;
              }
            }
            else if (this.rfq_details.containerLoadType.value == 2) {
              this.halfContianerLoadBool = true;
              this.totalVolumeWeightSEA = 0.0;
              response.shipmentPackages.forEach((shipmentPackage) => {
                this.totalVolumeWeightSEA = shipmentPackage.totalVolume + this.totalVolumeWeightSEA;
                this.pre_shipmentPackages.push(this.prePopulateLCL(shipmentPackage));
              });
              this.convertToMTSEA();
            }
            else if (this.rfq_details.containerLoadType.value == 3) {
              this.halfContianerLoadBool = true;
              this.fullContainerLoadBool = true;
              this.break_bulk = false;
              response.containers.forEach((container) => {
                this.pre_containers.push(this.prePopulateContainer(container));
                this.vendorRowContainer(0).push(this.initvendorQuoteContainers(container));
              });
              this.populateExWorks();
              this.totalVolumeWeightSEA = 0.0;
              response.shipmentPackages.forEach((shipmentPackage) => {
                this.totalVolumeWeightSEA = shipmentPackage.totalVolume + this.totalVolumeWeightSEA;
                this.pre_shipmentPackages.push(this.prePopulateLCL(shipmentPackage));
              });
              this.convertToMTSEA();
            }
            else if (this.rfq_details.containerLoadType.value == 4) {
              this.break_bulk = true;
              this.totalBreakBulkVolume = 0.0;
              enquiry.breakBulks.forEach((breakBulk) => {
                this.totalBreakBulkVolume = breakBulk.totalVolume;
                this.totalBreakBulkWeight = this.convertweightUOMtoMT(breakBulk.uom, breakBulk.totalGrossWeight)
                this.pre_breakBulks.push(this.prePopulateBreakBulk(breakBulk));
              });
            }
            this.collectSeaChargeTypes();
          }
          else if (this.rfq_details.modeOfShipment.value == 1) {
            this.transitTimeMax = 15;
            this.air_mode_shipment = true;
            this.sea_mode_shipment = false;
            this.break_bulk = false;
            this.totalVolumeWeightAIR = 0.0;
            for (let index = 0; index < response.shipmentPackages.length; index++) {
              const _package = response.shipmentPackages[index];
              this.totalVolumeWeightAIR = _package.totalVolume + this.totalVolumeWeightAIR;
              this.pre_shipmentPackages.push(this.prePopulatePackages(_package));
            }
            this.convertToMTAIR();
            this.collectAirChargeTypes();
          }
          else if (this.rfq_details.modeOfShipment.value == 3) {
            console.log("Other ModeOfShipment");
          }
          else {
            console.log("Other ModeOfShipment");
          }
          if (enquiry.terms.length > 0) {
            enquiry.terms.forEach((term) => {
              this.pre_termsConditions.push(this.prePopulateTerms(term, false));
            });
          }
          // Show Enquiry Artifacts.
          if (enquiry.artifacts.length > 0) {
            enquiry.artifacts.forEach((artifact) => {
              let data = {
                "content": artifact.content,
                "contentType": artifact.contentType,
                "name": artifact.name,
                "label": artifact?.label,
                "artifactId": artifact.id
              };
              if (artifact?.label == "Package Slip") {
                this.breakbulkfile.push(data);
              } else {
                this.downloadDocumentList.push(data);
              }
            });
          }
          console.log('this is enquiry data ', enquiry);
        }
      },
      (error) => {
        console.log(error);
      },
      () => { }
    );
  }

  goBackRFQ() {
    let _stepper = document.getElementById("item-1").classList.contains("active");
    let _sidebar = document.getElementById("list-item-1").classList.contains("active");
    let _content = document.getElementById("item-4");
    if (_stepper && _sidebar && _content) {
      document.getElementById("item-1").classList.remove("active");
      document.getElementById("list-item-1").classList.remove("active");
      document.getElementById("item-4").classList.remove("active");
    }
    document.getElementById("list-item-0").classList.add("active");
    document.getElementById("item-0").classList.add("active");
    document.getElementById("item-3").classList.add("active");
    this.breadcrumbBool = true;
    this.backfirstPage = false;
  }

  goBackDetails() {
    let _stepper = document.getElementById("item-2").classList.contains("active");
    let _sidebar = document.getElementById("list-item-2").classList.contains("active");
    let _content = document.getElementById("item-5");
    if (_stepper && _sidebar && _content) {
      document.getElementById("item-2").classList.remove("active");
      document.getElementById("list-item-2").classList.remove("active");
      document.getElementById("item-5").classList.remove("active");
    }
    document.getElementById("list-item-1").classList.add("active");
    document.getElementById("item-1").classList.add("active");
    document.getElementById("item-4").classList.add("active");
    this.backDetailPage = false;
    this.backfirstPage = true;
  }

  fetchQuoteByEnquiryId(id: number) {
    this.vendorService.fetchQuotesByEnquiry(id).subscribe(
      (response) => {
        let quotes: Array<any> = response;
        if (quotes.length == 1) {
          this.isBidUpdate = true
          // this.rfq_rates.number.disable()
          for (let index = 0; index < quotes.length; index++) {
            let quote = quotes[index];
            console.log(quote);
            this.updateQuote = true;
            let enquiry = quote.enquiry;
            this.chargableWeight = enquiry.chargableWeight;
            this.chargableWeightUOM = enquiry.chargableWeightUOM;
            this.rfq_details.description.patchValue(enquiry.description);
            this.rfq_details.number.patchValue(enquiry.number);
            this.rfq_details.projectName.patchValue(enquiry.projectName)
            this.rfq_details.projectCode.patchValue(enquiry.projectCode)
            this.rfq_details.modeOfShipment.patchValue(enquiry.modeOfShipment);

            if(this.rfq_details.modeOfShipment.value == 1){
              this.transitTimeMax = 15;
              this.air_mode_shipment = true;
              this.sea_mode_shipment = false;
              this.totalVolumeWeightAIR = 0.0;
              for (let index = 0; index < enquiry.shipmentPackages.length; index++) {
                const _package = enquiry.shipmentPackages[index];
                this.totalVolumeWeightAIR = _package.totalVolume + this.totalVolumeWeightAIR;
                this.pre_shipmentPackages.push(this.prePopulatePackages(_package));
              }
              this.convertToMTAIR();
            }
            else if (this.rfq_details.modeOfShipment.value == 2) {
              this.transitTimeMax = 60;
              this.rfq_details.containerLoadType.patchValue(enquiry.containerLoadType);
              this.rfq_details.hsCode.patchValue(enquiry.hsCode);
              this.rfq_details.detention.patchValue(enquiry.detention);
              this.air_mode_shipment = false;
              this.sea_mode_shipment = true;
              if (this.rfq_details.containerLoadType.value == 1) {
                this.fullContainerLoadBool = true;
                enquiry.containers.forEach((container) => {
                  this.pre_containers.push(this.prePopulateContainer(container));
                });
                if (enquiry.breakBulks.length > 0) {
                  this.break_bulk = true;
                  this.totalBreakBulkVolume = 0.0;
                  enquiry.breakBulks.forEach((breakBulk) => {
                    this.totalBreakBulkVolume = breakBulk.totalVolume;
                    this.totalBreakBulkWeight = this.convertweightUOMtoMT(breakBulk.uom, breakBulk.totalGrossWeight)
                    this.pre_breakBulks.push(this.prePopulateBreakBulk(breakBulk));
                  })
                }
              }
              else if (this.rfq_details.containerLoadType.value == 2) {
                this.halfContianerLoadBool = true;
                this.totalVolumeWeightSEA = 0.0;
                enquiry.shipmentPackages.forEach((shipmentPackage) => {
                  this.totalVolumeWeightSEA = shipmentPackage.totalVolume + this.totalVolumeWeightSEA;
                  this.pre_shipmentPackages.push(this.prePopulateLCL(shipmentPackage));
                });
                this.convertToMTSEA();
              }
              else if (this.rfq_details.containerLoadType.value == 3) {
                this.halfContianerLoadBool = true;
                this.fullContainerLoadBool = true;
                this.break_bulk = false;
                enquiry.containers.forEach((container) => {
                  this.pre_containers.push(this.prePopulateContainer(container));
                });
                this.totalVolumeWeightSEA = 0.0;
                enquiry.shipmentPackages.forEach((shipmentPackage) => {
                  this.totalVolumeWeightSEA = shipmentPackage.totalVolume + this.totalVolumeWeightSEA;
                  this.pre_shipmentPackages.push(this.prePopulateLCL(shipmentPackage));
                });
                this.convertToMTSEA();
              }
              // // Need changes
              else if (this.rfq_details.containerLoadType.value == 4) {
                this.break_bulk = true;
                this.totalBreakBulkVolume = 0.0;
                enquiry.breakBulks.forEach((breakBulk) => {
                  this.totalBreakBulkVolume = breakBulk.totalVolume;
                  this.totalBreakBulkWeight = this.convertweightUOMtoMT(breakBulk.uom, breakBulk.totalGrossWeight)
                  this.pre_breakBulks.push(this.prePopulateBreakBulk(breakBulk));
                });
              }
              else {
                this.air_mode_shipment = false;
                this.sea_mode_shipment = false;
              }
            }
            this.rfq_details.tradeType.patchValue(enquiry.tradeType);
            this.rfq_details.typeOfCargo.patchValue(enquiry.typeOfCargo);
            this.rfq_details.demurrage.patchValue(enquiry.demurrage);

            this.rfq_details.origin.patchValue(enquiry.origin);
            this.searchOriginValue = enquiry.originPortName
            // console.log('this is originPortName ', this.searchOriginValue);
            this.getSearchResultsOrigin(enquiry.modeOfShipment);
            
            this.rfq_details.destination.patchValue(enquiry.destination);
            this.searchDestinationValue = enquiry.destinationPortName
            // console.log('this is searchDestinationValue ', this.searchDestinationValue)
            this.getSearchResultDestination(enquiry.modeOfShipment)

            this.rfq_details.commodity.patchValue(enquiry.commodity);
            if (enquiry.storingType == 'n') {
              this.rfq_details.storingType.patchValue('Non-Stackable');
            } else if (enquiry.storingType == 's') {
              this.rfq_details.storingType.patchValue('Stackable');
            }
            this.rfq_details.natureOfMovement.patchValue(enquiry.natureOfMovement);
            this.rfq_details.currency.patchValue(enquiry.currency.currency);
            this.currency = enquiry.currency.currency;
            this.rfq_details.pickUpIncoterms.patchValue(enquiry.pickUpIncoterms);
            this.rfq_details.deliveryIncoterms.patchValue(enquiry.deliveryIncoterms);


            this.rfq_details.cargoReadinessDate.patchValue(enquiry.cargoReadinessDate);
            let last = this.datepipe.transform(enquiry.lastSubmissionDate, 'yyyy-MM-ddTHH:mm');
            this.rfq_details.lastSubmissionDate.patchValue(last);
            this.lastSubmissionDate = String(enquiry.lastSubmissionDate).split('T')[0];

            this.rfq_details.type.patchValue(enquiry.type);
            let _enq_enquiryDate = this.datepipe.transform(enquiry.enquiryDate, 'yyyy-MM-dd');
            this.rfq_details.enquiryDate.patchValue(_enq_enquiryDate);

            if (enquiry.tradeType == 3) {
              this.isThirdCountrySelected = true
              console.log('this is trade type 3 selected ', enquiry.tradeType);
              this.rfqDetails.addControl('exporterName', new FormControl(enquiry.exporterName));
              this.rfqDetails.addControl('exporterMailId', new FormControl(enquiry.exporterMailId));
              this.rfqDetails.addControl('exporterContact', new FormControl(enquiry.exporterContact));
              this.rfqDetails.addControl('exporterAddress', new FormGroup({}))
              const exporterAddress = this.rfqDetails.get('exporterAddress') as FormGroup
              exporterAddress.addControl('label', new FormControl('Exporter Address'));
              exporterAddress.addControl('street1', new FormControl(enquiry.exporterAddress.street1));
              exporterAddress.addControl('street2', new FormControl(enquiry.exporterAddress.street2));
              exporterAddress.addControl('city', new FormControl(enquiry.exporterAddress.city));
              exporterAddress.addControl('state', new FormControl(enquiry.exporterAddress.state));
              exporterAddress.addControl('country', new FormControl(enquiry.exporterAddress.country));
              exporterAddress.addControl('zipCode', new FormControl(enquiry.exporterAddress.zipCode));
            } else {
              this.isThirdCountrySelected = false
              this.rfqDetails.removeControl('exporterName');
              this.rfqDetails.removeControl('exporterMailId');
              this.rfqDetails.removeControl('exporterContact');
              this.rfqDetails.removeControl('exporterAddress');
            }
            if (enquiry.terms.length > 0) {
              enquiry.terms.forEach((term) => {
                this.pre_termsConditions.push(this.prePopulateTerms(term, true));
              });
            }

            if (enquiry.artifacts.length > 0) {
              enquiry.artifacts.forEach((artifact) => {
                let data = {
                  "content": artifact.content,
                  "contentType": artifact.contentType,
                  "name": artifact.name,
                  "label": artifact?.label,
                  "artifactId": artifact.id
                };
                if (artifact?.label == "Package Slip") {
                  this.breakbulkfile.push(data);
                } else {
                  this.downloadDocumentList.push(data);
                }
              });
            }

            // Quote Data Populate
            this.rfqRates.addControl('id', new FormControl(quote.id))
            this.rfqRates.get('number').patchValue(quote.number);
            this.rfqRates.get('noRegretOffer').patchValue(quote.noRegretOffer);
            // VendorQuoteRow
            let vendorQuoteRows: Array<any> = quote.vendorQuoteRows;
            for (let index = 0; index < vendorQuoteRows.length; index++) {
              const vendorQuoteRow = vendorQuoteRows[index];
              console.log(vendorQuoteRow);

              this.vendorQuoteRow().push(this.initParticular());
              this.vendorQuoteRow().at(index).get('lineName').patchValue(vendorQuoteRow.lineName);
              this.vendorQuoteRow().at(index).get('lineName').disable();
              this.vendorQuoteRow().at(index).get('frequency').patchValue(vendorQuoteRow.frequency);
              this.vendorQuoteRow().at(index).get('transitTime').patchValue(vendorQuoteRow.transitTime);
              this.vendorQuoteRow().at(index).get('transitPort').patchValue(vendorQuoteRow.transitPort);
              this.vendorQuoteRow().at(index).get('eta').patchValue(vendorQuoteRow.eta);
              this.vendorQuoteRow().at(index).get('etd').patchValue(vendorQuoteRow.etd);
              this.vendorQuoteRow().at(index).get('routing').patchValue(vendorQuoteRow.routing);
              this.vendorQuoteRow().at(index).get('quoteValidTill').patchValue(vendorQuoteRow.quoteValidTill);

              let vendorQuoteShipmentPackages: Array<any> = vendorQuoteRow.vendorQuoteShipmentPackages;
              let additionalCharges: Array<any> = vendorQuoteRow.additionalCharges;
              let vendorQuoteBreakBulks: Array<any> = vendorQuoteRow.vendorQuoteBreakBulks;
              let vendorQuoteContainers: Array<any> = vendorQuoteRow.vendorQuoteContainers;
              if (this.air_mode_shipment) {
                vendorQuoteShipmentPackages.filter(x => {
                  if (x.chargeType == null) {
                    if (x.type == "PER_SHIPMENT") {
                      this.vendorRowShipmentPackagePerShipment(index).push(this.populateShipmentPackagePerShipment(x));
                    }
                    else {
                      this.vendorRowShipmentPackagesPerUnit(index).push(this.populateShipmentPackagePerUnit(x));
                    }
                  }
                  else {
                    if (x.chargeType.type == "PER_SHIPMENT") {
                      this.vendorRowShipmentPackagePerShipment(index).push(this.populateShipmentPackagePerShipment(x));
                    }
                    else {
                      this.vendorRowShipmentPackagesPerUnit(index).push(this.populateShipmentPackagePerUnit(x));
                    }
                  }
                })
                additionalCharges.filter(x => {
                  if (x.chargeType == null) {
                    if (x.type == "PER_SHIPMENT") {
                      this.vendorRowAdditionalChargesPerShipment(index).push(this.populateAddtionalChargesPerShipment(x));
                    }
                    else {
                      this.vendorRowAdditionalChargesPerUnit(index).push(this.populateAdditionalChargesPerUnit(x))
                    }
                  }
                  else {
                    if (x.chargeType.type == "PER_SHIPMENT") {
                      this.vendorRowAdditionalChargesPerShipment(index).push(this.populateAddtionalChargesPerShipment(x));
                    }
                    else {
                      this.vendorRowAdditionalChargesPerUnit(index).push(this.populateAdditionalChargesPerUnit(x))
                    }
                  }
                })
                this.calculateTotalChargePerUnitAir(index);
                this.calculateTotalChargePerShipmentAIR(index);
                this.calculateAdditionalChargeAirPerUnit(index);
                this.calculateAdditionalChargeAirPerShipment(index);
                // console.log("DONE");
              }
              else if (this.sea_mode_shipment) {
                this.vendorQuoteRow().at(index).get('vesselName').patchValue(vendorQuoteRow.vesselName);
                this.vendorQuoteRow().at(index).get('vesselSchedule').patchValue(vendorQuoteRow.vesselSchedule);
                this.vendorQuoteRow().at(index).get('sailingDays').patchValue(vendorQuoteRow.sailingDays);
                this.vendorQuoteRow().at(index).get('loadingTerminal').patchValue(vendorQuoteRow.loadingTerminal);

                if (this.rfq_details.containerLoadType.value == 1) {
                  this.populateExWorks();
                  vendorQuoteContainers.filter(x => {
                    this.vendorRowContainer(index).push(this.populateContainer(x));
                  });
                  this.calculateContainerCharge(index);

                  if (this.break_bulk) {
                    vendorQuoteBreakBulks.filter(x => {
                      if (x.chargeType == null) {
                        if (x.type == "PER_SHIPMENT") {
                          this.vendorRowBreakBulkPerShipment(index).push(this.populateBreakBulkPerShipment(x));
                        }
                        else {
                          this.vendorRowBreakBulkPerUnit(index).push(this.populateBreakBulkPerUnit(x));
                        }
                      }
                      else {
                        if (x.chargeType.type == "PER_SHIPMENT") {
                          this.vendorRowBreakBulkPerShipment(index).push(this.populateBreakBulkPerShipment(x));
                        }
                        else {
                          this.vendorRowBreakBulkPerUnit(index).push(this.populateBreakBulkPerUnit(x));
                        }
                      }
                    })
                    this.calculateTotalChagePerUnitBreakBulk(index);
                    this.calculateTotalChargePerShipmentBreakBulk(index);
                  }

                  additionalCharges.filter(x => {
                    if (x.chargeType == null) {
                      if (x.type == "PER_SHIPMENT") {
                        this.vendorRowAdditionalChargesPerShipment(index).push(this.populateAddtionalChargesPerShipment(x));
                      }
                      else {
                        this.vendorRowAdditionalChargesPerUnit(index).push(this.populateAdditionalChargesPerUnit(x))
                      }
                    }
                    else {
                      if (x.chargeType.type == "PER_SHIPMENT") {
                        this.vendorRowAdditionalChargesPerShipment(index).push(this.populateAddtionalChargesPerShipment(x));
                      }
                      else {
                        this.vendorRowAdditionalChargesPerUnit(index).push(this.populateAdditionalChargesPerUnit(x))
                      }
                    }
                  })
                  this.calculateAdditionalChargePerUnitSea(index);
                  this.calculateAdditionalChargePerShipmentSea(index);
                }
                else if (this.rfq_details.containerLoadType.value == 2) {
                  vendorQuoteShipmentPackages.filter(x => {
                    if (x.chargeType == null) {
                      if (x.type == "PER_SHIPMENT") {
                        this.vendorRowShipmentPackagePerShipment(index).push(this.populateShipmentPackagePerShipment(x));
                      }
                      else {
                        this.vendorRowShipmentPackagesPerUnit(index).push(this.populateShipmentPackagePerUnit(x));
                      }
                    }
                    else {
                      if (x.chargeType.type == "PER_SHIPMENT") {
                        this.vendorRowShipmentPackagePerShipment(index).push(this.populateShipmentPackagePerShipment(x));
                      }
                      else {
                        this.vendorRowShipmentPackagesPerUnit(index).push(this.populateShipmentPackagePerUnit(x));
                      }
                    }
                  })
                  additionalCharges.filter(x => {
                    if (x.chargeType == null) {
                      if (x.type == "PER_SHIPMENT") {
                        this.vendorRowAdditionalChargesPerShipment(index).push(this.populateAddtionalChargesPerShipment(x));
                      }
                      else {
                        // this.vendorRowAdditionalChargesPerUnit(index).push(this.initAdditionalChargesPerUnit(x));
                        this.vendorRowAdditionalChargesPerUnit(index).push(this.populateAdditionalChargesPerUnit(x))
                      }
                    }
                    else {
                      if (x.chargeType.type == "PER_SHIPMENT") {
                        this.vendorRowAdditionalChargesPerShipment(index).push(this.populateAddtionalChargesPerShipment(x));
                        // this.vendorRowAdditionalChargesPerShipment(index).push(this.populateAddtionalChargesPerShipment(x, x.chargeType.id));
                      }
                      else {
                        this.vendorRowAdditionalChargesPerUnit(index).push(this.populateAdditionalChargesPerUnit(x))
                        // this.vendorRowAdditionalChargesPerUnit(index).push(this.initAdditionalChargesPerUnit(x, x.chargeType.id));
                      }
                    }
                  })
                  this.calculateTotalChargePerUnitSEA(index);
                  this.calculateTotalChargePerShipmentSEA(index);
                  this.calculateAdditionalChargePerUnitSea(index);
                  this.calculateAdditionalChargePerShipmentSea(index);
                }
                else if (this.rfq_details.containerLoadType.value == 3) {
                  this.populateExWorks();
                  vendorQuoteContainers.filter(x => {
                    this.vendorRowContainer(index).push(this.populateContainer(x));
                  });
                  vendorQuoteShipmentPackages.filter(x => {
                    if (x.chargeType == null) {
                      if (x.type == "PER_SHIPMENT") {
                        this.vendorRowShipmentPackagePerShipment(index).push(this.populateShipmentPackagePerShipment(x));
                      }
                      else {
                        this.vendorRowShipmentPackagesPerUnit(index).push(this.populateShipmentPackagePerUnit(x));
                      }
                    }
                    else {
                      if (x.chargeType.type == "PER_SHIPMENT") {
                        this.vendorRowShipmentPackagePerShipment(index).push(this.populateShipmentPackagePerShipment(x));
                      }
                      else {
                        this.vendorRowShipmentPackagesPerUnit(index).push(this.populateShipmentPackagePerUnit(x));
                      }
                    }
                  })
                  additionalCharges.filter(x => {
                    if (x.chargeType == null) {
                      if (x.type == "PER_SHIPMENT") {
                        this.vendorRowAdditionalChargesPerShipment(index).push(this.populateAddtionalChargesPerShipment(x));
                      }
                      else {
                        this.vendorRowAdditionalChargesPerUnit(index).push(this.populateAdditionalChargesPerUnit(x))
                      }
                    }
                    else {
                      if (x.chargeType.type == "PER_SHIPMENT") {
                        this.vendorRowAdditionalChargesPerShipment(index).push(this.populateAddtionalChargesPerShipment(x));
                      }
                      else {
                        this.vendorRowAdditionalChargesPerUnit(index).push(this.populateAdditionalChargesPerUnit(x))
                      }
                    }
                  })
                  this.calculateContainerCharge(index);
                  this.calculateTotalChargePerUnitSEA(index);
                  this.calculateTotalChargePerShipmentSEA(index);
                  this.calculateAdditionalChargePerUnitSea(index);
                  this.calculateAdditionalChargePerShipmentSea(index);
                }
                else {
                  vendorQuoteBreakBulks.filter(x => {
                    if (x.chargeType == null) {
                      if (x.type == "PER_SHIPMENT") {
                        this.vendorRowBreakBulkPerShipment(index).push(this.populateBreakBulkPerShipment(x));
                      }
                      else {
                        this.vendorRowBreakBulkPerUnit(index).push(this.populateBreakBulkPerUnit(x));
                      }
                    }
                    else {
                      if (x.chargeType.type == "PER_SHIPMENT") {
                        this.vendorRowBreakBulkPerShipment(index).push(this.populateBreakBulkPerShipment(x));
                      }
                      else {
                        this.vendorRowBreakBulkPerUnit(index).push(this.populateBreakBulkPerUnit(x));
                      }
                    }
                  })
                  this.calculateTotalChagePerUnitBreakBulk(index);
                  this.calculateTotalChargePerShipmentBreakBulk(index);
                  additionalCharges.filter(x => {
                    if (x.chargeType == null) {
                      if (x.type == "PER_SHIPMENT") {
                        this.vendorRowAdditionalChargesPerShipment(index).push(this.populateAddtionalChargesPerShipment(x));
                      }
                      else {
                        this.vendorRowAdditionalChargesPerUnit(index).push(this.populateAdditionalChargesPerUnit(x))
                      }
                    }
                    else {
                      if (x.chargeType.type == "PER_SHIPMENT") {
                        this.vendorRowAdditionalChargesPerShipment(index).push(this.populateAddtionalChargesPerShipment(x));
                      }
                      else {
                        this.vendorRowAdditionalChargesPerUnit(index).push(this.populateAdditionalChargesPerUnit(x))
                      }
                    }
                  })
                  this.calculateAdditionalChargePerUnitSea(index);
                  this.calculateAdditionalChargePerShipmentSea(index);
                }
              }
              else {
                console.log("Nothing here, byee!!!");
              }
            }
          }
          this.rfqDetails.disable();
        }
        else {
          this.fetchEnquiryById(id);
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  prePopulateContainer(item: any): FormGroup {
    return this.fb.group({
      id: [item.id],
      quantity: [item.quantity],
      size: [item.size],
      uom: [item.uom],
      weight: [item.weight],
      type: [item.type],
      length: [item.length],
      width: [item.width],
      height: [item.height]
    })
  }
  prePopulatePackages(item: any): FormGroup {
    return this.fb.group({
      id: [item.id],
      quantity: [item.quantity],
      length: [item.length],
      width: [item.width],
      height: [item.height],
      dimensionsUOM: [item.dimensionsUOM],
      grossWeight: [item.grossWeight],
      weightUOM: [item.weightUOM],
      totalVolume: [item.totalVolume]
    })
  }
  prePopulateBreakBulk(item: any): FormGroup {
    return this.fb.group({
      id: [item.id],
      totalnetWeight: [item.totalnetWeight],
      totalGrossWeight: [item.totalGrossWeight],
      totalVolume: [item.totalVolume],
      uom: [item.uom]
    })
  }
  prePopulateLCL(item: any): FormGroup {
    return this.fb.group({
      id: [item.id],
      quantity: [item.quantity],
      type: [item.type],
      length: [item.length],
      width: [item.width],
      height: [item.height],
      dimensionsUOM: [item.dimensionsUOM],
      grossWeight: [item.grossWeight],
      weightUOM: [item.weightUOM],
      totalVolume: [item.totalVolume]
    })
  }

  vendorSubmitQuote() {
    let rfq = this.rfqRates.value
    let metaData: Array<any> = this.vendorQuoteRow().getRawValue();
    let controls = this.findInvalidControls();
    console.log(controls);
    if(controls.length > 0){
      Swal.fire({
        titleText: "Warning",
        icon: "warning",
        timer: 5000,
        text: "Please fill the field " + controls[0],
      });
    }
    else if(this.rfqRates.invalid){
      Swal.fire({
        titleText: "Warning",
        icon: "warning",
        timer: 5000,
        text: "Please fill the mandatory field",
      });
    }
    else {
      let data = {
        enquiryDto: {
          id: this.enquiryId
        },
        id: rfq.id,
        number: rfq.number,
        quoteDate: rfq.quoteDate,
        noRegretOffer: rfq.noRegretOffer,
        vendorQuoteRows: []
      };
  
      if (this.air_mode_shipment) {
        for (let index = 0; index < metaData.length; index++) {
          const element = metaData[index];
          let additionalCharges: Array<any> = [];
          let vendorQuoteShipmentPackages: Array<any> = [];
          let additionalCharePerUnit: Array<any> = element.additionalChargesPerUnit
          let additionalChargesPerShipment: Array<any> = element.additionalChargesPerShipment
          let shipmentPackagePerUnit: Array<any> = element.vendorQuoteShipmentPackagesPerUnit
          let shipmentPackagesPerShipment: Array<any> = element.vendorQuoteShipmentPackagesPerShipment
  
          if (additionalCharePerUnit != undefined) {
            for (let index = 0; index < additionalCharePerUnit.length; index++) {
              const element = additionalCharePerUnit[index];
              if (element.chargeType != null) {
                delete element.chargeType.modeOfShipment
              }
              additionalCharges.push(element);
            }
          }
          if (additionalChargesPerShipment != undefined) {
            for (let index = 0; index < additionalChargesPerShipment.length; index++) {
              const element = additionalChargesPerShipment[index];
              if (element.chargeType != null) {
                delete element.chargeType.modeOfShipment
              }
              additionalCharges.push(element);
            }
          }
  
          if (shipmentPackagePerUnit != undefined) {
            for (let index = 0; index < shipmentPackagePerUnit.length; index++) {
              const element = shipmentPackagePerUnit[index];
              if (element.chargeType != null) {
                delete element.chargeType.modeOfShipment
              }
              vendorQuoteShipmentPackages.push(element)
            }
          }
          if (shipmentPackagesPerShipment != undefined) {
            for (let index = 0; index < shipmentPackagesPerShipment.length; index++) {
              const element = shipmentPackagesPerShipment[index];
              if (element.chargeType != null) {
                delete element.chargeType.modeOfShipment
              }
              vendorQuoteShipmentPackages.push(element)
            }
          }
  
          let _data = {
            additionalCharges: additionalCharges,
            additionalChargePerShipmentSubTotal: element.additionalChargePerShipmentSubTotal,
            additionalChargePerUnitSubTotal: element.additionalChargePerUnitSubTotal,
            additionalChargeTotal: element.additionalChargeTotal,
            vendorQuoteShipmentPackages: vendorQuoteShipmentPackages,
            packageFreightPerShipmentSubTotal: element.packageFreightPerShipmentSubTotal,
            packageFreightPerUnitSubTotal: element.packageFreightPerUnitSubTotal,
            packageFreightTotal: element.packageFreightTotal,
            amount: element.amount,
            eta: element.eta,
            etd: element.etd,
            frequency: element.frequency,
            lineName: element.lineName,
            quoteValidTill: element.quoteValidTill,
            routing: element.routing,
            transitPort: element.transitPort,
            transitTime: element.transitTime,
          };
          data.vendorQuoteRows.push(_data);
        }
      }
      else if (this.sea_mode_shipment) {
        if (this.rfq_details.containerLoadType.value == 1) {
          console.log("TRUE");
          for (let index = 0; index < metaData.length; index++) {
            let additionalCharges: Array<any> = [];
            let vendorQuoteContainers: Array<any> = [];
            let vendorQuoteBreakBulks: Array<any> = [];
            let vendorQuoteShipmentPackages: Array<any> = [];
  
            const element = metaData[index];
  
            let additionalCharePerUnit: Array<any> = element.additionalChargesPerUnit
            let additionalChargesPerShipment: Array<any> = element.additionalChargesPerShipment
            let containerPerVendor: Array<any> = element.vendorQuoteContainers;
            let breakBulkPerUnit: Array<any> = element.vendorQuoteBreakBulkPerUnit;
            let breakBulkPerShipment: Array<any> = element.vendorQuoteBreakBulkPerShipment
  
            if (this.break_bulk == true) {
              if (breakBulkPerUnit != undefined) {
                for (let index = 0; index < breakBulkPerUnit.length; index++) {
                  const element = breakBulkPerUnit[index];
                  if (element.chargeType != null) {
                    delete element.chargeType.modeOfShipment
                  }
                  vendorQuoteBreakBulks.push(element)
                }
              }
              if (breakBulkPerShipment != undefined) {
                for (let index = 0; index < breakBulkPerShipment.length; index++) {
                  const element = breakBulkPerShipment[index];
                  if (element.chargeType != null) {
                    delete element.chargeType.modeOfShipment
                  }
                  vendorQuoteBreakBulks.push(element);
                }
              }
            }
  
            // ToDo left.
            if (containerPerVendor != undefined) {
              for (let index = 0; index < containerPerVendor.length; index++) {
                const element = containerPerVendor[index];
                delete element.type
                delete element.size
                delete element.quantity
                vendorQuoteContainers.push(element);
              }
            }
  
            if (additionalCharePerUnit != undefined) {
              for (let index = 0; index < additionalCharePerUnit.length; index++) {
                const element = additionalCharePerUnit[index];
                if (element.chargeType != null) {
                  delete element.chargeType.modeOfShipment
                }
                additionalCharges.push(element);
              }
            }
            if (additionalChargesPerShipment != undefined) {
              for (let index = 0; index < additionalChargesPerShipment.length; index++) {
                const element = additionalChargesPerShipment[index];
                if (element.chargeType != null) {
                  delete element.chargeType.modeOfShipment
                }
                additionalCharges.push(element);
              }
            }
            let _data = {
              additionalCharges: additionalCharges,
              additionalChargePerShipmentSubTotal: element.additionalChargePerShipmentSubTotal,
              additionalChargePerUnitSubTotal: element.additionalChargePerUnitSubTotal,
              additionalChargeTotal: element.additionalChargeTotal,
              vendorQuoteShipmentPackages: vendorQuoteShipmentPackages,
              packageFreightPerShipmentSubTotal: element.packageFreightPerShipmentSubTotal,
              packageFreightPerUnitSubTotal: element.packageFreightPerUnitSubTotal,
              packageFreightTotal: element.packageFreightTotal,
              vendorQuoteContainers: vendorQuoteContainers,
              containerTotal: element.containerTotal,
              vendorQuoteBreakBulks: vendorQuoteBreakBulks,
              breakBulkPerUnitSubTotal: element.breakBulkPerUnitSubTotal,
              breakBulkPerShipmentSubTotal: element.breakBulkPerShipmentSubTotal,
              breakBulkTotal: element.breakBulkTotal,
              amount: element.amount,
              vesselName: element.vesselName,
              vesselSchedule: element.vesselSchedule,
              loadingTerminal: element.loadingTerminal,
              eta: element.eta,
              etd: element.etd,
              frequency: element.frequency,
              lineName: element.lineName,
              quoteValidTill: element.quoteValidTill,
              routing: element.routing,
              transitPort: element.transitPort,
              transitTime: element.transitTime,
              sailingDays: element.sailingDays
            };
            data.vendorQuoteRows.push(_data);
          }
        }
        else if (this.rfq_details.containerLoadType.value == 2) {
          for (let index = 0; index < metaData.length; index++) {
            let additionalCharges: Array<any> = [];
            let vendorQuoteShipmentPackages: Array<any> = []
            let vendorQuoteContainers: Array<any> = [];
            let vendorQuoteBreakBulks: Array<any> = [];
  
            const element = metaData[index];
  
            let shipmentPackagePerUnit: Array<any> = element.vendorQuoteShipmentPackagesPerUnit
            let shipmentPackagesPerShipment: Array<any> = element.vendorQuoteShipmentPackagesPerShipment
            let additionalCharePerUnit: Array<any> = element.additionalChargesPerUnit
            let additionalChargesPerShipment: Array<any> = element.additionalChargesPerShipment
  
            if (shipmentPackagePerUnit != undefined) {
              for (let index = 0; index < shipmentPackagePerUnit.length; index++) {
                const element = shipmentPackagePerUnit[index];
                if (element.chargeType != null) {
                  delete element.chargeType.modeOfShipment
                }
                vendorQuoteShipmentPackages.push(element);
              }
            }
            if (shipmentPackagesPerShipment != undefined) {
              for (let index = 0; index < shipmentPackagesPerShipment.length; index++) {
                const element = shipmentPackagesPerShipment[index];
                if (element.chargeType != null) {
                  delete element.chargeType.modeOfShipment
                }
                vendorQuoteShipmentPackages.push(element);
              }
            }
  
            if (additionalCharePerUnit != undefined) {
              for (let index = 0; index < additionalCharePerUnit.length; index++) {
                const element = additionalCharePerUnit[index];
                if (element.chargeType != null) {
                  delete element.chargeType.modeOfShipment
                }
                additionalCharges.push(element);
              }
            }
            if (additionalChargesPerShipment != undefined) {
              for (let index = 0; index < additionalChargesPerShipment.length; index++) {
                const element = additionalChargesPerShipment[index];
                if (element.chargeType != null) {
                  delete element.chargeType.modeOfShipment
                }
                additionalCharges.push(element);
              }
            }
            let _data = {
              additionalCharges: additionalCharges,
              additionalChargePerShipmentSubTotal: element.additionalChargePerShipmentSubTotal,
              additionalChargePerUnitSubTotal: element.additionalChargePerUnitSubTotal,
              additionalChargeTotal: element.additionalChargeTotal,
              vendorQuoteShipmentPackages: vendorQuoteShipmentPackages,
              packageFreightPerShipmentSubTotal: element.packageFreightPerShipmentSubTotal,
              packageFreightPerUnitSubTotal: element.packageFreightPerUnitSubTotal,
              packageFreightTotal: element.packageFreightTotal,
              vendorQuoteContainers: vendorQuoteContainers,
              vendorQuoteBreakBulks: vendorQuoteBreakBulks,
              amount: element.amount,
              vesselName: element.vesselName,
              vesselSchedule: element.vesselSchedule,
              loadingTerminal: element.loadingTerminal,
              eta: element.eta,
              etd: element.etd,
              frequency: element.frequency,
              lineName: element.lineName,
              quoteValidTill: element.quoteValidTill,
              routing: element.routing,
              transitPort: element.transitPort,
              transitTime: element.transitTime,
              sailingDays: element.sailingDays
            };
            data.vendorQuoteRows.push(_data);
          }
        }
        else if (this.rfq_details.containerLoadType.value == 3) {
          for (let index = 0; index < metaData.length; index++) {
            let additionalCharges: Array<any> = [];
            let vendorQuoteShipmentPackages: Array<any> = []
            let vendorQuoteContainers: Array<any> = [];
            let vendorQuoteBreakBulks: Array<any> = [];
  
            const element = metaData[index];
            let shipmentPackagePerUnit: Array<any> = element.vendorQuoteShipmentPackagesPerUnit
            let shipmentPackagesPerShipment: Array<any> = element.vendorQuoteShipmentPackagesPerShipment
            let containerPerVendor: Array<any> = element.vendorQuoteContainers;
            let additionalCharePerUnit: Array<any> = element.additionalChargesPerUnit
            let additionalChargesPerShipment: Array<any> = element.additionalChargesPerShipment
            if (shipmentPackagePerUnit != undefined) {
              for (let index = 0; index < shipmentPackagePerUnit.length; index++) {
                const element = shipmentPackagePerUnit[index];
                if (element.chargeType != null) {
                  delete element.chargeType.modeOfShipment
                }
                vendorQuoteShipmentPackages.push(element);
              }
            }
            if (shipmentPackagesPerShipment != undefined) {
              for (let index = 0; index < shipmentPackagesPerShipment.length; index++) {
                const element = shipmentPackagesPerShipment[index];
                if (element.chargeType != null) {
                  delete element.chargeType.modeOfShipment
                }
                vendorQuoteShipmentPackages.push(element);
              }
            }
            if (additionalCharePerUnit != undefined) {
              for (let index = 0; index < additionalCharePerUnit.length; index++) {
                const element = additionalCharePerUnit[index];
                if (element.chargeType != null) {
                  delete element.chargeType.modeOfShipment
                }
                additionalCharges.push(element);
              }
            }
            if (additionalChargesPerShipment != undefined) {
              for (let index = 0; index < additionalChargesPerShipment.length; index++) {
                const element = additionalChargesPerShipment[index];
                if (element.chargeType != null) {
                  delete element.chargeType.modeOfShipment
                }
                additionalCharges.push(element);
              }
            }
            if (containerPerVendor != undefined) {
              console.log("I WNT TO BE HERE");
              for (let index = 0; index < containerPerVendor.length; index++) {
                const element = containerPerVendor[index];
                delete element.type
                delete element.size
                delete element.quantity
                vendorQuoteContainers.push(element);
              }
            }
            let _data = {
              additionalCharges: additionalCharges,
              additionalChargePerShipmentSubTotal: element.additionalChargePerShipmentSubTotal,
              additionalChargePerUnitSubTotal: element.additionalChargePerUnitSubTotal,
              additionalChargeTotal: element.additionalChargeTotal,
              vendorQuoteShipmentPackages: vendorQuoteShipmentPackages,
              packageFreightPerShipmentSubTotal: element.packageFreightPerShipmentSubTotal,
              packageFreightPerUnitSubTotal: element.packageFreightPerUnitSubTotal,
              packageFreightTotal: element.packageFreightTotal,
              vendorQuoteContainers: vendorQuoteContainers,
              containerTotal: element.containerTotal,
              vendorQuoteBreakBulks: vendorQuoteBreakBulks,
              amount: element.amount,
              vesselName: element.vesselName,
              vesselSchedule: element.vesselSchedule,
              loadingTerminal: element.loadingTerminal,
              eta: element.eta,
              etd: element.etd,
              frequency: element.frequency,
              lineName: element.lineName,
              quoteValidTill: element.quoteValidTill,
              routing: element.routing,
              transitPort: element.transitPort,
              transitTime: element.transitTime,
              sailingDays: element.sailingDays
            };
            data.vendorQuoteRows.push(_data);
          }
        }
        else {
          for (let index = 0; index < metaData.length; index++) {
            let additionalCharges: Array<any> = [];
            let vendorQuoteBreakBulks: Array<any> = [];
            let vendorQuoteContainers: Array<any> = [];
            let vendorQuoteShipmentPackages: Array<any> = []
  
            const element = metaData[index];
            let breakBulkPerUnit: Array<any> = element.vendorQuoteBreakBulkPerUnit
            let breakBulkPerShipment: Array<any> = element.vendorQuoteBreakBulkPerShipment
            let additionalCharePerUnit: Array<any> = element.additionalChargesPerUnit
            let additionalChargesPerShipment: Array<any> = element.additionalChargesPerShipment
  
            if (breakBulkPerUnit != undefined) {
              for (let index = 0; index < breakBulkPerUnit.length; index++) {
                const element = breakBulkPerUnit[index];
                if (element.chargeType != null) {
                  delete element.chargeType.modeOfShipment
                }
                vendorQuoteBreakBulks.push(element)
              }
            }
            if (breakBulkPerShipment != undefined) {
              for (let index = 0; index < breakBulkPerShipment.length; index++) {
                const element = breakBulkPerShipment[index];
                if (element.chargeType != null) {
                  delete element.chargeType.modeOfShipment
                }
                vendorQuoteBreakBulks.push(element);
              }
            }
            if (additionalCharePerUnit != undefined) {
              for (let index = 0; index < additionalCharePerUnit.length; index++) {
                const element = additionalCharePerUnit[index];
                if (element.chargeType != null) {
                  delete element.chargeType.modeOfShipment
                }
                additionalCharges.push(element);
              }
            }
            if (additionalChargesPerShipment != undefined) {
              for (let index = 0; index < additionalChargesPerShipment.length; index++) {
                const element = additionalChargesPerShipment[index];
                if (element.chargeType != null) {
                  delete element.chargeType.modeOfShipment
                }
                additionalCharges.push(element);
              }
            }
            let _data = {
              additionalCharges: additionalCharges,
              additionalChargePerShipmentSubTotal: element.additionalChargePerShipmentSubTotal,
              additionalChargePerUnitSubTotal: element.additionalChargePerUnitSubTotal,
              additionalChargeTotal: element.additionalChargeTotal,
              vendorQuoteShipmentPackages: vendorQuoteShipmentPackages,
              vendorQuoteContainers: vendorQuoteContainers,
              vendorQuoteBreakBulks: vendorQuoteBreakBulks,
              breakBulkPerUnitSubTotal: element.breakBulkPerUnitSubTotal,
              breakBulkPerShipmentSubTotal: element.breakBulkPerShipmentSubTotal,
              breakBulkTotal: element.breakBulkTotal,
              amount: element.amount,
              vesselName: element.vesselName,
              vesselSchedule: element.vesselSchedule,
              loadingTerminal: element.loadingTerminal,
              eta: element.eta,
              etd: element.etd,
              frequency: element.frequency,
              lineName: element.lineName,
              quoteValidTill: element.quoteValidTill,
              routing: element.routing,
              transitPort: element.transitPort,
              transitTime: element.transitTime,
              sailingDays: element.sailingDays
            };
            data.vendorQuoteRows.push(_data);
          }
        }
      }
      else {
        console.log("Other");
      }
      this.vendorService.saveQuote(data).subscribe(
        (response) => {
          if (response.code == 201) {
            Swal.fire({
              titleText: "Successfully",
              icon: "success",
              text: response.message
            });
          }
          let type = this.rfqDetails.get('type').value;
          this.router.navigate(['/v1/vendor-rfq/listing/', type]);
        },
        (error) => {
          console.log(error);
          let _error = error.error;
          if(_error.errorCode == 412){
            Swal.fire({
              titleText: "Error",
              icon: "error",
              timer: 5000,
              text: _error.errorMessage
            });
          }
          else {
            Swal.fire({
              title: 'Invalid',
              text: error.error.errorMessage,
              timer: 5000,
              icon: 'error'
            });
          }
          let type = this.rfqDetails.get('type').value;
          this.router.navigate(['/v1/vendor-rfq/listing/', type]);
        }
      )
    }
  }

  updateQuoteVendor() {
    let rfq = this.rfqRates.value
    let metaData: Array<any> = this.vendorQuoteRow().getRawValue();

    // ToDo
    let controls = this.findInvalidControls();
    console.log(controls);
    if(controls.length > 0){
      Swal.fire({
        titleText: "Warning",
        icon: "warning",
        timer: 5000,
        text: "Please fill the field " + controls[0],
      });
    }
    else if(this.rfqRates.invalid){
      Swal.fire({
        titleText: "Warning",
        icon: "warning",
        timer: 5000,
        text: "Please fill the mandatory field",
      });
    }
    else{
      let data = {
        enquiryDto: {
          id: this.enquiryId
        },
        id: rfq.id,
        number: rfq.number,
        quoteDate: rfq.quoteDate,
        noRegretOffer: rfq.noRegretOffer,
        vendorQuoteRows: []
      };
      if (this.air_mode_shipment) {
      for (let index = 0; index < metaData.length; index++) {
        let additionalCharges: Array<any> = [];
        let vendorQuoteShipmentPackages: Array<any> = [];
        const element = metaData[index];
        let additionalCharePerUnit: Array<any> = element.additionalChargesPerUnit
        let additionalChargesPerShipment: Array<any> = element.additionalChargesPerShipment
        let shipmentPackagePerUnit: Array<any> = element.vendorQuoteShipmentPackagesPerUnit
        let shipmentPackagesPerShipment: Array<any> = element.vendorQuoteShipmentPackagesPerShipment

        if (additionalCharePerUnit != undefined) {
          for (let index = 0; index < additionalCharePerUnit.length; index++) {
            const element = additionalCharePerUnit[index];
            if (element.chargeType != null) {
              delete element.chargeType.modeOfShipment
            }
            additionalCharges.push(element);
          }
        }
        if (additionalChargesPerShipment != undefined) {
          for (let index = 0; index < additionalChargesPerShipment.length; index++) {
            const element = additionalChargesPerShipment[index];
            if (element.chargeType != null) {
              delete element.chargeType.modeOfShipment
            }
            additionalCharges.push(element);
          }
        }

        if (shipmentPackagePerUnit != undefined) {
          for (let index = 0; index < shipmentPackagePerUnit.length; index++) {
            const element = shipmentPackagePerUnit[index];
            if (element.chargeType != null) {
              delete element.chargeType.modeOfShipment
            }
            vendorQuoteShipmentPackages.push(element)
          }
        }
        if (shipmentPackagesPerShipment != undefined) {
          for (let index = 0; index < shipmentPackagesPerShipment.length; index++) {
            const element = shipmentPackagesPerShipment[index];
            if (element.chargeType != null) {
              delete element.chargeType.modeOfShipment
            }
            vendorQuoteShipmentPackages.push(element)
          }
        }

        let _data = {
          additionalCharges: additionalCharges,
          additionalChargePerShipmentSubTotal: element.additionalChargePerShipmentSubTotal,
          additionalChargePerUnitSubTotal: element.additionalChargePerUnitSubTotal,
          additionalChargeTotal: element.additionalChargeTotal,
          vendorQuoteShipmentPackages: vendorQuoteShipmentPackages,
          packageFreightPerShipmentSubTotal: element.packageFreightPerShipmentSubTotal,
          packageFreightPerUnitSubTotal: element.packageFreightPerUnitSubTotal,
          packageFreightTotal: element.packageFreightTotal,
          amount: element.amount,
          eta: element.eta,
          etd: element.etd,
          frequency: element.frequency,
          lineName: element.lineName,
          quoteValidTill: element.quoteValidTill,
          routing: element.routing,
          transitPort: element.transitPort,
          transitTime: element.transitTime,
        };
        data.vendorQuoteRows.push(_data);
      }
      }
      else if (this.sea_mode_shipment) {
        if (this.rfq_details.containerLoadType.value == 1) {
          for (let index = 0; index < metaData.length; index++) {
            let additionalCharges: Array<any> = [];
            let vendorQuoteContainers: Array<any> = [];
            let vendorQuoteBreakBulks: Array<any> = [];
            let vendorQuoteShipmentPackages: Array<any> = [];

            const element = metaData[index];

            let additionalCharePerUnit: Array<any> = element.additionalChargesPerUnit
            let additionalChargesPerShipment: Array<any> = element.additionalChargesPerShipment
            let containerPerVendor: Array<any> = element.vendorQuoteContainers;
            let breakBulkPerUnit: Array<any> = element.vendorQuoteBreakBulkPerUnit;
            let breakBulkPerShipment: Array<any> = element.vendorQuoteBreakBulkPerShipment

            if (this.break_bulk == true) {
              if (breakBulkPerUnit != undefined) {
                for (let index = 0; index < breakBulkPerUnit.length; index++) {
                  const element = breakBulkPerUnit[index];
                  if (element.chargeType != null) {
                    delete element.chargeType.modeOfShipment
                  }
                  vendorQuoteBreakBulks.push(element)
                }
              }
              if (breakBulkPerShipment != undefined) {
                for (let index = 0; index < breakBulkPerShipment.length; index++) {
                  const element = breakBulkPerShipment[index];
                  if (element.chargeType != null) {
                    delete element.chargeType.modeOfShipment
                  }
                  vendorQuoteBreakBulks.push(element);
                }
              }
            }

            // ToDo left.
            if (containerPerVendor != undefined) {
              for (let index = 0; index < containerPerVendor.length; index++) {
                const element = containerPerVendor[index];
                delete element.type
                delete element.size
                delete element.quantity
                delete element.id
                vendorQuoteContainers.push(element);
              }
            }

            if (additionalCharePerUnit != undefined) {
              for (let index = 0; index < additionalCharePerUnit.length; index++) {
                const element = additionalCharePerUnit[index];
                if (element.chargeType != null) {
                  delete element.chargeType.modeOfShipment
                }
                additionalCharges.push(element);
              }
            }
            if (additionalChargesPerShipment != undefined) {
              for (let index = 0; index < additionalChargesPerShipment.length; index++) {
                const element = additionalChargesPerShipment[index];
                if (element.chargeType != null) {
                  delete element.chargeType.modeOfShipment
                }
                additionalCharges.push(element);
              }
            }
            let _data = {
              additionalChargePerShipmentSubTotal: element.additionalChargePerShipmentSubTotal,
              additionalChargePerUnitSubTotal: element.additionalChargePerUnitSubTotal,
              additionalChargeTotal: element.additionalChargeTotal,
              additionalCharges: additionalCharges,
              vendorQuoteShipmentPackages: vendorQuoteShipmentPackages,
              packageFreightPerShipmentSubTotal: element.packageFreightPerShipmentSubTotal,
              packageFreightPerUnitSubTotal: element.packageFreightPerUnitSubTotal,
              packageFreightTotal: element.packageFreightTotal,
              vendorQuoteContainers: vendorQuoteContainers,
              containerTotal: element.containerTotal,
              vendorQuoteBreakBulks: vendorQuoteBreakBulks,
              breakBulkPerUnitSubTotal: element.breakBulkPerUnitSubTotal,
              breakBulkPerShipmentSubTotal: element.breakBulkPerShipmentSubTotal,
              breakBulkTotal: element.breakBulkTotal,
              amount: element.amount,
              vesselName: element.vesselName,
              vesselSchedule: element.vesselSchedule,
              loadingTerminal: element.loadingTerminal,
              eta: element.eta,
              etd: element.etd,
              frequency: element.frequency,
              lineName: element.lineName,
              quoteValidTill: element.quoteValidTill,
              routing: element.routing,
              transitPort: element.transitPort,
              transitTime: element.transitTime,
              sailingDays: element.sailingDays
            };
            data.vendorQuoteRows.push(_data);
          }
        }
        else if (this.rfq_details.containerLoadType.value == 2) {
          for (let index = 0; index < metaData.length; index++) {
            let additionalCharges: Array<any> = [];
            let vendorQuoteShipmentPackages: Array<any> = []
            let vendorQuoteContainers: Array<any> = [];
            let vendorQuoteBreakBulks: Array<any> = [];

            const element = metaData[index];

            let shipmentPackagePerUnit: Array<any> = element.vendorQuoteShipmentPackagesPerUnit
            let shipmentPackagesPerShipment: Array<any> = element.vendorQuoteShipmentPackagesPerShipment
            let additionalCharePerUnit: Array<any> = element.additionalChargesPerUnit
            let additionalChargesPerShipment: Array<any> = element.additionalChargesPerShipment

            if (shipmentPackagePerUnit != undefined) {
              for (let index = 0; index < shipmentPackagePerUnit.length; index++) {
                const element = shipmentPackagePerUnit[index];
                if (element.chargeType != null) {
                  delete element.chargeType.modeOfShipment
                }
                vendorQuoteShipmentPackages.push(element);
              }
            }
            if (shipmentPackagesPerShipment != undefined) {
              for (let index = 0; index < shipmentPackagesPerShipment.length; index++) {
                const element = shipmentPackagesPerShipment[index];
                if (element.chargeType != null) {
                  delete element.chargeType.modeOfShipment
                }
                vendorQuoteShipmentPackages.push(element);
              }
            }

            if (additionalCharePerUnit != undefined) {
              for (let index = 0; index < additionalCharePerUnit.length; index++) {
                const element = additionalCharePerUnit[index];
                if (element.chargeType != null) {
                  delete element.chargeType.modeOfShipment
                }
                additionalCharges.push(element);
              }
            }
            if (additionalChargesPerShipment != undefined) {
              for (let index = 0; index < additionalChargesPerShipment.length; index++) {
                const element = additionalChargesPerShipment[index];
                if (element.chargeType != null) {
                  delete element.chargeType.modeOfShipment
                }
                additionalCharges.push(element);
              }
            }
            let _data = {
              additionalChargePerShipmentSubTotal: element.additionalChargePerShipmentSubTotal,
              additionalChargePerUnitSubTotal: element.additionalChargePerUnitSubTotal,
              additionalChargeTotal: element.additionalChargeTotal,
              additionalCharges: additionalCharges,
              vendorQuoteShipmentPackages: vendorQuoteShipmentPackages,
              packageFreightPerShipmentSubTotal: element.packageFreightPerShipmentSubTotal,
              packageFreightPerUnitSubTotal: element.packageFreightPerUnitSubTotal,
              packageFreightTotal: element.packageFreightTotal,
              vendorQuoteContainers: vendorQuoteContainers,
              containerTotal: element.containerTotal,
              vendorQuoteBreakBulks: vendorQuoteBreakBulks,
              breakBulkPerUnitSubTotal: element.breakBulkPerUnitSubTotal,
              breakBulkPerShipmentSubTotal: element.breakBulkPerShipmentSubTotal,
              breakBulkTotal: element.breakBulkTotal,
              amount: element.amount,
              vesselName: element.vesselName,
              vesselSchedule: element.vesselSchedule,
              loadingTerminal: element.loadingTerminal,
              eta: element.eta,
              etd: element.etd,
              frequency: element.frequency,
              lineName: element.lineName,
              quoteValidTill: element.quoteValidTill,
              routing: element.routing,
              transitPort: element.transitPort,
              transitTime: element.transitTime,
              sailingDays: element.sailingDays
            };
            data.vendorQuoteRows.push(_data);
          }
        }
        else if (this.rfq_details.containerLoadType.value == 3) {
          for (let index = 0; index < metaData.length; index++) {
            let additionalCharges: Array<any> = [];
            let vendorQuoteShipmentPackages: Array<any> = []
            let vendorQuoteContainers: Array<any> = [];
            let vendorQuoteBreakBulks: Array<any> = [];

            const element = metaData[index];
            let shipmentPackagePerUnit: Array<any> = element.vendorQuoteShipmentPackagesPerUnit
            let shipmentPackagesPerShipment: Array<any> = element.vendorQuoteShipmentPackagesPerShipment
            let containerPerVendor: Array<any> = element.vendorQuoteContainers;
            let additionalCharePerUnit: Array<any> = element.additionalChargesPerUnit
            let additionalChargesPerShipment: Array<any> = element.additionalChargesPerShipment
            if (shipmentPackagePerUnit != undefined) {
              for (let index = 0; index < shipmentPackagePerUnit.length; index++) {
                const element = shipmentPackagePerUnit[index];
                if (element.chargeType != null) {
                  delete element.chargeType.modeOfShipment
                }
                vendorQuoteShipmentPackages.push(element);
              }
            }
            if (shipmentPackagesPerShipment != undefined) {
              for (let index = 0; index < shipmentPackagesPerShipment.length; index++) {
                const element = shipmentPackagesPerShipment[index];
                if (element.chargeType != null) {
                  delete element.chargeType.modeOfShipment
                }
                vendorQuoteShipmentPackages.push(element);
              }
            }
            if (additionalCharePerUnit != undefined) {
              for (let index = 0; index < additionalCharePerUnit.length; index++) {
                const element = additionalCharePerUnit[index];
                if (element.chargeType != null) {
                  delete element.chargeType.modeOfShipment
                }
                additionalCharges.push(element);
              }
            }
            if (additionalChargesPerShipment != undefined) {
              for (let index = 0; index < additionalChargesPerShipment.length; index++) {
                const element = additionalChargesPerShipment[index];
                if (element.chargeType != null) {
                  delete element.chargeType.modeOfShipment
                }
                additionalCharges.push(element);
              }
            }

            if (containerPerVendor != undefined) {
              for (let index = 0; index < containerPerVendor.length; index++) {
                const element = containerPerVendor[index];
                delete element.id
                delete element.type
                delete element.size
                delete element.quantity
                vendorQuoteContainers.push(element);
              }
            }

            let _data = {
              additionalCharges: additionalCharges,
              additionalChargePerShipmentSubTotal: element.additionalChargePerShipmentSubTotal,
              additionalChargePerUnitSubTotal: element.additionalChargePerUnitSubTotal,
              additionalChargeTotal: element.additionalChargeTotal,
              vendorQuoteShipmentPackages: vendorQuoteShipmentPackages,
              packageFreightPerShipmentSubTotal: element.packageFreightPerShipmentSubTotal,
              packageFreightPerUnitSubTotal: element.packageFreightPerUnitSubTotal,
              packageFreightTotal: element.packageFreightTotal,
              vendorQuoteContainers: vendorQuoteContainers,
              containerTotal: element.containerTotal,
              vendorQuoteBreakBulks: vendorQuoteBreakBulks,
              breakBulkPerUnitSubTotal: element.breakBulkPerUnitSubTotal,
              breakBulkPerShipmentSubTotal: element.breakBulkPerShipmentSubTotal,
              breakBulkTotal: element.breakBulkTotal,
              amount: element.amount,
              vesselName: element.vesselName,
              vesselSchedule: element.vesselSchedule,
              loadingTerminal: element.loadingTerminal,
              eta: element.eta,
              etd: element.etd,
              frequency: element.frequency,
              lineName: element.lineName,
              quoteValidTill: element.quoteValidTill,
              routing: element.routing,
              transitPort: element.transitPort,
              transitTime: element.transitTime,
              sailingDays: element.sailingDays
            };
            data.vendorQuoteRows.push(_data);
          }
        }
        else {
          for (let index = 0; index < metaData.length; index++) {
            let additionalCharges: Array<any> = [];
            let vendorQuoteBreakBulks: Array<any> = [];
            let vendorQuoteContainers: Array<any> = [];
            let vendorQuoteShipmentPackages: Array<any> = []

            const element = metaData[index];

            let breakBulkPerUnit: Array<any> = element.vendorQuoteBreakBulkPerUnit
            let breakBulkPerShipment: Array<any> = element.vendorQuoteBreakBulkPerShipment
            let additionalCharePerUnit: Array<any> = element.additionalChargesPerUnit
            let additionalChargesPerShipment: Array<any> = element.additionalChargesPerShipment

            if (breakBulkPerUnit != undefined) {
              for (let index = 0; index < breakBulkPerUnit.length; index++) {
                const element = breakBulkPerUnit[index];
                if (element.chargeType != null) {
                  delete element.chargeType.modeOfShipment
                }
                vendorQuoteBreakBulks.push(element)
              }
            }
            if (breakBulkPerShipment != undefined) {
              for (let index = 0; index < breakBulkPerShipment.length; index++) {
                const element = breakBulkPerShipment[index];
                if (element.chargeType != null) {
                  delete element.chargeType.modeOfShipment
                }
                vendorQuoteBreakBulks.push(element);
              }
            }
            if (additionalCharePerUnit != undefined) {
              for (let index = 0; index < additionalCharePerUnit.length; index++) {
                const element = additionalCharePerUnit[index];
                if (element.chargeType != null) {
                  delete element.chargeType.modeOfShipment
                }
                additionalCharges.push(element);
              }
            }
            if (additionalChargesPerShipment != undefined) {
              for (let index = 0; index < additionalChargesPerShipment.length; index++) {
                const element = additionalChargesPerShipment[index];
                if (element.chargeType != null) {
                  delete element.chargeType.modeOfShipment
                }
                additionalCharges.push(element);
              }
            }
            let _data = {
              additionalCharges: additionalCharges,
              additionalChargePerShipmentSubTotal: element.additionalChargePerShipmentSubTotal,
              additionalChargePerUnitSubTotal: element.additionalChargePerUnitSubTotal,
              additionalChargeTotal: element.additionalChargeTotal,
              vendorQuoteShipmentPackages: vendorQuoteShipmentPackages,
              packageFreightPerShipmentSubTotal: element.packageFreightPerShipmentSubTotal,
              packageFreightPerUnitSubTotal: element.packageFreightPerUnitSubTotal,
              packageFreightTotal: element.packageFreightTotal,
              vendorQuoteContainers: vendorQuoteContainers,
              containerTotal: element.containerTotal,
              vendorQuoteBreakBulks: vendorQuoteBreakBulks,
              breakBulkPerUnitSubTotal: element.breakBulkPerUnitSubTotal,
              breakBulkPerShipmentSubTotal: element.breakBulkPerShipmentSubTotal,
              breakBulkTotal: element.breakBulkTotal,
              amount: element.amount,
              vesselName: element.vesselName,
              vesselSchedule: element.vesselSchedule,
              loadingTerminal: element.loadingTerminal,
              eta: element.eta,
              etd: element.etd,
              frequency: element.frequency,
              lineName: element.lineName,
              quoteValidTill: element.quoteValidTill,
              routing: element.routing,
              transitPort: element.transitPort,
              transitTime: element.transitTime,
              sailingDays: element.sailingDays
            };
            data.vendorQuoteRows.push(_data);
          }
          console.log("Only Break Bulk");
        }
      }
      console.log(data);
      this.vendorService.updateQuote(this.rfq_rates.id.value, data).subscribe(
        (response) => {
          if (response.code == 201) {
            Swal.fire({
              titleText: "Success",
              icon: "success",
              timer: 5000,
              text: response.message
            })
            let type = this.rfqDetails.get('type').value;
            this.router.navigate(['/v1/vendor-rfq/listing/', type]);
          }
        },
        (error) => {
          let _error = error.error;
          if (_error.errorCode == 412) {
            Swal.fire({
              titleText: "Error",
              icon: "error",
              timer: 5000,
              text: _error.errorMessage
            });
            let type = this.rfqDetails.get('type').value;
            this.router.navigate(['/v1/vendor-rfq/listing/', type]);
          }
        },
        () => { }
      )
    }
  }

  validateTerms(){
    let bool = false
    for (let index = 0; index < this.pre_termsConditions.length; index++) {
      const term = this.pre_termsConditions.at(index);
      if(term.get('checked').value == false){
        Swal.fire({
          title: "Warning",
          timer: 5000,
          icon: "warning",
          text: "Accept Terms and condition"
        });
        bool = true;
        break;
      }
    }
    return bool
  }

  nextRatesDetails() {
    let bread_crum_1 = document.getElementById('item-0');
    let bread_crum_2 = document.getElementById('item-1');
    let current_slider = document.getElementById('list-item-0');
    let next_slider = document.getElementById('list-item-1');
    let current_page = document.getElementById('item-3');
    let next_page = document.getElementById('item-4');
    let validate = this.validateTerms();
    if (bread_crum_1.classList.contains('active') && current_slider.classList.contains('active') && current_page.classList.contains('active') && validate == false) {
      bread_crum_2.classList.add('active');
      next_slider.classList.add('active');
      next_page.classList.add('active');

      bread_crum_1.classList.remove('active');
      current_page.classList.remove('active');
      this.gotoTop();
    }
    else {
      console.log("Nothing");
    }
    this.breadcrumbBool = false;
    this.backfirstPage = true;
  }

  convertDate(date: any) {
    let _date = this.datepipe.transform(date, 'yyyy-MM-dd');
    return _date;
  }

  getTodayDate() {
    let date = this.rfq_details.cargoReadinessDate.value;
    return date
  }

  getMinDate(){
    let date = new Date().toISOString().split('T')[0];
    return date
  }

  getMasterTablesSessionStorage() {
    this.containerTypeList();
    this.getModeOfShipment();
    this.getTradeType();
    this.getTypeOfCargo();
    this.getNatureOfMovement();
    this.getPickUpIncoTerms();
    this.getDeliveryIncoTerms();
    this.getNatureOfGoods();
    this.getTypeOfPacking();
    this.getCountry();
  }

  containerTypeList() {
    this.masterTableService.getContainerTypes().subscribe(
      (response) => {
        this.container_type = response
      },
      (error) => { 
        console.log(error);
      },
    );
  }

  getModeOfShipment() {
    this.masterTableService.getModeOfShipment().subscribe(
      (response) => {
        this.mode_of_shipment = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getTradeType() {
    this.masterTableService.getTradeType().subscribe(
      (response) => {
        this.trade_type = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getTypeOfCargo(){
    this.masterTableService.getAllTypeOfCargo().subscribe(
      (response) => {
        this.type_cargo = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getNatureOfMovement() {
    this.masterTableService.getNatureOfMovement().subscribe(
      (response) => {
        this.nature_of_movement = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getPickUpIncoTerms() {
    this.masterTableService.getAllPickUpIncoTerms().subscribe(
      (response) => {
        this.pickup_terms = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getDeliveryIncoTerms() {
    this.masterTableService.getDeliveryIncoTerms().subscribe(
      (response) => {
        this.dropoff_terms = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getNatureOfGoods() {
    this.masterTableService.getNatureOfGoods().subscribe(
      (response) => {
        this.nature_goods = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getTypeOfPacking() {
    this.masterTableService.getAllTypeOfPacking().subscribe(
      (response) => {
        this.type_packing = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  prePopulateTerms(data, checked) {
    return this.fb.group({
      label: [data.label],
      content: [data.content],
      checked: [checked]
    })
  }

  downloadFileLocal(item) {
    if (item.artifactId != null && item.label != null) {
      this.dataURItoBlob(item);
    }
  }

  dataURItoBlob(data) {
    const linkSource = data.content;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = data.name;
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }
  getTodayDates() {
    return new Date().toISOString().split('T')[0]
  }

  findInvalidControls() {
    const invalid = [];
    const vendorQuoteRow = this.vendorQuoteRow();
    for (let index = 0; index < vendorQuoteRow.length; index++) {
      let element = vendorQuoteRow.at(index) as FormGroup;
      let shipmentPackagePerUnit = element.get('vendorQuoteShipmentPackagesPerUnit') as FormArray;
      let shipmentPackagesPerShipment = element.get('vendorQuoteShipmentPackagesPerShipment') as FormArray;
      let containers = element.get('vendorQuoteContainers') as FormArray;
      let breakBulkPerUnit = element.get('vendorQuoteBreakBulkPerUnit') as FormArray;
      let breakBulkPerShipment = element.get('vendorQuoteBreakBulkPerShipment') as FormArray;

      if(this.air_mode_shipment){
        for (let index = 0; index < shipmentPackagePerUnit.length; index++) {
          const _packagePerUnit = shipmentPackagePerUnit.at(index) as FormGroup;
          for (const name in _packagePerUnit.controls) {
            if (_packagePerUnit.controls[name].invalid) {
              invalid.push(name);
            }
          }
        }

        for (let index = 0; index < shipmentPackagesPerShipment.length; index++) {
          const _packagesPerShipment = shipmentPackagesPerShipment.at(index) as FormGroup;
          for (const name in _packagesPerShipment.controls) {
            if (_packagesPerShipment.controls[name].invalid) {
              invalid.push(name);
            }
          }
        }
      }
      else if(this.sea_mode_shipment){
        if(this.fullContainerLoadBool && this.break_bulk){
          for (let index = 0; index < containers.length; index++) {
            const _container = containers.at(index) as FormGroup;
            for (const name in _container.controls) {
              if (_container.controls[name].invalid) {
                invalid.push(name);
              }
            }
          }
          for (let index = 0; index < breakBulkPerUnit.length; index++) {
            const _breakBulkPerUnit = breakBulkPerUnit.at(index) as FormGroup;
            for (const name in _breakBulkPerUnit.controls) {
              if (_breakBulkPerUnit.controls[name].invalid) {
                invalid.push(name);
              }
            }
          }
          for (let index = 0; index < breakBulkPerShipment.length; index++) {
            const _breakBulkPerShipment = breakBulkPerShipment.at(index) as FormGroup;
            for (const name in _breakBulkPerShipment.controls) {
              if (_breakBulkPerShipment.controls[name].invalid) {
                invalid.push(name);
              }
            }
          }
        }
        else if(this.fullContainerLoadBool){
          for (let index = 0; index < containers.length; index++) {
            const _container = containers.at(index) as FormGroup;
            for (const name in _container.controls) {
              if (_container.controls[name].invalid) {
                invalid.push(name);
              }
            }
          }
        }
        else if(this.halfContianerLoadBool && this.fullContainerLoadBool){
          for (let index = 0; index < containers.length; index++) {
            const _container = containers.at(index) as FormGroup;
            for (const name in _container.controls) {
              if (_container.controls[name].invalid) {
                invalid.push(name);
              }
            }
          }
          for (let index = 0; index < shipmentPackagePerUnit.length; index++) {
            const _packagePerUnit = shipmentPackagePerUnit.at(index) as FormGroup;
            for (const name in _packagePerUnit.controls) {
              if (_packagePerUnit.controls[name].invalid) {
                invalid.push(name);
              }
            }
          }
          for (let index = 0; index < shipmentPackagesPerShipment.length; index++) {
            const _packagesPerShipment = shipmentPackagesPerShipment.at(index) as FormGroup;
            for (const name in _packagesPerShipment.controls) {
              if (_packagesPerShipment.controls[name].invalid) {
                invalid.push(name);
              }
            }
          }
        }
        else if(this.halfContianerLoadBool){
          for (let index = 0; index < shipmentPackagePerUnit.length; index++) {
            const _packagePerUnit = shipmentPackagePerUnit.at(index) as FormGroup;
            for (const name in _packagePerUnit.controls) {
              if (_packagePerUnit.controls[name].invalid) {
                invalid.push(name);
              }
            }
          }
          for (let index = 0; index < shipmentPackagesPerShipment.length; index++) {
            const _packagesPerShipment = shipmentPackagesPerShipment.at(index) as FormGroup;
            for (const name in _packagesPerShipment.controls) {
              if (_packagesPerShipment.controls[name].invalid) {
                invalid.push(name);
              }
            }
          }
        }
        else if(this.break_bulk){
          for (let index = 0; index < breakBulkPerUnit.length; index++) {
            const _breakBulkPerUnit = breakBulkPerUnit.at(index) as FormGroup;
            for (const name in _breakBulkPerUnit.controls) {
              if (_breakBulkPerUnit.controls[name].invalid) {
                invalid.push(name);
              }
            }
          }
          for (let index = 0; index < breakBulkPerShipment.length; index++) {
            const _breakBulkPerShipment = breakBulkPerShipment.at(index) as FormGroup;
            for (const name in _breakBulkPerShipment.controls) {
              if (_breakBulkPerShipment.controls[name].invalid) {
                invalid.push(name);
              }
            }
          }
        }
      }
      let additionalChargesPerUnit = element.get('additionalChargesPerUnit') as FormArray;
      let additionalChargesPerShipment = element.get('additionalChargesPerShipment') as FormArray;
      for (let index = 0; index < additionalChargesPerUnit.length; index++) {
        const _additionalChargesPerUnit = additionalChargesPerUnit.at(index) as FormGroup;
        for (const name in _additionalChargesPerUnit.controls) {
          if (_additionalChargesPerUnit.controls[name].invalid) {
            invalid.push(name);
          }
        }
      }
      for (let index = 0; index < additionalChargesPerShipment.length; index++) {
        const _additionalChargesPerShipment = additionalChargesPerShipment.at(index) as FormGroup;
        for (const name in _additionalChargesPerShipment.controls) {
          if (_additionalChargesPerShipment.controls[name].invalid) {
            invalid.push(name);
          }
        }
      }
      for (const name in element.controls) {
        if (element.controls[name].invalid) {
          invalid.push(name);
        }
      }
    }
    return invalid
  }

  compareETD(vendorInd: number) {
    let etd = this.vendorQuoteRow().at(vendorInd).get('etd').value;
    let eta = this.vendorQuoteRow().at(vendorInd).get('eta').value;
    if (etd == '' || eta == '') {
      this.invalidETDdate = false;
    }
    else {
      if (etd > eta) {
        this.invalidETDdate = true;
      }
      else {
        this.invalidETDdate = false;
      }
    }
  }

  downloadPackageslip(item) {
    if (item.artifactId != null && item.label != null) {
      this.dataURItoBlob(item);
    }
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  convertToMTAIR() {
    this.compareWeightUomAir();
    this.calculateWeightAir();
    this.compareWeightVolumeAir();
  }

  // Comman for air/sea. (shipmentPackages).
  convertweightUOMtoMT(weightUOM: string, weight: number) {
    let _weight: number = 0.0;
    if (weightUOM == "KG") {
      _weight = (weight / 1000.00);
    }
    else if (weightUOM == "LBS") {
      _weight = (weight / 2204.62);
    }
    else {
      _weight = weight;
    }
    return parseFloat(_weight.toFixed(3));
  }

  compareWeightVolumeAir() {
    let chargeableWeight: number;
    let metricTon: number = 0.0;
    for (let index = 0; index < this.pre_shipmentPackages.length; index++) {
      let _package = this.pre_shipmentPackages.at(index)
      if (this.finalAirWeightUom == "KG") {
        if (_package.get('weightUOM').value == "LBS") {
          let lbs = this.convertLBStoMT(_package.get('grossWeight').value)
          metricTon = lbs + metricTon
        }
        else {
          let kg = this.convertKGtoMT(_package.get('grossWeight').value);
          metricTon = kg + metricTon
        }
      }
      else {
        let lbs = this.convertLBStoMT(_package.get('grossWeight').value);
        metricTon = lbs + metricTon
      }
    }

    if (metricTon > this.totalVolumeWeightAIR) {
      this.finalAirWeightBool = true;
      chargeableWeight = metricTon
    }
    else {
      this.finalAirWeightBool = false;
      chargeableWeight = this.totalVolumeWeightAIR
    }
  }

  // Comman for air/sea(ShipmentPackages).
  convertdimensionsUOMtoMtr(dimensionsUOM, totalVolume) {
    let volume: number = 0.0;
    if (dimensionsUOM == "CM") {
      // 100.00
      volume = (totalVolume / 100.00);
    }
    else if (dimensionsUOM == "MM") {
      // 1000.00
      volume = (totalVolume / 1000.00);
    }
    else if (dimensionsUOM == "IN") {
      // 39.37
      volume = (totalVolume / 39.37);
    }
    else if (dimensionsUOM == "FT") {
      // 3.2808
      volume = (totalVolume / 3.2808);
    }
    else {
      // mtr
      volume = totalVolume
    }
    return parseFloat(volume.toFixed(4));
  }


  addChargeTypePerUnitAIR(vendorIndex: number) {
    let data = this.fb.group({
      charge: ['', Validators.required],
      chargeType: [null],
      description: ['', Validators.required],
      loadChargeType: ["PACKAGE_DETAILS"],
      type: ["PER_UNIT"]
    })
    this.vendorRowShipmentPackagesPerUnit(vendorIndex).push(data);
  }

  addChargeTypePerUnitSEA(vendorIndex: number) {
    let data = this.fb.group({
      charge: ['', Validators.required],
      chargeType: [null],
      description: ['', Validators.required],
      loadChargeType: ["PACKAGE_DETAILS"],
      type: ["PER_UNIT"]
    })
    this.vendorRowShipmentPackagesPerUnit(vendorIndex).push(data);
  }

  addChargeTypePerShipmentAIR(vendorIndex: number) {
    let data = this.fb.group({
      charge: ['', Validators.required],
      chargeType: [null],
      description: ['', Validators.required],
      loadChargeType: ["PACKAGE_DETAILS"],
      type: ["PER_SHIPMENT"]
    })
    this.vendorRowShipmentPackagePerShipment(vendorIndex).push(data);
  }

  addChargeTypePerShipmentSEA(vendorIndex: number) {
    let data = this.fb.group({
      charge: ['', Validators.required],
      chargeType: [null],
      description: ['', Validators.required],
      loadChargeType: ["PACKAGE_DETAILS"],
      type: ["PER_SHIPMENT"]
    })
    this.vendorRowShipmentPackagePerShipment(vendorIndex).push(data);
  }

  addChargeTypePerUnitBreakBulk(vendorIndex: number) {
    let data = this.fb.group({
      charge: ['', Validators.required],
      chargeType: [null],
      description: ['', Validators.required],
      loadChargeType: ["BREAK_BULK"],
      type: ["PER_UNIT"]
    })
    this.vendorRowBreakBulkPerUnit(vendorIndex).push(data);
  }
  addChargeTypePerShipmentBreakBulk(vendorIndex: number) {
    let data = this.fb.group({
      charge: ['', Validators.required],
      chargeType: [null],
      description: ['', Validators.required],
      loadChargeType: ["BREAK_BULK"],
      type: ["PER_SHIPMENT"]
    })
    this.vendorRowBreakBulkPerShipment(vendorIndex).push(data);
  }

  deleteChargeTypePerUnitAIR(vendorIndex: number, shipmentIndPerUnit: number) {
    this.vendorRowShipmentPackagesPerUnit(vendorIndex).removeAt(shipmentIndPerUnit);
    this.calculateTotalChargePerUnitAir(vendorIndex);
  }

  deleteChargeTypePerUnitSEA(vendorIndex: number, shipmentIndPerUnit: number) {
    this.vendorRowShipmentPackagesPerUnit(vendorIndex).removeAt(shipmentIndPerUnit);
    this.calculateTotalChargePerUnitSEA(vendorIndex);
  }

  deleteChargeTypePerShipmentSEA(vendorIndex: number, shipmentIndPerUnit: number) {
    this.vendorRowShipmentPackagePerShipment(vendorIndex).removeAt(shipmentIndPerUnit);
    this.calculateTotalChargePerShipmentSEA(vendorIndex);
  }

  deleteChargeTypePerUnitBreakBulk(vendorIndex: number, breakBulkIndex: number) {
    this.vendorRowBreakBulkPerUnit(vendorIndex).removeAt(breakBulkIndex);
    this.calculateTotalChagePerUnitBreakBulk(vendorIndex);
  }

  deleteChargeTypePerShipmentBreakBulk(vendorIndex: number, breakBulkIndex: number) {
    this.vendorRowBreakBulkPerShipment(vendorIndex).removeAt(breakBulkIndex);
    this.calculateTotalChargePerShipmentBreakBulk(vendorIndex);
  }

  deleteChargeTypePerShipmentAIR(vendorIndex: number, shipmentIndPerUnit: number) {
    this.vendorRowShipmentPackagePerShipment(vendorIndex).removeAt(shipmentIndPerUnit);
    this.calculateTotalChargePerShipmentAIR(vendorIndex);
  }

  calculateTotalChargePerUnitAir(vendorInd: number) {
    let packageFreightPerUnitSubTotal = this.vendorQuoteRow().at(vendorInd).get('packageFreightPerUnitSubTotal');
    let packageFreightPerShipmentSubTotal = this.vendorQuoteRow().at(vendorInd).get('packageFreightPerShipmentSubTotal');
    let totalCharge = this.vendorQuoteRow().at(vendorInd).get('packageFreightTotal');
    packageFreightPerUnitSubTotal.patchValue(0.0);
    let perUnit = this.vendorRowShipmentPackagesPerUnit(vendorInd);
    for (let index = 0; index < perUnit.length; index++) {
      let charge = perUnit.at(index).get('charge').value;
      if (charge != null) {
        let weight = Math.round((this.chargableWeight * charge) * 100) / 100;
        packageFreightPerUnitSubTotal.patchValue(weight + packageFreightPerUnitSubTotal.value);
      }
    }
    let total = Math.round((packageFreightPerUnitSubTotal.value + packageFreightPerShipmentSubTotal.value) * 100) / 100;
    totalCharge.patchValue(total);
    this.calculateGrandTotalAir(vendorInd);
  }

  calculateTotalChargePerShipmentAIR(vendorInd) {
    let packageFreightPerUnitSubTotal = this.vendorQuoteRow().at(vendorInd).get('packageFreightPerUnitSubTotal');
    let packageFreightPerShipmentSubTotal = this.vendorQuoteRow().at(vendorInd).get('packageFreightPerShipmentSubTotal');
    let totalCharge = this.vendorQuoteRow().at(vendorInd).get('packageFreightTotal');

    packageFreightPerShipmentSubTotal.patchValue(0.0);
    let perShipment = this.vendorRowShipmentPackagePerShipment(vendorInd);
    for (let index = 0; index < perShipment.length; index++) {
      let charge = perShipment.at(index).get('charge').value;
      if (charge != null) {
        let _charge = Math.round((charge + packageFreightPerShipmentSubTotal.value) * 100) / 100
        packageFreightPerShipmentSubTotal.patchValue(_charge);
      }
    }
    let total = Math.round((packageFreightPerUnitSubTotal.value + packageFreightPerShipmentSubTotal.value) * 100) / 100;
    totalCharge.patchValue(total);
    this.calculateGrandTotalAir(vendorInd);
  }

  calculateGrandTotalAir(vendorInd: number) {
    let amount = this.vendorQuoteRow().at(vendorInd).get('amount');
    let totalCharge = this.vendorQuoteRow().at(vendorInd).get('packageFreightTotal');
    let additionalChargeTotal = this.vendorQuoteRow().at(vendorInd).get('additionalChargeTotal');
    let total = Math.round((totalCharge.value + additionalChargeTotal.value) * 100) / 100;
    amount.patchValue(total);
  }

  calculateGrandTotalSea(vendorInd: number) {
    let amount = this.vendorQuoteRow().at(vendorInd).get('amount');
    let totalCharge = this.vendorQuoteRow().at(vendorInd).get('packageFreightTotal');
    let additionalChargeTotal = this.vendorQuoteRow().at(vendorInd).get('additionalChargeTotal');
    let total = Math.round((totalCharge.value + additionalChargeTotal.value) * 100) / 100
    amount.patchValue(total);
  }

  calculateGrandTotalBreakBulk(vendorInd: number) {
    let amount = this.vendorQuoteRow().at(vendorInd).get('amount');
    let totalCharge = this.vendorQuoteRow().at(vendorInd).get('breakBulkTotal');
    let additionalChargeTotal = this.vendorQuoteRow().at(vendorInd).get('additionalChargeTotal');
    let total = Math.round((totalCharge.value + additionalChargeTotal.value) * 100) / 100
    amount.patchValue(total);
  }

  calculateTotalChargePerUnitSEA(vendorInd: number) {
    let packageFreightPerUnitSubTotal = this.vendorQuoteRow().at(vendorInd).get('packageFreightPerUnitSubTotal');
    let packageFreightPerShipmentSubTotal = this.vendorQuoteRow().at(vendorInd).get('packageFreightPerShipmentSubTotal');
    let totalCharge = this.vendorQuoteRow().at(vendorInd).get('packageFreightTotal');
    let perUnit = this.vendorRowShipmentPackagesPerUnit(vendorInd);
    packageFreightPerUnitSubTotal.patchValue(0.0);
    for (let index = 0; index < perUnit.length; index++) {
      let charge = perUnit.at(index).get('charge').value;
      if (charge != null) {
        let weight = Math.round((this.chargableWeight * charge) * 100) / 100;
        packageFreightPerUnitSubTotal.patchValue(weight + packageFreightPerUnitSubTotal.value);
      }
    }
    totalCharge.patchValue(packageFreightPerUnitSubTotal.value + packageFreightPerShipmentSubTotal.value)
    this.calculateGrandTotalSea(vendorInd);
    this.calculateAdditionalChargePerUnitSea(vendorInd);
    this.calculateAdditionalChargePerShipmentSea(vendorInd);
  }

  calculateTotalChargePerShipmentSEA(vendorInd: number) {
    let packageFreightPerUnitSubTotal = this.vendorQuoteRow().at(vendorInd).get('packageFreightPerUnitSubTotal');
    let packageFreightPerShipmentSubTotal = this.vendorQuoteRow().at(vendorInd).get('packageFreightPerShipmentSubTotal');
    let totalCharge = this.vendorQuoteRow().at(vendorInd).get('packageFreightTotal');

    let perShipment = this.vendorRowShipmentPackagePerShipment(vendorInd);
    packageFreightPerShipmentSubTotal.patchValue(0.0);
    for (let index = 0; index < perShipment.length; index++) {
      let charge = perShipment.at(index).get('charge').value;
      if (charge != null) {
        let _charge = Math.round((charge + packageFreightPerShipmentSubTotal.value) * 100) / 100;
        packageFreightPerShipmentSubTotal.patchValue(_charge);
      }
    }
    let total = Math.round((packageFreightPerUnitSubTotal.value + packageFreightPerShipmentSubTotal.value) * 100) / 100;
    totalCharge.patchValue(total);
    this.calculateGrandTotalSea(vendorInd);
    this.calculateAdditionalChargePerUnitSea(vendorInd);
    this.calculateAdditionalChargePerShipmentSea(vendorInd);
  }

  calculateTotalChagePerUnitBreakBulk(vendorInd: number) {
    let breakBulkPerUnitSubTotal = this.vendorQuoteRow().at(vendorInd).get('breakBulkPerUnitSubTotal');
    let breakBulkPerShipmentSubTotal = this.vendorQuoteRow().at(vendorInd).get('breakBulkPerShipmentSubTotal');
    let breakBulkTotal = this.vendorQuoteRow().at(vendorInd).get('breakBulkTotal');

    let perUnit = this.vendorRowBreakBulkPerUnit(vendorInd);
    breakBulkPerUnitSubTotal.patchValue(0.0);
    for (let index = 0; index < perUnit.length; index++) {
      let charge = perUnit.at(index).get('charge').value;
      if (charge != null) {
        let weight = Math.round((this.chargableWeight * charge) * 100) / 100;
        breakBulkPerUnitSubTotal.patchValue(weight + breakBulkPerUnitSubTotal.value);
      }
    }
    let total = Math.round((breakBulkPerUnitSubTotal.value + breakBulkPerShipmentSubTotal.value) * 100) / 100;
    breakBulkTotal.patchValue(total);
    this.calculateGrandTotalBreakBulk(vendorInd);
    this.calculateAdditionalChargePerUnitSea(vendorInd);
    this.calculateAdditionalChargePerShipmentSea(vendorInd);
  }

  calculateTotalChargePerShipmentBreakBulk(vendorInd: number) {
    let breakBulkPerUnitSubTotal = this.vendorQuoteRow().at(vendorInd).get('breakBulkPerUnitSubTotal');
    let breakBulkPerShipmentSubTotal = this.vendorQuoteRow().at(vendorInd).get('breakBulkPerShipmentSubTotal');
    let breakBulkTotal = this.vendorQuoteRow().at(vendorInd).get('breakBulkTotal');

    let perShipment = this.vendorRowBreakBulkPerShipment(vendorInd);
    breakBulkPerShipmentSubTotal.patchValue(0.0);
    for (let index = 0; index < perShipment.length; index++) {
      let charge = perShipment.at(index).get('charge').value;
      if (charge != null) {
        let _charge = Math.round((charge + breakBulkPerShipmentSubTotal.value) * 100) / 100;
        breakBulkPerShipmentSubTotal.patchValue(_charge);
      }
    }
    let total = Math.round((breakBulkPerUnitSubTotal.value + breakBulkPerShipmentSubTotal.value) * 100) / 100;
    breakBulkTotal.patchValue(total);
    this.calculateGrandTotalBreakBulk(vendorInd);
    this.calculateAdditionalChargePerUnitSea(vendorInd);
    this.calculateAdditionalChargePerShipmentSea(vendorInd);
  }

  calculateContainerCharge(vendorInd: number) {
    let containerTotal = this.vendorQuoteRow().at(vendorInd).get('containerTotal');
    containerTotal.patchValue(0.0);
    for (let index = 0; index < this.vendorRowContainer(vendorInd).length; index++) {
      let ratePerContainer = this.vendorRowContainer(vendorInd).at(index).get('ratePerContainer').value;
      let quantity = this.vendorRowContainer(vendorInd).at(index).get('quantity').value;
      let totalFreight = this.vendorRowContainer(vendorInd).at(index).get('totalFreight').value;
      if(this.exWorksBool && this.deliveryChargeBool){
        let exWorks = this.vendorRowContainer(vendorInd).at(index).get('exWorks').value;
        let destinationCharges = this.vendorRowContainer(vendorInd).at(index).get('destinationCharges').value;
        totalFreight = Math.round((quantity * exWorks) + (quantity * destinationCharges) + (quantity * ratePerContainer) * 100/ 100);
      }
      else if(this.exWorksBool){
        let exWorks = this.vendorRowContainer(vendorInd).at(index).get('exWorks').value;
        totalFreight = Math.round((quantity * exWorks) + (quantity * ratePerContainer) * 100/ 100);
      }
      else if(this.deliveryChargeBool){
        let destinationCharges = this.vendorRowContainer(vendorInd).at(index).get('destinationCharges').value;
        totalFreight = Math.round((quantity * destinationCharges) + (quantity * ratePerContainer) * 100/ 100);
      }
      else{
        totalFreight = Math.round((quantity  * ratePerContainer) * 100) / 100;
      }
      this.vendorRowContainer(vendorInd).at(index).get('totalFreight').patchValue(totalFreight);
      containerTotal.patchValue(totalFreight + containerTotal.value);
    }
    // ToDo Caculate Total Charge of All types.
    this.calculateAdditionalChargePerUnitSea(vendorInd);
    this.calculateAdditionalChargePerShipmentSea(vendorInd);
  }

  convertToMTSEA() {
    this.compareWeightUOMSea();
    this.calculateWeightSea();
    this.compareWeightVolume();
  }

  compareWeightUOMSea() {
    let comparison = ""
    for (let index = 0; index < this.pre_shipmentPackages.length; index++) {
      const _package = this.pre_shipmentPackages.at(index);
      let weightUOM = _package.get('weightUOM').value;
      let latest = weightUOM;
      if (comparison.length > 0) {
        switch (latest) {
          case "LBS":
            if (comparison == "KG") {
              comparison = "KG"
            }
            else if (comparison == "MT") {
              comparison = "MT"
            }
            else {
              comparison = latest
            }
            break
          case "KG":
            if (comparison == "MT") {
              comparison = "MT"
            }
            else if (comparison == "LBS") {
              comparison = "KG"
            }
            else {
              comparison = latest
            }
            break
          case "MT":
            comparison = latest
            break
        }
      }
      else {
        comparison = latest
      }
    }
    this.finalSeaWeightUom = comparison;
  }

  calculateWeightSea() {
    this.totalGrossWeightSEA = 0.0
    for (let index = 0; index < this.pre_shipmentPackages.length; index++) {
      const _package = this.pre_shipmentPackages.at(index);
      let weightUOM = _package.get('weightUOM').value;
      let weight = _package.get('grossWeight').value;
      if (this.finalSeaWeightUom == "MT") {
        if (weightUOM == "KG") {
          let kg = this.convertKGtoMT(weight);
          this.totalGrossWeightSEA = kg + this.totalGrossWeightSEA;
        }
        else if (weightUOM == "LBS") {
          let lbs = this.convertLBStoMT(weight);
          this.totalGrossWeightSEA = lbs + this.totalGrossWeightSEA;
        }
        else {
          this.totalGrossWeightSEA = weight + this.totalGrossWeightSEA;
        }
      }
      else if (this.finalSeaWeightUom == "KG") {
        if (weightUOM == "LBS") {
          let lbs = this.convertLBStoKG(weight);
          this.totalGrossWeightSEA = lbs + this.totalGrossWeightSEA;
        }
        else {
          this.totalGrossWeightSEA = weight + this.totalGrossWeightSEA;
        }
      }
      else {
        this.totalGrossWeightSEA = weight + this.totalGrossWeightSEA;
      }
    }
  }

  compareWeightVolume() {
    let chargeableWeight: number = 0.0;
    let metricTon: number = 0.0;
    for (let index = 0; index < this.pre_shipmentPackages.length; index++) {
      let _package = this.pre_shipmentPackages.at(index)
      if (this.finalSeaWeightUom == "KG") {
        if (_package.get('weightUOM').value == "LBS") {
          let lbs = this.convertLBStoMT(_package.get('grossWeight').value)
          metricTon = lbs + metricTon
        }
        else {
          let kg = this.convertKGtoMT(_package.get('grossWeight').value);
          metricTon = kg + metricTon
        }
      }
      else if (this.finalSeaWeightUom == "MT") {
        if (_package.get('weightUOM').value == "LBS") {
          let lbs = this.convertLBStoMT(_package.get('grossWeight').value)
          metricTon = lbs + metricTon
        }
        else if (_package.get('weightUOM').value == "KG") {
          let lbs = this.convertKGtoMT(_package.get('grossWeight').value)
          metricTon = lbs + metricTon
        }
        else {
          metricTon = _package.get('grossWeight').value + metricTon
        }
      }
      else {
        let lbs = this.convertLBStoMT(_package.get('grossWeight').value);
        metricTon = lbs + metricTon
      }
    }
    if (metricTon > this.totalVolumeWeightSEA) {
      this.finalSeaWeightBool = true;
      chargeableWeight = metricTon
    }
    else {
      this.finalSeaWeightBool = false;
      chargeableWeight = this.totalVolumeWeightSEA
    }
  }

  convertKGtoLBS(weight) {
    let _weight = (weight * 2.205)
    return parseFloat(_weight.toFixed(4))
  }

  convertKGtoMT(weight) {
    let _weight = (weight / 1000)
    return parseFloat(_weight.toFixed(4))
  }

  convertLBStoKG(weight) {
    let _weight = (weight / 2.205)
    return parseFloat(_weight.toFixed(4))
  }

  convertLBStoMT(weight) {
    let _weight = (weight / 2205)
    return parseFloat(_weight.toFixed(4))
  }

  compareWeightUomAir() {
    let comparison = ""
    for (let index = 0; index < this.pre_shipmentPackages.length; index++) {
      const _package = this.pre_shipmentPackages.at(index);
      let weightUOM = _package.get('weightUOM').value;
      let latest = weightUOM;
      if (comparison.length > 0) {
        switch (latest) {
          case "LBS":
            if (comparison == "KG") {
              comparison = "KG"
            }
            else {
              comparison = latest
            }
            break
          case "KG":
            if (comparison == "KG") {
              comparison = "KG"
            }
            else {
              comparison = latest
            }
            break
        }
      }
      else {
        comparison = latest
      }
    }
    // ToDo if cbm greater than weight then apply that as unit for chargable weight.
    this.finalAirWeightUom = comparison;
  }

  calculateWeightAir() {
    this.totalGrossWeightAIR = 0.0;
    for (let index = 0; index < this.pre_shipmentPackages.length; index++) {
      const _package = this.pre_shipmentPackages.at(index);
      let weightUOM = _package.get('weightUOM').value;
      let weight = _package.get('grossWeight').value;
      if (this.finalAirWeightUom == "KG") {
        if (weightUOM == "LBS") {
          let _weight = this.convertLBStoKG(weight);
          this.totalGrossWeightAIR = _weight + this.totalGrossWeightAIR;
        }
        else {
          this.totalGrossWeightAIR = weight + this.totalGrossWeightAIR;
        }
      }
      else if (this.finalAirWeightUom == "LBS") {
        this.totalGrossWeightAIR = weight + this.totalGrossWeightAIR;
      }
    }
  }

  addVendorQuoteRow() {
    this.vendorQuoteRow().push(this.initParticular());
    let len = this.vendorQuoteRow().length;
    if (this.air_mode_shipment) {
      this.collectAirChargeTypes(len - 1);
    }
    else if (this.sea_mode_shipment) {
      if (this.fullContainerLoadBool && this.halfContianerLoadBool){
        this.collectSeaContainer(len - 1);
        this.collectSeaChargeTypes(len - 1);
      }
      else if(this.fullContainerLoadBool && this.break_bulk){
        this.collectSeaContainer(len - 1);
        this.collectSeaChargeTypes(len - 1);
      }
      else if (this.fullContainerLoadBool) {
        this.collectSeaContainer(len - 1);
        this.collectSeaChargeTypes(len - 1);
      }
      else if (this.halfContianerLoadBool) {
        this.collectSeaChargeTypes(len - 1);
      }
      else if (this.break_bulk) {
        this.collectSeaChargeTypes(len - 1);
      }
      else {
        console.log("NO CHARGES FOR SEA");
      }
    }
    else {
      console.log("No charge Type for vendorRow");
    }
  }

  collectAirChargeTypes(Ind?: number) {
    let airArray: Array<ChargeType> = [];
    this.chargeType.filter((charge) => {
      if (charge.modeOfShipment.id === this.rfq_details.modeOfShipment.value) {
        airArray.push(charge);
      }
    })
    if (airArray.length > 0) {
      for (let index = 0; index < airArray.length; index++) {
        const element = airArray[index];
        if (Ind) {
          if (element.loadChargeType == "PACKAGE_DETAILS") {
            if (element.type == "PER_UNIT") {
              this.vendorRowShipmentPackagesPerUnit(Ind).push(this.initShipmentPackagePerUnit(element));
            }
            else {
              this.vendorRowShipmentPackagePerShipment(Ind).push(this.initShipmentPackagePerShipment(element));
            }
          }
          else {
            element.loadChargeType = "ADDITIONAL_CHARGES"
            if (element.type == "PER_UNIT") {
              this.vendorRowAdditionalChargesPerUnit(Ind).push(this.initAdditionalChargesPerUnit(element));
            }
            else {
              this.vendorRowAdditionalChargesPerShipment(Ind).push(this.initAdditionalChargesPerShipment(element));
            }
          }
        }
        else {
          if (element.loadChargeType == "PACKAGE_DETAILS") {
            if (element.type == "PER_UNIT") {
              this.vendorRowShipmentPackagesPerUnit(0).push(this.initShipmentPackagePerUnit(element));
            }
            else {
              this.vendorRowShipmentPackagePerShipment(0).push(this.initShipmentPackagePerShipment(element));
            }
          }
          else {
            element.loadChargeType = "ADDITIONAL_CHARGES"
            if (element.type == "PER_UNIT") {
              this.vendorRowAdditionalChargesPerUnit(0).push(this.initAdditionalChargesPerUnit(element));
            }
            else {
              this.vendorRowAdditionalChargesPerShipment(0).push(this.initAdditionalChargesPerShipment(element));
            }
          }
        }
      }
    }
  }

  collectSeaChargeTypes(Ind?: number) {
    // PACKAGE_DETAILS, BREAK_BULK, ADDITIONAL_CHARGES
    let seaArray: Array<ChargeType> = [];
    this.chargeType.filter((charge) => {
      if (charge.modeOfShipment.id === this.rfq_details.modeOfShipment.value) {
        seaArray.push(charge);
      }
    })
    if (seaArray.length > 0) {
      for (let index = 0; index < seaArray.length; index++) {
        const element = seaArray[index];
        if (Ind) {
          if(this.halfContianerLoadBool){
            if (element.loadChargeType == "PACKAGE_DETAILS") {
              if (element.type == "PER_UNIT") {
                this.vendorRowShipmentPackagesPerUnit(Ind).push(this.initShipmentPackagePerUnit(element));
              }
              else {
                this.vendorRowShipmentPackagePerShipment(Ind).push(this.initShipmentPackagePerShipment(element));
              }
            }
            else if(element.loadChargeType == "ADDITIONAL_CHARGES"){
              if (element.type == "PER_UNIT") {
                this.vendorRowAdditionalChargesPerUnit(Ind).push(this.initAdditionalChargesPerUnit(element));
              }
              else {
                this.vendorRowAdditionalChargesPerShipment(Ind).push(this.initAdditionalChargesPerShipment(element));
              }
            }
          }
          else if(this.break_bulk){
            if (element.loadChargeType == "BREAK_BULK") {
              if (element.type == "PER_UNIT") {
                this.vendorRowBreakBulkPerUnit(Ind).push(this.initBreakBulkPerUnit(element));
              }
              else {
                this.vendorRowBreakBulkPerShipment(Ind).push(this.initBreakBulkPerShipment(element));
              }
            }
            else if(element.loadChargeType == "ADDITIONAL_CHARGES"){
              if (element.type == "PER_UNIT") {
                this.vendorRowAdditionalChargesPerUnit(Ind).push(this.initAdditionalChargesPerUnit(element));
              }
              else {
                this.vendorRowAdditionalChargesPerShipment(Ind).push(this.initAdditionalChargesPerShipment(element));
              }
            }
          }
        }
        else {
          if(this.halfContianerLoadBool){
            if (element.loadChargeType == "PACKAGE_DETAILS") {
              if (element.type == "PER_UNIT") {
                this.vendorRowShipmentPackagesPerUnit(0).push(this.initShipmentPackagePerUnit(element));
              }
              else {
                this.vendorRowShipmentPackagePerShipment(0).push(this.initShipmentPackagePerShipment(element));
              }
            } 
            else if (element.loadChargeType == "ADDITIONAL_CHARGES") {
              if (element.type == "PER_UNIT") {
                this.vendorRowAdditionalChargesPerUnit(0).push(this.initAdditionalChargesPerUnit(element));
              }
              else {
                this.vendorRowAdditionalChargesPerShipment(0).push(this.initAdditionalChargesPerShipment(element));
              }
            }
          }
          else if(this.break_bulk){
            if (element.loadChargeType == "BREAK_BULK") {
              if (element.type == "PER_UNIT") {
                this.vendorRowBreakBulkPerUnit(0).push(this.initBreakBulkPerUnit(element));
              }
              else {
                this.vendorRowBreakBulkPerShipment(0).push(this.initBreakBulkPerShipment(element));
              }
            }
            else if (element.loadChargeType == "ADDITIONAL_CHARGES") {
              if (element.type == "PER_UNIT") {
                this.vendorRowAdditionalChargesPerUnit(0).push(this.initAdditionalChargesPerUnit(element));
              }
              else {
                this.vendorRowAdditionalChargesPerShipment(0).push(this.initAdditionalChargesPerShipment(element));
              }
            }
          }
        }
      }
    }
  }

  collectSeaContainer(vendorIndex: number) {
    let containers: Array<any> = this.pre_containers.value;
    for (let index = 0; index < containers.length; index++) {
      const element = containers[index];
      let data = {
        id: element.id,
        quantity: element.quantity,
        size: element.size,
        type: element.type,
        exWorks: element.exWorks,
				destinationCharges: element.destinationCharges
      }
      this.vendorRowContainer(vendorIndex).push(this.initvendorQuoteContainers(data));
    }
  }

  addAdditionalChargesPerUnit(vendorInd: number) {
    let data = this.fb.group({
      charge: ['', Validators.required],
      chargeType: [null],
      description: ['', Validators.required],
      loadChargeType: ["ADDITIONAL_CHARGES"],
      type: ["PER_UNIT"]
    })
    this.vendorRowAdditionalChargesPerUnit(vendorInd).push(data);
  }

  addAdditionalChargesPerShipment(vendorInd) {
    let data = this.fb.group({
      charge: ['', Validators.required],
      chargeType: [null],
      description: ['', Validators.required],
      loadChargeType: ["ADDITIONAL_CHARGES"],
      type: ["PER_SHIPMENT"]
    })
    this.vendorRowAdditionalChargesPerShipment(vendorInd).push(data);
  }

  deleteAdditionalChargesPerUnit(vendorIndex, addChargePerUnitInd) {
    this.vendorRowAdditionalChargesPerUnit(vendorIndex).removeAt(addChargePerUnitInd);
    if (this.air_mode_shipment) {
      this.calculateAdditionalChargeAirPerUnit(vendorIndex);
    }
    else if (this.sea_mode_shipment) {
      this.calculateAdditionalChargePerUnitSea(vendorIndex);
    }
  }

  deleteAdditionalChargesPerShipment(vendorIndex, addChargePerShipmentInd) {
    this.vendorRowAdditionalChargesPerShipment(vendorIndex).removeAt(addChargePerShipmentInd);
    if (this.air_mode_shipment) {
      this.calculateAdditionalChargeAirPerShipment(vendorIndex);
    }
    else if (this.sea_mode_shipment) {
      this.calculateAdditionalChargePerShipmentSea(vendorIndex);
    }
  }

  calculateAdditionalChargeAirPerShipment(vendorInd) {
    let row = this.vendorRowAdditionalChargesPerShipment(vendorInd);
    let additionalChargePerUnitSubTotal = this.vendorQuoteRow().at(vendorInd).get('additionalChargePerUnitSubTotal');
    let additionalChargeTotal = this.vendorQuoteRow().at(vendorInd).get('additionalChargeTotal');
    let totalAdditionalChargePerShipment = this.vendorQuoteRow().at(vendorInd).get('additionalChargePerShipmentSubTotal');

    totalAdditionalChargePerShipment.patchValue(0.0);
    for (let index = 0; index < row.length; index++) {
      let charge = row.at(index).get('charge').value;
      if (charge != null) {
        let _charge = Math.round((charge + totalAdditionalChargePerShipment.value) * 100) / 100
        totalAdditionalChargePerShipment.patchValue(_charge);
      }
    }
    let total = Math.round((additionalChargePerUnitSubTotal.value + totalAdditionalChargePerShipment.value) * 100) / 100;
    additionalChargeTotal.patchValue(total);
    this.calculateGrandTotalAir(vendorInd);
  }

  calculateAdditionalChargeAirPerUnit(vendorInd) {
    let row = this.vendorRowAdditionalChargesPerUnit(vendorInd);
    let additionalChargePerUnitSubTotal = this.vendorQuoteRow().at(vendorInd).get('additionalChargePerUnitSubTotal');
    let additionalChargeTotal = this.vendorQuoteRow().at(vendorInd).get('additionalChargeTotal');
    let totalAdditionalChargePerShipment = this.vendorQuoteRow().at(vendorInd).get('additionalChargePerShipmentSubTotal');
    additionalChargePerUnitSubTotal.patchValue(0.0);
    for (let index = 0; index < row.length; index++) {
      let charge = row.at(index).get('charge').value;
      if (charge != null) {
        let weight = Math.round((this.chargableWeight * charge) * 100) / 100;
        additionalChargePerUnitSubTotal.patchValue(weight + additionalChargePerUnitSubTotal.value)
      }
    }
    let total = Math.round((additionalChargePerUnitSubTotal.value + totalAdditionalChargePerShipment.value) * 100) / 100;
    additionalChargeTotal.patchValue(total);
    this.calculateGrandTotalAir(vendorInd);
  }

  calculateAdditionalChargePerUnitSea(vendorInd) {
    let row = this.vendorRowAdditionalChargesPerUnit(vendorInd);
    let additionalChargePerUnitSubTotal = this.vendorQuoteRow().at(vendorInd).get('additionalChargePerUnitSubTotal');
    let totalAdditionalChargePerShipment = this.vendorQuoteRow().at(vendorInd).get('additionalChargePerShipmentSubTotal');
    let additionalChargeTotal = this.vendorQuoteRow().at(vendorInd).get('additionalChargeTotal');
    additionalChargePerUnitSubTotal.patchValue(0.0);
    for (let index = 0; index < row.length; index++) {
      let charge = row.at(index).get('charge').value;
      if (charge != null) {
        let weight = Math.round((this.chargableWeight * charge) * 100) / 100;
        additionalChargePerUnitSubTotal.patchValue(weight + additionalChargePerUnitSubTotal.value)
      }
    }
    let totalAdditional = Math.round((additionalChargePerUnitSubTotal.value + totalAdditionalChargePerShipment.value) * 100) / 100;
    additionalChargeTotal.patchValue(totalAdditional);
    if (this.fullContainerLoadBool && this.halfContianerLoadBool) {
      let containerTotal = this.vendorQuoteRow().at(vendorInd).get('containerTotal').value;
      let packageFreightTotal = this.vendorQuoteRow().at(vendorInd).get('packageFreightTotal').value;
      let total = Math.round((packageFreightTotal + containerTotal + additionalChargeTotal.value) * 100) / 100
      this.vendorQuoteRow().at(vendorInd).get('amount').patchValue(total);
    }
    else if (this.fullContainerLoadBool) {
      let containerTotal = this.vendorQuoteRow().at(vendorInd).get('containerTotal').value;
      if (this.break_bulk) {
        let breakBulkTotal = this.vendorQuoteRow().at(vendorInd).get('breakBulkTotal').value;
        let total = Math.round((containerTotal + breakBulkTotal + additionalChargeTotal.value) * 100) / 100
        this.vendorQuoteRow().at(vendorInd).get('amount').patchValue(total);
      }
      else {
        let total = Math.round((containerTotal + additionalChargeTotal.value) * 100) / 100;
        this.vendorQuoteRow().at(vendorInd).get('amount').patchValue(total);
      }
    }
    else if (this.halfContianerLoadBool) {
      let packageFreightTotal = this.vendorQuoteRow().at(vendorInd).get('packageFreightTotal').value;
      let total = Math.round((packageFreightTotal + additionalChargeTotal.value) * 100) / 100;
      this.vendorQuoteRow().at(vendorInd).get('amount').patchValue(total);
    }
    else {
      let breakBulkTotal = this.vendorQuoteRow().at(vendorInd).get('breakBulkTotal').value;
      let total = Math.round((breakBulkTotal + additionalChargeTotal.value) * 100) / 100;
      this.vendorQuoteRow().at(vendorInd).get('amount').patchValue(total);
    }
  }

  calculateAdditionalChargePerShipmentSea(vendorInd) {
    let row = this.vendorRowAdditionalChargesPerShipment(vendorInd);
    let additionalChargePerUnitSubTotal = this.vendorQuoteRow().at(vendorInd).get('additionalChargePerUnitSubTotal');
    let additionalChargeTotal = this.vendorQuoteRow().at(vendorInd).get('additionalChargeTotal');
    let totalAdditionalChargePerShipment = this.vendorQuoteRow().at(vendorInd).get('additionalChargePerShipmentSubTotal');

    totalAdditionalChargePerShipment.patchValue(0.0);
    for (let index = 0; index < row.length; index++) {
      let charge = row.at(index).get('charge').value;
      if (charge != null) {
        let _charge = Math.round((charge + totalAdditionalChargePerShipment.value) * 100) / 100;
        totalAdditionalChargePerShipment.patchValue(_charge);
      }
    }
    let totalAdditional = Math.round((additionalChargePerUnitSubTotal.value + totalAdditionalChargePerShipment.value) * 100) / 100;
    additionalChargeTotal.patchValue(totalAdditional);
    if (this.fullContainerLoadBool && this.halfContianerLoadBool) {
      let containerTotal = this.vendorQuoteRow().at(vendorInd).get('containerTotal').value;
      let packageFreightTotal = this.vendorQuoteRow().at(vendorInd).get('packageFreightTotal').value;
      let total = Math.round((packageFreightTotal + containerTotal + additionalChargeTotal.value) * 100) / 100
      this.vendorQuoteRow().at(vendorInd).get('amount').patchValue(total)
    }
    else if (this.fullContainerLoadBool) {
      let containerTotal = this.vendorQuoteRow().at(vendorInd).get('containerTotal').value;
      if (this.break_bulk) {
        let breakBulkTotal = this.vendorQuoteRow().at(vendorInd).get('breakBulkTotal').value;
        let total = Math.round((containerTotal + breakBulkTotal + additionalChargeTotal.value) * 100) / 100
        this.vendorQuoteRow().at(vendorInd).get('amount').patchValue(total)
      }
      else {
        let total = Math.round((containerTotal + additionalChargeTotal.value) * 100) / 100;
        this.vendorQuoteRow().at(vendorInd).get('amount').patchValue(total);
      }
    }
    else if (this.halfContianerLoadBool) {
      let packageFreightTotal = this.vendorQuoteRow().at(vendorInd).get('packageFreightTotal').value;
      let total = Math.round((packageFreightTotal + additionalChargeTotal.value) * 100) / 100;
      this.vendorQuoteRow().at(vendorInd).get('amount').patchValue(total);
    }
    else {
      let breakBulkTotal = this.vendorQuoteRow().at(vendorInd).get('breakBulkTotal').value;
      let total = Math.round((breakBulkTotal + additionalChargeTotal.value) * 100) / 100;
      this.vendorQuoteRow().at(vendorInd).get('amount').patchValue(total);
    }
  }

  //fuction to scroll to top on next button click
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  getChargeType() {
    this.masterTableService.getAllChargeType().subscribe(
      (response) => {
        this.chargeType = response;
      },
      (error) => {
        console.log(error);
      }
    )
  }

  getCountry() {
    this.masterTableService.getAllCountries().subscribe(
      (response) => {
        this.countries = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  fetchPortsByModeofShipmentCountry(countryId, modeOfShipmentId, type?:string){
    this.masterTableService.fetchPortsByModeOfShipmentAndCountryId(countryId, modeOfShipmentId).subscribe(
      (response: Array<any>) => {
        if (type == "loading") {
          this.loadingPorts = response;
        }
        else if (type == "destination") {
          this.destinationPorts = response
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getSearchResultsOrigin(modeOfShipmentFromEnqObj?: any){
    let size = 50
    if(this.searchOriginValue != ""){
      this.masterTableService.getSearchPort(modeOfShipmentFromEnqObj, this.searchOriginValue, size).subscribe((res)=>{
        this.loadingPorts = res
      },
      (error)=>{
        console.log(error);
      })
    } else {
      console.log('search value is empty ', this.searchOriginValue);
    }
  }

  getSearchResultDestination(modeOfShipmentFromEnqObj?: any){
    let size = 50
    if(this.searchDestinationValue != ""){
      this.masterTableService.getSearchPort(modeOfShipmentFromEnqObj, this.searchDestinationValue, size).subscribe((res)=>{
        this.destinationPorts = res
      },
      (error)=>{
        console.log(error);
      })
    } else {
      console.log('search value is empty ', this.searchDestinationValue);
    }
  }

  populateExWorks(){
    let natureOfMovement = this.rfq_details.natureOfMovement.value
    if(natureOfMovement == "P2P"){
      this.exWorksBool = false;
      this.deliveryChargeBool = false;
    }
    else if(natureOfMovement == "D2P"){
      this.exWorksBool = true;
      this.deliveryChargeBool = false;
    }
    else if(natureOfMovement == "D2D"){
      this.exWorksBool = true;
      this.deliveryChargeBool = true;
    }
    else if(natureOfMovement == "P2D"){
      this.exWorksBool = false;
      this.deliveryChargeBool = true;
    }
  }


}
