<h1 mat-dialog-title><strong>{{action}}</strong></h1>
<div mat-dialog-content>
    <form *ngIf="action != 'Delete'; else elseBlock" [formGroup]="dialogform">
        <div class="row">
            <mat-form-field>
                <input matInput placeholder="Enter Trade Agreement Name" [required]="isrequired" formControlName="tradeAgreementName">
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Description" [required]="isrequired" formControlName="description">
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Enter Link" [required]="isrequired" formControlName="tradeAgreementLink">
            </mat-form-field>
            <label for="">Country</label>
            <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="countryList"
                [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)"
                (onSelectAll)="onSelectAll($event)" (onDeSelect)="onDeSelect($event)"
                (onDeSelectAll)="onDeSelectAll()">
            </ng-multiselect-dropdown>

            <div class="row mt-2">
                <label class="form-label" for="">Artifacts</label>
                <ngx-file-drop dropZoneClassName="file-drop-4" dropZoneLabel="Drop files here"
                    (onFileDrop)="addFiles($event)" accept=".pdf, .docx, .txt">
                    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                        <div class="row text-center">
                            <div class="col-lg-12">
                                <button class="btn btn-success" type="button" (click)="openFileSelector()">Browse
                                    Files</button>
                            </div>
                        </div>
                    </ng-template>
                </ngx-file-drop>
                <table
                    class="table table-borderless table-striped table-head-dark table-head-condensed table-head-py-3 mt-2">
                    <thead>
                        <tr>
                            <th>Sr.No</th>
                            <th>File Name</th>
                            <th>Label</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of documentList; index as i">
                            <td>{{ i + 1 }}</td>
                            <td>{{ item.name }}</td>
                            <td>
                                <mat-form-field>
                                    <input type="text" matInput [required]="isrequired" [(ngModel)]="item.label" [ngModelOptions]="{standalone: true}">
                                </mat-form-field>
                            </td>
                            <td class="row">
                                <div class="col-md-3">
                                    <button *ngIf="item.id" (click)="downloadFileLocal(item)">
                                        <i class="fa fa-download" aria-hidden="true" title="Download"></i>
                                    </button>
                                </div>
                                <div class="col-md-3">
                                    <button title="Oversized" class="text-danger"
                                        *ngIf="(math.round(item.size) /1024) > 2048">
                                        <i class="fas fa-ban" aria-hidden="true"></i>
                                    </button>
                                </div>
                                <div class="col-md-3">
                                    <button title="Uploaded" class="text-primary" *ngIf="item.id">
                                        <i class="fa fa-check" aria-hidden="true"></i>
                                    </button>
                                </div>
                                <div class="col-md-3">
                                    <button title="Delete" *ngIf="item.id; else localDelete"
                                    (click)="deleteServerFile(item.id, i)">
                                        <i class="fa fa-trash color-blue"></i>
                                    </button>
                                    <button title="Delete" *ngIf="!item.id" (click)="deleteDocument(i)">
                                        <i class="fa fa-trash color-blue"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </form>
    <ng-template #elseBlock>
        Sure to delete <b>{{ local_data.tradeAgreementName }}</b>?
    </ng-template>
</div>

<div mat-dialog-actions>
    <button style="margin-right: 20px;" class="btn-primary" mat-button (click)="doAction()">{{action}}</button>
    <button class="btn-danger" mat-button (click)="closeDialog()" mat-flat-button color="warn">Cancel</button>
</div>