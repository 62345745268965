<!--header start-->
<header class="box-absolute d-flex align-items-center topline text-white">
    <app-sidebar></app-sidebar>
    <div class="page-title-center mt-3">
        <div class="text-center">
            <h1>Reports</h1>
        </div>
    </div>
    <div class="breadcrumb-div">
        <ul class="breadcrumb p-0 m-0">
            <li class="breadcrumb-item 3" id="0"><a href="dashboard.html">Dashboard</a></li>
            <li class="breadcrumb-item 3" id="1"><a href="report-listing.component.html">Reports</a></li>
        </ul>
    </div>
</header>

<!--header end-->
<div (click)="hideSideBar()">
<section class="banner text-center text-white d-flex" id="bnner">
    <div class="container position-relative">
        <div class="row justify-content-center">
            <div class="col-lg-12">
            </div>
        </div>
    </div>
</section>

<!--request start-->
<section class="request-demo-wrapper pb-10">
    <div class="container shadow bg-white">
        <div class="row">
            <div class="col-lg-12">
                <div class="box">
                    <form>
                        <mat-form-field class="form-control" appearance="standard">
                            <mat-label>Search</mat-label>
                            <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
                        </mat-form-field>

                        <div class="mat-elevation-z8 table-responsive">
                            <table class="table table-borderless table-head-dark" mat-table [dataSource]="dataSource"
                                matSort>

                                <!-- Id Column -->
                                <ng-container matColumnDef="id">
                                    <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                                    <td mat-cell *matCellDef="let row; index as i"> {{ i + 1 }} </td>
                                </ng-container>


                                <!-- name Column -->
                                <ng-container matColumnDef="name">
                                    <th class="text-white" mat-header-cell *matHeaderCellDef mat-sort-header> NAME
                                    </th>
                                    <td mat-cell *matCellDef="let row"> 
                                        <a style="text-decoration: none;" [routerLink]="['/v1/report/',row.name,row.inputParam,row.outputFormat]">{{row.name}}</a>
                                    </td>
                                </ng-container>

                                <tr class="bg-dark" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                                <!-- Row shown when there is no matching data. -->
                                <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell text-center text-danger" colspan="8">
                                        <div *ngIf="serchFilter">
                                            No data matching the filter "{{ input.value }}"
                                        </div>
                                        <div *ngIf="nodata">
                                            <h5>Data is Not Available</h5>
                                        </div>
                                    </td>
                                </tr>
                            </table>

                            <!-- <mat-paginator [pageSizeOptions]="[25, 50, 100]" aria-label="Select page of users">
                            </mat-paginator> -->
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
</div>