import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { MasterTableService } from '../helpers/master-table.service';
import { OnboardService } from '../helpers/onboard.service';
import { SocketService } from '../helpers/socket.service';

@Component({
  selector: 'app-vendor-onboarding',
  templateUrl: './vendor-onboarding.component.html',
  styleUrls: ['./vendor-onboarding.component.css']
})
export class VendorOnboardingComponent implements OnInit {

  public vendorOnBoardForm!: FormGroup;
  signatureImg: string | undefined;
  public next: boolean = true
  public countryCodes: Array<any> = [];

  signaturePadOptions: Object = {
    'minWidth': 5,
    'canvasWidth': 700,
    'canvasHeight': 100
  };
  token: string | null | undefined;
  vendor_status: boolean = false
  vendor_id: any;
  public addressTypeBoolean: boolean = false;
  public checkSameStreet: Boolean = false;

  constructor(private form: FormBuilder, private vendorService: OnboardService, private route: ActivatedRoute,
    private router: Router, private socketService: SocketService, private masterTableService: MasterTableService) { }
  ngOnInit(): void {
    this.vendorOnBoardForm = this.form.group({
      name: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
      website: [''],
      pocContactNumber: ['', Validators.compose([Validators.required, Validators.minLength(9), Validators.maxLength(16)])],
      pocEmailId: ['', Validators.compose([Validators.required, Validators.email])],
      pocFirstName: ['', Validators.required],
      pocLastName: ['', Validators.required],
      pocDesignation: [''],
      escalations: this.form.array([]),
      address: this.form.array([])
    });    

    this.token = this.route.snapshot.queryParamMap.get('t')
    if (this.token != null) {
      this.verifyVendorToken();
    }
    else {
      this.addNewAddress();
      this.getCountries();
    }

    this.gotoTop();
    // this.socketService.connectTOServerPopUpFuction();
  }

  get company() { return this.vendorOnBoardForm.controls; }

  get address() {
    return this.vendorOnBoardForm.get('address') as FormArray;
  }

  get getEscalations() {
    return this.vendorOnBoardForm.get('escalations') as FormArray;
  }

  initEscaltion(): FormGroup{
    return this.form.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      emailId: ['', Validators.compose([Validators.required, Validators.email])],
      contactNumber: ['', Validators.compose([Validators.required, Validators.minLength(9), Validators.maxLength(16)])],
      designation: ['']
    });
  }

  prePopulateEscalation(data: any):FormGroup{
    return this.form.group({
      firstName: [data.firstName, Validators.required],
      lastName: [data.lastName, Validators.required],
      emailId: [data.emailId, Validators.compose([Validators.required, Validators.email])],
      contactNumber: [data.contactNumber, Validators.compose([Validators.required, Validators.minLength(9), Validators.maxLength(16)])],
      designation: [data.designation]
    });
  }

  get addressType() { return this._address.controls }

  checkSameAddressType() {
    console.log(this.addressType)
    this.addressTypeBoolean = false;
    for (let i = 0; i < this.addressType.length; i++) {
      const value1 = this.addressType[i].value.label;
      for (let j = 0; j < this.addressType.length; j++) {
        if (i == j) {
          continue;
        }
        const value2 = this.addressType[j].value.label;
        if (value1 == value2) {
          this.addressTypeBoolean = true;
          break;
        }
      }
      if (this.addressTypeBoolean) {
        break;
      }
    }
  }

  addNewAddress(){
    let data = this.form.group({
      label: [''],
      street1: [''],
      street2: [''],
      city: [''],
      state: [''],
      country: this.form.group({
        id: ['']
      }),
      zipCode: [''],
    })
    this.address.push(data);
  }

  prePopulateAddress(data: any):FormGroup {
    return this.form.group({
      label: [data.label],
      street1: [data.street1],
      street2: [data.street2],
      city: [data.city],
      state: [data.state],
      country: this.form.group({
        id: [data.country.id]
      }),
      zipCode: [data.zipCode],
    });
  }

  removeNewAddress(i: number) {
    this.address.removeAt(i);
    this.checkSameAddressType();
    this.onStreetValueCheck();
  }

  addNewEscalations() {
    let i: number;
    let escalationBool: Boolean = false;
    for (let index = 0; index < this.getEscalations.length; index++) {
      const element = this.getEscalations.at(index);
      if (element.invalid) {
        i = index
        escalationBool = true
        break;
      }
    }
    if (escalationBool == true) {
      Swal.fire({
        text: "Fill mandatory fields at Escalation Point",
        timer: 5000,
        icon: "warning",
        titleText: "Invalid"
      });
      this.getEscalations.at(i).get('firstName').markAsDirty()
      this.getEscalations.at(i).get('lastName').markAsDirty()
      this.getEscalations.at(i).get('emailId').markAsDirty()
      this.getEscalations.at(i).get('contactNumber').markAsDirty()
    }
    else {
      this.getEscalations.push(this.initEscaltion());
      this.vendorOnBoardForm.updateValueAndValidity();
    }
  }

  removeNewEscalations(i: number) {
    this.getEscalations.removeAt(i);
  }


  get _address() { return this.company.address as FormArray }

  inviteVendor() {
    if(this.addressTypeBoolean == true){
      Swal.fire({
       text: "Cannot Select Same Address Type",
       timer: 5000,
       icon: "warning",
       titleText: "Invalid"
     });
   } else if(this.checkSameStreet == true){
    Swal.fire({
      text: "Street 1 & Street 2 Cannot Be Same",
      timer: 5000,
      icon: "warning",
      titleText: "Invalid"
    });
   }
    else if (this.vendorOnBoardForm.invalid) {
      Swal.fire({
        titleText: "Invalid",
        text: "Fill all the mandatory fields",
        timer: 5000,
        icon: "warning"
      })
      this.company.name.markAsDirty();
      this.company.pocFirstName.markAsDirty();
      this.company.pocLastName.markAsDirty();
      this.company.pocEmailId.markAsDirty();
      this.company.pocContactNumber.markAsDirty();
    }
    else {
      this.vendorService.createVendor(this.vendorOnBoardForm.value).subscribe(
        (response) => {
          if (response.code == 201) {
            Swal.fire({
              titleText: "Success",
              text: response.message,
              timer: 5000,
              icon: "success"
            })
            this.router.navigate(['/v1/vendor/listing']);
          }
        },
        (error) => {
          console.log(error);
          let _error = error.error;
          if (_error.status == 500) {
            Swal.fire({
              titleText: "Error",
              text: _error.message,
              timer: 5000,
              icon: "error"
            })
          }
          else if (_error.errorCode == 412) {
            Swal.fire({
              titleText: "Error",
              text: _error.errorMessage,
              timer: 5000,
              icon: "error"
            })
          }
          else {
            Swal.fire({
              titleText: "Error",
              text: "Something went wrong, try again later",
              timer: 5000,
              icon: "error"
            })
          }
        },
      );
    }
  }

  updateVendor() {
    if (this.vendorOnBoardForm.invalid) {
      console.log(this.vendorOnBoardForm.value);
      Swal.fire({
        titleText: "Invalid",
        text: "Fill all the mandatory fields",
        timer: 5000,
        icon: "warning"
      })
    }
    else {
      this.vendorOnBoardForm.addControl("onboardingStatus" , new FormControl("IN_PROGRESS"))
      let data = {
        'id': this.vendor_id,
        'website': this.company.website.value,
        'address': this.company.address.value,
        'pocFirstName': this.company.pocFirstName.value,
        'pocLastName': this.company.pocLastName.value,
        'pocEmailId': this.company.pocEmailId.value,
        'pocDesignation': this.company.pocDesignation.value,
        'pocContactNumber': this.company.pocContactNumber.value,
        'escalations': this.company.escalations.value,
        "onboardingStatus": this.company.onboardingStatus.value
      }
      this.vendorService.updateVendor(data).subscribe(
        (response) => {
          console.log(response);
          this.router.navigate(['/v1/vendor-onboard/overview']);
        },
        (error) => {
          console.log(error);
          Swal.fire({
            titleText: "Error",
            text: "Work in Process, please Wait",
            timer: 5000,
            icon: "warning"
          })
        },
      )
    }
  }

  onStreetValueCheck(){
    for (let i = 0; i < this._address.length; i++) {
      const value1 = this._address.value[i].street1;
      const value2 = this._address.value[i].street2;
      if(value1 == value2){
        this.checkSameStreet = true;
        if(value1 == '' && value2 == ''){
          this.checkSameStreet = false;
        }
      } else{
        this.checkSameStreet = false;
      }
    }
  }

  checkDuplicateEmail(i: number){
    let duplicateEmail: Boolean = false;
    let emailId = this.getEscalations.at(i).get('emailId');
    let escalation: Array<any> =  this.getEscalations.value;
    let lastIndex =  escalation.pop();
    for (let index = 0; index < escalation.length; index++) {
      const email = escalation[index]['emailId'];
      if (lastIndex.emailId === email) {
        emailId.patchValue("");
        duplicateEmail = true;
        break;
      }
    }
    if(duplicateEmail == true){
      Swal.fire({
        icon: "warning",
        timer: 5000,
        titleText: "Warning",
        text: "Same emailId not valid for escaltion points"
      });
    }
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  //fuction to scroll to top on next button click
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  verifyVendorToken(){
    sessionStorage.setItem('preToken', this.token);
    this.getAllCountriesunauthenticated();
    this.vendorService.vendorVerifyToken(this.token).subscribe(
      (response) => {
        if (response != null) {
          this.vendor_status = true;
          this.vendor_id = response.id
          sessionStorage.setItem('org_id', JSON.stringify(this.vendor_id));
          sessionStorage.setItem('email_id', response.pocEmailId);
          this.vendorOnBoardForm.patchValue({
            name: response.name,
            website: response.website,
            pocEmailId: response.pocEmailId,
            pocDesignation: response.pocDesignation,
            pocContactNumber: response.pocContactNumber,
            pocFirstName: response.pocFirstName,
            pocLastName: response.pocLastName
          });
          if (response.address.length >= 1) {
            for (let index = 0; index < response.address.length; index++) {
              const address = response.address[index];
              this._address.push(this.prePopulateAddress(address))
            }
          }
          else {
            this.addNewAddress();
          }
          if (response.escalations.length >= 1) {
            for (let index = 0; index < response.escalations.length; index++) {
              const escalation = response.escalations[index];
              this.getEscalations.push(this.prePopulateEscalation(escalation));
            }
          }
          else {
            this.addNewEscalations();
          }
          this.company.name.disable();
          this.company.pocEmailId.disable();
          this.company.pocContactNumber.disable();
        }

      },
      (error) => {
        console.log(error);
        if (error.status == 500) {
          Swal.fire({
            titleText: "Error",
            text: "Something went wrong, try agian later!",
            icon: "error",
            timer: 5000
          })
        }
        else if (error.status == 412) {
          let _error = error.error;
          Swal.fire({
            titleText: "Error",
            text: _error.errorMessage,
            timer: 5000,
            icon: "error"
          })
        }
      },
    )
  }

  getCountries(){
    this.masterTableService.getAllCountries().subscribe(
      (response) => {
        this.countryCodes = response;
      },
      (error) => {
        console.log(error);
      }
    )
  }

  getAllCountriesunauthenticated(){
    this.masterTableService.getAllCountriesUnauthenticated().subscribe(
      (response) => {
        this.countryCodes = response;
      },
      (error) => {
        console.log(error);
      }
    )
  }
}
