import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SocketService } from 'src/app/helpers/socket.service';

const demobackendData=[
  {"documentNumber":"Document No. 1","description":"Service Order","creatorName":"Creator 1","creationDate":"20/05/21","value":"25000","currency":"USD","status":"Open"},
  {"documentNumber":"Document No. 2","description":"Service Order","creatorName":"Creator 1","creationDate":"20/05/21","value":"26000","currency":"USD","status":"Open"},
  {"documentNumber":"Document No. 3","description":"Service Order","creatorName":"Creator 2","creationDate":"20/05/22","value":"25000","currency":"USD","status":"Open"},
  {"documentNumber":"Document No. 4","description":"Service Order","creatorName":"Creator 2","creationDate":"20/05/22","value":"22000","currency":"USD","status":"Open"}
];
                                        
@Component({
  selector: 'app-review-documents',
  templateUrl: './review-documents.component.html',
  styleUrls: ['./review-documents.component.css']
})
export class ReviewDocumentsComponent implements OnInit {

  @ViewChild(MatSort) sort!:MatSort;
  @ViewChild(MatPaginator) paginator!:MatPaginator;
  dataSource!:MatTableDataSource<any>;

  displaycolumns:string[]=["documentNumber","description","creatorName","creationDate","valueCurrency","status","view"];

  constructor(private socketService: SocketService) { }

 
  ngOnInit(): void {
    this.dataSource=new MatTableDataSource<any>(demobackendData);
    this.dataSource.sort=this.sort;

    // this.socketService.connectTOServerPopUpFuction();
  }

  applyfilter(filtervalue:string)
  {
    this.dataSource.filter=filtervalue.trim().toLowerCase();
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }
}

