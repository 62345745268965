import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ServiceOrderService {

  AUTH_API: string = `https://${window.location.hostname}:9004/api/v1/procurement`;

  constructor(private http: HttpClient) { }
  createServiceOrder(data: any): Observable<any> {
    return this.http.post(`${this.AUTH_API}/serviceOrder`, data);
  }
  
  getAllServiceOrderPageable(page, size): Observable<any> {
    let param = new HttpParams().set('page', page).set('size', size)
    return this.http.get(`${this.AUTH_API}/serviceOrders`, {params: param});
  }

  getServiceOrderById(id: any): Observable<any> {
    return this.http.get(`${this.AUTH_API}/serviceOrder/${id}`);
  }

  updateServiceOrder(id: any, data: any): Observable<any> {
    return this.http.put(`${this.AUTH_API}/serviceOrder/${id}`, data);
  }

  deleteServiceOrderArtifact(serviceOrderId, artifactId):Observable<any>{
    return this.http.delete(`${this.AUTH_API}/serviceOrder/${serviceOrderId}/artifact/${artifactId}`);
  }

  addServiceOrderArtifact(serviceOrderId, data: any):Observable<any>{
    return this.http.post(`${this.AUTH_API}/serviceOrder/${serviceOrderId}/artifact`, data);
  }

  getServiceOrderArtifacts(serviceOrderId):Observable<any>{
    return this.http.get(`${this.AUTH_API}/serviceOrder/${serviceOrderId}/artifacts`);
  }

  getServiceOrders():Observable<any>{
    return this.http.get(`${this.AUTH_API}/serviceOrderList`);
  }

  getServiceOrderPDF(id): Observable<Blob> {
    return this.http.get(`${this.AUTH_API}/downloadServiceOrderPdf/${id}`, {responseType: 'blob'});
  }

}
