import { Component, OnInit, ViewChild } from '@angular/core';
import { OnboardService } from '../helpers/onboard.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SocketService } from '../helpers/socket.service';
import { MatDialog } from '@angular/material/dialog';
import { VendorViewComponent } from './vendor-view/vendor-view.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-vendor-listing',
  templateUrl: './vendor-listing.component.html',
  styleUrls: ['./vendor-listing.component.css']
})
export class VendorListingComponent implements OnInit {

  page: number = 0;
  size: number = 25;
  dataSource= new MatTableDataSource();
  displayedColumns: string[] = ['name', 'website', 'pocEmailId', 'createDate' ,'onboardingStatus', 'actions']
  serchFilter: Boolean = false;
  nodata: Boolean = true;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  vendorLength: number;
  constructor(private apiService: OnboardService, private socketService: SocketService, private matDialog: MatDialog) { }

  ngOnInit(): void {
    this.getAllVendors();
    console.log("Im here");
  }

  getAllVendors(){
    this.apiService.getAllVendors(this.page, this.size, null).subscribe
    ((response)=>{
      this.vendorLength = response.totalElements;
      this.dataSource = new MatTableDataSource(response.content);
      this.dataSource.sort = this.sort;

    },
    (error)=>{
      console.log('Something Went Wrong');
    })
  }

  applyFilter(event: Event){
    const filterValue = (event.target as HTMLInputElement).value;
    this.nodata = false;
    this.serchFilter = true;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onPageChange(event: PageEvent){
    this.size = event.pageSize;
    this.page = event.pageIndex;
    this.getAllVendors();
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  routeToView(data){
    this.matDialog.open(VendorViewComponent, {
      height: "100%",
      width: "100%",
      data: data
    });
  }

  deleteClient(orgId: any, clientName: string) {
    Swal.fire({
      title: 'Delete',
      html: 'Are you sure you want to delete ' + `<br><b>${clientName}</b>`,
      icon: 'question',
      showCancelButton: true,
      showCloseButton: true,
    }).then((res) => {
      if (res.isConfirmed == true) {
        console.log('this is swal response ', res);
        this.apiService.deleteClientOrVendor(orgId).subscribe({
          next: (res) => {
            console.log('this is response ', res);
            Swal.fire({
              title: 'Success',
              text: res.message,
              timer: 5000,
              icon: 'success',
            });
            this.getAllVendors();
          },
          error: (error) => {
            console.log('this is error ', error);
          },
        });
      }
    });
  }
}
