<section class="wrapper">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-2 col-xl-1 col-left" style="height: 2240px;">
                <app-sidebar></app-sidebar>
            </div>
            <div class="col-md-10 col-xl-11 col-right px-start-end pb-6" (click)="hideSideBar()">
                <div class="topline py-start-end d-flex align-items-center justify-content-between mb-4 mb-md-5">
                    <div *ngIf="dType == 1" class="row">
                        <!-- Bulletin -->
                        <div class="col-lg-12 mb-3 mb-lg-4 ">
                            <div class="card bg-transparent border-0 h-100">
                                <h4>Welcome {{ userData.firstName }}  {{ userData.lastName }}</h4>
                            </div>
                        </div>
                        <div class="card bg-transparent border-0 h-100">
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="card-body bg-transparent border-0 h-100">
                                        <div class="card-body shadow rounded-xxl bg-white py-4 px-3 px-lg-4">
                                            <p class="text-center">{{ activeShipments }}</p>
                                            <h5 style="cursor: pointer;" class="text-primary text-center">
                                                <a (click)="routeToShipmentListing('Active')">Active Shipments</a>
                                            </h5>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="card-body bg-transparent border-0 h-100">
                                        <div class="card-body shadow rounded-xxl bg-white py-4 px-3">
                                            <p class="text-center">{{ countAtOrigin }}</p>
                                            <h5 style="cursor: pointer;" class="text-primary text-center">
                                                <a (click)="routeToShipmentListing('Booked')">At Origin</a>
                                            </h5>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="card-body bg-transparent border-0 h-100">
                                        <div class="card-body shadow rounded-xxl bg-white py-4 px-3">
                                            <p class="text-center">{{ countInTransit }}</p>
                                            <h5 style="cursor: pointer;" class="text-primary text-center">
                                                <a (click)="routeToShipmentListing('Sailing')">In Transit</a>
                                            </h5>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="card-body bg-transparent border-0 h-100">
                                        <div class="card-body shadow rounded-xxl bg-white py-4 px-3">
                                            <p class="text-center">{{ countInTranshipment }}</p>
                                            <h5 style="cursor: pointer;" class="text-primary text-center">
                                                <a (click)="routeToShipmentListing('TransShipment')">Transshipment</a>
                                            </h5>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="card-body bg-transparent border-0 h-100">
                                        <div class="card-body shadow rounded-xxl bg-white py-4 px-3">
                                            <p class="text-center">{{ countAtPOD }}</p>
                                            <h5 style="cursor: pointer;" class="text-primary text-center">
                                                <a (click)="routeToShipmentListing('Discharged')">Reached POD</a>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Recent Enquiries,*ngIf="showRecentEnquiry" -->
                        <div class="col-lg-6 mb-3 mb-lg-4 ">
                            <div class="card bg-transparent border-0 h-100">
                                <div class="card-header bg-transparent border-bottom-0 mb-4">
                                    <h5 class="text-primary">Recent Enquiries</h5>
                                </div>
                                <div class="card-body shadow rounded-xxl bg-white py-4 py-lg-5 px-3 px-lg-4">
                                    <div class="table-responsive">
                                        <table class="table table-borderless table-head-dark">
                                            <thead>
                                                <tr>
                                                    <th>Number</th>
                                                    <th>Type</th>
                                                    <th>Company Name</th>
                                                    <th>Description</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let data of recentEnquiryList">
                                                    <td>{{ data.number }}</td>
                                                    <td>
                                                        <span *ngIf="data.type == 'b'; else firm">
                                                            Budgetory
                                                        </span>
                                                        <ng-template #firm>
                                                            Firm
                                                        </ng-template>
                                                    </td>
                                                    <td>{{ data.org.name }}</td>
                                                    <td>{{ data.description }}</td>
                                                    <td>{{ data.enquiryDate | date: 'dd-MM-yyyy' }}</td>
                                                </tr>
                                            </tbody>
                                            <tr class="mat-row" *ngIf="!recentEnquiryList.length">
                                                <td class="mat-cell text-center text-danger" colspan="4">
                                                    <div>
                                                        <h5>No Data Available</h5>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                        <mat-paginator [pageSizeOptions]="[5,10]" aria-label="Select page of users"
                                            [length]="recentEnquirylength" (page)="onPageChange($event, 'recentEnquiryList')">
                                        </mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                        <!-- Recent Quotes,*ngIf="showRecentVendorQuotes" -->
                        <div class="col-lg-6 mb-3 mb-lg-4 ">
                            <div class="card bg-transparent border-0 h-100">
                                <div class="card-header bg-transparent border-bottom-0 mb-4">
                                    <h5 class="text-primary">Recent Quotes</h5>
                                </div>
                                <div class="card-body shadow rounded-xxl bg-white py-4 py-lg-5 px-3 px-lg-4">
                                    <div class="table-responsive">
                                        <table class="table table-borderless table-head-dark">
                                            <thead>
                                                <tr>
                                                    <th>Number</th>
                                                    <th>Company Name</th>
                                                    <th>Enquiry No</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let data of recentQuotesList">
                                                    <td>{{ data.number }}</td>
                                                    <td>{{ data.vendor.name }}</td>
                                                    <td>{{ data.enquiry.number }}</td>
                                                    <td>{{ data.quoteDate | date: 'dd-MM-yyyy' }}</td>
                                                </tr>
                                            </tbody>
                                            <tr class="mat-row" *ngIf="!recentQuotesList.length">
                                                <td class="mat-cell text-center text-danger" colspan="4">
                                                    <div>
                                                        <h5>No Data Available</h5>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                        <mat-paginator [pageSizeOptions]="[5,10]" aria-label="Select page of users"
                                            [length]="recentQuoteslength" (page)="onPageChange($event, 'recentQuotesList')">
                                        </mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Upcoming Auctions,*ngIf="showUpcomingAuctions" -->
                        <div class="col-lg-6 mb-3 mb-lg-4 ">
                            <div class="card bg-transparent border-0 h-100">
                                <div class="card-header bg-transparent border-bottom-0 mb-4">
                                    <h5 class="text-primary">Upcoming Auctions</h5>
                                </div>
                                <div class="card-body shadow rounded-xxl bg-white py-4 py-lg-5 px-3 px-lg-4">
                                    <div class="table-responsive">
                                        <table class="table table-borderless table-head-dark">
                                            <thead>
                                                <tr>
                                                    <th>Number</th>
                                                    <th>Company Name</th>
                                                    <th>Description</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let data of upcomingAuctionList; index as i">
                                                    <td>{{ data.number }}</td>
                                                    <td>{{ data.org.name }}</td>
                                                    <td>{{ data.description }}</td>
                                                    <td>{{ data.auctionDate | date: 'dd-MM-yyyy HH:mm:ss' }}</td>
                                                </tr>
                                            </tbody>
                                            <tr class="mat-row" *ngIf="!upcomingAuctionList.length">
                                                <td class="mat-cell text-center text-danger" colspan="4">
                                                    <div>
                                                        <h5>No Data Available</h5>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                        <mat-paginator [pageSizeOptions]="[5,10]" aria-label="Select page of users"
                                            [length]="upComingAuctionlength" (page)="onPageChange($event, 'upcomingAuctionList')">
                                        </mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- In-Progress Auctions,*ngIf="showInProgressAuctions" -->
                        <div class="col-lg-6 mb-3 mb-lg-4 ">
                            <div class="card bg-transparent border-0 h-100">
                                <div class="card-header bg-transparent border-bottom-0 mb-4">
                                    <h5 class="text-primary">In-Progress Auctions</h5>
                                </div>
                                <div class="card-body shadow rounded-xxl bg-white py-4 py-lg-5 px-3 px-lg-4">
                                    <div class="table-responsive">
                                        <table class="table table-borderless table-head-dark">
                                            <thead>
                                                <tr>
                                                    <th>Number</th>
                                                    <th>Company Name</th>
                                                    <th>Description</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let data of inProgressAuctionList; index as i">
                                                    <td>{{ data.number }}</td>
                                                    <td>{{ data.org.name }}</td>
                                                    <td>{{ data.description }}</td>
                                                    <td>{{ data.auctionDate | date: 'dd-MM-yyyy HH:mm:ss' }}</td>
                                                </tr>
                                            </tbody>
                                            <tr class="mat-row" *ngIf="!inProgressAuctionList.length">
                                                <td class="mat-cell text-center text-danger" colspan="4">
                                                    <div>
                                                        <h5>No Data Available</h5>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                        <mat-paginator [pageSizeOptions]="[5,10]" aria-label="Select page of users"
                                            [length]="inProgressAuctionlength" (page)="onPageChange($event, 'inProgressAuctionList')">
                                        </mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Shipments Per Month -->
                        <!-- <div class="col-lg-6 mb-3 mb-lg-4 ">
                            <div class="card bg-transparent border-0 h-100">
                                <div class="card-header bg-transparent border-bottom-0 mb-4">
                                    <h5 class="text-primary">Shipments Per Month</h5>
                                </div>
                                <div class="card-body shadow rounded-xxl bg-white py-4 py-lg-5 px-3 px-lg-4">
                                    <div>
                                        <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart"
                                            [xaxis]="chartOptions.xaxis" [title]="chartOptions.title">
                                        </apx-chart>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- Savings Per Month -->
                        <!-- <div class="col-lg-6 mb-3 mb-lg-4">
                            <div class="card bg-transparent border-0 h-100">
                                <div class="card-header bg-transparent border-bottom-0 mb-3">
                                    <h5 class="text-primary">Savings Per Month</h5>
                                </div>
                                <div class="card-body shadow rounded-xxl bg-white py-4 py-lg-5 px-3 px-lg-4">
                                    <div>
                                        <apx-chart [series]="lineChart.series" [chart]="lineChart.chart" [xaxis]="lineChart.xaxis"
                                            [title]="lineChart.title">
                                        </apx-chart>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- Shipment By Vendors -->
                        <!-- <div class="col-lg-6 mb-3 mb-lg-4">
                            <div class="card bg-transparent border-0 h-100">
                                <div class="card-header bg-transparent border-bottom-0 mb-3">
                                    <h5 class="text-primary">Shipment By Vendors</h5>
                                </div>
                                <div class="card-body shadow rounded-xxl bg-white py-4 py-lg-5 px-3 px-lg-4">
                                    <div class="d-flex justify-content-center">
                                        <apx-chart [title]="pieChart.title" [series]="pieChart.series" [chart]="pieChart.chart" [labels]="pieChart.labels"
                                            [responsive]="pieChart.responsive">
                                        </apx-chart>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>

                    <div *ngIf="dType == 2" class="row">
                        <div class="col-lg-12 mb-3 mb-lg-4 ">
                            <div class="card bg-transparent border-0 h-100">
                                <h4>Welcome {{ userData.firstName }}  {{ userData.lastName }}</h4>
                            </div>
                        </div>
                        <div class="col-lg-12 mb-3 mb-lg-4 ">
                            <div class="card bg-transparent border-0 h-100">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="card-body bg-transparent border-0 h-100">
                                            <div class="card-body shadow rounded-xxl bg-white py-4 px-3 px-lg-4">
                                                <p class="text-center">{{ activeShipments }}</p>
                                                <h5 style="cursor: pointer;" class="text-primary text-center">
                                                    <a (click)="routeToShipmentListing('Active')">Active Shipments</a>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-2">
                                        <div class="card-body bg-transparent border-0 h-100">
                                            <div class="card-body shadow rounded-xxl bg-white py-4 px-3">
                                                <p class="text-center">{{ countAtOrigin }}</p>
                                                <h5 style="cursor: pointer;" class="text-primary text-center">
                                                    <a (click)="routeToShipmentListing('Booked')">At Origin</a>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-2">
                                        <div class="card-body bg-transparent border-0 h-100">
                                            <div class="card-body shadow rounded-xxl bg-white py-4 px-3">
                                                <p class="text-center">{{ countInTransit }}</p>
                                                <h5 style="cursor: pointer;" class="text-primary text-center">
                                                    <a (click)="routeToShipmentListing('Sailing')">In Transit</a>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-2">
                                        <div class="card-body bg-transparent border-0 h-100">
                                            <div class="card-body shadow rounded-xxl bg-white py-4 px-3">
                                                <p class="text-center">{{ countInTranshipment }}</p>
                                                <h5 style="cursor: pointer;" class="text-primary text-center">
                                                    <a (click)="routeToShipmentListing('TransShipment')">Transshipment</a>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-2">
                                        <div class="card-body bg-transparent border-0 h-100">
                                            <div class="card-body shadow rounded-xxl bg-white py-4 px-3">
                                                <p class="text-center">{{ countAtPOD }}</p>
                                                <h5 style="cursor: pointer;" class="text-primary text-center">
                                                    <a (click)="routeToShipmentListing('Discharged')">Reached POD</a>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Recent Enquiry,*ngIf="showRecentEnquiry" -->
                        <div class="col-lg-6 mb-3 mb-lg-4 ">
                            <div class="card bg-transparent border-0 h-100">
                                <div class="card-header bg-transparent border-bottom-0 mb-4">
                                    <h5 class="text-primary">Recent Enquiries</h5>
                                </div>
                                <div class="card-body shadow rounded-xxl bg-white py-4 py-lg-5 px-3 px-lg-4">
                                    <div class="table-responsive">
                                        <table class="table table-borderless table-head-dark">
                                            <thead>
                                                <tr>
                                                    <th>Number</th>
                                                    <th>Type</th>
                                                    <th>Description</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let data of recentEnquiryList">
                                                    <td>
                                                        <a class="decor-route" [routerLink]="['/v1/client-rfq/view/', data.id]">{{ data.number }}</a>
                                                    </td>
                                                    <td>
                                                        <span *ngIf="data.type == 'b'; else firm">
                                                            Budgetory
                                                        </span>
                                                        <ng-template #firm>
                                                            Firm
                                                        </ng-template>
                                                    </td>
                                                    <td>{{ data.description }}</td>
                                                    <td>{{ data.enquiryDate | date: 'dd-MM-yyyy' }}</td>
                                                </tr>
                                            </tbody>
                                            <tr class="mat-row" *ngIf="!recentEnquiryList.length">
                                                <td class="mat-cell text-center text-danger" colspan="4">
                                                    <div>
                                                        <h5>No Data Available</h5>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                        <mat-paginator [pageSizeOptions]="[5,10]" aria-label="Select page of users"
                                            [length]="recentEnquirylength" (page)="onPageChange($event, 'recentEnquiryList')">
                                        </mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Recent Quotes, *ngIf="showRecentVendorQuotes" -->
                        <div class="col-lg-6 mb-3 mb-lg-4 ">
                            <div class="card bg-transparent border-0 h-100">
                                <div class="card-header bg-transparent border-bottom-0 mb-4">
                                    <h5 class="text-primary">Recent Quotes</h5>
                                </div>
                                <div class="card-body shadow rounded-xxl bg-white py-4 py-lg-5 px-3 px-lg-4">
                                    <div class="table-responsive">
                                        <table class="table table-borderless table-head-dark">
                                            <thead>
                                                <tr>
                                                    <th>Number</th>
                                                    <th>Company Name</th>
                                                    <th>Enquiry No</th>
                                                    <th>Date</th>
                                                    <!-- <th>Action</th> -->
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let data of recentQuotesList">
                                                    <td>{{ data.number }}</td>
                                                    <td>{{ data.vendor.name }}</td>
                                                    <td>{{ data.enquiry.number }}</td>
                                                    <td>{{ data.quoteDate | date: 'dd-MM-yyyy' }}</td>
                                                    <!-- <td>
                                                        <i class="fa fa-eye" [routerLink]="['/v1/client-rfq/single-quote-view/', data.id]"></i>
                                                    </td> -->
                                                </tr>
                                            </tbody>
                                            <tr class="mat-row" *ngIf="!recentQuotesList.length">
                                                <td class="mat-cell text-center text-danger" colspan="4">
                                                    <div>
                                                        <h5>No Data Available</h5>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>

                                        <mat-paginator [pageSizeOptions]="[5,10]" aria-label="Select page of users"
                                            [length]="recentQuoteslength" (page)="onPageChange($event, 'recentQuotesList')">
                                        </mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Upcoming Auctions, *ngIf="showUpcomingAuctions" -->
                        <div class="col-lg-6 mb-3 mb-lg-4 ">
                            <div class="card bg-transparent border-0 h-100">
                                <div class="card-header bg-transparent border-bottom-0 mb-4">
                                    <h5 class="text-primary">Upcoming Auctions</h5>
                                </div>
                                <div class="card-body shadow rounded-xxl bg-white py-4 py-lg-5 px-3 px-lg-4">
                                    <div class="table-responsive">
                                        <table class="table table-borderless table-head-dark">
                                            <thead>
                                                <tr>
                                                    <th>Number</th>
                                                    <th>Description</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let data of upcomingAuctionList; index as i">
                                                    <td>{{ data.number }}</td>
                                                    <td>{{ data.description }}</td>
                                                    <td>{{ data.auctionDate | date: 'dd-MM-yyyy HH:mm:ss' }}</td>
                                                </tr>
                                            </tbody>
                                            <tr class="mat-row" *ngIf="!upcomingAuctionList.length">
                                                <td class="mat-cell text-center text-danger" colspan="4">
                                                    <div>
                                                        <h5>No Data Available</h5>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                        <mat-paginator [pageSizeOptions]="[5,10]" aria-label="Select page of users"
                                            [length]="upComingAuctionlength" (page)="onPageChange($event, 'upcomingAuctionList')">
                                        </mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- In-Progress Auctions, *ngIf="showInProgressAuctions" -->
                        <div class="col-lg-6 mb-3 mb-lg-4 ">
                            <div class="card bg-transparent border-0 h-100">
                                <div class="card-header bg-transparent border-bottom-0 mb-4">
                                    <h5 class="text-primary">In-Progress Auctions</h5>
                                </div>
                                <div class="card-body shadow rounded-xxl bg-white py-4 py-lg-5 px-3 px-lg-4">
                                    <div class="table-responsive">
                                        <table class="table table-borderless table-head-dark">
                                            <thead>
                                                <tr>
                                                    <th>Number</th>
                                                    <th>Description</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let data of inProgressAuctionList; index as i">
                                                    <td>
                                                        <a class="decor-route" [routerLink]="['/v1/client-live-auction/', data.id]"  routerLinkActive="active">
                                                            {{ data.number }}
                                                        </a>
                                                    </td>
                                                    <td>{{ data.description }}</td>
                                                    <td>{{ data.auctionDate | date: 'dd-MM-yyyy HH:mm:ss' }}</td>
                                                </tr>
                                            </tbody>
                                            <tr class="mat-row" *ngIf="!inProgressAuctionList.length">
                                                <td class="mat-cell text-center text-danger" colspan="4">
                                                    <div>
                                                        <h5>No Data Available</h5>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                        <mat-paginator [pageSizeOptions]="[5,10]" aria-label="Select page of users"
                                            [length]="inProgressAuctionlength" (page)="onPageChange($event, 'inProgressAuctionList')">
                                        </mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Invoices -->
                        <!-- <div class="col-lg-6 mb-3 mb-lg-4 ">
                            <div class="card bg-transparent border-0 h-100">
                                <div class="card-header bg-transparent border-bottom-0 mb-4">
                                    <h5 class="text-primary">Invoices</h5>
                                </div>
                                <div class="card-body shadow rounded-xxl bg-white py-4 py-lg-5 px-3 px-lg-4">
                                    <div class="table-responsive">
                                        <table class="table table-borderless table-head-dark">
                                            <thead>
                                                <tr>
                                                    <th>Number</th>
                                                    <th>Description</th>
                                                    <th>Created Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>INV-213-2021</td>
                                                    <td>Ship from USA to ENGLAND</td>
                                                    <td>2021-12-15</td>
                                                </tr>
                                                <tr>
                                                    <td>INV-456-2021</td>
                                                    <td>Ship from India to China</td>
                                                    <td>2021-12-30</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- Shipments Per Month -->
                        <!-- <div class="col-lg-6 mb-3 mb-lg-4 ">
                            <div class="card bg-transparent border-0 h-100">
                                <div class="card-header bg-transparent border-bottom-0 mb-4">
                                    <h5 class="text-primary">Shipments Per Month</h5>
                                </div>
                                <div class="card-body shadow rounded-xxl bg-white py-4 py-lg-5 px-3 px-lg-4">
                                    <div>
                                        <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart"
                                            [xaxis]="chartOptions.xaxis" [title]="chartOptions.title">
                                        </apx-chart>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- Savings Per Month  -->
                        <!-- <div class="col-lg-6 mb-3 mb-lg-4">
                            <div class="card bg-transparent border-0 h-100">
                                <div class="card-header bg-transparent border-bottom-0 mb-3">
                                    <h5 class="text-primary">Savings Per Month</h5>
                                </div>
                                <div class="card-body shadow rounded-xxl bg-white py-4 py-lg-5 px-3 px-lg-4">
                                    <div>
                                        <apx-chart [series]="lineChart.series" [chart]="lineChart.chart" [xaxis]="lineChart.xaxis"
                                            [title]="lineChart.title">
                                        </apx-chart>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- Vessel Delivery Timeline -->
                        <!-- <div class="col-lg-6 mb-3 mb-lg-4">
                            <div class="card bg-transparent border-0 h-100">
                                <div class="card-header bg-transparent border-bottom-0 mb-3">
                                    <h5 class="text-primary">Vessel Delivery Timeline</h5>
                                </div>
                                <div class="card-body shadow rounded-xl bg-white py-4 py-lg-5 px-3 px-lg-4">
                                    <div>
                                        <apx-chart [series]="areaChart.series" [chart]="areaChart.chart" [xaxis]="areaChart.xaxis"
                                            [title]="areaChart.title">
                                        </apx-chart>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- Shipment By Vendors -->
                        <!-- <div class="col-lg-6 mb-3 mb-lg-4">
                            <div class="card bg-transparent border-0 h-100">
                                <div class="card-header bg-transparent border-bottom-0 mb-3">
                                    <h5 class="text-primary">Shipment By Vendors</h5>
                                </div>
                                <div class="card-body shadow rounded-xxl bg-white py-4 py-lg-5 px-3 px-lg-4">
                                    <div class="d-flex justify-content-center">
                                        <apx-chart [title]="pieChart.title" [series]="pieChart.series" [chart]="pieChart.chart" [labels]="pieChart.labels"
                                            [responsive]="pieChart.responsive">
                                        </apx-chart>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>

                    <div *ngIf="dType == 3" class="row">
                        <div class="col-lg-12 mb-3 mb-lg-4 ">
                            <div class="card bg-transparent border-0 h-100">
                                <h4>Welcome {{ userData.firstName }}  {{ userData.lastName }}</h4>
                            </div>
                        </div>
                        <div class="col-lg-12 mb-3 mb-lg-4 ">
                            <div class="card bg-transparent border-0 h-100">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="card-body bg-transparent border-0 h-100">
                                            <div class="card-body shadow rounded-xxl bg-white py-4 px-3 px-lg-4">
                                                <p class="text-center">{{ activeShipments }}</p>
                                                <h5 style="cursor: pointer;" class="text-primary text-center">
                                                    <a (click)="routeToShipmentListing('Active')">Active Shipments</a>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-2">
                                        <div class="card-body bg-transparent border-0 h-100">
                                            <div class="card-body shadow rounded-xxl bg-white py-4 px-3">
                                                <p class="text-center">{{ countAtOrigin }}</p>
                                                <h5 style="cursor: pointer;" class="text-primary text-center">
                                                    <a (click)="routeToShipmentListing('Booked')">At Origin</a>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-2">
                                        <div class="card-body bg-transparent border-0 h-100">
                                            <div class="card-body shadow rounded-xxl bg-white py-4 px-3">
                                                <p class="text-center">{{ countInTransit }}</p>
                                                <h5 style="cursor: pointer;" class="text-primary text-center">
                                                    <a (click)="routeToShipmentListing('Sailing')">In Transit</a>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-2">
                                        <div class="card-body bg-transparent border-0 h-100">
                                            <div class="card-body shadow rounded-xxl bg-white py-4 px-3">
                                                <p class="text-center">{{ countInTranshipment }}</p>
                                                <h5 style="cursor: pointer;" class="text-primary text-center">
                                                    <a (click)="routeToShipmentListing('TransShipment')">Transshipment</a>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-2">
                                        <div class="card-body bg-transparent border-0 h-100">
                                            <div class="card-body shadow rounded-xxl bg-white py-4 px-3">
                                                <p class="text-center">{{ countAtPOD }}</p>
                                                <h5 style="cursor: pointer;" class="text-primary text-center">
                                                    <a (click)="routeToShipmentListing('Discharged')">Reached POD</a>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Recent Enquiry, *ngIf="showRecentEnquiry"-->
                        <div class="col-lg-6 mb-3 mb-lg-4 ">
                            <div class="card bg-transparent border-0 h-100">
                                <div class="card-header bg-transparent border-bottom-0 mb-4">
                                    <h5 class="text-primary">Recent Enquiries</h5>
                                </div>
                                <div class="card-body shadow rounded-xxl bg-white py-4 py-lg-5 px-3 px-lg-4">
                                    <div class="table-responsive">
                                        <table class="table table-borderless table-head-dark">
                                            <thead>
                                                <tr>
                                                    <th>Number</th>
                                                    <th>Type</th>
                                                    <th>Description</th>
                                                    <th>Last Submission Date</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let data of recentEnquiryList">
                                                    <td>
                                                        <a class="decor-route" [routerLink]="['/v1/vendor-rfq/quote/edit/', data.id]">{{ data.number }}</a>
                                                    </td>
                                                    <td>
                                                        <span *ngIf="data.type == 'b'; else firm">
                                                            Budgetory
                                                        </span>
                                                        <ng-template #firm>
                                                            Firm
                                                        </ng-template>
                                                    </td>
                                                    <td>{{ data.description }}</td>
                                                    <td> {{ data.lastSubmissionDate | date: 'dd-MM-yyyy HH:mm:ss' }} </td>
                                                    <td>{{ data.enquiryDate | date: 'dd-MM-yyyy' }}</td>
                                                </tr>
                                            </tbody>
                                            <tr class="mat-row" *ngIf="!recentEnquiryList.length">
                                                <td class="mat-cell text-center text-danger" colspan="4">
                                                    <div>
                                                        <h5>No Data Available</h5>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                        <mat-paginator [pageSizeOptions]="[5,10]" aria-label="Select page of users"
                                            [length]="recentEnquirylength" (page)="onPageChange($event, 'recentEnquiryList')">
                                        </mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Recent Quotes,  *ngIf="showRecentVendorQuotes" -->
                        <div class="col-lg-6 mb-3 mb-lg-4">
                            <div class="card bg-transparent border-0 h-100">
                                <div class="card-header bg-transparent border-bottom-0 mb-4">
                                    <h5 class="text-primary">Recent Quotes</h5>
                                </div>
                                <div class="card-body shadow rounded-xxl bg-white py-4 py-lg-5 px-3 px-lg-4">
                                    <div class="table-responsive">
                                        <table class="table table-borderless table-head-dark">
                                            <thead>
                                                <tr>
                                                    <th>Number</th>
                                                    <th>Enquiry No</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let data of recentQuotesList">
                                                    <td>
                                                        <a class="decor-route" [routerLink]="['/v1/vendor-rfq/quote/edit/', data.enquiry.id]">{{ data.number }}</a>
                                                    </td>
                                                    <td>{{ data.enquiry.number }}</td>
                                                    <td>{{ data.quoteDate | date: 'dd-MM-yyyy' }}</td>
                                                </tr>
                                            </tbody>
                                            <tr class="mat-row" *ngIf="!recentQuotesList.length">
                                                <td class="mat-cell text-center text-danger" colspan="4">
                                                    <div>
                                                        <h5>No Data Available</h5>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                        <mat-paginator [pageSizeOptions]="[5,10]" aria-label="Select page of users"
                                            [length]="recentQuoteslength" (page)="onPageChange($event, 'recentQuotesList')">
                                        </mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Upcoming Auctions, *ngIf="showUpcomingAuctions" -->
                        <div class="col-lg-6 mb-3 mb-lg-4 ">
                            <div class="card bg-transparent border-0 h-100">
                                <div class="card-header bg-transparent border-bottom-0 mb-4">
                                    <h5 class="text-primary">Upcoming Auctions</h5>
                                </div>
                                <div class="card-body shadow rounded-xxl bg-white py-4 py-lg-5 px-3 px-lg-4">
                                    <div class="table-responsive">
                                        <table class="table table-borderless table-head-dark">
                                            <thead>
                                                <tr>
                                                    <th>Number</th>
                                                    <th>Description</th>
                                                    <th>Conducted By</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let data of upcomingAuctionList; index as i">
                                                    <td>{{ data.number }}</td>
                                                    <td>{{ data.description }}</td>
                                                    <td>{{ data.org?.name }} </td>
                                                    <td>{{ data.auctionDate | date: 'dd-MM-yyyy HH:mm:ss' }}</td>
                                                </tr>
                                            </tbody>
                                            <tr class="mat-row" *ngIf="!upcomingAuctionList.length">
                                                <td class="mat-cell text-center text-danger" colspan="4">
                                                    <div>
                                                        <h5>No Data Available</h5>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                        <mat-paginator [pageSizeOptions]="[5,10]" aria-label="Select page of users"
                                            [length]="upComingAuctionlength" (page)="onPageChange($event, 'upcomingAuctionList')">
                                        </mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- In-Progress Auctions, *ngIf="showInProgressAuctions" -->
                        <div class="col-lg-6 mb-3 mb-lg-4 ">
                            <div class="card bg-transparent border-0 h-100">
                                <div class="card-header bg-transparent border-bottom-0 mb-4">
                                    <h5 class="text-primary">In-Progress Auctions</h5>
                                </div>
                                <div class="card-body shadow rounded-xxl bg-white py-4 py-lg-5 px-3 px-lg-4">
                                    <div class="table-responsive">
                                        <table class="table table-borderless table-head-dark">
                                            <thead>
                                                <tr>
                                                    <th>Number</th>
                                                    <th>Description</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let data of inProgressAuctionList; index as i">
                                                    <td>
                                                        <a class="decor-route" [routerLink]="['/v1/vendor-live-auction/', data.id]"  routerLinkActive="active">{{ data.number }}</a>
                                                    </td>
                                                    <td>{{ data.description }}</td>
                                                    <td>{{ data.auctionDate | date: 'dd-MM-yyyy HH:mm:ss' }}</td>
                                                </tr>
                                            </tbody>
                                            <tr class="mat-row" *ngIf="!inProgressAuctionList.length">
                                                <td class="mat-cell text-center text-danger" colspan="4">
                                                    <div>
                                                        <h5>No Data Available</h5>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                        <mat-paginator [pageSizeOptions]="[5,10]" aria-label="Select page of users"
                                            [length]="inProgressAuctionlength" (page)="onPageChange($event, 'inProgressAuctionList')">
                                        </mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Invoices -->
                        <!-- <div class="col-lg-6 mb-3 mb-lg-4 ">
                            <div class="card bg-transparent border-0 h-100">
                                <div class="card-header bg-transparent border-bottom-0 mb-4">
                                    <h5 class="text-primary">Invoices</h5>
                                </div>
                                <div class="card-body shadow rounded-xxl bg-white py-4 py-lg-5 px-3 px-lg-4">
                                    <div class="table-responsive">
                                        <table class="table table-borderless table-head-dark">
                                            <thead>
                                                <tr>
                                                    <th>Number</th>
                                                    <th>Description</th>
                                                    <th>Created Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>INV-213-2021</td>
                                                    <td>Ship from USA to ENGLAND</td>
                                                    <td>2021-12-15</td>
                                                </tr>
                                                <tr>
                                                    <td>INV-456-2021</td>
                                                    <td>Ship from India to China</td>
                                                    <td>2021-12-30</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- Shipments Per Month -->
                        <!-- <div class="col-lg-6 mb-3 mb-lg-4 ">
                            <div class="card bg-transparent border-0 h-100">
                                <div class="card-header bg-transparent border-bottom-0 mb-4">
                                    <h5 class="text-primary">Shipments Per Month</h5>
                                </div>
                                <div class="card-body shadow rounded-xxl bg-white py-4 py-lg-5 px-3 px-lg-4">
                                    <div>
                                        <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart"
                                            [xaxis]="chartOptions.xaxis" [title]="chartOptions.title">
                                        </apx-chart>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- Savings Per Month -->
                        <!-- <div class="col-lg-6 mb-3 mb-lg-4">
                            <div class="card bg-transparent border-0 h-100">
                                <div class="card-header bg-transparent border-bottom-0 mb-3">
                                    <h5 class="text-primary">Savings Per Month</h5>
                                </div>
                                <div class="card-body shadow rounded-xxl bg-white py-4 py-lg-5 px-3 px-lg-4">
                                    <div>
                                        <apx-chart [series]="lineChart.series" [chart]="lineChart.chart" [xaxis]="lineChart.xaxis"
                                            [title]="lineChart.title">
                                        </apx-chart>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- Vessel Delivery Timeline -->
                        <!-- <div class="col-lg-6 mb-3 mb-lg-4">
                            <div class="card bg-transparent border-0 h-100">
                                <div class="card-header bg-transparent border-bottom-0 mb-3">
                                    <h5 class="text-primary">Vessel Delivery Timeline</h5>
                                </div>
                                <div class="card-body shadow rounded-xl bg-white py-4 py-lg-5 px-3 px-lg-4">
                                    <div>
                                        <apx-chart [series]="areaChart.series" [chart]="areaChart.chart" [xaxis]="areaChart.xaxis"
                                            [title]="areaChart.title">
                                        </apx-chart>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- Shipment By Vendors -->
                        <!-- <div class="col-lg-6 mb-3 mb-lg-4">
                            <div class="card bg-transparent border-0 h-100">
                                <div class="card-header bg-transparent border-bottom-0 mb-3">
                                    <h5 class="text-primary">Shipment By Vendors</h5>
                                </div>
                                <div class="card-body shadow rounded-xxl bg-white py-4 py-lg-5 px-3 px-lg-4">
                                    <div class="d-flex justify-content-center">
                                        <apx-chart [title]="pieChart.title" [series]="pieChart.series" [chart]="pieChart.chart" [labels]="pieChart.labels"
                                            [responsive]="pieChart.responsive">
                                        </apx-chart>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
