import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MasterTableService } from 'src/app/helpers/master-table.service';
import Swal from 'sweetalert2';
import { VehicleDetailBoxComponent } from './vehicle-detail-box/vehicle-detail-box.component';

@Component({
  selector: 'app-type-of-vehicle',
  templateUrl: './type-of-vehicle.component.html',
  styleUrls: ['./type-of-vehicle.component.css']
})
export class TypeOfVehicleComponent implements OnInit {


  displayedColumns: string[] = ['id', 'type', 'actions']
  dataSource = new MatTableDataSource();
  serchFilter: Boolean = false;
  nodata: Boolean = true;

  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort
  length: number;
  size: number = 25;
  page: number = 0;

  sessionStorageUserData: any
  disableCreateBtn: boolean = true
  disableEditBtn: boolean = true
  disableDeleteBtn: boolean = true

  constructor(private dialog: MatDialog, private masterData: MasterTableService) { }

  ngOnInit(): void {
    this.getAllVehicle();
    this.getCurrentUserDataFromSessionStorage();
  }

  openDialog(action, obj) {
    obj.action = action
    const dialogRef = this.dialog.open(VehicleDetailBoxComponent, {
      width: '250px',
      data: obj
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == 'Add') {
        this.addVehicle(result.data);
        this.getAllVehicle;
      } else if (result.event == 'Update') {
        this.updateVehicle(result.data.id, result.data);
        this.getAllVehicle;
      } else if (result.event == 'Delete') {
        this.deleteVehicle(result.data.id);
        this.getAllVehicle;
      }
    });
  }

  getAllVehicle() {
    this.masterData.getypeOfVehiclePageable(this.page, this.size).subscribe(
      (response) => {
        console.log(response);
        if (response.content != null) {
          this.dataSource = new MatTableDataSource(response.content);
          this.dataSource.sort = this.sort;
          this.length = response.totalElements;
        }
      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      }
    )
  }

  addVehicle(data) {
    this.masterData.addVehicleType(data).subscribe(
      (response) => {
        console.log(response);
        Swal.fire({
          titleText: "Warning",
          icon: "warning",
          text: response.message,
          timer: 5000
        });
        this.getAllVehicle;
      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      })
  }

  updateVehicle(id, data) {
    this.masterData.updateVehicleType(id, data).subscribe(
      (response) => {
        console.log(response);
        Swal.fire({
          titleText: "Warning",
          icon: "warning",
          text: response.message,
          timer: 5000
        });
        this.getAllVehicle;
      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      })
  }

  deleteVehicle(id) {
    this.masterData.deleteVehicleType(id).subscribe(
      (response) => {
        console.log(response);
        Swal.fire({
          titleText: "Warning",
          icon: "warning",
          text: response.message,
          timer: 5000
        });
        this.getAllVehicle;
      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
    this.serchFilter = true;
    this.nodata = false;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }
  
  onPageChange(event: PageEvent) {
    this.size = event.pageSize;
    this.page = event.pageIndex;
    this.getAllVehicle();
  }

  getCurrentUserDataFromSessionStorage(){
    // create auth code
    // RB0000112
    // update auth code
    // RB0000113
    // delete auth code
    // RB0000116
      this.sessionStorageUserData = JSON.parse(sessionStorage.getItem('userData'));
      console.log('this is user data ', this.sessionStorageUserData);
      for (let index = 0; index < this.sessionStorageUserData.authorities.length; index++) {
        const authority = this.sessionStorageUserData.authorities[index];
        console.log(authority);
        if(authority == 'RB0000112'){
          console.log('create authority is given');
          this.disableCreateBtn = false
        } else if(authority == 'RB0000113'){
          console.log('update authority is given');
          this.disableEditBtn = false
        } else if(authority == 'RB0000116') {
          console.log('delete authority is given');
          this.disableDeleteBtn = false
        }
      }
    }
}
