import { MatSort } from '@angular/material/sort';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { UserService } from '../helpers/user.service';
import { SocketService } from '../helpers/socket.service';


@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  page: number = 0;
  size: number = 25;
  displayedColumns: string[]= ['emailId', 'fname', 'lname' , 'expiryDate', 'action'];
  dataSource = new MatTableDataSource();
  userListLength: number;
  serchFilter: Boolean = false;
  nodata: Boolean = true;


  @ViewChild(MatPaginator) paginator:MatPaginator;
  @ViewChild(MatSort) sort:MatSort;


  constructor(private router: Router, private userService: UserService, private socketService: SocketService) { }

  ngOnInit(): void {
    this.getAllUser();
    // this.socketService.connectTOServerPopUpFuction();
  }

  getAllUser(){
    this.userService.getAllUsers(this.page, this.size).subscribe(
      (response) => {
        console.log(response);
        this.dataSource = new MatTableDataSource(response.content);
        this.dataSource.sort = this.sort;
        this.userListLength = response.totalElements;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  userCreateRoute(){
    this.router.navigate(['v1/user/create']);
  }

  applyFilter(filterValue:string){
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.nodata = false;
    this.serchFilter = true;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.dataSource.sort = this.sort;
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  onPageChange(event: PageEvent) {
    this.size = event.pageSize;
    this.page = event.pageIndex;
    this.getAllUser();
  }

  editUser(id: number){
    this.router.navigate(['v1/user/edit', id]);
  }

}
