import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  FormArray,
} from '@angular/forms';
import { Router } from '@angular/router';
import { CountryCode, CountryCodes } from '../helpers/country_code_picker';
import { DemoService } from '../helpers/demo.service';
import Swal from 'sweetalert2';
import { MasterTableService } from '../helpers/master-table.service';

@Component({
  selector: 'app-request-demo',
  templateUrl: './request-demo.component.html',
  styleUrls: ['./request-demo.component.css'],
})
export class RequestDemoComponent implements OnInit {
  public companyForm!: FormGroup;
  public companyRegionalAddress!: FormGroup;
  public companyHeadOfficeAddress!: FormGroup;
  public pointOfContactDetailForm!: FormGroup;
  public countryCodes: Array<any> = [];

  copyAddress: boolean = false;
  constructor(
    private form: FormBuilder,
    private demoService: DemoService,
    private router: Router,
    private masterTable: MasterTableService
  ) {}

  ngOnInit(): void {
    this.companyForm = this.form.group({
      companyName: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(100)]),
      ],
      companyWebsite: [''],
      regionalOfficeAddress: this.form.group({
        street1: [''],
        street2: [''],
        city: [''],
        state: [''],
        country: this.form.group({
          id: [''],
        }),
        zipCode: [''],
        label: ['Regional Office'],
      }),
      headOfficeAddress: this.form.group({
        street1: [''],
        street2: [''],
        city: [''],
        state: [''],
        country: this.form.group({
          id: [''],
        }),
        zipCode: [''],
        label: ['Head Office'],
      }),
      pocFirstName: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(100)]),
      ],
      pocLastName: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(100)]),
      ],
      pocEmailId: [
        '',
        Validators.compose([Validators.required, Validators.email]),
      ],
      pocDesignation: [''],
      pocContactNumber: ['', Validators.required],
    });

    this.gotoTop();
    this.getAllCountry();
  }

  get company() {
    return this.companyForm.controls;
  }
  get regionalAddressCompany() {
    return this.companyForm
      .get('regionalOfficeAddress')
      .get('country') as FormGroup;
  }
  get headAddressCompany() {
    return this.companyForm
      .get('headOfficeAddress')
      .get('country') as FormGroup;
  }

  get regionalOfficeAddress() {
    return this.companyForm.get('regionalOfficeAddress') as FormGroup;
  }
  get headOfficeAddress() {
    return this.companyForm.get('headOfficeAddress') as FormGroup;
  }

  demoDetails() {
    if (this.companyForm.valid) {
      if (this.regionalAddressCompany.get('id').value == '') {
        console.log('Remove');
        this.regionalOfficeAddress.removeControl('country');
      }
      if (this.headAddressCompany.get('id').value == '') {
        console.log('Remove');
        this.headOfficeAddress.removeControl('country');
      }
      console.log(this.companyForm.value);
      this.demoService.requestDemo(this.companyForm.getRawValue()).subscribe(
        (response) => {
          if (response.code == 201) {
            Swal.fire({
              titleText: 'Success',
              text: response.message,
              icon: 'success',
              showConfirmButton: true,
              confirmButtonColor: 'btn btn-info',
              timer: 5000,
            });
            this.router.navigate(['/home']);
          }
        },
        (error) => {
          let _error = error.error;
          if (_error.errorCode == 412) {
            Swal.fire({
              titleText: 'Error',
              text: _error.errorMessage,
              icon: 'error',
              showConfirmButton: true,
              timer: 5000,
            });
          } else if (_error.errorCode == 400) {
            Swal.fire({
              titleText: 'Error',
              text: _error.additionalData.pocContactNumber,
              icon: 'error',
              showConfirmButton: true,
              timer: 5000,
            });
          } else {
            Swal.fire({
              titleText: 'Error',
              text: 'Something went wrong!',
              icon: 'error',
              showConfirmButton: true,
              timer: 5000,
            });
          }
        },
        () => {}
      );
    } else {
      this.validateAllFormFields(this.companyForm);
      Swal.fire({
        titleText: 'Warning',
        icon: 'warning',
        text: 'Please fill all the compulsory fields',
        timer: 5000,
        confirmButtonColor: 'btn btn-sucess',
      });
    }
    // }
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.company;
    for (const name in controls) {
      console.log(name + ' is :: ' + controls[name].invalid);
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    if (this.regionalAddressCompany.get('id').value == '') {
      console.log('Remove');
      this.regionalOfficeAddress.removeControl('country');
    } else {
      console.log('keep');
      console.log(this.regionalAddressCompany.get('id').value);
    }

    if (this.headAddressCompany.get('id').value == '') {
      console.log('Remove');
      this.headOfficeAddress.removeControl('country');
    } else {
      console.log('keep');
      console.log(this.headAddressCompany.get('id').value);
    }
    return invalid;
  }

  //fuction to scroll to top on next button click
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  getAllCountry() {
    this.masterTable.getAllCountriesUnauthenticated().subscribe(
      (response) => {
        this.countryCodes = response;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onCopyAddressSelect() {
    console.log('this is value of copy address ', this.copyAddress);
    if (this.copyAddress) {
      this.headOfficeAddress
        .get('street1')
        ?.patchValue(this.regionalOfficeAddress.get('street1')?.value);
      this.headOfficeAddress
        .get('street2')
        ?.patchValue(this.regionalOfficeAddress.get('street2')?.value);
      this.headOfficeAddress
        .get('city')
        ?.patchValue(this.regionalOfficeAddress.get('city')?.value);
      this.headOfficeAddress
        .get('state')
        ?.patchValue(this.regionalOfficeAddress.get('state')?.value);
      this.headOfficeAddress
        .get('zipCode')
        ?.patchValue(this.regionalOfficeAddress.get('zipCode')?.value);
      this.headAddressCompany
        .get('id')
        ?.patchValue(
          this.regionalOfficeAddress.get('country')?.get('id').value
        );
      this.headOfficeAddress.disable();
    } else {
      this.headOfficeAddress.enable();
      this.headOfficeAddress.get('street1')?.setValue('');
      this.headOfficeAddress.get('street2')?.setValue('');
      this.headOfficeAddress.get('city')?.setValue('');
      this.headOfficeAddress.get('state')?.setValue('');
      this.headOfficeAddress.get('zipCode')?.setValue('');
      this.headAddressCompany
        .get('id')?.setValue('')
    }
  }

  validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      } else if (control instanceof FormArray) {
        for (const araControl of control.controls) {
          if (araControl instanceof FormGroup) {
            this.validateAllFormFields(araControl);
          }
        }
      }
    });
  }
}
