import { Component, Inject, NgModule, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-bid-vendor',
  templateUrl: './edit-bid-vendor.component.html',
  styleUrls: ['./edit-bid-vendor.component.css']
})
export class EditBidVendorComponent implements OnInit {

  local_data: any;
  action: string;

  editBidForm: FormGroup;

  bidValue: number = 0;
  previousBidValue: number = 0;

  constructor(public dialogRef: MatDialogRef<EditBidVendorComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
  }

  ngOnInit(): void {
    console.log(this.local_data);
    this.bidValue = this.local_data.bid.bidValue;
    this.previousBidValue = this.bidValue;
    this.editBidForm = this.fb.group({
      auctionValue: ['', Validators.required],
      reason: ['', Validators.required],
      id: ['']
    });
    this.editBidForm.get('auctionValue').patchValue(this.bidValue);
    this.editBidForm.get('id').patchValue(this.local_data.bid.id);
  }

  get bidForm() {
    return this.editBidForm.controls;
  }

  doAction() {
    let auctionValue = this.bidForm.auctionValue.value
    if (!this.editBidForm.valid) {
      Swal.fire({
        title: "Warning",
        icon: 'warning',
        titleText: "Please Provide mandatory Field",
        timer: 5000
      });
    }
    else if (auctionValue < this.previousBidValue) {
      Swal.fire({
        icon: "warning",
        timer: 5000,
        title: "Warning",
        text: "Bid Value cannot be decreased but only increased."
      });
    }
    else {
      this.dialogRef.close({ event: this.action, data: this.editBidForm.value });
    }
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }
}
