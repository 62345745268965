<section>
    <div class="row mt-3 pt-4 pl-4 pr-4">
        <div class="col-md-4">
            <h4>ORG Name :</h4>
            <p><b>{{ local_data.name }}</b></p>
        </div>
        <div class="col-md-4">
            <h4>Website :</h4>
            <p><b>{{ local_data?.website }}</b></p>
        </div>
        <div class="col-md-4">
            <h4>Onboarding Status :</h4>
            <p><b>{{ local_data?.onboardingStatus }}</b></p>
        </div>

        <div class="col-md-4">
            <h4>First Name :</h4>
            <p><b>{{ local_data?.pocFirstName }}</b></p>
        </div>

        <div class="col-md-4">
            <h4>Last Name :</h4>
            <p><b>{{ local_data?.pocLastName }}</b></p>
        </div>

        <div class="col-md-4">
            <h4>EmailId :</h4>
            <p><b>{{ local_data.pocEmailId }}</b></p>
        </div>

        <div class="col-md-4">
            <h4>Contact Number : </h4>
            <p><b>{{ local_data.pocContactNumber }}</b></p>
        </div>

        <div class="col-md-4">
            <h4>Designation :</h4>
            <p><b>{{ local_data?.pocDesignation }}</b></p>
        </div>
    </div>
    <br>
    <div *ngIf="addressBool" class="mt-3 mb-4 pt-4 pl-4 pr-4">
        <div class="row" *ngFor="let add of address">
            <div class="col-lg-12">
                <h4>{{ add.label }} Address :</h4>
            </div>

            <div class="col-md-4">
                <h4>Street 1 : </h4>
                <p><b> {{ add?.street1 }} </b></p>
            </div>

            <div class="col-md-4">
                <h4>Street 2 : </h4>
                <p><b>{{ add?.street2 }}</b></p>
            </div>

            <div class="col-md-4">
                <h4>City : </h4>
                <p><b>{{ add?.city}}</b></p>
            </div>

            <div class="col-md-4">
                <h4>State : </h4>
                <p><b>{{ add?.state }}</b></p>
            </div>

            <div class="col-md-4">
                <h4>Country : </h4>
                <p><b>{{ add?.country?.name }}</b></p>
            </div>

            <div class="col-md-4">
                <h4>ZipCode : </h4>
                <p><b>{{ add?.zipCode }}</b></p>
            </div>
        </div>
    </div>

    <div *ngIf="escalationsBool" class="table-responsive mt-3 mb-4 pt-4 pl-4 pr-4">
        <h4>Escalations</h4>
        <table class="table table-striped table-footer-dark">
            <thead>
                <th>Sr No.</th>
                <th>Name</th>
                <th>Designation</th>
                <th>EmailId</th>
                <th>Contact Number</th>
            </thead>
            <tbody>
                <tr *ngFor="let esc of escalations; index as i">
                    <td>{{ i + 1 }}</td>
                    <td>{{ esc.firstName }} {{ esc.lastName }} </td>
                    <td>{{ esc.designation }}</td>
                    <td>{{ esc.emailId }}</td>
                    <td>{{ esc.contactNumber }}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div *ngIf="artifactsBool" class="table-responsive mt-3 mb-4 pt-4 pl-4 pr-4">
        <h4>Artifacts</h4>
        <table class="table table-striped table-footer-dark">
            <thead>
                <th>Sr No.</th>
                <th>File Name</th>
                <th>Actions</th>
            </thead>
            <tbody>
                <tr *ngFor="let arti of artifacts; index as i">
                    <td>{{ i + 1 }}</td>
                    <td>{{ arti.label }}</td>
                    <td>
                        <button (click)="downloadFileLocal(arti)">
                            <i class="fa fa-download"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="row mt-3 pt-4 pl-4 pr-4">
        <div class="col-md-4">
            <h4>Business Type :</h4>
            <p><b>{{ local_data.businessType?.value || '----' }}</b></p>
        </div>
        <div class="col-md-4">
            <h4>Established Date :</h4>
            <p><b>{{ (local_data.establishedOn | date) || '----' }}</b></p>
        </div>
        <div class="col-md-4">
            <h4>Gross Annual Turnover :</h4>
            <p><b>{{ local_data.grossAnnualTurnOver || '----' }}</b></p>
        </div>
        <div class="col-md-4">
            <h4>Currency :</h4>
            <p><b>{{ local_data.grossAnnualTurnOverCurrency?.currency || '----' }}</b></p>
        </div>
        <div class="col-md-4">
            <h4>GeoService Area :</h4>
            <p><b>{{ local_data.geoServiceArea || '----' }}</b></p>
        </div>
        <div class="col-md-4">
            <h4>Details Of Goods And Services :</h4>
            <p><b>{{ local_data.detailsOfGoodsAndServices || '----' }}</b></p>
        </div>
        <div class="col-md-4">
            <h4>Bonded :</h4>
            <p>
                <span *ngIf="local_data.bonded == true; else bondedFalse">
                    <b>YES</b>
                </span>
                <ng-template #bondedFalse>
                    <b>NO</b>
                </ng-template>
            </p>
        </div>
        <div class="col-md-4">
            <h4>Legal Structure :</h4>
            <p><b>{{ local_data.legalStructure || '----' }}</b></p>
        </div>
        <div class="col-md-4">
            <h4>Licensed :</h4>
            <span *ngIf="local_data.licensed == true; else licensedFalse">
                <b>YES</b>
            </span>
            <ng-template #licensedFalse>
                <b>NO</b>
            </ng-template>
        </div>
        <div *ngIf="local_data.licensed == true" class="col-md-4">
            <h4>License Number :</h4>
            <p><b>{{ local_data.licenseNumber || '----' }}</b></p>
        </div>
        <div class="col-md-4">
            <h4>Name :</h4>
            <p><b>{{ local_data.certifiedByName || '----' }}</b></p>
        </div>
        <div class="col-md-4">
            <h4>Designation :</h4>
            <p><b>{{ local_data.certifiedByDesignation || '----' }}</b></p>
        </div>
        <div class="col-md-4">
            <h4>Certified On :</h4>
            <p><b>{{ (local_data.certifiedOn | date) || '----' }}</b></p>
        </div>
    </div>
</section>
<div mat-dialog-actions class="justify-content-center">
    <button mat-button class="btn-success" (click)="closeDialog()" mat-flat-button color="warn">Close</button>
</div>