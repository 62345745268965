<h1 mat-dialog-title><strong>{{action}}</strong></h1>
<div mat-dialog-content>
    <form *ngIf="action != 'Delete'; else elseBlock" [formGroup]="dialogform">
        <mat-form-field>
            <input placeholder="Code" matInput [required]="isrequired" formControlName="code" [readonly]="isReadOnly">
        </mat-form-field>
        <mat-form-field>
            <input placeholder="Value" matInput required formControlName="value">
        </mat-form-field>
        <div>
            <label for="">Nature of Movement</label>
            <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="natureOfMovementList"
                [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)"
                (onSelectAll)="onSelectAll($event)" (onDeSelect)="onDeSelect($event)" (onDeSelectAll)="onDeSelectAll()">
            </ng-multiselect-dropdown>
        </div>
    </form>
    <ng-template #elseBlock>
        Sure to delete <b>{{local_data.value}}</b>?
    </ng-template>
</div>
<div mat-dialog-actions>
    <button style="margin-right: 20px;" class="btn-primary" mat-button (click)="doAction()">{{action}}</button>
    <button class="btn-danger" mat-button (click)="closeDialog()" mat-flat-button color="warn">Cancel</button>
</div>