import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CountryCode, CountryCodes } from 'src/app/helpers/country_code_picker';
import { InvoiceService } from 'src/app/helpers/invoice.service';
import { MasterTableService } from 'src/app/helpers/master-table.service';
import { SocketService } from 'src/app/helpers/socket.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-invoice-create',
  templateUrl: './invoice-create.component.html',
  styleUrls: ['./invoice-create.component.css']
})
export class InvoiceCreateComponent implements OnInit {

  public invoiceForm: FormGroup;
  isEdit: Boolean = false
  isInvoiceCreation: false
  disabled: true
  countryCodes: Array<any> = [];
  originDestinationPorts: Array<any> = []
  modeOfShipment: Array<any> = []
  currency: Array<any> = []
  templateId: any
  userData: any
  searchOriginValue: any;
  searchDestinationValue: any;
  loadingPorts: Array<any> = [];
  destinationPorts: Array<any> = [];
  serviceOrderList: Array<any> = [];
  partyLabel: Array<any> = ['Sez Unit At', 'Exporter', 'Applicant', 'Notify']
  uom: Array<any> = [{ name: "Nos" },{ name: "Lot" }];
  unitOfWeight:Array<any> = [{ name: "KG" },{ name: "LBS" },{ name: "MT" }];
  dimensions: Array<any> = [{name: "MM"},{name: "CM"},{name: "IN"},{name: "FT"},{name: "MTR"}];
  containsInPackagesUOM: Array<any> = [{name: "Nos"}, {name: "Lot"}, {name: "SET"}];
  partyTypes: Array<string> = ['EXPORTER','APPLICANT','SEZ_UNIT_AT','NOTIFY'];
  preShipmentInvoiceList: Array<any> = [];
  dropdownSettings: IDropdownSettings = {};
  selectedItems: Array<any> = [];
  multiplePreShipmentInvoice: Array<any> = [];
  commericialInvoiceObj: any = {};
  typeOfPacking: Array<any> = [];

  additionalInfoFields: Array<any> = [];
  additionalBankFields: Array<any> = [];
  addressList: Array<any> = []
  addLessArray: Array<any> = ['LESS', 'ADD'];
  percentageAmountArray: Array<any> = ['PERCENTAGE', 'AMOUNT'];
  totalAdditionalCharges: number;

  invId: any
  samePortError: boolean = false

  constructor(private fb: FormBuilder, private invoiceservice: InvoiceService, private route: Router, private router: ActivatedRoute, private masterTableService: MasterTableService, private socketService: SocketService) { }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'number',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true
    };
    this.userData = JSON.parse(sessionStorage.getItem('userData'));

    //routing section
    let id = this.router.snapshot.paramMap.get('id')
    if (id != null) {
      this.invId = id;
      console.log('invoice');
      this.getInvoiceById(this.invId)
    }
    this.invoiceForm = this.fb.group({
      additionalCharges: this.fb.array([]),   // spelling mistake 
      additionalInformation: this.fb.array([]),
      bankDetail: this.fb.array([]),
      containerNo: [''],
      countryOfFinalDestination: [''],
      countryOfOriginOfGoods: [''],
      descriptionOfSupplies: [''],
      kindOfPackages: [''],
      netWeight: [''],
      notes: [''],
      otherRef: this.fb.array([]),
      projectName: [''],
      projectCode:[''],
      shippingMarks: this.fb.group({
          caseNumber:[''],
          shippingFrom:[''],
          shippingTo:[''],
          grossWeight:[''],
          netWeight:[''],
      }),
      parties: this.fb.array([]),
      buyersReference: [''],
      vesselVoyageFlightDetails: [''],
      terms: this.fb.array([]),
      preCarriageBy: [''],
      portOfLoading: [''],
      portOfDischarge: [''],
      placeOfReceiptByPreCarrier: [''],
      placeOfDelivery: [''],
      packingList: this.fb.group({
        unitOfWeight: [''],
        packingListRows: this.fb.array([]),
        grossWeightTotal: [''],
        netWeightTotal: ['']
      }),
      orderValue: [''],
      invoiceLine: this.fb.array([]),
      exportersReference: [''],
      declaration: [''], 
      currency: [''],
      annexurePackingList: this.fb.group({
        unitOfWeight:[''],
				dimensionsOfPackage:[''],
				cbmTotal:[''],
				quantityTotal:[''],
				netWeightTotal:[''],
				grossWeightTotal:[''],
        annexurePakingListRows: this.fb.array([])
      }),
      serviceOrder: this.fb.group({
        id: ['', Validators.required]  
      }),
      preshipmentInvoices: this.fb.array([]),
      number:  [],
      totalAmount: ['0'], // not at backend. left to map at html
      totalDueAmount: ['0'],
      modeOfTransport: [null]
    });
    this.getMasterTablesSessionStorageData();
    this.getUserAddresses();
    // this.socketService.connectTOServerPopUpFuction();
  }


  get invoice() {
    return this.invoiceForm.controls
  }

  get otherRef() {
    return this.invoiceForm.get('otherRef') as FormArray;
  }

  get parties() {
    return this.invoiceForm.get('parties') as FormArray;
  }

  get invoiceLine() {
    return this.invoiceForm.get('invoiceLine') as FormArray;
  }

  get shippingMarks() {
    return this.invoiceForm.get('shippingMarks') as FormGroup;
  }

  get terms() {
    return this.invoiceForm.get('terms') as FormArray;
  }

  get bankDetail() {
    return this.invoiceForm.get('bankDetail') as FormArray;
  }

  get additionalInformation() {
    return this.invoiceForm.get('additionalInformation') as FormArray;
  }

  get serviceOrder(){
    return this.invoiceForm.get('serviceOrder') as FormGroup;
  }

  get packingList() { return this.invoice.packingList as FormGroup; }
  get packingListRows() { return this.packingList.get('packingListRows') as FormArray; }

  get annexurePackingList(){return this.invoice.annexurePackingList as FormGroup; }
  get annexurePackingListRow() { return this.annexurePackingList.get('annexurePakingListRows') as FormArray; }

  get preshipmentInvoices(){return this.invoiceForm.get('preshipmentInvoices') as FormArray; }

  get additionalCharges(){return this.invoiceForm.get('additionalCharges') as FormArray; }

  containtsInPackages(index: number){ return this.annexurePackingListRow.at(index).get('containtsInPackages') as FormArray; }


  addAdditionalCharges(){
    this.additionalCharges.push(this.initAdditionalCharges());
  }

  removeAdditionalCharges(i: number){
    this.additionalCharges.removeAt(i);
    this.calculateAdditionalCharges();
  }

  initAdditionalCharges(){
    return this.fb.group({
      description:[''],
      addOrLess:[''],
      percentageOrAmount:[],
      percentageOrAmountValue:[0],
      amount:[]
    });
  }

  initPreShipmentInvoice(id){
    return this.fb.group({
      id: [id]
    })
  }
  
  // to hide side bar
  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  //this will return the todays date
  getTodayDate() {
    return new Date().toISOString().split('T')[0]
  }

  //for adding alternate other reference
  addAlternateOtherRef() {
    let newRef = this.fb.group({
      otherReference: [''],
    })
    this.otherRef.push(newRef);
  }
  removeAlternateOtherRef(i: number) {
    this.otherRef.removeAt(i);
  }
  patchOtherRef(response: any) {
    return this.fb.group({
      otherReference: [response.otherReference]
    })
  }
  //to display one default other ref input
  defaultOneOtherRef() {
    return this.fb.group({
      otherReference: '',
    })
  }

  //to add new party details
  addNewParty() {
    let group = this.fb.group({
      label: 'Enter label',
      partyType: '',
      name: '',
      email: '',
      contactNumbers: this.fb.array([this.defaultOneContactNo()]),
      address: this.fb.group({
        city: [''],
        country: this.fb.group({
          id: ['']
        }),
        label: [''],
        state: [''],
        street1: [''],
        street2: [''],
        zipCode: ['']
      })
    });
    this.parties.push(group)
  }

  //to remove any party details
  removeParty(i: number) {
    this.parties.removeAt(i);
  }

  defaultOneContactNo() {
    return this.fb.group({
      contacts: ['']
    })
  }

  // fuction to get contact number as formarray
  partyContactNo(index: number): FormArray {
    return this.parties.at(index).get("contactNumbers") as FormArray
  }

  //to add new contact no
  addNewContactNo(partyInd: number) {
    this.partyContactNo(partyInd).push(this.defaultOneContactNo())
  }

  // to remove contact number
  removeContactNo(index: number, ind: number) {
    this.partyContactNo(index).removeAt(ind)
  }

  //to get data from mastertabled / session storage
  getMasterTablesSessionStorageData() {
    this.getModeOfShipment();
    this.getOriginDestination();
    this.getCurrency();
    this.getCountry();
    this.getServiceOrderList();
    this.getTypeOfPacking();
  }

  getCountry(){
    this.masterTableService.getAllCountries().subscribe(
      (response) => {
        this.countryCodes = response
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getModeOfShipment(){
    this.masterTableService.getModeOfShipment().subscribe(
      (response) => {
        this.modeOfShipment = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getOriginDestination(){
    this.masterTableService.getLoadingDestinationPort().subscribe(
      (response) => {
        this.originDestinationPorts = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getCurrency(){
    this.masterTableService.getAllCurrency().subscribe(
      (response) => {
        this.currency = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getServiceOrderList(){
    this.invoiceservice.getServiceOrderListForCommercialInvoice().subscribe(
      (response) => {
        this.serviceOrderList = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getTypeOfPacking(){
    this.masterTableService.getAllTypeOfPacking().subscribe(
      (response) => {
        this.typeOfPacking = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  // to show selected currecy in package detail table
  currencyValue() {
    return this.invoice.currency.value
  }

  //for adding multiple rows in table
  addNewRow() {
    let newItem = this.fb.group({
      descriptionOfGoods: [''],
      tagNumber: [''],
      hsCode: [''],
      packageNumber: [''],
      uom: [''],
      amount: [0],
      quantity: [0],
      noOfPackages: [0]
    })
    this.invoiceLine.push(newItem);
  }
  removeItemRow(i: number) {
    this.invoiceLine.removeAt(i);
    this.calculateTotalAmount();
  }

  //to calculate total amount in package details total
  calculateTotalAmount() {
    let totalAmount = 0;
    for (let index = 0; index < this.invoiceLine.controls.length; index++) {
      const item = this.invoiceLine.controls[index];
      let amount = item.get('amount').value
      totalAmount = totalAmount + amount;
    }
    this.invoiceForm.get('totalAmount').patchValue(totalAmount);
    return totalAmount
  }

  // used to add new terms
  addNewTerms() {
    let group = this.fb.group({
      label: 'Enter Label',
      content: ''
    });
    this.terms.push(group)
  }
  // used to remove one terms by index
  removeTerms(i: number) {
    this.terms.removeAt(i);
  }

  //adding additional Info fields
  addNewField() {
    this.additionalInformation.push(this.initAdditionalInformationField());
  }

  initAdditionalInformationField(){
    return this.fb.group({
      label: [''],
      value: ['']
    })
  }

  removeAdditionalField(i: number) {
    this.additionalInformation.removeAt(i);
  }

  initBankField(){
    return this.fb.group({
      label: [''],
      value: ['']
    })
  }
  //adding additional bank Fields
  addNewBankField() {
    this.bankDetail.push(this.initBankField())
  }

  removeAdditionalBankFields(i: number) {
    this.bankDetail.removeAt(i);
  }

  initInvoiceLine(){
    return this.fb.group({
      amount:['0'],
			packageNumber:[''],
      descriptionOfGoods:[''],
      hsCode:[''],
      noOfPackages:['0'],
      tagNumber:[''],
      uom:[''],
    })
  }

  initPackingListRow(){
    return this.fb.group({
      packageNumber: [''],
      description: [''],
      tagNumber: [''],
      hsCode: [''],
      quantityPackages: [''],
      netWeight: [''],
      grossWeight: [''],
    })
  }


  addPackingListRow(){
    return this.packingListRows.push(this.initPackingListRow());
  }
  removePackingListRow(index){
    this.packingListRows.removeAt(index);
  }

  addInvoiceLine(){
    this.addPackingListRow();
    this.addAnnexurePakingListRow();
    return this.invoiceLine.push(this.initInvoiceLine());
  }

  initAnnexurePakingListRow(){
    return this.fb.group({
      packageNumber:[''],
      length:[''],
      width:[''],
      height:[''],
      cbm:[''],
      typeOfPacking:[''],
      netWeight:[''],
      grossWeight:[''],
      containtsInPackages: this.fb.array([this.initContaintsInPackages()])
    });
  }

  addAnnexurePakingListRow(){
    this.annexurePackingListRow.push(this.initAnnexurePakingListRow());
  }
  removeAnnexurePakingListRow(index){
    this.annexurePackingListRow.removeAt(index);
  }

  initContaintsInPackages(){
    return this.fb.group({
      description:[''],
      tagNo:[''],
      uom:[''],
      quantity:[''],
    })
  }

  addContaintsInPackage(index){
    return this.containtsInPackages(index).push(this.initContaintsInPackages());
  }

  removeContainsInPackage(index, p){
    return this.containtsInPackages(index).removeAt(p);
  }

  changeUnitOfWeight(){
    let unitOfWeight = this.packingList.get('unitOfWeight').value;
    this.annexurePackingList.get('unitOfWeight').patchValue(unitOfWeight);
  }

  //API to create invoice
  invoiceCreate() {
    if (this.samePortError) {
      Swal.fire({
        title: "Warning",
        icon: "warning",
        timer: 5000,
        text: "Loading & Destination Port cannot be same"
      })
    } else {
      this.invoiceservice.createCommercialInvoice(this.invoiceForm.getRawValue()).subscribe(
        (response) => {
          if(response.code == 201){
            Swal.fire({
              title: 'Success',
              text: response.message,
              timer: 5000,
              icon: 'success'
            })
            this.route.navigateByUrl('/v1/commerical-invoice/listing');
          }
        },
        (error) => {
          console.log(error);
        },
      )
    }
  }

  //API to update invoice
  updateInvoiceById() {
    this.invoiceservice.updateCommercialInvoice(this.invId, this.invoiceForm.getRawValue()).subscribe
      ((response) => {
        console.log(response);
        if(response.code == 201){
          Swal.fire({
            title: 'Success',
            text: response.message,
            timer: 5000,
            icon: 'success'
          })
          this.route.navigateByUrl('/v1/commerical-invoice/listing');
        }
      },
        (error) => {
          console.log(error);
          Swal.fire({
            title: 'Invalid',
            text: error.message,
            timer: 5000,
            icon: 'error'
          })
        })
  }

  //update invoice process to get invoice by ID
  getInvoiceById(invId: any) {
    this.invoiceservice.getCommercialInvoiceById(invId).subscribe
      ((response) => {
        this.isEdit = true
        this.commericialInvoiceObj = response;
        console.log(this.commericialInvoiceObj);
        this.invoiceForm.get('number').patchValue(response.number);
        this.serviceOrder.get('id').patchValue(response.serviceOrder.id);
        this.selectedServiceOrder();
        let preshipmentInvoices: Array<any> = response.preshipmentInvoices;
        for (let index = 0; index < preshipmentInvoices.length; index++) {
          const element = preshipmentInvoices[index];
          this.preshipmentInvoices.push(this.initPreShipmentInvoice(element.id));
        }
        this.selectedItems = response.preshipmentInvoices;
        this.populateCommericailInvoiceFields();
        this.populateInvoiceLine(response.invoiceLine);
        this.populatePackingList(response.packingList.packingListRows);
        this.populateAnnexurePackingList(response.annexurePackingList.annexurePakingListRows);
        this.populateAdditionalCharges(response.additionalCharges);
      },
        (error) => {
          console.log(error);
    })
  }

  //to get all addresses
  getUserAddresses() {
    this.invoiceservice.getUserAddressDetails().subscribe(
      (response) => {
        this.addressList = response;
      },
      (error) => {
        console.log(error);
      });
  }

  // to patch selected address to address section
  selectAddress(event: any, i: number) {
    let selectedAddressId = event.target.value
    this.addressList.filter((address) => {
      if (address.id == selectedAddressId) {
        let partyArray = this.invoiceForm.get('parties') as FormArray;
        let addressGroup = partyArray.at(i).get('address') as FormGroup;
        addressGroup.get('street1').patchValue(address.street1)
        addressGroup.get('street2').patchValue(address.street2)
        addressGroup.get('city').patchValue(address.city)
        addressGroup.get('state').patchValue(address.state)
        addressGroup.get('country').patchValue(address.country)
        addressGroup.get('zipCode').patchValue(address.zipCode)
      }
    })
  }

  //to validation if selected same origin and destination port
  samePortValidation() {
    let lPort = this.invoice.portOfLoading.value
    let dPort = this.invoice.portOfDischarge.value
    if (lPort == dPort) {
      this.samePortError = true;
      Swal.fire({
        title: "Warning",
        icon: "warning",
        timer: 5000,
        text: "Loading & Destination Port cannot be same"
      })
    } else {
      this.samePortError = false
    }
  }

  getSearchResultsOrigin(){
    let modeOfShipmentValue = this.invoice.modeOfTransport.value
    if(modeOfShipmentValue != null || undefined){
      let mode = this.modeOfShipment.find((x) => {
        if(x.value == modeOfShipmentValue){
          return x
        }
      });
      let size = 50
      if(this.searchOriginValue != ""){
        this.masterTableService.getSearchPort(mode.id, this.searchOriginValue, size).subscribe((res)=>{
          this.loadingPorts = res
        },
        (error)=>{
          console.log(error);
        })
      } else {
        console.log('search value is empty ', this.searchOriginValue);
      }
    }
  }

  getSearchResultDestination(){
    let modeOfShipmentValue = this.invoice.modeOfTransport.value
    if(modeOfShipmentValue != null || undefined){
      let mode = this.modeOfShipment.find((x) => {
        if(x.value == modeOfShipmentValue){
          return x
        }
      });
      let size = 50
      if(this.searchDestinationValue != ""){
        this.masterTableService.getSearchPort(mode.id, this.searchDestinationValue, size).subscribe((res)=>{
          this.destinationPorts = res
        },
        (error)=>{
          console.log(error);
        })
      } else {
        console.log('search value is empty ', this.searchDestinationValue);
      }
    }
  }

  selectedServiceOrder(){
    let serviceOrderId = this.serviceOrder.get('id').value;
    if(serviceOrderId != null || undefined){
      this.invoiceservice.getPreShipmentInvoiceByServiceOrderId(serviceOrderId).subscribe(
        (response) => {
          this.preShipmentInvoiceList = [];
          this.preShipmentInvoiceList = response;
        },
        (error) => {
          console.log(error);
        },
      )
    }
  }

  populatePackageNumber(index: number, controller: string){
    if(controller == "packageNumber"){
      let packageNumber = this.invoiceLine.at(index).get(controller).value;
      this.packingListRows.at(index).get(controller).patchValue(packageNumber);
      this.annexurePackingListRow.at(index).get(controller).patchValue(packageNumber);
    }
    else if(controller == "descriptionOfGoods"){
      let description = this.invoiceLine.at(index).get(controller).value;
      this.packingListRows.at(index).get('description').patchValue(description);
    }
    else if(controller == "tagNumber"){
      let tagNumber = this.invoiceLine.at(index).get(controller).value;
      this.packingListRows.at(index).get('tagNumber').patchValue(tagNumber);
    }
    else if(controller == "hsCode"){
      let hsCode = this.invoiceLine.at(index).get(controller).value;
      this.packingListRows.at(index).get('hsCode').patchValue(hsCode);
    }
    else if(controller == "noOfPackages"){
      let noOfPackages = this.invoiceLine.at(index).get(controller).value;
      this.packingListRows.at(index).get('quantityPackages').patchValue(noOfPackages);
      this.annexurePackingList.get('quantityTotal').patchValue(noOfPackages);
    }
  }

  calculatePackingListNetWeight(index?: number){
    let netWeight = this.packingListRows.at(index).get('netWeight').value;
    this.annexurePackingListRow.at(index).get('netWeight').patchValue(netWeight);
    let netWeightTotal = 0;
    for (let index = 0; index < this.packingListRows.length; index++) {
      let netWeight = this.packingListRows.at(index).get('netWeight').value;
      this.annexurePackingListRow.at(index).get('netWeight').patchValue(netWeight);
      netWeightTotal = netWeightTotal + netWeight;
    }
    this.packingList.get('netWeightTotal').patchValue(netWeightTotal);
    this.annexurePackingList.get('netWeightTotal').patchValue(netWeightTotal);
  }

  calculatePackingListGrossWeight(index?: number){
    let grossWeightTotal = 0;
    let grossWeight = this.packingListRows.at(index).get('grossWeight').value;
    this.annexurePackingListRow.at(index).get('grossWeight').patchValue(grossWeight);
    for (let index = 0; index < this.packingListRows.length; index++) {
      let grossWeight = this.packingListRows.at(index).get('grossWeight').value;
      grossWeightTotal = grossWeightTotal + grossWeight;
    }
    this.packingList.get('grossWeightTotal').patchValue(grossWeightTotal);
    this.annexurePackingList.get('grossWeightTotal').patchValue(grossWeightTotal);
  }

  calculateTotalCBM(){
    let totalCBM = 0.0;
    let quantity = this.annexurePackingList.get('quantityTotal').value;
    let dimensionsOfPackage = this.annexurePackingList.get('dimensionsOfPackage').value
    for (let index = 0; index < this.annexurePackingListRow.length; index++) {
      const element = this.annexurePackingListRow.at(index);
      let length = element.get('length').value;
      let height = element.get('height').value;
      let width = element.get('width').value;
      let totalVolume = (length * width * height) * quantity;
      let convert = this.convertdimensionsUOMtoMtr(dimensionsOfPackage, totalVolume); 
      element.get('cbm').patchValue(convert);
      totalCBM = totalCBM + convert
      this.annexurePackingList.get('cbmTotal').patchValue(totalCBM);
    }
  }

  convertdimensionsUOMtoMtr(dimensionsUOM, totalVolume) {
    let volume: number = 0.0;
    if (dimensionsUOM == "CM") {
      volume = (totalVolume / 1000000 );
    }
    else if (dimensionsUOM == "MM") {
      volume = (totalVolume / 1000000000 );
    }
    else if (dimensionsUOM == "IN") {
      volume = (totalVolume / 61020 );
    }
    else if (dimensionsUOM == "FT") {
      volume = (totalVolume / 35.315);
    }
    else {
      // mtr
      volume = totalVolume
    }
    return parseFloat(volume.toFixed(4));
  }

  onItemSelect(event) {
    this.preshipmentInvoices.push(this.initPreShipmentInvoice(event.id));
    this.fetchDataOfPreShipmentInvoices();
  }

  onSelectAll(event: Array<any>) {
    this.preshipmentInvoices.clear();
    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      this.preshipmentInvoices.push(this.initPreShipmentInvoice(element.id));
    }
    this.fetchDataOfPreShipmentInvoices();
  }

  onDeSelect(event) {
    let preshipmentInvoices: Array<any> = this.preshipmentInvoices.value;
    preshipmentInvoices.filter((element, index) => {
      if (element.id == event.id) {
        this.preshipmentInvoices.removeAt(index);
      }
    });
    this.fetchDataOfPreShipmentInvoices();
  }

  onDeSelectAll() {
    this.preshipmentInvoices.clear();
  }

  fetchDataOfPreShipmentInvoices(){
    let data = this.preshipmentInvoices.value;
    this.invoiceservice.getMultiplePreShipmentInvoices(data).subscribe(
      (response: Array<any>) => {
        if(response.length > 0){
          this.multiplePreShipmentInvoice = [];
          this.commericialInvoiceObj = {};
          this.multiplePreShipmentInvoice = response;
          this.commericialInvoiceObj = this.multiplePreShipmentInvoice[0];
          this.populateCommericailInvoiceFields();
        }
        this.populateDatafromPreShipmentArray();
      },
      (error) => {
        console.log(error);
      },
    )
  }

  populateDatafromPreShipmentArray(){
    this.packingListRows.clear();
    this.annexurePackingListRow.clear();
    this.invoiceLine.clear();
    let invoiceLine = [];
    let packingList = [];
    let annexurePackingList = [];
    for (let index = 0; index < this.multiplePreShipmentInvoice.length; index++) {
      const element = this.multiplePreShipmentInvoice[index];
      for (let index = 0; index < element.invoiceLine.length; index++) {
        const _element = element.invoiceLine[index];
        invoiceLine.push(_element)
      }

      for (let index = 0; index < element.packingList.packingListRows.length; index++) {
        const _element = element.packingList.packingListRows[index];
        packingList.push(_element)
      }

      for (let index = 0; index < element.annexurePackingList.annexurePakingListRows.length; index++) {
        const _element = element.annexurePackingList.annexurePakingListRows[index];
        annexurePackingList.push(_element)
      }
    }
    this.populateInvoiceLine(invoiceLine);
    this.populatePackingList(packingList);
    this.populateAnnexurePackingList(annexurePackingList);

  
  }

  populateInvoiceLine(invoiceLine: Array<any>){
    for (let index = 0; index < invoiceLine.length; index++) {
      const element = invoiceLine[index];
      this.addInvoiceLine();
      this.invoiceLine.at(index).get('descriptionOfGoods').patchValue(element.descriptionOfGoods)
      this.invoiceLine.at(index).get('tagNumber').patchValue(element.tagNumber)
      this.invoiceLine.at(index).get('hsCode').patchValue(element.hsCode)
      this.invoiceLine.at(index).get('packageNumber').patchValue(element.packageNumber)
      this.invoiceLine.at(index).get('uom').patchValue(element.uom)
      this.invoiceLine.at(index).get('amount').patchValue(element.amount)
      this.invoiceLine.at(index).get('noOfPackages').patchValue(element.noOfPackages)
      this.populatePackageNumber(index, "descriptionOfGoods");
      this.populatePackageNumber(index, "tagNumber");
      this.populatePackageNumber(index, "hsCode");
      this.populatePackageNumber(index, "packageNumber");
      this.populatePackageNumber(index, "noOfPackages");
    }
    this.calculateTotalAmount();
  }

  populatePackingList(packingList: Array<any>){
    for (let index = 0; index < packingList.length; index++) {
      const element = packingList[index];
      this.packingListRows.at(index).get('netWeight').patchValue(element.netWeight);
      this.packingListRows.at(index).get('grossWeight').patchValue(element.grossWeight);
      this.calculatePackingListNetWeight(index);
      this.calculatePackingListGrossWeight(index);
    }
    this.changeUnitOfWeight();
  }

  populateAnnexurePackingList(annexurePackingList: Array<any>){
    for (let index = 0; index < annexurePackingList.length; index++) {
      const element = annexurePackingList[index];
      this.annexurePackingListRow.at(index).get('length').patchValue(element.length);
      this.annexurePackingListRow.at(index).get('width').patchValue(element.width);
      this.annexurePackingListRow.at(index).get('height').patchValue(element.height);
      this.annexurePackingListRow.at(index).get('cbm').patchValue(element.cbm);
      this.annexurePackingListRow.at(index).get('typeOfPacking').patchValue(element.typeOfPacking);
      let containtsInPackages: Array<any> = element.containtsInPackages
      if(containtsInPackages.length > 0){
        for (let ind = 0; ind < containtsInPackages.length; ind++) {
          const element = containtsInPackages[ind];
          if(ind >= 1){
            this.addContaintsInPackage(index)
          }
          this.containtsInPackages(index).at(ind).get('description').patchValue(element.description);
          this.containtsInPackages(index).at(ind).get('quantity').patchValue(element.quantity);
          this.containtsInPackages(index).at(ind).get('tagNo').patchValue(element.tagNo);
          this.containtsInPackages(index).at(ind).get('uom').patchValue(element.uom);                  
        }
        this.calculateTotalCBM();
      }
    }
  }

  populateCommericailInvoiceFields(){
    this.additionalInformation.clear();
    this.bankDetail.clear();
    this.parties.clear();
    this.otherRef.clear();
    this.terms.clear();
    let additionalInformation: Array<any> = this.commericialInvoiceObj.additionalInformation;
    for (let index = 0; index < additionalInformation.length; index++) {
      const element = additionalInformation[index];
      this.addNewField();
      this.additionalInformation.at(index).get('label').patchValue(element.label);
      this.additionalInformation.at(index).get('value').patchValue(element.value);
    }

    let bankDetail: Array<any> = this.commericialInvoiceObj.bankDetail;
    for (let index = 0; index < bankDetail.length; index++) {
      const element = bankDetail[index];
      this.addNewBankField();
      this.bankDetail.at(index).get('label').patchValue(element.label);
      this.bankDetail.at(index).get('value').patchValue(element.value);
    }

    let parties: Array<any> = this.commericialInvoiceObj.parties;
    for (let index = 0; index < parties.length; index++) {
      const party = parties[index];
      this.addNewParty()
      this.parties.at(index).get('partyType').patchValue(party.partyType)
      this.parties.at(index).get('label').patchValue(party.label)
      this.parties.at(index).get('name').patchValue(party.name)
      this.parties.at(index).get('email').patchValue(party.email)
      if (party.contactNumbers.length > 0) {
        let contact = ((this.invoiceForm.get('parties') as FormArray)
          .at(index).get('contactNumbers') as FormArray)
        contact.clear()
        for (let ind = 0; ind < party.contactNumbers.length; ind++) {
          this.addNewContactNo(index)
          const element = party.contactNumbers[ind];
          ((this.invoiceForm.get('parties') as FormArray)
            .at(index).get('contactNumbers') as FormArray)
            .at(ind).get('contacts').patchValue(element.contacts);
        }
      }
      let address = this.parties.at(index).get('address') as FormGroup;
      address.get('street1').patchValue(party.address.street1)
      address.get('street2').patchValue(party.address.street2)
      address.get('city').patchValue(party.address.city)
      address.get('state').patchValue(party.address.state)
      address.get('country').patchValue(party.address.country)
      address.get('zipCode').patchValue(party.address.zipCode)
    }

    let otherRef: Array<any> = this.commericialInvoiceObj.otherRef;
    for (let index = 0; index < otherRef.length; index++) {
      this.addAlternateOtherRef();
      let element = otherRef[index];
      this.otherRef.at(index).get('otherReference').patchValue(element.otherReference)
    }

    let terms: Array<any> = this.commericialInvoiceObj.terms;
    for (let index = 0; index < terms.length; index++) {
      const element = terms[index];
      this.addNewTerms();
      this.terms.at(index).get('label').patchValue(element.label);
      this.terms.at(index).get('content').patchValue(element.content);
    }

    this.invoice.buyersReference.patchValue(this.commericialInvoiceObj.buyersReference);
    this.invoice.exportersReference.patchValue(this.commericialInvoiceObj.exportersReference);
    this.invoice.countryOfOriginOfGoods.patchValue(this.commericialInvoiceObj.countryOfOriginOfGoods);
    this.invoice.countryOfFinalDestination.patchValue(this.commericialInvoiceObj.countryOfFinalDestination);
    this.invoice.preCarriageBy.patchValue(this.commericialInvoiceObj.preCarriageBy)
    this.invoice.placeOfReceiptByPreCarrier.patchValue(this.commericialInvoiceObj.placeOfReceiptByPreCarrier)
    this.invoice.vesselVoyageFlightDetails.patchValue(this.commericialInvoiceObj.vesselVoyageFlightDetails)
    this.invoice.modeOfTransport.patchValue(this.commericialInvoiceObj.modeOfTransport);
    this.searchOriginValue = this.commericialInvoiceObj.portOfLoadingName;
    this.searchDestinationValue = this.commericialInvoiceObj.portOfDischargeName;
    this.getSearchResultsOrigin();
    this.getSearchResultDestination();
    this.invoice.portOfLoading.patchValue(this.commericialInvoiceObj.portOfLoading);
    this.invoice.portOfDischarge.patchValue(this.commericialInvoiceObj.portOfDischarge);
    this.invoice.placeOfDelivery.patchValue(this.commericialInvoiceObj.placeOfDelivery);
    this.invoice.currency.patchValue(this.commericialInvoiceObj.currency);
    this.invoice.containerNo.patchValue(this.commericialInvoiceObj.containerNo);
    this.invoice.kindOfPackages.patchValue(this.commericialInvoiceObj.kindOfPackages);
    this.invoice.descriptionOfSupplies.patchValue(this.commericialInvoiceObj.descriptionOfSupplies);
    this.invoice.orderValue.patchValue(this.commericialInvoiceObj.orderValue);
    this.invoice.declaration.patchValue(this.commericialInvoiceObj.declaration);
    this.invoice.notes.patchValue(this.commericialInvoiceObj.notes);

    let shippingMarks = this.commericialInvoiceObj.shippingMarks;
    this.shippingMarks.get('shippingFrom').patchValue(shippingMarks.shippingFrom)
    this.shippingMarks.get('shippingTo').patchValue(shippingMarks.shippingTo)
    this.shippingMarks.get('caseNumber').patchValue(shippingMarks.caseNumber)
    this.shippingMarks.get('grossWeight').patchValue(shippingMarks.grossWeight)
    this.shippingMarks.get('netWeight').patchValue(shippingMarks.netWeight)

    let packingList = this.commericialInvoiceObj.packingList;
    this.packingList.get('unitOfWeight').patchValue(packingList.unitOfWeight);
    this.packingList.get('grossWeightTotal').patchValue(packingList.grossWeightTotal);
    this.packingList.get('netWeightTotal').patchValue(packingList.netWeightTotal);

    let annexurePackingList = this.commericialInvoiceObj.annexurePackingList;
    this.annexurePackingList.get('unitOfWeight').patchValue(annexurePackingList.unitOfWeight);
    this.annexurePackingList.get('dimensionsOfPackage').patchValue(annexurePackingList.dimensionsOfPackage);
    this.annexurePackingList.get('cbmTotal').patchValue(annexurePackingList.cbmTotal);
    this.annexurePackingList.get('quantityTotal').patchValue(annexurePackingList.quantityTotal);
    this.annexurePackingList.get('netWeightTotal').patchValue(annexurePackingList.netWeightTotal);
    this.annexurePackingList.get('grossWeightTotal').patchValue(annexurePackingList.grossWeightTotal);

  }

  calculateAdditionalCharges(){
    let totalAmount = this.invoice.totalAmount.value;
    this.totalAdditionalCharges = 0.0;
    for (let index = 0; index < this.additionalCharges.length; index++) {
      const element = this.additionalCharges.at(index);
      let addOrLess = element.get('addOrLess').value;
      let percentageOrAmount = element.get('percentageOrAmount').value;
      let percentageOrAmountValue = element.get('percentageOrAmountValue').value;
      let amount = element.get('amount');

      if(addOrLess == "ADD"){
        if(percentageOrAmount == "PERCENTAGE"){
          let percentAmount = totalAmount * (percentageOrAmountValue / 100);
          amount.patchValue(Math.abs(percentAmount));
          this.totalAdditionalCharges = this.totalAdditionalCharges +  percentAmount;
        }
        else if(percentageOrAmount == "AMOUNT"){
          amount.patchValue(Math.abs(percentageOrAmountValue));
          this.totalAdditionalCharges = this.totalAdditionalCharges +  percentageOrAmountValue;
        }
      }
      else if(addOrLess == "LESS"){
        if(percentageOrAmount == "PERCENTAGE"){
          let percentAmount = totalAmount * (percentageOrAmountValue / 100);
          amount.patchValue(-Math.abs(percentAmount));
          this.totalAdditionalCharges = this.totalAdditionalCharges - percentAmount;
        }
        else if(percentageOrAmount == "AMOUNT"){
          amount.patchValue(-Math.abs(percentageOrAmountValue));
          this.totalAdditionalCharges = this.totalAdditionalCharges - percentageOrAmountValue;
        }
      }
    }
    this.invoice.totalDueAmount.patchValue(Math.abs((totalAmount) + (this.totalAdditionalCharges)));
  }

  populateAdditionalCharges(additionalCharges: Array<any>){
    for (let index = 0; index < additionalCharges.length; index++) {
      const element = additionalCharges[index];
      this.addAdditionalCharges();
      this.additionalCharges.at(index).get('description').patchValue(element.description);
      this.additionalCharges.at(index).get('addOrLess').patchValue(element.addOrLess);
      this.additionalCharges.at(index).get('percentageOrAmount').patchValue(element.percentageOrAmount);
      this.additionalCharges.at(index).get('percentageOrAmountValue').patchValue(element.percentageOrAmountValue);
      this.additionalCharges.at(index).get('amount').patchValue(element.amount);
    }
    this.calculateAdditionalCharges();
  }

}
