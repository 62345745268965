<h1 mat-dialog-title><strong>{{action}}</strong></h1>
<div mat-dialog-content>
  <form class="row" *ngIf="action != 'Delete'; else elseBlock" [formGroup]="dialogForm">

    <div class="col-md-6">
      <mat-form-field>
        <input placeholder="Company Name" required formControlName="companyName" matInput>
      </mat-form-field>
    </div>

    <div class="col-md-6">
      <mat-form-field>
        <input placeholder="Company Type" required formControlName="companyType" matInput>
      </mat-form-field>
    </div>

    <div class="col-md-6">
      <mat-form-field>
        <input placeholder="Name" required formControlName="name" matInput>
      </mat-form-field>
    </div>

    <div class="col-md-6">
      <mat-form-field>
        <input placeholder="Designation" required formControlName="designation" matInput>
      </mat-form-field>
    </div>

    <mat-form-field>
      <textarea class="" placeholder="Description" maxlength="250" required formControlName="description" rows="3"
        matInput></textarea>
        <div class="" style="float: right;">
          {{ descriptionLength | async }} / 250 characters
        </div>
      </mat-form-field>

  </form>

  <div class="row">
    <div class="form-group col-md-6">
      <div class="row" [formGroup]="logoForm">
        <div class="col-md-6">
          <label class="form-label" for="">Select Logo</label>
          <input type="text" formControlName="name" class="form-control" disabled>
        </div>
        <div class="col-md-6">
          <button matTooltip="Delete Logo" class="text-danger pull-right" *ngIf="isEdit && isLogoAvailableToDelete"
            (click)="onDeleteArtifact('LOGO')">
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </div>
      <input type="file" class="form-control mt-2" (change)="selectLogo($event)">
      <img class="mt-2" [src]="selectedLogoSrc" alt="" height="auto" width="100%" />
    </div>
    <div class="form-group col-md-6">
      <div class="row" [formGroup]="imageForm">
        <div class="col-md-6">
          <label class="form-label" for="">Select Image</label>
          <input type="text" formControlName="name" class="form-control" disabled>
        </div>
        <div class="col-md-6">
          <button matTooltip="Delete Image" class="text-danger pull-right" *ngIf="isEdit && isImageAvailableToDelete"
            (click)="onDeleteArtifact('IMAGE')">
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </div>
      <input type="file" class="form-control mt-2" (change)="selectImage($event)">
      <img class="mt-2" [src]="selectedImageSrc" alt="" height="auto" width="100%" />
    </div>
  </div>
  <ng-template #elseBlock>
    <!-- Sure to delete <b>{{local_data.value}}</b>? -->
  </ng-template>
</div>
<div mat-dialog-actions>
  <button style="margin-right: 20px;" class="btn-primary" mat-button (click)="onSubmit()">
    <!-- (click)="doAction()" -->
    {{action}}
  </button>
  <button class="btn-danger" mat-button (click)="closeDialog()" mat-flat-button color="warn">Cancel</button>
</div>
