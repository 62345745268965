import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MasterTableService } from '../helpers/master-table.service';

@Component({
  selector: 'app-hs-code-lists',
  templateUrl: './hs-code-lists.component.html',
  styleUrls: ['./hs-code-lists.component.css']
})
export class HsCodeListsComponent implements OnInit {

  page: number= 0;
  size: number = 25;
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['referenceLink', 'country', 'artifacts'];
  serchFilter: Boolean = false;
  nodata: Boolean = true;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  length: number;

  constructor(private masterTable: MasterTableService) { }

  ngOnInit(): void {
    this.getAllHSCodes();
  }

  getAllHSCodes(){
    this.masterTable.getHsCodeUnauthenticated(this.page, this.size).subscribe(
      (response) => {
        console.log(response);
        let content:Array<any> = response.content
        if(content.length > 0){
          this.dataSource = new MatTableDataSource(content);
          this.length = response.totalElements;
          this.dataSource.sort = this.sort;
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  onPageChange(event: PageEvent) {
    this.size = event.pageSize;
    this.page = event.pageIndex;
    this.getAllHSCodes();
  }

  dataURItoBlob(data) {
    const linkSource = data.content;
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = data.name;
    downloadLink.click();
  }

}
