import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  AUTH_API = `https://${window.location.hostname}:9001/api/v1`;
  constructor(private http: HttpClient) { }

  getrole(id: any): Observable<any> {
    return this.http.get(this.AUTH_API + '/role/' + id);
  }
  getAllRoles(): Observable<any> {
    return this.http.get(`${this.AUTH_API}/roles`)
  }

  addRole(data: any): Observable<any> {
    return this.http.post(`${this.AUTH_API}/role`, data);
  }
  updateRole(id: any, data: any): Observable<any> {
    return this.http.put(`${this.AUTH_API}/role/${id}`, data);
  }

  deleteRole(id: any): Observable<any> {
    return this.http.delete(`${this.AUTH_API}/role/${id}`);
  }


  getAllPermissions(page, size): Observable<any> {
    const params = new HttpParams().set('page', page).set('size', size);
    return this.http.get(`${this.AUTH_API}/permissions`, { params: params });
  }

  updatePermission(id: number, name: any): Observable<any> {
    return this.http.put(`${this.AUTH_API}/permission/${id}`, name);
  }
  addPermission(data: any): Observable<any> {
    return this.http.post(`${this.AUTH_API}/permission`, data);
  }
  deletePermission(id: any): Observable<any> {
    return this.http.delete(`${this.AUTH_API}/permisson/${id}`);
  }

  addUser(data): Observable<any> {
    return this.http.post(`${this.AUTH_API}/user`, data)
  }

  updateUser(data: any): Observable<any> {
    return this.http.put(`${this.AUTH_API}/user`, data);
  }

  getAllUsers(page, size): Observable<any> {
    const params = new HttpParams().set('page', page).set('size', size);
    return this.http.get(`${this.AUTH_API}/users`, { params: params });
  }

  getUserById(id: any): Observable<any> {
    return this.http.get(`${this.AUTH_API}/user/${id}`);
  }

  updateUserPassword(data: any): Observable<any> {
    return this.http.put(`${this.AUTH_API}/user-password`, data)
  }

  postProfilePicture(data: any):Observable<any> {
    return this.http.post(`${this.AUTH_API}/user/profile-artifact`, data);
  }

  getProfilePicture():Observable<any> {
    return this.http.get(`${this.AUTH_API}/user/profile-artifact`, { responseType: 'blob' });
  }

  deleteProfilePicture():Observable<any> {
    return this.http.delete(`${this.AUTH_API}/user/profile-artifact`);
  }

}
