import { Component, OnInit, ViewChild } from '@angular/core';
import { TransitionCheckState } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { DemoService } from '../helpers/demo.service';
import { ViewAddressComponent } from './view-address/view-address.component';

@Component({
  selector: 'app-request-demo-list',
  templateUrl: './request-demo-list.component.html',
  styleUrls: ['./request-demo-list.component.css']
})
export class RequestDemoListComponent implements OnInit {

  page: number = 0;
  size: number = 25;
  requestDemoLength: number;
  dataSource!: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  displayedColumns: string[] = ['name', 'emailId', 'contactNumber', 'designation', 'address', 'actions' ];
  checkedList: Array<any> = [];
  serchFilter: Boolean = false;
  nodata: Boolean = true;


  constructor(private demoService: DemoService, private router: Router, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getAllDemoList();
    this.gotoTop();
  }

  getAllDemoList(){
    this.demoService.getAllRequestDemo(this.page, this.size).subscribe(
      (response) => {
        let content: Array<any> = response.content;
        this.requestDemoLength = response.totalElements;
        if(content.length >= 1){
          this.dataSource = new MatTableDataSource(response.content);
          this.dataSource.sort = this.sort;
        }
      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      },
    )
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.nodata = false;
    this.serchFilter = true;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onPageChange(event: PageEvent){
    this.size = event.pageSize;
    this.page = event.pageIndex;
    this.getAllDemoList();
  }

  compare(event, id) {
    let value = event.srcElement.checked;
    let _id = id;
    if (value == true) {
      this.checkedList.push(_id);
    }
    else if (value == false) {
      this.checkedList.splice(this.checkedList.indexOf(_id), 1);
    }
    else {
      console.log("Cant do this");
    }
  }

  onBoardingClient(){
    if (this.checkedList.length > 1 || this.checkedList.length == 0) {
      Swal.fire({
        titleText: "Warning",
        icon: "warning",
        text: "Kindly check one checkbox",
        timer: 5000,
      })
    }
    else {
      this.router.navigate(['/v1/client-onboard/request-for-demo/', this.checkedList[0]])
    }
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  openDialog(data) {
    const dialogRef = this.dialog.open(ViewAddressComponent, {
      width: '50%;',
      data: { data }
    })
    dialogRef.afterClosed().subscribe((result) => {
    })
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
}
