import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ShipmentService } from 'src/app/helpers/shipment.service';
import { SocketService } from 'src/app/helpers/socket.service';
import Swal from 'sweetalert2';
import { ClientService } from '../../helpers/client-service.service';
import { ContianerLiveDialogComponent } from './contianer-live-dialog/contianer-live-dialog.component';

@Component({
  selector: 'app-client-container-schedule',
  templateUrl: './client-container-schedule.component.html',
  styleUrls: ['./client-container-schedule.component.css']
})
export class ClientContainerScheduleComponent implements OnInit {


  constructor(private clientService: ClientService, private socketService: SocketService,
    private matDialog: MatDialog, private router: ActivatedRoute, private shipmentService: ShipmentService) { }

  page: number = 0;
  size: number = 25;
  dataSource!: MatTableDataSource<any>;
  Ports = [];
  displayedColumns: string[] = ["shipment", "sailingStatus", "firstETADate", "isBL", "bLContainerCodes", "requestDate", "lastUpdatedDate",
    "transitPorts", "releaseTime", "referenceNo", "vessel", "carrier", "container", "country(pol)", "pol", "dateOfLoading", "country(pod)",
    "pod", "dateOfDischarge", "finalDeliveryPlace", "gateOutDate", "emptyReturnDate", "transitTime", "eta", "nOBLContainers", "bLReference"];
  containterNo: number;
  sailingDetails: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    this.router.params.subscribe((routeData) => {
      if (routeData.id) {
        this.fetchShipmentById(routeData.id);
      }
    });
    // this.socketService.connectTOServerPopUpFuction();
  }

  fetchShipmentById(shipmentId: any) {
    this.shipmentService.fetchShipmentById(shipmentId).subscribe
    ((response) => {
      console.log('this is reponse ', response);
      if(response != null){
        this.containterNo = response.containerNumber;
        this.searchDetails();
      }
    },
    (error) => {
      console.log(error);
    })
  }


  onKeyUpEventContainer(event) {
    console.log(event);
    this.containterNo = event.target.value;
    console.log(this.containterNo);
  }

  searchDetails() {
    this.clientService.getDetailofContainer(this.containterNo).subscribe(
      (response) => {
        console.log(response);
        if(response.Message){
          Swal.fire({
            title: "Error",
            text: response.Message,
            icon: "error",
            timer: 5000
          });
        }
        else{
          this.sailingDetails = response
          this.dataSource = new MatTableDataSource(response);;
          for (let index = 0; index < response.length; index++) {
            const element = response[index];
            for (let index = 0; index < element.TSPorts.length; index++) {
              const ele = element.TSPorts[index];
              this.Ports.push(ele.Port)
            }
          }
        }
      },
      (error) => {
        console.log(error);
      }
    )
  }


  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  openDialog(row: any){
    console.log(row);
    let data = {
      "url": row.LiveMapUrl,
      "ContainerNumber":row.ContainerNumber,
      "BLReferenceNo": row.BLReferenceNo
    };
    this.matDialog.open(ContianerLiveDialogComponent, {
      data: data,
      width: '700px',
      height: '700px'
    });
  }

}