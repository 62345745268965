import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexTitleSubtitle,
  ApexResponsive,
  ApexDataLabels
} from "ng-apexcharts";
import { SocketService } from '../helpers/socket.service';


export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
  title: ApexTitleSubtitle | any;
  responsive: ApexResponsive[] | any;
  labels: ApexDataLabels | any;
}

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.css']
})
export class UserDashboardComponent implements OnInit {

  @ViewChild('chart')
  chart!: ChartComponent;
  public chartOptions!: Partial<ChartOptions>;
  public lineChart!: Partial<ChartOptions>;
  public areaChart!: Partial<ChartOptions>;
  public pieChart!: Partial<ChartOptions>;

  constructor(private socketService: SocketService) {
    this.chartOptions = {
      series: [{
        name: "My-series",
        data: [10, 41, 35, 51, 49, 62, 69, 200, 148]
      }],

      chart: {
        height: 350,
        type: "bar"
      },

      title: {
        text: "Bar Chart"
      },

      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"]
      }

    };

    this.lineChart = {
      series: [{
        name: "Line chart",
        data: [20, 50, 10, 75, 35, 80, 25, 76, 125]
      }],

      chart: {
        height: 350,
        type: "line"
      },

      title: {
        text: "Line chart"
      },

      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"]
      }
    };

    this.areaChart = {
      series: [{
        name: "Area chart",
        data: [40, 20, 100, 35, 75, 49, 95, 26, 138]
      }],

      chart: {
        height: 350,
        type: "area"
      },

      title: {
        text: "Area Chart"
      },

      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"]
      }
    };

    this.pieChart = {
      title: {
        text: "Pie Chart"
      },
      series: [66, 78, 15, 66, 33],
      chart: {
        width: 380,
        type: "pie"
      },
      labels: ["Team A", "Team B", "Team C", "Team D", "Team E"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]

    };

  }

  ngOnInit(): void {
    this.socketService.connectTOServerPopUpFuction();
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }
}
