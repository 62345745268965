    <!--header start-->
    <div class="box-absolute d-flex align-items-center topline text-white">
      <button class="btn-opensidebar" type="button">
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M38 24H22" stroke="white" stroke-width="2" stroke-linecap="round"></path>
          <path d="M38 14H10" stroke="white" stroke-width="2" stroke-linecap="round"></path>
          <path d="M38 34H14" stroke="white" stroke-width="2" stroke-linecap="round"></path>
        </svg>
      </button>
      <ul class="breadcrumb p-0 m-0"> 
        <li class="breadcrumb-item 3" id="0"><a href="dashboard.html">Dashboard</a></li>
        <li class="breadcrumb-item 3" id="1"><a href="auction.html">Auction</a></li>
        <li class="breadcrumb-item"><a href="javascript:void(0)">Auction Comparison</a></li>
      </ul>
    </div>
    <!--header end-->
    <section class="banner text-center text-white d-flex" id="bnner">
      <div class="container position-relative">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <h1>Review and Approve Auction</h1>
          </div>
        </div>
      </div>
    </section>
    <!--request start-->
    <section class="request-demo-wrapper small pb-10">
      <div class="container shadow bg-white" (click)="hideSideBar()">
        <div class="row">
          <div class="col-lg-12">
            <div class="box">
              <form>
                <div class="btn-group d-flex gap-2 pb-3 pb-lg-4 px-3 px-lg-4">
                  <div class="input-group">
                    <input class="form-control rounded-3 px-4" type="text"(keyup)="filter($event.target.value)" id="">
                    <button class="input-group-btn start-0" type="button"><i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
                <div class="table-responsive"> 

                  <table mat-table [dataSource]="dataSource" matSort class="table table-borderless">

                      <ng-container matColumnDef="auctionEventNumber">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class='text-white'>Auction Event Number</th>  
                        <td mat-cell *matCellDef="let element">{{element.auctionEventNumber}}</td>
                      </ng-container>

                      <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class='text-white'>Description</th>  
                        <td mat-cell *matCellDef="let element">{{element.description}}</td>
                      </ng-container>
                      
                      <ng-container matColumnDef="creatorName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class='text-white'>Creator Name</th>  
                        <td mat-cell *matCellDef="let element">{{element.creatorName}}</td>
                      </ng-container>

                      <ng-container matColumnDef="creationDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class='text-white'>Creation Date</th>  
                        <td mat-cell *matCellDef="let element">{{element.creationDate}}</td>
                      </ng-container>

                      <ng-container matColumnDef="forwarderName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class='text-white'>Forwarder Name</th>  
                        <td mat-cell *matCellDef="let element">{{element.forwarderName}}</td>
                      </ng-container>

                      <ng-container matColumnDef="valueCurrency">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class='text-white'>Value & Currency</th>  
                        <td mat-cell *matCellDef="let element">{{element.value}}  {{element.currency}}</td>
                      </ng-container>

                      <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class='text-white'>Status</th>  
                        <td mat-cell *matCellDef="let element">{{element.status}}</td>
                      </ng-container>

                      <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef></th>  
                        <td mat-cell *matCellDef="let element"> 
                            <button class="text-success font-weight-bold" type="button"><span>View</span></button>
                        </td>
                      </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displaycolumns" class="bg-dark"></tr>
                    <tr mat-row *matRowDef="let row; columns: displaycolumns;" ></tr>
                    
                  </table>
                  <mat-paginator class="style-paginator" [pageSizeOptions]="[25,50,100]" showFirstLastButtons></mat-paginator>
                </div>
                <div class="row justify-content-center"> 
                  <div class="col-md-6 col-lg-3 form-group pt-lg-3">
                    <button class="btn text-dark small btn-outline-success px-2" type="button">REJECT</button>
                  </div>
                  <div class="col-md-6 col-lg-3 form-group pt-lg-3">
                    <button class="btn text-dark small btn-outline-success px-2" type="button">APPROVE</button>
                  </div>
                  <div class="col-md-6 col-lg-3 form-group pt-lg-3">
                    <button class="btn text-dark small btn-outline-success px-2" type="button">PROVIDE COMMENTS</button>
                  </div>
                  <div class="col-md-6 col-lg-3 form-group pt-lg-3">
                    <button class="btn text-dark small btn-outline-success px-2" type="button">CREATE SERVICE ORDER</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--request end -->