<section class="sign-in-wrapper">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 py-8 bg text-white d-flex flex-column justify-content-center position-relative"
        style="background-image: url(assets/images/sign-in-thumb.jpg)">
        <div class="box-absolute"><a href="index.html"><img class="img-fluid  " src="assets/images/logo-white.png"
              alt="Logsolutec" /></a>
        </div>
        <div class="box box-quote mx-auto position-relative">
          <p class="mb-3 mb-lg-4">“ Leaders win through logistics. Vision, sure. Strategy, yes. But when you go to war,
            you need to have both toilet paper and bullets at the right place at the right time. In other words, you
            must win through superior logistics. “</p>
          <h4>
            <svg width="27" height="19" viewBox="0 0 27 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M21.6797 7.32422C23.1641 7.32422 24.401 7.89323 25.3906 9.03125C26.3802 10.1693 26.875 11.4557 26.875 12.8906C26.875 14.5729 26.2812 15.9336 25.0938 16.9727C23.9062 17.9622 22.6445 18.457 21.3086 18.457C19.9727 18.457 18.6615 17.888 17.375 16.75C16.138 15.5625 15.5195 14.0286 15.5195 12.1484C15.5195 9.22917 16.4596 6.70573 18.3398 4.57812C20.2201 2.40104 22.1497 1.16406 24.1289 0.867188L24.7227 2.05469C23.5846 2.5 22.2734 3.36589 20.7891 4.65234C19.3542 5.9388 18.5872 7.20052 18.4883 8.4375C19.7253 7.69531 20.7891 7.32422 21.6797 7.32422ZM6.39062 7.32422C7.875 7.32422 9.11198 7.89323 10.1016 9.03125C11.0911 10.1693 11.5859 11.4557 11.5859 12.8906C11.5859 14.5729 10.9922 15.9336 9.80469 16.9727C8.61719 17.9622 7.35547 18.457 6.01953 18.457C4.73307 18.457 3.44661 17.888 2.16016 16.75C0.873698 15.5625 0.230469 14.0286 0.230469 12.1484C0.230469 9.22917 1.17057 6.70573 3.05078 4.57812C4.93099 2.40104 6.86068 1.16406 8.83984 0.867188L9.43359 2.05469C8.29557 2.5 6.98438 3.36589 5.5 4.65234C4.0651 5.9388 3.29818 7.20052 3.19922 8.4375C4.4362 7.69531 5.5 7.32422 6.39062 7.32422Z"
                fill="white"></path>
            </svg>- Tom Peters
          </h4>
        </div>
      </div>
      <div class="col-md-6 py-8 d-flex flex-column justify-content-center position-relative">
        <div class="box-absolute"><a class="btn-back" [routerLink]="['/forget-password']">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14 6L8.70711 11.2929C8.31658 11.6834 8.31658 12.3166 8.70711 12.7071L14 18" stroke="#414A53"
                stroke-width="1.5" stroke-linecap="round"></path>
            </svg>Back</a></div>
        <div class="box mx-auto">
          <form [formGroup]="passwordForm" (ngSubmit)="savePassword()">
            <h3>Reset Password</h3>
            <div class="row">
              <div class="col-md-12 form-group">
                <label class="form-label" for="new-password">New Password</label>
                <div class="input-group">
                  <input class="form-control" placeholder="" required="required" formControlName="password"
                    id="new-password" [type]="password ? 'text' : 'password'"
                    [ngClass]="{ 'is-invalid': submitted && f.password.errors}" />
                  <button [ngClass]="{'pass-view': errorPassword,'pass-error':!f.password.errors}"
                    (click)="showPassword()" class="input-group-btn text-primary btn-password-toggle" type="button">
                    <i class="fa fa-eye" [ngClass]="{'fa-eye-slash': !password, 'fa-eye': password}"></i>
                  </button>
                  <div class="invalid-feedback validation-message" *ngIf="submitted && f.password.errors">
                    <div [hidden]="!f.password.errors.required">Password is required</div>
                    <div [hidden]="!f.password.errors.minlength">Password must be at least 8 characters</div>
                    <div [hidden]="!f.password.hasError('passwordStrength')">
                      {{ f.password.errors['passwordStrength'] }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 form-group mt-4">
                <label class="form-label" for="confirm-password">Confirm Password</label>
                <div class="input-group">
                  <input class="form-control" type="password" placeholder="" required="required"
                    formControlName="confirm_password" id="confirm-password"
                    [type]="confirm_password ? 'text' : 'password'"
                    [ngClass]="{ 'is-invalid': submitted && f.confirm_password.errors}" />
                  <button [ngClass]="{'pass-view': errorPassword,'pass-error':!f.confirm_password.errors}"
                    (click)="showConfirmPassword()" class="input-group-btn text-primary btn-password-toggle"
                    type="button">
                    <i class="fa fa-eye"
                      [ngClass]="{'fa-eye-slash': !confirm_password, 'fa-eye': confirm_password}"></i>
                  </button>
                  <div class="invalid-feedback validation-message" *ngIf="f.confirm_password.errors">
                    <div *ngIf="submitted && f.confirm_password.errors?.required">Confirm Password is required</div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 form-group pt-4 pt-lg-5">
                <button class="btn btn-success w-100" type="submit">SUBMIT</button>
              </div>
              <div class="row justify-content-center">
                <div class="col-md-12">
                  <h6 class="msg-text">Password must have</h6>
                  <ul>
                    <li class="pb-2">At least 8 characters—the more characters, the better</li>
                    <li class="pb-2">A mixture of both uppercase and lowercase letters</li>
                    <li class="pb-2">A mixture of letters and numbers</li>
                    <li class="pb-2">Inclusion of at least one special character, e.g., ! @ # ? ]</li>
                  </ul>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>