import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-auction-dialog',
  templateUrl: './auction-dialog.component.html',
  styleUrls: ['./auction-dialog.component.css']
})
export class AuctionDialogComponent implements OnInit {
  local_data: any;
  action: any;

  
  constructor(public dialogRef: MatDialogRef<AuctionDialogComponent>,
    //@Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.local_data = { ...data };
    this.action = this.local_data.status; { }
  }

  ngOnInit(): void {
  }

  doAction() {
    if(this.local_data.reason != "" || null){
      this.dialogRef.close({ event: this.action, data: this.local_data });
    }
    else {
      Swal.fire({
        title: "warning",
        icon: "warning",
        titleText: "Please provide all mandatory fields",
        timer: 3000
      })
    }
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

}
