<h1 mat-dialog-title><strong>{{action}}</strong></h1>
<div mat-dialog-content>
    <form *ngIf="action != 'Delete'; else elseBlock" [formGroup]="dialogform">
        <mat-form-field>
            <input matInput placeholder="Param Key" [required]="isrequired" formControlName="paramKey" [readonly]="isReadOnly">
        </mat-form-field>
        <mat-form-field>
            <input placeholder="Param Value" matInput required formControlName="paramValue">
        </mat-form-field>
    </form>
    <ng-template #elseBlock>
        Sure to delete <b>{{local_data.value}}</b>?
    </ng-template>
</div>
<div mat-dialog-actions>
    <button style="margin-right:20px;" class="btn-primary" mat-button (click)="doAction()">{{action}}</button>
    <button class="btn-danger" mat-button (click)="closeDialog()" mat-flat-button color="warn">Cancel</button>
</div>