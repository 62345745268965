import { Component, ElementRef, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OnboardService } from 'src/app/helpers/onboard.service'
import Swal from 'sweetalert2';

@Component({
  selector: 'app-templatebox',
  templateUrl: './templatebox.component.html',
  styleUrls: ['./templatebox.component.css']
})
export class TemplateboxComponent implements OnInit {

  @ViewChild('editable', { static: true }) editRef: ElementRef;

  action: string;
  local_data: any;
  form: FormGroup;
  content: AbstractControl;

  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<TemplateboxComponent>,
    //@Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: OnboardService) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
  }


  ngOnInit(): void {
    this.form = this.fb.group({
      'id': [''],
      'templateKey': [''],
      'templateValue': ['']
    });
    if (this.action != "Add") {
      this.form.patchValue({
        id: this.local_data.id,
        templateKey: this.local_data.templateKey,
        templateValue: this.local_data.templateValue
      });
    }
  }

  doAction() {
    if (this.form.valid) {
      this.dialogRef.close({ event: this.action, data: this.form.value });
    } else {
      Swal.fire({
        title: 'warning',
        icon: 'warning',
        titleText: 'Please provide mandatory field',
        timer: 3000
      });
    }
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

}
