import { Component, Inject, OnInit, Optional } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { MasterTableService } from 'src/app/helpers/master-table.service';
import Swal from 'sweetalert2';
import { MarketingSpaceComponent } from '../marketing-space.component';

@Component({
  selector: 'app-dialog-marketing-space',
  templateUrl: './dialog-marketing-space.component.html',
  styleUrls: ['./dialog-marketing-space.component.css'],
})
export class DialogMarketingSpaceComponent implements OnInit {
  dialogForm: FormGroup;
  logoForm: FormGroup;
  imageForm: FormGroup;

  local_data: any;
  action: any;
  isEdit = false;

  marketingSpaceId: any;

  selectedLogo?: FileList;
  selectedLogoSrc: any = null;
  isLogoAvailableToDelete: boolean = false;

  selectedImage?: FileList;
  selectedImageSrc: any = null;
  isImageAvailableToDelete: boolean = false;

  public descriptionLength = new BehaviorSubject(0)

  constructor(
    private formbuilder: FormBuilder,
    private masterTableService: MasterTableService,
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<MarketingSpaceComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.action = data.action;
    this.local_data = { ...data };
    console.log('this is local data ', this.local_data);

    if (this.action == 'Update') {
      this.marketingSpaceId = this.local_data.id;
      this.isEdit = true;
    }
  }

  ngOnInit(): void {
    this.dialogForm = this.formbuilder.group({
      companyName: ['', Validators.required],
      companyType: ['', Validators.required],
      name: ['', Validators.required],
      description: ['', Validators.compose([Validators.required, Validators.maxLength(250)]) ],
      designation: ['', Validators.required],
    });

    this.logoForm = this.formbuilder.group({
      contentType: ['', Validators.required],
      label: 'LOGO',
      location: '',
      name: ['', Validators.required],
      content: ['', Validators.required],
    });

    this.imageForm = this.formbuilder.group({
      contentType: ['', Validators.required],
      label: 'IMAGE',
      location: '',
      name: ['', Validators.required],
      content: ['', Validators.required],
    });

    if (this.action != 'Add') {
      this.dialogForm.addControl('id', new FormControl());
      this.dialogForm.patchValue({
        // path values here
        id: this.local_data.id,
        companyName: this.local_data.companyName,
        companyType: this.local_data.companyType,
        name: this.local_data.name,
        description: this.local_data.description,
        designation: this.local_data.designation,
      });

      for (let index = 0; index < this.local_data?.artifacts.length; index++) {
        const element = this.local_data?.artifacts[index];
        console.log('this is artifacts uploaded ', element);
        if (element.label == 'LOGO') {
          this.logoForm.patchValue({
            contentType: element?.contentType,
            label: 'LOGO',
            location: '',
            name: element?.name,
            content: element?.content,
          });
          if (element.content) {
            this.isLogoAvailableToDelete = true;
            this.selectedLogoSrc =
              this.sanitizer.bypassSecurityTrustResourceUrl(
                `data:${element.contentType};base64, ${element.content}`
              );
          }
        }

        if (element.label == 'IMAGE') {
          this.imageForm.patchValue({
            contentType: element?.contentType,
            label: 'LOGO',
            location: '',
            name: element?.name,
            content: element?.content,
          });
          if (element.content) {
            this.isImageAvailableToDelete = true;
            this.selectedImageSrc =
              this.sanitizer.bypassSecurityTrustResourceUrl(
                `data:${element.contentType};base64, ${element.content}`
              );
          }
        }
      }
    }

    this.dialogForm.get('description').valueChanges.subscribe((v) =>
    this.descriptionLength.next(v.length)
  );
  }


  get logoFormControls() {
    return this.logoForm.controls;
  }

  get imageFormControls() {
    return this.imageForm.controls;
  }

  //fuction after selecting logo
  selectLogo(event: any): void {
    this.selectedLogo = event.target.files;
    let filename = event.target.files[0].name;
    let contentType = event.target.files[0].type;
    console.log('this is contentType ', contentType);
    this.logoFormControls['name']?.patchValue(filename);
    this.logoFormControls['contentType']?.patchValue(contentType);

    // File Preview
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.selectedLogoSrc = reader.result as string;
    };
    reader.readAsDataURL(file);
    this.convertFile(file).subscribe((base64) => {
      this.logoFormControls['content']?.patchValue(base64);
    });
  }

  //fuction after selecting image
  selectImage(event: any): void {
    this.selectedImage = event.target.files;
    let filename = event.target.files[0].name;
    let contentType = event.target.files[0].type;
    console.log('this is contentType ', contentType);
    this.imageFormControls['name']?.patchValue(filename);
    this.imageFormControls['contentType']?.patchValue(contentType);

    // File Preview
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.selectedImageSrc = reader.result as string;
    };
    reader.readAsDataURL(file);
    this.convertFile(file).subscribe((base64) => {
      this.imageFormControls['content']?.patchValue(base64);
    });
  }

  //fuction to convert file content into string
  convertFile(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event: any) =>
      result.next(btoa(event.target.result.toString()));
    return result;
  }

  // fuction to add marketing space
  addMarketingSpace() {
    if (this.dialogForm.valid && this.logoForm.valid && this.imageForm.valid) {
      this.masterTableService
        .addMarketingSpace(this.dialogForm.value)
        .subscribe({
          next: (res) => {
            console.log('this is add marketing response ', res);
            Swal.fire({
              title: 'Success',
              text: res.message,
              timer: 2000,
              icon: 'success',
            });
            if (this.selectedLogo) {
              this.masterTableService
                .uploadMarketingSpaceArtifacts(
                  res.entityId,
                  this.logoForm.value
                )
                .subscribe({
                  next: (res) => {
                    console.log('this is response upload artifact ', res);
                  },
                  error: (error) => {
                    console.log('this is error upload artifact ', error);
                  },
                });
            }
            if (this.selectedImage) {
              this.masterTableService
                .uploadMarketingSpaceArtifacts(
                  res.entityId,
                  this.imageForm.value
                )
                .subscribe({
                  next: (res) => {
                    console.log('this is response upload artifact ', res);
                  },
                  error: (error) => {
                    console.log('this is error upload artifact ', error);
                  },
                });
            }
            this.closeDialog();
          },
          error: (error) => {
            console.log('this is add marketing error ', error);
          },
        });
    } else {
      if (this.dialogForm.invalid) {
        Swal.fire({
          title: 'Warning',
          text: 'Please fill all mandatory fields',
          timer: 5000,
          icon: 'warning',
        });
      } else if (this.logoForm.invalid) {
        Swal.fire({
          title: 'Warning',
          text: 'Please select Logo',
          timer: 5000,
          icon: 'warning',
        });
      } else if (this.imageForm.invalid) {
        Swal.fire({
          title: 'Warning',
          text: 'Please select Image',
          timer: 5000,
          icon: 'warning',
        });
      }
    }
  }

  updateMarketingSpace() {
    if (this.dialogForm.valid && this.logoForm.valid && this.imageForm.valid) {
      this.masterTableService
        .updateMarketingSpace(this.marketingSpaceId, this.dialogForm.value)
        .subscribe({
          next: (res) => {
            console.log('this is response update marketing space ', res);
            Swal.fire({
              title: 'Success',
              text: res.message,
              timer: 5000,
              icon: 'success',
            });
            if (this.selectedLogo) {
              this.masterTableService
                .uploadMarketingSpaceArtifacts(
                  res.entityId,
                  this.logoForm.value
                )
                .subscribe({
                  next: (res) => {
                    console.log('this is response upload artifact ', res);
                  },
                  error: (error) => {
                    console.log('this is error upload artifact ', error);
                  },
                });
            }
            if (this.selectedImage) {
              this.masterTableService
                .uploadMarketingSpaceArtifacts(
                  res.entityId,
                  this.imageForm.value
                )
                .subscribe({
                  next: (res) => {
                    console.log('this is response upload artifact ', res);
                  },
                  error: (error) => {
                    console.log('this is error upload artifact ', error);
                  },
                });
            }
            this.closeDialog();
          },
          error: (error) => {
            console.log('this is error ', error);
          },
        });
    } else {
      if (this.dialogForm.invalid) {
        Swal.fire({
          title: 'Warning',
          text: 'Please fill all mandatory fields',
          timer: 5000,
          icon: 'warning',
        });
      } else if (this.logoForm.invalid) {
        Swal.fire({
          title: 'Warning',
          text: 'Please select Logo',
          timer: 5000,
          icon: 'warning',
        });
      } else if (this.imageForm.invalid) {
        Swal.fire({
          title: 'Warning',
          text: 'Please select Image',
          timer: 5000,
          icon: 'warning',
        });
      }
    }
  }

  onSubmit() {
    if (this.isEdit) {
      this.updateMarketingSpace();
    } else {
      this.addMarketingSpace();
    }
  }

  onDeleteArtifact(type: string) {
    for (let index = 0; index < this.local_data?.artifacts.length; index++) {
      const element = this.local_data?.artifacts[index];
      console.log('this is artifacts uploaded ', element);
      if (type == 'LOGO' && element.label == 'LOGO') {
        this.masterTableService
          .deleteMarketingSpaceArtifactById(this.local_data.id, element.id)
          .subscribe({
            next: (res) => {
              console.log('this is artifact delete res ', res);
              Swal.fire({
                title: 'Success',
                text: res.message,
                timer: 5000,
                icon: 'success',
              });
              this.logoForm.reset();
              this.logoForm.get('label').setValue('LOGO');
            },
            error: (error) => {
              console.log('this is error artifact delete ', error);
            },
          });
      } else if (type == 'IMAGE' && element.label == 'IMAGE') {
        this.masterTableService
          .deleteMarketingSpaceArtifactById(this.local_data.id, element.id)
          .subscribe({
            next: (res) => {
              console.log('this is artifact delete res ', res);
              Swal.fire({
                title: 'Success',
                text: res.message,
                timer: 5000,
                icon: 'success',
              });
              this.imageForm.reset();
              this.imageForm.get('label').setValue('IMAGE');
            },
            error: (error) => {
              console.log('this is error artifact delete ', error);
            },
          });
      }
    }
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }
}
