<!--header start-->
<app-header></app-header>
<!--banner start -->
<section class="banner text-center text-white d-flex align-items-center banner-request-demo" id="bnner"
    style="background-image: url(assets/images/banner-request-demo.jpg)">
    <div class="container position-relative">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <h2>Request For Demo</h2>
            </div>
        </div>
    </div>
</section>
<!--banner end-->
<!--request start-->
<section class="request-demo-wrapper pb-10">
    <div class="container shadow bg-white">
        <div class="row">
            <div class="col-lg-12">
                <div class="box">
                    <form [formGroup]="companyForm" (ngSubmit)="demoDetails()">
                        <h3>Company Information</h3>
                        <div class="row">
                            <div class="col-md-6 form-group">
                                <label class="form-label" for="name*">Company Name*</label>
                                <input class="form-control" type="text" placeholder="Enter Company / Individual" id="name*"
                                    formControlName="companyName" required="required"
                                    [ngClass]="{'is-invalid': company.companyName.invalid && ( company.companyName.dirty || company.companyName.touched )}" />
                                <div *ngIf="company.companyName.invalid && ( company.companyName.dirty || company.companyName.touched)"
                                    class="text-danger validation-message">
                                    <div [hidden]="!company.companyName.errors.maxlength">
                                        Max 100 Characters Allowed
                                    </div>
                                    <div [hidden]="!company.companyName.errors.required">
                                       Company Name is compulsory
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="form-label" for="website">Website</label>
                                <input class="form-control" type="text" placeholder="Text Input" id="website"
                                    formControlName="companyWebsite" />
                            </div>
                        </div>
                        <h5 class="mt-2">Regional Office Address</h5>
                        <div class="row" formGroupName="regionalOfficeAddress">
                            <div class="col-md-4 form-group">
                                <label class="form-label" for="street-address-1">Street Address 1</label>
                                <input class="form-control" type="text" placeholder="Text Input" id="street-address-1"
                                    formControlName="street1" />
                            </div>
                            <div class="col-md-4 form-group">
                                <label class="form-label" for="street-address-2">Street Address 2</label>
                                <input class="form-control" type="text" placeholder="Text Input" id="street-address-2"
                                    formControlName="street2" />
                            </div>
                            <div class="col-md-4 form-group">
                                <label class="form-label" for="city">City</label>
                                <input class="form-control" type="text" placeholder="Text Input" id="city"
                                    formControlName="city" />
                            </div>
                            <div class="col-md-4 form-group">
                                <label class="form-label" for="state">State</label>
                                <input class="form-control" type="text" placeholder="Text Input" id="state"
                                    formControlName="state" />
                            </div>
                            <div formGroupName="country" class="col-md-4 form-group">
                                <label class="form-label" for="country">Country</label>
                                <select id="country" formControlName="id" class="form-control form-select">
                                    <option disabled selected value="">Select Country</option>
                                    <option [value]="count.id" *ngFor="let count of countryCodes">
                                        {{ count.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-4 form-group">
                                <label class="form-label" for="zip-code">Zip Code</label>
                                <input class="form-control" type="text" placeholder="Text Input" id="zip-code"
                                    formControlName="zipCode" />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <h5>Head Office Address</h5>
                            </div>
                            <div class="col-md-6" style="text-align: right;">
                                <div class="">
                                    <input type="checkbox" id="copyAddress" class="form-check-input" [(ngModel)]="copyAddress" [ngModelOptions]="{standalone: true}" (change)="onCopyAddressSelect()"/>
                                    <label class="form-label m-1" for="copyAddress">
                                        Same as Regional Office Address
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="row" formGroupName="headOfficeAddress">
                            <div class="col-md-4 form-group">
                                <label class="form-label" for="street-address-1">Street Address 1</label>
                                <input class="form-control" type="text" placeholder="Text Input" id="street-address-1"
                                    formControlName="street1" />
                            </div>
                            <div class="col-md-4 form-group">
                                <label class="form-label" for="street-address-2">Street Address 2</label>
                                <input class="form-control" type="text" placeholder="Text Input" id="street-address-2"
                                    formControlName="street2" />
                            </div>
                            <div class="col-md-4 form-group">
                                <label class="form-label" for="city">City</label>
                                <input class="form-control" type="text" placeholder="Text Input" id="city"
                                    formControlName="city" />
                            </div>
                            <div class="col-md-4 form-group">
                                <label class="form-label" for="state">State</label>
                                <input class="form-control" type="text" placeholder="Text Input" id="state"
                                    formControlName="state" />
                            </div>
                            <div formGroupName="country" class="col-md-4 form-group">
                                <label class="form-label" for="country">Country</label>
                                <select id="country" name="country" formControlName="id"
                                    class="form-control form-select">
                                    <option disabled selected value="">Select Country</option>
                                    <option [value]="count.id" *ngFor="let count of countryCodes">
                                        {{ count.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-4 form-group">
                                <label class="form-label" for="zip-code">Zip Code</label>
                                <input class="form-control" type="text" placeholder="Text Input" id="zip-code"
                                    formControlName="zipCode" />
                            </div>
                        </div>
                        <h3>Point Of Contact Details</h3>
                        <div class="row">
                            <div class="col-md-4 form-group">
                                <label class="form-label" for="first-name*">First Name*</label>
                                <input class="form-control" type="text" placeholder="Text Input" id="first-name*"
                                    required="required" formControlName="pocFirstName" [ngClass]="{'is-invalid': company.pocFirstName.invalid && 
                                ( company.pocFirstName.dirty || company.pocFirstName.touched )}" />
                                <div *ngIf="company.pocFirstName.invalid && ( company.pocFirstName?.dirty || company.pocFirstName?.touched)"
                                    class="text-danger validation-message">
                                    <div [hidden]="!company.pocFirstName.errors.maxlength">
                                        Max 100 Characters Allowed
                                    </div>
                                    <div [hidden]="!company.pocFirstName.errors.required">
                                        First Name is compulsory
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 form-group">
                                <label class="form-label" for="last-name*">Last Name*</label>
                                <input class="form-control" type="text" placeholder="Text Input" id="last-name*"
                                    required="required" formControlName="pocLastName" [ngClass]="{'is-invalid': company.pocLastName.invalid && 
                                    ( company.pocLastName.dirty || company.pocLastName.touched )}" />
                                <div *ngIf="company.pocLastName.invalid && ( company.pocLastName?.dirty || company.pocLastName?.touched)"
                                    class="text-danger validation-message">
                                    <div [hidden]="!company.pocLastName.errors.maxlength">
                                        Max 100 Characters Allowed
                                    </div>
                                    <div [hidden]="!company.pocLastName.errors.required">
                                        Last Name is compulsory
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 form-group">
                                <label class="form-label" for="email-id*">Email-Id*</label>
                                <input class="form-control" type="email" placeholder="Text Input" id="email-id*"
                                    required="required" formControlName="pocEmailId" [ngClass]="{'is-invalid': company.pocEmailId.invalid && 
                                    ( company.pocEmailId.dirty || company.pocEmailId.touched )}" />
                                <div *ngIf="company.pocEmailId.invalid && ( company.pocEmailId?.dirty || company.pocEmailId?.touched)"
                                    class="text-danger validation-message">
                                    Email-Id is compulsory
                                </div>
                            </div>
                            <div class="col-md-4 form-group">
                                <label class="form-label" for="contact-number*">Contact Number*</label>
                                <input class="form-control" type="number" placeholder="Text Input" id="contact-number*"
                                    required="required" formControlName="pocContactNumber" [ngClass]="{'is-invalid': company.pocContactNumber.invalid && 
                                    ( company.pocContactNumber.dirty || company.pocContactNumber.touched )}" min="0"/>
                                <div *ngIf="company.pocContactNumber.invalid && ( company.pocContactNumber?.dirty || company.pocContactNumber?.touched)"
                                    class="text-danger validation-message">
                                    Contact Number is compulsory
                                </div>
                            </div>
                            <div class="col-md-4 form-group">
                                <label class="form-label" for="designation">Designation</label>
                                <input class="form-control" type="text" placeholder="Text Input" id="designation"
                                    formControlName="pocDesignation" />
                            </div>
                        </div>
                        <div class="row flex-column align-items-center">
                            <!-- CAPTCHA HERE TODO -->
                            <!-- <div class="col-md-4 form-group"><img class="img-fluid  "
                                    src="assets/images/google-captcha.png" data-aos="" data-aos-delay="" />
                            </div> -->
                            <div class="col-md-6 form-group pt-4 pt-lg-5">
                                <button class="btn btn-success w-100" type="submit">SUBMIT</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!--request end -->