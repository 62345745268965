<!-- sign-in section start-->
<section class="sign-in-wrapper">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-7 py-8 bg text-white d-flex flex-column justify-content-center position-relative"
        style="background-image: url(assets/images/sign-in-thumb.jpg)">
        <div class="box-absolute"><a href="index.html"><img class="img-fluid" src="assets/images/logo-white.png"
              alt="Logsolutec" /></a>
        </div>

        <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item" data-bs-interval="5000"
              *ngFor="let space of allMarketingSpaceItems; let i = index ;first as isFirst"
              [ngClass]="{'active': i == 0 }">
              <div class="d-none d-md-block text-center">
                <img class="imgPreview" [src]="logoPreviewArray[i]" alt="">
                <h3 class="mt-2">{{space?.companyName}}</h3>
                <h5 class="text-white">{{space?.companyType}}</h5>
                <p>{{space?.description}}</p>

                <div class="">
                  <img class="imgRound" [src]="imgPreviewArray[i]" alt="">
                </div>
                <div class="mt-2">
                  <strong>{{space?.name}}</strong> <br>
                  <strong>{{space?.designation}}</strong>
                </div>
              </div>
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls"
            data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls"
            data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>

      </div>
      <div class="col-md-5 py-8 d-flex flex-column justify-content-center position-relative">
        <div class="box-absolute brand">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 6L8.70711 11.2929C8.31658 11.6834 8.31658 12.3166 8.70711 12.7071L14 18" stroke="#414A53"
              stroke-width="1.5" stroke-linecap="round"></path>
          </svg>
          <button class="btn-back" [routerLink]="['/home']">
            Home</button>
        </div>
        <div class="box mx-auto">
          <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <h3>Sign In</h3>
            <div class="row">
              <div class="col-md-12 form-group">
                <label class="form-label" for="email-id">Email Id</label>
                <input class="form-control" type="email" formControlName="emailId" placeholder="someone@gmail.com"
                  id="email-id"
                  [ngClass]="{'is-invalid': f.emailId.invalid && f.emailId.errors && ( f.emailId.dirty || f.emailId.touched) }" />
                <div class="text-danger validation-message"
                  *ngIf="f.emailId.invalid && ( f.emailId.dirty || f.emailId.touched)">
                  Email Id is required
                </div>
              </div>
              <div class="col-md-12 form-group">
                <label class="form-label" for="password">Password</label>
                <div class="input-group">
                  <input class="form-control" [type]="password ? 'text' : 'password'" placeholder=""
                    formControlName="password" required="required" id="password" (change)="passwordChange()"
                    [ngClass]="{'is-invalid': f.password.invalid && f.password.errors && ( f.password.dirty || f.password.touched) }" />
                  <button [ngClass]="{'pass-view': f.password.errors,'pass-error':!f.password.errors}"
                    (click)="showPassword()" class="input-group-btn text-primary btn-password-toggle" type="button">
                    <i class="fa fa-eye" [ngClass]="{'fa-eye-slash': !password, 'fa-eye': password}"></i>
                  </button>
                </div>
                <div class="text-danger validation-message"
                  *ngIf="f.password.invalid && ( f.password.dirty || f.password.touched)">
                  <div [hidden]="!f.password.errors.required">
                    Password is required
                  </div>
                </div>
              </div>
              <div class="col-6 form-group mt-2">
                <div class="form-check d-inline-block">
                  <input class="form-check-input" [(ngModel)]="rememberMe" [ngModelOptions]="{standalone: true}"
                    type="checkbox" id="remember-me" name="option1" />
                  <label class="form-check-label" for="remember-me">Remember Me</label>
                </div>
              </div>
              <div class="col-6 form-group text-end"><a class="text-reset text-decoration-none"
                  [routerLink]="['/forget-password']">Forgot Password?</a>
              </div>
              <div class="col-md-12 form-group pt-4 pt-lg-5">
                <button class="btn btn-success w-100" type="submit">Sign In</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- sign-in section end-->
