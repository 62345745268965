<section class="wrapper">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-2 col-xl-2 col-left" style="height: 2240px">
        <app-sidebar></app-sidebar>
      </div>
      <div
        class="col-md-10 col-xl-10 col-right px-start-end pb-6 mt-5 justify-content-center"
      (click)="hideSideBar()">
        <div class="row mt-2">
          <div class="col-md-12 text-center">
            <h2 for="" class="m-2">
              <i class="fa fa-times-circle" style="color: red"> </i> No
              permissions to this module
            </h2>
          </div>

          <div class="col-md-12">
            <span></span>
          </div>
          <div class="col-md-12 text-center">
            <button
              class="btn btn-success mt-5"
              type="button"
              (click)="goBack()"
            >
              <i
                class="fa fa-chevron-circle-left"
                style="color: rgb(255, 255, 255)"
              ></i>
              &nbsp;&nbsp; Go Back
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
