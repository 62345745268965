<!--header start-->
<header class="box-absolute d-flex align-items-center topline text-white">
  <app-sidebar></app-sidebar>
  <div class="page-title-center mt-3">
    <div class="text-center">
      <h1>Quote Summary</h1>
    </div>
  </div>
  <div class="breadcrumb-div mt-3">
    <ul class="breadcrumb p-0 m-0">
      <li class="breadcrumb-item 3" id="0"><a [routerLink]="['/v1/dashboard']">Dashboard</a></li>
      <li class="breadcrumb-item 3" id="1"><a>Listing</a></li>
      <li class="breadcrumb-item"><a>Quote Summary</a></li>
    </ul>
  </div>
</header>
<!--header end-->
<section class="banner text-center text-white d-flex" id="bnner">
  <div class="container position-relative">
    <div class="row justify-content-center">
      <div class="col-lg-12">
      </div>
    </div>
  </div>
</section>
<!--request start-->
<section class="request-demo-wrapper pb-10" (click)="hideSideBar()">
  <div class="container shadow bg-white">
    <div class="row">
      <div class="col-lg-12">
        <div class="box">
          <form>
            <div *ngIf="rfqObject != null" class="row bg-lighter">
              <div class="col-lg-4 mt-3 form-group">
                <label class="label-bold" for="">Enquiry Number:</label>
                <label class="form-label mt-2" for="">{{ rfqObject.number }}</label>
              </div>
              <div class="col-lg-4 mt-3 form-group">
                <label class="label-bold" for="">Enquiry Description:</label>
                <label class="form-label mt-2" for="">{{ rfqObject.description }}</label>
              </div>
              <div class="col-lg-4 mt-3 form-group">
                <label class="label-bold" for="">Enquiry Date:</label>
                <label class="form-label mt-2" for="">{{ rfqObject.enquiryDate | date: 'dd-MM-YYYY' }}</label>
              </div>
              <div class="col-lg-4 form-group">
                <label class="label-bold" for="">Trade Type:</label>
                <label class="form-label mt-2" for="">
                  <div [ngSwitch]="rfqObject.tradeType">
                    <div *ngSwitchCase="1">Import</div>
                    <div *ngSwitchCase="2">Export</div>
                    <div *ngSwitchCase="3">Third Country</div>
                  </div>
                </label>
              </div>
              <div class="col-lg-4 form-group">
                <label class="label-bold" for="">Mode Of Shipment:</label>
                <label class="form-label mt-2" for="">
                  <div [ngSwitch]="rfqObject.modeOfShipment">
                    <div *ngSwitchCase="1">Air</div>
                    <div *ngSwitchCase="2">Sea</div>
                    <div *ngSwitchCase="3">Road</div>
                    <div *ngSwitchCase="4">Rail</div>
                  </div>
                </label>
              </div>
              <div class="col-lg-4 form-group">
                <label class="label-bold" for="">Origin:</label>
                <label class="form-label mt-2" for="">{{rfqObject.originPortName}}</label>
              </div>
              <div class="col-lg-4 form-group">
                <label class="label-bold" for="">Destination:</label>
                <label class="form-label mt-2" for="">{{rfqObject.destinationPortName}}</label>
              </div>
<!-- 
              <div *ngIf="rfqObject.modeOfShipment == 2" class="col-lg-4 form-group">
                <label class="label-bold" for="">Detention:</label>
                <label class="form-label mt-2" for="">{{rfqObject.detention}}</label>
              </div>

              <div class="col-lg-4 form-group">
                <label class="label-bold" for="">Demurrage:</label>
                <label class="form-label mt-2" for="">{{rfqObject.demurrage}}</label>
              </div>
 -->
            </div>
            <div class="btn-group d-flex gap-2 pb-3 pb-lg-4 px-3 px-lg-4 mt-3">
              <div class="input-group">
                <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input
                  class="form-control rounded-3 px-4" type="text" style="height: 40px;" />
                <button class="input-group-btn end-0" type="button"><i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-borderless table-head-dark" mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="quoteNumber">
                  <th mat-header-cell class="text-white" *matHeaderCellDef> Quote Number </th>
                  <td mat-cell *matCellDef="let row; let i = index"> {{ row.number || '-----' }} </td>
                </ng-container>

                <ng-container matColumnDef="date">
                  <th mat-header-cell class="text-white" *matHeaderCellDef> Date </th>
                  <td mat-cell *matCellDef="let row; let i = index"> {{ row.quoteDate | date }} </td>
                </ng-container>

                <ng-container matColumnDef="vendorName">
                  <th mat-header-cell class="text-white" *matHeaderCellDef> Vendor Name </th>
                  <td mat-cell *matCellDef="let row; let i = index"> {{ row.vendorName | uppercase }} </td>
                </ng-container>

                <ng-container matColumnDef="lineName">
                  <th mat-header-cell class="text-white" *matHeaderCellDef> Line Name </th>
                  <td mat-cell *matCellDef="let row; let i = index"> {{ row.lineName | uppercase }} </td>
                </ng-container>

                <ng-container matColumnDef="rank">
                  <th mat-header-cell class="text-white" *matHeaderCellDef> Rank </th>
                  <td mat-cell *matCellDef="let rankList; let i = index"> L {{ i + 1 }} </td>
                </ng-container>

                <ng-container matColumnDef="totalAmount">
                  <th mat-header-cell class="text-white" *matHeaderCellDef> Total Amount </th>
                  <td mat-cell *matCellDef="let row; let i = index"> {{ row?.amount }} </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                  <th mat-header-cell class="text-white" *matHeaderCellDef> Actions </th>
                  <td mat-cell *matCellDef="let row; let i = index">
                    <input type="checkbox" (change)="compare($event, row.vendorQuoteId, row.id)">
                  </td>
                </ng-container>

                <tr class="text-left bg-dark" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <!-- Row shown when there is no matching data -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell text-center text-danger" colspan="8">No data matching the filter "{{input.value}}"
                  </td>
                </tr>
              </table>
              <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator> -->
            </div>
            <div *ngIf="noQuote && rfqObject != null" class="row justify-content-center">
              <div class="col-md-3 px-2 form-group pt-lg-3">
                <button class="btn small btn-success px-3" (click)="viewSingleQuote()" type="button">View Quote</button>
              </div>
              <div *ngIf="rfqObject.type != 'b'" class="col-md-3 px-2 form-group pt-lg-3">
                <button class="btn text-dark small btn-outline-success px-3" (click)="createServiceOrder()"
                  type="button">CREATE SERVICE ORDER</button>
              </div>
              <div class="col-md-3 px-2 form-group pt-lg-3">
                <button class="btn small btn-success px-3" (click)="detailQuoteComparisonRoute()" type="button">DETAIL
                  COMPARISON</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
<!--request end  -->