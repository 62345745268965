<section class="wrapper multi-step-form">
    <div class="container-fluid">
        <div class="row">
            <div
                class="col-md-4 col-xl-3 col-left pt-12 d-flex flex-column justify-content-center position-sticky top-0">
                <div class="box-absolute d-flex">
                    <app-sidebar></app-sidebar>
                </div>
                <div class="box mx-auto">
                    <h3 class="text-center text-md-start">Vendor Onboarding</h3>
                    <ul class="list-unstyled step-one">
                        <li class="active">
                            <div class="icon">
                                <svg width="13" height="9" viewBox="0 0 13 9" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M1 5L3.97978 7.97978C4.17504 8.17504 4.49162 8.17504 4.68689 7.97978L11.6667 1"
                                        stroke="#003D90" stroke-width="1.5" stroke-linecap="round"></path>
                                </svg>
                            </div><span>Company Details</span>
                        </li>
                        <li class=" ">
                            <div class="icon">
                            </div><span>Company Overview</span>
                        </li>
                        <li class=" ">
                            <div class="icon">
                            </div><span>Upload Documents</span>
                        </li>
                        <li class=" ">
                            <div class="icon">
                            </div><span>Certification</span>
                        </li>
                        <li class=" ">
                            <div class="icon">
                            </div><span>Sign In</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-8 col-xl-9 col-right px-start-end pb-6" (click)="hideSideBar()">
                <div class="topline py-start-end d-flex align-items-center justify-content-between mb-4 mb-md-5">
                    <ul *ngIf="!vendor_status" class="breadcrumb p-0 m-0">
                        <li class="breadcrumb-item 3" id="0"><a [routerLink]="['/v1/dashboard']">Dashboard</a></li>
                        <li class="breadcrumb-item 3" id="1"><a>Vendor</a></li>
                        <li class="breadcrumb-item"><a>Onboarding</a></li>
                    </ul>
                    <ul *ngIf="vendor_status">
                    </ul>
                    <div> <span>1/5</span></div>
                </div>
                <form [formGroup]="vendorOnBoardForm">
                    <h3 class="text-center">Company Details</h3>
                    <div class="row">
                        <div class="col-md-4 form-group">
                            <label class="form-label" for="name">Name*</label>
                            <input class="form-control" type="text" placeholder="Text Input" id="name"
                                formControlName="name"
                                [ngClass]="{'is-invalid': company.name.invalid && ( company.name.dirty || company.name.touched )}" />
                            <div *ngIf="company.name.invalid && ( company.name.dirty || company.name.touched)"
                                class="text-danger validation-message">
                                <div [hidden]="!company.name.errors.maxlength">
                                    Maximum 100 Characters Required
                                </div>
                                <div [hidden]="!company.name.errors.required">
                                    Company Name is compulsory
                                </div>
                            </div>

                        </div>
                        <div class="col-md-4 form-group">
                            <label class="form-label" for="website">Website</label>
                            <input class="form-control" type="text" placeholder="Text Input" id="website"
                                formControlName="website" />
                        </div>
                    </div>
                    <div class="position-relative d-flex flex-wrap align-items-center add-new-wrap mb-2">
                        <div class="flex-grow-1">
                            <h4 class="mb-0">Address</h4>
                        </div>
                        <div class="position-absolute end-0 top-50 translate-middle-y">
                            <button class="btn-add-new text-dark btn-outline-success" type="button"
                                (click)="addNewAddress()"> <i class="fa fa-plus me-1"></i><span>Address</span>
                            </button>
                        </div>
                    </div>
                    <div formArrayName="address" *ngFor=" let address of address.controls; let i=index">
                        <div class="row" [formGroupName]="i">
                            <div class="col-md-10">
                            </div>
                            <div class="col-md-2" style="text-align: right;">
                                <button class="btn-outline-danger fas fa-trash btn-sm ml-xl-1 mt-2" type="button"
                                    (click)="removeNewAddress(i)" *ngIf="i != 0">
                                </button>
                            </div>
                            <div class="col-md-4 form-group">
                                <label for="form-label">Address Type</label>
                                <input type="text" list="addressType" class="form-select mt-2" formControlName="label"
                                    [ngClass]="{'is-invalid': addressTypeBoolean}" (change)="checkSameAddressType()">
                                <datalist id="addressType">
                                    <option value="Head Office">Head Office</option>
                                    <option value="Regional Office">Regional Office</option>
                                    <option value="Manufacturing Unit">Manufacturing Unit</option>
                                    <option value="SEZ Unit">SEZ Unit</option>
                                </datalist>
                            </div>

                            <div class="col-md-4 form-group">
                                <label class="form-label" for="street-address-1">Street Address 1</label>
                                <input class="form-control" type="text" (change)="onStreetValueCheck()"
                                    placeholder="Text Input" id="street-address-1" formControlName="street1"
                                    [ngClass]="{'is-invalid': checkSameStreet}" />
                            </div>
                            <div class="col-md-4 form-group">
                                <label class="form-label" for="street-address-2">Street Address 2</label>
                                <input class="form-control" type="text" (change)="onStreetValueCheck()"
                                    placeholder="Text Input" id="street-address-2" formControlName="street2"
                                    [ngClass]="{'is-invalid': checkSameStreet}" />
                            </div>
                            <div class="col-md-4 form-group">
                                <label class="form-label" for="city">City</label>
                                <input class="form-control" type="text" placeholder="Text Input" id="city"
                                    formControlName="city" />
                            </div>
                            <div class="col-md-4 form-group">
                                <label class="form-label" for="state">State</label>
                                <input class="form-control" type="text" placeholder="Text Input" id="state"
                                    formControlName="state" />
                            </div>
                            <div formGroupName="country" class="col-md-4 form-group">
                                <label class="form-label" for="country">Country</label>
                                <select id="country" name="country" formControlName="id"
                                    class="form-control form-select">
                                    <option value="" disabled selected>Select Country</option>
                                    <option [value]="count.id" *ngFor="let count of countryCodes">
                                        {{ count.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-4 form-group">
                                <label class="form-label" for="zip-code">Zip Code</label>
                                <input class="form-control" type="text" placeholder="Text Input" id="zip-code"
                                    formControlName="zipCode" />
                            </div>
                        </div>
                    </div>

                    <h4 class="mb-2 mt-4">Point Of Contact Details</h4>
                    <div class="row">
                        <div class="col-md-4 form-group">
                            <label class="form-label" for="point-of-contact-name">First Name*</label>
                            <input class="form-control" type="text" placeholder="Text Input" id="point-of-contact-name"
                                formControlName="pocFirstName"
                                [ngClass]="{'is-invalid': company.pocFirstName.invalid && ( company.pocFirstName.dirty || company.pocFirstName.touched )}" />
                            <div *ngIf="company.pocFirstName.invalid && ( company.pocFirstName.dirty || company.pocFirstName.touched)"
                                class="text-danger validation-message">
                                First Name is compulsory
                            </div>
                        </div>
                        <div class="col-md-4 form-group">
                            <label class="form-label" for="point-of-contact-name">Last Name*</label>
                            <input class="form-control" type="text" placeholder="Text Input" id="point-of-contact-name"
                                formControlName="pocLastName"
                                [ngClass]="{'is-invalid': company.pocLastName.invalid && ( company.pocLastName.dirty || company.pocLastName.touched )}" />
                            <div *ngIf="company.pocLastName.invalid && ( company.pocLastName.dirty || company.pocLastName.touched)"
                                class="text-danger validation-message">
                                Last Name is compulsory
                            </div>
                        </div>
                        <div class="col-md-4 form-group">
                            <label class="form-label" for="point-of-conatct-email-id">Email-Id*</label>
                            <input class="form-control" type="email" placeholder="Text Input"
                                id="point-of-conatct-email-id" formControlName="pocEmailId"
                                [ngClass]="{'is-invalid': company.pocEmailId.invalid && ( company.pocEmailId.dirty || company.pocEmailId.touched )}" />
                            <div *ngIf="company.pocEmailId.invalid && ( company.pocEmailId.dirty || company.pocEmailId.touched)"
                                class="text-danger validation-message">
                                <div [hidden]="company.pocEmailId.errors?.email">
                                    Company Email-Id is compulsory
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 form-group">
                            <label class="form-label" for="point-of-contact-number">Contact Number*</label>
                            <input class="form-control" type="text" placeholder="Text Input"
                                id="point-of-contact-number" formControlName="pocContactNumber"
                                [ngClass]="{'is-invalid': company.pocContactNumber.invalid && ( company.pocContactNumber.dirty || company.pocContactNumber.touched )}" />
                            <div *ngIf="company.pocContactNumber.invalid && ( company.pocContactNumber.dirty || company.pocContactNumber.touched)"
                                class="text-danger validation-message">
                                <div [hidden]="!company.pocContactNumber.errors.required">
                                    Company Contact Number is compulsory
                                </div>
                                <div [hidden]="!company.pocContactNumber.errors.minlength">
                                    Minimum 9 digits allowed
                                </div>
                                <div [hidden]="!company.pocContactNumber.errors.maxlength">
                                    Maximum 16 digits allowed
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 form-group">
                            <label class="form-label" for="designation">Designation</label>
                            <input class="form-control" type="text" placeholder="Text Input" id="designation"
                                formControlName="pocDesignation" />
                        </div>
                    </div>
                    <div *ngIf="vendor_status" class="position-relative d-flex flex-wrap align-items-center add-new-wrap mb-2">
                        <div class="flex-grow-1">
                            <h4 class="mb-0">Escalation Point</h4>
                        </div>
                        <div class="position-absolute end-0 top-50 translate-middle-y">
                            <button class="btn-add-new btn-sm text-dark btn-outline-success"
                                (click)="addNewEscalations()" type="button">
                                <i class="fa fa-plus me-1"></i><span>Escalation</span>
                            </button>
                        </div>
                    </div>
                    <div *ngIf="vendor_status" formArrayName="escalations">
                        <div class="row" *ngFor="let escalation of getEscalations.controls; let i=index"
                            [formGroupName]="i">
                            <div class="col-md-10"></div>
                            <div class="col-md-2" style="text-align: right;">
                                <button class="btn-outline-danger fas fa-trash btn-sm ml-xl-1 mt-2" type="button"
                                    (click)="removeNewEscalations(i)" *ngIf="i != 0">
                                </button>
                            </div>

                            <div class="col-md-4 form-group">
                                <label class="form-label" for="escalation-first">First Name*</label>
                                <input class="form-control" type="text" placeholder="Text Input" id="escalation-first"
                                    formControlName="firstName" name="firstname"
                                    [ngClass]="{'is-invalid': escalation.get('firstName').invalid && ( escalation.get('firstName').dirty || escalation.get('firstName').touched )}">
                                <div *ngIf="escalation.get('firstName').invalid && (escalation.get('firstName').dirty || escalation.get('firstName').touched)"
                                    class="text-danger validation-message">
                                    First Name Is Compulsory
                                </div>
                            </div>

                            <div class="col-md-4 form-group">
                                <label class="form-label" for="escalation-last">Last Name*</label>
                                <input class="form-control" type="text" placeholder="Text Input" id="escalation-last"
                                    formControlName="lastName"
                                    [ngClass]="{'is-invalid': escalation.get('lastName').invalid && ( escalation.get('lastName').dirty || escalation.get('lastName').touched )}">
                                <div *ngIf="escalation.get('lastName').invalid && ( escalation.get('lastName').dirty || escalation.get('lastName').touched )"
                                    class="text-danger validation-message">
                                    Last Name Is Compulsory
                                </div>
                            </div>

                            <div class="col-md-4 form-group">
                                <label class="form-label" for="escalation-name">Email-Id*</label>
                                <input class="form-control" type="email" placeholder="Text Input" id="escalation-name"
                                    formControlName="emailId" (change)="checkDuplicateEmail(i)"
                                    [ngClass]="{'is-invalid': escalation.get('emailId').invalid && ( escalation.get('emailId').dirty || escalation.get('emailId').touched )}">
                                <div *ngIf="escalation.get('emailId').invalid && ( escalation.get('emailId').dirty || escalation.get('emailId').touched )"
                                    class="text-danger validation-message">
                                    <div [hidden]="escalation.get('emailId').errors.email">
                                        Email-Id Is Compulsory
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4 form-group">
                                <label class="form-label" for="escalation-contact">Contact Number*</label>
                                <input class="form-control" type="text" placeholder="Text Input" id="escalation-contact"
                                    formControlName="contactNumber"
                                    [ngClass]="{'is-invalid': escalation.get('contactNumber').invalid && ( escalation.get('contactNumber').dirty || escalation.get('contactNumber').touched )}">
                                <div *ngIf="escalation.get('contactNumber').invalid && ( escalation.get('contactNumber').dirty || escalation.get('contactNumber').touched )"
                                    class="text-danger validation-message">
                                    <div [hidden]="!escalation.get('contactNumber').errors.required">
                                        Contact Number is compulsory
                                    </div>
                                    <div [hidden]="!escalation.get('contactNumber').errors.minlength">
                                        Minimum 9 Digits Required
                                    </div>

                                    <div [hidden]="!escalation.get('contactNumber').errors.maxlength">
                                        Max 16 Digits Allowed
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4 form-group">
                                <label class="form-label" for="escalation-designation">Designation</label>
                                <input class="form-control" type="text" placeholder="Text Input" id="escalation-designation"
                                    formControlName="designation">
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div *ngIf="vendor_status" class="col-md-6 form-group pt-4 pt-lg-5" (click)="updateVendor()">
                            <button class="btn btn-success w-100" type="submit">NEXT</button>
                        </div>
                        <div *ngIf="!vendor_status" class="col-md-6 form-group pt-4 pt-lg-5" (click)="inviteVendor()">
                            <button class="btn btn-success w-100" type="submit">INVITE</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>