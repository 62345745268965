<section class="wrapper multi-step-form">
    <div class="container-fluid">
        <div class="row">
            <div
                class="col-md-4 col-xl-3 col-left pt-12 d-flex flex-column justify-content-center position-sticky top-0">
                <app-sidebar></app-sidebar>
                <div class="box mx-auto">
                    <h3 class="text-center text-md-start">Client Onboarding</h3>
                    <ul class="list-unstyled step-one">
                        <li class="active">
                            <div class="icon">
                                <svg width="13" height="9" viewBox="0 0 13 9" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M1 5L3.97978 7.97978C4.17504 8.17504 4.49162 8.17504 4.68689 7.97978L11.6667 1"
                                        stroke="#003D90" stroke-width="1.5" stroke-linecap="round"></path>
                                </svg>
                            </div><span>Company Details</span>
                        </li>
                        <li class="active">
                            <div class="icon">
                                <svg width="13" height="9" viewBox="0 0 13 9" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M1 5L3.97978 7.97978C4.17504 8.17504 4.49162 8.17504 4.68689 7.97978L11.6667 1"
                                        stroke="#003D90" stroke-width="1.5" stroke-linecap="round"></path>
                                </svg>
                            </div><span>Document Uploadation</span>
                        </li>
                        <li class="active">
                            <div class="icon">
                                <svg width="13" height="9" viewBox="0 0 13 9" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M1 5L3.97978 7.97978C4.17504 8.17504 4.49162 8.17504 4.68689 7.97978L11.6667 1"
                                        stroke="#003D90" stroke-width="1.5" stroke-linecap="round"></path>
                                </svg>
                            </div><span>Sign Up</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-8 col-xl-9 col-right px-start-end pb-6">
                <div class="topline py-start-end d-flex align-items-center justify-content-between mb-4 mb-md-5">
                    <ul class="breadcrumb p-0 m-0">
                        <li class="breadcrumb-item 3 brand" id="1">
                            <button [routerLink]="['/v1/client-onboard/documents']">Back</button>
                        </li>
                        <!-- hovereffectRemaining -->
                        <!-- <li class="breadcrumb-item 3" id="0"><a href="dashboard.html">Dashboard</a></li>
                        <li class="breadcrumb-item 3" id="1"><a href="admin.html">Admin</a></li>
                        <li class="breadcrumb-item"><a href="javascript:void(0)">Client Onboarding</a></li> -->
                    </ul>
                    <div> <span>3/3</span></div>
                </div>
                <form [formGroup]="signupForm" (ngSubmit)="onSubmit()">
                    <h3 class="text-center">Sign Up</h3>
                    <div class="row justify-content-center">
                        <div class="col-md-7 form-group">
                            <label class="form-label" for="email-id">Email Id</label>
                            <input class="form-control" type="email" placeholder="sample@gmail.com" id="email-id"
                                formControlName="emailId" required="required" readonly="readonly"
                                [ngClass]="{ 'is-invalid': submitted && f.emailId.errors }" />
                            <div *ngIf="submitted && f.emailId.errors" class="invalid-feedback">
                                Email Id is required
                            </div>
                        </div>
                        <div class="col-md-7 mt-2 form-group">
                            <label class="form-label" for="password">Password</label>
                            <div class="input-group">
                                <input class="form-control" [type]="password ? 'text' : 'password'" placeholder=""
                                    required="required" id="password" formControlName="password"
                                    [ngClass]="{ 'is-invalid': submitted && f.password.errors}" />
                                <button (click)="showPassword()"
                                    [ngClass]="{'pass-view': errorPass,'pass-error':!f.password.errors}"
                                    class="input-group-btn text-primary btn-password-toggle" type="button">
                                    <i class="fa fa-eye"
                                        [ngClass]="{'fa-eye-slash': !password, 'fa-eye': password}"></i>
                                </button>
                                <div class="invalid-feedback validation-message" *ngIf="submitted && f.password.errors">
                                    <div *ngIf="f.password.errors.required">Password is required</div>
                                    <div *ngIf="f.password.errors.minlength">Password must be at least 8 characters
                                    </div>
                                    <div *ngIf="f.password.hasError('passwordStrength')">
                                        {{ f.password.errors['passwordStrength'] }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7 mt-4 form-group">
                            <label class="form-label" for="confirm-password">Confirm Password</label>
                            <div class="input-group">
                                <input class="form-control" [type]="confirm_password ? 'text' : 'password'"
                                    placeholder="" required="required" id="confirm-password"
                                    formControlName="confirm_password"
                                    [ngClass]="{ 'is-invalid': submitted && f.confirm_password.errors}" />
                                <button (click)="showHideConfirmPassword()"
                                    [ngClass]="{'pass-view': errorPass,'pass-error':!f.confirm_password.errors}"
                                    class="input-group-btn text-primary btn-password-toggle" type="button">
                                    <i class="fa fa-eye"
                                        [ngClass]="{'fa-eye-slash': !confirm_password, 'fa-eye': confirm_password}"></i>
                                </button>
                                <div *ngIf="submitted && f.confirm_password.errors"
                                    class="invalid-feedback validation-message">
                                    <div *ngIf="f.confirm_password.errors">Confirm Password is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mt-3 form-group">
                            <div class="form-check d-inline-block">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="terms_cond"
                                    [ngModelOptions]="{standalone: true}" id="i-accept-the-terms-&amp;-conditions*"
                                    name="option1" required="required" />
                                <label class="form-check-label" for="i-accept-the-terms-&amp;-conditions*">I accept the
                                    <a [routerLink]="['/v1/client-onboard/term-condition']" target="_blank">terms & conditions</a>*
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-md-6 form-group pt-4 pt-lg-5">
                            <button class="btn btn-success w-100" type="submit">SUBMIT</button>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-md-6">
                                <h6 class="msg-text">Password must have</h6>
                                <ul>
                                    <li class="pb-2">At least 8 characters—the more characters, the better</li>
                                    <li class="pb-2">A mixture of both uppercase and lowercase letters</li>
                                    <li class="pb-2">A mixture of letters and numbers</li>
                                    <li class="pb-2">Inclusion of at least one special character, e.g., ! @ # ? ]</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>