import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ReportService } from 'src/app/helpers/report.service';
import { SocketService } from 'src/app/helpers/socket.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  inputform: FormGroup;
  reportName: string;
  inputParams: Array<any> = [];
  outputParams: Array<any> = [];
  titleAlert: string = "this is required"

  constructor(private reportservice: ReportService, private route: ActivatedRoute, private fb: FormBuilder, private socketService: SocketService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.reportName = params.get("name");
      let input = params.get("input");
      let output = params.get("output");
      this.outputParams = output.trim().split(', ');
      this.inputParams = JSON.parse(input);
    })

    this.inputform = this.fb.group({})
    this.populateInputParams();
  }

  populateInputParams() {
    for (let index = 0; index < this.inputParams.length; index++) {
      const element = this.inputParams[index];
      this.inputform.addControl(element.fieldName, new FormControl());
      if (element.mandatory == true) {
        this.inputform.get(element.fieldName).setValidators([Validators.required])
        // this.inputform.get(element.fieldName).setValidators([Validators.required, Validators.pattern(element.regex)])
      } else {
        this.inputform.get(element.fieldName)
        // this.inputform.get(element.fieldName).setValidators(Validators.pattern(element.regex))
      }
    }
    console.log(this.inputform);
  }

  download(type: string) {
    console.log(this.reportName);
    if (this.inputform.invalid) {
      for (let index = 0; index < this.inputParams.length; index++) {
        const element = this.inputParams[index];
        if (element.mandatory == true) {
          this.inputform.get(element.fieldName).markAsDirty();
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: "Fill the mandatory fields",
            timer: 5000
          })
        }
      }
    } else {
      Swal.fire({
        text: "Downloading...",
        titleText: "Wait",
        timer: 3000,
        icon: "info"
      })
      // Complete
      if (this.reportName == "Request For Demo") {
        if (type == "pdf") {
          this.reportservice.getRequestDemo(type, this.inputform.value).subscribe(
            (response) => {
              console.log(response);   // it gives xml type
              if (response) {
                var file = new Blob([response], { type: 'application/pdf' });
                var fileURL = URL.createObjectURL(file);
                // window.open(fileURL);   // if u want to open in new tab
                var a = document.createElement('a');
                a.href = fileURL;
                a.target = '_blank';
                a.download = 'requestDemoList.pdf';
                document.body.appendChild(a);
                a.click();
              }
            },
            (error) => {
              Swal.fire({
                title: "Error",
                text: "Something Went Wrong..",
                timer: 3000,
                icon: "error"
              })
              console.log(error);
            }
          )
        }
        else if (type == 'xlsx') {
          // ToDo.
          // this.reportservice.getRequestDemo(type, this.inputform.value).subscribe(
          //   (response: Blob) => {
          //     // console.log(response)   // it gives xml type
          //     var file = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          //     console.log(file)
          //     var fileURL = URL.createObjectURL(file);
          //     console.log(fileURL);
          //     // window.open(fileURL);   // if u want to open in new tab
          //     var a = document.createElement('a');
          //     a.href = fileURL;
          //     a.target = '_blank';
          //     a.download = 'report.xlsx';
          //     document.body.appendChild(a);
          //     a.click();
          //   },
          //   (error) => {
          //     Swal.fire({
          //       title: "Error",
          //       text: "Something Went Wrong..",
          //       timer: 3000,
          //       icon: "error"
          //     })
          //     console.log(error);
          //   }
          // )
        }
      }
      else if (this.reportName == "End Buisness Details") {
        if (type == "pdf") {
          // ToDo, changes
          // this.reportservice.getRequestDemo(type, this.inputform.value).subscribe(
          //   (response: Blob) => {
          //     // console.log(response)   // it gives xml type
          //     var file = new Blob([response], { type: 'application/pdf' });
          //     console.log(file)
          //     var fileURL = URL.createObjectURL(file);
          //     console.log(fileURL);
          //     // window.open(fileURL);   // if u want to open in new tab
          //     var a = document.createElement('a');
          //     a.href = fileURL;
          //     a.target = '_blank';
          //     a.download = 'report.pdf';
          //     document.body.appendChild(a);
          //     a.click();
          //   },
          //   (error) => {
          //     Swal.fire({
          //       title: "Error",
          //       text: "Something Went Wrong..",
          //       timer: 3000,
          //       icon: "error"
          //     })
          //     console.log(error);
          //   }
          // )
        }
        else if (type == 'xlsx') {
          this.reportservice.getEndCustomerReport('xlsx').subscribe(
            (response: Blob) => {
              // console.log(response)   // it gives xml type
              var file = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
              console.log(file)
              var fileURL = URL.createObjectURL(file);
              console.log(fileURL);
              // window.open(fileURL);   // if u want to open in new tab
              var a = document.createElement('a');
              a.href = fileURL;
              a.target = '_blank';
              a.download = 'report.xlsx';
              document.body.appendChild(a);
              a.click();
            },
            (error) => {
              Swal.fire({
                title: "Error",
                text: "Something Went Wrong..",
                timer: 3000,
                icon: "error"
              })
              console.log(error);
            }
          )

        }
      }
      // Complete
      else if (this.reportName == "Savings From Auction") {
        if (type == "pdf") {
          this.reportservice.getAuctionSaving(type, this.inputform.value).subscribe(
            (response) => {
              // console.log(response)   // it gives xml type
              if (response) {
                var file = new Blob([response], { type: 'application/pdf' });
                console.log(file)
                var fileURL = URL.createObjectURL(file);
                console.log(fileURL);
                // window.open(fileURL);   // if u want to open in new tab
                var a = document.createElement('a');
                a.href = fileURL;
                a.target = '_blank';
                a.download = 'report.pdf';
                document.body.appendChild(a);
                a.click();
              }
            },
            (error) => {
              Swal.fire({
                title: "Error",
                text: "Something Went Wrong..",
                timer: 3000,
                icon: "error"
              })
              console.log(error);
            }
          )
        }
        else if (type == 'xlsx') {
          this.reportservice.getAuctionSaving('xlsx', this.inputform.value).subscribe(
            (response: Blob) => {
              // console.log(response)   // it gives xml type
              var file = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
              console.log(file)
              var fileURL = URL.createObjectURL(file);
              console.log(fileURL);
              // window.open(fileURL);   // if u want to open in new tab
              var a = document.createElement('a');
              a.href = fileURL;
              a.target = '_blank';
              a.download = 'report.xlsx';
              document.body.appendChild(a);
              a.click();
            },
            (error) => {
              Swal.fire({
                title: "Error",
                text: "Something Went Wrong..",
                timer: 3000,
                icon: "error"
              })
              console.log(error);
            }
          )
        }
      }
      // Complete
      else if (this.reportName == "Contact Us") {
        if (type == "pdf") {
          this.reportservice.getContactUs(type, this.inputform.value).subscribe(
            (response) => {
              console.log(response);   // it gives xml type
              if (response) {
                var file = new Blob([response], { type: 'application/pdf' });
                console.log(file)
                var fileURL = URL.createObjectURL(file);
                // window.open(fileURL);   // if u want to open in new tab
                var a = document.createElement('a');
                a.href = fileURL;
                a.target = '_blank';
                a.download = 'ContactUsList.pdf';
                document.body.appendChild(a);
                a.click();
              }
            },
            (error) => {
              let _error = error.error 
              console.log(_error);
              if(error.status == 412){
                Swal.fire({
                  title: "Error",
                  text: _error.errorMessage,
                  timer: 3000,
                  icon: "error"
                });
              }
              this.inputform.reset();
            }
          )
        }
        else if (type == 'xlsx') {
          // ToDo.
          // this.reportservice.getContactUs('xlsx', this.inputform.value).subscribe(
          //   (response: Blob) => {
          //     // console.log(response)   // it gives xml type
          //     var file = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          //     console.log(file)
          //     var fileURL = URL.createObjectURL(file);
          //     console.log(fileURL);
          //     // window.open(fileURL);   // if u want to open in new tab
          //     var a = document.createElement('a');
          //     a.href = fileURL;
          //     a.target = '_blank';
          //     a.download = 'report.xlsx';
          //     document.body.appendChild(a);
          //     a.click();
          //   },
          //   (error) => {
          //     Swal.fire({
          //       title: "Error",
          //       text: "Something Went Wrong..",
          //       timer: 3000,
          //       icon: "error"
          //     })
          //     console.log(error);
          //   }
          // )
        }
      }
      // Complete
      else if(this.reportName == "Budgetary Listing"){
        if (type == "pdf") {
          this.reportservice.getBugetaryEnquiryListing(type, this.inputform.value).subscribe(
            (response) => {
              console.log(response);
              if (response) {
                var file = new Blob([response], { type: 'application/pdf' });
                console.log(file)
                var fileURL = URL.createObjectURL(file);
                // window.open(fileURL);   // if u want to open in new tab
                var a = document.createElement('a');
                a.href = fileURL;
                a.target = '_blank';
                a.download = 'BudgetaryListing.pdf';
                document.body.appendChild(a);
                a.click();
              }
            },
            (error) => {
              console.log(error);
            },
          )
        }
        else if(type = "xlxs") {}
      }
      else if(this.reportName == "Vendor Bid"){
        if (type == "pdf") {
          this.reportservice.getvendorBidListing(type, this.inputform.value).subscribe(
            (response) => {
              console.log(response);
              if (response) {
                var file = new Blob([response], { type: 'application/pdf' });
                console.log(file)
                var fileURL = URL.createObjectURL(file);
                // window.open(fileURL);   // if u want to open in new tab
                var a = document.createElement('a');
                a.href = fileURL;
                a.target = '_blank';
                a.download = 'VendorBid.pdf';
                document.body.appendChild(a);
                a.click();
              }
            },
            (error) => {
              console.log(error);
            },
          )
        }
        else if(type = "xlxs") {}
      }
      // Complete
      else if(this.reportName == "Firm Listing"){
        if (type == "pdf") {
          this.reportservice.getFirmEnquiryListing(type, this.inputform.value).subscribe(
            (response) => {
              console.log(response);
              if (response) {
                var file = new Blob([response], { type: 'application/pdf' });
                console.log(file)
                var fileURL = URL.createObjectURL(file);
                // window.open(fileURL);   // if u want to open in new tab
                var a = document.createElement('a');
                a.href = fileURL;
                a.target = '_blank';
                a.download = 'FirmListing.pdf';
                document.body.appendChild(a);
                a.click();
              }
            },
            (error) => {
              console.log(error);
            },
          )
        }
        else if(type = "xlxs") {}
      }
    }
  }
}
