import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  AUTH_API: string = `https://${window.location.hostname}:9005/api/v1/report`
  constructor(private http: HttpClient) { }

  getAllReports(): Observable<any> {
    return this.http.get(`${this.AUTH_API}/reports`)
  }

  getRequestDemo(format, data): Observable<Blob>  {
    return this.http.put(`${this.AUTH_API}/requestDemo/${format}`, data, { responseType: 'blob' });
  }

  getEndCustomerReport(format): Observable<Blob> {
    return this.http.get(`${this.AUTH_API}/endBusinessCustomer/${format}`, { responseType: 'blob' });
  }

  getQuoteComparison(format, data): Observable<Blob> {
    return this.http.put(`${this.AUTH_API}/quoteComparison/${format}`, data, {responseType: 'blob'});
  }

  getAuctionSaving(format, data): Observable<Blob> {
    return this.http.put(`${this.AUTH_API}/auctionSavings/${format}`, data, { responseType: 'blob' });
  }

  getContactUs(format, data): Observable<Blob> {
    // return this.http.request<Blob>('GET', `${this.AUTH_API}/requestDemo/${format}`, {body: data});
    return this.http.put(`${this.AUTH_API}/contactUs/${format}`, data, { responseType: 'blob' });
  }

  getBugetaryEnquiryListing(format, data):Observable<Blob>{
    return this.http.put(`${this.AUTH_API}/budgetoryListing/${format}`, data, { responseType: 'blob' });
  }

  getFirmEnquiryListing(format, data):Observable<Blob>{
    return this.http.put(`${this.AUTH_API}/firmListing/${format}`, data, { responseType: 'blob' });
  }

  getvendorBidListing(format, data):Observable<Blob>{
    return this.http.put(`${this.AUTH_API}/vendorBid/${format}`, data, { responseType: 'blob' });
  }

  downloadAuctionBidSummery(auctionId: any): Observable<Blob> {
    return this.http.get(`${this.AUTH_API}/vendorBid/${auctionId}/pdf`, { responseType: 'blob' })
  }

}
