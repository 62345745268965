<app-header></app-header>
<section class="banner text-center text-white d-flex align-items-center banner-full"
    style="background-image: url(assets/images/banner.jpg)">
    <div class="container position-relative">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <div class="heading-dual">
                    <h1>LogSolutec</h1>
                    <h2>F.A.Q</h2>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="about-wrapper text-dark py-8">
    <div class="container-fluid p-lg-start-end">
        <div class="row mt-2">
            <p>Thank you for trusting us with your vision. We are grateful that you chose us to help you create a
                magnificent business experience. Our heads and hearts at Logsolutec are committed to turn your business
                into an extension of you so that you may thrive. Kindly find the answers to your questions, below:
            </p>
        </div>
        <div class="row mt-2">
            <h2 class="text-success">General Quetions</h2>

            <mat-accordion class="example-headers-align">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title class="pt-2">
                            <h4 class="text-dark">How can LogSolutec help my business?</h4>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="container-fluid ">
                        <p>Managing logistics can be a tumultuous experience, and investing in our platform that best
                            matches your organization may help you tremendously, from giving you control over your
                            freight to increasing transparency in your operations and saving money.</p>
                        <p>The following are some of the ways that we can help you:</p>

                        <p>
                            <b>Transportation Refinement:</b>
                            Transportation, which is an important aspect of logistics management, takes a lot of
                            resources and is one of the more expensive activities. Our system aids in the efficient
                            management of diverse modes of transportation and the effective use of data to save costs.
                            It accurately tells you who gives you the greatest service at the best pricing based on past
                            data, allowing you to make data-driven decisions.
                        </p>

                        <p>
                            <b>Faster delivery:</b>
                            Because logistics involves a linear flow of goods and services, and each stage of the
                            network is highly interdependent on various factors, delays are unavoidable. By being active
                            in the production cycle, our system can help you reduce these delays. It can help you select
                            the best transportation services at the correct moment, prepare ahead of time, and keep
                            track of your shipments. Even if there is a delay, you will be in a better position to make
                            alternate plans as a result of this.
                        </p>

                        <p>
                            <b>Reduces the risk of human error:</b>
                            As the system automates workflows, the risk of human error is reduced. Costly errors can and
                            do occur as a result of the complexity of logistics management. When a logistics system is
                            used, however, it reduces human intervention in the process, and its automation technology
                            speeds up and ensures that procedures are error-free. Furthermore, it eliminates paperwork,
                            allowing for more efficient and timely process management.
                        </p>

                        <p>
                            <b>Cost-cutting:</b>
                            Because the software can assess different shipping services, delivery agents, and
                            transportation methods and tell you exactly what you should choose based on the study. As a
                            result, you'll be able to save more money. Furthermore, higher overall process efficiency
                            and resilience equates to increased profitability in the end.
                        </p>

                        <p>
                            <b></b>
                        </p>
                    </div>
                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title class="pt-2">
                            <h4 class="text-dark">What are the advantages of automated invoice processing and how does
                                it work?</h4>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="container-fluid mt-2">
                        <p>In most cases, invoices are manually processed and approved by the accounting department,
                            which is prone to errors by design. Modern invoice processing systems, on the other hand,
                            have made it feasible to totally automate and make this procedure foolproof while lowering
                            the costs associated.</p>
                    </div>
                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title class="pt-2">
                            <h4 class="text-dark">How does ERP help in improving the business processes?</h4>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="container-fluid mt-2">
                        <p>ERP software helps firms handle orders, distribute products, and bill customers for goods in
                            a more efficient manner. Once we receive an intimation, we dispatch the cargo, connect
                            potential clients with facilitators and channelize the process. Users from these many
                            departments could all see the same data and update it as needed. When an order is completed
                            in one department, it is immediately forwarded to the next department via the ERP system.
                            Users merely need to connect into the ERP system and track the order to find out where it is
                            at any given time. The order procedure is well-organized within the company, and clients
                            receive their goods more quickly.</p>
                    </div>
                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title class="pt-2">
                            <h4 class="text-dark">Do better customer relations result from transportation logistics, at
                                the end of the day?</h4>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="container-fluid mt-2">
                        <p>Unquestionably. It is impossible to overestimate the importance of a transportation logistics
                            solution in improving customer happiness and service. While the value proposition for
                            manufacturing businesses should now be clear, the value proposition externally, or in a
                            customer-facing avenue, should be obvious right now. In order to build a favorable customer
                            relations platform and expand a company's client base and footprint, a company's ability to
                            supply the right product at the right time in the right location is critical. Customers will
                            not only be more likely to work with a given manufacturing company in the future because a
                            transport logistics solution gives planners and managers the power to ensure the right
                            products arrive in the right condition at the right time, but said manufacturing companies
                            will also create more stable and productive business relationships with partners in their
                            supply networks.</p>
                    </div>
                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title class="pt-2">
                            <h4 class="text-dark">If we are a small business, would you provide your services to us?
                            </h4>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="container-fluid mt-2">
                        <p>Answer: Yes, we enjoy working with start-ups and small businesses as equally as big brands.
                            LogSolutec has the experience and resources to help your business grow.</p>
                    </div>
                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title class="pt-2">
                            <h4 class="text-dark">What are the different solutions provided by LogSolutec?
                            </h4>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="container-fluid mt-2">
                        <p>From Vendor Registration, Auction Platform, Track and Trace, Vessel Schedule, Flight
                            schedule, etc. LogSolutec has got you all covered under one umbrella!</p>
                    </div>
                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title class="pt-2">
                            <h4 class="text-dark">Why should we choose LogSolutec?
                            </h4>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div>
                        <p>There are four reasons as to why you should pick LogSolutec solutions:</p>

                        <p>
                            <b>Easy accessibility</b>
                            Managing logistics virtually and stores data on cloud for easy access
                        </p>


                        <p>
                            <b>Exclusive auction platform</b>
                            Multiple auction platforms with transparent and cost-saving methods to render logistics
                            services
                        </p>


                        <p>
                            <b>Business efficiency</b>
                            Improves operations, vouches for shipment visibility, and real-time data
                        </p>

                        <p>
                            <b>Support 24x7</b>
                            Our dedicated team behind the desks is at your rescue 24 x 7
                        </p>


                        <p>In case, your query was not addressed in the FAQ section, we request you to email us on
                            <span><a href="contact@logsolutec.com">contact@logsolutec.com</a></span>
                        </p>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</section>