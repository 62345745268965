import { CDK_DROP_LIST_GROUP } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { MasterTableService } from '../helpers/master-table.service';

@Component({
  selector: 'app-trade-agreement-lists',
  templateUrl: './trade-agreement-lists.component.html',
  styleUrls: ['./trade-agreement-lists.component.css']
})
export class TradeAgreementListsComponent implements OnInit {

  page: number = 0;
  size: number = 25;
  dataSource: any;
  serchFilter: boolean;
  nodata: boolean;
  hsCodeLength: number;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns = ['tradeAgreementLink', 'tradeAgreementName', 'description', 'countries', 'artifacts'];
  length: number;
  countryList: Array<any> = [];
  firstCountry: any;
  secondCountry: any;

  constructor(private masterTable: MasterTableService) { }

  ngOnInit(): void {
    this.getAllCountries();
    this.getAllTradeAgreement();
  }

  getAllTradeAgreement() {
    this.masterTable.getAllTradeTypeAgreementPageUnauth(this.page, this.size).subscribe(
      (response) => {
        let content: Array<any> = response.content
        for (let index = 0; index < content.length; index++) {
          const element = content[index];
          element['countryString'] = "";
          let countries: Array<any> = element.countries;
          if (countries.length > 0) {
            let _countriesStr = "";
            for (let index = 0; index < countries.length; index++) {
              const _element = countries[index];
              if (index == 0) {
                _countriesStr = _element.name
              }
              else {
                _countriesStr = _countriesStr + ' , ' + _element.name
              }
              element['countryString'] = _countriesStr;
            }
          }
        }
        if (content.length > 0) {
          this.dataSource = new MatTableDataSource(content);
          this.length = response.totalElements;
          this.dataSource.sort = this.sort;
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  onPageChange(event: PageEvent) {
    this.size = event.pageSize;
    this.page = event.pageIndex;
    this.getAllTradeAgreement();
  }

  downloadFileLocal(data) {
    const linkSource = data.content;
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = data.name;
    downloadLink.click();
  }

  filterTradeAgreements() {
    if (this.firstCountry != null && this.secondCountry != null) {
      this.masterTable.filterTradeAgreement(this.firstCountry, this.secondCountry, this.page, this.size).subscribe(
        (response) => {
          this.dataSource = null;
          let content: Array<any> = response.content
          for (let index = 0; index < content.length; index++) {
            const element = content[index];
            element['countryString'] = "";
            let countries: Array<any> = element.countries;
            if (countries.length > 0) {
              let _countriesStr = "";
              for (let index = 0; index < countries.length; index++) {
                const _element = countries[index];
                if (index == 0) {
                  _countriesStr = _element.name
                }
                else {
                  _countriesStr = _countriesStr + ' , ' + _element.name
                }
                element['countryString'] = _countriesStr;
              }
            }
          }
          if (content.length > 0) {
            this.dataSource = new MatTableDataSource(content);
            this.length = response.totalElements;
            this.dataSource.sort = this.sort;
          }
        },
        (error) => {
          console.log(error);
        },
      )
    }
    else{
      Swal.fire({
        title: "Warning",
        icon: "warning",
        timer: 5000,
        text: "Select Both the Countries to filter data."
      });
    }
  }

  getAllCountries() {
    this.masterTable.getAllCountriesUnauthenticated().subscribe(
      (response) => {
        this.countryList = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

}
