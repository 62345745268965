<header class="box-absolute d-flex align-items-center topline text-white">
    <app-sidebar></app-sidebar>
    <div class="page-title-center mt-4">
        <div class="text-center">
            <h1 *ngIf="!subscriptionBool">Create Subscription</h1>
            <h1 *ngIf="subscriptionBool">Update Subscription</h1>
        </div>
    </div>
    <div class="breadcrumb-div mt-3">
        <ul class="breadcrumb p-0 m-0">
            <li class="breadcrumb-item 3" id="0"><a [routerLink]="['/v1/dashboard']">Dashboard</a></li>
            <li class="breadcrumb-item 3" id="1"><a>Admin</a></li>
            <li class="breadcrumb-item" *ngIf="!subscriptionBool"><a>Create Subscription</a></li>
            <li class="breadcrumb-item" *ngIf="subscriptionBool"><a>Update Subscription</a></li>
        </ul>
    </div>
</header>
<!--header end-->
<section class="banner text-center text-white d-flex" id="bnner">
    <div class="container position-relative">
        <div class="row justify-content-center">
            <div class="col-lg-12">
            </div>
        </div>
    </div>
</section>
<!--request start-->

<section class="request-demo-wrapper pb-10">
    <div class="container shadow bg-white px-start-end py-6" (click)="hideSideBar()">
        <form [formGroup]="buySubscriptionForm">
            <div class="row">
                <div class="col-md-6 form-group" formGroupName="client">
                    <label class="form-label" for="subscription-type">Client</label>
                    <select class="form-select" formControlName="id">
                        <option value="" selected>Select Type</option>
                        <option *ngFor="let item of allClientsList" [value]="item.id">{{ item.name }}</option>
                    </select>
                </div>

                <div class="col-md-6 form-group">
                    <label class="form-label" for="subscription-type">Subscription Type</label>
                    <select class="form-select" formControlName="subscriptionType">
                        <option value="" selected>Select Type</option>
                        <option value="GOLD">Gold</option>
                        <option value="SILVER">Silver</option>
                        <option value="BRONZE">Bronze</option>
                    </select>
                </div>

                <div class="col-md-6 form-group">
                    <label class="form-label" for="expiry-date">Licence Type</label>
                    <select class="form-select" formControlName="licenseType">
                        <option value="" selected>Select Type</option>
                        <option value="DEMO">Demo</option>
                        <option value="MONTHLY">Monthly</option>
                        <option value="YEARLY">Yearly</option>
                    </select>
                </div>

                <div class="col-md-6 form-group" *ngIf="subscriptionBool">
                    <label class="form-label" for="soft-expiry-date">Soft Expiry Date</label>
                    <input type="date" class="form-control" formControlName="softExpiryDate">
                </div>

                <div class="col-md-6 form-group" *ngIf="subscriptionBool">
                    <label class="form-label" for="expiry-date">Hard Expiry Date</label>
                    <input type="date" class="form-control" formControlName="hardExpiryDate">
                </div>

            </div>
            <div class="row justify-content-center pt-4 pt-lg-5">
                <div class="col-md-6 form-group pt-lg-3">
                    <button class="btn small btn-success px-3" type="button" (click)="buySubscription()" *ngIf="!subscriptionBool">BUY SUBSCRIPTION</button>
                    <button class="btn small btn-success px-3" type="button" (click)="updateSubscription()" *ngIf="subscriptionBool">UPDATE SUBSCRIPTION</button>
                </div>
            </div>
        </form>
    </div>
</section>
<!--request end  -->