<!--header start-->
<header class="box-absolute d-flex align-items-center topline text-white">
    <app-sidebar></app-sidebar>
    <div class="page-title-center mt-3">
      <div class="text-center">
        <h1>User Management List</h1>
      </div>
    </div>
    <div class="breadcrumb-div mt-3">
        <ul class="breadcrumb p-0 m-0">
            <li class="breadcrumb-item 3" id="0"><a [routerLink]="['/v1/dashboard']">Dashboard</a></li>
            <li class="breadcrumb-item 3" id="1"><a>User</a></li>
            <li class="breadcrumb-item"><a>Listing</a></li>
        </ul>
    </div>
</header>
<!--header end-->
<section class="banner text-center text-white d-flex" id="bnner">
    <div class="container position-relative">
        <div class="row justify-content-center">
            <div class="col-lg-12">
            </div>
        </div>
    </div>
</section>
<!--request start-->
<section class="request-demo-wrapper pb-10">
    <div class="container shadow bg-white" (click)="hideSideBar()">
        <div class="row">
            <div class="col-lg-12">
                <div class="box">
                    <form>
                        <div class="btn-group d-flex gap-2 pb-3 pb-lg-4 px-3 px-lg-4">
                            <div class="input-group">
                                <input class="form-control rounded-3 px-4" type="text" id=""
                                    (keyup)="applyFilter($event.target.value)" placeholder="Search" #input />
                                <button class="input-group-btn end-0" type="button"><i class="fa fa-search"></i>
                                </button>
                            </div>

                            <button class="rounded-3 flex-shrink-0" (click)="userCreateRoute()" type="button"><i
                                    class="fa fa-plus me-2"></i><span>New User</span>
                            </button>
                        </div>
                        <div class="table-responsive">
                            <table mat-table [dataSource]="dataSource" matSort class="table table-borderless table-striped 
                                table-footer-dark table-head-dark">
                                <ng-container matColumnDef="emailId">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-white"> Email Id
                                    </th>
                                    <td mat-cell *matCellDef="let element"> {{element.emailId}}</td>
                                </ng-container>

                                <ng-container matColumnDef="fname">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-white"> First Name
                                    </th>
                                    <td mat-cell *matCellDef="let element"> {{element.fname }}</td>
                                </ng-container>

                                <ng-container matColumnDef="lname">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-white"> Last Name
                                    </th>
                                    <td mat-cell *matCellDef="let element"> {{element.lname }}</td>
                                </ng-container>

                                <ng-container matColumnDef="createdDate">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-white"> Created
                                        Date </th>
                                    <td mat-cell *matCellDef="let element">{{element.createdDate}}</td>
                                </ng-container>
                                <ng-container matColumnDef="expiryDate">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-white"> Expiry
                                        Date </th>
                                    <td mat-cell *matCellDef="let element">{{element.expiryDate | date: 'short'}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="action">
                                    <th mat-header-cell *matHeaderCellDef colspan="3" class="text-white"> Actions</th>
                                    <td mat-cell *matCellDef="let element">
                                        <button class="fa fa-edit text-primary" type="button" (click)="editUser(element.id)">
                                        </button>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns" class="bg-dark"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                                <!-- Row shown when there is no matching data. -->
                                <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell text-center text-danger" colspan="6">
                                        <div *ngIf="serchFilter">
                                            No data matching the filter "{{ input.value }}"
                                        </div>
                                        <div *ngIf="nodata">
                                            <h5>Data is Not Available</h5>
                                        </div>
                                    </td>
                                </tr>

                            </table>
                            <mat-paginator #MatPaginator [pageSizeOptions]="[25, 50, 100]" [length]="userListLength" showFirstLastButtons
                            (page)="onPageChange($event)"></mat-paginator>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-md-4 form-group pt-lg-3">
                                <button class="btn text-dark text-small btn-outline-success" type="button"
                                    [routerLink]="['/v1/user/create']">Add New User</button>
                            </div>
                            <div class="col-md-4 form-group pt-lg-3">
                                <button class="btn text-dark text-small btn-outline-success"
                                    [routerLink]="['/v1/user-roles/listing']" type="button">Roles</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!--request end -->