
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { DemoService } from '../helpers/demo.service';
import { MasterTableService } from '../helpers/master-table.service';
import { OnboardService } from '../helpers/onboard.service';

@Component({
  selector: 'app-client-onboarding',
  templateUrl: './client-onboarding.component.html',
  styleUrls: ['./client-onboarding.component.css']
})
export class ClientOnboardingComponent implements OnInit {

  public clientOnBoardForm!: FormGroup;
  public countryCodes: Array<any> = [];
  public token: any;
  public client_status: boolean = false
  public client_id!: number;
  public addressTypeBoolean: boolean = false;
  public checkSameStreetBoolean: Boolean = false;

  constructor(private form: FormBuilder, private clientService: OnboardService, private route: ActivatedRoute, private router: Router,
    private demoService: DemoService, private masterTableService: MasterTableService) { }

  ngOnInit(): void {
    this.clientOnBoardForm = this.form.group({
      name: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
      website: [''],
      pocEmailId: ['', Validators.compose([Validators.required, Validators.email])],
      pocFirstName: ['', Validators.required],
      pocLastName: ['', Validators.required],
      pocContactNumber: [, Validators.compose([Validators.required, Validators.min(9), Validators.maxLength(16)])],
      pocDesignation: [''],
      address: this.form.array([])
    });
    this.token = this.route.snapshot.queryParamMap.get('t')
    let id: number = this.route.snapshot.params['id'];
    if (this.token != null) {
      this.verifyClientToken();
    }
    else if(id != null){
      this.fetchRequestByDemoById(id)
    }
    else {
      this.addNewAddress();
      this.getCountries();
    }

    this.gotoTop();
  }

  get address() {
    return this.clientOnBoardForm.get('address') as FormArray;
  }

  addNewAddress() {
    let data = this.form.group({
      label: [''],
      street1: [''],
      street2: [''],
      city: [''],
      state: [''],
      country: this.form.group({
        id: ['']
      }),
      zipCode: [''],
    })
    this.address.push(data);
  }
  prePopulateAddress(data: any){
    let _data = this.form.group({
      label: [data.label],
      street1: [data.street1],
      street2: [data.street2],
      city: [data.city],
      state: [data.state],
      country: this.form.group({
        id: data.country.id
      }),
      zipCode: [data.zipCode],
    })
    return this.address.push(_data);
  }

  removeNewAddress(i: number) {
    this.address.removeAt(i);
    this.checkSameAddressType()
    this.onStreetValueCheck()
  }

  get addressType() { return this.address.controls}

  get company() { return this.clientOnBoardForm.controls; }

  checkSameAddressType(){
    this.addressTypeBoolean = false;
    for (let i = 0; i < this.addressType.length; i++) {
      const value1 = this.addressType[i].value.label;
          for (let j = 0; j < this.addressType.length; j++) {
            if(i == j){
              continue;
            }
            const value2 = this.addressType[j].value.label;
            if(value1 == value2){
              this.addressTypeBoolean = true;
              break;
            }
          }
          if(this.addressTypeBoolean){
            break;
          } 
    }
  }

  clientInitiation() {
    if(this.addressTypeBoolean == true){
      Swal.fire({
       text: "Cannot Select Same Address Type",
       timer: 5000,
       icon: "warning",
       titleText: "Invalid"
     });
   } else if(this.checkSameStreetBoolean == true){
    Swal.fire({
      text: "Street 1 & Street 2 Cannot Be Same",
      timer: 5000,
      icon: "warning",
      titleText: "Invalid"
    });
   }
    else if (this.clientOnBoardForm.invalid) {
      Swal.fire({
        titleText: "Invalid",
        text: "Fill all the mandatory fields",
        timer: 5000,
        icon: "warning"
      })
      this.company.name.markAsDirty();
      this.company.pocContactNumber.markAsDirty();
      this.company.pocEmailId.markAsDirty();
      this.company.pocFirstName.markAsDirty();
      this.company.pocLastName.markAsDirty();
    }
    else {
      this.clientService.createClient(this.clientOnBoardForm.value).subscribe(
        (response) => {
          if (response.code == 201) {
            Swal.fire({
              titleText: "Success",
              text: response.message,
              timer: 5000,
              icon: "success"
            })
            this.router.navigate(['/v1/client/listing']);
          }
        },
        (error) => {
          let _error = error.error;
          if (_error.errorCode == 412) {
            Swal.fire({
              titleText: "Error",
              text: _error.errorMessage,
              timer: 5000,
              icon: "error"
            })
          }
          else if (error.status == 500 && _error.errorMessage) {
            Swal.fire({
              titleText: "Error",
              text: _error.errorMessage,
              timer: 5000,
              icon: "error"
            })
          }
          else if (error.status == 500 && _error.message) {
            Swal.fire({
              titleText: "Error",
              text: _error.message,
              timer: 5000,
              icon: "error"
            })
          }
          else {
            Swal.fire({
              titleText: "Error",
              text: "Something went wrong, try again later",
              timer: 5000,
              icon: "success"
            })
          }
        },
      )
    }
  }

  updateClient() {
    this.clientOnBoardForm.addControl( "onboardingStatus" , new FormControl("IN_PROGRESS"));
    let data = {
      "address": this.company.address.value,
      "name": this.company.name.value,
      "website": this.company.website.value,
      "pocContactNumber": this.company.pocContactNumber.value,
      "pocDesignation": this.company.pocDesignation.value,
      "pocEmailId": this.company.pocEmailId.value,
      "pocFirstName": this.company.pocFirstName.value,
      "pocLastName": this.company.pocLastName.value,
      "parent": {},
      "id": this.client_id,
      "onboardingStatus": this.company.onboardingStatus.value
    }
    this.clientService.updateClient(data).subscribe(
      (response) => {
        console.log(response);
        this.router.navigate(['v1/client-onboard/documents'])
      },
      (error) => {
        console.log(error);
      },
    );
  }

  onStreetValueCheck(){
    for (let i = 0; i < this.address.length; i++) {
      const value1 = this.address.value[i].street1;
      const value2 = this.address.value[i].street2;
      if(value1 == value2 ){
        this.checkSameStreetBoolean = true;
        if(value1 == '' && value2 == ''){
          this.checkSameStreetBoolean = false;
        }
      } else{
        this.checkSameStreetBoolean = false;
      }
    }
  }

  fetchRequestByDemoById(id){
    this.demoService.fetchRequestByDemoById(id).subscribe(
      (response) => {
        console.log(response);
        if(response.id != null){
          this.clientOnBoardForm.patchValue({
            name: response.companyName,
            website: response.companyWebsite,
            pocEmailId: response.pocEmailId,
            pocDesignation: response.pocDesignation,
            pocContactNumber: response.pocContactNumber,
            pocFirstName: response.pocFirstName,
            pocLastName: response.pocLastName,
          });
          this.company.name.disable();
          this.company.pocFirstName.disable();
          this.company.pocLastName.disable();
          this.company.pocEmailId.disable();
          this.company.pocContactNumber.disable();
          if(response.headOfficeAddress != null){
            this.prePopulateAddress(response.headOfficeAddress);
          }
          if(response.regionalOfficeAddress != null){
            this.prePopulateAddress(response.regionalOfficeAddress);
          }
          else{
            console.log("No address added");
            this.addNewAddress();
          }
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  verifyClientToken(){
    sessionStorage.setItem('preToken', this.token);
    this.getAllCountriesunauthenticated();
    this.clientService.verifyToken(this.token).subscribe(
      (response: any) => {
        if (response != null) {
          console.log(response);
          this.client_status = true;
          this.clientOnBoardForm.addControl('onboardingStatus', new FormControl('IN_PROGRESS'));
          this.client_id = response.id
          sessionStorage.setItem('org_id', JSON.stringify(this.client_id));
          sessionStorage.setItem('email_id', response.pocEmailId);
          this.company.name.disable();
          this.company.pocEmailId.disable();
          this.company.pocContactNumber.disable();
          this.clientOnBoardForm.patchValue({
            name: response.name,
            website: response.website,
            pocEmailId: response.pocEmailId,
            pocDesignation: response.pocDesignation,
            pocContactNumber: response.pocContactNumber,
            pocFirstName: response.pocFirstName,
            pocLastName: response.pocLastName,
          });
          if (response.address.length > 0) {
            for (let index = 0; index < response.address.length; index++) {
              const address = response.address[index];
              this.prePopulateAddress(address);
            }
          }
          else{
            this.addNewAddress();
          }
          console.log(this.clientOnBoardForm.value);
        }
      },
      (error: any) => {
        console.log(error);
        if (error.status == 500) {
          Swal.fire({
            titleText: "Error",
            text: "Something went wrong, try again later",
            timer: 5000,
            icon: "error"
          })
          this.router.navigate(['login']);
        }
        else if(error.status == 412){
          let _error = error.error;
          Swal.fire({
            titleText: "Error",
            text: _error.errorMessage,
            timer: 5000,
            icon: "error"
          })
        }
      },
    )
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  //fuction to scroll to top on next button click
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  getCountries(){
    this.masterTableService.getAllCountries().subscribe(
      (response) => {
        this.countryCodes = response;
      },
      (error) => {
        console.log(error);
      }
    )
  }

  getAllCountriesunauthenticated(){
    this.masterTableService.getAllCountriesUnauthenticated().subscribe(
      (response) => {
        this.countryCodes = response;
      },
      (error) => {
        console.log(error);
      }
    )
  }

}
