import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormControlName, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MasterTableService } from 'src/app/helpers/master-table.service';
import Swal from 'sweetalert2';
import { CurrencyComponent } from '../currency.component';

@Component({
  selector: 'app-currency-dialog-box',
  templateUrl: './currency-dialog-box.component.html',
  styleUrls: ['./currency-dialog-box.component.css']
})
export class CurrencyDialogBoxComponent implements OnInit {

  action: string;
  local_data: any;
  currencyForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<CurrencyComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder) {
    this.action = data.action;
    this.local_data = { ...data };

  }

  ngOnInit(): void {
    this.currencyForm = this.fb.group({
      currency: [""],
      currencyCode: [""],
      minorUnit: [""],
      numericCode: [""]
    });

    if (this.action != 'Add') {
      this.currencyForm.addControl('id', new FormControl());
      this.currencyForm.patchValue({
        currency: this.local_data.currency,
        currencyCode: this.local_data.currencyCode,
        minorUnit: this.local_data.minorUnit,
        numericCode: this.local_data.numericCode,
        id: this.local_data.id
      })
    }
  }

  doAction() {
    if (this.currencyForm.valid) {
      this.dialogRef.close({ event: this.action, data: this.currencyForm.value });
    } else {
      Swal.fire({
        titleText: "Please provide all mandatory fields",
        icon: "warning",
        timer: 3000
      })
    }

  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }


}
