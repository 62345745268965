<section class="wrapper multi-step-form">
    <div class="container-fluid">
        <div class="row">
            <div
                class="col-md-4 col-xl-3 col-left pt-12 d-flex flex-column justify-content-center position-sticky top-0">
                <div class="box-absolute d-flex">
                    <app-sidebar></app-sidebar>
                </div>
                <div class="box mx-auto">
                    <h3 class="text-center text-md-start">{{ title }}</h3>
                    <ul class="list-unstyled step-one">
                        <li class="active" id="list-item-0">
                            <div class="icon"> <i class="fa fa-check"></i></div><span>Shipment</span>
                        </li>
                        <li class=" " id="list-item-1">
                            <div class="icon"> <i class="fa fa-check"></i></div><span>Shipment Details</span>
                        </li>
                        <li class=" " id="list-item-2">
                            <div class="icon"> <i class="fa fa-check"></i></div><span>Shipment Particulars</span>
                        </li>
                        <li class=" " id="list-item-3">
                            <div class="icon"> <i class="fa fa-check"></i></div><span>Vendor Assignment</span>
                        </li>
                        <li class=" " id="list-item-4">
                            <div class="icon"> <i class="fa fa-check"></i></div><span>Terms &amp; Documents</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-8 col-xl-9 col-right px-start-end pb-6" (click)="hideSideBar()">
                <div class="topline pt-4 pt-lg-5 pb-3 pb-lg-4 d-flex align-items-center justify-content-between">
                    <ul *ngIf="breadcrumbBool" class="breadcrumb p-0 m-0">
                        <li class="breadcrumb-item 3 brand" id="0">
                            <button [routerLink]="['/v1/dashboard']">Dashboard</button>
                        </li>
                    </ul>
                    <ul class="breadcrumb p-0 m-0">
                        <li *ngIf="backfirstPage" class="brand"><button (click)="goBackShipment()">Back</button></li>
                        <li *ngIf="backDetailsPage" class="breadcrumb-item 3 brand"><button
                                (click)="goBackDetails()">Back</button></li>
                        <li *ngIf="backParticularsPage" class="breadcrumb-item 3 brand"><button
                                (click)="goBackParticulars()" id="brand">Back</button></li>
                        <li *ngIf="backVendorPage" class="breadcrumb-item 3 brand"><button
                                (click)="goBackVendor()">Back</button></li>
                    </ul>
                    <div class="tab-content step-two">
                        <div class="tab-pane active" id="item-0">1/5</div>
                        <div class="tab-pane" id="item-1">2/5</div>
                        <div class="tab-pane" id="item-2">3/5</div>
                        <div class="tab-pane" id="item-3">4/5</div>
                        <div class="tab-pane" id="item-4">5/5</div>
                    </div>
                </div>
                <form [formGroup]="enquiryForm">
                    <div class="tab-content form-step">
                        <div class="tab-pane active" id="item-5">
                            <h3 class="text-center">Shipment</h3>
                            <div class="row">
                            </div>
                            <h4 class="text-center  mb-3 mb-lg-4">Mode of Shipment*</h4>
                            <div class="row justify-content-center">
                                <div *ngFor="let item of mode_of_shipment" class="col-md-3 form-group">
                                    <div class="custom-checkbox">
                                        <input formControlName="modeOfShipment" class="custom-checkbox-input"
                                            type="radio" id="{{item.value}}" required="required" [value]="item.id"
                                            (change)="changeModeOfShipment()" />
                                        <label class="custom-checkbox-label" for="{{item.value}}">
                                            <div class="img-box"><img class="img-fluid"
                                                    src="assets/images/shipment-mode-{{ item.value | lowercase}}.png" />
                                            </div>
                                            <h5 class="text-center font-weight-bold mt-3 mb-0">{{ item.value }}</h5>
                                        </label>
                                    </div>
                                </div>
                                <div class="text-danger text-center" *ngIf="enquiry.modeOfShipment.errors?.required">
                                    Mode of Shipment is mandatory
                                </div>
                            </div>
                            <h4 class="text-center mt-2 mt-lg-3 mb-3 mb-lg-4">Trade Type*</h4>
                            <div class="row justify-content-center">
                                <div *ngFor="let item of trade_type" class="col-md-3 form-group">
                                    <div class="custom-checkbox">
                                        <input class="custom-checkbox-input" type="radio" id="{{item.value}}"
                                            formControlName="tradeType" [value]="item.id"
                                            (change)="changeTradeType()" />
                                        <label class="custom-checkbox-label" for="{{item.value}}">
                                            <div class="img-box"><img class="img-fluid"
                                                    src="assets/images/shipment-mode-{{item.value | lowercase}}.png" />
                                            </div>
                                            <h5 class="text-center font-weight-bold mt-3 mb-0">{{ item.value }}</h5>
                                        </label>
                                    </div>
                                </div>
                                <div class="text-danger text-center" *ngIf="enquiry.tradeType.errors?.required">
                                    Trade Type is mandatory
                                </div>
                            </div>
                            <h4 class="text-center mt-2 mt-lg-3 mb-3 mb-lg-4">RFQ Title*</h4>
                            <div class="row justify-content-center">
                                <div class="col-md-9 form-group">
                                    <textarea class="form-control" rows="6" formControlName="description"
                                        [ngClass]="{'is-invalid': enquiry.description.invalid && ( enquiry.description.dirty || enquiry.description.touched) }"
                                        placeholder="Text Input" id=""></textarea>
                                    <div class="text-danger validation-message-textArea"
                                        *ngIf="enquiry.description.invalid && ( enquiry.description.dirty || enquiry.description.touched )">
                                        Enquiry Title is mandatory
                                    </div>
                                </div>
                            </div>

                            <div class="row justify-content-center">
                                <div class="col-md-6 form-group pt-4 pt-lg-5">
                                    <button class="btn btn-success w-100" type="button" data-target="item-1"
                                        (click)="nextDetailsPage()">NEXT</button>
                                </div>
                            </div>
                            <div class="row" align="right">
                                <div class="col-lg-12">
                                    <p class="bg-white text-dark">
                                        Note: * denotes mandatory fields
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="item-6">
                            <h3 class="text-center">Shipment Details</h3>
                            <div class="row">
                                <!-- Enquiry date -->
                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="enquiryDate">Enquiry Date</label>
                                    <input class="form-control" type="date" formControlName="enquiryDate" [min]="getTodayDate()">
                                </div>
                                <!-- Loading Port -->
                                <div class="col-md-4 form-group">
                                    <label *ngIf="sea_mode_shipment" class="form-label" for="loading-port*">Loading
                                        Port*</label>
                                    <label *ngIf="air_mode_shipment" class="form-label" for="loading-port*">Loading
                                        Airport*</label>
                                    <mat-select #singleSelect class="form-control" formControlName="origin" (selectionChange)="checkOriginDestination()">
                                        <mat-option>
                                            <ngx-mat-select-search [(ngModel)]="searchOriginValue"
                                                [ngModelOptions]="{standalone: true}"
                                                (input)="getSearchResultsOrigin()" placeholderLabel="Search and select origin" noEntriesFoundLabel = 'No result available'></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let item of loadingPorts" [value]="item?.code?.code">
                                            {{ item?.value }}
                                        </mat-option>
                                    </mat-select>

                                    <div class="text-danger validation-message"
                                        *ngIf="enquiry.origin.invalid && ( enquiry.origin.dirty || enquiry.origin.touched)">
                                        Origin is mandatory
                                    </div>
                                    <div class="text-danger validation-message" *ngIf="samePortError">
                                        Same Port Not Allowed
                                    </div>
                                </div>

                                <!-- Destination Port -->
                                <div class="col-md-4 form-group">
                                    <label *ngIf="sea_mode_shipment" class="form-label" for="destination-port*">Destination
                                        Port*</label>
                                    <label *ngIf="air_mode_shipment" class="form-label" for="destination-port*">Destination
                                        Airport*</label>
                                    <mat-select #singleSelect class="form-control" formControlName="destination" (selectionChange)="checkOriginDestination()">
                                        <mat-option>
                                            <ngx-mat-select-search [(ngModel)]="searchDestinationValue" [ngModelOptions]="{standalone: true}"
                                            (input)="getSearchResultDestination()"
                                            placeholderLabel="Search and select destination" noEntriesFoundLabel = 'No result available'></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let item of destinationPorts" [value]="item?.code?.code">
                                            {{ item?.value }}
                                        </mat-option>
                                    </mat-select>
                                
                                    <div class="text-danger validation-message"
                                        *ngIf="enquiry.destination.invalid && ( enquiry.destination.dirty || enquiry.destination.touched)">
                                        Destination is mandatory
                                    </div>
                                    <div class="text-danger validation-message" *ngIf="samePortError">
                                        Same Port Not Allowed
                                    </div>
                                </div>
                                <!-- Type of Cargo -->
                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="type-of-cargo*">Type of Cargo*</label>
                                    <select class="form-control form-select" id="type_cargo"
                                        formControlName="typeOfCargo" (change)="changeTypeCargo('typeOfCargo')"
                                        [ngClass]="{'is-invalid': enquiry.typeOfCargo.invalid && ( enquiry.typeOfCargo.dirty || enquiry.typeOfCargo.touched) }">
                                        <option value="" selected>Select</option>
                                        <option *ngFor="let type of type_cargo" [value]="type.id">
                                            {{ type.value }}
                                        </option>
                                    </select>
                                    <div class="text-danger validation-message"
                                        *ngIf="enquiry.typeOfCargo.invalid && ( enquiry.typeOfCargo.dirty || enquiry.typeOfCargo.touched)">
                                        Type of Cargo mandatory
                                    </div>
                                </div>
                                <!-- Commodity -->
                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="commodity*">Commodity*</label>
                                    <input type="text" class="form-control" id="commodity" required="required"
                                        formControlName="commodity"
                                        [ngClass]="{'is-invalid': enquiry.commodity.invalid && ( enquiry.commodity.dirty || enquiry.commodity.touched) }">
                                    <div class="text-danger validation-message"
                                        *ngIf="enquiry.commodity.invalid && ( enquiry.commodity.dirty || enquiry.commodity.touched)">
                                        Commodity is mandatory
                                    </div>
                                </div>
                                <!-- Nature of Movement -->
                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="natureOfMovement">Nature Of Movement*</label>
                                    <select id="nature_movement" class="form-control form-select"
                                        formControlName="natureOfMovement" (change)="changeNatureOfMovement()"
                                        [ngClass]="{'is-invalid': enquiry.natureOfMovement.invalid && ( enquiry.natureOfMovement.dirty || enquiry.natureOfMovement.touched) }">
                                        <option value="" selected>Select</option>
                                        <option *ngFor="let item of nature_of_movement" [value]="item.code">
                                            {{ item.value }}
                                        </option>
                                    </select>
                                    <div class="text-danger validation-message"
                                        *ngIf="enquiry.natureOfMovement.invalid && ( enquiry.natureOfMovement.dirty || enquiry.natureOfMovement.touched) ">
                                        Nature of Goods is mandatory
                                    </div>
                                </div>
                                <!-- Nature of Good/Commodity -->
                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="nature-of-good/commodity">Nature of
                                        Good/Commodity*</label>
                                    <select id="" class="form-control form-select" formControlName="natureOfGoods"
                                        [ngClass]="{'is-invalid': enquiry.natureOfGoods.invalid && ( enquiry.natureOfGoods.dirty || enquiry.natureOfGoods.touched) }">
                                        <option value="" selected>Select</option>
                                        <option *ngFor="let item of filteredNatureOfGoods" [value]="item.id">
                                            {{ item.value }}
                                        </option>
                                    </select>
                                    <div class="text-danger validation-message"
                                        *ngIf="enquiry.natureOfGoods.invalid && ( enquiry.natureOfGoods.dirty || enquiry.natureOfGoods.touched) ">
                                        Nature of Goods is mandatory
                                    </div>
                                </div>

                                <!-- Type of Packing -->
                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="type-of-packing*">Type of Packing*</label>
                                    <select name="" id="type_packing" class="form-control form-select"
                                        formControlName="typeOfPacking"
                                        [ngClass]="{ 'is-invalid': enquiry.typeOfPacking.invalid && ( enquiry.typeOfPacking.dirty || enquiry.typeOfPacking.touched )}">
                                        <option value="" selected>Select</option>
                                        <option *ngFor="let item of type_packing" [value]="item.id">
                                            {{ item.value }}
                                        </option>
                                    </select>
                                    <small class="text-danger validation-message"
                                        *ngIf="enquiry.typeOfPacking.invalid && ( enquiry.typeOfPacking.dirty || enquiry.typeOfPacking.touched ) ">
                                        Type of Packing is mandatory
                                    </small>
                                </div>

                                <!-- Supplier Name -->
                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="supplier-name*">Supplier Name*</label>
                                    <input class="form-control" type="text" placeholder="{{ supplierName }}"
                                        id="supplier-name*" required="required" formControlName="supplierName"
                                        [ngClass]="{ 'is-invalid': enquiry.supplierName.invalid && ( enquiry.supplierName.dirty || enquiry.supplierName.touched )}" />
                                    <div class="text-danger validation-message"
                                        *ngIf="enquiry.supplierName.invalid && ( enquiry.supplierName.dirty || enquiry.supplierName.touched )">
                                        Supplier Name is mandatory
                                    </div>
                                </div>

                                <!-- Storing Type -->
                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="storing-type*">Storing Type*</label>
                                    <div class="form-check-wrap" id="storing-type*">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" id="storing-type*-stackable"
                                                value="s" formControlName="storingType" />
                                            <label class="form-check-label"
                                                for="storing-type*-stackable">Stackable</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio"
                                                id="storing-type*-non-stackable" value="n"
                                                formControlName="storingType" />
                                            <label class="form-check-label"
                                                for="storing-type*-non-stackable">Non-Stackable</label>
                                        </div>
                                    </div>
                                    <div class="text-danger text-center validation-message"
                                        *ngIf="enquiry.storingType.errors?.required">
                                        Select One
                                    </div>
                                </div>

                                <!-- Cargo Readiness Date -->
                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="cargo-readiness-date*">Cargo Readiness Date*</label>
                                    <div class="input-group">
                                        <input class="form-control" type="date" placeholder="DD/MM/YY"
                                            [min]="getTodayDate()" id="cargo-readiness-date*" required="required"
                                            formControlName="cargoReadinessDate"
                                            [ngClass]="{ 'is-invalid': enquiry.cargoReadinessDate.invalid && ( enquiry.cargoReadinessDate.dirty || enquiry.cargoReadinessDate.touched )}"
                                            (change)="validateCargoReadinessDate()" />
                                        <button class="input-group-btn invisible" type="button">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M18.2222 5H5.77778C4.79594 5 4 5.79594 4 6.77778V19.2222C4 20.2041 4.79594 21 5.77778 21H18.2222C19.2041 21 20 20.2041 20 19.2222V6.77778C20 5.79594 19.2041 5 18.2222 5Z"
                                                    stroke="#003D90" stroke-width="1.5" stroke-linecap="round"
                                                    stroke-linejoin="round"></path>
                                                <path d="M16 3V7" stroke="#003D90" stroke-width="1.5"
                                                    stroke-linecap="round" stroke-linejoin="round"></path>
                                                <path d="M8 3V7" stroke="#003D90" stroke-width="1.5"
                                                    stroke-linecap="round" stroke-linejoin="round"></path>
                                                <path d="M4 10H20" stroke="#003D90" stroke-width="1.5"
                                                    stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                        </button>
                                    </div>
                                    <div class="text-danger validation-message">
                                        <div
                                            *ngIf="enquiry.cargoReadinessDate.invalid && ( enquiry.cargoReadinessDate.dirty || enquiry.cargoReadinessDate.touched )">
                                            Date is mandatory
                                        </div>
                                        <div *ngIf="invalidOfferDate">
                                            Can't Be Smaller Than Last Submission Date
                                        </div>
                                    </div>
                                </div>
                                <!-- Project Name -->
                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="project-name">Project Name</label>
                                    <input class="form-control" type="text" placeholder="Text Input" id="project-name"
                                        formControlName="projectName" />
                                </div>
                                <!-- Project Code -->
                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="project-code">Project Code</label>
                                    <input class="form-control" type="text" placeholder="Text Input" id="project-code"
                                        formControlName="projectCode" />
                                </div>
                                <!-- Currency -->
                                <div formGroupName="currency" class="col-md-4 form-group">
                                    <label class="form-label" for="project-name">Currency*</label>
                                    <select class="form-control form-select" formControlName="id"
                                        [ngClass]="{'is-invalid': currencyGroup.get('id').invalid && ( currencyGroup.get('id').dirty || currencyGroup.get('id').touched )}">
                                        <option value="" selected>Select</option>
                                        <option *ngFor="let item of currencyPicker" [value]="item.id">
                                            {{ item.currency }}
                                        </option>
                                    </select>
                                    <div class="text-danger validation-message"
                                        *ngIf="currencyGroup.get('id').invalid && ( currencyGroup.get('id').dirty || currencyGroup.get('id').touched )">
                                        Currency is mandatory
                                    </div>
                                </div>
                                <!-- Last Date & Submission Time -->
                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="offer-last-submission-date-&amp;-time">Offer Last
                                        Submission Date* </label>
                                    <div class="input-group">
                                        <input class="form-control" type="datetime-local" placeholder="DD/MM/YY"
                                            formControlName="lastSubmissionDate" [min]="minOfferLastSubmissionDate()"
                                            [max]="maxOfferLastSubmissionDate()"
                                            [ngClass]="{ 'is-invalid': enquiry.lastSubmissionDate.invalid && (invalidOfferDate || enquiry.lastSubmissionDate.dirty || enquiry.lastSubmissionDate.touched )}"
                                            (change)="validateCargoReadinessDate()" />
                                        <button class="input-group-btn invisible" type="button">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M18.2222 5H5.77778C4.79594 5 4 5.79594 4 6.77778V19.2222C4 20.2041 4.79594 21 5.77778 21H18.2222C19.2041 21 20 20.2041 20 19.2222V6.77778C20 5.79594 19.2041 5 18.2222 5Z"
                                                    stroke="#003D90" stroke-width="1.5" stroke-linecap="round"
                                                    stroke-linejoin="round"></path>
                                                <path d="M16 3V7" stroke="#003D90" stroke-width="1.5"
                                                    stroke-linecap="round" stroke-linejoin="round"></path>
                                                <path d="M8 3V7" stroke="#003D90" stroke-width="1.5"
                                                    stroke-linecap="round" stroke-linejoin="round"></path>
                                                <path d="M4 10H20" stroke="#003D90" stroke-width="1.5"
                                                    stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                        </button>
                                    </div>
                                    <div class="text-danger validation-message">
                                        <div
                                            *ngIf="enquiry.lastSubmissionDate.invalid && (invalidOfferDate ||enquiry.lastSubmissionDate.dirty || enquiry.lastSubmissionDate.touched )">
                                            Date is mandatory
                                        </div>
                                        <div *ngIf="invalidOfferDate">
                                            Can't Be Greater Than Cargo Readiness Date
                                        </div>
                                    </div>
                                </div>
                                <!-- Internal Reference Number -->
                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="internalReferenceNumber">Internal Reference
                                        Number</label>
                                    <input class="form-control" id="internalReferenceNumber" type="text"
                                        formControlName="internalReferenceNumber" />
                                </div>

                                <!-- po number -->
                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="poNumber">PO Number</label>
                                    <input class="form-control" id="poNumber" type="text" formControlName="poNumber" />
                                </div>

                                <!-- Pick Up Incoterms -->
                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="pick-up-incoterms*">Pick Up Incoterms*</label>
                                    <select id="pickup_terms" class="form-control form-select"
                                        formControlName="pickUpIncoterms"
                                        [ngClass]="{'is-invalid': enquiry.pickUpIncoterms.invalid && ( enquiry.pickUpIncoterms.dirty || enquiry.pickUpIncoterms.touched) }"
                                        (change)="onChangePickupInco()">
                                        <option value="" selected>Select</option>
                                        <option *ngFor="let item of pickUpIncoTermsArray" [value]="item.code">
                                            {{ item.value }}
                                        </option>
                                    </select>
                                    <div class="text-danger validation-message"
                                        *ngIf="enquiry.pickUpIncoterms.invalid && ( enquiry.pickUpIncoterms.dirty || enquiry.pickUpIncoterms.touched)">
                                        Pickup Terms is mandatory
                                    </div>
                                </div>

                                <!-- contact Person -->
                                <div class="col-md-4 form-group" *ngIf="showPickupAddress">
                                    <label class="form-label" for="pickup-person-name">PickUp Person
                                        Name*</label>
                                    <input class="form-control" type="text" placeholder="Text Input"
                                        id="contact-person-name" formControlName="pickupPersonName"
                                        [ngClass]="{'is-invalid': enquiry.pickupPersonName.invalid && ( enquiry.pickupPersonName.dirty || enquiry.pickupPersonName.touched)}" />
                                    <div class="text-danger validation-message"
                                        *ngIf="enquiry.pickupPersonName.invalid && ( enquiry.pickupPersonName.dirty || enquiry.pickupPersonName.touched)">
                                        Pickup person name is mandatory
                                    </div>
                                </div>
                                <div *ngIf="showPickupAddress">
                                    <div class="row">
                                        <div formGroupName="pickupPersonAddress">
                                            <div class="row">
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label" for="street-address-1">Street Address
                                                        1*</label>
                                                    <input class="form-control" type="text" placeholder="Text Input"
                                                        id="street-address-1" formControlName="street1" (change)="validatePickupAddress()"
                                                        [ngClass]="{'is-invalid': pickupPersonAddress.get('street1').invalid && ( pickupPersonAddress.get('street1').dirty 
                                                        || pickupPersonAddress.get('street1').touched)  || pickupAddressBoolean}"
                                                        />
                                                    <small class="text-danger validation-message"
                                                        *ngIf="pickupAddressBoolean">street 1 & street 2 can't
                                                        be same</small>
                                                    <div class="text-danger validation-message"
                                                    *ngIf="pickupPersonAddress.get('street1').invalid && ( pickupPersonAddress.get('street1').dirty 
                                                    || pickupPersonAddress.get('street1').touched)">
                                                        Street 1 is mandatory
                                                    </div>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label" for="street-address-2">Street Address
                                                        2*</label>
                                                    <input class="form-control" type="text" placeholder="Text Input"
                                                        id="street-address-2" formControlName="street2"
                                                        (change)="validatePickupAddress()"
                                                        [ngClass]="{'is-invalid': pickupPersonAddress.get('street2').invalid && ( pickupPersonAddress.get('street2').dirty 
                                                        || pickupPersonAddress.get('street2').touched)  || pickupAddressBoolean}" />
                                                    <small class="text-danger validation-message"
                                                        *ngIf="pickupAddressBoolean">street 1 & street 2 can't
                                                        be same</small>
                                                    <div class="text-danger validation-message"
                                                    *ngIf="pickupPersonAddress.get('street2').invalid && ( pickupPersonAddress.get('street2').dirty 
                                                    || pickupPersonAddress.get('street2').touched)">
                                                    Street 2 is mandatory
                                                    </div>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label" for="city">City*</label>
                                                    <input class="form-control" type="text" placeholder="Enter City"
                                                        id="city" formControlName="city"
                                                        [ngClass]="{'is-invalid': pickupPersonAddress.get('city').invalid && ( pickupPersonAddress.get('city').dirty 
                                                        || pickupPersonAddress.get('city').touched) }"/>
                                                    <div class="text-danger validation-message"
                                                        *ngIf="pickupPersonAddress.get('city').invalid && ( pickupPersonAddress.get('city').dirty 
                                                        || pickupPersonAddress.get('city').touched)">
                                                        City is mandatory
                                                    </div>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label" for="state">State*</label>
                                                    <input class="form-control" type="text" placeholder="Enter State"
                                                        id="state" formControlName="state"  [ngClass]="{'is-invalid': pickupPersonAddress.get('state').invalid && ( pickupPersonAddress.get('state').dirty 
                                                        || pickupPersonAddress.get('state').touched) }"/>
                                                    <div class="text-danger validation-message"
                                                    *ngIf="pickupPersonAddress.get('state').invalid && ( pickupPersonAddress.get('state').dirty 
                                                    || pickupPersonAddress.get('state').touched)">
                                                        State is mandatory
                                                    </div>
                                                </div>
                                                <div formGroupName="country" class="col-md-4 form-group">
                                                    <label class="form-label" for="country">Country*</label>
                                                    <select id="country" name="country" formControlName="id"
                                                        class="form-control form-select" [ngClass]="{'is-invalid': pickUpAddressCountry.get('id').invalid && ( pickUpAddressCountry.get('id').dirty 
                                                        || pickUpAddressCountry.get('id').touched) }">
                                                        <option value="" disabled selected>Select Country</option>
                                                        <option [value]="count.id" *ngFor="let count of countries">
                                                            {{ count.name }}
                                                        </option>
                                                    </select>
                                                    <div class="text-danger validation-message" *ngIf="pickUpAddressCountry.get('id').invalid && ( pickUpAddressCountry.get('id').dirty 
                                                    || pickUpAddressCountry.get('id').touched)">
                                                        Country is mandatory
                                                    </div>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label" for="zip-code">Zip Code*</label>
                                                    <input class="form-control" type="text" placeholder="Enter ZipCode"
                                                        id="zip-code" formControlName="zipCode" />
                                                    <div class="text-danger validation-message"
                                                    *ngIf="pickupPersonAddress.get('zipCode').invalid && ( pickupPersonAddress.get('zipCode').dirty 
                                                    || pickupPersonAddress.get('zipCode').touched)">
                                                        Zip Code is mandatory
                                                    </div>    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Third Country Controls -->
                                <!-- exporter name -->
                                <div class="col-md-4 form-group" *ngIf="isThirdCountrySelected">
                                    <label class="form-label">Exporter Name*</label>
                                    <input class="form-control" type="text" placeholder="Text Input" id="personName"
                                        formControlName="exporterName"
                                        [ngClass]="{'is-invalid': enquiry.exporterName.invalid && (enquiry.exporterName.dirty || enquiry.exporterName.touched)}" />
                                    <div *ngIf="enquiry.exporterName.invalid && (enquiry.exporterName.dirty || enquiry.exporterName.touched)"
                                        class="text-danger validation-message">
                                        Exporter name is mandatory
                                    </div>
                                </div>

                                <!-- exporter email id -->
                                <div class="col-md-4 form-group" *ngIf="isThirdCountrySelected">
                                    <label class="form-label">Exporter Mail Id*</label>
                                    <input class="form-control" type="text" placeholder="Text Input" id="personName"
                                        formControlName="exporterMailId"  [ngClass]="{'is-invalid': enquiry.exporterMailId.invalid && 
                                        (enquiry.exporterMailId.dirty || enquiry.exporterMailId.touched)}"/>
                                    <div *ngIf="enquiry.exporterMailId.invalid && (enquiry.exporterMailId.dirty || enquiry.exporterMailId.touched)"
                                    class="text-danger validation-message">
                                        Exporter Email id is mandatory
                                    </div>
                                </div>

                                <!-- exporter contact no -->
                                <div class="col-md-4 form-group" *ngIf="isThirdCountrySelected">
                                    <label class="form-label">Exporter Contact No*</label>
                                    <input class="form-control" type="number" placeholder="Text Input" id="personName" min="0"
                                        formControlName="exporterContact" [ngClass]="{'is-invalid': enquiry.exporterContact.invalid && 
                                        (enquiry.exporterContact.dirty || enquiry.exporterContact.touched)}"/>
                                    <div *ngIf="enquiry.exporterContact.invalid && (enquiry.exporterContact.dirty || enquiry.exporterContact.touched)"
                                        class="text-danger validation-message">
                                            Exporter Contact Number is mandatory
                                    </div>
                                </div>

                                <div *ngIf="isThirdCountrySelected">
                                    <div class="row">
                                        <!-- exporter Adress -->
                                        <div formGroupName="exporterAddress">
                                            <div class="row">
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label" for="street-address-1">Street Address
                                                        1*</label>
                                                    <input class="form-control" type="text" placeholder="Text Input"
                                                        id="street-address-1" formControlName="street1"
                                                        [ngClass]="{'is-invalid': exporterAddress.get('street1').invalid && ( exporterAddress.get('street1').dirty 
                                                        || exporterAddress.get('street1').touched) }"/>
                                                    <div class="text-danger validation-message"
                                                    *ngIf="exporterAddress.get('street1').invalid && ( exporterAddress.get('street1').dirty 
                                                    || exporterAddress.get('street1').touched)">
                                                        Street 1 is mandatory
                                                    </div>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label" for="street-address-2">Street Address
                                                        2*</label>
                                                    <input class="form-control" type="text" placeholder="Text Input"
                                                        id="street-address-2" formControlName="street2" 
                                                        [ngClass]="{'is-invalid': exporterAddress.get('street2').invalid && ( exporterAddress.get('street2').dirty 
                                                        || exporterAddress.get('street2').touched) }"/>
                                                    <div class="text-danger validation-message"
                                                    *ngIf="exporterAddress.get('street2').invalid && ( exporterAddress.get('street2').dirty 
                                                    || exporterAddress.get('street2').touched)">
                                                        Street 1 is mandatory
                                                    </div>

                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label" for="city">City*</label>
                                                    <input class="form-control" type="text" placeholder="Text Input"
                                                        id="city" formControlName="city" [ngClass]="{'is-invalid': exporterAddress.get('city').invalid && 
                                                        ( exporterAddress.get('city').dirty || exporterAddress.get('city').touched) }"/>
                                                    <div class="text-danger validation-message"
                                                    *ngIf="exporterAddress.get('city').invalid && ( exporterAddress.get('city').dirty 
                                                    || exporterAddress.get('city').touched)">
                                                        City is mandatory
                                                    </div>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label" for="state">State*</label>
                                                    <input class="form-control" type="text" placeholder="Text Input"
                                                        id="state" formControlName="state" [ngClass]="{'is-invalid': exporterAddress.get('state').invalid && 
                                                        ( exporterAddress.get('state').dirty || exporterAddress.get('state').touched) }" />
                                                    <div class="text-danger validation-message"
                                                    *ngIf="exporterAddress.get('state').invalid && ( exporterAddress.get('state').dirty 
                                                    || exporterAddress.get('state').touched)">
                                                        State is mandatory
                                                    </div>
                                                </div>
                                                <div formGroupName="country" class="col-md-4 form-group">
                                                    <label class="form-label" for="country">Country*</label>
                                                    <select id="country" name="country" class="form-control form-select"
                                                        formControlName="id" [ngClass]="{'is-invalid': exporterAddressCountry.get('id').invalid && ( exporterAddressCountry.get('id').dirty 
                                                        || exporterAddressCountry.get('id').touched) }">
                                                        <option value="" disabled selected>Select Country</option>
                                                        <option [value]="count.id" *ngFor="let count of countries">
                                                            {{ count.name }}
                                                        </option>
                                                    </select>
                                                    <div class="text-danger validation-message" *ngIf="exporterAddressCountry.get('id').invalid && ( exporterAddressCountry.get('id').dirty 
                                                    || exporterAddressCountry.get('id').touched)">
                                                        Country is mandatory
                                                    </div>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label" for="zipCode">Zip Code*</label>
                                                    <input class="form-control" type="text" placeholder="Enter ZipCode"
                                                        id="zipCode" formControlName="zipCode" [ngClass]="{'is-invalid': exporterAddress.get('zipCode').invalid && 
                                                        ( exporterAddress.get('zipCode').dirty || exporterAddress.get('zipCode').touched) }"/>
                                                    <div class="text-danger validation-message"
                                                    *ngIf="exporterAddress.get('zipCode').invalid && ( exporterAddress.get('zipCode').dirty 
                                                    || exporterAddress.get('zipCode').touched)">
                                                        Zip Code is mandatory
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Delivery Incoterms -->
                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="delivery-incoterms*">Delivery Incoterms*</label>
                                    <select name="" id="delivery_terms" class="form-control form-select"
                                        formControlName="deliveryIncoterms" (change)="onChangeDelivaryTerm()"
                                        [ngClass]="{'is-invalid': enquiry.deliveryIncoterms.invalid && ( enquiry.deliveryIncoterms.dirty || enquiry.deliveryIncoterms.touched) }">
                                        <option value="" selected>Select</option>
                                        <option *ngFor="let item of dropOffIncoTermsArray" [value]="item.code">
                                            {{ item.value }}
                                        </option>
                                    </select>
                                    <div class="text-danger validation-message"
                                        *ngIf="enquiry.deliveryIncoterms.invalid && ( enquiry.deliveryIncoterms.dirty || enquiry.deliveryIncoterms.touched)">
                                        Delivery Terms is mandatory
                                    </div>
                                </div>

                                <!--Delivery contact Person -->
                                <div class="col-md-4 form-group" *ngIf="delivaryAddress">
                                    <label class="form-label">Delivery Person Name*</label>
                                    <input class="form-control" type="text" placeholder="Text Input" id="personName"
                                        formControlName="deliveryPersonName"
                                        [ngClass]="{'is-invalid': enquiry.deliveryPersonName.invalid && ( enquiry.deliveryPersonName.dirty || enquiry.deliveryPersonName.touched)}" />
                                    <div class="text-danger validation-message"
                                        *ngIf="enquiry.deliveryPersonName.invalid && ( enquiry.deliveryPersonName.dirty || enquiry.deliveryPersonName.touched)">
                                        Delivery person name is mandatory
                                    </div>
                                </div>
                                <div *ngIf="delivaryAddress">
                                    <div class="row">
                                        <!-- Adress -->
                                        <div formGroupName="deliveryPersonAddress">
                                            <div class="row">
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label" for="street-address-1">Street Address
                                                        1*</label>
                                                    <input class="form-control" type="text" placeholder="Text Input"
                                                        id="street-address-1" formControlName="street1"
                                                        (change)="validateDeliveryAddress()"
                                                        [ngClass]="{'is-invalid': deliveryPersonAddress.get('street1').invalid && ( deliveryPersonAddress.get('street1').dirty 
                                                        || deliveryPersonAddress.get('street1').touched)  || deliveryAddressBoolean}" />
                                                    <small class="text-danger validation-message"
                                                        *ngIf="this.deliveryAddressBoolean">street 1 & street 2 can't
                                                        be same</small>
                                                    <div class="text-danger validation-message"
                                                    *ngIf="deliveryPersonAddress.get('street1').invalid && ( deliveryPersonAddress.get('street1').dirty 
                                                    || deliveryPersonAddress.get('street1').touched)">
                                                        Street 1 is mandatory
                                                    </div>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label" for="street-address-2">Street Address
                                                        2*</label>
                                                    <input class="form-control" type="text" placeholder="Text Input"
                                                        id="street-address-2" formControlName="street2"
                                                        (change)="validateDeliveryAddress()"
                                                        [ngClass]="{'is-invalid': deliveryPersonAddress.get('street2').invalid && ( deliveryPersonAddress.get('street2').dirty 
                                                        || deliveryPersonAddress.get('street2').touched)  || deliveryAddressBoolean}" />
                                                    <small class="text-danger validation-message"
                                                        *ngIf="this.deliveryAddressBoolean">street 1 & street 2 can't
                                                        be same</small>
                                                    <div class="text-danger validation-message"
                                                    *ngIf="deliveryPersonAddress.get('street2').invalid && ( deliveryPersonAddress.get('street2').dirty 
                                                    || deliveryPersonAddress.get('street2').touched)">
                                                        Street 2 is mandatory
                                                    </div>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label" for="city">City*</label>
                                                    <input class="form-control" type="text" placeholder="Text Input"
                                                        id="city" formControlName="city" [ngClass]="{'is-invalid': deliveryPersonAddress.get('city').invalid && ( deliveryPersonAddress.get('city').dirty 
                                                        || deliveryPersonAddress.get('city').touched) }"/>
                                                    <div class="text-danger validation-message"
                                                    *ngIf="deliveryPersonAddress.get('city').invalid && ( deliveryPersonAddress.get('city').dirty 
                                                    || deliveryPersonAddress.get('city').touched)">
                                                        Street 2 is mandatory
                                                    </div>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label" for="state">State*</label>
                                                    <input class="form-control" type="text" placeholder="Text Input"
                                                        id="state" formControlName="state" [ngClass]="{'is-invalid': deliveryPersonAddress.get('state').invalid && ( deliveryPersonAddress.get('state').dirty 
                                                        || deliveryPersonAddress.get('state').touched) }"/>
                                                    <div class="text-danger validation-message"
                                                    *ngIf="deliveryPersonAddress.get('state').invalid && ( deliveryPersonAddress.get('state').dirty 
                                                    || deliveryPersonAddress.get('state').touched)">
                                                        State is mandatory
                                                    </div>
                                                </div>
                                                <div formGroupName="country" class="col-md-4 form-group">
                                                    <label class="form-label" for="country">Country*</label>
                                                    <select id="country" name="country" formControlName="id"
                                                        class="form-control form-select" [ngClass]="{'is-invalid': dropOffAddressCountry.get('id').invalid && ( dropOffAddressCountry.get('id').dirty 
                                                        || dropOffAddressCountry.get('id').touched) }">
                                                        <option value="" disabled selected>Select Country</option>
                                                        <option [value]="count.id" *ngFor="let count of countries">
                                                            {{ count.name }}
                                                        </option>
                                                    </select>
                                                    <div class="text-danger validation-message" *ngIf="dropOffAddressCountry.get('id').invalid && ( dropOffAddressCountry.get('id').dirty 
                                                    || dropOffAddressCountry.get('id').touched)">
                                                        Country is mandatory
                                                    </div>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label" for="zipCode">Zip Code*</label>
                                                    <input class="form-control" type="text" placeholder="Enter ZipCode"
                                                        id="zipCode" formControlName="zipCode" [ngClass]="{'is-invalid': deliveryPersonAddress.get('zipCode').invalid && 
                                                        ( deliveryPersonAddress.get('zipCode').dirty || deliveryPersonAddress.get('zipCode').touched) }"/>
                                                    <div class="text-danger validation-message"
                                                    *ngIf="deliveryPersonAddress.get('zipCode').invalid && ( deliveryPersonAddress.get('zipCode').dirty 
                                                    || deliveryPersonAddress.get('zipCode').touched)">
                                                        Zip Code is mandatory
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 form-group" *ngIf="showTerminal">
                                    <label class="form-label">Destination Terminal*</label>
                                    <input class="form-control" type="text" placeholder="Text Input"
                                        id="destinationTerminal" formControlName="destinationTerminal"
                                        [ngClass]="{'is-invalid': enquiry.destinationTerminal.invalid && ( enquiry.destinationTerminal.dirty || enquiry.destinationTerminal.touched)}" />
                                    <div class="text-danger validation-message"
                                        *ngIf="enquiry.destinationTerminal.invalid && ( enquiry.destinationTerminal.dirty || enquiry.destinationTerminal.touched)">
                                        Destination terminal is mandatory
                                    </div>
                                </div>

                                <!-- SEA HS Code -->
                                <div *ngIf="sea_mode_shipment" class="col-md-4 form-group">
                                    <label class="form-label" for="hs-code">HS Code*</label>
                                    <input class="form-control" type="text" placeholder="Text Input" id="hs-code"
                                        formControlName="hsCode"
                                        [ngClass]="{'is-invalid': enquiry.hsCode.invalid && ( enquiry.hsCode.dirty || enquiry.hsCode.touched )}" />
                                    <div class="text-danger validation-message"
                                        *ngIf="enquiry.hsCode.invalid && ( enquiry.hsCode.dirty || enquiry.hsCode.touched )">
                                        HS Code is mandatory
                                    </div>
                                </div>

                                <div  class="col-md-4 form-group">
                                    <label class="form-label" for="demurrage">Demurrage* (Days)</label>
                                    <input class="form-control" type="number" placeholder="Enter Days" id="demurrage"
                                        formControlName="demurrage" min="0"
                                        [ngClass]="{'is-invalid': enquiry.demurrage.invalid && ( enquiry.demurrage.dirty || enquiry.demurrage.touched )}" />
                                    <div class="text-danger validation-message"
                                        *ngIf="enquiry.demurrage.invalid && ( enquiry.demurrage.dirty || enquiry.demurrage.touched )">
                                        Demurrage is mandatory
                                    </div>
                                </div>

                                <div *ngIf="sea_mode_shipment" class="col-md-4 form-group">
                                    <label class="form-label" for="detention">Detention* (Days)</label>
                                    <input class="form-control" type="number" placeholder="Enter Days" id="detention"
                                        formControlName="detention" min="0"
                                        [ngClass]="{'is-invalid': enquiry.detention.invalid && ( enquiry.detention.dirty || enquiry.detention.touched )}" />
                                    <div class="text-danger validation-message"
                                        *ngIf="enquiry.detention.invalid && ( enquiry.detention.dirty || enquiry.detention.touched )">
                                        Detention is mandatory
                                    </div>
                                </div>

                            </div>
                            <div class="row justify-content-center">
                                <div class="col-md-6 form-group pt-4 pt-lg-5">
                                    <button class="btn btn-success w-100" type="button" data-target="item-2"
                                        (click)="nextParticularsPage()">NEXT</button>
                                </div>
                            </div>
                            <div class="row" align="right">
                                <div class="col-lg-12">
                                    <p class="bg-white text-dark">
                                        Note: * denotes mandatory fields
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane" id="item-7">
                            <h3 class="text-center">Shipment Particulars</h3>
                            <!-- SEA Container Load Type -->
                            <div *ngIf="sea_mode_shipment">
                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="container-load-type">Container Load Type</label>
                                    <select class="form-control form-select" id="container_load_type"
                                        formControlName="containerLoadType" (change)="changeContainerLoadType()"
                                        [ngClass]="{'is-invalid': enquiry.containerLoadType.invalid && ( enquiry.containerLoadType.dirty || enquiry.containerLoadType.touched )}">
                                        <option *ngFor="let container of container_load_type" [value]="container.id"
                                            selected>
                                            {{ container.value }}
                                        </option>
                                    </select>
                                    <div class="text-danger validation-message"
                                        *ngIf="enquiry.containerLoadType.invalid && ( enquiry.containerLoadType.dirty || enquiry.containerLoadType.touched )">
                                        Container Load Type is mandatory
                                    </div>
                                </div>
                            </div>

                            <!-- fullContainerLoadBool -->
                            <div *ngIf="sea_mode_shipment">
                                <div class="row mb-3">
                                    <div class="col-md-9 mt-2" style="padding-left: 25%;">
                                        <h4 *ngIf="fullContainerLoadBool" class="mb-0 text-center">Container Details
                                        </h4>
                                    </div>
                                    <div class="col-md-3" style="text-align:right">
                                        <button *ngIf="fullContainerLoadBool"
                                            class="btn-add-new text-dark btn-outline-success" (click)="addSeaFCL()"
                                            type="button">
                                            <i class="fa fa-plus me-1"></i><span>Container</span>
                                        </button>
                                    </div>
                                </div>
                                <div class="table-responsive">
                                    <table *ngIf="fullContainerLoadBool"
                                        class="table table-borderless text-center table-head-dark table-head-condensed table-head-py-3">
                                        <thead>
                                            <tr>
                                                <th>Type
                                                </th>
                                                <th>Size
                                                </th>
                                                <th>Length
                                                </th>
                                                <th>Width
                                                </th>
                                                <th>Height
                                                </th>
                                                <th>Flat UOM
                                                </th>
                                                <th>Quantity
                                                </th>
                                                <th>Weight/Container
                                                </th>
                                                <th>UOM
                                                </th>
                                                <th *ngIf="container_details.length > 1">Actions
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody formArrayName="containers">
                                            <tr *ngFor="let item of container_details.controls; let i=index; let l=last"
                                                [formGroupName]="i">
                                                <td>
                                                    <mat-form-field appearance="fill">
                                                        <select matNativeControl class="form-control-plaintext"
                                                            id="container_type" formControlName="type"
                                                            (change)="getContianerType(i)">
                                                            <option value="" selected>Select</option>
                                                            <option *ngFor="let container of container_type"
                                                                [value]="container.id">
                                                                {{ container.value }}
                                                            </option>
                                                        </select>
                                                    </mat-form-field>
                                                </td>
                                                <td >
                                                    <mat-form-field appearance="standard">
                                                        <input matInput class="form-control-plaintext"
                                                            placeholder="Enter Size" formControlName="size" type="text"
                                                            list="containerSize">
                                                        <datalist id="containerSize">
                                                            <option value="" selected>Select</option>
                                                            <option *ngFor="let item of containerSize"
                                                                [value]="item.size">
                                                                {{ item.size }}
                                                            </option>
                                                        </datalist>
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <!-- <input type="number"> -->
                                                    <mat-form-field appearance="standard">
                                                        <input matInput class="form-control-plaintext" type="number"
                                                            formControlName="length" placeholder="Enter Length" id=""
                                                            min="0" />
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <!-- <input type="number"> -->
                                                    <mat-form-field appearance="standard">
                                                        <input matInput class="form-control-plaintext" type="number"
                                                            formControlName="width" placeholder="Enter Width" id=""
                                                            min="0" />
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <!-- <input type="number"> -->
                                                    <mat-form-field appearance="standard">
                                                        <input matInput class="form-control-plaintext" type="number"
                                                            formControlName="height" placeholder="Enter Height" id=""
                                                            min="0" />
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <mat-form-field appearance="fill">
                                                        <select matNativeControl formControlName="flatRackUOM"
                                                            class="form-control-plaintext form-select">
                                                            <option value="" selected>Select</option>
                                                            <option *ngFor="let item of dimensionsUOM"
                                                                [value]="item.name">
                                                                {{ item.name }}
                                                            </option>
                                                        </select>
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <mat-form-field appearance="standard">
                                                        <input matInput class="form-control-plaintext" type="number"
                                                            formControlName="quantity" placeholder="Enter Quantity"
                                                            id="" min="0" />
                                                    </mat-form-field>
                                                </td>
                                                
                                                <td>
                                                    <mat-form-field appearance="standard">
                                                        <input matInput class="form-control-plaintext" type="number"
                                                            formControlName="weight" placeholder="Enter Weight" id=""
                                                            min="0" />
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <mat-form-field appearance="fill">
                                                        <select matNativeControl formControlName="uom"
                                                            class="form-control-plaintext form-select">
                                                            <option value="" selected>Select</option>
                                                            <option *ngFor="let item of grossWeightUom"
                                                                [value]="item.name">
                                                                {{ item.name }}
                                                            </option>
                                                        </select>
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <button class="btn-outline-danger fa fa-trash"
                                                        (click)="deleteSeaFCL(i)" *ngIf="container_details.length > 1">
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <!-- lessContainerLoadBool -->
                            <div *ngIf="sea_mode_shipment">
                                <div class="row mb-3">
                                    <div class="col-md-9 mt-2" style="padding-left: 25%;">
                                        <h4 *ngIf="lessContainerLoadBool" class="mb-0 text-center">Package Details
                                        </h4>
                                    </div>
                                    <div class="col-md-3" style="text-align:right">
                                        <button *ngIf="lessContainerLoadBool"
                                            class="btn-add-new text-dark btn-outline-success" type="button"
                                            (click)="addSeaHCL()">
                                            <i class="fa fa-plus me-1"></i><span>Package</span>
                                        </button>
                                    </div>
                                </div>
                                <div style="overflow-x: auto;" class="table-responsive">
                                    <table *ngIf="lessContainerLoadBool"
                                        class="table table-borderless text-center table-head-dark table-head-condensed table-head-py-3">
                                        <thead>
                                            <tr>
                                                <!-- <th nowrap>Type
                                                </th> -->
                                                <th>Quantity
                                                </th>
                                                <th>Length
                                                </th>
                                                <th>Width
                                                </th>
                                                <th>Height
                                                </th>
                                                <th nowrap>Unit of measurement
                                                </th>
                                                <th nowrap>Gross Weight
                                                </th>
                                                <th nowrap>Gross Weight UOM
                                                </th>
                                                <th nowrap>Total Volume
                                                </th>
                                                <th nowrap *ngIf="package_details.length > 1">Actions
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody style="overflow-x: auto;" formArrayName="shipmentPackages">
                                            <tr *ngFor="let item of package_details.controls; let i=index; let l=last"
                                                [formGroupName]="i">
                                                <!-- <td>
                                                    <mat-form-field appearance="fill">
                                                        <select matNativeControl
                                                            class="form-control-plaintext form-select"
                                                            formControlName="type">
                                                            <option value="" selected>Select</option>
                                                            <option *ngFor="let item of container_type"
                                                                [value]="item.id">
                                                                {{ item.value }}
                                                            </option>
                                                        </select>
                                                    </mat-form-field>
                                                </td> -->
                                                <td>
                                                    <mat-form-field appearance="standard">
                                                        <input matInput class="form-control-plaintext"
                                                            formControlName="quantity" type="number"
                                                            placeholder="Enter Quantity"
                                                            (change)="calculateTotalVolume()" min="0" />
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <mat-form-field appearance="standard">
                                                        <input matInput class="form-control-plaintext"
                                                            formControlName="length" type="number"
                                                            placeholder="Enter Length" (change)="calculateTotalVolume()"
                                                            min="0" />
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <mat-form-field appearance="standard">
                                                        <input matInput class="form-control-plaintext"
                                                            formControlName="width" type="number"
                                                            placeholder="Enter Width" (change)="calculateTotalVolume()"
                                                            min="0" />
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <mat-form-field appearance="standard">
                                                        <input matInput class="form-control-plaintext"
                                                            formControlName="height" type="number"
                                                            placeholder="Enter Height" (change)="calculateTotalVolume()"
                                                            min="0" />
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <mat-form-field appearance="fill">
                                                        <select matNativeControl
                                                            class="form-control-plaintext form-select"
                                                            formControlName="dimensionsUOM"
                                                            (change)="calculateTotalVolume()">
                                                            <option *ngFor="let item of dimensionsUOM"
                                                                [value]="item.name">
                                                                {{ item.name }}
                                                            </option>
                                                        </select>
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <mat-form-field appearance="standard">
                                                        <input matInput class="form-control-plaintext"
                                                            (change)="convertToMTsea()" formControlName="grossWeight"
                                                            type="number" placeholder="Enter Input" min="0" />
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <mat-form-field appearance="fill">
                                                        <select matNativeControl
                                                            class="form-control-plaintext form-select"
                                                            formControlName="weightUOM" (change)="convertToMTsea()">
                                                            <option *ngFor="let item of grossWeightUom"
                                                                [value]="item.name">
                                                                {{ item.name }}
                                                            </option>
                                                        </select>
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <mat-form-field appearance="standard">
                                                        <input matInput class="form-control-plaintext" type="number"
                                                            placeholder="Auto-calculated" formControlName="totalVolume"
                                                            min="0" />
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <button class="btn-outline-danger fa fa-trash"
                                                        (click)="deleteSeaHCL(i)" *ngIf="package_details.length > 1">
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot class="table-success">
                                            <tr>
                                                <th>TOTAL</th>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td nowrap *ngIf="finalSeaWeightBool; else gross">
                                                    Chargable Weight</td>
                                                <ng-template #gross>
                                                    <td></td>
                                                </ng-template>
                                                <td>{{ totalGrossWeightLCL | number: '1.2-3' }} {{ finalSeaWeightUom }}
                                                </td>
                                                <td nowrap *ngIf="!finalSeaWeightBool; else volume">
                                                    Chargable Weight</td>
                                                <ng-template #volume>
                                                    <td></td>
                                                </ng-template>
                                                <td>{{ totalVolumeWeightLCL | number: '1.2-4' }} CBM</td>
                                                <td></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>

                            <!-- BreakBulk Option -->
                            <div *ngIf="breakBulkBoolOption">
                                <div class="form-check-wrap">
                                    <div class="col-md-6">
                                        <label class="font-label m-2">Break Bulk ?</label>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" title="Yes" [value]="true"
                                                formControlName="break_bulk_label" name="break_bulk_label"
                                                (click)="openBreakBulkTable()"> Yes
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" title="No" [value]="false"
                                                checked formControlName="break_bulk_label" name="break_bulk_label"
                                                (click)="closeBreakBulkTable()"> No
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- breakbulk -->
                            <div *ngIf="breakbulkBool">
                                <div class="row mb-3">
                                    <div class="col-md-8" style="padding-left: 25%;">
                                        <h4 class="mb-0 text-center">Break Bulk Details</h4>
                                    </div>
                                    <div class="col-md-3 mt-2 filename">
                                        <div *ngFor="let item of breakBulkFile;index">
                                            <button (click)="downloadPackageslip(item)"><a
                                                    class="anchor-tag">{{item.name}}</a></button>
                                            <button *ngIf="item.artifactId">
                                                <i class="fa fa-trash color-blue" title="Delete"
                                                    (click)="deletePackagSlipServer(item.artifactId,index)"></i>
                                            </button>
                                            <button *ngIf="!item.artifactId">
                                                <i class="fa fa-trash color-blue" title="Delete"
                                                    (click)="deletePacakgeslip(index)"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-md-1">
                                        <button class="btn-outline-success" (click)="file.click()" title="Upload File">
                                            <input type="file" class="file-input" #file (change)="onChange($event)"
                                                accept=".csv, .xlsx">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                fill="currentColor" class="bi bi-upload" viewBox="0 0 16 16">
                                                <path
                                                    d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                <path
                                                    d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div class="table-responsive">
                                    <table id="breakbulk"
                                        class="table table-borderless text-center table-head-dark table-head-condensed table-head-py-3">
                                        <thead>
                                            <tr>
                                                <th>Total Net Weight
                                                </th>
                                                <th>Total Gross Weight
                                                </th>
                                                <th>UOM</th>
                                                <th>Total Volume
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody formArrayName="breakBulks">
                                            <tr *ngFor="let item of break_bulk_details.controls; let i=index; let l=last"
                                                [formGroupName]="i">
                                                <td>
                                                    <mat-form-field appearance="standard">
                                                        <input matInput class="form-control-plaintext"
                                                            formControlName="totalnetWeight" type="number"
                                                            placeholder="Enter Net Weight" id="" min="0" />
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <mat-form-field appearance="standard">
                                                        <input matInput class="form-control-plaintext" type="number"
                                                            formControlName="totalGrossWeight"
                                                            (change)="calculateBreakBulkWeight(i)"
                                                            placeholder="Enter Gross Weight" id="" min="0" />
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <mat-form-field appearance="fill">
                                                        <select matNativeControl
                                                            class="form-control-plaintext form-select"
                                                            formControlName="uom"
                                                            (change)="calculateBreakBulkWeight(i)">
                                                            <option value="" selected>Select</option>
                                                            <option *ngFor="let item of grossWeightUom"
                                                                [value]="item.name">
                                                                {{ item.name }}
                                                            </option>
                                                        </select>
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <mat-form-field appearance="standard">
                                                        <input matInput class="form-control-plaintext" type="number"
                                                            formControlName="totalVolume"
                                                            (change)="calculateBreakBulkWeight(i)"
                                                            placeholder="Enter Total Volume" id="" min="0" />
                                                    </mat-form-field>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot class="table-success">
                                            <tr>
                                                <td *ngIf="totalBreakBulkWeight > totalBreakBulkVolume; else breakWeight"
                                                    nowrap>Chargable Weight</td>
                                                <ng-template #breakWeight>
                                                    <td></td>
                                                </ng-template>
                                                <td>{{ totalBreakBulkWeight | number: '1.2-3' }} MT</td>
                                                <td *ngIf="totalBreakBulkVolume > totalBreakBulkWeight; else breakVolume"
                                                    nowrap>Chargable Weight</td>
                                                <ng-template #breakVolume>
                                                    <td></td>
                                                </ng-template>
                                                <td>{{ totalBreakBulkVolume | number: '1.2-4' }} CBM</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>

                            <!-- Air Package details -->
                            <div *ngIf="air_mode_shipment">
                                <div class="position-relative d-flex flex-wrap align-items-center add-new-wrap mb-3">
                                    <div class="flex-grow-1">
                                        <h4 class="mb-0 text-center">Package Details</h4>
                                    </div>
                                    <div class="position-absolute end-0 top-50 translate-middle-y">
                                        <button class="btn-add-new text-dark btn-outline-success" (click)="addAirRow()"
                                            type="button">
                                            <i class="fa fa-plus me-1"></i><span>Package</span>
                                        </button>
                                    </div>
                                </div>
                                <div class="table-responsive">
                                    <table
                                        class="table table-borderless text-center table-head-dark table-head-condensed table-head-py-3">
                                        <thead>
                                            <tr>
                                                <th>Quantity
                                                </th>
                                                <th>Length
                                                </th>
                                                <th>Width
                                                </th>
                                                <th>Height
                                                </th>
                                                <th>Unit of measurement
                                                </th>
                                                <th>Gross Weight
                                                </th>
                                                <th>Total Gross Weight UOM
                                                </th>
                                                <th>Total Volume
                                                </th>
                                                <th *ngIf="package_details.length > 1">Actions
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody formArrayName="shipmentPackages">
                                            <tr *ngFor="let item of package_details.controls; let i=index; let l=last"
                                                [formGroupName]="i">
                                                <td>
                                                    <mat-form-field appearance="standard">
                                                        <input matInput formControlName="quantity" type="number"
                                                            placeholder="Enter Quantity" min="0"
                                                            (change)="calculateAirVolume()" />
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <mat-form-field appearance="standard">
                                                        <input matInput formControlName="length" type="number"
                                                            placeholder="Enter Length" min="0"
                                                            (change)="calculateAirVolume()" />
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <mat-form-field appearance="standard">
                                                        <input matInput formControlName="width" type="number"
                                                            placeholder="Enter Width" min="0"
                                                            (change)="calculateAirVolume()" />
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <mat-form-field appearance="standard">
                                                        <input matInput formControlName="height" type="number"
                                                            placeholder="Enter Height" min="0"
                                                            (change)="calculateAirVolume()" />
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <mat-form-field appearance="fill">
                                                        <select matNativeControl formControlName="dimensionsUOM"
                                                            (change)="calculateAirVolume()">
                                                            <option *ngFor="let item of dimensionsUOM"
                                                                [value]="item.name">
                                                                {{ item.name }}
                                                            </option>
                                                        </select>
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <mat-form-field appearance="standard">
                                                        <input matInput formControlName="grossWeight" type="number"
                                                            placeholder="Enter Input" min="0"
                                                            (change)="convertToMTAIR()" />
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <!-- (change)="calculateTotalVolume(i)" -->
                                                    <mat-form-field appearance="fill">
                                                        <select matNativeControl formControlName="weightUOM"
                                                            (change)="convertToMTAIR()">
                                                            <option *ngFor="let item of airGrossWeightUom"
                                                                [value]="item.name">
                                                                {{ item.name }}
                                                            </option>
                                                        </select>
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <mat-form-field appearance="standard">
                                                        <input matInput type="number" formControlName="totalVolume"
                                                            placeholder="Enter Volume" min="0" />
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <button class="btn-outline-danger fa fa-trash"
                                                        (click)="deleteAirRow(i)" *ngIf="package_details.length > 1">
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot class="table-success">
                                            <tr>
                                                <th>TOTAL</th>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td nowrap *ngIf="finalAirWeightBool; else gross_air">
                                                    Chargable Weight</td>
                                                <ng-template #gross_air>
                                                    <td></td>
                                                </ng-template>
                                                <td>{{ totalGrossWeightAIR | number: '1.2-3' }} {{ finalAirWeightUom }}
                                                </td>
                                                <td nowrap *ngIf="!finalAirWeightBool; else volume_air">Chargable Weight
                                                </td>
                                                <ng-template #volume_air>
                                                    <td></td>
                                                </ng-template>
                                                <td>{{ totalVolumeWeightAIR | number: '1.2-4' }} {{ finalAirWeightUom }}
                                                </td>
                                                <td></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>

                            <div class="row justify-content-center">
                                <div class="col-md-6 form-group pt-4 pt-lg-5">
                                    <button class="btn btn-success w-100" type="button" data-target="item-3"
                                        (click)="nextVendorAssignmentPage()">NEXT</button>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane" id="item-8">
                            <h3 class="text-center">Vendor Assignment</h3>

                            <div class="row">
                                <div class="col-lg-6 form-group">
                                    <label class="form-label" for="vendorType">Vendor Type :</label>
                                    <select id="vendorType" class="form-control form-select"
                                        [(ngModel)]="vendorBusinessType" [ngModelOptions]="{standalone: true}"
                                        (change)="filterVendorData()">
                                        <option value="" selected>ALL</option>
                                        <option *ngFor="let business of vendorBusinessTypeList" [value]="business.id">
                                            {{ business.value }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-lg-6 form-group">
                                    <label class="form-label">Search :</label>
                                    <input class="form-control" type="text" (keyup)="applyFilter($event)"
                                        placeholder="Search" #input>
                                </div>
                            </div>


                            <div class="table-responsive">
                                <table
                                    class="table table-borderless table-striped table-head-dark table-head-condensed table-head-py-3"
                                    mat-table [dataSource]="dataSource" matSort matSortDisableClear>
                                    <ng-container matColumnDef="actions">
                                        <th mat-header-cell class="text-white" *matHeaderCellDef>
                                            Actions
                                            <input class="form-check-input mt-0" id="actions" type="checkbox" (click)="addAllVendors($event)">
                                        </th>
                                        <td mat-cell *matCellDef="let item; let i = index">
                                            <input class="form-check-input" type="checkbox" [(ngModel)]="item.flag"
                                                [ngModelOptions]="{standalone: true}"
                                                (change)="addVendors(item.id, i, item.flag)" id="check">
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="vendorName">
                                        <th mat-header-cell class="text-white" *matHeaderCellDef mat-sort-header> Vendor
                                            Name
                                        </th>
                                        <td mat-cell *matCellDef="let row">{{ row.name }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="businessType">
                                        <th mat-header-cell class="text-white" *matHeaderCellDef mat-sort-header>
                                            Business Type
                                        </th>
                                        <td mat-cell *matCellDef="let row">{{ row.businessType?.value }}</td>
                                    </ng-container>

                                    <tr class="text-left bg-dark" mat-header-row *matHeaderRowDef="displayedColumns">
                                    </tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                                    <tr class="mat-row" *matNoDataRow>
                                        <td class="mat-cell text-center text-danger" colspan="8">
                                            <h5>Data is Not Available</h5>
                                        </td>
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[25, 50, 100]" [length]="vendorsLength"
                                    showFirstLastButtons (page)="onPageChange($event)">
                                </mat-paginator>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-md-6 form-group pt-4 pt-lg-5">
                                    <button class="btn btn-success w-100" type="button" data-target="item-4"
                                        (click)="nextTermsPage()">NEXT</button>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="item-9">
                            <div class="row justify-content-center align-items-center">
                                <div class="col-lg-9">
                                    <h3 class="text-center">Terms &amp; Conditions</h3>
                                </div>
                                <div class="col-lg-3">
                                    <button style="float: right; height: 45px;"
                                        class="text-dark text-small btn-outline-success" (click)="addTermsCondition()">
                                        <i class="fa fa-plus me-1" aria-hidden="true"></i>
                                        <span class="">Terms & Conditions</span>
                                    </button>
                                </div>
                            </div>
                            <br>
                            <div formArrayName="terms">
                                <div class="row mb-4 justify-content-center align-items-center"
                                    *ngFor="let item of termsConditions.controls; index as i" [formGroupName]="i">
                                    <div class="col-lg-11">
                                        <input type="text" formControlName="label" class="form-control terms-label"
                                            placeholder="Enter Label Here">
                                        <textarea class="form-control" rows="6"
                                            placeholder="Type your terms &amp; Conditions Here"
                                            formControlName="content" id="terms-&amp;-conditions">
                                        </textarea>
                                    </div>
                                    <div class="col-lg-1 mb-5">
                                        <button *ngIf="termsConditions.length > 1" class="terms-delete-btn"
                                            (click)="deleteTermsCondition(i)">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                <path
                                                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path fill-rule="evenodd"
                                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <h4 class="text-center">OR</h4>
                            <div class="row mt-4">
                                <div class="col-lg-6 justify-content-center align-items-center">
                                    <ngx-file-drop dropZoneClassName="file-drop-2" dropZoneLabel="Drop files here"
                                        (onFileDrop)="addFiles($event)" accept=".jpg, .png, .pdf, .docx, .txt, .jpeg">
                                        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                                            <div class="row mt-5 pt-5 text-center">
                                                <div class="col-lg-12 pt-5 mt-5">
                                                    <i class="color-green far fa-file-alt fa-3x"></i>
                                                </div>
                                                <div class="col-lg-12 pt-2 pb-2">
                                                    Upload Multiple Files By Drag & Drop OR Click Browse Files
                                                </div>
                                                <div class="col-lg-12">
                                                    <button class="btn btn-success" type="button"
                                                        (click)="openFileSelector()">Browse Files</button>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ngx-file-drop>
                                </div>
                                <div class="col-lg-6">
                                    <div class="w3-card-4 note-border">
                                        <div class="card-header note-bg">
                                            <h3 class="text-white text-center">Notes</h3>
                                        </div>
                                        <div class="card-body bg-white">
                                            <ul>
                                                <li class="pb-2">Allowed File are .jpg, .png, .pdf, .docx, .txt, .jpeg,
                                                    .doc , .xls , .xlsx</li>
                                                <li class="pb-2">Upload Multiple Files By Drag & Drop OR Click Browse
                                                    Files</li>
                                                <li class="pb-2">Max size per file 2MB</li>
                                                <li class="pb-2">Max size of all files 10MB</li>
                                                <li class="pb-2">Use Delete button
                                                    <span>
                                                        <i class="fa fa-trash color-blue"></i>
                                                    </span>
                                                    to remove the uploaded file
                                                </li>
                                                <li class="pb-2">
                                                    Terms & Conditions is mandatory field. Either upload the file or
                                                    enter the details.
                                                </li>
                                                <li class="pb-2">Labels to be provided for Each File, Terms & Conditions
                                                </li>
                                                <li class="pb-2">
                                                    Document will be uploaded with SUBMIT/UPDATE button.
                                                </li>
                                                <li class="pb-2">
                                                    Use
                                                    <span>
                                                        <i class="fa fa-download"></i>
                                                    </span>
                                                    to Download file
                                                </li>
                                                <li>
                                                    <span>
                                                        <i class="fas fa-ban"></i>
                                                    </span>
                                                    Uploaded document is over-sized.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="table-responsive mt-4">
                                    <form>
                                        <table
                                            class="table table-borderless table-striped table-head-dark table-head-condensed table-head-py-3">
                                            <thead>
                                                <tr>
                                                    <th>Sr.No</th>
                                                    <th>File Name</th>
                                                    <th>Label</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of documentList; index as i">
                                                    <td>{{ i + 1 }}</td>
                                                    <td>{{ item.name }}</td>
                                                    <td>
                                                        <input type="text" [(ngModel)]="item.label"
                                                            [ngModelOptions]="{standalone: true}">
                                                    </td>
                                                    <td>
                                                        <div class="row">
                                                            <div class="col-md-3">
                                                                <button *ngIf="item.artifactId"
                                                                    (click)="downloadFileLocal(item)">
                                                                    <i class="fa fa-download" aria-hidden="true"
                                                                        title="Download"></i>
                                                                </button>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <button title="Oversized" class="text-danger"
                                                                    *ngIf="(math.round(item.size) /1024) > 2048">
                                                                    <i class="fas fa-ban" aria-hidden="true"></i>
                                                                </button>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <button title="Uploaded" class="text-primary"
                                                                    *ngIf="item.artifactId">
                                                                    <i class="fa fa-check" aria-hidden="true"></i>
                                                                </button>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <button title="Delete"
                                                                    *ngIf="item.artifactId; else localDelete">
                                                                    <i class="fa fa-trash color-blue"
                                                                        (click)="deleteServerFile(item.artifactId, i)"></i>
                                                                </button>
                                                                <button title="Delete" *ngIf="!item.artifactId">
                                                                    <i class="fa fa-trash color-blue"
                                                                        (click)="deleteDocument(i)"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </form>
                                </div>
                                <hr>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-md-6 form-group pt-4 pt-lg-5">
                                    <button *ngIf="!updateEnquiryBool" class="btn btn-success w-100"
                                        (click)="onSubmit()" type="submit">SUBMIT</button>
                                    <button *ngIf="updateEnquiryBool" class="btn btn-success w-100"
                                        (click)="updateEnquiry()" type="submit">UPDATE</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>