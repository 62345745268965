import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceOrderService } from 'src/app/helpers/service-order.service';

@Component({
  selector: 'app-vendor-service-order',
  templateUrl: './vendor-service-order.component.html',
  styleUrls: ['./vendor-service-order.component.css']
})
export class VendorServiceOrderComponent implements OnInit {

  serviceOrderId: any;
  serviceOrderObject: Object;
  title = "Service Order";
  constructor(private route: ActivatedRoute, private serviceOrder: ServiceOrderService) { }

  ngOnInit(): void {
    this.serviceOrderId = this.route.snapshot.paramMap.get('id');
    console.log(this.serviceOrderId);
    this.getServiceOrderById(this.serviceOrderId);
  }

  getServiceOrderById(id: any){
    this.serviceOrder.getServiceOrderById(id).subscribe(
      (response) => {
        this.serviceOrderObject = response;
        console.log(this.serviceOrderObject);
      },
      (error) => {
        console.log(error);
      },
    )
  }

}
