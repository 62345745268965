import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientDocumentComponent } from './client-onboarding/client-document/client-document.component';
import { ClientOnboardingComponent } from './client-onboarding/client-onboarding.component';
import { ClientRfqComponent } from './client-rfq-list/client-rfq/client-rfq.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { HomePageComponent } from './home-page/home-page.component';
import { RequestDemoComponent } from './request-demo/request-demo.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './client-onboarding/sign-up/sign-up.component';
import { VendorCertificationComponent } from './vendor-onboarding/vendor-certification/vendor-certification.component';
import { VendorDocumentComponent } from './vendor-onboarding/vendor-document/vendor-document.component';
import { VendorOnboardingComponent } from './vendor-onboarding/vendor-onboarding.component';
import { VendorOverviewComponent } from './vendor-onboarding/vendor-overview/vendor-overview.component';
import { VendorSignUpComponent } from './vendor-onboarding/vendor-sign-up/vendor-sign-up.component';
import { ClientAuctionComponent } from './client-auction-list/client-auction/client-auction.component';
import { ServiceOrderComponent } from './service-order-list/service-order/service-order.component';
import { UserPermissionListComponent } from './user-permission-list/user-permission-list.component';
import { UserRoleListComponent } from './user-role-list/user-role-list.component';
import { QuoteComparisonComponent } from './quote-comparison/quote-comparison.component';
import { DetailQuoteComparisonComponent } from './detail-quote-comparison/detail-quote-comparison.component';
import { ClientRfqListComponent } from './client-rfq-list/client-rfq-list.component';
import { ClientAuctionListComponent } from './client-auction-list/client-auction-list.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserComponent } from './user-list/user/user.component';
import { VendorRfqListComponent } from './vendor-rfq-list/vendor-rfq-list.component';
import { VendorRfqQuoteComponent } from './vendor-rfq-list/vendor-rfq-quote/vendor-rfq-quote.component';
import { VendorAuctionListComponent } from './vendor-auction-list/vendor-auction-list.component';
import { VendorAuctionQuoteComponent } from './vendor-auction-list/vendor-auction-quote/vendor-auction-quote.component';
import { AuctionComparisonComponent } from './auction-comparison/auction-comparison.component';
import { DetailComparisonComponent } from './auction-comparison/detail-comparison/detail-comparison.component'
import { RequestExtensionComponent } from "./request-extension/request-extension.component";
import { ManageExtensionComponent } from './request-extension/manage-extension/manage-extension.component';
import { ReviewAuctionComponent } from './review-auction/review-auction.component';
import { ReviewDocumentsComponent } from './review-auction/review-documents/review-documents.component';
import { RolesActivityMappingComponent } from './auction-comparison/roles-activity-mapping/roles-activity-mapping.component';
import { DocumentSequenceConfigurationComponent } from './document-sequence-configuration/document-sequence-configuration.component';
import { DocumentHierarchyMappingComponent } from './auction-comparison/hierarchy-master/document-hierarchy-mapping/document-hierarchy-mapping.component';
import { HierarchyMasterComponent } from './auction-comparison/hierarchy-master/hierarchy-master.component';
import { ServiceOrderListComponent } from './service-order-list/service-order-list.component';
import { InvoiceListingComponent } from './invoice-listing/invoice-listing.component';
import { InvoiceCreateComponent } from './invoice-listing/invoice-create/invoice-create.component';
import { ContactUsListComponent } from './contact-us-list/contact-us-list.component';
import { RequestDemoListComponent } from './request-demo-list/request-demo-list.component';
import { TypeOfCargoComponent } from './master-table/type-of-cargo/type-of-cargo.component';
import { TypeOfPackingComponent } from './master-table/type-of-packing/type-of-packing.component';
import { TradeTypeComponent } from './master-table/trade-type/trade-type.component';
import { ContainerTypeComponent } from './master-table/container-type/container-type.component';
import { NatureofMovementComponent } from './master-table/natureof-movement/natureof-movement.component';
import { ModeOfShipmentComponent } from './master-table/mode-of-shipment/mode-of-shipment.component';
import { DeliveryIncoTermsComponent } from './master-table/delivery-inco-terms/delivery-inco-terms.component';
import { PickUpIncoTermsComponent } from './master-table/pick-up-inco-terms/pick-up-inco-terms.component';
import { LoadingDestinationPortComponent } from './master-table/loading-destination-port/loading-destination-port.component';

import { ReportListingComponent } from './report-listing/report-listing.component';
import { ReportComponent } from './report-listing/report/report.component';

import { AuctionClientComparisonComponent } from './client-auction-list/auction-client-comparison/auction-client-comparison.component';
import { VendorQuoteComparisonComponent } from './vendor-rfq-list/vendor-quote-comparison/vendor-quote-comparison.component';
import { ClientLiveAuctionComponent } from './client-live-auction/client-live-auction.component';
import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';
import { NatureOfGoodsComponent } from './master-table/nature-of-goods/nature-of-goods.component';
import { ClientTemplateListComponent } from './client-template-list/client-template-list.component';
import { ClientTemplateComponent } from './client-template-list/client-template/client-template.component';
import { ClientTrackTraceComponent } from './client-track-trace/client-track-trace.component';
import { ClientShipmentComponent } from './client-track-trace/client-shipment/client-shipment.component';
import { ClientListingComponent } from './client-listing/client-listing.component';
import { VendorListingComponent } from './vendor-listing/vendor-listing.component';
import { AdminSubscriptionComponent } from './admin-subscription/admin-subscription.component';
import { ConfigurationParamatersComponent } from './configuration-paramaters/configuration-paramaters.component';
import { MessageTemplateComponent } from './message-template/message-template.component';
import { PreShipmentInvoiceTemplateComponent } from './pre-shipment-invoice-template/pre-shipment-invoice-template.component';
import { VendorLiveAuctionComponent } from './vendor-live-auction/vendor-live-auction.component';
import { PreShipmentInvoiceComponent } from './pre-shipment-invoice-template/pre-shipment-invoice/pre-shipment-invoice.component';
import { AdminClientParamComponent } from './admin-client-param/admin-client-param.component';
import { CurrencyComponent } from './master-table/currency/currency.component';
import { VendorDetailQuoteComparisonComponent } from './vendor-rfq-list/vendor-detail-quote-comparison/vendor-detail-quote-comparison.component';
import { AuthGuard } from './helpers/auth.guard';
import { ClientShipmentListComponent } from './client-track-trace/client-shipment-list/client-shipment-list.component';
import { ClientFlightScheduleComponent } from './client-track-trace/client-flight-schedule/client-flight-schedule.component';
import { VendorCertificateTermComponent } from './vendor-onboarding/vendor-certificate-term/vendor-certificate-term.component';
import { SingleQuoteViewComponent } from './quote-comparison/single-quote-view/single-quote-view.component';
import { PlatformParamComponent } from './platform-param/platform-param.component';
import { BuySubscriptionComponent } from './request-extension/buy-subscription/buy-subscription.component';
import { ClientContainerScheduleComponent } from './client-track-trace/client-container-schedule/client-container-schedule.component';
import { ClientVesselScheduleComponent } from './client-track-trace/client-vessel-schedule/client-vessel-schedule.component';
import { AdditionalChargeTypeComponent } from './master-table/additional-charge-type/additional-charge-type.component';
import { TypeOfVehicleComponent } from './master-table/type-of-vehicle/type-of-vehicle.component';
import { ClientTermsConditionComponent } from './client-onboarding/client-terms-condition/client-terms-condition.component';
import { VendorServiceOrderListingComponent } from './vendor-service-order-listing/vendor-service-order-listing.component';
import { FaqComponent } from './static-pages/faq/faq.component';
import { AboutUsComponent } from './static-pages/about-us/about-us.component';
import { FeedbackComponent } from './static-pages/feedback/feedback.component';
import { ServicesComponent } from './static-pages/services/services.component';
import { ReferralComponent } from './static-pages/referral/referral.component';
import { MissionVisionComponent } from './static-pages/mission-vision/mission-vision.component';
import { CsrPageComponent } from './static-pages/csr-page/csr-page.component';
import { PrivacyPolicyComponent } from './static-pages/privacy-policy/privacy-policy.component';
import { BusinessTypeComponent } from './master-table/business-type/business-type.component';
import { ClientTrackAirPackageComponent } from './client-track-trace/client-track-air-package/client-track-air-package.component';
import { ClientPageComponent } from './static-pages/client-page/client-page.component';
import { CountryComponent } from './master-table/country/country.component';
import { CareerPageComponent } from './static-pages/career-page/career-page.component';
import { FeedbackListingComponent } from './feedback-listing/feedback-listing.component';
import { CareerListingComponent } from './career-listing/career-listing.component';
import { UserRoleComponent } from './user-role-list/user-role/user-role.component';
import { NoPermissionRouteComponent } from './no-permission-route/no-permission-route.component';
import { ChangeUserPasswordComponent } from './change-user-password/change-user-password.component';
import { VendorViewComponent } from './vendor-listing/vendor-view/vendor-view.component';
import { ClientViewComponent } from './client-listing/client-view/client-view.component';
import { RfqViewComponent } from './client-rfq-list/rfq-view/rfq-view.component';
import { CarriersComponent } from './master-table/carriers/carriers.component';
import { VendorServiceOrderComponent } from './vendor-service-order-listing/vendor-service-order/vendor-service-order.component';
import { HsCodeComponent } from './master-table/hs-code/hs-code.component';
import { TradeTypeAgreementComponent } from './master-table/trade-type-agreement/trade-type-agreement.component';
import { HsCodeListsComponent } from './hs-code-lists/hs-code-lists.component';
import { TradeAgreementListsComponent } from './trade-agreement-lists/trade-agreement-lists.component';
import { ShippingLineComponent } from './master-table/shipping-line/shipping-line.component';
import { UserProfileComponent } from './user-list/user-profile/user-profile.component';
import { MarketingSpaceComponent } from './master-table/marketing-space/marketing-space.component';
import { VendorPreShipmentInvoicesComponent } from './vendor-pre-shipment-invoices/vendor-pre-shipment-invoices.component';
import { VendorCommercialInvoicesComponent } from './vendor-commercial-invoices/vendor-commercial-invoices.component';

const routes: Routes = [
  { path: 'home', component: HomePageComponent, pathMatch: 'full' },
  { path: 'request-demo', component: RequestDemoComponent, pathMatch: 'full' },
  { path: 'sign-in', component: SignInComponent, pathMatch: 'full' },
  { path: 'forget-password', component: ForgetPasswordComponent, pathMatch: 'full' },
  { path: 'reset-password', component: ResetPasswordComponent, pathMatch: 'full' },

  { path: 'v1/client-onboard/request-for-demo/:id', component: ClientOnboardingComponent, pathMatch: 'full', },
  { path: 'v1/client-onboard/details', component: ClientOnboardingComponent, pathMatch: 'full', },
  { path: 'v1/client-onboard/documents', component: ClientDocumentComponent, pathMatch: 'full', },
  { path: 'v1/client-onboard/term-condition', component: ClientTermsConditionComponent, pathMatch: 'full', },
  { path: 'v1/client-onboard/sign-up', component: SignUpComponent, pathMatch: 'full', },

  { path: 'v1/vendor-onboard/details', component: VendorOnboardingComponent, pathMatch: 'full', },
  { path: 'v1/vendor-onboard/overview', component: VendorOverviewComponent, pathMatch: 'full', },
  { path: 'v1/vendor-onboard/documents', component: VendorDocumentComponent, pathMatch: 'full', },
  { path: 'v1/vendor-onboard/certification', component: VendorCertificationComponent, pathMatch: 'full', },
  { path: 'v1/vendor-onboard/certification/term', component: VendorCertificateTermComponent, pathMatch: 'full', },
  { path: 'v1/vendor-onboard/sign-up', component: VendorSignUpComponent, pathMatch: 'full', },

  { path: 'v1/dashboard', component: DashboardComponent, pathMatch: 'full' },

  { path: 'v1/no-permission', component: NoPermissionRouteComponent, pathMatch: 'full'},

  // RFQ CLIENT ROUTES
  { path: 'v1/client-rfq/listing/:type', component: ClientRfqListComponent, pathMatch: 'full', canActivate: [AuthGuard] , data :{ authorityCode:"RB0000132"}},
  { path: 'v1/client-rfq/creation/:type', component: ClientRfqComponent, pathMatch: 'full', canActivate: [AuthGuard] , data :{ authorityCode:"RB0000123"} },
  { path: 'v1/client-rfq/edit/:id', component: ClientRfqComponent, pathMatch: 'full', canActivate: [AuthGuard] , data :{ authorityCode:"RB0000124"}},
  { path: 'v1/client-rfq/quote-comparison/:id', component: QuoteComparisonComponent, pathMatch: 'full', canActivate: [AuthGuard] , data :{ authorityCode:"RB0000157"}},

  { path: 'v1/client-rfq/detail-quote-comparison/:id', component: DetailQuoteComparisonComponent, pathMatch: 'full', canActivate: [AuthGuard] , data :{ authorityCode:"RB0000157"}},
  { path: 'v1/client-rfq/single-quote-view/:id', component: SingleQuoteViewComponent, pathMatch: 'full', canActivate: [AuthGuard] , data :{ authorityCode:"RB0000157"}},

  { path: 'v1/client-rfq/view/:id', component: RfqViewComponent, pathMatch: 'full', canActivate: [AuthGuard],  data :{ authorityCode:"RB0000133"}},

  // AUCTION CLIENT ROUTES
  { path: 'v1/client-auction/listing/:type', component: ClientAuctionListComponent, pathMatch: 'full' , canActivate: [AuthGuard] , data :{ authorityCode:"RB0000134"}},
  { path: 'v1/client-auction/creation/:id/:type', component: ClientAuctionComponent, pathMatch: 'full', canActivate: [AuthGuard] , data :{ authorityCode:"RB0000125"}},
  { path: 'v1/client-auction/creation/:type', component: ClientAuctionComponent, pathMatch: 'full', canActivate: [AuthGuard] , data :{ authorityCode:"RB0000125"}},
  { path: 'v1/client-auction/edit', component: ClientAuctionComponent, pathMatch: 'full', canActivate: [AuthGuard] , data :{ authorityCode:"RB0000127"}},
  // , data :{ authorityCode:"RB0000126"} it required
  { path: 'v1/client-auction/comparison/:id', component: AuctionComparisonComponent, pathMatch: 'full', canActivate: [AuthGuard] , data :{ authorityCode:"RB0000025"}},
  { path: 'v1/client-auction/detail-comparison/:id', component: DetailComparisonComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'v1/client-live-auction/:id', component: ClientLiveAuctionComponent, pathMatch: 'full', canActivate: [AuthGuard] , data :{ authorityCode:"RB0000127"}},

  // Service Order Client Routes
  { path: 'v1/client-service-order/listing', component: ServiceOrderListComponent, pathMatch: 'full', canActivate: [AuthGuard] , data :{ authorityCode:"RB0000130"}},
  { path: 'v1/client-service-order/creation/:type/:id', component: ServiceOrderComponent, pathMatch: 'full', canActivate: [AuthGuard] , data :{ authorityCode:"RB0000128"}},
  { path: 'v1/client-service-order/edit/:id', component: ServiceOrderComponent, pathMatch: 'full', canActivate: [AuthGuard] , data :{ authorityCode:"RB0000131"}},

  // Track And Track Module
  { path: 'v1/client-track-trace/vessel-schedule', component: ClientVesselScheduleComponent, pathMatch: 'full', canActivate: [AuthGuard] , data :{ authorityCode:"RB0000171"}},
  { path: 'v1/client-template/listing', component: ClientTemplateListComponent, pathMatch: 'full', canActivate: [AuthGuard], data: { authorityCode:"RB0000038"}},
  { path: 'v1/client-template/creation', component: ClientTemplateComponent, pathMatch: 'full', canActivate: [AuthGuard], data: { authorityCode:"RB0000037"}  },
  { path: 'v1/client-template/edit/:id', component: ClientTemplateComponent, pathMatch: 'full', canActivate: [AuthGuard], data: { authorityCode:"RB0000040"} },

  { path: 'v1/client-track-trace/container-track', component: ClientContainerScheduleComponent, pathMatch: 'full', canActivate: [AuthGuard], data :{ authorityCode:"RB0000174"}},
  { path: 'v1/client-track-trace/client-flight-schedule', component: ClientFlightScheduleComponent, pathMatch: 'full', canActivate: [AuthGuard],  data :{ authorityCode:"RB0000218"}},
  { path: 'v1/client-track-trace/shipment/listing', component: ClientShipmentListComponent, pathMatch: 'full', canActivate: [AuthGuard]  , data :{ authorityCode:"RB0000151"}},
  { path: 'v1/client-track-trace/shipment/creation', component: ClientShipmentComponent, pathMatch: 'full', canActivate: [AuthGuard] , data :{ authorityCode:"RB0000148"}},
  { path: 'v1/client-track-trace/shipment/edit/:id', component: ClientShipmentComponent, pathMatch: 'full', canActivate: [AuthGuard] , data :{ authorityCode:"RB0000150"}},
  { path: 'v1/client-track-trace/air-package-track', component: ClientTrackAirPackageComponent, pathMatch: 'full', canActivate: [AuthGuard] , data :{ authorityCode:"RB0000232"}},

  { path: 'v1/user/listing', component: UserListComponent, pathMatch: 'full', canActivate: [AuthGuard] , data :{ authorityCode:"RB0000184"} },
  { path: 'v1/user-roles/listing', component: UserRoleListComponent, pathMatch: 'full', canActivate: [AuthGuard] , data :{ authorityCode:"RB0000190"}},
  { path: 'v1/user-permission/listing', component: UserPermissionListComponent, pathMatch: 'full'},
  // canActivate: [AuthGuard]
  { path: 'v1/user/create', component: UserComponent, pathMatch: 'full', canActivate: [AuthGuard], data: { authorityCode: "RB0000178"}},
  { path: 'v1/user/edit/:id', component: UserComponent, pathMatch: 'full', canActivate: [AuthGuard], data: { authorityCode: "RB0000182"}},
  { path: 'v1/role-creation', component: UserRoleComponent, pathMatch: 'full' ,canActivate: [AuthGuard], data :{ authorityCode:"RB0000187"} },
  { path: 'v1/role-edit/:id', component: UserRoleComponent, pathMatch: 'full' ,canActivate: [AuthGuard], data :{ authorityCode:"RB0000189"}},
  { path: 'v1/user/profile', component: UserProfileComponent, pathMatch: 'full'},

  // Admin master tabe Routes
  { path: 'v1/masterTable/natureOfMovement', component: NatureofMovementComponent, pathMatch: 'full', canActivate: [AuthGuard] , data :{ authorityCode:"RB0000069"}},
  { path: 'v1/masterTable/modeOfShipment', component: ModeOfShipmentComponent, pathMatch: 'full', canActivate: [AuthGuard] , data :{ authorityCode:"RB0000046"}},
  { path: 'v1/masterTable/deliveryIncoTerms', component: DeliveryIncoTermsComponent, pathMatch: 'full', canActivate: [AuthGuard], data :{ authorityCode:"RB0000067"}},
  { path: 'v1/masterTable/pickUpIncoTerms', component: PickUpIncoTermsComponent, pathMatch: 'full', canActivate: [AuthGuard], data :{ authorityCode:"RB0000071"}},
  { path: 'v1/masterTable/typeOfCargo', component: TypeOfCargoComponent, pathMatch: 'full', canActivate: [AuthGuard] , data :{ authorityCode:"RB0000058"}},
  { path: 'v1/masterTable/typeOfPacking', component: TypeOfPackingComponent, pathMatch: 'full', canActivate: [AuthGuard] , data :{ authorityCode:"RB0000073"}},
  { path: 'v1/masterTable/tradeType', component: TradeTypeComponent, pathMatch: 'full', canActivate: [AuthGuard] , data :{ authorityCode:"RB0000050"}},
  { path: 'v1/masterTable/containerTypes', component: ContainerTypeComponent, pathMatch: 'full', canActivate: [AuthGuard] , data :{ authorityCode:"RB0000065"}},
  { path: 'v1/masterTable/loadingDestinationPort', component: LoadingDestinationPortComponent, pathMatch: 'full', canActivate: [AuthGuard] , data :{ authorityCode:"RB0000054"}},
  { path: 'v1/masterTable/natureOfGoods', component: NatureOfGoodsComponent, pathMatch: 'full', canActivate: [AuthGuard] , data :{ authorityCode:"RB0000062"}},
  { path: 'v1/masterTable/currency', component: CurrencyComponent, pathMatch: 'full', canActivate: [AuthGuard] , data :{ authorityCode:"RB0000096"}},
  { path: 'v1/masterTable/chargeType', component: AdditionalChargeTypeComponent, pathMatch: 'full', canActivate: [AuthGuard], data :{ authorityCode:"RB0000109"}},
  { path: 'v1/masterTable/country', component: CountryComponent, pathMatch: 'full', canActivate: [AuthGuard], data :{ authorityCode:"RB0000101"}},
  { path: 'v1/masterTable/typeOfVehicle', component: TypeOfVehicleComponent, pathMatch: 'full', data :{ authorityCode:"RB0000115"} },
  { path: 'v1/masterTable/businessType', component: BusinessTypeComponent, pathMatch: 'full',canActivate: [AuthGuard], data :{ authorityCode:"RB0000120"} },
  { path: 'v1/masterTable/carriers', component: CarriersComponent, pathMatch: 'full', canActivate: [AuthGuard], data :{ authorityCode:"RB0000247"}},
  { path: 'v1/masterTable/shippingLine', component: ShippingLineComponent, pathMatch: 'full' , canActivate: [AuthGuard], data :{ authorityCode:"RB0000245"}},
  { path: 'v1/masterTable/hs-codes', component: HsCodeComponent, pathMatch: 'full' , canActivate: [AuthGuard], data :{ authorityCode:"RB0000248"}},
  { path: 'v1/masterTable/trade-type-agreement', component: TradeTypeAgreementComponent, pathMatch: 'full' , canActivate: [AuthGuard], data :{ authorityCode:"RB0000246"}},
  { path: 'v1/masterTable/marketing-space', component: MarketingSpaceComponent, pathMatch: 'full', canActivate: [AuthGuard], data: {authorityCode: "RB0000250"} },

  // pending
  { path: 'v1/admin/feedback-listing', component: FeedbackListingComponent, pathMatch: 'full'},
  { path: 'v1/admin/career-listing', component: CareerListingComponent, pathMatch: 'full'},

  // Vendor RFQ Routes
  { path: 'v1/vendor-rfq/listing/:type', component: VendorRfqListComponent, pathMatch: 'full', canActivate: [AuthGuard] , data :{ authorityCode:"RB0000132"}},
  { path: 'v1/vendor-rfq/quote/:id', component: VendorRfqQuoteComponent, pathMatch: 'full', canActivate: [AuthGuard] , data :{ authorityCode:"RB0000157"}},
  { path: 'v1/vendor-rfq/quote/edit/:id', component: VendorRfqQuoteComponent, pathMatch: 'full', canActivate: [AuthGuard] , data :{ authorityCode:"RB0000157"}},

  // Vendor Auction Routes
  { path: 'v1/vendor-auction/listing/:type', component: VendorAuctionListComponent, pathMatch: 'full', canActivate: [AuthGuard] , data :{ authorityCode:"RB0000134"}},
  //pending
  { path: 'v1/vendor-auction/bid/:id', component: VendorAuctionQuoteComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'v1/vendor-live-auction/:id', component: VendorLiveAuctionComponent, pathMatch: 'full', canActivate: [AuthGuard] , data :{ authorityCode:"RB0000127"}},

  //vendor service order route
  { path: 'v1/vendor-service-order/listing', component: VendorServiceOrderListingComponent, pathMatch: 'full', canActivate: [AuthGuard], data :{ authorityCode:"RB0000130"}},
  { path: 'v1/vendor-service-order/edit/:id', component: VendorServiceOrderComponent, pathMatch: 'full', canActivate: [AuthGuard]},
  { path: 'v1/vendor-pre-shipment-invoice/listing', component: VendorPreShipmentInvoicesComponent, pathMatch: 'full', canActivate: [AuthGuard], data :{ authorityCode:"RB0000034"} },
  { path: 'v1/vendor-commercial-invoice/listing', component: VendorCommercialInvoicesComponent, pathMatch: 'full', canActivate: [AuthGuard], data :{ authorityCode:"RB0000255"} },

  // Admin Routes
  { path: 'v1/request-extension', component: RequestExtensionComponent, pathMatch: 'full', canActivate: [AuthGuard], data :{ authorityCode:"RB0000213"} },
  { path: 'v1/manage-extension', component: ManageExtensionComponent, pathMatch: 'full', canActivate: [AuthGuard], data :{ authorityCode:"RB0000214"}  },
  { path: 'v1/auction/review-approve', component: ReviewAuctionComponent, pathMatch: 'full' },
  { path: 'v1/review-approve-document', component: ReviewDocumentsComponent, pathMatch: 'full' },
  { path: 'v1/roles-activity-mapping', component: RolesActivityMappingComponent, pathMatch: 'full' },
  { path: 'v1/document-sequence-configuration', component: DocumentSequenceConfigurationComponent, pathMatch: 'full' },
  { path: 'v1/document-hierarchy-mapping', component: DocumentHierarchyMappingComponent, pathMatch: 'full' },
  { path: 'v1/hierarchy-master', component: HierarchyMasterComponent, pathMatch: 'full' },
  { path: 'v1/admin/configuration-client-parameters', component: AdminClientParamComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'v1/buy-subscription', component: BuySubscriptionComponent, pathMatch: 'full'},
  { path: 'v1/buy-subscription/edit/:id', component: BuySubscriptionComponent, pathMatch: 'full'},


  { path: 'v1/vendor-quote-comparison/:id', component: VendorQuoteComparisonComponent, pathMatch: 'full' ,canActivate: [AuthGuard] ,data :{ authorityCode:"RB0000157"}},
  //pending
  { path: 'v1/vendor-detail-quote-comparison/:id', component: VendorDetailQuoteComparisonComponent, pathMatch: 'full' },
  //pending
  { path: 'v1/report/:name/:input/:output', component: ReportComponent, pathMatch: 'full' },

  { path: 'v1/client/listing', component: ClientListingComponent, pathMatch: 'full',  canActivate: [AuthGuard], data :{ authorityCode:"RB0000010"}},
  { path: 'v1/vendor/listing', component: VendorListingComponent, pathMatch: 'full',   canActivate: [AuthGuard], data :{ authorityCode:"RB0000015"}},

  { path: 'v1/client/view', component: ClientViewComponent, pathMatch: 'full', },
  { path: 'v1/vendor/view', component: VendorViewComponent, pathMatch: 'full', },
  { path: 'v1/admin/subcription/listing', component: AdminSubscriptionComponent, pathMatch: 'full', },
  { path: 'v1/admin/contact-us-listing', component: ContactUsListComponent, pathMatch: 'full', canActivate: [AuthGuard],data :{ authorityCode:"RB0000005"} },
  { path: 'v1/admin/request-demo-listing', component: RequestDemoListComponent, pathMatch: 'full', canActivate: [AuthGuard], data :{ authorityCode:"RB0000002"} },
  { path: 'v1/reports/listing', component: ReportListingComponent, pathMatch: 'full', canActivate: [AuthGuard], data :{ authorityCode:"RB0000166"} },
  { path: 'v1/report/:name/:input/:output', component: ReportComponent, pathMatch: 'full', canActivate: [AuthGuard] },

  //pending
  { path: 'v1/admin/subcription/listing', component: AdminSubscriptionComponent, pathMatch: 'full' },
  { path: 'v1/admin/configuration-paramters', component: ConfigurationParamatersComponent, pathMatch: 'full'},
  { path: 'v1/admin/message-template', component: MessageTemplateComponent, pathMatch: 'full', canActivate: [AuthGuard], data :{ authorityCode:"RB0000209"} },

  //All invoice template and invoice routes
  { path: 'v1/commerical-invoice/create', component: InvoiceCreateComponent, pathMatch: 'full', canActivate: [AuthGuard], data :{ authorityCode:"RB0000253"}}, 
  { path: 'v1/commerical-invoice/edit/:id', component: InvoiceCreateComponent, pathMatch: 'full', canActivate: [AuthGuard], data :{ authorityCode:"RB0000256"}},
  { path: 'v1/commerical-invoice/listing', component: InvoiceListingComponent, pathMatch: 'full', canActivate: [AuthGuard] , data :{ authorityCode:"RB0000255"}},
  { path: 'v1/configuration/platform-param',component:PlatformParamComponent, pathMatch: 'full', canActivate: [AuthGuard] , data :{ authorityCode:"RB0000201"}}, 

  { path: 'v1/pre-shipment-invoice-list', component: PreShipmentInvoiceTemplateComponent, pathMatch: 'full', canActivate: [AuthGuard], data: { authorityCode:"RB0000034"} },
  { path: 'v1/pre-shipment-invoice/create/:type/:id', component: PreShipmentInvoiceComponent, pathMatch: 'full', canActivate: [AuthGuard], data: { authorityCode:"RB0000032"} },
  { path: 'v1/pre-shipment-invoice/edit/:id', component: PreShipmentInvoiceComponent, pathMatch: 'full', canActivate: [AuthGuard], data: { authorityCode:"RB0000033"} },
  

  { path: 'v1/configuration/platform-param',component:PlatformParamComponent, pathMatch: 'full' },

  // Static pages
  {path: 'faq-page', component: FaqComponent, pathMatch: 'full'},
  {path: 'about-us', component: AboutUsComponent, pathMatch: 'full'},
  {path: 'feedback', component: FeedbackComponent, pathMatch: 'full'},
  {path: 'services', component: ServicesComponent, pathMatch: 'full'},
  {path: 'referral', component: ReferralComponent, pathMatch: 'full'},
  {path: 'mission-vision', component: MissionVisionComponent, pathMatch: 'full'},
  {path: 'csr-page', component: CsrPageComponent, pathMatch: 'full'},
  {path: 'privacy-policy', component: PrivacyPolicyComponent, pathMatch: 'full'},
  {path: 'term-condition', component: ClientTermsConditionComponent, pathMatch: 'full'},
  {path: 'client', component: ClientPageComponent, pathMatch: 'full'},
  {path: 'career', component: CareerPageComponent, pathMatch: 'full'},
  {path: 'hs-code', component: HsCodeListsComponent, pathMatch: 'full'},
  {path: 'trade-agreement', component: TradeAgreementListsComponent, pathMatch: 'full'},

  {path: 'v1/change-password', component: ChangeUserPasswordComponent, pathMatch: 'full'},

  { path: "**", redirectTo: 'home', pathMatch: 'full' }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
