import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AlertService } from 'src/app/utils/sweet-alert.service';

/**
 * Description
 * Component for File upload
 * 
 * Usage:
 * <app-file-upload
 *  [files]=[Array of files]
 *  [maxFileSize]="2000"
 *  [maxAllowedTotalFileSize]="1000000"
 *  [allowedFileTypes]=".png,.jpg,.doc"
 *  [multipleFileBrowse]="true"
 *  [dropZoneLabel]="Drop files here"
 *  [showUploadIcon]="true"
 *  artifactKey="id"
 *  (uploadArtifact)="uploadDoc($event)"
 *  (deleteArtifact)="deleteDoc($event)"
 *  (uploadAllArtifacts)="uploadAllDocs($event)"
 * >
 * </app-file-upload>
 */

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit, OnChanges {

  /**
   * Input Variable for storing files
   */
  @Input() files = [];

  /**
   * Input Variable to provide maxFileSize for a single file
   * Default is 2mb
   */
  @Input() maxFileSize: number = 2000000;

  /**
   * Input variable to provide total maximum file size for upload
   * Default is 10mb
   */
  @Input() maxAllowedTotalFileSize: number = 10000000;

  /**
   * Input Variable for allowed file types which can be uploaded
   * Default Allowed File types: '.jpg, .png, .pdf, .docx, .txt, .jpeg'
   */
  @Input() allowedFileTypes = '.jpg, .png, .pdf, .docx, .txt, .jpeg';

  /**
   * Input Variable to handle if multiple file selection is allowed or not, 
   * If true multiple allowed, false otherwise
   */
  @Input() multipleFileBrowse = true;

  /**
   * Input Variable for Drop Zone Section Label
   */
  @Input() dropZoneLabel = 'Upload Files By Drag & Drop OR'

  /**
   * Input Variable to show upload icon, icon visible if true, false otherwise
   */
  @Input() showUploadIcon = true;

  /**
   * Artifact/Document Record key name which is stored in database
   * Default is "artifactId"
   */
  @Input() artifactKey = 'artifactId';

  /**
   * Output Emitter Event for Uploading Artifact
   */
  @Output() uploadArtifact = new EventEmitter();

  /**
   * Output Emitter Event for Deleting Artiface
   */
  @Output() deleteArtifact = new EventEmitter();

  /**
   * Output emitter for uploading all artifacts
   */
  @Output() uploadAllArtifacts = new EventEmitter();

  /**
   * Subject to listen for changes in label input box
   */
  private subject: Subject<string> = new Subject();

  totalFileSize = 0;



  constructor(private alertService: AlertService) { }

  ngOnInit(): void {

    this.subject.pipe(
      debounceTime(500)
    ).subscribe(() => {
      this.uploadAllArtifacts.emit(this.files);
    });

    this.initInputFileSize()

  }

  /**
   * Function to calculate file size when files are passed as Input
   */
  initInputFileSize() {
    this.files.forEach((file) => {
      this.calculateTotalFileSize(file);
    });
  }

  /**
   * Function to Calculate Total File Size
   * @param file 
   */
  calculateTotalFileSize(file) {
    this.totalFileSize += file.size;
  }

  /**
   * Function to handle input change in label text box
   */
  onLabelChange() {
    this.subject.next();
  }

  /**
   * Function to handle dropped files
   * @param files 
   */
  public dropped(files: NgxFileDropEntry[]) {

    let tempFiles = [];
    for (let index = 0; index < files.length; index++) {
      const droppedFile = files[index];
      // Is it a file?
      if (droppedFile?.fileEntry?.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.calculateTotalFileSize(file);
          // Checking if individual file is less than max allowed file size
          if (file.size <= this.maxFileSize) {
            tempFiles.push(files[index]);
          } else {
            this.totalFileSize -= file.size;
          }
          // Here you can access the real file size & content
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            files[index]['content'] = reader.result;
            files[index]['contentType'] = reader.result?.toString().split(',')[0];
            files[index]["name"] = file.name;
            files[index]["size"] = file.size;
          }
        });
      }
      else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }

    if (this.totalFileSize > this.maxAllowedTotalFileSize) {
      this.alertService.errorAlert('Uploaded file size exceeds total maximum allowed file size');
    } else {
      this.files = [...this.files, ...tempFiles];
      this.uploadAllArtifacts.emit(this.files);
    }
  }

  /**
   * Function to handle Upload Document Event
   * @param index index of item
   */
  uploadDocument(index: number) {
    const element = this.files[index];
    if (element.label == undefined) {
      this.alertService.errorAlert('Add label to file, then upload');
    }
    else {
      this.uploadArtifact.emit({ element, index })
    }
  }

  /**
   * Function to Delete Documents locally
   * @param index index of item
   */
  deleteDocument(index: number) {
    this.totalFileSize -= this.files[index].size;
    this.files.splice(index, 1);
    this.uploadAllArtifacts.emit(this.files);
  }

  /**
   * Function to delete Service Artifact
   * @param item item
   * @param index index of item
   */
  deleteServerArtifact(item, index) {
    this.deleteArtifact.emit({ item, index });
  }

  /**
   * Function to handle changes in input properties
   * @param changes 
   */
  ngOnChanges(changes: SimpleChanges) {
    this.totalFileSize = 0;
    if (changes?.files) {
      this.files = changes.files.currentValue;
      this.initInputFileSize();
    }
  }

}
