<section class="wrapper multi-step-form">
    <div class="container-fluid">
        <div class="row">
            <div
                class="col-md-4 col-xl-3 col-left pt-12 d-flex flex-column justify-content-center position-sticky top-0">
                <div class="box-absolute d-flex">
                    <app-sidebar></app-sidebar>
                </div>
                <div class="box mx-auto">
                    <h3 class="text-center text-md-start">{{ title }}</h3>
                    <ul class="list-unstyled step-one">
                        <li class="active" id="list-item-0">
                            <div class="icon"> <i class="fa fa-check"></i></div><span>Shipment</span>
                        </li>
                        <li class=" " id="list-item-1">
                            <div class="icon"> <i class="fa fa-check"></i></div><span>Shipment Details</span>
                        </li>
                        <li class=" " id="list-item-2">
                            <div class="icon"> <i class="fa fa-check"></i></div><span>Shipment Particulars</span>
                        </li>
                        <li class=" " id="list-item-3">
                            <div class="icon"> <i class="fa fa-check"></i></div><span>Vendor Assignment</span>
                        </li>
                        <li class=" " id="list-item-4">
                            <div class="icon"> <i class="fa fa-check"></i></div><span>Terms &amp; Documents</span>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-md-8 col-xl-9 col-right px-start-end pb-6" (click)="hideSideBar()">
                <div class="topline pt-4 pt-lg-5 pb-3 pb-lg-4 d-flex align-items-center justify-content-between">
                    <ul *ngIf="breadcrumbBool" class="breadcrumb p-0 m-0">
                        <li class="breadcrumb-item 3 brand" id="0">
                            <button [routerLink]="['/v1/dashboard']">Dashboard</button>
                        </li>
                    </ul>
                    <ul class="breadcrumb p-0 m-0">
                        <li *ngIf="backfirstPage" class="brand"><button (click)="goBackShipment()">Back</button></li>
                        <li *ngIf="backDetailsPage" class="breadcrumb-item 3 brand"><button
                                (click)="goBackDetails()">Back</button></li>
                        <li *ngIf="backParticularsPage" class="breadcrumb-item 3 brand"><button
                                (click)="goBackParticulars()" id="brand">Back</button></li>
                        <li *ngIf="backVendorPage" class="breadcrumb-item 3 brand"><button
                                (click)="goBackVendor()">Back</button></li>
                    </ul>
                    <div class="tab-content step-two">
                        <div class="tab-pane active" id="item-0">1/5</div>
                        <div class="tab-pane" id="item-1">2/5</div>
                        <div class="tab-pane" id="item-2">3/5</div>
                        <div class="tab-pane" id="item-3">4/5</div>
                        <div class="tab-pane" id="item-4">5/5</div>
                    </div>
                </div>
                <form>
                    <div class="tab-content form-step">
                        <div *ngIf="enquiryObject != null" class="tab-pane active" id="item-5">
                            <h3 class="text-center">Shipment</h3>
                            <div class="row mt-3">
                                <div class="col-lg-4 form-group">
                                    <label class="form-label" for="">Enquiry No</label>
                                    <input class="form-control" disabled type="text" [value]="enquiryObject.number">
                                </div>
                                <div class="col-lg-4 form-group">
                                    <label class="form-label" for="">Mode of Shipment</label>
                                    <input class="form-control" disabled type="text"
                                        value="{{ modeOfShipment?.value }}">
                                </div>
                                <div class="col-lg-4 form-group">
                                    <label class="form-label" for="">Trade Type</label>
                                    <input class="form-control" disabled type="text" value="{{ tradeType?.value }}">
                                </div>
                                <div class="col-lg-12 pt-4 form-group">
                                    <label class="form-label" for="">Description</label>
                                    <textarea class="form-control" disabled
                                        rows="6">{{ enquiryObject?.description }}</textarea>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-lg-4 form-group">
                                    <label class="form-label" for="">Loading Port</label>
                                    <input class="form-control" disabled type="text"
                                        [value]="enquiryObject?.originPortName">
                                </div>
                                <div class="col-lg-4">
                                    <label class="form-label" for="">Destination Port</label>
                                    <input class="form-control" disabled type="text"
                                        [value]="enquiryObject?.destinationPortName">
                                </div>
                                <div class="col-lg-4">
                                    <label class="form-label" for="type-of-cargo*">Type of Cargo</label>
                                    <input class="form-control" disabled type="text" [value]="typeOfCargo?.value">
                                </div>

                                <div class="col-lg-4">
                                    <label class="form-label" for="commodity*">Commodity</label>
                                    <input class="form-control" type="text" disabled
                                        value="{{ enquiryObject?.commodity }}">
                                </div>
                                <div class="col-lg-4">
                                    <label class="form-label" for="nature-of-good/commodity">Nature of
                                        Good/Commodity</label>
                                    <input class="form-control" disabled type="text" value="{{ natureOfGoods?.value }}">
                                </div>

                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="type-of-packing*">Type of Packing*</label>
                                    <input class="form-control" type="text" disabled value="{{ typeOfPacking?.value }}">
                                </div>

                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="supplier-name*">Supplier Name*</label>
                                    <input class="form-control" type="text" value="{{ enquiryObject?.supplierName }}"
                                        disabled>
                                </div>

                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="">Storing Type</label>
                                    <input disabled class="form-control" type="text"
                                        value="{{ enquiryObject?.storingType }}">
                                </div>

                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="cargo-readiness-date*">Cargo Readiness Date*</label>
                                    <input disabled class="form-control" type="date"
                                        value="{{ enquiryObject?.cargoReadinessDate }}">
                                </div>

                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="project-name">Project Name</label>
                                    <input disabled class="form-control" type="text"
                                        value="{{ enquiryObject?.projectName }}" />
                                </div>

                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="project-code">Project Code</label>
                                    <input disabled class="form-control" type="text"
                                        value="{{ enquiryObject?.projectCode }}" />
                                </div>

                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="project-name">Currency*</label>
                                    <input class="form-control" type="text" disabled value="{{ currency?.currency }}">
                                </div>

                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="offer-last-submission-date-&amp;-time">Offer Last
                                        Submission Date* </label>
                                    <input class="form-control" disabled type="datetime-local"
                                        value="{{ enquiryObject?.lastSubmissionDate }}">
                                </div>

                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="internalReferenceNumber">Internal Reference
                                        Number</label>
                                    <input disabled class="form-control" type="text"
                                        value="{{ enquiryObject?.internalReferenceNumber }}">
                                </div>

                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="poNumber">PO Number</label>
                                    <input disabled class="form-control" id="poNumber" type="text"
                                        value="{{ enquiryObject?.poNumber }}" />
                                </div>

                                <div class="row justify-content-center">
                                    <div class="col-md-6 form-group pt-4 pt-lg-5">
                                        <button class="btn btn-success w-100" type="button" data-target="item-1"
                                            (click)="nextDetailsPage()">NEXT</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="item-6">
                            <h3 class="text-center">Shipment Details</h3>
                            <div class="row mt-3">
                                <div class="col-lg-4 form-group">
                                    <label class="form-label" for="natureOfMovement">Nature Of Movement</label>
                                    <input class="form-control" type="text" disabled [value]="natureOfMovement?.value">
                                </div>

                                <div *ngIf="enquiryObject?.modeOfShipment == 2" class="col-lg-4 form-group">
                                    <label class="form-label" for="detention">Detention* (Days)</label>
                                    <input class="form-control" type="number" disabled value="{{ enquiryObject?.detention }}">
                                </div>

                                <div class="col-lg-4 form-group">
                                    <label class="form-label" for="demurrage">Demurrage (Days)</label>
                                    <input class="form-control" type="number" disabled value="{{ enquiryObject?.demurrage }}">
                                </div>

                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="pick-up-incoterms*">Pick Up Incoterms</label>
                                    <input class="form-control" type="text" disabled [value]="pickUpIncoterms?.value">
                                </div>

                                <div *ngIf="enquiryObject?.pickupPersonName" class="col-md-4 form-group">
                                    <label class="form-label" for="">Pickup Person Name</label>
                                    <input class="form-control" type="text" disabled
                                        [value]="enquiryObject?.pickupPersonName">
                                </div>

                                <div *ngIf="enquiryObject?.pickupPersonAddress">
                                    <h4>Pickup Person Address</h4>
                                    <div class="row">
                                        <div class="col-md-4 form-group">
                                            <label class="form-label" for="street-address-1">Street Address
                                                1</label>
                                            <input disabled class="form-control" type="text" id="street-address-1"
                                                [value]="enquiryObject.pickupPersonAddress?.street1" />
                                        </div>
                                        <div class="col-md-4 form-group">
                                            <label class="form-label" for="street-address-2">Street Address
                                                2</label>
                                            <input class="form-control" type="text" disabled id="street-address-2"
                                                [value]="enquiryObject.pickupPersonAddress?.street2" />
                                        </div>
                                        <div class="col-md-4 form-group">
                                            <label class="form-label" for="city">City</label>
                                            <input class="form-control" type="text" id="city" disabled
                                                [value]="enquiryObject.pickupPersonAddress?.city" />
                                        </div>
                                        <div class="col-md-4 form-group">
                                            <label class="form-label" for="state">State</label>
                                            <input class="form-control" type="text" id="state" disabled
                                                [value]="enquiryObject.pickupPersonAddress?.state" />
                                        </div>
                                        <div class="col-md-4 form-group">
                                            <label class="form-label" for="country">Country</label>
                                            <input class="form-control" type="text" disabled
                                                [value]="enquiryObject.pickupPersonAddress?.country.name">
                                        </div>
                                        <div class="col-md-4 form-group">
                                            <label class="form-label" for="zip-code">Zip Code</label>
                                            <input class="form-control" type="text" id="zip-code" disabled
                                                [value]="enquiryObject.pickupPersonAddress?.zipCode" />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="delivery-up-incoterms*">Delivery Up Incoterms</label>
                                    <input class="form-control" type="text" disabled [value]="deliveryIncoterms?.value">
                                </div>

                                <div *ngIf="enquiryObject?.deliveryPersonName" class="col-md-4 form-group">
                                    <label class="form-label" for="">Delivery Person Name</label>
                                    <input class="form-control" type="text" disabled
                                        [value]="enquiryObject?.deliveryPersonName">
                                </div>

                                <div *ngIf="enquiryObject?.destinationTerminal" class="col-md-4 form-group">
                                    <label class="form-label" for="">Destination Terminal</label>
                                    <input class="form-control" type="text" disabled
                                        [value]="enquiryObject?.destinationTerminal">
                                </div>

                                <div *ngIf="enquiryObject?.deliveryPersonAddress">
                                    <h4>Delivery Person Address</h4>
                                    <div class="row">
                                        <div class="col-md-4 form-group">
                                            <label class="form-label" for="street-address-1">Street Address
                                                1</label>
                                            <input disabled class="form-control" type="text" id="street-address-1"
                                                [value]="enquiryObject.deliveryPersonAddress?.street1" />
                                        </div>
                                        <div class="col-md-4 form-group">
                                            <label class="form-label" for="street-address-2">Street Address
                                                2</label>
                                            <input class="form-control" type="text" disabled id="street-address-2"
                                                [value]="enquiryObject.deliveryPersonAddress?.street2" />
                                        </div>
                                        <div class="col-md-4 form-group">
                                            <label class="form-label" for="city">City</label>
                                            <input class="form-control" type="text" id="city" disabled
                                                [value]="enquiryObject.deliveryPersonAddress?.city" />
                                        </div>
                                        <div class="col-md-4 form-group">
                                            <label class="form-label" for="state">State</label>
                                            <input class="form-control" type="text" id="state" disabled
                                                [value]="enquiryObject.deliveryPersonAddress?.state" />
                                        </div>
                                        <div class="col-md-4 form-group">
                                            <label class="form-label" for="country">Country</label>
                                            <input class="form-control" type="text" disabled
                                                [value]="enquiryObject.deliveryPersonAddress?.country.name">
                                        </div>
                                        <div class="col-md-4 form-group">
                                            <label class="form-label" for="zip-code">Zip Code</label>
                                            <input class="form-control" type="text" id="zip-code" disabled
                                                [value]="enquiryObject.deliveryPersonAddress?.zipCode" />
                                        </div>
                                    </div>
                                </div>


                                <div *ngIf="enquiryObject?.exporterName">
                                    <label class="form-label" for="">Exporter Name</label>
                                    <input class="form-control" type="text" disabled
                                        [value]="enquiryObject?.exporterName">
                                </div>
                                <div *ngIf="enquiryObject?.exporterContact">
                                    <label class="form-label" for="">Exporter Contact</label>
                                    <input class="form-control" type="number" disabled
                                        [value]="enquiryObject?.exporterContact">
                                </div>

                                <div *ngIf="enquiryObject?.exporterEmailId">
                                    <label class="form-label" for="">Exporter EmailId</label>
                                    <input class="form-control" type="email" disabled
                                        [value]="enquiryObject?.exporterEmailId">
                                </div>

                                <div *ngIf="enquiryObject?.exporterAddress">
                                    <h4>Exporter Address</h4>
                                    <div class="row">
                                        <div class="col-md-4 form-group">
                                            <label class="form-label" for="street-address-1">Street Address
                                                1</label>
                                            <input disabled class="form-control" type="text" id="street-address-1"
                                                [value]="enquiryObject.exporterAddress?.street1" />
                                        </div>
                                        <div class="col-md-4 form-group">
                                            <label class="form-label" for="street-address-2">Street Address
                                                2</label>
                                            <input class="form-control" type="text" disabled id="street-address-2"
                                                [value]="enquiryObject.exporterAddress?.street2" />
                                        </div>
                                        <div class="col-md-4 form-group">
                                            <label class="form-label" for="city">City</label>
                                            <input class="form-control" type="text" id="city" disabled
                                                [value]="enquiryObject.exporterAddress?.city" />
                                        </div>
                                        <div class="col-md-4 form-group">
                                            <label class="form-label" for="state">State</label>
                                            <input class="form-control" type="text" id="state" disabled
                                                [value]="enquiryObject.exporterAddress?.state" />
                                        </div>
                                        <div class="col-md-4 form-group">
                                            <label class="form-label" for="country">Country</label>
                                            <input class="form-control" type="text" disabled
                                                [value]="enquiryObject.exporterAddress?.country.name">
                                        </div>
                                        <div class="col-md-4 form-group">
                                            <label class="form-label" for="zip-code">Zip Code</label>
                                            <input class="form-control" type="text" id="zip-code" disabled
                                                [value]="enquiryObject.exporterAddress?.zipCode" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row justify-content-center">
                                <div class="col-md-6 form-group pt-4 pt-lg-5">
                                    <button class="btn btn-success w-100" type="button" data-target="item-2"
                                        (click)="nextParticularsPage()">NEXT</button>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="item-7">
                            <div *ngIf="enquiryObject?.modeOfShipment == 1" class="row">
                                <div class="position-relative d-flex flex-wrap align-items-center add-new-wrap mb-3">
                                    <div class="flex-grow-1">
                                        <h4 class="mb-0 text-center">Package Details</h4>
                                    </div>
                                </div>

                                <div class="table-responsive">
                                    <table
                                        class="table table-borderless text-center table-head-dark table-head-condensed table-head-py-3">
                                        <thead>
                                            <tr>
                                                <th>Quantity
                                                </th>
                                                <th>Length
                                                </th>
                                                <th>Width
                                                </th>
                                                <th>Height
                                                </th>
                                                <th>Unit of measurement
                                                </th>
                                                <th>Gross Weight
                                                </th>
                                                <th>Total Gross Weight UOM
                                                </th>
                                                <th>Total Volume
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let package of shipmentPackages">
                                                <td>{{ package.quantity }}</td>
                                                <td>{{ package.length }}</td>
                                                <td>{{ package.width }}</td>
                                                <td>{{ package.height }}</td>
                                                <td>{{ package.dimensionsUOM }}</td>
                                                <td>{{ package.grossWeight }}</td>
                                                <td>{{ package.weightUOM }}</td>
                                                <td>{{ package.totalVolume }}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot class="table-success">
                                            <tr>
                                                <td>Chargable Weight</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>{{ enquiryObject?.chargableWeight }} {{enquiryObject?.chargableWeightUOM }}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>

                            <div *ngIf="enquiryObject?.modeOfShipment == 2" class="row">
                                <div class="col-md-4">
                                    <label class="form-label" for="">HS Code</label>
                                    <input class="form-control" type="text" disabled [value]="enquiryObject?.hsCode">
                                </div>
                                <div class="col-md-4">
                                    <label class="form-label" for="">Container Load Type</label>
                                    <input class="form-control" type="text" disabled [value]="containerLoadType?.value">
                                </div>

                                <div *ngIf="containers.length > 0" class="table-responsive">
                                    <div
                                        class="position-relative d-flex flex-wrap align-items-center add-new-wrap mb-3">
                                        <div class="flex-grow-1">
                                            <h4 class="mb-0 text-center">Containers</h4>
                                        </div>
                                    </div>
                                    <table
                                        class="table table-borderless text-center table-head-dark table-head-condensed table-head-py-3">
                                        <thead>
                                            <tr>
                                                <th>Type</th>
                                                <th>Size</th>
                                                <th>Quantity</th>
                                                <th>Weight/Container</th>
                                                <th>UOM</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let container of containers">
                                                <td>{{ container.type }}</td>
                                                <td>{{ container.size }}</td>
                                                <td>{{ container.quantity }}</td>
                                                <td>{{ container.weight }}</td>
                                                <td>{{ container.uom }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div *ngIf="shipmentPackages.length > 0" class="table-responsive">
                                    <div
                                        class="position-relative d-flex flex-wrap align-items-center add-new-wrap mb-3">
                                        <div class="flex-grow-1">
                                            <h4 class="mb-0 text-center">Package Details</h4>
                                        </div>
                                    </div>
                                    <table
                                        class="table table-borderless text-center table-head-dark table-head-condensed table-head-py-3">
                                        <thead>
                                            <tr>
                                                <th>Quantity
                                                </th>
                                                <th>Length
                                                </th>
                                                <th>Width
                                                </th>
                                                <th>Height
                                                </th>
                                                <th>Unit of measurement
                                                </th>
                                                <th>Gross Weight
                                                </th>
                                                <th>Total Gross Weight UOM
                                                </th>
                                                <th>Total Volume
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let package of shipmentPackages">
                                                <td>{{ package.quantity }}</td>
                                                <td>{{ package.length }}</td>
                                                <td>{{ package.width }}</td>
                                                <td>{{ package.height }}</td>
                                                <td>{{ package.dimensionsUOM }}</td>
                                                <td>{{ package.grossWeight }}</td>
                                                <td>{{ package.weightUOM }}</td>
                                                <td>{{ package.totalVolume }}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot class="table-success">
                                            <tr>
                                                <td>Chargable Weight</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>{{ enquiryObject?.chargableWeight }} {{
                                                    enquiryObject?.chargableWeightUOM }}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>

                                <div *ngIf="breakBulks.length > 0" class="table-responsive">
                                    <div
                                        class="position-relative d-flex flex-wrap align-items-center add-new-wrap mb-3">
                                        <div class="flex-grow-1">
                                            <h4 class="mb-0 text-center">Break Bulk Details</h4>
                                        </div>
                                    </div>
                                    <table
                                        class="table table-borderless text-center table-head-dark table-head-condensed table-head-py-3">
                                        <thead>
                                            <tr>
                                                <th>Total Net Weight
                                                </th>
                                                <th>Total Gross Weight
                                                </th>
                                                <th>UOM</th>
                                                <th>Total Volume
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of breakBulks">
                                                <td>{{ item.totalnetWeight }}</td>
                                                <td>{{ item.totalGrossWeight }}</td>
                                                <td>{{ item.uom }}</td>
                                                <td>{{ item.totalVolume }}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot class="table-success">
                                            <tr>
                                                <td>Chargable Weight</td>
                                                <td></td>
                                                <td></td>
                                                <td>{{ enquiryObject?.chargableWeight }} {{
                                                    enquiryObject?.chargableWeightUOM }}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>

                            <div class="row justify-content-center">
                                <div class="col-md-6 form-group pt-4 pt-lg-5">
                                    <button class="btn btn-success w-100" type="button" data-target="item-3"
                                        (click)="nextVendorAssignmentPage()">NEXT</button>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="item-8">
                            <h3 class="text-center">Vendor Assignment</h3>
                            
                            <div *ngIf="assignedVendors.length > 0" class="table-responsive">
                                <table class="table table-borderless table-striped table-head-dark table-head-condensed table-head-py-3">
                                    <thead>
                                        <tr>
                                            <th>Vendor Org</th>
                                            <th>Name</th>
                                            <th>Business Type</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let vendor of assignedVendors">
                                            <td>{{ vendor.name }}</td>
                                            <td>{{ vendor.pocFirstName }} {{ vendor.pocLastName }}</td>
                                            <td>{{ vendor.businessType.value }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                            <div class="row justify-content-center">
                                <div class="col-md-6 form-group pt-4 pt-lg-5">
                                    <button class="btn btn-success w-100" type="button" data-target="item-4"
                                        (click)="nextTermsPage()">NEXT</button>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="item-9">
                            <div class="row justify-content-center align-items-center">
                                    <h3 class="text-center">Terms &amp; Conditions</h3>
                            </div>
                            <br>
                            <div *ngIf="termsConditions.length > 0" class="row mb-4 justify-content-center align-items-center">
                                <div class="col-lg-11"  *ngFor="let item of termsConditions">
                                    <input type="text" disabled class="form-control terms-label" [value]="item.label">
                                    <textarea class="form-control" disabled rows="6"id="terms-&amp;-conditions">{{item.content}}</textarea>
                                </div>
                            </div>
                            <div *ngIf="artifacts.length > 0" class="table-responsive mt-4">
                                <h3 class="text-center">Files</h3>
                                <form>
                                    <table
                                        class="table table-borderless table-striped table-head-dark table-head-condensed table-head-py-3">
                                        <thead>
                                            <tr>
                                                <th>Sr.No</th>
                                                <th>File Name</th>
                                                <th>Label</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of artifacts; index as i">
                                                <td>{{ i + 1 }}</td>
                                                <td>{{ item.name }}</td>
                                                <td>
                                                    {{ item.label }}
                                                </td>
                                                <td>
                                                    <button (click)="dataURItoBlob(item)">
                                                        <i class="fa fa-download" aria-hidden="true" title="Download"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </form>
                            </div>

                            <div class="row justify-content-center">
                                <div class="col-md-6 form-group pt-4 pt-lg-5">
                                    <button class="btn btn-success w-100" type="button" data-target="item-4"
                                        (click)="backPage()">Back</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>