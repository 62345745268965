import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SubscriptionExtensionService } from 'src/app/helpers/subscription-extension.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-manage-extension',
  templateUrl: './manage-extension.component.html',
  styleUrls: ['./manage-extension.component.css']
})
export class ManageExtensionComponent implements OnInit {

  page: number = 0;
  size: number = 25;
  subscriptionLength: any

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<any>;
  displayedColumns = [ 'client.id', 'client.pocFirstName', 'id', 'subscriptionType', 'licenseType', 'softExpiryDate', 'hardExpiryDate', 'action'];

  subscriptionList: Array<any> = [];
  disabled: Boolean = true;

  constructor(private http: HttpClient, private subscriptionSer: SubscriptionExtensionService) { }

  ngOnInit(): void {
    this.getSubscriptions();
    this.checkList();
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  //get all subscription list
  getSubscriptions() {
    this.subscriptionSer.getAllSubscription(this.page, this.size).subscribe
      ((res) => {
        console.log(res.content);
        this.dataSource = new MatTableDataSource(res.content);
        this.dataSource.sort = this.sort;
        this.subscriptionLength = res.totalElements;
      },
        (error) => {
          console.log(error);
        })
  }

  checkList() {
    for (let index = 0; index < this.subscriptionList.length; index++) {
      const element = this.subscriptionList[index];
      console.log(element);
    }
  }

  //Delete Subscription
  deleteSubscription(id: number) {
    this.subscriptionSer.deleteSubscriptionById(id).subscribe
      ((res) => {
        console.log(res);
        Swal.fire({
          titleText: "Success",
          text: res.message,
          icon: "success",
          timer: 5000
        });
        this.getSubscriptions();
      },
        (error) => {
          console.log(error);
          Swal.fire({
            titleText: "Invalid",
            text: error.message,
            icon: 'error',
            timer: 5000
          });
        })
  }

  onPageChange(event: PageEvent) {
    this.size = event.pageSize;
    this.page = event.pageIndex;
    this.getSubscriptions();
  }
}
