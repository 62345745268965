<!--header start-->
<header class="box-absolute d-flex align-items-center topline text-white">
  <app-sidebar></app-sidebar>
  <div class="page-title-center mt-3">
    <div class="text-center">
      <h1>Marketing Space List</h1>
    </div>
  </div>
  <div class="breadcrumb-div mt-3">
    <ul class="breadcrumb p-0 m-0">
      <li class="breadcrumb-item 3" id="0"><a class="a-space" [routerLink]="['/v1/dashboard']">Dashboard</a></li>
      <li class="breadcrumb-item 3" id="1"><a class="a-space">Master Data</a></li>
      <li class="breadcrumb-item"><a class="a-space">Marketing Space</a></li>
    </ul>
  </div>
</header>
<!--header end-->
<section class="banner text-center text-white d-flex" id="bnner">
  <div class="container position-relative">
    <div class="row justify-content-center">
      <div class="col-lg-12">
      </div>
    </div>
  </div>
</section>
<!--request start-->
<section class="request-demo-wrapper pb-10" (click)="hideSideBar()">
  <div class="container shadow bg-white">
    <div class="row">
      <div class="col-lg-12">
        <div class="box">
          <form>
            <div
              class="filter-wrap btn-group d-flex flex-wrap flex-md-nowrap justify-content-center gap-2 pb-3 pb-lg-4 px-3 px-lg-4">
              <div class="input-group">
                <input placeholder="Search" class="form-control rounded-3 px-4" style="height: 40px;" type="text" #input
                  (keyup)="applyFilter($event.target.value)">
                <button class="input-group-btn end-0" type="button"><i class="fa fa-search"></i>
                </button>
              </div>
              <button *ngIf="!disableCreateBtn" mat-button mat-flat-button color="success"
                class="rounded-3 flex-shrink-0 btn-success" (click)="openDialog('Add',{})"><i
                  class="fa fa-plus me-2"></i><span>
                  Market Space </span>
              </button>
              <button *ngIf="disableCreateBtn" mat-button mat-flat-button color="secondary"
                class="rounded-3 flex-shrink-0 btn-secondary"><i class="fa fa-plus me-2"></i><span>
                  Market Space </span>
              </button>
            </div>
            <div class="mat-elevation-z8 table-responsive">
              <table mat-table [dataSource]="dataSource" matSort
                class="table table-striped table-footer-dark table-borderless">

                <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef class="text-white"> Id </th>
                  <td mat-cell *matCellDef="let element;let i=index">{{i+1}}</td>
                </ng-container>

                <ng-container matColumnDef="companyName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-white">
                    Company Name</th>
                  <td mat-cell *matCellDef="let element">
                    {{element.companyName}} </td>
                </ng-container>
                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef colspan="3" class="text-white">Actions </th>
                  <td mat-cell *matCellDef="let element" class="action-link">
                    <button *ngIf="!disableAddBtn" class="fa fa-edit text-primary"
                      (click)="openDialog('Update',element)" type="button">
                    </button>
                    <button *ngIf="disableAddBtn" class="fa fa-edit text-secondary" type="button">
                    </button>

                    <button *ngIf="!disableAddBtn" class="fa fa-trash text-danger"
                      (click)="deleteMarketSpace(element.id, element.companyName)" type="button">
                    </button>
                    <button *ngIf="disableAddBtn" class="fa fa-trash text-secondary" type="button">
                    </button>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns" class="bg-dark"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell text-center text-danger" colspan="3">
                    <div *ngIf="serchFilter">
                      No data matching the filter "{{ input.value }}"
                    </div>
                    <div *ngIf="nodata">
                      <h5>Data is Not Available</h5>
                    </div>
                  </td>
                </tr>

              </table>
              <mat-paginator class="" [pageSizeOptions]="[25,50,100]" showFirstLastButtons
                aria-label="Select page of users" [length]="length" (page)="onPageChange($event)">
              </mat-paginator>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
