import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MasterTableService } from 'src/app/helpers/master-table.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dialog-pickup-terms',
  templateUrl: './dialog-pickup-terms.component.html',
  styleUrls: ['./dialog-pickup-terms.component.css']
})
export class DialogPickupTermsComponent implements OnInit {

  local_data: any;
  action: any;
  isReadOnly = false;
  dialogform: FormGroup;
  isrequired = true;
  natureOfMovementList: Array<any> = [];
  dropdownSettings: IDropdownSettings = {};
  selectedItems: Array<any> = [];

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<DialogPickupTermsComponent>,
    private masterTableService: MasterTableService,

    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.action = data.action;
    this.local_data = { ...data };
    if (this.action == 'Update') { this.isReadOnly = true; this.isrequired = false; }
  }
  ngOnInit(): void {
    this.dialogform = this.formBuilder.group({
      code: [""],
      value: [""],
      natureOfMovement: this.formBuilder.array([])
    });
    if (this.action != "Add") {
      this.dialogform.patchValue({
        code: this.local_data.code,
        value: this.local_data.value,
      });
      // ToDo selectedItems to be populated and add to formArray
      this.selectedItems = this.local_data.natureOfMovement;
      let nature: Array<any> = this.local_data.natureOfMovement;
      for (let index = 0; index < nature.length; index++) {
        const element = nature[index];
        this.prePopulateNatureOfMovement(element.code);
      }
    }
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'code',
      textField: 'value',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true
    };
    this.getAllNatureOfMovementList();
  }

  get natureOfMovement() { return this.dialogform.get('natureOfMovement') as FormArray; }

  prePopulateNatureOfMovement(code) {
    this.natureOfMovement.push(this.formBuilder.group({
      code: [code]
    }));
  }

  doAction() {
    if (this.dialogform.valid) {
      this.dialogRef.close({ event: this.action, data: this.dialogform.value });
    } else {
      Swal.fire({
        title: "warning",
        icon: 'warning',
        titleText: "Please Provide mandatory Field",
        timer: 3000
      })
    }
  }
  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  onItemSelect(event) {
    this.prePopulateNatureOfMovement(event.code);
  }

  onSelectAll(event: Array<any>) {
    this.natureOfMovement.clear();
    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      this.prePopulateNatureOfMovement(element.code)
    }
  }

  onDeSelect(event) {
    let natureOfMovement: Array<any> = this.natureOfMovement.value;
    natureOfMovement.filter((element, index) => {
      if (element.code == event.code) {
        this.natureOfMovement.removeAt(index);
      }
    })
  }

  onDeSelectAll() {
    this.natureOfMovement.clear();
  }

  getAllNatureOfMovementList() {
    this.masterTableService.getNatureOfMovement().subscribe(
      (response) => {
        console.log(response);
        this.natureOfMovementList = response
      },
      (error) => {
        console.log(error);
      },
    )
  }

}
