import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MasterTableService } from 'src/app/helpers/master-table.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cargo-box',
  templateUrl: './cargo-box.component.html',
  styleUrls: ['./cargo-box.component.css']
})
export class CargoBoxComponent implements OnInit {

  action: string;
  local_data: any;
  dialogForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<CargoBoxComponent>, private formbuilder: FormBuilder,
    //@Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: MasterTableService) {
    console.log(data);
    this.local_data = { ...data };
    this.action = this.local_data.action;
  }


  ngOnInit(): void {
    this.dialogForm = this.formbuilder.group({
      value: ''
    });
    if (this.action != "Add") {
      this.dialogForm.addControl('id', new FormControl());
      this.dialogForm.patchValue({
        value: this.local_data.value,
        id: this.local_data.id
      });
    }
  }

  doAction() {
    if (this.dialogForm.valid) {
      this.dialogRef.close({ event: this.action, data: this.dialogForm.value });
    } else {
      Swal.fire({
        title: 'warning',
        icon: 'warning',
        titleText: 'Please provide mandatory field',
        timer: 3000
      });
    }
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

}
