import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { MasterTableService } from 'src/app/helpers/master-table.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-hs-code-dialog-box',
  templateUrl: './hs-code-dialog-box.component.html',
  styleUrls: ['./hs-code-dialog-box.component.css']
})
export class HsCodeDialogBoxComponent implements OnInit {
  
  action: any;
  local_data: any;
  dialogform: FormGroup;
  countryList: Array<any> = [];
  documentList: Array<any> = [];
  math = Math;
  isReadOnly = false;
  isrequired = true;

  constructor(
    private masterTableService: MasterTableService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<HsCodeDialogBoxComponent>,

    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.action = data.action;
    this.local_data = { ...data };
    console.log(this.local_data);
    if (this.action == 'Update') { this.isReadOnly = true; this.isrequired = false; }
  }

  ngOnInit(): void {
    this.dialogform = this.formBuilder.group({
      country: this.formBuilder.group({
        id: ['', Validators.required]
      }),
      artifacts: this.formBuilder.array([]),
      referenceLink: ['', Validators.required]
    });
    this.getAllCountries();
    if (this.action != "Add") {
      this.dialogform.addControl('id', new FormControl(this.local_data.id));
      this.dialogform.get('referenceLink').patchValue(this.local_data.referenceLink);
      this.country.get('id').patchValue(this.local_data.country.id);
      this.documentList = this.local_data.artifacts;
    }
  }

  get artifacts(){return this.dialogform.get('artifacts') as FormArray; }
  get country(){return this.dialogform.get('country') as FormGroup; }
  get form(){return this.dialogform.controls; }

  doAction() {
    let submitBool = this.validateFilesAtSubmit();
    if (this.dialogform.valid && submitBool == false) {
      let data = {
        referenceLink: this.dialogform.get('referenceLink').value,
        country: {
          id: this.country.get('id').value
        },
        artifacts: this.documentList
      }
      if (this.action != 'Add') {
        data['id'] = this.local_data.id
      }
      this.dialogRef.close({ event: this.action, data: data });
    }
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  getAllCountries(){
    this.masterTableService.getAllCountries().subscribe(
      (response) => {
        this.countryList = response
      },
      (error) => {
        console.log(error);
        
      },
    )
  }

  addFiles(files: NgxFileDropEntry[]) {
    for (let index = 0; index < files.length; index++) {
      const droppedFile = files[index];
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          // Here you can access the real file size & content
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            let data = {
              "content": reader.result,
              "contentType": reader.result?.toString().split(',')[0],
              "name": file.name,
              "size": file.size
            }
            this.documentList.push(data);
          }
        })
      }
      else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }

  deleteDocument(index: number) {
    this.documentList.splice(index, 1)
  }

  deleteServerFile(artifactId, index) {
    this.masterTableService.deleteHsCodeArtifact(this.local_data.id, artifactId).subscribe(
      (response) => {
        console.log(response);
        if (response.code == 200) {
          Swal.fire({
            titleText: "Success",
            text: response.message,
            icon: "success",
            timer: 5000
          });
          this.deleteDocument(index);
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  validateFilesAtSubmit() {
    let submitBool = false;
    let totalFilesSize = 0;
    if (this.documentList.length >= 1) {
      for (let index = 0; index < this.documentList.length; index++) {
        const document = this.documentList[index];
        const fileSize = Math.round((document.size / 1024));
        totalFilesSize = fileSize + totalFilesSize;
        if (document.label == undefined) {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Add label to file " + document.name,
            timer: 5000
          })
          submitBool = true;
          break
        }
        else if (fileSize > 2048) {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Upload File :" + document.name + " with size less than 2MB",
            timer: 5000
          });
          submitBool = true;
          break
        }
        else if (totalFilesSize > 10240) {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Total File size should be size less than 10MB",
            timer: 5000
          });
          submitBool = true;
          break
        }
      }
    }
    return submitBool
  }

  downloadFileLocal(data) {
    const linkSource = data.content;
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = data.name;
    downloadLink.click();
  }

}
