import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DemoService } from '../helpers/demo.service';
import Swal from 'sweetalert2';
import { SocketService } from '../helpers/socket.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css'],
})
export class HomePageComponent implements OnInit {
  contactUsForm!: FormGroup;
  constructor(
    private fb: FormBuilder,
    private demoService: DemoService,
    private socketService: SocketService
  ) {}

  ngOnInit(): void {
    this.contactUsForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      emailId: [
        '',
        Validators.compose([Validators.required, Validators.email]),
      ],
      contactNumber: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(9),
          Validators.maxLength(16),
        ]),
      ],
      description: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(300)]),
      ],
    });

    this.clearStorage();
    this.gotoTop();
  }

  get contact() {
    return this.contactUsForm.controls;
  }

  contactUs() {
    if (!this.contactUsForm.invalid) {
      console.log(this.contactUsForm.value);
      this.demoService.contactUs(this.contactUsForm.value).subscribe(
        (response) => {
          if (response.code == 201) {
            Swal.fire({
              titleText: 'Success',
              text: response.message,
              icon: 'success',
              timer: 5000,
            });
            this.contactUsForm.reset();
          }
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      this.contact.firstName.markAsDirty();
      this.contact.lastName.markAsDirty();
      this.contact.emailId.markAsDirty();
      this.contact.description.markAsDirty();
      this.contact.contactNumber.markAsDirty();
      Swal.fire({
        titleText: 'Invalid',
        text: 'Fill the mandatory fields',
        icon: 'error',
        timer: 5000,
        showCancelButton: false,
      });
    }
  }

  clearStorage() {
    sessionStorage.clear();
    localStorage.clear();
  }

  //fuction to scroll to top on next button click
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

}
