<header class="box-absolute d-flex align-items-center topline text-white">
    <app-sidebar></app-sidebar>
    <div class="page-title-center mt-3">
        <div class="text-center">
            <h1>Detail Auction Comparison</h1>
        </div>
    </div>
    <div class="breadcrumb-div">
        <ul class="breadcrumb p-0 m-0">
            <li class="breadcrumb-item 3" id="0"><a href="dashboard.html">Dashboard</a></li>
            <li class="breadcrumb-item 3" id="1"><a href="request-for-quotation.html">Auction Summary</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0)">Detail Comparison</a></li>
        </ul>
    </div>
</header>
<!--header end-->
<section class="banner text-center text-white d-flex" id="bnner">
    <div class="container position-relative">
        <div class="row justify-content-center">
            <!-- <div class="col-lg-12">
                <h1>Detail Auction Comparison</h1>
            </div> -->
        </div>
    </div>
</section>
<!--request start-->
<section class="request-demo-wrapper pb-10">
    <div class="container shadow bg-white">
        <div class="row">
            <div class="col-lg-12">
                <div class="box">
                    <form>
                        <div class="row">
                            <div class="col-md-6 form-group form-group-inline">
                                <label class="form-label" for="auction-number-:">Auction Number :</label>
                                <input class="form-control" value={{auctionObj?.number}} type="text"
                                    id="auction-number-:" readonly="readonly">
                            </div>
                            <div class="col-md-6 form-group form-group-inline">
                                <label class="form-label" for="auction-date-&amp;-time-:">Auction Date &amp; Time
                                    :</label>
                                <input class="form-control"
                                    [value]="auctionObj?.auctionDate | date: 'dd-MM-YYYY HH:mm:ss'" type="text"
                                    id="auction-date-&amp;-time-:" readonly="readonly">
                            </div>
                            <div class="col-md-6 form-group form-group-inline">
                                <label class="form-label" for="project-name-:">Project Name :</label>
                                <input class="form-control" value={{enquiryObj?.projectName}} type="text"
                                    id="project-name-:" readonly="readonly">
                            </div>
                            <div class="col-md-6 form-group form-group-inline">
                                <label class="form-label" for="project-code-:">Project Code :</label>
                                <input class="form-control" value={{enquiryObj?.projectCode}} type="text"
                                    id="project-code-:" readonly="readonly">
                            </div>
                            <div class="col-md-6 form-group form-group-inline">
                                <label class="form-label" for="conducted-by-:">Conducted By :</label>
                                <input class="form-control" value={{auctionObj?.auctionConductedBy}} type="text"
                                    id="conducted-by-:" readonly="readonly">
                            </div>
                            <div class="col-md-6 form-group form-group-inline">
                                <label class="form-label" for="currency-:">Currency :</label>
                                <input class="form-control" value={{auctionObj?.currency}} type="text" id="currency-:"
                                    readonly="readonly">
                            </div>
                            <div class="col-md-12 form-group form-group-inline">
                                <label class="form-label" for="auction-description-:">Auction Title :</label>
                                <input class="form-control" value="{{auctionObj?.description}}" type="text"
                                    id="auction-description-:" readonly="readonly">
                            </div>
                        </div>
                        <div class="col-md-12 form-group form-group-inline">
                            <label class="form-label" for="auction-description-:">Base Price :</label>
                            <input class="form-control" value={{auctionObj?.baseprice}} type="text"
                                id="auction-description-:" readonly="readonly">
                        </div>
                        <div class="table-responsive">
                            <table mat-table [dataSource]="dataSource"
                                class="table table-borderless table-striped table-footer-dark table-head-dark">
                                <ng-container matColumnDef="forwarderName">
                                    <th mat-header-cell *matHeaderCellDef class="text-white">Forwarder Name</th>
                                    <td mat-cell *matCellDef="let element">{{element.vendor.name|uppercase}}</td>
                                </ng-container>
                                <ng-container matColumnDef="quoteValue">
                                    <th mat-header-cell *matHeaderCellDef class="text-white">Quote Value</th>
                                    <td mat-cell *matCellDef="let element">{{element.quoteValue || '---' }}</td>
                                </ng-container>
                                <ng-container matColumnDef="auctionValue">
                                    <th mat-header-cell *matHeaderCellDef class="text-white">Bid Value</th>
                                    <td mat-cell *matCellDef="let element">{{element.auctionValue}}</td>
                                </ng-container>
                                <ng-container matColumnDef="rank">
                                    <th mat-header-cell *matHeaderCellDef class="text-white">Rank</th>
                                    <td mat-cell *matCellDef="let element;let i=index">L{{i+1}}</td>
                                </ng-container>
                                <ng-container matColumnDef="var-amount">
                                    <th mat-header-cell *matHeaderCellDef class="text-white">Savings(Amount)</th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ (auctionObj.baseprice - element.auctionValue) }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="savings">
                                    <th mat-header-cell *matHeaderCellDef class="text-white">Savings(%)</th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ ((auctionObj.baseprice - element.auctionValue) * 100) / auctionObj.baseprice
                                        }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="actions">
                                    <th mat-header-cell class="text-white" *matHeaderCellDef> Actions </th>
                                    <td mat-cell *matCellDef="let row; let i = index">
                                        <input type="checkbox" (change)="check($event, row.id, i)">
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displaycolumn" class="bg-dark"></tr>
                                <tr mat-row *matRowDef="let row; columns: displaycolumn;"></tr>

                            </table>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-md-6 form-group pt-lg-3">
                                <button class="btn text-dark small btn-outline-success px-3" type="button"
                                    (click)="createServiceOrder()">CREATE
                                    SERVICE ORDER</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!--request end  -->