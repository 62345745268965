import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { UserService } from 'src/app/helpers/user.service';
import Swal from 'sweetalert2';
import { SocketService } from 'src/app/helpers/socket.service';
import {
  SidebarComponent,
  sideBarMenu,
} from 'src/app/layout/sidebar/sidebar.component';
import { AuthServiceService } from 'src/app/helpers/auth-service.service';

@Component({
  selector: 'app-user-role',
  templateUrl: './user-role.component.html',
  styleUrls: ['./user-role.component.css'],
})
export class UserRoleComponent implements OnInit {
  data: any;
  allPermissions: Array<sideBarMenu> = [];
  roleForm: FormGroup;
  roleId: any;
  user_id: any;
  breadcrumbBool: Boolean = true;
  title: string = 'NEW ROLE';
  dType: number;
  isEdit: boolean = false;

  allPermissionsList: Array<any> = [];
  allPermissionCategoryList: Array<any> = [];

  adminPermissionList: Array<any> = [];
  auctionPermissionList: Array<any> = [];
  invoicePermissionList: Array<any> = [];
  masterRecordPermissionList: Array<any> = [];
  reportPermissionList: Array<any> = [];
  trackAndTracePermissionList: Array<any> = [];
  userManagementPermissionList: Array<any> = [];
  serviceOrderPermissionList: Array<any> = [];
  configurationPermissionList: Array<any> = [];
  subscriptionPermissionList: Array<any> = [];
  dashboardPermissionList: Array<any> = [];
  procurmentPermissionList: Array<any> = [];

  simplePermissionList: Array<any> = [];

  currentUserData: any;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private userService: UserService,
    private socketService: SocketService,
    public sidebar: SidebarComponent,
    private authService: AuthServiceService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getAllPermissionsFromDB();
    this.currentUserData = JSON.parse(sessionStorage.getItem('userData'));
    console.log('this is current user data dType ', this.currentUserData.dType);
    if (this.dType == 1) {
      this.allPermissions = this.sidebar.initDTypeOneMenu();
    } else if (this.dType == 2) {
      this.allPermissions = this.sidebar.intiDTypeTwoMenu();
    } else if (this.dType == 3) {
      this.allPermissions = this.sidebar.initDTypeThreeMenu();
    }

    // Form
    this.roleForm = this.fb.group({
      authority: ['', Validators.required],
      permissions: this.fb.array([]),
    });

    this.roleId = this.route.snapshot.paramMap.get('id');
    if (this.roleId) {
      this.title = 'UPDATE ROLE';
      console.log(this.roleId);
      this.isEdit = true;
      this.getRoleById(this.roleId);
    }
    //  else {
    //   this.getAllPermissionsFromDB();
    // }
    this.getCategoryList();
  }

  get roleformcontroller() {
    return this.roleForm.controls;
  }

  get permissionsArray() {
    return this.roleForm.get('permissions') as FormArray;
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove('sidebar-active');
  }

  getAllPermissionsFromDB() {
    this.authService.getAllPermissions().subscribe({
      next: (res) => {
        // console.log('this is permissions response ', res);
        this.allPermissionsList = res;
        console.log('this is all permission lsit ', this.allPermissionsList);

        if (this.allPermissionsList != null) {
          this.filterPermissionsAccordingToCategory();
        }
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  getCategoryList() {
    this.authService.getAllCategoryList().subscribe({
      next: (res) => {
        console.log('this is res ', res);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  filterPermissionsAccordingToCategory() {
    this.allPermissionsList.filter((item) => {
      if (item.permissionCategory?.id == 1) {
        this.adminPermissionList.push(item);
      } else if (item.permissionCategory?.id == 2) {
        this.auctionPermissionList.push(item);
      } else if (item.permissionCategory?.id == 3) {
        this.invoicePermissionList.push(item);
      } else if (item.permissionCategory?.id == 4) {
        this.masterRecordPermissionList.push(item);
      } else if (item.permissionCategory?.id == 5) {
        this.reportPermissionList.push(item);
      } else if (item.permissionCategory?.id == 6) {
        this.trackAndTracePermissionList.push(item);
      } else if (item.permissionCategory?.id == 7) {
        this.userManagementPermissionList.push(item);
      } else if (item.permissionCategory?.id == 8) {
        this.serviceOrderPermissionList.push(item);
      } else if (item.permissionCategory?.id == 9) {
        this.configurationPermissionList.push(item);
      } else if (item.permissionCategory?.id == 10) {
        this.subscriptionPermissionList.push(item);
      } else if (item.permissionCategory?.id == 11) {
        this.dashboardPermissionList.push(item);
      } else if (item.permissionCategory?.id == 12) {
        this.procurmentPermissionList.push(item);
      }
    });
  }

  onClick(event: any, id: number) {
    let selectedCode = event.target.value;
    let selectedID = id;
    if (event.target.checked) {
      console.log('this is selected Code ', selectedCode);
      console.log('this is selected id ', selectedID);
      let found = false;
      for (
        let index = 0;
        index < this.permissionsArray.controls.length;
        index++
      ) {
        const element = this.permissionsArray.controls[index];
        console.log('this is element ', element);
        if (element.value.id == selectedID) {
          found = true;
          break;
        }
      }
      if (found == false) {
        this.permissionsArray.push(this.initIdGroup(id));
      }
      //create enquiry and enquiry listing check
      if (selectedCode == 'RB0000123') {
        console.log('we have create enquiry selected ', selectedCode);
        for (
          let index = 0;
          index < this.procurmentPermissionList.length;
          index++
        ) {
          const procrument = this.procurmentPermissionList[index];
          if (procrument.code == 'RB0000132') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == procrument.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              this.permissionsArray.push(this.initIdGroup(procrument.id));
              procrument['isChecked'] = true;
              console.log('this is procrument element ', procrument);
            }
          }
        }

        for (
          let index = 0;
          index < this.dashboardPermissionList.length;
          index++
        ) {
          const enquiry = this.dashboardPermissionList[index];
          if (enquiry.code == 'RB0000138') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == enquiry.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              enquiry['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(enquiry.id));
            }
          }
          if (enquiry.code == 'RB0000140') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == enquiry.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              enquiry['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(enquiry.id));
            }
          }
        }
      }
      //update enquiry and enquiry listing check ang get by id
      else if (selectedCode == 'RB0000124') {
        console.log('we have selectedCode enquiry update ', selectedCode);
        for (
          let index = 0;
          index < this.procurmentPermissionList.length;
          index++
        ) {
          const procrument = this.procurmentPermissionList[index];
          //here i am checking enquiry listing authority
          if (procrument.code == 'RB0000132') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == procrument.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              procrument['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(procrument.id));
            }
          }
          //here i am checking get enquiry by id authority
          if (procrument.code == 'RB0000133') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == procrument.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              procrument['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(procrument.id));
            }
          }
        }

        for (
          let index = 0;
          index < this.dashboardPermissionList.length;
          index++
        ) {
          const enquiry = this.dashboardPermissionList[index];
          if (enquiry.code == 'RB0000138') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == enquiry.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              enquiry['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(enquiry.id));
            }
          }
          if (enquiry.code == 'RB0000140') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == enquiry.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              enquiry['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(enquiry.id));
            }
          }
        }
      }
      //create auction and auction listing check
      else if (selectedCode == 'RB0000125') {
        console.log('we have create auction selected ', selectedCode);
        for (
          let index = 0;
          index < this.auctionPermissionList.length;
          index++
        ) {
          const auction = this.auctionPermissionList[index];
          if (auction.code == 'RB0000134') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == auction.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              auction['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(auction.id));
            }
          }
          if (auction.code == 'RB0000025') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == auction.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              auction['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(auction.id));
            }
          }
          if (auction.code == 'RB0000145') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == auction.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              auction['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(auction.id));
            }
          }
          if (auction.code == 'RB0000024') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == auction.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              auction['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(auction.id));
            }
          }
          if (auction.code == 'RB0000127') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == auction.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              auction['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(auction.id));
            }
          }
          if (auction.code == 'RB0000146') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == auction.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              auction['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(auction.id));
            }
          }
          if (auction.code == 'RB0000230') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == auction.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              auction['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(auction.id));
            }
          }
        }

        for (
          let index = 0;
          index < this.dashboardPermissionList.length;
          index++
        ) {
          const auction = this.dashboardPermissionList[index];
          if (auction.code == 'RB0000139') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == auction.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              auction['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(auction.id));
            }
          }
          if (auction.code == 'RB0000141') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == auction.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              auction['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(auction.id));
            }
          }
        }
      }
      //update auction with auction listing and get by id check
      else if (selectedCode == 'RB0000126') {
        for (
          let index = 0;
          index < this.auctionPermissionList.length;
          index++
        ) {
          const auction = this.auctionPermissionList[index];
          if (auction.code == 'RB0000134') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == auction.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              auction['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(auction.id));
            }
          }
          if (auction.code == 'RB0000127') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == auction.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              auction['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(auction.id));
            }
          }
          if (auction.code == 'RB0000145') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == auction.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              auction['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(auction.id));
            }
          }
        }
        for (
          let index = 0;
          index < this.dashboardPermissionList.length;
          index++
        ) {
          const auction = this.dashboardPermissionList[index];
          if (auction.code == 'RB0000139') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == auction.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              auction['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(auction.id));
            }
          }
          if (auction.code == 'RB0000141') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == auction.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              auction['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(auction.id));
            }
          }
        }
      }
      //create shipment and shipment listing check
      else if (selectedCode == 'RB0000148') {
        for (
          let index = 0;
          index < this.trackAndTracePermissionList.length;
          index++
        ) {
          const trackAndTrace = this.trackAndTracePermissionList[index];
          if (trackAndTrace.code == 'RB0000151') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == trackAndTrace.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              trackAndTrace['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(trackAndTrace.id));
            }
          }
        }
      }
      //update shipment and shipment listing check with get shipment by id
      else if (selectedCode == 'RB0000149') {
        for (
          let index = 0;
          index < this.trackAndTracePermissionList.length;
          index++
        ) {
          const trackAndTrace = this.trackAndTracePermissionList[index];
          if (trackAndTrace.code == 'RB0000151') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == trackAndTrace.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              trackAndTrace['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(trackAndTrace.id));
            }
          }
          if (trackAndTrace.code == 'RB0000150') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == trackAndTrace.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              trackAndTrace['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(trackAndTrace.id));
            }
          }
        }
      }
      //if create invoice then check invoice listing template listing get template by id
      else if (selectedCode == 'RB0000032') {
        for (
          let index = 0;
          index < this.invoicePermissionList.length;
          index++
        ) {
          const invoice = this.invoicePermissionList[index];
          if (invoice.code == 'RB0000034') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == invoice.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              invoice['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(invoice.id));
            }
          }
          if (invoice.code == 'RB0000038') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == invoice.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              invoice['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(invoice.id));
            }
          }
          if (invoice.code == 'RB0000040') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == invoice.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              invoice['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(invoice.id));
            }
          }
        }
      }
      //if update invoice then check invoice listing get invoice by id
      else if (selectedCode == 'RB0000035') {
        for (
          let index = 0;
          index < this.invoicePermissionList.length;
          index++
        ) {
          const invoice = this.invoicePermissionList[index];
          if (invoice.code == 'RB0000033') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == invoice.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              invoice['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(invoice.id));
            }
          }
          if (invoice.code == 'RB0000034') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == invoice.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              invoice['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(invoice.id));
            }
          }
        }
      }
      //if create invoice template then check template listing
      else if (selectedCode == 'RB0000037') {
        for (
          let index = 0;
          index < this.invoicePermissionList.length;
          index++
        ) {
          const invoice = this.invoicePermissionList[index];
          if (invoice.code == 'RB0000038') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == invoice.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              invoice['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(invoice.id));
            }
          }
        }
      }
      //if update invoice template then check template listing and get template by id
      else if (selectedCode == 'RB0000042') {
        for (
          let index = 0;
          index < this.invoicePermissionList.length;
          index++
        ) {
          const invoice = this.invoicePermissionList[index];
          if (invoice.code == 'RB0000038') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == invoice.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              invoice['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(invoice.id));
            }
          }
          if (invoice.code == 'RB0000040') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == invoice.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              invoice['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(invoice.id));
            }
          }
        }
      }

      //if Create user then check user listing
      else if (selectedCode == 'RB0000178') {
        for (
          let index = 0;
          index < this.userManagementPermissionList.length;
          index++
        ) {
          const userManage = this.userManagementPermissionList[index];
          if (userManage.code == 'RB0000184') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == userManage.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              userManage['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(userManage.id));
            }
          }
        }
      }

      //if update user then check user listing and get user by id
      else if (selectedCode == 'RB0000182') {
        for (
          let index = 0;
          index < this.userManagementPermissionList.length;
          index++
        ) {
          const userManage = this.userManagementPermissionList[index];
          if (userManage.code == 'RB0000184') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == userManage.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              userManage['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(userManage.id));
            }
          }
          if (userManage.code == 'RB0000185') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == userManage.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              userManage['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(userManage.id));
            }
          }
        }
      }

      //if Create role then check role listing
      else if (selectedCode == 'RB0000187') {
        for (
          let index = 0;
          index < this.userManagementPermissionList.length;
          index++
        ) {
          const userManage = this.userManagementPermissionList[index];
          if (userManage.code == 'RB0000190') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == userManage.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              userManage['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(userManage.id));
            }
          }
        }
      }

      //if update role then check role listing and get role by id
      else if (selectedCode == 'RB0000189') {
        for (
          let index = 0;
          index < this.userManagementPermissionList.length;
          index++
        ) {
          const userManage = this.userManagementPermissionList[index];
          if (userManage.code == 'RB0000190') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == userManage.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              userManage['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(userManage.id));
            }
          }
          if (userManage.code == 'RB0000194') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == userManage.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              userManage['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(userManage.id));
            }
          }
        }
      }

      // MASTER TABLE SECTION
      // if create mode of shipment selected
      else if (selectedCode == 'RB0000044') {
        for (
          let index = 0;
          index < this.masterRecordPermissionList.length;
          index++
        ) {
          const userManage = this.masterRecordPermissionList[index];
          if (userManage.code == 'RB0000046') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == userManage.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              userManage['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(userManage.id));
            }
          }
        }
      }
      // if update mode of shipment selected
      else if (selectedCode == 'RB0000047') {
        for (
          let index = 0;
          index < this.masterRecordPermissionList.length;
          index++
        ) {
          const userManage = this.masterRecordPermissionList[index];
          if (userManage.code == 'RB0000046') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == userManage.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              userManage['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(userManage.id));
            }
          }
        }
      }

      // if create trade type selected
      else if (selectedCode == 'RB0000048') {
        for (
          let index = 0;
          index < this.masterRecordPermissionList.length;
          index++
        ) {
          const userManage = this.masterRecordPermissionList[index];
          if (userManage.code == 'RB0000050') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == userManage.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              userManage['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(userManage.id));
            }
          }
        }
      }
      // if update trade type selected
      else if (selectedCode == 'RB0000051') {
        for (
          let index = 0;
          index < this.masterRecordPermissionList.length;
          index++
        ) {
          const userManage = this.masterRecordPermissionList[index];
          if (userManage.code == 'RB0000050') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == userManage.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              userManage['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(userManage.id));
            }
          }
        }
      }

      // if create port selected
      else if (selectedCode == 'RB0000052') {
        for (
          let index = 0;
          index < this.masterRecordPermissionList.length;
          index++
        ) {
          const userManage = this.masterRecordPermissionList[index];
          if (userManage.code == 'RB0000054') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == userManage.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              userManage['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(userManage.id));
            }
          }
        }
      }
      // if update port selected
      else if (selectedCode == 'RB0000055') {
        for (
          let index = 0;
          index < this.masterRecordPermissionList.length;
          index++
        ) {
          const userManage = this.masterRecordPermissionList[index];
          if (userManage.code == 'RB0000054') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == userManage.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              userManage['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(userManage.id));
            }
          }
        }
      }

      // if create type of cargo selected
      else if (selectedCode == 'RB0000056') {
        for (
          let index = 0;
          index < this.masterRecordPermissionList.length;
          index++
        ) {
          const userManage = this.masterRecordPermissionList[index];
          if (userManage.code == 'RB0000058') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == userManage.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              userManage['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(userManage.id));
            }
          }
        }
      }
      // if update type of cargo selected
      else if (selectedCode == 'RB0000059') {
        for (
          let index = 0;
          index < this.masterRecordPermissionList.length;
          index++
        ) {
          const userManage = this.masterRecordPermissionList[index];
          if (userManage.code == 'RB0000058') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == userManage.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              userManage['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(userManage.id));
            }
          }
        }
      }

      // if create nature of goods selected
      else if (selectedCode == 'RB0000060') {
        for (
          let index = 0;
          index < this.masterRecordPermissionList.length;
          index++
        ) {
          const userManage = this.masterRecordPermissionList[index];
          if (userManage.code == 'RB0000062') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == userManage.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              userManage['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(userManage.id));
            }
          }
        }
      }
      // if update nature of goods selected
      else if (selectedCode == 'RB0000063') {
        for (
          let index = 0;
          index < this.masterRecordPermissionList.length;
          index++
        ) {
          const userManage = this.masterRecordPermissionList[index];
          if (userManage.code == 'RB0000062') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == userManage.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              userManage['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(userManage.id));
            }
          }
        }
      }

      // if create container type selected
      else if (selectedCode == 'RB0000074') {
        for (
          let index = 0;
          index < this.masterRecordPermissionList.length;
          index++
        ) {
          const userManage = this.masterRecordPermissionList[index];
          if (userManage.code == 'RB0000065') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == userManage.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              userManage['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(userManage.id));
            }
          }
        }
      }
      // if update container type selected
      else if (selectedCode == 'RB0000075') {
        for (
          let index = 0;
          index < this.masterRecordPermissionList.length;
          index++
        ) {
          const userManage = this.masterRecordPermissionList[index];
          if (userManage.code == 'RB0000065') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == userManage.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              userManage['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(userManage.id));
            }
          }
        }
      }

      // if create delivery incoterms selected
      else if (selectedCode == 'RB0000076') {
        for (
          let index = 0;
          index < this.masterRecordPermissionList.length;
          index++
        ) {
          const userManage = this.masterRecordPermissionList[index];
          if (userManage.code == 'RB0000067') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == userManage.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              userManage['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(userManage.id));
            }
          }
        }
      }
      // if update delivery incoterms selected
      else if (selectedCode == 'RB0000077') {
        for (
          let index = 0;
          index < this.masterRecordPermissionList.length;
          index++
        ) {
          const userManage = this.masterRecordPermissionList[index];
          if (userManage.code == 'RB0000067') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == userManage.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              userManage['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(userManage.id));
            }
          }
        }
      }

      // if create nature of movement selected
      else if (selectedCode == 'RB0000078') {
        for (
          let index = 0;
          index < this.masterRecordPermissionList.length;
          index++
        ) {
          const userManage = this.masterRecordPermissionList[index];
          if (userManage.code == 'RB0000069') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == userManage.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              userManage['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(userManage.id));
            }
          }
        }
      }
      // if update nature of movement selected
      else if (selectedCode == 'RB0000077') {
        for (
          let index = 0;
          index < this.masterRecordPermissionList.length;
          index++
        ) {
          const userManage = this.masterRecordPermissionList[index];
          if (userManage.code == 'RB0000069') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == userManage.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              userManage['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(userManage.id));
            }
          }
        }
      }

      // if create pickup incoterms selected
      else if (selectedCode == 'RB0000080') {
        for (
          let index = 0;
          index < this.masterRecordPermissionList.length;
          index++
        ) {
          const userManage = this.masterRecordPermissionList[index];
          if (userManage.code == 'RB0000071') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == userManage.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              userManage['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(userManage.id));
            }
          }
        }
      }
      // if update pickup incoterms selected
      else if (selectedCode == 'RB0000081') {
        for (
          let index = 0;
          index < this.masterRecordPermissionList.length;
          index++
        ) {
          const userManage = this.masterRecordPermissionList[index];
          if (userManage.code == 'RB0000071') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == userManage.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              userManage['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(userManage.id));
            }
          }
        }
      }

      // if create type of packing selected
      else if (selectedCode == 'RB0000082') {
        for (
          let index = 0;
          index < this.masterRecordPermissionList.length;
          index++
        ) {
          const userManage = this.masterRecordPermissionList[index];
          if (userManage.code == 'RB0000073') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == userManage.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              userManage['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(userManage.id));
            }
          }
        }
      }
      // if update type of packing selected
      else if (selectedCode == 'RB0000083') {
        for (
          let index = 0;
          index < this.masterRecordPermissionList.length;
          index++
        ) {
          const userManage = this.masterRecordPermissionList[index];
          if (userManage.code == 'RB0000073') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == userManage.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              userManage['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(userManage.id));
            }
          }
        }
      }

      // if create currency selected
      else if (selectedCode == 'RB0000094') {
        for (
          let index = 0;
          index < this.masterRecordPermissionList.length;
          index++
        ) {
          const userManage = this.masterRecordPermissionList[index];
          if (userManage.code == 'RB0000096') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == userManage.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              userManage['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(userManage.id));
            }
          }
        }
      }
      // if update currency selected
      else if (selectedCode == 'RB0000097') {
        for (
          let index = 0;
          index < this.masterRecordPermissionList.length;
          index++
        ) {
          const userManage = this.masterRecordPermissionList[index];
          if (userManage.code == 'RB0000096') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == userManage.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              userManage['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(userManage.id));
            }
          }
        }
      }

      // if create country selected
      else if (selectedCode == 'RB0000099') {
        for (
          let index = 0;
          index < this.masterRecordPermissionList.length;
          index++
        ) {
          const userManage = this.masterRecordPermissionList[index];
          if (userManage.code == 'RB0000101') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == userManage.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              userManage['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(userManage.id));
            }
          }
        }
      }
      // if update country selected
      else if (selectedCode == 'RB0000102') {
        for (
          let index = 0;
          index < this.masterRecordPermissionList.length;
          index++
        ) {
          const userManage = this.masterRecordPermissionList[index];
          if (userManage.code == 'RB0000101') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == userManage.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              userManage['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(userManage.id));
            }
          }
        }
      }

      // if create country selected
      else if (selectedCode == 'RB0000107') {
        for (
          let index = 0;
          index < this.masterRecordPermissionList.length;
          index++
        ) {
          const userManage = this.masterRecordPermissionList[index];
          if (userManage.code == 'RB0000109') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == userManage.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              userManage['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(userManage.id));
            }
          }
        }
      }
      // if update country selected
      else if (selectedCode == 'RB0000110') {
        for (
          let index = 0;
          index < this.masterRecordPermissionList.length;
          index++
        ) {
          const userManage = this.masterRecordPermissionList[index];
          if (userManage.code == 'RB0000109') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == userManage.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              userManage['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(userManage.id));
            }
          }
        }
      }

      // if create country selected
      else if (selectedCode == 'RB0000112') {
        for (
          let index = 0;
          index < this.masterRecordPermissionList.length;
          index++
        ) {
          const userManage = this.masterRecordPermissionList[index];
          if (userManage.code == 'RB0000115') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == userManage.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              userManage['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(userManage.id));
            }
          }
        }
      }
      // if update country selected
      else if (selectedCode == 'RB0000113') {
        for (
          let index = 0;
          index < this.masterRecordPermissionList.length;
          index++
        ) {
          const userManage = this.masterRecordPermissionList[index];
          if (userManage.code == 'RB0000115') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == userManage.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              userManage['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(userManage.id));
            }
          }
        }
      }

      // if create country selected
      else if (selectedCode == 'RB0000117') {
        for (
          let index = 0;
          index < this.masterRecordPermissionList.length;
          index++
        ) {
          const userManage = this.masterRecordPermissionList[index];
          if (userManage.code == 'RB0000120') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == userManage.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              userManage['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(userManage.id));
            }
          }
        }
      }
      // if update country selected
      else if (selectedCode == 'RB0000118') {
        for (
          let index = 0;
          index < this.masterRecordPermissionList.length;
          index++
        ) {
          const userManage = this.masterRecordPermissionList[index];
          if (userManage.code == 'RB0000120') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == userManage.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              userManage['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(userManage.id));
            }
          }
        }
      }

      // if Create service order selected
      else if (selectedCode == 'RB0000128') {
        for (
          let index = 0;
          index < this.serviceOrderPermissionList.length;
          index++
        ) {
          const serviceOrder = this.serviceOrderPermissionList[index];
          if (serviceOrder.code == 'RB0000130') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == serviceOrder.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              serviceOrder['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(serviceOrder.id));
            }
          }
        }

        for (
          let index = 0;
          index < this.auctionPermissionList.length;
          index++
        ) {
          const serviceOrder = this.auctionPermissionList[index];
          if (serviceOrder.code == 'RB0000134') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == serviceOrder.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              serviceOrder['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(serviceOrder.id));
            }
          }
          if (serviceOrder.code == 'RB0000127') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == serviceOrder.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              serviceOrder['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(serviceOrder.id));
            }
          }
          if (serviceOrder.code == 'RB0000025') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == serviceOrder.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              serviceOrder['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(serviceOrder.id));
            }
          }
        }

        for (
          let index = 0;
          index < this.procurmentPermissionList.length;
          index++
        ) {
          const serviceOrder = this.procurmentPermissionList[index];
          if (serviceOrder.code == 'RB0000132') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == serviceOrder.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              serviceOrder['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(serviceOrder.id));
            }
          }
          if (serviceOrder.code == 'RB0000156') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == serviceOrder.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              serviceOrder['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(serviceOrder.id));
            }
          }
          if (serviceOrder.code == 'RB0000157') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == serviceOrder.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              serviceOrder['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(serviceOrder.id));
            }
          }
        }
      }

      // if update service order selected
      else if (selectedCode == 'RB0000129') {
        for (
          let index = 0;
          index < this.serviceOrderPermissionList.length;
          index++
        ) {
          const serviceOrder = this.serviceOrderPermissionList[index];
          if (serviceOrder.code == 'RB0000130') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == serviceOrder.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              serviceOrder['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(serviceOrder.id));
            }
          }
          if (serviceOrder.code == 'RB0000131') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == serviceOrder.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              serviceOrder['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(serviceOrder.id));
            }
          }
        }
      }

      // if Create auction bid selected
      else if (selectedCode == 'RB0000023') {
        for (
          let index = 0;
          index < this.auctionPermissionList.length;
          index++
        ) {
          const serviceOrder = this.auctionPermissionList[index];
          if (serviceOrder.code == 'RB0000024') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == serviceOrder.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              serviceOrder['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(serviceOrder.id));
            }
          }
          if (serviceOrder.code == 'RB0000025') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == serviceOrder.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              serviceOrder['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(serviceOrder.id));
            }
          }
          if (serviceOrder.code == 'RB0000127') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == serviceOrder.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              serviceOrder['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(serviceOrder.id));
            }
          }
          if (serviceOrder.code == 'RB0000134') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == serviceOrder.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              serviceOrder['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(serviceOrder.id));
            }
          }
        }
      }

      // if create vendor selected bid selected
      else if (selectedCode == 'RB0000012') {
        for (let index = 0; index < this.adminPermissionList.length; index++) {
          const admin = this.adminPermissionList[index];
          if (admin.code == 'RB0000015') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == admin.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              admin['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(admin.id));
            }
          }
        }
      }

      //if vendor quote selected
      else if (selectedCode == 'RB0000155') {
        for (
          let index = 0;
          index < this.procurmentPermissionList.length;
          index++
        ) {
          const admin = this.procurmentPermissionList[index];
          if (admin.code == 'RB0000132') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == admin.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              admin['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(admin.id));
            }
          }
          if (admin.code == 'RB0000133') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == admin.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              admin['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(admin.id));
            }
          }
          if (admin.code == 'RB0000157') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == admin.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              admin['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(admin.id));
            }
          }
        }
      }

      //if vendor quote update selected
      else if (selectedCode == 'RB0000161') {
        for (
          let index = 0;
          index < this.procurmentPermissionList.length;
          index++
        ) {
          const admin = this.procurmentPermissionList[index];
          if (admin.code == 'RB0000133') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == admin.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              admin['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(admin.id));
            }
          }
          if (admin.code == 'RB0000132') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == admin.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              admin['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(admin.id));
            }
          }
          if (admin.code == 'RB0000157') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == admin.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              admin['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(admin.id));
            }
          }
        }
      }

      //if save carrier selected
      else if (selectedCode == 'RB0000242') {
        for (
          let index = 0;
          index < this.masterRecordPermissionList.length;
          index++
        ) {
          const admin = this.masterRecordPermissionList[index];
          if (admin.code == 'RB0000247') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == admin.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              admin['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(admin.id));
            }
          }
        }
      }

      //if update carrier selected
      else if (selectedCode == 'RB0000243') {
        for (
          let index = 0;
          index < this.masterRecordPermissionList.length;
          index++
        ) {
          const admin = this.masterRecordPermissionList[index];
          if (admin.code == 'RB0000247') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == admin.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              admin['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(admin.id));
            }
          }
        }
      }

      //if save shipping line selected
      else if (selectedCode == 'RB0000233') {
        for (
          let index = 0;
          index < this.masterRecordPermissionList.length;
          index++
        ) {
          const admin = this.masterRecordPermissionList[index];
          if (admin.code == 'RB0000245') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == admin.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              admin['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(admin.id));
            }
          }
        }
      }

      //if update shipping line selected
      else if (selectedCode == 'RB0000235') {
        for (
          let index = 0;
          index < this.masterRecordPermissionList.length;
          index++
        ) {
          const admin = this.masterRecordPermissionList[index];
          if (admin.code == 'RB0000245') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == admin.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              admin['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(admin.id));
            }
          }
        }
      }

      //if save hs code selected
      else if (selectedCode == 'RB0000239') {
        for (
          let index = 0;
          index < this.masterRecordPermissionList.length;
          index++
        ) {
          const admin = this.masterRecordPermissionList[index];
          if (admin.code == 'RB0000248') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == admin.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              admin['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(admin.id));
            }
          }
        }
      }

      //if update hs code selected
      else if (selectedCode == 'RB0000240') {
        for (
          let index = 0;
          index < this.masterRecordPermissionList.length;
          index++
        ) {
          const admin = this.masterRecordPermissionList[index];
          if (admin.code == 'RB0000248') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == admin.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              admin['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(admin.id));
            }
          }
        }
      }

      //if save trade type aggrement selected
      else if (selectedCode == 'RB0000236') {
        for (
          let index = 0;
          index < this.masterRecordPermissionList.length;
          index++
        ) {
          const admin = this.masterRecordPermissionList[index];
          if (admin.code == 'RB0000246') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == admin.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              admin['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(admin.id));
            }
          }
        }
      }

      //if update trade type aggrement selected
      else if (selectedCode == 'RB0000237') {
        for (
          let index = 0;
          index < this.masterRecordPermissionList.length;
          index++
        ) {
          const admin = this.masterRecordPermissionList[index];
          if (admin.code == 'RB0000246') {
            let found = false;
            for (
              let index = 0;
              index < this.permissionsArray.controls.length;
              index++
            ) {
              const element = this.permissionsArray.controls[index];
              console.log('this is element ', element);
              if (element.value.id == admin.id) {
                found = true;
                break;
              }
            }
            if (found == false) {
              admin['isChecked'] = true;
              this.permissionsArray.push(this.initIdGroup(admin.id));
            }
          }
        }
      }
    }
    //else if checkbox unchecked
    else if (!event.target.checked) {
      console.log('this is unselected Code ', selectedCode);
      console.log('this is unselected id ', selectedID);
      this.permissionsArray.controls.forEach((item: any) => {
        if (item.value.id === id) {
          if (event.target.value == 'RB0000123') {
            console.log('we have create enquiry selected ', selectedCode);
            for (
              let index = 0;
              index < this.procurmentPermissionList.length;
              index++
            ) {
              const enquiry = this.procurmentPermissionList[index];
              if (enquiry.code == 'RB0000123') {
                enquiry['isChecked'] = false;
                let createEnquiry = this.permissionsArray.controls.findIndex(
                  (res) => res.value.id == enquiry.id
                );
                this.permissionsArray.removeAt(createEnquiry);
              }
              // if (enquiry.code == 'RB0000132') {
              //   enquiry['isChecked'] = false;
              //   let createEnquiry = this.permissionsArray.controls.findIndex(
              //     (res) => res.value.id == enquiry.id
              //   );
              //   this.permissionsArray.removeAt(createEnquiry);
              // }
            }
          } else if (event.target.value == 'RB0000125') {
            for (
              let index = 0;
              index < this.auctionPermissionList.length;
              index++
            ) {
              const enquiry = this.auctionPermissionList[index];
              if (enquiry.code == 'RB0000125') {
                enquiry['isChecked'] = false;
                let createEnquiry = this.permissionsArray.controls.findIndex(
                  (res) => res.value.id == enquiry.id
                );
                this.permissionsArray.removeAt(createEnquiry);
              }
              // if (enquiry.code == 'RB0000134') {
              //   enquiry['isChecked'] = false;
              //   let createEnquiry = this.permissionsArray.controls.findIndex(
              //     (res) => res.value.id == enquiry.id
              //   );
              //   this.permissionsArray.removeAt(createEnquiry);
              // }
            }
          } else {
            let idToRemove = this.permissionsArray.controls.findIndex(
              (res) => res.value.id == id
            );
            this.permissionsArray.removeAt(idToRemove);
          }
        }
      });
    }
  }

  initIdGroup(id: any) {
    return this.fb.group({
      id: new FormControl(id),
    });
  }

  createRole() {
    if (this.roleForm.valid && this.permissionsArray.length > 0) {
      this.userService.addRole(this.roleForm.value).subscribe({
        next: (res) => {
          console.log('this is res ', res);
          Swal.fire({
            title: 'Success',
            text: res?.message,
            timer: 5000,
            icon: 'success',
          });
          this.router.navigate(['v1/user-roles/listing']);
        },
        error: (error) => {
          console.log('this is error ', error);
        },
      });
    } else {
      this.roleForm.markAllAsTouched();
      if (this.roleformcontroller.authority.value == '') {
        // this.gotoTop();
        Swal.fire({
          title: 'Info',
          text: 'Please enter role name',
          timer: 5000,
          icon: 'info',
        });
      } else if (this.permissionsArray.length == 0) {
        Swal.fire({
          title: 'Info',
          text: 'Please select atleast one permission',
          timer: 5000,
          icon: 'info',
        });
      }
    }
    // http://localhost:9001/api/v1/role
  }

  updateRole() {
    if (this.roleForm.valid && this.permissionsArray.length > 0) {
      this.userService.updateRole(this.roleId, this.roleForm.value).subscribe({
        next: (res) => {
          console.log('this is res ', res);
          Swal.fire({
            title: 'Success',
            text: res?.message,
            timer: 5000,
            icon: 'success',
          });
          this.router.navigate(['v1/user-roles/listing']);
        },
        error: (error) => {
          console.log('this is error ', error);
        },
      });
    } else {
      this.roleForm.markAllAsTouched();
      if (this.roleformcontroller.authority.value == '') {
        // this.gotoTop();
        Swal.fire({
          title: 'Info',
          text: 'Please enter role name',
          timer: 5000,
          icon: 'info',
        });
      } else if (this.permissionsArray.length == 0) {
        Swal.fire({
          title: 'Info',
          text: 'Please select atleast one permission',
          timer: 5000,
          icon: 'info',
        });
      }
    }
    // http://localhost:9001/api/v1/role/1
  }

  getRoleById(id: any) {
    // this.getAllPermissionsFromDB();

    this.userService.getrole(id).subscribe({
      next: (res) => {
        console.log('this is res get role by id ', res);
        if (res != null) {
          this.roleformcontroller.authority.patchValue(res.authority);
          for (let index = 0; index < res.permissions.length; index++) {
            const permission = res.permissions[index];
            for (
              let index = 0;
              index < this.allPermissionsList.length;
              index++
            ) {
              const allPermission = this.allPermissionsList[index];
              if (permission.code == allPermission.code) {
                allPermission['isChecked'] = true;
                this.permissionsArray.push(this.initIdGroup(permission.id));
              }
            }
          }
        }
      },
      error: (error) => {
        console.log('this is error get role by id ', error);
      },
    });
  }

  //fuction to scroll to top on next button click
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
}
