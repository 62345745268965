<div class="container">
    <h4>Edit Vendor Bid</h4>
    <div class="row" [formGroup]="editBidForm">
        <div class="col-lg-12 form-group mt-2">
            <label class="form-label" for="bidValue">Bid Value*</label>
            <input class="form-control" id="bidValue" type="number" formControlName="auctionValue"
            [ngClass]="{'is-invalid': bidForm.auctionValue.invalid && ( bidForm.auctionValue.dirty || bidForm.auctionValue.touched )}">
            <div class="text-danger validation-message" *ngIf="bidForm.auctionValue.invalid && ( bidForm.auctionValue.dirty || bidForm.auctionValue.touched )">
                Bid Value is mandatory
            </div>
        </div>
        <div class="col-lg-12 form-group mt-2">
            <label class="form-label" for="reason">Reason*</label>
            <input class="form-control" id="reason" type="text" formControlName="reason"
            [ngClass]="{'is-invalid': bidForm.reason.invalid && ( bidForm.reason.dirty || bidForm.reason.touched )}">
            <div class="text-danger validation-message" *ngIf="bidForm.reason.invalid && ( bidForm.reason.dirty || bidForm.reason.touched )">
                Reason is mandatory
            </div>
        </div>
        <div class="col-lg-12 mt-3">
            <button class="btn-primary m-1" (click)="doAction()">Submit</button>
            <button class="btn-danger m-1" (click)="closeDialog()" >Cancel</button>
        </div>
    </div>
</div>