import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dialog-box',
  templateUrl: './nature-dialog-box.component.html',
  styleUrls: ['./nature-dialog-box.component.css']
})
export class NatureDialogBoxComponent implements OnInit {

  dialogform: FormGroup;
  local_data: any;
  action: any;
  isReadOnly = false;
  isrequired = true;

  constructor(

    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<NatureDialogBoxComponent>,

    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.action = data.action;
    this.local_data = { ...data };
    if (this.action == 'Update') { this.isReadOnly = true; this.isrequired = false }
  }
  ngOnInit(): void {
    this.dialogform = this.formBuilder.group({
      code: [""],
      value: [""]
    });
    if (this.action != "Add") {
      this.dialogform.patchValue({
        code: this.local_data.code,
        value: this.local_data.value,
      });
    }
  }
  doAction() {
    if (this.dialogform.valid) {
      this.dialogRef.close({ event: this.action, data: this.dialogform.value });
    } else {
      Swal.fire({
        title: "warning",
        icon: 'warning',
        titleText: "Please Provide mandatory Field",
        timer: 3000
      })
    }
  }
  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }
}
