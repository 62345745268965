import { Injectable, NgZone } from '@angular/core';
import { Observable, Observer, Subject } from 'rxjs';
import { EventSourcePolyfill } from 'ng-event-source';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { DashboardComponent } from '../dashboard/dashboard.component';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  observer: Observer<any>;
  ws = null;
  connected = false;
  serverUrl = `https://${window.location.hostname}:9004/api/v1/sse/subscribe/dashboard`;
  // token = JSON.parse(sessionStorage.getItem('token'));
  // userData = JSON.parse(sessionStorage.getItem('userData'))
  data: any;
  eventSource: EventSourcePolyfill

  dashboardComponent: DashboardComponent;
  constructor(
    private zone: NgZone,
    private http: HttpClient,
    private route: Router
  ) {}

  connectToServer(): Subject<any> {
    const token = JSON.parse(sessionStorage.getItem('token'));
    const userData = JSON.parse(sessionStorage.getItem('userData'));
    console.log('BEFORE API CALL');
    let subscription = new Subject();
      if (token != null && userData != null) {
         this.eventSource = new EventSourcePolyfill(this.serverUrl, {
          headers: {
            Authorization: 'Bearer ' + token,
            sid: userData.sId,
          },
        });
        this.eventSource.onmessage = (event) => {
          subscription.next(event);
          // this.zone.run(() => {
          //   subscription.next(event);
          // });
        };
        console.log('AFTER API CALL');
        this.eventSource.onopen = (a: any) => {};
        this.eventSource.onerror = (e: any) => {};
        return subscription;
    }
  }

  stopSSEService() {
    this.eventSource.close()
  }

  connectTOServerPopUpFuction() {
    let token = JSON.parse(sessionStorage.getItem('token'));
    let userData = JSON.parse(sessionStorage.getItem('userData'));
    let sid = userData['sId'];
    let dType = userData['dType'];
    console.log(sid);

    console.log('BEFORE API CALL');
    if ((token != null && sid != null) || undefined) {
      this.connectToServer().subscribe(
        (response) => {
          console.log(response);
          let data = JSON.parse(response.data);
          console.log(data);
          let auctionData = JSON.stringify(data.eventEntity);

          let finalActionType;
          if (auctionData != null) {
            let firstSplit = auctionData.split('type=');
            let secondSplit = firstSplit[1];
            finalActionType = secondSplit[0];
            console.log('This is final auction type ', finalActionType);
          }

          // here creating 2 seperate routing links for client and vendor
          let clientRouteLink = '/v1/client-auction/listing/' + finalActionType;
          let vendorRouteLink = '/v1/vendor-auction/listing/' + finalActionType;

          if (
            response.type == 'message' &&
            data.eventType == 'AUCTION_ABOUT_TO_START'
          ) {
            if (dType == 2) {
              Swal.fire({
                icon: 'info',
                timer: 1000000,
                title: 'Auction',
                html:
                  data.eventMessage +
                  `<br> <a href="${clientRouteLink}">Go To Auction</a>`,
              });
            } else if (dType == 3) {
              Swal.fire({
                icon: 'info',
                timer: 100000,
                title: 'Auction',
                html:
                  data.eventMessage +
                  `<br> <a href="${vendorRouteLink}">Go To Auction</a>'`,
              });
            }
          }

          if (data.eventType == 'AUCTION_STARTED') {
            if (dType == 2) {
              Swal.fire({
                icon: 'info',
                timer: 5000,
                title: 'Auction',
                html:
                  data.eventMessage +
                  `<br> <a href="${clientRouteLink}">Go To Auction</a>`,
              }).then((response) => {
                console.log('this is response ', response);
                if (
                  this.route.url == '/v1/dashboard' ||
                  this.route.url == '/v1/client-auction/listing/r'
                ) {
                  if (response.value) {
                    window.location.reload();
                    // this.reloadCurrentRoute()
                  } else if (response.isDenied) {
                    window.location.reload();
                    // this.reloadCurrentRoute()
                  } else if (response.isConfirmed) {
                    window.location.reload();
                    // this.reloadCurrentRoute()
                  } else if (response.isDismissed) {
                    window.location.reload();
                    // this.reloadCurrentRoute()
                  }
                }
              });
            }

            if (dType == 3) {
              Swal.fire({
                icon: 'info',
                timer: 5000,
                title: 'Auction',
                html:
                  data.eventMessage +
                  `<a href="${vendorRouteLink}">Go To Auction</a>'`,
              }).then((response) => {
                if (
                  this.route.url == '/v1/dashboard' ||
                  this.route.url == '/v1/client-auction/listing/r'
                ) {
                  if (response.value) {
                    window.location.reload();
                    // this.reloadCurrentRoute()
                  } else if (response.isDenied) {
                    window.location.reload();
                    // this.reloadCurrentRoute()
                  } else if (response.isConfirmed) {
                    window.location.reload();
                    // this.reloadCurrentRoute()
                  } else if (response.isDismissed) {
                    window.location.reload();
                    // this.reloadCurrentRoute()
                  }
                }
              });
            }
            // Need to test. ActivatedRoute to be implemented.
            // if(this.){}
            // this.dashboard.reloadFunction();
            // Auction listing. client & vendor.
          }

          // if(data.eventType == "AUCTION_TIMER_INCREASE"){
          //   if (dType == 2) {
          //     Swal.fire({
          //       icon: "info",
          //       timer: 1000000,
          //       title: "Auction",
          //       html: data.eventMessage
          //     })
          //   }

          //   else if (dType == 3) {
          //     Swal.fire({
          //       icon: "info",
          //       timer: 100000,
          //       title: "Auction",
          //       html: data.eventMessage
          //     })
          //   }
          // }

          // if(data.eventType == "AUCTION_COMPLETED"){
          //   if (dType == 2) {
          //     Swal.fire({
          //       icon: "success",
          //       timer: 100000,
          //       title: "Auction",
          //       html: data.eventMessage
          //     })
          //     this.router.navigate([vendorRouteLink])
          //   }
          //    if (dType == 3) {
          //     Swal.fire({
          //       icon: "success",
          //       timer: 100000,
          //       title: "Auction",
          //       html: data.eventMessage
          //     })
          //     this.router.navigate([vendorRouteLink])
          //   }
          // }
        },
        (error) => {
          console.log(error);
          if (error.status == 403) {
            console.log('user token expired need to login ');
          }
        }
      );
    }
    console.log('AFTER API CALL');
  }

  reloadCurrentRoute() {
    let currentUrl = this.route.url;
    this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.route.navigate([currentUrl]);
    });
  }
}
