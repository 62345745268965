import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { SocketService } from '../helpers/socket.service';

const data = [
  { "auctionEventNumber": "Auc", "description": "dfasdfasd", "creatorName": "hasdhf", "creationDate": "20/2/2022", "forwarderName": "XYZ", "value": "25000", "currency": "USD", "status": "Approved" },
  { "auctionEventNumber": "Auc", "description": "dfasdfasd", "creatorName": "hasdhf", "creationDate": "20/2/2022", "forwarderName": "XYZ", "value": "25000", "currency": "USD", "status": "Approved" },
  { "auctionEventNumber": "Auc", "description": "dfasdfasd", "creatorName": "hasdhf", "creationDate": "20/2/2022", "forwarderName": "XYZ", "value": "25000", "currency": "USD", "status": "Approved" },
  { "auctionEventNumber": "Auc", "description": "dfasdfasd", "creatorName": "hasdhf", "creationDate": "20/2/2022", "forwarderName": "XYZ", "value": "25000", "currency": "USD", "status": "Approved" },
  { "auctionEventNumber": "Auc", "description": "dfasdfasd", "creatorName": "hasdhf", "creationDate": "20/2/2022", "forwarderName": "XYZ", "value": "25000", "currency": "USD", "status": "Approved" },
  { "auctionEventNumber": "Auc", "description": "dfasdfasd", "creatorName": "hasdhf", "creationDate": "20/2/2022", "forwarderName": "XYZ", "value": "25000", "currency": "USD", "status": "Approved" },
  
];

@Component({
  selector: 'app-review-auction',
  templateUrl: './review-auction.component.html',
  styleUrls: ['./review-auction.component.css']
})
export class ReviewAuctionComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator:MatPaginator;

  dataSource!: MatTableDataSource<any>;
  displaycolumns: string[] = ["auctionEventNumber", "description", "creatorName", "creationDate", "forwarderName", "valueCurrency", "status", "action"];

  constructor(private socketService: SocketService) { }

  ngOnInit(): void {

    this.dataSource = new MatTableDataSource<any>(data);
    this.dataSource.sort=this.sort;

    // this.socketService.connectTOServerPopUpFuction();
  }
  filter(filterValue)
  {
    this.dataSource.filter=filterValue.trim().toLowerCase();
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }
}
