<section class="wrapper multi-step-form">
    <div class="container-fluid">
        <div class="row">
            <div
                class="col-md-4 col-xl-3 col-left pt-12 d-flex flex-column justify-content-center position-sticky top-0">
                <div class="box-absolute d-flex">
                    <app-sidebar></app-sidebar>
                </div>
                <div class="box mx-auto">
                    <h3 class="text-center text-md-start">User</h3>
                    <ul class="list-unstyled step-one">
                        <li class=" " id="list-item-1">
                            <div class="icon"><i class="fa fa-check"></i></div><span>Details & Role</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-8 col-xl-9 col-right px-start-end pb-6">
                <div class="topline pt-4 pt-lg-5 pb-3 pb-lg-4 d-flex align-items-center justify-content-between">
                    <ul *ngIf="breadcrumbBool" class="breadcrumb p-0 m-0">
                        <li class="breadcrumb-item 3 brand" id="0">
                            <button [routerLink]="['/v1/dashboard']">Dashboard</button>
                        </li>
                    </ul>
                </div>
                <form [formGroup]="userForm" autocomplete="off"
                autocorrect="off"
                autocapitalize="none"
                spellcheck="false">
                    <div class="tab-content form-step">
                        <div class="tab-pane" id="item-3">
                            <div class="row justify-content-center">
                                <div class="col-md-7 form-group">
                                    <label class="form-label" for="fname">First Name</label>
                                    <input class="form-control" type="text" formControlName="fname"
                                        placeholder="Enter First Name" id="fname"/>
                                </div>

                                <div class="col-md-7 form-group">
                                    <label class="form-label" for="lname">Last Name</label>
                                    <input class="form-control" type="text" formControlName="lname"
                                        placeholder="Enter First Name" id="lname"/>
                                </div>

                                <div class="col-md-7 form-group">
                                    <label class="form-label" for="contactNumber">Contact Number</label>
                                    <input class="form-control" type="number" formControlName="contactNumber"
                                        placeholder="contact number" id="contactNumber" min="0"/>
                                </div>

                                <div class="col-md-7 form-group">
                                    <label class="form-label" for="email-id">Email Id *</label>
                                    <input class="form-control" type="email" formControlName="emailId"
                                        placeholder="someone@gmail.com" id="email-id"
                                        [ngClass]="{'is-invalid': f.emailId.invalid && f.emailId.errors && ( f.emailId.dirty || f.emailId.touched) }" />
                                    <div class="text-danger validation-message"
                                        *ngIf="f.emailId.invalid && ( f.emailId.dirty || f.emailId.touched)">
                                        Email Id is required
                                    </div>
                                </div>

                                <div class="col-md-7 form-group" *ngIf="!updateUserBool">
                                    <label class="form-label" for="password">Password *</label>
                                    <div class="input-group">
                                        <input class="form-control" [type]="password ? 'text' : 'password'" placeholder=""
                                            formControlName="password" required="required" id="password" (change)="editPassword()"
                                            [ngClass]="{'is-invalid': f.password.invalid && f.password.errors && ( f.password.dirty || f.password.touched) }" />
                                        <button [ngClass]="{'pass-view': f.password.errors,'pass-error':!f.password.errors}"
                                            (click)="showPassword()"
                                            class="input-group-btn text-primary btn-password-toggle" type="button">
                                            <i class="fa fa-eye"
                                                [ngClass]="{'fa-eye-slash': !password, 'fa-eye': password}"></i>
                                        </button>
                                    </div>
                                    <div class="text-danger validation-message"
                                        *ngIf="f.password.invalid && ( f.password.dirty || f.password.touched)">
                                        <div [hidden]="!f.password.errors.required">
                                            Password is required
                                        </div>
                                        <div [hidden]="!f.password.errors.minlength">Password must be at least 8
                                            characters</div>
                                        <div *ngIf="f.password.hasError('passwordStrength')">
                                            {{ f.password.errors['passwordStrength'] }}
                                        </div>
                                    </div>
                                </div>


                                <div *ngIf="!updateUserBool" class="col-md-7 form-group">
                                    <label class="form-label" for="confirm_password">Confirm Password *</label>
                                    <div class="input-group">
                                        <input class="form-control" [type]="confirm_password ? 'text' : 'password'" placeholder=""
                                            formControlName="confirm_password" required="required" id="confirm_password"
                                            [ngClass]="{'is-invalid': f.confirm_password.invalid && f.confirm_password.errors && ( f.confirm_password.dirty || f.confirm_password.touched) }" />
                                        <button [ngClass]="{'pass-view': f.confirm_password.errors,'pass-error':!f.confirm_password.errors}"
                                            (click)="showHideConfirmPassword()"
                                            class="input-group-btn text-primary btn-password-toggle" type="button">
                                            <i class="fa fa-eye"
                                                [ngClass]="{'fa-eye-slash': !confirm_password, 'fa-eye': confirm_password}"></i>
                                        </button>
                                    </div>
                                    <div class="text-danger validation-message"
                                        *ngIf="f.confirm_password.invalid && ( f.confirm_password.dirty || f.confirm_password.touched)">
                                        <div [hidden]="!f.confirm_password.errors.required">
                                            Confirm Password is required
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="updateUserBool" class="col-md-7 form-group">
                                    <label class="form-label" for="unsuccessfulAttemp">Unsuccessful Attemps</label>
                                    <input class="form-control" type="number" id="unsuccessfulAttemp" formControlName="unsuccessfulAttemps" min="0"> 
                                </div>

                                <div *ngIf="updateUserBool" class="col-md-7 form-group">
                                    <label for="form-label" for="isLocked">Locked</label>
                                    <mat-slide-toggle color="primary" formControlName="isLocked">
                                    </mat-slide-toggle>
                                </div>

                                <div class="col-md-7 form-group">
                                    <label class="form-label" for="roles">Roles</label>
                                    <ng-multiselect-dropdown
                                        [settings]="dropdownSettings"
                                        [data]="rolesList"
                                        [(ngModel)]="selectedRoles"
                                        [ngModelOptions]="{standalone: true}"
                                        (onSelect)="onItemSelect($event)"
                                        (onDeSelect)="onDeSelect($event)">
                                    </ng-multiselect-dropdown>
                                </div>

                                <div class="col-md-7 form-group pt-4 pt-lg-5">
                                    <button *ngIf="!updateUserBool" [disabled]="!userForm.valid" class="btn btn-success w-100" type="submit"
                                        (click)="addUser()">SUBMIT</button>

                                    <button *ngIf="updateUserBool" [disabled]="!userForm.valid" class="btn btn-success w-100" type="submit"
                                        (click)="updateUser()">UPDATE</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>