import { getLocaleFirstDayOfWeek } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MasterTableService } from 'src/app/helpers/master-table.service';
import { QuoteService } from 'src/app/helpers/quote.service';
import { SocketService } from 'src/app/helpers/socket.service';

@Component({
  selector: 'app-vendor-detail-quote-comparison',
  templateUrl: './vendor-detail-quote-comparison.component.html',
  styleUrls: ['./vendor-detail-quote-comparison.component.css'],
})
export class VendorDetailQuoteComparisonComponent implements OnInit {
  vendorCurrentQuote: Array<any> = [];
  vendorQuoteHistoryArray: Array<any> = [];
  maxAdditionalCharge: number = 0;
  maxAdditionalChargeArray = [];
  maxContainer: number = 0;
  maxContainerArray = [];
  maxShipment: number = 0;
  maxShipmentArray = [];
  maxBreakbulk: number = 0;
  maxBreakbulkArray = [];
  maxGrandTotal: number = 0;
  maxGrandTotalArray = [];

  shipmentPackages: Boolean = false;
  containerDetails: Boolean = false;
  additionalCharges: Boolean = false;
  breakBulkdetails: Boolean = false;
  grandTotal: Boolean = false;
  chargableWeight: number = 0.0;
  seaBool: Boolean = false;
  airBool: Boolean = false;
  Math = Math;
  inputColumns: Array<any> = [];
  ValueColumns: Array<any> = [];
  outPut: Array<any> = [this.inputColumns, this.ValueColumns];

  containerTypes: Array<any> = [];

  constructor(
    private route: ActivatedRoute,
    private quateService: QuoteService,
    private socketService: SocketService,
    private masterTableService: MasterTableService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      let id = params.get('id');
      this.getdetails(id);
    });

    this.containerTypeList();
  }

  getdetails(id) {
    this.quateService.getAllQuoteHistory(id).subscribe(
      (response: Array<any>) => {
        console.log('this is response ', response);

        this.chargableWeight = response[0].enquiry.chargableWeight;
        let modeOfShipment = response[0].enquiry.modeOfShipment;
        if (modeOfShipment == 1) {
          this.airBool = true;
          this.inputColumns.push(
            'Quote ID',
            'Air Line',
            'Frequency',
            'Transit Time',
            'Transit Port',
            'Routing',
            'ETD',
            'ETA',
            'Offer Validity',
            'Amount'
          );
        } else if (modeOfShipment == 2) {
          this.seaBool = true;
          this.inputColumns.push(
            'Quote ID',
            'Shipping Line',
            'Frequency',
            'Transit Time',
            'Transit Port',
            'Vessel Name',
            'Vessel Schedule',
            'Sailing Days Of Week',
            'Loading Terminal',
            'Routing',
            'ETD',
            'ETA',
            'Offer Validity',
            'Amount'
          );
        } else if (modeOfShipment == 3) {
        } else if (modeOfShipment == 4) {
        }
        this.vendorCurrentQuote = response[0].vendorQuote.vendorQuoteRows;
        // console.log(this.vendorCurrentQuote);
        let lineName = [];
        let frequency = [];
        let transitTime = [];
        let transitPort = [];
        let vesselName = [];
        let quoteId = [];
        let vesselSchedule = [];
        let sailingDays = [];
        let loadingTerminal = [];
        let routing = [];
        let etd = [];
        let eta = [];
        let quoteValidTill = [];
        let amount = [];

        // populate current quote first and then history record.
        for (let index = 0; index < this.vendorCurrentQuote.length; index++) {
          const element = this.vendorCurrentQuote[index];
          console.log('this is vendor quote current ', element);
          quoteId.push(element.id);
          lineName.push(element.lineName);
          frequency.push(element.frequency);
          transitTime.push(element.transitTime);
          transitPort.push(element.transitPort);
          amount.push(element.amount);
          if (this.seaBool) {
            vesselName.push(element.vesselName);
            vesselSchedule.push(element.vesselSchedule);
            sailingDays.push(element.sailingDays);
            loadingTerminal.push(element.loadingTerminal);
          }
          routing.push(element.routing);
          etd.push(element.etd);
          eta.push(element.eta);
          quoteValidTill.push(element.quoteValidTill);
          let addChargeArray: Array<any> = element.additionalCharges;
          console.log('this is addcharge array ', addChargeArray);

          if (addChargeArray.length > this.maxAdditionalCharge) {
            this.additionalCharges = true;
            this.maxAdditionalCharge = addChargeArray.length;
          }

          let containerArray = element.vendorQuoteContainers;
          if (containerArray.length > this.maxContainer) {
            this.containerDetails = true;
            this.maxContainer = containerArray.length;
          }

          let shipmentArray = element.vendorQuoteShipmentPackages;
          if (shipmentArray.length > this.maxShipment) {
            this.shipmentPackages = true;
            this.maxShipment = shipmentArray.length;
          }

          let BreakBulkArray = element.vendorQuoteBreakBulks;
          if (BreakBulkArray.length > this.maxBreakbulk) {
            this.breakBulkdetails = true;
            this.maxBreakbulk = BreakBulkArray.length;
          }

          if(element.vendorQuoteContainers.length > 0){
            for (let index = 0; index < element.vendorQuoteContainers.length; index++) {
              const container = element.vendorQuoteContainers[index];
              console.log('this is history container ', container.container.id);
              for (let index = 0; index < this.containerTypes.length; index++) {
                const containerType = this.containerTypes[index];
                if(container.container.type == containerType.id){
                  console.log('this is container type ', containerType);
                  container.container['containerType'] = containerType.value
                }
              }
            }
          }

          this.vendorQuoteHistoryArray.push(element);
        }
        console.log(
          'this is vendor quote history ',
          this.vendorQuoteHistoryArray
        );

        for (let index = 0; index < response.length; index++) {
          const quote = response[index];
          for (
            let index = 0;
            index < quote.vendorQuoteRowHistory.length;
            index++
          ) {
            const element = quote.vendorQuoteRowHistory[index];
            console.log('this is vendor quote row history ', element);

            quoteId.push(quote.id);
            lineName.push(element.lineName);
            frequency.push(element.frequency);
            transitTime.push(element.transitTime);
            transitPort.push(element.transitPort);
            if (this.seaBool) {
              vesselName.push(element.vesselName);
              vesselSchedule.push(element.vesselSchedule);
              sailingDays.push(element.sailingDays);
              loadingTerminal.push(element.loadingTerminal);
            }
            routing.push(element.routing);
            etd.push(element.etd);
            eta.push(element.eta);
            quoteValidTill.push(element.quoteValidTill);
            amount.push(element.amount);

            let addChargeArray: Array<any> = element.additionalChargesHistory;
            if (addChargeArray.length > this.maxAdditionalCharge) {
              this.additionalCharges = true;
              this.maxAdditionalCharge = addChargeArray.length;
            }

            let containerArray = element.vendorQuoteContainersHistory;
            if (containerArray.length > this.maxContainer) {
              this.containerDetails = true;
              this.maxContainer = containerArray.length;
            }

            let shipmentArray = element.vendorQuoteShipmentPackagesHistory;
            if (shipmentArray.length > this.maxShipment) {
              this.shipmentPackages = true;
              this.maxShipment = shipmentArray.length;
            }

            let BreakBulkArray = element.vendorQuoteBreakBulksHistory;
            if (BreakBulkArray.length > this.maxBreakbulk) {
              this.breakBulkdetails = true;
              this.maxBreakbulk = BreakBulkArray.length;
            }


            if(element.vendorQuoteContainersHistory.length > 0){
              for (let index = 0; index < element.vendorQuoteContainersHistory.length; index++) {
                const container = element.vendorQuoteContainersHistory[index];
                console.log('this is history container ', container.container.id);
                for (let index = 0; index < this.containerTypes.length; index++) {
                  const containerType = this.containerTypes[index];
                  if(container.container.type == containerType.id){
                    console.log('this is container type ', containerType);
                    container.container['containerType'] = containerType.value
                  }
                }
              }
            }

            this.vendorQuoteHistoryArray.push(element);
          }
        }

        console.log(
          'this is vendor quote history Array ',
          this.vendorQuoteHistoryArray
        );

        this.ValueColumns.push(
          quoteId,
          lineName,
          frequency,
          transitTime,
          transitPort
        );
        if (this.seaBool) {
          this.ValueColumns.push(
            vesselName,
            vesselSchedule,
            sailingDays,
            loadingTerminal
          );
        }
        this.ValueColumns.push(routing, etd, eta, quoteValidTill, amount);
        this.maxBreakbulkArray = Array(this.maxBreakbulk)
          .fill(0)
          .map((x, i) => i);
        this.maxShipmentArray = Array(this.maxShipment)
          .fill(0)
          .map((x, i) => i);

        this.maxContainerArray = Array(this.maxContainer)
          .fill(0)
          .map((x, i) => i);
        this.maxAdditionalChargeArray = Array(this.maxAdditionalCharge)
          .fill(0)
          .map((x, i) => i);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove('sidebar-active');
  }

  containerTypeList() {
    this.masterTableService.getContainerTypes().subscribe(
      (response) => {
        this.containerTypes = response;
        console.log('this is container types ', this.containerTypes);
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
