<app-header></app-header>
<section class="banner text-center text-white d-flex align-items-center banner-full"
    style="background-image: url(assets/images/banner.jpg)">
    <div class="container position-relative">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <div class="heading-dual">
                    <h1>LogSolutec</h1>
                    <h2>Client Profile</h2>
                </div>
                <p class="text-center">We would love to join hands with you and exceed your expectations! Connect today!
                </p>
            </div>
        </div>
    </div>
</section>

<!-- ToDo image of client's -->
<section class="about-wrapper text-dark py-8">
    <div class="container position-relative">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="heading-dual">
                    <h6>Clients Profile</h6>
                    <h1>Clients</h1>
                </div>
                <h3>Company Who <span class="text-success">Trust</span> Us</h3>
                <p>
                    The client profile of LogSolutec represents our growth, our culture and our work. We would love it
                    if you can take a glance and let the profiles speak for themselves.
                </p>
            </div>
        </div>

        <div class="row justify-content-space-evenly">
            <div class="col-lg-3 pt-2">
                <img src="../../../assets/images/client_1.png" alt="">
            </div>
            <div class="col-lg-3 pt-2">
                <img src="../../../assets/images/client_2.png" alt="">
            </div>
            <div class="col-lg-3 pt-2">
                <img src="../../../assets/images/client_3.png" alt="">
            </div>
            <div class="col-lg-3 pt-2">
                <img src="../../../assets/images/client_4.png" alt="">
            </div>
            <div class="col-lg-3 pt-2">
                <img src="../../../assets/images/client_1.png" alt="">
            </div>
            <div class="col-lg-3 pt-2">
                <img src="../../../assets/images/client_2.png" alt="">
            </div>
            <div class="col-lg-3 pt-2">
                <img src="../../../assets/images/client_3.png" alt="">
            </div>
        </div>
    </div>
</section>


<!-- ToDo Testimonials -->
<section class="services-wrapper text-dark py-8">
    <div class="container-fluid p-lg-start-end">
        <div class="row">
            <div class="col-lg-4 mb-3 mb-lg-4">
                <div class="heading-dual">
                    <h6>Testimonials</h6>
                    <h1>Testimonials</h1>
                    <h2>What People Say <span class="text-success">About</span> Us </h2>
                </div>
            </div>
        </div>
        <div class="row justify-content-center mt-3">
            <div class="col-lg-6"></div>
            <div class="col-lg-6">

            </div>
        </div>
    </div>
</section>
