import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { OnboardService } from '../helpers/onboard.service';
import { SocketService } from '../helpers/socket.service';

@Component({
  selector: 'app-admin-client-param',
  templateUrl: './admin-client-param.component.html',
  styleUrls: ['./admin-client-param.component.css']
})
export class AdminClientParamComponent implements OnInit {

  page: number = 0;
  size: number = 5;
  clientLength: number;
  serchFilter: Boolean = false;
  nodata: Boolean = true;
  title = "Client Parameters";

  displayedColumns: string[] = ['orgName', 'hardExpiryDurationInDays', 'lockAfterMaxUnsuccuessfulAttempt',
    'maxBidTimeExtensions', 'maxExtensionAllowed', 'maxUnsuccessfulAttempts', 'otpExpiresInMinutes',
    'passwordExpiryDays', 'rfqDueReminersBeforeDueDate', 'softExpiryDurationInDays',]

  dataSource = new MatTableDataSource()


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private clientService: OnboardService, private socketService: SocketService) { }

  ngOnInit(): void {
    this.getAllClientParam();
    // this.socketService.connectTOServerPopUpFuction()
  }

  getAllClientParam() {
    this.clientService.getAllClientParam(this.page, this.size).subscribe(
      (response) => {
        console.log(response);
        this.clientLength = response.totalElements;
        if (response.content != null) {
          this.dataSource = new MatTableDataSource(response.content);
          this.dataSource.sort = this.sort
        }
      },
      (error) => {
        console.log(error)
      }
    )
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.serchFilter = true;
    this.nodata = false;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onPageChange(event: PageEvent) {
    console.log(event);
    this.size = event.pageSize;
    this.page = event.pageIndex;
    this.getAllClientParam();
  }
}
