import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AuctionService } from 'src/app/helpers/auction.service';
import { SocketService } from 'src/app/helpers/socket.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-detail-comparison',
  templateUrl: './detail-comparison.component.html',
  styleUrls: ['./detail-comparison.component.css']
})
export class DetailComparisonComponent implements OnInit {

  dataSource!: MatTableDataSource<any>;
  displaycolumn: string[] = ["forwarderName", "quoteValue","auctionValue", "rank","var-amount","savings", "actions"];
  auctionObj: any;
  enquiryObj: any;
  response:any;
  checkedList: Array<any>=[];

  constructor(private apiservice: AuctionService, private route: ActivatedRoute, private router: Router, private socketService: SocketService) { }

  ngOnInit(): void {
    let auctionId = this.route.snapshot.paramMap.get('id');
    this.fetchVendorBidById(auctionId);
    // this.socketService.connectTOServerPopUpFuction()
  }
  fetchVendorBidById(id: any) {
    this.apiservice.fetchVendorBidById(id).subscribe((response: Array<any>) => {
      console.log(response);
      if(response.length > 0){
        this.response=response;
        this.auctionObj = response[0].auction;
        this.enquiryObj = response[0].auction.enquiry;
        response.sort((a, b) => (a.auctionValue > b.auctionValue) ? 1 : -1);
        this.dataSource = new MatTableDataSource(response);
      }
      else{
        this.getAuctionById(id);
      }
    },
      (error) => {
        console.log(error);
        Swal.fire({
          titleText: "Error",
          text: "Something went wrong",
          timer: 5000,
          icon: "error"
        })
      });
  }
  check(event, id, index) {
    let value = event.srcElement.checked;
    let _id = id;
    if (value == true) {
      this.checkedList.push({"id":_id,"index":index});
    }
    else if (value == false) {
      this.checkedList.splice(this.checkedList.findIndex(obj => obj.id==_id), 1);
    }
    else {
      console.log("NULL");
    }
  }
  createServiceOrder() {
    if (this.checkedList.length != 1) {
      Swal.fire({
        titleText: "Warning",
        icon: "warning",
        text: "Please check only one Checkbox",
        timer: 5000
      })
    }
    else {
      this.router.navigate(['v1/client-service-order/creation/',"a", this.checkedList[0].id],{state:{data:this.response[this.checkedList[0].index]}});
    }
  }
  
  getAuctionById(id){
    this.apiservice.fetchAuctionById(id).subscribe(
      (response) => {
        this.auctionObj = response;
        this.enquiryObj = response?.enquiry;
      },
      (error) => {
        console.log(error);
      },
    )
  }
}
