<header class="box-absolute d-flex align-items-center topline text-white">
    <app-sidebar></app-sidebar>
    <div class="page-title-center mt-3">
        <div class="text-center">
          <h1>Template List</h1>
        </div>
    </div>
    <div class="breadcrumb-div">
        <ul class="breadcrumb p-0 m-0">
            <li class="breadcrumb-item 3" id="0"><a [routerLink]="['/v1/dashboard']">Dashboard</a></li>
            <li class="breadcrumb-item 3" id="0"><a>Template List</a></li>
        </ul>
    </div>
</header>
<div (click)="hideSideBar()">
    <section class="banner text-center text-white d-flex" id="bnner">
        <div class="container position-relative">
            <div class="row justify-content-center">
                <div class="col-lg-15">
                </div>
            </div>
        </div>
    </section>

    <section class="container">
        <div class="row">
            <div class="col-lg-3 mb-3 mt-3">
                <div style="border: 2px solid black; border-radius: 5px;" class="card-body">
                    <svg width="auto" height="auto" viewBox="0 0 260 279" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0 10C0 4.47715 4.47715 0 10 0H250C255.523 0 260 4.47715 260 10V269C260 274.523 255.523 279 250 279H10C4.47716 279 0 274.523 0 269V10Z"
                            fill="#F7F8FA" />
                    </svg>

                    <h5 class="card-text mt-3">New Blank Template</h5>
                    <div class="row justify-content-center">
                        <div class="col-md-6">
                            <button type="button" class="btn-outline-success" style="border-radius: 8px;">
                                <i class="fas fa-eye"></i>
                            </button>
                        </div>
                        <div class="col-md-6">
                            <button title="Create Template" type="button" class="btn-outline-primary"  style="border-radius: 8px;"
                            [routerLink]="['/v1/client-template/creation']" routerLinkActive="router-link-active">
                                <b>New</b>
                            </button>
                        </div>
                    </div>
                    <br>
                    <br>
                </div>
            </div>

            <div class="col-lg-3 mb-3 mt-3" *ngFor="let list of templateList; let i=index">
                <div style="border: 2px solid black; border-radius: 5px;" class="card-body">
                    <svg width="auto" height="auto" viewBox="0 0 260 279" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0 10C0 4.47715 4.47715 0 10 0H250C255.523 0 260 4.47715 260 10V269C260 274.523 255.523 279 250 279H10C4.47716 279 0 274.523 0 269V10Z"
                            fill="#F7F8FA" />
                        <rect x="148" y="121" width="36" height="36" rx="0.5" transform="rotate(90 148 121)"
                            stroke="#414A53" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M112 143L119.646 135.354C119.842 135.158 120.158 135.158 120.354 135.354L142 157"
                            stroke="#414A53" stroke-width="1.5" stroke-linecap="round" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M133 135C135.761 135 138 132.761 138 130C138 127.239 135.761 125 133 125C130.239 125 128 127.239 128 130C128 132.761 130.239 135 133 135Z"
                            stroke="#414A53" stroke-width="1.5" />
                        <path d="M133 148L148 133" stroke="#414A53" stroke-width="1.5" />
                        <path
                            d="M203.179 233.151H56.8231C54.2575 233.151 52.1774 231.286 52.1774 228.984V49.016C52.1774 46.7149 54.2571 44.8491 56.8231 44.8491H203.179C205.744 44.8491 207.825 46.7145 207.825 49.016V228.984C207.825 231.285 205.745 233.151 203.179 233.151V233.151Z"
                            fill="white" />
                        <path
                            d="M207.823 49.0158V228.984C207.823 231.286 205.744 233.151 203.177 233.151H193.013C195.579 233.151 197.659 231.286 197.659 228.984V49.0158C197.659 46.7136 195.579 44.8486 193.013 44.8486H203.177C205.744 44.8486 207.823 46.714 207.823 49.0158V49.0158Z"
                            fill="#F7F8FA" />
                        <path
                            d="M203.178 236H56.8221C52.5089 236 49 232.853 49 228.984V176.038C49 174.464 50.422 173.189 52.1764 173.189C53.9309 173.189 55.3528 174.464 55.3528 176.038V228.984C55.3528 229.711 56.0119 230.302 56.8221 230.302H203.178C203.988 230.302 204.647 229.711 204.647 228.984V49.0158C204.647 48.2891 203.988 47.698 203.178 47.698H56.8221C56.0123 47.698 55.3528 48.2891 55.3528 49.0158V162.718C55.3528 164.292 53.9309 165.567 52.1764 165.567C50.422 165.567 49 164.292 49 162.718V49.0158C49.0004 45.1472 52.5093 42 56.8221 42H203.178C207.491 42 211 45.1472 211 49.0158V228.984C211 232.853 207.491 236 203.178 236V236Z"
                            fill="#414A53" />
                        <path
                            d="M184.84 131.004H75.1621C74.4473 131.004 73.8681 130.484 73.8681 129.843V109.007C73.8681 108.366 74.4477 107.847 75.1621 107.847H184.84C185.555 107.847 186.134 108.366 186.134 109.007V129.843C186.134 130.484 185.555 131.004 184.84 131.004Z"
                            fill="#003D90" />
                        <path
                            d="M106.765 219.123H75.1621C74.4473 219.123 73.8681 218.604 73.8681 217.963V145.269C73.8681 144.628 74.4477 144.108 75.1621 144.108H106.765C107.48 144.108 108.059 144.628 108.059 145.269V217.963C108.059 218.604 107.48 219.123 106.765 219.123V219.123Z"
                            fill="#003D90" />
                        <path
                            d="M186.135 109.006V129.842C186.135 130.484 185.554 131.004 184.839 131.004H176.368C177.084 131.004 177.664 130.484 177.664 129.842V109.006C177.664 108.368 177.084 107.847 176.368 107.847H184.839C185.554 107.847 186.135 108.367 186.135 109.006Z"
                            fill="#003D90" />
                        <path
                            d="M108.057 145.268V217.964C108.057 218.602 107.477 219.123 106.766 219.123H98.2949C99.0063 219.123 99.5868 218.602 99.5868 217.964V145.268C99.5868 144.63 99.0068 144.109 98.2949 144.109H106.766C107.477 144.109 108.057 144.63 108.057 145.268Z"
                            fill="#003D90" />
                        <path
                            d="M72.7363 83.2357C73.1084 83.363 73.4903 83.4229 73.8663 83.4229C75.1464 83.4229 76.3529 82.7238 76.8353 81.5871L78.3185 78.0947H88.528L89.9939 81.5791C90.6132 83.0508 92.4454 83.7946 94.0874 83.2388C95.7287 82.6829 96.5571 81.0392 95.9378 79.5672L86.9366 58.1749C86.9256 58.1487 86.9142 58.1226 86.9024 58.0968C86.3257 56.8392 84.9739 56.0269 83.4577 56.0269C83.4564 56.0269 83.4552 56.0269 83.4539 56.0269C81.936 56.0284 80.5846 56.8434 80.0101 58.1033C80.0003 58.1245 79.991 58.1461 79.9817 58.1673L70.8982 79.5588C70.2742 81.0301 71.0967 82.6761 72.7363 83.2357V83.2357ZM83.4467 66.0186L86.1305 72.3971H80.7379L83.4467 66.0186Z"
                            fill="#414A53" />
                        <path
                            d="M108.058 82.651C109.373 82.651 110.626 82.4131 111.769 81.9841C112.316 82.8424 113.345 83.4221 114.526 83.4221C116.28 83.4221 117.702 82.1467 117.702 80.5731V67.4288C117.702 65.8552 116.28 64.5798 114.526 64.5798C113.345 64.5798 112.316 65.1596 111.769 66.0178C110.626 65.5889 109.373 65.3509 108.058 65.3509C102.741 65.3509 98.4142 69.2313 98.4142 74.001C98.4142 78.7706 102.741 82.651 108.058 82.651ZM108.058 71.0497C109.873 71.0497 111.349 72.3739 111.349 74.0013C111.349 75.6291 109.873 76.953 108.058 76.953C106.244 76.953 104.767 75.6287 104.767 74.0013C104.767 72.3736 106.244 71.0497 108.058 71.0497Z"
                            fill="#414A53" />
                        <path
                            d="M128.512 83.4224H186.134C187.888 83.4224 189.31 82.147 189.31 80.5734C189.31 78.9998 187.888 77.7244 186.134 77.7244H128.512C126.757 77.7244 125.335 78.9998 125.335 80.5734C125.335 82.1466 126.757 83.4224 128.512 83.4224Z"
                            fill="#414A53" />
                        <path
                            d="M186.133 91.1187H73.8672C72.1128 91.1187 70.6908 92.394 70.6908 93.9676C70.6908 95.5412 72.1128 96.8166 73.8672 96.8166H186.134C187.888 96.8166 189.31 95.5412 189.31 93.9676C189.31 92.394 187.888 91.1187 186.133 91.1187V91.1187Z"
                            fill="#414A53" />
                        <path
                            d="M184.839 104.997H166.424C164.669 104.997 163.247 106.272 163.247 107.846C163.247 109.42 164.669 110.695 166.424 110.695H182.957V128.155H77.0436V110.695H151.706C153.461 110.695 154.883 109.42 154.883 107.846C154.883 106.272 153.461 104.997 151.706 104.997H75.1612C72.6962 104.997 70.6904 106.796 70.6904 109.007V129.843C70.6904 132.054 72.6957 133.853 75.1612 133.853H184.839C187.304 133.853 189.31 132.054 189.31 129.843V109.007C189.31 106.796 187.304 104.997 184.839 104.997Z"
                            fill="#414A53" />
                        <path
                            d="M106.764 141.26H75.1612C72.6962 141.26 70.6904 143.058 70.6904 145.269V217.963C70.6904 220.174 72.6957 221.973 75.1612 221.973H106.764C109.229 221.973 111.235 220.175 111.235 217.963V145.269C111.235 143.058 109.229 141.26 106.764 141.26ZM104.882 216.275H77.0436V146.958H104.882V216.275Z"
                            fill="#414A53" />
                        <path
                            d="M186.133 141.26H147.287C145.533 141.26 144.111 142.535 144.111 144.109C144.111 145.682 145.533 146.958 147.287 146.958H186.133C187.888 146.958 189.31 145.682 189.31 144.109C189.31 142.535 187.888 141.26 186.133 141.26Z"
                            fill="#414A53" />
                        <path
                            d="M186.133 153.762H124.065C122.31 153.762 120.888 155.037 120.888 156.611C120.888 158.184 122.31 159.46 124.065 159.46H186.134C187.888 159.46 189.31 158.184 189.31 156.611C189.31 155.037 187.888 153.762 186.133 153.762Z"
                            fill="#414A53" />
                        <path
                            d="M186.133 166.264H124.065C122.31 166.264 120.888 167.54 120.888 169.113C120.888 170.687 122.31 171.962 124.065 171.962H186.134C187.888 171.962 189.31 170.687 189.31 169.113C189.31 167.54 187.888 166.264 186.133 166.264Z"
                            fill="#414A53" />
                        <path
                            d="M186.133 178.767H124.065C122.31 178.767 120.888 180.042 120.888 181.616C120.888 183.19 122.31 184.465 124.065 184.465H186.134C187.888 184.465 189.31 183.19 189.31 181.616C189.31 180.042 187.888 178.767 186.133 178.767Z"
                            fill="#414A53" />
                        <path
                            d="M186.133 191.269H124.065C122.31 191.269 120.888 192.545 120.888 194.118C120.888 195.692 122.31 196.967 124.065 196.967H186.134C187.888 196.967 189.31 195.692 189.31 194.118C189.31 192.545 187.888 191.269 186.133 191.269Z"
                            fill="#414A53" />
                        <path
                            d="M186.133 203.772H124.065C122.31 203.772 120.888 205.048 120.888 206.621C120.888 208.195 122.31 209.47 124.065 209.47H186.134C187.888 209.47 189.31 208.195 189.31 206.621C189.31 205.048 187.888 203.772 186.133 203.772Z"
                            fill="#414A53" />
                        <path
                            d="M169.029 216.275H124.065C122.31 216.275 120.888 217.55 120.888 219.124C120.888 220.697 122.31 221.973 124.065 221.973H169.029C170.784 221.973 172.206 220.697 172.206 219.124C172.206 217.55 170.783 216.275 169.029 216.275Z"
                            fill="#414A53" />
                    </svg>
                    <div class="row mt-2">
                        <div class="col-sm-12">
                            <h5 class="card-text mt-3">{{ list?.name || '----' }}</h5>
                        </div>
                        <div class="col-md-4 justify-content-center">
                            <button title="Edit Template" class="fas fa-pencil-alt btn-outline-dark pull-right" style="border-radius: 8px;" [routerLink]="['/v1/client-template/edit/', list.id]"
                                routerLinkActive="router-link-active"></button>
                        </div>
                        <div class="col-md-4 justify-content-center">
                            <button title="Delete Template" class="fas fa-trash btn-outline-danger pull-right" style="border-radius: 8px;" (click)="deleteTemplateById(list.id)"></button>
                        </div>

                        <button title="Use this template to create invoice" type="button" class="justify-content-center btn-outline-primary mt-3"  style="border-radius: 8px; height: 35px;" [routerLink]="['/v1/pre-shipment-invoice/create/', 'PreShipment' ,list.id]" routerLinkActive="router-link-active"><b> Use this template </b></button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>