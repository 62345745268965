<!--header start-->
<header class="box-absolute d-flex align-items-center topline text-white">
  <app-sidebar></app-sidebar>
  <div class="page-title-center mt-3">
    <div class="text-center">
      <h1>{{ title }}</h1>
    </div>
  </div>
  <div class="breadcrumb-div mt-3">
      <ul class="breadcrumb p-0 m-0">
          <li class="breadcrumb-item 3" id="0"><a [routerLink]="['/v1/dashboard']">Dashboard</a></li>
          <li class="breadcrumb-item 3" id="1"><a>RFQ</a></li>
          <li class="breadcrumb-item"><a>Quote Comparison</a></li>
      </ul>
  </div>
</header>
<!--header end-->
<section class="banner text-center text-white d-flex" id="bnner">
  <div class="container position-relative">
    <div class="row justify-content-center">
      <div class="col-lg-12">
      </div>
    </div>
  </div>
</section>
<!--request start-->
<section class="request-demo-wrapper pb-10" (click)="hideSideBar()">
  <div class="container shadow bg-white">
    <div class="row">
      <div class="col-lg-12">
        <div class="box">
          <h4>Latest Quote</h4>
          <div class="row">
              <div class="col-lg-6 form-group">
                <label class="label-bold"> RFQ Number :- </label>
                <label> {{ rfqNumber }}</label>
              </div>
              <div class="col-lg-6 form-group">
                <label class="label-bold"> Quote Number :- </label>
                <label> {{ quoteNumber || '---' }}</label>
              </div>
          </div>
          <div id="item-1" class="table-responsive mt-1">
            <table class="table table-borderless table-striped table-footer-dark table-head-dark" mat-table [dataSource]="dataSource" 
              matSort matSortDisableClear>

              <ng-container matColumnDef="shippingLine">
                <th mat-header-cell class="text-white" *matHeaderCellDef mat-sort-header> Shipping Line </th>
                <td mat-cell *matCellDef="let row; let i = index"> {{ row.shippingLine }} </td>
              </ng-container>

              <ng-container matColumnDef="frequency">
                <th mat-header-cell class="text-white" *matHeaderCellDef mat-sort-header> Frequency </th>
                <td mat-cell *matCellDef="let row; let i = index"> {{ row.frequency }} </td>
              </ng-container>

              <ng-container matColumnDef="quoteValidity">
                <th mat-header-cell class="text-white" *matHeaderCellDef mat-sort-header> Validity </th>
                <td mat-cell *matCellDef="let row; let i = index"> {{ row.quoteValidity }} </td>
              </ng-container>

              <ng-container matColumnDef="transitPort">
                <th mat-header-cell class="text-white" *matHeaderCellDef mat-sort-header> Transite Port </th>
                <td mat-cell *matCellDef="let row; let i = index"> {{ row.transitPort }} </td>
              </ng-container>

              <ng-container matColumnDef="transitTime">
                <th mat-header-cell class="text-white" *matHeaderCellDef mat-sort-header> Transite Time </th>
                <td mat-cell *matCellDef="let row; let i = index"> {{ row.transitTime }} </td>
              </ng-container>

              <ng-container matColumnDef="totalFreight">
                <th mat-header-cell class="text-white" *matHeaderCellDef mat-sort-header> Total Freight </th>
                <td mat-cell *matCellDef="let row; let i = index"> {{ row.totalFreight }} </td>
              </ng-container>

              <tr class="text-left bg-dark" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell text-center text-danger" colspan="12">
                  <h5>Data is Not Available</h5>
                </td>
                <div *ngIf="noQuoteBool">
                  NO Data Available
                </div>
              </tr>
            </table>
          </div>
        </div>
        <div class="box">
          <h4> Quote History </h4>
          <div id="item-2" class="table-responsive">
            <table class="table table-borderless table-striped table-footer-dark table-head-dark" mat-table [dataSource]="dataSource1" 
              matSort matSortDisableClear>

              <ng-container matColumnDef="id">
                <th mat-header-cell class="text-white" *matHeaderCellDef mat-sort-header> Quote ID </th>
                <td mat-cell *matCellDef="let row; let i = index"> {{ row.id }} </td>
              </ng-container>

              <ng-container matColumnDef="shippingLine">
                <th mat-header-cell class="text-white" *matHeaderCellDef mat-sort-header> Shipping Line </th>
                <td mat-cell *matCellDef="let row; let i = index"> {{ row.shippingLine }} </td>
              </ng-container>

              <ng-container matColumnDef="frequency">
                <th mat-header-cell class="text-white" *matHeaderCellDef mat-sort-header> Frequency </th>
                <td mat-cell *matCellDef="let row; let i = index"> {{ row.frequency }} </td>
              </ng-container>

              <ng-container matColumnDef="quoteValidity">
                <th mat-header-cell class="text-white" *matHeaderCellDef mat-sort-header> Validity </th>
                <td mat-cell *matCellDef="let row; let i = index"> {{ row.quoteValidity }} </td>
              </ng-container>

              <ng-container matColumnDef="transitPort">
                <th mat-header-cell class="text-white" *matHeaderCellDef mat-sort-header> Transite Port </th>
                <td mat-cell *matCellDef="let row; let i = index"> {{ row.transitPort }} </td>
              </ng-container>

              <ng-container matColumnDef="transitTime">
                <th mat-header-cell class="text-white" *matHeaderCellDef mat-sort-header> Transite Time </th>
                <td mat-cell *matCellDef="let row; let i = index"> {{ row.transitTime }} </td>
              </ng-container>

              <ng-container matColumnDef="totalFreight">
                <th mat-header-cell class="text-white" *matHeaderCellDef mat-sort-header> Total Freight </th>
                <td mat-cell *matCellDef="let row; let i = index"> {{ row.totalFreight }} </td>
              </ng-container>

              <tr class="text-left bg-dark" mat-header-row *matHeaderRowDef="displayedColumns1">
              </tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
              <!-- Row shown when there is no matching data -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell text-center text-danger" colspan="8">
                    <div>
                        <h5>Data is Not Available</h5>
                    </div>
                    <div *ngIf="noQuoteBool">
                      NO Data Available
                    </div>
                </td>
            </tr>
            </table>
          </div>
          <!-- WIP -->
          <div class="row justify-content-center">
            <div *ngIf="btndisable" class="col-md-3 px-2 form-group pt-lg-3">
              <button class="btn small btn-success px-3" (click)="gotodetail()"  type="button">DETAIL
                COMPARISON</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--request end  -->