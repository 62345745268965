import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MasterTableService } from 'src/app/helpers/master-table.service';
import { CurrencyDialogBoxComponent } from './currency-dialog-box/currency-dialog-box.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.css']
})
export class CurrencyComponent implements OnInit {

  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['id', 'currency', 'currencyCode', 'minorUnit', 'numericCode', 'actions'];
  serchFilter: Boolean = false;
  nodata: Boolean = true;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  currencyLenght: number;
  page: number = 0;
  size: number = 25;

  sessionStorageUserData: any
  disableCreateBtn: boolean = true
  disableEditBtn: boolean = true
  disableDeleteBtn: boolean = true

  constructor(private masterTableService: MasterTableService, public dialog: MatDialog,) { }

  ngOnInit(): void {
    this.getCurrencyList();
    this.getCurrentUserDataFromSessionStorage();
  }

  getCurrencyList() {
    this.masterTableService.getCurrencyPageable(this.page, this.size).subscribe(
      (response) => {
        console.log(response.content);
        if (response.content != null) {
          this.dataSource = new MatTableDataSource(response.content);
          this.dataSource.sort = this.sort;
          this.currencyLenght = response.totalElements;
        }
      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      },
    )
  }

  openDialog(action, obj) {
    obj.action = action;
    const dialogRef = this.dialog.open(CurrencyDialogBoxComponent, {
      width: '250px',
      data: obj
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      if (result.event == 'Create') {
        this.addCurrency(result.data);
      } else if (result.event == 'Update') {
        this.updateCurrency(result.data.id, result.data)
      } else if (result.event == 'Delete') {
        this.deleteCurrency(result.data.id);
      }
      this.getCurrencyList();
    });
  }

  addCurrency(data) {
    this.masterTableService.saveCurrency(data).subscribe(
      (response) => {
        if (response.code == 201) {
          Swal.fire({
            titleText: "Success",
            icon: "success",
            text: response.message,
            timer: 5000
          })
        }
        this.getCurrencyList();
      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      },
    )
  }

  updateCurrency(id, data) {
    this.masterTableService.updateCurrency(id, data).subscribe(
      (response) => {
        if (response.code == 201) {
          Swal.fire({
            titleText: "Success",
            icon: "success",
            text: response.message,
            timer: 5000
          })
        }
        this.getCurrencyList();
      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      },
    )
  }

  deleteCurrency(id) {
    this.masterTableService.deleteCurrency(id).subscribe(
      (response) => {
        if (response.code == 201) {
          Swal.fire({
            titleText: "Success",
            icon: "success",
            text: response.message,
            timer: 5000
          })
        }
        this.getCurrencyList();
      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      },
    )
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.serchFilter=true;
    this.nodata=false;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    if (filterValue != null && filterValue != '') {
      this.serchFilter = true;
    }
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  onPageChange(event: PageEvent) {
    this.size = event.pageSize;
    this.page = event.pageIndex;
    this.getCurrencyList();
  }

  getCurrentUserDataFromSessionStorage(){
    // create auth code
    // RB0000094
    // update auth code
    // RB0000097
    // delete auth code
    // RB0000098
      this.sessionStorageUserData = JSON.parse(sessionStorage.getItem('userData'));
      console.log('this is user data ', this.sessionStorageUserData);
      for (let index = 0; index < this.sessionStorageUserData.authorities.length; index++) {
        const authority = this.sessionStorageUserData.authorities[index];
        console.log(authority);
        if(authority == 'RB0000094'){
          console.log('create authority is given');
          this.disableCreateBtn = false
        } else if(authority == 'RB0000097'){
          console.log('update authority is given');
          this.disableEditBtn = false
        } else if(authority == 'RB0000098') {
          console.log('delete authority is given');
          this.disableDeleteBtn = false
        }
      }
    }
}
