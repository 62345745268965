<header class="box-absolute d-flex align-items-center topline text-white">
    <app-sidebar></app-sidebar>
    <div class="page-title-center mt-3">
      <div class="text-center">
        <h1>{{ title }}</h1>
      </div>
    </div>
    <div class="breadcrumb-div mt-3">
        <ul class="breadcrumb p-0 m-0">
            <li class="breadcrumb-item 3" id="0"><a [routerLink]="['/v1/dashboard']">Dashboard</a></li>
            <li class="breadcrumb-item 3" id="1"><a>Auction</a></li>
            <li class="breadcrumb-item"><a>{{ title }}</a></li>
        </ul>
    </div>
</header>
<!--header end-->
<section class="banner text-center text-white d-flex" id="bnner">
    <div class="container position-relative">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                
            </div>
        </div>
    </div>
</section>
<!--request start-->
<section class="request-demo-wrapper pb-10">
    <div class="container shadow bg-white" (click)="hideSideBar()">
        <div class="row">
            <div class="col-lg-12">
                <div class="box">
                    <form>
                        <mat-form-field class="form-control" appearance="standard">
                            <mat-label>Search</mat-label>
                            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search" #input>
                        </mat-form-field>
                        <div class="table-responsive">
                            <table mat-table [dataSource]="dataSource" matSort
                                class="table table-borderless table-head-dark ">

                                <ng-container matColumnDef="auctionNumber">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-white ">Auction Number
                                    </th>
                                    <td mat-cell *matCellDef="let element">{{element.number}}</td>
                                </ng-container>

                                <ng-container matColumnDef="description">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-white ">
                                        Description</th>
                                    <td mat-cell *matCellDef="let element">{{element.description}}</td>
                                </ng-container>

                                <ng-container matColumnDef="date">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-white ">Date</th>
                                    <td mat-cell *matCellDef="let element">{{element.auctionDate | date:'dd-MM-YYYY HH:mm:ss' }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="status">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-white "> Auction Status
                                    </th>
                                    <td mat-cell *matCellDef="let element">{{element.status||"DRAFT"}}</td>
                                </ng-container>

                                <ng-container matColumnDef="baseprice">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-white ">Base Price
                                    </th>
                                    <td mat-cell *matCellDef="let element">{{ element.baseprice }}</td>
                                </ng-container>

                                <ng-container matColumnDef="actions">
                                    <th mat-header-cell *matHeaderCellDef class="text-white ">Actions</th>
                                    <td mat-cell *matCellDef="let element; index as i">
                                        <input type="checkbox" (change)="compare($event, element.id, i)">
                                        <button *ngIf="element.status == 'IN_PROGRESS' && element.isAgreed == true" class="fa fa-play-circle text-primary" type="button"
                                            [routerLink]="['/v1/vendor-live-auction/', element.id]"
                                            routerLinkActive="router-link-active"></button>
                                        <button *ngIf="element.status == 'APPROVED'" type="button" class="text-primary mb-1" matTooltip="Auction Terms & Conditions" (click)="openTermsAndConditions(element)">
                                          <i class="fa fa-clipboard"></i>
                                        </button>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayedColumns" class="text-left bg-dark"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class=""></tr>

                                <tr class="mat-row text-danger" *matNoDataRow>
                                    <td class="mat-cell text-center text-danger" colspan="12">No data matching the filter
                                        "{{input.value}}"</td>
                                </tr>
                            </table>
                            <mat-paginator [pageSizeOptions]="[25, 50, 100]" [length]="auctionLength" showFirstLastButtons
                                (page)="onPageChange($event)">
                            </mat-paginator>
                        </div>

                        <div class="row justify-content-center">
                            <!-- <div class="col-md-4 col-lg-3 form-group pt-lg-3">
                                <button class="btn text-dark small btn-outline-success px-2"
                                    type="button" (click)="partcipate()">PARTICIPATE</button>
                            </div> -->
                            <!-- <div class="col-md-4 col-lg-3 form-group pt-lg-3">
                                <button class="btn text-dark small btn-outline-success px-2" type="button"
                                (click)="provideComments()">PROVIDE
                                    COMMENTS</button>
                            </div> -->
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
