import {
  HttpErrorResponse,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  ActivatedRoute,
} from '@angular/router';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { AuthServiceService } from './auth-service.service';
import Swal from 'sweetalert2';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  injector: any;
  userData: any;

  routeAuthCode: any;
  isUserValidToRoute: boolean = false;
  constructor(
    private router: Router,
    private authService: AuthServiceService,
    private route: ActivatedRoute
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Promise<boolean> {
    this.routeAuthCode = route.data;
    console.log(
      'this is activated route snapshop ',
      this.routeAuthCode.authorityCode
    );

    this.userData = JSON.parse(sessionStorage.getItem('userData'));
    console.log('this is user data ', this.userData);

    for (let index = 0; index < this.userData.authorities.length; index++) {
      const element = this.userData.authorities[index];
      if (this.routeAuthCode != undefined) {
        if (element == this.routeAuthCode.authorityCode) {
          console.log('we have a match ');
          this.isUserValidToRoute = true;
          break;
        } else {
          this.isUserValidToRoute = false;
          console.log('we dont have a match ');
        }
      } else if (this.routeAuthCode.length > 0) {
        for (let index = 0; index < this.routeAuthCode.length; index++) {
          const element = this.routeAuthCode[index];
          console.log(element);
        }
      }
    }

    var isAuthenticated = this.authService.isAuthenticated();
    if (!isAuthenticated) {
      this.router.navigate(['/login']);
      return false;
    } else if (isAuthenticated) {
      if (this.isUserValidToRoute) {
        console.log('this user is authenticated');
      } else {
        this.router.navigate(['/v1/no-permission']);
      }
    }
    return true;
  }
}
