import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MasterTableService } from 'src/app/helpers/master-table.service';
import Swal from 'sweetalert2';
import { HsCodeDialogBoxComponent } from './hs-code-dialog-box/hs-code-dialog-box.component';

@Component({
  selector: 'app-hs-code',
  templateUrl: './hs-code.component.html',
  styleUrls: ['./hs-code.component.css']
})
export class HsCodeComponent implements OnInit {


  page: number = 0;
  size: number = 25;
  dataSource: any;
  serchFilter: boolean;
  nodata: boolean;
  hsCodeLength: number;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns = ['referenceLink', 'country' ,'action'];
  documentList: Array<any> = [];
  hsCodeId: any;

  sessionStorageUserData: any
  disableCreateBtn: boolean = true
  disableEditBtn: boolean = true
  disableDeleteBtn: boolean = true

  constructor(private dialog: MatDialog, private masterTable: MasterTableService) { }

  ngOnInit(): void {
    this.getAllHsCodes();
    this.getCurrentUserDataFromSessionStorage();
  }


  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  onPageChange(event: PageEvent) {
    this.size = event.pageSize;
    this.page = event.pageIndex;
    this.getAllHsCodes();
  }
  
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.serchFilter=true;
    this.nodata=false;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getAllHsCodes(){
    this.masterTable.getAllHsCodesPagable(this.page, this.size).subscribe(
      (response) => {
        console.log(response);
        if (response.content != null) {
          this.dataSource = new MatTableDataSource(response.content);
          this.dataSource.sort = this.sort;
          this.hsCodeLength = response.totalElements;
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }


  openDialog(action: String, obj: any) {
    obj.action = action;
    let dialogRef: any;
    if(obj.action == 'Delete'){
      dialogRef = this.dialog.open(HsCodeDialogBoxComponent, {
        width: '300px',
        height: '200px',
        data: obj
      });
    }
    else{
      dialogRef = this.dialog.open(HsCodeDialogBoxComponent, {
        width: '600px',
        height: '600px',
        data: obj
      });
    }
    dialogRef.afterClosed().subscribe(
      (result) => {
        console.log(result);
        if (result.event == 'Add') {
          this.addHsCode(result.data);
        }
        else if (result.event == 'Update') {
          this.hsCodeId = result.data.id;
          this.updateHsCode(this.hsCodeId, result.data);
        }
        else if (result.event == 'Delete') {
          this.hsCodeId = result.data.id;
          this.deleteHsCode(result.data.id);
        }
      }
    );
  }


  addHsCode(data){
    let _data = {
      country: data.country,
      referenceLink: data.referenceLink
    }
    this.documentList = data.artifacts
    this.masterTable.postHsCode(_data).subscribe(
      (response) => {
        if(response.code == 201){
          this.hsCodeId = response.entityId;
          Swal.fire({
            title: "Success",
            icon: "success",
            text: response.message,
            timer: 5000
          });
          for (let index = 0; index < this.documentList.length; index++) {
            this.uploadFile(index);
          }
          this.getAllHsCodes();
        }
      },
      (error) => {
        console.log(error);
      }
    )
  }

  updateHsCode(id, data){
    let _data = {
      country: data.country,
      referenceLink: data.referenceLink
    }
    this.documentList = data.artifacts
    this.masterTable.updateHsCode(id, _data).subscribe(
      (response) => {
        console.log(response);
        if (response.code == 201) {
          Swal.fire({
            titleText: "Success",
            icon: "success",
            text: response.message,
            timer: 5000
          });
          for (let index = 0; index < this.documentList.length; index++) {
            this.uploadFile(index);
          }
          this.getAllHsCodes();
        }
      },
      (error) => {
        console.log(error);
      }
    )
  }


  deleteHsCode(id){
    this.masterTable.deleteHsCode(id).subscribe(
      (response) => {
        console.log(response);
        if (response.code == 201) {
          Swal.fire({
            titleText: "Success",
            icon: "success",
            text: response.message,
            timer: 5000
          });
          for (let index = 0; index < this.documentList.length; index++) {
            this.uploadFile(index);
          }
          this.getAllHsCodes();
        }
      },
      (error) => {
        console.log(error);
      }
    )
  }

  uploadFile(index) {
    let data = this.documentList[index];
    if(!data.id){
      this.masterTable.postHsCodeArtifact(this.hsCodeId, data).subscribe(
        (response) => {
          if (response.code == 201) {
            data['artifactId'] = response.entityId;
          }
        },
        (error) => {
          console.log(error);
        },
      )
    }
  }

  getCurrentUserDataFromSessionStorage(){
    this.sessionStorageUserData = JSON.parse(sessionStorage.getItem('userData'));
    console.log('this is user data ', this.sessionStorageUserData);
    for (let index = 0; index < this.sessionStorageUserData.authorities.length; index++) {
      const authority = this.sessionStorageUserData.authorities[index];
      console.log(authority);
      if(authority == 'RB0000239'){
        console.log('create authority is given');
        this.disableCreateBtn = false
      } else if(authority == 'RB0000240'){
        console.log('update authority is given');
        this.disableEditBtn = false
      } else if(authority == 'RB0000241') {
        console.log('delete authority is given');
        this.disableDeleteBtn = false
      }
    }
  }



}
