import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthServiceService } from 'src/app/helpers/auth-service.service';
import { SocketService } from 'src/app/helpers/socket.service';
import { UserService } from 'src/app/helpers/user.service';
import Swal from 'sweetalert2';

export interface links {
  linkName: string,
  linkRoute: string
}

export interface sideBarMenu {
  parent: string,
  child: Array<links>,
  parentRoute?: string
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  showHamburger = false;
  userData: any;
  dType: number;
  orgName: string;
  emailId: string;
  dTypeOne = [];
  dTypeTwo = [];
  dTypeThree = [];
  hideSubMenu: Boolean = false;
  isImgDefault: Boolean = false;
  userImage: any;

  constructor(private router: Router, private authService: AuthServiceService, private userService: UserService, private socketService: SocketService) { }

  ngOnInit(): void {
    this.userData = JSON.parse(sessionStorage.getItem('userData'));
    let userId  = this.userData.userId;
    if(userId != null) {
      this.getUserById(userId);
    }
    if (this.userData == null) {
      this.hideSubMenu = true;
    }
    else {
      this.dType = this.userData['dType'];
      this.emailId = this.userData['emailId'];
      this.orgName = this.userData['orgName'];
    }

    if (this.dType == 1) {
      this.initDTypeOneMenu();
    }
    else if (this.dType == 2) {
      this.intiDTypeTwoMenu();
    }
    else if (this.dType == 3) {
      this.initDTypeThreeMenu();
    }
    else {
      console.log("NO SIDE BAR");
    }
  }

  initDTypeOneMenu() {
    let dashboard: sideBarMenu = {
      parent: "Dashboard",
      child: [],
      parentRoute: '/v1/dashboard'
    }
    let track: sideBarMenu = {
      parent: 'Track & Trace',
      child: [
        { linkName: "Vessel Schedule", linkRoute: "/v1/client-track-trace/vessel-schedule" },
        { linkName: "Track Container", linkRoute: "/v1/client-track-trace/container-track" },
        { linkName: "Flight Schedule", linkRoute: "/v1/client-track-trace/client-flight-schedule" },
        { linkName: "Track Air Package", linkRoute: "/v1/client-track-trace/air-package-track"},
        { linkName: "Shipment Listing", linkRoute: "/v1/client-track-trace/shipment/listing" },
      ]
    }
    let report: sideBarMenu = {
      parent: "Report",
      child: [],
      parentRoute: '/v1/reports/listing'
    }
    let user: sideBarMenu = {
      parent: "User Management",
      child: [
        { linkName: "User List", linkRoute: "/v1/user/listing" },
        { linkName: "Role List", linkRoute: "/v1/user-roles/listing" },
        { linkName: "Permission List", linkRoute: "/v1/user-permission/listing" }
      ]
    }
    let configuration: sideBarMenu = {
      parent: "Configuration",
      child: [
        // { linkName: "Parameters", linkRoute: "/v1/admin/configuration-client-parameters" },
        { linkName: "Message Template", linkRoute: "/v1/admin/message-template" },
        { linkName: "Platform Params", linkRoute: "/v1/configuration/platform-param" },
      ]
    }
    let admin: sideBarMenu = {
      parent: "Admin",
      child: [
        { linkName: "Client On-boarding", linkRoute: "/v1/client-onboard/details" },
        { linkName: "Subscription", linkRoute: "/v1/admin/subcription/listing" },
        { linkName: "Client Listing", linkRoute: "/v1/client/listing" },
        { linkName: "Request Demo Listing", linkRoute: "/v1/admin/request-demo-listing" },
        { linkName: "Contact-Us Listing", linkRoute: "/v1/admin/contact-us-listing" },
        { linkName: "Manage Extension", linkRoute: "/v1/manage-extension" },
        { linkName: "Create Subscription", linkRoute: "/v1/buy-subscription" },
        { linkName: "Feedback Listing", linkRoute: "/v1/admin/feedback-listing" },
        { linkName: "Career Listing", linkRoute: "/v1/admin/career-listing" },
      ]
    }
    let masterTable: sideBarMenu = {
      parent: "Masters",
      child: [
        { linkName: "Mode of Shipment", linkRoute: "/v1/masterTable/modeOfShipment" },
        { linkName: "Trade Type", linkRoute: "/v1/masterTable/tradeType" },
        { linkName: "Type of Cargo", linkRoute: "/v1/masterTable/typeOfCargo" },
        { linkName: "Container Type", linkRoute: "/v1/masterTable/containerTypes" },
        { linkName: "Nature of Goods", linkRoute: "/v1/masterTable/natureOfGoods" },
        { linkName: "Nature of Movement", linkRoute: "/v1/masterTable/natureOfMovement" },
        { linkName: "Delivery Inco-Terms", linkRoute: "/v1/masterTable/deliveryIncoTerms" },
        { linkName: "Pickup Inco-Terms", linkRoute: "/v1/masterTable/pickUpIncoTerms" },
        { linkName: "Type of Packing", linkRoute: "/v1/masterTable/typeOfPacking" },
        { linkName: "Loading/Destination Port", linkRoute: "/v1/masterTable/loadingDestinationPort" },
        { linkName: "Currency", linkRoute: "/v1/masterTable/currency" },
        { linkName: "Country", linkRoute: "/v1/masterTable/country" },
        { linkName: "Charge Type", linkRoute: "/v1/masterTable/chargeType" },
        { linkName: "Type of Vehicle", linkRoute: "/v1/masterTable/typeOfVehicle" },
        { linkName: "Business Type", linkRoute: "/v1/masterTable/businessType"},
        { linkName: "Carriers", linkRoute: "/v1/masterTable/carriers"},
        { linkName: "Shipping Line", linkRoute: "/v1/masterTable/shippingLine"},
        { linkName: "HS Codes", linkRoute: "/v1/masterTable/hs-codes"},
        { linkName: "Trade Type Agreement", linkRoute: "/v1/masterTable/trade-type-agreement"},
        { linkName: "Market Space", linkRoute: "/v1/masterTable/marketing-space"}
      ]
    }
    // rfqData, auctionData, serviceOrder, invoice,
    this.dTypeOne.push(dashboard, track, report, user, configuration, admin, masterTable);
    return this.dTypeOne;
  }

  intiDTypeTwoMenu() {
    let dashboard: sideBarMenu = {
      parent: "Dashboard",
      child: [],
      parentRoute: '/v1/dashboard'
    }
    let rfqData: sideBarMenu = {
      parent: 'RFQ',
      child: [
        { linkName: 'Budgetory Enquiry Listing', linkRoute: '/v1/client-rfq/listing/b' },
        { linkName: 'Firm Enquiry Listing', linkRoute: '/v1/client-rfq/listing/f' },
        { linkName: 'Create Budgetory Enquiry', linkRoute: '/v1/client-rfq/creation/b' },
        { linkName: 'Create Firm Enquiry', linkRoute: '/v1/client-rfq/creation/f' },
      ]
    }
    let auctionData: sideBarMenu = {
      parent: 'AUCTION',
      child: [
        { linkName: 'Reverse Auction Listing', linkRoute: '/v1/client-auction/listing/r' },
        { linkName: 'Create Reverse Auction', linkRoute: '/v1/client-auction/creation/r' },
      ]
    }
    let serviceOrder: sideBarMenu = {
      parent: 'Service Order',
      child: [
        { linkName: "Service Order Listing", linkRoute: "/v1/client-service-order/listing" },
      ]
    }
    let invoice: sideBarMenu = {
      parent: "Invoice",
      child: [
        { linkName: "Pre-Shipment Invoice List", linkRoute: "/v1/pre-shipment-invoice-list" },
        { linkName: "Commercial Invoice List", linkRoute: "/v1/commerical-invoice/listing" },
        { linkName: "Create Commercial Invoice", linkRoute: "/v1/commerical-invoice/create" },
        { linkName: "Template List", linkRoute: "/v1/client-template/listing" },
        { linkName: "Create Template", linkRoute: "/v1/client-template/creation" },
      ]
    }
    let track: sideBarMenu = {
      parent: 'Track & Trace',
      child: [
        { linkName: "Vessel Schedule", linkRoute: "/v1/client-track-trace/vessel-schedule" },
        { linkName: "Track Container", linkRoute: "/v1/client-track-trace/container-track" },
        { linkName: "Flight Schedule", linkRoute: "/v1/client-track-trace/client-flight-schedule" },
        { linkName: "Track Air Package", linkRoute: "/v1/client-track-trace/air-package-track"},
        { linkName: "Create Shipment", linkRoute: "/v1/client-track-trace/shipment/creation" },
        { linkName: "Shipment Listing", linkRoute: "/v1/client-track-trace/shipment/listing" },
      ]
    }
    let report: sideBarMenu = {
      parent: "Report",
      child: [],
      parentRoute: '/v1/reports/listing'
    }
    let user: sideBarMenu = {
      parent: "User Management",
      child: [
        { linkName: "User List", linkRoute: "/v1/user/listing" },
        { linkName: "Role List", linkRoute: "/v1/user-roles/listing" },
      ]
    }
    let admin: sideBarMenu = {
      parent: "Admin",
      child: [
        // { linkName: "Configuartion Parameters", linkRoute: "/v1/admin/configuration-paramters" },
        { linkName: "Vendor On-boarding", linkRoute: "/v1/vendor-onboard/details" },
        { linkName: "Vendor Listing", linkRoute: "/v1/vendor/listing" },
        // { linkName: "Request Extension", linkRoute: "/v1/request-extension" }
      ]
    }
    this.dTypeTwo.push(dashboard, rfqData, auctionData, serviceOrder, invoice, track, report, user, admin);
    return this.dTypeTwo
  }

  initDTypeThreeMenu() {
    let dashboard: sideBarMenu = {
      parent: "Dashboard",
      child: [],
      parentRoute: '/v1/dashboard'
    }
    let rfqData: sideBarMenu = {
      parent: 'RFQ',
      child: [
        { linkName: 'Budgetory Enquiry Listing', linkRoute: '/v1/vendor-rfq/listing/b' },
        { linkName: 'Firm Enquiry Listing', linkRoute: '/v1/vendor-rfq/listing/f' },
      ]
    }
    let auctionData: sideBarMenu = {
      parent: 'AUCTION',
      child: [
        { linkName: 'Reverse Auction Listing', linkRoute: '/v1/vendor-auction/listing/r' },
      ]
    }
    let serviceOrder: sideBarMenu = {
      parent: 'Service Order',
      child: [
        { linkName: "Service Order Listing", linkRoute: "/v1/vendor-service-order/listing" },
      ]
    }
    let track: sideBarMenu = {
      parent: 'Track & Trace',
      child: [
        { linkName: "Vessel Schedule", linkRoute: "/v1/client-track-trace/vessel-schedule" },
        { linkName: "Track Container", linkRoute: "/v1/client-track-trace/container-track" },
        { linkName: "Flight Schedule", linkRoute: "/v1/client-track-trace/client-flight-schedule" },
        { linkName: "Track Air Package", linkRoute: "/v1/client-track-trace/air-package-track"},
        { linkName: "Shipment Listing", linkRoute: "/v1/client-track-trace/shipment/listing" },
      ]
    }
    let invoice: sideBarMenu = {
      parent: "Invoice",
      child: [
        { linkName: "Pre-Shipment Invoice List", linkRoute: "/v1/vendor-pre-shipment-invoice/listing"},
        { linkName: "Commercial Invoice List", linkRoute: "/v1/vendor-commercial-invoice/listing"}
      ]
    }
    let user: sideBarMenu = {
      parent: "User Management",
      child: [
        { linkName: "User List", linkRoute: "/v1/user/listing" },
        { linkName: "Role List", linkRoute: "/v1/user-roles/listing" },
      ]
    }
    this.dTypeThree.push(dashboard, rfqData, auctionData, serviceOrder, invoice, track, user);
    return this.dTypeThree;
  }

  showSideBar() {
    this.showHamburger = true;
    document.getElementById('sideBar').classList.add("sidebar-active");
  }

  hideSideBar() {
    this.showHamburger = false;
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  logout() {
    Swal.fire({
      title: 'Logout',
      text: 'Are you sure?',
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true
    })
      .then((response) => {
        console.log(response);
        if (response.isConfirmed) {
          this.authService.signOut().subscribe(
            (res) => {
              console.log(res);
              if(res.code == 202) {
                Swal.fire({
                  title: "Success",
                  timer: 5000,
                  icon: "success",
                  text: res.message
                });
                this.socketService.stopSSEService();
                sessionStorage.clear();
                localStorage.clear();
                this.router.navigate(['home']);
              }
            },
            (error) => {
              console.log(error);
            },
          );
        }
      });
  }

  collapseExpandMenu(navParentName: string) {
    if (this.dType == 1) {
      this.dTypeOne.forEach(
        (navParent) => {
          let parentAnchor = document.getElementById(navParent.parent);
          let childAnchor = document.getElementById(navParent.parent + 'child');
          parentAnchor.classList.remove('collapsed', 'nav-open-color');
          childAnchor.classList.remove('show');
          if (navParent.parent == navParentName) {
            parentAnchor.classList.add('collapsed', 'nav-open-color');
            childAnchor.classList.add('show');
          }
          else {
            parentAnchor.classList.remove('collapsed');
            childAnchor.classList.remove('show');
          }
        }
      );
    }
    else if (this.dType == 2) {
      this.dTypeTwo.forEach(
        (navParent) => {
          let parentAnchor = document.getElementById(navParent.parent);
          let childAnchor = document.getElementById(navParent.parent + 'child');
          parentAnchor.classList.remove('collapsed', 'nav-open-color');
          childAnchor.classList.remove('show');
          if (navParent.parent === navParentName) {
            parentAnchor.classList.add('collapsed', 'nav-open-color');
            childAnchor.classList.add('show');
          }
        }
      );
    }
    else if (this.dType == 3) {
      this.dTypeThree.forEach(
        (navParent) => {
          let parentAnchor = document.getElementById(navParent.parent);
          let childAnchor = document.getElementById(navParent.parent + 'child');
          parentAnchor.classList.remove('collapsed', 'nav-open-color');
          childAnchor.classList.remove('show');
          if (navParent.parent == navParentName) {
            parentAnchor.classList.add('collapsed', 'nav-open-color');
            childAnchor.classList.add('show');
          }
        }
      );
    }
    else {
      console.log("NO SIDE BAR");
    }
  }

  goToProfile(){
    this.router.navigate(['v1/user/profile']);
  }

  getUserById(id: any) {
    this.userService.getUserById(id).subscribe(
      (response) => {
        if (response.id != null) {
          if(response.artifact != null) {
            this.isImgDefault = true;
            this.dataURItoBlob(response.artifact);
          }
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  dataURItoBlob(data) {
    const linkSource = data.content;
    this.userImage = linkSource;
  }

}
