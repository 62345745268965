<div class="row">
    <div class="col-lg-12">
        <ngx-file-drop dropZoneClassName="file-drop-3" dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" [multiple]="multipleFileBrowse"
            [accept]="allowedFileTypes">
            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                <div class="text-center justify-content-between">
                    <div class="col-lg-12 mt-4">
                        <i class="far fa-file-alt fa-3x"></i>
                    </div>
                    <div class="col-md-12">
                        {{dropZoneLabel}}
                    </div>
                    <div class="col-lg-12">
                        <button class="btn btn-success" type="button" (click)="openFileSelector()">Browse Files</button>
                    </div>
                </div>
            </ng-template>
        </ngx-file-drop>
    </div>
</div>


<div class="table-responsive mt-4">
    <table class="table">
        <thead>
            <tr>
                <th>Sr.No</th>
                <th>File Name</th>
                <th>Label</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of files; index as i">
                <td>{{ i + 1 }}</td>
                <td>{{ item.name }}</td>
                <td>
                    <input type="text" (keyup)="onLabelChange()" [(ngModel)]="item.label">
                </td>
                <td>
                    <button *ngIf="item.size > maxFileSize">
                        <i class="fas fa-ban"></i>
                    </button>
                    <button class="text-primary" *ngIf="item[artifactKey]">
                        <i class="fa fa-check" aria-hidden="true"></i>
                    </button>
                    <button *ngIf="(item[artifactKey]); else localDelete">
                        <i class="fa fa-trash" (click)="deleteServerArtifact(item,i)"></i>
                    </button>
                    <ng-template #localDelete>
                        <button class="text-primary">
                            <i class="fa fa-trash" (click)="deleteDocument(i)"></i>
                        </button>
                    </ng-template>
                </td>
            </tr>
        </tbody>
    </table>
</div>