<app-header></app-header>
<section class="about-wrapper text-dark py-8" id="about-us">
    <div class="container-fluid p-lg-start-end">
        <div class="row justify-content-center">
            <div class="mat-elevation-z8 table-responsive">
                <h4 class="text-center pt-2">HS Codes</h4>
                <table class="table table-borderless table-striped table-footer-dark table-head-dark mt-3"
                    matSort mat-table [dataSource]="dataSource">
                    
                    <ng-container matColumnDef="referenceLink">
                        <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header>Reference Link </th>
                        <td mat-cell *matCellDef="let row">
                            <a target="_blank" href="{{row.referenceLink}}">{{row.referenceLink}}</a>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="country">
                        <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header> Country </th>
                        <td mat-cell *matCellDef="let row"> {{row.country.name}} </td>
                    </ng-container>


                    <ng-container matColumnDef="artifacts">
                        <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header> Artifacts </th>
                        <td mat-cell *matCellDef="let row">
                            <div class="row">
                                <div *ngFor="let artifact of row.artifacts"  class="col-md-2">
                                    <button class="" title="{{ artifact.label }}" (click)="dataURItoBlob(artifact)">
                                        <i class="fa fa-download"></i>
                                    </button>
                                </div>
                            </div>
                        </td>
                    </ng-container>

                    <tr class="bg-dark" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator [pageSizeOptions]="[25,50,100]" aria-label="Select page of users"
                    [length]="length" (page)="onPageChange($event)">
                </mat-paginator>
            </div>  
        </div>
    </div>
</section>