import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuctionService } from 'src/app/helpers/auction.service';
import { EnquiryService } from 'src/app/helpers/enquiry.service';
import { MasterTableService } from 'src/app/helpers/master-table.service';
import { OnboardService } from 'src/app/helpers/onboard.service';
import Swal from 'sweetalert2';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { CountryCode, CountryCodes } from 'src/app/helpers/country_code_picker';
import { SocketService } from 'src/app/helpers/socket.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-client-auction',
  templateUrl: './client-auction.component.html',
  styleUrls: ['./client-auction.component.css']
})
export class ClientAuctionComponent implements OnInit {

  auctionCreation!: FormGroup;
  auctionForm: FormGroup;
  enquiryId: any;
  air_mode_shipment: boolean;
  sea_mode_shipment: boolean;
  public currencyPicker = [];
  hide_btn: Boolean = true;

  public container_type = []
  public mode_of_shipment = []
  public type_cargo = []
  public trade_type = []
  public nature_of_movement = []
  public nature_goods: any = [];
  public pickup_terms = []
  public dropoff_terms = []
  public type_packing = []
  public originDestination = [];

  public container_load_type = [
    { 'id': 1, 'value': 'Full Container Load' },
    { 'id': 2, 'value': 'Less than Container Load' },
    { 'id': 3, 'value': 'Both' },
    { 'id': 4, 'value': 'Break Bulk' }
  ]
  public storing_type = [
    { 'code': 's', 'value': 'Stackable' },
    { 'code': 'n', 'value': 'Non-Stackable' }
  ]

  enquiryIdBool: Boolean = false;
  auctionUpdateBool: Boolean = false;
  filteredNatureOfGoods = [];

  dashboardBool: Boolean = true;
  auctionConfigBool: Boolean = false;
  auctionDetailBool: Boolean = false;
  auctionVendorsBool: Boolean = false;
  documentList: Array<any> = [];
  maxFileSize: number = 2000000;
  maxSizeImage: number = 1000000;
  auctionId: any;
  showPickupAddress: boolean = false;
  delivaryAddress: Boolean = false;
  showTerminal: Boolean = false;
  pickupAddressBoolean: Boolean = false;
  deliveryAddressBoolean: Boolean = false;

  containerSize: Array<any> = [
    { size: "20 ft" },
    { size: "40 ft" }
  ]
  dimensionsUOM: Array<any> = [
    { name: "CM" },
    { name: "MM" },
    { name: "IN" },
    { name: "FT" },
    { name: "MTR" },
  ]
  grossWeightUom: Array<any> = [
    { name: "KG" },
    { name: "LBS" },
    { name: "MT" }
  ]
  grossWeightUomAir: Array<any> = [
    { name: "KG" },
    { name: "LBS" }
  ]
  fileName: string;

  public countryCodes: Array<CountryCode> = CountryCodes;
  assignedVendors: Array<any> = [];

  samePortError: Boolean = false;
  breakbulkBool: boolean = false;
  fullContainerLoadBool: Boolean = false;
  lessContainerLoadBool: Boolean = false;
  breakBulkBoolOption: Boolean = false;
  public vendorQuotesForEnquiryById: Array<any> = []
  public quoteAmount: any;
  breakBulkFile: Array<any> = []
  breakbulkFileBool: Boolean = false;
  invalidOfferDate : Boolean = false;

  totalVolumeWeightAIR: number = 0.0;
  finalAirWeightUom: string;
  totalGrossWeightAIR: number;
  finalAirWeightBool: boolean;
  totalVolumeWeightLCL: number = 0.0;
  finalSeaWeightUom: string;
  totalGrossWeightLCL: number;
  finalSeaWeightBool: boolean;
  totalBreakBulkVolume: number = 0.0;
  totalBreakBulkWeight: number = 0.0;

  minimumDecrementUnitArray = [
    {value: "PERCENT"},
    {value: "AMOUNT"},
  ]
  auctionDurationUnitArray = [
    {value: "MINUTES" },
    {value: "HOURS" },
    // {value: "DAYS" },
  ]

  showStandingArray = [
    {code: "L1 ONLY" ,value: "L1_ONLY"},
    {code: "ALL", value: "ALL"},
  ]
  minimumDecrementBool: Boolean = false;
  targetPriceBool: Boolean = false;
  basePriceBool: Boolean = false;
  timeExtensionBool: Boolean = false;
  page: number = 0;
  size: number = 10;
  pickUpIncoTermsArray: Array<any> = [];
  dropOffIncoTermsArray: Array<any> = [];
  dataSource: MatTableDataSource<any>;
  vendorsLength: number = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  serchFilter: Boolean = false;
  nodata: Boolean = true;
  displayedColumns: string[] = ['actions', 'vendorName', 'businessType'];
  vendorBusinessType: number;
  vendorBusinessTypeList: Array<any> = [];
  math = Math;
  isThirdCountrySelected: boolean = false;
  destinationPorts: Array<any> = [];
  loadingPorts: Array<any> = [];
  countries: Array<any> = [];

  searchOriginValue: string
  searchDestinationValue: string
  backDatedAuctionBool: Boolean = false;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private enquiryService: EnquiryService,
    private auctionService: AuctionService, private router: Router, private datePipe: DatePipe,
    private masterTableService: MasterTableService, private onBoardService: OnboardService, private socketService: SocketService) { 
      this.gotoTop();
    }

  ngOnInit(): void {

    this.auctionForm = this.fb.group({
      description: ['', Validators.compose([Validators.required, Validators.maxLength(300)])],
      currency: this.fb.group({
        id: ['', Validators.required]
      }),
      remark: ['', Validators.compose([Validators.required, Validators.maxLength(300)])],
      reason: [''],
      basePrice: ['', Validators.required],
      targetPrice: [0],
      minimumDecrement: [0, Validators.required],
      minimumDecrementUnit: ['', Validators.required],
      auctionDate: ['', Validators.required],
      auctionDurationUnit: ['', Validators.required],
      durationinMinutes: ['', Validators.required],
      limitMaxattemptsPerVendor: [null , Validators.required],
      showStanding: [false, Validators.required],
      assignedVendors: this.fb.array([]),
      type: [''],
      auctionDetailsForm: this.fb.group({
        assignedVendors: this.fb.array([]),
        breakBulks: this.fb.array([]),
        cargoReadinessDate: ['', Validators.required],
        commodity: ['', Validators.required],
        containerLoadType: ['', Validators.required],
        containers: this.fb.array([]),
        createDate: [new Date().toISOString().substring(0, 10)],
        deliveryIncoterms: ['', Validators.required],
        description: ['', Validators.required],
        destination: ['', Validators.required],
        hsCode: [''],
        internalReferenceNumber: [''],
        lastSubmissionDate: ['', Validators.required],
        modeOfShipment: ['', Validators.required],
        natureOfGoods: [''],
        natureOfMovement: ['', Validators.required],
        number: [''],
        origin: ['', Validators.required],
        pickUpIncoterms: ['', Validators.required],
        projectCode: [''],
        projectName: [''],
        shipmentPackages: this.fb.array([]),
        storingType: ['', Validators.required],
        supplierName: [''],
        tradeType: ['', Validators.required],
        type: ['f'],
        typeOfCargo: ['', Validators.required],
        typeOfPacking: [''],
        break_bulk_label: [false],
        poNumber: [''],
        chargableWeight: [''],
        chargableWeightUOM: [''],
        totalGrossWeight: [''],
        totalGrossWeightUOM: [''],
        volumeInCBM: [''],
        demurrage: [''],
        detention: ['']
      }),
      terms: this.fb.array([]),
      extendTimeBool: [false, Validators.required],
      extendedTime: [''],
      bidSubmitDurationInLastMinutes: ['' ],
      auctionTimeExtensionLimit: ['' ],
    });

    this.enquiryId = this.route.snapshot.paramMap.get("id");
    let typeOfAuction = this.route.snapshot.paramMap.get('type');
    // Optional Query Param Route for edit Auction
    this.auctionId = this.route.snapshot.queryParamMap.get('id');
    if (this.auctionId != null) {
      this.fetchAuctionById(this.auctionId);
      this.getAllAuctionArtifacts(this.auctionId);
    }
    else if(this.enquiryId != null || undefined){
      this.fetchEnquiryById(this.enquiryId);
      this.getAllEnquiryArtifacts(this.enquiryId)
    }
    else {
      this.getAllVendors();
      this.addTermsCondition();
    }
    if (typeOfAuction == "f") {
      this.auctions.type.patchValue(typeOfAuction);
    }
    else if (typeOfAuction == "r") {
      this.auctions.type.patchValue(typeOfAuction);
    }
    else {
      // NO type of Auction
    }
    this.getMasterTablesSessionStorage();
    // this.socketService.connectTOServerPopUpFuction();
    this.gotoTop();
  }
  get auctions() { return this.auctionForm.controls; }
  get _detail() { return this.auctions.auctionDetailsForm as FormGroup; }
  get details() { return this._detail.controls; }
  get container_details() { return this._detail.get('containers') as FormArray; }
  get package_details() { return this._detail.get('shipmentPackages') as FormArray; }
  get break_bulk() { return this._detail.get('breakBulks') as FormArray; }
  get vendors() { return this.auctionForm.get('assignedVendors') as FormArray; }
  get termsConditions() { return this.auctions.terms as FormArray; }
  get currency(){return this.auctionForm.get('currency') as FormGroup; }

  //required pickup person name for EXW and FCA in pickupIncoTerms
  onChangePickupInco() {
    let pickUpValue = this.details.pickUpIncoterms.value
    if (pickUpValue == 'EXW' || pickUpValue == 'FCA') {
      this._detail.addControl('pickupPersonName', new FormControl('', Validators.required))
      this._detail.addControl('pickupPersonAddress', new FormGroup({}))
      let pickupAddress = this._detail.get('pickupPersonAddress') as FormGroup;
      pickupAddress.addControl('label', new FormControl('Pickup Address'))
      pickupAddress.addControl('street1', new FormControl(''))
      pickupAddress.addControl('street2', new FormControl())
      pickupAddress.addControl('city', new FormControl())
      pickupAddress.addControl('state', new FormControl())
      // pickupAddress.addControl('country', new FormControl())
      pickupAddress.addControl('country', new FormGroup({}));
      let country = pickupAddress.get('country') as FormGroup;
      country.addControl('id', new FormControl());
      pickupAddress.addControl('zipCode', new FormControl())
      this.showPickupAddress = true;
    }
    else {
      this._detail.removeControl('pickupPersonName')
      this._detail.removeControl('pickupPersonAddress')
      this.showPickupAddress = false;
      this._detail.removeControl('pickupPersonName')
      this._detail.removeControl('pickupPersonAddress')
    }
  }

  onChangeDeliveryInco() {
    let delivaryTermvalue = this.details.deliveryIncoterms.value
    console.log(delivaryTermvalue);
    if (delivaryTermvalue == 'DAP' || delivaryTermvalue == 'DDP' || delivaryTermvalue == "DPU") {
      this.delivaryAddress = true;
      this._detail.addControl('deliveryPersonName', new FormControl('', Validators.required))
      this._detail.addControl('deliveryPersonAddress', new FormGroup({}))
      let pickupAddress = this._detail.get('deliveryPersonAddress') as FormGroup;
      pickupAddress.addControl('label', new FormControl('Delivery Address'))
      pickupAddress.addControl('street1', new FormControl(''))
      pickupAddress.addControl('street2', new FormControl())
      pickupAddress.addControl('city', new FormControl())
      pickupAddress.addControl('state', new FormControl())
      // pickupAddress.addControl('country', new FormControl())
      pickupAddress.addControl('country', new FormGroup({}));
      let country = pickupAddress.get('country') as FormGroup;
      country.addControl('id', new FormControl());
      pickupAddress.addControl('zipCode', new FormControl())
      this.showTerminal = false;
    }
    else if (delivaryTermvalue == 'DAT') {
      this._detail.addControl('destinationTerminal', new FormControl('', Validators.required))
      this._detail.removeControl('deliveryPersonName')
      this._detail.removeControl('deliveryPersonAddress')
      this.showTerminal = true;
      this.delivaryAddress = false;
    }
    else {
      this._detail.removeControl('destinationTerminal')
      this._detail.removeControl('deliveryPersonName')
      this._detail.removeControl('deliveryPersonAddress')
      this.delivaryAddress = false;
      this.showTerminal = false;
    }
    console.log(this.auctionForm.value);
  }

  changeModeOfShipment() {
    // If modeOfShipment is "SEA"
    if (this._detail.get('modeOfShipment')?.value == 2) {
      this.package_details.clear();
      this.container_details.clear();
      this.fullContainerLoadBool = true;
      this.breakBulkBoolOption = true;
      this._detail.addControl('containerLoadType', new FormControl('', Validators.required));
      this._detail.get('containerLoadType').patchValue(1);
      this._detail.addControl('detention', new FormControl('', Validators.required))
      this._detail.addControl('break_bulk_label', new FormControl(false, Validators.required));
      this.addSeaRow();
      this.sea_mode_shipment = true;
      this.air_mode_shipment = false;
    }
    // If modeOfShipment is "AIR"
    else if (this._detail.get('modeOfShipment')?.value == 1) {
      this.package_details.clear();
      this.container_details.clear();
      this.fullContainerLoadBool = false;
      this.breakbulkFileBool = false;
      this.addAirRow();
      this._detail.removeControl('containerLoadType');
      this._detail.removeControl('break_bulk_label');
      this._detail.removeControl('detention');
      this.air_mode_shipment = true;
      this.sea_mode_shipment = false;
    }
    // If modeOfShipment is "ROAD"
    else if (this._detail.get('modeOfShipment')?.value == 3) {
      this.container_details.clear();
      this.package_details.clear();
      this.air_mode_shipment = false;
      this.sea_mode_shipment = false;
    }
    // If modeOfShipment is "RAIL"
    else if (this._detail.get('modeOfShipment')?.value == 4) {
      this.container_details.clear();
      this.package_details.clear();
      this.air_mode_shipment = false;
      this.sea_mode_shipment = false;
    }
  }

  changeTypeCargo(formControl: string) {
    let controller = this._detail.get(formControl).value
    this.filteredNatureOfGoods = this.filterNatureOfGoods(controller);
  }
  filterNatureOfGoods(id) {
    return this.nature_goods.filter(
      (item) => {
        return item.typeOfCargoDto.id == id
      }
    );
  }
  openBreakBulkTable() {
    this.breakbulkBool = true;
    this.addBreakBulk();
  }
  closeBreakBulkTable() {
    this.breakbulkBool = false;
    this.break_bulk.clear();
  }

  addBreakBulk() {
    this.break_bulk.push(this.break_bulk_init());
  }
  deleteBulkRow(index: number) {
    this.break_bulk.removeAt(index)
  }

  break_bulk_init() {
    return this.fb.group({
      totalnetWeight: [''],
      uom: [''],
      totalGrossWeight: [''],
      totalVolume: [''],
    })
  }

  container_sea_init() {
    return this.fb.group({
      quantity: [''],
      size: [''],
      uom: [''],
      weight: [''],
      type: ['']
    });
  }

  addSeaRow() {
    this.container_details.push(this.container_sea_init())
  }

  addLCLRow() {
    this.package_details.push(this.sea_lcl_init());
  }

  deleteSeaRow(index: number) {
    this.container_details.removeAt(index);
  }
  deleteSeaHCL(index: number) {
    this.package_details.removeAt(index);
    this.calculateTotalVolume();
  }

  package_air_init() {
    return this.fb.group({
      quantity: [''],
      length: [''],
      width: [''],
      height: [''],
      dimensionsUOM: [''],
      grossWeight: [''],
      weightUOM: [''],
      totalVolume: ['']
    });
  }

  sea_lcl_init() {
    return this.fb.group({
      quantity: [''],
      type: [''],
      length: [''],
      width: [''],
      height: [''],
      dimensionsUOM: [''],
      grossWeight: [''],
      weightUOM: [''],
      totalVolume: ['']
    });
  }

  addAirRow() {
    this.package_details.push(this.package_air_init());
  }
  deleteAirRow(index: number) {
    this.package_details.removeAt(index);
    this.calculateAirVolume();
  }

  createAuction() {
    // let submitBool = false;
    let submitBool = this.validateFilesAtSubmit();
    for (let index = 0; index < this.breakBulkFile.length; index++) {
      const element = this.breakBulkFile[index];
      if (element.label == "Package Slip") {
        this.uploadPackageSlip(index);
      }
    }
    if (this.auctionForm.invalid) {
      let x = this.findInvalidControls();
      console.log(x);
      Swal.fire({
        titleText: "Warning",
        icon: "warning",
        text: "Please fill the field " + x[0],
        timer: 5000
      })
    }
    else if(submitBool == false && !this.auctionForm.invalid && !this._detail.invalid) {
      let auctionDate = new Date(this.auctions.auctionDate.value)
      let lastSubmissionDate = new Date(this.details.lastSubmissionDate.value);
      this._detail.get('lastSubmissionDate').patchValue(lastSubmissionDate);
      let data = {
        "assignedVendors": this.auctionForm.get('assignedVendors')?.value,
        "terms": this.termsConditions.value,
        "baseprice": this.auctions.basePrice.value,
        "targetPrice": this.auctions.targetPrice.value,
        "currency": this.auctions.currency.value,
        "description": this.auctions.description.value,
        "durationinMinutes": this.auctions.durationinMinutes.value,
        "auctionDurationUnit": this.auctions.auctionDurationUnit.value,
        "limitMaxattemptsPerVendor": this.auctions.limitMaxattemptsPerVendor.value,
        "minimumDecrement": this.auctions.minimumDecrement.value,
        'minimumDecrementUnit': this.auctions.minimumDecrementUnit.value,
        "remark": this.auctions.remark.value,
        "showStanding": this.auctions.showStanding.value,
        "auctionDate": auctionDate,
        "enquiryDto": this._detail?.value,
        "type": this.auctions.type.value,
        "extendTimeBool": this.auctions.extendTimeBool.value,
        "extendedTime": this.auctions.extendedTime.value,
        "bidSubmitDurationInLastMinutes": this.auctions.bidSubmitDurationInLastMinutes.value,
        "auctionTimeExtensionLimit": this.auctions.auctionTimeExtensionLimit.value
      }
      this.auctionService.createAuction(data).subscribe(
        (response) => {
          if (response.code == 201) {
            Swal.fire({
              titleText: "Success",
              timer: 5000,
              icon: "success",
              text: response.message
            })
            this.auctionId = response.entityId;
            for (let index = 0; index < this.documentList.length; index++) {
              this.uploadFile(index);
            }
            this.router.navigate(['v1/client-auction/listing/', this.auctions.type.value]);
          }
        },
        (error) => {
          console.log(error);
          let _error = error.error;
          if(_error.errorCode == 412){
            Swal.fire({
              titleText: "Error",
              icon: "error",
              timer: 5000,
              text: _error.errorMessage
            });
          }
          else{
            Swal.fire({
              titleText: "Error",
              icon: "error",
              timer: 5000,
              text: "Something went wrong"
            })
          }
          // console.log(this.auctionForm.value)
        },
      )
    }
  }

  getAllVendors() {
    let vendorsList = [];
    this.onBoardService.getAllVendors(this.page, this.size, "COMPLETE", this.vendorBusinessType).subscribe(
      (response) => {
        if (response.content != null) {
          this.vendorsLength = response.totalElements;
          response.content.forEach((vendor) => {
            vendor.flag = false;
            vendorsList.push(vendor);
          });
          vendorsList.forEach((x) => {
            this.assignedVendors.filter((data) => {
              if (x.id === data.id) {
                x.flag = true;
              }
            })
          });
          if(vendorsList.length == this.assignedVendors.length){
            var actions = document.getElementById('actions') as HTMLInputElement;
            actions.checked = true;
          }
          this.dataSource = new MatTableDataSource(vendorsList);
          this.dataSource.sort = this.sort;
        }
        else {
          window.alert("NO Vendors in your system!");
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.nodata = false;
    this.serchFilter = true;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onPageChange(event: PageEvent) {
    this.size = event.pageSize;
    this.page = event.pageIndex;
    this.getAllVendors();
  }

  filterVendorData(){
    if(this.vendorBusinessType != null){
      this.getAllVendors();
    }
  }

  addVendors(id: number, index: number, flag: boolean) {
    if (flag == true) {
      this.vendors.push(this.pushVendorsId(id));
    }
    else {
      this.removeVendorsId(index);
    }

    // ToDo, for creation remaining.
    var actions = document.getElementById('actions') as HTMLInputElement;
    if(this.auctionUpdateBool){
      if(this.vendors.length == this.assignedVendors.length){
        if(actions.checked == false){
          actions.checked = true;
        }
      }
      else{
        console.log("NOT SAME LENGTH", this.vendors.length);
        if(actions.checked){
          actions.checked = false;
        }
      }
    }
    else if(this.enquiryIdBool){
      if(this.vendors.length == this.assignedVendors.length){
        if(actions.checked == false){
          actions.checked = true;
        }
      }
      else{
        if(actions.checked){
          actions.checked = false;
        }
      }
    }
    // ToDo, while creating direct auction
    else{
      console.log("ELSE ");
    }
  }
  pushVendorsId(id: number) {
    return this.fb.group({
      id: [id]
    })
  }
  removeVendorsId(index: number) {
    this.vendors.removeAt(index);
  }

  prePopulateBreakBulk(item: any): FormGroup {
    return this.fb.group({
      id: [item.id],
      totalnetWeight: [item.totalnetWeight],
      totalGrossWeight: [item.totalGrossWeight],
      totalVolume: [item.totalVolume],
      uom: [item.uom]
    })
  }

  prePopulateContainer(item: any): FormGroup {
    return this.fb.group({
      id: [item.id],
      quantity: [item.quantity],
      size: [item.size],
      uom: [item.uom],
      weight: [item.weight],
      type: [item.type]
    })
  }

  prePopulatePackages(item: any): FormGroup {
    return this.fb.group({
      id: [item.id],
      quantity: [item.quantity],
      length: [item.length],
      width: [item.width],
      height: [item.height],
      dimensionsUOM: [item.dimensionsUOM],
      grossWeight: [item.grossWeight],
      weightUOM: [item.weightUOM],
      totalVolume: [item.totalVolume],
      type: [item.type]
    })
  }

  prePopulateLCL(item: any): FormGroup {
    return this.fb.group({
      id: [item.id],
      quantity: [item.quantity],
      type: [item.type],
      length: [item.length],
      width: [item.width],
      height: [item.height],
      dimensionsUOM: [item.dimensionsUOM],
      grossWeight: [item.grossWeight],
      weightUOM: [item.weightUOM],
      totalVolume: [item.totalVolume]
    })
  }

  prePopulateTermsConditions(data: any): FormGroup {
    return this.fb.group({
      content: [data.content],
      label: [data.label]
    })
  }

  firstPage() {
    if (!this.auctions.description.invalid && !this.auctions.currency.invalid && !this.auctions.remark.invalid && !this.auctions.basePrice.invalid && !this.auctions.minimumDecrement.invalid && !this.auctions.auctionDate.invalid && !this.auctions.durationinMinutes.invalid && !this.auctions.limitMaxattemptsPerVendor.invalid) {
      this.dashboardBool = false;
      this.auctionConfigBool = true;
      let currentPage = document.getElementById('item-0');
      let nextPage = document.getElementById('item-1');
      let _page = document.getElementById("item-4");
      let _next = document.getElementById("item-5");
      let _list = document.getElementById("list-item-1");
      if (currentPage.classList.contains("active") && _page.classList.contains("active")) {
        _page.classList.remove("active");
        currentPage.classList.remove('active');
        _list.classList.add("active");
        nextPage.classList.add("active");
        _next.classList.add("active");
        this.gotoTop();
      }
      else {
        console.log("NOTHING");
      }
    } else {
      this.auctions.description.markAsDirty(); this.auctions.currency.markAsDirty(); this.auctions.remark.markAsDirty(); this.auctions.basePrice.markAsDirty(); this.auctions.minimumDecrement.markAsDirty(); this.auctions.auctionDate.markAsDirty(); this.auctions.durationinMinutes.markAsDirty(); this.auctions.limitMaxattemptsPerVendor.markAsDirty();
      Swal.fire({
        title: 'Warning',
        text: 'Please fill all the mandatory fields',
        icon: 'warning',
        timer: 5000
      })
    }
  }

  secondPage() {
    if (!this.details.commodity.invalid && !this.details.deliveryIncoterms.invalid && !this.details.description.invalid && !this.details.destination.invalid && !this.details.modeOfShipment.invalid && !this.details.natureOfMovement.invalid && !this.details.origin.invalid && !this.details.pickUpIncoterms.invalid && !this.details.storingType.invalid && !this.details.tradeType.invalid && !this.details.typeOfCargo.invalid) {
      if (this.details.modeOfShipment.value == 2) {
        if (!this.details.containerLoadType.invalid) {
          this.pageSecondToThird();
        } else {
          this.details.containerLoadType.markAsDirty();
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: "Please Select Container Load Type",
            timer: 5000
          })
        }
      }
      else if (this.samePortError == true) {
        Swal.fire({
          titleText: "Warning",
          icon: "warning",
          text: "Same Origin & Destination Port Not Allowed",
          timer: 5000
        })
      } else if (this.details.pickUpIncoterms.value == 'EXW' || this.details.pickUpIncoterms.value == 'FCA') {
        if (this.details.pickupPersonName.invalid) {
          this.details.pickupPersonName.markAsDirty();
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: "Please Fill Pickup Person Name",
            timer: 5000
          })
        } else {
          this.pageSecondToThird()
        }
      } else if (this.details.deliveryIncoterms.value == 'DAP' || this.details.deliveryIncoterms.value == 'DDP' || this.details.deliveryIncoterms.value == 'DPU') {
        if (this.details.deliveryPersonName.invalid) {
          this.details.deliveryPersonName.markAsDirty();
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: "Please Fill Delivery Person Name",
            timer: 5000
          })
        } else {
          this.pageSecondToThird()
        }
      } else if (this.details.deliveryIncoterms.value == 'DAT') {
        if (this.details.destinationTerminal.invalid) {
          this.details.destinationTerminal.markAsDirty();
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: "Please Fill Destination Terminal",
            timer: 5000
          })
        } else {
          this.pageSecondToThird()
        }
      }
      else {
        this.pageSecondToThird()
      }
    } else {
      this.details.commodity.markAsDirty(); this.details.deliveryIncoterms.markAsDirty(); this.details.description.markAsDirty(); this.details.destination.markAsDirty(); this.details.modeOfShipment.markAsDirty(); this.details.natureOfMovement.markAsDirty(); this.details.origin.markAsDirty(); this.details.pickUpIncoterms.markAsDirty(); this.details.storingType.markAsDirty(); this.details.tradeType.markAsDirty(); this.details.typeOfCargo.markAsDirty(); this.details.cargoReadinessDate.markAsDirty(); this.details.lastSubmissionDate.markAsDirty();
      Swal.fire({
        title: 'Warning',
        text: 'Please fill all the mandatory fields',
        icon: 'warning',
        timer: 5000
      })
    }
  }

  thirdPage() {
    this.auctionDetailBool = false;
    this.auctionVendorsBool = true;
    let currentPage = document.getElementById('item-2');
    let nextPage = document.getElementById('item-3');
    let _page = document.getElementById("item-6");
    let _next = document.getElementById("item-7");
    let _list = document.getElementById("list-item-3");
    if (currentPage.classList.contains("active") && _page.classList.contains("active")) {
      _page.classList.remove("active");
      currentPage.classList.remove('active');
      _list.classList.add("active");
      nextPage.classList.add("active");
      _next.classList.add("active");
      this.gotoTop();
    }
    else {
      console.log("NOTHING");
    }
  }

  fetchEnquiryById(id) {
    this.enquiryService.fetchEnquiryById(id).subscribe(
      (response) => {
        if (response != null) {
          this.assignedVendors = response.assignedVendors;
          this.getAllVendors();
          console.log(response);
          this.hide_btn = false;
          this.auctionForm.get('description')?.patchValue(response.description);
          this.currency.get('id').patchValue(response.currency.id);
          this.auctionForm.get('basePrice').patchValue(response.lowestQuote);
          this.enquiryIdBool = true;
          this._detail.addControl('id', new FormControl());
          this._detail.get('id')?.patchValue(response.id);
          this._detail.get('cargoReadinessDate')?.patchValue(response.cargoReadinessDate);
          this._detail.get('commodity')?.patchValue(response.commodity);
          let enquiryDate = this.datePipe.transform(response.enquiryDate, 'yyyy-MM-dd');
          this._detail.get('createDate')?.patchValue(enquiryDate);
          this._detail.get('description')?.patchValue(response.description);
          this._detail.get('internalReferenceNumber')?.patchValue(response.internalReferenceNumber);
          this._detail.get('modeOfShipment')?.patchValue(response.modeOfShipment);
          this._detail.get('natureOfGoods')?.patchValue(response.natureOfGoods);
          this._detail.get('natureOfMovement')?.patchValue(response.natureOfMovement);
          this.changeNatureOfMovement();
          this._detail.get('number')?.patchValue(response.number);

          this.searchOriginValue = response.originPortName
          this.getSearchResultsOrigin();
          this._detail.get('origin')?.patchValue(response.origin);

          this.searchDestinationValue = response.destinationPortName
          this.getSearchResultDestination();
          this._detail.get('destination')?.patchValue(response.destination);
          let lastSubmissionDate = this.datePipe.transform(response.lastSubmissionDate, 'yyyy-MM-ddTHH:mm');
          this._detail.get('lastSubmissionDate').patchValue(lastSubmissionDate);
          this._detail.get('projectCode')?.patchValue(response.projectCode);
          this._detail.get('projectName')?.patchValue(response.projectName);
          this._detail.get('storingType')?.patchValue(response.storingType);
          this._detail.get('typeOfCargo')?.patchValue(response.typeOfCargo);
          this._detail.get('typeOfPacking')?.patchValue(response.typeOfPacking);
          this._detail.get('type')?.patchValue(response.type);
          this._detail.addControl('status', new FormControl);
          this._detail.get('status')?.patchValue(response.status);
          this._detail.get('poNumber')?.patchValue(response.poNumber);
          this._detail.get('demurrage')?.patchValue(response.demurrage);
          for (let index = 0; index < response.assignedVendors.length; index++) {
            const vendor = response.assignedVendors[index];
            this.addVendors(vendor.id, index, true);
          }
          this._detail.get('deliveryIncoterms')?.patchValue(response.deliveryIncoterms);
          if (this.details.deliveryIncoterms.value == "DAP" || this.details.deliveryIncoterms.value == "DDP" || this.details.deliveryIncoterms.value == "DPU") {
            this.showTerminal = false;
            if (response.deliveryPersonAddress != null) {
              this.delivaryAddress = true;
              this._detail.addControl('deliveryPersonName', new FormControl(response.deliveryPersonName));
              this._detail.addControl('deliveryPersonAddress', new FormGroup({}))
              let deliveryAddress = this._detail.get('deliveryPersonAddress') as FormGroup;
              deliveryAddress.addControl('label', new FormControl('Delivery Address'))
              deliveryAddress.addControl('street1', new FormControl(response.deliveryPersonAddress.street1))
              deliveryAddress.addControl('street2', new FormControl(response.deliveryPersonAddress.street2))
              deliveryAddress.addControl('city', new FormControl(response.deliveryPersonAddress.city))
              deliveryAddress.addControl('state', new FormControl(response.deliveryPersonAddress.state))
              // deliveryAddress.addControl('country', new FormControl(response.deliveryPersonAddress.country))
              deliveryAddress.addControl('country', new FormGroup({}));
              let country = deliveryAddress.get('country') as FormGroup;
              country.addControl('id', new FormControl(response.deliveryPersonAddress?.country?.id));
              deliveryAddress.addControl('zipCode', new FormControl(response.deliveryPersonAddress.zipCode))
            }
            // else {
            //   this.delivaryAddress = false;
            // }
          }
          else if (this.details.deliveryIncoterms.value == "DAT") {
            this.delivaryAddress = false;
            if (response.destinationTerminal != null) {
              this.showTerminal = true;
              this._detail.addControl('destinationTerminal', new FormControl(response.destinationTerminal));
            }
            else {
              this.showTerminal = false;
            }
            this._detail.removeControl('deliveryPersonName')
            this._detail.removeControl('deliveryPersonAddress')
          }
          else {
            this._detail.removeControl('destinationTerminal')
            this._detail.removeControl('deliveryPersonName')
            this._detail.removeControl('deliveryPersonAddress')
            this.delivaryAddress = false;
            this.showTerminal = false;
          }

          this._detail.get('pickUpIncoterms')?.patchValue(response.pickUpIncoterms);
          if (this.details.pickUpIncoterms.value == "EXW" || this.details.pickUpIncoterms.value == "FCA") {
            this.showPickupAddress = true;
            if (response.pickupPersonName != null) {
              this._detail.addControl('pickupPersonName', new FormControl(response.pickupPersonName));
            }
            else {
              this._detail.addControl('pickupPersonName', new FormControl());
            }
            if (response.pickupPersonAddress != null) {
              this._detail.addControl('pickupPersonAddress', new FormGroup({}));
              let pickupAddress = this._detail.get('pickupPersonAddress') as FormGroup;
              pickupAddress.addControl('label', new FormControl('Pickup Address'))
              pickupAddress.addControl('street1', new FormControl(response.pickupPersonAddress.street1))
              pickupAddress.addControl('street2', new FormControl(response.pickupPersonAddress.street2))
              pickupAddress.addControl('city', new FormControl(response.pickupPersonAddress.city))
              pickupAddress.addControl('state', new FormControl(response.pickupPersonAddress.state))
              // pickupAddress.addControl('country', new FormControl(response.pickupPersonAddress.country))
              pickupAddress.addControl('country', new FormGroup({}));
              let country = pickupAddress.get('country') as FormGroup;
              country.addControl('id', new FormControl(response.pickupPersonAddress?.country?.id))
              pickupAddress.addControl('zipCode', new FormControl(response.pickupPersonAddress.zipCode))
            }
            else {
              this.showPickupAddress = false;
            }
          }
          else {
            this.showPickupAddress = false;
            this._detail.removeControl('pickupPersonName');
            this._detail.removeControl('pickupPersonAddress');
          }

          this._detail.get('tradeType')?.patchValue(response.tradeType);
          if (this.details.tradeType.value == 2) {
            this.details.supplierName.disable();
          }
          else {
            this._detail.get('supplierName')?.patchValue(response.supplierName);
          }
          if (this.details.tradeType.value == 3) {
            this.isThirdCountrySelected = true
            this._detail.addControl('exporterName', new FormControl(response?.exporterName));            
            this._detail.addControl('exporterMailId', new FormControl(response.exporterMailId));
            this._detail.addControl('exporterContact', new FormControl(response.exporterContact));
            this._detail.addControl('exporterAddress', new FormGroup({}))
            const exporterAddress = this._detail.get('exporterAddress') as FormGroup
            exporterAddress.addControl('label', new FormControl(response.exporterAddress.label));
            exporterAddress.addControl('street1', new FormControl(response.exporterAddress.street1));
            exporterAddress.addControl('street2', new FormControl(response.exporterAddress.street2));
            exporterAddress.addControl('city', new FormControl(response.exporterAddress.city));
            exporterAddress.addControl('state', new FormControl(response.exporterAddress.state));
            // exporterAddress.addControl('country', new FormControl(response.exporterAddress.country));
            exporterAddress.addControl('country', new FormGroup({}));
            let country = exporterAddress.get(('country')) as FormGroup;
            country.addControl('id', new FormControl(response.exporterAddress?.country?.id));
            exporterAddress.addControl('zipCode', new FormControl(response.exporterAddress.zipCode));
          }
          else {
            this.isThirdCountrySelected = false
            this._detail.removeControl('exporterName');
            this._detail.removeControl('exporterMailId');
            this._detail.removeControl('exporterContact');
            this._detail.removeControl('exporterAddress');
          }


          if (this._detail.get('modeOfShipment')?.value == 1) {
            this.air_mode_shipment = true;
            this.sea_mode_shipment = false;
            this.breakbulkBool = false;
            for (let index = 0; index < response.shipmentPackages.length; index++) {
              const element = response.shipmentPackages[index];
              this.package_details.push(this.prePopulatePackages(element));
            }
            this.calculateAirVolume();
          }
          else if (this._detail.get('modeOfShipment')?.value == 2) {
            this.air_mode_shipment = false;
            this.sea_mode_shipment = true;
            this._detail.get('hsCode')?.patchValue(response.hsCode || null);
            this._detail.get('containerLoadType')?.patchValue(response.containerLoadType);
            this._detail.get('detention')?.patchValue(response.detention);
            let continers: Array<any> = response.containers;
            let shipmentPackages: Array<any> = response.shipmentPackages;
            let breakBulks: Array<any> = response.breakBulks;

            if (this.details.containerLoadType.value == 1) {
              this.fullContainerLoadBool = true;
              continers.forEach((container) => {
                this.container_details.push(this.prePopulateContainer(container));
              });
              if (breakBulks != null && breakBulks.length > 0) {
                this.breakbulkBool = true;
                this.details.break_bulk_label.patchValue(true);
                breakBulks.forEach((breakBulk, index) => {
                  this.break_bulk.push(this.prePopulateBreakBulk(breakBulk));
                  this.calculateBreakBulkWeight(index);
                })
              }
            }
            else if(this.details.containerLoadType.value == 2){
              this.lessContainerLoadBool = true;
              shipmentPackages.forEach((shipment) => {
                this.package_details.push(this.prePopulateLCL(shipment));
              })
              this.calculateTotalVolume();
            }
            else if(this.details.containerLoadType.value == 3){
              this.fullContainerLoadBool = true;
              this.lessContainerLoadBool = true;
              continers.forEach((container) => {
                this.container_details.push(this.prePopulateContainer(container));
              });
              shipmentPackages.forEach((shipment) => {
                this.package_details.push(this.prePopulateLCL(shipment));
              });
              this.calculateTotalVolume();
            }
            else{
              this.breakbulkBool = true;
              this.details.break_bulk_label.patchValue(true);
              breakBulks.forEach((breakBulk, index) => {
                this.break_bulk.push(this.prePopulateBreakBulk(breakBulk));
                this.calculateBreakBulkWeight(index);
              })
            }
          }
          else if (this._detail.get('modeOfShipment')?.value == 3) {
            console.log("IM AT RAIL");
          }
          else {
            console.log("IM AT ROAD");
          }
          let terms: Array<any> = response.terms;
          if (terms.length >= 1) {
            terms.forEach((term) => {
              this.termsConditions.push(this.prePopulateTermsConditions(term));
            });
          }
          this._detail.disable();
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  createNewAuction() {
    if (this.details.internalReferenceNumber.value === '') {
      this.details.internalReferenceNumber.setValue(null);
    }
    let submitBool = this.validateFilesAtSubmit();
    if (this._detail.invalid) {
      let x = this.findInvalidControls();
      console.log(x);
      Swal.fire({
        titleText: "Warning",
        icon: "warning",
        text: "Please fill the field " + x[0],
        timer: 5000
      })
    }
    else if (submitBool == false && !this.auctionForm.invalid && !this._detail.invalid) {
      let auctionDate = new Date(this.auctions.auctionDate.value)
      let lastSubmissionDate = new Date(this.details.lastSubmissionDate.value);
      this._detail.get('lastSubmissionDate').patchValue(lastSubmissionDate);
      let data = {
        "assignedVendors": this.auctionForm.get('assignedVendors')?.value,
        "terms": this.termsConditions.value,
        "baseprice": this.auctions.basePrice.value,
        "targetPrice": this.auctions.targetPrice.value,
        "currency": this.auctions.currency.value,
        "description": this.auctions.description.value,
        "durationinMinutes": this.auctions.durationinMinutes.value,
        "auctionDurationUnit": this.auctions.auctionDurationUnit.value,
        "limitMaxattemptsPerVendor": this.auctions.limitMaxattemptsPerVendor.value,
        "minimumDecrement": this.auctions.minimumDecrement.value,
        'minimumDecrementUnit': this.auctions.minimumDecrementUnit.value,
        "remark": this.auctions.remark.value,
        "showStanding": this.auctions.showStanding.value,
        "auctionDate": auctionDate,
        "enquiryDto": this._detail?.value,
        "type": this.auctions.type.value,
        "extendTimeBool": this.auctions.extendTimeBool.value,
        "extendedTime": this.auctions.extendedTime.value,
        "bidSubmitDurationInLastMinutes": this.auctions.bidSubmitDurationInLastMinutes.value,
        "auctionTimeExtensionLimit": this.auctions.auctionTimeExtensionLimit.value
      }
      console.log(data);
      this.auctionService.createAuction(data).subscribe(
        (response) => {
          console.log(response);
          if (response.code == 201) {
            Swal.fire({
              titleText: "Success",
              text: response.message,
              icon: "success",
              timer: 5000
            })
            this.auctionId = response.entityId;
            for (let index = 0; index < this.documentList.length; index++) {
              this.uploadFile(index);
            }
            this.router.navigate(['v1/client-auction/listing/', this.auctions.type.value]);
          }
        },
        (error) => {
          console.log(error);
          let _error = error.error;
          if(_error.errorCode == 412){
            Swal.fire({
              titleText: "Error",
              icon: "error",
              timer: 5000,
              text: _error.errorMessage
            });
          }
        },
      )
    }
  }

  fetchAuctionById(id) {
    this.auctionService.fetchAuctionById(id).subscribe(
      (response) => {
        console.log(response);
        if (response != null) {
          this.getAllVendors();
          this.hide_btn = false;
          this.auctionUpdateBool = true;
          this.auctionForm.addControl('id', new FormControl());
          this.auctionForm.get('id').patchValue(response.id);
          this.auctions.description.patchValue(response.description);
          this.auctions.remark.patchValue(response.remark);
          this.auctions.basePrice.patchValue(response.baseprice);
          this.auctions.targetPrice.patchValue(response.targetPrice);
          this.auctions.auctionDurationUnit.patchValue(response.auctionDurationUnit);
          this.currency.get('id').patchValue(response.currency.id);
          this.auctions.minimumDecrement.patchValue(response.minimumDecrement);
          this.auctions.minimumDecrementUnit.patchValue(response.minimumDecrementUnit);
          let auctionDate = this.datePipe.transform(response.auctionDate, 'yyyy-MM-ddTHH:mm');
          this.auctions.auctionDate.patchValue(auctionDate);
          this.auctions.durationinMinutes.patchValue(response.durationinMinutes);
          this.auctions.limitMaxattemptsPerVendor.patchValue(response.limitMaxattemptsPerVendor);
          this.auctions.showStanding.patchValue(response.showStanding);
          this.auctions.type.patchValue(response.type);
          this.auctions.bidSubmitDurationInLastMinutes.patchValue(response.bidSubmitDurationInLastMinutes);
          this.auctions.auctionTimeExtensionLimit.patchValue(response.auctionTimeExtensionLimit);
          this.auctions.extendTimeBool.patchValue(response.extendTimeBool);
          if(this.auctions.extendTimeBool.value == true){
            this.timeExtensionBool = true;
          }
          this.auctions.extendedTime.patchValue(response.extendedTime);

          this.details.number.patchValue(response.enquiry.number || '12345');
          let createDate = this.datePipe.transform(response.enquiry.enquiryDate, 'yyyy-MM-dd');
          this.details.createDate.patchValue(createDate);
          this.details.description.patchValue(response.enquiry.description);
          this.details.projectName.patchValue(response.enquiry.projectName);
          this.details.projectCode.patchValue(response.enquiry.projectCode);
          this.details.cargoReadinessDate.patchValue(response.enquiry.cargoReadinessDate);
          this.details.commodity.patchValue(response.enquiry.commodity);
          let lastSubmissionDate = this.datePipe.transform(response.enquiry.lastSubmissionDate, 'yyyy-MM-ddTHH:mm');
          this._detail.get('lastSubmissionDate').patchValue(lastSubmissionDate);
          this.details.modeOfShipment.patchValue(response.enquiry.modeOfShipment);
          this.details.tradeType.patchValue(response.enquiry.tradeType);
          this.details.typeOfCargo.patchValue(response.enquiry.typeOfCargo);
          this.details.typeOfPacking.patchValue(response.enquiry.typeOfPacking);

          this.searchOriginValue = response.enquiry.originPortName
          this.getSearchResultsOrigin();

          this.searchDestinationValue = response.enquiry.destinationPortName
          this.getSearchResultDestination();

          this.details.origin.patchValue(response.enquiry.origin);
          this.details.destination.patchValue(response.enquiry.destination);
          this.details.natureOfMovement.patchValue(response.enquiry.natureOfMovement);
          this.details.natureOfGoods.patchValue(response.enquiry.natureOfGoods);
          this.details.poNumber.patchValue(response.enquiry.poNumber);
          this.details.storingType.patchValue(response.enquiry.storingType);
          this.details.internalReferenceNumber.patchValue(response.enquiry.internalReferenceNumber);
          this.details.demurrage.patchValue(response.enquiry.demurrage);

          this.changeTypeCargo('typeOfCargo');
          this.changeNatureOfMovement();
          this._detail.get('deliveryIncoterms')?.patchValue(response.enquiry.deliveryIncoterms);
          if (this.details.deliveryIncoterms.value == "DAP" || this.details.deliveryIncoterms.value == "DDP" || this.details.deliveryIncoterms.value == "DPU") {
            this.showTerminal = false;
            if (response.enquiry.deliveryPersonAddress != null) {
              this.delivaryAddress = true;
              this._detail.addControl('deliveryPersonName', new FormControl(response.enquiry.deliveryPersonName));
              this._detail.addControl('deliveryPersonAddress', new FormGroup({}))
              let deliveryAddress = this._detail.get('deliveryPersonAddress') as FormGroup;
              deliveryAddress.addControl('label', new FormControl('Delivery Address'))
              deliveryAddress.addControl('street1', new FormControl(response.enquiry.deliveryPersonAddress.street1))
              deliveryAddress.addControl('street2', new FormControl(response.enquiry.deliveryPersonAddress.street2))
              deliveryAddress.addControl('city', new FormControl(response.enquiry.deliveryPersonAddress.city))
              deliveryAddress.addControl('state', new FormControl(response.enquiry.deliveryPersonAddress.state))
              // deliveryAddress.addControl('country', new FormControl(response.enquiry.deliveryPersonAddress.country))
              deliveryAddress.addControl('country', new FormGroup({}));
              let country = deliveryAddress.get('country') as FormGroup;
              country.addControl('id', new FormControl(response.enquiry.deliveryPersonAddress?.country?.id));
              deliveryAddress.addControl('zipCode', new FormControl(response.enquiry.deliveryPersonAddress.zipCode))
            }
            else {
              this.delivaryAddress = false;
            }
          }
          else if (this.details.deliveryIncoterms.value == "DAT") {
            this.delivaryAddress = false;
            if (response.destinationTerminal != null) {
              this.showTerminal = true;
              this._detail.addControl('destinationTerminal', new FormControl(response.destinationTerminal));
            }
            else {
              this.showTerminal = false;
            }
            this._detail.removeControl('deliveryPersonName')
            this._detail.removeControl('deliveryPersonAddress')
          }
          else {
            this._detail.removeControl('destinationTerminal')
            this._detail.removeControl('deliveryPersonName')
            this._detail.removeControl('deliveryPersonAddress')
            this.delivaryAddress = false;
            this.showTerminal = false;
          }

          this._detail.get('pickUpIncoterms')?.patchValue(response.enquiry.pickUpIncoterms);
          if (this.details.pickUpIncoterms.value == "EXW" || this.details.pickUpIncoterms.value == "FCA") {
            this.showPickupAddress = true;
            if (response.enquiry.pickupPersonName != null) {
              this._detail.addControl('pickupPersonName', new FormControl(response.enquiry.pickupPersonName));
            }
            else {
              this._detail.addControl('pickupPersonName', new FormControl());
            }
            if (response.enquiry.pickupPersonAddress != null) {
              this._detail.addControl('pickupPersonAddress', new FormGroup({}));
              let pickupAddress = this._detail.get('pickupPersonAddress') as FormGroup;
              pickupAddress.addControl('label', new FormControl('Pickup Address'))
              pickupAddress.addControl('street1', new FormControl(response.enquiry.pickupPersonAddress.street1))
              pickupAddress.addControl('street2', new FormControl(response.enquiry.pickupPersonAddress.street2))
              pickupAddress.addControl('city', new FormControl(response.enquiry.pickupPersonAddress.city))
              pickupAddress.addControl('state', new FormControl(response.enquiry.pickupPersonAddress.state))
              // pickupAddress.addControl('country', new FormControl(response.enquiry.pickupPersonAddress.country))
              pickupAddress.addControl('country', new FormGroup({}));
              let country = pickupAddress.get('country') as FormGroup;
              country.addControl('id', new FormControl(response.enquiry.pickupPersonAddress?.country?.id));
              pickupAddress.addControl('zipCode', new FormControl(response.enquiry.pickupPersonAddress.zipCode))
            }
            // else{
            //   // this.showPickupAddress = false;
            // }
          }
          else {
            this.showPickupAddress = false;
            this._detail.removeControl('pickupPersonName');
            this._detail.removeControl('pickupPersonAddress');
          }

          if (this.details.tradeType.value == 2) {
            this.details.supplierName.disable();
          }
          else {
            this.details.supplierName.patchValue(response.enquiry.supplierName);
          }
          if (this.details.tradeType.value == 3) {
            this.isThirdCountrySelected = true
            this._detail.addControl('exporterName', new FormControl(response.enquiry?.exporterName));
            this._detail.addControl('exporterMailId', new FormControl(response.enquiry?.exporterMailId));
            this._detail.addControl('exporterContact', new FormControl(response.enquiry?.exporterContact));
            this._detail.addControl('exporterAddress', new FormGroup({}))
            const exporterAddress = this._detail.get('exporterAddress') as FormGroup
            exporterAddress.addControl('label', new FormControl(response.enquiry.exporterAddress?.label));
            exporterAddress.addControl('street1', new FormControl(response.enquiry.exporterAddress?.street1));
            exporterAddress.addControl('street2', new FormControl(response.enquiry.exporterAddress?.street2));
            exporterAddress.addControl('city', new FormControl(response.enquiry.exporterAddress?.city));
            exporterAddress.addControl('state', new FormControl(response.enquiry.exporterAddress?.state));
            // exporterAddress.addControl('country', new FormControl(response.enquiry.exporterAddress.country));
            exporterAddress.addControl('country', new FormGroup({}));
            let country = exporterAddress.get('country') as FormGroup;
            country.addControl('id', new FormControl(response.enquiry.exporterAddress?.country?.id));
            exporterAddress.addControl('zipCode', new FormControl(response.enquiry.exporterAddress?.zipCode));
          } else {
            this.isThirdCountrySelected = false
            this._detail.removeControl('exporterName');
            this._detail.removeControl('exporterMailId');
            this._detail.removeControl('exporterContact');
            this._detail.removeControl('exporterAddress');
          }

          if (this.details.modeOfShipment.value == 1) {
            this.air_mode_shipment = true;
            this.sea_mode_shipment = false;
            this.breakbulkBool = false;
            for (let index = 0; index < response.enquiry.shipmentPackages.length; index++) {
              const element = response.enquiry.shipmentPackages[index];
              this.package_details.push(this.prePopulatePackages(element));
            }
            this.calculateAirVolume();
          }
          else if (this.details.modeOfShipment.value == 2) {
            this.sea_mode_shipment = true;
            this.air_mode_shipment = false;
            let clt = response.enquiry.containerLoadType;
            this.details.containerLoadType.patchValue(response.enquiry.containerLoadType);
            this.details.hsCode.patchValue(response.enquiry.hsCode);
            this.details.detention.patchValue(response.enquiry.detention);
            if(clt == 1){
              this.fullContainerLoadBool = true;
              this.lessContainerLoadBool = false;
              this.breakBulkBoolOption = false;
              this.breakbulkBool = false;
              if(response.enquiry.containers.length >= 1){
                for (let index = 0; index < response.enquiry.containers.length; index++) {
                  const element = response.enquiry.containers[index];
                  this.container_details.push(this.prePopulateContainer(element));
                }
              }
              if (response.enquiry.breakBulks.length >= 1) {
                this.breakbulkBool = true;
                this.details.break_bulk_label.patchValue(true);
                  for (let index = 0; index < response.enquiry.breakBulks.length; index++) {
                    const element = response.enquiry.breakBulks[index];
                    this.break_bulk.push(this.prePopulateBreakBulk(element));
                    this.calculateBreakBulkWeight(index);
                  }
              }
            }
            else if(clt == 2){
              this.lessContainerLoadBool = true;
              this.fullContainerLoadBool = false;
              this.breakBulkBoolOption = false;
              this.breakbulkBool = false;
              for (let index = 0; index < response.enquiry.shipmentPackages.length; index++) {
                const element = response.enquiry.shipmentPackages[index];
                this.package_details.push(this.prePopulatePackages(element));
              }
              this.calculateTotalVolume();
            }
            else if(clt == 3){
              this.lessContainerLoadBool = true;
              this.fullContainerLoadBool = true;
              this.breakBulkBoolOption = false;
              this.breakbulkBool = false;
              for (let index = 0; index < response.enquiry.containers.length; index++) {
                const element = response.enquiry.containers[index];
                this.container_details.push(this.prePopulateContainer(element));
              }
              for (let index = 0; index < response.enquiry.shipmentPackages.length; index++) {
                const element = response.enquiry.shipmentPackages[index];
                this.package_details.push(this.prePopulatePackages(element));
              }
              this.calculateTotalVolume();
            }
            else{
              this.lessContainerLoadBool = false;
              this.fullContainerLoadBool = false;
              this.breakBulkBoolOption = true;
              this.breakbulkBool = true;
              this.details.break_bulk_label.patchValue(true);
              for (let index = 0; index < response.enquiry.breakBulks.length; index++) {
                const element = response.enquiry.breakBulks[index];
                this.break_bulk.push(this.prePopulateBreakBulk(element));
                this.calculateBreakBulkWeight(index);
              }
            }
          }
          else if (this.details.modeOfShipment.value == 3) {
            console.log("IM AT RAIL");
          }
          else {
            console.log("IM AT ROAD");
          }
          if (response.terms.length >= 1) {
            let terms: Array<any> = response.terms;
            terms.forEach((term) => {
              this.termsConditions.push(this.prePopulateTermsConditions(term));
            })
          }
          this.assignedVendors = response.assignedVendors;
          // ToDo, push assignedVendors to AssignVendors FormArray;
          for (let index = 0; index < response.assignedVendors.length; index++) {
            const vendor = response.assignedVendors[index];
            this.addVendors(vendor.id, index, true);
          }
          this._detail.disable();
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  updateAuction() {
    let submitBool = this.checkDocumentListValidation();
    if(this.auctionForm.invalid){
      let x = this.findInvalidControls();
      console.log(x);
      Swal.fire({
        titleText: "Warning",
        icon: "warning",
        text: "Please fill the field " + x[0],
        timer: 5000
      });
    }
    else if (submitBool == false && !this.auctionForm.invalid) {
      // ToDo auctionConductedBy param 
      let auctionDate = new Date(this.auctions.auctionDate.value)
      let lastSubmissionDate = new Date(this.details.lastSubmissionDate.value);
      this._detail.get('lastSubmissionDate').patchValue(lastSubmissionDate);
      let data = {
        "assignedVendors": this.auctionForm.get('assignedVendors')?.value,
        "terms": this.termsConditions.value,
        "baseprice": this.auctions.basePrice.value,
        "targetPrice": this.auctions.targetPrice.value,
        "currency": this.auctions.currency.value,
        "description": this.auctions.description.value,
        "durationinMinutes": this.auctions.durationinMinutes.value,
        "auctionDurationUnit": this.auctions.auctionDurationUnit.value,
        "limitMaxattemptsPerVendor": this.auctions.limitMaxattemptsPerVendor.value,
        "minimumDecrement": this.auctions.minimumDecrement.value,
        'minimumDecrementUnit': this.auctions.minimumDecrementUnit.value,
        "remark": this.auctions.remark.value,
        "showStanding": this.auctions.showStanding.value,
        "auctionDate": auctionDate,
        "enquiryDto": this._detail?.value,
        "type": this.auctions.type.value,
        "extendTimeBool": this.auctions.extendTimeBool.value,
        "extendedTime": this.auctions.extendedTime.value,
        "bidSubmitDurationInLastMinutes": this.auctions.bidSubmitDurationInLastMinutes.value,
        "auctionTimeExtensionLimit": this.auctions.auctionTimeExtensionLimit.value
      }
      this.auctionService.updateAuctionById(this.auctions.id.value, data).subscribe(
        (response) => {
          console.log(response);
          if (response.code == 201) {
            Swal.fire({
              titleText: "Success",
              text: response.message,
              timer: 5000,
              icon: "success"
            })
            console.log(this.auctions.type.value);
            this.router.navigate(['v1/client-auction/listing/', this.auctions.type.value]);
          }
          else {
            Swal.fire({
              titleText: "Error",
              text: "Something went wrong, try again later",
              timer: 5000,
              icon: "error"
            })
          }
        },
        (error) => {
          console.log(error);
          let _error = error.error;
          if(_error.errorCode == 412){
            Swal.fire({
              titleText: _error.errorMessage,
              icon: "error",
              timer: 5000,
              title: "Error"
            });
          }
          else{
            Swal.fire({
              titleText: "Error",
              text: "Something went wrong, try again later",
              timer: 5000,
              icon: "error"
            })
          }
        },
      )
    }
  }

  getMasterTablesSessionStorage() {
    this.containerTypeList();
    this.getModeOfShipment();
    this.getTradeType();
    this.getTypeOfCargo();
    this.getNatureOfMovement();
    this.getPickUpIncoTerms();
    this.getDeliveryIncoTerms();
    this.getNatureOfGoods();
    this.getTypeOfPacking();
    this.getCurrency();
    this.getAllBusinessType();
    this.getCountry();
  }

  containerTypeList(){
    this.masterTableService.getContainerTypes().subscribe(
      (response) => {
        if(response){
          this.container_type = response
        }
      },
      (error) => {error},
    );
  }

  getModeOfShipment(){
    this.masterTableService.getModeOfShipment().subscribe(
      (response) => {
        this.mode_of_shipment = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getTradeType(){
    this.masterTableService.getTradeType().subscribe(
      (response) => {
        this.trade_type = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getOriginDestination(){
    this.masterTableService.getLoadingDestinationPort().subscribe(
      (response) => {
        this.originDestination = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getTypeOfCargo(){
    this.masterTableService.getAllTypeOfCargo().subscribe(
      (response) => {
        this.type_cargo = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getNatureOfMovement(){
    this.masterTableService.getNatureOfMovement().subscribe(
      (response) => {
        this.nature_of_movement = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }
  getPickUpIncoTerms(){
    this.masterTableService.getAllPickUpIncoTerms().subscribe(
      (response) => {
        this.pickup_terms = response;
        this.changeNatureOfMovement();
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getDeliveryIncoTerms(){
    this.masterTableService.getDeliveryIncoTerms().subscribe(
      (response) => {
        this.dropoff_terms = response;
        this.changeNatureOfMovement();
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getNatureOfGoods(){
    this.masterTableService.getNatureOfGoods().subscribe(
      (response) => {
        this.nature_goods = response;
        this.changeTypeCargo('typeOfCargo');
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getTypeOfPacking(){
    this.masterTableService.getAllTypeOfPacking().subscribe(
      (response) => {
        this.type_packing = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getCurrency(){
    this.masterTableService.getAllCurrency().subscribe(
      (response) => {
        this.currencyPicker = response;
        console.log(this.currencyPicker);
        
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getTodayDateTime() {
    let _date = new Date();
    var month = this.formatDate(_date.getMonth() + 1);
    var day = this.formatDate(_date.getDate());
    var hours = this.formatDate(_date.getHours());
    var minutes = this.formatDate(_date.getMinutes());
    let dateAndTime = _date.getFullYear() + "-" + month + "-" + day + "T" + hours + ":" + minutes;
    return dateAndTime;
  }

  formatDate(nmbr: number): string {
    var date = nmbr + "";
    date = (date.length < 2) ? "0" + date : date;
    return date;
  }

  getTodayDate() {
    return new Date().toISOString().split('T')[0]
  }

  backAuctionConfig() {
    this.auctionConfigBool = false;
    this.dashboardBool = true;
    let currentPage = document.getElementById('item-5');
    let backPage = document.getElementById('item-4');
    let currentSideBar = document.getElementById('item-1');
    let backSideBar = document.getElementById('item-0');
    let dashboardPage = document.getElementById('list-item-1');
    if (currentPage.classList.contains("active") && currentSideBar.classList.contains("active")) {
      currentSideBar.classList.remove("active");
      currentPage.classList.remove('active');
      dashboardPage.classList.remove("active");
      backPage.classList.add("active");
      backSideBar.classList.add("active");
    }
    else {
      console.log("NOTHING");
    }
  }

  backAuctionDetail() {
    this.auctionDetailBool = false;
    this.auctionConfigBool = true;
    let currentPage = document.getElementById('item-6');
    let backPage = document.getElementById('item-5');
    let currentSideBar = document.getElementById('item-2');
    let backSideBar = document.getElementById('item-1');
    let auctionDetail = document.getElementById('list-item-2');
    if (currentPage.classList.contains("active") && currentSideBar.classList.contains("active")) {
      currentSideBar.classList.remove("active");
      currentPage.classList.remove('active');
      auctionDetail.classList.remove("active");
      backPage.classList.add("active");
      backSideBar.classList.add("active");
    }
    else {
      console.log("NOTHING");
    }
  }

  backAuctionVendors() {
    this.auctionDetailBool = true;
    this.auctionVendorsBool = false;
    let currentPage = document.getElementById('item-7');
    let backPage = document.getElementById('item-6');
    let currentSideBar = document.getElementById('item-3');
    let backSideBar = document.getElementById('item-2');
    let termsConditions = document.getElementById('list-item-3');
    if (currentPage.classList.contains("active") && currentSideBar.classList.contains("active")) {
      currentSideBar.classList.remove("active");
      currentPage.classList.remove('active');
      termsConditions.classList.remove("active");
      backPage.classList.add("active");
      backSideBar.classList.add("active");
    }
    else {
      console.log("NOTHING");
    }
  }

  addTermsCondition() {
    const term = this.fb.group({
      content: [''],
      label: [''],
    })
    this.termsConditions.push(term);
  }

  deleteTermsCondition(index) {
    const term = this.termsConditions
    if (term.length > 1) {
      this.termsConditions.removeAt(index);
    }
    else {
      term.reset();
    }
  }

  addFiles(files: NgxFileDropEntry[]) {
    for (let index = 0; index < files.length; index++) {
      const droppedFile = files[index];
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          // Here you can access the real file size & content
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            let data = {
              "content": reader.result,
              "contentType": reader.result?.toString().split(',')[0],
              "name": file.name,
              "size": file.size
            }
            this.documentList.push(data);
          }
        })
      }
      else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        // console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }
  uploadFile(index) {
    let data = this.documentList[index];
    if (data.label == undefined) {
      Swal.fire({
        titleText: "Error",
        icon: "error",
        text: "Add label to file, then upload",
        timer: 5000
      })
    }
    else if (data.size > this.maxFileSize) {
      Swal.fire({
        titleText: "Error",
        icon: "error",
        text: "Upload File with size less than 2MB",
        timer: 5000
      })
    }
    else {
      this.auctionService.createAuctionArtifact(this.auctionId, data).subscribe(
        (response) => {
          console.log(response);
          if (response.code == 201) {
            data['artifactId'] = response.entityId;
          }
        },
        (error) => {
          console.log(error);
        },
      )
    }
  }

  deleteServerFile(artifactId, index) {
    this.auctionService.deleteAuctionArtifact(this.auctionId, artifactId).subscribe(
      (response) => {
        console.log(response);
        if (response.code == 200) {
          Swal.fire({
            titleText: "Success",
            text: response.message,
            icon: "success",
            timer: 5000
          });
          this.deleteDocument(index);
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  deleteDocument(index: number) {
    this.documentList.splice(index, 1)
  }

  getAllAuctionArtifacts(auctionId) {
    this.auctionService.getAllAuctionArtifact(auctionId).subscribe(
      (response: Array<any>) => {
        if (response.length >= 1) {
          for (let index = 0; index < response.length; index++) {
            const document = response[index];
            let _data = {
              "label": document.label,
              "content": document.content,
              "contentType": document.contentType,
              "name": document.name,
              "artifactId": document.id
            }
            this.documentList.push(_data);
          }
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  changeTradeType() {
    let tradeType = this.details.tradeType.value;
    if (tradeType == 2) {
      this.details.supplierName.disable();
    }
    else {
      this.details.supplierName.enable();
    }

    if (tradeType == 3) {
      this.isThirdCountrySelected = true
      console.log('this is trade type 3 selected ', tradeType);
      this._detail.addControl('exporterName', new FormControl('', Validators.required));
      this._detail.addControl('exporterMailId', new FormControl(''));
      this._detail.addControl('exporterContact', new FormControl(''));
      this._detail.addControl('exporterAddress', new FormGroup({}))
      const exporterAddress = this._detail.get('exporterAddress') as FormGroup
      exporterAddress.addControl('label', new FormControl('Exporter Address'));
      exporterAddress.addControl('street1', new FormControl(''));
      exporterAddress.addControl('street2', new FormControl(''));
      exporterAddress.addControl('city', new FormControl(''));
      exporterAddress.addControl('state', new FormControl(''));
      // exporterAddress.addControl('country', new FormControl(''));
      exporterAddress.addControl('country', new FormGroup({}));
      let country = exporterAddress.get('country') as FormGroup;
      country.addControl('id', new FormControl());
      exporterAddress.addControl('zipCode', new FormControl(''));
    } else {
      this.isThirdCountrySelected = false
      this._detail.removeControl('exporterName');
      this._detail.removeControl('exporterMailId');
      this._detail.removeControl('exporterContact');
      this._detail.removeControl('exporterAddress');
    }
  }

  changeContainerLoadType() {
    let clt = this.details.containerLoadType.value;
    if (clt == 1) {
      this.fullContainerLoadBool = true;
      if (this.container_details.length == 0) {
        this.addSeaRow();
      }
      this.breakBulkBoolOption = true;
      this.breakbulkBool = false;
      this.break_bulk.clear()
      this.lessContainerLoadBool = false;
      this.package_details.clear();
    }
    else if (clt == 2) {
      this.fullContainerLoadBool = false;
      this.container_details.clear();
      this.breakBulkBoolOption = false;
      this.breakbulkBool = false;
      this.details.break_bulk_label.setValue(false);
      this.lessContainerLoadBool = true;
      if (this.package_details.length == 0) {
        this.addLCLRow();
      }
    }
    else if (clt == 3) {
      this.fullContainerLoadBool = true;
      this.container_details.clear();
      if (this.container_details.length == 0) {
        this.addSeaRow()
      }
      this.lessContainerLoadBool = true;
      this.package_details.clear();
      if (this.package_details.length == 0) {
        this.addLCLRow();
      }
      this.breakBulkBoolOption = false;
      this.breakbulkBool = false;
      this.break_bulk.clear()
      this.details.break_bulk_label.setValue(false);
    }
    else if (clt == 4) {
      this.fullContainerLoadBool = false;
      this.container_details.clear();
      this.lessContainerLoadBool = false;
      this.package_details.clear();
      this.breakbulkBool = true;
      if (this.break_bulk.length == 0) {
        this.addBreakBulk();
      }
      this.breakBulkBoolOption = false;
    }

  }

  selectLoadingPort() {
    let lPort = this.details.origin;
    let dPort = this.details.destination;
    if (lPort.value == dPort.value) {
      this.samePortError = true;
      Swal.fire({
        title: "Warning",
        icon: "warning",
        timer: 5000,
        text: "Loading & Destination Port cannot be same"
      })
    }
    else {
      this.samePortError = false;
    }
  }

  downloadFileLocal(item) {
    if (item.artifactId != null && item.label != null) {
      this.dataURItoBlob(item);
    }
    else {
      Swal.fire({
        title: "Warning",
        icon: "warning",
        timer: 5000,
        text: "To download file, provide label"
      })
    }
  }

  dataURItoBlob(data) {
    const linkSource = data.content;
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = data.name;
    downloadLink.click();
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.auctions;
    const enquiryControl = this.details;
    for (const name in controls) {
      console.log(name + " is :: " + controls[name].invalid);
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    for (const name in enquiryControl) {
      console.log(name + " is :: " + enquiryControl[name].invalid);
      if (enquiryControl[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  validatePickupAddress() {
    let street1 = this.details.pickupPersonAddress.value.street1
    console.log(street1)
    let street2 = this.details.pickupPersonAddress.value.street2
    console.log(street2)
    if (street1 == street2) {
      this.pickupAddressBoolean = true;
      console.log('same address')
    } else {
      this.pickupAddressBoolean = false;
    }
  }

  validateDeliveryAddress() {
    let street1 = this.details.deliveryPersonAddress.value.street1
    console.log(street1);
    let street2 = this.details.deliveryPersonAddress.value.street2
    console.log(street2)
    if (street1 == street2) {
      this.deliveryAddressBoolean = true;
      console.log('same address')
    } else {
      this.deliveryAddressBoolean = false;
    }
  }

  pageSecondToThird() {
    console.log("NOTHING");
    this.auctionConfigBool = false;
    this.auctionDetailBool = true;
    let currentPage = document.getElementById('item-1');
    let nextPage = document.getElementById('item-2');
    let _page = document.getElementById("item-5");
    let _next = document.getElementById("item-6");
    let _list = document.getElementById("list-item-2");
    if (currentPage.classList.contains("active") && _page.classList.contains("active")) {
      _page.classList.remove("active");
      currentPage.classList.remove('active');
      _list.classList.add("active");
      nextPage.classList.add("active");
      _next.classList.add("active");
    }
    this.gotoTop();
  }

  isValidFile(file: any) {
    return file.name.endsWith(".csv" || ".xlsx");
  }

  onChange(event) {
    this.fileName = event.srcElement.files[0].name;
    let files = event.srcElement.files;
    if (files.length > 1) {
      Swal.fire({
        titleText: "Warning",
        icon: "warning",
        timer: 5000,
        text: "Only one file allowed to upload"
      })
    }
    if (this.breakBulkFile.length == 1) {
      Swal.fire({
        titleText: "Warning",
        icon: "warning",
        timer: 5000,
        text: "Delete the uploaded file, first. Only one file allowed to upload"
      })
    }
    else {
      let file = files[0]
      // Here you can access the real file size & content
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let data = {
          "content": reader.result,
          "contentType": reader.result?.toString().split(',')[0],
          "name": file.name,
          "size": file.size,
          "label": "Package Slip"
        }
        this.breakBulkFile.push(data);
      }
    }
  }

  getAllEnquiryArtifacts(id) {
    this.enquiryService.getAllEnquiryArtifact(id).subscribe(
      (response: Array<any>) => {
        if (response.length >= 1) {
          for (let index = 0; index < response.length; index++) {
            const document = response[index];
            let _data = {
              "label": document.label,
              "content": document.content,
              "contentType": document.contentType,
              "name": document.name,
              "artifactId": document.id
            }
            if (document.label == "Package Slip") {
              this.breakBulkFile.push(_data);
              this.breakbulkFileBool = true;
            }
          }
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  uploadPackageSlip(index) {
    let data = this.breakBulkFile[index];
    this.enquiryService.createEnquiryArtifact(this.enquiryId, data).subscribe(
      (response) => {
        console.log(response);
        if (response.code == 201) {
          data['artifactId'] = response.entityId;
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  deletePacakgeslip(index) {
    this.breakBulkFile.splice(index, 1);
  }

  deletePackagSlipServer(artifactId, index) {
    console.log(this.breakBulkFile);
    this.enquiryService.deleteEnquiryArtifact(this.enquiryId, artifactId).subscribe(
      (response) => {
        console.log(response);
        if (response.code == 200) {
          Swal.fire({
            titleText: "Success",
            text: response.message,
            icon: "success",
            timer: 5000
          });
          this.deletePacakgeslip(index)
        }
      },
      (error) => {
        console.log(error);
      },
    )
    console.log(this.breakBulkFile);
  }

  downloadPackageslip(item) {
    if (item.artifactId != null && item.label != null) {
      this.dataURItoBlob(item);
    }
  }

  validateCargoReadinessDate(){
    let cargoDate = this.details.cargoReadinessDate.value 
    let offerDate = this.details.lastSubmissionDate.value
    if(offerDate > cargoDate){
      this.invalidOfferDate = true
    }else{
      this.invalidOfferDate = false
    }
  }
  
  maxOfferLastSubmissionDate(){
    return this.details.cargoReadinessDate.value
  }

  minOfferLastSubmissionDate(){
    return this.details.createDate.value
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  calculateAirVolume() {
    this.totalVolumeWeightAIR = 0.0;
    for (let index = 0; index < this.package_details.length; index++) {
      const _package = this.package_details.at(index);
      let length = _package.get('length').value;
      let height = _package.get('height').value;
      let width = _package.get('width').value;
      let quantity = _package.get('quantity').value;
      let dimensionsUOM = _package.get('dimensionsUOM').value;
      let totalVolume = (length * width * height * quantity);
      let convert = this.convertdimensionsUOMToMtrAIR(dimensionsUOM, totalVolume);
      _package.get('totalVolume').patchValue(convert);
      _package.get('totalVolume').disable();
      this.totalVolumeWeightAIR = convert + this.totalVolumeWeightAIR;
    }
    this.convertToMTAIR();
  }

  convertToMTAIR() {
    this.compareWeightUomAir();
    this.calculateWeightAir();
    this.compareWeightVolumeAir();
  }

  compareWeightUomAir(){
    let comparison = ""
    for (let index = 0; index < this.package_details.length; index++) {
      const _package = this.package_details.at(index);
      let weightUOM = _package.get('weightUOM').value;
      let latest = weightUOM;
      if(comparison.length > 0){
        switch(latest){
          case "LBS":
            if(comparison == "KG"){
              comparison = "KG"
            }
            else{
              comparison = latest
            }
            break
          case "KG":
            if(comparison == "KG"){
              comparison = "KG"
            }
            else{
              comparison = latest
            }
            break
        }
      }
      else{
        comparison = latest
      }
    }
    this.finalAirWeightUom = comparison;
  }

  calculateWeightAir(){
    this.totalGrossWeightAIR = 0.0;
    for (let index = 0; index < this.package_details.length; index++) {
      const _package = this.package_details.at(index);
      let weightUOM = _package.get('weightUOM').value;
      let weight = _package.get('grossWeight').value;
      if(this.finalAirWeightUom == "KG"){
        if(weightUOM == "LBS"){
          let _weight = this.convertLBStoKG(weight);
          this.totalGrossWeightAIR = _weight + this.totalGrossWeightAIR;
        }
        else{
          this.totalGrossWeightAIR = weight + this.totalGrossWeightAIR;
        }
      }
      else if(this.finalAirWeightUom == "LBS"){
        this.totalGrossWeightAIR = weight + this.totalGrossWeightAIR;
      }
    }
  }

  compareWeightVolumeAir() {
    let chargeableWeight: number;
    let metricTon: number = 0.0;
    for (let index = 0; index < this.package_details.length; index++) {
      let _package = this.package_details.at(index)
      if(this.finalAirWeightUom == "KG"){
        if(_package.get('weightUOM').value == "LBS"){
          let lbs = this.convertLBStoMT(_package.get('grossWeight').value)
          metricTon = lbs + metricTon
        }
        else{
          let kg = this.convertKGtoMT(_package.get('grossWeight').value);
          metricTon = kg + metricTon
        }
      }
      else{
        let lbs = this.convertLBStoMT(_package.get('grossWeight').value);
        metricTon = lbs + metricTon
      }
    }
    if(metricTon > this.totalVolumeWeightAIR) {
      this.finalAirWeightBool = true;
      chargeableWeight = metricTon
    }
    else{
      this.finalAirWeightBool = false;
      chargeableWeight = this.totalVolumeWeightAIR
    }
    this._detail.get('chargableWeight').patchValue(chargeableWeight);
    return chargeableWeight;
  }

  convertKGtoLBS(weight){
    let _weight = (weight * 2.205)
    return parseFloat(_weight.toFixed(4))
  }

  convertKGtoMT(weight){
    let _weight = (weight / 1000)
    return parseFloat(_weight.toFixed(4))
  }

  convertLBStoKG(weight){
    let _weight = (weight / 2.205)
    return parseFloat(_weight.toFixed(4))
  }

  convertLBStoMT(weight){
    let _weight = (weight / 2205)
    return parseFloat(_weight.toFixed(4))
  }

  calculateTotalVolume() {
    this.totalVolumeWeightLCL = 0.0;
    for (let index = 0; index < this.package_details.length; index++) {
      const _package = this.package_details.at(index);
      let quantity = _package.get('quantity').value;
      let length = _package.get('length').value;
      let height = _package.get('height').value;
      let width = _package.get('width').value;
      let dimensionsUOM = _package.get('dimensionsUOM').value;
      let totalVolume = (length * width * height) * quantity;
      let convert = this.convertdimensionsUOMtoMtr(dimensionsUOM, totalVolume);
      _package.get('totalVolume').patchValue(convert);
      _package.get('totalVolume').disable();
      this.totalVolumeWeightLCL = convert + this.totalVolumeWeightLCL;
    }
    // ToDo, add params
    this._detail.get('volumeInCBM').patchValue(this.totalVolumeWeightLCL);
    this.convertToMTsea();
  }

  // Comman for air/sea(ShipmentPackages).
  convertdimensionsUOMtoMtr(dimensionsUOM, totalVolume) {
    let volume: number = 0.0;
    if (dimensionsUOM == "CM") {
      volume = (totalVolume / 1000000 );
    }
    else if (dimensionsUOM == "MM") {
      volume = (totalVolume / 1000000000 );
    }
    else if (dimensionsUOM == "IN") {
      volume = (totalVolume / 61020 );
    }
    else if (dimensionsUOM == "FT") {
      volume = (totalVolume / 35.315);
    }
    else {
      // mtr
      volume = totalVolume
    }
    return parseFloat(volume.toFixed(4));
  }

  convertdimensionsUOMToMtrAIR(dimensionsUOM, totalVolume){
    let volume: number = 0.0;
    if(dimensionsUOM == "CM"){
      volume = totalVolume / 6000
    }
    else if(dimensionsUOM == "IN"){
      volume = totalVolume / 366
    }
    else if(dimensionsUOM == "MTR"){
      volume = totalVolume * 167
    }
    else if(dimensionsUOM == "FT"){
      volume = totalVolume * 4.7
    }
    else if(dimensionsUOM == "MM"){
      volume = (totalVolume/1000000000)*167
    }
    return parseFloat(volume.toFixed(4));
  }

  convertToMTsea() {
    this.compareWeightUOMSea();
    this.calculateWeightSea();
    this.compareWeightVolume();
  }

  compareWeightUOMSea(){
    let comparison = ""
    for (let index = 0; index < this.package_details.length; index++) {
      const _package = this.package_details.at(index);
      let weightUOM = _package.get('weightUOM').value;
      let latest = weightUOM;
      if(comparison.length > 0){
        switch(latest){
          case "LBS":
            if(comparison == "KG"){
              comparison = "KG"
            }
            else if(comparison == "MT"){
              comparison = "MT"
            }
            else{
              comparison = latest
            }
            break
          case "KG":
            if(comparison == "MT"){
              comparison = "MT"
            }
            else if(comparison == "LBS"){
              comparison = "KG"
            }
            else{
              comparison = latest
            }
            break
          case "MT":
            comparison = latest
            break
        }
      }
      else{
        comparison = latest
      }
    }
    this.finalSeaWeightUom = comparison;
  }

  calculateWeightSea(){
    this.totalGrossWeightLCL = 0.0
    for (let index = 0; index < this.package_details.length; index++) {
      const _package = this.package_details.at(index);
      let weightUOM = _package.get('weightUOM').value;
      let weight = _package.get('grossWeight').value;
      if(this.finalSeaWeightUom == "MT"){
        if(weightUOM == "KG"){
          let kg = this.convertKGtoMT(weight);
          this.totalGrossWeightLCL = kg + this.totalGrossWeightLCL;
        }
        else if(weightUOM == "LBS"){
          let lbs = this.convertLBStoMT(weight);
          this.totalGrossWeightLCL = lbs + this.totalGrossWeightLCL;
        }
        else{
          this.totalGrossWeightLCL = weight + this.totalGrossWeightLCL;
        }
      }
      else if(this.finalSeaWeightUom == "KG"){
        if(weightUOM == "LBS"){
          let lbs = this.convertLBStoKG(weight);
          this.totalGrossWeightLCL = lbs + this.totalGrossWeightLCL;
        }
        else{
          this.totalGrossWeightLCL = weight + this.totalGrossWeightLCL;
        }
      }
      else {
        this.totalGrossWeightLCL = weight + this.totalGrossWeightLCL;
      }
    }
  }

  compareWeightVolume() {
    let chargeableWeight: number = 0.0;
    let metricTon: number = 0.0;
    for (let index = 0; index < this.package_details.length; index++) {
      let _package = this.package_details.at(index)
      if(this.finalSeaWeightUom == "KG"){
        if(_package.get('weightUOM').value == "LBS"){
          let lbs = this.convertLBStoMT(_package.get('grossWeight').value)
          metricTon = lbs + metricTon
        }
        else{
          let kg = this.convertKGtoMT(_package.get('grossWeight').value);
          metricTon = kg + metricTon
        }
      }
      else if(this.finalSeaWeightUom == "MT"){
        if(_package.get('weightUOM').value == "LBS"){
          let lbs = this.convertLBStoMT(_package.get('grossWeight').value)
          metricTon = lbs + metricTon
        }
        else if(_package.get('weightUOM').value == "KG"){
          let lbs = this.convertKGtoMT(_package.get('grossWeight').value)
          metricTon = lbs + metricTon
        }
        else{
          metricTon = _package.get('grossWeight').value + metricTon
        }
      }
      else{
        let lbs = this.convertLBStoMT(_package.get('grossWeight').value);
        metricTon = lbs + metricTon
      }
    }
    if(metricTon > this.totalVolumeWeightLCL) {
      this.finalSeaWeightBool = true;
      chargeableWeight = metricTon
    }
    else{
      this.finalSeaWeightBool = false;
      chargeableWeight = this.totalVolumeWeightLCL
    }
    this._detail.get('chargableWeight').patchValue(chargeableWeight);
    return chargeableWeight;
  }

  calculateBreakBulkWeight(index: number) {
    let break_bulk = this.break_bulk.at(index).value;
    this.totalBreakBulkVolume = break_bulk.totalVolume;
    this.totalBreakBulkWeight = this.convertweightUOMtoMT(break_bulk.uom, break_bulk.totalGrossWeight);
    if(this.totalBreakBulkVolume > this.totalBreakBulkWeight){
      this._detail.get('chargableWeight').patchValue(this.totalBreakBulkVolume);
      this._detail.get('chargableWeightUOM').patchValue("CBM");
      this._detail.get('totalGrossWeight').patchValue(this.totalBreakBulkVolume);
      this._detail.get('totalGrossWeightUOM').patchValue("CBM");
    }
    else{
      this._detail.get('chargableWeight').patchValue(this.totalBreakBulkWeight);
      this._detail.get('chargableWeightUOM').patchValue("MT");
      this._detail.get('totalGrossWeight').patchValue(this.totalBreakBulkWeight);
      this._detail.get('totalGrossWeightUOM').patchValue("MT");
    }
  }

  convertweightUOMtoMT(weightUOM: string, weight: number) {
    let _weight: number = 0.0;
    if (weightUOM == "KG") {
      _weight = (weight / 1000.00);
    }
    else if (weightUOM == "LBS") {
      _weight = (weight / 2204.62);
    }
    else {
      _weight = weight;
    }
    return parseFloat(_weight.toFixed(3));
  }

  checkDecrementUnit(){
    let unit=  this.auctionForm.get('minimumDecrementUnit').value;
    let decrement = this.auctionForm.get('minimumDecrement').value;
    if (unit == "PERCENT" && decrement > 100){
      this.auctionForm.get('minimumDecrement').setValidators([Validators.max(100)]);
      this.auctionForm.get('minimumDecrement').updateValueAndValidity();
      console.log(this.auctionForm.get('minimumDecrement').errors);
      this.minimumDecrementBool = true;
    }
    else{
      this.auctionForm.get('minimumDecrement').clearValidators();
      this.auctionForm.get('minimumDecrement').setValidators([Validators.required]);
      this.auctionForm.get('minimumDecrement').updateValueAndValidity();
      this.minimumDecrementBool = false;
      console.log(this.auctionForm.get('minimumDecrement').errors);
    }
  }

  checkAuctionBasePrice(){
    let basePrice = this.auctionForm.get('basePrice').value;
    let targetPrice = this.auctionForm.get('targetPrice').value;
    let type = this.auctionForm.get('type').value;
    if(type == "r"){
      if(targetPrice >= basePrice){
        this.targetPriceBool = true;
      }
      else{
        this.targetPriceBool = false;
      }
    }
    else{
      console.log("NO TYPE");
    }
  }

  getExtensionTimeBool(){
    let extendTimeBool = this.auctionForm.get('extendTimeBool').value;
    let extendedTime = this.auctionForm.get('extendedTime');
    let bidSubmitDurationInLastMinutes = this.auctionForm.get('bidSubmitDurationInLastMinutes');
    let auctionTimeExtensionLimit = this.auctionForm.get('auctionTimeExtensionLimit');
    if(extendTimeBool == true){
      this.timeExtensionBool = true;
      extendedTime.setValidators([Validators.required]);
      bidSubmitDurationInLastMinutes.setValidators([Validators.required]);
      auctionTimeExtensionLimit.setValidators([Validators.required]);
    }
    else{
      this.timeExtensionBool = false;
      extendedTime.patchValue(null);
      extendedTime.clearValidators();
      bidSubmitDurationInLastMinutes.clearValidators();
      auctionTimeExtensionLimit.clearValidators();
    }
    this.auctionForm.updateValueAndValidity();
  }

  //fuction to scroll to top on next button click
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  changeNatureOfMovement(){
    let natureOfMovement = this._detail.get('natureOfMovement').value;
    this.pickUpIncoTermsArray = [];
    this.dropOffIncoTermsArray = [];
    // Filter pickUp
    for (let index = 0; index < this.pickup_terms.length; index++) {
      const element = this.pickup_terms[index];
      let natureArray: Array<any> = element.natureOfMovement;
      natureArray.filter((x) => {
        if(x.code == natureOfMovement){
          this.pickUpIncoTermsArray.push(element);
        }
      })
    }
    // Filter dropOff
    for (let index = 0; index < this.dropoff_terms.length; index++) {
      const element = this.dropoff_terms[index];
      let natureArray: Array<any> = element.natureOfMovement;
      natureArray.filter((x) => {
        if(x.code == natureOfMovement){
          this.dropOffIncoTermsArray.push(element);
        }
      })
    }
  }

  validateFilesAtSubmit(){
    let submitBool = false;
    let totalFilesSize = 0;
    if(this.documentList.length >= 1){
      for (let index = 0; index < this.documentList.length; index++) {
        const document = this.documentList[index];
        const fileSize = Math.round((document.size / 1024));
        totalFilesSize = fileSize + totalFilesSize;
        if (document.label == undefined) {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Add label to file " + document.name,
            timer: 5000
          })
          submitBool = true;
          break
        }
        else if (fileSize > 2048) {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Upload File :" +  document.name + " with size less than 2MB",
            timer: 5000
          });
          submitBool = true;
          break
        }
        else if(totalFilesSize > 10240){
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Total File size should be size less than 10MB",
            timer: 5000
          });
          submitBool = true;
          break
        }
      }
    }
    return submitBool
  }

  checkDocumentListValidation(){
    let submitBool = false;
    let totalFilesSize = 0;
    for (let index = 0; index < this.documentList.length; index++) {
      const document = this.documentList[index];
      const fileSize = Math.round((document.size / 1024));
      totalFilesSize = fileSize + totalFilesSize;
      if (document.label == undefined) {
        Swal.fire({
          titleText: "Error",
          icon: "error",
          text: "Add label to file, " + document.name,
          timer: 5000
        });
        submitBool = true;
        break
      }
      else if(fileSize > 2048){
        Swal.fire({
          titleText: "Error",
          icon: "error",
          text: "File : " + document.name + " should be size less than 2MB",
          timer: 5000
        });
        submitBool = true;
        break
      }
      else if(totalFilesSize > 10240){
        Swal.fire({
          titleText: "Error",
          icon: "error",
          text: "Total File size should be size less than 10MB",
          timer: 5000
        });
        submitBool = true;
        break
      }
      else if (document.artifactId == undefined || null && fileSize < 2048 && totalFilesSize < 10240 && document.label != undefined) {
        this.uploadFile(index);
      }
    }
    return submitBool
  }

  getAllBusinessType() {
    this.masterTableService.getAllBusinessType().subscribe(
      (response) => {

        this.vendorBusinessTypeList = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  fetchPortsByModeofShipmentCountry(countryId, modeOfShipmentId, type?:string){
    this.masterTableService.fetchPortsByModeOfShipmentAndCountryId(countryId, modeOfShipmentId).subscribe(
      (response: Array<any>) => {
        if(type == "loading"){
          this.loadingPorts = response;
        }
        else if(type == "destination"){
          this.destinationPorts = response
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getCountry(){
    this.masterTableService.getAllCountries().subscribe(
      (response) => {
        this.countries = response;
      },
      (error) => {
        console.log(error);
      }, 
    )
  }

  getSearchResultsOrigin(){
    let modeOfShipmentValue = this.details.modeOfShipment.value
    let size = 50
    if(this.searchOriginValue != ""){
      this.masterTableService.getSearchPort(modeOfShipmentValue, this.searchOriginValue, size).subscribe((res)=>{
        this.loadingPorts = res
      },
      (error)=>{
        console.log(error);
      })
    } else {
      console.log('search value is empty ', this.searchOriginValue);
    }
  }

  getSearchResultDestination(){
    let modeOfShipmentValue = this.details.modeOfShipment.value
    let size = 50
    if(this.searchDestinationValue != ""){
      this.masterTableService.getSearchPort(modeOfShipmentValue, this.searchDestinationValue, size).subscribe((res)=>{
        this.destinationPorts = res
      },
      (error)=>{
        console.log(error);
      })
    } else {
      console.log('search value is empty ', this.searchDestinationValue);
    }
  }

  validateAuctionDate(){
    let auctionDate = new Date(this.auctionForm.get('auctionDate').value);
    var nowDate= new Date();
    let currentTime = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate(), nowDate.getHours(), nowDate.getMinutes());
    if(auctionDate <= currentTime){
      this.auctionForm.get('auctionDate').setErrors({'invalid': true});
      this.backDatedAuctionBool = true;
    }
    else{
      this.auctionForm.get('auctionDate');
      this.auctionForm.get('auctionDate').setErrors(null);
      this.auctionForm.get('auctionDate').invalid;
      this.backDatedAuctionBool = false;
    }
  }

  addAllVendors(event){
    let value = event.target.checked;
    let assignedVendors: Array<any> = this.dataSource.data;
    if(value) {
      for (let index = 0; index < assignedVendors.length; index++) {
        const vendor = assignedVendors[index];
        vendor.flag = true;
        this.addVendors(vendor.id, index, vendor.flag);
      }
    }
    else {
      for (let index = 0; index < assignedVendors.length; index++) {
        const vendor = assignedVendors[index];
        vendor.flag = false;
        this.addVendors(vendor.id, index, vendor.flag);
      }
    }
  }

}
