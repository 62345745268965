import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MasterTableService } from 'src/app/helpers/master-table.service';
import Swal from 'sweetalert2';
import { ChargeBoxComponent } from './charge-box/charge-box.component';

@Component({
  selector: 'app-additional-charge-type',
  templateUrl: './additional-charge-type.component.html',
  styleUrls: ['./additional-charge-type.component.css']
})
export class AdditionalChargeTypeComponent implements OnInit {


  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['chargeName', 'chargeType', 'modeOfShipment', 'loadChargeType', 'action'];
  serchFilter: Boolean = false;
  nodata: Boolean = true;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  page = 0;
  size = 25;
  chargeTypeLength: number;

  sessionStorageUserData: any
  disableCreateBtn: boolean = true
  disableEditBtn: boolean = true
  disableDeleteBtn: boolean = true

  constructor(private dialog: MatDialog, private masterService: MasterTableService) { }

  ngOnInit(): void {
    this.getAllchargeType();
    this.getCurrentUserDataFromSessionStorage();
  }

  openDialog(action, obj) {
    obj.action = action;
    const dialogRef = this.dialog.open(ChargeBoxComponent, {
      width: '250px',
      data: obj
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      console.log(result.data);
      if (result.event == 'Create') {
        this.addChargeType(result.data);
      } else if (result.event == 'Update') {
        this.updateChargeType(result.data.id, result.data);
      } else if (result.event == 'Delete') {
        this.deleteChargeType(result.data.id);
      }
    });
  }

  getAllchargeType() {
    this.masterService.getChargeTypePageable(this.page, this.size).subscribe(
      (response) => {
        if(response.content != null){
          this.dataSource = new MatTableDataSource(response.content);
          this.chargeTypeLength = response.totalElements;
          this.dataSource.sort = this.sort;
        }
      },
      (error) => {
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      })
  }

  addChargeType(data) {
    this.masterService.addChargeType(data).subscribe(
      (response) => {
        console.log(response);
        if (response.code == 201) {
          Swal.fire({
            title: 'Success',
            icon: 'success',
            timer: 3000,
            text: response.message
          })
          this.getAllchargeType();
        }
      },
      (error) => {
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
      }
    )
  }

  updateChargeType(id, data) {
    this.masterService.updateChargeType(id, data).subscribe(
      (response) => {
        if (response.code == 200) {
          Swal.fire({
            title: 'Success',
            icon: 'success',
            timer: 3000,
            text: response.message
          })
          this.getAllchargeType();
        }
      },
      (error) => {
        console.log(error);
      }
    )
  }

  deleteChargeType(id) {
    this.masterService.deleteChargeType(id).subscribe(
      (response) => {
        if (response.code == 200) {
          Swal.fire({
            title: 'Success',
            icon: 'success',
            timer: 3000,
            text: response.message
          })
          this.getAllchargeType();
        }
      },
      (error) => {
        console.log(error);
      }
    )
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.serchFilter = true;
    this.nodata = false;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  onPageChange(event: PageEvent) {
    this.size = event.pageSize;
    this.page = event.pageIndex;
    this.getAllchargeType();
  }

  getCurrentUserDataFromSessionStorage(){
    // create auth code
    // RB0000107
    // update auth code
    // RB0000110
    // delete auth code
    // RB0000111
      this.sessionStorageUserData = JSON.parse(sessionStorage.getItem('userData'));
      console.log('this is user data ', this.sessionStorageUserData);
      for (let index = 0; index < this.sessionStorageUserData.authorities.length; index++) {
        const authority = this.sessionStorageUserData.authorities[index];
        console.log(authority);
        if(authority == 'RB0000107'){
          console.log('create authority is given');
          this.disableCreateBtn = false
        } else if(authority == 'RB0000110'){
          console.log('update authority is given');
          this.disableEditBtn = false
        } else if(authority == 'RB0000111') {
          console.log('delete authority is given');
          this.disableDeleteBtn = false
        }
      }
    }

}
