import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SocketService } from '../helpers/socket.service';

@Component({
  selector: 'app-current-previous-quote-comparison-dialog',
  templateUrl: './current-previous-quote-comparison-dialog.component.html',
  styleUrls: ['./current-previous-quote-comparison-dialog.component.css']
})
export class CurrentPreviousQuoteComparisonDialogComponent implements OnInit {

  airBool: Boolean = false;
  seaBool: Boolean = false;
  constructor( private socketService: SocketService,
    public dialogRef: MatDialogRef<CurrentPreviousQuoteComparisonDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data

  ) { }

  ngOnInit(): void {

    console.log("Data", this.data);
    this.checkSeaAirQuote();
    // this.socketService.connectTOServerPopUpFuction();
  }

  checkSeaAirQuote(){
    let mos = this.data.currentQuote.vendorQuote.enquiry.modeOfShipment;
    if(mos == 1){
      this.airBool = true;
    }
    else if(mos == 2){
      this.seaBool = true;
    }
  }

}
