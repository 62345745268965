import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MasterTableService } from 'src/app/helpers/master-table.service';
import { CargoBoxComponent } from './cargo-box/cargo-box.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-type-of-cargo',
  templateUrl: './type-of-cargo.component.html',
  styleUrls: ['./type-of-cargo.component.css']
})
export class TypeOfCargoComponent implements OnInit {

  displayedColumns: string[] = ['id', 'value', 'actions']
  dataSource = new MatTableDataSource();
  serchFilter: Boolean = false;
  nodata: Boolean = true;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  length: number;
  size: number = 25;
  page: number = 0;

  sessionStorageUserData: any

  disableCreateBtn : boolean = true
  disableEditBtn : boolean = true
  disableDeleteBtn : boolean = true

  constructor(private masterTableService: MasterTableService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getAllCargo();
    this.getCurrentUserDataFromSessionStorage();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
    this.serchFilter=true;
    this.nodata=false;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDialog(action, obj) {
    obj.action = action
    const dialogRef = this.dialog.open(CargoBoxComponent, {
      width: '250px',
      data: obj
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == 'Add') {
        this.addCargo(result.data);
        this.getAllCargo();
      } else if (result.event == 'Update') {
        this.updateCargo(result.data.id, result.data.value);
        this.getAllCargo();
      } else if (result.event == 'Delete') {
        this.deleteCargo(result.data.id);
        this.getAllCargo();
      }
    });
  }

  getAllCargo() {
    this.masterTableService.getTypeOfCargoPageable(this.page, this.size).subscribe(
      (response) => {
        if (response.content != null) {
          this.dataSource = new MatTableDataSource(response.content);
          this.dataSource.sort = this.sort
          this.length = response.totalElements;
        }
      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      }
    )
  }

  addCargo(data: any) {
    let _data = { "value": data.value }
    this.masterTableService.addTypeOfCargo(_data).subscribe(
      (response) => {
        if (response.code == 201) {
          Swal.fire({
            titleText: "Success",
            icon: "success",
            text: response.message,
            timer: 5000
          })
        }
        setTimeout(() => {
          this.getAllCargo();
        }, 500);
      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      }
    );
  }

  updateCargo(id: any, data: any) {
    let _data = { "id": id, "value": data };
    this.masterTableService.updateTypeOfCargo(id, _data).subscribe((response) => {
      if (response.code == 201) {
        Swal.fire({
          titleText: "Success",
          icon: "success",
          text: response.message,
          timer: 5000
        })
      }
      setTimeout(() => {
        this.getAllCargo();
      }, 500);
    },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      }
    );
  }

  deleteCargo(id: any) {
    this.masterTableService.deleteTypeOfCargo(id).subscribe((response) => {
      if (response.code == 201) {
        Swal.fire({
          titleText: "Success",
          icon: "success",
          text: response.message,
          timer: 5000
        })
      }
      setTimeout(() => {
        this.getAllCargo();
      }, 500);
    },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      }
    );
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  onPageChange(event: PageEvent) {
    this.size = event.pageSize;
    this.page = event.pageIndex;
    this.getAllCargo();
  }

  getCurrentUserDataFromSessionStorage(){
    //create type of cargo
    // RB0000056
    //update type of cargo
    // RB0000059
    //delete type of cargo
    // RB0000087
    this.sessionStorageUserData = JSON.parse(sessionStorage.getItem('userData'));
    console.log('this is user data ', this.sessionStorageUserData);
    for (let index = 0; index < this.sessionStorageUserData.authorities.length; index++) {
      const authority = this.sessionStorageUserData.authorities[index];
      console.log(authority);
      if(authority == 'RB0000056'){
        console.log('create authority is given');
        this.disableCreateBtn = false
      } else if(authority == 'RB0000059'){
        console.log('update authority is given');
        this.disableEditBtn = false
      } else if(authority == 'RB0000087') {
        console.log('delete authority is given');
        this.disableDeleteBtn = false
      }
    }
  }
}




