<!--header start-->
<app-header></app-header>
<!--banner start -->
<section class="banner text-center text-white d-flex align-items-center banner-full" id="home-banner"
  style="background-image: url(assets/images/banner.jpg)">
  <div class="container position-relative">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="heading-dual">
          <h1>LogSolutec</h1>
          <h2>Digital Procurement</h2>
        </div>
        <p class="text-center">Bottlenecks never again with with streamlined logistics and supply chain operations.</p><a class="btn btn-success" [routerLink]="['/services']">Explore
          Now
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 12L21 12" stroke="white" stroke-width="1.5" stroke-linecap="round"></path>
            <path d="M15 18L20.6464 12.3536C20.8417 12.1583 20.8417 11.8417 20.6464 11.6464L15 6" stroke="white"
              stroke-width="1.5" stroke-linecap="round"></path>
          </svg></a>
      </div>
    </div>
  </div>
</section>
<!--banner end-->
<!--about section start-->
<section class="about-wrapper text-dark py-8" id="about-us">
  <div class="container-fluid p-lg-start-end">
    <div class="row justify-content-around">
      <div class="col-lg-6 col-xxl-5">
        <div class="heading-dual">
          <h6>ABOUT LOGSOLUTEC</h6>
          <h1>About</h1>
          <h3>Welcome to LogSolutec’s <span class="text-success">Online Platform </span><span
              class="text-primary">Logistic Experience </span>Made Simpler for you!</h3>
        </div>
        <p>LogSolutec is a ERP+CRM module software that provides logistic solutions to companies operating in the field of logistics and shipping. Our software is a one-of-a-kind combination of data science and artificial intelligence that assists firms in achieving efficient logistical management while not costing a fortune. We at LogSolutec are constantly working to improve the logistics sector by eliminating miscommunication and lowering manual labor.</p>
            <h5><span class="text-primary">LogSolutec's</span> Core Values: </h5>
        <ul>
          <li>Trust</li>
          <li>Transparency</li>
          <li>Transformation</li>
        </ul>
        <p>Our success is determined with the extension of brands we have helped with our technology-driven solutions. We’re here to solve your business logistics challenges and to deliver knockout-results. </p>
      </div>
      <div class="col-lg-6 col-xxl-4"><img class="img-fluid  " src="assets/images/about.png" alt="about us" />
      </div>
    </div>
  </div>
</section>
<!--about section end-->
<!--services section start-->
<section class="services-wrapper text-dark py-8" id="services">
  <div class="container-fluid p-lg-start-end">
    <div class="row">
      <div class="col-lg-4 mb-3 mb-lg-4">
        <div class="heading-dual">
          <h6>SERVICES</h6>
          <h1>Services</h1>
          <h2>Services Segment </h2>
        </div><a [routerLink]="['/services']" class="btn btn-outline-success text-dark mt-4 mt-lg-5" href="index.html">All Services
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 12L21 12" stroke="white" stroke-width="1.5" stroke-linecap="round"></path>
            <path d="M15 18L20.6464 12.3536C20.8417 12.1583 20.8417 11.8417 20.6464 11.6464L15 6" stroke="white"
              stroke-width="1.5" stroke-linecap="round"></path>
          </svg></a>
      </div>
      <div class="col-lg-8 mb-3 mb-lg-4">
        <p> <b>A software solution which understands your business needs when it comes to procurement, track and trace, logistics support, integration and backend support. Seek real-time visibility with the agile platform. </b></p>
        <p>With years of experience across all modes of transportation, LogSolutec has got your business covered under one umbrella.</p>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-6 col-lg-3 text-center pt-5 pt-sm-5">
        <div class="img-wrap"><img class="img-fluid  " src="assets/images/procurement.png"
            alt="Procurement" />
        </div>
        <div class="position-relative d-inline-block">
          <div class="service-index">01</div>
          <h3> 01  Procurement</h3>
        </div>
      </div>
      <div class="col-sm-6 col-lg-3 text-center pt-5 pt-sm-5">
        <div class="img-wrap"><img class="img-fluid  " src="assets/images/track-trace.png" alt="Trace & Trace" />
        </div>
        <div class="position-relative d-inline-block">
          <div class="service-index">02</div>
          <h3>02  Track and Trace &nbsp;&nbsp;</h3>
        </div>
      </div>
      <div class="col-sm-6 col-lg-3 text-center pt-5 pt-sm-5">
        <div class="img-wrap"><img class="img-fluid  " src="assets/images/logistic-support-2.png" alt="Logistic Support" />
        </div>
        <div class="position-relative d-inline-block">
          <div class="service-index">&nbsp;&nbsp;&nbsp;03&nbsp;&nbsp;</div>
          <h3>03  Logistics Support</h3>
        </div>
      </div>
      <div class="col-sm-6 col-lg-3 text-center pt-5 pt-sm-5">
        <div class="img-wrap"><img class="img-fluid  " src="assets/images/logistic-support.png" alt="Integration" />
        </div>
        <div class="position-relative d-inline-block">
          <div class="service-index">04</div>
          <h3>04  Integration</h3>
        </div>
      </div>
    </div>
  </div>
</section>
<!--services section end-->
<!--subscription section start-->
<section class="subscription-wrapper pt-6 pb-10 bg-dark text-white" id="subscription">
  <div class="container">
    <div class="heading-dual text-center">
      <h6>SUBSCRIPTION</h6>
      <h1>Subscription</h1>
      <h2>Are you ready to experience the boundless technology? We’ve got the perfect customized plan for every business type.  <span class="text-success"> </span></h2>
    </div>
    <div class="row pt-4 pt-lg-5">
      <div class="col-sm-6 col-md-4 pt-4 pt-lg-5 mx-auto">
        <div class="plan-box mx-auto">
          <h3>Bronze Plan</h3>
          <ul>
            <li>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 13L8.64645 17.6464C8.84171 17.8417 9.15829 17.8417 9.35355 17.6464L20 7" stroke="white"
                  stroke-width="1.5" stroke-linecap="round"></path>
              </svg><span>1 warehouse</span>
            </li>
            <li>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 13L8.64645 17.6464C8.84171 17.8417 9.15829 17.8417 9.35355 17.6464L20 7" stroke="white"
                  stroke-width="1.5" stroke-linecap="round"></path>
              </svg><span>Custom business rules</span>
            </li>
            <li>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 13L8.64645 17.6464C8.84171 17.8417 9.15829 17.8417 9.35355 17.6464L20 7" stroke="white"
                  stroke-width="1.5" stroke-linecap="round"></path>
              </svg><span>Real-time rate shopping</span>
            </li>
            <li>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 13L8.64645 17.6464C8.84171 17.8417 9.15829 17.8417 9.35355 17.6464L20 7" stroke="white"
                  stroke-width="1.5" stroke-linecap="round"></path>
              </svg><span>100 freight shipments</span>
            </li>
          </ul><a class="btn btn-outline-white mt-4 mt-lg-5" href="index.html">FRee trial
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3 12L21 12" stroke="white" stroke-width="1.5" stroke-linecap="round"></path>
              <path d="M15 18L20.6464 12.3536C20.8417 12.1583 20.8417 11.8417 20.6464 11.6464L15 6" stroke="white"
                stroke-width="1.5" stroke-linecap="round"></path>
            </svg></a>
        </div>
      </div>
      <div class="col-sm-6 col-md-4 pt-4 pt-lg-5 mx-auto">
        <div class="plan-box mx-auto">
          <h3>Gold Plan</h3>
          <ul>
            <li>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 13L8.64645 17.6464C8.84171 17.8417 9.15829 17.8417 9.35355 17.6464L20 7" stroke="white"
                  stroke-width="1.5" stroke-linecap="round"></path>
              </svg><span>1 warehouse</span>
            </li>
            <li>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 13L8.64645 17.6464C8.84171 17.8417 9.15829 17.8417 9.35355 17.6464L20 7" stroke="white"
                  stroke-width="1.5" stroke-linecap="round"></path>
              </svg><span>Custom business rules</span>
            </li>
            <li>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 13L8.64645 17.6464C8.84171 17.8417 9.15829 17.8417 9.35355 17.6464L20 7" stroke="white"
                  stroke-width="1.5" stroke-linecap="round"></path>
              </svg><span>Real-time rate shopping</span>
            </li>
            <li>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 13L8.64645 17.6464C8.84171 17.8417 9.15829 17.8417 9.35355 17.6464L20 7" stroke="white"
                  stroke-width="1.5" stroke-linecap="round"></path>
              </svg><span>100 freight shipments</span>
            </li>
          </ul><a class="btn btn-outline-white mt-4 mt-lg-5" href="index.html">FRee trial
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3 12L21 12" stroke="white" stroke-width="1.5" stroke-linecap="round"></path>
              <path d="M15 18L20.6464 12.3536C20.8417 12.1583 20.8417 11.8417 20.6464 11.6464L15 6" stroke="white"
                stroke-width="1.5" stroke-linecap="round"></path>
            </svg></a>
        </div>
      </div>
      <div class="col-sm-6 col-md-4 pt-4 pt-lg-5 mx-auto">
        <div class="plan-box mx-auto">
          <h3>Silver Plan</h3>
          <ul>
            <li>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 13L8.64645 17.6464C8.84171 17.8417 9.15829 17.8417 9.35355 17.6464L20 7" stroke="white"
                  stroke-width="1.5" stroke-linecap="round"></path>
              </svg><span>1 warehouse</span>
            </li>
            <li>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 13L8.64645 17.6464C8.84171 17.8417 9.15829 17.8417 9.35355 17.6464L20 7" stroke="white"
                  stroke-width="1.5" stroke-linecap="round"></path>
              </svg><span>Custom business rules</span>
            </li>
            <li>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 13L8.64645 17.6464C8.84171 17.8417 9.15829 17.8417 9.35355 17.6464L20 7" stroke="white"
                  stroke-width="1.5" stroke-linecap="round"></path>
              </svg><span>Real-time rate shopping</span>
            </li>
            <li>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 13L8.64645 17.6464C8.84171 17.8417 9.15829 17.8417 9.35355 17.6464L20 7" stroke="white"
                  stroke-width="1.5" stroke-linecap="round"></path>
              </svg><span>100 freight shipments</span>
            </li>
          </ul><a class="btn btn-outline-white mt-4 mt-lg-5" href="index.html">FRee trial
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3 12L21 12" stroke="white" stroke-width="1.5" stroke-linecap="round"></path>
              <path d="M15 18L20.6464 12.3536C20.8417 12.1583 20.8417 11.8417 20.6464 11.6464L15 6" stroke="white"
                stroke-width="1.5" stroke-linecap="round"></path>
            </svg></a>
        </div>
      </div>
    </div>
  </div>
</section>
<!--subscription section end-->
<!--features section start-->
<section class="features-wrapper py-8" id="features">
  <div class="container-fluid p-lg-start-end">
    <div class="row">
      <div class="col-lg-4">
        <div class="heading-dual">
          <h6>FEATURES</h6>
          <h1>Features</h1>
          <h2>Why Choose  </h2>
          <h2> <span class="text-success">LogSolutec?  </span></h2>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid p-lg-start-end">
    <div class="row px-sm-4">
      <div class="col-sm-6 col-lg-3 pt-4 pt-sm-5">
        <div class="inner mb-3 mb-sm-4"><img class="img-fluid  " src="assets/images/cloud-storage.png" alt="Cloud Storage" />
        </div>
        <div>
          <h3>Easy accessibility </h3>
          <p class="lead mt-3">Managing logistics virtually and stores data on cloud for easy access 
          </p>
        </div>
      </div>
      <div class="col-sm-6 col-lg-3 pt-4 pt-sm-5">
        <div class="inner mb-3 mb-sm-4"><img class="img-fluid  " src="assets/images/business-efficency.png" alt="Support 24/7" />
        </div>
        <div>
          <h3>Support 24/7</h3>
          <p class="lead mt-3">Our dedicated team behind the desks is at your rescue 24 x 7
          </p>
        </div>
      </div>
      <div class="col-sm-6 col-lg-3 pt-4 pt-sm-5">
        <div class="inner mb-3 mb-sm-4"><img class="img-fluid  " src="assets/images/auction.png"
            alt="Auction" />
        </div>
        <div>
          <h3>Exclusive auction platform</h3>
          <p class="lead mt-3">
            Multiple auction platforms with transparent and cost-saving methods to render logistics services
            </p>
        </div>
      </div>
      <div class="col-sm-6 col-lg-3 pt-4 pt-sm-5">
        <div class="inner mb-3 mb-sm-4"><img class="img-fluid  " src="assets/images/setting.png"
            alt="Business Efficency" />
        </div>
        <div>
          <h3>Business efficiency</h3>
          <p class="lead mt-3">
            Improves operations, vouches for shipment visibility, and real-time data
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!--features section end-->
<!--contact section start-->
<section class="contact-wrapper text-white py-3 py-lg-4" id="contact-us">
  <div class="container">
    <div class="row justify-content-between align-items-center">
      <div class="col-lg-5">
        <div class="heading-dual">
          <h6>CONTACT US</h6>
          <h1>Contact</h1>
          <h2>We vouch to improve business processes with new-age technology to resolve challenges. Reach out, today!  <span class="text-success"> </span></h2>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="contact-box p-2 p-sm-3 mt-4 mt-lg-0">
          <form [formGroup]="contactUsForm">
            <h2 class="text-dark">Get In Touch</h2>
            <div class="row">
              <div class="col-sm-6 form-group">
                <label for="name">First Name *</label>
                <input class="form-control" type="text" placeholder="Enter First Name" formControlName="firstName"
                  [ngClass]="{'is-invalid': contact.firstName.invalid && ( contact.firstName.dirty || contact.firstName.touched )}">
                <div class="text-danger validation-message"
                  *ngIf="contact.firstName.invalid && ( contact.firstName.dirty || contact.firstName.touched )">
                  First Name Is Mandatory
                </div>
              </div>
              <div class="col-sm-6 form-group">
                <label for="name">Last Name *</label>
                <input class="form-control" type="text" placeholder="Enter Last Name" formControlName="lastName"
                  [ngClass]="{'is-invalid': contact.lastName.invalid && ( contact.lastName.dirty || contact.lastName.touched )}">
                <div class="text-danger validation-message"
                  *ngIf="contact.lastName.invalid && ( contact.lastName.dirty || contact.lastName.touched )">
                  Last Name Is Mandatory
                </div>
              </div>
              <div class="col-sm-6 form-group">
                <label for="email-id">Email Id *</label>
                <input class="form-control" type="email" placeholder="Enter Email Id" formControlName="emailId"
                  [ngClass]="{'is-invalid': contact.emailId.invalid && ( contact.emailId.dirty || contact.emailId.touched )}">
                <div class="text-danger validation-message"
                  *ngIf="contact.emailId.invalid && ( contact.emailId.dirty || contact.emailId.touched )">
                  Email Id Is Mandatory
                </div>
              </div>
              <div class="col-sm-6 form-group">
                <label for="phone-number">Contact Number *</label>
                <input class="form-control" type="text" id="phone-number" placeholder="Enter Contact Number"
                  formControlName="contactNumber"
                  [ngClass]="{'is-invalid': contact.contactNumber.invalid && ( contact.contactNumber.dirty || contact.contactNumber.touched )}">
                <div class="text-danger validation-message"
                  *ngIf="contact.contactNumber.invalid && ( contact.contactNumber.dirty || contact.contactNumber.touched )">
                  <div [hidden]="!contact.contactNumber.errors.minlength">
                    Minimum 9 Digits Required
                  </div>
                  <div [hidden]="!contact.contactNumber.errors.required">
                    Contact Number is compulsory
                  </div>
                  <div [hidden]="!contact.contactNumber.errors.maxlength">
                    Max 16 Digits Allowed
                  </div>
                </div>
                <div>

                </div>
              </div>
              <div class="col-sm-12 form-group">
                <label for="description">Description *</label>
                <textarea class="form-control" rows="5" placeholder="Enter Message Description"
                  formControlName="description"
                  [ngClass]="{'is-invalid': contact.description.invalid && ( contact.description.dirty || contact.description.touched )}">
                </textarea>
                <div class="text-danger validation-message"
                  *ngIf="contact.description.invalid && ( contact.description.dirty || contact.description.touched )">
                  Description Is Mandatory
                </div>
              </div>
              <div class="col-sm-12 form-group pt-3 pt-lg-4">
                <button class="btn btn-outline-success text-dark font-weight-bold" type="submit"
                  (click)="contactUs()">Send Message
                  <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.57471 10.9422L19.988 10.9422" stroke="#21BB9F" stroke-width="1.36777"
                      stroke-linecap="round"></path>
                    <path
                      d="M14.5168 16.4133L19.6656 11.2646C19.8436 11.0866 19.8436 10.7979 19.6656 10.6198L14.5168 5.47113"
                      stroke="#21BB9F" stroke-width="1.36777" stroke-linecap="round"></path>
                  </svg>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
<!--contact section end-->