<!--header start-->
<header class="box-absolute d-flex align-items-center topline text-white">
    <app-sidebar></app-sidebar>
    <div class="page-title-center mt-3">
        <div class="text-center">
            <h1>Track Container</h1>
        </div>
    </div>
    <div class="breadcrumb-div mt-3">
        <ul class="breadcrumb p-0 m-0">
            <li class="breadcrumb-item 3" id="0"><a [routerLink]="['/v1/dashboard']">Dashboard</a></li>
            <li class="breadcrumb-item 3" id="1"><a href="vendor.html">Track & Trace</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0)">Container</a></li>
        </ul>
    </div>
</header>
<!--header end-->

<section class="banner text-center text-white d-flex" id="bnner">
    <div class="container position-relative">
        <div class="row justify-content-center">
            <div class="col-lg-12">
            </div>
        </div>
    </div>
</section>

<!--Section start-->
<section class="request-demo-wrapper pb-10">
    <div class="container shadow bg-white" (click)="hideSideBar()">
        <div class="row">
            <div class="col-lg-12">
                <div class="box">
                    <form>
                        <!-- Changes in form. -->
                        <div
                            class="filter-wrap btn-group d-flex flex-wrap flex-md-nowrap justify-content-center gap-2 pb-3 pb-lg-4 px-3 px-lg-4">
                            <div class="input-group">
                                <input class="form-control rounded-3 px-5" type="text" matInput
                                    placeholder="Enter Container NO. / BL Reference NO." [(ngModel)]="containterNo" [ngModelOptions]="{standalone: true}" 
                                    (keyup)="onKeyUpEventContainer($event)">
                            </div>
                            <button class="rounded-3 flex-shrink-0" type="button" (click)="searchDetails()">
                                <i class="fa fa-search"></i><span> Search</span>
                            </button>
                        </div>
                        <div class="table-responsive">
                            <div class="bg-lighter">
                                <table class="table table-borderless">
                                    <tr class="row" *ngFor="let row of sailingDetails">
                                        <td class="col-md-4 font-weight-bold">
                                            <label>Sailing Status </label>{{ row.Status || '--' }}
                                        </td>
                                        <td class="col-md-4 font-weight-bold">
                                            <label> First ETA Date </label>{{ row.FirstETA || '--' }}
                                        </td>
                                        <td class="col-md-4 font-weight-bold">
                                            <label> BL Container Count </label> {{ row.BLContainerCount }}
                                        </td>
                                        <td class="col-md-4 font-weight-bold">
                                            <label>BL Reference </label>{{ row.BLReferenceNo || '--' }}
                                        </td>
                                        <td class="col-md-4 font-weight-bold">
                                            <label> Reference No </label> {{ row.ReferenceNo || '--' }}
                                        </td>
                                        <td class="col-md-4 font-weight-bold">
                                            <label>Vessel </label>{{ row.Vessel || '--' }}
                                        </td>
                                        <td class="col-md-4 font-weight-bold">
                                            <label>Vessel IMO </label>{{ row.VesselIMO || '--' }}
                                        </td>
                                        <td class="col-md-4 font-weight-bold">
                                            <label> Carrier </label>{{ row.ShippingLine || '--' }}
                                        </td>
                                        <td class="col-md-4 font-weight-bold">
                                            <label> Container </label> {{ row.ContainerNumber || '--' }}
                                        </td>
                                        <td class="col-md-4 font-weight-bold">
                                            <label>Country(POL) </label>{{ row.FromCountry || '--' }}
                                        </td>
                                        <td class="col-md-4 font-weight-bold">
                                            <label> POL </label>{{ row.Pol || '--' }}
                                        </td>
                                        <td class="col-md-4 font-weight-bold">
                                            <label> Date Of Loading </label> {{ row.LoadingDate.Date || '--' }}
                                        </td>
                                        <td class="col-md-4 font-weight-bold">
                                            <label>Country(POD) </label>{{ row.ToCountry || '--' }}
                                        </td>
                                        <td class="col-md-4 font-weight-bold">
                                            <label> POD </label>{{ row.Pod || '--' }}
                                        </td>
                                        <td class="col-md-4 font-weight-bold">
                                            <label> Date Of Discharge </label> {{ row.DischargeDate.Date || '--' }}
                                        </td>
                                        <td class="col-md-4 font-weight-bold">
                                            <label> Gate Out Date </label>{{ row.GateOutDate || '--' }}
                                        </td>
                                        <td class="col-md-4 font-weight-bold">
                                            <label> Empty Return Date </label> {{ row.EmptyReturnDate || '--' }}
                                        </td>
                                        <td class="col-md-4 font-weight-bold">
                                            <label>Transit Time </label>{{ row.FormatedTransitTime || '--' }}
                                        </td>
                                        <td class="col-md-4 font-weight-bold">
                                            <label> ETA </label>{{ row.ETA || '--' }}
                                        </td>
                                        <td class="col-md-4 font-weight-bold">
                                            <label> NO. BL Containers </label> {{ row.BLContainerCount }}
                                        </td>
                                        <td class="col-md-4">
                                            <div class="row">
                                                <label class="col-md-4" style="font-weight: bold; font-family: 'Avenir Next LT Pro Bold';">Live Track</label>
                                                <div class="col-md-4 font-weight-bold">
                                                    <button (click)="openDialog(row)">
                                                        <span>
                                                            <h5>Track</h5>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                                <br>
                                <table class="table table-borderless">
                                    <tr class="row" *ngFor="let row of sailingDetails">
                                        <td class="col-md-12">
                                            <h5> Transit Ports </h5>
                                            <table class="table table-responsive table-striped table-head-dark">
                                                <thead>
                                                    <tr class="font-weight-bold">
                                                        <th>Port</th>
                                                        <th>Arrival Date</th>
                                                        <th>Departure Date</th>
                                                        <th>Vessel</th>
                                                        <th>Vessel IMO</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let row of row.TSPorts">
                                                        <td>{{row.Port}}</td>
                                                        <td>{{row.ArrivalDate.Date}}</td>
                                                        <td>{{row.DepartureDate.Date}}</td>
                                                        <td>{{row.Vessel}}</td>
                                                        <td>{{row.VesselIMO}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[25, 50, 100]" showFirstLastButtons>
                                </mat-paginator>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>