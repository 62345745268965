import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { MasterTableService } from 'src/app/helpers/master-table.service';

@Component({
  selector: 'app-client-view',
  templateUrl: './client-view.component.html',
  styleUrls: ['./client-view.component.css']
})
export class ClientViewComponent implements OnInit {
  
  local_data: any;
  address: Array<any> = [];
  artifacts: Array<any> = [];
  addressBool = false;
  artifactsBool = false;

  constructor(public dialogRef: MatDialogRef<ClientViewComponent>,  
    @Optional() @Inject(MAT_DIALOG_DATA) public data: MasterTableService, private route: ActivatedRoute) {
      console.log(data);
      this.local_data = { ...data };
      let address: Array<any> = this.local_data.address;
      let artifacts : Array<any> = this.local_data.artifacts;
      if(address.length > 0){
        this.address = address;
        this.addressBool = true;
      }
      if(artifacts.length > 0){
        this.artifacts = artifacts;
        this.artifactsBool = true;

      }
      console.log(this.address);
  }

  ngOnInit(): void {
    // this.route.queryParams.subscribe(params => {
    //   const data = JSON.parse(params["data"]);
    //   console.log(data);
    // });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  downloadFileLocal(item) {
    console.log(item);
    if (item.id != null && item.label != null) {
      this.dataURItoBlob(item);
    }
  }

  dataURItoBlob(data) {
    const linkSource = data.content;
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = data.name;
    downloadLink.click();
  }


}
