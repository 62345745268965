import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MasterTableService } from 'src/app/helpers/master-table.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-container-dialog-box',
  templateUrl: './container-dialog-box.component.html',
  styleUrls: ['./container-dialog-box.component.css']
})
export class ContainerDialogBoxComponent implements OnInit {

  action: string;
  local_data: any;
  dialogForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<ContainerDialogBoxComponent>, public fb: FormBuilder,
    //@Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: MasterTableService) {
    console.log(data);
    this.local_data = { ...data };
    this.action = this.local_data.action; { }

  }

  ngOnInit(): void {
    this.dialogForm = this.fb.group({
      value: ['']
    });
    if (this.action != "Add") {
      this.dialogForm.addControl('id', new FormControl());
      this.dialogForm.patchValue({
        id: this.local_data.id,
        value: this.local_data.value
      })
    }
  }

  doAction() {
    if (this.dialogForm.valid) {
      this.dialogRef.close({ event: this.action, data: this.dialogForm.value });
    } else {
      Swal.fire({
        title: "warning",
        icon: "warning",
        titleText: "Value is Required",
        timer: 3000
      })
    }
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

}
