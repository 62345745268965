<!--header start-->
<div class="box-absolute d-flex align-items-center topline text-white">
    <button class="btn-opensidebar" type="button">
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M38 24H22" stroke="white" stroke-width="2" stroke-linecap="round"></path>
            <path d="M38 14H10" stroke="white" stroke-width="2" stroke-linecap="round"></path>
            <path d="M38 34H14" stroke="white" stroke-width="2" stroke-linecap="round"></path>
        </svg>
    </button>
    <ul class="breadcrumb p-0 m-0">
        <li class="breadcrumb-item 3" id="0"><a href="dashboard.html">Dashboard</a></li>
        <li class="breadcrumb-item 3" id="1"><a href="auction.html">Auction</a></li>
        <li class="breadcrumb-item"><a href="javascript:void(0)">Auction Comparison</a></li>
    </ul>
</div>
<!--header end-->
<section class="banner text-center text-white d-flex" id="bnner">
    <div class="container position-relative">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <h1>Hierarchy Master</h1>
            </div>
        </div>
    </div>
</section>
<!--request start-->
<section class="request-demo-wrapper small pb-10">
    <div class="container shadow bg-white">
        <div class="row">
            <div class="col-lg-12">
                <div class="box">
                    <form>
                        <div class="position-relative d-flex flex-wrap align-items-center add-new-wrap mb-2">
                            <div class="flex-grow-1">
                                <h4 class="mb-0"></h4>
                            </div>
                            <div class="position-absolute end-0 top-50 translate-middle-y">
                                <button class="btn-add-new text-dark btn-outline-success" type="button"> <i
                                        class="fa fa-plus me-1"></i><span>New Row</span>
                                </button>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-borderless table-striped table-head-dark text-center">
                                <thead>
                                    <tr class="e-small">
                                        <th>
                                            <input class="form-check-input" type="checkbox" id="" name="option1">
                                        </th>
                                        <th>SerOrder Hierarchy
                                        </th>
                                        <th>User Role
                                        </th>
                                        <th>Creator
                                        </th>
                                        <th>Approver
                                        </th>
                                        <th>Status
                                        </th>
                                        <th>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <input class="form-check-input" type="checkbox" id="" name="option1">
                                        </td>
                                        <td>
                                            <input class="form-control" type="text" value="SerOrder Hierarchy 1" id=""
                                                readonly="readonly">
                                        </td>
                                        <td>
                                            <input class="form-control" type="text" value="Buyer" id=""
                                                readonly="readonly">
                                        </td>
                                        <td>
                                            <select class="form-control form-select border-0" id="">
                                                <option>Yes</option>
                                                <option>No</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select class="form-control form-select border-0" id="">
                                                <option>No</option>
                                                <option>Yes</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select class="form-control form-select border-0 font-weight-bold" id="">
                                                <option>Active</option>
                                                <option>Inactive</option>
                                            </select>
                                        </td>
                                        <td>
                                            <button class="fa fa-trash text-primary lead" type="button">
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <input class="form-check-input" type="checkbox" id="" name="option1">
                                        </td>
                                        <td>
                                            <input class="form-control" type="text" value="---" id=""
                                                readonly="readonly">
                                        </td>
                                        <td>
                                            <input class="form-control" type="text" value="Role 1" id=""
                                                readonly="readonly">
                                        </td>
                                        <td>
                                            <select class="form-control form-select border-0" id="">
                                                <option>Yes</option>
                                                <option>No</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select class="form-control form-select border-0" id="">
                                                <option>No</option>
                                                <option>Yes</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select class="form-control form-select border-0 font-weight-bold" id="">
                                                <option>Active</option>
                                                <option>Inactive</option>
                                            </select>
                                        </td>
                                        <td>
                                            <button class="fa fa-trash text-primary lead" type="button">
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <input class="form-check-input" type="checkbox" id="" name="option1">
                                        </td>
                                        <td>
                                            <input class="form-control" type="text" value="---" id=""
                                                readonly="readonly">
                                        </td>
                                        <td>
                                            <input class="form-control" type="text" value="Role 3" id=""
                                                readonly="readonly">
                                        </td>
                                        <td>
                                            <select class="form-control form-select border-0" id="">
                                                <option>Yes</option>
                                                <option>No</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select class="form-control form-select border-0" id="">
                                                <option>No</option>
                                                <option>Yes</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select class="form-control form-select border-0 font-weight-bold" id="">
                                                <option>Active</option>
                                                <option>Inactive</option>
                                            </select>
                                        </td>
                                        <td>
                                            <button class="fa fa-trash text-primary lead" type="button">
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <input class="form-check-input" type="checkbox" id="" name="option1">
                                        </td>
                                        <td>
                                            <input class="form-control" type="text" value="---" id=""
                                                readonly="readonly">
                                        </td>
                                        <td>
                                            <input class="form-control" type="text" value="Role 4" id=""
                                                readonly="readonly">
                                        </td>
                                        <td>
                                            <select class="form-control form-select border-0" id="">
                                                <option>Yes</option>
                                                <option>No</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select class="form-control form-select border-0" id="">
                                                <option>No</option>
                                                <option>Yes</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select class="form-control form-select border-0 font-weight-bold" id="">
                                                <option>Active</option>
                                                <option>Inactive</option>
                                            </select>
                                        </td>
                                        <td>
                                            <button class="fa fa-trash text-primary lead" type="button">
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <input class="form-check-input" type="checkbox" id="" name="option1">
                                        </td>
                                        <td>
                                            <input class="form-control" type="text" value="---" id=""
                                                readonly="readonly">
                                        </td>
                                        <td>
                                            <input class="form-control" type="text" value="Role 4" id=""
                                                readonly="readonly">
                                        </td>
                                        <td>
                                            <select class="form-control form-select border-0" id="">
                                                <option>Yes</option>
                                                <option>No</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select class="form-control form-select border-0" id="">
                                                <option>No</option>
                                                <option>Yes</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select class="form-control form-select border-0 font-weight-bold" id="">
                                                <option>Active</option>
                                                <option>Inactive</option>
                                            </select>
                                        </td>
                                        <td>
                                            <button class="fa fa-trash text-primary lead" type="button">
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <input class="form-check-input" type="checkbox" id="" name="option1">
                                        </td>
                                        <td>
                                            <input class="form-control" type="text" value="SerOrder Hierarchy 1" id=""
                                                readonly="readonly">
                                        </td>
                                        <td>
                                            <input class="form-control" type="text" value="Role 5" id=""
                                                readonly="readonly">
                                        </td>
                                        <td>
                                            <select class="form-control form-select border-0" id="">
                                                <option>Yes</option>
                                                <option>No</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select class="form-control form-select border-0" id="">
                                                <option>No</option>
                                                <option>Yes</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select class="form-control form-select border-0 font-weight-bold" id="">
                                                <option>Active</option>
                                                <option>Inactive</option>
                                            </select>
                                        </td>
                                        <td>
                                            <button class="fa fa-trash text-primary lead" type="button">
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <input class="form-check-input" type="checkbox" id="" name="option1">
                                        </td>
                                        <td>
                                            <input class="form-control" type="text" value="---" id=""
                                                readonly="readonly">
                                        </td>
                                        <td>
                                            <input class="form-control" type="text" value="Role 6" id=""
                                                readonly="readonly">
                                        </td>
                                        <td>
                                            <select class="form-control form-select border-0" id="">
                                                <option>Yes</option>
                                                <option>No</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select class="form-control form-select border-0" id="">
                                                <option>No</option>
                                                <option>Yes</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select class="form-control form-select border-0 font-weight-bold" id="">
                                                <option>Active</option>
                                                <option>Inactive</option>
                                            </select>
                                        </td>
                                        <td>
                                            <button class="fa fa-trash text-primary lead" type="button">
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <input class="form-check-input" type="checkbox" id="" name="option1">
                                        </td>
                                        <td>
                                            <input class="form-control" type="text" value="---" id=""
                                                readonly="readonly">
                                        </td>
                                        <td>
                                            <input class="form-control" type="text" value="Role 7" id=""
                                                readonly="readonly">
                                        </td>
                                        <td>
                                            <select class="form-control form-select border-0" id="">
                                                <option>Yes</option>
                                                <option>No</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select class="form-control form-select border-0" id="">
                                                <option>No</option>
                                                <option>Yes</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select class="form-control form-select border-0 font-weight-bold" id="">
                                                <option>Active</option>
                                                <option>Inactive</option>
                                            </select>
                                        </td>
                                        <td>
                                            <button class="fa fa-trash text-primary lead" type="button">
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <input class="form-check-input" type="checkbox" id="" name="option1">
                                        </td>
                                        <td>
                                            <input class="form-control" type="text" value="---" id=""
                                                readonly="readonly">
                                        </td>
                                        <td>
                                            <input class="form-control" type="text" value="Buyer" id=""
                                                readonly="readonly">
                                        </td>
                                        <td>
                                            <select class="form-control form-select border-0" id="">
                                                <option>Yes</option>
                                                <option>No</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select class="form-control form-select border-0" id="">
                                                <option>No</option>
                                                <option>Yes</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select class="form-control form-select border-0 font-weight-bold" id="">
                                                <option>Inactive</option>
                                                <option>Active</option>
                                            </select>
                                        </td>
                                        <td>
                                            <button class="fa fa-trash text-primary lead" type="button">
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <input class="form-check-input" type="checkbox" id="" name="option1">
                                        </td>
                                        <td>
                                            <input class="form-control" type="text" value="---" id=""
                                                readonly="readonly">
                                        </td>
                                        <td>
                                            <input class="form-control" type="text" value="Role 8" id=""
                                                readonly="readonly">
                                        </td>
                                        <td>
                                            <select class="form-control form-select border-0" id="">
                                                <option>Yes</option>
                                                <option>No</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select class="form-control form-select border-0" id="">
                                                <option>No</option>
                                                <option>Yes</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select class="form-control form-select border-0 font-weight-bold" id="">
                                                <option>Active</option>
                                                <option>Inactive</option>
                                            </select>
                                        </td>
                                        <td>
                                            <button class="fa fa-trash text-primary lead" type="button">
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-md-6 col-lg-6 form-group pt-lg-3">
                                <button class="btn small btn-success px-2" type="button">SAVE</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!--request end -->