import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { InvoiceService } from '../helpers/invoice.service';

@Component({
  selector: 'app-vendor-pre-shipment-invoices',
  templateUrl: './vendor-pre-shipment-invoices.component.html',
  styleUrls: ['./vendor-pre-shipment-invoices.component.css']
})
export class VendorPreShipmentInvoicesComponent implements OnInit {

  title = "Pre Shipment Invoices";
  page: number = 0;
  size: number = 25;
  invoiceLength: number;
  // ,'createdBy'
  displayedColumns: string[] = ['number', 'placeOfDelivery', 'portOfLoading', 'portOfDischarge', 'createdDate', 'countryOfOriginOfGoods', 'countryOfFinalDestination', 'action'];
  dataSource = new MatTableDataSource();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(private invoiceService: InvoiceService) { }

  ngOnInit(): void {
    this.getAllPreShipmentInvoices();
  }

  getAllPreShipmentInvoices(){
    this.invoiceService.getAllPreShipmentInvoice(this.page, this.size).subscribe(
      (response) => {
        console.log(response);
        if (response.content != null) {
          this.dataSource = new MatTableDataSource(response.content);
          this.invoiceLength = response.totalElements;
          this.dataSource.sort = this.sort;
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  onPageChange(event: PageEvent) {
    this.size = event.pageSize;
    this.page = event.pageIndex;
    this.getAllPreShipmentInvoices();
  }


  downloadPDF(id, type){
    console.log(id, type);
    this.invoiceService.downloadPreShipmentInvoicePDF(id, type).subscribe(
      (response) => {
        console.log(response);
        if (response) {
          var file = new Blob([response], { type: 'application/pdf' });
          console.log(file)
          var fileURL = URL.createObjectURL(file);
          var a = document.createElement('a');
          a.href = fileURL;
          a.target = '_blank';
          a.download = `PreShipmentInvoice-${type}-${id}.pdf`
          document.body.appendChild(a);
          a.click();
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

}
