<section class="wrapper multi-step-form">
    <div class="container-fluid">
        <div class="row">
            <div
                class="col-md-4 col-xl-3 col-left pt-12 d-flex flex-column justify-content-center position-sticky top-0">
                <div class="box-absolute d-flex">
                </div>
                <div class="box mx-auto">
                    <h3 class="text-center text-md-start">Vendor Onboarding</h3>
                </div>
            </div>
            <div class="col-md-8 col-xl-9 col-right px-start-end pb-6">
            </div>
        </div>
    </div>
</section>