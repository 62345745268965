import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OnboardService } from 'src/app/helpers/onboard.service';
import Swal from 'sweetalert2';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';


@Component({
  selector: 'app-vendor-document',
  templateUrl: './vendor-document.component.html',
  styleUrls: ['./vendor-document.component.css']
})
export class VendorDocumentComponent implements OnInit {

  vendor_id: number = parseInt(sessionStorage.getItem('org_id'));
  files: Array<any> = [];
  logoArray: Array<any> = [];
  waterMarkArray: Array<any> = [];
  signatureArray: Array<any> = [];
  math = Math;

  constructor(private router: Router, private vendorService: OnboardService) { }

  ngOnInit(): void {
    this.getAllVendorDocuments();
    this.gotoTop();
  }

  getAllVendorDocuments() {
    this.vendorService.getAllVendorArtifacts(this.vendor_id).subscribe(
      (response: Array<any>) => {
        if (response.length >= 1) {
          for (let index = 0; index < response.length; index++) {
            const document = response[index];
            if (document.label == "Logo") {
              let _data = {
                "label": document.label,
                "content": document.content,
                "contentType": document.contentType,
                "name": document.name,
                "artifactId": document.id
              }
              this.logoArray.push(_data);
            }
            else if (document.label == "Watermark") {
              let _data = {
                "label": document.label,
                "content": document.content,
                "contentType": document.contentType,
                "name": document.name,
                "artifactId": document.id
              }
              this.waterMarkArray.push(_data);
            }
            else if (document.label == "Digital Signature") {
              let _data = {
                "label": document.label,
                "content": document.content,
                "contentType": document.contentType,
                "name": document.name,
                "artifactId": document.id
              }
              this.signatureArray.push(_data);
            }
            else {
              let _data = {
                "label": document.label,
                "content": document.content,
                "contentType": document.contentType,
                "name": document.name,
                "artifactId": document.id
              }
              this.files.push(_data);
            }
          }
        }
        else {
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  public updateVendor() {
    let submitBool = false;
    let totalFilesSize = 0;
    // Files Upload
    for (let index = 0; index < this.files.length; index++) {
      const document = this.files[index];
      const fileSize = Math.round((document.size / 1024));
      totalFilesSize = fileSize + totalFilesSize;
      if (document.label == undefined) {
        Swal.fire({
          titleText: "Error",
          icon: "error",
          text: "Add label to file " + document.name,
          timer: 5000
        });
        submitBool = true;
        break;
      }
      else if (fileSize > 2048) {
        Swal.fire({
          titleText: "Error",
          icon: "error",
          text: "Upload File :" +  document.name + " with size less than 2MB",
          timer: 5000
        });
        submitBool = true;
        break
      }
      else if(totalFilesSize > 10240){
        Swal.fire({
          titleText: "Error",
          icon: "error",
          text: "Total File size should be size less than 10MB",
          timer: 5000
        });
        submitBool = true; 
        break
      }
      else if(submitBool == false && document.artifactId == null){
        this.uploadDocument(index);
      };
    }
    // Logo Upload
    for (let index = 0; index < this.logoArray.length; index++) {
      const document = this.logoArray[index];
      const fileSize = Math.round((document.size / 1024));
      totalFilesSize = fileSize + totalFilesSize;
      if(fileSize > 1024){
        Swal.fire({
          titleText: "Error",
          icon: "error",
          text: "Upload File :" +  document.name + " with size less than 1MB",
          timer: 5000
        });
        submitBool = true;
        break
      }
      else{
        this.uploadLogoWaterMark(index, document);
      }
    }
    // WaterMark Upload
    for (let index = 0; index < this.waterMarkArray.length; index++) {
      const document = this.waterMarkArray[index];
      const fileSize = Math.round((document.size / 1024));
      totalFilesSize = fileSize + totalFilesSize;
      if(fileSize > 1024){
        Swal.fire({
          titleText: "Error",
          icon: "error",
          text: "Upload File :" +  document.name + " with size less than 1MB",
          timer: 5000
        });
        submitBool = true;
        break
      }
      else{
        this.uploadLogoWaterMark(index, document);
      }
    }

    // Signature Upload
    for (let index = 0; index < this.signatureArray.length; index++) {
      const document = this.signatureArray[index];
      const fileSize = Math.round((document.size / 1024));
      totalFilesSize = fileSize + totalFilesSize;
      if(fileSize > 1024){
        Swal.fire({
          titleText: "Error",
          icon: "error",
          text: "Upload File :" +  document.name + " with size less than 1MB",
          timer: 5000
        });
        submitBool = true;
        break
      }
      else{
        this.uploadLogoWaterMark(index, document);
      }
    }
    if(submitBool == false){
      this.router.navigate(['/v1/vendor-onboard/certification']);
    }
  }

  public dropped(files: NgxFileDropEntry[]) {
    for (let index = 0; index < files.length; index++) {
      const droppedFile = files[index];
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          // Here you can access the real file size & content
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            let data = {
              "content": reader.result,
              "contentType": reader.result?.toString().split(',')[0],
              "name": file.name,
              "size": file.size
            }
            this.files.push(data);
          }
        })
      }
      else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }

  uploadDocument(index: number) {
    const element = this.files[index];
    this.vendorService.vendorCreateArtifact(this.vendor_id, element).subscribe(
      (response) => {
        if (response.code == 201) {
          this.files[index]['artifactId'] = response.entityId
        }
      },
      (error) => {
        console.log(error);
      }
    )
  }

  addSignature(files: NgxFileDropEntry[]){
    if (files.length > 1) {
      Swal.fire({
        titleText: "Warning",
        icon: "warning",
        timer: 5000,
        text: "Only one file allowed to upload"
      })
    }
    if (this.signatureArray.length == 1) {
      Swal.fire({
        titleText: "Warning",
        icon: "warning",
        timer: 5000,
        text: "Delete the uploaded file, first. Only one file allowed to upload"
      })
    }
    else {
      for (let index = 0; index < files.length; index++) {
        const droppedFile = files[index];
        // Is it a file?
        if (droppedFile.fileEntry.isFile) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          fileEntry.file((file: File) => {
            const fileSize = Math.round((file.size / 1024));
            if(fileSize > 1024){
              Swal.fire({
                titleText: "Error",
                icon: "error",
                text: "Upload File :" +  file.name + " with size less than 1MB",
                timer: 5000
              });
            }
            else{
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => {
                let data = {
                  "content": reader.result,
                  "contentType": reader.result?.toString().split(',')[0],
                  "name": file.name,
                  "size": file.size,
                  "label": "Digital Signature"
                }
                this.signatureArray.push(data);
            }
          }
          })
        }
        else {
          // It was a directory (empty directories are added, otherwise only files)
          const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        }
      }
    }
  }

  deleteDocument(index: number) {
    this.files.splice(index, 1);
  }

  deleteServerArtifact(artifactId, index) {
    this.vendorService.vendorDeleteArtifact(artifactId, this.vendor_id).subscribe(
      (response) => {
        console.log(response);
        if (response.code == 200) {
          Swal.fire({
            titleText: "Success",
            text: response.message,
            icon: "success",
            timer: 5000
          });
          this.deleteDocument(index);
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  addLogo(files: NgxFileDropEntry[]) {
    if (files.length > 1) {
      Swal.fire({
        titleText: "Warning",
        icon: "warning",
        timer: 5000,
        text: "Only one file allowed to upload"
      })
    }
    if (this.logoArray.length == 1) {
      Swal.fire({
        titleText: "Warning",
        icon: "warning",
        timer: 5000,
        text: "Delete the uploaded file, first. Only one file allowed to upload"
      })
    }
    else {
      for (let index = 0; index < files.length; index++) {
        const droppedFile = files[index];
        // Is it a file?
        if (droppedFile.fileEntry.isFile) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          fileEntry.file((file: File) => {
            console.log(file);
            const fileSize = Math.round((file.size / 1024));
            if(fileSize > 1024){
              Swal.fire({
                titleText: "Error",
                icon: "error",
                text: "Upload File :" +  file.name + " with size less than 1MB",
                timer: 5000
              });
            }
            else {
              // Here you can access the real file size & content
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => {
                let data = {
                  "content": reader.result,
                  "contentType": reader.result?.toString().split(',')[0],
                  "name": file.name,
                  "size": file.size,
                  "label": "Logo"
                }
                this.logoArray.push(data);
              }
            }

          })
        }
        else {
          // It was a directory (empty directories are added, otherwise only files)
          const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        }
      }
    }
  }
  addWatermark(files: NgxFileDropEntry[]) {
    if (files.length > 1) {
      Swal.fire({
        titleText: "Warning",
        icon: "warning",
        timer: 5000,
        text: "Only one file allowed to upload"
      })
    }
    if (this.waterMarkArray.length == 1) {
      Swal.fire({
        titleText: "Warning",
        icon: "warning",
        timer: 5000,
        text: "Delete the uploaded file, first. Only one file allowed to upload"
      })
    }
    else {
      for (let index = 0; index < files.length; index++) {
        const droppedFile = files[index];
        // Is it a file?
        if (droppedFile.fileEntry.isFile) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          fileEntry.file((file: File) => {
            const fileSize = Math.round((file.size / 1024));
            if(fileSize > 1024){
              Swal.fire({
                titleText: "Error",
                icon: "error",
                text: "Upload File :" +  file.name + " with size less than 1MB",
                timer: 5000
              });
            }
            else {
              // Here you can access the real file size & content
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => {
                let data = {
                  "content": reader.result,
                  "contentType": reader.result?.toString().split(',')[0],
                  "name": file.name,
                  "size": file.size,
                  "label": "Watermark"
                }
                this.waterMarkArray.push(data);
              }
            }
          })
        }
        else {
          // It was a directory (empty directories are added, otherwise only files)
          const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        }
      }
    }
  }

  uploadLogoWaterMark(index: number, object: any) {
    let data: any;
    if (object.label == "Logo") {
      data = this.logoArray[index];
    }
    else if (object.label == "Watermark") {
      data = this.waterMarkArray[index];
    }
    else if (object.label == "Digital Signature") {
      data = this.signatureArray[index];
    }
    if(data.artifactId == null){
      this.vendorService.vendorCreateArtifact(this.vendor_id, data).subscribe(
        (response) => {
          object.artifactId = response.entityId;
        },
        (error) => {
          console.log(error);
        },
      )
    }
  }

  deleteServerLogoWatermark(artifactId, index, object: any) {
    let data = {};
    if (object.label == "Logo") {
      data = this.logoArray[index];
    }
    else if (object.label == "Watermark") {
      data = this.waterMarkArray[index];
    }
    else if (object.label == "Digital Signature") {
      data = this.signatureArray[index];
    }
    this.vendorService.vendorDeleteArtifact(artifactId, this.vendor_id).subscribe(
      (response) => {
        if (response.code == 200) {
          Swal.fire({
            titleText: "Success",
            text: response.message,
            icon: "success",
            timer: 5000
          });
          if (object.label == "Logo") {
            this.logoArray.splice(index, 1);
          }
          else if (object.label == "Watermark") {
            this.waterMarkArray.splice(index, 1);
          }
          else if (object.label == "Digital Signature") {
            this.signatureArray.splice(index, 1);
          }
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  deleteLocalLogo(index){
    this.logoArray.splice(index, 1)
  }

  deleteLocalWatermark(index){
    this.waterMarkArray.splice(index, 1)
  }

  deleteLocalSignature(index){
    this.signatureArray.splice(index, 1)
  }

  //fuction to scroll to top on next button click
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

}
