import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OnboardService {

  AUTH_API: string = `https://${window.location.hostname}:9003/api/v1`
  constructor(private http: HttpClient) { }

  createClient(data: any): Observable<any> {
    return this.http.post(`${this.AUTH_API}/onboarding/client`, data);
  }

  verifyToken(token: string): Observable<any> {
    let params = {'t': token};
    return this.http.get(`${this.AUTH_API}/onboarding/client/token`, {params: params});
  }

  updateClient(data: any): Observable<any> {
    return this.http.put(`${this.AUTH_API}/onboarding/client`, data);
  }

  artifactUpload(data: any): Observable<any> {
    return this.http.post(`${this.AUTH_API}/onboarding/artifact`, data);
  }


  vendorCreateArtifact(vendorId, data: any): Observable<any> {
    return this.http.post(`${this.AUTH_API}/onboarding/vendor/${vendorId}/artifact`, data);
  }
  vendorDeleteArtifact(id: any, vendorId: any): Observable<any> {
    return this.http.delete(`${this.AUTH_API}/onboarding/vendor/${vendorId}/artifact/${id}`);
  }
  getAllVendorArtifacts(vendorId): Observable<any> {
    return this.http.get(`${this.AUTH_API}/onboarding/vendor/${vendorId}/artifacts`);
  }

  clientCreateArtifact(clientId, data: any): Observable<any> {
    return this.http.post(`${this.AUTH_API}/onboarding/client/${clientId}/artifact`, data);
  }
  clientDeleteArtifact(id: any, clientId): Observable<any> {
    return this.http.delete(`${this.AUTH_API}/onboarding/client/${clientId}/artifact/${id}`);
  }
  getAllClientArtifacts(clientId): Observable<any> {
    return this.http.get(`${this.AUTH_API}/onboarding/client/${clientId}/artifacts`);
  }

  getAllVendors(page: any, size: any, status?: any, businessType?: number): Observable<any> {
    let params = {'page': page, 'size': size};
    if(status){
      params['onboardingStatus'] = status;
    }
    if(businessType){
      params['businessType'] = businessType;
    }
    return this.http.get(`${this.AUTH_API}/onboarding/vendors`, {params: params});
  }


  createVendor(data: any): Observable<any> {
    return this.http.post(`${this.AUTH_API}/onboarding/vendor`, data);
  }

  updateVendor(data: any): Observable<any> {
    return this.http.put(`${this.AUTH_API}/onboarding/vendor`, data);
  }

  vendorVerifyToken(token: any): Observable<any> {
    let params = {'t': token};
    return this.http.get(`${this.AUTH_API}/onboarding/vendor/token`, {params: params});
  }

  getMessageTemplate(page: any, size: any): Observable<any> {
    let param = new HttpParams().set('page', page).set('size', size);
    return this.http.get(`${this.AUTH_API}/parameter/messageTemplates`, {params: param});
  }

  createMessageTemplate(data): Observable<any> {
    return this.http.post(`${this.AUTH_API}/parameter/messageTemplate`, data);
  }

  updateMessageTemplate(id, data): Observable<any> {
    return this.http.put(`${this.AUTH_API}/parameter/messageTemplate/${id}`, data);
  }

  deleteMessageTemplate(id): Observable<any> {
    return this.http.delete(`${this.AUTH_API}/parameter/messageTemplate/${id}`);
  }

  getAllClientParam(page: any, size: any): Observable<any> {
    const params = new HttpParams().set('page', page).set('size', size);
    return  this.http.get(`${this.AUTH_API}/parameter/clientParams`, {params: params});
  }

  createClientParam(data: any): Observable<any> {
    return this.http.post(`${this.AUTH_API}/parameter/clientParam`, data);
  }

  updateClientParam(id: any, data: any): Observable<any> {
    return this.http.put(`${this.AUTH_API}/parameter/clientParam/${id}`, data);
  }

  getAllclientListing(page, size): Observable<any> {
    const params = new HttpParams().set('page', page).set('size', size);
    return this.http.get(`${this.AUTH_API}/onboarding/clients`, {params: params});
  }

  getAllPlatformParam(page, size): Observable<any> {
    const params = new HttpParams().set('page', page).set('size', size);
    return this.http.get(`${this.AUTH_API}/parameter/platformParams`, {params: params});
  }

  createPlatformParam(data): Observable<any> {
    return this.http.post(`${this.AUTH_API}/parameter/platformParam`, data);
  }

  updatePlatformParam(id, data): Observable<any> {
    return this.http.put(`${this.AUTH_API}/parameter/platformParam/${id}`, data);
  }

  getVendorsListing():Observable<any>{
    return this.http.get(`${this.AUTH_API}/onboarding/vendorsListing`);
  }

  deleteClientOrVendor(orgId: any): Observable<any>{
    return this.http.delete(`${this.AUTH_API}/onboarding/org/${orgId}`)
  }

}


// http://localhost:9003/api/v1/onboarding/vendorsListing?onboardingStatus=COMPLETE&businessType=4