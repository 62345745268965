import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuctionService {

  redirectUrl: string = "";
  AUTH_API = `https://${window.location.hostname}:9004/api/v1`;

  constructor(private http: HttpClient) { }

  createAuction(data: any): Observable<any>{
    return this.http.post(`${this.AUTH_API}/procurement/auction`, data);
  }

  fetchAuctionById(id: number): Observable<any>{
    return this.http.get(`${this.AUTH_API}/procurement/auction/${id}`);
  }

  updateAuctionById(id: number, data: any): Observable<any>{
    return this.http.put(`${this.AUTH_API}/procurement/auction/${id}`, data);
  }

  getAllAuctions(page: any, size: any, type: string, status: string):Observable<any>{
    let params = { 'page': page, 'size': size}
    if(type != null){
      params['type'] = type;
    }
    if(status != null){
      params['status'] = status;
    }
    return this.http.get(`${this.AUTH_API}/procurement/auctions`, {params: params});
  }

  fetchVendorBidById(id: any): Observable<any>{
    const params = new HttpParams().set('isParticipating', 'true')
    return this.http.get(`${this.AUTH_API}/procurement/vendorbidbyauction/${id}`, {params: params});
  }

  fetchVendorBidByIdParticipatingFalse(id: any): Observable<any>{
    const params = new HttpParams().set('isParticipating', 'false')
    return this.http.get(`${this.AUTH_API}/procurement/vendorbidbyauction/${id}`, {params: params});
  }

  createVendorBid(data): Observable<any>{
    return this.http.post(`${this.AUTH_API}/procurement/vendorbid`, data);
  }

  updateVendorBid(id, data): Observable<any>{
    return this.http.put(`${this.AUTH_API}/procurement/vendorbid/${id}`, data);
  }

  createAuctionArtifact(auctionId, data):Observable<any>{
    return this.http.post(`${this.AUTH_API}/procurement/auction/${auctionId}/artifact`, data);
  }

  deleteAuctionArtifact(auctionId, artifactId):Observable<any>{
    return this.http.delete(`${this.AUTH_API}/procurement/auction/${auctionId}/artifact/${artifactId}`);
  }

  getAllAuctionArtifact(auctionId):Observable<any>{
    return this.http.get(`${this.AUTH_API}/procurement/auction/${auctionId}/artifacts`);
  }

  getAllQuotesByEnquiryId(enquiryId):Observable<any>{
    return this.http.get(`${this.AUTH_API}/procurement/enquiry/${enquiryId}/quotes`);
  }

  getAllRecentAuctions(page: any, size: any, type: string):Observable<any>{
    const params = new HttpParams().set('page', page).set('size', size).set('type', type);
    return this.http.get(`${this.AUTH_API}/procurement/recentAuctions`, {params: params});
  }

  getAllUpcomingAuctions(page: any, size: any, type: string):Observable<any>{
    const params = new HttpParams().set('page', page).set('size', size).set('type', type);
    return this.http.get(`${this.AUTH_API}/procurement/upcomingAuctions`, {params: params});
    // 
  }

  updateAuctionStatus(id: number, data: any): Observable<any>{
    return this.http.put(`${this.AUTH_API}/procurement/auctionStatus/${id}`, data);
  }

  // getAllAuctionsStatusInProgress():Observable<any>{
  //   return this.http.get(this.AUTH_API + '/api/v1/procurement/statusInProgress');
  // }

  updateAuctionTimeByClient(auctionId: any, data: any): Observable<any>{
    return this.http.put(`${this.AUTH_API}/procurement/auctionEndTime/${auctionId}`, data);
  }

  GetAllNoShowVendorsByAuctionId(auctionId: number): Observable<any>{
    return this.http.get(`${this.AUTH_API}/procurement/noShowVendorsByAuction/${auctionId}`);
  }

  getVendorBidBydBidId(bidId: number): Observable<any> {
    return this.http.get(`${this.AUTH_API}/procurement/vendorbidbyid/${bidId}`);
  }

  acceptRejectAuctionTerms(auctionId: number, data: any): Observable<any> {
    return this.http.post(`${this.AUTH_API}/procurement/auctionTermsAndConditon/${auctionId}`, data)
  }
}
