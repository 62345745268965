<section class="wrapper multi-step-form">
    <div class="container-fluid">
        <div class="row">
            <div
                class="col-md-4 col-xl-3 col-left pt-12 d-flex flex-column justify-content-center position-sticky top-0">
                <app-sidebar></app-sidebar>
                <div class="box mx-auto">
                    <h3>Client Onboarding</h3>
                    <ul class="list-unstyled">
                        <li class="active">
                            <div class="icon">
                                <svg width="13" height="9" viewBox="0 0 13 9" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M1 5L3.97978 7.97978C4.17504 8.17504 4.49162 8.17504 4.68689 7.97978L11.6667 1"
                                        stroke="#003D90" stroke-width="1.5" stroke-linecap="round"></path>
                                </svg>
                            </div><span>Company Details</span>
                        </li>
                        <li class="active">
                            <div class="icon">
                                <svg width="13" height="9" viewBox="0 0 13 9" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M1 5L3.97978 7.97978C4.17504 8.17504 4.49162 8.17504 4.68689 7.97978L11.6667 1"
                                        stroke="#003D90" stroke-width="1.5" stroke-linecap="round"></path>
                                </svg>
                            </div><span>Document Uploadation</span>
                        </li>
                        <li class=" ">
                            <div class="icon">
                            </div><span>Sign Up</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-8 col-xl-9 col-right px-start-end pb-6">
                <div class="topline pt-4 pt-lg-5 pb-3 pb-lg-4 d-flex align-items-center justify-content-between">
                    <ul class="breadcrumb p-0 m-0">
                        <li class="breadcrumb-item 3 brand" id="1"><button [routerLink]="['/v1/client-onboard/details']"
                                [queryParams]="{ t:pre_token }">Back</button></li>
                    </ul>
                    <h3 class="text-center text-primary ">Document Uploadation</h3>
                    <div> <span>2/3</span></div>
                </div>

                <div class="row">
                    <div class="col-lg-9 mt-2">
                        <div class="ngx-box">
                            <ngx-file-drop dropZoneClassName="file-drop" dropZoneLabel="Drop files here"
                                (onFileDrop)="dropped($event)" multiple="true"
                                accept=".jpg, .png, .pdf, .docx, .txt, .jpeg">
                                <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                                    <div class="text-center justify-content-between">
                                        <div class="col-lg-12 pt-5 mt-5">
                                            <i class="far fa-file-alt fa-3x"></i>
                                        </div>
                                        <div class="col-lg-12 pt-2 pb-2">
                                            Upload Multiple Files By Drag & Drop OR Click Browse Files
                                        </div>
                                        <div class="col-lg-12">
                                            <button class="btn btn-success" type="button"
                                                (click)="openFileSelector()">Browse Files</button>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-file-drop>
                        </div>
                        <div class="table-responsive mt-4">
                            <table class="table table-borderless table-striped table-head-dark table-head-condensed">
                                <thead>
                                    <tr>
                                        <th>Sr.No</th>
                                        <th>File Name</th>
                                        <th>Label</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of files; index as i">
                                        <td>{{ i + 1 }}</td>
                                        <td>{{ item.name }}</td>
                                        <td>
                                            <input type="text" [(ngModel)]="item.label">
                                        </td>
                                        <td>
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <button title="Oversized" class="text-danger" *ngIf="(math.round(item.size) /1024) > 2048">
                                                        <i class="fas fa-ban" aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                                <div class="col-md-3">
                                                    <button title="Uploaded" class="text-primary" *ngIf="item.artifactId">
                                                        <i class="fa fa-check" aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                                <div class="col-md-3">
                                                    <button title="Delete" *ngIf="item.artifactId; else localDelete">
                                                        <i class="fa fa-trash color-blue"
                                                            (click)="deleteServerArtifact(item.artifactId)"></i>
                                                    </button>
                                                    <button title="Delete" *ngIf="!item.artifactId">
                                                        <i class="fa fa-trash color-blue" (click)="deleteDocument(i)"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <hr>

                        <div class="row justify-content-center mt-4">
                            <div class="col-lg-12">
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title">Upload Logo</h5>
                                        <div class="ngx-box">
                                            <ngx-file-drop dropZoneClassName="file-drop" dropZoneLabel="Drop files here"
                                                (onFileDrop)="addLogo($event)" accept=".jpg, .png, .jpeg, .svg">
                                                <ng-template ngx-file-drop-content-tmp
                                                    let-openFileSelector="openFileSelector">
                                                    <div class="text-center justify-content-between">
                                                        <div class="col-lg-12 pt-5 mt-5">
                                                            <i class="far fa-file-alt fa-3x"></i>
                                                        </div>
                                                        <div class="col-lg-12 pt-2 pb-2">
                                                            Upload File By Drag & Drop OR Click Browse File
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <button class="btn btn-success" type="button"
                                                                (click)="openFileSelector()">Browse File</button>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </ngx-file-drop>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive mt-4">
                            <form>
                                <table
                                    class="table table-borderless table-striped table-head-dark table-head-condensed">
                                    <thead>
                                        <tr>
                                            <th>Sr.No</th>
                                            <th>File Name</th>
                                            <th>Label</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of logoArray; index as i">
                                            <td>{{ i + 1 }}</td>
                                            <td>{{ item.name }}</td>
                                            <td>
                                                <input type="text" [(ngModel)]="item.label"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                            </td>
                                            <td>
                                                <button class="text-primary" *ngIf="item.artifactId">
                                                    <i class="fa fa-check" aria-hidden="true"></i>
                                                </button>
                                                <button *ngIf="item.artifactId; else logolocalDelete">
                                                    <i class="fa fa-trash color-blue"
                                                        (click)="deleteServerLogoWatermark(item.artifactId, i, item)"></i>
                                                </button>
                                                <ng-template #logolocalDelete>
                                                    <button><i class="fa fa-trash color-blue"
                                                            (click)="deleteLocalLogo(i)"></i></button>
                                                </ng-template>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </form>
                        </div>

                        <div class="row justify-content-center mt-4">
                            <div class="col-lg-12">
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title">Upload Watermark</h5>
                                        <div class="ngx-box">
                                            <ngx-file-drop dropZoneClassName="file-drop" dropZoneLabel="Drop files here"
                                                (onFileDrop)="addWatermark($event)" accept=".jpg, .png, .jpeg, .svg">
                                                <ng-template ngx-file-drop-content-tmp
                                                    let-openFileSelector="openFileSelector">
                                                    <div class="text-center justify-content-between">
                                                        <div class="col-lg-12 pt-5 mt-5">
                                                            <i class="far fa-file-alt fa-3x"></i>
                                                        </div>
                                                        <div class="col-lg-12 pt-2 pb-2">
                                                            Upload File By Drag & Drop OR Click Browse File
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <button class="btn btn-success" type="button"
                                                                (click)="openFileSelector()">Browse File</button>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </ngx-file-drop>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive mt-4">
                            <form>
                                <table
                                    class="table table-borderless table-striped table-head-dark table-head-condensed">
                                    <thead>
                                        <tr>
                                            <th>Sr.No</th>
                                            <th>File Name</th>
                                            <th>Label</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of waterMarkArray; index as i">
                                            <td>{{ i + 1 }}</td>
                                            <td>{{ item.name }}</td>
                                            <td>
                                                <input type="text" [(ngModel)]="item.label"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                            </td>
                                            <td>
                                                <button class="text-primary" *ngIf="item.artifactId">
                                                    <i class="fa fa-check" aria-hidden="true"></i>
                                                </button>
                                                <button *ngIf="item.artifactId; else watermarklocalDelete">
                                                    <i class="fa fa-trash color-blue"
                                                        (click)="deleteServerLogoWatermark(item.artifactId, i, item)"></i>
                                                </button>
                                                <ng-template #watermarklocalDelete>
                                                    <button>
                                                        <i class="fa fa-trash color-blue"
                                                            (click)="deleteLocalWatermark(i)"></i>
                                                    </button>
                                                </ng-template>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </form>
                        </div>


                        <div class="row justify-content-center mt-4">
                            <div class="col-lg-12">
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title">Upload Digital Signature</h5>
                                        <div class="ngx-box">
                                            <!-- ToDo -->
                                            <ngx-file-drop dropZoneClassName="file-drop" dropZoneLabel="Drop files here"
                                                (onFileDrop)="addSignature($event)" accept=".jpg, .png, .jpeg, .svg">
                                                <ng-template ngx-file-drop-content-tmp
                                                    let-openFileSelector="openFileSelector">
                                                    <div class="text-center justify-content-between">
                                                        <div class="col-lg-12 pt-5 mt-5">
                                                            <i class="far fa-file-alt fa-3x"></i>
                                                        </div>
                                                        <div class="col-lg-12 pt-2 pb-2">
                                                            Upload File By Drag & Drop OR Click Browse File
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <button class="btn btn-success" type="button"
                                                                (click)="openFileSelector()">Browse File</button>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </ngx-file-drop>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive mt-4">
                            <form>
                                <table
                                    class="table table-borderless table-striped table-head-dark table-head-condensed">
                                    <thead>
                                        <tr>
                                            <th>Sr.No</th>
                                            <th>File Name</th>
                                            <th>Label</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of signatureArray; index as i">
                                            <td>{{ i + 1 }}</td>
                                            <td>{{ item.name }}</td>
                                            <td>
                                                <input type="text" [(ngModel)]="item.label"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                            </td>
                                            <td>
                                                <button class="text-primary" *ngIf="item.artifactId">
                                                    <i class="fa fa-check" aria-hidden="true"></i>
                                                </button>
                                                <button *ngIf="item.artifactId; else signaturelocalDelete">
                                                    <i class="fa fa-trash color-blue"
                                                        (click)="deleteServerLogoWatermark(item.artifactId, i, item)"></i>
                                                </button>
                                                <ng-template #signaturelocalDelete>
                                                    <button>
                                                        <i class="fa fa-trash color-blue"
                                                            (click)="deleteLocalSignature(i)"></i>
                                                    </button>
                                                </ng-template>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </form>
                        </div>

                        <div class="row justify-content-center">
                            <div class="col-md-6 form-group pt-4 pt-lg-5">
                                <button class="btn btn-success w-100" type="submit"
                                    (click)="updateClient()">NEXT</button>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3">
                        <div class="w3-card-4 note-border">
                            <div class="card-header note-bg">
                                <h3 class="text-white text-center">Notes</h3>
                            </div>
                            <div class="card-body bg-white">
                                <ul>
                                    <li class="pb-2">Allowed File are .jpg, .png, .pdf, .docx, .txt, .jpeg, .doc , .xls
                                        , .xlsx
                                    </li>
                                    <li class="pb-2">Allowed File for Watermark & Logo: . jpg, .png, .jpeg, .svg</li>
                                    <li class="pb-2">Logo & Watermark to be uploaded seprately</li>
                                    <li class="pb-2">Upload Multiple files By Drag & Drop OR Click Browser Files</li>
                                    <li class="pb-2">Max size per file 2MB</li>
                                    <li class="pb-2">Logo & Watermark max size per file is 1MB</li>
                                    <li class="pb-2">Max size of all files 10MB</li>
                                    <li class="pb-2">Successfully Uploaded file have icon as :
                                        <i class="fas fa-check color-blue"></i>
                                    </li>
                                    <li>Oversized file have icon as : 
                                        <i class="fas fa-ban text-danger"></i>
                                    </li>
                                    <li>
                                        Delete file have icon as :
                                        <i class="fas fa-trash color-blue"></i>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>