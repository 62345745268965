<section class="wrapper multi-step-form">
    <div class="container-fluid">
        <div class="row">
            <div
                class="col-md-4 col-xl-3 col-left pt-12 d-flex flex-column justify-content-center position-sticky top-0">
                <div class="box-absolute d-flex">
                    <app-sidebar></app-sidebar>
                </div>
                <div class="box mx-auto">
                    <h3>Enquiry Quotation</h3>
                    <ul class="list-unstyled step-one">
                        <li class="active" id="list-item-0">
                            <div class="icon"> <i class="fa fa-check"></i></div><span>RFQ</span>
                        </li>
                        <li class=" " id="list-item-1">
                            <div class="icon"> <i class="fa fa-check"></i></div><span>Rates Details</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-8 col-xl-9 col-right px-start-end pb-6" (click)="hideSideBar()">
                <div class="topline py-start-end d-flex align-items-center justify-content-between mb-4 mb-md-5">
                    <ul *ngIf="breadcrumbBool" class="breadcrumb p-0 m-0">
                        <li class="breadcrumb-item 3 brand" id="0"><button
                                [routerLink]="['/v1/dashboard']">Dashboard</button>
                        </li>
                    </ul>
                    <ul class="breadcrumb p-0 m-0">
                        <li *ngIf="backfirstPage" class="breadcrumb-item 3 brand"><button
                                (click)="goBackRFQ()">Back</button></li>
                        <li *ngIf="backDetailPage" class="breadcrumb-item 3 brand"><button
                                (click)="goBackDetails()">Back</button>
                        </li>
                    </ul>
                    <div class="tab-content step-two">
                        <div class="tab-pane active" id="item-0">1/2</div>
                        <div class="tab-pane" id="item-1">2/2</div>
                    </div>
                </div>
                <div class="tab-content form-step">
                    <div class="tab-pane active" id="item-3">
                        <form [formGroup]="rfqDetails">
                            <h3 class="text-center">RFQ Details</h3>
                            <div class="row"></div>
                            <div class="row justify-content-center">
                                <div class="col-md-6 form-group">
                                    <label for="enquiry_number">Enquiry Number</label>
                                    <input class="form-control" formControlName="number" type="text"
                                        id="enquiry_number" disabled>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label for="enquiry_date">Enquiry Date</label>
                                    <input class="form-control" formControlName="enquiryDate" type="date"
                                        id="enquiry_date" disabled>
                                </div>
                                <div class="col-lg-12 form-group">
                                    <label for="enquiry_description">Description</label>
                                    <textarea class="form-control" formControlName="description" type="text"
                                        id="enquiry_description" rows="3" cols="10" disabled></textarea>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label for="project_name">Project Name</label>
                                    <input class="form-control" formControlName="projectName" type="text"
                                        id="project_name" disabled>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label for="project_code">Project Code</label>
                                    <input class="form-control" formControlName="projectCode" type="text"
                                        id="project_code" disabled>
                                </div>
                            </div>
                            <h3 class="text-center mt-3">Shipment Details</h3>
                            <div class="row">
                                <div class="col-md-6 form-group">
                                    <label for="modeOfShipment">Mode Of Shipment</label>
                                    <select class="form-control form-select" formControlName="modeOfShipment" disabled>
                                        <option *ngFor="let mode of mode_of_shipment" [value]="mode.id">
                                            {{ mode.value }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label for="tradeType">Trade Type</label>
                                    <select class="form-control form-select" formControlName="tradeType" disabled>
                                        <option *ngFor="let trade of trade_type" [value]="trade.id">
                                            {{ trade.value }}
                                        </option>
                                    </select>
                                </div>

                                <div class="col-md-6 form-group">
                                    <label *ngIf="sea_mode_shipment" for="loading-port">Loading Port</label>
                                    <label *ngIf="air_mode_shipment" for="loading-port">Loading Airport</label>
                                    <mat-select #singleSelect class="form-control" formControlName="origin" disabled>
                                        <mat-option>
                                            <ngx-mat-select-search [(ngModel)]="searchOriginValue" [ngModelOptions]="{standalone: true}"
                                            (input)="getSearchResultsOrigin()"
                                            placeholderLabel="Enter Name" noEntriesFoundLabel = 'No result available'></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let item of loadingPorts" [value]="item?.code?.code">
                                            {{ item?.value }}
                                        </mat-option>
                                    </mat-select>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label *ngIf="sea_mode_shipment" for="destination-port">Destination Port</label>
                                    <label *ngIf="air_mode_shipment" for="loading-port">Destination Airport</label>
                                    <mat-select #singleSelect class="form-control" formControlName="destination" disabled>
                                        <mat-option>
                                            <ngx-mat-select-search [(ngModel)]="searchDestinationValue" [ngModelOptions]="{standalone: true}"
                                            (input)="getSearchResultDestination()"
                                            placeholderLabel="Enter Name" noEntriesFoundLabel = 'No result available'></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let item of destinationPorts" [value]="item?.code?.code">
                                            {{ item?.value }}
                                        </mat-option>
                                    </mat-select>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label for="type-of-cargo">Type Of Cargo</label>
                                    <select class="form-control form-select" id="type_cargo"
                                        formControlName="typeOfCargo" disabled> 
                                        <option *ngFor="let type of type_cargo" [value]="type.id">
                                            {{ type.value }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label for="commodity">Commodity</label>
                                    <input class="form-control" formControlName="commodity" type="text" id="commodity" disabled>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label for="storing-type">Storing Type</label>
                                    <input class="form-control" formControlName="storingType" type="text"
                                        id="storing-type" disabled>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label for="nature_of_movement">Nature Of Movement</label>
                                    <select class="form-control form-select" formControlName="natureOfMovement" disabled>
                                        <option *ngFor="let item of nature_of_movement" [value]="item.code">
                                            {{ item.value }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label for="currency">Currency</label>
                                    <input class="form-control" formControlName="currency" type="text" 
                                        id="currency" disabled>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label for="cargoReadinessDate">Cargo Readiness Date</label>
                                    <input class="form-control" formControlName="cargoReadinessDate" type="date"
                                        id="cargoReadinessDate" disabled>
                                </div>

                                <div *ngIf="sea_mode_shipment" class="col-md-4 form-group">
                                    <label for="hs_code">HS Code</label>
                                    <input class="form-control" formControlName="hsCode" type="text" id="hs_code" disabled>
                                </div>
                                <div *ngIf="sea_mode_shipment" class="col-md-4 form-group">
                                    <label for="container_load_type">Container Load Type</label>
                                    <select class="form-control" formControlName="containerLoadType" disabled>
                                        <option *ngFor="let item of container_load_type" [value]="item.id">
                                            {{ item.value }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label for="lastSubmissionDate">Offer Last Submission Date</label>
                                    <input class="form-control" type="datetime-local" formControlName="lastSubmissionDate" disabled>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label for="pick-up-incoterms">PickUp-Terms</label>
                                    <select class="form-control form-select" formControlName="pickUpIncoterms" disabled>
                                        <option *ngFor="let item of pickup_terms" [value]="item.code">
                                            {{ item.value }}
                                        </option>
                                    </select>
                                </div>

                                <!-- Pickup Person name -->
                                <div class="col-md-4 form-group" *ngIf="showPickupAddress">
                                    <label for="pickup-person-name">PickUp Person
                                        Name</label>
                                    <input class="form-control" type="text" placeholder="Text Input" disabled
                                        id="contact-person-name" formControlName="pickupPersonName" />
                                </div>

                                <!-- pickup person address -->
                                <div *ngIf="showPickupAddress">
                                    <div class="row">
                                        <div formGroupName="pickupPersonAddress">
                                            <div class="row">
                                                <div class="col-md-4 form-group">
                                                    <label for="street-address-1">Street Address
                                                        1</label>
                                                    <input class="form-control" type="text" placeholder="Text Input"
                                                        id="street-address-1" formControlName="street1" disabled/>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label for="street-address-2">Street Address
                                                        2</label>
                                                    <input class="form-control" type="text" placeholder="Text Input"
                                                        id="street-address-2" formControlName="street2" disabled/>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label for="city">City</label>
                                                    <input class="form-control" type="text" placeholder="Text Input"
                                                        id="city" formControlName="city" disabled/>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label for="state">State</label>
                                                    <input class="form-control" type="text" placeholder="Text Input"
                                                        id="state" formControlName="state" disabled/>
                                                </div>
                                                <div formGroupName="country" class="col-md-4 form-group">
                                                    <label for="country">Country</label>
                                                    <select id="country" name="country" formControlName="id"
                                                        class="form-control form-select" disabled>
                                                        <option [value]="count.id" *ngFor="let count of countries">
                                                            {{ count.name }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label for="zip-code">Zip Code</label>
                                                    <input class="form-control" type="text" placeholder="Text Input"
                                                        id="zip-code" formControlName="zipCode" disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Delivery Terms -->
                                <div class="col-md-4 form-group">
                                    <label for="delivery-incoterms">Delivery-Terms</label>
                                    <select class="form-control form-select" formControlName="deliveryIncoterms" disabled>
                                        <option *ngFor="let item of dropoff_terms" [value]="item.code">
                                            {{ item.value }}
                                        </option>
                                    </select>
                                </div>

                                <!--Delivery contact Person -->
                                <div class="col-md-4 form-group" *ngIf="delivaryAddress">
                                    <label>Delivery Person Name</label>
                                    <input class="form-control" type="text" placeholder="Text Input" id="personName"
                                        formControlName="deliveryPersonName" disabled/>
                                </div>

                                <!-- Delivery person address -->
                                <div *ngIf="delivaryAddress">
                                    <div class="row">
                                        <!-- Adress -->
                                        <div formGroupName="deliveryPersonAddress">
                                            <div class="row">
                                                <div class="col-md-4 form-group">
                                                    <label for="street-address-1">Street Address
                                                        1</label>
                                                    <input class="form-control" type="text" placeholder="Text Input"
                                                        id="street-address-1" formControlName="street1" disabled/>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label for="street-address-2">Street Address
                                                        2</label>
                                                    <input class="form-control" type="text" placeholder="Text Input"
                                                        id="street-address-2" formControlName="street2" disabled/>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label for="city">City</label>
                                                    <input class="form-control" type="text" placeholder="Text Input"
                                                        id="city" formControlName="city" disabled/>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label for="state">State</label>
                                                    <input class="form-control" type="text" placeholder="Text Input"
                                                        id="state" formControlName="state" disabled/>
                                                </div>
                                                <div formGroupName="country" class="col-md-4 form-group">
                                                    <label for="country">Country</label>
                                                    <select id="country" name="country" formControlName="id" disabled
                                                        class="form-control form-select">
                                                        <option [value]="count.id" *ngFor="let count of countries">
                                                            {{ count.name }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label for="zipCode">ZipCode</label>
                                                    <input class="form-control" type="text" placeholder="Text Input"
                                                        id="zipCode" formControlName="zipCode" disabled/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Destination Terminal -->
                                <div class="col-md-4 form-group" *ngIf="showTerminal">
                                    <label>Destination Terminal</label>
                                    <input class="form-control" type="text" placeholder="Text Input"
                                        id="destinationTerminal" formControlName="destinationTerminal" disabled />

                                </div>
                                <br>

                                  <!-- Third Country Controls -->
                                <!-- exporter name -->
                                <div class="col-md-4 form-group" *ngIf="isThirdCountrySelected">
                                    <label class="form-label">Exporter Name</label>
                                    <input class="form-control" type="text" placeholder="Text Input" id="personName"
                                        formControlName="exporterName" disabled/>
                                </div>

                                <!-- exporter email id -->
                                <div class="col-md-4 form-group" *ngIf="isThirdCountrySelected">
                                    <label class="form-label">Exporter Mail Id</label>
                                    <input class="form-control" type="text" placeholder="Text Input" id="personName"
                                        formControlName="exporterMailId" disabled/>
                                </div>

                                <!-- exporter contact no -->
                                <div class="col-md-4 form-group" *ngIf="isThirdCountrySelected">
                                    <label class="form-label">Exporter Contact No</label>
                                    <input class="form-control" type="number" placeholder="Text Input" id="personName"
                                        formControlName="exporterContact" disabled />
                                </div>

                                <div *ngIf="isThirdCountrySelected">
                                    <div class="row">
                                        <!-- exporter Adress -->
                                        <div formGroupName="exporterAddress">
                                            <div class="row">
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label" for="street-address-1">Street Address
                                                        1</label>
                                                    <input class="form-control" type="text" placeholder="Text Input"
                                                        id="street-address-1" formControlName="street1" disabled/>
                                                
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label" for="street-address-2">Street Address
                                                        2</label>
                                                    <input class="form-control" type="text" placeholder="Text Input"
                                                        id="street-address-2" formControlName="street2" disabled/>
                                                
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label" for="city">City</label>
                                                    <input class="form-control" type="text" placeholder="Text Input"
                                                        id="city"  formControlName="city" disabled/>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label" for="state">State</label>
                                                    <input class="form-control" type="text" placeholder="Text Input"
                                                        id="state"  formControlName="state" disabled/>
                                                </div>
                                                <div formGroupName="country" class="col-md-4 form-group">
                                                    <label class="form-label" for="country">Country</label>
                                                    <select id="country" name="country" disabled
                                                        class="form-control form-select" formControlName="id">
                                                        <option value="" selected>Select</option>
                                                        <option [value]="count.id" *ngFor="let count of countries">
                                                            {{ count.name }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="form-label" for="zipCode">ZipCode</label>
                                                    <input class="form-control" type="number" placeholder="Text Input"
                                                        id="zipCode"  formControlName="zipCode" disabled/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="sea_mode_shipment" class="col-md-4 form-group">
                                    <label class="form-label" for="detention">Detention (Days)</label>
                                    <input class="form-control" type="number" formControlName="detention" disabled>
                                </div>

                                <div class="col-md-4 form-group">
                                    <label class="form-label" for="demurrage">Demurrage (Days)</label>
                                    <input class="form-control" type="number" formControlName="demurrage" disabled>
                                </div>

                                <div *ngIf="sea_mode_shipment" class="col-md-12 form-group">
                                    <h3 class="text-center" *ngIf="fullContainerLoadBool">Container Details</h3>
                                    <div *ngIf="fullContainerLoadBool" class="table-responsive">
                                        <table
                                            class="table table-borderless table-striped table-head-dark table-head-condensed table-head-py-3">
                                            <thead>
                                                <tr>
                                                    <th>Type</th>
                                                    <th>Size</th>
                                                    <th>Length</th>
                                                    <th>Width</th>
                                                    <th>Height</th>
                                                    <th>Quantity</th>
                                                    <th>Weight</th>
                                                    <th>Unit of Measurement</th>
                                                </tr>
                                            </thead>
                                            <tbody formArrayName="containers">
                                                <tr *ngFor="let item of pre_containers?.controls; let i=index; let l=last"
                                                    [formGroupName]="i" style="height: 40px">
                                                    <td>
                                                        <select class="form-control-plaintext" id="container_type"
                                                            formControlName="type" disabled> 
                                                            <option *ngFor="let item of container_type"
                                                                [value]="item?.id">
                                                                {{ item.value }}
                                                            </option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <input class="form-control-plaintext" type="text"
                                                            formControlName="size" disabled>
                                                    </td>
                                                    <td>
                                                        <input class="form-control-plaintext" type="text"
                                                            formControlName="length" disabled>
                                                    </td>
                                                    <td>
                                                        <input class="form-control-plaintext" type="text"
                                                            formControlName="width" disabled>
                                                    </td>
                                                    <td>
                                                        <input class="form-control-plaintext" type="text"
                                                            formControlName="height" disabled>
                                                    </td>
                                                    <td>
                                                        <input class="form-control-plaintext" type="number"
                                                            formControlName="quantity" min="0" disabled>
                                                    </td>
                                                    <td>
                                                        <input class="form-control-plaintext" type="number"
                                                            formControlName="weight" min="0" disabled>
                                                    </td>
                                                    <td>
                                                        <input class="form-control-plaintext" type="text"
                                                            formControlName="uom" disabled>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <h3 class="text-center" *ngIf="halfContianerLoadBool">Package Details</h3>
                                    <div *ngIf="halfContianerLoadBool" class="table-responsive">
                                        <table
                                            class="table table-borderless table-striped table-head-dark table-head-condensed table-head-py-3">
                                            <thead>
                                                <tr>
                                                    <th>Quantity</th>
                                                    <th>Type</th>
                                                    <th>Lenght</th>
                                                    <th>Width</th>
                                                    <th>Height</th>
                                                    <th>UOM</th>
                                                    <th>Gross Weight</th>
                                                    <th>Weight UOM</th>
                                                    <th>Total Volume</th>
                                                </tr>
                                            </thead>
                                            <tbody formArrayName="shipmentPackages">
                                                <tr *ngFor="let item of pre_shipmentPackages.controls; let i=index; let l=last"
                                                    [formGroupName]="i">
                                                    <td>
                                                        <input type="text" class="form-control-plaintext"
                                                            formControlName="quantity" disabled>
                                                    </td>
                                                    <td>
                                                        <select class="form-control-plaintext" id="container_type"
                                                            formControlName="type" disabled>
                                                            <option *ngFor="let item of container_type"
                                                                [value]="item.id">
                                                                {{ item.value }}
                                                            </option>
                                                        </select>
                                                    </td>

                                                    <td>
                                                        <input type="text" class="form-control-plaintext"
                                                            formControlName="length" disabled>
                                                    </td>

                                                    <td>
                                                        <input type="text" class="form-control-plaintext"
                                                            formControlName="width" disabled>
                                                    </td>

                                                    <td>
                                                        <input type="text" class="form-control-plaintext"
                                                            formControlName="height" disabled>
                                                    </td>

                                                    <td>
                                                        <input type="text" class="form-control-plaintext"
                                                            formControlName="dimensionsUOM" disabled>
                                                    </td>

                                                    <td>
                                                        <input type="text" class="form-control-plaintext"
                                                            formControlName="grossWeight" disabled>
                                                    </td>

                                                    <td>
                                                        <input type="text" class="form-control-plaintext"
                                                            formControlName="weightUOM" disabled>
                                                    </td>
                                                    <td>
                                                        <input type="number" class="form-control-plaintext"
                                                            formControlName="totalVolume" min="0" disabled>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot class="table-success">
                                                <tr>
                                                    <th>TOTAL</th>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td nowrap *ngIf="finalSeaWeightBool; else gross_air">Chargable
                                                        Weight</td>
                                                    <ng-template #gross_air>
                                                        <td></td>
                                                    </ng-template>
                                                    <td>{{ totalGrossWeightSEA | number: '1.2-3' }} {{ finalSeaWeightUom
                                                        }}</td>
                                                    <td nowrap *ngIf="!finalSeaWeightBool; else volume_air">Chargable
                                                        Weight</td>
                                                    <ng-template #volume_air>
                                                        <td></td>
                                                    </ng-template>
                                                    <td>{{ totalVolumeWeightSEA | number: '1.2-4' }} CBM</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>

                                    <div *ngIf="break_bulk">
                                        <h3 class="text-center">Break Bulk Details</h3>
                                        <div class="col-md-2 mt-2">
                                            <div *ngFor="let item of breakbulkfile;index">
                                                <a (click)="downloadPackageslip(item)"
                                                    class="anchor-tag">{{item.label}}</a>
                                            </div>
                                        </div>
                                        <div class="table-responsive">
                                            <table
                                                class="table table-borderless table-striped table-head-dark table-head-condensed table-head-py-3">
                                                <thead>
                                                    <tr>
                                                        <th>Total Net Weight</th>
                                                        <th>Total Gross Weight</th>
                                                        <th>Gross Weight UOM</th>
                                                        <th>Total Volume</th>
                                                    </tr>
                                                </thead>
                                                <tbody formArrayName="breakBulks">
                                                    <tr *ngFor="let item of pre_breakBulks.controls; let i=index; let l=last"
                                                        [formGroupName]="i">
                                                        <td>
                                                            <input class="form-control-plaintext" type="text"
                                                                formControlName="totalnetWeight" disabled>
                                                        </td>
                                                        <td>
                                                            <input class="form-control-plaintext" type="text"
                                                                formControlName="totalGrossWeight" disabled>
                                                        </td>
                                                        <td>
                                                            <input class="form-control-plaintext" type="text"
                                                                formControlName="uom" disabled>
                                                        </td>
                                                        <td>
                                                            <input class="form-control-plaintext" type="text"
                                                                formControlName="totalVolume" disabled>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tfoot class="table-success">
                                                    <tr>
                                                        <td *ngIf="totalBreakBulkWeight > totalBreakBulkVolume; else breakWeight"
                                                            nowrap>Chargable Weight</td>
                                                        <ng-template #breakWeight>
                                                            <td></td>
                                                        </ng-template>
                                                        <td>{{ totalBreakBulkWeight | number: '1.2-3' }} MT</td>
                                                        <td *ngIf="totalBreakBulkVolume > totalBreakBulkWeight; else breakVolume"
                                                            nowrap>Chargable Weight</td>
                                                        <ng-template #breakVolume>
                                                            <td></td>
                                                        </ng-template>
                                                        <td>{{ totalBreakBulkVolume | number: '1.2-4' }} CBM</td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="air_mode_shipment" class="col-md-12">
                                    <h3 class="text-center">Package Details</h3>
                                    <div class="table-responsive">
                                        <table
                                            class="table table-borderless table-striped table-head-dark table-head-condensed table-head-py-3">
                                            <thead>
                                                <tr>
                                                    <th>Quantity</th>
                                                    <th>Lenght</th>
                                                    <th>Width</th>
                                                    <th>Height</th>
                                                    <th>UOM</th>
                                                    <th>Gross Weight</th>
                                                    <th>Weight UOM</th>
                                                    <th>Total Volume</th>
                                                </tr>
                                            </thead>
                                            <tbody formArrayName="shipmentPackages">
                                                <tr *ngFor="let item of pre_shipmentPackages.controls; let i=index; let l=last"
                                                    [formGroupName]="i" style="height: 30px;">
                                                    <td>
                                                        <input type="text" class="form-control-plaintext"
                                                            formControlName="quantity" disabled>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control-plaintext"
                                                            formControlName="length" disabled>
                                                    </td>

                                                    <td>
                                                        <input type="text" class="form-control-plaintext"
                                                            formControlName="width" disabled>
                                                    </td>

                                                    <td>
                                                        <input type="text" class="form-control-plaintext"
                                                            formControlName="height" disabled>
                                                    </td>

                                                    <td>
                                                        <input type="text" class="form-control-plaintext"
                                                            formControlName="dimensionsUOM" disabled>
                                                    </td>

                                                    <td>
                                                        <input type="text" class="form-control-plaintext"
                                                            formControlName="grossWeight" disabled>
                                                    </td>

                                                    <td>
                                                        <input type="text" class="form-control-plaintext"
                                                            formControlName="weightUOM" disabled>
                                                    </td>
                                                    <td>
                                                        <input type="number" class="form-control-plaintext"
                                                            formControlName="totalVolume" min="0" disabled>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot class="table-success">
                                                <tr>
                                                    <th>TOTAL</th>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td nowrap *ngIf="finalAirWeightBool; else gross_air">Chargable
                                                        Weight</td>
                                                    <ng-template #gross_air>
                                                        <td></td>
                                                    </ng-template>
                                                    <td>{{ totalGrossWeightAIR | number: '1.2-3' }} {{ finalAirWeightUom
                                                        }}</td>
                                                    <td nowrap *ngIf="!finalAirWeightBool; else volume_air">Chargable
                                                        Weight</td>
                                                    <ng-template #volume_air>
                                                        <td></td>
                                                    </ng-template>
                                                    <td>{{ totalVolumeWeightAIR | number: '1.2-4' }} CBM</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-md-12 form-group">
                                    <h3 class="text-center">Terms & Conditions</h3>
                                    <div formArrayName="terms">
                                        <div class="row mb-4 justify-content-center align-items-center"
                                            *ngFor="let item of pre_termsConditions.controls; index as i"
                                            [formGroupName]="i">
                                            <div class="col-lg-11">
                                                <input type="text" formControlName="label"
                                                    class="form-control terms-label" placeholder="Enter Label Here" disabled>
                                                <textarea class="form-control" rows="6"
                                                    placeholder="Type your terms &amp; Conditions Here" disabled
                                                    formControlName="content" id="terms-&amp;-conditions"></textarea>
                                            </div>
                                            <div class="col-lg-1">
                                                <input class="term-checkbox" type="checkbox" formControlName="checked">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 form-group">
                                    <h3 class="text-center">Attachments</h3>
                                    <div class="table-responsive">
                                        <table
                                            class="table table-borderless table-striped table-head-dark table-head-condensed table-head-py-3">
                                            <thead>
                                                <tr>
                                                    <th>Sr.No</th>
                                                    <th>File Name</th>
                                                    <th>Label</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngIf="downloadDocumentList.length > 0; else noData">
                                                <tr *ngFor="let item of downloadDocumentList; index as i">
                                                    <td>{{ i + 1 }}</td>
                                                    <td>{{ item.name }}</td>
                                                    <td>
                                                        <input type="text" [(ngModel)]="item.label"
                                                            [ngModelOptions]="{standalone: true}" disabled>
                                                    </td>
                                                    <td class="row">
                                                        <div class="col-md-4">
                                                            <button *ngIf="item.artifactId"
                                                                (click)="downloadFileLocal(item)">
                                                                <i class="fa fa-download" aria-hidden="true"
                                                                    title="Download"></i>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <ng-template #noData>
                                                <div class="text-center text-danger mt-3">
                                                    NO Files Uploaded by Client for Enquiry
                                                </div>
                                            </ng-template>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="row justify-content-center">
                            <div class="col-md-6 form-group pt-4 pt-lg-5">
                                <button class="btn btn-success w-100" type="button" data-target="item-3"
                                    (click)="nextRatesDetails()">NEXT</button>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane" id="item-4">
                        <form [formGroup]="rfqRates">
                            <h3 class="text-center">Rates Details</h3>
                            <div class="row">
                                <!-- *ngIf="isBidUpdate" -->
                                <div class="col-md-4 form-group">
                                    <label for="quote_number">Quotation Number</label>
                                    <input class="form-control" placeholder="Auto-generate if blank"
                                        formControlName="number" type="text" id="quote_number">
                                </div>
                                <div class="col-md-4 form-group">
                                    <label for="quote_date">Quotation Date</label>
                                    <input class="form-control" formControlName="quoteDate" type="date" id="quote_date" [min]="getMinDate()">
                                </div>
                                <div class="row mb-2">
                                    <h3 class="col-md-10 text-center mt-3">Shipment Details</h3>
                                    <button (click)="addVendorQuoteRow()"
                                        class="col-md-2 text-dark text-small btn-outline-success">
                                        <i class="fa fa-plus me-2" aria-hidden="true"></i>
                                        <span *ngIf="air_mode_shipment">
                                            AIRLINE
                                        </span>
                                        <span *ngIf="sea_mode_shipment">
                                            SHIPMENT
                                        </span>
                                    </button>
                                </div>
                                <mat-tab-group formArrayName="vendorQuoteRows" [selectedIndex]="selected.value"
                                    (selectedIndexChange)="selected.setValue($event)">
                                    <mat-tab bodyClass="chrome_tab" *ngFor="let vendor of vendorQuoteRow().controls; let vendorInd=index; let l=last"
                                    [formGroupName]="vendorInd" [label]="vendor.get('lineName')?.value">
                                        <ng-template class="row" mat-tab-label>
                                            <div class="col-md-9" style="margin-left: 20px;">{{ vendor.get('lineName')?.value }}</div>
                                            <button class="col-md-3" *ngIf="vendorQuoteRow().length > 1"  style="color:black" mat-icon-button (click)="removeTab(vendorInd)">
                                                <mat-icon>close</mat-icon>
                                            </button>
                                        </ng-template>
                                        <div class="row mt-4">
                                            <div class="col-md-3 form-group">
                                                <label *ngIf="sea_mode_shipment" for="lineName">Shipping Line*</label>
                                                <label *ngIf="air_mode_shipment" for="lineName">Air Line*</label>
                                                <input class="form-control" type="text" formControlName="lineName" id="lineName"
                                                [ngClass]="{'is-invalid': vendorQuoteRow().at(vendorInd).get('lineName').invalid &&  (vendorQuoteRow().at(vendorInd).get('lineName').dirty || vendorQuoteRow().at(vendorInd).get('lineName').touched )}">
                                                <div class="text-danger validation-message" *ngIf="vendorQuoteRow().at(vendorInd).get('lineName').invalid &&  (vendorQuoteRow().at(vendorInd).get('lineName').dirty || vendorQuoteRow().at(vendorInd).get('lineName').touched )">
                                                    <div [hidden]="!air_mode_shipment">
                                                        Airline name is mandatory
                                                    </div>
                                                    <div [hidden]="!sea_mode_shipment">
                                                        Shipping Line is mandatory
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3 form-group">
                                                <label for="frequency">Weekly Frequency In Numbers*</label>
                                                <input type="number" min="0" formControlName="frequency" id="frequency" class="form-control"
                                                [ngClass]="{'is-invalid': vendorQuoteRow().at(vendorInd).get('frequency').invalid &&  (vendorQuoteRow().at(vendorInd).get('frequency').dirty || vendorQuoteRow().at(vendorInd).get('frequency').touched )}">
                                                <div class="text-danger validation-message" *ngIf="vendorQuoteRow().at(vendorInd).get('frequency').invalid &&  (vendorQuoteRow().at(vendorInd).get('frequency').dirty || vendorQuoteRow().at(vendorInd).get('frequency').touched )">
                                                    <div [hidden]="!air_mode_shipment">
                                                        Airline Frequency is mandatory
                                                    </div>
                                                    <div [hidden]="!sea_mode_shipment">
                                                        Shipping Frequency is mandatory
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3 form-group">
                                                <label for="transitTime">Transit Time*</label>
                                                <input type="number" min="0" formControlName="transitTime" id="transitTime"
                                                    class="form-control" [max]="transitTimeMax"
                                                    [ngClass]="{'is-invalid': vendorQuoteRow().at(vendorInd).get('transitTime').invalid &&  (vendorQuoteRow().at(vendorInd).get('transitTime').dirty || vendorQuoteRow().at(vendorInd).get('transitTime').touched )}">
                                                    <div class="text-danger validation-message" *ngIf="vendorQuoteRow().at(vendorInd).get('transitTime').invalid &&  (vendorQuoteRow().at(vendorInd).get('transitTime').dirty || vendorQuoteRow().at(vendorInd).get('transitTime').touched )">
                                                        Transit time is mandatory
                                                    </div>
                                            </div>

                                            <div class="col-md-3 form-group">
                                                <label for="transitPort">Transit Port*</label>
                                                <input class="form-control" formControlName="transitPort" type="text" id="transitPort"
                                                [ngClass]="{'is-invalid': vendorQuoteRow().at(vendorInd).get('transitPort').invalid &&  (vendorQuoteRow().at(vendorInd).get('transitPort').dirty || vendorQuoteRow().at(vendorInd).get('transitPort').touched )}">
                                                <div class="text-danger validation-message" *ngIf="vendorQuoteRow().at(vendorInd).get('transitPort').invalid &&  (vendorQuoteRow().at(vendorInd).get('transitPort').dirty || vendorQuoteRow().at(vendorInd).get('transitPort').touched )">
                                                    Transit Port is mandatory
                                                </div>
                                            </div>
                                            <div *ngIf="sea_mode_shipment" class="col-md-3 form-group">
                                                <label for="vesselName">Vessel Name*</label>
                                                <input type="text" formControlName="vesselName" id="vesselName" class="form-control"
                                                [ngClass]="{'is-invalid': vendorQuoteRow().at(vendorInd).get('vesselName').invalid &&  (vendorQuoteRow().at(vendorInd).get('vesselName').dirty || vendorQuoteRow().at(vendorInd).get('vesselName').touched )}">
                                                <div class="text-danger validation-message" *ngIf="vendorQuoteRow().at(vendorInd).get('vesselName').invalid &&  (vendorQuoteRow().at(vendorInd).get('vesselName').dirty || vendorQuoteRow().at(vendorInd).get('vesselName').touched )">
                                                    Vessel Name is mandatory
                                                </div>
                                            </div>
                                            <div *ngIf="sea_mode_shipment" class="col-md-3 form-group">
                                                <label for="vesselSchedule">Vessel Schedule*</label>
                                                <input type="date" formControlName="vesselSchedule" id="vesselSchedule" class="form-control"
                                                [ngClass]="{'is-invalid': vendorQuoteRow().at(vendorInd).get('vesselSchedule').invalid &&  (vendorQuoteRow().at(vendorInd).get('vesselSchedule').dirty || vendorQuoteRow().at(vendorInd).get('vesselSchedule').touched )}">
                                                <div class="text-danger validation-message" *ngIf="vendorQuoteRow().at(vendorInd).get('vesselSchedule').invalid &&  (vendorQuoteRow().at(vendorInd).get('vesselSchedule').dirty || vendorQuoteRow().at(vendorInd).get('vesselSchedule').touched )">
                                                    Vessel Schedule is mandatory
                                                </div>
                                            </div>
                                            <div class="col-md-3 form-group">
                                                <label for="etd">ETD*</label>
                                                <input type="date" formControlName="etd" id="etd" class="form-control"
                                                    [min]="getTodayDate()" (change)="compareETD(vendorInd)" 
                                                    [ngClass]="{'is-invalid': vendorQuoteRow().at(vendorInd).get('etd').invalid &&  (vendorQuoteRow().at(vendorInd).get('etd').dirty || vendorQuoteRow().at(vendorInd).get('etd').touched )}">
                                                <div class="text-danger validation-message" *ngIf="vendorQuoteRow().at(vendorInd).get('etd').invalid &&  (vendorQuoteRow().at(vendorInd).get('etd').dirty || vendorQuoteRow().at(vendorInd).get('etd').touched )">
                                                    ETD is mandatory
                                                </div>

                                                <div class="text-danger validation-message" *ngIf="invalidETDdate">
                                                    Departure cannot be greater than Arrival
                                                </div>
                                            </div>
                                            <div class="col-md-3 form-group">
                                                <label for="eta">ETA</label>
                                                <input type="date" formControlName="eta" id="eta" class="form-control" [min]="getTodayDate()" (change)="compareETD(vendorInd)" >
                                                <div class="text-danger validation-message" *ngIf="invalidETDdate">
                                                    Arrival should be less than Departure
                                                </div>
                                            </div>
                                            <div *ngIf="sea_mode_shipment" class="col-md-3 form-group">
                                                <label for="loadingTerminal">Loading Terminal*</label>
                                                <input type="text" formControlName="loadingTerminal" id="loadingTerminal" class="form-control"
                                                [ngClass]="{'is-invalid': vendorQuoteRow().at(vendorInd).get('loadingTerminal').invalid &&  (vendorQuoteRow().at(vendorInd).get('loadingTerminal').dirty || vendorQuoteRow().at(vendorInd).get('loadingTerminal').touched )}">
                                                <div class="text-danger validation-message" *ngIf="vendorQuoteRow().at(vendorInd).get('loadingTerminal').invalid &&  (vendorQuoteRow().at(vendorInd).get('loadingTerminal').dirty || vendorQuoteRow().at(vendorInd).get('loadingTerminal').touched )">
                                                    Loading Terminal is mandatory
                                                </div>
                                            </div>
                                            <!-- quoteValidTill -->
                                            <div class="col-md-3 form-group">
                                                <label for="quoteValidTill">Offer Validity*</label>
                                                <!-- [max]="lastSubmissionDate" -->
                                                <input type="date" formControlName="quoteValidTill" id="quoteValidTill" class="form-control" [min]="getMinDate()"
                                                [ngClass]="{'is-invalid': vendorQuoteRow().at(vendorInd).get('quoteValidTill').invalid &&  (vendorQuoteRow().at(vendorInd).get('quoteValidTill').dirty || vendorQuoteRow().at(vendorInd).get('quoteValidTill').touched )}">
                                                <div class="text-danger validation-message" *ngIf="vendorQuoteRow().at(vendorInd).get('quoteValidTill').invalid &&  (vendorQuoteRow().at(vendorInd).get('quoteValidTill').dirty || vendorQuoteRow().at(vendorInd).get('quoteValidTill').touched )">
                                                    Offer Validity is mandatory
                                                </div>
                                            </div>
                                            <div class="col-md-3 form-group">
                                                <label for="routing">Routing*</label>
                                                <input type="text" formControlName="routing" id="routing" class="form-control" placeholder="Chicago - Delhi - Mumbai"
                                                [ngClass]="{'is-invalid': vendorQuoteRow().at(vendorInd).get('routing').invalid &&  (vendorQuoteRow().at(vendorInd).get('routing').dirty || vendorQuoteRow().at(vendorInd).get('routing').touched )}">
                                                <div class="text-danger validation-message" *ngIf="vendorQuoteRow().at(vendorInd).get('routing').invalid &&  (vendorQuoteRow().at(vendorInd).get('routing').dirty || vendorQuoteRow().at(vendorInd).get('routing').touched )">
                                                    Routing is mandatory
                                                </div>
                                            </div>
                                            <div *ngIf="sea_mode_shipment" class="col-md-3 form-group">
                                                <label for="sailingDays">Sailing Days Of Week*</label>
                                                <input type="text" formControlName="sailingDays" id="sailingDays" class="form-control"
                                                [ngClass]="{'is-invalid': vendorQuoteRow().at(vendorInd).get('sailingDays').invalid &&  (vendorQuoteRow().at(vendorInd).get('sailingDays').dirty || vendorQuoteRow().at(vendorInd).get('sailingDays').touched )}">
                                                <div class="text-danger validation-message" *ngIf="vendorQuoteRow().at(vendorInd).get('sailingDays').invalid &&  (vendorQuoteRow().at(vendorInd).get('sailingDays').dirty || vendorQuoteRow().at(vendorInd).get('sailingDays').touched )">
                                                    Sailing Days is mandatory
                                                </div>
                                            </div>
                                        </div>

                                        <div class="mt-3">
                                            <h5>Chargable Weight is  <span class="text-black"> {{ chargableWeight }} {{ chargableWeightUOM }} </span></h5>
                                        </div> 

                                        <div *ngIf="air_mode_shipment" class="row mt-2 mb-2">
                                            <h3 class="text-center">Air Package Freight</h3>
                                            <!-- Air Charges Per UNIT & Per SHIPMENT -->
                                            <div class="table-responsive">
                                                <table
                                                    class="table table-borderless table-striped table-head-dark table-head-condensed table-head-py-3">
                                                        <thead>
                                                            <tr>
                                                                <td><b> Freight & Surcharges </b></td>
                                                                <td><b>Per {{ chargableWeightUOM }}</b></td>
                                                                <td>
                                                                    <button class="text-white btn-success" (click)="addChargeTypePerUnitAIR(vendorInd)"><i class="fa fa-plus"></i> <b> Charge</b> </button>
                                                                </td>
                                                            </tr>
                                                        </thead>
                                                        <tbody formArrayName="vendorQuoteShipmentPackagesPerUnit">
                                                            <tr style="font-size: 12px" *ngFor="let item of vendorRowShipmentPackagesPerUnit(vendorInd).controls; let shipmentIndPerUnit = index"
                                                                [formGroupName]="shipmentIndPerUnit">
                                                                <td>
                                                                    <mat-form-field appearance="standard">
                                                                        <input matInput class="form-control-plaintext" placeholder="Enter Charge Name"
                                                                        type="text" formControlName="description">
                                                                    </mat-form-field>
                                                                </td>
                                                                <td>
                                                                    <mat-form-field appearance="standard">
                                                                        <input matInput class="form-control-plaintext" placeholder="Enter Charge"
                                                                        (change)="calculateTotalChargePerUnitAir(vendorInd, shipmentIndPerUnit)" type="number" min="0"
                                                                        formControlName="charge">
                                                                    </mat-form-field>
                                                                </td>
                                                                <td>
                                                                    <button class="btn-outline-danger" (click)="deleteChargeTypePerUnitAIR(vendorInd, shipmentIndPerUnit)">
                                                                        <i class="fa fa-trash"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot class="table-success">
                                                            <tr>
                                                                <th>Sub-Total</th>
                                                                <td>
                                                                    <input class="" type="number"
                                                                    formControlName="packageFreightPerUnitSubTotal" [attr.disabled]="true">
                                                                    {{ currency }}
                                                                </td>
                                                                <td></td>
                                                            </tr>
                                                        </tfoot>
                                                </table>

                                                <table class="table table-borderless table-striped table-head-dark table-head-condensed table-head-py-3 mt-3">
                                                    <thead>
                                                        <tr>
                                                            <td><b>Freight & Surcharges</b></td>
                                                            <td><b>Per Shipment</b></td>
                                                            <td>
                                                                <button class="text-white btn-success"  (click)="addChargeTypePerShipmentAIR(vendorInd)"> <i class="fa fa-plus"></i> <b> Charge</b></button>
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                    <tbody formArrayName="vendorQuoteShipmentPackagesPerShipment">
                                                        <tr style="font-size: 12px" *ngFor="let item of vendorRowShipmentPackagePerShipment(vendorInd).controls; let shipmentIndPerShipment = index"
                                                            [formGroupName]="shipmentIndPerShipment">
                                                            <td>
                                                                <mat-form-field appearance="standard">
                                                                    <input matInput class="form-control-plaintext" placeholder="Enter Charge Name"
                                                                    type="text" formControlName="description" min="0">
                                                                </mat-form-field>
                                                            </td>
                                                            <td>
                                                                <mat-form-field appearance="standard">
                                                                    <input matInput class="form-control-plaintext" placeholder="Enter Charge"
                                                                    (change)="calculateTotalChargePerShipmentAIR(vendorInd, shipmentIndPerShipment)" type="number" 
                                                                    formControlName="charge" min="0">
                                                                </mat-form-field>
                                                            </td>
                                                            <td>
                                                                <button class="btn-outline-danger" (click)="deleteChargeTypePerShipmentAIR(vendorInd, shipmentIndPerShipment)">
                                                                    <i class="fa fa-trash"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    <tfoot class="table-success">
                                                        <tr>
                                                            <th>Sub-Total</th>
                                                            <td>
                                                                <input class="" type="number" 
                                                                formControlName="packageFreightPerShipmentSubTotal" [attr.disabled]="true">
                                                                {{ currency }}
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                    </tfoot>                                                                                                
                                                </table>

                                                <!-- Sub-Total -->
                                                <table class="table table-borderless table-striped table-head-dark table-head-condensed table-head-py-3">
                                                    <tfoot class="table-success">
                                                        <tr>
                                                            <th>Total</th>
                                                            <td>
                                                                <input class="" type="number" 
                                                                formControlName="packageFreightTotal"
                                                                [attr.disabled]="true">
                                                                {{ currency }}
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>

                                            <!-- Additional Charges Per UNIT & Per SHIPMENT -->
                                            <div class="table-responsive">
                                                <table class="table table-borderless table-striped table-head-dark table-head-condensed table-head-py-3">
                                                    <thead>
                                                        <tr>
                                                            <td><b> Additional Charges/Unit </b></td>
                                                            <td><b> Per {{ finalAirWeightUom }} </b></td>
                                                            <td>
                                                                <button class="text-white btn-success" (click)="addAdditionalChargesPerUnit(vendorInd)"><i class="fa fa-plus"></i> <b> Charge</b> </button>
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                    <tbody formArrayName="additionalChargesPerUnit">
                                                        <tr style="font-size: 12px" *ngFor="let item of vendorRowAdditionalChargesPerUnit(vendorInd).controls; let addChargePerUnitInd = index"
                                                            [formGroupName]="addChargePerUnitInd">
                                                            <td>
                                                                <mat-form-field appearance="standard">
                                                                    <input matInput class="form-control-plaintext" placeholder="Enter Charge Name"
                                                                    type="text" formControlName="description" min="0">
                                                                </mat-form-field>
                                                            </td>
                                                            <td>
                                                                <mat-form-field appearance="standard">
                                                                    <input matInput class="form-control-plaintext" placeholder="Enter Charge"
                                                                    type="number" (change)="calculateAdditionalChargeAirPerUnit(vendorInd, addChargePerUnitInd)"
                                                                    formControlName="charge" min="0">
                                                                </mat-form-field>
                                                            </td>
                                                            <td>
                                                                <button class="btn-outline-danger" (click)="deleteAdditionalChargesPerUnit(vendorInd, addChargePerUnitInd)">
                                                                    <i class="fa fa-trash"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    <tfoot class="table-success">
                                                        <tr>
                                                            <th>Sub-Total</th>
                                                            <td>
                                                                <input class="" type="number"
                                                                formControlName="additionalChargePerUnitSubTotal" [attr.disabled]="true">
                                                                {{ currency }}
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                    </tfoot>
                                                </table>

                                                <table class="table table-borderless table-striped table-head-dark table-head-condensed table-head-py-3">
                                                    <thead>
                                                        <tr>
                                                            <td><b> Additional Charges </b></td>
                                                            <td><b> Per Shipment </b></td>
                                                            <td>
                                                                <button class="text-white btn-success" (click)="addAdditionalChargesPerShipment(vendorInd)"><i class="fa fa-plus"></i> <b> Charge</b> </button>
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                    <tbody formArrayName="additionalChargesPerShipment">
                                                        <tr style="font-size: 12px" *ngFor="let item of vendorRowAdditionalChargesPerShipment(vendorInd).controls; let addChargePerShipmentInd = index"
                                                            [formGroupName]="addChargePerShipmentInd">
                                                            <td>
                                                                <mat-form-field appearance="standard">
                                                                    <input matInput class="form-control-plaintext" placeholder="Enter Charge Name"
                                                                    type="text" formControlName="description" min="0">
                                                                </mat-form-field>
                                                            </td>
                                                            <td>
                                                                <mat-form-field appearance="standard">
                                                                    <input matInput class="form-control-plaintext" placeholder="Enter Charge"
                                                                    type="number" (change)="calculateAdditionalChargeAirPerShipment(vendorInd, addChargePerShipmentInd)"
                                                                    formControlName="charge" min="0">
                                                                </mat-form-field>
                                                            </td>
                                                            <td>
                                                                <button class="btn-outline-danger" (click)="deleteAdditionalChargesPerShipment(vendorInd, addChargePerShipmentInd)">
                                                                    <i class="fa fa-trash"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    <tfoot class="table-success">
                                                        <tr>
                                                            <th>Sub-Total</th>
                                                            <td>
                                                                <input class="" type="number"
                                                                formControlName="additionalChargePerShipmentSubTotal" [attr.disabled]="true">
                                                                {{ currency }}
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                    </tfoot>
                                                </table>

                                                <!-- Sub-Total -->
                                                <table class="table table-borderless table-striped table-head-dark table-head-condensed table-head-py-3">
                                                    <tfoot class="table-success">
                                                        <tr>
                                                            <th>Total</th>
                                                            <td>
                                                                <input class="" type="number"
                                                                formControlName="additionalChargeTotal" [attr.disabled]="true">
                                                                {{ currency }}
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                            <!-- Grand Total -->
                                            <div class="table-responsive">
                                                <table class="table table-borderless table-striped table-head-dark table-head-condensed table-head-py-3">
                                                    <tfoot class="table-success">
                                                        <tr>
                                                            <th>Grand Total</th>
                                                            <td>
                                                                <input class="" type="text" 
                                                                    formControlName="amount" [attr.disabled]="true">
                                                                {{ currency }}
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>

                                        <div *ngIf="sea_mode_shipment" class="row mt-2 mb-2">
                                            <div *ngIf="fullContainerLoadBool">
                                                <h3  class="text-center">Container Freight</h3>
                                                <div class="table-responsive">
                                                    <table class="table table-borderless table-striped table-head-dark table-head-condensed table-head-py-3">
                                                        <thead>
                                                            <tr>
                                                                <th>Container Type</th>
                                                                <th>Size</th>
                                                                <th>Quantity</th>
                                                                <th>Charge/Container</th>
                                                                <th *ngIf="exWorksBool">Ex Works</th>
                                                                <th *ngIf="deliveryChargeBool">Delivery Charge</th>
                                                                <th>Total</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody formArrayName="vendorQuoteContainers">
                                                            <tr style="font-size: 12px" *ngFor="let item of vendorRowContainer(vendorInd).controls; let containerInd = index"
                                                                [formGroupName]="containerInd">
                                                                <td>
                                                                    <select class="form-control-plaintext" id="container_type"
                                                                        formControlName="type" [attr.disabled]="true">
                                                                        <option *ngFor="let item of container_type"
                                                                            [value]="item.id">
                                                                            {{ item.value }}
                                                                        </option>
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <input type="text" [attr.disabled]="true"
                                                                    class="form-control-plaintext" formControlName="size">
                                                                </td>
                                                                <td>
                                                                    <input type="number" [attr.disabled]="true"
                                                                    class="form-control-plaintext" formControlName="quantity">
                                                                </td>
                                                                <td>
                                                                    <mat-form-field appearance="standard">
                                                                        <input matInput type="number" formControlName="ratePerContainer" required="true"
                                                                        placeholder="Enter Charge Name" min="0" (change)="calculateContainerCharge(vendorInd)">
                                                                    </mat-form-field>
                                                                </td>
                                                                <td *ngIf="exWorksBool"> 
                                                                    <mat-form-field appearance="standard">
                                                                        <input matInput type="number" formControlName="exWorks" placeholder="Enter Charge Name"
                                                                        min="0" (change)="calculateContainerCharge(vendorInd)">
                                                                    </mat-form-field>
                                                                </td>
                                                                <td *ngIf="deliveryChargeBool">
                                                                    <mat-form-field appearance="standard">
                                                                        <input matInput type="number" formControlName="destinationCharges"
                                                                        placeholder="Enter Charge Name" min="0" (change)="calculateContainerCharge(vendorInd)">
                                                                    </mat-form-field>
                                                                </td>
                                                                <td>
                                                                    <input type="number" class="form-control-plaintext"
                                                                    formControlName="totalFreight" [attr.disabled]="true">
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot class="table-success">
                                                            <tr>
                                                                <th>Total</th>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td *ngIf="exWorksBool"></td>
                                                                <td *ngIf="deliveryChargeBool"></td>
                                                                <td>
                                                                    <input class="form-control-plaintext" type="number" [attr.disabled]="true"
                                                                    formControlName="containerTotal">
                                                                </td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>
                                            <div *ngIf="halfContianerLoadBool">
                                                <h3 class="text-center">Package Freight</h3>
                                                <div class="table-responsive">
                                                    <table class="table table-borderless table-striped table-head-dark table-head-condensed table-head-py-3">
                                                        <thead>
                                                            <tr>
                                                                <td><b> Freight & Surcharges </b></td>
                                                                <td><b> Per {{ finalSeaWeightUom }} </b></td>
                                                                <td>
                                                                    <button class="text-white btn-success" (click)="addChargeTypePerUnitSEA(vendorInd)"><i class="fa fa-plus"></i> <b> Charge</b> </button>
                                                                </td>
                                                            </tr>
                                                        </thead>
                                                        <tbody formArrayName="vendorQuoteShipmentPackagesPerUnit">
                                                            <tr style="font-size: 12px" *ngFor="let item of vendorRowShipmentPackagesPerUnit(vendorInd).controls; let shipmentIndPerUnit = index"
                                                                [formGroupName]="shipmentIndPerUnit">
                                                                <td>
                                                                    <mat-form-field appearance="standard">
                                                                        <input matInput class="form-control-plaintext" placeholder="Enter Charge Name"
                                                                        type="text" formControlName="description">
                                                                    </mat-form-field>
                                                                    
                                                                </td>
                                                                <td>
                                                                    <mat-form-field appearance="standard">
                                                                        <input matInput class="form-control-plaintext" placeholder="Enter Charge"
                                                                        (change)="calculateTotalChargePerUnitSEA(vendorInd)" type="number" min="0"
                                                                        formControlName="charge">
                                                                    </mat-form-field>
                                                                </td>
                                                                <td>
                                                                    <button class="btn-outline-danger" (click)="deleteChargeTypePerUnitSEA(vendorInd, shipmentIndPerUnit)">
                                                                        <i class="fa fa-trash"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot class="table-success">
                                                            <tr>
                                                                <th>Sub-Total</th>
                                                                <td>
                                                                    <input class="" type="number"
                                                                    formControlName="packageFreightPerUnitSubTotal" [attr.disabled]="true">
                                                                    {{ currency }}
                                                                </td>
                                                                <td></td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
        
                                                    <table class="table table-borderless table-striped table-head-dark table-head-condensed table-head-py-3 mt-3">
                                                        <thead>
                                                            <tr>
                                                                <td><b>Freight & Surcharges</b></td>
                                                                <td><b>Per Shipment</b></td>
                                                                <td>
                                                                    <button class="text-white btn-success"  (click)="addChargeTypePerShipmentAIR(vendorInd)"> <i class="fa fa-plus"></i> <b> Charge</b></button>
                                                                </td>
                                                            </tr>
                                                        </thead>
                                                        <tbody formArrayName="vendorQuoteShipmentPackagesPerShipment">
                                                            <tr style="font-size: 12px" *ngFor="let item of vendorRowShipmentPackagePerShipment(vendorInd).controls; let shipmentIndPerShipment = index"
                                                                [formGroupName]="shipmentIndPerShipment">
                                                                <td>
                                                                    <mat-form-field appearance="standard">
                                                                        <input matInput class="form-control-plaintext" placeholder="Enter Charge Name"
                                                                        type="text" formControlName="description" min="0">
                                                                    </mat-form-field>
                                                                </td>
                                                                <td>
                                                                    <mat-form-field appearance="standard">
                                                                        <input matInput class="form-control-plaintext" placeholder="Enter Charge"
                                                                        (change)="calculateTotalChargePerShipmentSEA(vendorInd)" type="number" 
                                                                        formControlName="charge" min="0">
                                                                    </mat-form-field>
                                                                </td>
                                                                <td>
                                                                    <button class="btn-outline-danger" (click)="deleteChargeTypePerShipmentSEA(vendorInd, shipmentIndPerShipment)">
                                                                        <i class="fa fa-trash"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot class="table-success">
                                                            <tr>
                                                                <th>Sub-Total</th>
                                                                <td>
                                                                    <input class="" type="number" 
                                                                    formControlName="packageFreightPerShipmentSubTotal" [attr.disabled]="true">
                                                                    {{ currency }}
                                                                </td>
                                                                <td></td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>

                                                    <table class="table table-borderless table-striped table-head-dark table-head-condensed table-head-py-3">
                                                        <tfoot class="table-success">
                                                            <tr>
                                                                <th>Total</th>
                                                                <td>
                                                                    <input class="" type="number" 
                                                                    formControlName="packageFreightTotal" [attr.disabled]="true">
                                                                    {{ currency }}
                                                                </td>
                                                                <td></td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>
                                            <div *ngIf="break_bulk">
                                                <h3 class="text-center">Break Bulk Freight</h3>
                                                <div class="table-responsive">
                                                    <table class="table table-borderless table-striped table-head-dark table-head-condensed table-head-py-3">
                                                        <thead>
                                                            <tr>
                                                                <td><b> Freight & Surcharges </b></td>
                                                                <td><b> Per {{ chargableWeightUOM }} </b></td>
                                                                <td>
                                                                    <button class="text-white btn-success" (click)="addChargeTypePerUnitBreakBulk(vendorInd)"><i class="fa fa-plus"></i> <b> Charge</b> </button>
                                                                </td>
                                                            </tr>
                                                        </thead>
                                                        <tbody formArrayName="vendorQuoteBreakBulkPerUnit">
                                                            <tr style="font-size: 12px" *ngFor="let item of vendorRowBreakBulkPerUnit(vendorInd).controls; let breakBulkInd = index;"
                                                            [formGroupName]="breakBulkInd">
                                                                <td>
                                                                    <mat-form-field appearance="standard">
                                                                        <input matInput class="form-control-plaintext" placeholder="Enter Charge Name"
                                                                        type="text" formControlName="description">
                                                                    </mat-form-field>
                                                                </td>
                                                                <td>
                                                                    <mat-form-field appearance="standard">
                                                                        <input matInput class="form-control-plaintext" placeholder="Enter Charge"
                                                                        (change)="calculateTotalChagePerUnitBreakBulk(vendorInd, breakBulkInd)" type="number" min="0"
                                                                        formControlName="charge">
                                                                    </mat-form-field>
                                                                </td>
                                                                <td>
                                                                    <button class="btn-outline-danger" (click)="deleteChargeTypePerUnitBreakBulk(vendorInd, breakBulkInd)">
                                                                        <i class="fa fa-trash"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot class="table-success">
                                                            <tr>
                                                                <th>Sub-Total</th>
                                                                <td>
                                                                    <input class="form-control-plaintext" type="number" 
                                                                    formControlName="breakBulkPerUnitSubTotal" [attr.disabled]="true">
                                                                </td>
                                                                <td></td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                    <table class="table table-borderless table-striped table-head-dark table-head-condensed table-head-py-3">
                                                        <thead>
                                                            <tr>
                                                                <td><b> Freight & Surcharges </b></td>
                                                                <td><b> Per Shipment </b></td>
                                                                <td>
                                                                    <button class="text-white btn-success" (click)="addChargeTypePerShipmentBreakBulk(vendorInd)"><i class="fa fa-plus"></i> <b> Charge</b> </button>
                                                                </td>
                                                            </tr>
                                                        </thead>
                                                        <tbody formArrayName="vendorQuoteBreakBulkPerShipment">
                                                            <tr style="font-size: 12px" *ngFor="let item of vendorRowBreakBulkPerShipment(vendorInd).controls; let breakBulkInd = index;"
                                                                [formGroupName]="breakBulkInd">
                                                                <td>
                                                                    <mat-form-field appearance="standard">
                                                                        <input matInput class="form-control-plaintext" placeholder="Enter Charge Name"
                                                                        type="text" formControlName="description">
                                                                    </mat-form-field>
                                                                </td>
                                                                <td>
                                                                    <mat-form-field appearance="standard">
                                                                        <input matInput class="form-control-plaintext" placeholder="Enter Charge"
                                                                        (change)="calculateTotalChargePerShipmentBreakBulk(vendorInd, breakBulkInd)" type="number" min="0"
                                                                        formControlName="charge">
                                                                    </mat-form-field>
                                                                </td>
                                                                <td>
                                                                    <button class="btn-outline-danger" (click)="deleteChargeTypePerShipmentBreakBulk(vendorInd, breakBulkInd)">
                                                                        <i class="fa fa-trash"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot class="table-success">
                                                            <tr>
                                                                <th>Sub-Total</th>
                                                                <td>
                                                                    <input class="form-control-plaintext" type="number" 
                                                                    formControlName="breakBulkPerShipmentSubTotal" [attr.disabled]="true">
                                                                </td>
                                                                <td></td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                    <table class="table table-borderless table-striped table-head-dark table-head-condensed table-head-py-3">
                                                        <tfoot class="table-success">
                                                            <tr>
                                                                <th>Total</th>
                                                                <td>
                                                                    <input class="form-control-plaintext" type="number" 
                                                                    formControlName="breakBulkTotal" [attr.disabled]="true">
                                                                </td>
                                                                <td></td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            
                                            </div>
                                            <!-- ToDo, if only fcl, dont show per unit table. -->
                                            <!-- Additional Charges Per UNIT -->
                                            <div *ngIf="!additionalChargePerUnitBool" class="table-responsive">
                                                <h3 class="text-center">Additional Charges/Unit</h3>
                                                    <table class="table table-borderless table-striped table-head-dark table-head-condensed table-head-py-3">
                                                        <thead>
                                                            <tr>
                                                                <td><b> Additional Charges </b></td>
                                                                <td><b> Per {{ chargableWeightUOM }} </b></td>
                                                                <td>
                                                                    <button class="text-white btn-success" (click)="addAdditionalChargesPerUnit(vendorInd)"><i class="fa fa-plus"></i> <b> Charge</b> </button>
                                                                </td>
                                                            </tr>
                                                        </thead>
                                                        <tbody formArrayName="additionalChargesPerUnit">
                                                            <tr style="font-size: 12px" *ngFor="let item of vendorRowAdditionalChargesPerUnit(vendorInd).controls; let addChargePerUnitInd = index"
                                                                [formGroupName]="addChargePerUnitInd">
                                                                <td>
                                                                    <mat-form-field appearance="standard">
                                                                        <input matInput class="form-control-plaintext" placeholder="Enter Charge Name"
                                                                        type="text" formControlName="description" min="0">
                                                                    </mat-form-field>
                                                                </td>
                                                                <td>
                                                                    <mat-form-field appearance="standard">
                                                                        <input matInput class="form-control-plaintext" placeholder="Enter Charge"
                                                                        type="number" (change)="calculateAdditionalChargePerUnitSea(vendorInd, addChargePerUnitInd)"
                                                                        formControlName="charge" min="0">
                                                                    </mat-form-field>
                                                                </td>
                                                                <td>
                                                                    <button class="btn-outline-danger" (click)="deleteAdditionalChargesPerUnit(vendorInd, addChargePerUnitInd)">
                                                                        <i class="fa fa-trash"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot class="table-success">
                                                            <tr>
                                                                <th>Sub-Total</th>
                                                                <td>

                                                                    <input class="" type="number"
                                                                    formControlName="additionalChargePerUnitSubTotal" [attr.disabled]="true">
                                                                    {{ currency }}
                                                                </td>
                                                                <td></td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                            </div>
                                            <!-- Additional Charges Per SHIPMENT -->
                                            <div class="table-responsive">
                                                <h3 class="text-center">Additional Charges/Shipment</h3>
                                                <table class="table table-borderless table-striped table-head-dark table-head-condensed table-head-py-3">
                                                            <thead>
                                                                <tr>
                                                                    <td><b> Additional Charges </b></td>
                                                                    <td><b> Per Shipment </b></td>
                                                                    <td>
                                                                        <button class="text-white btn-success" (click)="addAdditionalChargesPerShipment(vendorInd)"><i class="fa fa-plus"></i> <b> Charge</b> </button>
                                                                    </td>
                                                                </tr>
                                                            </thead>
                                                            <tbody formArrayName="additionalChargesPerShipment">
                                                                <tr style="font-size: 12px" *ngFor="let item of vendorRowAdditionalChargesPerShipment(vendorInd).controls; let addChargePerShipmentInd = index"
                                                                    [formGroupName]="addChargePerShipmentInd">
                                                                    <td>
                                                                        <mat-form-field appearance="standard">
                                                                            <input matInput class="form-control-plaintext" placeholder="Enter Charge Name"
                                                                            type="text" formControlName="description" min="0">
                                                                        </mat-form-field>
                                                                    </td>
                                                                    <td>
                                                                        <mat-form-field appearance="standard">
                                                                            <input matInput class="form-control-plaintext" placeholder="Enter Charge"
                                                                            type="number" (change)="calculateAdditionalChargePerShipmentSea(vendorInd, addChargePerShipmentInd)"
                                                                            formControlName="charge" min="0">
                                                                        </mat-form-field>
                                                                    </td>
                                                                    <td>
                                                                        <button class="btn-outline-danger" (click)="deleteAdditionalChargesPerShipment(vendorInd, addChargePerShipmentInd)">
                                                                            <i class="fa fa-trash"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tfoot class="table-success">
                                                                <tr>
                                                                    <th>Sub-Total</th>
                                                                    <td>
                                                                        <input class="" type="number"
                                                                        formControlName="additionalChargePerShipmentSubTotal" [attr.disabled]="true">
                                                                        {{ currency }}
                                                                    </td>
                                                                    <td></td>
                                                                </tr>
                                                            </tfoot>
                                                </table>
                                                <table class="table table-borderless table-striped table-head-dark table-head-condensed table-head-py-3">
                                                    <tfoot class="table-success">
                                                        <tr>
                                                            <th>Total</th>
                                                            <td>
                                                                <input class="" type="number"
                                                                    formControlName="additionalChargeTotal" [attr.disabled]="true">
                                                                    {{ currency }}
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>

                                            <!-- Grand Total -->
                                            <div class="table-responsive">
                                                <table class="table table-borderless table-striped table-head-dark table-head-condensed table-head-py-3">
                                                    <tfoot class="table-success">
                                                        <tr>
                                                            <th>Grand Total</th>
                                                            <td>
                                                                <input class="" type="text" 
                                                                    formControlName="amount" [attr.disabled]="true">
                                                                    {{ currency }}
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    </mat-tab>
                                </mat-tab-group>
                            </div>
                            <div *ngIf="isBidUpdate" class="row justify-content-center">
                                <div class="col-md-6 form-group ml-3 mt-2">
                                    <div class="form-check ml-1">
                                        <input class="form-check-input" formControlName="noRegretOffer" type="checkbox"
                                        value="" id="noRegret">
                                        <h4 class="">No Regret Offer</h4>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="row justify-content-center">
                            <div class="col-md-6 form-group pt-4 pt-lg-5">
                                <button *ngIf="createQuote" class="btn btn-success w-100" type="submit" data-target="item-3"
                                    (click)="vendorSubmitQuote()">Submit</button>
                                <button *ngIf="updateQuote" class="btn btn-success w-100" type="submit" data-target="item-3"
                                    (click)="updateQuoteVendor()">Update</button>
                            </div>
                        </div>
                    </div>
                    <div class="row" align="right">
                        <div class="col-lg-12">
                            <p class="bg-white text-dark">
                                Note: * denotes mandatory fields
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>