import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { EnquiryService } from '../helpers/enquiry.service';
import Swal from 'sweetalert2';
import { SocketService } from '../helpers/socket.service';

@Component({
  selector: 'app-client-rfq-list',
  templateUrl: './client-rfq-list.component.html',
  styleUrls: ['./client-rfq-list.component.css']
})
export class ClientRfqListComponent implements OnInit {

  constructor(private enquiryService: EnquiryService, private router: Router, private route: ActivatedRoute, private socketService: SocketService) { }

  page: number = 0;
  size: number = 5;
  dataSource!: MatTableDataSource<any>;
  checkedList: Array<any> = [];
  displayedColumns: string[] = ['id', 'enquiryDate', 'modeOfShipment', 'description', 'lastSubmissionDate', 'quoteSubmitted', 'actions'];
  // status

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  title: string;
  typeOfEnquiry: string;
  enquiryLength: number;
  serchFilter: Boolean = false;
  nodata: Boolean = true;
  typeBool = false;

  ngOnInit(): void {


    this.route.paramMap.subscribe((params) => {
      let _type = params.get('type');
      let type = _type.substring(0, 1).toLowerCase();
      this.getEnquiryForType(type);
    });

    // this.socketService.connectTOServerPopUpFuction();
  }

  getAllEnquires() {
    this.enquiryService.getAllEnquiries(this.page, this.size, this.typeOfEnquiry).subscribe(
      (response) => {
        if(response.content != null){
          this.enquiryLength = response.totalElements;
          for (let index = 0; index < response.content.length; index++) {
            const element = response.content[index];
            if (element.modeOfShipment == 1) {
              element["modeOfShipmentLabel"] = "AIR"
            }
            else if (element.modeOfShipment == 2) {
              element["modeOfShipmentLabel"] = "SEA"
            }
            else if (element.modeOfShipment == 3) {
              element["modeOfShipmentLabel"] = "ROAD"
            }
            else {
              element["modeOfShipmentLabel"] = "RAIL"
            }
            if (element.assignedVendors != null) {
              element["lenghtOfassignedVendors"] = element.assignedVendors.length
            }
          }
          this.dataSource = new MatTableDataSource(response.content);
          this.dataSource.sort = this.sort;
        }
      },
      (error) => {
        console.log(error);
      },
    );

  }

  applyFilter(event: Event) {
    console.log(event);
    const filterValue = (event.target as HTMLInputElement).value;
    console.log(filterValue);
    this.nodata = false;
    this.serchFilter = true;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  createEnquiry() {
    let type = this.typeOfEnquiry;
    this.router.navigate(['/v1/client-rfq/creation', type]);
  }

  compare(event, id, index) {
    let value = event.srcElement.checked;
    let _id = id;
    if (value == true) {
      this.checkedList.push(_id);
    }
    else if (value == false) {
      this.checkedList.splice(this.checkedList.indexOf(_id), 1);
    }
    else {
      console.log("Cant do this");
    }
  }

  createReverseAuction() {
    if (this.checkedList.length > 1 || this.checkedList.length == 0) {
      Swal.fire({
        titleText: "Warning",
        icon: "warning",
        text: "Kindly Select one enquiry",
        timer: 5000
      })
    }
    else {
      this.router.navigate(['/v1/client-auction/creation/', this.checkedList[0], 'r'])
    }
  }

  compareQuotes() {
    if (this.checkedList.length > 1 || this.checkedList.length == 0) {
      Swal.fire({
        titleText: "Warning",
        icon: "warning",
        text: "Kindly Select one enquiry",
        timer: 5000
      })
    }
    else {
      this.router.navigate(['/v1/client-rfq/quote-comparison/', this.checkedList[0]]);
    }
  }

  editEnquiry(enquiry: any) {
    this.router.navigate(['v1/client-rfq/edit/', enquiry]);
  }

  getEnquiryForType(type: string) {
    if (type == 'b') {
      this.typeBool = false;
      this.title = "Budgetory Enquiry Listing";
      this.typeOfEnquiry = 'b';
    }
    else if (type == 'f') {
      this.typeBool = true;
      this.title = "Firm Enquiry Listing";
      this.typeOfEnquiry = 'f';
    }
    else {
      console.log("CODE IS BROKEN");
    }
    this.getAllEnquires();
  }

  onPageChange(event: PageEvent) {
    this.size = event.pageSize;
    this.page = event.pageIndex;
    this.getAllEnquires();
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  recentEnquiry(){
    this.enquiryService.getAllRecentEnquiries(this.page, this.size, this.typeOfEnquiry).subscribe(
      (response) => {
        console.log(response);
        if (response != null) {
          this.dataSource = null;
          let content: Array<any> = response.content;
          this.enquiryLength = response.totalElements;
          if (content.length > 0) {
            for (let index = 0; index < content.length; index++) {
              const element = content[index];
              if (element.modeOfShipment == 1) {
                element["modeOfShipmentLabel"] = "AIR"
              }
              else if (element.modeOfShipment == 2) {
                element["modeOfShipmentLabel"] = "SEA"
              }
              else if (element.modeOfShipment == 3) {
                element["modeOfShipmentLabel"] = "ROAD"
              }
              else {
                element["modeOfShipmentLabel"] = "RAIL"
              }
              if (element.assignedVendors != null) {
                element["lenghtOfassignedVendors"] = element.assignedVendors.length
              }
            }
            this.dataSource = new MatTableDataSource(response.content);
            this.dataSource.sort = this.sort;
            // this.dataSource.sortingDataAccessor = (item, property) => {
            //   switch (property) {
            //     case 'date': return new Date(item.lastSubmissionDate);
            //     default: return item[property];
            //   }
            // }
          }
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  dueSoonEnquiry(){
    this.enquiryService.getAllDueSoonEnquiries(this.page, this.size, this.typeOfEnquiry).subscribe(
      (response) => {
        console.log(response);
        if (response != null) {
          this.dataSource = null;
          let content: Array<any> = response.content;
          this.enquiryLength = response.totalElements;
          if (content.length >= 1) {
            for (let index = 0; index < content.length; index++) {
              const element = content[index];
              if (element.modeOfShipment == 1) {
                element["modeOfShipmentLabel"] = "AIR"
              }
              else if (element.modeOfShipment == 2) {
                element["modeOfShipmentLabel"] = "SEA"
              }
              else if (element.modeOfShipment == 3) {
                element["modeOfShipmentLabel"] = "ROAD"
              }
              else {
                element["modeOfShipmentLabel"] = "RAIL"
              }
              if (element.assignedVendors != null) {
                element["lenghtOfassignedVendors"] = element.assignedVendors.length
              }
            }
            this.dataSource = new MatTableDataSource(response.content);
            this.dataSource.sort = this.sort;
            // this.dataSource.sortingDataAccessor = (item, property) => {
            //   switch (property) {
            //     case 'date': return new Date(item.lastSubmissionDate);
            //     default: return item[property];
            //   }
            // }
          }
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }



}
