import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AuthServiceService } from '../helpers/auth-service.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {

  otpForm!: FormGroup;
  showOtpInput!: boolean;
  sendOtpInput: boolean = true;
  otpValue!: number;
  submitted: boolean = false;
  timeLeft: number
  disableBtn: boolean = false
  disableEmailInput: boolean = false

  constructor(private form: FormBuilder, private authService: AuthServiceService, private route: Router) { }

  ngOnInit(): void {
    this.otpForm = this.form.group({
      emailId: [null, [Validators.required, Validators.email]],
      otp: [null, [Validators.required]]
    })
    this.gotoTop();
  }

  get f() { return this.otpForm.controls; }

  sendOtp() {
    if (this.f.emailId.value == '' || this.f.emailId.getError('email')) {
      Swal.fire({
        titleText: "Warning",
        text: "Enter valid EmailId",
        icon: "warning",
        timer: 5000
      })
    }
    else {
      this.submitted = true;
      this.authService.sendOtpOnEmail(this.f.emailId.value).subscribe(
        (response) => {
          if (response.code == 200) {
            this.showOtpInput = true;
            this.sendOtpInput = false;
            this.disableBtn = true;
            this.f.emailId.disable();
            this.startTimer()
            Swal.fire({
              titleText: "Success",
              text: response.message,
              icon: "success",
              timer: 5000
            })
          }
          else {
            Swal.fire({
              titleText: "Success",
              text: response.message,
              icon: "success",
              timer: 5000
            })
          }
        },
        (_error) => {
          let error = _error.error
          console.log(error);
          if (error.errorCode == 412) {
            Swal.fire({
              titleText: "Error",
              text: error.errorMessage,
              icon: "error",
              timer: 5000
            })
          }
          else if (error.status == 412) {
            Swal.fire({
              titleText: "Error",
              text: error.message,
              icon: "error",
              timer: 5000
            })
          }
          else if (error.status == 403) {
            Swal.fire({
              titleText: "Error",
              text: error.errorMessage,
              icon: "error",
              timer: 5000
            })
          }
        },
      )
    }
  }

  verifyOtp() {
    this.authService.verifyUser(this.f.emailId.value, this.f.otp.value).subscribe(
      (response) => {
        if (response.code == 202) {
          Swal.fire({
            titleText: "Success",
            text: response.message,
            icon: "success",
            timer: 5000
          })
          sessionStorage.setItem('emailId', this.f.emailId.value);
          this.route.navigate(['/reset-password']);
        }
      },
      (_error) => {
        let error = _error.error
        if (error.errorCode == 412) {
          Swal.fire({
            titleText: "Error",
            text: error.errorMessage,
            icon: "error",
            timer: 5000
          })
          this.f.otp.reset();
        }
      },
    )
  }

  resendOTP() {
    this.authService.sendOtpOnEmail(this.f.emailId.value).subscribe(
      (response) => {
        if (response.code == 200) {
          this.showOtpInput = true;
          this.sendOtpInput = false;
          this.disableBtn = true;
          this.startTimer()
          Swal.fire({
            titleText: "Success",
            text: response.message,
            icon: "success",
            timer: 5000
          })
        }
        else {
          Swal.fire({
            titleText: "Success",
            text: response.message,
            icon: "success",
            timer: 5000
          })
        }
      },
      (_error) => {
        let error = _error.error
        console.log(error);
        if (error.errorCode == 412) {
          Swal.fire({
            titleText: "Error",
            text: error.errorMessage,
            icon: "error",
            timer: 5000
          })
        }
        else if (error.status == 403) {
          Swal.fire({
            titleText: "Error",
            text: error.errorMessage,
            icon: "error",
            timer: 5000
          })
        }
      },
    )
  }

  startTimer() {
    this.timeLeft = 10
    let interval;
    interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
        if (this.timeLeft == 0) {
          clearInterval(interval);
          this.disableBtn = false;
        }
      }
    }, 1000);
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
}
