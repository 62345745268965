import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EnquiryService } from 'src/app/helpers/enquiry.service';
import { MasterTableService } from 'src/app/helpers/master-table.service';

@Component({
  selector: 'app-rfq-view',
  templateUrl: './rfq-view.component.html',
  styleUrls: ['./rfq-view.component.css']
})
export class RfqViewComponent implements OnInit {

  enquiryId: any;
  enquiryObject: any;
  title: string = "View Enquiry";
  mode_of_shipment: Array<any> = [];
  trade_type: Array<any> = [];
  type_cargo: Array<any> = [];
  nature_of_movement:Array<any> = [];
  filteredNatureOfGoods: Array<any> = [];
  nature_goods: Array<any> = [];
  pickup_terms: Array<any> = [];
  dropoff_terms: Array<any> = [];
  type_packing:Array<any> = [];
  currencyPicker: Array<any> = [];
  countries: Array<any> = [];
  breadcrumbBool: Boolean = true;
  backfirstPage: Boolean = false;
  backDetailsPage:Boolean = false;
  backParticularsPage:Boolean = false;
  backVendorPage:Boolean = false;
  modeOfShipment:any;
  tradeType:any;
  typeOfCargo:any;
  natureOfGoods:any;
  currency:any;
  typeOfPacking:any;
  natureOfMovement:any;
  pickUpIncoterms:any;
  deliveryIncoterms: any;
  containerLoadType: any;
  shipmentPackages: Array<any> = [];
  containers: Array<any> = [];
  breakBulks: Array<any> = [];
  assignedVendors: Array<any> = [];
  termsConditions: Array<any> = [];
  artifacts: Array<any> = [];

  public container_load_type = [
    { 'id': 1, 'value': 'Full Container Load (FCL)' },
    { 'id': 2, 'value': 'Less than Container Load (LCL)' },
    { 'id': 3, 'value': 'Both (FCL + LCL)' },
    { 'id': 4, 'value': 'Break Bulk' }
  ]

  constructor(private enquiryService: EnquiryService, private route: ActivatedRoute, private router: Router, private masterTableService: MasterTableService,
    private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.enquiryId = this.route.snapshot.paramMap.get("id");
    this.getEnquiryById(this.enquiryId);
  }

  getEnquiryById(id){
    this.enquiryService.fetchEnquiryById(id).subscribe(
      (response) => {
        this.enquiryObject = response;
        let lastSubmissionDate = this.datePipe.transform(response.lastSubmissionDate, 'yyyy-MM-ddTHH:mm');
        this.enquiryObject.lastSubmissionDate = lastSubmissionDate;
        if (this.enquiryObject.storingType == 'n') {
          this.enquiryObject.storingType = "Non-Stackable"
        } else if (this.enquiryObject.storingType == 's') {
          this.enquiryObject.storingType = "Stackable"
        }

        if(this.enquiryObject.modeOfShipment == 1){
          this.shipmentPackages = this.enquiryObject.shipmentPackages
        }
        else if(this.enquiryObject.modeOfShipment == 2){
          this.containerLoadType = this.container_load_type.find(
            (x) => {
              if(x.id == this.enquiryObject.containerLoadType){
                return x
              }
            }
          );
          if(this.containerLoadType.id == 1){
            this.containers = this.enquiryObject.containers;
            if(this.enquiryObject.breakBulks.length > 0){
              this.breakBulks = this.enquiryObject.breakBulks;
            }
          }
          else if(this.containerLoadType.id == 2){
            this.shipmentPackages = this.enquiryObject.shipmentPackages
          }
          else if(this.containerLoadType.id == 3){
            this.containers = this.enquiryObject.containers;
            this.shipmentPackages = this.enquiryObject.shipmentPackages
          }
          else if(this.containerLoadType.id == 4){
            this.breakBulks = this.enquiryObject.breakBulks;
          }
        }
        else if(this.enquiryObject.modeOfShipment == 3){}
        else if(this.enquiryObject.modeOfShipment == 4){}
        // shipmentPackages
        this.assignedVendors = response.assignedVendors;
        this.termsConditions = response.terms;
        this.artifacts = response.artifacts;
        this.getMasterTablesSessionStorage();
      },
      (error) => {
        console.log(error);
      },
    )
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  getModeOfShipment(){
    this.masterTableService.getModeOfShipment().subscribe(
      (response) => {
        this.mode_of_shipment = response;
        this.modeOfShipment = this.mode_of_shipment.find(
          (x) => {
            if(x.id == this.enquiryObject.modeOfShipment){
              return x.value
            }
          }
        )
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getTradeType(){
    this.masterTableService.getTradeType().subscribe(
      (response) => {
        this.trade_type = response;
        this.tradeType = this.trade_type.find(
          (x) => {
            if(x.id == this.enquiryObject.tradeType){
              return x.value
            }            
          }
        )
      },
      (error) => {
        console.log(error);
      },
    )
  }
  getTypeOfCargo(){
    this.masterTableService.getAllTypeOfCargo().subscribe(
      (response) => {
        this.type_cargo = response
        this.typeOfCargo =this.type_cargo.find(
          (x) => {
            if(x.id == this.enquiryObject.typeOfCargo){
              return x
            }
          }
        )
      },
      (error) => {
        console.log(error);
      },
    )
  }

  

  getMasterTablesSessionStorage() {
    // this.containerTypeList();
    this.getModeOfShipment();
    this.getTradeType();
    this.getTypeOfCargo();
    this.getNatureOfMovement();
    this.getPickUpIncoTerms();
    this.getDeliveryIncoTerms();
    this.getNatureOfGoods();
    this.getTypeOfPacking();
    this.getCurrency();
    this.getCountry();
    this.getAllBusinessType();
  }

  getPickUpIncoTerms() {
    this.masterTableService.getAllPickUpIncoTerms().subscribe(
      (response) => {
        this.pickup_terms = response;
        this.pickUpIncoterms = this.pickup_terms.find(
          (x) => {
            if(x.code == this.enquiryObject.pickUpIncoterms){
              return x
            }
          }
        )
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getDeliveryIncoTerms() {
    this.masterTableService.getDeliveryIncoTerms().subscribe(
      (response) => {
        this.dropoff_terms = response;
        this.deliveryIncoterms = this.dropoff_terms.find(
          (x) => {
            if(x.code == this.enquiryObject.deliveryIncoterms){
              return x
            }
          }
        )
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getTypeOfPacking() {
    this.masterTableService.getAllTypeOfPacking().subscribe(
      (response) => {
        this.type_packing = response;
        this.typeOfPacking = this.type_packing.find(
          (x) => {
            if(x.id == this.enquiryObject.typeOfPacking){
              return x
            }
          }
        )
      },
      (error) => {
        console.log(error);
      },
    )
  }
  getCurrency() {
    this.masterTableService.getAllCurrency().subscribe(
      (response) => {
        this.currencyPicker = response;
        this.currency = this.currencyPicker.find(
          (x) => {
            if(x.id == this.enquiryObject.currency.id){
              return x
            }
          }
        )
      },
      (error) => {
        console.log(error);
      },
    )
  }
  getCountry() {
    this.masterTableService.getAllCountries().subscribe(
      (response) => {
        this.countries = response;
      },
      () => {},
    )
  }
  getAllBusinessType() {
    // this.masterTableService.getAllBusinessType().subscribe(
    //   () => {},
    //   () => {},
    // )
  }
  getNatureOfGoods() {
    this.masterTableService.getNatureOfGoods().subscribe(
      (response) => {
        this.nature_goods = response
        this.natureOfGoods = this.nature_goods.find(
          (x) => {
            if(x.id == this.enquiryObject.natureOfGoods){
              return x
            }
          }
        )
      },
      (error) => {
        console.log(error);
      },
    )
  }
  getNatureOfMovement() {
    this.masterTableService.getNatureOfMovement().subscribe(
      (response) => {
        this.nature_of_movement = response
        this.natureOfMovement= this.nature_of_movement.find(
          (x) => {
            if(x.code == this.enquiryObject.natureOfMovement){
              return x
            }
          }
        )
      },
      (error) => {
        console.log(error);
      }
    )
  }

  gotoTop() {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

  goBackShipment() {
    let _stepper = document.getElementById("item-1").classList.contains("active");
    let _sidebar = document.getElementById("list-item-1").classList.contains("active");
    let _content = document.getElementById("item-6").classList.contains("active");
    if (_stepper && _sidebar && _content) {
      document.getElementById("item-1").classList.remove("active");
      document.getElementById("list-item-1").classList.remove("active");
      document.getElementById("item-6").classList.remove("active");
    }
    document.getElementById("list-item-0").classList.add("active");
    document.getElementById("item-0").classList.add("active");
    document.getElementById("item-5").classList.add("active");
    this.breadcrumbBool = true;
    this.backfirstPage = false;
  }

  goBackDetails() {
    let _stepper = document.getElementById("item-2").classList.contains("active");
    let _sidebar = document.getElementById("list-item-2").classList.contains("active");
    let _content = document.getElementById("item-7").classList.contains("active");
    if (_stepper && _sidebar && _content) {
      document.getElementById("item-2").classList.remove("active");
      document.getElementById("list-item-2").classList.remove("active");
      document.getElementById("item-7").classList.remove("active");
    }
    document.getElementById("list-item-1").classList.add("active");
    document.getElementById("item-1").classList.add("active");
    document.getElementById("item-6").classList.add("active");
    this.backDetailsPage = false;
    this.backfirstPage = true;
  }

  goBackParticulars() {
    let _stepper = document.getElementById("item-3").classList.contains("active");
    let _sidebar = document.getElementById("list-item-3").classList.contains("active");
    let _content = document.getElementById("item-8").classList.contains("active");
    if (_stepper && _sidebar && _content) {
      document.getElementById("item-3").classList.remove("active");
      document.getElementById("list-item-3").classList.remove("active");
      document.getElementById("item-8").classList.remove("active");
    }
    document.getElementById("list-item-2").classList.add("active");
    document.getElementById("item-2").classList.add("active");
    document.getElementById("item-7").classList.add("active");
    this.backDetailsPage = true;
    this.backParticularsPage = false;
  }
  goBackVendor() {
    let _stepper = document.getElementById("item-4").classList.contains("active");
    let _sidebar = document.getElementById("list-item-4").classList.contains("active");
    let _content = document.getElementById("item-9").classList.contains("active");
    if (_stepper && _sidebar && _content) {
      document.getElementById("item-4").classList.remove("active");
      document.getElementById("list-item-4").classList.remove("active");
      document.getElementById("item-9").classList.remove("active");
    }
    document.getElementById("list-item-3").classList.add("active");
    document.getElementById("item-3").classList.add("active");
    document.getElementById("item-8").classList.add("active");
    this.backVendorPage = false;
    this.backParticularsPage = true;
  }

  nextDetailsPage(){
    let _stepper = document.getElementById("item-0").classList.contains("active");
    let _sidebar = document.getElementById("list-item-0").classList.contains("active");
    let _content = document.getElementById("item-5").classList.contains("active");
    if (_sidebar && _content && _stepper) {
      document.getElementById("item-0").classList.remove("active");
      document.getElementById("item-5").classList.remove("active");
    }
    this.breadcrumbBool = false;
    this.backfirstPage = true;
    document.getElementById("item-1").classList.add("active");
    document.getElementById("list-item-1").classList.add("active");
    document.getElementById("item-6").classList.add("active");
    this.gotoTop();
  }

  nextParticularsPage() {
    let _stepper = document.getElementById("item-1").classList.contains("active");
    let _sidebar = document.getElementById("list-item-1").classList.contains("active");
    let _content = document.getElementById("item-6").classList.contains("active");
    if (_stepper && _sidebar && _content) {
      document.getElementById("item-1").classList.remove("active");
      document.getElementById("item-6").classList.remove("active");
    }
    this.backDetailsPage = true;
    this.backfirstPage = false;
    document.getElementById("item-2").classList.add("active");
    document.getElementById("list-item-2").classList.add("active");
    document.getElementById("item-7").classList.add("active");
    this.gotoTop();
  }

  nextVendorAssignmentPage() {
    let _stepper = document.getElementById("item-2").classList.contains("active");
    let _sidebar = document.getElementById("list-item-2").classList.contains("active");
    let _content = document.getElementById("item-7").classList.contains("active");
    if (_stepper && _sidebar && _content) {
      document.getElementById("item-2").classList.remove("active");
      document.getElementById("item-7").classList.remove("active");
    }
    this.backDetailsPage = false;
    this.backParticularsPage = true;
    document.getElementById("item-3").classList.add("active");
    document.getElementById("list-item-3").classList.add("active");
    document.getElementById("item-8").classList.add("active");
    this.gotoTop();
  }

  nextTermsPage() {
    let _stepper = document.getElementById("item-3").classList.contains("active");
    let _sidebar = document.getElementById("list-item-3").classList.contains("active");
    let _content = document.getElementById("item-8").classList.contains("active");
    if (_stepper && _sidebar && _content) {
      document.getElementById("item-3").classList.remove("active");
      document.getElementById("item-8").classList.remove("active");
    }
    this.backVendorPage = true;
    this.backParticularsPage = false;
    document.getElementById("item-4").classList.add("active");
    document.getElementById("list-item-4").classList.add("active");
    document.getElementById("item-9").classList.add("active");
    this.gotoTop();
  }

  backPage(){
    history.back();
  }

  dataURItoBlob(data) {
    const linkSource = data.content;
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = data.name;
    downloadLink.click();
  }

}
