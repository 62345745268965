import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { OnboardService } from '../helpers/onboard.service';
import { PlatformDialogBoxComponent } from './platform-dialog-box/platform-dialog-box.component';

@Component({
  selector: 'app-platform-param',
  templateUrl: './platform-param.component.html',
  styleUrls: ['./platform-param.component.css']
})
export class PlatformParamComponent implements OnInit {

  page: number = 0;
  size: number = 25;
  paramsLength: number;
  displayedColumns: string[] = ['paramKey', 'paramValue', 'createdDate', 'updatedDate', 'action']
  dataSource = new MatTableDataSource;
  serchFilter: Boolean = false;
  nodata: Boolean = true;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private platformService: OnboardService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getAllPlatformParams();
  }

  getAllPlatformParams() {
    this.platformService.getAllPlatformParam(this.page, this.size).subscribe(
      (response) => {
      let content: Array<any> = response.content;
      if (content.length > 0) {
        this.paramsLength = response.totalElements;
        this.dataSource = new MatTableDataSource(content)
        this.dataSource.sort = this.sort
      }
    },
    (error)=>{
      console.log(error);
      let _error = error.error;
      if (_error.errorCode == 412) {
        Swal.fire({
          titleText: "Warning",
          icon: "warning",
          text: _error.errorMessage,
          timer: 5000
        });
      } else if (_error.errorCode == 403) {
        Swal.fire({
          titleText: "Warning",
          icon: "warning",
          text: _error.errorMessage,
          timer: 5000
        });
      } else if (_error.errorCode == 500) {
        Swal.fire({
          titleText: "Warning",
          icon: "warning",
          text: _error.errorMessage,
          timer: 5000
        });
      }
      else {
        Swal.fire({
          titleText: "Error",
          icon: "error",
          text: "Something Went Wrong",
          timer: 5000
        });
      }
    })
  }

  openDialog(action, obj) {
    obj.action = action
    const dialogRef = this.dialog.open(PlatformDialogBoxComponent, {
      width: '300px',
      data: obj
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == 'Update') {
        this.updatePlatformParam(result.data.id, result.data);
      }
    });
  }

  updatePlatformParam(id, data) {
    this.platformService.updatePlatformParam(id, data).subscribe((response) => {
      console.log(response);
      if (response.code == 201) {
        Swal.fire({
          title: response.message,
          icon: "success",
          timer: 3000,
        })
        this.getAllPlatformParams();
      }
    },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.nodata = false;
    this.serchFilter = true;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  onPageChange(event: PageEvent) {
    this.size = event.pageSize;
    this.page = event.pageIndex;
    this.getAllPlatformParams();
  }
}
