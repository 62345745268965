import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { OnboardService } from '../helpers/onboard.service';
import { SocketService } from '../helpers/socket.service';


@Component({
  selector: 'app-configuration-paramaters',
  templateUrl: './configuration-paramaters.component.html',
  styleUrls: ['./configuration-paramaters.component.css']
})
export class ConfigurationParamatersComponent implements OnInit {

  clientParamForm: FormGroup;
  page = "";
  size = "";
  addButtonBool: Boolean = false;
  updateButtonBool: Boolean = false;
  lockBool: Array<any> = [
    {
      "key": 1,
      "value": "TRUE"
    },
    {
      "key": 0,
      "value": "FALSE"
    },
  ]

  constructor(private parameterService: OnboardService, private fb: FormBuilder, private socketService: SocketService) { }

  ngOnInit(): void {
    this.clientParamForm = this.fb.group({
      hardExpiryDurationInDays: [''],
      lockAfterMaxUnsuccessfulAttempts: [new Boolean()],
      maxBidTimeExtensions: [''],
      maxExtensionsAllowed: [''],
      maxUnsuccessfulAttempts: [''],
      otpExpiryInMinutes: [''],
      passwordExpiryDays: [''],
      rfqDueReminersBeforeDueDate: [''],
      softExpiryDurationInDays: ['']
    })
    this.getClientParam();

    // this.socketService.connectTOServerPopUpFuction();
  }
  get _clientParam() { return this.clientParamForm.controls }
 
  getClientParam() {
    this.parameterService.getAllClientParam(this.page, this.size).subscribe(
      (response) => {
        console.log(response.content)
        let content: Array<any> = response.content;
        if (content.length >= 1) {
          this.clientParamForm.addControl("id", new FormControl());
          for (let index = 0; index < content.length; index++) {
            const element = content[index];
            this._clientParam.hardExpiryDurationInDays.patchValue(element.hardExpiryDurationInDays);
            this._clientParam.lockAfterMaxUnsuccessfulAttempts.patchValue(element.lockAfterMaxUnsuccessfulAttempts);
            this._clientParam.maxBidTimeExtensions.patchValue(element.maxBidTimeExtensions);
            this._clientParam.maxExtensionsAllowed.patchValue(element.maxExtensionsAllowed);
            this._clientParam.maxUnsuccessfulAttempts.patchValue(element.maxUnsuccessfulAttempts);
            this._clientParam.otpExpiryInMinutes.patchValue(element.otpExpiryInMinutes);
            this._clientParam.passwordExpiryDays.patchValue(element.passwordExpiryDays);
            this._clientParam.rfqDueReminersBeforeDueDate.patchValue(element.rfqDueReminersBeforeDueDate);
            this._clientParam.softExpiryDurationInDays.patchValue(element.softExpiryDurationInDays);
            this._clientParam.id.patchValue(element.id);
            this.updateButtonBool = true
            console.log(this.clientParamForm.value);
          }
          console.log("Update");
        }
        else {
          this.addButtonBool = true;
          console.log("Add method");
        }
      },
      (error) => {
        console.log(error);
      }
    )
  }

  updateClientParam() {
    if (this._clientParam.lockAfterMaxUnsuccessfulAttempts.value == 'false') {
      console.log(this.clientParamForm.value);
      this._clientParam.lockAfterMaxUnsuccessfulAttempts.patchValue(false);
    }
    else {
      console.log(this.clientParamForm.value);
      this._clientParam.lockAfterMaxUnsuccessfulAttempts.patchValue(true);
    }
    this.parameterService.updateClientParam(this._clientParam.id.value, this.clientParamForm.value).subscribe(
      (response) => {
        if (response.code == 201) {
          console.log("UPDATED SUCESSFULY");
          Swal.fire({
            titleText: "Success",
            text: response.message,
            icon: "success",
            timer: 5000
          });
        }
      },
      (error) => {
        console.log(error);
        Swal.fire({
          titleText: "Error",
          icon: "error",
          timer: 5000,
          text: "Something went wrong"
        })
        setTimeout(() => {
          this.getClientParam();
        }, 500);
      }
    )
  }

}

