<section class="wrapper multi-step-form">
    <div class="container-fluid">
        <div class="row">
            <div
                class="col-md-4 col-xl-3 col-left pt-12 d-flex flex-column justify-content-center position-sticky top-0">
                <div class="box-absolute d-flex">
                    <app-sidebar></app-sidebar>
                </div>
                <div class="box mx-auto">
                    <h3 class="text-center text-md-start">Vendor Onboarding</h3>
                    <ul class="list-unstyled step-one">
                        <li class="active">
                            <div class="icon">
                                <svg width="13" height="9" viewBox="0 0 13 9" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M1 5L3.97978 7.97978C4.17504 8.17504 4.49162 8.17504 4.68689 7.97978L11.6667 1"
                                        stroke="#003D90" stroke-width="1.5" stroke-linecap="round"></path>
                                </svg>
                            </div><span>Company Details</span>
                        </li>
                        <li class="active">
                            <div class="icon">
                                <svg width="13" height="9" viewBox="0 0 13 9" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M1 5L3.97978 7.97978C4.17504 8.17504 4.49162 8.17504 4.68689 7.97978L11.6667 1"
                                        stroke="#003D90" stroke-width="1.5" stroke-linecap="round"></path>
                                </svg>
                            </div><span>Company Overview</span>
                        </li>
                        <li class="active">
                            <div class="icon">
                                <svg width="13" height="9" viewBox="0 0 13 9" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M1 5L3.97978 7.97978C4.17504 8.17504 4.49162 8.17504 4.68689 7.97978L11.6667 1"
                                        stroke="#003D90" stroke-width="1.5" stroke-linecap="round"></path>
                                </svg>
                            </div><span>Upload Documents</span>
                        </li>
                        <li class="active">
                            <div class="icon">
                                <svg width="13" height="9" viewBox="0 0 13 9" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M1 5L3.97978 7.97978C4.17504 8.17504 4.49162 8.17504 4.68689 7.97978L11.6667 1"
                                        stroke="#003D90" stroke-width="1.5" stroke-linecap="round"></path>
                                </svg>
                            </div><span>Certification</span>
                        </li>
                        <li class=" ">
                            <div class="icon">
                            </div><span>Sign In</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-8 col-xl-9 col-right px-start-end pb-6">
                <div class="topline py-start-end d-flex align-items-center justify-content-between mb-4 mb-md-5">
                    <ul class="breadcrumb p-0 m-0">
                        <li class="breadcrumb-item 3 brand" id="1">
                            <button [routerLink]="['/v1/vendor-onboard/documents']">Back</button>
                        </li>
                    </ul>
                    <div> <span>4/5</span></div>
                </div>
                <form [formGroup]="certificationForm" (ngSubmit)="vendorSubmitCert()">
                    <h3 class="text-center">Certification</h3>
                    <div class="row justify-content-center">
                        <div class="col-md-12 form-group">
                            <h5>I hereby affirm that all information supplied is true and accurate to the best of my
                                knowledge and belief, and I understand that this information will be considered material
                                in the evaluation of quotations, bids, and proposals. Notice must be given of any change
                                in status impacting the information provided within two (2) days of said change.</h5>
                        </div>
                        <div class="col-md-4 form-group">
                            <label class="form-label" for="name">Name*</label>
                            <input class="form-control" type="text" placeholder="Text Input" id="name"
                                formControlName="certifiedByName"
                                [ngClass]="{'is-invalid': certification.certifiedByName.invalid && ( certification.certifiedByName.dirty || certification.certifiedByName.touched )}" />
                            <div *ngIf="certification.certifiedByName.invalid && ( certification.certifiedByName.dirty || certification.certifiedByName.touched )"
                                class="text-danger validation-message">
                                Name is compulsory
                            </div>
                        </div>
                        <div class="col-md-4 form-group">
                            <label class="form-label" for="designation">Designation</label>
                            <input class="form-control" type="text" placeholder="Text Input" id="designation"
                                formControlName="certifiedByDesignation" />
                        </div>
                        <div class="col-md-4 form-group">
                            <label class="form-label" for="date">Date*</label>
                            <div class="input-group">
                                <input class="form-control" type="date" placeholder="DD/MM/YY" id="date"
                                    formControlName="certifiedOn" [min]="getToday()" [attr.disabled]="disabled"/>
                                <button class="input-group-btn invisible" type="button">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M18.2222 5H5.77778C4.79594 5 4 5.79594 4 6.77778V19.2222C4 20.2041 4.79594 21 5.77778 21H18.2222C19.2041 21 20 20.2041 20 19.2222V6.77778C20 5.79594 19.2041 5 18.2222 5Z"
                                            stroke="#003D90" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                        <path d="M16 3V7" stroke="#003D90" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                        <path d="M8 3V7" stroke="#003D90" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                        <path d="M4 10H20" stroke="#003D90" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div class="col-md-7 text-center form-group">
                            <div>
                                <input class="form-check-input" style="margin-right: 8px;" type="checkbox"
                                    id="i-accept-the-terms-&amp;-conditions*" name="option1" required="required"
                                    [(ngModel)]="terms_conditions" [ngModelOptions]="{standalone: true}" />
                                <label class="form-check-label" for="i-accept-the-terms-&amp;-conditions*">I accept the
                                    <a [routerLink]="['/v1/vendor-onboard/certification/term']" >
                                        terms & conditions
                                    </a>*
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-md-6 form-group pt-4 pt-lg-5">
                            <button class="btn btn-success w-100" type="submit">NEXT</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>