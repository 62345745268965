<!--header start-->
<header class="box-absolute d-flex align-items-center topline text-white">
    <app-sidebar></app-sidebar>
    <div class="page-title-center mt-3">
        <div class="text-center">
            <h1>Request Demo Listing</h1>
        </div>
    </div>
    <div class="breadcrumb-div mt-3">
        <ul class="breadcrumb p-0 m-0">
            <li class="breadcrumb-item 3" id="0"><a [routerLink]="['/v1/dashboard']">Dashboard</a></li>
            <li class="breadcrumb-item 3" id="1"><a>Listing</a></li>
            <li class="breadcrumb-item"><a>Request Demo</a></li>
        </ul>
    </div>
</header>
<!--header end-->
<div (click)="hideSideBar()">
<section class="banner text-center text-white d-flex" id="bnner">
    <div class="container position-relative">
        <div class="row justify-content-center">
            <div class="col-lg-12">
            </div>
        </div>
    </div>
</section>
<!--request start-->
<section class="request-demo-wrapper pb-10">
    <div class="container shadow bg-white">
        <div class="row">
            <div class="col-lg-12">
                <div class="box">
                    <form>
                        <div class="filter-wrap btn-group d-flex flex-wrap flex-md-nowrap justify-content-center gap-2 pb-3 pb-lg-4 px-3 px-lg-4">
                            <div class="input-group">
                                <input class="form-control rounded-3 px-5" type="text" matInput
                                    (keyup)="applyFilter($event)" placeholder="Search" #input>
                                <button class="input-group-btn px-3 end-0" type="button"><i class="fa fa-search"></i>
                                </button>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-borderless table-striped table-footer-dark table-head-dark" mat-table [dataSource]="dataSource" matSort
                                matSortDisableClear>
                                <ng-container matColumnDef="name">
                                    <th mat-header-cell class="text-white" *matHeaderCellDef mat-sort-header>  Name </th>
                                    <td mat-cell *matCellDef="let row; let i = index"> {{ row.pocFirstName + ' ' + row.pocLastName }} </td>
                                </ng-container>
                                <ng-container matColumnDef="emailId">
                                    <th mat-header-cell class="text-white" *matHeaderCellDef mat-sort-header> EmailId </th>
                                    <td mat-cell *matCellDef="let row; let i = index"> {{ row.pocEmailId }} </td>
                                </ng-container>
                                <ng-container matColumnDef="contactNumber">
                                    <th mat-header-cell class="text-white" *matHeaderCellDef mat-sort-header> Contact Number </th>
                                    <td mat-cell *matCellDef="let row; let i = index"> {{ row.pocContactNumber }} </td>
                                </ng-container>
                                <ng-container matColumnDef="designation">
                                    <th class="text-white" mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Designation
                                    </th>
                                    <td mat-cell *matCellDef="let row"> {{ row.pocDesignation || 'N/A' }} </td>
                                </ng-container>

                                <ng-container matColumnDef="address">
                                    <th class="text-white" mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Address
                                    </th>
                                    <td mat-cell *matCellDef="let row">
                                        <button>
                                            <a class="anchor-tag" (click)="openDialog(row)">Click</a>
                                        </button>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="actions">
                                    <th class="text-white" mat-header-cell *matHeaderCellDef>
                                        Actions
                                    </th>
                                    <td mat-cell *matCellDef="let row">
                                        <input type="checkbox" (change)="compare($event, row.id)" />
                                    </td>
                                </ng-container>

                                <tr class="text-left bg-dark" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


                                <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell text-center text-danger" colspan="6">
                                        <div *ngIf="serchFilter">
                                            No data matching the filter "{{ input.value }}"
                                        </div>
                                        <div *ngIf="nodata">
                                            <h5>Data is Not Available</h5>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                            <mat-paginator [pageSizeOptions]="[25,50,100]" aria-label="Select page of users" showFirstLastButtons [length]="requestDemoLength">
                            </mat-paginator>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-md-4 form-group pt-lg-3">
                                <button class="btn text-dark text-small btn-outline-success" (click)="onBoardingClient()"
                                    type="button">On-board Client</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!--request end -->
</div>
