import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from "@angular/material/table";
import { MasterTableService } from 'src/app/helpers/master-table.service';
import { DialogNatureOfGoodsComponent } from './dialog-nature-of-goods/dialog-nature-of-goods.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-nature-of-goods',
  templateUrl: './nature-of-goods.component.html',
  styleUrls: ['./nature-of-goods.component.css']
})
export class NatureOfGoodsComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<any>;
  displayedColumns = ['id', 'value', 'typeOfCargo', 'action'];
  serchFilter: Boolean = false;
  nodata: Boolean = true;
  page: number = 0;
  length: number;
  size: number = 25;

  sessionStorageUserData: any
  disableCreateBtn: boolean = true
  disableEditBtn: boolean = true
  disableDeleteBtn: boolean = true

  constructor(private service: MasterTableService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getNatureOfGoods();
    this.getCurrentUserDataFromSessionStorage();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.serchFilter = true;
    this.nodata = false;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDialog(action: String, obj: any) {
    obj.action = action;
    console.log(obj);
    const dialogRef = this.dialog.open(DialogNatureOfGoodsComponent, {
      width: '250px',
      data: obj
    });
    dialogRef.afterClosed().subscribe(
      (result) => {
        console.log(result);
        if (result.event == 'Add') {
          this.addRowData(result.data);
        } else if (result.event == 'Update') {
          this.updateRowData(result.data);
        } else if (result.event == 'Delete') {
          this.deleteRowData(result.data);
        }
      }
    );
  }

  addRowData(row_obj) {
    this.AddNatureOfGoods(row_obj);
    setTimeout(() => {
      this.getNatureOfGoods();
    }, 500);
  }
  updateRowData(row_obj) {
    this.updateNatureOfGoods(row_obj.id, row_obj);
    setTimeout(() => {
      this.getNatureOfGoods();
    }, 500);
  }
  deleteRowData(row_obj) {
    this.deleteNatureOfGoods(row_obj.id);
    setTimeout(() => {
      this.getNatureOfGoods();
    }, 500);
  }

  getNatureOfGoods() {
    this.service.getNatureOfGoodsPageable(this.page, this.size).subscribe(
      (response) => {
        if(response.content != null){
          this.dataSource = new MatTableDataSource(response.content);
          this.dataSource.sort = this.sort;
          this.length = response.totalElements;
        }
      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      }
    );
  }
  AddNatureOfGoods(data) {
    delete data.id;
    this.service.addNatureOfGoods(data).subscribe(
      (response) => {
        if (response.code == 201) {
          Swal.fire({
            titleText: "Success",
            icon: "success",
            text: response.message,
            timer: 5000,
          })
        }
      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      });
  }
  updateNatureOfGoods(id: any, data: any) {
    this.service.updateNatureOfGoods(id, data).subscribe(
      (response) => {
        if (response.code == 201) {
          Swal.fire({
            titleText: "Success",
            icon: "success",
            text: response.message,
            timer: 5000,
          })
        }

      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      });
  }
  deleteNatureOfGoods(id: any) {
    this.service.deleteNatureOfGoods(id).subscribe(
      (response) => {
        if (response.code == 201) {
          Swal.fire({
            titleText: "Success",
            icon: "success",
            text: response.message,
            timer: 5000,
          })
        }

      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      });
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  onPageChange(event: PageEvent) {
    this.size = event.pageSize;
    this.page = event.pageIndex;
    this.getNatureOfGoods();
  }

  getCurrentUserDataFromSessionStorage(){
    // create auth code
    // RB0000060
    // update auth code
    // RB0000063
    // delete auth code
    // RB0000088
      this.sessionStorageUserData = JSON.parse(sessionStorage.getItem('userData'));
      console.log('this is user data ', this.sessionStorageUserData);
      for (let index = 0; index < this.sessionStorageUserData.authorities.length; index++) {
        const authority = this.sessionStorageUserData.authorities[index];
        console.log(authority);
        if(authority == 'RB0000060'){
          console.log('create authority is given');
          this.disableCreateBtn = false
        } else if(authority == 'RB0000063'){
          console.log('update authority is given');
          this.disableEditBtn = false
        } else if(authority == 'RB0000088') {
          console.log('delete authority is given');
          this.disableDeleteBtn = false
        }
      }
    }
}

