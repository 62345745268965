<div class="container">
  <div class="card bg-transparent border-0 mb-4 mb-lg-5">
    <div class="card-body shadow rounded-xxl bg-white py-4 py-lg-5 px-3 px-lg-4" *ngIf="local_data.terms">
      <h4 class="text-primary">Auction Terms & Conditions</h4>
      <ol class="lead m-0">
        <li *ngFor="let item of local_data.terms">
          <div class="row">
            <div class="col-lg-6">
              <label class="mt-3" for="terms">{{ item.label }}</label>
            </div>
            <input class="form-control" type="text" id="terms" [(ngModel)]="item.content"
              [ngModelOptions]="{standalone: true}" disabled>
          </div>
        </li>
      </ol>

      <h4 *ngIf="local_data.artifacts" class="text-primary mt-4">Auction Documents</h4>
      <div class="row" *ngIf="local_data.artifacts">
        <div *ngFor="let item of local_data.artifacts" class="col-6 col-md-4 mb-3">
          <div class="img-thumbnail p-2 p-sm-3 shadow border-0 shadow rounded-xl"><img class="img-fluid w-100"
              src="assets/images/thumbnail.png" />
            <p class="mt-2 mt-sm-3 mb-0">{{ item.label }}
              <button (click)="downloadFileLocal(item)">
                <i class="fa fa-download" aria-hidden="true" title="Download"></i>
              </button>
            </p>
          </div>
        </div>
      </div>

      <div *ngIf="!local_data.isAgreed && local_data.commentOnTermsAndCondition == null" class="form-group" [formGroup]="termsAndConditionsForm">
        <div class="row mt-2 justify-content-center">
          <div class="col-md-8 text-center">
            <input id="acceptTerms" type="checkbox" class="form-check-input m-1" formControlName="isAgreed"
              (change)="toggleControl()">
            <label class="form-label m-1" for="acceptTerms">Accept Terms & Conditions</label>
          </div>
          <div class="col-md-8 mt-1">
            <textarea *ngIf="showCommentField" class="form-control" formControlName="comment" rows="2"
              placeholder="Provide comment"
              [ngClass]="{'is-invalid': controls['comment'].invalid && (controls['comment'].dirty || controls['comment'].touched)}"></textarea>
            <div class="text-danger"
              *ngIf="controls['comment'].invalid && (controls['comment'].dirty || controls['comment'].touched)">
              Comment is mandatory
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center mt-2">
            <button class="btn text-dark btn-outline-success" (click)="submit()">Submit</button>
          </div>
        </div>
      </div>

      <div *ngIf="!local_data.isAgreed && (local_data.commentOnTermsAndCondition != null || '') " class="form-group">
        <div class="row mt-2 justify-content-center">
          <h4 class="form-label m-1">Terms & Conditions Rejected</h4>
          <label class="form-label" for=""> Reason : {{ local_data.commentOnTermsAndCondition }}</label>
        </div>
      </div>

      <div *ngIf="local_data.isAgreed" class="form-group">
        <div class="row mt-2 justify-content-center">
          <h4 class="form-label m-1">Terms & Conditions Accepted</h4>
        </div>
      </div>
    </div>
  </div>
</div>