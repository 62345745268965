<section class="wrapper multi-step-form">
    <div class="container-fluid">
        <div class="row">
            <div
                class="col-md-4 col-xl-3 col-left pt-12 d-flex flex-column justify-content-center position-sticky top-0">
                <div class="box-absolute d-flex">
                    <app-sidebar></app-sidebar>
                </div>
                <div class="box mx-auto">
                    <h3 class="text-center text-md-start">User Profile</h3>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <img *ngIf="!isImgDefault" style="height: 250px; width: 250px;" class="img center" src="assets/images/user-dp.png" alt="User Profile" title="View Profile"/>
                        <img *ngIf="isImgDefault" style="height: 250px; width: 250px;" class="img center"  [src]="userImage" alt="User Profile" title="View Profile">
                    </div>
                    <div class="col-md-6">
                        <button class="center" type="button" id="get_file" value="Grab file"
                            onclick="document.getElementById('my_file').click()">
                            <i class="fa fa-edit" title="Update Image"></i>
                        </button>
                        <input type="file" accept="image/*" id="my_file" (change)="uploadImage($event)">
                    </div>
                    <div class="col-md-6">
                        <button class="center" *ngIf="isImgDefault">
                            <i class="fa fa-trash" title="Remove Image" (click)="deleteImage()"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-8 col-xl-9 col-right px-start-end pb-6">
                <div class="topline pt-4 pt-lg-5 pb-3 pb-lg-4 d-flex align-items-center justify-content-between">
                    <ul class="breadcrumb p-0 m-0">
                        <li class="breadcrumb-item 3 brand" id="0">
                            <button [routerLink]="['/v1/dashboard']">Dashboard</button>
                        </li>
                    </ul>
                    <ul class="breadcrumb p-0 m-0">
                        <li class="breadcrumb-item 3 brand">
                            <button class="btn btn-primary" (click)="routeToPasswordChange()">Change Password</button>
                        </li>
                    </ul>
                </div>
                <form [formGroup]="userForm" autocomplete="off" autocorrect="off" autocapitalize="none"
                    spellcheck="false">
                    <div class="row justify-content-center">
                        <div class="col-md-7 form-group">
                            <label class="form-label" for="email-id">Email Id *</label>
                            <input class="form-control" type="email" formControlName="emailId"
                                placeholder="someone@gmail.com" id="email-id"/>
                        </div>
                        <div class="col-md-7 form-group">
                            <label class="form-label" for="fname">Username</label>
                            <input class="form-control" type="text" formControlName="username"
                                placeholder="Enter Username" id="fname" />
                        </div>
                        <div class="col-md-7 form-group">
                            <label class="form-label" for="fname">First Name</label>
                            <input class="form-control" type="text" formControlName="fname"
                                placeholder="Enter First Name" id="fname" />
                        </div>

                        <div class="col-md-7 form-group">
                            <label class="form-label" for="lname">Last Name</label>
                            <input class="form-control" type="text" formControlName="lname"
                                placeholder="Enter First Name" id="lname" />
                        </div>

                        <div class="col-md-7 form-group">
                            <label class="form-label" for="contactNumber">Contact Number</label>
                            <input class="form-control" type="text" formControlName="contactNumber"
                                placeholder="Enter Contact number" id="contactNumber" />
                        </div>

                        <div class="col-md-6 form-group pt-4 pt-lg-5">
                            <button class="btn" [disabled]="!userForm.valid" class="btn btn-success w-100" type="submit"
                                (click)="updateUser()">UPDATE</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>