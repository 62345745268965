<h1 mat-dialog-title><strong>{{action}}</strong></h1>
<div mat-dialog-content style="height: 300px; width: 750px;">
  <form *ngIf="action != 'Delete'; else elseBlock" [formGroup]="form">
    <mat-form-field>
      <input matInput placeholder="Template Key" required formControlName="templateKey"
        readonly="true">
    </mat-form-field>
    <div>
      <label>Template Value</label>
      <quill-editor class="form-control" formControlName="templateValue">
      </quill-editor>
    </div>
  </form>

  <ng-template #elseBlock>
    Sure to delete <b>{{local_data.templateCode}}</b>?
  </ng-template>
</div>
<div mat-dialog-actions>
  <button style="margin-right:20px;" class="btn-primary" mat-button (click)="doAction()">{{action}}</button>
  <button class="btn-danger" mat-button (click)="closeDialog()" mat-flat-button color="warn">Cancel</button>
</div>