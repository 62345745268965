import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-csr-page',
  templateUrl: './csr-page.component.html',
  styleUrls: ['./csr-page.component.css']
})
export class CsrPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.gotoTop();
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

}
