import { DatePipe, DecimalPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import Swal from 'sweetalert2';
import { CurrentPreviousQuoteComparisonDialogComponent } from '../current-previous-quote-comparison-dialog/current-previous-quote-comparison-dialog.component';
import { MasterTableService } from '../helpers/master-table.service';
import { QuoteService } from '../helpers/quote.service';
import { ReportService } from '../helpers/report.service';
import { SocketService } from '../helpers/socket.service';
import { AlertService } from '../utils/sweet-alert.service';
import { DetailTermsAttachmentBoxComponent } from './detail-terms-attachment-box/detail-terms-attachment-box.component';

@Component({
  selector: 'app-detail-quote-comparison',
  templateUrl: './detail-quote-comparison.component.html',
  styleUrls: ['./detail-quote-comparison.component.css']
})
export class DetailQuoteComparisonComponent implements OnInit {

  enquiry_id: any;
  public quoteDetailInfo: Array<any> = [];
  public historyquoteDetailInfo: Array<any> = [];
  portOfLanding: any;
  projectName: any;
  portOfDestination: any;
  projectCode: any;
  deliveryTerms: any;
  deliveryTermsArray: Array<any> = [];
  enquiryDescription: any;
  createDate: any;
  vendorNames: Array<any> = [];
  styleList: Array<any> = ['btn-success', 'btn-primary', 'btn-secondary', 'btn-success', 'btn-primary', 'btn-secondary', 'btn-success', 'btn-primary', 'btn-secondary', 'btn-success', 'btn-primary', 'btn-secondary',
    'btn-success', 'btn-primary', 'btn-secondary', 'btn-success', 'btn-primary', 'btn-secondary', 'btn-success', 'btn-primary', 'btn-secondary', 'btn-success', 'btn-primary', 'btn-secondary', 'btn-success',
    'btn-primary', 'btn-secondary', 'btn-success', 'btn-primary', 'btn-secondary'];
  typeOfContainer: Array<any> = [];
  ports: Array<any> = [];
  tradeTypeArray: Array<any> = [];
  tradeType: string = '';
  enquiryNumber: any;
  modeOfShipmentArray = [];
  modeOfShipment: string = ''
  quantityList: Array<any> = [];
  tableHeaders: Array<any> = [];
  air_mode_shipment: Boolean = false;
  sea_mode_shipment: Boolean = false;
  break_bulk: Boolean = false;
  shipmentPackages: Array<any> = [];
  quantity: any;
  currency: string;

  shipmentParentArray: Array<any> = [];
  containerParentArray: Array<any> = [];
  breakBulkParentArray: Array<any> = [];
  additionalCharges: Array<any> = [];
  grandTotals: Array<any> = [];

  checknoRegretOffer: Array<any> = [];

  checkedList: Array<any> = [];
  data: Array<any> = [];

  // Latest Changes
  vendorQuoteRows = [];

  containerObj = {
    id: '',
    name: '',
    value: '',
    data: []
  }

  additionalChargesObj = {
    name: '',
    data: []
  }

  containerFinalArray = [];

  additionalChargesArray = [];

  additionalChargeItemArray = {};

  grandTotalAmount = [];

  additionalItemArr = {};


  shipmentPackageItemArr = {};

  breakBulkItemArr = {};

  chargeableWeight;
  totalQuotes: any[] = [];
  pickupTermsArray: Array<any> = [];
  pickupTerms: string = '';
  enquiryObject: any;

  exWorksBool: Boolean = false;
  deliveryChargeBool: Boolean = false;
  colSpan: number = 2;
  detention: any;
  demurrage: any;

  constructor(private route: ActivatedRoute, private quoteService: QuoteService, private router: Router,
    private masterTableService: MasterTableService, private reportservice: ReportService, public dialog: MatDialog,
    private sweetAlertService: AlertService, private socketService: SocketService, private datePipe: DatePipe,
    private numberPipe: DecimalPipe
  ) { }


  ngOnInit(): void {
    this.getMasterTableData();
    this.enquiry_id = this.route.snapshot.paramMap.get('id');
    this.fetchHistoryQuotes();
    // this.socketService.connectTOServerPopUpFuction();
  }

  openDialog(data, type) {
    const dialogRef = this.dialog.open(DetailTermsAttachmentBoxComponent, {
      width: '800px;',
      data: { data, type }
    })
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
    })
  }

  fetchHistoryQuotes() {
    let historyarray = [this.quoteService.fetchQuotesByEnquiry(this.enquiry_id), this.quoteService.letestQuoteHistory(this.enquiry_id)]
    forkJoin(historyarray).subscribe(data => {
      console.log("history data", data);
      for (let index = 0; index < data[0].length; index++) {
        const element = data[0][index];
        this.quoteDetailInfo.push(element);
        this.createDate = this.datePipe.transform(element.enquiry.enquiryDate, 'yyyy-MM-dd' );
        this.enquiryObject = element.enquiry;
        this.portOfLanding = this.enquiryObject.originPortName;
        this.portOfDestination = this.enquiryObject.destinationPortName;
        this.tradeTypeArray.filter(x => {
          if (x.id == element.enquiry.tradeType) {
            this.tradeType = x.value;
          }
        });
        this.modeOfShipmentArray.filter(x => {
          if (x.id == element.enquiry.modeOfShipment) {
            this.modeOfShipment = x.value;
          }
        })
        this.pickupTermsArray.filter(x => {
          if (x.code == element.enquiry.pickUpIncoterms) {
            this.pickupTerms = x.value;
          }
        })
        this.deliveryTermsArray.filter(x => {
          if (x.code == element.enquiry.deliveryIncoterms) {
            this.deliveryTerms = x.value;
          }
        })

        this.projectName = this.enquiryObject.projectName;
        this.projectCode = this.enquiryObject.projectCode;
        this.enquiryDescription = this.enquiryObject.description;
        this.detention = this.enquiryObject.detention;
        this.demurrage = this.enquiryObject.demurrage;
        this.currency = this.enquiryObject.currency.currency;
        this.enquiryNumber = this.enquiryObject.number;
      }

      for (let index = 0; index < data[1].length; index++) {
        const element = data[1][index];
        this.historyquoteDetailInfo = [...this.historyquoteDetailInfo, ...element.vendorQuoteRowHistory];
      }

      this.generateVendorQuotes(this.quoteDetailInfo);
    })
  }

  compare(event, id) {
    let value = event.srcElement.checked;
    let _id = id;
    console.log(id, value);
    if (value == true) {
      this.checkedList.push(_id);
    }
    else if (value == false) {
      this.checkedList.splice(this.checkedList.indexOf(_id), 1);
    }
    else {
      console.log("Cant do this");
    }
  }

  createAuction() {
    this.router.navigate(['/v1/client-auction/creation/', this.enquiry_id, 'r']);
  }

  createServiceOrder() {
    if (this.checkedList.length > 1 || this.checkedList.length == 0) {
      Swal.fire({
        titleText: "Warning",
        icon: "warning",
        text: "Check only one checkbox",
        timer: 5000,
        showCancelButton: true,
        showCloseButton: true,
      })
    }
    else {
      this.router.navigate(['/v1/client-service-order/creation/', "q", this.checkedList[0]]);
    }
  }

  getMasterTableData() {
    this.containerTypeList();
    this.getModeOfShipment();
    this.getTradeType();
    this.getPickUpIncoTerms();
    this.getDeliveryIncoTerms();
  }

  containerTypeList(){
    this.masterTableService.getContainerTypes().subscribe(
      (response) => {
        if(response){
          this.typeOfContainer = response
        }
      },
      (error) => {error},
    );
  }

  getModeOfShipment(){
    this.masterTableService.getModeOfShipment().subscribe(
      (response) => {
        this.modeOfShipmentArray = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getTradeType(){
    this.masterTableService.getTradeType().subscribe(
      (response) => {
        this.tradeTypeArray = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getPickUpIncoTerms(){
    this.masterTableService.getAllPickUpIncoTerms().subscribe(
      (response) => {
        this.pickupTermsArray = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getDeliveryIncoTerms(){
    this.masterTableService.getDeliveryIncoTerms().subscribe(
      (response) => {
        this.deliveryTermsArray = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  downloadPDF() {
    this.quoteService.getQuoteComparisonPDF(this.enquiry_id).subscribe(
      (response) => {
        if (response) {
          Swal.fire({
            title: "Downloading...",
            text: "Wait",
            timer: 3000,
            icon: "info"
          });
          var file = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          var fileURL = URL.createObjectURL(file);
          // window.open(fileURL);   // if u want to open in new tab
          var a = document.createElement('a');
          a.href = fileURL;
          a.target = '_blank';
          a.download = `DetailQuoteComparison-${this.enquiryNumber}.xlsx`;
          document.body.appendChild(a);
          a.click();
        }
      },
      (error) => {
        console.log(error);
        Swal.fire({
          title: "Error",
          text: "Something Went Wrong..",
          timer: 3000,
          icon: "error"
        })
      }
    );
  }

  callForNoRegretOffer() {
    this.quoteService.callForNoRegretOffer(this.enquiry_id).subscribe(
      (response) => {
        if (response.code == 200) {
          Swal.fire({
            titleText: "Success",
            icon: "success",
            text: response.message,
            timer: 5000
          })
        }
      },
      (error) => {
        console.log(error);
        Swal.fire({
          titleText: "Error",
          icon: "error",
          text: "Something Went Wrong",
          timer: 5000
        })
      }
    )
  }

  /**
   * Generate Vendor Quotes Object
   * @param quote Quotes array recieved in response
   */
  generateVendorQuotes(quotes: Array<any>) {
    this.data = quotes;
    // Get enquiry object and check for MODE OF SHIPMENT AND calculate accordingly.
    this.data.forEach((quote) => {
      this.chargeableWeight = quote?.enquiry?.chargableWeight;
      let mos = quote?.enquiry?.modeOfShipment;
      if(mos == 1){
        this.generateAirObject(quote);
        this.air_mode_shipment = true;
      }
      else if(mos == 2){
        this.sea_mode_shipment = true;
        this.generateFCLObject(quote);
      }
      else if(mos == 3){
        console.log("RAIL");
      }
      else if(mos == 4){
        console.log("ROAD");
      }
    });

    this.data.forEach((quote) => {
      if(this.sea_mode_shipment == true){
        this.generationOfQuotesSea(quote);
      }
      else if(this.air_mode_shipment == true){
        this.generationOfQuotesAir(quote);
      }
    });

    this.totalQuotes.sort((a, b) => (a.amount > b.amount) ? 1 : -1);
    for (let i = 0; i < this.totalQuotes.length; i++) {
      this.totalQuotes[i]['rank'] = "L" + (i + 1);
    }
  }

  /**
   * Generate FCL Payload for Sea
   * @param quote 
   */
  generateFCLObject(quote) {
    this.checknoRegretOffer.push(quote.noRegretOffer);
    // TODO: Need to check condition for air or sea mode shipment
    let vendorQuoteRows = quote.vendorQuoteRows;

    this.totalQuotes = [...this.totalQuotes, ...vendorQuoteRows];

    // Container Object Calculation, ToDo test more scenario.
    vendorQuoteRows.forEach((vendorQuote) => {
      // Finding History Quote Previous Value
      const historyData = this.historyquoteDetailInfo.find((historyQuote) => historyQuote.lineName === vendorQuote.lineName);
      if (historyData) {
        vendorQuote['previousTotal'] = historyData.amount;
      }
      // Additional Charges Prepare Array
      this.prepareAdditionalChargeItemArray(vendorQuote);

      // Shipment Package Containers Prepare Array
      this.prepareShipmentPackageContainerData(vendorQuote);

      // Break Bulk Containers
      this.prepareBreakBulkPackageContainerData(vendorQuote);

      let vendorQuoteContainers = vendorQuote.vendorQuoteContainers;
      // Vendor Quote Containers
      vendorQuoteContainers.forEach((vendorQuoteContainer) => {

        this.containerObj = {
          id: vendorQuoteContainer.container.type,
          name: this.getContainerType(vendorQuoteContainer.container),
          value: vendorQuoteContainer.container.quantity,
          data: []
        }

        let containerElement = this.containerFinalArray.find((container) => container.id === this.containerObj.id);
        this.populateExWorks(); //populate colspan and show, hide exWorks & destination Charges.
        if (containerElement) {
          containerElement.data.push({ amount: vendorQuoteContainer.ratePerContainer,exWorks: vendorQuoteContainer.exWorks,
            destinationCharges:  vendorQuoteContainer.destinationCharges ,total: vendorQuoteContainer.totalFreight });
        } else {
          this.containerObj.data.push({ amount: vendorQuoteContainer.ratePerContainer,exWorks: vendorQuoteContainer.exWorks,
            destinationCharges:  vendorQuoteContainer.destinationCharges, total: vendorQuoteContainer.totalFreight });
          this.containerFinalArray.push(this.containerObj);
        }
      });

    });
  }

  generateAirObject(quote){
    let vendorQuoteRows = quote.vendorQuoteRows;
    this.totalQuotes = [...this.totalQuotes, ...vendorQuoteRows];
    
    vendorQuoteRows.forEach((vendorQuote) => {
      // Finding History Quote Previous Value
      const historyData = this.historyquoteDetailInfo.find((historyQuote) => historyQuote.lineName === vendorQuote.lineName);
      if (historyData) {
        vendorQuote['previousTotal'] = historyData.amount;
      }
      // Additional Charges Prepare Array
      this.prepareAdditionalChargeItemArray(vendorQuote);
      // Shipment Package Containers Prepare Array
      this.prepareShipmentPackageContainerData(vendorQuote);
    });

  }

  generationOfQuotesSea(quote){
    let vendorQuoteRows = quote.vendorQuoteRows;
    vendorQuoteRows.forEach((vendorQuote) => {
      // Generate shipment Package
      this.generateShipmentPackageChargesObject(vendorQuote);

      // Generate Break Bulk Data
      this.generateBreakBulkChargesObject(vendorQuote);

      // Generate shipment package additional charge object
      this.generateAdditionChargesObject(vendorQuote);
    });
  }

  generationOfQuotesAir(quote){
    let vendorQuoteRows = quote.vendorQuoteRows;
    vendorQuoteRows.forEach((vendorQuote) => {
      // Generate shipment Package
      this.generateShipmentPackageChargesObject(vendorQuote);
      // Generate shipment package additional charge object
      this.generateAdditionChargesObject(vendorQuote);
    });
  }

  /**
   * Prepare Shipment Package Item Array
   * @param vendorQuote 
   */
  prepareShipmentPackageContainerData(vendorQuote) {
    let shipmentPackageLocalArray = vendorQuote.vendorQuoteShipmentPackages;
    shipmentPackageLocalArray.forEach((shipmentPackage) => {
      let name = shipmentPackage?.description || shipmentPackage?.chargeType?.name;
      if (!this.shipmentPackageItemArr[name]) {
        this.shipmentPackageItemArr[name] = [];
      }
    });
  }

  /**
   * Prepare Break Bulk Item Array
   * @param vendorQuote 
   */
  prepareBreakBulkPackageContainerData(vendorQuote) {
    let breakBulkLocalArray = vendorQuote.vendorQuoteBreakBulks;
    breakBulkLocalArray.forEach((breakBulk) => {
      let name = breakBulk?.description || breakBulk?.chargeType?.name;
      if (!this.breakBulkItemArr[name]) {
        this.breakBulkItemArr[name] = [];
      }
    });
  }

  /**
   * Prepare Additional Charge Item Array
   * @param vendorQuote Vendor Quote Object
   */
  prepareAdditionalChargeItemArray(vendorQuote) {
    let additionalChargesLocalArray = vendorQuote.additionalCharges;
    additionalChargesLocalArray.forEach((additionalCharges) => {
      let name = additionalCharges?.description || additionalCharges?.chargeType?.name;
      if (!this.additionalItemArr[name]) {
        this.additionalItemArr[name] = [];
      }
    });
  }

  /**
   * Generate Break Bulk Charges Array Object
   * @param vendorQuote Vendor Quote Object
   * @param sum total counter variable
   */
  generateBreakBulkChargesObject(vendorQuote) {
    let breakBulkLocalArray = vendorQuote.vendorQuoteBreakBulks;
    for (let key in this.breakBulkItemArr) {
      let data = breakBulkLocalArray.find((element) => {
        let name = element.description;
        return name === key;
      });
      if (data) {
        let charge = data.type === "PER_UNIT" ? data.charge * this.chargeableWeight : data.charge;
        this.breakBulkItemArr[key].push(this.numberPipe.transform(charge, '1.2'));
      } 
      else {
        this.breakBulkItemArr[key].push('0.0');
      }
    }

  }

  /**
   * Generate Shipment Package Charges Array Object
   * @param vendorQuote Vendor Quote Object
   * @param sum total counter variable
   */
  generateShipmentPackageChargesObject(vendorQuote) {
    let shipmentPackageLocalArray: Array<any> = vendorQuote.vendorQuoteShipmentPackages;
    for (let key in this.shipmentPackageItemArr) {
      let data  = shipmentPackageLocalArray.find((element) => {
        let name = element.description;
        if(name === key){
          return element
        }
      });
      if(data){
        let charge = data.type === "PER_UNIT" ? data.charge * this.chargeableWeight : data.charge;
        this.shipmentPackageItemArr[key].push(this.numberPipe.transform(charge, '1.2'));
      }
      else{
        this.shipmentPackageItemArr[key].push('0.0');
      }
    }
  }


  /**
   * Generate Additional Charges Array Object
   * @param vendorQuote Vendor Quote Object
   * @param sum total counter variable
   */
  generateAdditionChargesObject(vendorQuote) {
    let additionalChargesLocalArray = vendorQuote.additionalCharges;
    for (let key in this.additionalItemArr) {
      let data = additionalChargesLocalArray.find((element) => {
        let name = element.description;
        return name === key;
      });
      if (data) {
        let charge = data.type === "PER_UNIT" ? data.charge * this.chargeableWeight : data.charge;        
        this.additionalItemArr[key].push(this.numberPipe.transform(charge, '1.2'));
      } 
      else {
        this.additionalItemArr[key].push('0.0');
      }
    }
  }

  /**
   * Get Container Type Name
   * @param enqContainer Enquiry Container Object
   * @returns 
   */
  getContainerType(enqContainer): string {
    const typeOfContainer = this.typeOfContainer.find(
      (container) => {
        return container.id === enqContainer.type;
      }
    );
    return typeOfContainer.value;
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  /**
   * Compare Current and Previous Quote
   * @param quote Quotation Object
   */
  compareQuotes(quote) {
    let historyQuote = this.historyquoteDetailInfo.find((historyQuote) => historyQuote.lineName === quote.lineName);
    if (!historyQuote) {
      this.sweetAlertService.errorAlert('No history quote found for comparison');
      return;
    }
    console.log("Quote,qu", quote);

    const dialogRef = this.dialog.open(CurrentPreviousQuoteComparisonDialogComponent, {
      data: {
        currentQuote: quote,
        historyQuote: historyQuote
      },
      height: '50%',
      width: '70%'
    });
  }

  populateExWorks(){
    let natureOfMovement = this.enquiryObject.natureOfMovement
    if(natureOfMovement == "P2P"){
      this.colSpan = 2;
      this.exWorksBool = false;
      this.deliveryChargeBool = false;
    }
    else if(natureOfMovement == "D2P"){
      this.colSpan = 3;
      this.exWorksBool = true;
      this.deliveryChargeBool = false;
    }
    else if(natureOfMovement == "D2D"){
      this.colSpan = 4;
      this.exWorksBool = true;
      this.deliveryChargeBool = true;
    }
    else if(natureOfMovement == "P2D"){
      this.colSpan = 3;
      this.exWorksBool = false;
      this.deliveryChargeBool = true;
    }
  }

}