<!-- <h1 mat-dialog-title><strong>{{action}}</strong></h1> -->
<div mat-dialog-content class="m-3">
    <div class="mat-elevation-z10">
        <table class="table table-borderless table-striped table-footer-dark table-head-dark" mat-table
            [dataSource]="dataSource">

            <ng-container matColumnDef="transport">
                <th class="text-white" mat-header-cell *matHeaderCellDef>&nbsp;&nbsp; Transport via &nbsp;&nbsp;</th>
                <td mat-cell *matCellDef="let row">
                    &nbsp;&nbsp; {{ row.transport }} &nbsp;&nbsp;
                </td>
            </ng-container>
            &nbsp;&nbsp; &nbsp;&nbsp;
            <ng-container matColumnDef="cutOff">
                <th class="text-white" mat-header-cell *matHeaderCellDef>&nbsp; CY Cutoff &nbsp;
                </th>
                <td mat-cell *matCellDef="let row"> &nbsp;&nbsp; {{ row.cutOff | date:'d MMM (EEE) hh:mm a' }}
                    &nbsp;&nbsp;
                </td>
            </ng-container>
            &nbsp;&nbsp; &nbsp;&nbsp;
            <ng-container matColumnDef="departure">
                <th class="text-white" mat-header-cell *matHeaderCellDef>&nbsp;&nbsp; Departure &nbsp;&nbsp;
                </th>
                <td mat-cell *matCellDef="let row"> &nbsp;&nbsp; {{ row.departure | date:'d MMMM, (EEE)' }} &nbsp;&nbsp;
                </td>
            </ng-container>
            &nbsp;&nbsp; &nbsp;&nbsp;
            <ng-container matColumnDef="arrival">
                <th class="text-white" mat-header-cell *matHeaderCellDef>&nbsp;&nbsp; Arrival &nbsp;&nbsp;</th>
                <td mat-cell *matCellDef="let row"> &nbsp;&nbsp; {{ row.arrival | date:'d MMMM, (EEE)' }} &nbsp;&nbsp;
                </td>
            </ng-container>
            &nbsp;&nbsp; &nbsp;&nbsp;
            <ng-container matColumnDef="serviceVessel">
                <th class="text-white" mat-header-cell *matHeaderCellDef>&nbsp;&nbsp; Vessel Voyage Details &nbsp;&nbsp;
                </th>
                <td mat-cell *matCellDef="let row">
                    &nbsp;&nbsp; {{ row.vesselDetails}} &nbsp;&nbsp;
                </td>
            </ng-container>

            <tr class="text-left bg-dark" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            &nbsp;&nbsp; &nbsp;&nbsp;
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>

<div mat-dialog-actions class="justify-content-center">
    <!-- <button mat-button (click)="doAction()">{{action}}</button> -->
    <button mat-button (click)="closeDialog()" class="justify-content-center btn-outline-primary"
        mat-flat-button>Close</button>
</div>