<header class="box-absolute d-flex align-items-center topline text-white">
  <app-sidebar></app-sidebar>
  <div class="page-title-center mt-3">
    <div class="text-center">
      <h1>{{ title }}</h1>
    </div>
  </div>
  <div class="breadcrumb-div mt-3">
    <ul class="breadcrumb p-0 m-0">
      <li class="breadcrumb-item 3" id="0"><a [routerLink]="['/v1/dashboard']">Dashboard</a></li>
      <li class="breadcrumb-item 3" id="1"><a>Listing</a></li>
      <li class="breadcrumb-item"><a>{{ title }}</a></li>
    </ul>
  </div>
</header>
<!--header end-->
<section class="banner text-center text-white d-flex" id="bnner">
  <div class="container position-relative">
    <div class="row justify-content-center">
      <div class="col-lg-12">
      </div>
    </div>
  </div>
</section>
<!--request start-->
<section class="request-demo-wrapper pb-10" (click)="hideSideBar()">
  <div class="container shadow bg-white">
    <div class="row">
      <div class="col-lg-12">
        <div class="box">
          <form>
            <div class="filter-wrap btn-group d-flex flex-wrap flex-md-nowrap justify-content-center gap-2 pb-3 pb-lg-4 px-3 px-lg-4">
              <div class="input-group">
                <input class="form-control rounded-3 px-5" type="text" matInput
                    (keyup)="applyFilter($event.srcElement.value)" placeholder="Search" #input>
                  <button class="input-group-btn px-3 end-0" type="button"><i class="fa fa-search"></i>
                </button>
              </div>

              <button class="rounded-3 flex-shrink-0" type="button" (click)="createAuction()"><i
                  class="fa fa-plus me-2"></i><span>CREATE
                  AUCTION</span>
              </button>
              <button class="rounded-3 flex-shrink-0" type="button"  (click)="getAllUpcomingAuctions()">
                <i class="fa fa-filter me-2"></i>
                <span>UPCOMING AUCTIONS</span>
              </button>
              <button class="rounded-3 flex-shrink-0" type="button" (click)="getAllRecentAuctions()">
                <i class="fa fa-filter me-2"></i>
                <span>RECENT AUCTIONS</span>
              </button>
              <button class="rounded-3 flex-shrink-0" type="button" (click)="getAllAuctions()">
                <i class="fa fa-refresh me-2"></i>
                <span>Reset</span>
              </button>


            </div>
            <div *ngIf="!orgName" class="table-responsive">
              <table mat-table [dataSource]="dataSource" matSort class="table table-borderless table-striped table-footer-dark table-head-dark">

                <ng-container matColumnDef="checkbox">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-white ">Action</th>
                  <td mat-cell *matCellDef="let element; index as i">
                    <input type="checkbox" (change)="compare($event, element.id, i) ">
                  </td>
                </ng-container>

                <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-white ">Auction Number</th>
                  <td mat-cell *matCellDef="let element">{{element.number || "----"}}</td>
                </ng-container>

                <ng-container matColumnDef="description">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-white ">Description</th>
                  <td mat-cell *matCellDef="let element">{{element.description}}</td>
                </ng-container>

                <ng-container matColumnDef="date">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-white ">Auction Date</th>
                  <td mat-cell *matCellDef="let element">{{ element.auctionDate | date:'dd-MM-YYYY HH:mm:ss' }}</td>
                </ng-container>

                <ng-container matColumnDef="status">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-white ">Status</th>
                  <td mat-cell *matCellDef="let element">{{ element.status }}</td>
                </ng-container>

                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef class="text-white "> Actions </th>
                  <td mat-cell *matCellDef="let element; index as i">

                    <div class="row">
                      <div *ngIf="element.status ==  'DRAFT'" class="col-lg-3">
                        <button (click)="editAuctionRoute(element.id)" class="fa fa-edit text-primary"
                        type="button"></button>
                      </div>
                      <div *ngIf="element.status == 'IN_PROGRESS' " class="col-lg-3">
                        <button class="fa fa-play-circle text-primary" [routerLink]="['/v1/client-live-auction', element.id]" type="button">
                        </button>
                      </div>
                      <div *ngIf="element.status ==  'COMPLETE'" class="col-lg-3">
                        <button class="fa fa-info-circle text-primary"
                          [routerLink]="['/v1/client-auction/comparison', element.id]" type="button"
                          title="Sumarry">
                        </button>
                      </div>

                      <div *ngIf="element.status ==  'COMPLETE'" class="col-lg-3">
                        <button class="fa fa-eye text-primary" *ngIf="element.status ==  'COMPLETE' && downloadBidReport" (click)="downloadBidSummary(element.id, 'view')">
                        </button>
                      </div>
                      
                      <div *ngIf="element.status ==  'COMPLETE'" class="col-lg-3">
                        <button class="fa fa-download text-primary && downloadBidReport" (click)="downloadBidSummary(element.id, 'download')">
                        </button>
                      </div>
                    </div>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns" class="text-left bg-dark"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class=""></tr>
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell text-center text-danger" colspan="12">
                      <div *ngIf="serchFilter">
                          No data matching the filter "{{ input.value }}"
                      </div>
                      <div>
                          <h5>Data is Not Available</h5>
                      </div>
                  </td>
                </tr>
              </table>
              <mat-paginator [pageSizeOptions]="[25, 50, 100]" [length]="auctionLength" showFirstLastButtons
                (page)="onPageChange($event)">
              </mat-paginator>
            </div>
            <div *ngIf="orgName" class="table-responsive">
              <table mat-table [dataSource]="dataSource" matSort class="table table-borderless table-striped table-footer-dark table-head-dark">

                <ng-container matColumnDef="checkbox">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-white ">Action</th>
                  <td mat-cell *matCellDef="let element; index as i">
                    <input type="checkbox" (change)="compare($event, element.id, i) ">
                  </td>
                </ng-container>

                <ng-container matColumnDef="orgName">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-white ">Company Name</th>
                  <td mat-cell *matCellDef="let element">{{element.org.name}}</td>
                </ng-container>

                <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-white ">Auction Number</th>
                  <td mat-cell *matCellDef="let element">{{element.number || "----"}}</td>
                </ng-container>
                           
                <ng-container matColumnDef="description">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-white ">Description</th>
                  <td mat-cell *matCellDef="let element">{{element.description}}</td>
                </ng-container>

                <ng-container matColumnDef="date">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-white ">Auction Date</th>
                  <td mat-cell *matCellDef="let element">{{ element.auctionDate | date:'dd/MM/yyyy HH:mm:ss' }}</td>
                </ng-container>

                <ng-container matColumnDef="status">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-white ">Status</th>
                  <td mat-cell *matCellDef="let element">{{ element.status }}</td>
                </ng-container>

                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef class="text-white "> Actions </th>
                  <td mat-cell *matCellDef="let element; index as i">
                    <div class="row">
                      <div *ngIf="element.status ==  'DRAFT'" class="col-lg-3">
                        <button (click)="editAuctionRoute(element.id)" class="fa fa-edit text-primary"
                        type="button"></button>
                      </div>
                      <div *ngIf="element.status == 'IN_PROGRESS' " class="col-lg-3">
                        <button class="fa fa-play-circle text-primary" [routerLink]="['/v1/client-live-auction', element.id]" type="button">
                        </button>
                      </div>
                      <div *ngIf="element.status ==  'COMPLETE'" class="col-lg-3">
                        <button class="fa fa-info-circle text-primary"
                          [routerLink]="['/v1/client-auction/comparison', element.id]" type="button"
                          title="Sumarry">
                        </button>
                      </div>
                    </div>

                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns1" class="text-left bg-dark"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns1;" class=""></tr>
                 <!-- Row shown when there is no matching data. -->
                 <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell text-center text-danger" colspan="10">
                      <div *ngIf="serchFilter">
                          No data matching the filter "{{ input.value }}"
                      </div>
                      <div>
                          <h5>Data is Not Available</h5>
                      </div>
                  </td>
              </tr>
              </table>
              <mat-paginator [pageSizeOptions]="[5, 10, 25]" [length]="auctionLength" showFirstLastButtons
                (page)="onPageChange($event)">
              </mat-paginator>
            </div>
            <div class="row justify-content-center">
              <div class="col-md-6 col-lg-3 form-group pt-lg-3" *ngIf="isApproveBtnVisible">
                <button class="btn text-dark small btn-outline-success px-2" (click)="rejectAuctionDialog()"
                  type="button">REJECT</button>
              </div>
              <div class="col-md-6 col-lg-3 form-group pt-lg-3">
                <button class="btn text-dark small btn-outline-success px-2" (click)="cancelAuctionDialog()"
                  type="button">CANCEL</button>
              </div>
              <div class="col-md-6 col-lg-3 form-group pt-lg-3" *ngIf="isApproveBtnVisible">
                <button class="btn text-dark small btn-outline-success px-2" (click)="approveAuction()"
                  type="button">APPROVE</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>