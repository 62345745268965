<div mat-dialog-content [formGroup]="dialogForm">
    <div *ngIf="termsBool" class="row mb-4 justify-content-center align-items-center" formArrayName="termsArr">
        <h4 matDialogTitle>Terms & Condition</h4>
        <div class="col-lg-11" *ngFor="let item of termsCondition.controls;let i=index">
            <div [formGroupName]="i">
                <input type="text" class="form-control terms-label" readonly formControlName="label">
                <textarea class="form-control" readonly formControlName="content" rows="6"></textarea>
            </div>
            <br>
        </div>
    </div>
</div>
<div mat-dialog-content>
    <div *ngIf="artifactsBool">
        <h4 matDialogTitle>Attachments</h4>
        <table class="table table-borderless table-head-dark table-responsive">
            <thead>
                <tr>
                    <th>Sr.No</th>
                    <th>File Name</th>
                    <th>Label</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of local_data; index as i">
                    <td>{{ i + 1 }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{item.label}}</td>
                    <td>
                        <button (click)="downloadFile(item)">
                            <i class="fa fa-download" aria-hidden="true" title="Download"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div mat-dialog-actions class="justify-content-center">
    <button mat-button class="btn-success" (click)="closeDialog()" mat-flat-button color="warn">Close</button>
</div>