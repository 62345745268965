import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MasterTableService } from 'src/app/helpers/master-table.service';
import { ContainerDialogBoxComponent } from './container-dialog-box/container-dialog-box.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-container-type',
  templateUrl: './container-type.component.html',
  styleUrls: ['./container-type.component.css']
})
export class ContainerTypeComponent implements OnInit {

  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['id', 'value', 'action'];
  serchFilter: Boolean = false;
  nodata: Boolean = true;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  page = 0;
  size = 25;
  containerTypeLength: number;

  sessionStorageUserData: any
  disableCreateBtn: boolean = true
  disableEditBtn: boolean = true
  disableDeleteBtn: boolean = true

  constructor(private masterService: MasterTableService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getContainerTypes();
    this.getCurrentUserDataFromSessionStorage();
  }

  openDialog(action, obj) {
    obj.action = action;
    const dialogRef = this.dialog.open(ContainerDialogBoxComponent, {
      width: '250px',
      data: obj
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.event == 'Create') {
        this.createContainerType(result.data);
      } else if (result.event == 'Update') {
        this.updateContainerType(result.data.id, result.data.value);
      } else if (result.event == 'Delete') {
        this.deleteContainerType(result.data.id);
      }
    });
  }

  getContainerTypes() {
    this.masterService.getcontainerTypesPageable(this.page, this.size).subscribe(
      (response) => {
        if (response.content != null) {
          this.dataSource = new MatTableDataSource(response.content);
          this.dataSource.sort = this.sort;
          this.containerTypeLength = response.totalElements
        }
      },
      (error) => { 
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      },
    )
  }

  createContainerType(data: any) {
    let _data = {
      "value": data.value
    }
    this.masterService.createContainerType(_data).subscribe(
      (response) => {
        if (response.code == 201) {
          Swal.fire({
            titleText: "Success",
            icon: "success",
            text: response.message,
            timer: 5000
          })
        }
        this.getContainerTypes();
      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      },
    )
  }


  updateContainerType(id: any, data: any) {
    let _data = {
      "id": id,
      "value": data
    }
    this.masterService.updateContainerType(id, _data).subscribe(
      (response) => {
        if (response.code == 201) {
          Swal.fire({
            titleText: "Success",
            icon: "success",
            text: response.message,
            timer: 5000
          })
        }
        this.getContainerTypes();
      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      }
    );
  }

  deleteContainerType(id: any) {
    this.masterService.deleteContainerType(id).subscribe(
      (response) => {
        if (response.code == 201) {
          Swal.fire({
            titleText: "Success",
            icon: "success",
            text: response.message,
            timer: 5000
          })
        }
        this.getContainerTypes();
      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.serchFilter=true;
    this.nodata=false;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  onPageChange(event: PageEvent) {
    this.size = event.pageSize;
    this.page = event.pageIndex;
    this.getContainerTypes();
  }

  getCurrentUserDataFromSessionStorage(){
  // create auth code
  // RB0000074
  // update auth code
  // RB0000075
  // delete auth code
  // RB0000089
    this.sessionStorageUserData = JSON.parse(sessionStorage.getItem('userData'));
    console.log('this is user data ', this.sessionStorageUserData);
    for (let index = 0; index < this.sessionStorageUserData.authorities.length; index++) {
      const authority = this.sessionStorageUserData.authorities[index];
      console.log(authority);
      if(authority == 'RB0000074'){
        console.log('create authority is given');
        this.disableCreateBtn = false
      } else if(authority == 'RB0000075'){
        console.log('update authority is given');
        this.disableEditBtn = false
      } else if(authority == 'RB0000089') {
        console.log('delete authority is given');
        this.disableDeleteBtn = false
      }
    }
  }
}
