import { Component, OnInit } from '@angular/core';
import { SocketService } from '../helpers/socket.service';

@Component({
  selector: 'app-admin-subscription',
  templateUrl: './admin-subscription.component.html',
  styleUrls: ['./admin-subscription.component.css']
})
export class AdminSubscriptionComponent implements OnInit {

  constructor(private socketService: SocketService) { }

  ngOnInit(): void {
    // this.socketService.connectTOServerPopUpFuction()
  }

}
