<section class="wrapper multi-step-form">
    <div class="container-fluid">
        <div class="row">
            <div
                class="col-md-4 col-xl-3 col-left pt-12 d-flex flex-column justify-content-center position-sticky top-0">
                <div class="box-absolute d-flex">
                    <app-sidebar></app-sidebar>
                </div>
                <div class="box mx-auto">
                    <h3 class="text-center text-md-start">{{ title }}</h3>
                    <!-- <ul class="list-unstyled step-one">
                        <li class="active" id="list-item-0">
                            <div class="icon"> <i class="fa fa-check"></i></div><span>Shipment</span>
                        </li>
                        <li class=" " id="list-item-1">
                            <div class="icon"> <i class="fa fa-check"></i></div><span>Shipment Details</span>
                        </li>
                        <li class=" " id="list-item-2">
                            <div class="icon"> <i class="fa fa-check"></i></div><span>Shipment Particulars</span>
                        </li>
                        <li class=" " id="list-item-3">
                            <div class="icon"> <i class="fa fa-check"></i></div><span>Vendor Assignment</span>
                        </li>
                        <li class=" " id="list-item-4">
                            <div class="icon"> <i class="fa fa-check"></i></div><span>Terms &amp; Documents</span>
                        </li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
</section>