<!--header start-->
<header class="box-absolute d-flex align-items-center topline text-white">
    <app-sidebar></app-sidebar>
    <div class="page-title-center mt-3">
        <div class="text-center">
            <h1>Detail Quote</h1>
        </div>
    </div>
    <div class="breadcrumb-div mt-3">
        <ul class="breadcrumb p-0 m-0">
            <li class="breadcrumb-item 3" id="0"><a [routerLink]="['/v1/dashboard']">Dashboard</a></li>
            <li class="breadcrumb-item 3" id="1"><a>Quote</a></li>
            <li class="breadcrumb-item"><a>Detail</a></li>
        </ul>
    </div>
</header>
<!--header end-->
<section class="banner text-center text-white d-flex" id="bnner">
    <div class="container position-relative">
        <div class="row justify-content-center">
            <div class="col-lg-12">
            </div>
        </div>
    </div>
</section>
<!--request start-->
<section class="comparison-wrapper pb-10" (click)="hideSideBar()">
    <div class="container shadow bg-white px-start-end py-6">
        <div class="row">
            <div class="col-lg-12">
                <div class="box">
                    <form>
                        <div class="row bg-lighter mb-4 mb-lg-5">
                            <div class="col-md-6 form-group mt-3 form-group-inline">
                                <label class="form-label" for="enquiry-description-:">Number :</label>
                                <input class="form-control" [ngModel]="vendorQuote?.vendorQuote?.number"
                                    [ngModelOptions]="{standalone: true}" readonly="readonly">
                            </div>
                            <div *ngIf="seaBool" class="col-md-6 form-group mt-3 form-group-inline">
                                <label class="form-label" for="enquiry-description-:">Loading Terminal :</label>
                                <input class="form-control" [ngModel]="vendorQuote?.loadingTerminal || '----' "
                                    [ngModelOptions]="{standalone: true}" readonly="readonly">
                            </div>
                            <div class="col-md-6 form-group form-group-inline">
                                <label class="form-label" for="report-created-date-:"> Quote Date :</label>
                                <input class="form-control" [ngModel]="vendorQuote?.vendorQuote?.quoteDate"
                                    [ngModelOptions]="{standalone: true}" id="report-created-date-:"
                                    readonly="readonly" />
                            </div>
                            <div class="col-md-6 form-group form-group-inline">
                                <label class="form-label" for="report-created-date-:"> Quote Validity :</label>
                                <input class="form-control" [ngModel]="vendorQuote?.quoteValidTill"
                                    [ngModelOptions]="{standalone: true}" id="report-created-date-:"
                                    readonly="readonly" />
                            </div>
                            <div class="col-md-6 form-group form-group-inline">
                                <label class="form-label" for="enquiry-description-:">Currency :</label>
                                <input class="form-control" [ngModel]="vendorQuote?.vendorQuote?.enquiry?.currency?.currency"
                                    [ngModelOptions]="{standalone: true}" readonly="readonly">
                            </div>
                            <div class="col-md-6 form-group form-group-inline">
                                <label class="form-label" for="enquiry-description-:">Routing :</label>
                                <input class="form-control" [ngModel]="vendorQuote?.routing"
                                    [ngModelOptions]="{standalone: true}" readonly="readonly">
                            </div>
                            <div class="col-md-6 form-group form-group-inline">
                                <label class="form-label" for="enquiry-description-:">Regret Offer :</label>
                                    <input *ngIf="vendorQuote?.vendorQuote.noRegretOffer == true; else noRegret" 
                                        class="form-control" readonly="readonly" type="text" value="Yes">
                                <ng-template #noRegret>
                                    <input class="form-control" type="text" value="No" readonly="readonly">
                                </ng-template>
                            </div>
                            <div class="col-md-6 form-group form-group-inline">
                                <label class="form-label" for="enquiry-description-:">ETA :</label>
                                <input class="form-control" [ngModel]="vendorQuote?.eta | date: 'dd-MM-YYYY' "
                                    [ngModelOptions]="{standalone: true}" readonly="readonly">
                            </div>
                            <div class="col-md-6 form-group form-group-inline">
                                <label class="form-label" for="enquiry-description-:">ETD :</label>
                                <input class="form-control" [ngModel]="vendorQuote?.etd | date: 'dd-MM-YYYY' "
                                    [ngModelOptions]="{standalone: true}" readonly="readonly">
                            </div>
                            <div *ngIf="seaBool" class="col-md-6 form-group form-group-inline">
                                <label class="form-label" for="enquiry-description-:">Vessel Name :</label>
                                <input class="form-control" [ngModel]="vendorQuote?.vesselName || '----'"
                                    [ngModelOptions]="{standalone: true}" readonly="readonly">
                            </div>
                            <div *ngIf="seaBool" class="col-md-6 form-group form-group-inline">
                                <label class="form-label" for="enquiry-description-:">Vessel Schedule :</label>
                                <input class="form-control" [ngModel]="vendorQuote?.vesselSchedule | date: 'dd-MM-YYYY' "
                                    [ngModelOptions]="{standalone: true}" readonly="readonly">
                            </div>
                            <div class="col-md-6 form-group form-group-inline">
                                <label *ngIf="seaBool" class="form-label" for="enquiry-description-:">Shipping Line :</label>
                                <label *ngIf="airBool" for="lineName">Air Line*</label>
                                <input class="form-control" [ngModel]="vendorQuote?.lineName"
                                    [ngModelOptions]="{standalone: true}" readonly="readonly">
                            </div>
                            <div class="col-md-6 form-group form-group-inline">
                                <label *ngIf="seaBool" class="form-label" for="enquiry-frequency-:">Shipping Frequency:</label>
                                <label *ngIf="airBool" class="form-label" for="enquiry-frequency-:">Airline Frequency:</label>
                                <input class="form-control" [ngModel]="vendorQuote?.frequency"
                                    [ngModelOptions]="{standalone: true}" readonly="readonly">
                            </div>
                            <div *ngIf="seaBool" class="col-md-6 form-group form-group-inline">
                                <label class="form-label" for="enquiry-description-:">Sailing Days:</label>
                                <input class="form-control" [ngModel]="vendorQuote?.sailingDays || '----' "
                                    [ngModelOptions]="{standalone: true}" readonly="readonly">
                            </div>
                            <div class="col-md-6 form-group form-group-inline">
                                <label class="form-label" for="enquiry-description-:">Cargo Readiness Date:</label>
                                <input class="form-control" [ngModel]="vendorQuote?.vendorQuote.enquiry.cargoReadinessDate | date: 'dd-MM-YYYY' "
                                    [ngModelOptions]="{standalone: true}" readonly="readonly">
                            </div>
                            <div class="col-md-6 form-group form-group-inline">
                                <label class="form-label" for="enquiry-description-:">Transit Time:</label>
                                <input class="form-control" [ngModel]="vendorQuote?.transitTime"
                                    [ngModelOptions]="{standalone: true}" readonly="readonly">
                            </div>
                            <div class="col-md-6 form-group form-group-inline">
                                <label class="form-label" for="enquiry-description-:">Transite Port:</label>
                                <input class="form-control" [ngModel]="vendorQuote?.transitPort"
                                    [ngModelOptions]="{standalone: true}" readonly="readonly">
                            </div>
                            <div class="col-md-6 form-group form-group-inline">
                                <label class="form-label" for="enquiry-description-:">Chargeable Weight:</label>
                                <input class="form-control" [ngModel]="chargableWeight || '---' "
                                    [ngModelOptions]="{standalone: true}" readonly="readonly">
                            </div>
                        </div>

                        <!-- Charges -->
                        <div *ngIf="containers.length > 0" class="table-responsive">
                            <h3 class="mb-3">Container Freight</h3>
                            <table
                                class="table table-borderless table-striped table-footer-dark text-center table-head-dark">
                                <thead>
                                    <tr>
                                        <th>Type of Container</th>
                                        <th>Size</th>
                                        <th>Weight</th>
                                        <th>Weight UOM</th>
                                        <th>Quantity</th>
                                        <th>Rate/Container</th>
                                        <th>Ex Works</th>
                                        <th>Destination Charges</th>
                                        <th>Total Freight</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of containers">
                                        <td>
                                            <div *ngFor="let container of container_type">
                                                <span *ngIf="container.id == item.container.type">
                                                    {{ container.value }}
                                                </span>
                                            </div>
                                        </td>
                                        <td>{{ item.container.size }}</td>
                                        <td>{{ item.container.weight }}</td>
                                        <td>{{ item.container.uom }}</td>
                                        <td>{{ item.container.quantity }}</td>
                                        <td>{{ item.ratePerContainer }}</td>
                                        <td>{{ item.exWorks || '---' }}</td>
                                        <td>{{ item.destinationCharges || '---' }}</td>
                                        <td>{{ item.totalFreight }}</td>
                                    </tr>
                                </tbody>
                                <tfoot class="table-success">
                                    <tr>
                                        <th scope="row">Total</th>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>{{ vendorQuote?.containerTotal }}</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div *ngIf="shipmentPackages.length > 0" class="table-responsive">
                            <h3 *ngIf="seaBool" class="mb-3">Shipment Freight</h3>
                            <h3 *ngIf="airBool" class="mb-3">Air Freight</h3>
                            <table
                                class="table table-borderless table-striped table-footer-dark text-center table-head-dark">
                                <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th>Type</th>
                                        <th>Charge</th>
                                        <th>Total Freight</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of shipmentPackages">
                                        <td>{{ item.description }}</td>
                                        <td>{{ item.type }}</td>
                                        <td>{{ item.charge }}</td>
                                        <td>
                                            <span *ngIf="item.type == 'PER_UNIT'; else PER_SHIPMENT">
                                                {{ math.round(item.charge * chargableWeight) | number: '1.2' }}
                                            </span>
                                            <ng-template #PER_SHIPMENT>
                                                {{ item.charge }}
                                            </ng-template>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot class="table-success">
                                    <tr>
                                        <th scope="row">Total</th>
                                        <td></td>
                                        <td></td>
                                        <td>{{ vendorQuote?.packageFreightTotal }}</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>

                        <div *ngIf="breakBulks.length > 0" class="table-responsive">
                            <h3 class="mb-3">Break Bulk Freight</h3>
                            <table
                                class="table table-borderless table-striped table-footer-dark text-center table-head-dark">
                                <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th>Type</th>
                                        <th>Rate/CBM</th>
                                        <th>Total Freight</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of breakBulks">
                                        <td>{{ item.description }}</td>
                                        <td>{{ item.type }}</td>
                                        <td>{{ item.charge }}</td>
                                        <span *ngIf="item.type == 'PER_UNIT'; else PER_SHIPMENT">
                                            {{ math.round(item.charge * chargableWeight) | number: '1.2' }}
                                        </span>
                                        <ng-template #PER_SHIPMENT>
                                            {{ item.charge }}
                                        </ng-template>
                                    </tr>
                                </tbody>
                                <tfoot class="table-success">
                                    <tr>
                                        <th scope="row">Total</th>
                                        <td></td>
                                        <td></td>
                                        <td>{{ vendorQuote?.breakBulkTotal }}</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>

                        <div *ngIf="additionalCharges.length > 0" class="table-responsive">
                            <h3 class="mb-3">Additional Charges</h3>
                            <table
                                class="table table-borderless table-striped table-footer-dark text-center table-head-dark">
                                <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th>Type</th>
                                        <th>Charge</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of additionalCharges">
                                        <td>{{ item.description }}</td>
                                        <td>{{ item.type }}</td>
                                        <td>{{ item.charge }}</td>
                                        <td>
                                            <span *ngIf="item.type == 'PER_UNIT'; else PER_SHIPMENT">
                                                {{ math.round(item.charge * chargableWeight) | number: '1.2' }}
                                            </span>
                                            <ng-template #PER_SHIPMENT>
                                                {{ item.charge }}
                                            </ng-template>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot class="table-success">
                                    <tr>
                                        <th>Total</th>
                                        <td></td>
                                        <td></td>
                                        <td>{{ vendorQuote?.additionalChargeTotal }}</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>

                        <div class="table-responsive">
                            <table class="table table-borderless table-striped table-footer-dark text-center table-head-dark">
                                <tfoot class="table-success">
                                    <tr>
                                        <th>Grand Total</th>
                                        <td></td>
                                        <td>{{ vendorQuote?.amount }}</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div class="col-lg-12"></div>
                        <div class="row justify-content-center">
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!--request end  -->