<!--header start-->
<header class="box-absolute d-flex align-items-center topline text-white">
  <app-sidebar></app-sidebar>
  <div class="page-title-center mt-3">
    <div class="text-center">
      <h1>Detail Quote Comparison</h1>
    </div>
  </div>
  <div class="breadcrumb-div mt-3">
    <ul class="breadcrumb p-0 m-0">
      <li class="breadcrumb-item 3" id="0"><a [routerLink]="['/v1/dashboard']">Dashboard</a></li>
      <li class="breadcrumb-item 3" id="1"><a>Quotes</a></li>
      <li class="breadcrumb-item"><a>Detail Comparison</a></li>
    </ul>
  </div>
</header>
<!--header end-->
<section class="banner text-center text-white d-flex" id="bnner">
  <div class="container position-relative">
    <div class="row justify-content-center">
      <div class="col-lg-12">
      </div>
    </div>
  </div>
</section>
<!--request start-->
<section class="comparison-wrapper pb-10" (click)="hideSideBar()">
  <div class="container shadow bg-white px-start-end py-6">
    <div class="row">
      <div class="col-lg-12">
        <div class="box">
          <form>
            <div class="row bg-lighter mb-4 mb-lg-5">
              <!-- Enquiry Details -->
              <div class="col-md-6 form-group mt-3 form-group-inline">
                <label class="form-label" for="enquiryNumber"> Enquiry No :</label>
                <input class="form-control" [(ngModel)]="enquiryNumber" [ngModelOptions]="{standalone: true}" type="text"
                  id="enquiryNumber" readonly="readonly" />
              </div>
              <div class="col-md-6 form-group mt-3 form-group-inline">
                <label class="form-label" for="report-created-date-:"> Created On :</label>
                <input class="form-control" [(ngModel)]="createDate" [ngModelOptions]="{standalone: true}" type="date"
                  id="report-created-date-:" readonly="readonly" />
              </div>
              <div class="col-md-6 form-group mt-3 form-group-inline">
                <label class="form-label" for="modeOfShipment">Mode Of Shipment :</label>
                <input class="form-control" [(ngModel)]="modeOfShipment" [ngModelOptions]="{standalone: true}"
                  type="text" id="modeOfShipment" readonly="readonly" />
              </div>
              <div class="col-md-6 form-group mt-3 form-group-inline">
                <label class="form-label" for="tradeType">Trade Type :</label>
                <input class="form-control" [(ngModel)]="tradeType" [ngModelOptions]="{standalone: true}"
                  type="text" id="tradeType" readonly="readonly" />
              </div>
              <div class="col-md-6 form-group mt-3 form-group-inline">
                <label class="form-label" for="port-of-loading-:">Port Of Origin :</label>
                <input class="form-control" [(ngModel)]="portOfLanding" [ngModelOptions]="{standalone: true}"
                  type="text" id="port-of-loading-:" readonly="readonly" />
              </div>
              <div class="col-md-6 form-group form-group-inline">
                <label class="form-label" for="project-name-:">Project Name :</label>
                <input class="form-control" [(ngModel)]="projectName" [ngModelOptions]="{standalone: true}" type="text"
                  id="project-name-:" readonly="readonly" />
              </div>
              <div class="col-md-6 form-group form-group-inline">
                <label class="form-label" for="port-of-destination-:">Port Of Destination :</label>
                <input class="form-control" [(ngModel)]="portOfDestination" [ngModelOptions]="{standalone: true}"
                  type="text" id="port-of-destination-:" readonly="readonly" />
              </div>
              <div class="col-md-6 form-group form-group-inline">
                <label class="form-label" for="project-code-:">Project Code :</label>
                <input class="form-control" [(ngModel)]="projectCode" [ngModelOptions]="{standalone: true}" type="text"
                  id="project-code-:" readonly="readonly" />
              </div>
              <div class="col-md-6 form-group form-group-inline">
                <label class="form-label" for="pickupTerms">Pickup Terms :</label>
                <input class="form-control" [(ngModel)]="pickupTerms" [ngModelOptions]="{standalone: true}"
                  type="text" id="pickupTerms" readonly="readonly" />
              </div>
              <div class="col-md-6 form-group form-group-inline">
                <label class="form-label" for="delivery-terms-:">Delivery Terms :</label>
                <input class="form-control" [(ngModel)]="deliveryTerms" [ngModelOptions]="{standalone: true}"
                  type="text" id="delivery-terms-:" readonly="readonly" />
              </div>
              <div class="col-md-6 form-group form-group-inline">
                <label class="form-label" for="currency-:">Currency :</label>
                <input class="form-control" [(ngModel)]="currency" [ngModelOptions]="{standalone: true}"
                  readonly="readonly">
              </div>
              <div class="col-md-6 form-group form-group-inline">
                <label class="form-label" for="enquiry-description-:">Enquiry Description :</label>
                <input class="form-control" [(ngModel)]="enquiryDescription" [ngModelOptions]="{standalone: true}"
                  type="text" id="enquiry-description-:" readonly="readonly" />
              </div>

              <div  *ngIf="sea_mode_shipment" class="col-md-6 form-group form-group-inline">
                <label class="form-label" for="detention">Detention :</label>
                <input class="form-control" [(ngModel)]="detention" [ngModelOptions]="{standalone: true}"
                  type="number" id="detention-:" readonly="readonly" />
              </div>

              <div class="col-md-6 form-group form-group-inline">
                <label class="form-label" for="demurrage">Demurrage :</label>
                <input class="form-control" [(ngModel)]="demurrage" [ngModelOptions]="{standalone: true}"
                  type="number" id="demurrage-:" readonly="readonly" />
              </div>
            </div>

            <!-- Charges -->
            <div class="table-responsive" style="height: 800px;overflow: scroll;">

              <div *ngIf="sea_mode_shipment">
                <table id="dtHorizontalVerticalExample" class="text-center table table-striped table-bordered table-sm "
                  cellspacing="0" width="100%">
                  <thead>
                    <tr>
                      <td class="px-0 sticky-col first-col" colspan="2">
                        <button class="btn btn-dark rounded-0 e-small text-capitalize w-100"
                          type="submit">Vendors</button><span></span>
                      </td>
                      <td class="px-0" [attr.colspan]="item.vendorQuoteRows.length*colSpan"
                        *ngFor="let item of data;let i = index">
                        <button class="{{styleList[i]}} btn rounded-0 e-small text-capitalize w-100" type="submit">
                          {{ item.vendor.name }}</button><span></span>
                      </td>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <td class="px-0 sticky-col first-col" colspan="2">
                        <button class="btn btn-dark rounded-0 e-small text-capitalize w-100" type="submit">Shipping
                          Line</button><span></span>
                      </td>
                      <ng-container *ngFor="let item of data;let i = index">
                        <td class="px-0" [attr.colspan]="colSpan" *ngFor="let quote of item.vendorQuoteRows;let j=index">
                          <button class="{{styleList[j]}} btn rounded-0 e-small text-capitalize w-100" type="submit">
                            {{ quote.lineName }}</button><span></span>
                        </td>
                      </ng-container>
                    </tr>
                  </thead>
                  <thead>
                    <!-- Type Of Container -->
                    <tr *ngIf="containerFinalArray.length">
                      <td class="sticky-col first-col">Type of Container
                      </td>
                      <td>Quantity
                      </td>
                      <ng-container *ngFor="let item of data;let i = index">
                        <ng-container *ngFor="let quote of item.vendorQuoteRows">
                          <td>AMT/Container</td>
                          <td *ngIf="exWorksBool" >ExWorks</td>
                          <td *ngIf="deliveryChargeBool">Destination Charges</td>
                          <td>Total</td>
                        </ng-container>
                      </ng-container>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- Container Section -->
                    <tr *ngFor="let item of containerFinalArray; index as i">
                      <td class="sticky-col first-col">{{item?.name}}</td>
                      <td>{{item?.value}}</td>
                      <ng-container *ngFor="let data of item.data">
                        <td>{{data?.amount}}</td>
                        <td *ngIf="exWorksBool">{{ data?.exWorks }}</td>
                        <td *ngIf="deliveryChargeBool">{{ data?.destinationCharges }}</td>
                        <td>{{data?.total}}</td>
                      </ng-container>
                    </tr>

                    <!--Shipment Package Section-->
                    <tr *ngFor="let item of shipmentPackageItemArr | keyvalue; index as i">

                      <td class="sticky-col first-col">{{ item.key }}</td>
                      <td> </td>
                      <ng-container *ngFor="let element of item.value;let i = index">
                        <td [attr.colspan]="colSpan">{{element === 0 ? '' : element}}</td>
                      </ng-container>
                    </tr>

                    <!--Break Bulk Section-->

                    <tr *ngFor="let item of breakBulkItemArr | keyvalue; index as i">
                      <td class="sticky-col first-col">{{ item.key }}</td>
                      <td> </td>
                      <ng-container *ngFor="let element of item.value;let i = index">
                        <td [attr.colspan]="colSpan">{{element === 0 ? '' : element}}</td>
                      </ng-container>
                    </tr>

                    <!-- Additional Charges -->
                    <tr class="bg-l">
                      <td class="font-weight-bold sticky-col first-col">
                        <label for="">Additional Charges:</label>
                      </td>
                    </tr>

                    <tr *ngFor="let item of additionalItemArr | keyvalue; index as i">
                      <td class="sticky-col first-col">{{ item.key }}</td>
                      <td> </td>
                      <ng-container *ngFor="let element of item.value;let i = index">
                        <td [attr.colspan]="colSpan">{{element === 0 ? '' : element}}</td>
                      </ng-container>
                    </tr>
                    <!-- Grand Total -->
                    <tr class="table-dark text-white table-sm">
                      <!-- sticky-col -->
                      <td class="px-0 first-col">
                        <button class="btn btn-dark rounded-0 e-small text-capitalize w-100" type="submit">Grand
                          Total:</button><span></span>
                      </td>
                      <td> </td>
                      <ng-container *ngFor="let item of data;let i = index">
                        <td class="px-0" [attr.colspan]="colSpan" *ngFor="let quote of item.vendorQuoteRows;let j=index">
                          {{quote.amount ? quote.amount.toFixed(2) : quote.amount}}
                        </td>
                      </ng-container>
                    </tr>

                    <!-- Previous Grand Total -->
                    <tr class="table-sm">
                      <td class="px-0 sticky-col first-col">
                        <button class="btn text-dark rounded-0 e-small text-capitalize w-100" type="submit">Previous
                          Grand
                          Total:</button><span></span>
                      </td>
                      <td> </td>
                      <ng-container *ngFor="let item of data;let i = index">
                        <td class="px-0" [attr.colspan]="colSpan" *ngFor="let quote of item.vendorQuoteRows;let j=index">
                          {{quote.previousTotal ? quote.previousTotal.toFixed(2) : quote.previousTotal}}
                        </td>
                      </ng-container>
                    </tr>


                    <!--Ranking-->
                    <tr>
                      <td class="font-weight-bold sticky-col first-col">
                        <label for="">Ranking</label>
                      </td>
                      <td> </td>
                      <ng-container *ngFor="let item of data;let i = index">
                        <td class="px-0" [attr.colspan]="colSpan" *ngFor="let quote of item.vendorQuoteRows;let j=index">
                          {{ quote?.rank }}
                        </td>
                      </ng-container>
                    </tr>

                    <!--Routing-->
                    <tr>
                      <td class="font-weight-bold sticky-col first-col">
                        <label for="">Routing</label>
                      </td>
                      <td> </td>
                      <ng-container *ngFor="let item of data;let i = index">
                        <td class="px-0" [attr.colspan]="colSpan" *ngFor="let quote of item.vendorQuoteRows;let j=index">
                          {{ quote.routing }}
                        </td>
                      </ng-container>
                    </tr>

                    <tr>
                      <td class="font-weight-bold sticky-col first-col">
                        <label for="">ETD</label>
                      </td>
                      <td> </td>
                      <ng-container *ngFor="let item of data;let i = index">
                        <td class="px-0" [attr.colspan]="colSpan" *ngFor="let quote of item.vendorQuoteRows;let j=index">
                          {{ quote.etd | date: 'dd-MM-YYYY' }}
                        </td>
                      </ng-container>
                    </tr>

                    <tr>
                      <td class="font-weight-bold sticky-col first-col">
                        <label for="">ETA</label>
                      </td>
                      <td> </td>
                      <ng-container *ngFor="let item of data;let i = index">
                        <td class="px-0" [attr.colspan]="colSpan" *ngFor="let quote of item.vendorQuoteRows;let j=index">
                          {{ quote.eta | date: 'dd-MM-YYYY' }}
                        </td>
                      </ng-container>
                    </tr>

                    <!--Frequency-->
                    <tr>
                      <td class="font-weight-bold sticky-col first-col">
                        <label for="">Frequency</label>
                      </td>
                      <td> </td>
                      <ng-container *ngFor="let item of data;let i = index">
                        <td class="px-0" [attr.colspan]="colSpan" *ngFor="let quote of item.vendorQuoteRows;let j=index">
                          {{ quote.frequency }}
                        </td>
                      </ng-container>
                    </tr>

                    <!--Quote Date-->
                    <tr>
                      <td class="font-weight-bold sticky-col first-col">
                        <label for="">Quote Date</label>
                      </td>
                      <td> </td>
                      <ng-container *ngFor="let item of data;let i = index">
                        <td class="px-0" [attr.colspan]="colSpan" *ngFor="let quote of item.vendorQuoteRows;let j=index">
                          {{ item.quoteDate | date: 'dd-MM-YYYY' }}
                        </td>
                      </ng-container>
                    </tr>

                    <!--Valid Till-->
                    <tr>
                      <td class="font-weight-bold sticky-col first-col">
                        <label for="">Valid Till</label>
                      </td>
                      <td> </td>
                      <ng-container *ngFor="let item of data;let i = index">
                        <td class="px-0" [attr.colspan]="colSpan" *ngFor="let quote of item.vendorQuoteRows;let j=index">
                          {{ quote.quoteValidTill | date: 'dd-MM-YYYY' }}
                        </td>
                      </ng-container>
                    </tr>

                    <!--Vessel Schedule-->
                    <tr *ngIf="modeOfShipment != 'AIR'">
                      <td class="font-weight-bold sticky-col first-col">
                        <label for="">Vessel Schedule</label>
                      </td>
                      <td> </td>
                      <ng-container *ngFor="let item of data;let i = index">
                        <td class="px-0" [attr.colspan]="colSpan" *ngFor="let quote of item.vendorQuoteRows">
                          {{ quote.vesselSchedule | date: 'dd-MM-YYYY' }}
                        </td>
                      </ng-container>
                    </tr>

                    <!--Vessel Name-->
                    <tr *ngIf="modeOfShipment != 'AIR'">
                      <td class="font-weight-bold sticky-col first-col">
                        <label for="">Vessel Name</label>
                      </td>
                      <td> </td>
                      <ng-container *ngFor="let item of data;let i = index">
                        <td class="px-0" [attr.colspan]="colSpan" *ngFor="let quote of item.vendorQuoteRows">
                          {{ quote.vesselName }}
                        </td>
                      </ng-container>
                    </tr>

                    <!--Loading Terminal-->
                    <tr *ngIf="modeOfShipment != 'AIR'">
                      <td class="font-weight-bold sticky-col first-col">
                        <label for="">Loading Terminal</label>
                      </td>
                      <td> </td>
                      <ng-container *ngFor="let item of data;let i = index">
                        <td class="px-0" [attr.colspan]="colSpan" *ngFor="let quote of item.vendorQuoteRows">
                          {{ quote.loadingTerminal }}
                        </td>
                      </ng-container>
                    </tr>

                    <!-- sailingDays -->
                    <tr *ngIf="modeOfShipment != 'AIR'">
                      <td class="font-weight-bold sticky-col first-col">
                        <label for="">Sailing Days</label>
                      </td>
                      <td> </td>
                      <ng-container *ngFor="let item of data;let i = index">
                        <td class="px-0" [attr.colspan]="colSpan" *ngFor="let quote of item.vendorQuoteRows">
                          {{ quote.sailingDays }}
                        </td>
                      </ng-container>
                    </tr>

                    <!--Transit Time-->
                    <tr>
                      <td class="font-weight-bold sticky-col first-col">
                        <label for="">Transit Time</label>
                      </td>
                      <td> </td>
                      <ng-container *ngFor="let item of data;let i = index">
                        <td class="px-0" [attr.colspan]="colSpan" *ngFor="let quote of item.vendorQuoteRows">
                          {{ quote.transitTime }}
                        </td>
                      </ng-container>
                    </tr>

                    <!--Transit Port-->
                    <tr>
                      <td class="font-weight-bold sticky-col first-col">
                        <label for="">Transit Port</label>
                      </td>
                      <td> </td>
                      <ng-container *ngFor="let item of data;let i = index">
                        <td class="px-0" [attr.colspan]="colSpan" *ngFor="let quote of item.vendorQuoteRows">
                          {{ quote.transitPort }}
                        </td>
                      </ng-container>
                    </tr>

                    <!--No Regret Offer-->
                    <tr>
                      <td class="font-weight-bold sticky-col first-col">
                        <label>No Regret Offer</label>
                      </td>
                      <td> </td>

                      <ng-container *ngFor="let item of data;let i = index">
                        <td class="px-0" [attr.colspan]="colSpan" *ngFor="let quote of item.vendorQuoteRows">
                          <input class="form-check-input" [hidden]="item == undefined" disabled
                            [checked]="item.noRegretOffer" type="checkbox" />
                          <button (click)="compareQuotes(quote)" *ngIf="item.noRegretOffer" class="btn-sm">
                            <i class="fa fa-eye" aria-hidden="true"></i>
                          </button>
                        </td>
                      </ng-container>
                    </tr>

                  </tbody>
                </table>
              </div>

              <div *ngIf="air_mode_shipment">
                <table id="dtHorizontalVerticalExample" class="text-center table table-striped table-bordered table-sm "
                  cellspacing="0" width="100%">
                  <thead>
                    <tr>
                      <td class="px-0 sticky-col first-col" colspan="2">
                        <button class="btn btn-dark rounded-0 e-small text-capitalize w-100"
                          type="submit">Vendors</button><span></span>
                      </td>
                      <td class="px-0" [attr.colspan]="item.vendorQuoteRows.length*2"
                        *ngFor="let item of data;let i = index">
                        <button class="{{styleList[i]}} btn rounded-0 e-small text-capitalize w-100" type="submit">
                          {{ item.vendor.name }}</button><span></span>
                      </td>
                    </tr>
                  </thead>

                  <thead>
                    <tr>
                      <td class="px-0 sticky-col first-col" colspan="2">
                        <button class="btn btn-dark rounded-0 e-small text-capitalize w-100" type="submit">Air
                          Line</button><span></span>
                      </td>
                      <ng-container *ngFor="let item of data;let i = index">
                        <td class="px-0" colspan="2" *ngFor="let quote of item.vendorQuoteRows;let j=index">
                          <button class="{{styleList[j]}} btn rounded-0 e-small text-capitalize w-100" type="submit">
                            {{ quote.lineName }}</button><span></span>
                        </td>
                      </ng-container>
                    </tr>
                  </thead>

                  <tbody>


                    <!--Shipment Package Section-->
                    <tr *ngFor="let item of shipmentPackageItemArr | keyvalue; index as i">

                      <td class="sticky-col first-col">{{ item.key }}</td>
                      <td> </td>
                      <ng-container *ngFor="let element of item.value;let i = index">
                        <td colspan="2">{{element === 0 ? '' : element}}</td>
                      </ng-container>
                    </tr>

                    <!-- Additional Charges -->
                    <tr class="bg-l">
                      <td class="font-weight-bold sticky-col first-col">
                        <label for="">Additional Charges:</label>
                      </td>
                    </tr>
                    <tr *ngFor="let item of additionalItemArr | keyvalue; index as i">
                      <td class="sticky-col first-col">{{ item.key }}</td>
                      <td> </td>
                      <ng-container *ngFor="let element of item.value;let i = index">
                        <td colspan="2">{{element === 0 ? '' : element}}</td>
                      </ng-container>
                    </tr>

                    <!-- Grand Total -->
                    <tr class="table-dark text-white table-sm">
                      <!-- sticky-col -->
                      <td class="px-0 first-col">
                        <button class="btn btn-dark rounded-0 e-small text-capitalize w-100" type="submit">Grand
                          Total:</button><span></span>
                      </td>
                      <td> </td>
                      <ng-container *ngFor="let item of data;let i = index">
                        <ng-container *ngFor="let quote of item.vendorQuoteRows">

                          <td class="px-0" colspan="2">{{quote.amount ? quote.amount.toFixed(2) : quote.amount}}</td>
                        </ng-container>
                      </ng-container>
                    </tr>

                    <!-- Previous Grand Total, need changes at ts file for air -->
                    <tr class="table-sm">
                      <td class="px-0 sticky-col first-col">
                        <button class="btn text-dark rounded-0 e-small text-capitalize w-100" type="submit">Previous
                          Grand
                          Total:</button><span></span>
                      </td>
                      <td> </td>
                      <ng-container *ngFor="let item of data;let i = index">
                        <ng-container *ngFor="let quote of item.vendorQuoteRows">

                          <td class="px-0" colspan="2">{{quote.previousTotal ? quote.previousTotal.toFixed(2) :
                            quote.previousTotal}}</td>
                        </ng-container>
                      </ng-container>
                    </tr>

                    <!--Ranking-->
                    <tr>
                      <td class="font-weight-bold sticky-col first-col">
                        <label for="">Ranking</label>
                      </td>
                      <td> </td>
                      <ng-container *ngFor="let item of data;let i = index">
                        <td class="px-0" colspan="2" *ngFor="let quote of item.vendorQuoteRows;let j=index">
                          {{ quote?.rank }}
                        </td>
                      </ng-container>
                    </tr>

                    <!--Routing-->
                    <tr>
                      <td class="font-weight-bold sticky-col first-col">
                        <label for="">Routing</label>
                      </td>
                      <td> </td>
                      <ng-container *ngFor="let item of data;let i = index">
                        <td class="px-0" colspan="2" *ngFor="let quote of item.vendorQuoteRows">
                          {{ quote.routing }}
                        </td>
                      </ng-container>
                    </tr>

                    <tr>
                      <td class="font-weight-bold sticky-col first-col">
                        <label for="">ETD</label>
                      </td>
                      <td> </td>
                      <ng-container *ngFor="let item of data;let i = index">
                        <td class="px-0" [attr.colspan]="colSpan" *ngFor="let quote of item.vendorQuoteRows;let j=index">
                          {{ quote.etd | date: 'dd-MM-YYYY' }}
                        </td>
                      </ng-container>
                    </tr>

                    <tr>
                      <td class="font-weight-bold sticky-col first-col">
                        <label for="">ETA</label>
                      </td>
                      <td> </td>
                      <ng-container *ngFor="let item of data;let i = index">
                        <td class="px-0" [attr.colspan]="colSpan" *ngFor="let quote of item.vendorQuoteRows;let j=index">
                          {{ quote.eta | date: 'dd-MM-YYYY' }}
                        </td>
                      </ng-container>
                    </tr>

                    <!--Frequency-->
                    <tr>
                      <td class="font-weight-bold sticky-col first-col">
                        <label for="">Frequency</label>
                      </td>
                      <td> </td>
                      <ng-container *ngFor="let item of data;let i = index">
                        <td class="px-0" colspan="2" *ngFor="let quote of item.vendorQuoteRows">
                          {{ quote.frequency }}
                        </td>
                      </ng-container>
                    </tr>

                    <!--Quote Date-->
                    <tr>
                      <td class="font-weight-bold sticky-col first-col">
                        <label for="">Quote Date</label>
                      </td>
                      <td> </td>
                      <ng-container *ngFor="let item of data;let i = index">
                        <td class="px-0" colspan="2" *ngFor="let quote of item.vendorQuoteRows">
                          {{ item.quoteDate | date: 'dd-MM-YYYY' }}
                        </td>
                      </ng-container>
                    </tr>

                    <!--Valid Till-->
                    <tr>
                      <td class="font-weight-bold sticky-col first-col">
                        <label for="">Valid Till</label>
                      </td>
                      <td> </td>
                      <ng-container *ngFor="let item of data;let i = index">
                        <td class="px-0" colspan="2" *ngFor="let quote of item.vendorQuoteRows">
                          {{ quote.quoteValidTill | date: 'dd-MM-YYYY'  }}
                        </td>
                      </ng-container>
                    </tr>

                    <!--Transit Time-->
                    <tr>
                      <td class="font-weight-bold sticky-col first-col">
                        <label for="">Transit Time</label>
                      </td>
                      <td> </td>
                      <ng-container *ngFor="let item of data;let i = index">
                        <td class="px-0" colspan="2" *ngFor="let quote of item.vendorQuoteRows">
                          {{ quote.transitTime }}
                        </td>
                      </ng-container>
                    </tr>

                    <!--Transit Port-->
                    <tr>
                      <td class="font-weight-bold sticky-col first-col">
                        <label for="">Transit Port</label>
                      </td>
                      <td> </td>
                      <ng-container *ngFor="let item of data;let i = index">
                        <td class="px-0" colspan="2" *ngFor="let quote of item.vendorQuoteRows">
                          {{ quote.transitPort }}
                        </td>
                      </ng-container>
                    </tr>

                    <!--No Regret Offer-->
                    <tr>
                      <td class="font-weight-bold sticky-col first-col">
                        <label>No Regret Offer</label>
                      </td>
                      <td> </td>

                      <ng-container *ngFor="let item of data;let i = index">
                        <td class="px-0" colspan="2" *ngFor="let quote of item.vendorQuoteRows">
                          <input class="form-check-input" [hidden]="item == undefined" disabled
                            [checked]="item.noRegretOffer" type="checkbox" />
                          <button (click)="compareQuotes(quote)" *ngIf="item.noRegretOffer" class="btn-sm">
                            <i class="fa fa-eye" aria-hidden="true"></i>
                          </button>
                        </td>
                      </ng-container>
                    </tr>

                    <!--Select For Service Order, *ngIf="enquiryObject?.type != 'b' "-->
                    <tr>
                      <td class="font-weight-bold sticky-col first-col">
                        <label> Select For Service Order </label>
                      </td>
                      <td> </td>

                      <ng-container *ngFor="let item of data;let i = index">
                        <td class="px-0" colspan="2" *ngFor="let quote of item.vendorQuoteRows">
                          <input class="form-check-input" type="checkbox" (change)="compare($event, quote.id)"
                            name="option1" />
                        </td>
                      </ng-container>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-lg-12"></div>
            <!-- Buttons -->
            <div class="row justify-content-center">
              <div *ngIf="enquiryObject?.type != 'b' " class="col-md-3 form-group pt-lg-3">
                <button class="btn text-dark small btn-outline-success px-3" (click)="createAuction()"
                  type="button">CREATE AUCTION</button>
              </div>
              <div class="col-md-3 form-group pt-lg-3">
                <button class="btn text-dark small btn-outline-success px-3" (click)="createServiceOrder()"
                  type="button">CREATE SERVICE ORDER</button>
              </div>
              <div *ngIf="!enquiryObject?.callForNoRegretOfferSent" class="col-md-3 form-group pt-lg-3">
                <button class="btn text-dark small btn-outline-success px-3" (click)="callForNoRegretOffer()"
                  type="button">Call For NO REGRET</button>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-md-3 form-group pt-lg-3">
                <button class="btn text-dark small btn-outline-success px-3" type="button" (click)="downloadPDF()">
                  <i style="margin-right: 10px;" class="fa fa-download fa-lg" aria-hidden="true" title="Download"></i>
                  Xlsx 
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
<!--request end  -->