<!--header start-->
<header class="box-absolute d-flex align-items-center topline text-white">
    <app-sidebar></app-sidebar>
    <div class="page-title-center mt-3">
        <div class="text-center">
            <h1>Shipment Order Listing</h1>
        </div>
    </div>
    <div class="breadcrumb-div mt-3">
        <ul class="breadcrumb p-0 m-0">
            <li class="breadcrumb-item 3" id="0"><a [routerLink]="'/v1/dashboard'">Dashboard</a></li>
            <li class="breadcrumb-item 3" id="1"><a>Listing</a></li>
            <li class="breadcrumb-item"><a>Shipment Order Listing</a></li>
        </ul>
    </div>
</header>
<!--header end-->
<section class="banner text-center text-white d-flex" id="bnner">
    <div class="container position-relative">
        <div class="row justify-content-center">
            <div class="col-lg-12">
            </div>
        </div>
    </div>
</section>

<!--request start-->
<section class="request-demo-wrapper pb-10">
    <div class="container shadow bg-white" (click)="hideSideBar()">
        <div class="row">
            <div class="col-lg-12">
                <form>
                    <div
                        class="filter-wrap btn-group d-flex flex-wrap flex-md-nowrap justify-content-center gap-2 pb-3 pb-lg-4 px-3 px-lg-4">
                        <div class="input-group">
                            <input class="form-control rounded-3 px-5" type="text" matInput
                                (keyup)="applyFilter($event)" placeholder="Search" #input>
                            <button class="input-group-btn px-3 end-0" type="button"><i class="fa fa-search"></i>
                            </button>
                        </div>
                        <button *ngIf="showShipmentCreateBtn" class="rounded-3 flex-shrink-0" type="button"
                            (click)="createShipmentOrder()">
                            <i class="fa fa-plus me-2"></i><span>Create Shipment Order</span>
                        </button>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-md-2 bg-transparent border-0">
                            <div style="border: 2px solid black;"
                                class="card-body rounded-xxl bg-white py-4 px-3 px-lg-4">
                                <p class="text-center">{{ activeShipments }}</p>
                                <h5 class="text-success text-center">Active</h5>
                            </div>
                        </div>
                        <div class="col-md-2 bg-transparent border-0">
                            <div style="border: 2px solid black;"
                                class="card-body rounded-xxl bg-white py-4 px-3 px-lg-4">
                                <p class="text-center">{{ countAtOrigin }}</p>
                                <h5 class="text-success text-center">At Origin</h5>
                            </div>
                        </div>
                        <div class="col-md-2 bg-transparent border-0">
                            <div style="border: 2px solid black;"
                                class="card-body rounded-xxl bg-white py-4 px-3 px-lg-4">
                                <p class="text-center">{{ countInTransit }}</p>
                                <h5 class="text-success text-center">In Transit</h5>
                            </div>
                        </div>
                        <div class="col-md-2 bg-transparent border-0">
                            <div style="border: 2px solid black;"
                                class="card-body rounded-xxl bg-white py-4 px-3 px-lg-4">
                                <p class="text-center">{{ countInTranshipment }}</p>
                                <h5 class="text-success text-center">Transhipment</h5>
                            </div>
                        </div>
                        <div class="col-md-2 bg-transparent border-0">
                            <div style="border: 2px solid black;"
                                class="card-body rounded-xxl bg-white py-4 px-3 px-lg-4">
                                <p class="text-center">{{ countAtPOD }}</p>
                                <h5 class="text-success text-center">P.O.D</h5>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-4">
                            <select class="form-select form-control" [(ngModel)]="filterLabel"
                                [ngModelOptions]="{standalone: true}" (change)="filterShipments($event)">
                                <option *ngFor="let filter of filterShipmentArray" [value]="filter.code">
                                    {{ filter.value }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-4">
                            <input class="form-control" type="text" [(ngModel)]="filterValue"
                                [ngModelOptions]="{standalone: true}" placeholder="{{filterPlaceHolder}}">
                        </div>
                        <div class="col-md-4">
                            <button class="btn text-dark text-small btn-outline-success w-100 h-10"
                                (click)="filterShipmentAPI()">Filter</button>
                        </div>
                    </div>
                    <div class="table-responsive mt-2">
                        <table class="table table-borderless" mat-table [dataSource]="dataSource" matSort
                            matSortDisableClear>

                            <ng-container matColumnDef="createdDate">
                                <th class="text-white" mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Created Date
                                </th>
                                <td mat-cell *matCellDef="let row"> {{ row.createdDate | date: 'YYYY-MM-dd' }} </td>
                            </ng-container>

                            <ng-container matColumnDef="modeOfShipment">
                                <th class="text-white" mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Mode Of Shipment
                                </th>
                                <td mat-cell *matCellDef="let row"> {{ row.modeOfShipmentLabel }} </td>
                            </ng-container>

                            <ng-container matColumnDef="waybillIdentification">
                                <th class="text-white" mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Way Bill Identification
                                </th>
                                <td mat-cell *matCellDef="let row">
                                    <div *ngIf="row.waybillIdentification.length == 0">
                                        {{ '----' }}
                                    </div>
                                    <div *ngFor="let way of row.waybillIdentification; index as i">
                                        {{ way.waybillIdentification || '----' }}
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="bookingNumber">
                                <th class="text-white" mat-header-cell *matHeaderCellDef mat-sort-header> Booking
                                    Number
                                </th>
                                <td mat-cell *matCellDef="let row">{{ row.bookingNumber || '----' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="containerNumber">
                                <th class="text-white" mat-header-cell *matHeaderCellDef mat-sort-header> Container
                                    Number
                                </th>
                                <td mat-cell *matCellDef="let row">{{ row.containerNumber || '----' }}</td>
                            </ng-container>
                            <ng-container matColumnDef="blContainersRef">
                                <th class="text-white" mat-header-cell *matHeaderCellDef> BL Number </th>
                                <td mat-cell *matCellDef="let row">{{ row.blContainersRef || '----' }} </td>
                            </ng-container>
                            <ng-container matColumnDef="actions">
                                <th class="text-white" mat-header-cell *matHeaderCellDef> Actions</th>
                                <td mat-cell *matCellDef="let row; index as i">
                                    <div class="row">
                                        <div *ngIf="showUpdateShipmentBtn" class="col-md-6">
                                            <button class="btn-sm mb-1" (click)="editShipmentOrder(row.id)">
                                                <i class="fa fa-edit" title="EDIT"></i>
                                            </button>
                                        </div>
                                        <div class="col-md-6">
                                            <input type="checkbox" (change)="shipmentCheckboxSelect($event,row,i)" />
                                        </div>
                                    </div>
                                </td>
                            </ng-container>

                            <tr class="text-left bg-dark" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                            <!-- Row shown when there is no matching data -->

                            <!-- Row shown when there is no matching data -->
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell text-center text-danger" colspan="8">
                                    <div *ngIf="serchFilter">
                                        No data matching the filter "{{ input.value }}"
                                    </div>
                                    <div *ngIf="nodata">
                                        <h5>Data is Not Available</h5>
                                    </div>
                                </td>
                            </tr>
                        </table>
                        <mat-paginator [pageSizeOptions]="[25, 50, 100]" aria-label="Select page of users"
                            [length]="length" showFirstLastButtons (page)="onPageChange($event)">
                        </mat-paginator>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-md-4 form-group pt-lg-3">
                            <button class="btn text-dark text-small btn-outline-success" type="button"
                                (click)="trackButtonClick()">TRACK</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
<!--request end -->