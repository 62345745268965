import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { OnboardService } from 'src/app/helpers/onboard.service';
import { SubscriptionExtensionService } from 'src/app/helpers/subscription-extension.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-buy-subscription',
  templateUrl: './buy-subscription.component.html',
  styleUrls: ['./buy-subscription.component.css']
})
export class BuySubscriptionComponent implements OnInit {

  buySubscriptionForm: FormGroup
  page: number = 0;
  size: number = 25;
  allClientsList: Array<any> = [];
  filteredClients: Array<any> = [];
  subscriptionBool: Boolean = false;
  subscriptionId: any
  dateTimeSoftExpiry: any
  dateTimeHardExpiry: any
  modifiedDate: any
  modifiedHardExDate: any

  constructor(private fb: FormBuilder, private clientService: OnboardService,
    private subscriptionSer: SubscriptionExtensionService, private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    this.buySubscriptionForm = this.fb.group({
      client: this.fb.group({
        id: ''
      }),
      subscriptionType: '',
      licenseType: '',
    })

    this.getAllClients();
    this.subscriptionId = this.route.snapshot.paramMap.get("id")
    if (this.subscriptionId != null) {
      this.getSubscriptionDetailById(this.subscriptionId)
    }
  }

  get details() {
    return this.buySubscriptionForm.controls
  }

  get client() {
    let a = this.details.client as FormGroup;
    return a.controls
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  //To get clients list
  getAllClients() {
    this.clientService.getAllclientListing(this.page, this.size).subscribe(
      (response) => {
        if (response != null) {
          this.allClientsList = response.content
          console.log(this.allClientsList)
          // this.filterClients();
        }
      },
      (error) => {
        console.log(error);
      }
    )
  }

  //To filter clients with complete status
  filterClients() {
    this.allClientsList.filter((res) => {
      if (res.onboardingStatus == 'COMPLETE') {
        console.log(res)
        this.filteredClients.push(res)
      }
    })
  }

  //API POST buy subscription
  buySubscription() {
    this.subscriptionSer.buySubscription(this.buySubscriptionForm.value).subscribe
      ((res) => {
        console.log(res);
        Swal.fire({
          titleText: "Success",
          text: res.message,
          icon: "success",
          timer: 5000
        });
        this.buySubscriptionForm.reset();
      },
        (error) => {
          console.log(error);
          Swal.fire({
            titleText: "Invalid",
            text: error.message,
            icon: "error",
            timer: 5000
          });
        })
  }

  //Update APi
  updateSubscription() {
    this.subscriptionSer.updateSubscriptionById(this.subscriptionId, this.buySubscriptionForm.value).subscribe
      ((res) => {
        console.log(res);
        Swal.fire({
          titleText: "Success",
          text: res.message,
          icon: "success",
          timer: 5000
        });
        this.router.navigateByUrl('/v1/manage-extension');
      },
        (error) => {
          console.log(error);
        })
  }

  getSubscriptionDetailById(id: any) {
    this.subscriptionSer.getSubscriptionDetailsById(id).subscribe
      ((res) => {
        if (res != null) {
          console.log(res);
          this.subscriptionBool = true;
          this.client.id.patchValue(res.client.id)
          this.details.subscriptionType.patchValue(res.subscriptionType)
          this.details.licenseType.patchValue(res.licenseType)
          this.buySubscriptionForm.addControl("softExpiryDate", new FormControl(Date))
          this.dateTimeSoftExpiry = res.softExpiryDate
          this.modifyDateSoftEx();
          this.details.softExpiryDate.patchValue(this.modifyDateSoftEx())
          this.buySubscriptionForm.addControl("hardExpiryDate", new FormControl(res.hardExpiryDate))
          this.dateTimeHardExpiry = res.hardExpiryDate
          this.modifyDateHardEx();
          this.details.hardExpiryDate.patchValue(this.modifyDateHardEx())          
       }
      },
        (error) => {
          console.log(error);
        })
  }

  modifyDateSoftEx() {
    var a = this.dateTimeSoftExpiry.split("T")
    // console.log(a);
    this.modifiedDate = a[0]
    return this.modifiedDate
  }

  modifyDateHardEx(){
    var a = this.dateTimeHardExpiry.split("T")
    // console.log(a);
    this.modifiedHardExDate = a[0]
    return this.modifiedHardExDate
  }


}
