<!--header start-->
<header class="box-absolute d-flex align-items-center topline text-white">
    <app-sidebar></app-sidebar>
    <div class="page-title-center mt-3">
      <div class="text-center">
        <h1>Vendor Listing</h1>
      </div>
    </div>
    <div class="breadcrumb-div mt-3">
        <ul class="breadcrumb p-0 m-0">
            <li class="breadcrumb-item 3" id="0"><a [routerLink]="['/v1/dashboard']">Dashboard</a></li>
            <li class="breadcrumb-item 3" id="1"><a>Listing</a></li>
            <li class="breadcrumb-item"><a>Vendor</a></li>
        </ul>
    </div>
</header>
<!--header end-->

<div (click)="hideSideBar()">
<section class="banner text-center text-white d-flex" id="bnner">
    <div class="container position-relative">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                
            </div>
        </div>
    </div>
</section>
<!--request start-->
<section class="request-demo-wrapper pb-10">
    <div class="container shadow bg-white">
        <div class="row">
            <div class="col-lg-12">
                <div class="box">
                    <form>
                        <div class="btn-group d-flex gap-2 pb-3 pb-lg-4 px-3 px-lg-4">
                            <div class="input-group">
                                <input class="form-control rounded-3 px-4" type="text" placeholder=" Search" matInput
                                    (keyup)="applyFilter($event)" #input />
                                <button class="input-group-btn end-0" type="button"><i class="fa fa-search"></i>
                                </button>
                            </div>
                            <!-- <button class="rounded-3 flex-shrink-0" (click)="openDialog('Create', {})" type="button"><i
                                    class="fa fa-plus me-2"></i><span>
                                    Add Trade Type </span>
                            </button> -->
                        </div>

                        <div class="mat-elevation-z8 table-responsive">
                            <table class="table table-borderless table-head-dark" mat-table matSort [dataSource]="dataSource">

                                <!-- ID Column -->
                                <ng-container matColumnDef="name">
                                    <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                                    <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                                </ng-container>

                                <!-- Consignee Column -->
                                <ng-container matColumnDef="website">
                                    <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header>Website</th>
                                    <td mat-cell *matCellDef="let row"> {{row.website}} </td>
                                </ng-container>

                                <!-- <ng-container matColumnDef="pocFirstName">
                                    <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header>POC First Name</th>
                                    <td mat-cell *matCellDef="let row"> {{row.pocFirstName}} </td>
                                </ng-container>

                                <ng-container matColumnDef="pocLastName">
                                    <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header>POC Last Name</th>
                                    <td mat-cell *matCellDef="let row"> {{row.pocLastName}} </td>
                                </ng-container> -->

                                <ng-container matColumnDef="pocEmailId">
                                    <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header>POC Email Id</th>
                                    <td mat-cell *matCellDef="let row"> {{row.pocEmailId}} </td>
                                </ng-container>

                                <!-- <ng-container matColumnDef="pocContactNumber">
                                    <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header>POC Contact Number</th>
                                    <td mat-cell *matCellDef="let row"> {{row.pocContactNumber}} </td>
                                </ng-container> -->

                                <ng-container matColumnDef="createDate">
                                    <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header>Create Date</th>
                                    <td mat-cell *matCellDef="let row"> {{row.createDate | date}} </td>
                                </ng-container>

                                <ng-container matColumnDef="onboardingStatus">
                                    <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header>Onboarding Status</th>
                                    <td mat-cell *matCellDef="let row"> {{row.onboardingStatus}} </td>
                                </ng-container>

                                <ng-container matColumnDef="actions">
                                    <th class="text-white " mat-header-cell *matHeaderCellDef>Actions</th>
                                    <td mat-cell *matCellDef="let row">
                                        <button class="text-primary" (click)="routeToView(row)">
                                            <i class="fa fa-eye" title="View"></i>
                                        </button>
                                        <button *ngIf="row.onboardingStatus == 'INITIATED'" class="text-danger" (click)="deleteClient(row.id, row.name)">
                                            <i class="fa fa-trash"></i>
                                        </button>
                                    </td>
                                </ng-container>

                                <tr class="bg-dark" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                                <!-- Row shown when there is no matching data. -->
                                <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell text-center text-danger" colspan="8">
                                        <div *ngIf="serchFilter">
                                            No data matching the filter "{{ input.value }}"
                                        </div>
                                        <div *ngIf="nodata">
                                            <h5>Data is Not Available</h5>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                            <mat-paginator [pageSizeOptions]="[25,50,100]" [length]="vendorLength"
                                showFirstLastButtons (page)="onPageChange($event)">
                            </mat-paginator>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
</section>
</div>