<header class="box-absolute d-flex align-items-center topline text-white">
    <app-sidebar></app-sidebar>
    <div class="page-title-center mt-3">
      <div class="text-center">
        <h1>{{ title }}</h1>
      </div>
    </div>
    <div class="breadcrumb-div mt-3">
        <ul class="breadcrumb p-0 m-0">
            <li class="breadcrumb-item 3" id="0"><a [routerLink]="['/v1/dashboard']">Dashboard</a></li>
            <li class="breadcrumb-item 3" id="1"><a>Listing</a></li>
            <li class="breadcrumb-item"><a>{{ title }}</a></li>
        </ul>
    </div>
</header>
<!--header end-->
<section class="banner text-center text-white d-flex" id="bnner">
    <div class="container position-relative">
        <div class="row justify-content-center">
            <div class="col-lg-12">
            </div>
        </div>
    </div>
</section>
<!--request start-->
<section class="request-demo-wrapper pb-10">
    <div class="container shadow bg-white" (click)="hideSideBar()">
        <div class="row">
            <div class="col-lg-12">
                <div class="box">
                    <form>
                        <div class="filter-wrap btn-group d-flex flex-wrap flex-md-nowrap justify-content-center gap-2 pb-3 pb-lg-4 px-3 px-lg-4">
                            <div class="input-group">
                                <input class="form-control rounded-3 px-5" type="text" matInput
                                    (keyup)="applyFilter($event.target.value)" placeholder="Search" #input>
                                <button class="input-group-btn px-3 end-0" type="button"><i class="fa fa-search"></i>
                                </button>
                            </div>
                        </div>
                        <div class="mat-elevation-z8 table-responsive">
                            <table mat-table [dataSource]="dataSource" matSort class="table table-borderless table-striped table-footer-dark table-head-dark">
                                <ng-container matColumnDef="number">
                                    <th class="text-white" mat-header-cell *matHeaderCellDef mat-sort-header>Number</th>
                                    <td mat-cell *matCellDef="let row">{{row.number||"---"}}</td>
                                </ng-container>
                                <ng-container matColumnDef="date">
                                    <th class="text-white" mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Service Order Date
                                    </th>
                                    <td mat-cell *matCellDef="let row">{{row.serviceOrderDate}}</td>
                                </ng-container>
                                <ng-container matColumnDef="quoteNumber">
                                    <th class="text-white" mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Quotation Number
                                    </th>
                                    <td mat-cell *matCellDef="let row">{{row.quotationNumber||"---"}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="auctionNumber">
                                    <th class="text-white" mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Auction Number
                                    </th>
                                    <td mat-cell *matCellDef="let row">{{row.auctionNumber||"---"}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="forwaderName">
                                    <th class="text-white" mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Vendor Name
                                    </th>
                                    <td mat-cell *matCellDef="let row">{{row.vendor?.name}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="actions">
                                    <th class="text-white" mat-header-cell *matHeaderCellDef> Actions</th>
                                    <td mat-cell *matCellDef="let row; index as i">
                                        <button (click)="editServiceOrder(row.id)">
                                            <i class="fa fa-edit" title="EDIT"></i>
                                        </button>
                                        <button (click)="downloadServiceOrder(row.id)">
                                            <i class="fa fa-download" title="Download"></i>
                                        </button>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayColumns" class="text-left bg-dark"></tr>
                                <tr mat-row *matRowDef="let row; columns:displayColumns;"></tr>
                                <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell text-center text-danger" colspan="12">No data matching the filter"{{input.value}}"</td>
                                </tr>
                                <ng-container *ngIf="!objBool">
                                    <h3 class="text-center text-danger" >
                                        No data available
                                    </h3>
                                </ng-container>
                            </table>
                            <mat-paginator [pageSizeOptions]="[25,50,100]" aria-label="Select page of users" 
                                (page)="onPageChange($event)" [length]="length" showFirstLastButtons>
                            </mat-paginator>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>