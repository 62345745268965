import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { OnboardService } from 'src/app/helpers/onboard.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-vendor-certification',
  templateUrl: './vendor-certification.component.html',
  styleUrls: ['./vendor-certification.component.css']
})
export class VendorCertificationComponent implements OnInit {
  certificationForm: FormGroup;
  vendor_id = sessionStorage.getItem('org_id');
  pre_token = sessionStorage.getItem('preToken');
  terms_conditions: Boolean = false;
  disabled: Boolean = true;

  constructor(private form: FormBuilder, private vendorService: OnboardService, private router: Router) { }

  ngOnInit(): void {
    this.certificationForm = this.form.group({
      certifiedByName: ['', Validators.required],
      certifiedByDesignation: [''],
      certifiedOn: ['', Validators.required],
    });
    console.log(this.vendor_id);
    if(this.pre_token != null){
      this.vendorService.vendorVerifyToken(this.pre_token).subscribe(
        (response) => {
          console.log(response);
          if(response){
            this.certificationForm.patchValue({
              certifiedByName: response.certifiedByName,
              certifiedByDesignation: response.certifiedByDesignation,
              certifiedOn: String(response.certifiedOn).split('T')[0]
            });
          }
        },
        (error) => {
          console.log(error);
        },
      )
    }

    this.gotoTop();
  }

  get certification() { return this.certificationForm.controls; }

  vendorSubmitCert(){
    if(this.terms_conditions != true){
      Swal.fire({
        titleText: "Warning",
        timer: 5000,
        text: "Please Accept Terms And Conditions",
        icon: "warning"
      })
    }
    else if(!this.certificationForm.invalid && this.terms_conditions == true){
      let data = {
        "id": this.vendor_id,
        "certifiedByName": this.certification.certifiedByName.value,
        "certifiedByDesignation": this.certification.certifiedByDesignation.value,
        "certifiedOn": this.certification.certifiedOn.value
      }
      console.log(data);
      this.vendorService.updateVendor(data).subscribe(
        (response) => {
          console.log(response);
          this.router.navigate(['v1/vendor-onboard/sign-up']);
        },
        (error) => {
          console.log(error);
        },
      )
    }
    else{
      Swal.fire({
        titleText: "Invalid",
        text: "Fill all the mandatory fields",
        timer: 5000,
        icon: "warning"
      })
    }
  }

  getToday(){
    return new Date().toISOString().split('T')[0]
  }

  //fuction to scroll to top on next button click
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

}
