import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

declare var jQuery: any;

@Component({
  selector: 'app-contianer-live-dialog',
  templateUrl: './contianer-live-dialog.component.html',
  styleUrls: ['./contianer-live-dialog.component.css']
})
export class ContianerLiveDialogComponent implements OnInit {

  local_data: any;
  url: SafeResourceUrl;
  containerNumber: any;
  blReferenceNo: any;
  iFrameUrl: string = "";


  constructor(public dialogRef: MatDialogRef<ContianerLiveDialogComponent>, private domSanitizer: DomSanitizer,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: string) {
    this.local_data = data;
    this.url = this.transform(this.local_data.url);
    this.containerNumber = this.local_data.ContainerNumber;
    this.blReferenceNo = this.local_data.BLReferenceNo;
    this.iFrameUrl = `https://shipsgo.com/iframe/where-is-my-container/${this.containerNumber}`;
  }

  ngOnInit(): void {
    this.hello();
  }

  transform(url) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  hello() {
    if (window.addEventListener)
      window.addEventListener("message", ShipsgoMessagesListener);

    function ShipsgoMessagesListener(event) {
      if (event.data.Action === "LoadNewContainerCode") {
        <HTMLInputElement><unknown>document.getElementById("IframeShipsgoLiveMap").setAttribute('src', `https://shipsgo.com/iframe/where-is-my-container/${event.data.Parameters.ContainerCode}`);
      }
    }

    var urlParams = new URLSearchParams(window.location.search);
    var defaultQuery = urlParams.get('query');

    if (defaultQuery === undefined || defaultQuery === null)
      defaultQuery = `https://shipsgo.com/iframe/where-is-my-container/${this.containerNumber}`;

    <HTMLInputElement><unknown>document.getElementById("IframeShipsgoLiveMap").setAttribute('src', `${defaultQuery}`)
  }

}