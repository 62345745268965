import { Options } from '@angular-slider/ngx-slider';
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { ClientService } from 'src/app/helpers/client-service.service';
import { SocketService } from 'src/app/helpers/socket.service';
import Swal from 'sweetalert2';
import { VesselDetailsBoxComponent } from './vessel-details-box/vessel-details-box.component';

export interface carrier {
  name: string;
  selected: boolean;
}

export interface VesselShedule {
  carrier: object;
  por: object;
  fnd: object;
  route: Array<object>
}

@Component({
  selector: 'app-client-vessel-schedule',
  templateUrl: './client-vessel-schedule.component.html',
  styleUrls: ['./client-vessel-schedule.component.css']
})
export class ClientVesselScheduleComponent implements OnInit {
  org: any = '';
  des: any = '';
  page: number = 0;
  size: number = 25;
  dataSource!: MatTableDataSource<any>;
  selectedOrigin: string;
  selectedDestination: string;
  VesselShedule: VesselShedule[];
  displayedColumns: string[] = ['transitTime', 'carrier', 'cutOff', 'departure', 'arrival', 'serviceVessel', 'action'];
  objBool: Boolean = false;
  checked: boolean = true;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataArray: Array<any> = [];
  showTable: boolean = false;
  dataArrayFilter: Array<any>;
  hidebtn: Boolean = false;
  todayDate: Date = new Date();
  @Input('matMenuTriggerData') carrierList: carrier[] = [];
  displayMsg: string;
  nodataMsg: Boolean = false;
  directVessels: any;
  minValue: number = 0;
  maxValue: number = 0;
  value: number = 0;
  highvalue: number = 0;
  options: Options = {
    floor: 0,
    ceil: 100
  };
  validCutOffDates: Array<any> = [];
  validDepartureDates: Array<any> = [];
  validArrivalDates: Array<any> = [];
  cutOff: Array<any> = [];
  departureDate: Array<any> = [];
  arrivalDate: Array<any> = [];
  carriers: Array<any> = [];
  transitEvent: any;
  CLOSE_ON_SELECTED = false;
  selectedRange: string;
  transit: Boolean = false;

  CutOffFilter = (d: Date): boolean => {
    let enableFlag = false;
    this.validCutOffDates.some((date) => {
      if (date.Vdate != null) {
        let validDate = this.datepipe.transform(date.Vdate, 'yyyy-MM-dd')
        let dates = this.datepipe.transform(d, 'yyyy-MM-dd')
        if (validDate == dates) {
          enableFlag = true;
        }
      }
    })
    return enableFlag;
  }

  DepartureFilter = (d: Date): boolean => {
    let enableFlag = false;
    this.validDepartureDates.some((date) => {
      if (date.Vdate != null) {
        let validDate = this.datepipe.transform(date.Vdate, 'yyyy-MM-dd')
        let dates = this.datepipe.transform(d, 'yyyy-MM-dd')
        if (validDate == dates) {
          enableFlag = true;
        }
      }
    })
    return enableFlag;
  }

  ArrivalFilter = (d: Date): boolean => {
    let enableFlag = false;
    this.validArrivalDates.some((date) => {
      if (date.Vdate != null) {
        let validDate = this.datepipe.transform(date.Vdate, 'yyyy-MM-dd')
        let dates = this.datepipe.transform(d, 'yyyy-MM-dd')
        if (validDate == dates) {
          enableFlag = true;
        }
      }
    })
    return enableFlag;
  }
  //class for selected value in calender
  dateCutoffClass = (date: Date) => {
    if (this.findcutoffDate(date) !== -1) {
      return ['selected'];
    }
    return [];
  }
  dateDepartureClass = (date: Date) => {
    if (this.findDepartureDate(date) !== -1) {
      return ['selected'];
    }
    return [];
  }
  dateArrivalClass = (date: Date) => {
    if (this.findArrivalDate(date) !== -1) {
      return ['selected'];
    }
    return [];
  }

  CarrierHeading: any
  CarrierBody: any

  constructor(private dialog: MatDialog, public datepipe: DatePipe, private clientService: ClientService, private fb: FormBuilder, private router: Router, private route: ActivatedRoute, private socketService: SocketService) {
  }


  ngOnInit(): void {
    this.org = [];
    this.des = [];
    this.selectedOrigin = '';
    this.selectedDestination = '';
  }

  onKeyUpEventOrg(event) {
    const checkOrg = this.org.find(org => org.fullName === this.selectedOrigin)
    if (!checkOrg) {
      this.clientService.getLocation(event.target.value).subscribe(
        (response) => {
          this.org = [];
          for (let index = 0; index < response.length; index++) {
            this.org.push(response[index]);
          }
        },
        (error) => {
          console.log(error);
        },
        () => { },
      )
    }
  }

  onKeyUpEventDes(event) {
    const checkDes = this.des.find(des => des.fullName === this.selectedDestination)
    if (!checkDes) {
      this.clientService.getLocation(event.target.value).subscribe(
        (response) => {
          this.des = [];
          for (let index = 0; index < response.length; index++) {
            this.des.push(response[index]);
          }
        },
        (error) => {
          console.log(error);
        },
        () => { },
      )
    }
  }

  searchDetails() {
    let searchDetails: Array<any> = [];
    const orgVal = this.org.find(org => org.fullName === this.selectedOrigin);
    const desVal = this.des.find(des => des.fullName === this.selectedDestination);
    this.VesselShedule = []
    this.carrierList = [];
    if (orgVal.locationID != undefined || '' && desVal.locationID != undefined || '') {
      this.clientService.getDetailsOfSailing(orgVal.locationID, desVal.locationID).subscribe(
        (response) => {
          console.log(response);
          searchDetails = response.routeGroupsList;
          if (searchDetails.length != 0) {
            searchDetails.forEach(listItem => {
              listItem.route.forEach(eachRoute => {
                this.VesselShedule.push({
                  'carrier': listItem.carrier,
                  'fnd': listItem.fnd,
                  'por': listItem.por,
                  'route': eachRoute
                });
                this.validCutOffDates.push({ "Vdate": eachRoute.defaultCutoff.cutoffTime });
                this.validDepartureDates.push({ "Vdate": eachRoute.por.etd });
                this.validArrivalDates.push({ "Vdate": eachRoute.fnd.eta });
              });
              //get carrier list
              this.carrierList.push({ 'name': listItem.carrier.name, 'selected': false });
            });
            if (this.VesselShedule.length == 0) {
              this.showTable = false;
            } else {
              this.showTable = true;
              this.resizeTable();
            }
            this.dataArray = this.VesselShedule;
            this.dataSource = new MatTableDataSource(this.dataArray);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.value = Math.min.apply(null, this.dataArray.map(function (a) { return a.route.transitTime; }));
            this.highvalue = Math.max.apply(Math, this.dataArray.map(function (a) { return a.route.transitTime; }));
            this.minValue = this.value
            this.maxValue = this.highvalue
          } else {
            this.nodataMsg = true;
          }
        },
        (error) => {
          this.objBool = false;
          console.log(error);
          Swal.fire({
            title: "success",
            text: "Something is Wrong",
            timer: 3000
          })
        },
      );
    } else {
      Swal.fire({
        title: 'Alert',
        text: 'Check selected origin & destination',
        timer: 2000
      })
    }

  }

  openDialog(data) {
    const dialogRef = this.dialog.open(VesselDetailsBoxComponent, {
      width: '100%;',
      data: data
    })
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
    })
  }

  filterData() {
    this.hidebtn = true;
    this.dataArrayFilter = [...this.dataArray];
    this.dataArrayFilter = this.filterCarriers(this.dataArrayFilter);
    this.dataArrayFilter = this.getCutOff(this.dataArrayFilter);
    this.dataArrayFilter = this.getDeparture(this.dataArrayFilter);
    this.dataArrayFilter = this.getArrival(this.dataArrayFilter);
    this.dataArrayFilter = this.filterTransit(this.dataArrayFilter);
    this.dataArrayFilter = this.directVessel(this.dataArrayFilter);
    console.log('filrerdData', this.dataArrayFilter);
    this.dataSource = new MatTableDataSource(this.dataArrayFilter);
    this.dataSource.sort = this.sort;
    // this.dataSource.paginator = this.paginator;
  }

  dateChanged(event: MatDatepickerInputEvent<Date>, type): void {
    if (type == 'cutOff') {
      if (event.value) {
        const date = event.value;
        const index = this.findcutoffDate(date);
        if (index === -1) {
          this.cutOff.push(date);
        } else {
          this.cutOff.splice(index, 1)
        }
      }
    } else if (type == 'departure') {
      if (event.value) {
        const date = event.value;
        const index = this.findDepartureDate(date);
        if (index === -1) {
          this.departureDate.push(date);
        } else {
          this.departureDate.splice(index, 1)
        }
      }
    } else if (type == 'arrival') {
      if (event.value) {
        const date = event.value;
        const index = this.findArrivalDate(date);
        if (index === -1) {
          this.arrivalDate.push(date);
        } else {
          this.arrivalDate.splice(index, 1)
        }
      }
    } else {
      console.log('no type Matching..');
    }

    this.filterData();
  }

  remove(date: Date, type): void {
    if (type == 'cutOff') {
      const index = this.findcutoffDate(date);
      this.cutOff.splice(index, 1);
    } else if (type == 'departure') {
      const index = this.findDepartureDate(date);
      this.departureDate.splice(index, 1);
    } else if (type == 'arrival') {
      const index = this.findArrivalDate(date);
      this.arrivalDate.splice(index, 1);
    } else {
      console.log('no type Matching..');
    }
    this.filterData();
  }

  findcutoffDate(date: Date): number {
    return this.cutOff.map((m) => +m).indexOf(+date);
  }
  findDepartureDate(date: Date): number {
    return this.departureDate.map((m) => +m).indexOf(+date);
  }

  findArrivalDate(date: Date): number {
    return this.arrivalDate.map((m) => +m).indexOf(+date);
  }
  findCarrier(value: string): number {
    return this.carriers.map((m) => +m).indexOf(+value);
  }

  checkedCarries(event) {
    console.log(event);
    this.carriers = [];
    for (let index = 0; index < this.carrierList.length; index++) {
      if (this.carrierList[index].selected == true) {
        this.carriers.push(this.carrierList[index].name)
      }
    }
    this.filterData();
  }

  removeCarrier(value): void {
    const index = this.carriers.indexOf(value);
    if (index >= 0) {
      this.carriers.splice(index, 1);
      this.carrierList.forEach(a => {
        if (a.name == value) {
          a.selected = false;
        }
      })
    }
    this.filterData();
  }

  filterCarriers(data) {
    if (this.carriers.length >= 1) {
      let dataArr = []
      for (var arr in data) {
        for (var filter in this.carrierList) {
          if (data[arr].carrier.name == this.carrierList[filter].name && this.carrierList[filter].selected == true) {
            dataArr.push(data[arr]);
          }
        }
      }
      // enable dates
      this.validCutOffDates = [];
      this.validDepartureDates = [];
      this.validArrivalDates = [];
      for (let index = 0; index < dataArr.length; index++) {
        this.validDepartureDates.push({ "Vdate": dataArr[index].route.por.etd });
        this.validArrivalDates.push({ "Vdate": dataArr[index].route.fnd.eta });
        this.validCutOffDates.push({ "Vdate": dataArr[index].route.defaultCutoff.cutoffTime });
      }

      return dataArr;
    } else {
      return data;
    }
  }

  // Filteration date
  getCutOff(data) {
    if (this.cutOff.length >= 1) {
      let dataArr = [];
      for (var arr in data) {
        for (var filter in this.cutOff) {
          if (data[arr].route.defaultCutoff.cutoffTime != null) {
            let validDate = this.datepipe.transform(data[arr].route.defaultCutoff.cutoffTime, 'yyyy-MM-dd')
            let selectedDates = this.datepipe.transform(this.cutOff[filter], 'yyyy-MM-dd')
            if (validDate == selectedDates) {
              dataArr.push(data[arr]);
            }
          }
        }
      }
      // enable dates
      this.validDepartureDates = [];
      this.validArrivalDates = [];
      for (let index = 0; index < dataArr.length; index++) {
        this.validDepartureDates.push({ "Vdate": dataArr[index].route.por.etd });
        this.validArrivalDates.push({ "Vdate": dataArr[index].route.fnd.eta });
      }

      return dataArr;
    } else {
      return data;
    }
  }

  getDeparture(data) {
    if (this.departureDate.length >= 1) {
      let dataArr = []
      for (var arr in data) {
        for (var filter in this.departureDate) {
          if (data[arr].route.por.etd != null) {
            let validDate = this.datepipe.transform(data[arr].route.por.etd, 'yyyy-MM-dd')
            let selectedDates = this.datepipe.transform(this.departureDate[filter], 'yyyy-MM-dd')
            if (validDate == selectedDates) {
              dataArr.push(data[arr]);
            }
          }
        }
      }
      // enable dates
      this.validCutOffDates = [];
      this.validArrivalDates = [];
      for (let index = 0; index < dataArr.length; index++) {
        this.validCutOffDates.push({ "Vdate": dataArr[index].route.defaultCutoff.cutoffTime });
        this.validArrivalDates.push({ "Vdate": dataArr[index].route.fnd.eta });
      }
      return dataArr;
    } else {
      return data;
    }
  }

  getArrival(data) {
    if (this.arrivalDate.length >= 1) {
      let dataArr = [];
      for (var arr in data) {
        for (var filter in this.arrivalDate) {
          if (data[arr].route.fnd.eta != null) {
            let validDate = this.datepipe.transform(data[arr].route.fnd.eta, 'yyyy-MM-dd')
            let selectedDates = this.datepipe.transform(this.arrivalDate[filter], 'yyyy-MM-dd')
            if (validDate == selectedDates) {
              dataArr.push(data[arr]);
            }
          }
        }
      }
      // enable dates
      this.validCutOffDates = [];
      this.validDepartureDates = [];
      for (let index = 0; index < dataArr.length; index++) {
        this.validCutOffDates.push({ "Vdate": dataArr[index].route.defaultCutoff.cutoffTime });
        this.validDepartureDates.push({ "Vdate": dataArr[index].route.por.etd });
      }
      return dataArr;
    } else {
      return data;
    }
  }

  directShipment(event) {
    this.directVessels = event;
    this.filterData();
  }
  directVessel(data) {
    if (this.directVessels != undefined) {
      if (this.directVessels.checked) {
        data = data.filter(x => x.route.direct == this.directVessels.checked);
      }
      else {
        data = data.filter(x => x.route.direct == this.directVessels.checked);
      }
      // enable dates
      this.validCutOffDates = [];
      this.validDepartureDates = [];
      this.validArrivalDates = [];
      for (let index = 0; index < data.length; index++) {
        this.validCutOffDates.push({ "Vdate": data[index].route.defaultCutoff.cutoffTime });
        this.validDepartureDates.push({ "Vdate": data[index].route.por.etd });
        this.validArrivalDates.push({ "Vdate": data[index].route.fnd.eta });
      }
      return data;
    } else {
      return data;
    }

  }

  transitDays(event) {
    this.transit = true;
    this.transitEvent = event;
    this.filterData();
  }

  filterTransit(data) {
    if (this.transitEvent != undefined) {
      let value = this.transitEvent.value;
      let highvalue = this.transitEvent.highValue;
      this.selectedRange = 'Min ' + value + ' - Max ' + highvalue;
      data = data.filter(function (a) {
        return a.route.transitTime >= value && a.route.transitTime <= highvalue
      });
      // enable dates
      this.validCutOffDates = [];
      this.validDepartureDates = [];
      this.validArrivalDates = [];
      for (let index = 0; index < data.length; index++) {
        this.validCutOffDates.push({ "Vdate": data[index].route.defaultCutoff.cutoffTime });
        this.validDepartureDates.push({ "Vdate": data[index].route.por.etd });
        this.validArrivalDates.push({ "Vdate": data[index].route.fnd.eta });
      }
      return data;
    } else {
      return data;
    }
  }

  resetFilteration() {
    this.carrierList.forEach(a => {
      a.selected = false;
    });
    this.carriers = [];
    this.cutOff = [];
    this.departureDate = [];
    this.arrivalDate = [];
    this.dataArrayFilter = [...this.dataArray];
    this.dataSource = new MatTableDataSource(this.dataArrayFilter);
    this.dataSource.sort = this.sort;
    // this.dataSource.paginator = this.paginator;
    this.minValue = Math.min.apply(null, this.dataArray.map(function (a) { return a.route.transitTime; }));
    this.maxValue = Math.max.apply(Math, this.dataArray.map(function (a) { return a.route.transitTime; }));
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  resizeTable() {
    let table = document.getElementById('dataTable')
    console.log(table.className);

    if (this.showTable) {
      table.classList.remove('col-md-12')
      table.classList.add('col-md-8')
    } else {
      table.classList.remove('col-md-8')
      table.classList.add('col-md-12')
    }

  }
}
