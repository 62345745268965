<header class="box-absolute d-flex align-items-center topline text-white">
  <app-sidebar></app-sidebar>
  <div class="page-title-center mt-4">
    <div class="text-center">
      <h1>Manage Extension</h1>
    </div>
  </div>
  <div class="breadcrumb-div mt-3">
      <ul class="breadcrumb p-0 m-0">
          <li class="breadcrumb-item 3" id="0"><a [routerLink]="['/v1/dashboard']">Dashboard</a></li>
          <li class="breadcrumb-item 3" id="1"><a>Admin</a></li>
          <li class="breadcrumb-item 3" id="1"><a>Manage Extension</a></li>
      </ul>
  </div>
</header>
<!--header end-->
<section class="banner text-center text-white d-flex" id="bnner">
  <div class="container position-relative">
    <div class="row justify-content-center">
      <div class="col-lg-12">
      </div>
    </div>
  </div>
</section>
<!--request start-->
<section class="request-demo-wrapper pb-10">
  <div class="container shadow bg-white" (click)="hideSideBar()">
    <div class="row">
      <div class="col-lg-12">
        <div class="box">
          <form>
            <div class="btn-group d-flex gap-2 pb-3 pb-lg-4 px-3 px-lg-4">
              <div class="input-group">
                <input placeholder="Search" class="form-control rounded-3 px-4 serchbox" type="text" #input
                  (keyup)="applyFilter($event.target.value)">
                <button class="input-group-btn end-0" type="button"><i class="fa fa-search"></i>
                </button>
              </div>
              <button mat-button mat-flat-button color="primary" class="rounded-3 flex-shrink-0"
                [routerLink]="['/v1/buy-subscription']" routerLinkActive="router-link-active"><i
                  class="fa fa-plus me-2"></i><span> Subscription</span></button>
            </div>

            <div class="table-responsive">
              <table mat-table [dataSource]="dataSource" matSort
                class="table table-striped table-footer-dark table-borderless">

                <ng-container matColumnDef="client.id">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-white"> Client Id</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.client.id }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="client.pocFirstName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-white"> Client Name</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.client.pocFirstName + ' ' + element.client.pocLastName }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-white"> Subscription Id</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.id }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="subscriptionType">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-white"> Subscription Type</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.subscriptionType }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="licenseType">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-white"> License Type</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.licenseType }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="softExpiryDate">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-white"> Soft Expiry Date</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.softExpiryDate | date}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="hardExpiryDate">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-white"> Hard Expiry Date</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.hardExpiryDate | date}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef colspan="3" class="text-white">Actions </th>
                  <td mat-cell *matCellDef="let element" class="action-link">
                    <button class="btn-outline-success fas fa-edit"
                      [routerLink]="['/v1/buy-subscription/edit/', element.id]"
                      routerLinkActive="router-link-active"></button>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns" class="bg-dark"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell text-center text-danger" colspan="4">No data matching the filter "{{input.value}}"
                  </td>
                </tr>

              </table>
            </div>
            <mat-paginator [pageSizeOptions]="[25, 50, 100]" [length]="subscriptionLength" showFirstLastButtons
              (page)="onPageChange($event)">
            </mat-paginator>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
<!--request end  -->