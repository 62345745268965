<app-header></app-header>
<section class="about-wrapper text-dark py-8" id="about-us">
    <div class="container-fluid p-lg-start-end">
        <div class="row justify-content-center">
            <div class="mat-elevation-z8 table-responsive mt-2">
                <h4 class="text-center pt-2">Trade Agreement</h4>
                <div class="row mt-3">
                    <div class="col-md-4 form-group">
                        <label class="form-label" for="">From Country</label>
                        <select class="form-control form-select" [(ngModel)]="firstCountry">
                            <option value="" disabled selected>Select Country</option>
                            <option *ngFor="let country of countryList" [value]="country.id">
                                {{ country.name }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-4 form-group">
                        <label class="form-label" for=""> To Country</label>
                        <select class="form-control form-select" [(ngModel)]="secondCountry">
                            <option value="" disabled selected>Select Country</option>
                            <option *ngFor="let country of countryList" [value]="country.id">
                                {{ country.name }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-2">
                        <button class="btn btn-primary mt-4" (click)="filterTradeAgreements()">Filter</button>
                    </div>
                </div>

                <table class="table table-borderless table-striped table-footer-dark table-head-dark mt-5"
                    matSort mat-table [dataSource]="dataSource">
                    
                    <ng-container matColumnDef="tradeAgreementLink">
                        <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header>Agreement Link </th>
                        <td mat-cell *matCellDef="let row">
                            <a target="_blank" href="{{row.tradeAgreementLink}}">{{row.tradeAgreementLink}}</a>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="tradeAgreementName">
                        <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header> Trade Agreement Name </th>
                        <td mat-cell *matCellDef="let row"> {{row.tradeAgreementName}} </td>
                    </ng-container>


                    <ng-container matColumnDef="description">
                        <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
                        <td mat-cell *matCellDef="let row"> {{row.description}} </td>
                    </ng-container>


                    <ng-container matColumnDef="countries">
                        <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header> Countries </th>
                        <td mat-cell *matCellDef="let row">
                            {{ row.countryString }} 
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="artifacts">
                        <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header> Artifacts </th>
                        <td mat-cell *matCellDef="let row">

                            <button *ngFor="let artifact of row.artifacts" class="" title="{{ artifact.label }}" (click)="downloadFileLocal(artifact)">
                                <i class="fa fa-download"></i>
                            </button>
                        </td>
                    </ng-container>

                    <tr class="bg-dark" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[25,50,100]" aria-label="Select page of users"
                    [length]="length" (page)="onPageChange($event)">
                </mat-paginator>
            </div>
        </div>
    </div>
</section>