import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MasterTableService } from 'src/app/helpers/master-table.service';
import Swal from 'sweetalert2';
import { CarrierDialogComponent } from '../carriers/carrier-dialog/carrier-dialog.component';
import { ShippingLineDialogComponent } from './shipping-line-dialog/shipping-line-dialog.component';

@Component({
  selector: 'app-shipping-line',
  templateUrl: './shipping-line.component.html',
  styleUrls: ['./shipping-line.component.css']
})
export class ShippingLineComponent implements OnInit {

  page: number = 0;
  size: number = 25;
  displayedColumns: string[] = ['id', 'value', 'action'];
  dataSource = new MatTableDataSource();
  serchFilter: Boolean = false;
  nodata: Boolean = true;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  carrierLength: number;

  sessionStorageUserData: any
  disableCreateBtn: boolean = true
  disableEditBtn: boolean = true
  disableDeleteBtn: boolean = true

  constructor(private masterService: MasterTableService, public dialog: MatDialog) { }


  ngOnInit(): void {
    this.getAllShippingLineList();
    this.getCurrentUserDataFromSessionStorage();
  }

  getAllShippingLineList() {
    this.masterService.getShippingLinePageable(this.page, this.size).subscribe(
      (response) => {
        console.log(response);
        if (response.content != null) {
          this.dataSource = new MatTableDataSource(response.content);
          this.dataSource.sort = this.sort;
          this.carrierLength = response.totalElements
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  deleteShippingLine(id) {
    this.masterService.deleteShippingLine(id).subscribe(
      (response) => {
        if (response.code == 201) {
          Swal.fire({
            titleText: "Success",
            icon: "success",
            text: response.message,
            timer: 5000
          })
          this.getAllShippingLineList();
        }
      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      },
    )
  }

  updateShippingLine(id, data) {
    this.masterService.updateShippingLine(id, data).subscribe(
      (response) => {
        if (response.code == 201) {
          Swal.fire({
            titleText: "Success",
            icon: "success",
            text: response.message,
            timer: 5000
          })
          this.getAllShippingLineList();
        }
      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      },
    )
  }

  addShippingLine(data) {
    this.masterService.addShippingLine(data).subscribe(
      (response) => {
        if (response.code == 201) {
          Swal.fire({
            titleText: "Success",
            icon: "success",
            text: response.message,
            timer: 5000
          })
          this.getAllShippingLineList();
        }
      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode) {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: _error.errorMessage,
            timer: 5000
          })
        }
      },
    )
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.serchFilter = true;
    this.nodata = false;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  onPageChange(event: PageEvent) {
    this.size = event.pageSize;
    this.page = event.pageIndex;
    this.getAllShippingLineList();
  }

  openDialog(action, obj) {
    obj.action = action;
    const dialogRef = this.dialog.open(ShippingLineDialogComponent, {
      width: '250px',
      data: obj
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.event == 'Create') {
        this.addShippingLine(result.data);
      }
      else if (result.event == 'Update') {
        this.updateShippingLine(result.data.id, result.data);
      }
      else if (result.event == 'Delete') {
        this.deleteShippingLine(result.data.id);
      }
    });
  }

  getCurrentUserDataFromSessionStorage(){
    this.sessionStorageUserData = JSON.parse(sessionStorage.getItem('userData'));
    console.log('this is user data ', this.sessionStorageUserData);
    for (let index = 0; index < this.sessionStorageUserData.authorities.length; index++) {
      const authority = this.sessionStorageUserData.authorities[index];
      console.log(authority);
      if(authority == 'RB0000233'){
        console.log('create authority is given');
        this.disableCreateBtn = false
      } else if(authority == 'RB0000235'){
        console.log('update authority is given');
        this.disableEditBtn = false
      } else if(authority == 'RB0000234') {
        console.log('delete authority is given');
        this.disableDeleteBtn = false
      }
    }
  }

}
