import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-terms-condition',
  templateUrl: './client-terms-condition.component.html',
  styleUrls: ['./client-terms-condition.component.css']
})
export class ClientTermsConditionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.gotoTop();
  }

  //fuction to scroll to top on next button click
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

}
