import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { GranularSanityChecks } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InvoiceService } from 'src/app/helpers/invoice.service';
import { MasterTableService } from 'src/app/helpers/master-table.service';
import { ServiceOrderService } from 'src/app/helpers/service-order.service';
import { SocketService } from 'src/app/helpers/socket.service';
import { AlertService } from 'src/app/utils/sweet-alert.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-pre-shipment-invoice',
  templateUrl: './pre-shipment-invoice.component.html',
  styleUrls: ['./pre-shipment-invoice.component.css']
})
export class PreShipmentInvoiceComponent implements OnInit {

  templateId: any = null
  invId: any;
  addressList: Array<any> = [];
  exporterLabel: string
  typeOfPacking: Array<any> = [];
  modeOfShipment: Array<any> = [];
  samePortError: boolean;
  declarationLabel: any;
  notesLabel: any;
  bankDetailLabel: any;
  invoiceTemplateForm: FormGroup;
  additionalInfoFields: Array<any> = [
    {label: 'Project Name', value: ''},
    {label: 'Project Number', value: ''},
    {label: 'Bill Of Lading No', value: ''},
    {label: 'Shipping Bill No', value: ''}
  ];
  additionalBankFields: Array<any> = [
    {label: 'Agent', value: ''},
    {label: 'Commission', value: ''},
    {label: 'Bank', value: ''}
  ];
  isEdit: Boolean = false;
  countryCodes: Array<any> = [];
  currency: Array<any> = [];
  templateObject: any = null;
  termsLabel: string = ''
  serviceOrderList: Array<any> = [];
  loadingPorts: Array<any> = [];
  destinationPorts: Array<any> = [];
  searchOriginValue: any;
  searchDestinationValue: any;
  partyLabel: Array<any> = ['Sez Unit At', 'Exporter', 'Applicant', 'Notify']
  uom: Array<any> = [{ name: "Nos" },{ name: "Lot" }];
  unitOfWeight:Array<any> = [{ name: "KG" },{ name: "LBS" },{ name: "MT" }];
  dimensions: Array<any> = [{name: "MM"},{name: "CM"},{name: "IN"},{name: "FT"},{name: "MTR"}];
  invoiceId: any;
  containsInPackagesUOM: Array<any> = [{name: "Nos"}, {name: "Lot"}, {name: "SET"}];
  partyTypes: Array<string> = ['EXPORTER','APPLICANT','SEZ_UNIT_AT','NOTIFY'];

  constructor(private fb: FormBuilder, private invoiceService: InvoiceService, private route: ActivatedRoute, private masterTableService: MasterTableService, 
    private alertService: AlertService, private socketService: SocketService, private serviceOrder: ServiceOrderService, private router: Router) { }

  ngOnInit(): void {
    this.getMasterTablesSessionStorageData();
    this.invoiceTemplateForm = this.fb.group({
      additionalInformation: this.fb.array([]),
      bankDetail: this.fb.array([]),
      containerNo: [''],
      countryOfFinalDestination: [''],
      countryOfOriginOfGoods: [''],
      descriptionOfSupplies: [''],
      kindOfPackages: [''],
      netWeight: [''],
      notes: [''],
      otherRef: this.fb.array([]),
      projectName: [''],
      projectCode:[''],
      shippingMarks: this.fb.group({
          caseNumber:[''],
          // pointOfDischarge:[''],
          shippingFrom:[''],
          shippingTo:[''],
          grossWeight:[''],
          netWeight:[''],
      }),
      // status: [''],
      invoiceTemplate: this.fb.group({
        id: ['']
      }),
      parties: this.fb.array([]),
      buyersReference: [''],
      vesselVoyageFlightDetails: [''],
      terms: this.fb.array([]),
      preCarriageBy: [''],
      portOfLoading: [''],
      portOfDischarge: [''],
      placeOfReceiptByPreCarrier: [''],
      placeOfDelivery: [''],
      packingList: this.fb.group({
        unitOfWeight: [''],
        packingListRows: this.fb.array([]),
        grossWeightTotal: [''],
        netWeightTotal: ['']
      }),
      orderValue: [''],
      invoiceLine: this.fb.array([]),
      exportersReference: [''],
      declaration: [''], 
      currency: [''],
      annexurePackingList: this.fb.group({
        unitOfWeight:[''],
				dimensionsOfPackage:[''],
				cbmTotal:[''],
				quantityTotal:[''],
				netWeightTotal:[''],
				grossWeightTotal:[''],
        annexurePakingListRows: this.fb.array([])
      }),
      serviceOrder: this.fb.group({
        id: ['', Validators.required]  
      }),
      number:  [],
      totalAmount: ['0'], // not at backend. left to map at html
      modeOfTransport: [null]
    });
    //routing section
    let type = this.route.snapshot.paramMap.get('type');
    let id = this.route.snapshot.paramMap.get('id');
    // Create
    if (type == 'PreShipment') {
      if (id != null) {
        this.getTemplateById(id);
      }
    }
    // Edit
    else if(type == null && id != null){
      this.invoiceId = id;
      this.getPreShipmentInvoiceById(id);
    }
  }

  get invoice() { return this.invoiceTemplateForm.controls; }
  get otherRef() { return this.invoice.otherRef as FormArray;}
  get parties() { return this.invoice.parties as FormArray;}
  get additionalInformation() {return this.invoice.additionalInformation  as FormArray;}
  get bankDetail() { return this.invoice.bankDetail as FormArray;  }

  get packingList() { return this.invoice.packingList as FormGroup; }
  get packingListRows() { return this.packingList.get('packingListRows') as FormArray; }

  get invoiceLine(){return this.invoice.invoiceLine as FormArray; }

  get annexurePackingList(){return this.invoice.annexurePackingList as FormGroup; }
  get annexurePackingListRow() { return this.annexurePackingList.get('annexurePakingListRows') as FormArray; }

  get terms() { return this.invoice.terms as FormArray; }
  get shippingMarks(){return this.invoice.shippingMarks as FormGroup; }

  get serviceOrderForm(){return this.invoice.serviceOrder as FormGroup; }

  containtsInPackages(index: number){ return this.annexurePackingListRow.at(index).get('containtsInPackages') as FormArray; }

  // get notify(){ return this.invoice.notify1 as FormArray; }


  getUserAddresses() {
    this.invoiceService.getUserAddressDetails().subscribe(
      (response) => {
        this.addressList = response;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  initAddress() {
    return this.fb.group({
      city: [''],
      contactNumber: this.fb.array([]),
      country: this.fb.group({
        id: ['']
      }),
      first: [''],
      label: [''],
      last: [''],
      state: [''],
      street1: [''],
      street2: [''],
      title: [''],
      zipCode: [''],
    })
  }

  initAnnexurePakingListRow(){
    return this.fb.group({
      packageNumber:[''],
      length:[''],
      width:[''],
      height:[''],
      cbm:[''],
      typeOfPacking:[''],
      netWeight:[''],
      grossWeight:[''],
      containtsInPackages: this.fb.array([this.initContaintsInPackages()])
    });
  }

  addAnnexurePakingListRow(){
    this.annexurePackingListRow.push(this.initAnnexurePakingListRow());
  }
  removeAnnexurePakingListRow(index){
    this.annexurePackingListRow.removeAt(index);
  }


  initContaintsInPackages(){
    return this.fb.group({
      description:[''],
      tagNo:[''],
      uom:[''],
      quantity:[''],
    })
  }

  addContaintsInPackage(index){
    return this.containtsInPackages(index).push(this.initContaintsInPackages());
  }

  removeContainsInPackage(index, p){
    return this.containtsInPackages(index).removeAt(p);
  }

  // to show selected currecy in package detail table
  currencyValue() {
    return this.invoice.currency.value
  }

  initInvoiceLine(){
    return this.fb.group({
      amount:['0'],
			packageNumber:[''],
      descriptionOfGoods:[''],
      hsCode:[''],
      noOfPackages:['0'],
      tagNumber:[''],
      uom:[''],
    })
  }

  addInvoiceLine(){
    this.addPackingListRow();
    this.addAnnexurePakingListRow();
    return this.invoiceLine.push(this.initInvoiceLine());
  }

  removeInvoiceLine(index: number){
    this.removePackingListRow(index);
    this.removeAnnexurePakingListRow(index);
    this.invoiceLine.removeAt(index);
  }

  initPackingListRow(){
    return this.fb.group({
      packageNumber: [''],
      description: [''],
      tagNumber: [''],
      hsCode: [''],
      quantityPackages: [''],
      netWeight: [''],
      grossWeight: [''],
    })
  }

  addPackingListRow(){
    return this.packingListRows.push(this.initPackingListRow());
  }
  removePackingListRow(index){
    this.packingListRows.removeAt(index);
  }

  initOtherReference(){
    return this.fb.group({
      otherReference: ['']
    })
  }

  addserviceOrder(){
    return this.fb.group({
      id: ['']
    })
  }

  initShippingData(){
    return this.fb.group({
      dueDate: [''],
      salesRep: [''],
      shipDate: [''],
      shipNumber: [''],
      shipVia: [''],
      terms: ['']
    })
  }

  //for adding alternate other reference
  addAlternateOtherRef() {
    let newRef = this.fb.group({
      otherReference: [''],
    })
    this.otherRef.push(newRef);
  }

  removeAlternateOtherRef(i: number) {
    this.otherRef.removeAt(i);
  }

  //  //to show default one party
   defaultOneParty() {
    return this.fb.group({
      partyType: '',
      label: '',
      name: '',
      email: '',
      contactNumbers: this.fb.array([this.defaultOneContactNo()]),
      address: this.fb.group({
        street1: [''],
        street2: [''],
        city: [''],
        state: [''],
        country: this.fb.group({
          id: ['']
        }),
        zipCode: [''],
        label: [''],
      })
    });
  }


  // to remove contact number
  removeContactNo(index: number, ind: number) {
    this.partyContactNo(index).removeAt(ind)
  }

  //to add new party details
  addNewParty() {
    this.parties.push(this.defaultOneParty())
  }

  // to remove any party details
  removeParty(i: number) {
    this.parties.removeAt(i);
  }

  defaultOneContactNo() {
    return this.fb.group({
      contacts: ['']
    })
  }

  // to patch selected address to address section
  selectAddress(event: any, i: number) {
    let selectedAddressId = event.target.value
    this.addressList.filter((address) => {
      if (address.id == selectedAddressId) {
        let addressGroup = this.parties.at(i).get('address') as FormGroup;
        addressGroup.get('street1').patchValue(address.street1)
        addressGroup.get('street2').patchValue(address.street2)
        addressGroup.get('city').patchValue(address.city)
        addressGroup.get('state').patchValue(address.state)
        addressGroup.get('country').get('id').patchValue(address.country.id)
        addressGroup.get('zipCode').patchValue(address.zipCode)
      }
    })
  }

  // fuction to get contact number as formarray
  partyContactNo(index: number): FormArray {
    return this.parties.at(index).get("contactNumbers") as FormArray
  }

  addNewContactNo(partyInd: number) {
    this.partyContactNo(partyInd).push(this.defaultOneContactNo())
  }

  selectLoadingPort() {
    let lPort = this.invoice.portOfLoading.value
    let dPort = this.invoice.portOfDischarge.value
    if (lPort == dPort) {
      this.samePortError = true;    
      Swal.fire({
        title: "Warning",
        icon: "warning",
        timer: 5000,
        text: "Loading & Destination Port cannot be same"
      })
    } else {
      this.samePortError = false
    }
  }

  //to get template by id to create invoice
  getTemplateById(id) {
    this.invoiceService.getTemplateById(id).subscribe
      ((response) => {
        this.templateObject = response;
        this.invoice.invoiceTemplate.get('id').patchValue(this.templateObject.id);
        console.log(this.templateObject);
        
        if (response.buyersRefRequired == true) {
          this.invoice.buyersReference.patchValue(response.buyersRef)
        }

        if (response.exporterRefRequired == true) {
          this.exporterLabel = response.exporterRefLabel
          this.invoice.exportersReference.patchValue(response.exporterRef)
        }

        if (response.otherRef.length > 0) {
          for (let index = 0; index < response.otherRef.length; index++) {
            this.addAlternateOtherRef();
            const otherRef = response.otherRef[index];
            this.otherRef.at(index).get('otherReference').patchValue(otherRef.otherReference)
          }
        }

        if (response.parties.length > 0) {
          for (let index = 0; index < response.parties.length; index++) {
            this.addNewParty();
            const party = response.parties[index];
            this.parties.at(index).get('partyType').patchValue(party.partyType)
            this.parties.at(index).get('label').patchValue(party.label)
            this.parties.at(index).get('name').patchValue(party.name)
            this.parties.at(index).get('email').patchValue(party.email)
            if (party.contactNumbers.length > 0) {
              let contact = ((this.invoice.parties as FormArray)
                .at(index).get('contactNumbers') as FormArray)
              contact.clear()
              for (let ind = 0; ind < party.contactNumbers.length; ind++) {
                this.addNewContactNo(index)
                const element = party.contactNumbers[ind];
                ((this.invoice.parties as FormArray)
                  .at(index).get('contactNumbers') as FormArray)
                  .at(ind).get('contacts').patchValue(element.contacts);
              }
            }
            let address = this.parties.at(index).get('address') as FormGroup;
            address.get('street1').patchValue(party.address.street1)
            address.get('street2').patchValue(party.address.street2)
            address.get('city').patchValue(party.address.city)
            address.get('state').patchValue(party.address.state)
            address.get('country').patchValue(party.address.country)
            address.get('zipCode').patchValue(party.address.zipCode)
          }
        }
        else{
          this.addNewParty();
        }

        if (response.countryOfOriginOfGoodsRequired == true) {
          this.invoice.countryOfOriginOfGoods.patchValue(response.countryOfOriginGoods)
        }

        if (response.countryOfFinalDestinationRequired == true) {
          this.invoice.countryOfFinalDestination.patchValue(response.countryOfFinalDestination)
        }

        if (response.preCarriageByRequired == true) {
          this.invoice.preCarriageBy.patchValue(response.preCarriageBy)
        }

        if (response.placeOfReceiptByPreCarrierRequired == true) {
          this.invoice.placeOfReceiptByPreCarrier.patchValue(response.placeOfReceiptByPreCarrier)
        }

        if (response.vesselVoyageFlightDetailsRequired == true) {
          this.invoice.vesselVoyageFlightDetails.patchValue(response.vesselVoyageFlightDetails)
        }

        if(response.modeOfTransportRequired == true){
          this.invoice.modeOfTransport.patchValue(response.modeOfTransport);
        }

        if (response.portOfLoadingRequired == true) {
          this.searchOriginValue = response.portOfLoadingName;
          this.getSearchResultsOrigin();
          this.invoice.portOfLoading.patchValue(response.portOfLoading)
        }

        if (response.portOfDischargeRequired == true) {
          this.searchDestinationValue = response.portOfDischargeName;
          this.getSearchResultDestination();
          this.invoice.portOfDischarge.patchValue(response.portOfDischarge)
        }

        if (response.placeOfDeliveryRequired == true) {
          this.invoice.placeOfDelivery.patchValue(response.placeOfDelivery)
        }

        if (response.currency != '') {
          this.invoice.currency.patchValue(response.currency)
        }

        if (response.containerNoRequired == true) {
          this.invoice.containerNo.patchValue(response.containerNo)
        }

        if (response.kindOfPackagesRequired == true) {
          this.invoice.kindOfPackages.patchValue(response.kindOfPackages)
        }

        if (response.descriptionOfSuppliesRequired == true) {
          this.invoice.descriptionOfSupplies.patchValue(response.descriptionOfSupplies)
        }

        if (response.invoiceRows.length > 0) {
          for (let index = 0; index < response.invoiceRows.length; index++) {
            const packagingDetails = response.invoiceRows[index];
            this.addInvoiceLine();
            this.invoiceLine.at(index).get('descriptionOfGoods').patchValue(packagingDetails.descriptionOfGoods)
            this.invoiceLine.at(index).get('tagNumber').patchValue(packagingDetails.tagNumber)
            this.invoiceLine.at(index).get('hsCode').patchValue(packagingDetails.hsCode)
            this.invoiceLine.at(index).get('packageNumber').patchValue(packagingDetails.packageNumber)
            this.invoiceLine.at(index).get('uom').patchValue(packagingDetails.uom)
            this.invoiceLine.at(index).get('amount').patchValue(packagingDetails.amount)
            this.invoiceLine.at(index).get('noOfPackages').patchValue(packagingDetails.noOfPackages)
            this.populatePackageNumber(index, "descriptionOfGoods");
            this.populatePackageNumber(index, "tagNumber");
            this.populatePackageNumber(index, "hsCode");
            this.populatePackageNumber(index, "packageNumber");
            this.populatePackageNumber(index, "noOfPackages");
          }
          this.calculateTotalAmount();
        }
        else{
          this.addInvoiceLine();
        }

        if (response.shippingMarksRequired == true) {
          if (response.shippingMarks.shippingFromRequired == true) {
            this.shippingMarks.get('shippingFrom').patchValue(response.shippingMarks.shippingFrom)
          }

          if (response.shippingMarks.shippingToRequired == true) {
            this.shippingMarks.get('shippingTo').patchValue(response.shippingMarks.shippingTo)
          }

          if (response.shippingMarks.caseNumberRequired == true) {
            this.shippingMarks.get('caseNumber').patchValue(response.shippingMarks.caseNumber)
          }

          if (response.shippingMarks.grossWeightRequired == true) {
            this.shippingMarks.get('grossWeight').patchValue(response.shippingMarks.grossWeight)
          }

          if (response.shippingMarks.netWeightRequired == true) {
            this.shippingMarks.get('netWeight').patchValue(response.shippingMarks.netWeight)
          }
        }

        // patching addition info
        if (response.additionalInfoRequired == true) {
          for (let index = 0; index < response.additionalInformation.length; index++) {
            const element = response.additionalInformation[index];
            this.additionalInformation.push(this.prePopulateAdditionalInfo(element));
          }
        }

        // check terms required
        if (response.termsRequired == true) {
          this.termsLabel = response.termsLabel;
          if(response.terms.length > 0){
            for (let index = 0; index < response.terms.length; index++) {
              const terms = response.terms[index];
              this.addNewTerms();
              this.terms.at(index).get('label').patchValue(terms.label)
              this.terms.at(index).get('content').patchValue(terms.content)
            }
          }
          else{
            this.addNewTerms();
          }
        }
        else{
          this.addNewTerms();
        }

        if (response.declarationRequired == true) {
          if (response.declaration != '') {
            this.invoice.declaration.patchValue(response.declaration)
          }
        }

        if (response.notesRequired == true) {
          this.invoice.notes.patchValue(response.notes)
        }

        //pre populating bank details
        for (let index = 0; index < response.bankDetail.length; index++) {
          const element = response.bankDetail[index];
          this.bankDetail.push(this.prePopulateBankInfo(element));
        }

        if(response.serviceOrder != null){
          this.serviceOrderForm.get('id').patchValue(response.serviceOrder.id);
        }

        if(response.orderValueRequired){
          this.invoiceTemplateForm.get('orderValue').patchValue(response.orderValue);
        }
      },
        (error) => {
          console.log(error);
        })
  }

  initAdditionalInformation(){
    return this.fb.group({
      label: [''],
      value: ['']
    });
  }

  initBankDetail(){
    return this.fb.group({
      label: [''],
      value: ['']
    });
  }

  prePopulateAdditionalInfo(data){
    return this.fb.group({
      label: [data.label],
      value: [data.value]
    })
  }

  prePopulateBankInfo(data){
    return this.fb.group({
      label: [data.label],
      value: [data.value]
    })
  }

  //adding additional Info fields
  addNewField() {
    this.additionalInformation.push(this.initAdditionalInformation());
  }

  removeAdditionalField(i: number) {
    this.additionalInformation.removeAt(i);
  }

  // used to add new terms
  addNewTerms() {
    let group = this.fb.group({
      label: 'Enter Label',
      content: ''
    });
    this.terms.push(group)
  }
  // used to remove one terms by index
  removeTerms(i: number) {
    this.terms.removeAt(i);
  }

  //adding additional bank Fields
  addNewBankField() {
    this.bankDetail.push(this.initBankDetail());
  }

  removeAdditionalBankFields(i: number) {
    this.bankDetail.removeAt(i);
  }

  getCurrency(){
    this.masterTableService.getAllCurrency().subscribe(
      (response) => {
        this.currency = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  //to get data from mastertabled / session storage
  getMasterTablesSessionStorageData() {
    this.getModeOfShipment();
    this.getCurrency();
    this.getCountry();
    this.getUserAddresses();
    this.getAllServiceOrder();
    this.getTypeOfPacking();
  }
  getCountry(){
    this.masterTableService.getAllCountries().subscribe(
      (response) => {
        this.countryCodes = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getModeOfShipment(){
    this.masterTableService.getModeOfShipment().subscribe(
      (response) => {
        this.modeOfShipment = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getTypeOfPacking(){
    this.masterTableService.getAllTypeOfPacking().subscribe(
      (response) => {
        this.typeOfPacking = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  //to calculate total amount in package details total
  calculateTotalAmount() {
    let totalAmount = 0;
    for (let index = 0; index < this.invoiceLine.controls.length; index++) {
      const item = this.invoiceLine.controls[index];
      let amount = item.get('amount').value
      totalAmount = totalAmount + amount;
    }
    this.invoice.totalAmount.patchValue(totalAmount);
    return totalAmount
  }

  createPreShipment(){
    console.log(this.invoiceTemplateForm.value);
    this.invoiceService.createPreShipmentInvoice(this.invoiceTemplateForm.value).subscribe(
      (response) => {
        console.log(response);
        if(response.code == 201){
          Swal.fire({
            timer: 5000,
            icon: "success",
            title: "Success",
            text: response.message
          });
          this.router.navigate(['v1/pre-shipment-invoice-list'])
        }
      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if(_error.errorCode == 412){
          Swal.fire({
            title:"Error",
            icon: "error",
            text: _error.errorMessage,
            timer: 5000
          });
        }
      },
    )
  }

  updatePreShipment(){
    console.log(this.invoiceTemplateForm.value);
    if(this.invoiceTemplateForm.valid){
      this.invoiceService.updateInvoice(this.invoiceId ,this.invoiceTemplateForm.value).subscribe(
        (response) => {
          console.log(response);
          if(response.code == 201){
            Swal.fire({
              timer: 5000,
              icon: "success",
              title: "Success",
              text: response.message
            });
            this.router.navigate(['v1/pre-shipment-invoice-list'])
          }
        },
        (error) => {
          console.log(error);
        },
      )
    }
    else{
      Swal.fire({
        title: "Fill all mandatory fields",
        icon: "warning",
        timer: 5000
      });
    }
  }

  // Changes will come.
  getAllServiceOrder(){
    this.serviceOrder.getServiceOrders().subscribe(
      (response) => {
        this.serviceOrderList = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  // Fetch Preshipment by ID.
  getPreShipmentInvoiceById(invoiceId){
    this.invoiceService.invoiceById(invoiceId).subscribe(
      (response) => {
        console.log(response);
        if(response != null){
          this.isEdit = true;
          this.templateObject = response.invoiceTemplate;
          this.invoice.invoiceTemplate.get('id').patchValue(this.templateObject.id);
          this.invoice.number.patchValue(response.number);
          this.invoice.buyersReference.patchValue(response.buyersReference)

          this.invoice.exportersReference.patchValue(response.exportersReference);

          let otherRef: Array<any> = response.otherRef;
          for (let index = 0; index < otherRef.length; index++) {
            this.addAlternateOtherRef();
            const _otherRef = otherRef[index];
            this.otherRef.at(index).get('otherReference').patchValue(_otherRef.otherReference);
          }

          if (response.parties.length > 0) {
            for (let index = 0; index < response.parties.length; index++) {
              this.addNewParty();
              const party = response.parties[index];
              this.parties.at(index).get('partyType').patchValue(party.partyType)
              this.parties.at(index).get('label').patchValue(party.label)
              this.parties.at(index).get('name').patchValue(party.name)
              this.parties.at(index).get('email').patchValue(party.email)
              if (party.contactNumbers.length > 0) {
                let contact = ((this.invoice.parties as FormArray)
                  .at(index).get('contactNumbers') as FormArray)
                contact.clear()
                for (let ind = 0; ind < party.contactNumbers.length; ind++) {
                  this.addNewContactNo(index)
                  const element = party.contactNumbers[ind];
                  ((this.invoice.parties as FormArray)
                    .at(index).get('contactNumbers') as FormArray)
                    .at(ind).get('contacts').patchValue(element.contacts);
                }
              }
              let address = this.parties.at(index).get('address') as FormGroup;
              address.get('street1').patchValue(party.address.street1)
              address.get('street2').patchValue(party.address.street2)
              address.get('city').patchValue(party.address.city)
              address.get('state').patchValue(party.address.state)
              address.get('country').patchValue(party.address.country)
              address.get('zipCode').patchValue(party.address.zipCode)
            }
          }
          else{
            this.addNewParty();
          }

          if(response.serviceOrder != null){
            this.serviceOrderForm.get('id').patchValue(response.serviceOrder.id);
          }
  
          this.invoiceTemplateForm.get('orderValue').patchValue(response.orderValue);

          if (this.templateObject.declarationRequired == true) {
            if (response.declaration != '') {
              this.invoice.declaration.patchValue(response.declaration)
            }
          }
          if (this.templateObject.notesRequired == true) {
            this.invoice.notes.patchValue(response.notes)
          }


          // check terms required
          if (this.templateObject.termsRequired == true) {
            this.termsLabel = this.templateObject.termsLabel;
            if(response.terms.length > 0){
              for (let index = 0; index < response.terms.length; index++) {
                const terms = response.terms[index];
                this.addNewTerms();
                this.terms.at(index).get('label').patchValue(terms.label)
                this.terms.at(index).get('content').patchValue(terms.content)
              }
            }
            else{
              this.addNewTerms();
            }
          }

          // patching addition info
          if(response.additionalInformation.length > 0){
            for (let index = 0; index < response.additionalInformation.length; index++) {
              const element = response.additionalInformation[index];
              this.additionalInformation.push(this.prePopulateAdditionalInfo(element));
            }
          }

          if(response.bankDetail.length > 0){
            for (let index = 0; index < response.bankDetail.length; index++) {
              const element = response.bankDetail[index];
              this.bankDetail.push(this.prePopulateBankInfo(element));
            }
          }

          this.invoice.containerNo.patchValue(response.containerNo);
          this.invoice.kindOfPackages.patchValue(response.kindOfPackages);
          this.invoice.descriptionOfSupplies.patchValue(response.descriptionOfSupplies);

          if(response.invoiceLine.length > 0){
            for (let index = 0; index < response.invoiceLine.length; index++) {
              const element = response.invoiceLine[index];
              this.addInvoiceLine();
              this.invoiceLine.at(index).get('amount').patchValue(element.amount);
              this.invoiceLine.at(index).get('descriptionOfGoods').patchValue(element.descriptionOfGoods);
              this.invoiceLine.at(index).get('hsCode').patchValue(element.hsCode);
              this.invoiceLine.at(index).get('packageNumber').patchValue(element.packageNumber);
              this.invoiceLine.at(index).get('uom').patchValue(element.uom);
              this.invoiceLine.at(index).get('tagNumber').patchValue(element.tagNumber);
              this.invoiceLine.at(index).get('noOfPackages').patchValue(element.noOfPackages);
              this.populatePackageNumber(index, "descriptionOfGoods");
              this.populatePackageNumber(index, "tagNumber");
              this.populatePackageNumber(index, "hsCode");
              this.populatePackageNumber(index, "packageNumber");
              this.populatePackageNumber(index, "noOfPackages");
            }
            this.calculateTotalAmount()
          }

          if(response.packingList != null){
            this.packingList.get('unitOfWeight').patchValue(response.packingList.unitOfWeight);
            this.packingList.get('grossWeightTotal').patchValue(response.packingList.grossWeightTotal);
            this.packingList.get('netWeightTotal').patchValue(response.packingList.netWeightTotal);
          }
          if(response.packingList.packingListRows.length > 0){
            for (let index = 0; index < response.packingList.packingListRows.length; index++) {
              const element = response.packingList.packingListRows[index];
              this.packingListRows.at(index).get('netWeight').patchValue(element.netWeight);
              this.packingListRows.at(index).get('grossWeight').patchValue(element.grossWeight);
              this.calculatePackingListNetWeight(index);
              this.calculatePackingListGrossWeight(index);
            }
            this.changeUnitOfWeight();
          }

          if(response.annexurePackingList != null){
            this.annexurePackingList.get('unitOfWeight').patchValue(response.annexurePackingList.unitOfWeight);
            this.annexurePackingList.get('dimensionsOfPackage').patchValue(response.annexurePackingList.dimensionsOfPackage);
            this.annexurePackingList.get('cbmTotal').patchValue(response.annexurePackingList.cbmTotal);
            this.annexurePackingList.get('quantityTotal').patchValue(response.annexurePackingList.quantityTotal);
            this.annexurePackingList.get('netWeightTotal').patchValue(response.annexurePackingList.netWeightTotal);
            this.annexurePackingList.get('grossWeightTotal').patchValue(response.annexurePackingList.grossWeightTotal);
          }
          if(response.annexurePackingList.annexurePakingListRows.length > 0){
            for (let index = 0; index < response.annexurePackingList.annexurePakingListRows.length; index++) {
              const element = response.annexurePackingList.annexurePakingListRows[index];
              this.annexurePackingListRow.at(index).get('length').patchValue(element.length);
              this.annexurePackingListRow.at(index).get('width').patchValue(element.width);
              this.annexurePackingListRow.at(index).get('height').patchValue(element.height);
              this.annexurePackingListRow.at(index).get('cbm').patchValue(element.cbm);
              this.annexurePackingListRow.at(index).get('typeOfPacking').patchValue(element.typeOfPacking);
              let containtsInPackages: Array<any> = element.containtsInPackages
              if(containtsInPackages.length > 0){
                for (let ind = 0; ind < containtsInPackages.length; ind++) {
                  const element = containtsInPackages[ind];
                  if(ind >= 1){
                    this.addContaintsInPackage(index)
                  }
                  this.containtsInPackages(index).at(ind).get('description').patchValue(element.description);
                  this.containtsInPackages(index).at(ind).get('quantity').patchValue(element.quantity);
                  this.containtsInPackages(index).at(ind).get('tagNo').patchValue(element.tagNo);
                  this.containtsInPackages(index).at(ind).get('uom').patchValue(element.uom);                  
                }
                this.calculateTotalCBM();
              }
            }
          }

          this.shippingMarks.get('shippingFrom').patchValue(response.shippingMarks.shippingFrom)
          this.shippingMarks.get('shippingTo').patchValue(response.shippingMarks.shippingTo)
          this.shippingMarks.get('caseNumber').patchValue(response.shippingMarks.caseNumber)
          this.shippingMarks.get('grossWeight').patchValue(response.shippingMarks.grossWeight);
          this.shippingMarks.get('netWeight').patchValue(response.shippingMarks.netWeight)

          this.invoice.countryOfOriginOfGoods.patchValue(response.countryOfOriginOfGoods);
          this.invoice.countryOfFinalDestination.patchValue(response.countryOfFinalDestination)
          this.invoice.preCarriageBy.patchValue(response.preCarriageBy)
          this.invoice.placeOfReceiptByPreCarrier.patchValue(response.placeOfReceiptByPreCarrier);
          this.invoice.vesselVoyageFlightDetails.patchValue(response.vesselVoyageFlightDetails);
          this.invoice.modeOfTransport.patchValue(response.modeOfTransport);
          this.searchOriginValue = response.portOfLoadingName;
          this.searchDestinationValue = response.portOfDischargeName;
          this.getSearchResultsOrigin();
          this.getSearchResultDestination();
          this.invoice.portOfLoading.patchValue(response.portOfLoading);
          this.invoice.portOfDischarge.patchValue(response.portOfDischarge);
          this.invoice.placeOfDelivery.patchValue(response.placeOfDelivery);
          this.invoice.currency.patchValue(response.currency);

        }
      },
      (error) => {
        console.log(error);
      }
    )
  }

  getSearchResultsOrigin(){
    let modeOfShipmentValue = this.invoice.modeOfTransport.value
    if(modeOfShipmentValue != null || undefined){
      let mode = this.modeOfShipment.find((x) => {
        if(x.value == modeOfShipmentValue){
          return x
        }
      });
      let size = 50
      if(this.searchOriginValue != ""){
        this.masterTableService.getSearchPort(mode.id, this.searchOriginValue, size).subscribe((res)=>{
          this.loadingPorts = res
        },
        (error)=>{
          console.log(error);
        })
      } else {
        console.log('search value is empty ', this.searchOriginValue);
      }
    }
  }

  getSearchResultDestination(){
    let modeOfShipmentValue = this.invoice.modeOfTransport.value
    if(modeOfShipmentValue != null || undefined){
      let mode = this.modeOfShipment.find((x) => {
        if(x.value == modeOfShipmentValue){
          return x
        }
      });
      let size = 50
      if(this.searchDestinationValue != ""){
        this.masterTableService.getSearchPort(mode.id, this.searchDestinationValue, size).subscribe((res)=>{
          this.destinationPorts = res
        },
        (error)=>{
          console.log(error);
        })
      } else {
        console.log('search value is empty ', this.searchDestinationValue);
      }
    }
  }

  calculatePackingListNetWeight(index?: number){
    let netWeight = this.packingListRows.at(index).get('netWeight').value;
    this.annexurePackingListRow.at(index).get('netWeight').patchValue(netWeight);
    let netWeightTotal = 0;
    for (let index = 0; index < this.packingListRows.length; index++) {
      let netWeight = this.packingListRows.at(index).get('netWeight').value;
      this.annexurePackingListRow.at(index).get('netWeight').patchValue(netWeight);
      netWeightTotal = netWeightTotal + netWeight;
    }
    this.packingList.get('netWeightTotal').patchValue(netWeightTotal);
    this.annexurePackingList.get('netWeightTotal').patchValue(netWeightTotal);
  }

  calculatePackingListGrossWeight(index?: number){
    let grossWeightTotal = 0;
    let grossWeight = this.packingListRows.at(index).get('grossWeight').value;
    this.annexurePackingListRow.at(index).get('grossWeight').patchValue(grossWeight);
    for (let index = 0; index < this.packingListRows.length; index++) {
      let grossWeight = this.packingListRows.at(index).get('grossWeight').value;
      grossWeightTotal = grossWeightTotal + grossWeight;
    }
    this.packingList.get('grossWeightTotal').patchValue(grossWeightTotal);
    this.annexurePackingList.get('grossWeightTotal').patchValue(grossWeightTotal);
  }

  populatePackageNumber(index: number, controller: string){
    if(controller == "packageNumber"){
      let packageNumber = this.invoiceLine.at(index).get(controller).value;
      this.packingListRows.at(index).get(controller).patchValue(packageNumber);
      this.annexurePackingListRow.at(index).get(controller).patchValue(packageNumber);
    }
    else if(controller == "descriptionOfGoods"){
      let description = this.invoiceLine.at(index).get(controller).value;
      this.packingListRows.at(index).get('description').patchValue(description);
    }
    else if(controller == "tagNumber"){
      let tagNumber = this.invoiceLine.at(index).get(controller).value;
      this.packingListRows.at(index).get('tagNumber').patchValue(tagNumber);
    }
    else if(controller == "hsCode"){
      let hsCode = this.invoiceLine.at(index).get(controller).value;
      this.packingListRows.at(index).get('hsCode').patchValue(hsCode);
    }
    else if(controller == "noOfPackages"){
      let noOfPackages = this.invoiceLine.at(index).get(controller).value;
      this.packingListRows.at(index).get('quantityPackages').patchValue(noOfPackages);
      this.annexurePackingList.get('quantityTotal').patchValue(noOfPackages);
    }
  }

  calculateTotalCBM(){
    let totalCBM = 0.0;
    let quantity = this.annexurePackingList.get('quantityTotal').value;
    let dimensionsOfPackage = this.annexurePackingList.get('dimensionsOfPackage').value
    for (let index = 0; index < this.annexurePackingListRow.length; index++) {
      const element = this.annexurePackingListRow.at(index);
      let length = element.get('length').value;
      let height = element.get('height').value;
      let width = element.get('width').value;
      let totalVolume = (length * width * height) * quantity;
      let convert = this.convertdimensionsUOMtoMtr(dimensionsOfPackage, totalVolume); 
      element.get('cbm').patchValue(convert);
      totalCBM = totalCBM + convert
      this.annexurePackingList.get('cbmTotal').patchValue(totalCBM);
    }
  }

  convertdimensionsUOMtoMtr(dimensionsUOM, totalVolume) {
    let volume: number = 0.0;
    if (dimensionsUOM == "CM") {
      volume = (totalVolume / 1000000 );
    }
    else if (dimensionsUOM == "MM") {
      volume = (totalVolume / 1000000000 );
    }
    else if (dimensionsUOM == "IN") {
      volume = (totalVolume / 61020 );
    }
    else if (dimensionsUOM == "FT") {
      volume = (totalVolume / 35.315);
    }
    else {
      // mtr
      volume = totalVolume
    }
    return parseFloat(volume.toFixed(4));
  }

  changeUnitOfWeight(){
    let unitOfWeight = this.packingList.get('unitOfWeight').value;
    this.annexurePackingList.get('unitOfWeight').patchValue(unitOfWeight);
  }

}
