<!--header start-->
<header class="box-absolute d-flex align-items-center topline text-white">
    <app-sidebar></app-sidebar>
    <div class="page-title-center mt-3">
        <div class="text-center">
            <h1>{{ title }}</h1>
        </div>
    </div>
    <div class="breadcrumb-div mt-3">
        <ul class="breadcrumb p-0 m-0">
            <li class="breadcrumb-item 3" id="0"><a [routerLink]="['/v1/dashboard']">Dashboard</a></li>
            <li class="breadcrumb-item 3" id="1"><a>Invoice</a></li>
            <li class="breadcrumb-item"><a>Listing</a></li>
        </ul>
    </div>
</header>
<!--header end-->
<section class="banner text-center text-white d-flex" id="bnner">
    <div class="container position-relative">
        <div class="row justify-content-center">
            <div class="col-lg-12">
            </div>
        </div>
    </div>
</section>
<!--request start-->
<section class="request-demo-wrapper pb-10">
    <div class="container shadow bg-white" (click)="hideSideBar()">
        <div class="row">
            <div class="col-lg-12">
                <div class="box">
                    <form>
                        <div class="btn-group d-flex gap-2 pb-3 pb-lg-4 px-3 px-lg-4">
                            <div class="input-group">
                                <input class="form-control rounded-3 px-4" type="text" placeholder=" Search" matInput
                                    (keyup)="applyFilter($event)" #input />
                                <button class="input-group-btn end-0" type="button"><i class="fa fa-search"></i>
                                </button>
                            </div>
                            <!-- TODo changes at route -->
                            <button class="rounded-3 flex-shrink-0" [routerLink]="['/v1/commerical-invoice/create']"
                                type="button"><i class="fa fa-plus me-2"></i><span> Add Invoice </span>
                            </button>
                        </div>

                        <div class="mat-elevation-z8 table-responsive">
                            <table class="table table-striped table-footer-dark table-borderless table-head-dark"
                                mat-table [dataSource]="dataSource" matSort>

                                <!-- ID Column -->
                                <ng-container matColumnDef="number">
                                    <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header> Invoice
                                        Number </th>
                                    <td mat-cell *matCellDef="let row"> {{row.commercialInvoiceListing.number}} </td>
                                </ng-container>

                                <!-- CreatedBy Column -->
                                <ng-container matColumnDef="serviceOrder">
                                    <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header> Service Order
                                    </th>
                                    <td mat-cell *matCellDef="let row"> {{row.commercialInvoiceListing.serviceOrder?.number}}
                                    </td>
                                </ng-container>

                                <!-- portOfLoading Column -->
                                <ng-container matColumnDef="portOfLoading">
                                    <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header> Port Of
                                        Loading
                                    </th>
                                    <td mat-cell *matCellDef="let row">
                                        {{row.portOfLoadingName}} </td>
                                </ng-container>

                                <ng-container matColumnDef="portOfDischarge">
                                    <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header> Port Of
                                        Discharge
                                    </th>
                                    <td mat-cell *matCellDef="let row">
                                        {{row.portOfDischargeName}} </td>
                                </ng-container>

                                <!-- Origin Column -->
                                <ng-container matColumnDef="countryOfOriginOfGoods">
                                    <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header> Origin
                                    </th>
                                    <td mat-cell *matCellDef="let row">
                                        {{row.commercialInvoiceListing.countryOfOriginOfGoods}} </td>
                                </ng-container>
                                <!-- Destination Column -->
                                <ng-container matColumnDef="countryOfFinalDestination">
                                    <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Destination </th>
                                    <td mat-cell *matCellDef="let row">
                                        {{row.commercialInvoiceListing.countryOfFinalDestination}} </td>
                                </ng-container>
                                <!-- Status Column -->
                                <ng-container matColumnDef="status">
                                    <th class="text-white" mat-header-cell *matHeaderCellDef mat-sort-header> Status
                                    </th>
                                    <td mat-cell *matCellDef="let row"> {{row.status}} </td>
                                </ng-container>

                                <!-- CreatedDate Column -->
                                <ng-container matColumnDef="createdDate">
                                    <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header>Created
                                        Date </th>
                                    <td mat-cell *matCellDef="let row"> {{row.commercialInvoiceListing.createDate | date
                                        }} </td>
                                </ng-container>

                                <!-- Actions -->
                                <ng-container matColumnDef="action">
                                    <th class="text-white" mat-header-cell *matHeaderCellDef>Actions </th>
                                    <td mat-cell *matCellDef="let row" class="action-link">
                                        <button class="fa fa-edit text-primary" title="Edit"
                                            [routerLink]="['/v1/commerical-invoice/edit/', row.commercialInvoiceListing.id]" type="button">
                                        </button>
                                        <button *ngIf="row.commercialInvoiceCheck"  class="fa fa-download" type="button" title="Invoice Download" (click)="downloadPDF(row.commercialInvoiceListing.id, 'I')">
                                        </button>
                                        <button *ngIf="row.packingListCheck" class="fa fa-download" title="Packing List Download" type="button" (click)="downloadPDF(row.commercialInvoiceListing.id, 'P')">
                                        </button>
                                        <button *ngIf="row.annexurePackingListCheck" class="fa fa-download" title="Annexure List Download" type="button" (click)="downloadPDF(row.commercialInvoiceListing.id, 'A')">
                                        </button>
                                    </td>
                                </ng-container>

                                <tr class="bg-dark" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                                <!-- Row shown when there is no matching data. -->
                                <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell text-center text-danger" colspan="8">
                                        <div *ngIf="serchFilter">
                                            No data matching the filter "{{ input.value }}"
                                        </div>
                                        <div *ngIf="nodata">
                                            <h5>Data is Not Available</h5>
                                        </div>
                                    </td>
                                </tr>
                            </table>

                            <mat-paginator [pageSizeOptions]="[25,50,100]" aria-label="Select page of users"
                                (page)="onPageChange($event)" [length]="length">
                            </mat-paginator>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</section>