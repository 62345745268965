import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuoteService {

  AUTH_API: string = `https://${window.location.hostname}:9004/api/v1/procurement`
  constructor(private http: HttpClient) { }

  getAllVendorQuotes(): Observable<any> {
    return this.http.get(`${this.AUTH_API}/quotes`);
  }

  saveQuote(data: any): Observable<any> {
    return this.http.post(`${this.AUTH_API}/quote`, data);
  }

  updateQuote(id: number, data: any): Observable<any> {
    return this.http.put(`${this.AUTH_API}/quote/${id}`, data);
  }

  fetchQuoteById(id: number): Observable<any> {
    return this.http.get(`${this.AUTH_API}/quote/${id}`);
  }

  fetchQuotesByEnquiry(id: number): Observable<any> {
    return this.http.get(`${this.AUTH_API}/enquiry/${id}/quotes`);
  }

  getAllVendorEnquiry(): Observable<any> {
    return this.http.get(`${this.AUTH_API}/enquiries`);
  }

  fetchEnquiryById(id: number): Observable<any> {
    return this.http.get(`${this.AUTH_API}/enquiry/${id}`);
  }

  getAllQuoteHistory(id: number): Observable<any> {
    return this.http.get(`${this.AUTH_API}/vendorQuoteHistory/vendorQuote/${id}`);
  }

  createQuoteArtifact(quoteId, data):Observable<any>{
    return this.http.post(`${this.AUTH_API}/quote/${quoteId}/artifact`, data);
  }

  deleteQuoteArtifact(quoteId, artifactId){
    return this.http.delete(`${this.AUTH_API}/quote/${quoteId}/artifact/${artifactId}`);
  }

  getAllQuoteArtifacts(quoteId):Observable<any>{
    return this.http.get(`${this.AUTH_API}/quote/${quoteId}/artifacts`);
  }

  callForNoRegretOffer(id): Observable<any> {
    return this.http.get(`${this.AUTH_API}/enquiry/${id}/callForNoRegretOffer`);
  }

  letestQuoteHistory(id): Observable<any> {
    return this.http.get(`${this.AUTH_API}/enquiry/${id}/latestQuoteHistory`);
  }

  getVendorBidArtifacts(vendorbidId): Observable<any> {
    return this.http.get(`${this.AUTH_API}/vendorbid/${vendorbidId}/artifacts`);
  }

  createVendorBidArtifact(vendorbidId, data): Observable<any> {
    return this.http.post(`${this.AUTH_API}/vendorbid/${vendorbidId}/artifact`, data);
  }

  deleteVendorBidArtifact(vendorbidId, artifactId): Observable<any> {
    return this.http.delete(`${this.AUTH_API}/vendorbid/${vendorbidId}/artifact/${artifactId}`);
  }

  fetchVendorQuoteRowById(quoteRowId: number):Observable<any>{
    return this.http.get(`${this.AUTH_API}/quoteRow/${quoteRowId}`);
  }

  fetchQuotesByVendorQuoteId(quoteId, rowId):Observable<any>{
    return this.http.get(`${this.AUTH_API}/quote/${quoteId}/quoteRows/${rowId}`);
  }

  getRecentQuotes(page, size):Observable<any>{
    let param = new HttpParams().set('page', page).set('size', size);
    return this.http.get(`${this.AUTH_API}/recentVendorQuotes`, {params: param})
  }

  getQuoteComparisonPDF(enquiryId):Observable<Blob>{
    return this.http.get(`${this.AUTH_API}/enquiry/${enquiryId}/quoteComparison/e`, {responseType: 'blob'});
  }

}
