import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { PasswordStrengthValidator } from '../helpers/password-strenght-validation';
import { UserService } from '../helpers/user.service';

@Component({
  selector: 'app-change-user-password',
  templateUrl: './change-user-password.component.html',
  styleUrls: ['./change-user-password.component.css'],
})
export class ChangeUserPasswordComponent implements OnInit {
  currentUserData: any;
  changePasswordForm: FormGroup;

  bothPasswordMatched: boolean = false;

  constructor(private fb: FormBuilder, private userService: UserService, private router : Router) {}

  ngOnInit(): void {
    this.currentUserData = JSON.parse(sessionStorage.getItem('userData'));
    console.log('this is current user data ', this.currentUserData);

    this.changePasswordForm = this.fb.group({
      emailId: [this.currentUserData.emailId],
      currentPassword: ['', Validators.required],
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          PasswordStrengthValidator,
        ]),
      ],
      confirmPassword: ['', Validators.required],
    });

    this.changePasswordForm.get('emailId').disable();
  }

  get f() {
    return this.changePasswordForm.controls;
  }

  validateBothPasswords() {
    let password = this.f.password?.value;
    let confirmPassword = this.f.confirmPassword.value;
    if (password == confirmPassword) {
      this.bothPasswordMatched = true;
    } else {
      console.log('password and confirm password dont match ');
      this.bothPasswordMatched = false;
    }
  }

  onSubmit() {
    this.validateBothPasswords();
    if (this.changePasswordForm.valid && this.bothPasswordMatched) {
      let data = {
        emailId: this.f.emailId.value,
        currentPassword: this.f.currentPassword.value,
        password: this.f.password.value,
      };
      this.userService.updateUserPassword(data).subscribe({
        next: (res) => {
          console.log('this is res ', res);
          Swal.fire({
            title: 'Success',
            text: res.message,
            timer: 5000,
            icon: 'success',
          });
          this.router.navigate(['v1/dashboard']) 
        },
        error: (error) => {
          console.log('this is error ', error);
          if (error.error.errorCode == 412) {
            Swal.fire({
              title: 'Alert',
              text: error.error.errorMessage,
              timer: 5000,
              icon: 'warning',
            });
          }
        },
      });
    } else {
      if (!this.bothPasswordMatched) {
        Swal.fire({
          title: 'Alert',
          text: 'Password and confirm password dont match',
          timer: 5000,
          icon: 'warning',
        });
      } else {
        Swal.fire({
          title: 'Alert',
          text: 'Fill all the mandatory fields',
          timer: 2000,
          icon: 'warning',
        });
      }
    }
  }
}
