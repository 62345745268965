import { Component, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';

import {
  ChartComponent, ApexAxisChartSeries, ApexChart, ApexXAxis,
  ApexTitleSubtitle, ApexResponsive, ApexDataLabels
} from "ng-apexcharts";
import Swal from 'sweetalert2';
import { AuctionService } from '../helpers/auction.service';
import { EnquiryService } from '../helpers/enquiry.service';
import { MasterTableService } from '../helpers/master-table.service';
import { OnboardService } from '../helpers/onboard.service';
import { QuoteService } from '../helpers/quote.service';
import { ShipmentService } from '../helpers/shipment.service';
import { SocketService } from '../helpers/socket.service';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
  title: ApexTitleSubtitle | any;
  responsive: ApexResponsive[] | any;
  labels: ApexDataLabels | any;
};

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  @ViewChild('chart') chart!: ChartComponent;
  public chartOptions!: Partial<ChartOptions>;
  public lineChart!: Partial<ChartOptions>;
  public areaChart!: Partial<ChartOptions>;
  public pieChart!: Partial<ChartOptions>;

  userData: any;
  dType: number;
  inProgressAuctionList: Array<any> = [];
  inProgressAuctionlength: number;
  page = 0;
  size = 5;
  upComingAuctionlength: number;
  upcomingAuctionList: Array<any> = [];

  vendorLength: number;
  vendorOnboardingRoute: string;
  recentEnquiryList: Array<any> = [];
  recentEnquirylength: number;

  recentQuotesList: Array<any> = [];
  recentQuoteslength: number;

  showRecentEnquiry: boolean = false;
  showRecentVendorQuotes: boolean = false;
  showUpcomingAuctions: boolean = false;
  showInProgressAuctions: boolean = false

  countAtOrigin: number = 0;
  countAtPOD: number = 0;
  countInTransit: number = 0;
  countInTranshipment: number = 0;
  activeShipments: number = 0;

  constructor(
    private socketService: SocketService,
    private auctionService: AuctionService,
    private route: Router,
    private apiService: OnboardService,
    private enquiryService: EnquiryService,
    private quoteService: QuoteService,
    private shipmentService: ShipmentService
  ) {
    this.chartOptions = {
      series: [
        {
          name: 'My-series',
          data: [10, 41, 35, 51, 49, 62, 69, 200, 148],
        },
      ],

      chart: {
        height: 350,
        type: 'bar',
      },

      title: {
        text: 'Bar Chart',
      },

      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
        ],
      },
    };

    this.lineChart = {
      series: [
        {
          name: 'Line chart',
          data: [20, 50, 10, 75, 35, 80, 25, 76, 125],
        },
      ],

      chart: {
        height: 350,
        type: 'line',
      },

      title: {
        text: 'Line chart',
      },

      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
        ],
      },
    };

    this.areaChart = {
      series: [
        {
          name: 'Area chart',
          data: [40, 20, 100, 35, 75, 49, 95, 26, 138],
        },
      ],

      chart: {
        height: 350,
        type: 'area',
      },

      title: {
        text: 'Area Chart',
      },

      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
        ],
      },
    };

    this.pieChart = {
      title: {
        text: 'Pie Chart',
      },
      series: [66, 78, 15, 66, 33],
      chart: {
        width: 380,
        type: 'pie',
      },
      labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    };
  }

  ngOnInit(): void {
    this.userData = JSON.parse(sessionStorage.getItem('userData'));
    console.log('this is user data ', this.userData);

    this.dType = this.userData['dType'];
    this.socketService.connectTOServerPopUpFuction();

    if(this.dType == 2){
      this.getAllVendors();
    }
    this.getAllShipmentStatusCount();
    this.gotoTop();
    this.showDashboardCharts();
    // this.showPopUpMsg(`Welcome ${this.userData.orgName}`);
  }

  // getAllMasterTableData() {
  //   this.masterTableService.getAllMasterTables().subscribe(
  //     (response) => {
  //       if(response != null){
  //         console.log(response);
  //         sessionStorage.setItem('containerTypes', JSON.stringify(response.containerTypes));
  //         sessionStorage.setItem('deliveryIncoterms', JSON.stringify(response.deliveryIncoterms));
  //         sessionStorage.setItem('modeOfShipment', JSON.stringify(response.modeOfShipment));
  //         sessionStorage.setItem('natureOfGoods', JSON.stringify(response.natureOfGoods));
  //         sessionStorage.setItem('natureOfMovement', JSON.stringify(response.natureOfMovement));
  //         sessionStorage.setItem('pickupIncoterms', JSON.stringify(response.pickupIncoterms));
  //         sessionStorage.setItem('port', JSON.stringify(response.port));
  //         sessionStorage.setItem('tradeTypes', JSON.stringify(response.tradeTypes));
  //         sessionStorage.setItem('typeOfCargo', JSON.stringify(response.typeOfCargo));
  //         sessionStorage.setItem('typeOfPacking', JSON.stringify(response.typeOfPacking));
  //         sessionStorage.setItem('currency', JSON.stringify(response.currency));
  //         sessionStorage.setItem('chargeType', JSON.stringify(response.chargeType));
  //         sessionStorage.setItem('typeOfVehicle', JSON.stringify(response.typeOfVehicle));
  //         sessionStorage.setItem('businessType', JSON.stringify(response.businessType));
  //         sessionStorage.setItem('countries', JSON.stringify(response.countries));
  //       }
  //     },
  //     (error) => {
  //       console.log(error);
  //     },
  //   )
  // }

  reloadFunction(){
    this.getAllInProgressAuctions();
    this.getAllUpcomingAuctions();
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove('sidebar-active');
  }

  getAllInProgressAuctions(){
    this.auctionService.getAllAuctions(this.page, this.size, null ,'IN_PROGRESS').subscribe(
      (response) => {
        console.log(response);
        let content: Array<any> = response.content;
        if(content.length > 0){
          this.inProgressAuctionList = content;
          this.inProgressAuctionlength = response.totalElements;
          console.log(this.inProgressAuctionList);
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getAllUpcomingAuctions(){
    this.auctionService.getAllUpcomingAuctions(this.page, this.size, 'r').subscribe(
      (response) => {
        let content: Array<any> = response.content;
        if(content.length > 0){
          this.upcomingAuctionList = content;
          this.upComingAuctionlength = response.totalElements;
          console.log(this.upcomingAuctionList);
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getAllRecentEnquiry(){
    this.enquiryService.getAllRecentEnquiries(this.page, this.size, '').subscribe(
      (response) => {
        console.log(response);
        let content: Array<any> = response.content;
        if(content.length > 0){
          this.recentEnquiryList = content;
          this.recentEnquirylength = response.totalElements;
          console.log(this.recentEnquiryList);
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getAllRecentQuotes() {
    this.quoteService.getRecentQuotes(this.page, this.size).subscribe(
      (response) => {
        console.log(response);
        let content: Array<any> = response.content;
        if (content.length > 0) {
          this.recentQuotesList = content;
          this.recentQuoteslength = response.totalElements;
          console.log(this.recentQuotesList);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getAllVendors() {
    this.vendorOnboardingRoute = '/v1/vendor-onboard/details';
    this.apiService.getAllVendors(this.page, this.size, null).subscribe(
      (response) => {
        console.log(
          'This is dashboard get all vendors res ',
          response.totalElements
        );
        this.vendorLength = response.totalElements;
        if (this.dType == 2 && this.vendorLength == 0) {
          Swal.fire({
            title: 'Info',
            timer: 5000,
            html: `Start with onboarding vendor <br> <a href="${this.vendorOnboardingRoute}">Go To Vendor Onboard</a>`,
          });
        }
      },
      (error) => {
        console.log('This is dashboard get all vendors error ', error);
      }
    );
  }

  //fuction to scroll to top on next button click
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  onPageChange(event: PageEvent, type: string) {
    console.log(event, type);
    this.page = event.pageIndex;
    this.size = event.pageSize;
    if (type == 'upcomingAuctionList') {
      this.getAllUpcomingAuctions();
    } else if (type == 'inProgressAuctionList') {
      this.getAllInProgressAuctions();
    } else if (type == 'recentEnquiryList') {
      this.getAllRecentEnquiry();
    } else if (type == 'recentQuotesList') {
      this.getAllRecentQuotes();
    }
  }

  showDashboardCharts() {
    this.getAllRecentEnquiry();
    this.getAllRecentQuotes();
    this.getAllUpcomingAuctions();
    this.getAllInProgressAuctions();
    // for (let index = 0; index < this.userData.authorities.length; index++) {
    //   const element = this.userData.authorities[index];
    //   if (element == 'RB0000138') {
    //     console.log('we have a match recent enquiry match');
    //     this.showRecentEnquiry = true;
    //     this.getAllRecentEnquiry();
    //   } else if (element == 'RB0000219') {
    //     console.log('we have a match recent quotes match');
    //     this.showRecentVendorQuotes = true;
    //     this.getAllRecentQuotes();
    //   } else if (element == 'RB0000141') {
    //     console.log('we have a match upcoming auctions');
    //     this.showUpcomingAuctions = true;
    //     this.getAllUpcomingAuctions();
    //   } else if (element == 'RB0000134'){
    //     console.log('we have a match In-Progress Auctions');
    //     this.showInProgressAuctions = true;
    //     this.getAllInProgressAuctions();
    //   }
    // }
  }

  getAllShipmentStatusCount(){
    let boooked =  this.shipmentService.getAllShipmentCount('Booked')
    let sailing = this.shipmentService.getAllShipmentCount('Sailing')
    let discharge = this.shipmentService.getAllShipmentCount('Discharged')
    let active = this.shipmentService.getAllShipmentCount('Active')
    let transhipment = this.shipmentService.getAllShipmentCount('TransShipment')

    boooked.subscribe(
      (response) => {
        this.countAtOrigin = response.statusCount;
      },
      (error) => {
        console.log(error);
      },
    )

    sailing.subscribe(
      (response) => {
        this.countInTransit = response.statusCount;
      },
      (error) => {
        console.log(error);
      },
    )

    discharge.subscribe(
      (response) => {
        this.countAtPOD = response.statusCount
      },
      (error) => {
        console.log(error);
      },
    )

    active.subscribe(
      (response) => {
        this.activeShipments = response.statusCount
      },
      (error) => {
        console.log(error);
      },
    )

    transhipment.subscribe(
      (response) => {
        this.countInTranshipment = response.statusCount
      },
      (error) => {
        console.log(error);
      },
    )
  }

  routeToShipmentListing(param) {
    this.route.navigate(['/v1/client-track-trace/shipment/listing', {param: param}]);
  }

  //recent enquiry
  // "RB0000138" ,

  //in progress auctions
  // 'RB0000134'

  //upcoming auctions
  // "RB0000141",

  //recent vendor quote
  // "RB0000219"
}
