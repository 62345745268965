import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hierarchy-master',
  templateUrl: './hierarchy-master.component.html',
  styleUrls: ['./hierarchy-master.component.css']
})
export class HierarchyMasterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
