<div class="box-absolute d-flex align-items-center topline text-white">
    <app-sidebar></app-sidebar>
    <div class="breadcrumb-div">
        <ul class="breadcrumb p-0 m-0">
            <li class="breadcrumb-item 3" id="0"><a [routerLink]="['/v1/dashboard']">Dashboard</a></li>
            <li class="breadcrumb-item 3" id="0"><a>Template</a></li>
            <li class="breadcrumb-item 3" id="0"><a>{{isEdit ? 'Update' : 'Create'}}</a></li>
        </ul>
    </div>
</div>

<div (click)="hideSideBar()">
    <section class="banner text-center text-white d-flex" id="bnner">
        <div class="container position-relative">
            <div class="row justify-content-center">
                <div class="col-lg-15">
                    <h1>Invoice Template {{isEdit ? 'Update' : 'Create'}}</h1>
                </div>
            </div>
        </div>
    </section>

    <section class="background">
        <div class="container">
            <form [formGroup]="invoiceTemplateForm">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card border-0 h-100 rounded-0  pt-4 shadow rounded-xxl ">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-4 mt-2">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <label class="form-label">Template Name</label>
                                            </div>
                                            <div class="col-md-2"></div>
                                        </div>
                                        <input type="text" formControlName="name" class="form-control mt-1"
                                            placeholder="Enter Template Name">
                                    </div>
                                    <div class="col-lg-4 mt-2">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <label class="form-label">Buyers Reference</label>
                                            </div>
                                            <div class="col-md-2">
                                                <input type="checkbox" class="form-check-input pull-right"
                                                    formControlName="buyersRefRequired" id="buyersReference1" checked
                                                    (change)="toggleCheckbox($event, 'buyersRef')">
                                            </div>
                                        </div>
                                        <input class="form-control mt-1" formControlName="buyersRef"
                                            id="buyersReference2" type="text" placeholder="Text Input">
                                    </div>

                                    <div class="col-lg-4">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <input type="text" class="form-control inputLine"
                                                    formControlName="exporterRefLabel">
                                            </div>
                                            <div class="col-md-2">
                                                <input type="checkbox" class="form-check-input pull-right"
                                                    formControlName="exporterRefRequired" id="buyersReference1" checked
                                                    (change)="toggleCheckbox($event, 'exporterRef')">
                                            </div>
                                        </div>
                                        <input class="form-control" id="input1" formControlName="exporterRef"
                                            type="text">
                                    </div>
                                </div>

                                <div class="row">
                                    <!-- Add Controllers for othersref -->
                                    <div class="col-md-12 mt-2" formArrayName="otherRef">
                                        <div class="row">
                                            <div class="row">
                                                <div class="col-md-2">
                                                    <label for="">Other Reference</label>
                                                </div>
                                                <div class="col-md-1">
                                                    <input type="checkbox" class="form-check-input pull-right" id=""
                                                        checked>
                                                </div>
                                                <div class="col-md-1">
                                                    <button class="fas fa-plus-circle text-success"
                                                        (click)="addAlternateOtherRef()"></button>
                                                </div>
                                            </div>
                                            <div class="col-md-4"
                                                *ngFor="let otherref of otherRef.controls; let i=index; let l=last"
                                                [formGroupName]="i">
                                                <div class="row">
                                                    <div class="col-md-10">
                                                        <input class="form-control mt-1"
                                                            formControlName="otherReference" type="text"
                                                            placeholder="Text Input">
                                                    </div>
                                                    <div class="col-md-1 mt-2">
                                                        <button *ngIf="otherRef.length > 1"
                                                            class="fas fa-trash btn-outline-danger btn-sm"
                                                            (click)="removeAlternateOtherRef(i)"></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 mt-3">
                        <div class="card border-0 rounded-0  pt-4 shadow rounded-xxl ">
                            <div class="row">
                                <div class="col-md-6">
                                    <h4 class="ml-1">Party Details</h4>
                                </div>
                                <div class="col-md-6">
                                    <button class="btn-outline-success fas fa-plus pull-right mr-1"
                                        (click)="addNewParty()">Party</button>
                                </div>
                            </div>
                            <div class="row" formArrayName="parties">
                                <div class="col-md-6" *ngFor="let item of parties.controls; let i = index"
                                    [formGroupName]="i">
                                    <div class="card border-0 rounded-0  pt-4 shadow rounded-xxl m-2">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <select class="form-select" formControlName="partyType">
                                                                <option *ngFor="let party of partyTypes" [value]="party">
                                                                    {{party}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div class="col-md-4">

                                                        </div>
                                                        <div class="col-md-2">
                                                            <button class="btn-outline-danger btn-sm fas fa-trash pull-right"
                                                                *ngIf="parties.length > 1" (click)="removeParty(i)"></button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 mt-1">
                                                    <input class="form-control inputLine" list="party" type="text" formControlName="label" >
                                                    <datalist id="party">
                                                        <option *ngFor="let party of partyLabel" [value]="party">{{ party }}</option>
                                                    </datalist>
                                                    <input type="text" id="label" class="form-control"
                                                        placeholder="Enter Name" formControlName="name">
                                                </div>
                                                <div class="col-md-6">
                                                    <label for="" class="form-label">Email-Id</label>
                                                    <input type="email" id="email-id" class="form-control mt-1"
                                                        placeholder="Enter Email-Id" formControlName="email">
                                                </div>
                                                <div class="row" formArrayName="contactNumbers">
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <label for="" class="form-label mt-2">Contact No</label>
                                                        </div>
                                                        <div class="col-md-2">
                                                            <button class="fas fa-plus-circle text-success mt-2"
                                                                (click)="addNewContactNo(i)"></button>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6"
                                                        *ngFor="let contact of partyContactNo(i).controls; let ind = index"
                                                        [formGroupName]="ind">
                                                        <div class="row">
                                                            <div class="col-md-10">
                                                                <input type="number" id="contact-number"
                                                                    class="form-control m-1" placeholder="Enter Number"
                                                                    formControlName="contacts" min="0">
                                                            </div>
                                                            <div class="col-md-1">
                                                                <button
                                                                    class="btn-outline-danger btn-sm fas fa-trash mt-2"
                                                                    *ngIf="partyContactNo(i).length > 1"
                                                                    (click)="removeContactNo(i, ind)"></button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div class="col-md-6">
                                                    <label for="" class="form-label mt-2">Address</label>
                                                    <select class="form-control form-select"
                                                        (click)="selectAddress($event, i)">
                                                        <option value="" selected>Select Address</option>
                                                        <option *ngFor="let address of addressList"
                                                            [value]="address.id">{{address.street1+ " " +
                                                            address.street2 + " " + address.city + " " + address.state +
                                                            ", " + address.country.name + ", " + address.zipCode + " " +
                                                            address.label}}</option>
                                                    </select>
                                                </div>

                                                <div class="col-md-12" formGroupName="address">
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <label for="" class="form-label mt-1">Street 1</label>
                                                            <input type="text" class="form-control"
                                                                formControlName="street1">
                                                        </div>
                                                        <div class="col-md-4">
                                                            <label for="" class="form-label mt-1">Street 2</label>
                                                            <input type="text" class="form-control"
                                                                formControlName="street2">
                                                        </div>
                                                        <div class="col-md-4">
                                                            <label for="" class="form-label mt-1">City</label>
                                                            <input type="text" class="form-control"
                                                                formControlName="city">
                                                        </div>
                                                        <div class="col-md-4">
                                                            <label for="" class="form-label mt-1">State</label>
                                                            <input type="text" class="form-control"
                                                                formControlName="state">
                                                        </div>
                                                        <div formGroupName="country" class="col-md-4">
                                                            <label for="country" class="form-label mt-1">Country</label>
                                                            <select id="country" class="form-control form-select" formControlName="id">
                                                                <option value="" disabled selected>Select Country</option>
                                                                <option [value]="country.id" *ngFor="let country of countryCodes">
                                                                    {{ country.name }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <label for="" class="form-label mt-1">ZipCode</label>
                                                            <input type="text" class="form-control"
                                                                formControlName="zipCode">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 mt-3">
                        <div class="card border-0 rounded-0  pt-4 shadow rounded-xxl ">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-3">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <label class="form-label" style="white-space:nowrap">Country Of Origin Of Goods</label>
                                            </div>
                                            <div class="col-md-2">
                                                <input type="checkbox" class="form-check-input pull-right"
                                                    formControlName="countryOfOriginOfGoodsRequired"
                                                    (change)="toggleCheckbox($event, 'countryOfOriginGoods')" checked>
                                            </div>
                                        </div>
                                        <select class="form-select form-control mb-3"
                                            formControlName="countryOfOriginGoods">
                                            <option value="" disabled selected>Select Country</option>
                                            <option [value]="count.name" *ngFor="let count of countryCodes">
                                                {{count.name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <label class="form-label" style="white-space:nowrap">Country Of Final Destination</label>
                                            </div>
                                            <div class="col-md-2">
                                                <input type="checkbox" class="form-check-input pull-right"
                                                    formControlName="countryOfFinalDestinationRequired"
                                                    (change)="toggleCheckbox($event, 'countryOfFinalDestination')"
                                                    checked>
                                            </div>
                                        </div>
                                        <select class="form-select form-control mb-3"
                                            formControlName="countryOfFinalDestination">
                                            <option value="" disabled selected>Select Country</option>
                                            <option [value]="count.name" *ngFor="let count of countryCodes">
                                                {{count.name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <label class="form-label" style="white-space:nowrap">Pre-Carriage By</label>
                                            </div>
                                            <div class="col-md-2">
                                                <input type="checkbox" class="form-check-input pull-right"
                                                    formControlName="preCarriageByRequired"
                                                    (change)="toggleCheckbox($event, 'preCarriageBy')" checked>
                                            </div>
                                        </div>
                                        <select class="form-select form-control mb-3" formControlName="preCarriageBy">
                                            <option [value]="list.value" *ngFor="let list of modeOfShipment">
                                                {{list.value}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-lg-3">
                                        <label class="form-label" style="white-space:nowrap">Place Of Receipt By Pre-Carries</label>
                                        <input type="checkbox" class="form-check-input pull-right"
                                            formControlName="placeOfReceiptByPreCarrierRequired"
                                            (change)="toggleCheckbox($event, 'placeOfReceiptByPreCarrier')" checked>
                                        <input type="text" class="form-control"
                                            formControlName="placeOfReceiptByPreCarrier" placeholder="Enter Place">
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <label class="form-label" style="white-space:nowrap">Vessel/Voyage/Flight Details </label>
                                            </div>
                                            <div class="col-md-2">
                                                <input type="checkbox" class="form-check-input pull-right"
                                                    formControlName="vesselVoyageFlightDetailsRequired"
                                                    (change)="toggleCheckbox($event, 'vesselVoyageFlightDetails')"
                                                    checked>
                                            </div>
                                        </div>
                                        <input class="form-control mb-3" type="text" formControlName="vesselVoyageFlightDetails">
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <label class="form-label" style="white-space:nowrap">Mode Of Transport</label>
                                            </div>
                                            <div class="col-md-2">
                                                <input type="checkbox" class="form-check-input pull-right"
                                                    formControlName="modeOfTransportRequired"
                                                    (change)="toggleCheckbox($event, 'modeOfTransport')"
                                                    checked>
                                            </div>
                                        </div>
                                        <select class="form-select form-control mb-3" formControlName="modeOfTransport">
                                            <option [value]="list.value" *ngFor="let list of modeOfShipment">
                                                {{list.value}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <label class="form-label" style="white-space:nowrap">Port Of Loading</label>
                                            </div>
                                            <div class="col-md-2">
                                                <input type="checkbox" class="form-check-input pull-right"
                                                    formControlName="portOfLoadingRequired"
                                                    (change)="toggleCheckbox($event, 'portOfLoading')"  checked>
                                            </div>
                                        </div>
                                        <mat-select #singleSelect class="form-control" formControlName="portOfLoading" (selectionChange)="selectLoadingPort()">
                                            <mat-option>
                                                <ngx-mat-select-search [(ngModel)]="searchOriginValue" [ngModelOptions]="{standalone: true}"
                                                    (input)="getSearchResultsOrigin()" placeholderLabel="Enter Name" noEntriesFoundLabel = 'No result available'>
                                                </ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let item of loadingPorts" [value]="item?.code?.code">
                                                {{ item?.value }}
                                            </mat-option>
                                        </mat-select>
                                        <div class="text-danger validation-message" *ngIf="samePortError">
                                            Same Port Not Allowed
                                        </div>
                                    </div>

                                    <div class="col-lg-3">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <label class="form-label" style="white-space:nowrap">Port Of Discharge</label>
                                            </div>
                                            <div class="col-md-2">
                                                <input type="checkbox" class="form-check-input pull-right"
                                                    formControlName="portOfDischargeRequired"
                                                    (change)="toggleCheckbox($event, 'portOfDischarge')" checked>
                                            </div>
                                        </div>
                                        <mat-select #singleSelect class="form-control" formControlName="portOfDischarge" (selectionChange)="selectLoadingPort()">
                                            <mat-option>
                                                <ngx-mat-select-search [(ngModel)]="searchDestinationValue" [ngModelOptions]="{standalone: true}"
                                                (input)="getSearchResultDestination()"
                                                placeholderLabel="Enter Name" noEntriesFoundLabel = 'No result available'></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let item of destinationPorts" [value]="item?.code?.code">
                                                {{ item?.value }}
                                            </mat-option>
                                        </mat-select>
                                        <div class="text-danger validation-message" *ngIf="samePortError">
                                            Same Port Not Allowed
                                        </div>
                                    </div>

                                    <div class="col-lg-3">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <label class="form-label" style="white-space:nowrap">Place Of Delivery</label>
                                            </div>
                                            <div class="col-md-2">
                                                <input type="checkbox" class="form-check-input pull-right"
                                                    formControlName="placeOfDeliveryRequired"
                                                    (change)="toggleCheckbox($event, 'placeOfDelivery')" checked>
                                            </div>
                                        </div>
                                        <input class="form-control mb-3" type="text" formControlName="placeOfDelivery">
                                    </div>

                                    <div class="col-lg-3">
                                        <label for="" class="form-label" style="white-space:nowrap">Currency</label>
                                        <select name="" id="" class="form-control form-select"
                                            formControlName="currency">
                                            <option *ngFor="let item of currency" [value]="item.currency"> {{
                                                item.currency }} </option>
                                        </select>
                                    </div>

                                    <div class="col-lg-3">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <label class="form-label" style="white-space:nowrap">Order Value</label>
                                            </div>
                                            <div class="col-md-2">
                                                <input type="checkbox" class="form-check-input pull-right"
                                                    formControlName="orderValueRequired"
                                                    (change)="toggleCheckbox($event, 'orderValue')" checked>
                                            </div>
                                        </div>
                                        <input type="number" class="form-control" formControlName="orderValue">
                                    </div>

                                    <div *ngIf="serviceOrderBool" formGroupName="serviceOrder" class="col-lg-3">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <label class="form-label" style="white-space:nowrap">Service Order</label>
                                            </div>
                                        </div>
                                        <select class="form-select form-control mb-3" formControlName="id">
                                            <option *ngFor="let service of serviceOrderList" [value]="service.id">
                                                {{ service.number }}
                                            </option>
                                        </select>
                                    </div>

                                    <div class="col-lg-3">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 mt-3">
                        <div class="card border-0 rounded-0  pt-4 shadow rounded-xxl ">
                            <div class="card-body">
                                <div class="col-md-3">
                                    <input type="text" formControlName="packageDetailLabel"
                                        class="form-control inputLine">
                                </div>

                                <div class="row">
                                    <div class="col-3">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <input type="text" formControlName="containerNoLabel"
                                                    class="form-control inputLine">
                                            </div>
                                            <div class="col-md-2">
                                                <input type="checkbox" class="form-check-input pull-right"
                                                    formControlName="containerNoRequired"
                                                    (change)="toggleCheckbox($event, 'containerNo')" checked>
                                            </div>
                                        </div>
                                        <input type="text" class="form-control mt-2" placeholder="Text Input"
                                            formControlName="containerNo">
                                    </div>
                                    <div class="col-3">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <input type="text" formControlName="kindOfPackagesLabel"
                                                    class="form-control inputLine">
                                            </div>
                                            <div class="col-md-2">
                                                <input type="checkbox" class="form-check-input pull-right"
                                                    formControlName="kindOfPackagesRequired"
                                                    (change)="toggleCheckbox($event, 'kindOfPackages')" checked>
                                            </div>
                                        </div>
                                        <input type="text" class="form-control mt-2" placeholder="Text Input"
                                            formControlName="kindOfPackages">
                                    </div>
                                    <div class="col-6">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <input type="text" formControlName="descriptionOfSuppliesLabel"
                                                    class="form-control inputLine">
                                            </div>
                                            <div class="col-md-2">
                                                <input type="checkbox" class="form-check-input pull-right"
                                                    formControlName="descriptionOfSuppliesRequired"
                                                    (change)="toggleCheckbox($event, 'descriptionOfSupplies')" checked>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <textarea class="form-control mt-2" cols="50" rows="2"
                                                placeholder="Text Input"
                                                formControlName="descriptionOfSupplies"></textarea>
                                        </div>
                                    </div>
                                </div>

                                <div class="table-responsive">
                                    <table
                                        class="table table-borderless text-center table-head-dark table-head-condensed table-head-py-3 table-striped mt-3">
                                        <thead>
                                            <tr>
                                                <th>Package No</th>

                                                <th>Description Of Goods</th>

                                                <th>Tag Number</th>

                                                <th>HS Code</th>

                                                <th>No Of Packages</th>

                                                <th>UOM</th>

                                                <th>Amount In {{currencyValue()}}</th>

                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody formArrayName="invoiceRows">
                                            <tr *ngFor="let item of invoiceRows.controls; let i=index; let l=last"
                                                [formGroupName]="i">
                                                <td>
                                                    <input class="form-control inputLine" type="text"
                                                        formControlName="packageNumber" placeholder="Enter Text"/>
                                                </td>
                                                <td>
                                                    <input class="form-control inputLine"
                                                        formControlName="descriptionOfGoods" type="text"
                                                        placeholder="Text Input">
                                                </td>
                                                <td>
                                                    <input class="form-control inputLine" type="text"
                                                        formControlName="tagNumber" placeholder="Text Input" />
                                                </td>
                                                <td>
                                                    <input class="form-control inputLine" type="text"
                                                        formControlName="hsCode" placeholder="Text Input" />
                                                </td>
                                                <td>
                                                    <input type="number" class="form-control inputLine"
                                                        placeholder="Text Input" min="0" formControlName="noOfPackages">
                                                </td>
                                                <td>
                                                    <select class="form-control form-select inputLine" name="uom" id="uom">
                                                        <option *ngFor="let u of uom" [value]="u.name" id="uom">
                                                            {{ u.name }}
                                                        </option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <input class="form-control inputLine" type="number"
                                                        (change)="calculateTotalAmount()" formControlName="amount"
                                                        placeholder="Amount" min="0" />
                                                </td>
                                                <td>
                                                    <button *ngIf="invoiceRows.length > 1"
                                                        class="btn-outline-danger fas fa-trash btn-sm m-2"
                                                        (click)="removeItemRow(i)"></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tr>
                                            <td colspan="2" style="text-align: center;" class="pull-right">
                                                Total Amount
                                                <input type="checkbox" formControlName="totalAmountRequired"
                                                    class="form-check-input" (change)="toggleCheckbox($event, 'totalAmount')" checked>
                                                <input class="form-control inputLine mt-3" type="number"
                                                    formControlName="totalAmount" placeholder="Number"
                                                    min="0" />
                                            </td>
                                        </tr>
                                    </table>
                                    <div class="col-lg-12 mt-3">
                                        <button class="btn-add-new text-dark btn-outline-success pull-right"
                                            (click)="addNewRow()">+ Row</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 mt-3">
                        <div class="card border-0 rounded-0  pt-4 shadow rounded-xxl ">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-4">
                                        <div class="row">
                                            <div class="col-lg-9">
                                                <input type="text" class="form-control inputLine"
                                                    formControlName="shippingMarksLabel">
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="checkbox" class="form-check-input"
                                                    formControlName="shippingMarksRequired" checked>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3" formGroupName="shippingMarks">
                                    <div class="col-lg-4">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <label class="form-label">Case Number</label>
                                            </div>
                                            <div class="col-md-2">
                                                <input type="checkbox" class="form-check-input pull-right"
                                                    formControlName="caseNumberRequired"
                                                    (change)="toggleCheckbox($event, 'shippingMarks.caseNumber')"
                                                    checked>
                                            </div>
                                        </div>
                                        <input type="text" class="form-control" formControlName="caseNumber"
                                            placeholder="Enter Case No">
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <label class="form-label">Gross Weight</label>
                                            </div>
                                            <div class="col-md-2">
                                                <input type="checkbox" class="form-check-input pull-right"
                                                    formControlName="grossWeightRequired"
                                                    (change)="toggleCheckbox($event, 'shippingMarks.grossWeight')"
                                                    checked>
                                            </div>
                                        </div>
                                        <input class="form-control" type="number" formControlName="grossWeight"
                                            placeholder="Text Input" min="0" />
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <label class="form-label">Net Weight</label>
                                            </div>
                                            <div class="col-md-2">
                                                <input type="checkbox" class="form-check-input pull-right"
                                                    formControlName="netWeightRequired"
                                                    (change)="toggleCheckbox($event, 'shippingMarks.netWeight')"
                                                    checked>
                                            </div>
                                        </div>
                                        <input class="form-control" type="number" formControlName="netWeight"
                                            placeholder="Text Input" min="0" />
                                    </div>
                                    <div class="col-lg-6 mt-2">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <label class="form-label">From</label>
                                            </div>
                                            <div class="col-md-2">
                                                <input type="checkbox" class="form-check-input pull-right"
                                                    formControlName="shippingFromRequired"
                                                    (change)="toggleCheckbox($event, 'shippingMarks.shippingFrom')"
                                                    checked>
                                            </div>
                                        </div>
                                        <textarea class="form-control mb-3" formControlName="shippingFrom" id="" cols="30" rows="5"
                                            placeholder="Enter Address">
                                        </textarea>
                                    </div>
                                    <div class="col-lg-6 mt-2">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <label class="form-label">To</label>
                                            </div>
                                            <div class="col-md-2">
                                                <input type="checkbox" class="form-check-input pull-right"
                                                    formControlName="shippingToRequired"
                                                    (change)="toggleCheckbox($event, 'shippingMarks.shippingTo')"
                                                    checked>
                                            </div>
                                        </div>
                                        <textarea class="form-control mb-3" formControlName="shippingTo" id="" cols="30" rows="5"
                                            placeholder="Enter Address">
                                        </textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 mt-3">
                        <div class="card border-0 rounded-0  pt-4 shadow rounded-xxl ">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-4">
                                        <div class="row">
                                            <div class="col-lg-9">
                                                <input type="text" class="form-control inputLine"
                                                    formControlName="additionalInfoLabel">
                                            </div>
                                            <div class="col-lg-1">
                                                <input type="checkbox" class="form-check-input"
                                                    formControlName="additionalInfoRequired" checked>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6"></div>
                                    <div class="col-2">
                                        <button class="pull-right mr-1 fas fa-plus btn-outline-primary"
                                            (click)="addNewField()">&nbsp; Field</button>
                                    </div>
                                </div>

                                <div formArrayName="additionalInformation" class="row">
                                    <div class="col-lg-3" *ngFor="let item of additionalInformation.controls; let i=index"
                                        [formGroupName]="i">
                                        <div class="row">
                                            <div class="col-10">
                                                <input type="text" class="form-control inputLine"
                                                    formControlName="label" placeholder="Enter Label">
                                            </div>
                                            <div class="col-2">
                                                <button class="mt-2 pull-right btn-sm fas fa-trash btn-outline-danger"
                                                    (click)="removeAdditionalField(i)"></button>
                                            </div>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Enter Value"
                                            formControlName="value">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 mt-3">
                        <div class="card border-0 rounded-0 h-100  pt-4 shadow rounded-xxl">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-4">
                                        <input type="text" class="form-control inputLine" formControlName="termsLabel">
                                    </div>
                                    <div class="col-md-2">
                                        <input type="checkbox" class="form-check-input" formControlName="termsRequired"
                                            checked>
                                    </div>
                                    <div class="col-md-6">
                                        <button class="pull-right mr-1 fas fa-plus btn-outline-primary"
                                            (click)="addNewTerms()">&nbsp; Term</button>
                                    </div>
                                </div>
                                <div class="row" formArrayName="terms">
                                    <div class="col-md-12" *ngFor="let item of terms.controls; let i = index"
                                        [formGroupName]="i">
                                        <div class="row mt-1">
                                            <div class="col-md-10">
                                                <input type="text" class="form-control inputLine"
                                                    formControlName="label" placeholder="Enter Label">
                                                <input type="text" class="form-control" formControlName="content"
                                                    placeholder="Enter Terms">
                                            </div>
                                            <div class="col-md-1">
                                                <button class="mt-2 fas fa-trash btn-sm btn-outline-danger"
                                                    (click)="removeTerms(i)" *ngIf="terms.length > 1"></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 mt-3">
                        <div class="card border-0 rounded-0  pt-4 shadow rounded-xxl h-100">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-4">
                                        <input type="text" class="form-control inputLine"
                                            formControlName="declarationLabel">
                                    </div>
                                    <div class="col-md-1">
                                        <input type="checkbox" class="form-check-input"
                                            formControlName="declarationRequired"
                                            (change)="toggleCheckbox($event, 'declaration')" checked>
                                    </div>
                                </div>
                                <div class="col-lg-12 mt-1">
                                    <textarea class="form-control" rows="8" cols="50" formControlName="declaration"
                                        placeholder="Text Input"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 mt-3">
                        <div class="card border-0 rounded-0 h-100 pt-4 shadow rounded-xxl ">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-4">
                                        <input type="text" class="form-control inputLine" formControlName="notesLabel">
                                    </div>
                                    <div class="col-md-1">
                                        <input type="checkbox" class="form-check-input" formControlName="notesRequired"
                                            (change)="toggleCheckbox($event, 'notes')" checked>
                                    </div>
                                </div>
                                <div class="col-lg-12 mt-1">
                                    <textarea class="form-control" rows="4" cols="50" placeholder="Text Input"
                                        formControlName="notes"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 mt-3">
                        <div class="card border-0 rounded-0 h-100 pt-4 shadow rounded-xxl ">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-4">
                                        <input type="text" class="form-control inputLine"
                                            formControlName="bankDetailLabel">
                                    </div>
                                    <div class="col-lg-8">
                                        <button class="pull-right fas fa-plus btn-outline-primary"
                                            (click)="addNewBankField()">&nbsp; Field
                                        </button>
                                    </div>
                                </div>
                                <div formArrayName="bankDetail" class="row mt-2">
                                    <div class="col-lg-4" *ngFor="let item of bankDetail.controls; let i=index"
                                        [formGroupName]="i">
                                        <div class="row mt-1">
                                            <div class="col-md-10">
                                                <input type="text" class="form-control inputLine"
                                                    formControlName="label" placeholder="Enter Label">
                                            </div>
                                            <div class="col-md-2">
                                                <button class="mt-2 pull-right btn-sm fas fa-trash btn-outline-danger"
                                                    (click)="removeAdditionalBankFields(i)"></button>
                                            </div>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Enter Value"
                                            formControlName="value">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 mt-3">
                        <button class="btn text-dark text-small btn-outline-success"
                            style="width: 100%;">CANCEL</button>
                    </div>

                    <div class="col-lg-4 mt-3">
                        <button class="btn text-dark text-small btn-outline-success" (click)="resetForm()" style="width: 100%;">RESET</button>
                    </div>

                    <div class="col-lg-4 mt-3 mb-3" *ngIf="!isEdit">
                        <button class="btn text-dark text-small btn-outline-success" (click)="saveTemplate()"
                            style="width: 100%;">SAVE</button>
                    </div>

                    <div class="col-lg-4 mt-3 mb-3" *ngIf="isEdit">
                        <button class="btn text-dark text-small btn-outline-success"
                            (click)="updateInvoiceTemplateData()" style="width: 100%;">UPDATE</button>
                    </div>
                </div>
            </form>
        </div>
    </section>
</div>
