import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { AuthServiceService } from './helpers/auth-service.service';
import { PreLoadingServiceService } from './helpers/pre-loading-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements AfterViewChecked, OnInit {
  title = 'front-end';

  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;

  subscriptions: Subscription[] = [];

  connectionStatusMessage: string;
  connectionStatus: string;

  public loading$ = this.loadingService.loading$;
  isFooterVisible: boolean = false;
  networkStatus: any;

  constructor(
    public loadingService: PreLoadingServiceService,
    private CDR: ChangeDetectorRef,
    private authService: AuthServiceService
  ) {}

  ngAfterViewChecked(): void {
    this.CDR.detectChanges();
    let userLoggedIn = this.authService.isAuthenticated();
    if (userLoggedIn == true) {
      this.isFooterVisible = true;
    } else {
      this.isFooterVisible = false;
    }
  }

  ngOnInit(): void {
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(
      this.onlineEvent.subscribe((e) => {
        this.connectionStatusMessage = 'You are connected to internet';
        Swal.fire({
          title: 'Info',
          text: this.connectionStatusMessage,
          timer: 5000,
          icon: 'success'
        });
        this.connectionStatus = 'online';
        console.log('Online...');
      })
    );

    this.subscriptions.push(
      this.offlineEvent.subscribe((e) => {
        this.connectionStatusMessage =
          'Connection lost! You are not connected to internet';
        Swal.fire({
          title: 'Warning',
          text: this.connectionStatusMessage,
          timer: 5000,
          icon: 'warning',
        });
        this.connectionStatus = 'offline';
        console.log('Offline...');
      })
    );
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove('sidebar-active');
  }
}
