<!--header start-->
<header class="box-absolute d-flex align-items-center topline text-white">
    <div class="page-title-center mt-3">
        <div class="text-center">
            <h1> Configuration Parameters </h1>
        </div>
    </div>
    <div class="box-absolute d-flex align-items-center topline text-white">
        <app-sidebar></app-sidebar>
        <div class="breadcrumb-div">
            <ul class="breadcrumb p-0 m-0">
                <li class="breadcrumb-item 3" id="0"><a [routerLink]="['/v1/dashboard']">Dashboard</a></li>
                <li class="breadcrumb-item 3" id="1"><a>Configuration</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)">Parameters</a></li>
            </ul>
        </div>
    </div>
</header>
<!--header end-->
<section class="banner text-center text-white d-flex" id="bnner">
    <div class="container position-relative">
        <div class="row justify-content-center">
            <div class="col-lg-12">
            </div>
        </div>
    </div>
</section>
<!--request start-->
<section class="request-demo-wrapper pb-10">
    <div class="container shadow bg-white">
        <div class="row">
            <div class="col-lg-12">
                <div class="box">
                    <form [formGroup]="clientParamForm">
                        <div class="row">
                            <div class="col-md-6 form-group form-group-inline">
                                <label class="form-label" for="hardExpiryDurationInDays">Hard Expiry Duration In
                                    Days
                                    :</label>
                                <input class="form-control" formControlName="hardExpiryDurationInDays">
                            </div>
                            <div class="col-md-6 form-group form-group-inline">
                                <label class="form-label">Lock After Max
                                    Unsuccuessful :</label>
                                <select class="form-control form-select"
                                    formControlName="lockAfterMaxUnsuccessfulAttempts">
                                    <option value="true">TRUE</option>
                                    <option value="false">FALSE</option>
                                </select>

                            </div>
                            <div class="col-md-6 form-group form-group-inline">
                                <label class="form-label" for="maxBidTimeExtensions">Max Bid Time Extensions
                                    :-</label>
                                <input class="form-control" type="number" formControlName="maxBidTimeExtensions"
                                    id="maxBidTimeExtensions" min="0">
                            </div>
                            <div class="col-md-6 form-group form-group-inline">
                                <label class="form-label" for="maxExtensionsAllowed -:"> Max Extension Allowed
                                    :-</label>
                                <input class="form-control" type="number" formControlName="maxExtensionsAllowed"
                                    id="maxExtensionsAllowed" min="0">
                            </div>
                            <div class="col-md-6 form-group form-group-inline">
                                <label class="form-label" for="maxUnsuccessfulAttempts"> Max Unsuccessful
                                    Attempts:-</label>
                                <input class="form-control" type="number" formControlName="maxUnsuccessfulAttempts"
                                    id="maxUnsuccessfulAttempts" min="0">
                            </div>
                            <div class="col-md-6 form-group form-group-inline">
                                <label class="form-label" for="otpExpiryInMinutes"> OTP Expires In Minutes
                                    :-</label>
                                <input class="form-control" type="number" formControlName="otpExpiryInMinutes"
                                    id="otpExpiryInMinutes" min="0">
                            </div>
                            <div class="col-md-6 form-group form-group-inline">
                                <label class="form-label" for="passwordExpiryDays"> Password Expiry Days:-</label>
                                <input class="form-control" type="number" formControlName="passwordExpiryDays" min="0">
                            </div>
                            <div class="col-md-6 form-group form-group-inline">
                                <label class="form-label" for="rfqDueReminersBeforeDueDate">RFQ Due Reminers Before
                                    Due Date :</label>
                                <input class="form-control" type="number" formControlName="rfqDueReminersBeforeDueDate"
                                    id="rfqDueReminersBeforeDueDate" min="0">
                            </div>
                            <div class="col-md-6 form-group form-group-inline">
                                <label class="form-label" for="softExpiryDurationInDays">Soft Expiry Duration In
                                    Days
                                    :</label>
                                <input class="form-control" type="number" formControlName="softExpiryDurationInDays"
                                    id="softExpiryDurationInDays" min="0">
                            </div>
                        </div>
                        <div class="col-md-6 text-center form-group form-group-inline">
                            <button class="btn-primary" *ngIf="updateButtonBool" (click)="updateClientParam()">
                                UPDATE
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>