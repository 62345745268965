import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MasterTableService } from 'src/app/helpers/master-table.service';
import { ShipmentService } from 'src/app/helpers/shipment.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-client-track-air-package',
  templateUrl: './client-track-air-package.component.html',
  styleUrls: ['./client-track-air-package.component.css']
})
export class ClientTrackAirPackageComponent implements OnInit {

  accordionHeading: any
  accordionBody: any

  getSearchValue: any

  testReponse: any = {}

  allEvents: Array<any> = []
  consignments: any
  currentEvent: any
  shipmentId: any

  //array to show route 
  uniquePortArray: Array<any> = []
  // finalRouteListToShow: Array<any> = []

  detailsArray: Array<any> = []

  //to store all the port list
  allPortsList: Array<any> = []

  //to store origin value
  mainOriginValue: any

  //to store destination value
  mainDestinationValue: any

  //to store current airport full name
  currentAirPortName: any

  //to store final routes as a string
  routeSingleStringValue: string

  finalDetailArray: Array<any> = []
  noData: Boolean = false;

  constructor(private router: ActivatedRoute, private shipmentService: ShipmentService, private masterTableService: MasterTableService) { }

  ngOnInit(): void {
    this.router.params.subscribe((routeData) => {
      if (routeData.id) {
        this.shipmentId = routeData.id;
        this.fetchShipmentById(this.shipmentId);
      }
    });

  }

  // "arrived",     "arrival documentation delivered",     "arrival documentation received",     "booked",     "customs cleared",     "reported to customs",     "delivered at door",     "departed",     "discrepancy",     "delivered",     "freight on hand",     "manifested",     "consignee notified",     "freight prepared",     "received from flight",     "received from shipper",     "received from carrier",     "transferred to carrier",     "transferred to customs",     "transfer manifested"

  // EXPORT 
  // RCS - 
  // RCT - 
  // MAN - 
  // PRE - 
  // DEP -

  // IMPORT 
  // RCF - 
  // TRM - 
  // TFD - 
  // CRC - 
  // CCD - 
  // AWD - 
  // NFD - 
  // DLV - 
  // DDL - 
  // DIS -

  sortReponseEventsAccordingType() {
    this.uniquePortArray = []
    let sortRes: Array<any> = this.testReponse.events

    this.allEvents =
      sortRes.sort((val1, val2) => {
        return new Date(val1.timeOfEvent).getTime() - new
          Date(val2.timeOfEvent).getTime()
      })

    console.log('all events before sorted by time of event ', sortRes);
    console.log('all events after sorted by time of event ', this.allEvents);

    for (let index = 0; index < this.allEvents.length; index++) {
      const element = this.allEvents[index];
      // console.log('this is time of event ', element.timeOfEvent);

      if (element.type == 'booked' && this.uniquePortArray.length == 0) {
        console.log('this is item after statys booked ', element);
        if (this.testReponse.originAndDestination.origin == element.origin) {
          let data = {
            "port": element.origin,
            "flight": element.flight,
            "origin": element.origin
          }
          this.uniquePortArray.push(data)
        }
      }
      else if (element.type == 'booked') {
        console.log(element);
        if (element.origin != this.testReponse.originAndDestination.origin) {
          console.log('element origin is not equal to main origin ');
          let data = {
            "port": element.origin,
            "flight": element.flight,
            "origin": element.origin
          }
          this.uniquePortArray.push(data)
        }
      }
      else if (element.type == 'manifested') {
        console.log('this is type manifested log');
        if (element.origin == this.testReponse.originAndDestination.origin) {
          let data = {
            "port": element.origin,
            "flight": element.flight,
            "origin": element.origin
          }
          this.uniquePortArray.push(data)
        }
      }
      else if (element.type == 'departed') {
        if (element.destination == this.testReponse.originAndDestination.destination) {
          let data = {
            "port": element.destination,
            "flight": element.flight,
            "destination": element.destination
          }
          this.uniquePortArray.push(data)
        }
      }

    }

    //this key is used to filter out the duplicate objects from array
    // const key = 'port';
    // const arrayUniqueByKey = [...new Map(this.uniquePortArray.map(item =>
    //   [item[key], item])).values()];
    // this.finalRouteListToShow = arrayUniqueByKey

    console.log('this is unique array ', this.uniquePortArray);

    let test = this.uniquePortArray.map((item) => {
      return item['port'];
    })

    this.uniquePortArray.map((value)=>{
      let detailsForOnePort: Array<any> = []
      for (let index = 0; index < this.allEvents.length; index++) {
        const element = this.allEvents[index];
        if(value.port == element.port){
          detailsForOnePort.push(element)
        }
      }
      let data = { "port": detailsForOnePort[0].port, "flight": detailsForOnePort[0].flight, "groupOfDetails": detailsForOnePort}

      this.detailsArray.push(data)
    })

    console.log('this is details array ', this.detailsArray);
    

    this.routeSingleStringValue = test.join('-')
    // console.log('this is test data ', this.routeSingleStringValue);

    this.createCustomDetailsArray();
  }

  //note:- if current event data matches to loop data we can change the color of that index

  fetchShipmentById(shipmentId: any) {
    this.shipmentService.fetchShipmentById(shipmentId).subscribe
      ((res) => {
        console.log('this is reponse ', res);
        for (let index = 0; index < res.waybillIdentification.length; index++) {
          const waybillIdentification = res.waybillIdentification[index];
          this.getSearchValue = waybillIdentification.waybillIdentification
          this.onClickSearch();
        }
      },
        (error) => {
          console.log(error);
        })
  }

  fetchAirShipmentByIdOrByAirWaybillNumber(airWaybillNumber: number, id: number){
    this.shipmentService.fetchAirShipmentByIdOrByAirWaybillNumber(airWaybillNumber, id).subscribe(
      (response) => {
        this.testReponse = response;
        this.noData = true;
        this.getLoadingDestinationPort();
        this.workingOnTestJson();
        this.sortReponseEventsAccordingType();
        this.findCurrentAirPortNameByCode();
      },
      (error) => {
        console.log(error);
        this.noData = false;
        let _error = error.error;
        if(_error.errorCode){
          Swal.fire({
            title: "Error",
            text: _error.errorMessage,
            timer: 5000,
            icon: "error"
          });
        }
      },
    )
  }

  workingOnTestJson() {
    let currentEventDummy: any
    console.log(this.testReponse);
      if (this.testReponse?.quantity?.weight?.unit == "KILOGRAM") {
        this.testReponse.quantity.weight['shortUnit'] = "KG"
      }

      for (let index = 0; index < this.testReponse.events.length; index++) {
        const event = this.testReponse.events[index];
        currentEventDummy = event
        if (currentEventDummy.weight?.unit == "KILOGRAM") {
          currentEventDummy.weight['shortUnit'] = "KG"
        }
        if (event.type == "manifested") {
          currentEventDummy['port'] = event.origin
        }
        else if (event.type == "received from shipper") {
          currentEventDummy['port'] = event.airportOfReceipt
        }
        else if (event.type == "freight on hand") {
          currentEventDummy['port'] = event.airportOfReceipt
        }
        else if (event.type == "booked") {
          currentEventDummy['port'] = event.origin
        }
        else if (event.type == "departed") {
          currentEventDummy['port'] = event.origin
        }
        else if (event.type == "received from flight") {
          currentEventDummy['port'] = event.desination
        }
        else if (event.type == "arrived") {
          currentEventDummy['port'] = event.desination
        }
        else if (event.type == "received from flight") {
          currentEventDummy['port'] = event.desination
        }
        else if (event.type == "consignee notified") {
          // console.log('currently this event is occured ');

          currentEventDummy['port'] = event.airportOfNotification
          // console.log('this is type consignee notified and airportOfNotification ', event.airportOfNotification);
        }
        else if (event.type == "delivered") {
          currentEventDummy['port'] = event.airportOfDelivery
        }
      }
    // }

    // let latestEvent = this.consignments.events.pop()
    this.currentEvent = currentEventDummy

    //here we get the last index as the current status
    console.log('out of for loop current event log ', this.currentEvent);

  }

  onClickReset() {
    this.getSearchValue = ''
  }

  onClickSearch() {
    console.log('this is entered value ', this.getSearchValue);
    this.fetchAirShipmentByIdOrByAirWaybillNumber(this.getSearchValue, null);
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  //fuction to get all loading and desination port list
  getLoadingDestinationPort() {
    this.masterTableService.getLoadingDestinationPort().subscribe(
      (response) => {
        this.allPortsList = response
        this.findOriginAndDesinationFromList();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  //fuction to find value of origin and desination port code from All loading and desination port list
  findOriginAndDesinationFromList() {
    this.allPortsList.filter((res) => {
      if (this.testReponse.originAndDestination.origin == res.code) {
        this.mainOriginValue = res
      }
      if (this.testReponse.originAndDestination.destination == res.code) {
        this.mainDestinationValue = res
      }
    })
    this.findCurrentAirPortNameByCode();
  }

  findCurrentAirPortNameByCode() {
    if (this.currentEvent.port != '' || null || undefined) {
      this.allPortsList.filter((res) => {
        if (this.currentEvent.port == res.code) {
          // console.log('custome filtering ', res);
          this.currentAirPortName = res.value
        }
      })
    }
  }

  createCustomDetailsArray() {
    this.uniquePortArray
    this.finalDetailArray

    for (let index = 0; index < this.uniquePortArray.length; index++) {
      const element = this.uniquePortArray[index];
      // console.log('this is element ', element);

    }
  }

}
