import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MasterTableService } from 'src/app/helpers/master-table.service';
import { UserService } from 'src/app/helpers/user.service';
import Swal from 'sweetalert2';
import { ShipmentDialogBoxComponent } from '../mode-of-shipment/shipment-dialog-box/shipment-dialog-box.component';
import { DialogMarketingSpaceComponent } from './dialog-marketing-space/dialog-marketing-space.component';

@Component({
  selector: 'app-marketing-space',
  templateUrl: './marketing-space.component.html',
  styleUrls: ['./marketing-space.component.css'],
})
export class MarketingSpaceComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<any>;
  displayedColumns = ['id', 'companyName', 'action'];
  serchFilter: Boolean = false;
  nodata: Boolean = true;
  page: number = 0;
  size: number = 25;
  length: number;

  sessionStorageUserData: any;
  disableCreateBtn: boolean = true
  disableEditBtn: boolean = true;
  disableDeleteBtn: boolean = true;

  constructor(
    private service: MasterTableService,
    private dialog: MatDialog,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.getMarketingSpacePagination();
    this.getCurrentUserDataFromSessionStorage();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.serchFilter = true;
    this.nodata = false;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDialog(action: String, obj: any) {
    obj.action = action;
    const dialogRef = this.dialog.open(DialogMarketingSpaceComponent, {
      width: '80%',
      height: '90%',
      data: obj,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.event == 'Add') {
        this.addRowData(result.data);
      } else if (result.event == 'Update') {
        this.updateRowData(result.data);
      } else if (result.event == 'Delete') {
        this.deleteRowData(result.data);
      }

      this.getMarketingSpacePagination();
    });
  }

  addRowData(row_obj) {
    this.AddModeOfShipment(row_obj);
    setTimeout(() => {
      this.getMarketingSpacePagination();
    }, 500);
  }
  updateRowData(row_obj) {
    this.updateModeOfShipment(row_obj.id, row_obj.value);
    setTimeout(() => {
      this.getMarketingSpacePagination();
    }, 500);
  }
  deleteRowData(row_obj) {
    this.deleteModeOfShipment(row_obj.id);
    setTimeout(() => {
      this.getMarketingSpacePagination();
    }, 500);
  }

  getMarketingSpacePagination() {
    this.service.getAllMarketSpacePageable(this.page, this.size).subscribe(
      (response) => {
        if (response.content != null) {
          console.log('this is response content ', response.content);

          this.dataSource = new MatTableDataSource(response.content);
          this.dataSource.sort = this.sort;
          this.length = response.totalElements;
        }
      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: 'Warning',
            icon: 'warning',
            text: _error.errorMessage,
            timer: 5000,
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: 'Warning',
            icon: 'warning',
            text: _error.errorMessage,
            timer: 5000,
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: 'Warning',
            icon: 'warning',
            text: _error.errorMessage,
            timer: 5000,
          });
        } else {
          Swal.fire({
            titleText: 'Error',
            icon: 'error',
            text: 'Something Went Wrong',
            timer: 5000,
          });
        }
      }
    );
  }
  AddModeOfShipment(data) {
    this.service.AddModeOfShipment(data).subscribe(
      (response) => {
        if (response.code == 201) {
          Swal.fire({
            titleText: 'Success',
            icon: 'success',
            text: response.message,
            timer: 5000,
          });
        }
      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: 'Warning',
            icon: 'warning',
            text: _error.errorMessage,
            timer: 5000,
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: 'Warning',
            icon: 'warning',
            text: _error.errorMessage,
            timer: 5000,
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: 'Warning',
            icon: 'warning',
            text: _error.errorMessage,
            timer: 5000,
          });
        } else {
          Swal.fire({
            titleText: 'Error',
            icon: 'error',
            text: 'Something Went Wrong',
            timer: 5000,
          });
        }
      }
    );
  }
  updateModeOfShipment(id: any, value: any) {
    const data = { value: value };
    this.service.updateModeOfShipment(id, data).subscribe(
      (response) => {
        if (response.code == 201) {
          Swal.fire({
            titleText: 'Success',
            icon: 'success',
            text: response.message,
            timer: 5000,
          });
        }
      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: 'Warning',
            icon: 'warning',
            text: _error.errorMessage,
            timer: 5000,
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: 'Warning',
            icon: 'warning',
            text: _error.errorMessage,
            timer: 5000,
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: 'Warning',
            icon: 'warning',
            text: _error.errorMessage,
            timer: 5000,
          });
        } else {
          Swal.fire({
            titleText: 'Error',
            icon: 'error',
            text: 'Something Went Wrong',
            timer: 5000,
          });
        }
      }
    );
  }
  deleteModeOfShipment(id: any) {
    this.service.deleteModeOfShipment(id).subscribe(
      (response) => {
        if (response.code == 201) {
          Swal.fire({
            titleText: 'Success',
            icon: 'success',
            text: response.message,
            timer: 5000,
          });
        }
      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: 'Warning',
            icon: 'warning',
            text: _error.errorMessage,
            timer: 5000,
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: 'Warning',
            icon: 'warning',
            text: _error.errorMessage,
            timer: 5000,
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: 'Warning',
            icon: 'warning',
            text: _error.errorMessage,
            timer: 5000,
          });
        } else {
          Swal.fire({
            titleText: 'Error',
            icon: 'error',
            text: 'Something Went Wrong',
            timer: 5000,
          });
        }
      }
    );
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove('sidebar-active');
  }

  onPageChange(event: PageEvent) {
    this.size = event.pageSize;
    this.page = event.pageIndex;
    this.getMarketingSpacePagination();
  }

  getCurrentUserDataFromSessionStorage(){
    // create auth code
    // RB0000249
    // update auth code
    // RB0000251
    // delete auth code
    // RB0000252
      this.sessionStorageUserData = JSON.parse(sessionStorage.getItem('userData'));
      console.log('this is user data ', this.sessionStorageUserData);
      for (let index = 0; index < this.sessionStorageUserData.authorities.length; index++) {
        const authority = this.sessionStorageUserData.authorities[index];
        console.log(authority);
        if(authority == 'RB0000249'){
          console.log('create authority is given');
          this.disableCreateBtn = false
        } else if(authority == 'RB0000251'){
          console.log('update authority is given');
          this.disableEditBtn = false
        } else if(authority == 'RB0000252') {
          console.log('delete authority is given');
          this.disableDeleteBtn = false
        }
      }
    }

  deleteMarketSpace(id: any, companyName: string) {
    Swal.fire({
      title: '',
      // text: 'Are you sure you want to delete ', companyName,
      html: `Are you sure you want to delete <br> <b> ${companyName} </b>`,
      icon: 'question',
      showCancelButton: true,
      showConfirmButton: true,
    }).then((res) => {
      console.log('this is response', res);
      if (res.isConfirmed == true) {
        this.service.deleteMarketSpace(id).subscribe({
          next: (res) => {
            console.log('this is res ', res);
            Swal.fire({
              title: 'Success',
              text: res.message,
              icon: 'success',
              timer: 5000,
            });
            this.getMarketingSpacePagination();
          },
          error: (error) => {
            console.log('this is error ', error);
          },
        });
      }
    });
  }
}
