import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MasterTableService } from 'src/app/helpers/master-table.service';
import { ShipmentService } from 'src/app/helpers/shipment.service';
import { SocketService } from 'src/app/helpers/socket.service';
import Swal from 'sweetalert2';
import { ClientService } from '../../helpers/client-service.service';

@Component({
  selector: 'app-client-flight-schedule',
  templateUrl: './client-flight-schedule.component.html',
  styleUrls: ['./client-flight-schedule.component.css']
})
export class ClientFlightScheduleComponent implements OnInit {

  showTable: boolean = false;
  carrierList: Array<any> = [];
  selectedCarriers:Array<any> = [];
  selectedItems: Array<any> = [];
  dropdownSettings: IDropdownSettings = {};
  searchOriginValue: any;
  searchDestinationValue: any;
  loadingPorts: Array<any> = [];
  destinationPorts: Array<any> = [];
  flightSheduleForm: FormGroup;
  flightArray: Array<any> = [];


  constructor(private clientService: ClientService, public datepipe: DatePipe, private socketService: SocketService,private masterService: MasterTableService, 
    private fb: FormBuilder, private shipmentService: ShipmentService) { }

  ngOnInit(): void {
    this.getCarrierList();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'iataCode',
      textField: 'airlineName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true
    };

    this.flightSheduleForm = this.fb.group({
      carrier: this.fb.array([]),
      origin: ['', Validators.required],
      destination: ['', Validators.required],
      date: ['', Validators.required],
      earliestDepartureTime: ['00:00:00']
    });
    // this.socketService.connectTOServerPopUpFuction();
  }

  get flightForm(){ return this.flightSheduleForm.controls; }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }


  getCarrierList(){
    this.masterService.getCarriersListing().subscribe(
      (response) => {
        this.carrierList = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  onItemSelect(event) {
    this.selectedCarriers.push(event.iataCode);
  }

  onSelectAll(event: Array<any>) {
    this.selectedCarriers = [];
    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      this.selectedCarriers.push(element.iataCode);
    }
  }

  onDeSelect(event) {
    this.selectedCarriers.filter((element, index) => {
      if (element == event.iataCode) {
        this.selectedCarriers.splice(index, 1);
      }
    })
  }

  onDeSelectAll() {
    this.selectedCarriers = [];
  }

  getSearchResultsOrigin(){
    let modeOfShipmentValue = 1;
    let size = 50
    if(this.searchOriginValue != ""){
      this.masterService.getSearchPort(modeOfShipmentValue, this.searchOriginValue, size).subscribe((res)=>{
        this.loadingPorts = res
      },
      (error)=>{
        console.log(error);
      })
    } else {
      console.log('search value is empty ', this.searchOriginValue);
    }
  }

  getSearchResultDestination(){
    let modeOfShipmentValue = 1;
    let size = 50
    if(this.searchDestinationValue != ""){
      this.masterService.getSearchPort(modeOfShipmentValue, this.searchDestinationValue, size).subscribe((res)=>{
        this.destinationPorts = res
      },
      (error)=>{
        console.log(error);
      })
    } else {
      console.log('search value is empty ', this.searchDestinationValue);
    }
  }

  submit(){
    if(this.flightSheduleForm.valid){
      let data: any = this.flightSheduleForm.value;
      data['carrier'] = this.selectedCarriers;
      this.shipmentService.getFlightsShedules(data).subscribe(
        (response) => {
          if(response != null){
            console.log(response);
            this.showTable = true;
            let carrier: Array<any> = response.carriers;
            for (let index = 0; index < carrier.length; index++) {
              const element = carrier[index];
              this.populateRoutes(element.routes);
            }
          }
        },
        (error) => {
          console.log(error);
        },
      )
    }
    else{
      console.log("FORM INVALID");
      Swal.fire({
        title: "Warning",
        text: "Fill all mandatory field",
        timer: 5000,
        icon: "warning"
      });
    }
  }

  getTodayDate() {
    return new Date().toISOString().split('T')[0]
  }

  populateRoutes(routes: Array<any>){
    for (let index = 0; index < routes.length; index++) {
      const element = routes[index];
      let flights: Array<any> = element.flights;
      this.populateFlights(flights);
    }
  }

  populateFlights(flights: Array<any>){
    for (let index = 0; index < flights.length; index++) {
        const _element = flights[index];
        this.flightArray.push(_element);
    }
    console.log(this.flightArray);
  }


}
