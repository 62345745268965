import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MasterTableService } from 'src/app/helpers/master-table.service';
import Swal from 'sweetalert2';
import { CountryDialogBoxComponent } from './country-dialog-box/country-dialog-box.component';

@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.css']
})
export class CountryComponent implements OnInit {

  serchFilter: boolean;
  nodata: boolean;
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['id', 'country', 'currency', 'actions'];
  page: number = 0;
  size: number = 25;
  totalPages: number = 0;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  countryLenght: number = 0;

  sessionStorageUserData: any
  disableCreateBtn: boolean = true
  disableEditBtn: boolean = true
  disableDeleteBtn: boolean = true

  constructor(private masterService: MasterTableService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getAllCountries();
    this.getCurrentUserDataFromSessionStorage();
  }

  getAllCountries() {
    this.masterService.getCountriesPageable(this.page, this.size).subscribe(
      (response) => {
        console.log(response);
        let content:Array<any> = response.content;
        if(content.length > 0){
          this.countryLenght = response.totalElements;
          this.dataSource = new MatTableDataSource(content);
          this.dataSource.sort = this.sort;
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  onPageChange(event: PageEvent) {
    this.size = event.pageSize;
    this.page = event.pageIndex;
    this.getAllCountries();
  }

  openDialog(action, obj) {
    obj.action = action;
    const dialogRef = this.dialog.open(CountryDialogBoxComponent, {
      width: '250px',
      data: obj
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      if (result.event == 'Create') {
        this.addCountry(result.data);
      }
      else if (result.event == 'Update') {
        this.updateCountry(result.data.id, result.data)
      } else if (result.event == 'Delete') {
        this.deleteCountry(result.data.id);
      }
      this.getAllCountries();
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.serchFilter = true;
    this.nodata = false;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    if (filterValue != null && filterValue != '') {
      this.serchFilter = true;
    }
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  addCountry(data) {
    this.masterService.addCountry(data).subscribe(
      (response) => {
        console.log(response);
        if (response.code == 201) {
          Swal.fire({
            titleText: "Success",
            icon: "success",
            text: response.message,
            timer: 5000
          })
        }
        this.getAllCountries();
      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      },
    )
  }

  updateCountry(id, data) {
    this.masterService.updateCountry(id, data).subscribe(
      (response) => {
        console.log(response);
        if (response.code == 201) {
          Swal.fire({
            titleText: "Success",
            icon: "success",
            text: response.message,
            timer: 5000
          })
        }
        this.getAllCountries();
      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      },
    )
  }

  deleteCountry(id) {
    this.masterService.deleteCountry(id).subscribe(
      (response) => {
        console.log(response);
        if (response.code == 201) {
          Swal.fire({
            titleText: "Success",
            icon: "success",
            text: response.message,
            timer: 5000
          })
        }
        this.getAllCountries();
      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      },
    )
  }

  getCurrentUserDataFromSessionStorage(){
    // create auth code
    // RB0000099
    // update auth code
    // RB0000102
    // delete auth code
    // RB0000103
      this.sessionStorageUserData = JSON.parse(sessionStorage.getItem('userData'));
      console.log('this is user data ', this.sessionStorageUserData);
      for (let index = 0; index < this.sessionStorageUserData.authorities.length; index++) {
        const authority = this.sessionStorageUserData.authorities[index];
        console.log(authority);
        if(authority == 'RB0000099'){
          console.log('create authority is given');
          this.disableCreateBtn = false
        } else if(authority == 'RB0000102'){
          console.log('update authority is given');
          this.disableEditBtn = false
        } else if(authority == 'RB0000103') {
          console.log('delete authority is given');
          this.disableDeleteBtn = false
        }
      }
    }

}
