import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-platform-dialog-box',
  templateUrl: './platform-dialog-box.component.html',
  styleUrls: ['./platform-dialog-box.component.css']
})
export class PlatformDialogBoxComponent implements OnInit {

  dialogform: FormGroup;
  local_data: any;
  action: any;
  isReadOnly = false;
  isrequired = true;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<PlatformDialogBoxComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {

    this.action = data.action;
    this.local_data = { ...data };
    if (this.action == 'Update') { this.isReadOnly = true; this.isrequired = false; }
  }
  ngOnInit(): void {
    this.dialogform = this.formBuilder.group({
      id: [""],
      paramKey: [""],
      paramValue: [""]
    });
    if (this.action != "Add") {
      this.dialogform.patchValue({
        paramKey: this.local_data.paramKey,
        paramValue: this.local_data.paramValue,
        id: this.local_data.id,
      });
    }
  }
  doAction() {
    if (this.dialogform.valid) {
      this.dialogRef.close({ event: this.action, data: this.dialogform.value });
    } else {
      Swal.fire({
        titleText: "Please provide all mandatory fields",
        icon: "warning",
        timer: 3000
      })
    }
  }
  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }
}
