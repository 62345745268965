import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportService } from 'src/app/helpers/report.service';
import { ShipmentService } from 'src/app/helpers/shipment.service';
import { SocketService } from 'src/app/helpers/socket.service';
import { AlertService } from 'src/app/utils/sweet-alert.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-client-shipment-list',
  templateUrl: './client-shipment-list.component.html',
  styleUrls: ['./client-shipment-list.component.css']
})
export class ClientShipmentListComponent implements OnInit {

  /**
   * Paginator Viewchild variable
   */
  @ViewChild(MatPaginator) paginator: MatPaginator;

  /**
   * Mat sort sorting view child variable
   */
  @ViewChild(MatSort) sort!: MatSort;

  /**
   * Datasource for storing shipment data
   */
  dataSource!: MatTableDataSource<any>;

  /**
   * Variable to store Shipment List
   */
  shipmentList = [];

  /**
   * Variable for Table columns display
   */
  displayedColumns: string[] = ['createdDate', 'modeOfShipment', 'waybillIdentification', 'bookingNumber', 'containerNumber', 'blContainersRef', 'actions'];

  /**
   * Variable for storing list of selected shipments
   */
  trackList = [];
  page: number = 0;
  size: number = 25;
  length: number = 0;
  countAtOrigin: number = 0;
  countAtPOD: number = 0;
  countInTransit: number = 0;
  countInTranshipment: number = 0;
  activeShipments: number = 0;
  shipmentStatus: Boolean = false;
  shipmentParamStatus: string = "";

  filterShipmentArray: Array<any> = [
    {code: 'containerNumber', value: "Container Number"},
    {code: 'referenceNo', value: "Reference No"},
    {code: 'blContainersRef', value: "BL Containers Ref"},
    {code: 'airWaybillNumber', value: "Air Waybill Number"},
    {code: 'internalNumber', value: "Internal Number"},
    {code: 'internalNumber', value: "Internal Number"},
    {code: 'purchaseOrderNumber', value: "Purchase Order Number"},
    {code: 'supplierRefNumber', value: "Supplier Ref Number"},
    {code: 'customerRefNumber', value: "Customer Ref Number"},
    {code: 'stuffingId', value: "Stuffing Id"},
    {code: 'soNumber', value: "SO Number"},
    {code: 'bookingNumber', value: "Booking Number"}
  ];
  filterLabel: string = '';
  filterValue: string = '';
  filterPlaceHolder: string = ''
  nodata: Boolean = false;
  serchFilter: Boolean = false;

  userDetails: any
  showShipmentCreateBtn: boolean = false
  showUpdateShipmentBtn: boolean = false;
  constructor(
    private router: Router, private route: ActivatedRoute,
    private shipmentService: ShipmentService, private reportService: ReportService,
    private alertService: AlertService, private socketService: SocketService
  ) { 
    this.filterLabel = this.filterShipmentArray[0].code
    this.filterPlaceHolder = `Enter ${this.filterShipmentArray[0].value}`
  }

  ngOnInit(): void {
    this.route.params.subscribe(
      (response) => {
        console.log(response);
        if (response.param != undefined || null) {
          let param = response.param;
          this.shipmentParamStatus = param;
          this.getAllShipmentAccordingToStatus(param);
          this.shipmentStatus = true;
        }
        else {
          this.getAllShipments();
          this.shipmentStatus = false;
        }
      }
    )
    this.userDetails = JSON.parse(sessionStorage.getItem('userData'));
    if (this.userDetails.dType == 1) {
      this.showShipmentCreateBtn = false
      this.showUpdateShipmentBtn = false;
    } 
    else if(this.userDetails.dType == 2){
      this.showShipmentCreateBtn = true;
      this.showUpdateShipmentBtn = true;
    }
    else if(this.userDetails.dType == 3){
      this.showShipmentCreateBtn = false;
      this.showUpdateShipmentBtn = true;
    }
    else {
      this.showShipmentCreateBtn = true
    }
    this.getAllShipmentStatusCount();
  }

  /**
   * Function to get all shipments
   */
  getAllShipments() {
    this.shipmentService.getAllShipments(this.page, this.size).subscribe((data) => {
      if (data && data?.content.length > 0) {
        for (let index = 0; index < data.content.length; index++) {
          const element = data.content[index];
          if (element.modeOfShipment == 1) {
            element["modeOfShipmentLabel"] = "AIR"
          }
          else if (element.modeOfShipment == 2) {
            element["modeOfShipmentLabel"] = "SEA"
          }
          else if (element.modeOfShipment == 3) {
            element["modeOfShipmentLabel"] = "ROAD"
          }
          else {
            element["modeOfShipmentLabel"] = "RAIL"
          }
        }

        this.shipmentList = data.content;
        this.dataSource = new MatTableDataSource(this.shipmentList);
        this.dataSource.sort = this.sort;
        this.length = data.totalElements;
      }
      else{
        this.nodata = true;
      }
    },
      (error) => {
        console.log(error);

      });
  }


  getAllShipmentAccordingToStatus(status) {
    this.shipmentService.getAllShipmentsStatus(this.page, this.size, status).subscribe(
      (data) => {
        console.log(data);
        if (data && data?.content) {
          for (let index = 0; index < data.content.length; index++) {
            const element = data.content[index];
            if (element.modeOfShipment == 1) {
              element["modeOfShipmentLabel"] = "AIR"
            }
            else if (element.modeOfShipment == 2) {
              element["modeOfShipmentLabel"] = "SEA"
            }
            else if (element.modeOfShipment == 3) {
              element["modeOfShipmentLabel"] = "ROAD"
            }
            else if (element.modeOfShipment == 4) {
              element["modeOfShipmentLabel"] = "RAIL"
            }
            else {
              element["modeOfShipmentLabel"] = "---"
            }
          }
          this.shipmentList = data.content;
          this.dataSource = new MatTableDataSource(this.shipmentList);
          this.dataSource.sort = this.sort;
          this.length = data.totalElements;
        }
      },
      (error) => {
        console.log(error);
    });
  }

  /**
   * Function to handle input in search textbox
   * @param event 
   */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.nodata = false;
    this.serchFilter = true;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**
   * Function to handle selection of checkbox in list
   * @param event 
   * @param row 
   */
  shipmentCheckboxSelect(event, row) {
    if (event.target.checked) {
      this.trackList.push(row);
      console.log('this is tracklist after push ', this.trackList);

    } else {
      this.trackList.splice(this.trackList.indexOf(row), 1);
      console.log('this is tracklist after splice ', this.trackList);

    }
  }

  /**
   * Function to handle "Create Shipment Order" button
   */
  createShipmentOrder() {
    this.router.navigate(['/v1/client-track-trace/shipment/creation'])
  }

  /**
   * Function to handle "Edit icon" click
   * @param id shipment id
   */
  editShipmentOrder(id) {
    this.router.navigate([`/v1/client-track-trace/shipment/edit/${id}`])
  }

  /**
   * Function to handle "Track" Button click
   * @returns 
   */
  trackButtonClick() {
    if (!this.trackList.length) {
      this.alertService.warningAlert('Select atleast one checkbox');
      return;
    } else if (this.trackList.length > 1) {
      this.alertService.warningAlert('Select only one checkbox');
    } else {
      if (this.trackList.length == 1) {
        for (let index = 0; index < this.trackList.length; index++) {
          const element = this.trackList[index];
          console.log('this is element ', element);
          if (element.modeOfShipment == 1) {
            console.log('this is Air');
            this.router.navigate(['/v1/client-track-trace/air-package-track', { id: element.id }])
          } else if (element.modeOfShipment == 2) {
            console.log('this is Sea');
            this.router.navigate(['/v1/client-track-trace/container-track', { id: element.id }])
          }
        }
      }
    }
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  onPageChange(event: PageEvent) {
    this.size = event.pageSize;
    this.page = event.pageIndex;
    if(this.shipmentStatus){
      this.getAllShipmentAccordingToStatus(this.shipmentParamStatus);
    }
    else{
      this.getAllShipments();
    }
  }

  getAllShipmentStatusCount() {
    let boooked = this.shipmentService.getAllShipmentCount('Booked')
    let sailing = this.shipmentService.getAllShipmentCount('Sailing')
    let discharge = this.shipmentService.getAllShipmentCount('Discharged')
    let active = this.shipmentService.getAllShipmentCount('Active')
    let transhipment = this.shipmentService.getAllShipmentCount('TransShipment')

    boooked.subscribe(
      (response) => {
        this.countAtOrigin = response.statusCount;
      },
      (error) => {
        console.log(error);
      },
    )

    sailing.subscribe(
      (response) => {
        this.countInTransit = response.statusCount;
      },
      (error) => {
        console.log(error);
      },
    )

    discharge.subscribe(
      (response) => {
        this.countAtPOD = response.statusCount
      },
      (error) => {
        console.log(error);
      },
    )

    active.subscribe(
      (response) => {
        this.activeShipments = response.statusCount
      },
      (error) => {
        console.log(error);
      },
    )

    transhipment.subscribe(
      (response) => {
        this.countInTranshipment = response.statusCount
      },
      (error) => {
        console.log(error);
      },
    )
  }

  filterShipments(event){
    if(event.target.value != null || ''){
      this.filterLabel = event.target.value;
      let filter = this.filterShipmentArray.find(
        (x) => {
          if(x.code == this.filterLabel){
            return x
          }
        }
      )
      this.filterPlaceHolder = `Enter ${filter.value}`
    }
  }

  filterShipmentAPI(){
    if(this.filterValue != null || ''){
      this.shipmentService.filterShipments(this.filterLabel, this.filterValue).subscribe(
        (response) => {
          console.log(response);
          let element: any = response;
          if (element.modeOfShipment == 1) {
            console.log('this is Air');
            this.router.navigate(['/v1/client-track-trace/air-package-track', { id: element.id }])
          } else if (element.modeOfShipment == 2) {
            console.log('this is Sea');
            this.router.navigate(['/v1/client-track-trace/container-track', { id: element.id }])
          }
        },
        (error) => {
          console.log(error);
        },
      )
    }
    else{
      Swal.fire({
        icon: "warning",
        timer: 5000,
        title: "Select filter & provide its value"
      });
    }
  }

}