<h1 mat-dialog-title><strong>{{action}}</strong></h1>
<div mat-dialog-content>
    <form *ngIf="action != 'Delete'; else elseBlock" [formGroup]="dialogform">
        <div formGroupName="code" >
            <mat-form-field>
                <input matInput placeholder="Code" [required]="isrequired" formControlName="code" [readonly]="isReadOnly">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Mode Of Shipment</mat-label>
                <mat-select required formControlName="modeOfShipmentId" [attr.disabled]="disabled">
                    <mat-option *ngFor="let item of modeOfShipment" [value]="item.id">
                        {{item.value}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <mat-form-field>
            <input placeholder="Value" required matInput formControlName="value">
        </mat-form-field>

        <div formGroupName="country">
            <mat-form-field>
                <mat-label>Country </mat-label>
                <mat-select required formControlName="id">
                    <mat-option *ngFor="let country of countryList" [value]="country.id">
                        {{ country.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </form>
    <ng-template #elseBlock>
        Sure to delete <b>{{local_data.value}}</b>?
    </ng-template>
</div>
<div mat-dialog-actions>
    <button style="margin-right: 20px;" class="btn-primary" mat-button (click)="doAction()">{{action}}</button>
    <button class="btn-danger" mat-button (click)="closeDialog()" mat-flat-button color="warn">Cancel</button>
</div>