<!--header start-->
<header class="box-absolute d-flex align-items-center topline text-white">
  <app-sidebar></app-sidebar>
  <div class="page-title-center mt-3">
    <div class="text-center">
      <h1>Detail Quote Comparison</h1>
    </div>
  </div>
  <div class="breadcrumb-div mt-3">
    <ul class="breadcrumb p-0 m-0">
      <li class="breadcrumb-item 3" id="0"><a href="dashboard.html">Dashboard</a></li>
      <li class="breadcrumb-item 3" id="1"><a href="">Quote History</a></li>
      <li class="breadcrumb-item"><a href="javascript:void(0)">Detail Quote Comparison</a></li>
    </ul>
  </div>
</header>
<!--header end-->
<section class="banner text-center text-white d-flex" id="bnner">
  <div class="container position-relative">
    <div class="row justify-content-center">
      <div class="col-lg-12">
      </div>
    </div>
  </div>
</section>
<!--request start-->
<section class="comparison-wrapper pb-10" (click)="hideSideBar()">
  <div class="container shadow bg-white px-start-end py-6">
    <div class="row">
      <div class="col-lg-12">
        <div class="box">
          <div class="container-fluid">
            <div class="table-responsive">
              <h3>Shipment Details</h3>
              <table class="table table-borderless table-footer-dark text-center">
                <thead class="bg-dark text-white">
                  <!-- <tr style="font-weight: bold;">
                                        <td>Particulars</td>
                                        <td *ngFor="let vqh of vendorQuoteHistoryArray; let i = index">
                                            {{i + 1}}
                                        </td>
                                    </tr> -->
                </thead>
                <tbody>
                  <tr *ngFor="let out of outPut[0]; index as i">
                    <td style="font-weight: bold;">{{ out }}</td>
                    <td *ngFor="let vqh of outPut[1][i]">
                      {{ vqh }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="containerDetails" class="table-responsive">
              <h3> Container Charges</h3>
              <table class="table table-borderless table-footer-dark text-center">
                <thead class="bg-dark text-white">
                  <tr style="font-weight: bold;">
                    <td>Particulars</td>
                    <td *ngFor="let vqh of vendorQuoteHistoryArray;let i=index">
                      {{i + 1}}
                    </td>
                  </tr>
                </thead>
                <tbody *ngFor="let addContainer of maxContainerArray">
                  <tr>
                    <td style="font-weight: bold;">Container Type</td>
                    <td *ngFor="let vqh of vendorQuoteHistoryArray">
                      <!-- for vendor quote row -->
                      <div class="" *ngIf="vqh.vendorQuoteContainers">
                        {{vqh.vendorQuoteContainers[addContainer].container.containerType}}
                      </div>

                      <!-- for vendor quote row history-->
                      <div class="" *ngIf="vqh.vendorQuoteContainersHistory">
                        {{vqh.vendorQuoteContainersHistory[addContainer].container.containerType}}
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td style="font-weight: bold;">Quantity</td>
                    <td *ngFor="let vqh of vendorQuoteHistoryArray">
                      <!-- for vendor quote row -->
                      <div class="" *ngIf="vqh.vendorQuoteContainers">
                        {{vqh.vendorQuoteContainers[addContainer].container.quantity}}
                      </div>
                      <!-- for vendor quote row history-->
                      <div class="" *ngIf="vqh.vendorQuoteContainersHistory">
                        {{vqh.vendorQuoteContainersHistory[addContainer].container.quantity}}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold;">Rate / Container</td>
                    <td *ngFor="let vqh of vendorQuoteHistoryArray">
                      <div class="" *ngIf="vqh.vendorQuoteContainers">
                        {{vqh.vendorQuoteContainers[addContainer].ratePerContainer}}
                      </div>
                      <div class="" *ngIf="vqh.vendorQuoteContainersHistory">
                        {{vqh.vendorQuoteContainersHistory[addContainer].ratePerContainer}}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold;">Total</td>
                    <td *ngFor="let vqh of vendorQuoteHistoryArray">
                      <div class="" *ngIf="vqh.vendorQuoteContainers">
                        {{vqh.vendorQuoteContainers[addContainer].totalFreight}}
                      </div>
                      <div class="" *ngIf="vqh.vendorQuoteContainersHistory">
                        {{vqh.vendorQuoteContainersHistory[addContainer].totalFreight}}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td [colSpan]="[vendorQuoteHistoryArray.length]+1">
                      <hr>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>


            <div *ngIf="shipmentPackages" class="table-responsive">
              <h3>Shipment Packages Charges</h3>
              <table class="table table-borderless table-footer-dark text-center">
                <thead class="bg-dark text-white">
                  <tr style="font-weight: bold;">
                    <td>Particulars</td>
                    <td *ngFor="let vqh of vendorQuoteHistoryArray;let i=index">
                      {{i + 1}}
                    </td>
                  </tr>
                </thead>
                <tbody *ngFor="let addShipment of maxShipmentArray">
                  <tr>
                    <td style="font-weight: bold;">Description</td>
                    <td *ngFor="let vqh of vendorQuoteHistoryArray">
                      <!-- for vendor quote row -->
                      <div class="" *ngIf="vqh.vendorQuoteShipmentPackages">
                        <div *ngIf="vqh.vendorQuoteShipmentPackages.length > addShipment">
                          {{vqh.vendorQuoteShipmentPackages[addShipment].description }}
                        </div>
                        <div *ngIf="vqh.vendorQuoteShipmentPackages.length <= addShipment">
                          ----
                        </div>
                      </div>

                      <!-- for vendor quote row history-->
                      <div class="" *ngIf="vqh.vendorQuoteShipmentPackagesHistory">
                        <div *ngIf="vqh.vendorQuoteShipmentPackagesHistory.length > addShipment">
                          {{vqh.vendorQuoteShipmentPackagesHistory[addShipment].description }}
                        </div>
                        <div *ngIf="vqh.vendorQuoteShipmentPackagesHistory.length <= addShipment">
                          ----
                        </div>
                      </div>

                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold;">Rate</td>
                    <td *ngFor="let vqh of vendorQuoteHistoryArray">
                      <!-- for vendor quote row -->
                      <div class="" *ngIf="vqh.vendorQuoteShipmentPackages">
                        <div *ngIf="vqh.vendorQuoteShipmentPackages.length > addShipment">
                          <div
                            *ngIf="vqh.vendorQuoteShipmentPackages[addShipment].type == 'PER_SHIPMENT' else per_unit ">
                            {{vqh.vendorQuoteShipmentPackages[addShipment].charge }}
                          </div>
                          <ng-template #per_unit>
                            {{ Math.abs(this.chargableWeight *
                            vqh.vendorQuoteShipmentPackages[addShipment].charge
                            ) }}
                          </ng-template>
                        </div>
                        <div *ngIf="vqh.vendorQuoteShipmentPackages.length <= addShipment">
                          ----
                        </div>
                      </div>
                      <!-- for vendor quote row history-->
                      <div class="" *ngIf="vqh.vendorQuoteShipmentPackagesHistory">
                        <div *ngIf="vqh.vendorQuoteShipmentPackagesHistory.length > addShipment">
                          <div
                            *ngIf="vqh.vendorQuoteShipmentPackagesHistory[addShipment].type == 'PER_SHIPMENT' else per_unit ">
                            {{vqh.vendorQuoteShipmentPackagesHistory[addShipment].charge }}
                          </div>
                          <ng-template #per_unit>
                            {{ Math.abs(this.chargableWeight *
                            vqh.vendorQuoteShipmentPackagesHistory[addShipment].charge
                            ) }}
                          </ng-template>
                        </div>
                        <div *ngIf="vqh.vendorQuoteShipmentPackagesHistory.length <= addShipment">
                          ----
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td [colSpan]="[vendorQuoteHistoryArray.length]+1">
                      <hr>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>


            <div *ngIf="breakBulkdetails" class="table-responsive">
              <h3>Break Bulk Charges</h3>
              <table class="table table-borderless table-footer-dark text-center">
                <thead class="bg-dark text-white">
                  <tr style="font-weight: bold;">
                    <td>Particulars</td>
                    <td *ngFor="let vqh of vendorQuoteHistoryArray;let i=index">
                      {{i + 1}}
                    </td>
                  </tr>
                </thead>
                <tbody *ngFor="let addBreakbulk of maxBreakbulkArray">
                  <tr>
                    <td style="font-weight: bold;">Description</td>
                    <td *ngFor="let vqh of vendorQuoteHistoryArray">
                      <!-- for vendor quote row -->
                      <div class="" *ngIf="vqh.vendorQuoteShipmentPackages">
                        <div *ngIf="vqh.vendorQuoteShipmentPackages.length > addBreakbulk">
                          {{vqh.vendorQuoteShipmentPackages[addBreakbulk].description }}
                        </div>
                        <div *ngIf="vqh.vendorQuoteShipmentPackages.length <= addBreakbulk">
                          ----
                        </div>
                      </div>

                      <!-- for vendor quote row history-->
                      <div class="" *ngIf="vqh.vendorQuoteShipmentPackagesHistory">
                        <div *ngIf="vqh.vendorQuoteShipmentPackagesHistory.length > addBreakbulk">
                          {{vqh.vendorQuoteShipmentPackagesHistory[addBreakbulk].description }}
                        </div>
                        <div *ngIf="vqh.vendorQuoteShipmentPackagesHistory.length <= addBreakbulk">
                          ----
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold;">Rate </td>
                    <td *ngFor="let vqh of vendorQuoteHistoryArray">
                      <div *ngIf="vqh.vendorQuoteShipmentPackagesHistory.length > addBreakbulk">
                        <div
                          *ngIf="vqh.vendorQuoteShipmentPackagesHistory[addBreakbulk].type == 'PER_SHIPMENT' else per_unit ">
                          {{vqh.vendorQuoteShipmentPackagesHistory[addBreakbulk].charge }}
                        </div>
                        <ng-template #per_unit>
                          {{ Math.abs(this.chargableWeight * vqh.vendorQuoteShipmentPackagesHistory[addBreakbulk].charge
                          ) }}
                        </ng-template>
                      </div>
                      <div *ngIf="vqh.vendorQuoteShipmentPackagesHistory.length <= addBreakbulk">
                        ----
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td [colSpan]="[vendorQuoteHistoryArray.length]+1">
                      <hr>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>


            <!-- {{vqh.additionalChargesHistory[addCharge].charge }} -->


            <div *ngIf="additionalCharges" class="table-responsive">
              <h3>Additional Charges</h3>
              <table class="table table-borderless table-footer-dark text-center">
                <thead class="bg-dark text-white">
                  <tr style="font-weight: bold;">
                    <td>Particulars</td>
                    <td *ngFor="let vqh of vendorQuoteHistoryArray; let i = index">
                      {{i + 1}}
                    </td>
                  </tr>
                </thead>
                <tbody *ngFor="let addCharge of maxAdditionalChargeArray">
                  <tr>
                    <td style="font-weight: bold;">Description</td>
                    <td *ngFor="let vqh of vendorQuoteHistoryArray">
                      <!-- for vendor quote row -->
                      <div class="" *ngIf="vqh.additionalCharges">
                        <div *ngIf="vqh.additionalCharges.length > addCharge">
                          {{vqh.additionalCharges[addCharge].description }}
                        </div>
                        <div *ngIf="vqh.additionalCharges.length <= addCharge">
                          ----
                        </div>
                      </div>

                      <!-- for vendor quote row history-->
                      <div class="" *ngIf="vqh.additionalChargesHistory">
                        <div *ngIf="vqh.additionalChargesHistory.length > addCharge">
                          {{vqh.additionalChargesHistory[addCharge].description }}
                        </div>
                        <div *ngIf="vqh.additionalChargesHistory.length <= addCharge">
                          ----
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold;">Rate</td>
                    <td *ngFor="let vqh of vendorQuoteHistoryArray">
                      <!-- for vendor quote row -->
                      <div class="" *ngIf="vqh.additionalCharges">
                        <div *ngIf="vqh.additionalCharges.length > addCharge">
                          <div *ngIf="vqh.additionalCharges[addCharge].type == 'PER_SHIPMENT' else per_unit ">
                            {{vqh.additionalCharges[addCharge].charge }}
                          </div>
                          <ng-template #per_unit>
                            {{ Math.abs(this.chargableWeight * vqh.additionalCharges[addCharge].charge ) }}
                          </ng-template>
                        </div>
                        <div *ngIf="vqh.additionalCharges.length <= addCharge">
                          ----
                        </div>
                      </div>

                      <!-- for vendor quote row history-->
                      <div class="" *ngIf="vqh.additionalChargesHistory">
                        <div *ngIf="vqh.additionalChargesHistory.length > addCharge">
                          <div *ngIf="vqh.additionalChargesHistory[addCharge].type == 'PER_SHIPMENT' else per_unit ">
                            {{vqh.additionalChargesHistory[addCharge].charge }}
                          </div>
                          <ng-template #per_unit>
                            {{ Math.abs(this.chargableWeight * vqh.additionalChargesHistory[addCharge].charge ) }}
                          </ng-template>
                        </div>
                        <div *ngIf="vqh.additionalChargesHistory.length <= addCharge">
                          ----
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td [colSpan]="[vendorQuoteHistoryArray.length]+1">
                      <hr>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="table-responsive">
              <table class="table table-borderless table-footer-dark text-center">
                <tfoot class="table-success">
                  <tr>
                    <td style="font-weight: bold;">Total</td>
                    <td *ngFor="let vqh of vendorQuoteHistoryArray">
                      <div>
                        {{ vqh.amount }}
                      </div>
                      <!-- <div *ngIf="vqh.vendorQuoteShipmentPackagesHistory.length > addShipment">
                                                {{vqh.vendorQuoteShipmentPackagesHistory[addShipment].description }}
                                            </div>
                                            <div *ngIf="vqh.vendorQuoteShipmentPackagesHistory.length <= addShipment">
                                                ----
                                            </div> -->
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
