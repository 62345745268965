<!--header start-->
<header class="box-absolute d-flex align-items-center topline text-white">
    <app-sidebar></app-sidebar>
    <div class="page-title-center mt-3">
      <div class="text-center">
        <h1>{{ title }}</h1>
      </div>
    </div>
    <div class="breadcrumb-div mt-3">
        <ul class="breadcrumb p-0 m-0">
            <li class="breadcrumb-item 3" id="0"><a [routerLink]="['/v1/dashboard']">Dashboard</a></li>
            <li class="breadcrumb-item 3" id="1"><a>RFQ</a></li>
            <li class="breadcrumb-item"><a>{{ title }}</a></li>
        </ul>
    </div>
</header>
<!--header end-->
<section class="banner text-center text-white d-flex" id="bnner">
    <div class="container position-relative">
        <div class="row justify-content-center">
            <div class="col-lg-12">
            </div>
        </div>
    </div>
</section>
<!--request start-->
<section class="request-demo-wrapper pb-10" (click)="hideSideBar()">
    <div class="container shadow bg-white">
        <div class="row">
            <div class="col-lg-12">
                <div class="box">
                    <mat-form-field class="form-control" appearance="standard">
                        <mat-label>Search</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
                    </mat-form-field>
                    <div class="table-responsive">
                        <table class="table table-striped table-footer-dark table-borderless" mat-table
                            [dataSource]="dataSource" matSort matSortDisableClear>

                            <ng-container matColumnDef="action">
                                <th class="bg-dark text-white" mat-header-cell mat-sort-header *matHeaderCellDef> Action </th>
                                <td mat-cell *matCellDef="let row; let i=index">
                                    <input type="checkbox" (change)="compare($event,row.id)">
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="number">
                                <th class="bg-dark text-white" mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Enquiry Number </th>
                                <td mat-cell *matCellDef="let row">
                                    {{ row.number }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="modeOfShipment">
                                <th class="bg-dark text-white" mat-header-cell *matHeaderCellDef mat-sort-header> Mode
                                    of Shipment </th>
                                <td mat-cell *matCellDef="let row">
                                    {{ row.modeOfShipmentLabel }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="origin">
                                <th class="bg-dark text-white" mat-header-cell *matHeaderCellDef mat-sort-header> Origin
                                </th>
                                <td mat-cell *matCellDef="let row">
                                    {{ row.originPortName }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="destination">
                                <th class="bg-dark text-white" mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Destination </th>
                                <td mat-cell *matCellDef="let row">
                                    {{ row.destinationPortName }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="description">
                                <th class="bg-dark text-white" mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Description </th>
                                <td mat-cell *matCellDef="let row"> {{ row.description }} </td>
                            </ng-container>
                            <ng-container matColumnDef="enquiryDate">
                                <th class="bg-dark text-white" mat-header-cell *matHeaderCellDef mat-sort-header> Enquiry Date </th>
                                <td mat-cell *matCellDef="let row">{{ row.enquiryDate | date: 'YYYY-MM-dd' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="lastSubmissionDate">
                                <th class="bg-dark text-white" mat-header-cell *matHeaderCellDef mat-sort-header> Last
                                    Submission Date </th>
                                <td mat-cell *matCellDef="let row">{{ row.lastSubmissionDate | date: 'yyyy-MM-dd HH:mm' }}
                                </td>
                            </ng-container>
                            <!-- <ng-container matColumnDef="status">
                                <th class="bg-dark text-white" mat-header-cell *matHeaderCellDef mat-sort-header> Status
                                </th>
                                <td mat-cell *matCellDef="let row">{{ row.status | uppercase }}</td>
                            </ng-container> -->


                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell text-center text-danger" colspan="9">
                                    <div *ngIf="serchFilter">
                                        No data matching the filter "{{ input.value }}"
                                    </div>
                                    <div *ngIf="nodata">
                                        <h5>Data is Not Available</h5>
                                    </div>
                                </td>
                            </tr>
                        </table>
                        <mat-paginator [pageSizeOptions]="[25,50,100]" [length]="enquiryLength" showFirstLastButtons
                            (page)="onPageChange($event)">
                        </mat-paginator>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-md-3 form-group pt-lg-3">
                            <button class="btn text-dark text-small btn-outline-success"
                                (click)="enterQuote()">Enter Quote</button>
                        </div>
                        <div class="col-md-3 form-group pt-lg-3">
                            <button class="btn text-dark text-small btn-outline-success" (click)="quoteHistory()"> Quote History</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--request end -->