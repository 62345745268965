<header class="box-absolute d-flex align-items-center topline text-white">
    <app-sidebar></app-sidebar>
    <div class="page-title-center mt-3">
        <div class="text-center">
            <h1>Shipment Order</h1>
        </div>
    </div>
    <div class="breadcrumb-div mt-3">
        <ul class="breadcrumb p-0 m-0">
            <li class="breadcrumb-item 3" id="0"><a [routerLink]="['/v1/dashboard']">Dashboard</a></li>
            <li class="breadcrumb-item 3" id="1"><a href="vendor.html">Track & Trace</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0)">Shipment</a></li>
        </ul>
    </div>
</header>
<!--header end-->

<section class="banner text-center text-white d-flex" id="bnner">
    <div class="container position-relative">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <h1>Shipment</h1>
            </div>
        </div>
    </div>
</section>

<!--Section start-->
<section class="request-demo-wrapper pb-10">
    <div class="container shadow bg-white" (click)="hideSideBar()">
        <form [formGroup]="shipmentTrackingForm" novalidate>
            <div class="row">
                <div class="tab-content form-step">
                    <div class="tab-pane active" id="item-5">
                        <div class="row">
                            <div class="col-md-4 form-group">
                                <label class="form-label" for="modeOfShipment">Mode Of Shipment*</label>
                                <select class="form-control form-select" formControlName="modeOfShipment"
                                    (change)="onModeOfShipmentChange()"
                                    [ngClass]="{'is-invalid': shipment.modeOfShipment.invalid && (shipment.modeOfShipment.touched || shipment.modeOfShipment.dirty)}">
                                    <option value="">Select One</option>
                                    <option *ngFor="let item of modeOfShipmentList" [ngValue]="item.id">{{item.value}}
                                    </option>
                                </select>
                                <div class="text-danger validation-message"
                                    *ngIf="shipment.modeOfShipment.invalid && (shipment.modeOfShipment.touched || shipment.modeOfShipment.dirty)">
                                    Mode of shipment required
                                </div>
                            </div>

                            <div *ngIf="isAirModeOfShipment">
                                <label class="form-label" for="waybillIdentification">Way Bill Number*</label>
                                <div class="row" formArrayName="waybillIdentification">
                                    <div class="col-md-4 form-group" *ngFor="let air of waybillIdentification.controls; index as i" [formGroupName]="i">
                                        <input type="text" class="form-control" formControlName="waybillIdentification"
                                        [ngClass]="{'is-invalid': waybillIdentification.at(i).get('waybillIdentification').invalid && (waybillIdentification.at(i).get('waybillIdentification').touched 
                                        || waybillIdentification.at(i).get('waybillIdentification').dirty)}">
                                        <div class="text-danger validation-message" [hidden]="!getWaybillIdentification(i)">
                                            Air Way Bill Number Required
                                        </div>
                                        <!-- <div class="" [hidden]="!getMaxWaybillIdentification(i)">
                                            Max 25 characters allowed
                                        </div> -->
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4 form-group" *ngIf="isSeaModeOfShipment">
                                <label class="form-label" for="blNumber">BL Container Reference*</label>
                                <input class="form-control" formControlName="blContainersRef" type="text"
                                    [ngClass]="{'is-invalid': shipment.blContainersRef.invalid}">
                                <div class="text-danger validation-message" *ngIf="shipment.blContainersRef.invalid">
                                    Max 25 characters allowed
                                </div>
                            </div>

                            <div class="col-md-4 form-group" *ngIf="isSeaModeOfShipment">
                                <label class="form-label" for="containerNumber">Container Number* </label>
                                <input class="form-control" formControlName="containerNumber" type="text"
                                    [ngClass]="{'is-invalid': shipment.containerNumber.invalid}">
                                <div class="text-danger validation-message" *ngIf="shipment.containerNumber.invalid">
                                    Max 25 characters allowed
                                </div>
                            </div>

                            <div class="col-md-4 form-group" *ngIf="isSeaModeOfShipment">
                                <label class="form-label" for="bookingNumber">Booking Number*</label>
                                <input class="form-control" formControlName="bookingNumber" type="text"
                                    [ngClass]="{'is-invalid': shipment.bookingNumber.invalid}">
                                <div class="text-danger validation-message" *ngIf="shipment.bookingNumber.invalid">
                                    Max 100 characters allowed
                                </div>
                            </div>

                            <div class="col-md-4 form-group" *ngIf="isSeaModeOfShipment">
                                <label class="form-label" for="containersCount">Containers Count*</label>
                                <input class="form-control" formControlName="containersCount" type="number" [ngClass]="{'is-invalid': isContainersCountValid}" (keyup)="isContainersCountEntered()">
                                <div class="text-danger validation-message" *ngIf="isContainersCountValid">
                                    Enter containers count
                                </div>
                            </div>

                            <div class="col-md-4 form-group">
                                <label class="form-label" for="invoices">Invoice Number*</label>
                                <ng-multiselect-dropdown [disabled]="isEdit"  [settings]="dropdownSettings" [data]="invoicesList"
                                    (onSelect)="onItemSelect($event)" (onDeSelect)="onDeSelect($event)"
                                    [(ngModel)]="selectedInvoice" [ngModelOptions]="{standalone: true}">
                                </ng-multiselect-dropdown>
                            </div>

                            <!-- ToDo changes needed. -->
                            <div class="col-md-4 form-group" formGroupName="shippingLine" *ngIf="isSeaModeOfShipment">
                                <label class="form-label" for="shippingLine">Shipping Line*</label>
                                <select class="form-control form-select" formControlName="id"
                                [ngClass]="{'is-invalid': shipment.shippingLine.invalid && (shipment.shippingLine.touched || shipment.shippingLine.dirty)}">
                                    <option *ngFor="let item of carrierList" [ngValue]="item.id">
                                        {{item.shippingLineName}}</option>
                                </select>
                                <div class="text-danger validation-message"
                                    *ngIf="shipment.shippingLine.invalid && (shipment.shippingLine.touched || shipment.shippingLine.dirty)">
                                    Shipping Line is required
                                </div>
                            </div>

                            <div class="col-md-4 form-group">
                                <label class="form-label" for="movementType">Movement Type*</label>
                                <select required="true" formControlName="movementType" class="form-control form-select" (change)="changeNatureOfMovement()"
                                    [ngClass]="{'is-invalid': shipment.movementType.invalid && (shipment.movementType.touched || shipment.movementType.dirty)}">
                                    <option value="" selected>Select Movement Type</option>
                                    <option *ngFor="let movementType of movementTypeList" [value]="movementType.code">
                                        {{movementType.value}}</option>
                                </select>
                                <div class="text-danger validation-message"
                                    *ngIf="shipment.movementType.invalid && (shipment.movementType.touched || shipment.movementType.dirty)">
                                    Movement Type is required</div>
                            </div>

                            <div class="col-md-4 form-group">
                                <label class="form-label" for="internalNumber">Internal Reference Number</label>
                                <input class="form-control" formControlName="internalNumber" type="text" [ngClass]="{'is-invalid': shipment.internalNumber.invalid}">
                                <div class="text-danger validation-message" *ngIf="shipment.internalNumber.invalid">
                                    Max 100 characters allowed
                                </div>
                            </div>

                            <div class="col-md-4 form-group">
                                <label class="form-label" for="customer">Customer</label>
                                <input class="form-control" formControlName="customer" type="text"
                                    [ngClass]="{'is-invalid': shipment.customer.invalid}">
                                <div class="text-danger validation-message" *ngIf="shipment.customer.invalid">
                                    Max 200 characters allowed
                                </div>
                            </div>

                            <div class="col-md-4 form-group">
                                <label class="form-label" for="customerRefNumber">Customer Reference Number</label>
                                <input class="form-control" formControlName="customerRefNumber" type="text"
                                    [ngClass]="{'is-invalid': shipment.customerRefNumber.invalid}">
                                <div class="text-danger validation-message" *ngIf="shipment.customerRefNumber.invalid">
                                    Max 100 characters allowed
                                </div>
                            </div>

                            <div class="col-md-4 form-group">
                                <label class="form-label" for="supplier">Supplier</label>
                                <input class="form-control" formControlName="supplier" type="text" [ngClass]="{'is-invalid': shipment.supplier.invalid}">
                                <div class="text-danger validation-message" *ngIf="shipment.supplier.invalid">
                                    Max 200 characters allowed
                                </div>
                            </div>

                            <div class="col-md-4 form-group">
                                <label class="form-label" for="supplierRefNumber">Supplier Reference Number</label>
                                <input class="form-control" formControlName="supplierRefNumber" type="text" [ngClass]="{'is-invalid': shipment.supplierRefNumber.invalid}">
                                <div class="text-danger validation-message" *ngIf="shipment.supplierRefNumber.invalid"> 
                                    Max 100 characters allowed
                                </div>
                            </div>

                            <div class="col-md-4 form-group">
                                <label class="form-label" for="purchaseOrderNumber">Purchase Order Number</label>
                                <input class="form-control" formControlName="purchaseOrderNumber" type="text" [ngClass]="{'is-invalid': shipment.purchaseOrderNumber.invalid}">
                                <div class="text-danger validation-message" *ngIf="shipment.purchaseOrderNumber.invalid">
                                    Max 100 characters allowed
                                </div>
                            </div>

                            <div class="col-md-4 form-group">
                                <label class="form-label" for="productName">Product Name (Internal)</label>
                                <input class="form-control" formControlName="productName" type="text" [ngClass]="{'is-invalid': shipment.productName.invalid}">
                                <div class="text-danger validation-message" *ngIf="shipment.productName.invalid">
                                    Max 100 characters allowed
                                </div>
                            </div>

                            <div class="col-md-4 form-group">
                                <label class="form-label" for="soNumber">SO Number</label>
                                <input class="form-control" formControlName="soNumber" type="text"
                                    [ngClass]="{'is-invalid': shipment.soNumber.invalid}">
                                <div class="text-danger validation-message" *ngIf="shipment.soNumber.invalid">
                                    Max 100 characters allowed
                                </div>
                            </div>

                            <div class="col-md-4 form-group">
                                <label class="form-label" for="stuffingId">Stuffing ID</label>
                                <input class="form-control" formControlName="stuffingId" type="text"
                                    [ngClass]="{'is-invalid': shipment.stuffingId.invalid}">
                                <div class="text-danger validation-message" *ngIf="shipment.stuffingId.invalid">
                                    Max 100 characters allowed
                                </div>
                            </div>

                            <div class="col-md-4 form-group">
                                <label class="form-label" for="incoterms">Pickup Incoterm</label>
                                <select formControlName="pickupIncoterms" class="form-control form-select">
                                    <option disabled selected>Select Pickup Incoterm</option>
                                    <option *ngFor="let item of pickUpIncoTerms" [value]="item.code">
                                        {{item.value}}
                                    </option>
                                </select>
                            </div>

                            <div class="col-md-4 form-group">
                                <label class="form-label" for="deliveryIncoterms">Delivery Incoterm</label>
                                <select formControlName="deliveryIncoterms" class="form-control form-select">
                                    <option disabled selected>Select Delivery Incoterm</option>
                                    <option *ngFor="let item of dropOffIncoTerms" [value]="item.code">
                                        {{item.value}}
                                    </option>
                                </select>
                            </div>

                            <div class="col-md-4 form-group">
                                <label class="form-label" for="company">Company Name</label>
                                <input class="form-control" formControlName="company" type="text"
                                    [ngClass]="{'is-invalid': shipment.company.invalid}">
                                <div class="text-danger validation-message" *ngIf="shipment.company.invalid">
                                    Max 200 characters allowed
                                </div>
                            </div>

                            <div class="col-md-4 form-group">
                                <label class="form-label" for="businessLocation">Business Location</label>
                                <input class="form-control" formControlName="businessLocation" type="text"
                                    [ngClass]="{'is-invalid': shipment.businessLocation.invalid}">
                                <div class="text-danger validation-message" *ngIf="shipment.businessLocation.invalid">
                                    Max 100 characters allowed
                                </div>
                            </div>

                            <div class="col-md-4 form-group">
                                <label class="form-label" for="channel">Channel</label>
                                <input class="form-control" formControlName="channel" type="text"
                                    [ngClass]="{'is-invalid' : shipment.channel.invalid}">
                                <div class="text-danger validation-message" *ngIf="shipment.channel.invalid">
                                    Max 100 characters allowed
                                </div>
                            </div>

                            <div class="col-md-4 form-group">
                                <label class="form-label" for="channel">Shipment Owner</label>
                                <textarea class="form-control" formControlName="shipmentOwner"
                                    [ngClass]="{'is-invalid': shipment.shipmentOwner.invalid}"></textarea>
                                <div class="text-danger validation-message" *ngIf="shipment.shipmentOwner.invalid">
                                    Max 200 characters allowed
                                </div>
                            </div>

                            <div class="col-md-4 form-group">
                                <label class="form-label" for="referenceNo">Reference No</label>
                                <input class="form-control" formControlName="referenceNo" type="text">
                            </div>


                        </div>
                    </div>

                    <!--Additonal Details Section-->
                    <div class="row mt-4">
                        <h3>Additional Details</h3>

                        <div class="col-md-4 form-group">
                            <label class="form-label" for="contractNumber">Contract Number</label>
                            <input class="form-control" formControlName="contractNumber" type="text"
                                [ngClass]="{'is-invalid': shipment.contractNumber.invalid}">
                            <div class="text-danger validation-message" *ngIf="shipment.contractNumber.invalid">
                                Max 100 characters allowed
                            </div>
                        </div>

                        <div class="col-md-4 form-group">
                            <label for="linkedBlNumber">Linked BL Number</label>
                            <input class="form-control" formControlName="linkedBlNumber" type="text" id="linkedBlNumber"
                                [ngClass]="{'is-invalid': shipment.linkedBlNumber.invalid}">
                            <div class="text-danger validation-message" *ngIf="shipment.linkedBlNumber.invalid">
                                Max 100 characters allowed
                            </div>
                        </div>

                        <div class="col-md-4 form-group">
                            <label for="blIssueDate">BL Issue Date</label>
                            <input class="form-control" formControlName="blIssueDate" type="date" id="blIssueDate">
                        </div>

                        <div class="col-md-4 form-group">
                            <label for="pgiDate">PGI Date</label>
                            <input class="form-control" formControlName="pgiDate" type="date" id="pgiDate">
                        </div>

                        <div class="col-md-4 form-group">
                            <label for="promisedDate">Promised Transit Date</label>
                            <input class="form-control" formControlName="promisedDate" type="date" id="promisedDate">
                        </div>

                        <div class="col-md-4 form-group">
                            <label for="emailAddress">Email Address</label>
                            <input class="form-control" formControlName="emailAddress" type="text" id="emailAddress">
                        </div>

                    </div>
                    <!--Additonal Details Section End-->

                    <!--Demurrage And Detention Details Section-->
                    <div class="row mt-4">
                        <h3>Demurrage And Detention Details</h3>
                        <div class="col-md-4 form-group">
                            <label class="form-label" for="demurrageFreeDays">Demurrage Free Days</label>
                            <input class="form-control" formControlName="demurrageFreeDays" type="number" maxlength="1">
                            <!-- oninput="this.value = this.value.replace(/[^0-5]/g, '').replace(/(\..*)\./g, '$1');"
                            max="1" -->
                        </div>

                        <div class="col-md-4 form-group">
                            <label class="form-label" for="detentionFreeDays">Detention Free Days</label>
                            <input class="form-control" formControlName="detentionFreeDays" type="number" min="0">
                        </div>
                        <div class="col-md-4 form-group">
                            <label for="cfsFreeDays">CSF Free Days</label>
                            <input class="form-control" formControlName="cfsFreeDays" type="number" id="cfsFreeDays"
                                min="0">
                        </div>

                    </div>
                    <!--Demurrage And Detention Details Section End-->

                    <!--Document Section-->
                    <div class="row mt-4">
                        <h3>Document</h3>
                        <app-file-upload [files]="artifactsList" artifactKey="id" [showUploadIcon]="true"
                            (uploadAllArtifacts)="uploadAllArtifacts($event)" (deleteArtifact)="deleteDocument($event)">
                        </app-file-upload>
                    </div>

                    <!--Document End-->

                    <div class="row justify-content-center">
                        <div class="col-md-6 form-group pt-4 pt-lg-5">
                            <button *ngIf="!isEdit" class="btn btn-success w-100" (click)="onSubmit()" type="submit">
                                Create
                            </button>
                            <button *ngIf="isEdit" class="btn btn-success w-100" (click)="onUpdate()" type="submit">
                                Update
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>