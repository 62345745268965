import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SocketService } from 'src/app/helpers/socket.service';

@Component({
  selector: 'app-detail-terms-attachment-box',
  templateUrl: './detail-terms-attachment-box.component.html',
  styleUrls: ['./detail-terms-attachment-box.component.css']
})
export class DetailTermsAttachmentBoxComponent implements OnInit {

  local_data: Array<any>;
  action: string;
  termsBool: Boolean = false;
  artifactsBool: Boolean = false;
  dialogForm: FormGroup;
  terms: FormArray;

  constructor(private socketService: SocketService,
    public dialogRef: MatDialogRef<DetailTermsAttachmentBoxComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder
  ) {
    this.local_data = data.data
    if (data.type == 'terms') {
      this.termsBool = true

    } else {
      this.artifactsBool = true;
      this.termsBool = false;
    }
  }
  get termsCondition() { return this.dialogForm.controls.termsArr as FormArray }

  ngOnInit(): void {
    this.dialogForm = this.fb.group({
      termsArr: this.fb.array([])
    });

    if (this.local_data.length > 0) {
      for (let index = 0; index < this.local_data.length; index++) {
        const element = this.local_data[index];
        console.log(element);
        let a = this.fb.group({
          label: element.label,
          content: element.content
        })
        this.termsCondition.push(a)
      }
    }

    // this.socketService.connectTOServerPopUpFuction();
  }

  downloadFile(data) {
    const linkSource = data.content;
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = data.name;
    downloadLink.click();
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Close' });
  }
}


