import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DemoService } from '../helpers/demo.service';

@Component({
  selector: 'app-feedback-listing',
  templateUrl: './feedback-listing.component.html',
  styleUrls: ['./feedback-listing.component.css']
})
export class FeedbackListingComponent implements OnInit {

  page: number = 0;
  size: number = 25;
  feedbackLength: number;
  displayedColumns: string[] = ['name', 'emailId', 'description'];
  dataSource!: MatTableDataSource<any>;
  serchFilter: Boolean = false;
  nodata: Boolean = true;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  title = "Feedback List";
  
  constructor(private demoService: DemoService) { }

  ngOnInit(): void {
    this.getAllFeedbackListing();
  }

  getAllFeedbackListing(){
    this.demoService.getAllFeedbacks(this.page, this.size).subscribe(
      (response) => {
        console.log(response);
        let content: Array<any> = response.content;
        this.feedbackLength = response.totalElements;
        if (content.length >= 1) {
          this.dataSource = new MatTableDataSource(response.content);
          this.dataSource.sort = this.sort;
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.nodata = false;
    this.serchFilter = true;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onPageChange(event: PageEvent) {
    this.size = event.pageSize;
    this.page = event.pageIndex;
    this.getAllFeedbackListing();
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

}
