<header class="box-absolute d-flex align-items-center topline text-white">
    <app-sidebar></app-sidebar>
    <div class="page-title-center mt-4">
        <div class="text-center">
            <h1>Request Extension</h1>
        </div>
    </div>
    <div class="breadcrumb-div mt-3">
        <ul class="breadcrumb p-0 m-0">
            <li class="breadcrumb-item 3" id="0"><a [routerLink]="['/v1/dashboard']">Dashboard</a></li>
            <li class="breadcrumb-item 3" id="1"><a>Admin</a></li>
            <li class="breadcrumb-item"><a>Request Extension</a></li>
        </ul>
    </div>
</header>
<section class="banner text-center text-white d-flex" id="bnner">
    <div class="container position-relative">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <h1></h1>
            </div>
        </div>
    </div>
</section>
<!--request start-->
<section class="request-demo-wrapper pb-10">
    <div class="container shadow bg-white px-start-end py-6" (click)="hideSideBar()">
        <div class="row">
            <div class="col-lg-12">
                <div class="box">
                    <form [formGroup]="requestextensionform" action="manage-extension.html">
                        <div class="row">
                            <div class="col-md-6 form-group">
                                <label class="form-label" for="subscription-id">Subscription ID</label>
                                <input class="form-control " type="text" placeholder="Text Input"
                                    formControlName="subscriptionId" id="subscription-id" />
                            </div>

                            <div class="col-md-6 form-group">
                                <label class="form-label" for="subscription-type">Subscription Type</label>
                                <select class="form-select" formControlName="subscriptionType">
                                    <option value="" selected>Select Type</option>
                                    <option value="GOLD">Gold</option>
                                    <option value="SILVER">Silver</option>
                                    <option value="BRONZE">Bronze</option>
                                </select>
                            </div>

                            <div class="col-md-6 form-group">
                                <label class="form-label" for="expiry-date">Licence Type</label>
                                <select class="form-select" formControlName="licenceType">
                                    <option value="" selected>Select Type</option>
                                    <option value="DEMO">Demo</option>
                                    <option value="MONTHLY">Monthly</option>
                                    <option value="YEARLY">Yearly</option>
                                </select>
                            </div>

                            <div class="col-md-6 form-group">
                                <label class="form-label" for="expiry-date">Expiry Date</label>
                                <input class="form-control" type="date" placeholder="Text Input"
                                    formControlName="expirydate" id="expiry-date" />
                            </div>

                        </div>
                        <div class="row justify-content-center pt-4 pt-lg-5">
                            <div class="col-md-6 form-group pt-lg-3">
                                <button class="btn small btn-success px-3" type="button">BUY SUBSCRIPTION</button>
                            </div>
                            <div class="col-md-6 form-group pt-lg-3">
                                <button class="btn text-success small btn-outline-success px-3" type="submit">REQUEST
                                    EXTENSION</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!--request end  -->