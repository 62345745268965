<!--header start-->
<header class="box-absolute d-flex align-items-center topline text-white">
    <app-sidebar></app-sidebar>
    <div class="page-title-center mt-3">
        <div class="text-center">
            <h1>Flight Schedule</h1>
        </div>
    </div>
    <div class="breadcrumb-div mt-3">
        <ul class="breadcrumb p-0 m-0">
            <li class="breadcrumb-item 3" id="0"><a [routerLink]="['/v1/dashboard']">Dashboard</a></li>
            <li class="breadcrumb-item 3" id="1"><a href="vendor.html">Track & Trace</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0)">Flight</a></li>
        </ul>
    </div>
</header>
<!--header end-->

<section class="banner text-center text-white d-flex" id="bnner">
    <div class="container position-relative">
        <div class="row justify-content-center">
            <div class="col-lg-12">
            </div>
        </div>
    </div>
</section>

<!--request start-->
<section class="request-demo-wrapper pb-10">
    <div class="container shadow bg-white" (click)="hideSideBar()">
        <div class="row">
            <div class="col-lg-12">
                <div class="box">
                    <form>
                        <div [formGroup]="flightSheduleForm" class="row">
                            <div class="form-group col-md-3">
                                <label class="form-label" for="">Carrier</label>
                                <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="carrierList"
                                    [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)"
                                    (onSelectAll)="onSelectAll($event)" (onDeSelect)="onDeSelect($event)"
                                    (onDeSelectAll)="onDeSelectAll()">
                                </ng-multiselect-dropdown>
                            </div>
                            <div class="form-group col-md-3">
                                <label class="form-label" for="">Origin</label>
                                <mat-select #singleSelect class="form-control" formControlName="origin">
                                    <mat-option>
                                        <ngx-mat-select-search [(ngModel)]="searchOriginValue" [ngModelOptions]="{standalone: true}"
                                            (input)="getSearchResultsOrigin()" placeholderLabel="Enter Name" noEntriesFoundLabel = 'No result available'></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let item of loadingPorts" [value]="item?.code?.code">
                                        {{ item?.value }}
                                    </mat-option>
                                </mat-select>
                                <div class="text-danger validation-message"
                                    *ngIf="flightForm.origin.invalid && ( flightForm.origin.dirty || flightForm.origin.touched)">
                                    Origin is mandatory
                                </div>
                            </div>
                            <div class="form-group col-md-3">
                                <label class="form-label" for="">Destination</label>
                                <mat-select #singleSelect class="form-control" formControlName="destination">
                                    <mat-option>
                                        <ngx-mat-select-search [(ngModel)]="searchDestinationValue" [ngModelOptions]="{standalone: true}"
                                        (input)="getSearchResultDestination()" placeholderLabel="Enter Name" noEntriesFoundLabel = 'No result available'></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let item of destinationPorts" [value]="item?.code?.code">
                                        {{ item?.value }}
                                    </mat-option>
                                </mat-select>
                                <div class="text-danger validation-message"
                                    *ngIf="flightForm.destination.invalid && ( flightForm.destination.dirty || flightForm.destination.touched)">
                                    Destination is mandatory
                                </div>
                            </div>
                            <div class="form-group col-md-3">
                                <label class="form-label" for="">Date</label>
                                <input class="form-control" type="date" placeholder="Enter Date" formControlName="date" [min]="getTodayDate()"
                                [ngClass]="{'is-invalid': flightForm.date.invalid && ( flightForm.date.dirty || flightForm.date.touched) }">
                                <div class="text-danger validation-message"
                                    *ngIf="flightForm.date.invalid && ( flightForm.date.dirty || flightForm.date.touched)">
                                    Date is mandatory
                                </div>
                            </div>

                            <button class="btn btn-primary mt-2" (click)="submit()" type="button">Submit</button>
                        </div>

                        <div *ngIf="showTable" class="table table-responsive mt-4">
                            <table class="table table-borderless table-striped table-footer-dark table-head-dark">
                                <thead>
                                    <tr>
                                        <th>Flight Number</th>
                                        <th>Airport Of Departure</th>
                                        <th>Airport Of Arrival</th>
                                        <th>Time Of Departure</th>
                                        <th>Time Of Arrival</th>
                                        <th>Aircraft Type Code</th>
                                        <th>Number Of Stops</th>
                                        <th>Weight</th>
                                        <th>Volume</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let flight of flightArray">
                                        <td>{{ flight.flightNumber }}</td>
                                        <td>{{ flight.airportOfDeparture }}</td>
                                        <td>{{ flight.airportOfArrival }}</td>
                                        <td>{{ flight.timeOfDeparture }}</td>
                                        <td>{{ flight.timeOfArrival }}</td>
                                        <td>{{ flight.aircraftTypeCode }}</td>
                                        <td>{{ flight.numberOfStops }}</td>
                                        <td>{{ flight.weight.amount }}  {{ flight.weight.unit }} </td>
                                        <td>{{ flight.volume.amount }}  {{ flight.volume.unit }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>