import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MasterTableService } from 'src/app/helpers/master-table.service';
import Swal from 'sweetalert2';
import { TradeTypeAgreementDialogBoxComponent } from './trade-type-agreement-dialog-box/trade-type-agreement-dialog-box.component';

@Component({
  selector: 'app-trade-type-agreement',
  templateUrl: './trade-type-agreement.component.html',
  styleUrls: ['./trade-type-agreement.component.css']
})
export class TradeTypeAgreementComponent implements OnInit {
  
  size: number = 25;
  page: number = 0;
  length: number;
  tradeTypeId: number;
  documentList: Array<any> = [];
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['tradeAgreementName', 'tradeAgreementLink', 'description', 'countries', 'action'];
  serchFilter: Boolean = false;
  nodata: Boolean = true;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  sessionStorageUserData: any
  disableCreateBtn: boolean = true
  disableEditBtn: boolean = true
  disableDeleteBtn: boolean = true

  constructor(private dialog: MatDialog, private masterTable: MasterTableService) { }

  ngOnInit(): void {
    this.getAllTradeTypeAgreement();
    this.getCurrentUserDataFromSessionStorage();
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  onPageChange(event: PageEvent) {
    this.size = event.pageSize;
    this.page = event.pageIndex;
    this.getAllTradeTypeAgreement();
  }
  
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.serchFilter=true;
    this.nodata=false;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getAllTradeTypeAgreement(){
    this.masterTable.getAllTradeTypeAgreementPage(this.page, this.size).subscribe(
      (response) => {
        console.log(response);
        if (response.content != null) {
          this.dataSource = new MatTableDataSource(response.content);
          this.dataSource.sort = this.sort;
          this.length = response.totalElements
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }


  openDialog(action: String, obj: any) {
    obj.action = action;
    let dialogRef: any;
    if(obj.action == 'Delete'){
      dialogRef = this.dialog.open(TradeTypeAgreementDialogBoxComponent, {
        width: '300px',
        height: '200px',
        data: obj
      });
    }
    else{
      dialogRef = this.dialog.open(TradeTypeAgreementDialogBoxComponent, {
        width: '600px',
        height: '600px',
        data: obj
      });
    }
    dialogRef.afterClosed().subscribe(
      result => {
        console.log(result);
        if (result.event == 'Add') {
          console.log(result.data);
          this.addTradeTypeAgreement(result.data);
        } else if (result.event == 'Update') {
          this.tradeTypeId = result.data.id;
          this.updateTradeTypeAgreement(result.data.id, result.data);
        } else if (result.event == 'Delete') {
          this.tradeTypeId = result.data.id;
          this.deleteTradeAgreement(result.data.id);
        }
      }
    );
  }


  addTradeTypeAgreement(data: any){
    let _data = {
      tradeAgreementName: data.tradeAgreementName,
      tradeAgreementLink: data.tradeAgreementLink,
      description: data.description,
      countries: data.countries
    }
    this.documentList = data.artifacts
    this.masterTable.addTradeTypeAgreement(_data).subscribe(
      (response) => {
        console.log(response);
        if(response.code == 201){
          this.tradeTypeId = response.entityId;
          Swal.fire({
            title: "Success",
            icon: "success",
            text: response.message,
            timer: 5000
          });
          for (let index = 0; index < this.documentList.length; index++) {
            this.uploadFile(index);
          }
          this.getAllTradeTypeAgreement();
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  uploadFile(index) {
    let data = this.documentList[index];
    if(!data.id){
      this.masterTable.postArtifactTradeTypeAgreement(this.tradeTypeId, data).subscribe(
        (response) => {
          if (response.code == 201) {
            data['artifactId'] = response.entityId;
          }
        },
        (error) => {
          console.log(error);
        },
      )
    }    
  }


  deleteTradeAgreement(id){
    this.masterTable.deleteTradeTypeAgreement(id).subscribe(
      (response) => {
        console.log(response);
        if (response.code == 201) {
          Swal.fire({
            titleText: "Success",
            icon: "success",
            text: response.message,
            timer: 5000
          })
        }
        this.getAllTradeTypeAgreement();
      },
      (error) => {
        console.log(error);
      },
    )
  }

  updateTradeTypeAgreement(id, data){
    let _data = {
      tradeAgreementName: data.tradeAgreementName,
      tradeAgreementLink: data.tradeAgreementLink,
      description: data.description,
      countries: data.countries
    }
    this.documentList = data.artifacts
    this.masterTable.updateTradeTypeAgreement(id, _data).subscribe(
      (response) => {
        console.log(response);
        if (response.code == 201) {
          Swal.fire({
            titleText: "Success",
            icon: "success",
            text: response.message,
            timer: 5000
          });
          for (let index = 0; index < this.documentList.length; index++) {
            this.uploadFile(index);
          }
          this.getAllTradeTypeAgreement();
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getCurrentUserDataFromSessionStorage(){
    this.sessionStorageUserData = JSON.parse(sessionStorage.getItem('userData'));
    console.log('this is user data ', this.sessionStorageUserData);
    for (let index = 0; index < this.sessionStorageUserData.authorities.length; index++) {
      const authority = this.sessionStorageUserData.authorities[index];
      console.log(authority);
      if(authority == 'RB0000236'){
        console.log('create authority is given');
        this.disableCreateBtn = false
      } else if(authority == 'RB0000237'){
        console.log('update authority is given');
        this.disableEditBtn = false
      } else if(authority == 'RB0000238') {
        console.log('delete authority is given');
        this.disableDeleteBtn = false
      }
    }
  }

}
