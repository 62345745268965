import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DemoService } from 'src/app/helpers/demo.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

  feedbackForm: FormGroup;

  constructor(private fb: FormBuilder, private feedbackService: DemoService) { }

  ngOnInit(): void {
    this.gotoTop();

    this.feedbackForm = this.fb.group({
      name: ['', Validators.required],
      emailId: ['', Validators.compose([Validators.required, Validators.email])],
      description: ['', Validators.compose([Validators.required, Validators.maxLength(255)])]
    });
  }

  get feedback(){ return this.feedbackForm.controls };

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  submitFeedback(){
    if(this.feedbackForm.invalid){
      let invalid = this.findInvalidControls();
      Swal.fire({
        title: "Warning",
        text: "Please fill the field " + invalid[0],
        icon: "warning",
        timer: 5000
      });
    }
    else{
      this.feedbackService.postFeedback(this.feedbackForm.value).subscribe(
        (response) => {
          console.log(response);
          if(response.code == 201){
            Swal.fire({
              title: "Success",
              text: response.message,
              icon: "success",
              timer: 5000
            });
          }
          this.feedbackForm.reset();
        },
        (error) => {
          console.log(error);
        },
      )
    }
  }

  findInvalidControls(){
    const invalid = [];
    const controls = this.feedback;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

}
