<section>
    <div class="row mt-3 pt-4 pl-4 pr-4">
        <div class="col-md-4">
            <h4>ORG Name :</h4>
            <p><b>{{ local_data.name }}</b></p>
        </div>
        <div class="col-md-4">
            <h4>Website :</h4>
            <p><b>{{ local_data?.website }}</b></p>
        </div>
        <div class="col-md-4">
            <h4>Onboarding Status :</h4>
            <p><b>{{ local_data?.onboardingStatus }}</b></p>
        </div>

        <div class="col-md-4">
            <h4>First Name :</h4>
            <p><b>{{ local_data?.pocFirstName }}</b></p>
        </div>

        <div class="col-md-4">
            <h4>Last Name :</h4>
            <p><b>{{ local_data?.pocLastName }}</b></p>
        </div>

        <div class="col-md-4">
            <h4>EmailId :</h4>
            <p><b>{{ local_data.pocEmailId }}</b></p>
        </div>

        <div class="col-md-4">
            <h4>Contact Number : </h4>
            <p><b>{{ local_data.pocContactNumber }}</b></p>
        </div>

        <div class="col-md-4">
            <h4>Designation :</h4>
            <p><b>{{ local_data?.pocDesignation }}</b></p>
        </div>
    </div>
    <br>
    <div *ngIf="addressBool" class="mt-3 mb-4 pt-4 pl-4 pr-4">
        <div class="row" *ngFor="let add of address">
            <div class="col-lg-12">
                <h4>{{ add.label }} Address :</h4>
            </div>

            <div class="col-md-4">
                <h4>Street 1 : </h4>
                <p><b> {{ add?.street1 }} </b></p>
            </div>

            <div class="col-md-4">
                <h4>Street 2 : </h4>
                <p><b>{{ add?.street2 }}</b></p>
            </div>

            <div class="col-md-4">
                <h4>City : </h4>
                <p><b>{{ add?.city}}</b></p>
            </div>

            <div class="col-md-4">
                <h4>State : </h4>
                <p><b>{{ add?.state }}</b></p>
            </div>

            <div class="col-md-4">
                <h4>Country : </h4>
                <p><b>{{ add?.country?.name }}</b></p>
            </div>

            <div class="col-md-4">
                <h4>ZipCode : </h4>
                <p><b>{{ add?.zipCode }}</b></p>
            </div>
        </div>
    </div>

    <div *ngIf="artifactsBool" class="table-responsive mt-3 mb-4 pt-4 pl-4 pr-4">
        <h4>Artifacts</h4>
        <table class="table table-striped table-footer-dark">
            <thead>
                <th>Sr No.</th>
                <th>File Name</th>
                <th>Actions</th>
            </thead>
            <tbody>
                <tr *ngFor="let arti of artifacts; index as i">
                    <td>{{ i + 1 }}</td>
                    <td>{{ arti.label }}</td>
                    <td>
                        <button (click)="downloadFileLocal(arti)">
                            <i class="fa fa-download"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</section>
<div mat-dialog-actions class="justify-content-center">
    <button mat-button class="btn-success" (click)="closeDialog()" mat-flat-button color="warn">Close</button>
</div>