import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterTableService } from 'src/app/helpers/master-table.service';
import { EnquiryService } from '../../helpers/enquiry.service';
import Swal from 'sweetalert2';
import { OnboardService } from 'src/app/helpers/onboard.service';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { SocketService } from 'src/app/helpers/socket.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-client-rfq',
  templateUrl: './client-rfq.component.html',
  styleUrls: ['./client-rfq.component.css']
})
export class ClientRfqComponent implements OnInit {

  fileName: string;
  public countryCodes: Array<any> = [];
  page: number = 0
  size: number = 50;
  enquiryForm: FormGroup;
  title: string;
  sea_mode_shipment: boolean;
  air_mode_shipment: boolean;
  public currencyPicker = [];
  public container_type = []
  public mode_of_shipment = []
  public type_cargo = []
  public trade_type = []
  public nature_of_movement = []
  public nature_goods = [];
  public pickup_terms = [];
  public pickUpIncoTermsArray: Array<any> = [];
  public dropoff_terms = [];
  public dropOffIncoTermsArray: Array<any> = [];
  public type_packing = []
  public container_load_type = [
    { 'id': 1, 'value': 'Full Container Load (FCL)' },
    { 'id': 2, 'value': 'Less than Container Load (LCL)' },
    { 'id': 3, 'value': 'Both (FCL + LCL)' },
    { 'id': 4, 'value': 'Break Bulk' }
  ]
  countries: Array<any> = [];
  type: string;

  backfirstPage: Boolean = false;
  backDetailsPage: Boolean = false;
  backParticularsPage: Boolean = false;
  backVendorPage: Boolean = false;
  breadcrumbBool: Boolean = true;
  updateEnquiryBool: Boolean = false;
  originDestination = [];
  filteredNatureOfGoods: Array<any> = [];
  documentList: Array<any> = [];
  maxFileSize: number = 2000000;
  maxSizeImage: number = 1000000;
  enquiryId: any;
  showPickupAddress: boolean = false;
  containerSize: Array<any> = [
    { size: "20 Feet" },
    { size: "40 Feet" }
  ]
  dimensionsUOM: Array<any> = [
    { name: "CM" },
    { name: "MM" },
    { name: "IN" },
    { name: "FT" },
    { name: "MTR" },
  ]
  grossWeightUom: Array<any> = [
    { name: "MT" },
    { name: "KG" },
    { name: "LBS" },
  ]
  airGrossWeightUom: Array<any> = [
    { name: "KG" },
    { name: "LBS" },
  ]



  supplierName = "Enter Name";
  delivaryAddress: Boolean = false;
  showTerminal: Boolean = false;
  assignedVendors: Array<any> = [];
  samePortError: Boolean = false;
  pickupAddressBoolean: Boolean = false;
  deliveryAddressBoolean: Boolean = false;
  flatRackBool: Boolean = false;
  totalGrossWeightLCL: number = 0.0;
  totalVolumeWeightLCL: number = 0.0;
  totalGrossWeightAIR: number = 0.0;
  totalVolumeWeightAIR: number = 0.0;
  fullContainerLoadBool: Boolean = false;
  lessContainerLoadBool: Boolean = false;
  breakbulkBool: Boolean = false;
  breakBulkBoolOption: Boolean = false;
  breakBulkFile: Array<any> = [];
  invalidOfferDate: Boolean = false;
  totalBreakBulkWeight: number = 0.0;
  totalBreakBulkVolume: number = 0.0;

  finalAirWeightUom: string = '';
  finalSeaWeightUom: string = '';
  finalAirWeightBool: Boolean = false;
  finalSeaWeightBool: Boolean = false;
  filteredPorts: Array<any> = [];
  loadingPorts: Array<any> = [];
  destinationPorts: Array<any> = [];
  vendorsLength: number = 0;
  dataSource!: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  displayedColumns: string[] = ['actions', 'vendorName', 'businessType'];
  vendorBusinessType: number;
  serchFilter: Boolean = false;
  nodata: Boolean = true;
  vendorBusinessTypeList: Array<any> = [];

  offerLastSubmissionDate: any

  isThirdCountrySelected: boolean = false
  math = Math;

  searchOriginValue: string
  searchDestinationValue: string

  constructor(private enquiryService: EnquiryService, private fb: FormBuilder, private route: ActivatedRoute, private router: Router,
    private masterTableService: MasterTableService, private onBoardService: OnboardService, private socketService: SocketService,
    private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.getMasterTablesSessionStorage();
    this.enquiryForm = this.fb.group({
      description: ['', [Validators.required, Validators.maxLength(300)]],
      modeOfShipment: ['', Validators.required],
      tradeType: ['', Validators.required],
      origin: ['', Validators.required],
      destination: ['', Validators.required],
      typeOfCargo: ['', Validators.required],
      commodity: ['', Validators.required],
      natureOfMovement: ['', Validators.required],
      natureOfGoods: ['', Validators.required],
      deliveryIncoterms: ['', [Validators.required, Validators.max(25)]],
      pickUpIncoterms: ['', [Validators.required, Validators.max(25)]],
      typeOfPacking: ['', Validators.required],
      storingType: ['', [Validators.required, Validators.maxLength(20)]],
      supplierName: [''],
      cargoReadinessDate: ['', Validators.required],
      projectName: [''],
      projectCode: [''],
      lastSubmissionDate: ['', Validators.required],
      containers: this.fb.array([]),
      shipmentPackages: this.fb.array([]),
      terms: this.fb.array([]),
      type: ['', Validators.required],
      internalReferenceNumber: [''],
      assignedVendors: this.fb.array([]),
      currency:  this.fb.group({
        id: ['', Validators.required]
      }),
      enquiryDate: [new Date().toISOString().substring(0, 10)],
      poNumber: [''],
      chargableWeight: [''],
      chargableWeightUOM: [''],
      totalGrossWeight: [''],
      totalGrossWeightUOM: [''],
      volumeInCBM: [''],
      demurrage: ['', Validators.required],
    });
    this.enquiryId = this.route.snapshot.paramMap.get("id");
    let type = this.route.snapshot.paramMap.get('type');
    if (this.enquiryId) {
      // Edit Enquiry Object.
      this.getEnquiryById(this.enquiryId);
      this.getAllEnquiryArtifacts(this.enquiryId);
    }
    else {
      // New Enquiry Object
      if (type == 'f') {
        this.title = "Firm Enquiry Creation"
        this.enquiryForm.get('type')?.patchValue('f');
      }
      else {
        this.title = "Budgetory Enquiry Creation"
        this.enquiryForm.get('type')?.patchValue('b');
      }
      this.type = type;
      this.addTermsCondition();
      this.getAllVendors();
    }

    // this.socketService.connectTOServerPopUpFuction();
  }

  onChangeDelivaryTerm() {
    let delivaryTermvalue = this.enquiry.deliveryIncoterms.value
    if (delivaryTermvalue == 'DAP' || delivaryTermvalue == 'DDP' || delivaryTermvalue == 'DPU') {
      this.enquiryForm.addControl('deliveryPersonName', new FormControl('', Validators.required))
      this.enquiryForm.addControl('deliveryPersonAddress', new FormGroup({}))
      let pickupAddress = this.enquiryForm.get('deliveryPersonAddress') as FormGroup;
      pickupAddress.addControl('label', new FormControl('Delivery Address'))
      pickupAddress.addControl('street1', new FormControl('', Validators.required))
      pickupAddress.addControl('street2', new FormControl('', Validators.required))
      pickupAddress.addControl('city', new FormControl('', Validators.required))
      pickupAddress.addControl('state', new FormControl('', Validators.required))
      pickupAddress.addControl('country', new FormGroup({}));
      let country = pickupAddress.get('country') as FormGroup;
      country.addControl('id', new FormControl('', Validators.required));
      pickupAddress.addControl('zipCode', new FormControl('', Validators.required))
      this.delivaryAddress = true;
      this.showTerminal = false;
      this.enquiryForm.removeControl('destinationTerminal');
    }
    else if (delivaryTermvalue == 'DAT') {
      this.enquiryForm.addControl('destinationTerminal', new FormControl('', Validators.required))
      this.enquiryForm.removeControl('deliveryPersonName')
      this.enquiryForm.removeControl('deliveryPersonAddress')
      this.showTerminal = true;
      this.delivaryAddress = false;
    }
    else {
      this.enquiryForm.removeControl('destinationTerminal')
      this.enquiryForm.removeControl('deliveryPersonName')
      this.enquiryForm.removeControl('deliveryPersonAddress')
      this.delivaryAddress = false;
      this.showTerminal = false;
    }
  }

  get pickupPersonAddress(){
    return this.enquiryForm.get('pickupPersonAddress') as FormGroup;
  }

  get deliveryPersonAddress(){
    return this.enquiryForm.get('deliveryPersonAddress') as FormGroup;
  }

  get pickUpAddressCountry(){
    return this.pickupPersonAddress.get('country') as FormGroup;
  }

  get dropOffAddressCountry(){
    return this.deliveryPersonAddress.get('country') as FormGroup;
  }

  get exporterAddress(){
    return this.enquiryForm.get('exporterAddress') as FormGroup;
  }

  get exporterAddressCountry(){
    return this.exporterAddress.get('country') as FormGroup;
  }

  get currencyGroup(){
    return this.enquiryForm.get('currency') as FormGroup;
  }

  onChangePickupInco() {
    let pickUpValue = this.enquiry.pickUpIncoterms.value
    if (pickUpValue == 'EXW' || pickUpValue == 'FCA') {
      this.enquiryForm.addControl('pickupPersonName', new FormControl('', Validators.required))
      this.enquiryForm.addControl('pickupPersonAddress', new FormGroup({}))
      let pickupAddress = this.enquiryForm.get('pickupPersonAddress') as FormGroup;
      pickupAddress.addControl('label', new FormControl('Pickup Address'))
      pickupAddress.addControl('street1', new FormControl('', Validators.required))
      pickupAddress.addControl('street2', new FormControl('', Validators.required))
      pickupAddress.addControl('city', new FormControl('', Validators.required))
      pickupAddress.addControl('state', new FormControl('', Validators.required))
      pickupAddress.addControl('country', new FormGroup({}));
      let country = pickupAddress.get('country') as FormGroup;
      country.addControl('id', new FormControl('', Validators.required));
      pickupAddress.addControl('zipCode', new FormControl('', Validators.required))
      this.showPickupAddress = true;
    } else {
      this.showPickupAddress = false;
      this.enquiryForm.removeControl('pickupPersonName')
      this.enquiryForm.removeControl('pickupPersonAddress')
    }
  }

  get enquiry() {
    return this.enquiryForm.controls;
  }

  get container_details() {
    return this.enquiryForm.get('containers') as FormArray;
  }

  get package_details() {
    return this.enquiryForm.get('shipmentPackages') as FormArray;
  }

  get break_bulk_details() {
    return this.enquiryForm.get('breakBulks') as FormArray;
  }

  get vendors() {
    return this.enquiryForm.get('assignedVendors') as FormArray;
  }
  get termsConditions() { return this.enquiry.terms as FormArray; }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.enquiry;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }
  container_sea_init() {
    return this.fb.group({
      quantity: ['', Validators.required],
      size: ['', Validators.required],
      uom: ['', Validators.required],
      weight: ['', Validators.required],
      type: ['', Validators.required],
      length: [''],
      width: [''],
      height: [''],
      flatRackUOM: ['']
    });
  }

  package_air_init() {
    return this.fb.group({
      quantity: ['', Validators.required],
      length: ['', Validators.required],
      width: ['', Validators.required],
      height: ['', Validators.required],
      dimensionsUOM: ['CM', Validators.required],
      grossWeight: ['', Validators.required],
      weightUOM: ['KG', Validators.required],
      totalVolume: ['']
    });
  }

  break_bulk_init() {
    return this.fb.group({
      totalnetWeight: [0.0, Validators.required],
      totalGrossWeight: [0.0, Validators.required],
      totalVolume: [0.0, Validators.required],
      uom: ['', Validators.required]
    })
  }

  addSeaFCL() {
    if (!this.container_details.invalid) {
      this.container_details.push(this.container_sea_init())
    }
    else {
      this.container_details.markAllAsTouched();
      Swal.fire({
        timer: 5000,
        icon: "warning",
        titleText: "Warning",
        text: "Fill all the fields"
      });
    }
  }

  addSeaHCL() {
    if (!this.package_details.invalid) {
      this.package_details.push(this.hcl_sea_init());
    }
    else {
      this.package_details.markAllAsTouched();
      Swal.fire({
        timer: 5000,
        icon: "warning",
        titleText: "Warning",
        text: "Fill all the fields"
      });
    }
  }

  hcl_sea_init() {
    return this.fb.group({
      // type: ['', Validators.required],
      quantity: ['', Validators.required],
      length: ['', Validators.required],
      width: ['', Validators.required],
      height: ['', Validators.required],
      dimensionsUOM: ['CM', Validators.required],
      grossWeight: ['', Validators.required],
      weightUOM: ['MT', Validators.required],
      totalVolume: ['']
    });
  }

  deleteSeaFCL(index: number) {
    this.container_details.removeAt(index);
  }
  deleteSeaHCL(index: number) {
    this.package_details.removeAt(index);
    this.calculateTotalVolume();
    this.convertToMTsea();
  }

  addBreakBulk() {
    this.break_bulk_details.push(this.break_bulk_init());
  }

  // Need to change
  addAirRow() {
    if (!this.package_details.invalid) {
      this.package_details.push(this.package_air_init());
    }
    else {
      this.package_details.markAllAsTouched();
      Swal.fire({
        timer: 5000,
        icon: "warning",
        titleText: "Warning",
        text: "Fill all the fields"
      });
    }
  }
  // Need to change
  deleteAirRow(index: number) {
    this.package_details.removeAt(index);
    this.calculateAirVolume();
    this.convertToMTAIR();
  }

  getAllVendors() {
    let vendorsList = [];
    this.onBoardService.getAllVendors(this.page, this.size, "COMPLETE", this.vendorBusinessType).subscribe(
      (response) => {
        if (response.content != null) {
          this.vendorsLength = response.totalElements;
          response.content.forEach((vendor) => {
            vendor.flag = false;
            vendorsList.push(vendor);
          });
          vendorsList.forEach((x) => {
            this.assignedVendors.filter((data) => {
              if (x.id === data.id) {
                x.flag = true;
              }
            })
          })
          if(vendorsList.length == this.assignedVendors.length){
            var actions = document.getElementById('actions') as HTMLInputElement;
            actions.checked = true;
          }
          this.dataSource = new MatTableDataSource(vendorsList);
          this.dataSource.sort = this.sort;
        }
        else {
          window.alert("NO Vendors in your system!");
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }
  
  onPageChange(event: PageEvent) {
    this.size = event.pageSize;
    this.page = event.pageIndex;
    this.getAllVendors();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.nodata = false;
    this.serchFilter = true;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  filterVendorData(){
    if(this.vendorBusinessType != null){
      this.getAllVendors();
    }
  }

  addVendors(id: number, index: number, flag: boolean) {
    if (flag == true) {
      this.vendors.push(this.pushVendorsId(id));
    }
    else {
      this.removeVendorsId(index);
    }
    // ToDo, for creation remaining.
    var actions = document.getElementById('actions') as HTMLInputElement;
    if(this.updateEnquiryBool){
      if(this.vendors.length == this.assignedVendors.length){
        if(actions.checked == false){
          actions.checked = true;
        }
      }
      else{
        console.log("NOT SAME LENGTH", this.vendors.length);
        if(actions.checked){
          actions.checked = false;
        }
      }
    }
    else {
      if(this.dataSource.data.length == this.vendors.length){
        if(actions.checked == false){
          actions.checked = true;
        }
      }
      else{
        if(actions.checked){
          actions.checked = false;
        }
      }
    }
  }

  pushVendorsId(id: number) {
    return this.fb.group({
      id: [id]
    })
  }

  removeVendorsId(index: number) {
    this.vendors.removeAt(index);
  }

  nextDetailsPage() {
    if (!this.enquiry.description.invalid && !this.enquiry.modeOfShipment.invalid && !this.enquiry.tradeType.invalid) {
      let _stepper = document.getElementById("item-0").classList.contains("active");
      let _sidebar = document.getElementById("list-item-0").classList.contains("active");
      let _content = document.getElementById("item-5").classList.contains("active");
      if (_stepper && _sidebar && _content) {
        document.getElementById("item-0").classList.remove("active");
        document.getElementById("item-5").classList.remove("active");
      }
      this.breadcrumbBool = false;
      this.backfirstPage = true;
      document.getElementById("item-1").classList.add("active");
      document.getElementById("list-item-1").classList.add("active");
      document.getElementById("item-6").classList.add("active");
      this.gotoTop();
    }
    else {
      this.enquiry.description.markAsDirty();
      this.enquiry.modeOfShipment.markAsDirty();
      this.enquiry.tradeType.markAsDirty();
      Swal.fire({
        titleText: "Warning",
        icon: "warning",
        text: "Please fill all the mandatory fields",
        timer: 5000
      })
    }
  }

  nextParticularsPage() {
    if (!this.enquiry.origin.invalid && !this.enquiry.destination.invalid && !this.enquiry.typeOfCargo.invalid &&
      !this.enquiry.commodity.invalid && !this.enquiry.natureOfMovement.invalid && !this.enquiry.natureOfGoods.invalid && !this.enquiry.supplierName.invalid && !this.enquiry.typeOfPacking.invalid && !this.enquiry.supplierName.invalid && !this.enquiry.cargoReadinessDate.invalid && !this.enquiry.lastSubmissionDate.invalid && !this.enquiry.deliveryIncoterms.invalid && !this.enquiry.pickUpIncoterms.invalid
      && !this.enquiry.currency.invalid) {
      if (this.enquiry.modeOfShipment.value == 2) {
        if(this.enquiry.hsCode.invalid){
          this.enquiry.hsCode.markAsDirty();
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: "Please Fill HS Code",
            timer: 5000
          })
        } else if(this.enquiry.demurrage.invalid){
          this.enquiry.demurrage.markAsDirty();
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: "Please Fill Demurrage",
            timer: 5000
          })
        } else if(this.enquiry.detention.invalid){
          this.enquiry.detention.markAsDirty();
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: "Please Fill Detention",
            timer: 5000
          })
        } else {
          this.perticularPage();
        }
      } else if (this.enquiry.modeOfShipment.value == 1 && this.enquiry.demurrage.invalid){
        this.enquiry.demurrage.markAsDirty();
        Swal.fire({
          titleText: "Warning",
          icon: "warning",
          text: "Please Fill Demurrage",
          timer: 5000
        })
      }
      else if (this.samePortError == true) {
        Swal.fire({
          titleText: "Warning",
          icon: "warning",
          text: "Same Origin & Destination Port Not Allowed",
          timer: 5000
        })
      } else if (this.enquiry.pickUpIncoterms.value == 'EXW' || this.enquiry.pickUpIncoterms.value == 'FCA') {
        if (this.enquiry.pickupPersonName.invalid) {
          this.enquiry.pickupPersonName.markAsDirty();
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: "Please Fill Pickup Person Name",
            timer: 5000
          })
        } else {
          this.perticularPage()
        }
      } else if (this.enquiry.deliveryIncoterms.value == 'DAP' || this.enquiry.deliveryIncoterms.value == 'DDP') {
        if (this.enquiry.deliveryPersonName.invalid) {
          this.enquiry.deliveryPersonName.markAsDirty();
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: "Please Fill Delivery Person Name",
            timer: 5000
          })
        } else {
          this.perticularPage()
        }
      } else if (this.enquiry.deliveryIncoterms.value == 'DAT') {
        if (this.enquiry.destinationTerminal.invalid) {
          this.enquiry.destinationTerminal.markAsDirty();
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: "Please Fill Destination Terminal",
            timer: 5000
          })
        }
        else {
          this.perticularPage()
        }
      } else if(this.enquiry.tradeType.value == 3){
        if(this.enquiry.exporterName.invalid){
          this.enquiry.exporterName.markAsDirty();
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: "Please Fill Destination Terminal",
            timer: 5000
          })
        } else {
          this.perticularPage();
        }
      }
      else {
        this.perticularPage()
      }
    }
    else {
      this.enquiry.origin.markAsDirty(); this.enquiry.destination.markAsDirty(); this.enquiry.typeOfCargo.markAsDirty();
      this.enquiry.commodity.markAsDirty(); this.enquiry.natureOfMovement.markAsDirty(); this.enquiry.natureOfGoods.markAsDirty();
      this.enquiry.natureOfGoods.markAsDirty(); this.enquiry.typeOfPacking.markAsDirty(); this.enquiry.supplierName.markAsDirty();
      this.enquiry.cargoReadinessDate.markAsDirty(); this.enquiry.lastSubmissionDate.markAsDirty(); this.currencyGroup.get('id').markAsDirty();
      this.enquiry.deliveryIncoterms.markAsDirty(); this.enquiry.pickUpIncoterms.markAsDirty();
      this.enquiry.demurrage.markAsDirty();
      Swal.fire({
        titleText: "Warning",
        icon: "warning",
        text: "Please fill all the mandatory fields",
        timer: 5000
      })
    }
  }

  perticularPage() {
    let _stepper = document.getElementById("item-1").classList.contains("active");
    let _sidebar = document.getElementById("list-item-1").classList.contains("active");
    let _content = document.getElementById("item-6").classList.contains("active");
    if (_stepper && _sidebar && _content) {
      document.getElementById("item-1").classList.remove("active");
      document.getElementById("item-6").classList.remove("active");
    }
    this.backDetailsPage = true;
    this.backfirstPage = false;
    document.getElementById("item-2").classList.add("active");
    document.getElementById("list-item-2").classList.add("active");
    document.getElementById("item-7").classList.add("active");
    this.gotoTop();
  }

  nextVendorAssignmentPage() {
    if (this.enquiry.modeOfShipment.value == 1) {
      if (!this.enquiry.shipmentPackages.invalid) {
        this.vendorAssignmentPage();
      } else {
        Swal.fire({
          titleText: "Warning",
          icon: "warning",
          text: "Please fill all the fields",
          timer: 5000
        })
      }
    } else if (this.enquiry.modeOfShipment.value == 2) {
      if (this.enquiry.containerLoadType.value == 1) {
        if (!this.enquiry.containers.invalid && !this.enquiry.breakBulks.invalid) {
          this.vendorAssignmentPage();
        } else {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: "Please fill all the fields",
            timer: 5000
          })
        }
      }
      else if (this.enquiry.containerLoadType.value == 2) {
        if (!this.enquiry.shipmentPackages.invalid) {
          this.vendorAssignmentPage();
        } else {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: "Please fill all the fields",
            timer: 5000
          })
        }
      }
      else if (this.enquiry.containerLoadType.value == 3) {
        if (!this.enquiry.containers.invalid && !this.enquiry.shipmentPackages.invalid) {
          this.vendorAssignmentPage();
        } else {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: "Please fill all the fields",
            timer: 5000
          })
        }
      }
      else if (this.enquiry.containerLoadType.value == 4) {
        if (!this.enquiry.breakBulks.invalid) {
          this.vendorAssignmentPage();
        } else {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: "Please fill all the fields",
            timer: 5000
          })
        }
      }
    }
  }

  vendorAssignmentPage() {
    let _stepper = document.getElementById("item-2").classList.contains("active");
    let _sidebar = document.getElementById("list-item-2").classList.contains("active");
    let _content = document.getElementById("item-7").classList.contains("active");
    if (_stepper && _sidebar && _content) {
      document.getElementById("item-2").classList.remove("active");
      document.getElementById("item-7").classList.remove("active");
    }
    this.backDetailsPage = false;
    this.backParticularsPage = true;
    document.getElementById("item-3").classList.add("active");
    document.getElementById("list-item-3").classList.add("active");
    document.getElementById("item-8").classList.add("active");
    this.gotoTop();
  }

  nextTermsPage() {
    let _stepper = document.getElementById("item-3").classList.contains("active");
    let _sidebar = document.getElementById("list-item-3").classList.contains("active");
    let _content = document.getElementById("item-8").classList.contains("active");
    if (_stepper && _sidebar && _content) {
      document.getElementById("item-3").classList.remove("active");
      document.getElementById("item-8").classList.remove("active");
    }
    this.backVendorPage = true;
    this.backParticularsPage = false;
    document.getElementById("item-4").classList.add("active");
    document.getElementById("list-item-4").classList.add("active");
    document.getElementById("item-9").classList.add("active");
    this.gotoTop();
  }

  changeModeOfShipment() {
    // If modeOfShipment is "SEA"
    if (this.enquiryForm.get('modeOfShipment')?.value == 2) {
      this.package_details.clear();
      this.container_details.clear();
      this.totalGrossWeightLCL = 0.0;
      this.totalVolumeWeightLCL = 0.0;
      this.totalGrossWeightAIR = 0.0;
      this.totalVolumeWeightAIR = 0.0;
      this.enquiryForm.addControl('hsCode', new FormControl('', Validators.required));
      this.enquiryForm.addControl('containerLoadType', new FormControl(1, Validators.required));
      this.enquiryForm.addControl('detention', new FormControl('', Validators.required));
      this.enquiryForm.get('origin').patchValue(null);
      this.enquiryForm.get('destination').patchValue(null);
      this.fullContainerLoadBool = true;
      this.addSeaFCL();
      this.enquiryForm.addControl('break_bulk_label', new FormControl(false, Validators.required));
      this.enquiryForm.addControl('breakBulks', new FormArray([]));
      this.sea_mode_shipment = true;
      this.air_mode_shipment = false;
      this.breakBulkBoolOption = true;
    }
    // If modeOfShipment is "AIR"
    else if (this.enquiryForm.get('modeOfShipment')?.value == 1) {
      this.container_details.clear();
      this.package_details.clear();
      this.totalGrossWeightLCL = 0.0;
      this.totalVolumeWeightLCL = 0.0;
      this.totalGrossWeightAIR = 0.0;
      this.totalVolumeWeightAIR = 0.0;
      this.enquiryForm.get('origin').patchValue(null);
      this.enquiryForm.get('destination').patchValue(null);
      this.fullContainerLoadBool = false;
      this.addAirRow();
      this.enquiryForm.removeControl('hsCode');
      this.enquiryForm.removeControl('containerLoadType');
      this.enquiryForm.removeControl('break_bulk_label');
      this.enquiryForm.removeControl('breakBulks');
      this.enquiryForm.removeControl('detention');
      this.air_mode_shipment = true;
      this.sea_mode_shipment = false;
      this.breakbulkBool = false;
      this.breakBulkBoolOption = false;
    }
    // If modeOfShipment is "ROAD"
    else if (this.enquiryForm.get('modeOfShipment')?.value == 3) {
      Swal.fire({
        title: "Warning",
        icon: "info",
        timer: 5000,
        text: "Functionality Under Development"
      })
      this.air_mode_shipment = false;
      this.sea_mode_shipment = false;
      this.totalGrossWeightLCL = 0.0;
      this.totalVolumeWeightLCL = 0.0;
      this.totalGrossWeightAIR = 0.0;
      this.totalVolumeWeightAIR = 0.0;
    }
    // If modeOfShipment is "RAIL"
    else if (this.enquiryForm.get('modeOfShipment')?.value == 4) {
      Swal.fire({
        title: "Warning",
        icon: "info",
        timer: 5000,
        text: "Functionality Under Development"
      })
      this.air_mode_shipment = false;
      this.sea_mode_shipment = false;
      this.totalGrossWeightLCL = 0.0;
      this.totalVolumeWeightLCL = 0.0;
      this.totalGrossWeightAIR = 0.0;
      this.totalVolumeWeightAIR = 0.0;
    }
  }

  changeTypeCargo(formControl: string) {
    let controller = this.enquiryForm.get(formControl).value;
    this.filteredNatureOfGoods = this.filterNatureOfGoods(controller);
  }
  filterNatureOfGoods(id) {
    return this.nature_goods.filter(
      (item) => {
        return item.typeOfCargoDto.id == id
      }
    );
  }

  openBreakBulkTable() {
    this.breakbulkBool = true;
    this.totalBreakBulkVolume = 0.0;
    this.totalBreakBulkWeight = 0.0;
    this.enquiry.break_bulk_label.patchValue(true);
    this.addBreakBulk()
  }

  closeBreakBulkTable() {
    this.breakbulkBool = false;
    this.enquiry.break_bulk_label.patchValue(false);
    this.totalBreakBulkVolume = 0.0;
    this.totalBreakBulkWeight = 0.0;
    this.enquiryForm.get('chargableWeight').patchValue(0);
    this.clearBreakBulk();
  }

  clearBreakBulk() {
    const control = <FormArray>this.enquiry.breakBulks;
    for (let i = control.length - 1; i >= 0; i--) {
      const element = control[i];
      control.removeAt(i);
    }
  }

  onSubmit() {
    this.termsConditionsValidators();
    if (this.enquiryForm.controls.internalReferenceNumber.value === '') {
      this.enquiryForm.controls.internalReferenceNumber.setValue(null);
    }
    let submitBool = this.validateFilesAtSubmit();
    if(this.enquiryForm.invalid){
      let x = this.findInvalidControls();
      Swal.fire({
        titleText: "Warning",
        icon: "warning",
        text: "Please fill the field " + x[0],
        timer: 5000
      })
    }
    else if(submitBool == false && !this.enquiryForm.invalid){
      this.enquiry.lastSubmissionDate.patchValue(new Date(this.enquiry.lastSubmissionDate.value))
      this.enquiryForm.updateValueAndValidity();
      this.enquiryService.clientCreateEnquiry(this.enquiryForm.getRawValue()).subscribe(
        (response) => {
          if (response.code == 201) {
            Swal.fire({
              titleText: "Success",
              text: response.message,
              icon: "success",
              timer: 5000
            })
            this.enquiryId = response.entityId;
            for (let index = 0; index < this.documentList.length; index++) {
              this.uploadFile(index);
            }
            for (let index = 0; index < this.breakBulkFile.length; index++) {
              const element = this.breakBulkFile[index];
              if (element.label == "Package Slip") {
                if (element.artifactId == null || undefined) {
                  this.uploadPackageSlip(index);
                }
              }
            }
            this.router.navigate(['v1/client-rfq/listing/', this.type]);
          }
        },
        (error) => {
          let _error = error.error;
          if (_error.errorCode == 412) {
            Swal.fire({
              titleText: "Error",
              text: _error.errorMessage,
              icon: "error",
              timer: 5000,
              showCancelButton: true,
              showCloseButton: true
            })
          }
          else {
            Swal.fire({
              titleText: "Error",
              text: "Something went wrong",
              icon: "error",
              timer: 5000,
            })
          }
        },
      )
    }
  }

  validateFilesAtSubmit(){
    let submitBool = false;
    let totalFilesSize = 0;
    if(this.documentList.length >= 1){
      for (let index = 0; index < this.documentList.length; index++) {
        const document = this.documentList[index];
        const fileSize = Math.round((document.size / 1024));
        totalFilesSize = fileSize + totalFilesSize;
        if (document.label == undefined) {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Add label to file " + document.name,
            timer: 5000
          })
          submitBool = true;
          break
        }
        else if (fileSize > 2048) {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Upload File :" +  document.name + " with size less than 2MB",
            timer: 5000
          });
          submitBool = true;
          break
        }
        else if(totalFilesSize > 10240){
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Total File size should be size less than 10MB",
            timer: 5000
          });
          submitBool = true;
          break
        }
      }
    }
    return submitBool
  }

  goBackShipment() {
    let _stepper = document.getElementById("item-1").classList.contains("active");
    let _sidebar = document.getElementById("list-item-1").classList.contains("active");
    let _content = document.getElementById("item-6").classList.contains("active");
    if (_stepper && _sidebar && _content) {
      document.getElementById("item-1").classList.remove("active");
      document.getElementById("list-item-1").classList.remove("active");
      document.getElementById("item-6").classList.remove("active");
    }
    document.getElementById("list-item-0").classList.add("active");
    document.getElementById("item-0").classList.add("active");
    document.getElementById("item-5").classList.add("active");
    this.breadcrumbBool = true;
    this.backfirstPage = false;
  }

  goBackDetails() {
    let _stepper = document.getElementById("item-2").classList.contains("active");
    let _sidebar = document.getElementById("list-item-2").classList.contains("active");
    let _content = document.getElementById("item-7").classList.contains("active");
    if (_stepper && _sidebar && _content) {
      document.getElementById("item-2").classList.remove("active");
      document.getElementById("list-item-2").classList.remove("active");
      document.getElementById("item-7").classList.remove("active");
    }
    document.getElementById("list-item-1").classList.add("active");
    document.getElementById("item-1").classList.add("active");
    document.getElementById("item-6").classList.add("active");
    this.backDetailsPage = false;
    this.backfirstPage = true;
  }

  goBackParticulars() {
    let _stepper = document.getElementById("item-3").classList.contains("active");
    let _sidebar = document.getElementById("list-item-3").classList.contains("active");
    let _content = document.getElementById("item-8").classList.contains("active");
    if (_stepper && _sidebar && _content) {
      document.getElementById("item-3").classList.remove("active");
      document.getElementById("list-item-3").classList.remove("active");
      document.getElementById("item-8").classList.remove("active");
    }
    document.getElementById("list-item-2").classList.add("active");
    document.getElementById("item-2").classList.add("active");
    document.getElementById("item-7").classList.add("active");
    this.backDetailsPage = true;
    this.backParticularsPage = false;
  }

  goBackVendor() {
    let _stepper = document.getElementById("item-4").classList.contains("active");
    let _sidebar = document.getElementById("list-item-4").classList.contains("active");
    let _content = document.getElementById("item-9").classList.contains("active");
    if (_stepper && _sidebar && _content) {
      document.getElementById("item-4").classList.remove("active");
      document.getElementById("list-item-4").classList.remove("active");
      document.getElementById("item-9").classList.remove("active");
    }
    document.getElementById("list-item-3").classList.add("active");
    document.getElementById("item-3").classList.add("active");
    document.getElementById("item-8").classList.add("active");
    this.backVendorPage = false;
    this.backParticularsPage = true;
  }

  getEnquiryById(id: any) {
    this.enquiryService.fetchEnquiryById(id).subscribe(
      (response) => {
        if (response.id != null) {
          console.log(response);
          this.getAllVendors();
          this.updateEnquiryBool = true;
          this.enquiryForm.addControl('id', new FormControl());
          this.enquiryForm.addControl('breakBulks', new FormArray([]));

          this.enquiry.id.patchValue(response.id);
          this.type = response.type;
          this.enquiry.type.patchValue(response.type);
          this.enquiry.description.patchValue(response.description);
          this.enquiry.modeOfShipment.patchValue(response.modeOfShipment);
          this.enquiry.tradeType.patchValue(response.tradeType);
          if (this.enquiry.tradeType.value == 2) {
            this.enquiry.supplierName.disable();
          }
          else {
            this.enquiry.supplierName.patchValue(response.supplierName);
          }
          if (this.enquiry.tradeType.value == 3) {
            this.isThirdCountrySelected = true
            this.enquiryForm.addControl('exporterName', new FormControl(response.exporterName));
            this.enquiryForm.addControl('exporterMailId', new FormControl(response.exporterMailId));
            this.enquiryForm.addControl('exporterContact', new FormControl(response.exporterContact));
            this.enquiryForm.addControl('exporterAddress', new FormGroup({}))
            const exporterAddress = this.enquiryForm.get('exporterAddress') as FormGroup
            exporterAddress.addControl('label', new FormControl(response.exporterAddress.label));
            exporterAddress.addControl('street1', new FormControl(response.exporterAddress.street1));
            exporterAddress.addControl('street2', new FormControl(response.exporterAddress.street2));
            exporterAddress.addControl('city', new FormControl(response.exporterAddress.city));
            exporterAddress.addControl('state', new FormControl(response.exporterAddress.state));
            // exporterAddress.addControl('country', new FormControl(response.exporterAddress.country));
            exporterAddress.addControl('country', new FormGroup({}));
            let country = exporterAddress.get('country') as FormGroup;
            country.addControl('id', new FormControl(response.exporterAddress?.country?.id));
            exporterAddress.addControl('zipCode', new FormControl(response.exporterAddress.zipCode));
          } else {
            this.isThirdCountrySelected = false
            this.enquiryForm.removeControl('exporterName');
            this.enquiryForm.removeControl('exporterMailId');
            this.enquiryForm.removeControl('exporterContact');
            this.enquiryForm.removeControl('exporterAddress');
          }
          
          this.searchOriginValue = response.originPortName
          this.getSearchResultsOrigin();

          this.searchDestinationValue = response.destinationPortName
          this.getSearchResultDestination();

          this.enquiry.origin.patchValue(response.origin);
          this.enquiry.destination.patchValue(response.destination);
          this.enquiry.typeOfCargo.patchValue(response.typeOfCargo);
          this.enquiry.commodity.patchValue(response.commodity);
          this.enquiry.natureOfMovement.patchValue(response.natureOfMovement);
          this.enquiry.natureOfGoods.patchValue(response.natureOfGoods);
          this.changeNatureOfMovement();
          this.enquiry.deliveryIncoterms.patchValue(response.deliveryIncoterms);
          this.enquiry.pickUpIncoterms.patchValue(response.pickUpIncoterms);
          this.enquiry.typeOfPacking.patchValue(response.typeOfPacking);
          this.enquiry.storingType.patchValue(response.storingType);
          this.enquiry.cargoReadinessDate.patchValue(response.cargoReadinessDate);
          this.enquiry.projectName.patchValue(response.projectName);
          this.enquiry.projectCode.patchValue(response.projectCode);
          this.enquiry.demurrage.patchValue(response.demurrage);

          let lastSubmissionDate = this.datePipe.transform(response.lastSubmissionDate, 'yyyy-MM-ddTHH:mm');
          this.enquiry.lastSubmissionDate.patchValue(lastSubmissionDate);

          this.enquiry.internalReferenceNumber.patchValue(response.internalReferenceNumber);
          this.currencyGroup.get('id').patchValue(response.currency.id);
          let _date: string = this.datePipe.transform(response.enquiryDate, 'yyyy-MM-dd')
          this.enquiry.enquiryDate.patchValue(_date);
          this.enquiry.enquiryDate.disable();
          this.enquiry.poNumber.patchValue(response.poNumber);
          if (response.pickUpIncoterms == 'EXW' || response.pickUpIncoterms == 'FCA') {
            this.showPickupAddress = true;
            if (response.pickupPersonAddress != null) {
              this.enquiryForm.addControl('pickupPersonAddress', new FormGroup({}));
              let pickupAddress = this.enquiryForm.get('pickupPersonAddress') as FormGroup;
              pickupAddress.addControl('label', new FormControl('Pickup Address'));
              pickupAddress.addControl('street1', new FormControl(response.pickupPersonAddress.street1));
              pickupAddress.addControl('street2', new FormControl(response.pickupPersonAddress.street2));
              pickupAddress.addControl('city', new FormControl(response.pickupPersonAddress.city));
              pickupAddress.addControl('state', new FormControl(response.pickupPersonAddress.state));
              // pickupAddress.addControl('country', new FormControl(response.pickupPersonAddress.country));
              pickupAddress.addControl('country', new FormGroup({}));
              let country = pickupAddress.get('country') as FormGroup;
              country.addControl('id', new FormControl(response.pickupPersonAddress?.country?.id));
              pickupAddress.addControl('zipCode', new FormControl(response.pickupPersonAddress.zipCode));
            }
            else {
              this.enquiryForm.addControl('pickupPersonAddress', new FormGroup({}));
              let pickupAddress = this.enquiryForm.get('pickupPersonAddress') as FormGroup;
              pickupAddress.addControl('label', new FormControl('Pickup Address'));
              pickupAddress.addControl('street1', new FormControl());
              pickupAddress.addControl('street2', new FormControl());
              pickupAddress.addControl('city', new FormControl());
              pickupAddress.addControl('state', new FormControl());
              // pickupAddress.addControl('country', new FormControl());
              pickupAddress.addControl('country', new FormGroup({}));
              let country = pickupAddress.get('country') as FormGroup;
              country.addControl('id', new FormControl(response.pickupPersonAddress?.country?.id));
              pickupAddress.addControl('zipCode', new FormControl());
            }
            if (response.pickupPersonName != null) {
              this.enquiryForm.addControl('pickupPersonName', new FormControl(response.pickupPersonName));
            }
            else {
              this.enquiryForm.addControl('pickupPersonName', new FormControl());
            }
          }
          else {
            this.showPickupAddress = false;
            this.enquiryForm.removeControl('pickupPersonName');
            this.enquiryForm.removeControl('pickupPersonAddress');
          }

          if (response.deliveryIncoterms == 'DAP' || response.deliveryIncoterms == 'DDP' || response.deliveryIncoterms == 'DPU') {
            this.delivaryAddress = true;
            this.showTerminal = false;
            if (response.deliveryPersonAddress != null) {
              this.enquiryForm.addControl('deliveryPersonAddress', new FormGroup({}));
              let deliveryAddress = this.enquiryForm.get('deliveryPersonAddress') as FormGroup;
              deliveryAddress.addControl('label', new FormControl('Delivery Address'));
              deliveryAddress.addControl('street1', new FormControl(response.deliveryPersonAddress.street1));
              deliveryAddress.addControl('street2', new FormControl(response.deliveryPersonAddress.street2));
              deliveryAddress.addControl('city', new FormControl(response.deliveryPersonAddress.city));
              deliveryAddress.addControl('state', new FormControl(response.deliveryPersonAddress.state));
              deliveryAddress.addControl('country', new FormGroup({}));
              let country = deliveryAddress.get('country') as FormGroup;
              country.addControl('id', new FormControl(response.deliveryPersonAddress?.country?.id));
              deliveryAddress.addControl('zipCode', new FormControl(response.deliveryPersonAddress?.zipCode));
            }
            else {
              this.enquiryForm.addControl('deliveryPersonAddress', new FormGroup({}));
              let deliveryAddress = this.enquiryForm.get('deliveryPersonAddress') as FormGroup;
              deliveryAddress.addControl('label', new FormControl('Delivery Address'));
              deliveryAddress.addControl('street1', new FormControl('', Validators.required));
              deliveryAddress.addControl('street2', new FormControl('', Validators.required));
              deliveryAddress.addControl('city', new FormControl('', Validators.required));
              deliveryAddress.addControl('state', new FormControl('', Validators.required));
              deliveryAddress.addControl('country', new FormGroup({}));
              let country = deliveryAddress.get('country') as FormGroup;
              country.addControl('id', new FormControl(response.deliveryPersonAddress?.country?.id, Validators.required));
              deliveryAddress.addControl('zipCode', new FormControl('', Validators.required));
            }
            if (response.deliveryPersonName != null) {
              this.enquiryForm.addControl('deliveryPersonName', new FormControl(response.deliveryPersonName))
            }
            else {
              this.enquiryForm.addControl('deliveryPersonName', new FormControl('', Validators.required));
            }
          }
          else if (response.deliveryIncoterms == 'DAT') {
            this.showTerminal = true;
            this.delivaryAddress = false;
            if (response.destinationTerminal != null) {
              this.enquiryForm.addControl('destinationTerminal', new FormControl(response.destinationTerminal));
            }
            else {
              this.enquiryForm.addControl('destinationTerminal', new FormControl());
            }
            this.enquiryForm.removeControl('deliveryPersonName')
            this.enquiryForm.removeControl('deliveryPersonAddress')
          }
          else {
            this.enquiryForm.removeControl('deliveryPersonName')
            this.enquiryForm.removeControl('deliveryPersonAddress')
            this.enquiryForm.removeControl('destinationTerminal')
            this.showTerminal = false;
            this.delivaryAddress = false;
          }
          let continers: Array<any> = response.containers;
          let shipmentPackages: Array<any> = response.shipmentPackages;
          if (this.enquiry.modeOfShipment.value == 2) {
            this.package_details.clear();
            this.container_details.clear();
            this.sea_mode_shipment = true;
            this.air_mode_shipment = false;
            this.enquiryForm.addControl('hsCode', new FormControl(response.hsCode, Validators.required));
            this.enquiryForm.addControl('detention', new FormControl(response.detention, Validators.required));
            this.enquiryForm.addControl('containerLoadType', new FormControl(response.containerLoadType, Validators.required));
            this.enquiryForm.addControl('break_bulk_label', new FormControl(false, Validators.required));
            if (this.enquiry.containerLoadType.value == 1) {
              this.fullContainerLoadBool = true;
              continers.forEach((container, index) => {
                // console.log('this is container ', container);
                // console.log('this is size value at index testing ', this.container_details.at(index)?.get('size')?.value);
                
                // if (container.type == 4) {
                  //   console.log('this is flat rack selected');
                  //   console.log('this is container details ', this.container_details);
                  // }
                  
                  this.container_details.push(this.prePopulateContainer(container));
                  this.getContianerType(index)
              });
              if(response.breakBulks.length > 0){
                this.breakbulkBool = true;
                this.breakBulkBoolOption = true;
                this.enquiry.break_bulk_label.patchValue(true);
                let breakBulks: Array<any> = response.breakBulks;
                breakBulks.forEach((breakBulk, index) => {
                  this.break_bulk_details.push(this.prePopulateBreakBulk(breakBulk));
                  // Cross check.
                  this.calculateBreakBulkWeight(index);
                });
              }
              else{
                this.breakBulkBoolOption = true;
              }
            }
            else if (this.enquiry.containerLoadType.value == 2) {
              this.fullContainerLoadBool = false;
              this.lessContainerLoadBool = true;
              this.breakbulkBool = false;
              this.breakBulkBoolOption = false;
              shipmentPackages.forEach((shipment) => {
                this.package_details.push(this.prePopulateShipmentLCL(shipment));
              })
              // Calculate
              this.calculateTotalVolume();
            }
            else if (this.enquiry.containerLoadType.value == 3) {
              this.fullContainerLoadBool = true;
              continers.forEach((container) => {
                this.container_details.push(this.prePopulateContainer(container));
              });
              this.lessContainerLoadBool = true;
              shipmentPackages.forEach((shipment) => {
                this.package_details.push(this.prePopulateShipmentLCL(shipment));
              })
              this.calculateTotalVolume();
              this.breakbulkBool = false;
              this.breakBulkBoolOption = false;
            }
            else if (this.enquiry.containerLoadType.value == 4) {
              this.fullContainerLoadBool = false;
              this.lessContainerLoadBool = false;
              this.breakbulkBool = true;
              this.enquiry.break_bulk_label.patchValue(true);
              let breakBulks: Array<any> = response.breakBulks;
              breakBulks.forEach((breakBulk, index) => {
                this.break_bulk_details.push(this.prePopulateBreakBulk(breakBulk));
                this.calculateBreakBulkWeight(index);
              });
            }
          }
          else if (this.enquiry.modeOfShipment.value == 1) {
            this.container_details.clear();
            this.package_details.clear();
            this.enquiryForm.removeControl('hsCode');
            this.enquiryForm.removeControl('containerLoadType');
            this.enquiryForm.removeControl('break_bulk_label');
            this.enquiryForm.removeControl('breakBulks');
            this.air_mode_shipment = true;
            this.sea_mode_shipment = false;
            shipmentPackages.forEach((shipment) => {
              this.package_details.push(this.prePopulateShipmentPackages(shipment));
            })
            this.calculateAirVolume();
          }
          else if(this.enquiry.modeOfShipment.value == 3){
            // ToDo Road/Rail shipments/containers.
            console.log(this.enquiry.modeOfShipment.value);
          }
          else {
            // ToDo Road/Rail shipments/containers.
            console.log("NO shipment here.")
          }

          if (response.type == 'f') {
            this.title = "Firm Enquiry Creation"
          }
          else {
            this.title = "Budgetory Enquiry Creation"
          }
          this.assignedVendors = response.assignedVendors;
          // ToDo, push assignedVendors to AssignVendors FormArray;
          for (let index = 0; index < response.assignedVendors.length; index++) {
            const vendor = response.assignedVendors[index];
            this.addVendors(vendor.id, index, true);
          }
          this.filteredNatureOfGoods = this.filterNatureOfGoods(response.typeOfCargo);
          if (response.terms.length >= 1) {
            let terms: Array<any> = response.terms;
            terms.forEach((term) => {
              this.termsConditions.push(this.prePopulateTermsConditions(term));
            })
          }
          else {
            this.addTermsCondition();
          }
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  prePopulateContainer(data: any): FormGroup {
    return this.fb.group({
      id: [data.id],
      quantity: [data.quantity],
      size: [data.size],
      uom: [data.uom],
      weight: [data.weight],
      type: [data.type],
      length: [data.length],
      width: [data.width],
      height: [data.height],
      flatRackUOM: [data.flatRackUOM]
    });
  }

  prePopulateShipmentLCL(data: any): FormGroup {
    return this.fb.group({
      id: [data.id],
      quantity: [data.quantity],
      type: [data.type],
      length: [data.length],
      width: [data.width],
      height: [data.height],
      dimensionsUOM: [data.dimensionsUOM],
      grossWeight: [data.grossWeight],
      weightUOM: [data.weightUOM],
      totalVolume: [data.totalVolume]
    });
  }

  prePopulateShipmentPackages(data: any): FormGroup {
    return this.fb.group({
      id: [data.id],
      quantity: [data.quantity],
      length: [data.length],
      width: [data.width],
      height: [data.height],
      dimensionsUOM: [data.dimensionsUOM],
      grossWeight: [data.grossWeight],
      weightUOM: [data.weightUOM],
      totalVolume: [data.totalVolume]
    });
  }

  prePopulateBreakBulk(data: any): FormGroup {
    return this.fb.group({
      id: [data.id],
      totalnetWeight: [data.totalnetWeight],
      totalGrossWeight: [data.totalGrossWeight],
      totalVolume: [data.totalVolume],
      uom: [data.uom]
    })
  }

  prePopulateTermsConditions(data: any): FormGroup {
    return this.fb.group({
      content: [data.content],
      label: [data.label]
    })
  }

  updateEnquiry() {
    // Terms & Conditions
    this.termsConditionsValidators();
    if (this.enquiryForm.controls.internalReferenceNumber.value === '') {
      this.enquiryForm.controls.internalReferenceNumber.setValue(null);
    }
    for (let index = 0; index < this.breakBulkFile.length; index++) {
      const document = this.breakBulkFile[index];
      const fileSize = Math.round((document.size / 1024));
      if(fileSize > 2048){
        Swal.fire({
          titleText: "Error",
          icon: "error",
          text: "File : " + document.name + " should be size less than 2MB",
          timer: 5000
        });
        // submitBool = true;
        break
      }
      else if (document.label == "Package Slip") {
        if (document.artifactId == null || undefined) {
          this.uploadPackageSlip(index);
        }
      }
    }

    let submitBool = this.checkDocumentListValidation();
    if(this.enquiryForm.invalid){
      let x = this.findInvalidControls();
      Swal.fire({
        titleText: "Warning",
        icon: "warning",
        text: "Please fill the field " + x[0],
        timer: 5000,
        showCancelButton: true,
        showCloseButton: true
      })
    }
    else if(submitBool == false && !this.enquiryForm.invalid){
      this.updateEnquiryApi();
    }
  }

  updateEnquiryApi(){
    this.enquiry.lastSubmissionDate.patchValue(new Date(this.enquiry.lastSubmissionDate.value));
    this.enquiryForm.updateValueAndValidity();
    this.enquiryService.updateEnquiry(this.enquiry.id.value, this.enquiryForm.getRawValue()).subscribe(
      (response) => {
        if (response.code == 201) {
          Swal.fire({
            titleText: "Success",
            text: response.message,
            icon: "success",
            timer: 5000
          });
          this.router.navigate(['v1/client-rfq/listing/', this.type]);
        }
      },
      (error) => {
        let _error = error.error;
        Swal.fire({
          titleText: "Error",
          text: _error.message,
          icon: "error",
          timer: 5000,
          showCancelButton: true,
          showCloseButton: true
        })
      },
    );
  }

  checkDocumentListValidation(){
    let submitBool = false;
    let totalFilesSize = 0;
    for (let index = 0; index < this.documentList.length; index++) {
      const document = this.documentList[index];
      const fileSize = Math.round((document.size / 1024));
      totalFilesSize = fileSize + totalFilesSize;
      if (document.label == undefined) {
        Swal.fire({
          titleText: "Error",
          icon: "error",
          text: "Add label to file, " + document.name,
          timer: 5000
        });
        submitBool = true;
        break
      }
      else if(fileSize > 2048){
        Swal.fire({
          titleText: "Error",
          icon: "error",
          text: "File : " + document.name + " should be size less than 2MB",
          timer: 5000
        });
        submitBool = true;
        break
      }
      else if(totalFilesSize > 10240){
        Swal.fire({
          titleText: "Error",
          icon: "error",
          text: "Total File size should be size less than 10MB",
          timer: 5000
        });
        submitBool = true;
        break
      }
      else if (document.artifactId == undefined || null && fileSize < 2048 && totalFilesSize < 10240 && document.label != undefined) {
        this.uploadFile(index);
      }
    }
    return submitBool
  }

  getMasterTablesSessionStorage() {
    this.containerTypeList();
    this.getModeOfShipment();
    this.getTradeType();
    this.getTypeOfCargo();
    this.getNatureOfMovement();
    this.getPickUpIncoTerms();
    this.getDeliveryIncoTerms();
    this.getNatureOfGoods();
    this.getTypeOfPacking();
    this.getCurrency();
    this.getCountry();
    this.getAllBusinessType();
  }

  containerTypeList(){
    this.masterTableService.getContainerTypes().subscribe(
      (response) => {
        if(response){
          this.container_type = response
        }
      },
      (error) => {error},
    );
  }

  getModeOfShipment(){
    this.masterTableService.getModeOfShipment().subscribe(
      (response) => {
        this.mode_of_shipment = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getTradeType(){
    this.masterTableService.getTradeType().subscribe(
      (response) => {
        this.trade_type = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getOriginDestination(){
    this.masterTableService.getLoadingDestinationPort().subscribe(
      (response) => {
        this.originDestination = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getTypeOfCargo(){
    this.masterTableService.getAllTypeOfCargo().subscribe(
      (response) => {
        this.type_cargo = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getNatureOfMovement(){
    this.masterTableService.getNatureOfMovement().subscribe(
      (response) => {
        this.nature_of_movement = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getPickUpIncoTerms(){
    this.masterTableService.getAllPickUpIncoTerms().subscribe(
      (response) => {
        this.pickup_terms = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getDeliveryIncoTerms(){
    this.masterTableService.getDeliveryIncoTerms().subscribe(
      (response) => {
        this.dropoff_terms = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getNatureOfGoods(){
    this.masterTableService.getNatureOfGoods().subscribe(
      (response) => {
        this.nature_goods = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getTypeOfPacking(){
    this.masterTableService.getAllTypeOfPacking().subscribe(
      (response) => {
        this.type_packing = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getCurrency(){
    this.masterTableService.getAllCurrency().subscribe(
      (response) => {
        this.currencyPicker = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getCountry(){
    this.masterTableService.getAllCountries().subscribe(
      (response) => {
        this.countries = response;
      },
      (error) => {
        console.log(error);
      }, 
    )
  }

  getTodayDate() {
    return new Date().toISOString().split('T')[0]
  }

  addTermsCondition() {
    const term = this.fb.group({
      content: [''],
      label: [''],
    })
    if(!this.termsConditions.invalid){
      this.termsConditions.push(term);
    }
    else{
      this.termsConditions.markAllAsTouched();
      Swal.fire({
        timer: 5000,
        icon: "warning",
        titleText: "Warning",
        text: "Fill all the fields"
      });
    }
  }

  deleteTermsCondition(index) {
    if (this.termsConditions.length > 1) {
      this.termsConditions.removeAt(index);
    }
  }

  deleteDocument(index: number) {
    this.documentList.splice(index, 1)
  }

  addFiles(files: NgxFileDropEntry[]) {
    for (let index = 0; index < files.length; index++) {
      const droppedFile = files[index];
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          // Here you can access the real file size & content
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            let data = {
              "content": reader.result,
              "contentType": reader.result?.toString().split(',')[0],
              "name": file.name,
              "size": file.size
            }
            this.documentList.push(data);
          }
        })
      }
      else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }

  uploadFile(index) {
    let data = this.documentList[index];
    this.enquiryService.createEnquiryArtifact(this.enquiryId, data).subscribe(
      (response) => {
        if (response.code == 201) {
          data['artifactId'] = response.entityId;
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  deleteServerFile(artifactId, index) {
    this.enquiryService.deleteEnquiryArtifact(this.enquiryId, artifactId).subscribe(
      (response) => {
        if (response.code == 200) {
          Swal.fire({
            titleText: "Success",
            text: response.message,
            icon: "success",
            timer: 5000
          });
          this.deleteDocument(index);
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getAllEnquiryArtifacts(id) {
    this.enquiryService.getAllEnquiryArtifact(id).subscribe(
      (response: Array<any>) => {
        if (response.length >= 1) {
          for (let index = 0; index < response.length; index++) {
            const document = response[index];
            let _data = {
              "label": document.label,
              "content": document.content,
              "contentType": document.contentType,
              "name": document.name,
              "artifactId": document.id
            }
            if (document.label == "Package Slip") {
              this.breakBulkFile.push(_data);
            } else {
              this.documentList.push(_data);
            }
          }
          console.log(this.documentList);
          
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  changeContainerLoadType() {
    let clt = this.enquiry.containerLoadType.value;
    if (clt == 1) {
      this.fullContainerLoadBool = true;
      this.flatRackBool = false;
      this.container_details.clear();
      this.addSeaFCL();
      this.breakBulkBoolOption = true;
      this.breakbulkBool = false;
      this.break_bulk_details.clear()
      this.lessContainerLoadBool = false;
      this.package_details.clear();
      this.enquiry.break_bulk_label.setValue(false);
    }
    else if (clt == 2) {
      this.fullContainerLoadBool = false;
      this.container_details.clear();
      this.package_details.clear();
      this.break_bulk_details.clear()
      this.breakBulkBoolOption = false;
      this.breakbulkBool = false;
      this.enquiry.break_bulk_label.setValue(false);
      this.lessContainerLoadBool = true;
      this.addSeaHCL();
    }
    else if (clt == 3) {
      this.fullContainerLoadBool = true;
      this.container_details.clear();
      this.addSeaFCL();
      this.lessContainerLoadBool = true;
      this.package_details.clear();
      this.addSeaHCL();
      this.breakBulkBoolOption = false;
      this.breakbulkBool = false;
      this.break_bulk_details.clear()
      this.enquiry.break_bulk_label.setValue(false);
    }
    else if (clt == 4) {
      this.fullContainerLoadBool = false;
      this.lessContainerLoadBool = false;
      this.container_details.clear();
      this.package_details.clear();
      this.breakbulkBool = true;
      this.break_bulk_details.clear();
      this.totalBreakBulkWeight = 0.0;
      this.totalBreakBulkVolume = 0.0;``
      this.addBreakBulk();
      this.breakBulkBoolOption = false;
    }
  }

  changeTradeType() {
    let tradeType = this.enquiry.tradeType.value;
    if (tradeType == 2) {
      this.enquiry.supplierName.disable();
      this.enquiryForm.get('supplierName').clearValidators();
      this.enquiryForm.get('supplierName').updateValueAndValidity();
      this.supplierName = "";
    }
    else {
      this.supplierName = "Enter Name"
      this.enquiryForm.get('supplierName').setValidators([Validators.required]);
      this.enquiryForm.get('supplierName').updateValueAndValidity();
      this.enquiry.supplierName.enable();
    }

    if (tradeType == 3) {
      this.isThirdCountrySelected = true
      console.log('this is trade type 3 selected ', tradeType);
      this.enquiryForm.addControl('exporterName', new FormControl('', Validators.required));
      this.enquiryForm.addControl('exporterMailId', new FormControl('', Validators.required));
      this.enquiryForm.addControl('exporterContact', new FormControl('', Validators.required));
      this.enquiryForm.addControl('exporterAddress', new FormGroup({}))
      const exporterAddress = this.enquiryForm.get('exporterAddress') as FormGroup
      exporterAddress.addControl('label', new FormControl('Exporter Address'));
      exporterAddress.addControl('street1', new FormControl('', Validators.required));
      exporterAddress.addControl('street2', new FormControl('', Validators.required));
      exporterAddress.addControl('city', new FormControl('', Validators.required));
      exporterAddress.addControl('state', new FormControl('', Validators.required));
      exporterAddress.addControl('country', new FormGroup({}));
      let country = exporterAddress.get('country') as FormGroup;
      country.addControl('id', new FormControl('', Validators.required));
      exporterAddress.addControl('zipCode', new FormControl('', Validators.required));
    } else {
      this.isThirdCountrySelected = false
      this.enquiryForm.removeControl('exporterName');
      this.enquiryForm.removeControl('exporterMailId');
      this.enquiryForm.removeControl('exporterContact');
      this.enquiryForm.removeControl('exporterAddress');
    }
  }

  // ToDo, not used.
  selectLoadingPort() {
    let lPort = this.enquiry.origin.value
    let dPort = this.enquiry.destination.value
    if (lPort == dPort) {
      this.samePortError = true;
      Swal.fire({
        title: "Warning",
        icon: "warning",
        timer: 5000,
        text: "Loading & Destination Port cannot be same"
      })
    } else {
      this.samePortError = false;
    }
    for (let index = 0; index < this.filteredPorts.length; index++) {
      const element = this.filteredPorts[index];
      if(element.code.code == lPort){
        this.enquiry.currency.patchValue(element.country?.currency.currency);
      }
    }
  }

  downloadFileLocal(item) {
    if (item.artifactId != null && item.label != null) {
      this.dataURItoBlob(item);
    }
  }

  dataURItoBlob(data) {
    const linkSource = data.content;
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = data.name;
    downloadLink.click();
  }


  validateDeliveryAddress() {
    let street1 = this.enquiry.deliveryPersonAddress.value.street1
    let street2 = this.enquiry.deliveryPersonAddress.value.street2
    if (street1 == street2) {
      this.deliveryAddressBoolean = true;
    } else {
      this.deliveryAddressBoolean = false;
    }
  }

  validatePickupAddress() {
    let street1 = this.enquiry.pickupPersonAddress.value.street1
    let street2 = this.enquiry.pickupPersonAddress.value.street2
    if (street1 == street2) {
      this.pickupAddressBoolean = true;
    } else {
      this.pickupAddressBoolean = false;
    }
  }

  termsConditionsValidators() {
    let termBool = false;
    for (let index = 0; index < this.termsConditions.length; index++) {
      const term = this.termsConditions.at(index).value;
      if (term.label == "" || term.content == "") {
        this.termsConditions.removeAt(index);
      }
    }
  }

  getContianerType(index: number) {
    let type = this.container_details.at(index).get('type').value;
    let ty = this.container_type.find((x) => {
      if (x.id == type) {
        return x
      }
    })
    if (ty.value == "Flat Rack") {
      this.flatRackBool = true;
      for (let index = 0; index < this.container_details.length; index++) {
        this.container_details.at(index).get('size').clearValidators();
        this.container_details.at(index).get('size').updateValueAndValidity();
      }
      this.container_details.at(index).get('length').enable();
      this.container_details.at(index).get('width').enable();
      this.container_details.at(index).get('height').enable();
      this.container_details.at(index).get('size').disable();
      this.container_details.at(index).get('size').setValue('');
      this.container_details.at(index).get('flatRackUOM').enable();
      this.container_details.at(index).get('flatRackUOM').setValue('');
    }
    else {
      this.flatRackBool = false;
      this.container_details.at(index).get('size').enable();
      this.container_details.at(index).get('length').disable();
      this.container_details.at(index).get('length').setValue('');
      this.container_details.at(index).get('width').disable();
      this.container_details.at(index).get('width').setValue('');
      this.container_details.at(index).get('height').disable();
      this.container_details.at(index).get('height').setValue('');
      this.container_details.at(index).get('flatRackUOM').disable();
      this.container_details.at(index).get('flatRackUOM').setValue('');
    }
  }

  validateCargoReadinessDate() {
    let cargoDate = this.enquiry.cargoReadinessDate.value
    let offerDate = this.enquiry.lastSubmissionDate.value
    if (offerDate > cargoDate) {
      this.invalidOfferDate = true
    } else {
      this.invalidOfferDate = false
    }
  }

  maxOfferLastSubmissionDate() {
    let date = this.enquiry.cargoReadinessDate.value
    return date
  }

  minOfferLastSubmissionDate() {
    let enquiryDate = new Date().toISOString().substring(0, 16);
    return enquiryDate;
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  convertweightUOMtoMT(weightUOM: string, weight: number) {
    let _weight: number = 0.0;
    if (weightUOM == "KG") {
      _weight = (weight / 1000.00);
    }
    else if (weightUOM == "LBS") {
      _weight = (weight / 2204.62);
    }
    else {
      _weight = weight;
    }
    return parseFloat(_weight.toFixed(3));
  }

  calculateTotalVolume() {
    this.totalVolumeWeightLCL = 0.0;
    for (let index = 0; index < this.package_details.length; index++) {
      const _package = this.package_details.at(index);
      let quantity = _package.get('quantity').value;
      let length = _package.get('length').value;
      let height = _package.get('height').value;
      let width = _package.get('width').value;
      let dimensionsUOM = _package.get('dimensionsUOM').value;
      let totalVolume = (length * width * height) * quantity;
      let convert = this.convertdimensionsUOMtoMtr(dimensionsUOM, totalVolume);
      _package.get('totalVolume').patchValue(convert);
      _package.get('totalVolume').disable();
      this.totalVolumeWeightLCL = convert + this.totalVolumeWeightLCL;
    }
    this.enquiryForm.get('volumeInCBM').patchValue(this.totalVolumeWeightLCL);
    this.convertToMTsea();
  }

  convertdimensionsUOMtoMtr(dimensionsUOM, totalVolume) {
    let volume: number = 0.0;
    if (dimensionsUOM == "CM") {
      volume = (totalVolume / 1000000 );
    }
    else if (dimensionsUOM == "MM") {
      volume = (totalVolume / 1000000000 );
    }
    else if (dimensionsUOM == "IN") {
      volume = (totalVolume / 61020 );
    }
    else if (dimensionsUOM == "FT") {
      volume = (totalVolume / 35.315);
    }
    else {
      // mtr
      volume = totalVolume
    }
    return parseFloat(volume.toFixed(4));
  }

  compareWeightVolume() {
    let chargableWeight: number = 0.0;
    let metricTon: number = 0.0;
    for (let index = 0; index < this.package_details.length; index++) {
      let _package = this.package_details.at(index)
      if(this.finalSeaWeightUom == "KG"){
        if(_package.get('weightUOM').value == "LBS"){
          let lbs = this.convertLBStoMT(_package.get('grossWeight').value)
          metricTon = lbs + metricTon
        }
        else{
          let kg = this.convertKGtoMT(_package.get('grossWeight').value);
          metricTon = kg + metricTon
        }
      }
      else if(this.finalSeaWeightUom == "MT"){
        if(_package.get('weightUOM').value == "LBS"){
          let lbs = this.convertLBStoMT(_package.get('grossWeight').value)
          metricTon = lbs + metricTon
        }
        else if(_package.get('weightUOM').value == "KG"){
          let lbs = this.convertKGtoMT(_package.get('grossWeight').value)
          metricTon = lbs + metricTon
        }
        else{
          metricTon = _package.get('grossWeight').value + metricTon
        }
      }
      else{
        let lbs = this.convertLBStoMT(_package.get('grossWeight').value);
        metricTon = lbs + metricTon
      }
    }
    if(metricTon > this.totalVolumeWeightLCL) {
      this.finalSeaWeightBool = true;
      if(this.finalSeaWeightUom == "KG"){
        let kg = this.convertMTtoKG(metricTon);
        this.enquiryForm.get('chargableWeight').patchValue(kg);
      }
      else if(this.finalSeaWeightUom == "LBS"){
        let lbs = this.convertMTtoLBS(metricTon);
        this.enquiryForm.get('chargableWeight').patchValue(lbs);
      }
      else{
        this.enquiryForm.get('chargableWeight').patchValue(metricTon);
      }
      this.enquiryForm.get('chargableWeightUOM').patchValue(this.finalSeaWeightUom);
    }
    else{
      this.finalSeaWeightBool = false;
      this.enquiryForm.get('chargableWeightUOM').patchValue("CBM");
      chargableWeight = this.totalVolumeWeightLCL
      this.enquiryForm.get('chargableWeight').patchValue(chargableWeight);
    }
    return chargableWeight;
  }

  compareWeightVolumeAir() {
    let chargableWeight: number = 0.0;
    if(this.totalGrossWeightAIR > this.totalVolumeWeightAIR){
      this.finalAirWeightBool = true;
      this.enquiryForm.get('chargableWeight').patchValue(this.totalGrossWeightAIR);
    }
    else{
      this.finalAirWeightBool = false;
      this.enquiryForm.get('chargableWeight').patchValue(this.totalVolumeWeightAIR);
    }
    this.enquiryForm.get('chargableWeightUOM').patchValue(this.finalAirWeightUom);
    return chargableWeight;
  }

  convertToMTsea() {
    this.compareWeightUOMSea();
    this.calculateWeightSea();
    this.compareWeightVolume();
  }

  compareWeightUOMSea(){
    let comparison = ""
    for (let index = 0; index < this.package_details.length; index++) {
      const _package = this.package_details.at(index);
      let weightUOM = _package.get('weightUOM').value;
      let latest = weightUOM;
      if(comparison.length > 0){
        switch(latest){
          case "LBS":
            if(comparison == "KG"){
              comparison = "KG"
            }
            else if(comparison == "MT"){
              comparison = "MT"
            }
            else{
              comparison = latest
            }
            break
          case "KG":
            if(comparison == "MT"){
              comparison = "MT"
            }
            else if(comparison == "LBS"){
              comparison = "KG"
            }
            else{
              comparison = latest
            }
            break
          case "MT":
            comparison = latest
            break
        }
      }
      else{
        comparison = latest
      }
    }
    this.finalSeaWeightUom = comparison;
    this.enquiryForm.get('totalGrossWeightUOM').patchValue(this.finalSeaWeightUom);
  }

  calculateWeightSea(){
    this.totalGrossWeightLCL = 0.0
    for (let index = 0; index < this.package_details.length; index++) {
      const _package = this.package_details.at(index);
      let weightUOM = _package.get('weightUOM').value;
      let weight = _package.get('grossWeight').value;
      if(this.finalSeaWeightUom == "MT"){
        if(weightUOM == "KG"){
          let kg = this.convertKGtoMT(weight);
          this.totalGrossWeightLCL = kg + this.totalGrossWeightLCL;
        }
        else if(weightUOM == "LBS"){
          let lbs = this.convertLBStoMT(weight);
          this.totalGrossWeightLCL = lbs + this.totalGrossWeightLCL;
        }
        else{
          this.totalGrossWeightLCL = weight + this.totalGrossWeightLCL;
        }
      }
      else if(this.finalSeaWeightUom == "KG"){
        if(weightUOM == "LBS"){
          let lbs = this.convertLBStoKG(weight);
          this.totalGrossWeightLCL = lbs + this.totalGrossWeightLCL;
        }
        else{
          this.totalGrossWeightLCL = weight + this.totalGrossWeightLCL;
        }
      }
      else {
        this.totalGrossWeightLCL = weight + this.totalGrossWeightLCL;
      }
    }
    this.enquiryForm.get('totalGrossWeight').patchValue(this.totalGrossWeightLCL);
  }

  isValidFile(file: any) {
    return file.name.endsWith(".csv" || ".xlsx");
  }

  onChange(event) {
    this.fileName = event.srcElement.files[0].name;
    let files = event.srcElement.files;
    if (files.length > 1) {
      Swal.fire({
        titleText: "Warning",
        icon: "warning",
        timer: 5000,
        text: "Only one file allowed to upload"
      })
    }
    if (this.breakBulkFile.length == 1) {
      Swal.fire({
        titleText: "Warning",
        icon: "warning",
        timer: 5000,
        text: "Delete the uploaded file, first. Only one file allowed to upload"
      })
    }
    else {
      let file = files[0]
      // Here you can access the real file size & content
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let data = {
          "content": reader.result,
          "contentType": reader.result?.toString().split(',')[0],
          "name": file.name,
          "size": file.size,
          "label": "Package Slip"
        }
        this.breakBulkFile.push(data);
      }
    }
  }

  uploadPackageSlip(index) {
    let data = this.breakBulkFile[index];
    this.enquiryService.createEnquiryArtifact(this.enquiryId, data).subscribe(
      (response) => {
        if (response.code == 201) {
          data['artifactId'] = response.entityId;
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  deletePacakgeslip(index) {
    this.breakBulkFile.splice(index, 1);
  }

  deletePackagSlipServer(artifactId, index) {
    this.enquiryService.deleteEnquiryArtifact(this.enquiryId, artifactId).subscribe(
      (response) => {
        if (response.code == 200) {
          Swal.fire({
            titleText: "Success",
            text: response.message,
            icon: "success",
            timer: 5000
          });
          this.deletePacakgeslip(index)
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  downloadPackageslip(item) {
    if (item.artifactId != null && item.label != null) {
      this.dataURItoBlob(item);
    }
  }

  calculateAirVolume() {
    this.totalVolumeWeightAIR = 0.0;
    for (let index = 0; index < this.package_details.length; index++) {
      const _package = this.package_details.at(index);
      let length = _package.get('length').value;
      let height = _package.get('height').value;
      let width = _package.get('width').value;
      let quantity = _package.get('quantity').value;
      let dimensionsUOM = _package.get('dimensionsUOM').value;
      let totalVolume = (length * width * height) * quantity;
      let convert = this.convertdimensionsUOMtoMtrAir(dimensionsUOM, totalVolume);
      _package.get('totalVolume').patchValue(convert);
      _package.get('totalVolume').disable();
      this.totalVolumeWeightAIR = convert + this.totalVolumeWeightAIR;
    }
    this.convertToMTAIR();
  }

  convertToMTAIR() {
    this.compareWeightUomAir();
    this.calculateWeightAir();
    this.compareWeightVolumeAir();
  }

  compareWeightUomAir(){
    let comparison = ""
    for (let index = 0; index < this.package_details.length; index++) {
      const _package = this.package_details.at(index);
      let weightUOM = _package.get('weightUOM').value;
      let latest = weightUOM;
      if(comparison.length > 0){
        switch(latest){
          case "LBS":
            if(comparison == "KG"){
              comparison = "KG"
            }
            else{
              comparison = latest
            }
            break
          case "KG":
            if(comparison == "KG"){
              comparison = "KG"
            }
            else{
              comparison = latest
            }
            break
        }
      }
      else{
        comparison = latest
      }
    }
    this.finalAirWeightUom = comparison;
  }

  calculateWeightAir(){
    this.totalGrossWeightAIR = 0.0;
    for (let index = 0; index < this.package_details.length; index++) {
      const _package = this.package_details.at(index);
      let weightUOM = _package.get('weightUOM').value;
      let weight = _package.get('grossWeight').value;
      if(this.finalAirWeightUom == "KG"){
        if(weightUOM == "LBS"){
          let _weight = this.convertLBStoKG(weight);
          this.totalGrossWeightAIR = _weight + this.totalGrossWeightAIR;
        }
        else{
          this.totalGrossWeightAIR = weight + this.totalGrossWeightAIR;
        }
      }
      else if(this.finalAirWeightUom == "LBS"){
        this.totalGrossWeightAIR = weight + this.totalGrossWeightAIR;
      }
    }
  }

  convertdimensionsUOMtoMtrAir(dimensionsUOM, totalVolume) {
    let volume: number = 0.0;
    if(dimensionsUOM == "CM"){
      volume = totalVolume / 6000
    }
    else if(dimensionsUOM == "IN"){
      volume = totalVolume / 366
    }
    else if(dimensionsUOM == "MTR"){
      volume = totalVolume * 167
    }
    else if(dimensionsUOM == "FT"){
      volume = totalVolume * 4.7
    }
    else if(dimensionsUOM == "MM"){
      volume = (totalVolume/1000000000)*167
    }
    return parseFloat(volume.toFixed(4));
  }

  calculateBreakBulkWeight(index: number) {
    let break_bulk = this.break_bulk_details.at(index).value;
    this.totalBreakBulkVolume = break_bulk.totalVolume;
    this.totalBreakBulkWeight = this.convertweightUOMtoMT(break_bulk.uom, break_bulk.totalGrossWeight);
    if(this.totalBreakBulkVolume > this.totalBreakBulkWeight){
      this.enquiryForm.get('chargableWeight').patchValue(this.totalBreakBulkVolume);
      this.enquiryForm.get('chargableWeightUOM').patchValue("CBM");
      this.enquiryForm.get('totalGrossWeight').patchValue(this.totalBreakBulkVolume);
      this.enquiryForm.get('totalGrossWeightUOM').patchValue("CBM");
    }
    else{
      this.enquiryForm.get('chargableWeight').patchValue(this.totalBreakBulkWeight);
      this.enquiryForm.get('chargableWeightUOM').patchValue("MT");
      this.enquiryForm.get('totalGrossWeight').patchValue(this.totalBreakBulkWeight);
      this.enquiryForm.get('totalGrossWeightUOM').patchValue("MT");
    }
  }

  convertKGtoLBS(weight){
    let _weight = (weight * 2.205)
    return parseFloat(_weight.toFixed(4))
  }

  convertKGtoMT(weight){
    let _weight = (weight / 1000)
    return parseFloat(_weight.toFixed(4))
  }

  convertLBStoKG(weight){
    let _weight = (weight / 2.205)
    return parseFloat(_weight.toFixed(4))
  }

  convertLBStoMT(weight){
    let _weight = (weight / 2205)
    return parseFloat(_weight.toFixed(4))
  }
  
  convertMTtoKG(weight){
    let _weight = (weight * 1000)
    return parseFloat(_weight.toFixed(4))
  }

  convertMTtoLBS(weight){
    let _weight = (weight * 2204.62)
    return parseFloat(_weight.toFixed(4))
  }

  //fuction to scroll to top on next button click
  gotoTop() {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

  changeNatureOfMovement(){
    let natureOfMovement = this.enquiry.natureOfMovement.value;
    this.pickUpIncoTermsArray = [];
    this.dropOffIncoTermsArray = [];
    // Filter pickUp
    for (let index = 0; index < this.pickup_terms.length; index++) {
      const element = this.pickup_terms[index];
      let natureArray: Array<any> = element.natureOfMovement;
      natureArray.filter((x) => {
        if(x.code == natureOfMovement){
          this.pickUpIncoTermsArray.push(element);
        }
      })
    }
    // Filter dropOff
    for (let index = 0; index < this.dropoff_terms.length; index++) {
      const element = this.dropoff_terms[index];
      let natureArray: Array<any> = element.natureOfMovement;
      natureArray.filter((x) => {
        if(x.code == natureOfMovement){
          this.dropOffIncoTermsArray.push(element);
        }
      })
    }

  }

  fetchPortsByModeofShipmentCountry(countryId, modeOfShipmentId, type?:string){
    this.masterTableService.fetchPortsByModeOfShipmentAndCountryId(countryId, modeOfShipmentId).subscribe(
      (response: Array<any>) => {
        if(type == "loading"){
          this.loadingPorts = response;
        }
        else if(type == "destination"){
          this.destinationPorts = response
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getAllBusinessType() {
    this.masterTableService.getAllBusinessType().subscribe(
      (response) => {
        this.vendorBusinessTypeList = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getSearchResultsOrigin(){
    let modeOfShipmentValue = this.enquiry.modeOfShipment.value
    let size = 50
    if(this.searchOriginValue != ""){
      this.masterTableService.getSearchPort(modeOfShipmentValue, this.searchOriginValue, size).subscribe((res)=>{
        this.loadingPorts = res
      },
      (error)=>{
        console.log(error);
        
      })
    } else {
      console.log('search value is empty ', this.searchOriginValue);
    }
  }

  getSearchResultDestination(){
    let modeOfShipmentValue = this.enquiry.modeOfShipment.value
    let size = 50
    if(this.searchDestinationValue != ""){
      this.masterTableService.getSearchPort(modeOfShipmentValue, this.searchDestinationValue, size).subscribe((res)=>{
        this.destinationPorts = res
      },
      (error)=>{
        console.log(error);
      })
    } else {
      console.log('search value is empty ', this.searchDestinationValue);
    }
  }

  checkOriginDestination(){
    let origin = this.enquiry.origin.value;
    let destination = this.enquiry.destination.value;
    console.log(origin, destination);
    if(origin == destination){
      this.samePortError = true;
      console.log("INVALID");
    }
    else{
      this.samePortError = false;
      console.log("OKAY");
    }
  }

  addAllVendors(event){
    let value = event.target.checked;
    let assignedVendors: Array<any> = this.dataSource.data;
    if(value) {
      for (let index = 0; index < assignedVendors.length; index++) {
        const vendor = assignedVendors[index];
        vendor.flag = true;
        this.addVendors(vendor.id, index, vendor.flag);
      }
    }
    else {
      for (let index = 0; index < assignedVendors.length; index++) {
        const vendor = assignedVendors[index];
        vendor.flag = false;
        this.addVendors(vendor.id, index, vendor.flag);
      }
    }
  }

}
