import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { EnquiryService } from '../helpers/enquiry.service';
import { QuoteService } from '../helpers/quote.service';
import { SocketService } from '../helpers/socket.service';

@Component({
  selector: 'app-vendor-rfq-list',
  templateUrl: './vendor-rfq-list.component.html',
  styleUrls: ['./vendor-rfq-list.component.css']
})
export class VendorRfqListComponent implements OnInit {
  page: number = 0;
  size: number = 25;
  enquiryLength: number;
  public listingForm!: FormGroup;
  public enquiry_listing: any;
  public modeOfShipment: any;
  checkedList: Array<any> = []
  dataSource!: MatTableDataSource<any>;
  displayedColumns: string[] = ['action', 'number', 'modeOfShipment', 'origin', 'destination', 'description', 'enquiryDate', 'lastSubmissionDate'];
  // status
  nodata: Boolean = true;
  serchFilter: Boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  title: string;
  typeOfEnquiry: string = '';

  andriodVerison = "value";

  constructor(private enquiryService: EnquiryService, private route: ActivatedRoute, private router: Router, private socketService: SocketService,
    private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.typeOfEnquiry = params.get('type').substring(0, 1).toLowerCase();
      if (this.typeOfEnquiry == "f") {
        this.title = "Firm Enquiry Listing"
      }
      else {
        this.title = "Budgetory Enquiry Listing"
      }
      this.getAllEnquires();
    })

    // this.socketService.connectTOServerPopUpFuction();
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.nodata = false;
    this.serchFilter = true;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  compare(event, id) {
    let value = event.srcElement.checked;
    let _id = id;
    if (value == true) {
      this.checkedList.push(_id)
    } else {
      this.checkedList.splice(this.checkedList.indexOf(this.checkedList[_id], 1));
    }
  }

  enterQuote() {
    if (this.checkedList.length > 1 || this.checkedList.length == 0) {
      Swal.fire({
        titleText: 'Warning',
        icon: 'warning',
        text: 'Kindly Select One Enquiry',
        timer: 5000
      })
    } else {
      // ToDo, remaining.
      let obj = this.dataSource.data.find(x => {
        if(x.id == this.checkedList[0]){
          return x
        }
      });
      let now = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm');
      let last = this.datePipe.transform(obj.lastSubmissionDate, 'yyyy-MM-dd HH:mm');
      if(last < now){
        console.log("TRUE");
        Swal.fire({
          titleText: 'Warning',
          icon: 'warning',
          text: `Last Submission Date for enquiry was ${last}`,
          timer: 5000
        });
      }
      else{
        this.router.navigate(['/v1/vendor-rfq/quote/edit/', this.checkedList[0]])
      }
    }
  }

  quoteHistory() {
    if (this.checkedList.length > 1 || this.checkedList.length == 0) {
      Swal.fire({
        titleText: 'Warning',
        icon: 'warning',
        text: 'Kindly Select One Enquiry',
        timer: 5000
      })
    } else {
      this.router.navigate(['/v1/vendor-quote-comparison/', this.checkedList[0]])
    }
  }

  getAllEnquires() {
    this.enquiryService.getAllEnquiries(this.page, this.size, this.typeOfEnquiry).subscribe(
      (response) => {
        if (response != null) {
          this.enquiryLength = response.totalElements;
          for (let index = 0; index < response.content.length; index++) {
            const element = response.content[index];
            if (element.modeOfShipment == 1) {
              element["modeOfShipmentLabel"] = "AIR"
            }
            else if (element.modeOfShipment == 2) {
              element["modeOfShipmentLabel"] = "SEA"
            }
            else if (element.modeOfShipment == 3) {
              element["modeOfShipmentLabel"] = "ROAD"
            }
            else {
              element["modeOfShipmentLabel"] = "RAIL"
            }
          }
          this.dataSource = new MatTableDataSource(response.content);
          this.dataSource.sort = this.sort;

          this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
              case 'date': return new Date(item.lastSubmissionDate);
              default: return item[property];
            }
          }
        }
      },
      (error) => {
        console.log(error);
      },
    );
  }

  onPageChange(event: PageEvent) {
    this.size = event.pageSize;
    this.page = event.pageIndex;
    this.getAllEnquires();
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }
}
