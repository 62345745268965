<div mat-dialog-content>
    <div class="row mb-4 justify-content-center align-items-center">
        <h4 mat-dialog-title>Head Office Address</h4>
        <div class="col-lg-4">
            <label class="form-label"><b> Street 1 </b></label> <br>
            <label class="form-label">{{local_data.headOfficeAddress.street1}}</label>
        </div>
        <div class="col-lg-4">
            <label class="form-label"><b> Street 2 </b></label> <br>
            <label class="form-label">{{local_data.headOfficeAddress.street2}}</label>
        </div>
        <div class="col-lg-4">
            <label class="form-label"><b> City </b></label> <br>
            <label class="form-label">{{local_data.headOfficeAddress.city}}</label>
        </div>
        <div class="col-lg-4">
            <label class="form-label"><b> State </b></label> <br>
            <label class="form-label">{{local_data.headOfficeAddress.state}}</label>
        </div>
        <div class="col-lg-4">
            <label class="form-label"><b> Country </b></label> <br>
            <label class="form-label">{{local_data.headOfficeAddress.country}}</label>
        </div>
        <div class="col-lg-4">
            <label class="form-label"><b> ZipCode </b></label> <br>
            <label class="form-label">{{local_data.headOfficeAddress.zipCode}}</label>
        </div>
        <br>
    </div>
</div>

<div mat-dialog-content>
    <div class="row mb-4 justify-content-center align-items-center">
        <h4 mat-dialog-title>Regional Office Address</h4>
        <div class="col-lg-4">
            <label class="form-label"><b> Street 1 </b></label> <br>
            <label class="form-label">{{local_data.regionalOfficeAddress.street1}}</label>
        </div>
        <div class="col-lg-4">
            <label class="form-label"><b> Street 2 </b></label> <br>
            <label class="form-label">{{local_data.regionalOfficeAddress.street2}}</label>
        </div>
        <div class="col-lg-4">
            <label class="form-label"><b> City </b></label> <br>
            <label class="form-label">{{local_data.regionalOfficeAddress.city}}</label>
        </div>
        <div class="col-lg-4">
            <label class="form-label"><b> State </b></label> <br>
            <label class="form-label">{{local_data.regionalOfficeAddress.state}}</label>
        </div>
        <div class="col-lg-4">
            <label class="form-label"><b> Country </b></label> <br>
            <label class="form-label">{{local_data.regionalOfficeAddress.country}}</label>
        </div>
        <div class="col-lg-4">
            <label class="form-label"><b> ZipCode </b></label> <br>
            <label class="form-label">{{local_data.regionalOfficeAddress.zipCode}}</label>
        </div>
        <br>
    </div>
</div>