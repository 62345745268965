import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MasterTableService } from 'src/app/helpers/master-table.service';
import { PackingBoxComponent } from './packing-box/packing-box.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-type-of-packing',
  templateUrl: './type-of-packing.component.html',
  styleUrls: ['./type-of-packing.component.css']
})
export class TypeOfPackingComponent implements OnInit {

  displayedColumns: string[] = ['id', 'value', 'actions']
  dataSource = new MatTableDataSource();
  serchFilter: Boolean = false;
  nodata: Boolean = true;

  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort
  length: number;
  size: number = 25;
  page: number = 0;

  sessionStorageUserData: any
  disableCreateBtn: boolean = true
  disableEditBtn: boolean = true
  disableDeleteBtn: boolean = true

  constructor(private masterTableService: MasterTableService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getAllPacking();
    this.getCurrentUserDataFromSessionStorage();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
    this.serchFilter = true;
    this.nodata =false;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDialog(action, obj) {
    obj.action = action
    const dialogRef = this.dialog.open(PackingBoxComponent, {
      width: '250px',
      data: obj
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == 'Add') {
        this.addPacking(result.data);
        this.getAllPacking();
      } else if (result.event == 'Update') {
        this.updatePacking(result.data.id, result.data.value);
        this.getAllPacking();
      } else if (result.event == 'Delete') {
        this.deletePacking(result.data.id);
        this.getAllPacking();
      }
    });
  }

  getAllPacking() {
    this.masterTableService.getTypeOfPackingPageable(this.page, this.size).subscribe(
      (response) => {
        if (response.content != null) {
          this.dataSource = new MatTableDataSource(response.content);
          this.dataSource.sort = this.sort
          this.length = response.totalElements;
        }
      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      }
    )
  }

  addPacking(data: any) {
    let _data = { "value": data.value }
    this.masterTableService.addTypeOfPacking(_data).subscribe(
      (response) => {
        if (response.code == 201) {
          Swal.fire({
            titleText: "Success",
            icon: "success",
            text: response.message,
            timer: 5000
          })
        };
        setTimeout(() => {
          this.getAllPacking();
        }, 500);
      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      }
    );
  }

  updatePacking(id: any, data: any) {
    let _data = { "id": id, "value": data };
    this.masterTableService.updateTypeOfPacking(id, _data).subscribe((response) => {
      if (response.code == 201) {
        Swal.fire({
          titleText: "Success",
          icon: "success",
          text: response.message,
          timer: 5000
        })
      };
      setTimeout(() => {
        this.getAllPacking();
      }, 500);
    },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      }
    );
  }


  deletePacking(id) {
    this.masterTableService.deleteTypeOfPacking(id).subscribe(
      (response) => {
        if (response.code == 201) {
          Swal.fire({
            titleText: "Success",
            icon: "success",
            text: response.message,
            timer: 5000
          })
        };
        setTimeout(() => {
          this.getAllPacking();
        }, 500);
      },
      (error) => {
        console.log(error);
        let _error = error.error;
        if (_error.errorCode == 412) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 403) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        } else if (_error.errorCode == 500) {
          Swal.fire({
            titleText: "Warning",
            icon: "warning",
            text: _error.errorMessage,
            timer: 5000
          });
        }
        else {
          Swal.fire({
            titleText: "Error",
            icon: "error",
            text: "Something Went Wrong",
            timer: 5000
          });
        }
      }
    );
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  onPageChange(event: PageEvent) {
    this.size = event.pageSize;
    this.page = event.pageIndex;
    this.getAllPacking();
  }

  getCurrentUserDataFromSessionStorage(){
    // create auth code
    // RB0000082
    // update auth code
    // RB0000083
    // delete auth code
    // RB0000093
      this.sessionStorageUserData = JSON.parse(sessionStorage.getItem('userData'));
      console.log('this is user data ', this.sessionStorageUserData);
      for (let index = 0; index < this.sessionStorageUserData.authorities.length; index++) {
        const authority = this.sessionStorageUserData.authorities[index];
        console.log(authority);
        if(authority == 'RB0000082'){
          console.log('create authority is given');
          this.disableCreateBtn = false
        } else if(authority == 'RB0000083'){
          console.log('update authority is given');
          this.disableEditBtn = false
        } else if(authority == 'RB0000093') {
          console.log('delete authority is given');
          this.disableDeleteBtn = false
        }
      }
    }
}
