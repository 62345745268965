import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { EnquiryService } from '../helpers/enquiry.service';
import { MasterTableService } from '../helpers/master-table.service';
import { QuoteService } from '../helpers/quote.service';
import { SocketService } from '../helpers/socket.service';

@Component({
  selector: 'app-quote-comparison',
  templateUrl: './quote-comparison.component.html',
  styleUrls: ['./quote-comparison.component.css']
})
export class QuoteComparisonComponent implements OnInit {
  enquiryId: string;
  displayedColumns: string[] = ['quoteNumber', 'date', 'vendorName', 'lineName', 'rank', 'totalAmount', 'actions'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  dataSource: any;
  checkedList: Array<any> = [];
  rankList: Array<any> = [];
  quoteArray = [];
  noQuote: boolean = true;
  rfqObject: any;

  constructor(private route: ActivatedRoute, private router: Router, private quoteService: QuoteService, private masterTableService: MasterTableService, private socketService: SocketService,
    private enquiryService: EnquiryService) {}

  ngOnInit(): void {
    this.enquiryId = this.route.snapshot.paramMap.get("id");
    if (this.enquiryId != null || undefined) {
      this.fetchQuotesByEnquiryId(this.enquiryId);
    }
    else {
      console.log("NO ENQUIRY ID TO GET QUOTES");
    }
    // this.socketService.connectTOServerPopUpFuction();
  }


  fetchQuotesByEnquiryId(id) {
    this.quoteService.fetchQuotesByEnquiry(id).subscribe(
      (response: Array<any>) => {
        console.log(response);
        if(response.length == 0){
          this.getEnquiryById();
          this.dataSource = new MatTableDataSource();
          this.dataSource.sort = this.sort;
          this.noQuote = false;
        }
        else{
          response.forEach(element => {
            element.vendorQuoteRows.forEach((vendorQuote) => {
              let obj = {
                number: element.number,
                quoteDate: element.quoteDate,
                vendorName: element.vendor.name,
                vendorQuoteId: vendorQuote.id,
                lineName: vendorQuote.lineName,
                amount: vendorQuote.amount,
                id: element.id
              }
              this.quoteArray.push(obj);
            });
            // this.rfqObject = element.enquiry;
          });
  
          this.quoteArray.sort((a, b) => (a.amount > b.amount) ? 1 : -1);
          this.dataSource = new MatTableDataSource(this.quoteArray);
  
          this.dataSource.sort = this.sort;
  
          for (let index = 0; index < response.length; index++) {
            let quote = response[index];
            console.log(quote)
            this.rankList.push(quote.amount);
            this.rfqObject = quote.enquiry
          }
          this.rankList.sort((a, b) => a < b ? -1 : a > b ? 1 : 0)	// Ascending
          console.log(this.rfqObject.type);
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getEnquiryById(){
    this.enquiryService.fetchEnquiryById(JSON.parse(this.enquiryId)).subscribe(
      (response) => {
        this.rfqObject = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  detailQuoteComparisonRoute() {
    this.router.navigate(['/v1/client-rfq/detail-quote-comparison/', this.enquiryId]);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  compare(event, VendorQuoteRowid, VendorQuoteId) {
    let value = event.srcElement.checked;
    let _id = VendorQuoteRowid;
    if (value == true) {
      this.checkedList.push(_id);
    }
    else if (value == false) {
      this.checkedList.splice(this.checkedList.indexOf(_id), 1);
    }
    else {
      console.log("NULL");
    }
  }

  createAuction(type: string) {
    this.router.navigate(['/v1/client-auction/creation/', this.enquiryId], { queryParams: { type } });
  }

  createServiceOrder() {
    if (this.checkedList.length > 1 || this.checkedList.length == 0) {
      Swal.fire({
        titleText: "Warning",
        icon: "warning",
        text: "Check only one checkbox",
        timer: 5000,
        showCancelButton: true,
        showCloseButton: true,
      })
    }
    else {
      this.router.navigate(['/v1/client-service-order/creation/', "q", this.checkedList[0]]);
    }
  }

  viewSingleQuote() {
    if (this.checkedList.length > 1 || this.checkedList.length == 0) {
      Swal.fire({
        titleText: "Warning",
        icon: "warning",
        text: "Check only one checkbox",
        timer: 5000,
        showCancelButton: true,
        showCloseButton: true,
      })
    }
    else {
      this.router.navigate(['/v1/client-rfq/single-quote-view/', this.checkedList[0]]);
    }
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }
}