import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MasterTableService } from 'src/app/helpers/master-table.service';
import { QuoteService } from 'src/app/helpers/quote.service';
import { SocketService } from 'src/app/helpers/socket.service';

@Component({
  selector: 'app-single-quote-view',
  templateUrl: './single-quote-view.component.html',
  styleUrls: ['./single-quote-view.component.css']
})
export class SingleQuoteViewComponent implements OnInit {

  vendorQuote: any;
  enquiry: any;
  mode_of_shipment: Array<any> = [];
  container_type: Array<any> = [];
  trade_type: Array<any> = [];
  // originDestination: Array<any> = [];
  type_cargo: Array<any> = [];
  nature_of_movement: Array<any> = [];
  pickup_terms: Array<any> = [];
  dropoff_terms: Array<any> = [];
  nature_goods: Array<any> = [];
  type_packing: Array<any> = [];
  typeOfCargo: string;
  pickUpIncoTerms: string;
  deliveryIncoterms: string;
  sea_mode_shipment: Boolean = false;
  showPickupAddress: Boolean = false;
  createdDate: string;
  currency: string;
  containerTotalFreight: number = 0;
  breakBulkTotalFreight: number = 0;
  shipmentPackageTotalFreight: number = 0;
  grandTotalAmount: number = 0;
  additionalChargeTotal: number = 0;
  containers: Array<any> = [];
  shipmentPackages: Array<any> = [];
  breakBulks: Array<any> = [];
  additionalCharges: Array<any> = [];
  chargableWeight: number;
  chargableWeightUOM: string;
  math = Math;
  airBool: Boolean = false;
  seaBool: Boolean = false;

  constructor(private route: ActivatedRoute, private quoteService: QuoteService, private masterTableService: MasterTableService, private socketService: SocketService) { }
  ngOnInit(): void {
    let id = this.route.snapshot.paramMap.get('id');
    this.getQuoteById(id);
    this.getMasterTablesSessionStorage();
    // this.socketService.connectTOServerPopUpFuction();
  }

  getQuoteById(id){
    this.quoteService.fetchVendorQuoteRowById(id).subscribe(
      (response) => {
        console.log(response);
        this.vendorQuote = response;
        this.chargableWeight = this.vendorQuote.vendorQuote.enquiry.chargableWeight;
        this.enquiry = this.vendorQuote.vendorQuote.enquiry;
        console.log(this.enquiry);
        if(this.enquiry.modeOfShipment == 1){
          this.airBool = true;
        }
        else if(this.enquiry.modeOfShipment == 2){
          this.seaBool = true;
        }
        // else if(this.enquiry.modeOfShipment == 3){}
        // else if(this.enquiry.modeOfShipment == 4){}
        this.chargableWeightUOM = (this.chargableWeight + ' ' + this.vendorQuote.vendorQuote.enquiry.chargableWeightUOM);
        this.filterData();
      },
      (error) => {
        console.log(error);
        // Interceptor error catch, show swal.
      },
    )
  }

  filterData(){
    let containers: Array<any> = this.vendorQuote.vendorQuoteContainers;
    let breakBulk: Array<any> = this.vendorQuote.vendorQuoteBreakBulks;
    let shipment: Array<any> = this.vendorQuote.vendorQuoteShipmentPackages;
    let additional: Array<any> = this.vendorQuote.additionalCharges;
    if(containers.length > 0){
      this.containers = containers;
    }
    if(breakBulk.length > 0){
      this.breakBulks = breakBulk;
    }
    if(shipment.length > 0){
      this.shipmentPackages = shipment;
    }
    if(additional.length > 0){
      this.additionalCharges = additional;
    }
  }

  getMasterTablesSessionStorage() {
    this.containerTypeList();
    this.getModeOfShipment();
    this.getTradeType();
    this.getTypeOfCargo();
    this.getNatureOfMovement();
    this.getPickUpIncoTerms();
    this.getDeliveryIncoTerms();
    this.getNatureOfGoods();
    this.getTypeOfPacking();
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  containerTypeList(){
    this.masterTableService.getContainerTypes().subscribe(
      (response) => {
        if(response){
          this.container_type = response
        }
      },
      (error) => {
        console.log(error);
      },
    );
  }

  getModeOfShipment(){
    this.masterTableService.getModeOfShipment().subscribe(
      (response) => {
        this.mode_of_shipment = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getTradeType(){
    this.masterTableService.getTradeType().subscribe(
      (response) => {
        this.trade_type = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getTypeOfCargo(){
    this.masterTableService.getAllTypeOfCargo().subscribe(
      (response) => {
        this.type_cargo = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getNatureOfMovement(){
    this.masterTableService.getNatureOfMovement().subscribe(
      (response) => {
        this.nature_of_movement = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getPickUpIncoTerms(){
    this.masterTableService.getAllPickUpIncoTerms().subscribe(
      (response) => {
        this.pickup_terms = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getDeliveryIncoTerms(){
    this.masterTableService.getDeliveryIncoTerms().subscribe(
      (response) => {
        this.dropoff_terms = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getNatureOfGoods(){
    this.masterTableService.getNatureOfGoods().subscribe(
      (response) => {
        this.nature_goods = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

  getTypeOfPacking(){
    this.masterTableService.getAllTypeOfPacking().subscribe(
      (response) => {
        this.type_packing = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }
}
