import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { QuoteService } from 'src/app/helpers/quote.service';
import { SocketService } from 'src/app/helpers/socket.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-vendor-quote-comparison',
  templateUrl: './vendor-quote-comparison.component.html',
  styleUrls: ['./vendor-quote-comparison.component.css']
})
export class VendorQuoteComparisonComponent implements OnInit {

  displayedColumns: string[] = ['shippingLine', 'quoteValidity', 'frequency', 'transitPort', 'transitTime', 'totalFreight'];
  displayedColumns1: string[] = ['id','shippingLine', 'quoteValidity', 'frequency', 'transitPort', 'transitTime', 'totalFreight'];
  dataSource: MatTableDataSource<any>;
  dataSource1: MatTableDataSource<any>;
  enquiryId: string;
  checkedList: Array<any> = [];
  rfqNumber: any
  quoteNumber: any;
  qId: number;
  btndisable: Boolean;
  title: string = "Quote Comparison";
  noQuoteBool: Boolean = false;

  @ViewChild(MatSort) sort!: MatSort;

  constructor(private route: ActivatedRoute, private router: Router, private quoteService: QuoteService, private socketService: SocketService) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      let id = params.get('id')
      this.getAllQuote(id);
    })

    // this.socketService.connectTOServerPopUpFuction();
  }

  getAllQuote(id: any) {
    this.quoteService.fetchQuotesByEnquiry(id).subscribe(
      (response) => {
        if (response.length > 0 ) {
          let data = [];
          let quote = response[0];
          this.rfqNumber = quote.enquiry.number;
          this.quoteNumber = quote.number
          this.qId = quote.id;
          this.getAllQuoteHistory();
          let vendorQuoteRows: Array<any> = quote.vendorQuoteRows;
          for (let index = 0; index < vendorQuoteRows.length; index++) {
            const element = vendorQuoteRows[index];
            let _data = {
              "shippingLine": element.lineName,
              "frequency": element.frequency,
              "quoteValidity": element.quoteValidTill,
              "totalFreight": element.amount,
              "transitPort": element.transitPort,
              "transitTime": element.transitTime,
            }
            data.push(_data);
          }
          this.dataSource = new MatTableDataSource(data)
          this.dataSource.sort = this.sort;
        }
        else{
          this.noQuoteBool = true;
        }
      },
      (error) => {
        console.log(error);
      }
    )
  }

  gotodetail() {
    this.router.navigate(['/v1/vendor-detail-quote-comparison/', this.qId])
  }

  getAllQuoteHistory() {
    this.quoteService.getAllQuoteHistory(this.qId).subscribe(
      (response: Array<any>) => {
        if(response.length > 0){
          let data = [];
          this.btndisable = true;
          for (let index = 0; index < response.length; index++) {
            const quote = response[index];
            let vendorQuoteRowHistory: Array<any> = quote.vendorQuoteRowHistory;
            for (let index = 0; index < vendorQuoteRowHistory.length; index++) {
              const element = vendorQuoteRowHistory[index];
              let _data = {
                "shippingLine": element.lineName,
                "frequency": element.frequency,
                "quoteValidity": element.quoteValidTill,
                "totalFreight": element.amount,
                "transitPort": element.transitPort,
                "transitTime": element.transitTime,
                "id": quote.id
              }
              data.push(_data);
            }
          }
          this.dataSource1 = new MatTableDataSource(data)
          this.dataSource1.sort = this.sort;
        }
        else{
          this.btndisable = false;
          this.noQuoteBool = true;
        }
      },
      (error) => {
        console.log(error);
      }
    )
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }
}
