import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MasterTableService } from 'src/app/helpers/master-table.service';
import Swal from 'sweetalert2';
import { BusinessTypeDialogComponent } from '../../business-type/business-type-dialog/business-type-dialog.component';

@Component({
  selector: 'app-country-dialog-box',
  templateUrl: './country-dialog-box.component.html',
  styleUrls: ['./country-dialog-box.component.css']
})
export class CountryDialogBoxComponent implements OnInit {

  action: string;
  local_data: any;
  countryForm: FormGroup;
  currencyList: Array<any> = [];

  constructor(public dialogRef: MatDialogRef<BusinessTypeDialogComponent>, public fb: FormBuilder, 
    private masterTableServie: MasterTableService,
    //@Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data) {
    console.log(data);
    this.local_data = { ...data };
    this.action = this.local_data.action; { }

  }

  ngOnInit(): void {
    this.getAllCurrencies();

    this.countryForm = this.fb.group({
      currency: this.fb.group({
        id: ['', Validators.required]
      }),
      name: ["", Validators.required],
    });

    if(this.action != "Add"){
      this.countryForm.patchValue({
        name: this.local_data.name,
      });
      this.currency.get('id').patchValue(this.local_data.currency?.id);
      this.countryForm.addControl('id', new FormControl(this.local_data.id));      
    }

    console.log(this.countryForm.value);
  }

  get currency(){
     return this.countryForm.get('currency') as  FormGroup;
  }

  doAction() {
    if (this.countryForm.valid) {
      this.dialogRef.close({ event: this.action, data: this.countryForm.value });
    } else {
      Swal.fire({
        title: "warning",
        icon: "warning",
        titleText: "Fill all mandatory fields",
        timer: 3000
      })
    }
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  getAllCurrencies(){
    this.masterTableServie.getAllCurrency().subscribe(
      (response) => {
        this.currencyList = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

}
