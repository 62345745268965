import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MasterTableService } from 'src/app/helpers/master-table.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dialog-loading-destination-port',
  templateUrl: './dialog-loading-destination-port.component.html',
  styleUrls: ['./dialog-loading-destination-port.component.css']
})
export class DialogLoadingDestinationPortComponent implements OnInit {

  dialogform: FormGroup;
  local_data: any;
  action: any;
  isReadOnly = false;
  isrequired = true;
  modeOfShipment = [];
  countryList: Array<any> = [];

  constructor(
    private service: MasterTableService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<DialogLoadingDestinationPortComponent>,

    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.action = data.action;
    this.local_data = { ...data };
    console.log(this.local_data);
  }
  ngOnInit(): void {
    this.dialogform = this.formBuilder.group({
      code: this.formBuilder.group({
        code: ['', Validators.required],
        modeOfShipmentId: ['', Validators.required]
      }),
      value: [""],
      country: this.formBuilder.group({
        id: ['']
      })
    });
    if (this.action == 'Update') {
      this.isReadOnly = true;
      this.isrequired = false;
      this.dialogform.patchValue({
        value: this.local_data.value,
        country: { id: this.local_data.country?.id }
      });
      this.code.get('code').patchValue(this.local_data.code.code);
      this.code.get('modeOfShipmentId').patchValue(this.local_data.code.modeOfShipmentId);
      this.code.get('modeOfShipmentId').disable();
    }
    if (this.action == 'Delete') {
      this.dialogform.patchValue({
        value: this.local_data.value,
        country: { id: this.local_data.country?.id }
      });
      this.code.get('code').patchValue(this.local_data.code.code);
      this.code.get('modeOfShipmentId').patchValue(this.local_data.code.modeOfShipmentId);
    }
    this.getAllModeOfShipments()
    this.getCountry();
  }

  get formcontrol() {
    return this.dialogform.controls
  }

  get code(){ return this.dialogform.get('code') as FormGroup; }

  doAction() {
    if (this.dialogform.valid) {
      this.dialogRef.close({ event: this.action, data: this.dialogform.getRawValue() });
    } else {
      Swal.fire({
        title: 'warning',
        icon: 'warning',
        titleText: 'Please provide mandatory field',
        timer: 3000
      });
    }
  }
  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  // to get all list of mode of shipment
  getAllModeOfShipments() {
    this.service.getModeOfShipment().subscribe(
      (response) => {
        this.modeOfShipment = response;
      },
      (error) => {
        console.log(error);
      }
    )
  }

  getCountry(){
    this.service.getAllCountries().subscribe(
      (response) => {
        this.countryList = response;
      },
      (error) => {
        console.log(error);
      }
    )
  }
}

