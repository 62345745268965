<header class="box-absolute d-flex align-items-center topline text-white">
    <app-sidebar></app-sidebar>
    <div class="breadcrumb-div">
        <ul class="breadcrumb p-0 m-0">
            <li class="breadcrumb-item 3" id="0"><a [routerLink]="['/v1/dashboard']">Dashboard</a></li>
            <li class="breadcrumb-item 3" id="0"><a>Pre Shipment Invoice</a></li>
            <li class="breadcrumb-item 3" id="0"><a>{{isEdit ? 'Update' : 'Creation'}}</a></li>

        </ul>
    </div>
</header>

<div (click)="hideSideBar()">
    <section class="banner text-center text-white d-flex" id="bnner">
        <div class="container position-relative">
            <div class="row justify-content-center">
                <div class="col-lg-15">
                    <h1>Pre Shipment Invoice {{isEdit ? 'Update' : 'Creation'}}</h1>
                </div>
            </div>
        </div>
    </section>

    <section class="background">
        <div *ngIf="templateObject != null" class="container">
            <form [formGroup]="invoiceTemplateForm">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card border-0 h-100 rounded-0  pt-4 shadow rounded-xxl ">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-10">
                                        <label class="form-label">Invoice Number</label>
                                    </div>
                                </div>
                                <input class="form-control mt-1" formControlName="number" id="invoiceNumber" type="text"
                                    placeholder="Auto-generated if left blank">
                            </div>
                        </div>
                    </div>

                    <!-- buyersReference, otherRef completed  -->
                    <div class="col-lg-12">
                        <div class="card border-0 h-100 rounded-0  pt-4 shadow rounded-xxl ">
                            <div class="card-body">
                                <div class="row">
                                    <div *ngIf="templateObject.buyersRefRequired" class="col-lg-4 mt-2">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <label class="form-label">Buyers Reference</label>
                                            </div>
                                        </div>
                                        <input class="form-control mt-1" formControlName="buyersReference"
                                            id="buyersReference2" type="text" placeholder="Text Input">
                                    </div>
                                </div>
                                <!-- otherRef -->
                                <div *ngIf="templateObject.otherRefRequired" class="row">
                                    <div class="col-md-12 mt-2" formArrayName="otherRef">
                                        <div class="row">
                                            <div class="row">
                                                <div class="col-md-2">
                                                    <label for="">Other Reference</label>
                                                </div>
                                                <div class="col-md-1">
                                                    <button class="fas fa-plus-circle text-success"
                                                        (click)="addAlternateOtherRef()"></button>
                                                </div>
                                            </div>
                                            <div class="col-md-4"
                                                *ngFor="let otherref of otherRef.controls; let i=index; let l=last"
                                                [formGroupName]="i">
                                                <div class="row">
                                                    <div class="col-md-10">
                                                        <input class="form-control mt-1"
                                                            formControlName="otherReference" type="text"
                                                            placeholder="Text Input">
                                                    </div>
                                                    <div class="col-md-1 mt-2">
                                                        <button *ngIf="otherRef.length > 1"
                                                            class="fas fa-trash btn-outline-danger btn-sm"
                                                            (click)="removeAlternateOtherRef(i)"></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Party details -->
                    <div class="col-md-12 mt-3">
                        <div class="card border-0 rounded-0  pt-4 shadow rounded-xxl ">
                            <div class="row">
                                <div class="col-md-6">
                                    <h4 class="ml-1">Party Details</h4>
                                </div>
                                <div class="col-md-6">
                                    <button class="btn-outline-success fas fa-plus pull-right mr-1"
                                        (click)="addNewParty()">Party</button>
                                </div>
                            </div>
                            <div class="row" formArrayName="parties">
                                <div class="col-md-6" *ngFor="let item of parties.controls; let i = index"
                                    [formGroupName]="i">
                                    <div class="card border-0 rounded-0  pt-4 shadow rounded-xxl m-2">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <select class="form-select" formControlName="partyType">
                                                                <option *ngFor="let party of partyTypes" [value]="party">
                                                                    {{party}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div class="col-md-4">

                                                        </div>
                                                        <div class="col-md-2">
                                                            <button class="btn-outline-danger btn-sm fas fa-trash pull-right"
                                                                *ngIf="parties.length > 1" (click)="removeParty(i)"></button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 mt-1">
                                                    <input class="form-control inputLine" list="party" type="text" formControlName="label" >
                                                    <datalist id="party">
                                                        <option *ngFor="let party of partyLabel" [value]="party">{{ party }}</option>
                                                    </datalist>
                                                    <input type="text" id="label" class="form-control"
                                                        placeholder="Enter Name" formControlName="name">
                                                </div>
                                                <div class="col-md-6">
                                                    <label for="" class="form-label">Email-Id</label>
                                                    <input type="email" id="email-id" class="form-control mt-1"
                                                        placeholder="Enter Email-Id" formControlName="email">
                                                </div>
                                                <div class="row" formArrayName="contactNumbers">
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <label for="" class="form-label mt-2">Contact No</label>
                                                        </div>
                                                        <div class="col-md-2">
                                                            <button class="fas fa-plus-circle text-success mt-2"
                                                                (click)="addNewContactNo(i)"></button>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6"
                                                        *ngFor="let contact of partyContactNo(i).controls; let ind = index"
                                                        [formGroupName]="ind">
                                                        <div class="row">
                                                            <div class="col-md-10">
                                                                <input type="number" id="contact-number"
                                                                    class="form-control m-1" placeholder="Enter Number"
                                                                    formControlName="contacts" min="0">
                                                            </div>
                                                            <div class="col-md-1">
                                                                <button
                                                                    class="btn-outline-danger btn-sm fas fa-trash mt-2"
                                                                    *ngIf="partyContactNo(i).length > 1"
                                                                    (click)="removeContactNo(i, ind)"></button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div class="col-md-6">
                                                    <label for="" class="form-label mt-2">Address</label>
                                                    <select class="form-control form-select"
                                                        (click)="selectAddress($event, i)">
                                                        <option value="" selected>Select Address</option>
                                                        <option *ngFor="let address of addressList"
                                                            [value]="address.id">{{address.street1+ " " +
                                                            address.street2 + " " + address.city + " " + address.state +
                                                            ", " + address.country.name + ", " + address.zipCode + " " +
                                                            address.label}}</option>
                                                    </select>
                                                </div>

                                                <div class="col-md-12" formGroupName="address">
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <label for="" class="form-label mt-1">Street 1</label>
                                                            <input type="text" class="form-control"
                                                                formControlName="street1">
                                                        </div>
                                                        <div class="col-md-4">
                                                            <label for="" class="form-label mt-1">Street 2</label>
                                                            <input type="text" class="form-control"
                                                                formControlName="street2">
                                                        </div>
                                                        <div class="col-md-4">
                                                            <label for="" class="form-label mt-1">City</label>
                                                            <input type="text" class="form-control"
                                                                formControlName="city">
                                                        </div>
                                                        <div class="col-md-4">
                                                            <label for="" class="form-label mt-1">State</label>
                                                            <input type="text" class="form-control"
                                                                formControlName="state">
                                                        </div>
                                                        <div formGroupName="country" class="col-md-4">
                                                            <label for="" class="form-label mt-1">Country</label>
                                                            <select class="form-control form-select"
                                                                formControlName="id">
                                                                <option value="" disabled selected>Select Country
                                                                </option>
                                                                <option *ngFor="let country of countryCodes"
                                                                    [value]="country.id">
                                                                    {{ country.name }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <label for="" class="form-label mt-1">ZipCode</label>
                                                            <input type="text" class="form-control"
                                                                formControlName="zipCode">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Details, completed -->
                    <div class="col-lg-12 mt-3">
                        <div class="card border-0 rounded-0  pt-4 shadow rounded-xxl ">
                            <div class="card-body">
                                <div class="row">
                                    <div *ngIf="templateObject.exporterRefRequired" class="col-lg-3">
                                        <div class="row">
                                            <div class="col-md-10" style="white-space:nowrap">
                                                <label class="form-label">{{ templateObject.exporterRefLabel }}</label>
                                            </div>
                                        </div>
                                        <input type="text" class="form-control" formControlName="exportersReference">
                                    </div>

                                    <div *ngIf="templateObject.countryOfOriginOfGoodsRequired" class="col-lg-3">
                                        <div class="row">
                                            <div class="col-md-10" style="white-space:nowrap">
                                                <label class="form-label">Country Of Origin Of Goods</label>
                                            </div>
                                        </div>
                                        <select class="form-select form-control mb-3"
                                            formControlName="countryOfOriginOfGoods">
                                            <option value="" disabled selected>Select Country</option>
                                            <option [value]="count.name" *ngFor="let count of countryCodes">
                                                {{count.name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div *ngIf="templateObject.countryOfFinalDestinationRequired" class="col-lg-3">
                                        <div class="row">
                                            <div class="col-md-10" style="white-space:nowrap">
                                                <label class="form-label">Country Of Final Destination</label>
                                            </div>
                                        </div>
                                        <select class="form-select form-control mb-3"
                                            formControlName="countryOfFinalDestination">
                                            <option value="" disabled selected>Select Country</option>
                                            <option [value]="count.name" *ngFor="let count of countryCodes">
                                                {{count.name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div *ngIf="templateObject.preCarriageByRequired" class="col-lg-3">
                                        <div class="row">
                                            <div class="col-md-10" style="white-space:nowrap">
                                                <label class="form-label">Pre-Carriage By</label>
                                            </div>
                                        </div>
                                        <select class="form-select form-control mb-3" formControlName="preCarriageBy">
                                            <option *ngFor="let list of modeOfShipment" [value]="list.value">
                                                {{list.value}}
                                            </option>
                                        </select>
                                    </div>
                                    <div *ngIf="templateObject.placeOfReceiptByPreCarrierRequired" class="col-lg-3"
                                        style="white-space:nowrap">
                                        <label class="form-label">Place Of Receipt By Pre-Carries</label>
                                        <input type="text" class="form-control"
                                            formControlName="placeOfReceiptByPreCarrier" placeholder="Enter Place">
                                    </div>
                                    <div *ngIf="templateObject.vesselVoyageFlightDetailsRequired" class="col-lg-3">
                                        <div class="row">
                                            <div class="col-md-10" style="white-space:nowrap">
                                                <label class="form-label">Vessel/Voyage/Flight Details </label>
                                            </div>
                                        </div>
                                        <input class="form-control mb-3" type="text" formControlName="vesselVoyageFlightDetails">
                                    </div>

                                    <div *ngIf="templateObject.modeOfTransportRequired" class="col-lg-3">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <label class="form-label">Mode Of Transport</label>
                                            </div>
                                            <div class="col-md-2">
                                            </div>
                                        </div>
                                        <select class="form-select form-control" formControlName="modeOfTransport">
                                            <option *ngFor="let mode of modeOfShipment" [value]="mode.value">
                                                {{ mode.value }}
                                            </option>
                                        </select>
                                    </div>

                                    <div *ngIf="templateObject.portOfLoadingRequired" class="col-lg-3">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <label class="form-label">Port Of Loading</label>
                                            </div>
                                            <div class="col-md-2">
                                            </div>
                                        </div>
                                        <mat-select #singleSelect class="form-control" formControlName="portOfLoading">
                                            <mat-option>
                                                <ngx-mat-select-search [(ngModel)]="searchOriginValue"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (input)="getSearchResultsOrigin()" placeholderLabel="Enter Name"
                                                    noEntriesFoundLabel='No result available'>
                                                </ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let item of loadingPorts" [value]="item?.code?.code">
                                                {{ item?.value }}
                                            </mat-option>
                                        </mat-select>
                                    </div>

                                    <div *ngIf="templateObject.portOfDischargeRequired" class="col-lg-3">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <label class="form-label">Port Of Discharge</label>
                                            </div>
                                            <div class="col-md-2">
                                            </div>
                                        </div>
                                        <mat-select #singleSelect class="form-control"
                                            formControlName="portOfDischarge">
                                            <mat-option>
                                                <ngx-mat-select-search [(ngModel)]="searchDestinationValue"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (input)="getSearchResultDestination()" placeholderLabel="Enter Name"
                                                    noEntriesFoundLabel='No result available'></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let item of destinationPorts"
                                                [value]="item?.code?.code">
                                                {{ item?.value }}
                                            </mat-option>
                                        </mat-select>
                                    </div>

                                    <div *ngIf="templateObject.placeOfDeliveryRequired" class="col-lg-3">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <label class="form-label">Place Of Delivery</label>
                                            </div>
                                            <div class="col-md-2">
                                            </div>
                                        </div>
                                        <input class="form-control mb-3" type="text" formControlName="placeOfDelivery">
                                    </div>

                                    <div class="col-lg-3">
                                        <label for="" class="form-label">Currency</label>
                                        <select name="" id="" class="form-control form-select"
                                            formControlName="currency">
                                            <option *ngFor="let item of currency" [value]="item.currency">
                                                {{ item.currency }}
                                            </option>
                                        </select>
                                    </div>

                                    <div *ngIf="templateObject.orderValueRequired" class="col-lg-3">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <label class="form-label">Order Value</label>
                                            </div>
                                            <div class="col-md-2">
                                            </div>
                                        </div>
                                        <input type="number" class="form-control" formControlName="orderValue">
                                    </div>

                                    <div formGroupName="serviceOrder" class="col-lg-3">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <label class="form-label">Service Order</label>
                                            </div>
                                            <div class="col-md-2">
                                            </div>
                                        </div>
                                        <select class="form-control form-select" formControlName="id">
                                            <option *ngFor="let service of serviceOrderList" [value]="service.id">
                                                {{ service.number }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- invoiceLine Table -->
                    <div class="col-lg-12 mt-3">
                        <div class="card border-0 rounded-0  pt-4 shadow rounded-xxl ">
                            <div class="card-body">

                                <div class="col-4">
                                    <h4>{{packageDetailLabel}}</h4>
                                </div>

                                <div class="row">
                                    <div *ngIf="templateObject.containerNoRequired" class="col-3">
                                        <div class="row">
                                            <div class="col-12">
                                                <label for=""
                                                    class="form-label">{{templateObject.containerNoLabel}}</label>
                                            </div>
                                        </div>
                                        <input type="text" class="form-control mt-2" placeholder="Text Input"
                                            formControlName="containerNo">
                                    </div>
                                    <div *ngIf="templateObject.kindOfPackagesRequired" class="col-3">
                                        <div class="row">
                                            <div class="col-12">
                                                <label for=""
                                                    class="form-label">{{templateObject.kindOfPackagesLabel}}</label>
                                            </div>
                                        </div>
                                        <input type="text" class="form-control mt-2" placeholder="Text Input"
                                            formControlName="kindOfPackages">
                                    </div>
                                    <div *ngIf="templateObject.descriptionOfSuppliesRequired" class="col-6">
                                        <div class="row">
                                            <div class="col-12">
                                                <label for=""
                                                    class="form-label">{{templateObject.descriptionOfSuppliesLabel}}</label>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <textarea class="form-control mt-2" cols="50" rows="2"
                                                placeholder="Text Input"
                                                formControlName="descriptionOfSupplies"></textarea>
                                        </div>
                                    </div>
                                </div>

                                <div class="table-responsive">
                                    <div *ngIf="templateObject.packageNumberRequired" class="row">
                                        <h3>{{ templateObject.packageDetailLabel }}</h3>
                                    </div>
                                    <table
                                        class="table table-borderless text-center table-head-dark table-head-condensed table-head-py-3 table-striped mt-3">
                                        <thead>
                                            <tr>
                                                <th>Package No</th>

                                                <th>Description Of Goods</th>

                                                <th>Tag Number</th>

                                                <th>HS Code</th>

                                                <th>No Of Packages</th>

                                                <th>UOM</th>

                                                <th>Amount In {{currencyValue()}}</th>

                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody formArrayName="invoiceLine">
                                            <tr *ngFor="let item of invoiceLine.controls; let i=index; let l=last"
                                                [formGroupName]="i">
                                                <td>
                                                    <input class="form-control inputLine" type="text"
                                                        formControlName="packageNumber" placeholder="Enter Text"
                                                        (change)="populatePackageNumber(i, 'packageNumber')" />
                                                </td>
                                                <td>
                                                    <input class="form-control inputLine"
                                                        formControlName="descriptionOfGoods" type="text"
                                                        placeholder="Text Input"
                                                        (change)="populatePackageNumber(i, 'descriptionOfGoods')">
                                                </td>
                                                <td>
                                                    <input class="form-control inputLine" type="text"
                                                        formControlName="tagNumber" placeholder="Text Input" 
                                                        (change)="populatePackageNumber(i, 'tagNumber')"/>
                                                </td>
                                                <td>
                                                    <input class="form-control inputLine" type="text"
                                                        formControlName="hsCode" placeholder="Text Input" 
                                                        (change)="populatePackageNumber(i, 'hsCode')"/>
                                                </td>
                                                <td>
                                                    <input type="number" class="form-control inputLine"
                                                        placeholder="Text Input" formControlName="noOfPackages"
                                                        (change)="populatePackageNumber(i, 'noOfPackages')">
                                                </td>
                                                <td>
                                                    <select class="form-control form-select inputLine" name="uom"
                                                        id="uom" formControlName="uom">
                                                        <option *ngFor="let u of uom" [value]="u.name" id="uom">
                                                            {{ u.name }}
                                                        </option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <input class="form-control inputLine" type="number"
                                                        (change)="calculateTotalAmount()" formControlName="amount"
                                                        placeholder="Amount" min="0" />
                                                </td>

                                                <td>
                                                    <button *ngIf="invoiceLine.length > 1"
                                                        class="btn-outline-danger fas fa-trash btn-sm m-2"
                                                        (click)="removeInvoiceLine(i)"></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot class="table-success">
                                            <tr>
                                                <th>Total Amount</th>
                                                <td>{{ invoice.totalAmount.value }}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                    <div class="col-lg-12 mt-3">
                                        <button class="btn-add-new text-dark btn-outline-success pull-right"
                                            (click)="addInvoiceLine()">+ Row</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Packing List -->
                    <div formGroupName="packingList" class="col-lg-12 mt-3">
                        <div class="card border-0 rounded-0  pt-4 shadow rounded-xxl ">
                            <div class="card-body">
                                <div class="table-responsive">
                                    <div class="row">
                                        <h3 for="">Packing List</h3>
                                    </div>
                                    <table
                                        class="table table-borderless text-center table-head-dark table-head-condensed table-head-py-3 table-striped mt-3">
                                        <thead>
                                            <tr>
                                                <th>Package Number</th>
                                                <th>Description</th>
                                                <th>Tag Number</th>
                                                <th>HS Code</th>
                                                <th>Quantity Packages</th>
                                                <th>Net Weight</th>
                                                <th>Gross Weight</th>
                                            </tr>
                                        </thead>
                                        <tbody formArrayName="packingListRows">
                                            <tr *ngFor="let item of packingListRows.controls; let i = index"
                                                [formGroupName]="i">
                                                <td>
                                                    <input class="form-control inputLine" type="text"
                                                        formControlName="packageNumber" disabled>
                                                </td>
                                                <td>
                                                    <input class="form-control inputLine" type="text"
                                                        formControlName="description" disabled>
                                                </td>
                                                <td>
                                                    <input class="form-control inputLine" type="text"
                                                        formControlName="tagNumber" disabled>
                                                </td>
                                                <td>
                                                    <input class="form-control inputLine" type="text"
                                                        formControlName="hsCode" disabled>
                                                </td>
                                                <td>
                                                    <input class="form-control inputLine" type="number"
                                                        formControlName="quantityPackages" disabled>
                                                </td>
                                                <td>
                                                    <input class="form-control inputLine" type="number"
                                                        formControlName="netWeight"
                                                        (change)="calculatePackingListNetWeight(i)">
                                                </td>
                                                <td>
                                                    <input class="form-control inputLine" type="number"
                                                        formControlName="grossWeight"
                                                        (change)="calculatePackingListGrossWeight(i)">
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot></tfoot>
                                    </table>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-lg-6">
                                        <label class="form-label" for="">Unit of Weight</label>
                                        <select class="form-control form-select" name="" id=""
                                            formControlName="unitOfWeight" (change)="changeUnitOfWeight()">
                                            <option *ngFor="let u of unitOfWeight" [value]="u.name">
                                                {{ u.name }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-lg-6">
                                        <label class="form-label" for="">Gross Weight Total</label>
                                        <input class="form-control" type="number" formControlName="grossWeightTotal">
                                    </div>
                                    <div class="col-lg-6">
                                        <label class="form-label" for="">Net Weight Total</label>
                                        <input class="form-control" type="number" formControlName="netWeightTotal">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Annexure Packing List -->
                    <div formGroupName="annexurePackingList" class="col-lg-12 mt-3">
                        <div class="card border-0 rounded-0  pt-4 shadow rounded-xxl ">
                            <div class="card-body">
                                <div class="table-responsive">
                                    <div class="row">
                                        <h3 for="">Annexure Packing List</h3>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-4">
                                            <label class="form-label" for="">Dimensions Of Package</label>
                                            <select class="form-control form-select" formControlName="dimensionsOfPackage"
                                            (change)="calculateTotalCBM()">
                                                <option *ngFor="let dim of dimensions" [value]="dim.name">
                                                    {{ dim.name }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-lg-4">
                                            <label class="form-label" for="">CBM Total</label>
                                            <input class="form-control" type="number" formControlName="cbmTotal" disabled>
                                        </div>
                                        <div class="col-lg-4">
                                            <label class="form-label" for="">Unit Of Weight</label>
                                            <input class="form-control" type="text" formControlName="unitOfWeight" disabled>
                                        </div>
                                        <div class="col-lg-4">
                                            <label class="form-label" for="">Quantity Total</label>
                                            <input class="form-control" type="number" formControlName="quantityTotal" disabled>
                                        </div>
                                        <div class="col-lg-4">
                                            <label class="form-label" for="">Net Weight Total</label>
                                            <input class="form-control" type="number" formControlName="netWeightTotal" disabled>
                                        </div>
                                        <div class="col-lg-4">
                                            <label class="form-label" for="">Gross Weight Total</label>
                                            <input class="form-control" type="number" formControlName="grossWeightTotal" disabled>
                                        </div>
                                    </div>
                                    <table
                                        class="table table-borderless text-center table-head-dark table-head-condensed table-head-py-3 table-striped mt-3">
                                        <tbody formArrayName="annexurePakingListRows">
                                            <tr *ngFor="let item of annexurePackingListRow.controls; let i = index"
                                                [formGroupName]="i">
                                                <div class="row">
                                                    <div class="col-md-7 mt-3">
                                                        <div class="row mt-3">
                                                            <div class="col-md-3">
                                                                <label class="pull-left">Package Number</label>
                                                                <input type="text" class="form-control"
                                                                    formControlName="packageNumber" disabled>
                                                            </div>
                                                            <div class="col-md-2">
                                                                <label class="pull-left">Length</label>
                                                                <input type="number" class="form-control"
                                                                    formControlName="length" (change)="calculateTotalCBM()">
                                                            </div>
                                                            <div class="col-md-2">
                                                                <label class="pull-left">Width</label>
                                                                <input type="number" class="form-control"
                                                                    formControlName="width" (change)="calculateTotalCBM()">
                                                            </div>
                                                            <div class="col-md-2">
                                                                <label class="pull-left">Height</label>
                                                                <input type="number" class="form-control"
                                                                    formControlName="height" (change)="calculateTotalCBM()">
                                                            </div>
                                                            <div class="col-md-2">
                                                                <label class="pull-left">CBM</label>
                                                                <input type="text" class="form-control"
                                                                    formControlName="cbm" disabled>
                                                            </div>
                                                            <div class="col-md-3 mt-1">
                                                                <label class="pull-left">Type Of Packing</label>
                                                                <select class="form-control form-select" formControlName="typeOfPacking">
                                                                    <option *ngFor="let type of typeOfPacking" [value]="type.value">
                                                                        {{ type.value }}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                            <div class="col-md-3 mt-1">
                                                                <label class="pull-left">Net Weight</label>
                                                                <input type="text" class="form-control"
                                                                    formControlName="netWeight" disabled>
                                                            </div>
                                                            <div class="col-md-3 mt-1">
                                                                <label class="pull-left">Gross Weight</label>
                                                                <input type="text" class="form-control"
                                                                    formControlName="grossWeight" disabled>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4" formArrayName="containtsInPackages">
                                                        <div class="col-md-12 pull-right">
                                                            <button class="text-success btn-outline-success pull-right"
                                                                (click)="addContaintsInPackage(i)"> <i
                                                                    class="fa fa-plus"></i> Contains</button>
                                                        </div>
                                                        <div class="row mt-1"
                                                            *ngFor="let item of containtsInPackages(i).controls; let ind = index"
                                                            [formGroupName]="ind">
                                                            <div class="col-md-12 mt-1">
                                                                <label class="pull-left">Description</label>
                                                                <textarea rows="1" class="form-control"
                                                                    formControlName="description"></textarea>
                                                            </div>
                                                            <div class="col-md-4 mt-1">
                                                                <label class="pull-left">TagNo</label>
                                                                <input type="text" class="form-control"
                                                                    formControlName="tagNo">
                                                            </div>
                                                            <div class="col-md-3 mt-1">
                                                                <label class="pull-left">Quantity</label>
                                                                <input type="number" class="form-control"
                                                                    formControlName="quantity">
                                                            </div>
                                                            <div class="col-md-4 mt-1">
                                                                <label class="pull-left">UOM</label>
                                                                <select class="form-control form-select"
                                                                    name="uom" id="uom" formControlName="uom">
                                                                    <option *ngFor="let u of containsInPackagesUOM" [value]="u.name"
                                                                        id="uom">
                                                                        {{ u.name }}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                            <div class="col-md-1 mt-4">
                                                                <button *ngIf="containtsInPackages(i).length > 1" class="btn-sm btn-outline-danger mt-1"
                                                                    (click)="removeContainsInPackage(i, ind)"> <i
                                                                        class="fa fa-times"></i> </button>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <hr class="">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- shippingMarks -->
                    <div class="col-lg-12 mt-3">
                        <div class="card border-0 rounded-0  pt-4 shadow rounded-xxl ">
                            <div *ngIf="templateObject.shippingMarksRequired" class="card-body">
                                <div class="row">
                                    <div class="col-4">
                                        <div class="row">
                                            <div class="col-lg-9">
                                                <label class="form-label" for="">{{ templateObject.shippingMarksLabel
                                                    }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3" formGroupName="shippingMarks">
                                    <div *ngIf="templateObject.shippingMarks.caseNumberRequired" class="col-lg-4">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <label class="form-label">Case Number</label>
                                            </div>
                                        </div>
                                        <input type="text" class="form-control" formControlName="caseNumber"
                                            placeholder="Enter Case No">
                                    </div>
                                    <div *ngIf="templateObject.shippingMarks.grossWeightRequired" class="col-lg-4">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <label class="form-label">Gross Weight</label>
                                            </div>
                                        </div>
                                        <input class="form-control" type="number" formControlName="grossWeight"
                                            placeholder="Text Input" min="0" />
                                    </div>
                                    <div *ngIf="templateObject.shippingMarks.netWeightRequired" class="col-lg-4">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <label class="form-label">Net Weight</label>
                                            </div>
                                        </div>
                                        <input class="form-control" type="number" formControlName="netWeight"
                                            placeholder="Text Input" min="0" />
                                    </div>
                                    <div *ngIf="templateObject.shippingMarks.shippingFromRequired" class="col-lg-6">
                                        <div class="row mt-2">
                                            <div class="col-md-10">
                                                <label class="form-label">From</label>
                                            </div>
                                        </div>
                                        <textarea class="form-control mb-3" name="" id="" cols="30" rows="5"
                                            placeholder="Enter Address" formControlName="shippingFrom"></textarea>
                                    </div>
                                    <div *ngIf="templateObject.shippingMarks.shippingToRequired" class="col-lg-6">
                                        <div class="row mt-2">
                                            <div class="col-md-10">
                                                <label class="form-label">To</label>
                                            </div>
                                        </div>
                                        <textarea class="form-control mb-3" name="" id="" cols="30" rows="5"
                                            placeholder="Enter Address" formControlName="shippingTo">
                                        </textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- additionalInfoFields -->
                    <div class="col-lg-12 mt-3">
                        <div class="card border-0 rounded-0  pt-4 shadow rounded-xxl ">
                            <div class="card-body">
                                <div *ngIf="templateObject.additionalInfoRequired" class="row">
                                    <div class="col-4">
                                        <div class="row">
                                            <div class="col-lg-9">
                                                <label class="form-label" for="">{{ templateObject.additionalInfoLabel
                                                    }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6"></div>
                                    <div class="col-2">
                                        <button class="pull-right mr-1 fas fa-plus btn-outline-primary"
                                            (click)="addNewField()">&nbsp; Field</button>
                                    </div>
                                </div>

                                <div formArrayName="additionalInformation" class="row">
                                    <div class="col-lg-3" *ngFor="let item of additionalInformation.controls; let i=index"
                                        [formGroupName]="i">
                                        <div class="row">
                                            <div class="col-10">
                                                <input type="text" class="form-control inputLine"
                                                    formControlName="label">
                                            </div>
                                            <div class="col-2">
                                                <button class="mt-2 pull-right btn-sm fas fa-trash btn-outline-danger"
                                                    (click)="removeAdditionalField(i)"></button>
                                            </div>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Enter Value"
                                            formControlName="value">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Terms & conditions -->
                    <div *ngIf="templateObject.termsRequired" class="col-lg-6 mt-3">
                        <div class="card border-0 rounded-0 h-100  pt-4 shadow rounded-xxl">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label class="form-label" for="">{{ templateObject.termsLabel }}</label>
                                    </div>
                                    <div class="col-md-6">
                                        <button class="pull-right mr-1 fas fa-plus btn-outline-primary"
                                            (click)="addNewTerms()">&nbsp; Term</button>
                                    </div>
                                </div>
                                <div class="row" formArrayName="terms">
                                    <div class="col-md-12" *ngFor="let item of terms.controls; let i = index"
                                        [formGroupName]="i">
                                        <div class="row mt-1">
                                            <div class="col-md-10">
                                                <input type="text" class="form-control inputLine"
                                                    formControlName="label">
                                                <input type="text" class="form-control" formControlName="content"
                                                    placeholder="Enter Terms">
                                            </div>
                                            <div class="col-md-1">
                                                <button class="mt-2 fas fa-trash btn-sm btn-outline-danger"
                                                    (click)="removeTerms(i)" *ngIf="terms.length > 1"></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- declaration -->
                    <div *ngIf="templateObject.declarationRequired" class="col-lg-6 mt-3">
                        <div class="card border-0 rounded-0  pt-4 shadow rounded-xxl h-100">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label class="form-label" for="">{{ templateObject.declarationLabel }}</label>
                                    </div>
                                </div>
                                <div class="col-lg-12 mt-1">
                                    <textarea class="form-control" rows="8" cols="50" formControlName="declaration"
                                        placeholder="Text Input"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- notes -->
                    <div *ngIf="templateObject.notesRequired" class="col-lg-6 mt-3">
                        <div class="card border-0 rounded-0 h-100 pt-4 shadow rounded-xxl ">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label class="form-label" for="">{{ templateObject.notesLabel }}</label>
                                    </div>
                                </div>
                                <div class="col-lg-12 mt-1">
                                    <textarea class="form-control" rows="4" cols="50" placeholder="Text Input"
                                        formControlName="notes"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- bank details -->
                    <div class="col-lg-6 mt-3">
                        <div class="card border-0 rounded-0 h-100 pt-4 shadow rounded-xxl ">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label class="form-label" for="">{{ templateObject.bankDetailLabel }}</label>
                                    </div>
                                    <div class="col-lg-8">
                                        <button class="pull-right fas fa-plus btn-outline-primary"
                                            (click)="addNewBankField()">&nbsp; Field
                                        </button>
                                    </div>
                                </div>
                                <div formArrayName="bankDetail" class="row mt-2">
                                    <div class="col-lg-4" *ngFor="let item of bankDetail.controls; let i=index"
                                        [formGroupName]="i">
                                        <div class="row mt-1">
                                            <div class="col-md-10">
                                                <input type="text" class="form-control inputLine"
                                                    formControlName="label" placeholder="Enter Label">
                                            </div>
                                            <div class="col-md-2">
                                                <button class="mt-2 pull-right btn-sm fas fa-trash btn-outline-danger"
                                                    (click)="removeAdditionalBankFields(i)"></button>
                                            </div>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Enter Value"
                                            formControlName="value">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Buttons -->
                    <div class="row">
                        <div class="col-lg-4 mt-3">
                            <button class="btn text-dark text-small btn-outline-success"
                                style="width: 100%;">CANCEL</button>
                        </div>

                        <div class="col-lg-4 mt-3 mb-3" *ngIf="!isEdit">
                            <button class="btn text-dark text-small btn-outline-success" (click)="createPreShipment()"
                                style="width: 100%;">SAVE</button>
                        </div>

                        <div class="col-lg-4 mt-3 mb-3" *ngIf="isEdit">
                            <button class="btn text-dark text-small btn-outline-success" (click)="updatePreShipment()"
                                style="width: 100%;">UPDATE</button>
                        </div>
                    </div>

                </div>
            </form>
        </div>
    </section>
</div>