import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, _MatTableDataSource } from "@angular/material/table";
import { AuctionService } from '../helpers/auction.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { SocketService } from '../helpers/socket.service';

@Component({
  selector: 'app-auction-comparison',
  templateUrl: './auction-comparison.component.html',
  styleUrls: ['./auction-comparison.component.css']
})
export class AuctionComparisonComponent implements OnInit {

  dataSource!: MatTableDataSource<any>;
  displaycolumn: string[] = ["name", "bidValue", "rank" ,"var-amount" ,"saving" , "actions"];
  checkedList: Array<any> = [];
  auctionObj: any;
  response: any;

  constructor(private apiservice: AuctionService,private route:ActivatedRoute ,private router: Router, private socketService: SocketService) { }

  ngOnInit(): void {
    let auctionId=this.route.snapshot.paramMap.get('id')
    this.fetchVendorsbyAuction(auctionId);
    // this.socketService.connectTOServerPopUpFuction()
  }

  fetchVendorsbyAuction(id: any) {
    this.apiservice.fetchVendorBidById(id)
      .subscribe((response: Array<any>) => {
        if(response.length >0){
          this.response= response;
          this.auctionObj = response[0].auction;
          response.sort((a, b) => (a.auctionValue > b.auctionValue) ? 1 : -1);
          this.dataSource = new MatTableDataSource(response);
        }
        else{
          this.getAuctionById(id);
        }
      }, (error) => {
        console.log(error);
        Swal.fire({
          titleText: "Error",
          text: "Something went wrong",
          timer: 5000,
          icon: "error"
        })
      }
      );
  }
  check(event, id, index) {
    let value = event.srcElement.checked;
    let _id = id;
    if (value == true) {
      this.checkedList.push({"id":_id,"index":index});
    }
    else if (value == false) {
      this.checkedList.splice(this.checkedList.findIndex(obj => obj.id==_id), 1);
    }
    else {
      console.log("NULL");
    }
  }

  createServiceOrder() {
    if (this.checkedList.length != 1) {
      Swal.fire({
        titleText: "Warning",
        text: "Please check only one Checkbox",
        timer: 5000,
        icon: "warning"
      })
    }
    else {
      this.router.navigate(['v1/client-service-order/creation/',"a", this.checkedList[0].id]);
    }
  }

  getAuctionById(id){
    this.apiservice.fetchAuctionById(id).subscribe(
      (response) => {
        this.auctionObj = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }
}
