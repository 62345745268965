<!--header start-->
<header class="box-absolute d-flex align-items-center topline text-white">
    <app-sidebar></app-sidebar>
    <div class="page-title-center mt-3">
        <div class="text-center">
            <h1>Service Order Listing</h1>
        </div>
    </div>
    <div class="breadcrumb-div mt-3">
        <ul class="breadcrumb p-0 m-0">
            <li class="breadcrumb-item 3" id="0"><a class="a-space" [routerLink]="['/v1/dashboard']">Dashboard</a></li>
            <li class="breadcrumb-item 3" id="1"><a class="a-space">Vendor</a></li>
            <li class="breadcrumb-item"><a class="a-space">Service Order Listing</a></li>
        </ul>
    </div>
</header>
<!--header end-->
<section class="banner text-center text-white d-flex" id="bnner">
    <div class="container position-relative">
        <div class="row justify-content-center">
            <div class="col-lg-12">
            </div>
        </div>
    </div>
</section>

<section class="request-demo-wrapper pb-10" (click)="hideSideBar()">
    <div class="container shadow bg-white">
        <div class="row">
            <div class="col-lg-12">
                <div class="box">
                    <form>
                        <div class="filter-wrap btn-group d-flex flex-wrap flex-md-nowrap justify-content-center gap-2 pb-3 pb-lg-4 px-3 px-lg-4">
                            <div class="input-group">
                                <input class="form-control rounded-3 px-4 serchbox" type="text" placeholder=" Search"
                                    matInput (keyup)="applyFilter($event)" #input />
                                <button class="input-group-btn end-0" type="button"><i class="fa fa-search"></i>
                                </button>
                            </div>
                        </div>
                        <div class="mat-elevation-z8 table-responsive">
                            <table class="table table-borderless table-striped table-footer-dark table-head-dark"
                                matSort mat-table [dataSource]="dataSource">

                                <ng-container matColumnDef="number">
                                    <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header>Number</th>
                                    <td mat-cell *matCellDef="let row"> {{row.number}} </td>
                                </ng-container>

                                <ng-container matColumnDef="date">
                                    <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
                                    <td mat-cell *matCellDef="let row"> {{row.serviceOrderDate}} </td>
                                </ng-container>

                                <ng-container matColumnDef="forwaderName">
                                    <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header>Forwader Name</th>
                                    <td mat-cell *matCellDef="let row"> {{row.forwaderName}} </td>
                                </ng-container>

                                <ng-container matColumnDef="quoteNumber">
                                    <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header>Quote No</th>
                                    <td mat-cell *matCellDef="let row"> {{row.quotationNumber || '---' }} </td>
                                </ng-container>

                                <ng-container matColumnDef="auctionNumber">
                                    <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header>Auction No</th>
                                    <td mat-cell *matCellDef="let row"> {{row.auctionNumber || '---' }} </td>
                                </ng-container>

                                <ng-container matColumnDef="actions">
                                    <th class="text-white " mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                                    <td mat-cell *matCellDef="let row">
                                        <!-- <button class="btn-sm" (click)="vendorEdit(row.id)">
                                            <i class="fa fa-edit" title="EDIT"></i>
                                        </button> -->
                                        <button (click)="downloadServiceOrder(row.id)">
                                            <i class="fa fa-download" title="Download"></i>
                                        </button>
                                    </td>
                                </ng-container>

                                <tr class="bg-dark" mat-header-row *matHeaderRowDef="displayedColumns">
                                </tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                                <tr class="mat-row" *matNoDataRow>
                                    <!-- <td class="mat-cell text-center text-danger" colspan="4">
                                        <div *ngIf="serchFilter">
                                            No data matching the filter "{{ input.value }}"
                                        </div>
                                        <div *ngIf="nodata">
                                            <h5>Data is Not Available</h5>
                                        </div>
                                    </td> -->
                                </tr>
                            </table>
                            <mat-paginator [pageSizeOptions]="[25,50,100]" aria-label="Select page of users"
                                [length]="totalCount" (page)="onPageChange($event)">
                            </mat-paginator>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
</section>