import { Component, Inject, OnInit, Optional } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuctionService } from 'src/app/helpers/auction.service';
import { MasterTableService } from 'src/app/helpers/master-table.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-show-auction-terms-and-conditions',
  templateUrl: './show-auction-terms-and-conditions.component.html',
  styleUrls: ['./show-auction-terms-and-conditions.component.css'],
})
export class ShowAuctionTermsAndConditionsComponent implements OnInit {
  local_data: any;
  termsAndConditionsForm: FormGroup;
  showCommentField: boolean = false;
  constructor(
    private auctionService: AuctionService,
    public dialogRef: MatDialogRef<ShowAuctionTermsAndConditionsComponent>,
    public fb: FormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: MasterTableService
  ) {
    this.local_data = data;
    console.log('this is local data from terms component ', this.local_data);
  }

  ngOnInit(): void {
    this.termsAndConditionsForm = this.fb.group({
      isAgreed: true,
      comment: '',
    });
  }

  get controls() {
    return this.termsAndConditionsForm.controls;
  }

  toggleControl() {
    let booleanValue = this.controls['isAgreed'].value;
    console.log('this is boolean value ', booleanValue);
    if (booleanValue == false) {
      this.showCommentField = true;
      this.termsAndConditionsForm
        .get('comment')
        .setValidators(Validators.required);
      this.termsAndConditionsForm.get('comment').updateValueAndValidity();
    } else {
      this.showCommentField = false;
      this.termsAndConditionsForm.get('comment').clearValidators();
      this.termsAndConditionsForm.get('comment').updateValueAndValidity();
    }
  }

  submit() {
    if (this.termsAndConditionsForm.valid) {
      this.auctionService
        .acceptRejectAuctionTerms(
          this.local_data.id,
          this.termsAndConditionsForm.value
        )
        .subscribe({
          next: (res) => {
            console.log('this is res ', res);
            Swal.fire({
              title: 'Success',
              text: res.message,
              timer: 5000,
              icon: 'success',
            });
            this.dialogRef.close();
          },
          error: (error) => {
            if (error.error.errorCode == 412) {
              Swal.fire({
                title: 'Error',
                text: error.error.errorMessage,
                timer: 5000,
                icon: 'error',
              });
            } else {
              Swal.fire({
                title: 'Error',
                text: 'Something Went Wrong',
                timer: 5000,
                icon: 'error',
              });
            }
            console.log('this is error ', error);
          },
        });
    } else {
      this.termsAndConditionsForm.markAllAsTouched();
    }
  }

  downloadFileLocal(item) {
    if (item.artifactId != null && item.label != null) {
      this.dataURItoBlob(item);
    }
  }

  dataURItoBlob(data) {
    const linkSource = data.content;
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = data.name;
    downloadLink.click();
  }
}
