import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MasterTableService {

  constructor(private http: HttpClient) {}
  AUTH_API: string = `https://${window.location.hostname}:9004/api/v1/masterData`

  // Type of cargo
  getAllTypeOfCargo(): Observable<any> {
    return this.http.get(`${this.AUTH_API}/typesOfCargo`);
  }
  getTypeOfCargoPageable(page, size): Observable<any> {
    let param = new HttpParams().set('page', page).set('size', size);
    return this.http.get(`${this.AUTH_API}/typeOfCargoPageable`, {
      params: param,
    });
  }
  addTypeOfCargo(data: any): Observable<any> {
    return this.http.post(`${this.AUTH_API}/typeOfCargo`, data);
  }
  updateTypeOfCargo(id: any, data: any): Observable<any> {
    return this.http.put(`${this.AUTH_API}/typeOfCargo/${id}`, data);
  }
  deleteTypeOfCargo(id: any): Observable<any> {
    return this.http.delete(`${this.AUTH_API}/typeOfCargo/${id}`);
  }

  // Type Of Packing
  getAllTypeOfPacking(): Observable<any> {
    return this.http.get(`${this.AUTH_API}/typeOfPacking`);
  }
  getTypeOfPackingPageable(page, size): Observable<any> {
    let param = new HttpParams().set('page', page).set('size', size);
    return this.http.get(`${this.AUTH_API}/typeOfPackingPageable`, {
      params: param,
    });
  }
  addTypeOfPacking(data: any): Observable<any> {
    return this.http.post(`${this.AUTH_API}/typeOfPacking`, data);
  }
  updateTypeOfPacking(id: any, data: any): Observable<any> {
    return this.http.put(`${this.AUTH_API}/typeOfPacking/${id}`, data);
  }
  deleteTypeOfPacking(id: any): Observable<any> {
    return this.http.delete(`${this.AUTH_API}/typeOfPacking/${id}`);
  }

  // Trade Type
  public getTradeType(): Observable<any> {
    return this.http.get(`${this.AUTH_API}/tradeTypes`);
  }
  public getTradeTypesPageable(page, size): Observable<any> {
    let param = new HttpParams().set('page', page).set('size', size);
    return this.http.get(`${this.AUTH_API}/tradeTypesPageable`, {
      params: param,
    });
  }
  public createTradeType(data: any): Observable<any> {
    return this.http.post(`${this.AUTH_API}/tradeTypes`, data);
  }
  public updateTradeType(id: number, data: any): Observable<any> {
    return this.http.put(`${this.AUTH_API}/tradeType/${id}`, data);
  }
  public deleteTradeType(id: any): Observable<any> {
    return this.http.delete(`${this.AUTH_API}//tradeType/${id}`);
  }

  // Container Type
  public getContainerTypes(): Observable<any> {
    return this.http.get(`${this.AUTH_API}/containerTypes`);
  }
  public getcontainerTypesPageable(page, size): Observable<any> {
    let param = new HttpParams().set('page', page).set('size', size);
    return this.http.get(`${this.AUTH_API}/containerTypesPageable`, {
      params: param,
    });
  }
  public createContainerType(data: any): Observable<any> {
    return this.http.post(`${this.AUTH_API}/containerType`, data);
  }
  public updateContainerType(id: any, data: any): Observable<any> {
    return this.http.put(`${this.AUTH_API}/containerType/${id}`, data);
  }
  public deleteContainerType(id: any): Observable<any> {
    return this.http.delete(`${this.AUTH_API}/containerType/${id}`);
  }

  //NatureOfMovement
  public getNatureOfMovement(): Observable<any> {
    return this.http.get(`${this.AUTH_API}/natureOfMovement`);
  }
  public getNatureOfMovementPageable(page, size): Observable<any> {
    let param = new HttpParams().set('page', page).set('size', size);
    return this.http.get(`${this.AUTH_API}/natureOfMovementPageable`, {
      params: param,
    });
  }
  public AddNatureOfMOvement(data: any): Observable<any> {
    return this.http.post(`${this.AUTH_API}/natureOfMovement`, data);
  }
  public updateNatureOfMovement(code: any, data: any): Observable<any> {
    return this.http.put(`${this.AUTH_API}/natureOfMovement/${code}`, data);
  }
  public deleteNatureOfMovement(code: any): Observable<any> {
    return this.http.delete(`${this.AUTH_API}/natureOfMovement/${code}`);
  }

  //ModeOfShipment
  public getModeOfShipment(): Observable<any> {
    return this.http.get(`${this.AUTH_API}/modeOfShipments`);
  }
  public modeOfShipmentsPageable(page, size): Observable<any> {
    let param = new HttpParams().set('page', page).set('size', size);
    return this.http.get(`${this.AUTH_API}/modeOfShipmentsPageable`, {
      params: param,
    });
  }
  public AddModeOfShipment(data: any): Observable<any> {
    return this.http.post(`${this.AUTH_API}/modeOfShipments`, data);
  }
  public updateModeOfShipment(id: any, data: any): Observable<any> {
    return this.http.put(`${this.AUTH_API}/modeOfShipment/${id}`, data);
  }
  public deleteModeOfShipment(id: any): Observable<any> {
    return this.http.delete(`${this.AUTH_API}/modeOfShipment/${id}`);
  }

  //DeliveryIncoTerms
  public getDeliveryIncoTerms(): Observable<any> {
    return this.http.get(`${this.AUTH_API}/deliveryIncoterms`);
  }
  public getdeliveryIncotermsPageable(page, size): Observable<any> {
    let param = new HttpParams().set('page', page).set('size', size);
    return this.http.get(`${this.AUTH_API}/deliveryIncotermsPageable`, {
      params: param,
    });
  }
  public AddDeliveryIncoTerms(data: any): Observable<any> {
    return this.http.post(`${this.AUTH_API}/deliveryIncoterms`, data);
  }
  public updateDeliveryIncoTerms(code: any, data: any): Observable<any> {
    return this.http.put(`${this.AUTH_API}/deliveryIncoterms/${code}`, data);
  }
  public deleteDeliveryIncoTerms(code: any): Observable<any> {
    return this.http.delete(`${this.AUTH_API}/deliveryIncoterms/${code}`);
  }

  // PickUpIncoTerms
  public getAllPickUpIncoTerms(): Observable<any> {
    return this.http.get(`${this.AUTH_API}/pickupIncoterms`);
  }
  public getPickupIncotermsPageable(page, size): Observable<any> {
    let param = new HttpParams().set('page', page).set('size', size);
    return this.http.get(`${this.AUTH_API}/pickupIncotermsPageable`, {
      params: param,
    });
  }
  public addPickUpIncoTerms(data: any): Observable<any> {
    return this.http.post(`${this.AUTH_API}/pickupIncoterms`, data);
  }
  public updatePickUpIncoTerms(id: number, data: any): Observable<any> {
    return this.http.put(`${this.AUTH_API}/pickupIncoterms/${id}`, data);
  }
  public deletePickUpIncoTerms(id: number): Observable<any> {
    return this.http.delete(`${this.AUTH_API}/pickupIncoterms/${id}`);
  }

  // LoadingDestinationPort
  public getLoadingDestinationPort(): Observable<any> {
    return this.http.get(`${this.AUTH_API}/ports`);
  }
  public getPortsPageable(page, size): Observable<any> {
    let param = new HttpParams().set('page', page).set('size', size);
    return this.http.get(`${this.AUTH_API}/portsPageable`, { params: param });
  }
  public addLoadingDestinationPort(data: any): Observable<any> {
    return this.http.post(`${this.AUTH_API}/port`, data);
  }
  public updateLoadingDestinationPort(data: any): Observable<any> {
    return this.http.put(`${this.AUTH_API}/port`, data);
  }
  public deleteLoadingDestinationPort(data: any): Observable<any> {
    let url = `${this.AUTH_API}/port`;
    return this.http.request('DELETE', url, { body: data });
  }

  // NatureOfGoods
  public getNatureOfGoods(): Observable<any> {
    return this.http.get(`${this.AUTH_API}/natureOfGoods`);
  }
  public getNatureOfGoodsPageable(page, size): Observable<any> {
    let param = new HttpParams().set('page', page).set('size', size);
    return this.http.get(`${this.AUTH_API}/natureOfGoodsPageable`, {
      params: param,
    });
  }
  public addNatureOfGoods(data: any): Observable<any> {
    return this.http.post(`${this.AUTH_API}/natureOfGood`, data);
  }
  public updateNatureOfGoods(id: any, data: any): Observable<any> {
    return this.http.put(`${this.AUTH_API}/natureOfGoods/${id}`, data);
  }
  public deleteNatureOfGoods(id: number): Observable<any> {
    return this.http.delete(`${this.AUTH_API}/natureOfGoods/${id}`);
  }

  public getAllCurrency(): Observable<any> {
    return this.http.get(`${this.AUTH_API}/currencies`);
  }
  public getCurrencyPageable(page, size): Observable<any> {
    let param = new HttpParams().set('page', page).set('size', size);
    return this.http.get(`${this.AUTH_API}/currencyPageable`, {
      params: param,
    });
  }
  public saveCurrency(data): Observable<any> {
    return this.http.post(`${this.AUTH_API}/currency`, data);
  }
  public updateCurrency(id, data): Observable<any> {
    return this.http.put(`${this.AUTH_API}/currency/${id}`, data);
  }
  public deleteCurrency(id): Observable<any> {
    return this.http.delete(`${this.AUTH_API}/currency/${id}`);
  }

  // Charge Type
  getAllChargeType(): Observable<any> {
    return this.http.get(`${this.AUTH_API}/chargeTypes`);
  }
  public getChargeTypePageable(page, size): Observable<any> {
    let param = new HttpParams().set('page', page).set('size', size);
    return this.http.get(`${this.AUTH_API}/chargeTypePageable`, {
      params: param,
    });
  }
  addChargeType(data: any): Observable<any> {
    return this.http.post(`${this.AUTH_API}/chargeType`, data);
  }
  updateChargeType(id, data: any): Observable<any> {
    return this.http.put(`${this.AUTH_API}/chargeType/${id}`, data);
  }
  deleteChargeType(id): Observable<any> {
    return this.http.delete(`${this.AUTH_API}/chargeType/${id}`);
  }

  // Vehicle Type
  getAllVehicleType(): Observable<any> {
    return this.http.get(`${this.AUTH_API}/typeOfVehicle`);
  }
  getypeOfVehiclePageable(page, size): Observable<any> {
    let param = new HttpParams().set('page', page).set('size', size);
    return this.http.get(`${this.AUTH_API}/typeOfVehiclePageable`, {
      params: param,
    });
  }
  addVehicleType(data): Observable<any> {
    return this.http.post(`${this.AUTH_API}/typeOfVehicle`, data);
  }
  updateVehicleType(id, data): Observable<any> {
    return this.http.put(`${this.AUTH_API}/typeOfVehicle/${id}`, data);
  }
  deleteVehicleType(id): Observable<any> {
    return this.http.delete(`${this.AUTH_API}/typeOfVehicle/${id}`);
  }

  // Business Type
  getAllBusinessType(): Observable<any> {
    return this.http.get(`${this.AUTH_API}/businessTypes`);
  }
  getBusinessTypesPageable(page, size): Observable<any> {
    let param = new HttpParams().set('page', page).set('size', size);
    return this.http.get(`${this.AUTH_API}/businessTypesPageable`, {
      params: param,
    });
  }
  addBusinessType(data): Observable<any> {
    return this.http.post(`${this.AUTH_API}/businessType`, data);
  }
  updateBusinessType(id, data): Observable<any> {
    return this.http.put(`${this.AUTH_API}/businessType/${id}`, data);
  }
  deleteBusinessType(id): Observable<any> {
    return this.http.delete(`${this.AUTH_API}/businessType/${id}`);
  }

  // Country
  getAllCountries(): Observable<any> {
    return this.http.get(`${this.AUTH_API}/countries`);
  }
  getAllCountriesUnauthenticated(): Observable<any> {
    return this.http.get(`${this.AUTH_API}/countryList`);
  }
  getCountriesPageable(page, size): Observable<any> {
    let param = new HttpParams().set('page', page).set('size', size);
    return this.http.get(`${this.AUTH_API}/countriesPageable`, {
      params: param,
    });
  }
  addCountry(data): Observable<any> {
    return this.http.post(`${this.AUTH_API}/country`, data);
  }
  updateCountry(id, data): Observable<any> {
    return this.http.put(`${this.AUTH_API}/country/${id}`, data);
  }
  deleteCountry(id): Observable<any> {
    return this.http.delete(`${this.AUTH_API}/country/${id}`);
  }

  fetchPortsByModeOfShipmentAndCountryId(
    countryId,
    modeOfShipmentId
  ): Observable<any> {
    return this.http.get(
      `${this.AUTH_API}/ports/${countryId}/${modeOfShipmentId}`
    );
  }

  public getAllMasterTables(): Observable<any> {
    return this.http.get(`${this.AUTH_API}`);
  }

  // Carrier Master table
  getCarriersListing(): Observable<any> {
    return this.http.get(`${this.AUTH_API}/carriers`);
  }

  getCarrierPageable(page, size): Observable<any> {
    let param = new HttpParams().set('page', page).set('size', size);
    return this.http.get(`${this.AUTH_API}/carriersPageable`, {
      params: param,
    });
  }

  addCarrier(data): Observable<any> {
    return this.http.post(`${this.AUTH_API}/carrier`, data);
  }

  updateCarrier(id, data): Observable<any> {
    return this.http.put(`${this.AUTH_API}/carrier/${id}`, data);
  }

  deleteCarrier(id): Observable<any> {
    return this.http.delete(`${this.AUTH_API}/carrier/${id}`);
  }

  // Shipping Line Master table
  getShippingLinePageable(page, size): Observable<any> {
    let param = new HttpParams().set('page', page).set('size', size);
    return this.http.get(`${this.AUTH_API}/shippingLinePageable`, {
      params: param,
    });
  }

  // ToDo api call to component remaining
  getAllShippingLines(): Observable<any> {
    return this.http.get(`${this.AUTH_API}/shippingLines`);
  }

  addShippingLine(data): Observable<any> {
    return this.http.post(`${this.AUTH_API}/shippingLine`, data);
  }

  updateShippingLine(id, data): Observable<any> {
    return this.http.put(`${this.AUTH_API}/shippingLine/${id}`, data);
  }

  deleteShippingLine(id): Observable<any> {
    return this.http.delete(`${this.AUTH_API}/shippingLine/${id}`);
  }

  getSearchPort(
    modeOfShipment: any,
    searchPort: any,
    size: any
  ): Observable<any> {
    let param: any = { size: size };
    return this.http.get(
      `${this.AUTH_API}/ports/${modeOfShipment}/${searchPort}`,
      { params: param }
    );
  }

  // TradeTypeAgreement API's

  getAllTradeTypeAgreementPage(page, size): Observable<any> {
    let param = new HttpParams().set('page', page).set('size', size);
    return this.http.get(`${this.AUTH_API}/tradeAgreementPageable`, {
      params: param,
    });
  }

  deleteTradeTypeAgreement(id): Observable<any> {
    return this.http.delete(`${this.AUTH_API}/tradeAgreement/${id}`);
  }

  addTradeTypeAgreement(data: any): Observable<any> {
    return this.http.post(`${this.AUTH_API}/tradeAgreement`, data);
  }

  postArtifactTradeTypeAgreement(id: number, data: any): Observable<any> {
    return this.http.post(
      `${this.AUTH_API}/tradeAgreement/${id}/artifact`,
      data
    );
  }

  getArtifactTradeTypeAgreement(id: number): Observable<any> {
    return this.http.get(`${this.AUTH_API}/tradeAgreement/${id}/artifact`);
  }

  deleteArtifactTradeTypeAgreement(id, artifactId): Observable<any> {
    return this.http.delete(
      `${this.AUTH_API}/tradeAgreement/${id}/artifact/${artifactId}`
    );
  }

  updateTradeTypeAgreement(id, data): Observable<any> {
    return this.http.put(`${this.AUTH_API}/tradeAgreement/${id}`, data);
  }

  getAllTradeTypeAgreementPageUnauth(page, size): Observable<any> {
    let param = new HttpParams().set('page', page).set('size', size);
    return this.http.get(`${this.AUTH_API}/tradeAgreements`, { params: param });
  }

  filterTradeAgreement(
    firstCountry,
    secondCountry,
    page,
    size
  ): Observable<any> {
    let param = new HttpParams()
      .set('page', page)
      .set('size', size)
      .set('firstCountry', firstCountry)
      .set('secondCountry', secondCountry);
    return this.http.get(
      `${this.AUTH_API}/tradeAgreementsBetweenTwoCountries`,
      { params: param }
    );
  }

  // HsCodes
  postHsCode(data): Observable<any> {
    return this.http.post(`${this.AUTH_API}/hsCode`, data);
  }

  getAllHsCodesPagable(page, size): Observable<any> {
    let param = new HttpParams().set('page', page).set('size', size);
    return this.http.get(`${this.AUTH_API}/hsCodePageable`, { params: param });
  }

  deleteHsCode(id: any): Observable<any> {
    return this.http.delete(`${this.AUTH_API}/hsCode/${id}`);
  }

  updateHsCode(id, data): Observable<any> {
    return this.http.put(`${this.AUTH_API}/hsCode/${id}`, data);
  }

  postHsCodeArtifact(id, data): Observable<any> {
    return this.http.post(`${this.AUTH_API}/hsCode/${id}/artifact`, data);
  }

  deleteHsCodeArtifact(id, artifactId): Observable<any> {
    return this.http.delete(
      `${this.AUTH_API}/hsCode/${id}/artifact/${artifactId}`
    );
  }

  getHsCodeUnauthenticated(page, size): Observable<any> {
    let param = new HttpParams().set('page', page).set('size', size);
    return this.http.get(`${this.AUTH_API}/hsCodeList`, { params: param });
  }

  getHsCodeArtifacts(id): Observable<any> {
    return this.http.get(`${this.AUTH_API}/hsCode/${id}/artifacts`);
  }

  // Marketing Space API's
  addMarketingSpace(data: any): Observable<any> {
    return this.http.post(`${this.AUTH_API}/marketingSpace`, data);
  }

  updateMarketingSpace(marketingSpaceId: number, data: any): Observable<any> {
    return this.http.put(
      `${this.AUTH_API}/marketingSpace/${marketingSpaceId}`,
      data
    );
  }

  getAllMarketSpaceUnauth(): Observable<any> {
    return this.http.get(`${this.AUTH_API}/marketingSpacesList`);
  }

  getAllMarketSpacePageable(page: any, size: any): Observable<any> {
    let param = new HttpParams().set('page', page).set('size', size);
    return this.http.get(`${this.AUTH_API}/marketingSpacePageable`, {
      params: param,
    });
  }

  deleteMarketSpace(marketingSpaceId: number): Observable<any> {
    return this.http.delete(
      `${this.AUTH_API}/marketingSpace/${marketingSpaceId}`
    );
  }

  uploadMarketingSpaceArtifacts(
    marketingSpaceId: number,
    data: any
  ): Observable<any> {
    return this.http.post(
      `${this.AUTH_API}/marketingSpace/${marketingSpaceId}/artifact`,
      data
    );
  }

  getMarketingArtifacts(marketingSpaceId: number): Observable<any> {
    return this.http.get(
      `${this.AUTH_API}/marketingSpace/${marketingSpaceId}/artifacts`
    );
  }

  deleteMarketingSpaceArtifactById(
    marketingSpaceId: any,
    artifactId: any
  ): Observable<any> {
    return this.http.delete(
      `${this.AUTH_API}/marketingSpace/${marketingSpaceId}/artifact/${artifactId}`
    );
  }
}
