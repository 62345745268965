<h1 mat-dialog-title><strong>{{action}}</strong></h1>
<div mat-dialog-content *ngIf="component  == 'roles'; else permisson" >
  <mat-form-field *ngIf="action != 'Delete'; else elseTemplate">
    <input placeholder="Authority" matInput [(ngModel)]="local_data.authority">
  </mat-form-field>
  
  <ng-template #elseTemplate>
    Sure to delete <b>{{local_data.authority}}</b>?
  </ng-template>
</div>

<ng-template #permisson>
  <div mat-dialog-content>
    <mat-form-field *ngIf="action != 'Delete'; else elseTemplateP">
      <input placeholder="Authority" matInput [(ngModel)]="local_data.name">
    </mat-form-field>
    <ng-template #elseTemplateP>
      Sure to delete <b>{{local_data.name}}</b>?
    </ng-template>
  </div>
</ng-template>
<div mat-dialog-actions>
  <button mat-button (click)="doAction()">{{action}}</button>
  <button mat-button (click)="closeDialog()" mat-flat-button color="warn">Cancel</button>
</div>