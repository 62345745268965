import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { InvoiceService } from 'src/app/helpers/invoice.service';
import { MasterTableService } from 'src/app/helpers/master-table.service';
import { ServiceOrderService } from 'src/app/helpers/service-order.service';
import { SocketService } from 'src/app/helpers/socket.service';
import { AlertService } from 'src/app/utils/sweet-alert.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-client-template',
  templateUrl: './client-template.component.html',
  styleUrls: ['./client-template.component.css'],
})
export class ClientTemplateComponent implements OnInit {
  public invoiceTemplateForm!: FormGroup;
  public addressList: Array<any> = [];
  public countryCodes: Array<any> = [];
  public originDestinationPorts = [];
  public modeOfShipment = [];
  // public totalAmount: number = 0;
  public mapInfo: any;
  public currency = [];
  templateId: any;
  isEdit: Boolean = false;

  additionalInfoFields: Array<any> = [
    {label: 'Project Name', value: ''},
    {label: 'Project Number', value: ''},
    {label: 'Bill Of Lading No', value: ''},
    {label: 'Shipping Bill No', value: ''}
  ];
  additionalBankFields: Array<any> = [
    {label: 'Agent', value: ''},
    {label: 'Commission', value: ''},
    {label: 'Bank', value: ''}
  ];
  data: any;
  samePortError: boolean = false;
  serviceOrderList: Array<any> = [];
  serviceOrderBool: boolean = true;
  loadingPorts: Array<any> = [];
  destinationPorts: Array<any> = [];
  searchOriginValue: any;
  searchDestinationValue: any;
  partyLabel: Array<any> = ['Sez Unit At', 'Exporter', 'Applicant', 'Notify'];
  // uom: Array<any> = [{ name: 'MT' }, { name: 'KG' }, { name: 'LBS' }];
  uom: Array<any> = [{ name: "Nos" },{ name: "Lot" }];
  partyTypes: Array<string> = [
    'EXPORTER',
    'APPLICANT',
    'SEZ_UNIT_AT',
    'NOTIFY',
  ];

  constructor(
    private fb: FormBuilder,
    private _invoice: InvoiceService,
    private router: Router,
    private masterTableService: MasterTableService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private socketService: SocketService,
    private serviceOrder: ServiceOrderService
  ) {}

  ngOnInit(): void {
    this.invoiceTemplateForm = this.fb.group({
      exporterRef: '',
      exporterRefRequired: [true],
      exporterRefLabel: 'Exporter Reference',
      additionalInfoLabel: 'Additional Information',
      additionalInfoRequired: [true],
      buyersRef: '',
      buyersRefRequired: [true],

      shippingMarks: this.fb.group({
        caseNumber: null,
        caseNumberRequired: [true],
        grossWeight: '',
        grossWeightRequired: [true],
        netWeight: '',
        netWeightRequired: [true],
        shippingFrom: '',
        shippingFromRequired: [true],
        shippingTo: '',
        shippingToRequired: [true],
      }),

      countryOfFinalDestination: '',
      countryOfFinalDestinationRequired: [true],
      countryOfOriginGoods: '',
      countryOfOriginOfGoodsRequired: [true],
      declaration: '',
      declarationRequired: [true],
      notes: '',
      notesRequired: [true],
      orderValue: '',
      orderValueRequired: [true],
      otherRefRequired: [true],
      packageDetailLabel: 'Package Details',
      placeOfDelivery: '',
      placeOfDeliveryRequired: [true],
      placeOfReceiptByPreCarrier: '',
      placeOfReceiptByPreCarrierRequired: [true],
      portOfDischarge: '',
      portOfDischargeRequired: [true],
      portOfLoading: '',
      portOfLoadingRequired: [true],
      preCarriageBy: '',
      preCarriageByRequired: [true],
      packageNumberRequired: [true],
      shippingMarksLabel: 'Shipping Marks',
      shippingMarksRequired: [true],
      totalAmount: [0],
      totalAmountRequired: [true],
      vesselVoyageFlightDetails: '',
      vesselVoyageFlightDetailsRequired: [true],
      descriptionOfGoodsRequired: [true],
      tagNumberRequired: [true],
      hsCodeRequired: [true],
      uomRequired: [true],
      amountRequired: [true],

      containerNo: '',
      containerNoLabel: 'Container No',
      containerNoRequired: [true],

      kindOfPackages: '',
      kindOfPackagesLabel: 'Kind Of Packages',
      kindOfPackagesRequired: [true],

      descriptionOfSupplies: '',
      descriptionOfSuppliesLabel: 'Description Of Supplies',
      descriptionOfSuppliesRequired: [true],

      otherRef: this.fb.array([
        this.fb.group({
          otherReference: '',
        }),
      ]),

      //table rows
      invoiceRows: this.fb.array([
        this.fb.group({
          descriptionOfGoods: new FormControl(null),
          tagNumber: new FormControl(null),
          hsCode: new FormControl(null),
          packageNumber: new FormControl(null),
          uom: new FormControl(null),
          amount: new FormControl(0),
          noOfPackages: new FormControl(0),
          quantity: new FormControl(0)
        }),
      ]),

      //additional information fields
      additionalInformation: this.fb.array([]),

      //additional bank fields
      bankDetail: this.fb.array([]),

      //new UI changes
      parties: this.fb.array([this.defaultOneParty()]),
      currency: '',
      termsRequired: [true],
      terms: this.fb.array([this.defaultOneTerms()]),

      termsLabel: 'Terms',
      declarationLabel: 'Declaration',
      notesLabel: 'Note',
      bankDetailLabel: 'Bank Detail',
      serviceOrder: this.fb.group({
        id: [],
      }),
      name: [null],
      modeOfTransport: [null],
      modeOfTransportRequired: [true]
    });
    this.getUserAddresses();
    this.getMasterTablesSessionStorageData();

    //route params
    this.route.paramMap.subscribe((params) => {
      this.templateId = params.get('id');
      if (this.templateId != null) {
        this.getTemplateById();
      } else {
        for (let index = 0; index < this.additionalInfoFields.length; index++) {
          const element = this.additionalInfoFields[index];
          this.additionalInformation.push(this.prePopulateAdditionalInfo(element));
        }
        for (let index = 0; index < this.additionalBankFields.length; index++) {
          const element = this.additionalBankFields[index];
          this.bankDetail.push(this.prePopulateBankInfo(element));
        }
      }
    });

    // this.socketService.connectTOServerPopUpFuction();
  }

  get detail() {
    return this.invoiceTemplateForm.controls;
  }

  get otherRef() {
    return this.invoiceTemplateForm.get('otherRef') as FormArray;
  }
  get additionalInformation() {
    return this.invoiceTemplateForm.get('additionalInformation') as FormArray;
  }
  get bankDetail() {
    return this.invoiceTemplateForm.get('bankDetail') as FormArray;
  }
  get invoiceRows() {
    return this.invoiceTemplateForm.get('invoiceRows') as FormArray;
  }
  get f() {
    return this.invoiceTemplateForm.controls;
  }

  get parties() {
    return this.invoiceTemplateForm.get('parties') as FormArray;
  }

  get partyControl() {
    return this.parties.controls;
  }

  get contactNo() {
    return this.parties.get('contactNumbers') as FormArray;
  }

  get partyAddress() {
    return this.parties.get('address') as FormGroup;
  }

  get shippingMarks() {
    return this.invoiceTemplateForm.get('shippingMarks') as FormGroup;
  }

  get shippingMarksControls() {
    return this.shippingMarks.controls;
  }

  get serviceOrderForm() {
    return this.detail.serviceOrder as FormGroup;
  }

  //to add new party details
  addNewParty() {
    let group = this.fb.group({
      label: '',
      name: '',
      email: '',
      contactNumbers: this.fb.array([this.defaultOneContactNo()]),
      partyType: '',
      address: this.fb.group({
        city: [''],
        country: this.fb.group({
          id: [''],
        }),
        label: [''],
        state: [''],
        street1: [''],
        street2: [''],
        zipCode: [''],
      }),
    });
    this.parties.push(group);
  }

  //to remove any party details
  removeParty(i: number) {
    this.parties.removeAt(i);
  }

  defaultOneContactNo() {
    return this.fb.group({
      contacts: [''],
    });
  }

  //to show default one party
  defaultOneParty() {
    return this.fb.group({
      label: '',
      name: '',
      email: '',
      contactNumbers: this.fb.array([this.defaultOneContactNo()]),
      partyType: 'EXPORTER',
      address: this.fb.group({
        street1: [''],
        street2: [''],
        city: [''],
        state: [''],
        // country: [''],
        country: this.fb.group({
          id: [''],
        }),
        zipCode: [''],
        label: [''],
      }),
    });
  }

  // fuction to get contact number as formarray
  partyContactNo(index: number): FormArray {
    return this.parties.at(index).get('contactNumbers') as FormArray;
  }

  //to add new contact no
  addNewContactNo(partyInd: number) {
    this.partyContactNo(partyInd).push(this.defaultOneContactNo());
  }

  // to remove contact number
  removeContactNo(index: number, ind: number) {
    this.partyContactNo(index).removeAt(ind);
  }

  // to patch selected address to address section
  selectAddress(event: any, i: number) {
    let selectedAddressId = event.target.value;
    console.log(selectedAddressId);
    this.addressList.filter((address) => {
      if (address.id == selectedAddressId) {
        let partyArray = this.invoiceTemplateForm.get('parties') as FormArray;
        let addressGroup = partyArray.at(i).get('address') as FormGroup;
        addressGroup.get('street1').patchValue(address.street1);
        addressGroup.get('street2').patchValue(address.street2);
        addressGroup.get('city').patchValue(address.city);
        addressGroup.get('state').patchValue(address.state);
        addressGroup.get('country').get('id').patchValue(address.country.id);
        addressGroup.get('zipCode').patchValue(address.zipCode);
      }
    });
  }

  // to show selected currecy in package detail table
  currencyValue() {
    return this.detail.currency.value;
  }

  get terms() {
    return this.invoiceTemplateForm.get('terms') as FormArray;
  }

  // used to add new terms
  addNewTerms() {
    let group = this.fb.group({
      label: 'Enter Label',
      content: '',
    });
    this.terms.push(group);
  }
  // used to remove one terms by index
  removeTerms(i: number) {
    this.terms.removeAt(i);
  }

  //default one terms on UI
  defaultOneTerms() {
    return this.fb.group({
      label: 'Enter Label',
      content: '',
    });
  }

  //reusing same component for Edit method
  getTemplateById() {
    this._invoice.getTemplateById(this.templateId).subscribe(
      (response) => {
        console.log(response);
        this.isEdit = true;
        this.detail.name.patchValue(response.name);
        this.detail.additionalInfoLabel.patchValue(
          response.additionalInfoLabel
        );

        //to patch buyers ref
        if (response.buyersRefRequired == false) {
          this.detail.buyersRef.disable();
          this.detail.buyersRefRequired.patchValue(response.buyersRefRequired);
        } else {
          this.detail.buyersRef.patchValue(response.buyersRef);
          this.detail.buyersRefRequired.patchValue(response.buyersRefRequired);
        }

        //to patch exporter ref
        if (response.exporterRefRequired == false) {
          this.detail.exporterRef.disable();
          this.detail.exporterRefRequired.patchValue(
            response.exporterRefRequired
          );
        } else {
          this.detail.exporterRef.patchValue(response.exporterRef);
          this.detail.exporterRefRequired.patchValue(
            response.exporterRefRequired
          );
        }
        this.detail.exporterRefLabel.patchValue(response.exporterRefLabel);

        //to patch other ref
        this.otherRef.clear();
        if (response.otherRef.length > 0) {
          for (let index = 0; index < response.otherRef.length; index++) {
            this.addAlternateOtherRef();
            const otherRef = response.otherRef[index];
            this.otherRef
              .at(index)
              .get('otherReference')
              .patchValue(otherRef.otherReference);
          }
        }
        this.detail.otherRefRequired.patchValue(response.otherRefRequired);

        //to patch parties
        this.parties.clear();
        if (response.parties.length > 0) {
          for (let index = 0; index < response.parties.length; index++) {
            this.addNewParty();
            const party = response.parties[index];
            this.parties.at(index).get('partyType').patchValue(party.partyType);
            this.parties.at(index).get('label').patchValue(party.label);
            this.parties.at(index).get('name').patchValue(party.name);
            this.parties.at(index).get('email').patchValue(party.email);

            if (party.contactNumbers.length > 0) {
              let contact = (
                this.invoiceTemplateForm.get('parties') as FormArray
              )
                .at(index)
                .get('contactNumbers') as FormArray;
              contact.clear();
              for (let ind = 0; ind < party.contactNumbers.length; ind++) {
                this.addNewContactNo(index);
                const element = party.contactNumbers[ind];
                (
                  (this.invoiceTemplateForm.get('parties') as FormArray)
                    .at(index)
                    .get('contactNumbers') as FormArray
                )
                  .at(ind)
                  .get('contacts')
                  .patchValue(element.contacts);
              }
            }

            let address = this.parties.at(index).get('address') as FormGroup;
            address.get('street1').patchValue(party.address.street1);
            address.get('street2').patchValue(party.address.street2);
            address.get('city').patchValue(party.address.city);
            address.get('state').patchValue(party.address.state);
            address.get('country').patchValue(party.address.country);
            address.get('zipCode').patchValue(party.address.zipCode);
          }
        }

        //to patch origin of goods
        if (response.countryOfOriginOfGoodsRequired == false) {
          this.detail.countryOfOriginGoods.disable();
          this.detail.countryOfOriginOfGoodsRequired.patchValue(
            response.countryOfOriginOfGoodsRequired
          );
        } else {
          this.detail.countryOfOriginGoods.patchValue(
            response.countryOfOriginGoods
          );
          this.detail.countryOfOriginOfGoodsRequired.patchValue(
            response.countryOfOriginOfGoodsRequired
          );
        }

        //to patch destination of goods
        if (response.countryOfFinalDestinationRequired == false) {
          this.detail.countryOfFinalDestination.disable();
          this.detail.countryOfFinalDestinationRequired.patchValue(
            response.countryOfFinalDestinationRequired
          );
        } else {
          this.detail.countryOfFinalDestination.patchValue(
            response.countryOfFinalDestination
          );
          this.detail.countryOfFinalDestinationRequired.patchValue(
            response.countryOfFinalDestinationRequired
          );
        }

        //to patch pre carriage by
        if (response.preCarriageByRequired == false) {
          this.detail.preCarriageBy.disable();
          this.detail.preCarriageByRequired.patchValue(
            response.preCarriageByRequired
          );
        } else {
          this.detail.preCarriageBy.patchValue(response.preCarriageBy);
          this.detail.preCarriageByRequired.patchValue(
            response.preCarriageByRequired
          );
        }

        //to patch Place Of Receipt By Pre-Carries
        if (response.placeOfReceiptByPreCarrierRequired == false) {
          this.detail.placeOfReceiptByPreCarrier.disable();
          this.detail.placeOfReceiptByPreCarrierRequired.patchValue(
            response.placeOfReceiptByPreCarrierRequired
          );
        } else {
          this.detail.placeOfReceiptByPreCarrier.patchValue(
            response.placeOfReceiptByPreCarrier
          );
          this.detail.placeOfReceiptByPreCarrierRequired.patchValue(
            response.placeOfReceiptByPreCarrierRequired
          );
        }

        //to patch Vessel/Voyage/Flight Details
        if (response.vesselVoyageFlightDetailsRequired == false) {
          this.detail.vesselVoyageFlightDetails.disable();
          this.detail.vesselVoyageFlightDetailsRequired.patchValue(
            response.vesselVoyageFlightDetailsRequired
          );
        } else {
          this.detail.vesselVoyageFlightDetails.patchValue(
            response.vesselVoyageFlightDetails
          );
          this.detail.vesselVoyageFlightDetailsRequired.patchValue(
            response.vesselVoyageFlightDetailsRequired
          );
        }

        if(response.modeOfTransportRequired == false){
          this.detail.modeOfTransportRequired.patchValue(response.modeOfTransportRequired);
          this.detail.modeOfTransport.disable();
          this.detail.portOfLoading.disable();
          this.detail.portOfDischarge.disable();
        }
        else{
          this.detail.modeOfTransportRequired.patchValue(response.modeOfTransportRequired);
          this.detail.modeOfTransport.patchValue(response.modeOfTransport);
        }
        console.log(response.modeOfTransport);
        //to patch Port Of Loading
        if (response.portOfLoadingRequired == false) {
          this.detail.portOfLoading.disable();
          this.detail.portOfLoadingRequired.patchValue(
            response.portOfLoadingRequired
          );
        } else {
          this.searchOriginValue = response.portOfLoadingName;
          this.getSearchResultsOrigin();
          this.detail.portOfLoading.patchValue(response.portOfLoading);
          this.detail.portOfLoadingRequired.patchValue(
            response.portOfLoadingRequired
          );
        }

        //to patch Port Of Discharge
        if (response.portOfDischargeRequired == false) {
          this.detail.portOfDischarge.disable();
          this.detail.portOfDischargeRequired.patchValue(
            response.portOfDischargeRequired
          );
        } else {
          this.searchDestinationValue = response.portOfDischargeName;
          this.getSearchResultDestination();
          this.detail.portOfDischarge.patchValue(response.portOfDischarge);
          this.detail.portOfDischargeRequired.patchValue(
            response.portOfDischargeRequired
          );
        }

        //to patch place of delivery
        if (response.placeOfDeliveryRequired == false) {
          this.detail.placeOfDelivery.disable();
          this.detail.placeOfDeliveryRequired.patchValue(
            response.placeOfDeliveryRequired
          );
        } else {
          this.detail.placeOfDelivery.patchValue(response.placeOfDelivery);
          this.detail.placeOfDeliveryRequired.patchValue(
            response.placeOfDeliveryRequired
          );
        }

        //to patch currency value
        if (response.currency != null) {
          this.detail.currency.patchValue(response.currency);
        }

        //to patch order value
        if (response.orderValueRequired == false) {
          this.detail.orderValue.disable();
          this.detail.orderValueRequired.patchValue(
            response.orderValueRequired
          );
        } else {
          this.detail.orderValue.patchValue(response.orderValue);
          this.detail.orderValueRequired.patchValue(
            response.orderValueRequired
          );
        }

        //to patch container no
        if (response.containerNoLabel != null) {
          this.detail.containerNoLabel.patchValue(response.containerNoLabel);
        }

        if (response.containerNoRequired == false) {
          this.detail.containerNo.disable();
          this.detail.containerNoRequired.patchValue(
            response.containerNoRequired
          );
        } else {
          this.detail.containerNo.patchValue(response.containerNo);
          this.detail.containerNoRequired.patchValue(
            response.containerNoRequired
          );
        }

        if (response.kindOfPackagesRequired == false) {
          this.detail.kindOfPackages.disable();
          this.detail.kindOfPackagesRequired.patchValue(
            response.kindOfPackagesRequired
          );
        } else {
          this.detail.kindOfPackages.patchValue(response.kindOfPackages);
          this.detail.kindOfPackagesRequired.patchValue(
            response.kindOfPackagesRequired
          );
        }
        this.detail.kindOfPackagesLabel.patchValue(
          response.kindOfPackagesLabel
        );

        if (response.descriptionOfSuppliesRequired == false) {
          this.detail.descriptionOfSupplies.disable();
          this.detail.descriptionOfSuppliesRequired.patchValue(
            response.descriptionOfSuppliesRequired
          );
        } else {
          this.detail.descriptionOfSupplies.patchValue(
            response.descriptionOfSupplies
          );
          this.detail.descriptionOfSuppliesRequired.patchValue(
            response.descriptionOfSuppliesRequired
          );
        }
        this.detail.descriptionOfSuppliesLabel.patchValue(
          response.descriptionOfSuppliesLabel
        );

        // pre populating table content
        if (response.invoiceRows.length > 0) {
          this.invoiceRows.clear();
          for (let index = 0; index < response.invoiceRows.length; index++) {
            this.addNewRow();
            const packagingDetails = response.invoiceRows[index];
            this.invoiceRows
              .at(index)
              .get('descriptionOfGoods')
              .patchValue(packagingDetails.descriptionOfGoods);
            this.invoiceRows
              .at(index)
              .get('tagNumber')
              .patchValue(packagingDetails.tagNumber);
            this.invoiceRows
              .at(index)
              .get('hsCode')
              .patchValue(packagingDetails.hsCode);
            this.invoiceRows
              .at(index)
              .get('packageNumber')
              .patchValue(packagingDetails.packageNumber);
            this.invoiceRows
              .at(index)
              .get('uom')
              .patchValue(packagingDetails.uom);
            this.invoiceRows
              .at(index)
              .get('amount')
              .patchValue(packagingDetails.amount);
            this.invoiceRows
              .at(index)
              .get('noOfPackages')
              .patchValue(packagingDetails.noOfPackages);
          }
        }
        if(response.totalAmountRequired == false){
          this.detail.totalAmountRequired.disable();
          this.detail.totalAmountRequired.patchValue(
            response.totalAmountRequired
          );
        }
        else{
          this.detail.totalAmount.patchValue(response.totalAmount);
          this.detail.totalAmountRequired.patchValue(
            response.totalAmountRequired
          );
        }

        if(response.bankDetail.length > 0){
          for (let index = 0; index < response.bankDetail.length; index++) {
            const element = response.bankDetail[index];
            this.bankDetail.push(this.prePopulateBankInfo(element));
          }
        }

        if(response.additionalInformation.length > 0){
          for (let index = 0; index < response.additionalInformation.length; index++) {
            const element = response.additionalInformation[index];
            this.additionalInformation.push(this.prePopulateAdditionalInfo(element));
          }
        }

        this.shippingMarksControls.shippingFrom.patchValue(
          response.shippingMarks.shippingFrom
        );

        //to patch shipping marks group
        if (response.shippingMarks.shippingFromRequired == false) {
          this.shippingMarksControls.shippingFrom.disable();
          this.shippingMarksControls.shippingFromRequired.patchValue(
            response.shippingMarks.shippingFromRequired
          );
        } else {
          this.shippingMarksControls.shippingFrom.patchValue(
            response.shippingMarks.shippingFrom
          );
          this.shippingMarksControls.shippingFromRequired.patchValue(
            response.shippingMarks.shippingFromRequired
          );
        }

        if (response.shippingMarks.shippingToRequired == false) {
          this.shippingMarksControls.shippingTo.disable();
          this.shippingMarksControls.shippingToRequired.patchValue(
            response.shippingMarks.shippingToRequired
          );
        } else {
          this.shippingMarksControls.shippingTo.patchValue(
            response.shippingMarks.shippingTo
          );
          this.shippingMarksControls.shippingToRequired.patchValue(
            response.shippingMarks.shippingToRequired
          );
        }

        if (response.shippingMarks.caseNumberRequired == false) {
          this.shippingMarksControls.caseNumber.disable();
          this.shippingMarksControls.caseNumberRequired.patchValue(
            response.shippingMarks.caseNumberRequired
          );
        } else {
          this.shippingMarksControls.caseNumber.patchValue(
            response.shippingMarks.caseNumber
          );
          this.shippingMarksControls.caseNumberRequired.patchValue(
            response.shippingMarks.caseNumberRequired
          );
        }

        if (response.shippingMarks.grossWeightRequired == false) {
          this.shippingMarksControls.grossWeight.disable();
          this.shippingMarksControls.grossWeightRequired.patchValue(
            response.shippingMarks.grossWeightRequired
          );
        } else {
          this.shippingMarksControls.grossWeight.patchValue(
            response.shippingMarks.grossWeight
          );
          this.shippingMarksControls.grossWeightRequired.patchValue(
            response.shippingMarks.grossWeightRequired
          );
        }

        if (response.shippingMarks.netWeightRequired == false) {
          this.shippingMarksControls.netWeight.disable();
          this.shippingMarksControls.netWeightRequired.patchValue(
            response.shippingMarks.netWeightRequired
          );
        } else {
          this.shippingMarksControls.netWeight.patchValue(
            response.shippingMarks.netWeight
          );
          this.shippingMarksControls.netWeightRequired.patchValue(
            response.shippingMarks.netWeightRequired
          );
        }
        //end shipping marks

        this.detail.termsLabel.patchValue(response.termsLabel);

        //to patch terms array
        this.terms.clear();
        if (response.terms.length > 0) {
          for (let index = 0; index < response.terms.length; index++) {
            this.addNewTerms();
            const terms = response.terms[index];
            this.terms.at(index).get('label').patchValue(terms.label);
            this.terms.at(index).get('content').patchValue(terms.content);
          }
        }

        //to patch declaration
        this.detail.declarationLabel.patchValue(response.declarationLabel);
        if (response.declarationRequired == false) {
          this.detail.declaration.disable();
          this.detail.declarationRequired.patchValue(
            response.declarationRequired
          );
        } else {
          this.detail.declaration.patchValue(response.declaration);
          this.detail.declarationRequired.patchValue(
            response.declarationRequired
          );
        }

        //to patch notes
        this.detail.notesLabel.patchValue(response.notesLabel);
        if (response.notesRequired == false) {
          this.detail.notes.disable();
          this.detail.notesRequired.patchValue(response.notesRequired);
        } else {
          this.detail.notes.patchValue(response.notes);
          this.detail.notesRequired.patchValue(response.notesRequired);
        }

        if (response.serviceOrder != null) {
          this.serviceOrderForm.get('id').patchValue(response.serviceOrder.id);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  initAdditionalInfo(){
    return this.fb.group({
      label: [''],
      value: ['']
    })
  }

  initBankInfo(){
    return this.fb.group({
      label: [''],
      value: ['']
    })
  }

  prePopulateAdditionalInfo(data){
    return this.fb.group({
      label: [data.label],
      value: [data.value]
    })
  }

  prePopulateBankInfo(data){
    return this.fb.group({
      label: [data.label],
      value: [data.value]
    })
  }

  //adding additional Info fields
  addNewField() {
    this.additionalInformation.push(this.initAdditionalInfo());
  }

  removeAdditionalField(i: number) {
    this.additionalInformation.removeAt(i);
  }

  //adding bank Fields
  addNewBankField() {
    this.bankDetail.push(this.initBankInfo());
  }

  removeAdditionalBankFields(i: number) {
    this.bankDetail.removeAt(i);
  }

  //for adding alternate other reference
  addAlternateOtherRef() {
    let newRef = this.fb.group({
      otherReference: [''],
    });
    this.otherRef.push(newRef);
  }
  removeAlternateOtherRef(i: number) {
    this.otherRef.removeAt(i);
  }
  patchOtherRef(response: any) {
    return this.fb.group({
      otherReference: [response.otherReference],
    });
  }

  //for adding multiple rows in table
  addNewRow() {
    let newItem = this.fb.group({
      descriptionOfGoods: [''],
      tagNumber: [''],
      hsCode: [''],
      packageNumber: [''],
      uom: [''],
      amount: [0],
      noOfPackages: [0],
      quantity: [0]
    });
    this.invoiceRows.push(newItem);
  }
  removeItemRow(i: number) {
    this.invoiceRows.removeAt(i);
    this.calculateTotalAmount();
  }

  //Api's to save template
  saveTemplate() {
    let term = this.invoiceTemplateForm.get('terms') as FormArray;
    let control = term.controls;
    let content;
    for (let index = 0; index < control.length; index++) {
      const element = control[index].value.content;
      content = element;
    }
    if (this.detail.termsRequired.value == false || content == '') {
      this.terms.clear();
    }
    if (this.samePortError) {
      Swal.fire({
        title: 'Warning',
        icon: 'warning',
        timer: 5000,
        text: 'Loading & Destination Port cannot be same',
      });
    } else {
      // ToDo, check remaining.
      if (this.serviceOrderForm.get('id').value == null) {
        // this.serviceOrderForm.removeControl('id');
        this.invoiceTemplateForm.removeControl('serviceOrder');
        this.invoiceTemplateForm.addControl('serviceOrder', new FormControl(null))
        this.serviceOrderBool = false;
      }
      this._invoice
        .saveInvoiceTemplate(this.invoiceTemplateForm.value)
        .subscribe(
          (response) => {
            console.log(response);
            Swal.fire({
              title: 'Success',
              icon: 'success',
              text: response.message,
              timer: 5000,
            });
            this.router.navigateByUrl('v1/client-template/listing');
          },
          (error) => {
            let _error = error.error;
            if(_error.errorCode == 412){
              Swal.fire({
                title: 'Invalid',
                icon: 'error',
                text: _error.errorMessage,
                timer: 5000,
              });
            }
          }
        );
    }
  }

  //to get all addresses
  getUserAddresses() {
    this._invoice.getUserAddressDetails().subscribe(
      (response) => {
        this.addressList = response;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  //to disable field based on checkbox enable or disable
  toggleCheckbox(event, formControl: string) {
    let x = event.srcElement.checked;
    if (x == false) {
      this.invoiceTemplateForm.get(formControl).disable();
    } else {
      this.invoiceTemplateForm.get(formControl).enable();
    }
    if(formControl == "modeOfTransport" && x == false){
      this.invoiceTemplateForm.get('portOfLoading').disable();
      this.invoiceTemplateForm.get('portOfDischarge').disable();
    }
    else if(formControl == "modeOfTransport" && x == true){
      this.invoiceTemplateForm.get('portOfLoading').enable();
      this.invoiceTemplateForm.get('portOfDischarge').enable();
    }
  }

  //to get data from session storage
  getMasterTablesSessionStorageData() {
    this.getOriginDestination();
    this.getCurrency();
    this.getCountries();
    this.getAllServiceOrder();
    this.getModeOfShipment();
  }

  getModeOfShipment() {
    this.masterTableService.getModeOfShipment().subscribe(
      (response) => {
        this.modeOfShipment = response;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getOriginDestination() {
    this.masterTableService.getLoadingDestinationPort().subscribe(
      (response) => {
        this.originDestinationPorts = response;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getCurrency() {
    this.masterTableService.getAllCurrency().subscribe(
      (response) => {
        this.currency = response;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getCountries() {
    this.masterTableService.getAllCountries().subscribe(
      (response) => {
        this.countryCodes = response;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  //to calculate total amount
  calculateTotalAmount() {
    let totalAmount = 0;
    for (let index = 0; index < this.invoiceRows.controls.length; index++) {
      const item = this.invoiceRows.controls[index];
      let amount = item.get('amount').value;
      totalAmount = totalAmount + amount;
    }
    this.invoiceTemplateForm.get('totalAmount').patchValue(totalAmount);
  }

  //update template API
  updateInvoiceTemplateData() {
    if (this.serviceOrderForm.get('id').value == '') {
      this.serviceOrderForm.removeControl('id');
      this.invoiceTemplateForm.removeControl('serviceOrder');
      this.serviceOrderBool = false;
    }
    this._invoice.updateInvoiceTemplate(this.templateId, this.invoiceTemplateForm.value).subscribe(
        (res) => {
          console.log(res);
          if (res.code == 201) {
            Swal.fire({
              title: 'Success',
              text: res.message,
              timer: 5000,
              icon: 'success',
            });
            this.router.navigateByUrl('v1/client-template/listing');
          }
        },
        (error) => {
          console.log(error);
        }
    );
  }

  //to hide sidebar
  hideSideBar() {
    document.getElementById('sideBar').classList.remove('sidebar-active');
  }

  selectLoadingPort() {
    let lPort = this.detail.portOfLoading.value;
    let dPort = this.detail.portOfDischarge.value;
    if (lPort == dPort) {
      this.samePortError = true;
      Swal.fire({
        title: 'Warning',
        icon: 'warning',
        timer: 5000,
        text: 'Loading & Destination Port cannot be same',
      });
    } else {
      this.samePortError = false;
    }
  }

  // Changes will come.
  getAllServiceOrder() {
    this.serviceOrder.getServiceOrders().subscribe(
      (response) => {
        this.serviceOrderList = response;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  resetForm() {
    this.serviceOrderForm.reset();
  }

  getSearchResultsOrigin() {
    let modeOfShipmentValue = this.detail.modeOfTransport.value;
    if (modeOfShipmentValue != null || undefined) {
      let mode = this.modeOfShipment.find((x) => {
        if (x.value == modeOfShipmentValue) {
          return x;
        }
      });
      let size = 50;
      if (this.searchOriginValue != '') {
        this.masterTableService
          .getSearchPort(mode.id, this.searchOriginValue, size)
          .subscribe(
            (res) => {
              this.loadingPorts = res;
            },
            (error) => {
              console.log(error);
            }
          );
      } else {
        console.log('search value is empty ', this.searchOriginValue);
      }
    }
  }

  getSearchResultDestination() {
    let modeOfShipmentValue = this.detail.modeOfTransport.value;
    if (modeOfShipmentValue != null || undefined) {
      let mode = this.modeOfShipment.find((x) => {
        if (x.value == modeOfShipmentValue) {
          return x;
        }
      });
      let size = 50;
      if (this.searchDestinationValue != '') {
        this.masterTableService
          .getSearchPort(mode.id, this.searchDestinationValue, size)
          .subscribe(
            (res) => {
              this.destinationPorts = res;
            },
            (error) => {
              console.log(error);
            }
          );
      } else {
        console.log('search value is empty ', this.searchDestinationValue);
      }
    }
  }

  checkOriginDestination(){
    let origin = this.invoiceTemplateForm.get('portOfLoading').value;
    let destination = this.invoiceTemplateForm.get('portOfDischarge').value;
    console.log(origin, destination);
    if(origin == destination){
      this.samePortError = true;
      console.log("INVALID");
    }
    else{
      this.samePortError = false;
      console.log("OKAY");
    }
  }
}
