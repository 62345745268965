import { DialogBoxComponent } from './dialog-box/dialog-box.component';
import { UserRoleListComponent } from './user-role-list/user-role-list.component';
import { UserPermissionListComponent } from './user-permission-list/user-permission-list.component';
import { MatSortModule } from '@angular/material/sort';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageComponent } from './home-page/home-page.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SignUpComponent } from './client-onboarding/sign-up/sign-up.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RequestDemoComponent } from './request-demo/request-demo.component';
import { VendorOnboardingComponent } from './vendor-onboarding/vendor-onboarding.component';
import { ClientOnboardingComponent } from './client-onboarding/client-onboarding.component';
import { VendorOverviewComponent } from './vendor-onboarding/vendor-overview/vendor-overview.component';
import { VendorDocumentComponent } from './vendor-onboarding/vendor-document/vendor-document.component';
import { VendorCertificationComponent } from './vendor-onboarding/vendor-certification/vendor-certification.component';
import { ClientDocumentComponent } from './client-onboarding/client-document/client-document.component';
import { ClientRfqComponent } from './client-rfq-list/client-rfq/client-rfq.component';
import { VendorSignUpComponent } from './vendor-onboarding/vendor-sign-up/vendor-sign-up.component';
import { ClientAuctionComponent } from './client-auction-list/client-auction/client-auction.component';
import { ServiceOrderComponent } from './service-order-list/service-order/service-order.component';
import { AuthInterceptorService } from './helpers/interceptor';
import { ServiceOrderListComponent } from './service-order-list/service-order-list.component';
import { UserRoleComponent } from './user-role-list/user-role/user-role.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { QuoteComparisonComponent } from './quote-comparison/quote-comparison.component';
import { DetailQuoteComparisonComponent } from './detail-quote-comparison/detail-quote-comparison.component';
import { ClientRfqListComponent } from './client-rfq-list/client-rfq-list.component';
import { ClientAuctionListComponent } from './client-auction-list/client-auction-list.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserComponent } from './user-list/user/user.component';
import { InvoiceListingComponent } from './invoice-listing/invoice-listing.component';
import { InvoiceCreateComponent } from './invoice-listing/invoice-create/invoice-create.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { VendorRfqListComponent } from './vendor-rfq-list/vendor-rfq-list.component';
import { VendorAuctionListComponent } from './vendor-auction-list/vendor-auction-list.component';
import { VendorRfqQuoteComponent } from './vendor-rfq-list/vendor-rfq-quote/vendor-rfq-quote.component';
import { VendorAuctionQuoteComponent } from './vendor-auction-list/vendor-auction-quote/vendor-auction-quote.component';
import { AuctionComparisonComponent } from './auction-comparison/auction-comparison.component';
import { DetailComparisonComponent } from './auction-comparison/detail-comparison/detail-comparison.component';
import { RequestExtensionComponent } from './request-extension/request-extension.component';
import { ManageExtensionComponent } from "./request-extension/manage-extension/manage-extension.component";
import { ReviewAuctionComponent } from './review-auction/review-auction.component';
import { ReviewDocumentsComponent } from './review-auction/review-documents/review-documents.component';
import { RolesActivityMappingComponent } from './auction-comparison/roles-activity-mapping/roles-activity-mapping.component';
import { DocumentHierarchyMappingComponent } from './auction-comparison/hierarchy-master/document-hierarchy-mapping/document-hierarchy-mapping.component';
import { HierarchyMasterComponent } from './auction-comparison/hierarchy-master/hierarchy-master.component';
import { DocumentSequenceConfigurationComponent } from './document-sequence-configuration/document-sequence-configuration.component';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ContactUsListComponent } from './contact-us-list/contact-us-list.component';
import { RequestDemoListComponent } from './request-demo-list/request-demo-list.component';
import { DatePipe, DecimalPipe } from '@angular/common';
import { TypeOfPackingComponent } from './master-table/type-of-packing/type-of-packing.component';
import { PackingBoxComponent } from './master-table/type-of-packing/packing-box/packing-box.component';
import { NatureofMovementComponent } from './master-table/natureof-movement/natureof-movement.component';
import { NatureDialogBoxComponent } from './master-table/natureof-movement/nature-dialog-box/nature-dialog-box.component';
import { ModeOfShipmentComponent } from './master-table/mode-of-shipment/mode-of-shipment.component';
import { ShipmentDialogBoxComponent } from './master-table/mode-of-shipment/shipment-dialog-box/shipment-dialog-box.component';
import { DeliveryIncoTermsComponent } from './master-table/delivery-inco-terms/delivery-inco-terms.component';
import { DeliveryIncoDialogBoxComponent } from './master-table/delivery-inco-terms/delivery-inco-dialog-box/delivery-inco-dialog-box.component';
import { DialogPickupTermsComponent } from './master-table/pick-up-inco-terms/dialog-pickup-terms/dialog-pickup-terms.component';
import { PickUpIncoTermsComponent } from './master-table/pick-up-inco-terms/pick-up-inco-terms.component';
import { ContainerTypeComponent } from './master-table/container-type/container-type.component';
import { ContainerDialogBoxComponent } from './master-table/container-type/container-dialog-box/container-dialog-box.component';
import { TypeOfCargoComponent } from './master-table/type-of-cargo/type-of-cargo.component';
import { CargoBoxComponent } from './master-table/type-of-cargo/cargo-box/cargo-box.component';
import { TradeTypeComponent } from './master-table/trade-type/trade-type.component';
import { TradeDialogBoxComponent } from './master-table/trade-type/trade-dialog-box/trade-dialog-box.component';
import { LoadingDestinationPortComponent } from './master-table/loading-destination-port/loading-destination-port.component';
import { DialogLoadingDestinationPortComponent } from './master-table/loading-destination-port/dialog-loading-destination-port/dialog-loading-destination-port.component';
import { ReportListingComponent } from './report-listing/report-listing.component';
import { ReportComponent } from './report-listing/report/report.component';

import { AuctionClientComparisonComponent } from './client-auction-list/auction-client-comparison/auction-client-comparison.component';
import { VendorQuoteComparisonComponent } from './vendor-rfq-list/vendor-quote-comparison/vendor-quote-comparison.component';
import { ClientLiveAuctionComponent } from './client-live-auction/client-live-auction.component';
import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { AdminSubscriptionComponent } from './admin-subscription/admin-subscription.component';
import { ClientListingComponent } from './client-listing/client-listing.component';
import { ClientTemplateListComponent } from './client-template-list/client-template-list.component';
import { ClientTemplateComponent } from './client-template-list/client-template/client-template.component';
import { ClientShipmentComponent } from './client-track-trace/client-shipment/client-shipment.component';
import { ClientTrackTraceComponent } from './client-track-trace/client-track-trace.component';
import { ConfigurationParamatersComponent } from './configuration-paramaters/configuration-paramaters.component';
import { FooterComponent } from './layout/footer/footer.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { DialogNatureOfGoodsComponent } from './master-table/nature-of-goods/dialog-nature-of-goods/dialog-nature-of-goods.component';
import { NatureOfGoodsComponent } from './master-table/nature-of-goods/nature-of-goods.component';
import { MessageTemplateComponent } from './message-template/message-template.component';
import { VendorListingComponent } from './vendor-listing/vendor-listing.component';
import { PreShipmentInvoiceTemplateComponent } from './pre-shipment-invoice-template/pre-shipment-invoice-template.component';
import { VendorLiveAuctionComponent } from './vendor-live-auction/vendor-live-auction.component';
import { PreShipmentInvoiceComponent } from './pre-shipment-invoice-template/pre-shipment-invoice/pre-shipment-invoice.component';

import { CountdownModule } from 'ngx-countdown';
import { TemplateboxComponent } from './message-template/templatebox/templatebox.component';
import { AdminClientParamComponent } from './admin-client-param/admin-client-param.component';
import { CurrencyComponent } from './master-table/currency/currency.component';
import { CurrencyDialogBoxComponent } from './master-table/currency/currency-dialog-box/currency-dialog-box.component';
import { VendorDetailQuoteComparisonComponent } from './vendor-rfq-list/vendor-detail-quote-comparison/vendor-detail-quote-comparison.component';
import { AuthGuard } from './helpers/auth.guard';
import { NgxFileDropModule } from 'ngx-file-drop';
import { FileUploadComponent } from './shared/file-upload/file-upload.component';
import { ClientShipmentListComponent } from './client-track-trace/client-shipment-list/client-shipment-list.component';
import { DetailTermsAttachmentBoxComponent } from './detail-quote-comparison/detail-terms-attachment-box/detail-terms-attachment-box.component';
import { SocketService } from './helpers/socket.service';
import { ClientFlightScheduleComponent } from './client-track-trace/client-flight-schedule/client-flight-schedule.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatTableExporterModule } from 'mat-table-exporter';
import { VendorCertificateTermComponent } from './vendor-onboarding/vendor-certificate-term/vendor-certificate-term.component';
import { SingleQuoteViewComponent } from './quote-comparison/single-quote-view/single-quote-view.component';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { BuySubscriptionComponent } from './request-extension/buy-subscription/buy-subscription.component'
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PlatformParamComponent } from './platform-param/platform-param.component';
import { PlatformDialogBoxComponent } from './platform-param/platform-dialog-box/platform-dialog-box.component';
import { QuillModule } from 'ngx-quill';
import { ClientContainerScheduleComponent } from './client-track-trace/client-container-schedule/client-container-schedule.component';
import { ClientVesselScheduleComponent } from './client-track-trace/client-vessel-schedule/client-vessel-schedule.component';
import { ViewAddressComponent } from './request-demo-list/view-address/view-address.component';
import { AdditionalChargeTypeComponent } from './master-table/additional-charge-type/additional-charge-type.component';
import { ChargeBoxComponent } from './master-table/additional-charge-type/charge-box/charge-box.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { VesselDetailsBoxComponent } from './client-track-trace/client-vessel-schedule/vessel-details-box/vessel-details-box.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {MatTabsModule} from '@angular/material/tabs';
import { MatSliderModule } from '@angular/material/slider';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { TypeOfVehicleComponent } from './master-table/type-of-vehicle/type-of-vehicle.component';
import { VehicleDetailBoxComponent } from './master-table/type-of-vehicle/vehicle-detail-box/vehicle-detail-box.component';
import {MatChipsModule} from '@angular/material/chips';
import { AuctionDialogComponent } from './client-auction-list/auction-dialog/auction-dialog.component';
import { CurrentPreviousQuoteComparisonDialogComponent } from './current-previous-quote-comparison-dialog/current-previous-quote-comparison-dialog.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { IncreaseTimerComponent } from './client-live-auction/increase-timer/increase-timer.component';
import { ClientTermsConditionComponent } from './client-onboarding/client-terms-condition/client-terms-condition.component';
import { VendorServiceOrderListingComponent } from './vendor-service-order-listing/vendor-service-order-listing.component';
import { ToastrModule } from 'ngx-toastr';
import { FaqComponent } from './static-pages/faq/faq.component';
import { ServicesComponent } from './static-pages/services/services.component';
import { AboutUsComponent } from './static-pages/about-us/about-us.component';
import { PrivacyPolicyComponent } from './static-pages/privacy-policy/privacy-policy.component';
import { CsrPageComponent } from './static-pages/csr-page/csr-page.component';
import { FeedbackComponent } from './static-pages/feedback/feedback.component';
import { MissionVisionComponent } from './static-pages/mission-vision/mission-vision.component';
import { ReferralComponent } from './static-pages/referral/referral.component';
import { HeaderComponent } from './layout/header/header.component';
import { BusinessTypeComponent } from './master-table/business-type/business-type.component';
import { BusinessTypeDialogComponent } from './master-table/business-type/business-type-dialog/business-type-dialog.component';
import { EditBidVendorComponent } from './client-live-auction/edit-bid-vendor/edit-bid-vendor.component';
import { ClientPageComponent } from './static-pages/client-page/client-page.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { ContianerLiveDialogComponent } from './client-track-trace/client-container-schedule/contianer-live-dialog/contianer-live-dialog.component';
import {CdkAccordionModule} from '@angular/cdk/accordion';
import { ClientTrackAirPackageComponent } from './client-track-trace/client-track-air-package/client-track-air-package.component';
import { CountryComponent } from './master-table/country/country.component';
import { CountryDialogBoxComponent } from './master-table/country/country-dialog-box/country-dialog-box.component';
import { CareerPageComponent } from './static-pages/career-page/career-page.component';
import { FeedbackListingComponent } from './feedback-listing/feedback-listing.component';
import { CareerListingComponent } from './career-listing/career-listing.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NoPermissionRouteComponent } from './no-permission-route/no-permission-route.component';
import { ChangeUserPasswordComponent } from './change-user-password/change-user-password.component';
import { VendorViewComponent } from './vendor-listing/vendor-view/vendor-view.component';
import { ClientViewComponent } from './client-listing/client-view/client-view.component';
import { RfqViewComponent } from './client-rfq-list/rfq-view/rfq-view.component';
import { CarriersComponent } from './master-table/carriers/carriers.component';
import { CarrierDialogComponent } from './master-table/carriers/carrier-dialog/carrier-dialog.component';
import { VendorServiceOrderComponent } from './vendor-service-order-listing/vendor-service-order/vendor-service-order.component';
import { HsCodeComponent } from './master-table/hs-code/hs-code.component';
import { HsCodeDialogBoxComponent } from './master-table/hs-code/hs-code-dialog-box/hs-code-dialog-box.component';
import { TradeTypeAgreementComponent } from './master-table/trade-type-agreement/trade-type-agreement.component';
import { TradeTypeAgreementDialogBoxComponent } from './master-table/trade-type-agreement/trade-type-agreement-dialog-box/trade-type-agreement-dialog-box.component';
import { HsCodeListsComponent } from './hs-code-lists/hs-code-lists.component';
import { TradeAgreementListsComponent } from './trade-agreement-lists/trade-agreement-lists.component';
import { ShippingLineComponent } from './master-table/shipping-line/shipping-line.component';
import { ShippingLineDialogComponent } from './master-table/shipping-line/shipping-line-dialog/shipping-line-dialog.component';
import { UserProfileComponent } from './user-list/user-profile/user-profile.component';
import { MarketingSpaceComponent } from './master-table/marketing-space/marketing-space.component';
import { DialogMarketingSpaceComponent } from './master-table/marketing-space/dialog-marketing-space/dialog-marketing-space.component';
import { MatTooltipModule } from "@angular/material/tooltip";
import { HashLocationStrategy, LocationStrategy, Location, PathLocationStrategy } from '@angular/common';
import { VendorPreShipmentInvoicesComponent } from './vendor-pre-shipment-invoices/vendor-pre-shipment-invoices.component';
import { VendorCommercialInvoicesComponent } from './vendor-commercial-invoices/vendor-commercial-invoices.component';
import { ShowAuctionTermsAndConditionsComponent } from './vendor-auction-list/show-auction-terms-and-conditions/show-auction-terms-and-conditions.component';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    SignInComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    SignUpComponent,
    DashboardComponent,
    RequestDemoComponent,
    VendorOnboardingComponent,
    ClientOnboardingComponent,
    VendorOverviewComponent,
    VendorDocumentComponent,
    VendorCertificationComponent,
    ClientDocumentComponent,
    ClientRfqComponent,
    VendorSignUpComponent,
    ClientAuctionComponent,
    ServiceOrderComponent,
    ServiceOrderListComponent,
    UserPermissionListComponent,
    UserRoleListComponent,
    UserRoleComponent,
    DialogBoxComponent,
    QuoteComparisonComponent,
    DetailQuoteComparisonComponent,
    ClientRfqListComponent,
    ClientAuctionListComponent,
    UserListComponent,
    UserComponent,
    InvoiceListingComponent,
    InvoiceCreateComponent,

    VendorRfqListComponent,
    VendorAuctionListComponent,
    VendorRfqQuoteComponent,
    VendorAuctionQuoteComponent,

    AuctionComparisonComponent,
    DetailComparisonComponent,
    RequestExtensionComponent,
    ManageExtensionComponent,
    ReviewAuctionComponent,
    ReviewDocumentsComponent,
    RolesActivityMappingComponent,
    DocumentHierarchyMappingComponent,
    HierarchyMasterComponent,
    DocumentSequenceConfigurationComponent,
    ContactUsListComponent,
    RequestDemoListComponent,
    TypeOfCargoComponent,
    TypeOfPackingComponent,
    CargoBoxComponent,
    PackingBoxComponent,
    TradeTypeComponent,
    TradeDialogBoxComponent,
    ContainerTypeComponent,
    ContainerDialogBoxComponent,
    NatureofMovementComponent,
    NatureDialogBoxComponent,
    ModeOfShipmentComponent,
    ShipmentDialogBoxComponent,
    DeliveryIncoTermsComponent,
    DeliveryIncoDialogBoxComponent,
    PickUpIncoTermsComponent,
    DialogPickupTermsComponent,
    LoadingDestinationPortComponent,
    DialogLoadingDestinationPortComponent,
    ReportListingComponent,
    ReportComponent,
    AuctionClientComparisonComponent,
    VendorQuoteComparisonComponent,
    ClientLiveAuctionComponent,
    UserDashboardComponent,
    NatureOfGoodsComponent,
    DialogNatureOfGoodsComponent,
    FooterComponent,
    SidebarComponent,
    ClientTemplateListComponent,
    ClientTemplateComponent,
    ClientTrackTraceComponent,
    ClientShipmentComponent,
    ClientListingComponent,
    VendorListingComponent,
    AdminSubscriptionComponent,
    ConfigurationParamatersComponent,
    MessageTemplateComponent,
    PreShipmentInvoiceTemplateComponent,
    VendorLiveAuctionComponent,
    PreShipmentInvoiceComponent,
    TemplateboxComponent,
    AdminClientParamComponent,
    CurrencyComponent,
    CurrencyDialogBoxComponent,
    VendorDetailQuoteComparisonComponent,
    FileUploadComponent,
    ClientShipmentListComponent,
    DetailTermsAttachmentBoxComponent,
    ClientFlightScheduleComponent,
    VendorCertificateTermComponent,
    SingleQuoteViewComponent,
    PlatformParamComponent,
    PlatformDialogBoxComponent,
    BuySubscriptionComponent,
    ClientContainerScheduleComponent,
    ClientVesselScheduleComponent,
    ViewAddressComponent,
    AdditionalChargeTypeComponent,
    ChargeBoxComponent,
    VesselDetailsBoxComponent,
    TypeOfVehicleComponent,
    VehicleDetailBoxComponent,
    AuctionDialogComponent,
    CurrentPreviousQuoteComparisonDialogComponent,
    IncreaseTimerComponent,
    ClientTermsConditionComponent,
    VendorServiceOrderListingComponent,
    FaqComponent,
    ServicesComponent,
    AboutUsComponent,
    PrivacyPolicyComponent,
    CsrPageComponent,
    FeedbackComponent,
    MissionVisionComponent,
    ReferralComponent,
    HeaderComponent,
    BusinessTypeComponent,
    BusinessTypeDialogComponent,
    ClientTrackAirPackageComponent,
    EditBidVendorComponent,
    ClientPageComponent,
    ContianerLiveDialogComponent,
    CountryComponent,
    CountryDialogBoxComponent,
    CareerPageComponent,
    FeedbackListingComponent,
    CareerListingComponent,
    NoPermissionRouteComponent,
    ChangeUserPasswordComponent,
    VendorViewComponent,
    ClientViewComponent,
    RfqViewComponent,
    CarriersComponent,
    CarrierDialogComponent,
    VendorServiceOrderComponent,
    HsCodeComponent,
    HsCodeDialogBoxComponent,
    TradeTypeAgreementComponent,
    TradeTypeAgreementDialogBoxComponent,
    HsCodeListsComponent,
    TradeAgreementListsComponent,
    ShippingLineComponent,
    ShippingLineDialogComponent,
    UserProfileComponent,
    MarketingSpaceComponent,
    DialogMarketingSpaceComponent,
    VendorPreShipmentInvoicesComponent,
    VendorCommercialInvoicesComponent,
    ShowAuctionTermsAndConditionsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatDialogModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    DragDropModule,
    MatSelectModule,
    MatSidenavModule,
    NgApexchartsModule,
    CountdownModule,
    NgxFileDropModule,
    MatToolbarModule,
    MatIconModule,
    MatTableExporterModule,
    MatProgressSpinnerModule,
    NgMultiSelectDropDownModule.forRoot(),
    MatSlideToggleModule,
    QuillModule.forRoot(),
    MatMenuModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSliderModule,
    MatTabsModule,
    NgxSliderModule,
    MatChipsModule,
    MatProgressBarModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    MatExpansionModule,
    CdkAccordionModule,
    NgxMatSelectSearchModule,
    MatTooltipModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    DatePipe,
    AuthGuard,
    SocketService,
    SidebarComponent,
    DecimalPipe,
    Location, {provide: LocationStrategy, useClass: PathLocationStrategy}
    // {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent],
  exports: [
    FooterComponent,
    SidebarComponent,
  ]
})
export class AppModule { }
