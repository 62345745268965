<header class="position-fixed top-0 w-100 index-1 px-2 px-sm-3">
    <nav class="navbar navbar-expand-lg navbar-light bg-white">
        <div class="container-fluid">
            <a class="navbar-brand" href="index.html"><img class="img" src="assets/images/logo.png" /></a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbar-collapse"><span class="navbar-toggler-icon"></span></button>
            <div class="collapse navbar-collapse" id="navbar-collapse">
                <ul class="navbar-nav ms-auto">
                    <li class="nav-item active"><a class="nav-link" href="#home-banner">Home</a></li>

                    <li class="nav-item dropdown" id="services">
                        <a class="nav-link dropdown-toggle" id="services" role="button" data-bs-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">Services
                        </a>
                        <div class="dropdown-menu" aria-labelledby="services">
                            <a class="dropdown-item" (click)="scroll()"
                                [routerLink]="['/services']">Products/Services</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" [routerLink]="['/hs-code']">HS-Codes</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" [routerLink]="['/trade-agreement']">Trade Agreement</a>
                        </div>
                    </li>


                    <li class="nav-item dropdown" id="navbarDropdown">
                        <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            About US
                        </a>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <a class="dropdown-item" [routerLink]="['/about-us']">Mission</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" [routerLink]="['/about-us']">Vision</a>
                            <div class="dropdown-divider"></div>
                            <a class="nav-link" [routerLink]="['/csr-page']">CSR</a>
                        </div>
                    </li>
                    <li class="nav-item  "><a class="nav-link" [routerLink]="['/career']">Career</a></li>
                    <li class="nav-item  "><a class="nav-link" [routerLink]="['/client']">Client</a></li>
                    <li style="white-space: nowrap" class="nav-item  "><a class="nav-link" href="#contact-us">Contact Us</a></li>
                    <li style="white-space: nowrap" class="nav-item  "><a class="nav-link" [routerLink]="['/request-demo']">Request Demo</a></li>
                    <li class="nav-item  "><a class="nav-link" href="#subscription">Subscription</a></li>
                    <li style="white-space: nowrap" class="nav-item nav-item-btn"><a class="nav-link" [routerLink]="['/sign-in']">Sign In</a></li>
                </ul>
            </div>
        </div>
    </nav>
</header>