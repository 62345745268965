import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuctionService } from '../helpers/auction.service';
import { MasterTableService } from '../helpers/master-table.service';
import { QuoteService } from '../helpers/quote.service';
import Swal from 'sweetalert2';
import {
  NgxFileDropEntry,
  FileSystemFileEntry,
  FileSystemDirectoryEntry,
} from 'ngx-file-drop';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { SocketService } from '../helpers/socket.service';
import { ToastrService } from 'ngx-toastr';
import { interval } from 'rxjs';
import { ThemePalette } from '@angular/material/core';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-vendor-live-auction',
  templateUrl: './vendor-live-auction.component.html',
  styleUrls: ['./vendor-live-auction.component.css'],
})
export class VendorLiveAuctionComponent implements OnInit {
  @ViewChild('scrollframe', { static: false }) scrollFrame: ElementRef;
  auctionSummary: any = {};
  packageDetails: Array<any> = [];
  contianerDetails: Array<any> = [];
  breakBulkDetails: Array<any> = [];
  packageBool: Boolean = false;
  containerBool: Boolean = false;
  breakBulkBool: Boolean = false;

  auctionId: any;
  comments: string;
  participating: Boolean = true;
  reason: string;
  remainingAttempts: number = 0;
  vendorBidId: any;
  updateBidBool: boolean = false;
  maxAttempts: number;
  auctionValue: number = 0;
  previousBidValue: number = 0;
  minDecrement: number = 0;
  minimumDecrementUnit: string;
  editBidBool: Boolean = false;
  notParticipatingBool: Boolean = false;
  vendorName: string;
  notParticipatingButtonBool: Boolean = false;
  container_type = [];
  auctionTerms: Array<any> = [];
  auctionDocuments: Array<any> = [];
  documentList: Array<any> = [];
  termsForm: FormGroup;
  maxFileSize: number = 2000000;
  maxSizeImage: number = 1000000;
  modeOfShipment: any;

  air_mode_shipment: Boolean = false;
  sea_mode_shipment: Boolean = false;
  totalVolumeWeightAIR = 0.0;
  finalAirWeightUom: string;
  totalGrossWeightAIR = 0.0;
  finalAirWeightBool: Boolean = false;
  totalBreakBulkVolume: number = 0.0;
  totalBreakBulkWeight: number = 0.0;
  totalVolumeWeightLCL: number;
  finalSeaWeightUom: string;
  totalGrossWeightLCL: number;
  finalSeaWeightBool: boolean = false;

  vendorRank: string;
  infiniteAttempts: boolean = false;
  curSec: number = 0;
  remainingTimer: number = 0;
  color: ThemePalette = 'primary';
  mode: ProgressBarMode = 'buffer';
  totalVolume: number = 0;

  // isTermsAndConditionsAccepted: boolean = false;
  disableTermsCheckbox: boolean = false;

  constructor(
    private vendorService: AuctionService,
    private route: ActivatedRoute,
    private masterTableService: MasterTableService,
    private socketService: SocketService,
    private router: Router,
    private toastService: ToastrService,
  ) {}

  ngOnInit(): void {
    this.auctionId = this.route.snapshot.paramMap.get('id');
    if (this.auctionId != null) {
      this.vendorBidByAuctionId(this.auctionId);
    }
    this.getMasterTablesSessionStorage();

    let userData = JSON.parse(sessionStorage.getItem('userData'));
    let dType = userData['dType'];
    let x = this.socketService.connectToServer();
    x.subscribe(
      (response) => {
        console.log(response);
        let data = JSON.parse(response.data);
        console.log(data);
        let auctionData = JSON.stringify(data.eventEntity);
        let finalActionType;
        if (auctionData != null) {
          let firstSplit = auctionData.split('type=');
          let secondSplit = firstSplit[1];
          finalActionType = secondSplit[0];
          console.log('This is final auction type ', finalActionType);
        }

        let vendorRouteLink = '/v1/vendor-auction/listing/' + finalActionType;

        if (data.eventType == 'AUCTION_TIMER_INCREASE') {
          if (dType == 3) {
            this.showPopUpMsg(data.eventMessage);
            this.getAuctionById(this.auctionId);
            // Reload screen.
            window.location.reload();
          }
        } else if (data.eventType == 'AUCTION_COMPLETED') {
          if (dType == 3) {
            Swal.fire({
              icon: 'success',
              timer: 100000,
              title: 'Auction',
              html: data.eventMessage,
            });
            this.router.navigate([vendorRouteLink]);
          }
        } else if (data.eventType == 'NEW_BID_RANKING') {
          console.log('THis will log if new bid ranking notification ');
          if (dType == 3) {
            this.showPopUpMsg(data.eventMessage);
            this.vendorBidByAuctionId(this.auctionId);
          }
        } else {
          console.log('Nothing');
        }
      },
      (error) => {
        console.log(error);
      }
    );

  }

  getAuctionById(id) {
    this.vendorService.fetchAuctionById(id).subscribe(
      (response) => {
        console.log(response);
        if (response != null) {
          this.auctionSummary['description'] = response.description;
          this.auctionSummary['auctionNumber'] = response.number;
          this.auctionSummary['enquiryNumber'] = response.enquiry.number;
          this.auctionSummary['projectCode'] = response.enquiry.projectCode;
          this.auctionSummary['projectName'] = response.enquiry.projectName;
          this.auctionSummary['auctionType'] = response.type;
          this.auctionSummary['minimumDecrement'] = response.minimumDecrement;
          this.minDecrement = response.minimumDecrement;
          this.minimumDecrementUnit = response.minimumDecrementUnit;
          this.auctionSummary['startDate'] = new Date(response.auctionDate);
          this.auctionSummary['endDate'] = new Date(response.auctionEndTime);
          this.auctionSummary['auctionConductedBy'] =
            response.auctionConductedBy;
          this.auctionSummary['origin'] = response.enquiry.originPortName;
          this.auctionSummary['destination'] =
            response.enquiry.destinationPortName;
          this.auctionSummary['status'] = response.status;
          this.auctionSummary['basePrice'] = response.baseprice;
          this.totalVolume = response.enquiry.chargableWeight;
          this.auctionSummary['timerCountDownInSec'] =
            response.timerCountDownInSec;

          this.differenceInSeconds(
            this.auctionSummary.startDate,
            this.auctionSummary.endDate
          );
          let packagesDetails: Array<any> = response.enquiry.shipmentPackages;
          let containersDetails: Array<any> = response.enquiry.containers;
          let breakBulkDetails: Array<any> = response.enquiry.breakBulks;
          let termsConditions: Array<any> = response.terms;
          let documentList: Array<any> = response.artifacts;
          let modeOfShipment = response.enquiry.modeOfShipment;
          this.modeOfShipment = modeOfShipment;

          if (termsConditions.length >= 1) {
            this.auctionTerms = termsConditions;
          } else {
            console.log('No terms & conditions uploaded by client for Auction');
          }
          if (documentList.length >= 1) {
            documentList.forEach((document) => {
              let data = {
                label: document.label,
                content: document.content,
                contentType: document.contentType,
                name: document.name,
                artifactId: document.id,
              };
              this.auctionDocuments.push(data);
            });
          } else {
            console.log('No files uploaded by client for Auction');
          }
          if (this.updateBidBool == false) {
            this.auctionValue = response.baseprice;
            this.remainingAttempts = response.limitMaxattemptsPerVendor;
            if (response.limitMaxattemptsPerVendor == 0) {
              this.infiniteAttempts = true;
            } else {
              this.infiniteAttempts = false;
              this.maxAttempts = response.limitMaxattemptsPerVendor;
            }
          }

          // AIR
          if (modeOfShipment == 1) {
            this.air_mode_shipment = true;
            this.packageDetails = [];
            if (packagesDetails.length >= 1) {
              packagesDetails.forEach((element: any) => {
                let details = this.prePopulatePackageDetails(element);
                this.packageDetails.push(details);
              });
              this.calculateAirVolume();
            } else {
              console.log('No Package Details');
            }
          }
          // SEA
          else if (modeOfShipment == 2) {
            this.sea_mode_shipment = true;
            let clt = response.enquiry.containerLoadType;
            if (clt == 1) {
              this.containerBool = true;
              this.contianerDetails = [];
              containersDetails.forEach((element: any) => {
                this.contianerDetails.push(this.prePopulateContainers(element));
              });
              if (breakBulkDetails.length > 0) {
                this.breakBulkBool = true;
                this.breakBulkDetails = [];
                breakBulkDetails.forEach((element: any, index: number) => {
                  let details = this.prePopulateBreakBulk(element);
                  this.breakBulkDetails.push(details);
                  this.calculateBreakBulkWeight(index);
                });
              }
            } else if (clt == 2) {
              this.packageDetails = [];
              this.packageBool = true;
              packagesDetails.forEach((element: any) => {
                let details = this.prePopulatePackageShipmentDetails(element);
                this.packageDetails.push(details);
              });
              this.calculateTotalVolume();
            } else if (clt == 3) {
              this.containerBool = true;
              this.packageBool = true;
              this.packageDetails = [];
              this.contianerDetails = [];
              containersDetails.forEach((element: any) => {
                this.contianerDetails.push(this.prePopulateContainers(element));
              });
              packagesDetails.forEach((element: any) => {
                let details = this.prePopulatePackageShipmentDetails(element);
                this.packageDetails.push(details);
              });
              this.calculateTotalVolume();
            } else {
              this.breakBulkBool = true;
              this.breakBulkDetails = [];
              breakBulkDetails.forEach((element: any, index: number) => {
                let details = this.prePopulateBreakBulk(element);
                this.breakBulkDetails.push(details);
                this.calculateBreakBulkWeight(index);
              });
            }
          }
          // OTHER
          else {
            console.log('Other modeOfShipment');
          }
          // this.checkTermsAndConditionValue();
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  vendorBidByAuctionId(id) {
    this.vendorService.fetchVendorBidById(id).subscribe(
      (response) => {
        this.packageDetails = [];
        this.breakBulkDetails = [];
        this.contianerDetails = [];
        this.auctionDocuments = [];
        for (let index = 0; index < response.length; index++) {
          let bid = response[index];
          if (bid.id != null) {
            console.log(bid);
            this.updateBidBool = true;
            // this.isTermsAndConditionsAccepted = true;
            this.disableTermsCheckbox = true;
            this.auctionId = bid.auction.id;
            this.vendorBidId = bid.id;
            this.remainingAttempts = bid.remainingAttempts;
            this.auctionValue = bid.auctionValue;
            this.previousBidValue = this.auctionValue;
            if (bid.auction.limitMaxattemptsPerVendor == 0) {
              this.infiniteAttempts = true;
            } else {
              this.infiniteAttempts = false;
              this.maxAttempts = bid.auction.limitMaxattemptsPerVendor;
            }
            this.comments = bid.comments;
            this.participating = bid.participating;
            this.reason = bid.reason;
            this.vendorName = bid.vendor.name;
            this.vendorRank = bid.rank;
            if (this.participating == false) {
              this.notParticipatingBool = true;
              let checkbox = <HTMLInputElement>(
                document.getElementById('notParticipatingCheck')
              );
              checkbox.checked = true;
              let x = document.getElementById('reasonInput');
            }

            this.auctionSummary['description'] = bid.auction.description;
            this.auctionSummary['auctionNumber'] = bid.auction.number;
            this.auctionSummary['enquiryNumber'] = bid.auction.enquiry.number;
            this.auctionSummary['projectCode'] =
              bid.auction.enquiry.projectCode;
            this.auctionSummary['projectName'] =
              bid.auction.enquiry.projectName;
            this.auctionSummary['auctionType'] = bid.auction.type;
            this.auctionSummary['minimumDecrement'] =
              bid.auction.minimumDecrement;
            this.minDecrement = bid.auction.minimumDecrement;
            this.minimumDecrementUnit = bid.auction.minimumDecrementUnit;
            this.auctionSummary['auctionConductedBy'] =
              bid.auction.auctionConductedBy;
            this.auctionSummary['origin'] = bid.auction.enquiry.originPortName;
            this.auctionSummary['destination'] =
              bid.auction.enquiry.destinationPortName;
            this.auctionSummary['status'] = bid.auction.status;
            this.auctionSummary['basePrice'] = bid.auction.baseprice;
            this.auctionSummary['startDate'] = new Date(
              bid.auction.auctionDate
            );
            this.auctionSummary['endDate'] = new Date(
              bid.auction.auctionEndTime
            );

            this.differenceInSeconds(
              this.auctionSummary.startDate,
              this.auctionSummary.endDate
            );

            if (bid.auction.status == 'COMPLETE') {
              Swal.fire({
                title: 'Info',
                text: 'This Auction Is Completed',
                timer: 5000,
                icon: 'info',
              });
              this.router.navigate([
                '/v1/vendor-auction/listing/' + bid.auction.type,
              ]);
            }
            this.modeOfShipment = bid.auction.enquiry.modeOfShipment;
          }
          break;
        }
        this.getAuctionById(id);
      },
      (error) => {
        console.log(error);
        let _error = error.error;
        console.log(_error);
        this.getAuctionById(id);
      }
    );
  }

  prePopulatePackageDetails(form: any) {
    return {
      id: form.id,
      length: form.length,
      height: form.height,
      width: form.width,
      quantity: form.quantity,
      grossWeight: form.grossWeight,
      dimensionsUOM: form.dimensionsUOM,
      weightUOM: form.weightUOM,
      totalVolume: form.totalVolume,
    };
  }
  prePopulatePackageShipmentDetails(form: any) {
    let type = this.filterContainerType(form.type);
    return {
      id: form.id,
      length: form.length,
      height: form.height,
      width: form.width,
      quantity: form.quantity,
      grossWeight: form.grossWeight,
      dimensionsUOM: form.dimensionsUOM,
      weightUOM: form.weightUOM,
      totalVolume: form.totalVolume,
      type: type.value,
    };
  }
  prePopulateContainers(form: any) {
    let type = this.filterContainerType(form.type);
    return {
      id: form.id,
      quantity: form.quantity,
      size: form.size,
      type: type.value,
      uom: form.uom,
      weight: form.weight,
    };
  }
  prePopulateBreakBulk(form: any) {
    return {
      id: form.id,
      totalGrossWeight: form.totalGrossWeight,
      totalVolume: form.totalVolume,
      totalnetWeight: form.totalnetWeight,
    };
  }

  hitVendorBid() {
    if (this.updateBidBool == true) {
      this.updateBid();
    } else {
      this.createBid();
    }
  }

  createBid() {
    let bool = this.minimumDecrement();
    if (bool == false) {
      this.disableTermsCheckbox = true;
      if (this.infiniteAttempts == true) {
        this.editBidBool = false;
        let data = {
          auction: {
            id: this.auctionId,
          },
          comments: this.comments,
          isParticipating: this.participating,
          auctionValue: this.auctionValue.toFixed(2),
          reason: this.reason,
          remainingAttempts: this.remainingAttempts,
        };
        this.createVendorBid(data);
      } else {
        this.remainingAttempts = this.remainingAttempts - 1;
        this.editBidBool = false;
        let data = {
          auction: {
            id: this.auctionId,
          },
          comments: this.comments,
          isParticipating: this.participating,
          auctionValue: this.auctionValue.toFixed(2),
          reason: this.reason,
          remainingAttempts: this.remainingAttempts,
        };
        this.createVendorBid(data);
      }
    } else {
      this.getAuctionById(this.auctionId);
    }
  }

  createVendorBid(data) {
    this.vendorService.createVendorBid(data).subscribe(
      (response) => {
        console.log(response);
        this.updateBidBool = true;
        if (response.code == 201) {
          this.showSuccessUpMsg(response.message);
          this.vendorBidByAuctionId(this.auctionId);
        }
      },
      (error) => {
        console.log(error);
        if (error.error.errorCode == 412) {
          Swal.fire({
            titleText: 'Error',
            text: error.error.errorMessage,
            timer: 5000,
            icon: 'error',
          });
        } else {
          Swal.fire({
            titleText: 'Error',
            text: 'Something went wrong',
            timer: 5000,
            icon: 'error',
          });
        }
      }
    );
  }

  updateBid() {
    console.log(this.previousBidValue, this.auctionValue);
    if (this.previousBidValue < this.auctionValue) {
      Swal.fire({
        text: 'Cannot Increase BID Value',
        icon: 'warning',
        timer: 5000,
        title: 'Warning',
      });
      this.auctionValue = this.previousBidValue;
      this.editBidBool = true;
    } else {
      let bool = this.minimumDecrement();
      if (bool == false) {
        if (this.infiniteAttempts == true) {
          this.editBidBool = false;
          let data = {
            auction: {
              id: this.auctionId,
            },
            comments: this.comments,
            isParticipating: this.participating,
            auctionValue: this.auctionValue.toFixed(2),
            reason: this.reason,
            remainingAttempts: this.remainingAttempts,
          };
          this.previousBidValue = this.auctionValue;
          this.updateVendorBid(data);
        } else {
          if (this.remainingAttempts == 0) {
            Swal.fire({
              titleText: 'Warning',
              text: 'No Attempts remaining',
              timer: 5000,
              icon: 'warning',
            });
          } else {
            this.remainingAttempts = this.remainingAttempts - 1;
            this.editBidBool = false;
            let data = {
              auction: {
                id: this.auctionId,
              },
              comments: this.comments,
              isParticipating: this.participating,
              auctionValue: this.auctionValue.toFixed(2),
              reason: this.reason,
              remainingAttempts: this.remainingAttempts,
            };
            this.previousBidValue = this.auctionValue;
            this.updateVendorBid(data);
          }
        }
      } else {
        this.editBidBool = false;
        this.vendorBidByAuctionId(this.auctionId);
      }
    }
  }

  updateVendorBid(data) {
    this.vendorService.updateVendorBid(this.vendorBidId, data).subscribe(
      (response) => {
        console.log(response);
        if (response.code == 201) {
          this.showSuccessUpMsg(response.message);
        }
        this.vendorBidByAuctionId(this.auctionId);
      },
      (error) => {
        console.log(error);
        if (error.error.errorCode == 412) {
          Swal.fire({
            titleText: 'Error',
            text: error.error.errorMessage,
            timer: 5000,
            icon: 'error',
          });
        } else {
          Swal.fire({
            titleText: 'Error',
            text: 'Something went wrong',
            timer: 5000,
            icon: 'error',
          });
        }
        if (this.updateBidBool) {
          this.vendorBidByAuctionId(this.auctionId);
        } else {
          this.getAuctionById(this.auctionId);
        }
      }
    );
  }

  notParticipatingBid() {
    let data = {
      auction: {
        id: this.auctionId,
      },
      comments: this.comments,
      isParticipating: this.participating,
      auctionValue: this.auctionValue,
      reason: this.reason,
      remainingAttempts: this.remainingAttempts,
    };
    if (this.notParticipatingBool == true && this.vendorBidId != null) {
      console.log('UPDATE');
      this.updateVendorBid(data);
    } else {
      console.log('CREATE');
      this.createVendorBid(data);
    }
  }

  editAuction() {
    if (this.infiniteAttempts == true) {
      this.editBidBool = true;
    } else {
      if (this.remainingAttempts == 0) {
        Swal.fire({
          titleText: 'Warning',
          text: 'No Attempts remaining',
          timer: 5000,
          icon: 'warning',
        });
      } else {
        this.editBidBool = true;
      }
    }
  }

  cancelBid() {
    this.editBidBool = false;
  }

  notParticipatingCheckbox(event: any) {
    if (event.target.checked == true) {
      this.notParticipatingBool = true;
      this.notParticipatingButtonBool = true;
      this.participating = false;
    } else {
      this.notParticipatingBool = false;
      this.notParticipatingButtonBool = false;
      this.participating = true;
      this.reason = '';
    }
  }

  getMasterTablesSessionStorage() {
    // this.container_type = this.masterTableService.getcontainerTypesSessionStorage();
    this.containerTypeList();
  }

  containerTypeList() {
    this.masterTableService.getContainerTypes().subscribe(
      (response) => {
        if (response) {
          this.container_type = response;
        }
      },
      (error) => {
        error;
      }
    );
  }

  filterContainerType(type: any) {
    return this.container_type.find((item) => {
      return item.id === type;
    });
  }

  downloadFileLocal(item) {
    if (item.artifactId != null && item.label != null) {
      this.dataURItoBlob(item);
    }
  }

  dataURItoBlob(data) {
    const linkSource = data.content;
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = data.name;
    downloadLink.click();
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove('sidebar-active');
  }

  calculateAirVolume() {
    this.totalVolumeWeightAIR = 0.0;
    for (let index = 0; index < this.packageDetails.length; index++) {
      const _package = this.packageDetails[index];
      let length = _package.length;
      let height = _package.height;
      let width = _package.width;
      let quantity = _package.quantity;
      let dimensionsUOM = _package.dimensionsUOM;
      let totalVolume = (length * width * height * quantity) / 6000;
      let convert = this.convertdimensionsUOMtoCm(dimensionsUOM, totalVolume);
      _package.totalVolume = convert;
      this.totalVolumeWeightAIR =
        _package.totalVolume + this.totalVolumeWeightAIR;
    }
    this.convertToMTAIR();
  }

  convertToMTAIR() {
    this.compareWeightUomAir();
    this.calculateWeightAir();
    this.compareWeightVolumeAir();
  }

  compareWeightUomAir() {
    let comparison = '';
    for (let index = 0; index < this.packageDetails.length; index++) {
      const _package = this.packageDetails[index];
      let weightUOM = _package.weightUOM;
      let latest = weightUOM;
      if (comparison.length > 0) {
        switch (latest) {
          case 'LBS':
            if (comparison == 'KG') {
              comparison = 'KG';
            } else {
              comparison = latest;
            }
            break;
          case 'KG':
            if (comparison == 'KG') {
              comparison = 'KG';
            } else {
              comparison = latest;
            }
            break;
        }
      } else {
        comparison = latest;
      }
    }
    this.finalAirWeightUom = comparison;
  }

  calculateWeightAir() {
    this.totalGrossWeightAIR = 0.0;
    for (let index = 0; index < this.packageDetails.length; index++) {
      const _package = this.packageDetails[index];
      let weightUOM = _package.weightUOM;
      let weight = _package.grossWeight;
      if (this.finalAirWeightUom == 'KG') {
        if (weightUOM == 'LBS') {
          let _weight = this.convertLBStoKG(weight);
          this.totalGrossWeightAIR = _weight + this.totalGrossWeightAIR;
        } else {
          this.totalGrossWeightAIR = weight + this.totalGrossWeightAIR;
        }
      } else if (this.finalAirWeightUom == 'LBS') {
        this.totalGrossWeightAIR = weight + this.totalGrossWeightAIR;
      }
    }
  }

  compareWeightVolumeAir() {
    let chargeableWeight: number;
    let metricTon: number = 0.0;
    for (let index = 0; index < this.packageDetails.length; index++) {
      let _package = this.packageDetails[index];
      if (this.finalAirWeightUom == 'KG') {
        if (_package.weightUOM == 'LBS') {
          let lbs = this.convertLBStoMT(_package.grossWeight);
          metricTon = lbs + metricTon;
        } else {
          let kg = this.convertKGtoMT(_package.grossWeight);
          metricTon = kg + metricTon;
        }
      } else {
        let lbs = this.convertLBStoMT(_package.grossWeight);
        metricTon = lbs + metricTon;
      }
    }
    if (metricTon > this.totalVolumeWeightAIR) {
      this.finalAirWeightBool = true;
      chargeableWeight = metricTon;
    } else {
      this.finalAirWeightBool = false;
      chargeableWeight = this.totalVolumeWeightAIR;
    }
    // ToDo
    // this.enquiryForm.get('chargableWeight').patchValue(chargeableWeight);
    return chargeableWeight;
  }

  convertKGtoLBS(weight) {
    let _weight = weight * 2.205;
    return parseFloat(_weight.toFixed(4));
  }

  convertKGtoMT(weight) {
    let _weight = weight / 1000;
    return parseFloat(_weight.toFixed(4));
  }

  convertLBStoKG(weight) {
    let _weight = weight / 2.205;
    return parseFloat(_weight.toFixed(4));
  }

  convertLBStoMT(weight) {
    let _weight = weight / 2205;
    return parseFloat(_weight.toFixed(4));
  }

  convertdimensionsUOMtoCm(dimensionsUOM, totalVolume) {
    let volume: number = 0.0;
    if (dimensionsUOM == 'MTR') {
      // 100.00
      volume = totalVolume * 100;
    } else if (dimensionsUOM == 'MM') {
      // 10.00 need to make some changes here.
      volume = totalVolume / 10.0;
    } else if (dimensionsUOM == 'IN') {
      // 2.54
      volume = totalVolume * 2.54;
    } else if (dimensionsUOM == 'FT') {
      // 30.48
      volume = totalVolume * 30.48;
    } else {
      // cm
      volume = totalVolume;
    }
    return parseFloat(volume.toFixed(4));
  }

  calculateBreakBulkWeight(index: number) {
    let break_bulk = this.breakBulkDetails[index];
    this.totalBreakBulkVolume = break_bulk.totalVolume;
    this.totalBreakBulkWeight = this.convertweightUOMtoMT(
      break_bulk.totalGrossWeightUOM,
      break_bulk.totalGrossWeight
    );
    if (this.totalBreakBulkVolume > this.totalBreakBulkWeight) {
      console.log(this.totalBreakBulkVolume);
      //   this._detail.get('chargableWeight').patchValue(this.totalBreakBulkVolume);
    } else {
      console.log(this.totalBreakBulkWeight);
      //   this._detail.get('chargableWeight').patchValue(this.totalBreakBulkWeight);
    }
  }

  convertweightUOMtoMT(weightUOM: string, weight: number) {
    let _weight: number = 0.0;
    if (weightUOM == 'KG') {
      _weight = weight / 1000.0;
    } else if (weightUOM == 'LBS') {
      _weight = weight / 2204.62;
    } else {
      _weight = weight;
    }
    return parseFloat(_weight.toFixed(3));
  }

  calculateTotalVolume() {
    this.totalVolumeWeightLCL = 0.0;
    for (let index = 0; index < this.packageDetails.length; index++) {
      const _package = this.packageDetails[index];
      let quantity = _package.quantity;
      let length = _package.length;
      let height = _package.height;
      let width = _package.width;
      let dimensionsUOM = _package.dimensionsUOM;
      let totalVolume = length * width * height * quantity;
      let convert = this.convertdimensionsUOMtoMtr(dimensionsUOM, totalVolume);
      _package.totalVolume = convert;
      this.totalVolumeWeightLCL = convert + this.totalVolumeWeightLCL;
    }
    this.convertToMTsea();
  }

  // Comman for air/sea(ShipmentPackages).
  convertdimensionsUOMtoMtr(dimensionsUOM, totalVolume) {
    let volume: number = 0.0;
    if (dimensionsUOM == 'CM') {
      // 100.00
      volume = totalVolume / 100.0;
    } else if (dimensionsUOM == 'MM') {
      // 1000.00
      volume = totalVolume / 1000.0;
    } else if (dimensionsUOM == 'IN') {
      // 39.37
      volume = totalVolume / 39.37;
    } else if (dimensionsUOM == 'FT') {
      // 3.2808
      volume = totalVolume / 3.2808;
    } else {
      // mtr
      volume = totalVolume;
    }
    return parseFloat(volume.toFixed(4));
  }

  convertToMTsea() {
    this.compareWeightUOMSea();
    this.calculateWeightSea();
    this.compareWeightVolume();
  }

  compareWeightUOMSea() {
    let comparison = '';
    for (let index = 0; index < this.packageDetails.length; index++) {
      const _package = this.packageDetails[index];
      let weightUOM = _package.weightUOM;
      let latest = weightUOM;
      if (comparison.length > 0) {
        switch (latest) {
          case 'LBS':
            if (comparison == 'KG') {
              comparison = 'KG';
            } else if (comparison == 'MT') {
              comparison = 'MT';
            } else {
              comparison = latest;
            }
            break;
          case 'KG':
            if (comparison == 'MT') {
              comparison = 'MT';
            } else if (comparison == 'LBS') {
              comparison = 'KG';
            } else {
              comparison = latest;
            }
            break;
          case 'MT':
            comparison = latest;
            break;
        }
      } else {
        comparison = latest;
      }
    }
    this.finalSeaWeightUom = comparison;
  }

  calculateWeightSea() {
    this.totalGrossWeightLCL = 0.0;
    for (let index = 0; index < this.packageDetails.length; index++) {
      const _package = this.packageDetails[index];
      let weightUOM = _package.weightUOM;
      let weight = _package.grossWeight;
      if (this.finalSeaWeightUom == 'MT') {
        if (weightUOM == 'KG') {
          let kg = this.convertKGtoMT(weight);
          this.totalGrossWeightLCL = kg + this.totalGrossWeightLCL;
        } else if (weightUOM == 'LBS') {
          let lbs = this.convertLBStoMT(weight);
          this.totalGrossWeightLCL = lbs + this.totalGrossWeightLCL;
        } else {
          this.totalGrossWeightLCL = weight + this.totalGrossWeightLCL;
        }
      } else if (this.finalSeaWeightUom == 'KG') {
        if (weightUOM == 'LBS') {
          let lbs = this.convertLBStoKG(weight);
          this.totalGrossWeightLCL = lbs + this.totalGrossWeightLCL;
        } else {
          this.totalGrossWeightLCL = weight + this.totalGrossWeightLCL;
        }
      } else {
        this.totalGrossWeightLCL = weight + this.totalGrossWeightLCL;
      }
    }
  }

  compareWeightVolume() {
    let chargeableWeight: number = 0.0;
    let metricTon: number = 0.0;
    for (let index = 0; index < this.packageDetails.length; index++) {
      let _package = this.packageDetails[index];
      if (this.finalSeaWeightUom == 'KG') {
        if (_package.weightUOM == 'LBS') {
          let lbs = this.convertLBStoMT(_package.grossWeight);
          metricTon = lbs + metricTon;
        } else {
          let kg = this.convertKGtoMT(_package.grossWeight);
          metricTon = kg + metricTon;
        }
      } else if (this.finalSeaWeightUom == 'MT') {
        if (_package.weightUOM == 'LBS') {
          let lbs = this.convertLBStoMT(_package.grossWeight);
          metricTon = lbs + metricTon;
        } else if (_package.weightUOM == 'KG') {
          let lbs = this.convertKGtoMT(_package.grossWeight);
          metricTon = lbs + metricTon;
        } else {
          metricTon = _package.grossWeight + metricTon;
        }
      } else {
        let lbs = this.convertLBStoMT(_package.grossWeight);
        metricTon = lbs + metricTon;
      }
    }
    if (metricTon > this.totalVolumeWeightLCL) {
      this.finalSeaWeightBool = true;
      chargeableWeight = metricTon;
    } else {
      this.finalSeaWeightBool = false;
      chargeableWeight = this.totalVolumeWeightLCL;
    }
    // this.enquiryForm.get('chargableWeight').patchValue(chargeableWeight);
    return chargeableWeight;
  }

  showPopUpMsg(message?): void {
    this.toastService.info(message, undefined, {
      enableHtml: true,
      closeButton: true,
      progressBar: true,
      timeOut: 5000,
    });
  }

  showSuccessUpMsg(message?): void {
    this.toastService.success(message, undefined, {
      enableHtml: true,
      closeButton: true,
      progressBar: true,
      timeOut: 5000,
    });
  }

  handleEventOnTime(event: any) {
    let actualStartSeconds = this.auctionSummary.timerCountDownInSec;
    this.startTimer(actualStartSeconds);
  }

  startTimer(seconds: number) {
    const timer$ = interval(1000);
    let start = this.auctionSummary.startDate;
    let end = this.auctionSummary.endDate;
    const sub = timer$.subscribe((sec) => {
      this.differenceInSeconds(start, end);
      this.curSec = sec;
      if (this.curSec === seconds) {
        sub.unsubscribe();
      }
    });
  }

  differenceInSeconds(start: Date, end: Date) {
    // Difference in start & end is 100% of progress bar.
    let current = new Date();
    let remainingSeconds = (end.getTime() - start.getTime()) / 1000;
    let currentSecondsLeft = (end.getTime() - current.getTime()) / 1000;
    let timer = currentSecondsLeft / remainingSeconds;
    this.remainingTimer = Math.round(timer * 100);
  }

  minimumDecrement() {
    let bool = false;
    if (this.minimumDecrementUnit == 'AMOUNT' && this.updateBidBool == true) {
      let number = Math.round(this.previousBidValue - this.auctionValue);
      if (number < this.minDecrement) {
        console.log('WRONG');
        Swal.fire({
          title: 'Warning',
          icon: 'warning',
          text: `Cannot submit bid, Minimum Decrement is ${this.minDecrement} ${this.minimumDecrementUnit}`,
          timer: 5000,
        });
        bool = true;
      }
    } else if (
      this.minimumDecrementUnit == 'AMOUNT' &&
      this.updateBidBool == false
    ) {
      let number = Math.round(
        this.auctionSummary.basePrice - this.auctionValue
      );
      if (number < this.minDecrement) {
        console.log('WRONG');
        Swal.fire({
          title: 'Warning',
          icon: 'warning',
          text: `Cannot submit bid, Minimum Decrement is ${this.minDecrement} ${this.minimumDecrementUnit}`,
          timer: 5000,
        });
        bool = true;
      }
    } else if (
      this.minimumDecrementUnit == 'PERCENT' &&
      this.updateBidBool == true
    ) {
      console.log('ITS PERCENT ' + this.minDecrement);
      let difference = Math.round(this.previousBidValue - this.auctionValue);
      let number = Math.round(
        this.auctionSummary.basePrice * (this.minDecrement / 100)
      );
      console.log(difference, number);
      if (difference < number) {
        Swal.fire({
          title: '',
          icon: 'warning',
          timer: 5000,
          text: `Cannot submit bid, Minimun Decrement is ${this.minDecrement} ${this.minimumDecrementUnit}`,
        });
        bool = true;
      }
    } else if (
      this.minimumDecrementUnit == 'PERCENT' &&
      this.updateBidBool == false
    ) {
      let difference = Math.round(
        this.auctionSummary.basePrice - this.auctionValue
      );
      let number = Math.round(
        this.auctionSummary.basePrice * (this.minDecrement / 100)
      );
      if (difference < number) {
        Swal.fire({
          title: '',
          icon: 'warning',
          timer: 5000,
          text: `Cannot submit bid, Minimun Decrement is ${this.minDecrement} ${this.minimumDecrementUnit}`,
        });
        bool = true;
      }
    }
    return bool;
  }

  // checkTermsAndConditionValue() {
  //   if (!this.isTermsAndConditionsAccepted) {
  //     Swal.fire({
  //       title: 'Auction Terms & Conditions',
  //       text: 'Please accept terms and conditions before submitting BID',
  //       timer: 5000,
  //       icon: 'info',
  //     }).then((res) => {
  //       console.log('this is response ', res);
  //       // this.router.navigate([], { fragment: "targetBlue" });
  //       if (res.isConfirmed == true) {
  //         window.scrollBy(0, 600)
  //       }
  //     });
  //   }
  // }
}
