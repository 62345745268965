<app-header></app-header>
<section class="banner text-center text-white d-flex align-items-center banner-full"
    style="background-image: url(assets/images/banner.jpg)">
    <div class="container position-relative">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <div class="heading-dual">
                    <h1>LogSolutec</h1>
                    <h2>Terms and Conditions</h2>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="about-wrapper text-dark py-8">
    <div class="container-fluid p-lg-start-end">
        <div class="row">
            <h3 class="text-center">
                <span class="text-success">LogSolutec</span>
                Terms and Conditions
            </h3>

            <div>
                <p>We are grateful for your trust in us. We demand the highest standards for secure transactions and
                    customer data protection at LogSolutec. Please read the following statement to understand more about
                    how we collect and exchange data. Our terms and conditions page is subject to change without notice
                    at any time. Please revisit this policy on a regular basis to ensure that you are aware of any
                    updates. By visiting this website, you agree to be bound by the terms and conditions set forth on
                    this page. If you do not agree, please do not use or access our website. By merely using the
                    website, you fully accept our use and publication of your personal information in accordance with
                    these terms and conditions.
                </p>
            </div>
            <br>

            <div>
                <h4 class="text-success">You agree to these terms by using the site</h4>
                <ul>
                    <li>By using the Site, you acknowledge that you have read, understood, and agree to be bound by
                        these terms of service.</li>
                    <li>You must not use the site if you do not agree to these terms.</li>
                    <li>For future reference, we recommend that you print or keep a copy of these terms.</li>
                    <li>You accept that by opening a vendor/carrier/freight forwarder account or talking with our team
                        members about offering your services, we may contact you with freight questions and logistics
                        requests, as well as listing you in our logistics provider directories or include you in our
                        networks.</li>
                </ul>
            </div>

            <br>

            <div>
                <h4 class="text-success">We reserve the right to make modifications to these terms</h4>
                <p>We may make changes to these terms at any time. Please review these Terms each time you want to use
                    the site to ensure you understand the terms in effect at the time.</p>
            </div>
            <br>

            <div>
                <h4 class="text-success">We reserve the right to make modifications to the website</h4>
                <p>From time to time, we may update and amend the site to reflect changes in our products, user demands,
                    and company goals.</p>
            </div>
            <br>

            <div>
                <h4 class="text-success">Please Note</h4>
                <p>You are responsible for making sure that anyone who uses your internet connection to access the Site
                    is aware of these Terms of Service and other relevant terms and conditions, and that they follow
                    them.</p>
            </div>

            <br>

            <div>
                <h4 class="text-success">Do not depend on the information provided on this website</h4>
                <p>The Site's material is solely intended to give general information. It is not intended to be taken as
                    authoritative advice. Before taking or abstaining from taking any action based on the material on
                    the site, you should get professional or specialised advice. </p>
            </div>

            <br>

            <div>
                <h4 class="text-success">We are not responsible for the content of any websites to which we provide
                    links</h4>
                <p>Where the Site provides connections to third-party sites and resources, these links are provided only
                    for your convenience. Such links should not be construed as an endorsement by us of the linked
                    websites or any information obtained from them.</p>
                <p>We have no control over the content of those sites or resources, and we accept no responsibility for
                    any content or loss incurred as a result of visiting them.</p>
            </div>

            <br>

            <div>
                <h4 class="text-success">Our liability for any loss or harm you may incur is as follows: </h4>
                <p>Where it would be illegal to do so, we do not exclude or restrict our obligation to you in any
                    manner. This covers death or physical injury caused by our carelessness or that of our employees,
                    agents, or subcontractors, as well as fraud or fraudulent misrepresentation.</p>
                <p>Responsibility arising as a consequence of the provision of any new services to you will be subject
                    to different restrictions and exclusions of liability, which will be detailed in the applicable
                    Membership Agreement. </p>
                <p>All implicit conditions, warranties, representations, and other terms that may apply to the Site or
                    any of its content are thus disclaimed.
                </p>
                <p>We will not be liable to you for any loss or damage arising out of or in connection with:</p>
                <ul>
                    <li>use of the Site or inability to utilise it;</li>
                    <li>profits, sales, business, or income are all lost;</li>
                    <li>disruption of business;</li>
                    <li>a reduction in projected savings</li>
                    <li>a loss of commercial potential, reputation, or goodwill; or</li>
                    <li>any loss or damage that is caused indirectly or as a result of the loss or damage</li>
                </ul>
            </div>

            <br>

            <div>
                <h4 class="text-success">Dispute Resolution and Governing Law</h4>
                <p>The Republic of India's laws shall govern these Terms of Service, without regard to any
                    considerations that may provide for the application of the law of another country. Any dispute or
                    claim arising out of or in connection with these Terms of Service will be resolved by binding
                    arbitration in accordance with the Indian Arbitration and Conciliation Act, 1996. The solitary
                    arbitrator's ruling is final and non-appealable. Regardless of the above, we may seek injunctive or
                    other equitable action in any court of competent jurisdiction to defend our rights. The Jurisdiction
                    of Pune will be taken into account for the dispute resolution and governing law.</p>
            </div>

            <br>

            <div>
                <h4 class="text-success">Force Majeure</h4>
                <p>We will not be liable for any losses, damages, delays, wrongful or missed deliveries, or
                    nonperformance, in whole or in part, of our obligations under the Agreement, resulting from events
                    beyond our or our subcontractors' control, including but not limited to:</p>
                <ul>
                    <li>Acts of God and/or nature, including flood, earthquake, storm, hurricane, pandemic, power
                        outage, or other natural disaster</li>
                    <li>War, hijacking, robbery, theft, or terrorist activities; Incidents or deterrents;</li>
                    <li>Riots or civil disturbances</li>

                    <li>Acts, breaches of contract, or omissions by you, Shipper, Consignee, or anyone else with an
                        interest in the shipment;</li>
                    <li>Acts by any government or any agency or subdivision thereof, including denial or cancellation of
                        any import/export or other necessary licence;</li>
                    <li>or Strikes, lockouts, or other labour conflicts</li>
                </ul>
            </div>

            <br>

            <div>
                <h4 class="text-success">Liability Limitation</h4>
                <p>You understand and agree that LogSolutec its affiliates and subsidiaries, and their respective
                    officers, directors, agents, co-branders or other partners, and employees are not liable for any
                    direct, indirect, incidental, special, consequential, or exemplary damages, including but not
                    limited to damages for loss of profits, goodwill, use, data, or other intangible losses (even if we
                    have been advised of the possibility of such damages).</p>
                <p>The termination of your engagement with us shall be your single and exclusive recourse for any
                    disagreement about the LogSolutec Platform, with us, or with any of the individuals listed above. In
                    no circumstances may our total liability to you, whether direct or indirect, in connection with any
                    Service exceed the prices paid by you for the services in issue.</p>
                <p>We will not be liable for any claims that may emerge as a result of our agreement, and your sole
                    remedy for any losses you may incur will be through the relevant insurance policy you may have
                    purchased for the goods consignment. You agree to hold us harmless from any claims arising from your
                    contacts with your customers or their conduct as a result of your usage of the LogSolutec Platform.
                    The LogSolutec Platform does not carry out any independent verification and assumes no
                    responsibility as a result.</p>
            </div>

            <br>

            <div>
                <h4 class="text-success">Disclaimer Warranties</h4>
                <p>We do not guarantee that: the LogSolutec Platform will meet your specific requirements; the
                    LogSolutec Platform's services will be uninterrupted, timely, secure, or error-free, or that they
                    will be available to you at all times; the LogSolutec Platform's services will be accurate or
                    reliable; the quality of any products, services, information, or other material purchased or
                    obtained by you through the LogSolutec Platform will meet your expectations; and any errors on the
                    LogSolutec Platform will be corrected.</p>
            </div>

            <br>

            <div>
                <h4 class="text-success">Intellectual Property Rights (IP)</h4>
                <p>You acknowledge and agree that all intellectual property rights (including applicable copyrights,
                    trademarks, and other proprietary rights) in the LogSolutec Platform, as well as our websites,
                    emails, platforms, mobile sites, applications, and other products and/or services, are owned by us
                    and our licensors. By virtue of these Terms of Service, we are not providing you any licence under
                    any of those intellectual property rights. You also understand and agree that we own and control the
                    "look and feel" and content of our tools, widgets, buttons, programmes, and other similar items. All
                    rights not expressly granted to you under these Terms of Service are reserved by us.
                </p>
                <p>Our intellectual property includes our trademarks, logos, service marks, pictures, trade names,
                    designs, page headers, button icons, scripts, and other distinctive branding aspects, which may not
                    be duplicated, imitated, or utilised in whole or in part. When you use the LogSolutec Platform, you
                    undertake not to infringe on any intellectual property rights and to indemnify us against any claims
                    that may arise in this respect.
                </p>
                <p>The LogSolutec Platform is the result of a significant amount of work and effort, and it incorporates
                    our own or licenced intellectual property. You also promise not to infringe on our intellectual
                    property rights by attempting to reverse-engineer or uncover the LogSolutec Platform's source code
                    (by any means). You acknowledge that any breach of these limits will result in us suffering
                    irreparable harm.
                </p>
            </div>

            <br>

            <div>
                <h4 class="text-success">Prohibited Behaviors</h4>
                <p>You agree not to use the LogSolutec Platform for any illegal, harmful, misleading, fraudulent, or
                    other malicious purposes, or to post, disseminate, or communicate any unlawful, defamatory, obscene,
                    lewd, excessively violent, harassing, sexually explicit, or otherwise objectionable subject matter,
                    or to transmit material that contains viruses or other malicious code, or that infringes or may
                    infringe on another's intellectual property or other rights. You agree not to use the Services to
                    send "junk mail," "spam," "chain letters," "phishing," or other unsolicited mass email distribution.
                </p>
                <p>You agree to hold us harmless from any responsibility that may come as a result of your use of the
                    LogSolutec Platform. Regardless of these limits, information shared by other LogSolutec Platform
                    users may contain erroneous, inappropriate, offensive, or sexually explicit material, goods, or
                    services, for which we bear no responsibility or liability. We may delete any Content or suspend any
                    account associated with it that we have reasonable grounds to believe violates the Terms of Service,
                    any applicable law, or is offensive or illegal, or violates the rights, harms, or threatens the
                    safety of any person, without assuming any obligation to do so.</p>
                <p>We may delete any Content or suspend any account associated with it that we have reasonable grounds
                    to believe violates the Terms of Service, any applicable law, or is offensive or illegal, or
                    violates the rights, harms, or threatens the safety of any person, without assuming any obligation
                    to do so.</p>
            </div>

            <br>

            <div>
                <h4 class="text-success">General</h4>
                <p>Our omission to execute or enforce any right or provision of these Terms of Service does not imply
                    that we have waived that right or provision. If a court of competent jurisdiction rules that any
                    provision of these Terms of Service is unlawful, the rest of the Terms of Service will continue in
                    full force and effect, and we will attempt to give effect to the purpose expressed in the provision.
                    Without your approval or notification, we may assign our agreement according to the Terms of Service
                    in whole or in part at our sole discretion. Nothing in this Agreement prevents us from adhering to
                    all applicable laws and regulations.</p>
            </div>

            <br>

            <div>
                <h4 class="text-success">Miscellaneous</h4>
                <p>This Agreement does not form any partnership, joint venture, employer-employee, agency, or
                    franchisor-franchisee relationship between you and Company, and shall not be understood to do so. If
                    any term of this Agreement is found to be invalid, void, or unenforceable for any reason, that
                    provision will be deemed severable from the rest of the Agreement, and its legality and
                    enforceability will not be affected. Without our explicit prior written agreement, you may not
                    assign, transfer, or sublicense any or all of your rights or responsibilities under this Agreement.
                    We have the freedom to assign, transfer, or sublicense any or all of our rights or obligations under
                    this Agreement.</p>
                <p>No waiver of any breach or default thereof by any party will be deemed a waiver of any subsequent or
                    succeeding breach or default. Any header, caption, or section title used in this document is for
                    informational purposes only and does not define or explain any section or provision. Unless
                    otherwise indicated, all terms defined in the single should have the same meaning when used in the
                    plural. This Agreement, including any terms and conditions incorporated herein, constitutes the
                    entire agreement between you and Company relating to the subject matter hereof, and supersedes any
                    and all prior or contemporaneous written or oral agreements or understandings between you and
                    Company relating to such subject matter.</p>
                <p>Notifications to you (including notices of changes to this Agreement) may be sent to you via the
                    Site, e-mail (including via links in each case), or ordinary mail. A printed version of this
                    Agreement and any notice given in electronic form, without limitation, shall be admissible in
                    judicial or administrative proceedings based on or relating to this Agreement to the same extent and
                    under the same conditions as other business documents and records originally generated and
                    maintained in printed form. Company shall not be liable for any failure to perform its obligations
                    due to circumstances beyond its control.</p>
            </div>

            <br>

            <div>
                <h4 class="text-success">Requests for Information and Suggestions</h4>
                <p>If you have any questions, ideas, or need to contact us in accordance with our Terms of Service, or
                    if you want to file a complaint, please contact us on <span> <a href="">contact@logsolutec.com</a>
                    </span></p>
            </div>
        </div>
    </div>
</section>