<h2 mat-dialog-title>Shipping Line: {{data?.currentQuote?.lineName}}</h2>
<mat-dialog-content class="mat-typography">

    <div class="table-responsive">

        <table class="text-center table table-striped table-bordered table-sm" cellspacing="0" width="100%">

            <thead>
                <tr>
                    <th> </th>
                    <th>Current Quote</th>
                    <th>Previous Quote</th>
                </tr>
            </thead>
            <tbody>

                <!--Grand Total-->
                <tr>
                    <td class="font-weight-bold sticky-col first-col">
                        <label for="">Grand Total</label>
                    </td>
                    <td>{{data?.currentQuote?.amount}}</td>
                    <td>{{data?.historyQuote?.amount}}</td>
                </tr>

                <!--Routing-->
                <tr>
                    <td class="font-weight-bold sticky-col first-col">
                        <label for="">Routing</label>
                    </td>
                    <td>{{data?.currentQuote?.routing}}</td>
                    <td>{{data?.historyQuote?.routing}}</td>
                </tr>

                <!--Frequency-->
                <tr>
                    <td class="font-weight-bold sticky-col first-col">
                        <label for="">Frequency</label>
                    </td>
                    <td>{{data?.currentQuote?.frequency}}</td>
                    <td>{{data?.historyQuote?.frequency}}</td>
                </tr>

                <!--Valid Till-->
                <tr>
                    <td class="font-weight-bold sticky-col first-col">
                        <label for="">Valid Till</label>
                    </td>
                    <td>{{data?.currentQuote?.quoteValidTill | date: 'dd-MM-YYYY'}}</td>
                    <td>{{data?.historyQuote?.quoteValidTill | date: 'dd-MM-YYYY'}}</td>
                </tr>

                <!--Vessel Schedule-->
                <tr *ngIf="seaBool">
                    <td class="font-weight-bold sticky-col first-col">
                        <label for="">Vessel Schedule</label>
                    </td>
                    <td>{{data?.currentQuote?.vesselSchedule | date: 'dd-MM-YYYY'}}</td>
                    <td>{{data?.historyQuote?.vesselSchedule | date: 'dd-MM-YYYY'}}</td>
                </tr>

                <!--Transit Time-->
                <tr>
                    <td class="font-weight-bold sticky-col first-col">
                        <label for="">Transit Time</label>
                    </td>
                    <td>{{data?.currentQuote?.transitTime}}</td>
                    <td>{{data?.historyQuote?.transitTime}}</td>
                </tr>

            </tbody>
        </table>
    </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="btn-danger" mat-button mat-flat-button color="warn" mat-dialog-close>Close</button>
</mat-dialog-actions>