<header class="box-absolute d-flex align-items-center topline text-white">
    <app-sidebar></app-sidebar>
    <div class="page-title-center mt-3">
        <div class="text-center">
            <h1>Auction Summary</h1>
        </div>
    </div>
    <div class="breadcrumb-div">
        <ul class="breadcrumb p-0 m-0">
            <li class="breadcrumb-item 3" id="0"><a href="dashboard.html">Dashboard</a></li>
            <li class="breadcrumb-item 3" id="1"><a href="request-for-quotation.html">Auction Listing</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0)">Auction Comparison</a></li>
        </ul>
    </div>
</header>
<!--header end-->
<section class="banner text-center text-white d-flex" id="bnner">
    <div class="container position-relative">
        <div class="row justify-content-center">
        </div>
    </div>
</section>

<section class="request-demo-wrapper pb-10">
    <div class="container shadow bg-white">
        <div class="row">
            <div class="col-lg-12">
                <div class="box">
                    <form *ngIf="auctionObj != null">
                        <div class="row">
                            <div class="col-md-6 form-group form-group-inline">
                                <label class="form-label" for="auction-number">Auction Number :</label>
                                <input class="form-control" value={{auctionObj?.number}} type="text" id="auction-number"
                                    readonly="readonly" />
                            </div>
                            <div class="col-md-6 form-group form-group-inline">
                                <label class="form-label" for="auction-description">Auction Title :</label>
                                <input class="form-control" value={{auctionObj?.description}} type="text"
                                    id="auction-description" readonly="readonly" />
                            </div>
                            <div class="col-md-6 form-group form-group-inline">
                                <label class="form-label" for="auction-date-time">Auction Date &amp; Time
                                    :</label>
                                <input class="form-control"
                                    value="{{ auctionObj?.auctionDate | date: 'dd-MM-YYYY HH:mm:ss' }}" type="datetime"
                                    id="auction-date-time" readonly="readonly" />
                            </div>
                            <div class="col-md-6 form-group form-group-inline">
                                <label class="form-label" for="auction-end-time">Auction End Time
                                    :</label>
                                <input class="form-control"
                                    value="{{ auctionObj?.auctionEndTime | date: 'dd-MM-YYYY HH:mm:ss' }}"
                                    type="datetime" id="auction-end-time" readonly="readonly" />
                            </div>

                            <div class="col-md-6 form-group form-group-inline">
                                <label class="form-label" for="auction-baseprice">Base Price :</label>
                                <input class="form-control" value={{auctionObj?.baseprice}} type="text"
                                    id="auction-baseprice" readonly="readonly" />
                            </div>

                            <div class="col-md-6 form-group form-group-inline">
                                <label class="form-label" for="auction-baseprice">Target Price :</label>
                                <input class="form-control" value={{auctionObj?.targetPrice}} type="text"
                                    id="auction-baseprice" readonly="readonly" />
                            </div>

                            <div class="col-md-6 form-group form-group-inline">
                                <label class="form-label" for="currency">Currency :</label>
                                <input class="form-control" value={{auctionObj?.currency.currency}} type="text"
                                    id="currency" readonly="readonly" />
                            </div>

                            <div class="col-md-6 form-group form-group-inline">
                                <label class="form-label" for="auction-durationinMinutes">Duration :</label>
                                <input class="form-control"
                                    value="{{auctionObj?.durationinMinutes + ' ' + auctionObj?.auctionDurationUnit}}"
                                    type="text" id="auction-durationinMinutes" readonly="readonly" />
                            </div>

                            <div *ngIf="auctionObj?.extendTimeBool" class="col-md-6 form-group form-group-inline">
                                <label class="form-label">Time Extension :</label>
                                <input class="form-control" type="text" readonly="readonly" value="Yes">
                            </div>

                            <div *ngIf="auctionObj?.extendTimeBool" class="col-md-6 form-group form-group-inline">
                                <label class="form-label">Time Extension Limit :</label>
                                <input class="form-control" type="text" readonly="readonly"
                                    value="{{ auctionObj?.auctionTimeExtensionLimit }}">
                            </div>

                            <div *ngIf="auctionObj?.extendTimeBool" class="col-md-6 form-group form-group-inline">
                                <label class="form-label">Extended Time :</label>
                                <input class="form-control" type="text" readonly="readonly"
                                    value="{{ auctionObj?.extendedTime +  ' Minutes' }}">
                            </div>

                            <div *ngIf="auctionObj?.totalCountAuctionExtended"
                                class="col-md-6 form-group form-group-inline">
                                <label class="form-label">Total Extended Count :</label>
                                <input class="form-control" type="text" readonly="readonly"
                                    value="{{ auctionObj?.totalCountAuctionExtended }}">
                            </div>

                            <div *ngIf="auctionObj?.extendTimeBool" class="col-md-6 form-group form-group-inline">
                                <label class="form-label">Submit Bid In Last Minutes:</label>
                                <input class="form-control" type="text" readonly="readonly"
                                    value="{{ auctionObj?.bidSubmitDurationInLastMinutes }}">
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-borderless" mat-table [dataSource]="dataSource" matSort>
                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef class="text-white">Forwarder
                                        Name</th>
                                    <td mat-cell *matCellDef="let element">{{element.vendor.name}}</td>
                                </ng-container>

                                <ng-container matColumnDef="bidValue">
                                    <th mat-header-cell *matHeaderCellDef class="text-white">Bid
                                        Value</th>
                                    <td mat-cell *matCellDef="let element">{{element.auctionValue}}</td>
                                </ng-container>

                                <ng-container matColumnDef="rank">
                                    <th mat-header-cell *matHeaderCellDef class="text-white">Rank</th>
                                    <td mat-cell *matCellDef="let element;let i=index">L{{ i+1 }}</td>
                                </ng-container>

                                <ng-container matColumnDef="var-amount">
                                    <th mat-header-cell *matHeaderCellDef class="text-white">Savings(Amount)</th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ (auctionObj.baseprice - element.auctionValue) }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="saving">
                                    <th mat-header-cell *matHeaderCellDef class="text-white">Saving in (%)</th>
                                    <td mat-cell *matCellDef="let element;let i=index">
                                        {{ ((auctionObj.baseprice - element.auctionValue) * 100) / auctionObj.baseprice
                                        | number: '2.2-2' }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="actions">
                                    <th mat-header-cell class="text-white" *matHeaderCellDef> Actions </th>
                                    <td mat-cell *matCellDef="let row; let i = index">
                                        <input type="checkbox" (change)="check($event, row.id, i)">
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displaycolumn" class="bg-dark text-left"></tr>
                                <tr mat-row *matRowDef="let row; columns: displaycolumn;"></tr>
                            </table>
                        </div>

                        <div class="row justify-content-center">
                            <div class="col-md-6 form-group pt-lg-3">
                                <button class="btn text-dark small btn-outline-success px-3" type="button"
                                    (click)="createServiceOrder()">CREATE
                                    SERVICE ORDER</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!--request end  -->