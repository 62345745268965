<div class="container">

    <div class="form-group" [formGroup]="increaseTimerForm">
        <label for="" class="form-label">Enter Time in minutes</label>
        <input type="number" class="form-control" formControlName="durationinMinutes">
    </div>
    
    <div class="form-group mt-5">
        <button class="btn-primary m-1" (click)="doAction()" >Increase</button>
        <button class="btn-danger m-1" (click)="closeDialog()" >Cancel</button>
    </div>
</div>

