<h1 mat-dialog-title><strong>{{action}}</strong></h1>
<div mat-dialog-content>
    <form [formGroup]="currencyForm" *ngIf="action != 'Delete'; else elseTemplate">
        <mat-form-field>
            <input placeholder="Currency" type="text" required aria-pressed="false" matInput formControlName="currency">
        </mat-form-field>

        <mat-form-field>
            <input placeholder="Currency Code" type="text" required aria-pressed="false" matInput formControlName="currencyCode">
        </mat-form-field>

        <mat-form-field>
            <input placeholder="Minor Unit" type="number" min="0" required aria-pressed="false" matInput formControlName="minorUnit">
        </mat-form-field>

        <mat-form-field>
            <input placeholder="Numeric Code" type="number" min="0" required aria-pressed="false" matInput formControlName="numericCode">
        </mat-form-field>
    </form>
    <ng-template #elseTemplate>
        Sure to delete <b>{{ local_data.currency }}</b>?
    </ng-template>
</div>
<div mat-dialog-actions>
    <button style="margin-right: 20px;" class="btn-primary" mat-button (click)="doAction()">{{action}}</button>
    <button class="btn-danger" mat-button (click)="closeDialog()" mat-flat-button color="warn">Cancel</button>
</div>