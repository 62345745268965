<!--header start-->
<header class="box-absolute d-flex align-items-center topline text-white">
    <app-sidebar></app-sidebar>
    <div class="page-title-center mt-3">
        <div class="text-center">
            <h1>{{ title }}</h1>
        </div>
    </div>
    <div class="breadcrumb-div mt-3">
        <ul class="breadcrumb p-0 m-0">
            <li class="breadcrumb-item 3" id="0"><a [routerLink]="['/v1/dashboard']">Dashboard</a></li>
            <li class="breadcrumb-item 3" id="1"><a>Listing</a></li>
            <li class="breadcrumb-item"><a>{{ title }}</a></li>
        </ul>
    </div>
</header>
<div (click)="hideSideBar()">
<!--header end-->
<section class="banner text-center text-white d-flex" id="bnner">
    <div class="container position-relative">
        <div class="row justify-content-center">
            <div class="col-lg-12">
            </div>
        </div>
    </div>
</section>
<!--request start-->
<section class="request-demo-wrapper pb-10">
    <div class="container shadow bg-white">
        <div class="row">
            <div class="col-lg-12">
                <div class="box">
                    <form>
                        <div
                            class="filter-wrap btn-group d-flex flex-wrap flex-md-nowrap justify-content-center gap-2 pb-3 pb-lg-4 px-3 px-lg-4">
                            <div class="input-group">
                                <input class="form-control rounded-3 px-5" type="text" matInput
                                    (keyup)="applyFilter($event)" placeholder="Search" #input>
                                <button class="input-group-btn px-3 end-0" type="button"><i class="fa fa-search"></i>
                                </button>
                            </div>
                            <button class="rounded-3 flex-shrink-0" type="button" (click)="createEnquiry()">
                                <i class="fa fa-plus me-2"></i><span>Generate New Enquiry</span>
                            </button>
                            <button class="rounded-3 flex-shrink-0" type="button" (click)="recentEnquiry()">
                                <i class="fa fa-filter me-2"></i><span>Recent Enquiries</span>
                            </button>
                            <button class="rounded-3 flex-shrink-0" type="button" (click)="dueSoonEnquiry()">
                                <i class="fa fa-filter me-2"></i><span> Due Soon</span>
                            </button>
                            <button class="rounded-3 flex-shrink-0" type="button" (click)="getAllEnquires()">
                                <i class="fa fa-solid fa-arrows-rotate"><span> Reset</span></i>
                            </button>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-borderless table-striped table-footer-dark table-head-dark"
                                mat-table [dataSource]="dataSource" matSort matSortDisableClear>
                                <ng-container matColumnDef="id">
                                    <th mat-header-cell class="text-white" *matHeaderCellDef mat-sort-header> RFQ Number
                                    </th>
                                    <td mat-cell *matCellDef="let row; let i = index"> {{ row.number || '----' }} </td>
                                </ng-container>
                                <ng-container matColumnDef="enquiryDate">
                                    <th mat-header-cell class="text-white" *matHeaderCellDef mat-sort-header> Enquiry
                                        Date </th>
                                    <td mat-cell *matCellDef="let row; let i = index"> {{ row.enquiryDate | date}} </td>
                                </ng-container>
                                <ng-container matColumnDef="modeOfShipment">
                                    <th class="text-white" mat-header-cell *matHeaderCellDef mat-sort-header> Mode of
                                        Shipment </th>
                                    <td mat-cell *matCellDef="let row">
                                        {{ row.modeOfShipmentLabel }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="description">
                                    <th class="text-white" mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Description
                                    </th>
                                    <td mat-cell *matCellDef="let row"> {{ row.description }} </td>
                                </ng-container>
                                <ng-container matColumnDef="lastSubmissionDate">
                                    <th class="text-white" mat-header-cell *matHeaderCellDef mat-sort-header> Due Date
                                    </th>
                                    <td mat-cell *matCellDef="let row">{{ row.lastSubmissionDate | date: 'dd-MM-YYYY HH:mm:ss' }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="quoteSubmitted">
                                    <th class="text-white" mat-header-cell *matHeaderCellDef> Quote Submitted </th>
                                    <td mat-cell *matCellDef="let row">{{ row.quoteCount }} / {{
                                        row.lenghtOfassignedVendors }}</td>
                                </ng-container>
                                <ng-container matColumnDef="actions">
                                    <th class="text-white" mat-header-cell *matHeaderCellDef> Actions</th>
                                    <td mat-cell *matCellDef="let row; index as i">
                                        <div class="row">
                                            <div class="col-lg-4">
                                                <input type="checkbox" (change)="compare($event, row.id, i)" />
                                            </div>
                                            <div class="col-lg-4">
                                                <i class="fa fa-eye" title="VIEW" [routerLink]="['/v1/client-rfq/view/', row.id]"></i>
                                            </div>
                                            <div *ngIf="row.quoteCount == 0 && row.status != 'AUCTION_CREATED'" class="col-lg-4">
                                                <button class="btn-sm" (click)="editEnquiry(row.id)">
                                                    <i class="fa fa-edit" title="EDIT"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>

                                <tr class="text-left bg-dark" mat-header-row *matHeaderRowDef="displayedColumns">
                                </tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                                <!-- Row shown when there is no matching data -->
                                <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell text-center text-danger" colspan="8">
                                        <div *ngIf="serchFilter">
                                            No data matching the filter "{{ input.value }}"
                                        </div>
                                        <div>
                                            <h5>Data is Not Available</h5>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                            <mat-paginator [pageSizeOptions]="[5, 10, 25]" [length]="enquiryLength" showFirstLastButtons
                                (page)="onPageChange($event)">
                            </mat-paginator>
                        </div>
                        <div class="row justify-content-center">
                            <div *ngIf="typeBool" class="col-md-4 form-group pt-lg-3">
                                <button class="btn text-dark text-small btn-outline-success"
                                    (click)="createReverseAuction()" type="button">REVERSE
                                    AUCTION</button>
                            </div>
                            <div class="col-md-4 form-group pt-lg-3">
                                <button class="btn text-dark text-small btn-outline-success" (click)="compareQuotes()"
                                    type="button">COMPARE
                                    QUOTES</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!--request end -->
</div>