import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SocketService } from '../helpers/socket.service';
@Component({
  selector: 'app-request-extension',
  templateUrl: './request-extension.component.html',
  styleUrls: ['./request-extension.component.css']
})
export class RequestExtensionComponent implements OnInit {

  public requestextensionform!: FormGroup;

  constructor(private fb: FormBuilder, private socketService: SocketService) { }
  
  ngOnInit(): void {
    this.requestextensionform = this.fb.group({
      subscriptionId: ['', Validators.required],
      subscriptionType: ['', Validators.required],
      licenceType:['', Validators.required],
      expirydate: ['', Validators.required],
    });

    // this.socketService.connectTOServerPopUpFuction();
  }

  get request() { return this.requestextensionform.controls; }


  onRequestExtension() {

  }
  onBuySubscription() {

  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

}
