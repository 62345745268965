import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { InvoiceService } from '../helpers/invoice.service';
import { SocketService } from '../helpers/socket.service';

@Component({
  selector: 'app-vendor-commercial-invoices',
  templateUrl: './vendor-commercial-invoices.component.html',
  styleUrls: ['./vendor-commercial-invoices.component.css']
})
export class VendorCommercialInvoicesComponent implements OnInit {

  displayedColumns: string[] = ['number', 'serviceOrder', 'portOfLoading', 'portOfDischarge', 'countryOfOriginOfGoods', 'countryOfFinalDestination', 'createdDate' ,'action'];
  dataSource = new MatTableDataSource();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  title = "Commercial Invoice List" 
  serchFilter: Boolean = false;
  nodata: Boolean = true;
  page: number = 0;
  size: number = 25;
  length: number;

  constructor(private invoiceService: InvoiceService, private socketService: SocketService) { }

  ngOnInit(): void {
    this.getAllInvoices();
    // this.socketService.connectTOServerPopUpFuction();
  }

  //get All Invoice List
  getAllInvoices(){
    this.invoiceService.getAllCommercialInvoice(this.page, this.size).subscribe(
      (response) => {
        console.log(response);
        if (response.content != null) {
          this.length = response.totalElements;
          this.dataSource = new MatTableDataSource(response.content);
          this.dataSource.sort = this.sort;
        }
      },
      (error) => {
        console.log(error)
      }
    )
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.nodata = false;
    this.serchFilter = true;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  //to hide sidebar
  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  onPageChange(event: PageEvent) {
    this.size = event.pageSize;
    this.page = event.pageIndex;
    this.getAllInvoices();
  }


  // Download commercial invoice.
  downloadPDF(id, type){
    console.log(id, type);
    this.invoiceService.downloadCommercialInvoice(id, type).subscribe(
      (response) => {
        console.log(response);
        if (response) {
          var file = new Blob([response], { type: 'application/pdf' });
          console.log(file)
          var fileURL = URL.createObjectURL(file);
          var a = document.createElement('a');
          a.href = fileURL;
          a.target = '_blank';
          a.download = `CommercialInvoice-${type}-${id}.pdf`
          document.body.appendChild(a);
          a.click();
        }
      },
      (error) => {
        console.log(error);
      }
    )
  }

}
