import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-no-permission-route',
  templateUrl: './no-permission-route.component.html',
  styleUrls: ['./no-permission-route.component.css']
})
export class NoPermissionRouteComponent implements OnInit {

  constructor(private location: Location) { }

  ngOnInit(): void {
  }

  goBack(){
    this.location.back()
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

}
