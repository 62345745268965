import { UserService } from './../helpers/user.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { DialogBoxComponent } from '../dialog-box/dialog-box.component';
import Swal from 'sweetalert2';
import { SocketService } from '../helpers/socket.service';

@Component({
  selector: 'app-user-permission-list',
  templateUrl: './user-permission-list.component.html',
  styleUrls: ['./user-permission-list.component.css']
})

export class UserPermissionListComponent implements OnInit {

  page: number = 0;
  size: number = 25;
  total: any
  permissionLength: number;
  permissionList: Array<any> = [];
  displayedColumns: string[] = ['srNo', 'name', 'permissionCategory'];
  // , 'createdBy', 'createdDate', 'updatedBy', 'updatedDate',
  dataSource !: MatTableDataSource<any>;
  nodata:Boolean = true;
  serchFilter:Boolean = false;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;

  constructor(public dialog: MatDialog, private userService: UserService, private socketService: SocketService) { }

  ds = this.permissionList;



  ngOnInit(): void {
    this.getAllpermission();

    // this.socketService.connectTOServerPopUpFuction();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.nodata = false;
    this.serchFilter = true;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDialog(action, obj) {
    obj.action = action;
    obj.component = "permission"
    const dialogRef = this.dialog.open(DialogBoxComponent, {
      width: '250px',
      data: obj
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.event == 'Add') {
        this.addRowData(result.data);
      } else if (result.event == 'Update') {
        this.updateRowData(result.data);
      } else if (result.event == 'Delete') {
        this.deleteRowData(result.data);
      }
    });
  }

  addRowData(row_obj) {
    this.addPermission(row_obj.name);
    setTimeout(() => {
      this.getAllpermission();
    }, 1000);
  }
  updateRowData(row_obj) {
    this.permissionList.filter((value, key) => {
      if (value.id == row_obj.id) {
        this.updatePermission(value.id, row_obj.name);
        value.name = row_obj.name;
        setTimeout(() => {
          this.getAllpermission();
        }, 500);
      }
      return true;
    });
  }
  deleteRowData(row_obj) {
    this.deletePermission(row_obj.id);
    setTimeout(() => {
      this.getAllpermission();
    }, 500);
  }

  getAllpermission() {
    this.userService.getAllPermissions(this.page, this.size).subscribe(
      (response) => {
        if (response.content != null) {
          console.log(response);
          
          this.dataSource = new MatTableDataSource(response.content);
          this.permissionList = response.content;
          this.total = response.totalElements
        }
        this.dataSource.sort = this.sort;
      },
      (error) => {
        console.log(error);
      },
      () => { },
    )
  }
  updatePermission(id: any, name: any) {
    const data = { "name": name };
    this.userService.updatePermission(id, data).subscribe((response) => {
      Swal.fire({
        titleText: "Success",
        text: response.message,
        timer: 5000,
        icon: "success"
      })
    },
      (error) => {
        console.log(error);
        Swal.fire({
          titleText: "Error",
          text: "Something went wrong",
          timer: 5000,
          icon: "error"
        })
      }
    );
  }
  addPermission(name: any) {
    const data = { "name": name }
    this.userService.addPermission(data).subscribe(
      (response) => {
        Swal.fire({
          titleText: "Success",
          text: response.message,
          timer: 5000,
          icon: "success"
        })
      },
      (error) => {
        console.log(error);
        Swal.fire({
          titleText: "Error",
          text: "Something went wrong",
          timer: 5000,
          icon: "error"
        })
      }
    );
  }
  deletePermission(id: any) {
    this.userService.deletePermission(id).subscribe((response) => {
      Swal.fire({
        titleText: "Success",
        text: response.message,
        timer: 5000,
        icon: "success"
      })
    },
      (error) => {
        console.log(error);
        Swal.fire({
          titleText: "Error",
          text: "Something went wrong",
          timer: 5000,
          icon: "error"
        })
      }
    );
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  onPageChange(event: PageEvent) {
    console.log(event);
    this.size = event.pageSize;
    this.page = event.pageIndex;
    this.getAllpermission();
  }
}