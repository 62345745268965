import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { InvoiceService } from '../helpers/invoice.service';
import { SocketService } from '../helpers/socket.service';

@Component({
  selector: 'app-client-template-list',
  templateUrl: './client-template-list.component.html',
  styleUrls: ['./client-template-list.component.css']
})
export class ClientTemplateListComponent implements OnInit {

  public templateList: Array<any> = [];
  public invoiceName: string;

  constructor(private _invoice: InvoiceService, private route: ActivatedRoute, private router: Router, private socketService: SocketService) { }

  ngOnInit(): void {
    this.getAllTemplates();
    // this.socketService.connectTOServerPopUpFuction();
  }

  getAllTemplates() {
    this._invoice.getInvoiceTemplates().subscribe
      ((response) => {
        console.log(response);
        this.templateList = response;
        for (let index = 0; index < response.length; index++) {
          const element = response[index];
          this.invoiceName = element.projectName + '' + element.projectNumber;
        }
      },
        (error) => {
          console.log('Something Went Wrong', error);
        })
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }

  //fuction to delete template using id
  deleteTemplateById(id: any) {
    this._invoice.deleteTemplate(id).subscribe
      ((response) => {
        console.log(response);
        Swal.fire({
          title: 'Success',
          text: response.message,
          timer: 5000,
          icon: "success"
        })
        this.getAllTemplates();
      },
      (error)=>{
        console.log(error);
        Swal.fire({
          title: 'Invalid',
          text: error.message,
          timer: 5000,
          icon: "error"
        })
      })
  }

}
