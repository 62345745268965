<app-header></app-header>
<section class="banner text-center text-white d-flex align-items-center banner-full"
    style="background-image: url(assets/images/banner.jpg)">
    <div class="container position-relative">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <div class="heading-dual">
                    <h1>LogSolutec</h1>
                    <h2>About Us</h2>
                </div>
            </div>
        </div>
    </div>
</section>
<!--about section start-->
<section class="about-wrapper text-dark py-8" id="about-us">
    <div class="container-fluid p-lg-start-end">
        <div class="row">
            <div class="col-lg-6">
                <div class="heading-dual">
                    <h6>ABOUT LOGSOLUTEC</h6>
                    <h1>About</h1>
                </div>
                <h3 class="text-success">LogSolutec</h3>
                <p>LogSolutec is a ERP+CRM module software that provides logistic solutions to companies operating in
                    the field of logistics and shipping. Our software is a one-of-a-kind combination of data science and
                    artificial intelligence that assists firms in achieving efficient logistical management while not
                    costing a fortune. We at LogSolutec are constantly working to improve the logistics sector by
                    eliminating miscommunication and lowering manual labor.
                </p>
                <p>
                    Our success is determined with the extension of brands we have helped with our technology-driven
                    solutions. We’re here to solve your business logistics challenges and to deliver knockout-results.
                </p>
                <p>
                    Empower your business with real-time information, maximize savings, reduce manual work load and
                    ensure data-driven decision making. LogSolutec is our name and we break into the minds of your
                    consumers, blow up conversions and drive profits.
                </p>
            </div>
            <div class="col-lg-6 col-xxl-4">
                <img class="img-fluid" src="assets/images/about.png" alt="about us" />
            </div>
            <div class="col-lg-12">
                <p>LogSolutec commenced our journey in the year 2021, to provide an agile platform for easy management
                    of businesses. We provide comprehensive software solutions that address the most pressing
                    difficulties that international logistics corporations and small warehousing businesses confront
                    today. Our products adapt to your company's particular needs to help you manage complex supply
                    chains, satisfy expanding customer demands, and manage critical business processes. With
                    LogSolutec's logistics software services, you can improve service quality, streamline procedures,
                    and boost business profits.
                </p>
                <p>
                    Our clients are the heart of our business! Thus, we ensure that we can break through the clutter for
                    big and small businesses across a variety of industries.
                </p>
            </div>
        </div>
    </div>
</section>
<!--about section end-->

<!-- mission section start -->
<section class="services-wrapper text-dark py-8" id="mission">
    <div class="container-fluid p-lg-start-end">
        <div class="row">
            <div class="col-lg-8 mb-3 mb-lg-4">
                <div class="heading-dual">
                    <h6>Mission and Vision</h6>
                    <h1>Mission & Vision</h1>
                    <h2><span class="text-success">LogSolutec</span> Mission & Vision</h2>
                </div>
                <br>
                <h5 class="text-dark">At LogSolutec, we'll walk you through our values, culture, and everyday life.
                    Prepare yourself for a
                    rewarding journey.</h5>
                <br>

                <h5 class="text-primary">Go gritty or go home.</h5>
                <p>At LogSolutec, we live by this philosophy to ensure that we can tackle any challenge with grit and
                    dedication. Our goal is customer pleasure, and our motto is "work hard."</p>

                <br>

                <h5 class="text-primary">Let the greatest idea take the prize</h5>
                <p>We believe that a large number of people have a variety of viewpoints, ideas, and solutions to
                    address various difficulties. For a clear win, we provide everyone an equal opportunity to express
                    their point of view and provide the greatest services to our clients.</p>

                <br>
                <h5 class="text-primary">Humble and Rumble</h5>
                <p>With years of experience, we mold young minds. Our team works hard to guarantee that curiosity and
                    humility may propel our team forward and help us reach new professional heights.</p>

                <br>
                <h5 class="text-primary">Taking a step closer to the future</h5>
                <p>Our achievement was not attained overnight; instead, it was made possible by our employees.
                    The world of technology has been turned upside down, and as a result, we now have unprecedented
                    potential to help the next generation.
                </p>

                <br>
                <h5 class="text-primary">Taking a Step Towards Success</h5>
                <p>Our company principles have always been centered on providing a world-class employee experience in
                    order to maintain and attract fresh talent. Furthermore, our jobs are laid out in such a way that we
                    can keep you connected with your clients' ever-changing wants. With our vast experience and
                    cutting-edge technologies, we hope to improve the future.</p>

            </div>
            <!-- ToDo, image creation -->
            <div class="col-lg-4">
            </div>
        </div>
    </div>
</section>
<!--mission section end-->