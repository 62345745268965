import { UserService } from './../helpers/user.service';
import { Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DialogBoxComponent } from '../dialog-box/dialog-box.component';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { SocketService } from '../helpers/socket.service';

@Component({
  selector: 'app-user-role-list',
  templateUrl: './user-role-list.component.html',
  styleUrls: ['./user-role-list.component.css'],
})
export class UserRoleListComponent implements OnInit {
  page: number = 0;
  size: number = 5;
  roleLength: number;
  serchFilter: Boolean = false;
  nodata: Boolean = true;

  displayedColumns: string[] = [
    'authority',
    'createdBy',
    'createdDate',
    'updatedBy',
    'updatedDate',
    'action',
  ];
  dataSource: MatTableDataSource<any>;
  roleList: Array<any> = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private userService: UserService,
    private dialog: MatDialog,
    private socketService: SocketService
  ) {}

  ngOnInit(): void {
    this.getAllRoles();

    // this.socketService.connectTOServerPopUpFuction();
  }

  openDialog(action, obj) {
    obj.action = action;
    obj.component = 'roles';
    const dialogRef = this.dialog.open(DialogBoxComponent, {
      width: '250px',
      data: obj,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      if (result.event == 'Create') {
        this.addRole(result.data);
      } else if (result.event == 'Update') {
        this.updateRole(result.data.id, result.data);
      } else if (result.event == 'Delete') {
        this.deleteRowData(result.data);
      } else {
        console.log(result);
      }
    });
  }

  deleteRowData(row_obj) {
    this.deleteRole(row_obj.id);
    setTimeout(() => {
      this.getAllRoles();
    }, 500);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.nodata = false;
    this.serchFilter = true;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getAllRoles() {
    this.userService.getAllRoles().subscribe(
      (response) => {
        if (response != null) {
          this.roleList = response;
          this.roleLength = response;
          this.dataSource = new MatTableDataSource(response);
          // this.dataSource.sort = this.sort;
        }
      },
      (error) => {
        console.log(error);
      },
      () => {}
    );
  }

  addRole(data: any) {
    this.userService.addRole(data).subscribe(
      (response) => {
        if (response.code == 201) {
          Swal.fire({
            icon: 'success',
            timer: 5000,
            titleText: 'Success',
            text: response.message,
          });
          setTimeout(() => {
            this.getAllRoles();
          }, 500);
        }
      },
      (error) => {
        console.log(error);
        Swal.fire({
          titleText: 'Error',
          text: 'Something went wrong',
          timer: 5000,
          icon: 'error',
        });
      }
    );
  }

  deleteRole(id: any) {
    this.userService.deleteRole(id).subscribe(
      (response) => {
        Swal.fire({
          titleText: 'Success',
          text: response.message,
          timer: 5000,
          icon: 'success',
        });
      },
      (error) => {
        console.log('this is error ', error);
        if (error.error.errorCode == 412) {
          Swal.fire({
            titleText: 'Error',
            text: error.error.errorMessage,
            timer: 5000,
            icon: 'error',
          });
        }
        else {
          console.log(error);
          Swal.fire({
            titleText: "Error",
            text: "Something went wrong",
            timer: 5000,
            icon: "error"
          })
        }
      }
    );
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove('sidebar-active');
  }

  updateRole(id: number, data: any) {
    this.userService.updateRole(id, data).subscribe(
      (response) => {
        if (response.code == 201) {
          Swal.fire({
            icon: 'success',
            timer: 5000,
            titleText: 'Success',
            text: response.message,
          });
          setTimeout(() => {
            this.getAllRoles();
          }, 500);
        }
      },
      (error) => {
        console.log(error);
        Swal.fire({
          titleText: 'Error',
          text: 'Something went wrong',
          timer: 5000,
          icon: 'error',
        });
      }
    );
  }

  onPageChange(event: PageEvent) {
    this.size = event.pageSize;
    this.page = event.pageIndex;
    this.getAllRoles();
  }
}
