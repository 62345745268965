<section class="sign-in-wrapper">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 py-8 bg text-white d-flex flex-column justify-content-center position-relative"
        style="background-image: url(assets/images/sign-in-thumb.jpg)">
        <div class="box-absolute"><a href="index.html"><img class="img-fluid  " src="assets/images/logo-white.png"
              alt="Logsolutec" /></a>
        </div>
        <div class="box box-quote mx-auto position-relative">
          <p class="mb-3 mb-lg-4">“ Leaders win through logistics. Vision, sure. Strategy, yes. But when you go to war,
            you need to have both toilet paper and bullets at the right place at the right time. In other words, you
            must win through superior logistics. “</p>
          <h4>
            <svg width="27" height="19" viewBox="0 0 27 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M21.6797 7.32422C23.1641 7.32422 24.401 7.89323 25.3906 9.03125C26.3802 10.1693 26.875 11.4557 26.875 12.8906C26.875 14.5729 26.2812 15.9336 25.0938 16.9727C23.9062 17.9622 22.6445 18.457 21.3086 18.457C19.9727 18.457 18.6615 17.888 17.375 16.75C16.138 15.5625 15.5195 14.0286 15.5195 12.1484C15.5195 9.22917 16.4596 6.70573 18.3398 4.57812C20.2201 2.40104 22.1497 1.16406 24.1289 0.867188L24.7227 2.05469C23.5846 2.5 22.2734 3.36589 20.7891 4.65234C19.3542 5.9388 18.5872 7.20052 18.4883 8.4375C19.7253 7.69531 20.7891 7.32422 21.6797 7.32422ZM6.39062 7.32422C7.875 7.32422 9.11198 7.89323 10.1016 9.03125C11.0911 10.1693 11.5859 11.4557 11.5859 12.8906C11.5859 14.5729 10.9922 15.9336 9.80469 16.9727C8.61719 17.9622 7.35547 18.457 6.01953 18.457C4.73307 18.457 3.44661 17.888 2.16016 16.75C0.873698 15.5625 0.230469 14.0286 0.230469 12.1484C0.230469 9.22917 1.17057 6.70573 3.05078 4.57812C4.93099 2.40104 6.86068 1.16406 8.83984 0.867188L9.43359 2.05469C8.29557 2.5 6.98438 3.36589 5.5 4.65234C4.0651 5.9388 3.29818 7.20052 3.19922 8.4375C4.4362 7.69531 5.5 7.32422 6.39062 7.32422Z"
                fill="white"></path>
            </svg>- Tom Peters
          </h4>
        </div>
      </div>
      <div class="col-md-6 py-8 d-flex flex-column justify-content-center position-relative">
        <div class="box-absolute brand"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14 6L8.70711 11.2929C8.31658 11.6834 8.31658 12.3166 8.70711 12.7071L14 18" stroke="#414A53"
            stroke-width="1.5" stroke-linecap="round"></path>
        </svg><button class="btn-back" [routerLink]="['/sign-in']">
            Back</button></div>
        <div class="box mx-auto">
          <form [formGroup]="otpForm">
            <h3>Forgot Password?</h3>
            <div class="row">
              <div class="col-md-12 form-group mb-4">
                <label class="form-label" for="email-id">Email Id</label>
                <input class="form-control" type="email" formControlName="emailId" placeholder="someone@gmail.com"
                  id="email-id" />
              </div>
              <div *ngIf="showOtpInput" class="col-md-12 form-group mb-4">
                <label class="form-label" for="email-id">Verify Otp</label>
                <input class="form-control" type="email" formControlName="otp" placeholder="Enter OTP" id="email-id"
                  [ngClass]="{'is-invalid': f.otp.invalid && (f.otp.touched || f.otp.dirty )}" />
                <div *ngIf="f.otp.invalid && (f.otp.touched || f.otp.dirty )">
                  <div class="text-danger validation-message" [hidden]="!f.otp.errors.required">
                    OTP Is Required
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 form-group mb-2">
                  <div *ngIf="showOtpInput" class="">
                    <button [disabled]="disableBtn" class="text-start" type="button" (click)="resendOTP()" style="text-align: left">
                      <span *ngIf="!disableBtn" class="text-success" style="font-weight: 900;"> Resend OTP </span> 
                      <span *ngIf="disableBtn" class="disabled-otp-btn" style="font-weight: 900;"> Resend OTP </span> 
                    </button>
                    <span *ngIf="disableBtn"> in {{timeLeft}} seconds </span>
                  </div>
                </div>

                <div class="col-md-12 form-group pt-4 pt-lg-5">
                  <div *ngIf="sendOtpInput">
                    <button type="submit" class="btn btn-success w-100" (click)="sendOtp()">Submit</button>
                  </div>
                  <div *ngIf="showOtpInput">
                    <button type="submit" class="btn btn-success w-100" (click)="verifyOtp()">Submit OTP</button>
                  </div>
                </div>
                <div class="col-md-12 form-group text-center">
                  <p class="lead">Remembered Your Password?
                    <a class="text-primary text-decoration-none" [routerLink]="['/sign-in']">Sign In</a>
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>