import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ClientService } from '../helpers/client-service.service';
import { SocketService } from '../helpers/socket.service';

@Component({
  selector: 'app-client-track-trace',
  templateUrl: './client-track-trace.component.html',
  styleUrls: ['./client-track-trace.component.css']
})
export class ClientTrackTraceComponent implements OnInit {

  noDataMsg: Boolean = false;
  org: any = '';
  des: any = '';
  page: number = 0;
  size: number = 25;
  public listingForm!: FormGroup;
  public enquiry_listing: any;
  public modeOfShipment: any;
  dataSource!: MatTableDataSource<any>;
  rfq_id: number;
  checkedList: Array<any> = [];
  selectedOrigin: string;
  selectedDestination: string;
  jsonForRowDetails: any;
  displayedColumns: string[] = ['transitTime', 'carrier', 'cutOff', 'departure', 'arrival', 'serviceVessel'];
  // dataSource = new MatTableDataSource(backendData);
  objBool: Boolean = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private clientService: ClientService, private fb: FormBuilder, private router: Router, private route: ActivatedRoute, private socketService: SocketService) {
    this.jsonForRowDetails = [];
  }

  ngOnInit(): void {
    this.org = [];
    this.des = [];
    this.selectedOrigin = '';
    this.selectedDestination = '';

    // this.socketService.connectTOServerPopUpFuction();
  }

  onKeyUpEventOrg(event) {
    console.log(event);
    const checkOrg = this.org.find(org => org.fullName === this.selectedOrigin)

    if (!checkOrg) {
      this.clientService.getLocation(event.target.value).subscribe(
        (response) => {
          this.org = [];
          for (let index = 0; index < response.length; index++) {
            const element = response[index];
            this.org.push(element);
          }
          console.log(response);
        },
        (error) => {
          console.log(error);
        },
        () => { },
      )
    }
  }

  onKeyUpEventDes(event) {
    console.log(event);
    const checkDes = this.des.find(des => des.fullName === this.selectedDestination)

    if (!checkDes) {
      this.clientService.getLocation(event.target.value).subscribe(
        (response) => {
          this.des = [];
          for (let index = 0; index < response.length; index++) {
            const element = response[index];
            this.des.push(element);
          }
          // this.des = response;
          console.log(response);
        },
        (error) => {
          console.log(error);
        },
        () => { },
      )
    }
  }

  searchDetails() {
    let searchDetails;
    const orgVal = this.org.find(org => org.fullName === this.selectedOrigin);
    console.log(orgVal);

    const desVal = this.des.find(des => des.fullName === this.selectedDestination);
    console.log(desVal);

    this.clientService.getDetailsOfSailing(orgVal.locationID, desVal.locationID).subscribe(
      (response) => {
        console.log(response);

        searchDetails = response.routeGroupsList;
        if (searchDetails != null) {
          searchDetails.forEach(listItem => {
            listItem.route.forEach(eachRoute => {
              eachRoute.leg.forEach((eachLeg, i) => {
                // console.log(i + ' ' +eachLeg.service.name + '/' + eachLeg.vessel.code);
                this.jsonForRowDetails.push({
                  'transitTime': eachRoute.transitTime,
                  'carrier': eachRoute.carrierScac,
                  'cutOff': eachRoute.defaultCutoff.cutoffTime,
                  'departure': eachRoute.por.location.name,
                  'arrival': eachRoute.fnd.location.name,
                  'serviceVessel': eachRoute.serviceVessel
                  // 'serviceVessel': (eachLeg !== undefined && eachLeg.service !== undefined && eachLeg.service.name ) ? eachLeg.service.name : '-'  + '/' + (eachLeg !== undefined && eachLeg.vessel !==undefined && eachLeg.vessel.code !== undefined) ? eachLeg.vessel.code : '?',
                });
              });
            });
          });
        }
        else { 
          this.noDataMsg=true
        }

          console.log(this.jsonForRowDetails);
          this.objBool = true;
          this.dataSource = new MatTableDataSource(this.jsonForRowDetails);
          this.dataSource.sort = this.sort;
          // this.dataSource.paginator = this.paginator
      },
      (error) => {
        this.objBool = false;
        console.log(error);
      },
    )
  }


  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  hideSideBar() {
    document.getElementById('sideBar').classList.remove("sidebar-active");
  }
}
