import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AuthServiceService } from '../helpers/auth-service.service';
import { PasswordStrengthValidator } from '../helpers/password-strenght-validation';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  public passwordForm!: FormGroup;
  public password!: boolean
  public confirm_password!: boolean
  public emailId: string = sessionStorage.getItem('emailId');
  public submitted = false;
  public errorPassword: Boolean = false;

  constructor(private form: FormBuilder, private authService: AuthServiceService, private route: Router) { }

  ngOnInit(): void {
    this.passwordForm = this.form.group({
      password: ['', Validators.compose([Validators.required, Validators.minLength(8), PasswordStrengthValidator])],
      confirm_password: ['', Validators.compose([Validators.required])],
    })
    this.gotoTop();
  }

  get f() { return this.passwordForm.controls; }

  savePassword() {
    if (this.f.password.errors) {
      this.errorPassword = true;
    } else {
      this.errorPassword = false;
    }
    this.submitted = true;
    if (this.passwordForm.invalid) {
      Swal.fire({
        titleText: "Invalid",
        text: "Password criteria doesn't match",
        timer: 5000,
        icon: "warning"
      })
    }
    else if (this.f.password?.value != this.f.confirm_password?.value) {
      Swal.fire({
        titleText: "Error",
        text: "Password doesn't match",
        icon: "error",
        timer: 5000
      })
    }
    else {
      this.authService.changePassword(this.emailId, this.passwordForm.value).subscribe(
        (response) => {
          if (response.code == 202) {
            Swal.fire({
              titleText: "Success",
              text: response.message,
              icon: "success",
              timer: 5000
            })
            sessionStorage.clear();
            this.route.navigate(['login']);
          }
        },
        (_error) => {
          let error = _error.error
          console.log(error);
          if(error.errorCode == 412){
            Swal.fire({
              titleText: "Error",
              text: error.errorMessage,
              icon: "error",
              timer: 5000
            })
          }
          else if(error.password){
            Swal.fire({
              titleText: "Error",
              text: error.password,
              icon: "error",
              timer: 5000
            })
          }
          else if(error.emailId){
            Swal.fire({
              titleText: "Error",
              text: error.emailId,
              icon: "error",
              timer: 5000
            })
          }
          else{
            Swal.fire({
              titleText: "Error",
              text: 'Something went wrong try again later',
              icon: "error",
              timer: 5000
            })
          }
        },
      )
    }
  }

  showPassword() {
    this.password = !this.password;
  }

  showConfirmPassword() {
    this.confirm_password = !this.confirm_password;
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
}
