<!--header start-->
<header class="box-absolute d-flex align-items-center topline text-white">
    <app-sidebar></app-sidebar>
    <div class="page-title-center mt-3" (click)="hideSideBar()">
        <div class="text-center">
            <h1>Container Vessel Schedule</h1>
        </div>
    </div>
    <div class="breadcrumb-div mt-3">
        <ul class="breadcrumb p-0 m-0">
            <li class="breadcrumb-item 3" id="0"><a [routerLink]="['/v1/dashboard']">Dashboard</a></li>
            <li class="breadcrumb-item 3" id="1"><a href="vendor.html">Track & Trace</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0)">Vessel</a></li>
        </ul>
    </div>
</header>
<!--header end-->
<section class="banner d-flex" id="bnner">
    <div class="container position-relative">
        <div class="row justify-content-center">
            <div class="col-lg-12">
            </div>
        </div>
    </div>
</section>

<section class="request-demo-wrapper pb-10" (click)="hideSideBar()">
    <div class="container-fluid shadow bg-white">
        <div class="row">
            <div class="col-md-12">
                <div class="filter-wrap btn-group d-flex flex-wrap flex-md-nowrap justify-content-center gap-2 pb-3 pb-lg-4 px-3 px-lg-4"
                    style="background: #F7F8FA; padding-top: 2%;">

                    <div class="input-group">
                        <input list="originList" name="orgin" [(ngModel)]="selectedOrigin"
                            (keyup)="onKeyUpEventOrg($event)" class="form-control rounded-3 px-5" type="text"
                            placeholder="Origin">
                        <datalist id="originList">
                            <option *ngFor="let or of org" [data-value]="or.locationID">{{or.fullName}}</option>
                        </datalist>
                    </div>

                    <div class="input-group">
                        <input list="destinationList" name="destination" [(ngModel)]="selectedDestination"
                            (keyup)="onKeyUpEventDes($event)" class="form-control rounded-3 px-5" type="text"
                            placeholder="Destination">
                        <datalist id="destinationList">
                            <option *ngFor="let de of des" [data-value]="de.locationID">{{de.fullName}}</option>
                        </datalist>
                    </div>

                    <button class="rounded-3 flex-shrink-0" type="button" (click)="searchDetails()">
                        <i class="fa fa-search"></i><span> Search</span>
                    </button>
                </div>
            </div>

            <div class="col-md-4 card" *ngIf="showTable" id="showFilter">
                <!-- Carrier Filter -->
                <cdk-accordion class="example-accordion">
                    <cdk-accordion-item #carrierItem="cdkAccordionItem" class="example-accordion-item" role="button"
                        tabindex="0" [attr.id]="CarrierHeading" [attr.aria-expanded]="carrierItem.expanded"
                        [attr.aria-controls]="CarrierBody">
                        <div class="example-accordion-item-header" (click)="carrierItem.toggle()">
                            Carrier
                            <span class="example-accordion-item-description">
                                <svg *ngIf="!carrierItem.expanded" width="22" height="22" viewBox="0 0 22 22"
                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M5.5 9.16663L10.3268 13.9935C10.6986 14.3652 11.3014 14.3652 11.6732 13.9935L16.5 9.16663"
                                        stroke="#003D90" stroke-width="1.42797" stroke-linecap="round" />
                                </svg>
                                <svg *ngIf="carrierItem.expanded" width="22" height="22" viewBox="0 0 22 22" fill="none"
                                    xmlns="http://www.w3.org/2000/svg" class="rotateimg180">
                                    <path
                                        d="M5.5 9.16663L10.3268 13.9935C10.6986 14.3652 11.3014 14.3652 11.6732 13.9935L16.5 9.16663"
                                        stroke="#003D90" stroke-width="1.42797" stroke-linecap="round" />
                                </svg>
                            </span>
                        </div>
                        <div class="example-accordion-item-body row" role="region"
                            [style.display]="carrierItem.expanded ? '' : 'none'" [attr.id]="CarrierBody"
                            [attr.aria-labelledby]="CarrierHeading">
                            <div (click)="$event.stopPropagation()" class=" row">
                                <div *ngFor="let item of carrierList" class="col-md-6">
                                    <mat-checkbox [(ngModel)]="item.selected" [matChipInputFor]="chipList"
                                        (ngModelChange)="checkedCarries($event)">{{item.name}}
                                    </mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </cdk-accordion-item>
                </cdk-accordion>

                <!-- CY Cutoff Filter -->
                <cdk-accordion class="example-accordion">
                    <cdk-accordion-item #cyCutoffItem="cdkAccordionItem" class="example-accordion-item" role="button"
                        tabindex="0" [attr.id]="CarrierHeading" [attr.aria-expanded]="cyCutoffItem.expanded"
                        [attr.aria-controls]="CarrierBody">
                        <div class="example-accordion-item-header" (click)="cyCutoffItem.toggle()">
                            CY Cutoff
                            <span class="example-accordion-item-description">
                                <span class="example-accordion-item-description">
                                    <svg *ngIf="!cyCutoffItem.expanded" width="22" height="22" viewBox="0 0 22 22"
                                        fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M5.5 9.16663L10.3268 13.9935C10.6986 14.3652 11.3014 14.3652 11.6732 13.9935L16.5 9.16663"
                                            stroke="#003D90" stroke-width="1.42797" stroke-linecap="round" />
                                    </svg>
                                    <svg *ngIf="cyCutoffItem.expanded" width="22" height="22" viewBox="0 0 22 22"
                                        fill="none" xmlns="http://www.w3.org/2000/svg" class="rotateimg180">
                                        <path
                                            d="M5.5 9.16663L10.3268 13.9935C10.6986 14.3652 11.3014 14.3652 11.6732 13.9935L16.5 9.16663"
                                            stroke="#003D90" stroke-width="1.42797" stroke-linecap="round" />
                                    </svg>
                                </span>
                            </span>
                        </div>
                        <div class="example-accordion-item-body" role="region"
                            [style.display]="cyCutoffItem.expanded ? '' : 'none'" [attr.id]="CarrierBody"
                            [attr.aria-labelledby]="CarrierHeading">
                            <div (click)="$event.stopPropagation()">
                                <mat-form-field appearance="fill" class="">
                                    <input [matDatepickerFilter]="CutOffFilter" matInput [matDatepicker]="picker"
                                        placeholder="Choose a date" (dateChange)="dateChanged($event,'cutOff')">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker [dateClass]="dateCutoffClass"></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                    </cdk-accordion-item>
                </cdk-accordion>

                <!-- Departure Date Filter -->
                <cdk-accordion class="example-accordion">
                    <cdk-accordion-item #departureDateItem="cdkAccordionItem" class="example-accordion-item"
                        role="button" tabindex="0" [attr.id]="CarrierHeading"
                        [attr.aria-expanded]="departureDateItem.expanded" [attr.aria-controls]="CarrierBody">
                        <div class="example-accordion-item-header" (click)="departureDateItem.toggle()">
                            Departure Date
                            <span class="example-accordion-item-description">
                                <span class="example-accordion-item-description">
                                    <svg *ngIf="!departureDateItem.expanded" width="22" height="22" viewBox="0 0 22 22"
                                        fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M5.5 9.16663L10.3268 13.9935C10.6986 14.3652 11.3014 14.3652 11.6732 13.9935L16.5 9.16663"
                                            stroke="#003D90" stroke-width="1.42797" stroke-linecap="round" />
                                    </svg>
                                    <svg *ngIf="departureDateItem.expanded" width="22" height="22" viewBox="0 0 22 22"
                                        fill="none" xmlns="http://www.w3.org/2000/svg" class="rotateimg180">
                                        <path
                                            d="M5.5 9.16663L10.3268 13.9935C10.6986 14.3652 11.3014 14.3652 11.6732 13.9935L16.5 9.16663"
                                            stroke="#003D90" stroke-width="1.42797" stroke-linecap="round" />
                                    </svg>
                                </span>
                            </span>
                        </div>
                        <div class="example-accordion-item-body" role="region"
                            [style.display]="departureDateItem.expanded ? '' : 'none'" [attr.id]="CarrierBody"
                            [attr.aria-labelledby]="CarrierHeading">
                            <div (click)="$event.stopPropagation()">
                                <mat-form-field appearance="fill" class="">
                                    <input matInput [matDatepickerFilter]="DepartureFilter" [matDatepicker]="picker1"
                                        placeholder="Choose a date" (dateChange)="dateChanged($event,'departure')">
                                    <mat-datepicker-toggle matSuffix [for]="picker1">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker [dateClass]="dateDepartureClass" #picker1>
                                    </mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                    </cdk-accordion-item>
                </cdk-accordion>

                <!-- Arrival Date Filter -->
                <cdk-accordion class="example-accordion">
                    <cdk-accordion-item #arrivalDateItem="cdkAccordionItem" class="example-accordion-item" role="button"
                        tabindex="0" [attr.id]="CarrierHeading" [attr.aria-expanded]="arrivalDateItem.expanded"
                        [attr.aria-controls]="CarrierBody">
                        <div class="example-accordion-item-header" (click)="arrivalDateItem.toggle()">
                            Arrival Date
                            <span class="example-accordion-item-description">
                                <span class="example-accordion-item-description">
                                    <span class="example-accordion-item-description">
                                        <svg *ngIf="!arrivalDateItem.expanded" width="22" height="22"
                                            viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M5.5 9.16663L10.3268 13.9935C10.6986 14.3652 11.3014 14.3652 11.6732 13.9935L16.5 9.16663"
                                                stroke="#003D90" stroke-width="1.42797" stroke-linecap="round" />
                                        </svg>
                                        <svg *ngIf="arrivalDateItem.expanded" width="22" height="22" viewBox="0 0 22 22"
                                            fill="none" xmlns="http://www.w3.org/2000/svg" class="rotateimg180">
                                            <path
                                                d="M5.5 9.16663L10.3268 13.9935C10.6986 14.3652 11.3014 14.3652 11.6732 13.9935L16.5 9.16663"
                                                stroke="#003D90" stroke-width="1.42797" stroke-linecap="round" />
                                        </svg>
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div class="example-accordion-item-body" role="region"
                            [style.display]="arrivalDateItem.expanded ? '' : 'none'" [attr.id]="CarrierBody"
                            [attr.aria-labelledby]="CarrierHeading">
                            <div (click)="$event.stopPropagation()" class="">
                                <ngx-slider [value]="value" [highValue]="highvalue" (userChange)="transitDays($event)"
                                    [options]="options"></ngx-slider>
                                <div>
                                    <h6>Mininum Days : </h6> {{value}}
                                    <h6>Maximum Days : </h6> {{highvalue}}
                                </div>
                                <div>
                                    Selected Range : {{ maxValue + '-' +maxValue }}
                                </div>
                            </div>
                        </div>
                    </cdk-accordion-item>
                </cdk-accordion>

                <!-- Transit Time (Days) Filter -->
                <cdk-accordion class="example-accordion">
                    <cdk-accordion-item #transitTimeItem="cdkAccordionItem" class="example-accordion-item" role="button"
                        tabindex="0" [attr.id]="CarrierHeading" [attr.aria-expanded]="transitTimeItem.expanded"
                        [attr.aria-controls]="CarrierBody">
                        <div class="example-accordion-item-header" (click)="transitTimeItem.toggle()">
                            Transit Time (Days)
                            <span class="example-accordion-item-description">
                                <span class="example-accordion-item-description">
                                    <span class="example-accordion-item-description">
                                        <span class="example-accordion-item-description">
                                            <svg *ngIf="!transitTimeItem.expanded" width="22" height="22"
                                                viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.5 9.16663L10.3268 13.9935C10.6986 14.3652 11.3014 14.3652 11.6732 13.9935L16.5 9.16663"
                                                    stroke="#003D90" stroke-width="1.42797" stroke-linecap="round" />
                                            </svg>
                                            <svg *ngIf="transitTimeItem.expanded" width="22" height="22"
                                                viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"
                                                class="rotateimg180">
                                                <path
                                                    d="M5.5 9.16663L10.3268 13.9935C10.6986 14.3652 11.3014 14.3652 11.6732 13.9935L16.5 9.16663"
                                                    stroke="#003D90" stroke-width="1.42797" stroke-linecap="round" />
                                            </svg>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div class="example-accordion-item-body" role="region"
                            [style.display]="transitTimeItem.expanded ? '' : 'none'" [attr.id]="CarrierBody"
                            [attr.aria-labelledby]="CarrierHeading">
                            <div (click)="$event.stopPropagation()" class="m-3">
                                <ngx-slider [value]="value" [highValue]="highvalue" (userChange)="transitDays($event)"
                                    [options]="options"></ngx-slider>
                                <div>
                                    <h6>Mininum Days : </h6> {{value}}
                                    <h6>Maximum Days : </h6> {{highvalue}}
                                </div>
                                <div>
                                    Selected Range : {{ maxValue + '-' +maxValue }}
                                </div>
                            </div>
                        </div>
                    </cdk-accordion-item>
                </cdk-accordion>

                <!-- show direct filter -->
                <cdk-accordion class="example-accordion">
                    <cdk-accordion-item #showDirect="cdkAccordionItem" class="example-accordion-item" role="button"
                        tabindex="0" [attr.id]="CarrierHeading" [attr.aria-expanded]="showDirect.expanded"
                        [attr.aria-controls]="CarrierBody">
                        <div class="example-accordion-item-header" (click)="showDirect.toggle()">
                            Direct/Transshipment
                            <span class="example-accordion-item-description">
                                <span class="example-accordion-item-description">
                                    <svg *ngIf="!showDirect.expanded" width="22" height="22" viewBox="0 0 22 22"
                                        fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M5.5 9.16663L10.3268 13.9935C10.6986 14.3652 11.3014 14.3652 11.6732 13.9935L16.5 9.16663"
                                            stroke="#003D90" stroke-width="1.42797" stroke-linecap="round" />
                                    </svg>
                                    <svg *ngIf="showDirect.expanded" width="22" height="22" viewBox="0 0 22 22"
                                        fill="none" xmlns="http://www.w3.org/2000/svg" class="rotateimg180">
                                        <path
                                            d="M5.5 9.16663L10.3268 13.9935C10.6986 14.3652 11.3014 14.3652 11.6732 13.9935L16.5 9.16663"
                                            stroke="#003D90" stroke-width="1.42797" stroke-linecap="round" />
                                    </svg>
                                </span>
                            </span>
                        </div>
                        <div class="example-accordion-item-body" role="region"
                            [style.display]="showDirect.expanded ? '' : 'none'" [attr.id]="CarrierBody"
                            [attr.aria-labelledby]="CarrierHeading">
                            <div (click)="$event.stopPropagation()">
                                <mat-checkbox class="m-4" (change)="directShipment($event)">
                                    Show Direct Only
                                </mat-checkbox>
                            </div>
                        </div>
                    </cdk-accordion-item>
                </cdk-accordion>

                <div class="row text-center" *ngIf="hidebtn">
                    <div class="col-md-12">
                        <button class="mt-2 rounded-3 flex-shrink-0 btn btn-secondary" type="button"
                            (click)="resetFilteration()">
                            <span> Reset Filter </span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="col-md-12 card border-0" id="dataTable">
                <div class="table table-responsive">
                    <table class="table table-borderless table-striped table-footer-dark table-head-dark" mat-table
                        [dataSource]="dataSource" matSort matSortDisableClear>

                        <ng-container matColumnDef="transitTime">
                            <th class="text-white" mat-header-cell *matHeaderCellDef mat-sort-header> Transit
                                Time</th>
                            <td mat-cell *matCellDef="let row">
                                {{ row.route.transitTime }} days
                                <div *ngIf="row.route.direct"> Direct* </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="carrier">
                            <th class="text-white" mat-header-cell *matHeaderCellDef mat-sort-header>
                                Carrier
                            </th>
                            <td mat-cell *matCellDef="let row"> {{ row.carrier.name }} </td>
                        </ng-container>
                        <ng-container matColumnDef="cutOff">
                            <th class="text-white" mat-header-cell *matHeaderCellDef mat-sort-header> CY Cutoff
                            </th>
                            <td mat-cell *matCellDef="let row">
                                {{ row.route.defaultCutoff.cutoffTime | date:'d MMM (EEE) hh:mm a'}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="departure">
                            <th class="text-white" mat-header-cell *matHeaderCellDef mat-sort-header> Departure
                            </th>
                            <td mat-cell *matCellDef="let row">{{ row.route.por.location.name }}
                                <p>{{row.route.por.etd | date:'d MMM, (EEE)' }}</p>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="arrival">
                            <th class="text-white" mat-header-cell *matHeaderCellDef> Arrival </th>
                            <td mat-cell *matCellDef="let row"> {{ row.route.fnd.location.name }}
                                <p>{{row.route.fnd.eta | date:'d MMM, (EEE)'}}</p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="serviceVessel">
                            <th class="text-white" mat-header-cell *matHeaderCellDef> Service / Vessel </th>
                            <td mat-cell *matCellDef="let row">{{ row.serviceVessel }}</td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th class="text-white" mat-header-cell *matHeaderCellDef> Actions</th>
                            <td mat-cell *matCellDef="let row; index as i">
                                <button (click)="openDialog(row)">
                                    <a class="anchor-tag">View Details </a></button>
                            </td>
                        </ng-container>

                        <tr class="text-left bg-dark" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                        <tr class="mat-row" *matNoDataRow>
                            <td colspan="7" class="mat-cell text-center text-danger">
                                <div *ngIf="nodataMsg">
                                    The schedule From " {{ selectedOrigin }} " to " {{ selectedDestination }} "
                                    could not be found at this time..
                                </div>
                            </td>
                        </tr>

                    </table>
                    <mat-paginator [pageSizeOptions]="[25, 50, 100]" aria-label="Select page of users">
                    </mat-paginator>
                </div>
            </div>
        </div>
    </div>
</section>