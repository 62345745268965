<nav class="navbar navbar-light bg-light">
    <a class="navbar-brand">Dashboard</a>
    <form class="form-inline">
      <input class="form-control mr-3" type="search" placeholder="Search" aria-label="Search">
    </form>
  </nav>

<section class="auction-client-wrapper">
    <div class="row" (click)="hideSideBar()">
        <div class="col-lg-6 mb-3 mb-lg-4 ">
            <div class="card bg-transparent border-0 h-100">
                <div class="card-header bg-transparent border-bottom-0 mb-4">
                    <h4 class="text-primary">CARD 1</h4>
                </div>
                <div class="card-body shadow rounded-xxl bg-white py-4 py-lg-5 px-3 px-lg-4">
                    <div>
                        <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart"
                            [xaxis]="chartOptions.xaxis" [title]="chartOptions.title">
                        </apx-chart>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 mb-3 mb-lg-4">
            <div class="card bg-transparent border-0 h-100">
                <div class="card-header bg-transparent border-bottom-0 mb-3">
                    <h4 class="text-primary">CARD 2</h4>
                </div>
                <div class="card-body shadow rounded-xxl bg-white py-4 py-lg-5 px-3 px-lg-4">
                    <div>
                        <apx-chart [series]="lineChart.series" [chart]="lineChart.chart" [xaxis]="lineChart.xaxis"
                            [title]="lineChart.title">
                        </apx-chart>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 mb-3 mb-lg-4">
            <div class="card bg-transparent border-0 h-100">
                <div class="card-header bg-transparent border-bottom-0 mb-3">
                    <h4 class="text-primary">CARD 3</h4>
                </div>
                <div class="card-body shadow rounded-xl bg-white py-4 py-lg-5 px-3 px-lg-4">
                    <div>
                        <apx-chart [series]="areaChart.series" [chart]="areaChart.chart" [xaxis]="areaChart.xaxis"
                            [title]="areaChart.title">
                        </apx-chart>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 mb-3 mb-lg-4">
            <div class="card bg-transparent border-0 h-100">
                <div class="card-header bg-transparent border-bottom-0 mb-3">
                    <h4 class="text-primary">CARD 4</h4>
                </div>
                <div class="card-body shadow rounded-xxl bg-white py-4 py-lg-5 px-3 px-lg-4">
                    <div class="d-flex justify-content-center">
                        <apx-chart [title]="pieChart.title" [series]="pieChart.series" [chart]="pieChart.chart" [labels]="pieChart.labels"
                            [responsive]="pieChart.responsive">
                        </apx-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>