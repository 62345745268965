<!--header start-->
<header class="box-absolute d-flex align-items-center topline text-white">
    <app-sidebar></app-sidebar>
    <div class="page-title-center mt-3">
        <div class="text-center">
            <h1>{{reportName}}</h1>
        </div>
    </div>
    <div class="breadcrumb-div">
        <ul class="breadcrumb p-0 m-0">
            <li class="breadcrumb-item 3" id="0"><a href="dashboard.html">Dashboard</a></li>
            <li class="breadcrumb-item 3" id="1"><a href="/v1/reports/listing">Reports</a></li>
            <li class="breadcrumb-item"><a>{{reportName}}</a></li>
        </ul>
    </div>
</header>

<!--header end-->
<section class="banner text-center text-white d-flex" id="bnner">
    <div class="container position-relative">
        <div class="row justify-content-center">
            <div class="col-lg-12">
            </div>
        </div>
    </div>
</section>

<!-- request start -->
<section class="request-demo-wrapper pb-10">
    <div class="container shadow bg-white ">
        <div class="row">
            <div class="col-lg-12">
                <div class="box">
                    <form [formGroup]="inputform" (onsubmit)="download">
                        <div class="row justify-content-center">
                            <div class="col-md-4" *ngFor="let item of inputParams; let i=index; let l=last">
                                <div class="form-group">
                                    <label class="form-label">{{item.fieldLabel}} :-</label>
                                    <!-- pattern={{item.regex}} -->
                                    <input class="form-control" id="{{item.fieldName}}" formControlName={{item.fieldName}}
                                         type="{{item.type}}" 
                                        [ngClass]="{'is-invalid': inputform.controls[item.fieldName].invalid && ( inputform.controls[item.fieldName].dirty || inputform.controls[item.fieldName].touched )}">
                                    <!-- <div *ngIf="item.mandatory == true">
                                        <div *ngIf="inputform.controls[item.fieldName].invalid && (inputform.controls[item.fieldName].dirty || inputform.controls[item.fieldName].touched)"
                                            class="text-danger">{{item.fieldLabel}} Required</div>
                                    </div>
                                    <div class="text-danger" *ngIf="inputform.controls[item.fieldName].errors?.pattern">
                                        {{item.errorMsg}}
                                    </div> -->
                                    <div *ngIf="item.mandatory == true">
                                        <div *ngIf="inputform.controls[item.fieldName].invalid && (inputform.controls[item.fieldName].dirty || inputform.controls[item.fieldName].touched)"
                                            class="text-danger">{{item.fieldLabel}} Required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-md-2" *ngFor="let item of outputParams">
                                <button (click)="download(item)" type="submit" class="btn-primary mt-2">
                                    {{ "Download " + item }}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>